import apiCall from '../../../utils/api/api';
import {
  CREATE_BRUSHFIRE_ERROR,
  CREATE_BRUSHFIRE_REQUEST,
  CREATE_BRUSHFIRE_SUCCESS,
  DELETE_BRUSHFIRE_ERROR,
  DELETE_BRUSHFIRE_REQUEST,
  DELETE_BRUSHFIRE_SUCCESS,
  EDIT_BRUSHFIRE_ERROR,
  EDIT_BRUSHFIRE_REQUEST,
  EDIT_BRUSHFIRE_SUCCESS,
  GET_BRUSHFIRE_ERROR,
  GET_BRUSHFIRE_EVENT_GROUP_LIST_ERROR,
  GET_BRUSHFIRE_EVENT_GROUP_LIST_REQUEST,
  GET_BRUSHFIRE_EVENT_GROUP_LIST_SUCCESS,
  GET_BRUSHFIRE_EVENT_META_ERROR,
  GET_BRUSHFIRE_EVENT_META_REQUEST,
  GET_BRUSHFIRE_EVENT_META_SUCCESS,
  GET_BRUSHFIRE_EVENT_STANDALONE_LIST_ERROR,
  GET_BRUSHFIRE_EVENT_STANDALONE_LIST_REQUEST,
  GET_BRUSHFIRE_EVENT_STANDALONE_LIST_SUCCESS,
  GET_BRUSHFIRE_EVENT_TYPES_ERROR,
  GET_BRUSHFIRE_EVENT_TYPES_REQUEST,
  GET_BRUSHFIRE_EVENT_TYPES_SUCCESS,
  GET_BRUSHFIRE_EVENTS_ERROR,
  GET_BRUSHFIRE_EVENTS_REQUEST,
  GET_BRUSHFIRE_EVENTS_SUCCESS,
  GET_BRUSHFIRE_FOLDER_LIST_ERROR,
  GET_BRUSHFIRE_FOLDER_LIST_REQUEST,
  GET_BRUSHFIRE_FOLDER_LIST_SUCCESS,
  GET_BRUSHFIRE_REQUEST,
  GET_BRUSHFIRE_SUCCESS,
  GET_BRUSHFIRE_VIDEO_LIST_ERROR,
  GET_BRUSHFIRE_VIDEO_LIST_REQUEST,
  GET_BRUSHFIRE_VIDEO_LIST_SUCCESS,
  IGNORE_BRUSHFIRE_ERROR,
  IGNORE_BRUSHFIRE_REQUEST,
  IGNORE_BRUSHFIRE_SUCCESS, REFRESH_QR_CODE_ERROR, REFRESH_QR_CODE_REQUEST, REFRESH_QR_CODE_SUCCESS,
} from '../../actions/brushfire/brushfire';

const state = {
  isLoading: false,
  brushfireEvents: [],
  brushfireList: [],
  brushfireMediaList: [],
  brushfireEventGroupList: [],
  brushfireEventStandaloneList: [],
  pagination: {},
};

const actions = {
  [GET_BRUSHFIRE_EVENTS_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_BRUSHFIRE_EVENTS_REQUEST);
    apiCall.get('choice/brushfire/event')
      .then((resp) => {
        commit(GET_BRUSHFIRE_EVENTS_SUCCESS, resp.data.data);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_BRUSHFIRE_EVENTS_ERROR, error);
        reject(error);
      });
  }),
  [GET_BRUSHFIRE_EVENT_TYPES_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_BRUSHFIRE_EVENT_TYPES_REQUEST);
    apiCall.get(`choice/brushfire/event/${params.brushfireEventId}/type`)
      .then((resp) => {
        commit(GET_BRUSHFIRE_EVENT_TYPES_SUCCESS, resp.data.data);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_BRUSHFIRE_EVENT_TYPES_ERROR, error);
        reject(error);
      });
  }),
  [GET_BRUSHFIRE_EVENT_META_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_BRUSHFIRE_EVENT_META_REQUEST);
    apiCall.get(`choice/brushfire/event/${params.brushfireEventId}`)
      .then((resp) => {
        commit(GET_BRUSHFIRE_EVENT_META_SUCCESS, resp.data.data);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_BRUSHFIRE_EVENT_META_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [CREATE_BRUSHFIRE_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(CREATE_BRUSHFIRE_REQUEST);

    apiCall.post(`/${accountId}/brushfire-event`, data)
      .then((res) => {
        commit(CREATE_BRUSHFIRE_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_BRUSHFIRE_ERROR, error);
        reject(error);
      });
  }),
  [GET_BRUSHFIRE_FOLDER_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_BRUSHFIRE_FOLDER_LIST_REQUEST);
    apiCall.get(`${params.accountId}/brushfire-event/list/media-folder/${params.folderId}`)
      .then((resp) => {
        commit(GET_BRUSHFIRE_FOLDER_LIST_SUCCESS, resp.data.data);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_BRUSHFIRE_FOLDER_LIST_ERROR, error);
        reject(error);
      });
  }),
  [GET_BRUSHFIRE_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_BRUSHFIRE_REQUEST);
    apiCall.get(`${params.accountId}/brushfire-event/${params.brushfireEventId}`)
      .then((resp) => {
        commit(GET_BRUSHFIRE_SUCCESS, resp.data.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_BRUSHFIRE_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [EDIT_BRUSHFIRE_REQUEST]: ({ commit }, { accountId, brushfireEventId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_BRUSHFIRE_REQUEST);
    apiCall.put(`${accountId}/brushfire-event/${brushfireEventId}`, data)
      .then(() => {
        commit(EDIT_BRUSHFIRE_SUCCESS);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_BRUSHFIRE_ERROR, error);
        reject(error);
      });
  }),
  [DELETE_BRUSHFIRE_REQUEST]:
    ({ commit }, { accountId, brushfireEventId }) => new Promise((resolve, reject) => {
      commit(DELETE_BRUSHFIRE_REQUEST);

      const url = `${accountId}/brushfire-event/${brushfireEventId}`;

      apiCall.delete(url)
        .then(() => {
          commit(DELETE_BRUSHFIRE_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(DELETE_BRUSHFIRE_ERROR, error);
          reject(error);
        });
    }),
  [GET_BRUSHFIRE_VIDEO_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_BRUSHFIRE_VIDEO_LIST_REQUEST);
    apiCall.get(`${params.accountId}/brushfire-event/list/media-item/${params.mediaItemId}`)
      .then((resp) => {
        commit(GET_BRUSHFIRE_VIDEO_LIST_SUCCESS, resp.data.data);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_BRUSHFIRE_VIDEO_LIST_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [IGNORE_BRUSHFIRE_REQUEST]: ({ commit }, { accountId, brushfireEventId, data }) => new Promise((resolve, reject) => {
    commit(IGNORE_BRUSHFIRE_REQUEST);
    apiCall.put(`${accountId}/brushfire-event/${brushfireEventId}/ignore`, data)
      .then(() => {
        commit(IGNORE_BRUSHFIRE_SUCCESS);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(IGNORE_BRUSHFIRE_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [GET_BRUSHFIRE_EVENT_GROUP_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_BRUSHFIRE_EVENT_GROUP_LIST_REQUEST);
    apiCall.get(`${params.accountId}/brushfire-event/list/standalone-event-group/${params.stEvId}`)
      .then((resp) => {
        commit(GET_BRUSHFIRE_EVENT_GROUP_LIST_SUCCESS, resp.data.data);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_BRUSHFIRE_EVENT_GROUP_LIST_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [GET_BRUSHFIRE_EVENT_STANDALONE_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_BRUSHFIRE_EVENT_STANDALONE_LIST_REQUEST);
    apiCall.get(`${params.accountId}/brushfire-event/list/standalone-event/${params.stEvId}`)
      .then((resp) => {
        commit(GET_BRUSHFIRE_EVENT_STANDALONE_LIST_SUCCESS, resp.data.data);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_BRUSHFIRE_EVENT_STANDALONE_LIST_ERROR, error);
        reject(error);
      });
  }),
  [REFRESH_QR_CODE_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(REFRESH_QR_CODE_REQUEST);
    apiCall.get(`util/qrcode?content=${params.url}`)
      .then((resp) => {
        commit(REFRESH_QR_CODE_SUCCESS, resp.data.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(REFRESH_QR_CODE_ERROR, error);
        reject(error);
      });
  }),
};

const mutations = {
  [GET_BRUSHFIRE_EVENTS_SUCCESS]: (state, payload) => {
    state.brushfireEvents = payload;
    state.isLoading = false;
  },
  [GET_BRUSHFIRE_EVENTS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_BRUSHFIRE_EVENTS_ERROR]: () => {
    state.isLoading = false;
  },
  [CREATE_BRUSHFIRE_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_BRUSHFIRE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_BRUSHFIRE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_BRUSHFIRE_EVENT_TYPES_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_BRUSHFIRE_EVENT_TYPES_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_BRUSHFIRE_EVENT_TYPES_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_BRUSHFIRE_FOLDER_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_BRUSHFIRE_FOLDER_LIST_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.brushfireList = payload;
    state.pagination = payload.pagination;
  },
  [GET_BRUSHFIRE_FOLDER_LIST_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_BRUSHFIRE_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_BRUSHFIRE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_BRUSHFIRE_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_BRUSHFIRE_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_BRUSHFIRE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_BRUSHFIRE_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_BRUSHFIRE_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_BRUSHFIRE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_BRUSHFIRE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_BRUSHFIRE_VIDEO_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_BRUSHFIRE_VIDEO_LIST_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.brushfireMediaList = payload;
  },
  [GET_BRUSHFIRE_VIDEO_LIST_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_BRUSHFIRE_EVENT_GROUP_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_BRUSHFIRE_EVENT_GROUP_LIST_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.brushfireEventGroupList = payload;
  },
  [GET_BRUSHFIRE_EVENT_GROUP_LIST_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_BRUSHFIRE_EVENT_STANDALONE_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_BRUSHFIRE_EVENT_STANDALONE_LIST_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.brushfireEventStandaloneList = payload;
  },
  [GET_BRUSHFIRE_EVENT_STANDALONE_LIST_ERROR]: () => {
    state.isLoading = false;
  },
  [REFRESH_QR_CODE_REQUEST]: () => {
    state.isLoading = true;
  },
  [REFRESH_QR_CODE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [REFRESH_QR_CODE_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
