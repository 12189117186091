<template>
  <div>
    <div v-if="deliveryTargets && deliveryTargets.length">
      <div
        v-for="(item, index) in deliveryTargets"
        :key="`${item.id ? item.id : index}-${item.appName}`"
        class="delivery-target-list"
      >
        <div
          :class="[
            'delivery-target-item',
            errors && errors[index] ? 'error-item' : ''
          ]"
        >
          <div class="delivery-target-title">
            <div class="toggle-btn">
              <ToggleButton
                v-model="item.enabled"
                color="#2591CF"
                :sync="true"
                :labels="false"
                @change="handleStateChange(item)"
              />
            </div>
            <div class="item-title">
              {{ customTypes.filter((i) => i.code === item.type )[0].name }}
            </div>
          </div>
          <div class="icons-block">
            <div
              v-if="showRecorderSync"
              class="toggle-btn"
            >
              <ToggleButton
                v-model="item.recorderSync"
                color="#2591CF"
                :sync="true"
                :labels="{ checked: 'sync', unchecked: 'sync' }"
                :width="55"
                @change="handleSyncChange(item)"
              />
            </div>
            <div
              class="icon-edit"
              :title="'Edit Delivery Target'"
            >
              <inline-svg
                :src="iconEdit"
                class="svg-icon"
                @click="handleEdit(item, index, errors[index])"
              >
              </inline-svg>
            </div>
            <div
              class="icon-remove"
              :title="'Delete Delivery Target'"
            >
              <inline-svg
                :src="iconRemove"
                class="svg-icon"
                @click="() => handleRemove(index)"
              >
              </inline-svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="empty-search"
    >
      No targets to display
    </div>
  </div>
</template>
<script>
import iconAdd from '../../../assets/icons/media-add.svg';
import CONFIG from '../../../constants/config';
import iconRemove from '../../../assets/icons/icon-remove-target.svg';
import iconEdit from '../../../assets/icons/icon-edit-acc.svg';

export default {
  name: 'ListlDeliveryTargets',
  props: {
    handleRemove: {
      type: Function,
      default: () => {},
    },
    handleEdit: {
      type: Function,
      default: () => {},
    },
    handleStateChange: {
      type: Function,
      default: () => {},
    },
    handleSyncChange: {
      type: Function,
      default: () => {},
    },
    deliveryTargets: {
      type: Array,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    showRecorderSync: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconAdd,
      iconRemove,
      customTypes: CONFIG.deliveryTargetTypes,
      iconEdit,
    };
  },
};
</script>
<style>
  .delivery-target-item {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .icon-edit,
  .icon-remove {
    cursor: pointer;
  }

  .icon-remove {
    margin-top: -2px;
    padding-left: 3px;
  }

  .toggle-btn {
    margin-right: 10px;
  }

  .item-title {
    font-size: 16px;
    padding-top: 3px;
  }

  @media all and (max-width: 1400px) {
    .item-title {
      display: block;
    }
  }

  .empty-search {
    color: #4c5057;
    padding-top: 40px;
    text-align: center;
  }

  .error-item {
    color: #fb3951;
  }

  .icons-block,
  .delivery-target-title {
    display: flex;
  }
</style>
