<template>
  <div class="overlay">
    <AlternativeAudioForm
      :altType="altType"
      :onCloseAlt="onCloseAlt"
      :updateAssetList="updateAssetList"
      :hasDefaultAssets="hasDefaultAssets"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { SET_EDIT_FORM } from '../../../../store/actions/common';
import AlternativeAudioForm from './AlternativeAudioForm.vue';

export default {
  name: 'AlternativeAudio',
  components: {
    AlternativeAudioForm,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: null,
    },
    modalMode: {
      type: String,
      default: '',
    },
    onCloseAlt: {
      type: Function,
      default: () => {},
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
    showLoader: {
      type: Boolean,
      default: true,
    },
    updateAssetList: {
      type: Function,
      default: () => {},
    },
    altType: {
      type: String,
      default: '',
    },
    hasDefaultAssets: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalType: 'subtitle',
    };
  },
  computed: {
    ...mapState({
      isEditedForm: (state) => state.common.isEditedForm,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  methods: {
    preventNav(event) {
      if (this.modal && this.isEditedForm) {
        event.returnValue = 'You have unfinished changes!';
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
    },
  },
};
</script>
<style>
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
