import { render, staticRenderFns } from "./CmsWrapper.vue?vue&type=template&id=2bc1fe70&scoped=true&"
import script from "./CmsWrapper.vue?vue&type=script&lang=js&"
export * from "./CmsWrapper.vue?vue&type=script&lang=js&"
import style0 from "./CmsWrapper.vue?vue&type=style&index=0&id=2bc1fe70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bc1fe70",
  null
  
)

export default component.exports