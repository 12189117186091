<template>
  <div class="flex">
    <div>
      <label
        class="btn upload-btn"
        for="replace"
      >
        Yes
      </label>
      <input
        id="replace"
        ref="replace"
        class="input"
        type="file"
        accept="video/*,.mkv,audio/*"
        @click="fileInputClick"
        @change="fileInputChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  GENERATE_UPLOAD_URL_REPLACE_REQUEST,
  FILE_APPEND_REPLACE_REQUEST,
  SEND_TRANSCODE_REPLACE_REQUEST,
} from '../../store/actions/replaceActions/replace';
import CONFIG from '../../constants/config';
import { SET_UPLOADING_FILES } from '../../store/actions/UploadingActions/upload';

export default {
  name: 'ReplaceMedia',
  props: {
    closeModalhandler: {
      type: Function,
      default: () => {},
    },
    getMediaList: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      lockedError: CONFIG.errorMessages.lockedError,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.common.profile,
      mediaItemId: (state) => state.mediaItem.mediaItem.id,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getAppConfig',
    ]),
  },
  methods: {
    fileInputClick(e) {
      e.target.value = null;
    },
    fileInputChange() {
      const chunkSize = Number(this.getAppConfig.mediaItem.uploading.maxPartSize);
      const { files } = this.$refs.replace;
      const {
        errorTypeFormat,
        invalidFileFormat,
        failedUpload,
        errorTypeSize,
        failedSizeUpload,
      } = CONFIG.errorMessages.upload;

      this.$store.dispatch(SET_UPLOADING_FILES, files);

      Array.from(files).forEach((file) => {
        this.isLoading = true;

        const params = {
          accountId: this.getCurrentAccountId,
          itemId: this.mediaItemId,
          file,
        };
        this.$refs.replace.value = '';
        this.closeModalhandler();
        this.$store.dispatch(GENERATE_UPLOAD_URL_REPLACE_REQUEST, params)
          .then(async (res) => {
            let chunkCounter = 0;
            let loaded = 0;

            const appendFile = (params) => {
              const {
                invalidTranscode,
              } = CONFIG.errorMessages.upload;
              return this.$store.dispatch(FILE_APPEND_REPLACE_REQUEST, params)
                .then((data) => {
                  if (data.totalSize <= chunkSize ? !!data.id : data.id && data.isLastChunk) {
                    const params = {
                      accountId: this.getCurrentAccountId,
                      itemId: data.id,
                    };
                    this.$store.dispatch(SEND_TRANSCODE_REPLACE_REQUEST, params)
                      .then(() => {
                        this.getMediaList(localStorage.getItem('currentPage-media-list') || 1);
                      })
                      .catch(() => {
                        this.$toasted.global.error({
                          message: invalidTranscode,
                        });
                      });
                  }
                });
            };

            for (let i = 0; i < res.uploadUrl.length; i += 1) {
              chunkCounter += 1;
              const numberofChunks = Math.ceil(res.file.size / chunkSize);
              const start = 0;
              const chunkEnd = Math.min(start + chunkSize, res.file.size);
              let chunk = res.file.slice(start, chunkEnd);

              if (i !== 0) {
                loaded += chunkSize;
              }

              if (loaded <= res.file.size) {
                chunk = file.slice(loaded, loaded + chunkSize);
              } else {
                loaded = res.file.size;
              }

              const params = {
                file: res.file.size <= chunkSize ? res.file : chunk,
                id: res.id,
                uploadUrl: res.uploadUrl[i],
                numberofChunks,
                chunkCounter,
                totalSize: res.file.size,
              };

              if (res.file.size > chunkSize && (i + 1 === res.uploadUrl.length)) {
                params.isLastChunk = true;
              }

              // eslint-disable-next-line no-await-in-loop
              await appendFile(params);
            }
          })
          .catch((err) => {
            if (err.data && err.data.error === this.lockedError) {
              this.$toasted.global.error({
                message: CONFIG.errorMessages.lockedMessage,
              });
              this.$refs.replace.value = '';
              return;
            }

            if (err === errorTypeFormat) {
              this.$toasted.global.error({
                message: invalidFileFormat,
              });
            } else if (err.status === CONFIG.statuses.badRequest) {
              this.$toasted.global.error({
                message: failedUpload,
              });
            } else if (err === errorTypeSize) {
              this.$toasted.global.error({
                message: failedSizeUpload,
              });
            }
          });
      });
    },
  },
};
</script>

<style scoped>
  .flex {
    display: flex;
    justify-content: flex-end;
  }

  .input {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
  }

  .btn:hover {
    opacity: 0.85;
  }

  .btn {
    align-items: center;
    background-color: #22a5fd;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    height: 40px;
    justify-content: center;
    letter-spacing: 0.089em;
    line-height: 19px;
    outline: none;
    text-transform: capitalize;
    transition: all 100ms ease;
    width: 70px;
  }

  .disabled-button {
    cursor: not-allowed;
  }
</style>
