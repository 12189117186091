<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="input-wrapper">
        <MaterialInput
          v-model="formData.name"
          v-bind="formFields.name"
          :label="isAPI ? 'API Key Name' : ''"
        />
      </div>
      <div
        v-if="isAPI ? false : formData.name === compName"
        class="delete-info"
      >
        Link to Account History you can find here:
        <span class="deleted-link">
          {{ `/deleted_accounts/${compName}_${timestamp}` }}
        </span>
      </div>
      <div class="button-wrapper">
        <Button
          :title="cancelButtonTitle"
          :classType="'warning'"
          :eventFunction="close"
        />
        <Button
          type="submit"
          :title="confirmButtonTitle"
          :classType="'primary'"
        />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import CONFIG from '../../constants/config';

import BaseForm from './BaseForm.vue';
import Button from '../common/Button.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import { DELETE_ACCOUNT_REQUEST } from '../../store/actions/accountsActions/accounts';
import { DELETE_API_KEY_REQUEST } from '../../store/actions/api/api_keys';

export default {
  name: 'DeleteAccForm',
  components: {
    Button,
    MaterialInput,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'deleteAcc',
    },
    confirmButtonTitle: {
      type: String,
      default: 'Delete',
    },
    cancelButtonTitle: {
      type: String,
      default: 'Cancel',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isEditDelete: {
      type: Boolean,
      default: false,
    },
    isAPI: {
      type: Boolean,
      default: false,
    },
    compName: {
      type: String,
      default: '',
    },
    accId: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    getAccounts: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      timestamp: '',
      isModalOpen: false,
      enabledError: '',
      formData: {
        name: '',
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
      },
      submitEvent: this.isAPI ? DELETE_API_KEY_REQUEST : DELETE_ACCOUNT_REQUEST,
      successMessage: this.isAPI ? 'The API Key has been successfully deleted' : 'The Account has been successfully deleted',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  mounted() {
    this.getTime();
  },
  methods: {
    close() {
      this.formData.companyName = '';
      this.onClose();
    },
    getTime() {
      this.timestamp = Date.now();
      return Date.now();
    },
    getRequestData() {
      const data = {
        accountId: this.accId,
        postfix: `${this.timestamp}`,
        name: this.formData.name,
      };

      if (this.isAPI) {
        delete data.postfix;
        delete data.accountId;
        data.apiId = this.accId;
      }

      return data;
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.formData.name = '';
        if (this.isEditDelete) {
          const route = this.isAPI ? 'api-keys' : 'accounts';
          this.$router.push(`/settings/${route}`);
        } else {
          this.getAccounts();
        }
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>
.status-checkbox {
  margin-bottom: 30px;
}

.checkbox {
  margin-top: 3px;
}

.button-wrapper {
  display: flex;
  align-items: center;
}

.edit-button-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 26px;
}

.breadcrumbs {
  margin-bottom: 25px;
}

.system {
  color: #4c5057;
}

.delition-wrapper {
  padding: 15px 20px;
  padding-top: 0;
}

.input-wrapper {
  margin-left: 30px;
  margin-right: 30px;
}

.delete-info {
  margin-right: 30px;
  margin-left: 30px;
}

.deleted-link {
  color: #2591CF;
}

.button-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  padding-top: 10px;
}

.button-wrapper button:first-child {
  margin-right: 15px;
}
</style>
