<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, classes, required, ariaInput, ariaMsg }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    mode="passive"
  >
    <div
      class="group"
      :class="classes"
    >
      <label
        class="checkbox-container"
        :class="className"
      >
        <input
          v-model="valueModel"
          type="checkbox"
          :name="fieldName"
          :disabled="disabled"
          v-bind="ariaInput"
        />
        <span class="checkmark"></span>
        <div
          :title="label"
          class="checkbox-label"
        >
          {{ label }}
        </div>
      </label>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
          v-bind="ariaMsg"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>
<script>
import BaseMaterialInput from './BaseMaterialInput.vue';

export default {
  name: 'MaterialCheckboxItem',
  extends: BaseMaterialInput,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
  .checkbox-container {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    margin-right: 25px;
    padding-top: 10px;
    position: relative;
    user-select: none;
  }

  .checkbox-container input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  .checkmark {
    border: 1px solid #383b40;
    border-radius: 3px;
    box-sizing: border-box;
    height: 21px;
    margin-right: 6px;
    margin-top: -2px;
    width: 21px;
  }

  .checkbox-container:hover input ~ .checkmark {
    border: 1px solid #2591cf;
  }

  .disabled-checkbox:hover input ~ .checkmark {
    border: 1px solid #383b40;
    cursor: not-allowed;
  }

  .disabled-checkbox:hover {
    cursor: not-allowed;
  }

  .checkbox-container input:checked ~ .checkmark {
    border: 1px solid #2591cf;
  }

  .checkmark::after {
    content: '';
    display: none;
    position: absolute;
  }

  .checkbox-container input:checked ~ .checkmark::after {
    display: block;
  }

  .checkbox-container .checkmark::after {
    border: solid #2591cf;
    border-width: 0 2px 2px 0;
    height: 10px;
    left: 7px;
    top: 10px;
    transform: rotate(45deg);
    width: 6px;
  }

  .inspector-checkbox .checkmark {
    background: #222;
  }

  .group {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  .checkbox-label {
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
</style>
