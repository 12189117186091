var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"validator",attrs:{"name":_vm.fieldName,"vid":_vm.name,"rules":_vm.rules,"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
return [_c('div',{class:[
      'picker-datetime',
      _vm.blockClassName,
      errors.length && 'failed',
      _vm.isClosed ? 'closed-picker' : ''
    ]},[_c('div',{staticClass:"label",class:_vm.classNameLabel},[_vm._v(" "+_vm._s(_vm.fieldLabel)+" "),(required)?_c('span',{staticClass:"asterisk"},[_vm._v(" * ")]):_vm._e()]),_c('div',{staticClass:"field-wrapper"},[_c('datepicker',{staticClass:"picker-date",attrs:{"inputClass":(_vm.className + " picker"),"minimumView":_vm.minimumView,"format":_vm.dateFormat,"disabled":_vm.disabled,"disabledDates":_vm.disabledDates,"clearButton":true},model:{value:(_vm.dateModel),callback:function ($$v) {_vm.dateModel=$$v},expression:"dateModel"}}),_c('span',{ref:"pickerTimeRef"},[_c('vue-timepicker',{staticClass:"time-picker",attrs:{"disabled":_vm.disabled,"format":_vm.timeFormat},on:{"change":_vm.onChangeTime},model:{value:(_vm.timeModel),callback:function ($$v) {_vm.timeModel=$$v},expression:"timeModel"}})],1)],1),_c('transition',{attrs:{"name":"bounce"}},[(errors.length)?_c('span',{staticClass:"error-label"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }