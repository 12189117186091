<template>
  <div class="an-table">
    <div class="an-table-header">
      <div class="an-th-title">
        <SortingByColumn
          :sortField="sortField.title"
          :currentSortParams="currentSortParams"
          :handleSort="setCurrentSortParams"
        />
        <div
          v-if="withSelect"
          class="an-th-select"
          @change.capture="onSelectType"
        >
          View by:
          <select
            :value="selectedType"
            class="an-table-select"
          >
            <option
              v-for="geoType of filterByGeo"
              :key="geoType.value"
              class="an-select-option"
              :value="geoType.value"
            >
              <span class="an-select-span">{{ geoType.name }}</span>
            </option>
          </select>
          <inline-svg
            :src="iconSortDown"
            :class="['svg', 'svg-an-table']"
          >
          </inline-svg>
        </div>
        <span v-else>
          {{ selectedTab === 'geo' ? 'Country' : selectedTab === 'device' ? 'Device' : 'Browser' }}
        </span>
      </div>
      <div class="an-th-plays">
        <SortingByColumn
          :sortField="sortField.views"
          :currentSortParams="currentSortParams"
          :handleSort="setCurrentSortParams"
        />
        Plays
      </div>
      <div class="an-th-viewers">
        <SortingByColumn
          :sortField="sortField.uniques"
          :currentSortParams="currentSortParams"
          :handleSort="setCurrentSortParams"
        />
        Unique Viewers
      </div>
      <div class="an-th-avg">
        <SortingByColumn
          :sortField="sortField.playtime"
          :currentSortParams="currentSortParams"
          :handleSort="setCurrentSortParams"
        />
        Avg. Play Time
      </div>
      <div class="an-th-total">
        <SortingByColumn
          :sortField="sortField.hours"
          :currentSortParams="currentSortParams"
          :handleSort="setCurrentSortParams"
        />
        Total Play Time
      </div>
    </div>
    <div class="an-table-body">
      <div
        v-for="(item, index) in sortedMediaItems"
        :key="`${item.views}_${item.uniques}_${index}`"
      >
        <div
          v-if="(!resized && index < 5) || resized"
          class="an-table-rows"
        >
          <div
            class="an-th-title"
            :title="getItemByType(item)"
          >
            {{ getItemByType(item) }}
          </div>
          <div class="an-th-plays">
            {{ item.views }}
          </div>
          <div class="an-th-viewers">
            {{ item.uniques }}
          </div>
          <div class="an-th-avg">
            {{ getFormatPlayTime(item.playtime) }}
          </div>
          <div class="an-th-total">
            {{ getFormatHourTime(item.hours) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!mediaItems.length"
      class="empty-chart"
    >
      No Metrics to display
    </div>

    <DataReloader
      name="simpleTable"
      :isHide="isLoadingFailed"
      :onReload="onReloadData"
    />
  </div>
</template>
<script>
import iconSortDown from '../../assets/icons/icon-sort-arrow-down.svg';
import SortingByColumn from '../common/SortingByColumn.vue';
import DataReloader from './DataReloader.vue';
import CONFIG from '../../constants/config';

export default {
  name: 'AnalyticsSimpleTable',
  components: {
    SortingByColumn,
    DataReloader,
  },
  props: {
    mediaItems: {
      type: Array,
      default: () => [],
    },
    resized: {
      type: Boolean,
      default: false,
    },
    withSelect: {
      type: Boolean,
      default: false,
    },
    selectedType: {
      type: String,
      default: 'country',
    },
    selectedTab: {
      type: String,
      default: 'geo',
    },
    onSelectType: {
      type: Function,
      default: () => {},
    },
    isLoadingFailed: {
      type: Boolean,
      default: true,
    },
    onReloadData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconSortDown,
      selected: 'Country',
      options: [],
      sortField: CONFIG.analyticSortField,
      titleValues: CONFIG.analyticTitleValues,
      currentSortParams: {
        field: 'uniques',
        order: CONFIG.sort.sortDown,
      },
      filterByGeo: CONFIG.analyticFilterByGeo,
    };
  },
  computed: {
    sortedMediaItems() {
      const sortingMediaItems = [...this.mediaItems];
      const sortingFieldName = this.currentSortParams.field !== 'title'
        ? this.currentSortParams.field
        : this.getTitleFieldName();

      sortingMediaItems.sort((a, b) => {
        const firstValue = (typeof a[sortingFieldName] === 'string')
          ? a[sortingFieldName].toLowerCase()
          : a[sortingFieldName];

        const secondValue = (typeof b[sortingFieldName] === 'string')
          ? b[sortingFieldName].toLowerCase()
          : b[sortingFieldName];

        if (firstValue < secondValue) {
          return 1;
        }
        if (firstValue > secondValue) {
          return -1;
        }
        return 0;
      });

      if (this.currentSortParams.order === CONFIG.sort.sortUp) {
        return sortingMediaItems.reverse();
      }
      return sortingMediaItems;
    },
  },
  watch: {
    mediaItems: {
      handler: 'getTitleFieldName',
      deep: true,
    },
  },
  methods: {
    getTitleFieldName() {
      const item = this.mediaItems[0] || {};
      return Object.keys(item).find((key) => this.titleValues.includes(key));
    },
    getItemByType(item) {
      switch (this.selectedTab) {
      case 'geo':
        return item[this.selectedType] ? item[this.selectedType] : 'Unknown';
      case 'device':
        return item.device;
      case 'browsers':
        return item.browser;
      default:
        return item[this.selectedType];
      }
    },
    getFormatPlayTime(minutes) {
      if (minutes) {
        const hrs = this.getInteger(minutes / 60);
        const mins = this.getInteger(minutes - (hrs * 60));
        const secs = this.getInteger(this.getDecimal(minutes) * 60);
        return (!hrs) ? `${mins} min ${secs} sec` : `${hrs} ${hrs === 1 ? 'hr' : 'hrs'} ${mins} min`;
      }
      return minutes;
    },
    getFormatHourTime(hours) {
      if (hours) {
        const hrs = this.getInteger(hours);
        const fullMins = this.getDecimal(hours) * 60;
        const mins = this.getInteger(fullMins);
        const secs = this.getInteger(this.getDecimal(fullMins) * 60);
        if (!hrs) {
          return `${mins} min ${secs} sec`;
        }
        return (hrs === 1) ? `${hrs} hr ${mins} min` : `${hrs.toLocaleString('en')} hrs ${mins} min`;
      }
      return hours;
    },
    getInteger(value) {
      return Math.trunc(value);
    },
    getDecimal(value) {
      return (value - Math.trunc(value));
    },
    setCurrentSortParams(field) {
      if (this.currentSortParams.field === field) {
        this.currentSortParams.order = (this.currentSortParams.order === CONFIG.sort.sortUp)
          ? CONFIG.sort.sortDown
          : CONFIG.sort.sortUp;
      } else {
        this.currentSortParams.field = field;
        this.currentSortParams.order = CONFIG.sort.sortDown;
      }
    },
  },
};
</script>
<style scoped>
  .an-table {
    position: relative;
    min-height: 120px;
  }

  .an-table-header,
  .an-table-rows {
    border-bottom: 1px solid #383b40;
    color: #ffffff;
    display: flex;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    padding: 5px 6px;
  }

  .an-table-header,
  .an-select-span {
    font-weight: 700;
  }

  .an-table-header > div:not(:first-child),
  .an-table-rows > div:not(:first-child) {
    text-align: center;
  }

  .an-table-header > div:last-child,
  .an-table-rows > div:last-child {
    text-align: right;
  }

  .an-th-title {
    display: flex;
    width: 37%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .an-th-plays,
  .an-th-viewers,
  .an-th-avg {
    width: 15%;
  }

  .an-th-total {
    width: 18%;
  }

  .analytics-icon .svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  .analytics-icon-filter .svg {
    cursor: pointer;
    height: 17px;
    width: 17px;
  }

  .an-table-select {
    z-index: 1;
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #fff;
    font: 700 16px 'Roboto', sans-serif;
    outline: none;
    padding: 0;
    width: 120px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 5px;
  }

  .svg {
    fill: #fff;
  }

  .an-table-body {
    max-height: 650px;
    overflow-y: auto;
  }

  .empty-chart {
    color: #4c5057;
    padding-top: 40px;
    text-align: center;
  }

  .an-th-select {
    display: flex;
  }

  .svg-an-table {
    position: relative;
    left: -10px;
    top: 10px;
  }

  .an-select-option {
    background: #383b40;
    color: #fff;
    font-size: 1em;
  }

  .an-select-span {
    padding: 2px 5px;
  }

</style>
