<script>
import FieldValueRow from '../../customFields/FieldValueRow.vue';

export default {
  name: 'CustomFieldFormMixin',
  components: {
    FieldValueRow,
  },
  props: {
  },
  data() {
    return {
      customFields: [],
      sortedCustomFields: [],
      errorMapping: {},
    };
  },
  methods: {
    getSortedFields(appearance = '') {
      if (!appearance) {
        return this.sortedCustomFields;
      }

      return this.sortedCustomFields.filter((f) => f.field.appearance === appearance);
    },
    hasSortedFields(appearance) {
      return this.getSortedFields(appearance).some((item) => item.field.enabled);
    },
    getFieldName(field) {
      return `fields.${field.id}.value`;
    },
    setCustomFields(fields) {
      this.customFields = fields;
      this.prepareErrorMapping();
      this.prepareSortedFields();
    },
    getFieldsData() {
      return this.getCustomFieldsData(this.customFields);
    },
    getCustomFieldsData(customFields) {
      const fields = [];

      if (customFields && customFields instanceof Array) {
        // fields should be sent in the same order, sortedCustomField array contains the same object
        // and value is passed to customFields array by link
        customFields.forEach((fieldValue) => {
          fields.push({
            field: fieldValue.field.id,
            value: fieldValue.fieldValue,
          });
        });
      }

      return fields;
    },
    prepareErrorMapping() {
      // eslint-disable-next-line no-unused-expressions
      this.customFields && this.customFields.length && this.customFields.forEach((item, index) => {
        this.errorMapping[this.getFieldName(item.field)] = [
          `${this.formPrefix}.fields.${index}.value`,
          `${this.formPrefix}.fields.${index}.field`,
        ];
      });
    },
    prepareSortedFields() {
      this.sortedCustomFields = [...this.customFields];
      this.sortedCustomFields.sort((a, b) => {
        if (a.field.label < b.field.label) {
          return -1;
        }
        if (a.field.label > b.field.label) {
          return 1;
        }
        return 0;
      });
    },
    hasCustomFieldErrors(fields, errors) {
      return fields.some((item) => {
        const fieldName = this.getFieldName(item.field);
        return errors[fieldName] && errors[fieldName].length;
      });
    },
  },
};
</script>
