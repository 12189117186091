<template>
  <div class="analytic-window">
    <div
      v-if="mediaItem.id"
      class="tabs-wrapper"
    >
      <div class="analytic-title">
        <Button
          :title="'Back to Inspector'"
          :className="'back-local'"
          :eventFunction="backToInspector"
          :srcSvg="iconBackTo"
          :hasBorder="true"
          :isBack="true"
        />
      </div>
      <Tabs
        :onSelectTab="handleSelectTab"
        :smallStyle="false"
      >
        <Tab
          v-if="!isRead && (mediaItem.type === 'livestream' || mediaItem.type === 'livestream_url')"
          :id="'live'"
          name="Live Event Details"
          :selected="mode === 'live'"
          :className="[tabErrors.general ? 'error-tab' : 'tab']"
        >
          <AddLiveStreamForm
            :isEdit="true"
            :mediaItem="mediaItem"
            :onClose="onClose"
            :getMediaList="getMediaList"
          />
        </Tab>
        <Tab
          :id="'analytics'"
          name="Analytics"
          :selected="mode === 'analytics'"
          :className="[tabErrors.general ? 'error-tab' : 'tab']"
        >
          <AnalyticsInspector
            :itemId="mediaItem.id"
            :onClose="onClose"
          />
        </Tab>
        <Tab
          v-if="!isRead"
          :id="'assets'"
          name="Assets"
          :selected="mode === 'assets'"
          :className="[tabErrors.general ? 'error-tab' : 'tab']"
        >
          <AssetList
            :isEdit="true"
            :mediaItem="mediaItem"
          />
        </Tab>
        <Tab
          v-if="enabledLanguages.length"
          :id="'localization'"
          name="Localization"
          :selected="mode === 'localization'"
          :className="[tabErrors.general ? 'error-tab' : 'tab']"
        >
          <MediaLocalization
            :enabledLanguages="enabledLanguages"
            :media="mediaItem"
          />
        </Tab>
        <Tab
          v-if="enabledLanguages.length
            ? (enabledLanguages.length && getCurrentAccount.brushfireProtectionEnabled && !isRead)
            : getCurrentAccount.brushfireProtectionEnabled && !isRead"
          :id="'protection'"
          name="Protection"
          :selected="mode === 'protection'"
          :className="[tabErrors.general ? 'error-tab' : 'tab']"
        >
          <VideoItemProtection :mediaItem="mediaItem" />
        </Tab>
      </Tabs>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { GET_LANGUAGES_REQUEST } from '../../../store/actions/localization/localizationActions';
import AnalyticsInspector from './AnalyticsInspector.vue';
import AddLiveStreamForm from '../../forms/AddLiveStreamForm.vue';
import AssetList from '../asset/AssetList.vue';
import Tabs from '../../common/Tabs/Tabs.vue';
import Tab from '../../common/Tabs/Tab.vue';
import Button from '../../common/Button.vue';
import MediaLocalization from '../../localization/MediaLocalization.vue';
import iconBackTo from '../../../assets/icons/icon-back-to.svg';
import VideoItemProtection from '../../Brushfire/VideoItemProtection.vue';
import { GET_MEDIA_ITEM_REQUEST } from '../../../store/actions/media/mediaItem';
import { GET_PROCESSING_SSE_CLOSED } from '../../../store/actions/media/mediaAsset';

export default {
  name: 'PeopleAndRoles',
  components: {
    VideoItemProtection,
    MediaLocalization,
    AssetList,
    AddLiveStreamForm,
    AnalyticsInspector,
    Button,
    Tabs,
    Tab,
  },
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    getMediaList: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      mode: '',
      mediaItem: {},
      iconBackTo,
      enabledLanguages: [],
      typeVOD: 'vod',
      audio: 'audio',
      tabErrors: {
        live: false,
        analytics: false,
      },
    };
  },
  computed: {
    ...mapState({
      isEditedForm: (state) => state.localization.isEditedForm,
    }),
    ...mapGetters([
      'getCurrentAccount',
      'isRead',
    ]),
  },
  created() {
    const itemParam = {
      accountId: this.getCurrentAccountId,
      itemId: this.$route.params.mediaId,
    };
    this.$store.dispatch(GET_MEDIA_ITEM_REQUEST, itemParam).then((res) => {
      this.mediaItem = res;
      this.selectCurrentTab();
    });

    const params = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };
    this.$store.dispatch(GET_LANGUAGES_REQUEST, params).then((res) => {
      this.enabledLanguages = res.data;
    });
  },
  destroyed() {
    this.$store.dispatch(GET_PROCESSING_SSE_CLOSED);
    sessionStorage.removeItem('metaTab');
  },
  methods: {
    selectCurrentTab() {
      if (sessionStorage.getItem('metaTab')) {
        this.mode = sessionStorage.getItem('metaTab');
        return;
      }

      if (this.mediaItem.type === this.typeVOD
        || this.isRead) {
        this.mode = 'analytics';
      }

      if (!this.isRead && this.mediaItem.type !== this.typeVOD) {
        this.mode = 'live';
      }

      if (this.mediaItem.type === 'audio') {
        this.mode = 'analytics';
      }
    },
    handleSelectTab(id) {
      this.mode = id;
      sessionStorage.setItem('metaTab', this.mode);

      if (id === 'live') {
        this.tabErrors.live = false;
      }

      if (id === 'analytics') {
        this.tabErrors.analytics = false;
      }
    },
    backToInspector() {
      this.$router.push({
        path: `/media/folder/${this.$route.params.folderId}?mediaId=${this.$route.params.mediaId}`,
        replace: true,
      });
    },
  },
};
</script>
<style>
  .analytic-window {
    background-color: #222;
    height: calc(100% - 60px);
    left: 0;
    overflow: auto;
    position: fixed;
    top: 50px;
    width: 100%;
    z-index: 10;
  }

  .tabs-wrapper {
    padding: 25px;
  }

  .back-local {
    padding-left: 0;
  }
</style>
