import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  GET_BROWSER_REQUEST,
  GET_BROWSER_SUCCESS,
  GET_BROWSER_ERROR,
  GET_DEVICE_REQUEST,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_ERROR,
  GET_TIME_PLAYED_REQUEST,
  GET_TIME_PLAYED_SUCCESS,
  GET_TIME_PLAYED_ERROR,
  GET_TOTAL_PLAY_REQUEST,
  GET_TOTAL_PLAY_SUCCESS,
  GET_TOTAL_PLAY_ERROR,
  GET_UNIQUE_PLAY_REQUEST,
  GET_UNIQUE_PLAY_SUCCESS,
  GET_UNIQUE_PLAY_ERROR,
  GET_GEO_LOCATION_REQUEST,
  GET_GEO_LOCATION_SUCCESS,
  GET_GEO_LOCATION_ERROR,
  GET_UNIQUE_COUNTRY_PLAYS_REQUEST,
  GET_UNIQUE_COUNTRY_PLAYS_SUCCESS,
  GET_UNIQUE_COUNTRY_PLAYS_ERROR,
  GET_UNIQUE_USA_STATES_PLAYS_REQUEST,
  GET_UNIQUE_USA_STATES_PLAYS_SUCCESS,
  GET_UNIQUE_USA_STATES_PLAYS_ERROR,
  GET_ITEM_LIST_STATS_REQUEST,
  GET_ITEM_LIST_STATS_SUCCESS,
  GET_ITEM_LIST_STATS_ERROR,
  GET_MEDIA_ITEM_LIST_REQUEST,
  GET_MEDIA_ITEM_LIST_SUCCESS,
  GET_MEDIA_ITEM_LIST_ERROR,
  SET_MEDIA_ITEM_LIST_CANCEL_TOKEN,
  GET_MEDIA_ITEM_LIST_ANALYTIC_REQUEST,
  GET_MEDIA_ITEM_LIST_ANALYTIC_SUCCESS,
  GET_MEDIA_ITEM_LIST_ANALYTIC_ERROR,
  GET_MEDIA_TOTAL_REQUEST,
  GET_MEDIA_TOTAL_SUCCESS,
  GET_MEDIA_TOTAL_ERROR,
  GET_MOST_REQUESTED_REQUEST,
  GET_MOST_REQUESTED_SUCCESS,
  GET_MOST_REQUESTED_ERROR,
  GET_GEO_LOCATION_BY_CITY_REQUEST,
  GET_GEO_LOCATION_BY_CITY_ERROR,
  GET_GEO_LOCATION_BY_CITY_SUCCESS,
  GET_GEO_LOCATION_BY_STATE_REQUEST,
  GET_GEO_LOCATION_BY_STATE_ERROR,
  GET_GEO_LOCATION_BY_STATE_SUCCESS,
} from '../../actions/analytic/analytic';

const state = {
  status: '',
  isLoading: false,
  isAnalyticLoading: false,
  source: null,
  mediaItems: [],
  itemStats: {},
  pagination: {},
};

const actions = {
  [GET_BROWSER_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_BROWSER_REQUEST);

    data.type = 'browser';
    data.granularity = 'none';

    let url = `${accountId}/youbora?type=${data.type}&timezone=${data.timezone}&timeframe=${data.timeframe}&granularity=${data.granularity}&mediaItems[]=${data.mediaItems}&mediaItemName=${encodeURIComponent(data.mediaItemName)}&mediaType=${data.mediaType}`;
    if (data.fromDate && data.toDate) {
      url = `${url}&fromDate=${data.fromDate}&toDate=${data.toDate}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_BROWSER_SUCCESS);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_BROWSER_ERROR);
        reject();
      });
  }),
  [GET_DEVICE_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_DEVICE_REQUEST);

    data.type = data.type || 'device';
    data.granularity = 'none';

    let url = `${accountId}/youbora?type=${data.type}&timezone=${data.timezone}&timeframe=${data.timeframe}&granularity=${data.granularity}&mediaItems[]=${data.mediaItems}&mediaItemName=${encodeURIComponent(data.mediaItemName)}&mediaType=${data.mediaType}`;
    if (data.fromDate && data.toDate) {
      url = `${url}&fromDate=${data.fromDate}&toDate=${data.toDate}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_DEVICE_SUCCESS);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_DEVICE_ERROR);
        reject();
      });
  }),
  [GET_TIME_PLAYED_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_TIME_PLAYED_REQUEST);

    data.type = 'hours_watched';

    let url = `${accountId}/youbora?type=${data.type}&timezone=${data.timezone}&timeframe=${data.timeframe}&granularity=${data.granularity}&mediaItems[]=${data.mediaItems}&mediaItemName=${encodeURIComponent(data.mediaItemName)}&mediaType=${data.mediaType}`;
    if (data.fromDate && data.toDate) {
      url = `${url}&fromDate=${data.fromDate}&toDate=${data.toDate}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_TIME_PLAYED_SUCCESS);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_TIME_PLAYED_ERROR);
        reject();
      });
  }),
  [GET_TOTAL_PLAY_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_TOTAL_PLAY_REQUEST);

    data.type = 'total_plays';

    let url = `${accountId}/youbora?type=${data.type}&timezone=${data.timezone}&timeframe=${data.timeframe}&granularity=${data.granularity}&mediaItems[]=${data.mediaItems}&mediaItemName=${encodeURIComponent(data.mediaItemName)}&mediaType=${data.mediaType}`;
    if (data.fromDate && data.toDate) {
      url = `${url}&fromDate=${data.fromDate}&toDate=${data.toDate}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_TOTAL_PLAY_SUCCESS);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_TOTAL_PLAY_ERROR);
        reject();
      });
  }),
  [GET_UNIQUE_PLAY_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_UNIQUE_PLAY_REQUEST);

    data.type = 'unique_plays';

    let url = `${accountId}/youbora?type=${data.type}&timezone=${data.timezone}&timeframe=${data.timeframe}&granularity=${data.granularity}&mediaItems[]=${data.mediaItems}&mediaItemName=${encodeURIComponent(data.mediaItemName)}&mediaType=${data.mediaType}`;
    if (data.fromDate && data.toDate) {
      url = `${url}&fromDate=${data.fromDate}&toDate=${data.toDate}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_UNIQUE_PLAY_SUCCESS);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_UNIQUE_PLAY_ERROR);
        reject();
      });
  }),
  [GET_GEO_LOCATION_REQUEST]: ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
    commit(GET_GEO_LOCATION_REQUEST);

    const requests = [
      dispatch(GET_UNIQUE_COUNTRY_PLAYS_REQUEST, params),
      dispatch(GET_UNIQUE_USA_STATES_PLAYS_REQUEST, params),
    ];

    Promise.all(requests)
      .then((values) => {
        commit(GET_GEO_LOCATION_SUCCESS);
        resolve(values);
      })
      .catch((err) => {
        commit(GET_GEO_LOCATION_ERROR);
        reject(err);
      });
  }),
  [GET_UNIQUE_COUNTRY_PLAYS_REQUEST]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_UNIQUE_COUNTRY_PLAYS_REQUEST);

    const params = {
      ...data,
      type: 'country',
      granularity: 'none',
    };

    let url = `${accountId}/youbora?type=${params.type}&timezone=${params.timezone}&timeframe=${params.timeframe}&granularity=${params.granularity}&mediaItems[]=${params.mediaItems}&mediaItemName=${encodeURIComponent(data.mediaItemName)}&mediaType=${data.mediaType}`;
    if (params.fromDate && params.toDate) {
      url = `${url}&fromDate=${params.fromDate}&toDate=${params.toDate}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_UNIQUE_COUNTRY_PLAYS_SUCCESS);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_UNIQUE_COUNTRY_PLAYS_ERROR);
        reject();
      });
  }),
  [GET_UNIQUE_USA_STATES_PLAYS_REQUEST]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_UNIQUE_USA_STATES_PLAYS_REQUEST);

    const params = {
      ...data,
      type: 'usa_states',
      granularity: 'none',
    };

    let url = `${accountId}/youbora?type=${params.type}&timezone=${params.timezone}&timeframe=${params.timeframe}&granularity=${params.granularity}&mediaItems[]=${params.mediaItems}&mediaItemName=${encodeURIComponent(data.mediaItemName)}&mediaType=${data.mediaType}`;
    if (params.fromDate && params.toDate) {
      url = `${url}&fromDate=${params.fromDate}&toDate=${params.toDate}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_UNIQUE_USA_STATES_PLAYS_SUCCESS);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_UNIQUE_USA_STATES_PLAYS_ERROR);
        reject();
      });
  }),
  [GET_ITEM_LIST_STATS_REQUEST]: ({ commit },
    { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_ITEM_LIST_STATS_REQUEST);

    data.type = 'item_list';
    data.granularity = 'none';

    let url = `${accountId}/youbora?type=${data.type}&timezone=${data.timezone}&timeframe=${data.timeframe}&granularity=${data.granularity}&mediaItems[]=${data.mediaItems}&mediaItemName=${encodeURIComponent(data.mediaItemName)}&mediaType=${data.mediaType}`;
    if (data.fromDate && data.toDate) {
      url = `${url}&fromDate=${data.fromDate}&toDate=${data.toDate}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_ITEM_LIST_STATS_SUCCESS);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_ITEM_LIST_STATS_ERROR);
        reject();
      });
  }),
  [GET_MEDIA_ITEM_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    commit(GET_MEDIA_ITEM_LIST_REQUEST);

    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_MEDIA_ITEM_LIST_CANCEL_TOKEN, { token: source });

    let url = `${params.accountId}/media-collection/item/search?p=${params.page}&l=15&filterField=title&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url = `${url}&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url,
      {
        cancelToken: source.token,
        cache: false,
      })
      .then((resp) => {
        commit(GET_MEDIA_ITEM_LIST_SUCCESS, resp.data);
        localStorage.setItem('analyticPage', resp.data.pagination.page);
        resolve(resp.data);
      }).catch(() => {
        commit(GET_MEDIA_ITEM_LIST_ERROR);
      });
  }),
  [GET_MEDIA_ITEM_LIST_ANALYTIC_REQUEST]:
    ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
      commit(GET_MEDIA_ITEM_LIST_ANALYTIC_REQUEST);

      dispatch(GET_MEDIA_ITEM_LIST_REQUEST, params)
        .then(() => {
          const mediaItems = state.mediaItems.map((item) => item.id);

          if (mediaItems.length) {
            const data = { ...params.data };
            data.mediaItems = mediaItems;

            dispatch(GET_ITEM_LIST_STATS_REQUEST, data)
              .then((values) => {
                commit(GET_MEDIA_ITEM_LIST_ANALYTIC_SUCCESS, values);
                resolve();
              })
              .catch(() => {
                commit(GET_MEDIA_ITEM_LIST_ANALYTIC_ERROR);
                reject();
              });
          } else {
            commit(GET_MEDIA_ITEM_LIST_ANALYTIC_SUCCESS);
          }
        }).catch(() => {
          commit(GET_MEDIA_ITEM_LIST_ANALYTIC_ERROR);
          reject();
        });
    }),
  [GET_MEDIA_TOTAL_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_MEDIA_TOTAL_REQUEST);

    data.type = 'total';

    let url = `${accountId}/youbora?type=${data.type}&timezone=${data.timezone}&timeframe=${data.timeframe}&granularity=${data.granularity}&mediaItems[]=${data.mediaItems}&mediaItemName=${encodeURIComponent(data.mediaItemName)}&mediaType=${data.mediaType}`;
    if (data.fromDate && data.toDate) {
      url = `${url}&fromDate=${data.fromDate}&toDate=${data.toDate}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_MEDIA_TOTAL_SUCCESS);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_MEDIA_TOTAL_ERROR);
        reject();
      });
  }),
  [GET_MOST_REQUESTED_REQUEST]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_MOST_REQUESTED_REQUEST);

    data.type = 'most_requested';

    let url = `${accountId}/youbora?type=${data.type}&timezone=${data.timezone}&timeframe=${data.timeframe}&granularity=${data.granularity}&mediaItems[]=${data.mediaItems}&limit=5&mediaType=${data.mediaType}&mediaItemName=${encodeURIComponent(data.mediaItemName)}`;
    if (data.fromDate && data.toDate) {
      url = `${url}&fromDate=${data.fromDate}&toDate=${data.toDate}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_MOST_REQUESTED_SUCCESS);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_MOST_REQUESTED_ERROR);
        reject();
      });
  }),
  [GET_GEO_LOCATION_BY_CITY_REQUEST]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_GEO_LOCATION_BY_CITY_REQUEST);

    data.type = 'city';

    let url = `${accountId}/youbora?type=${data.type}&timezone=${data.timezone}&timeframe=${data.timeframe}&granularity=${data.granularity}&mediaItems[]=${data.mediaItems}&mediaItemName=${encodeURIComponent(data.mediaItemName)}&mediaType=${data.mediaType}`;
    if (data.fromDate && data.toDate) {
      url = `${url}&fromDate=${data.fromDate}&toDate=${data.toDate}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_GEO_LOCATION_BY_CITY_SUCCESS);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_GEO_LOCATION_BY_CITY_ERROR);
        reject();
      });
  }),
  [GET_GEO_LOCATION_BY_STATE_REQUEST]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_GEO_LOCATION_BY_STATE_REQUEST);

    data.type = 'state';

    let url = `${accountId}/youbora?type=${data.type}&timezone=${data.timezone}&timeframe=${data.timeframe}&granularity=${data.granularity}&mediaItems[]=${data.mediaItems}&mediaItemName=${encodeURIComponent(data.mediaItemName)}&mediaType=${data.mediaType}`;
    if (data.fromDate && data.toDate) {
      url = `${url}&fromDate=${data.fromDate}&toDate=${data.toDate}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_GEO_LOCATION_BY_STATE_SUCCESS);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_GEO_LOCATION_BY_STATE_ERROR);
        reject();
      });
  }),
};

const mutations = {
  [GET_BROWSER_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_BROWSER_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_BROWSER_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_DEVICE_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_DEVICE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_DEVICE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_TIME_PLAYED_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_TIME_PLAYED_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_TIME_PLAYED_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_TOTAL_PLAY_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_TOTAL_PLAY_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_TOTAL_PLAY_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_UNIQUE_PLAY_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_UNIQUE_PLAY_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_UNIQUE_PLAY_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_GEO_LOCATION_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_GEO_LOCATION_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_GEO_LOCATION_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_UNIQUE_COUNTRY_PLAYS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_UNIQUE_COUNTRY_PLAYS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_UNIQUE_COUNTRY_PLAYS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_UNIQUE_USA_STATES_PLAYS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_UNIQUE_USA_STATES_PLAYS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_UNIQUE_USA_STATES_PLAYS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ITEM_LIST_STATS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_ITEM_LIST_STATS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_ITEM_LIST_STATS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_MEDIA_ITEM_LIST_REQUEST]: () => {
    state.isLoading = true;
    state.status = 'loading';
  },
  [GET_MEDIA_ITEM_LIST_SUCCESS]: (state, payload) => {
    state.mediaItems = payload.data;
    state.pagination = payload.pagination;
    state.isLoading = false;
    state.status = 'success';
  },
  [GET_MEDIA_ITEM_LIST_ERROR]: () => {
    state.isLoading = false;
    state.status = 'error';
  },
  [SET_MEDIA_ITEM_LIST_CANCEL_TOKEN]: (state, payload) => {
    state.source = payload.token;
  },
  [GET_MEDIA_ITEM_LIST_ANALYTIC_REQUEST]: () => {
    state.status = 'loading';
  },
  [GET_MEDIA_ITEM_LIST_ANALYTIC_SUCCESS]: (state, payload) => {
    const result = {};

    if (payload.data && payload.data[0] && payload.data[0].metrics) {
      const { metrics } = payload.data[0];
      metrics.forEach((item) => {
        if (item.values && item.values.length) {
          item.values.forEach((value) => {
            if (value.content_id) {
              const id = value.content_id;
              const object = { [item.code]: value.value };

              if (!result[id]) {
                result[id] = object;
              } else {
                result[id] = Object.assign(result[id], object);
              }
            }
          });
        }
      });
    }

    state.itemStats = result;
    state.isAnalyticLoading = false;
    state.status = 'success';
  },
  [GET_MEDIA_ITEM_LIST_ANALYTIC_ERROR]: () => {
    state.isAnalyticLoading = false;
    state.status = 'error';
  },
  [GET_MEDIA_TOTAL_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_MEDIA_TOTAL_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_MEDIA_TOTAL_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_MOST_REQUESTED_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_MOST_REQUESTED_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_MOST_REQUESTED_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_GEO_LOCATION_BY_CITY_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_GEO_LOCATION_BY_CITY_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_GEO_LOCATION_BY_CITY_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_GEO_LOCATION_BY_STATE_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_GEO_LOCATION_BY_STATE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_GEO_LOCATION_BY_STATE_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
