<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="btn-create-account-mobile">
      <Button
        :title="'Add Account'"
        :classType="'primary'"
        :eventFunction="toCreateAccHandler"
      />
    </div>
    <div class="flex">
      <div class="left-path">
        <span class="system">
          System Management
        </span>
        <span>
          / Accounts
        </span>
      </div>
      <div class="right-path">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="255"
        />
        <div class="btn-create-account-full">
          <Button
            :title="'Add Account'"
            :classType="'primary'"
            :eventFunction="toCreateAccHandler"
          />
        </div>
      </div>
    </div>
    <div class="accounts-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`a.${sortedField.name}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          company name
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`a.${sortedField.industry}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          industry
        </div>
        <div class="th-name users-count">
          <Sorting
            :sortField="`${sortedField.userCount}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          number of users
        </div>
        <div class="th-name status">
          <Sorting
            :sortField="`a.${sortedField.enabled}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          account status
        </div>
        <div class="th-name status">
          payment status
        </div>
        <div class="th-name status pay">
          payment type
        </div>
        <div class="th-name status">
          akamai status
        </div>
        <div class="th-name date">
          finish date
        </div>
      </div>
      <div
        v-if="!accounts.length"
        class="empty-search"
      >
        No accounts to display
      </div>
      <div
        v-for="(item, key) in accounts"
        :key="item.id"
        class="account"
        @mouseover="onHoverElem(item)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div
          class="td company"
          :title="item.name"
        >
          {{ item.name }}
        </div>
        <div
          class="td company"
          :title="item.industry"
        >
          {{ item.industry }}
        </div>
        <div class="td users-count">
          {{ item.userCount }}
        </div>
        <div class="td status">
          <div
            class="status-ellipse"
            :class="item.enabled ? 'enabled' : 'disabled'"
          >
          </div>
          <span v-if="item.enabled">
            Enabled
          </span>
          <span v-else>
            Disabled
          </span>
        </div>
        <div class="td status">
          <div
            class="status-ellipse"
            :class="item.subscription.paymentStatus"
          >
          </div>
          {{ paymentStatuses[item.subscription.paymentStatus] }}
        </div>
        <div class="td status pay">
          {{ item.subscription.type }}
        </div>
        <div class="td status">
          <div
            class="status-ellipse"
            :class="getAkamaiAppStatusClass(item)"
          >
          </div>
          <span v-if="isAkamaiAppActive(item)">
            Enabled
          </span>
          <span
            v-else-if="getAkamaiAppError(item)"
            :title="getAkamaiAppError(item)"
          >
            Error
          </span>
          <span
            v-else
          >
            Pending
          </span>
        </div>
        <div class="td date">
          <span v-if="item.subscription.finishDate">
            {{ item.subscription.finishDate | date }}
          </span>
          <span v-else>
            -
          </span>
        </div>
        <div
          v-show="isHover && item.id === selectedId"
          class="icons-wrapper"
        >
          <div
            v-if="!item.enabled"
            :title="'Delete Account'"
          >
            <inline-svg
              :src="iconRemove"
              class="svg-icon remove"
              @click="onOpenModalForRemove(item)"
            >
            </inline-svg>
          </div>
          <div
            v-if="item && !item.akamaiApp"
            :title="createAkamaiApp"
            class="create-icon"
          >
            <inline-svg
              :src="iconFix"
              class="svg-icon"
              @click="onAkamaiAppCreateOpenModal(item)"
            />
          </div>
          <div
            v-if="!isAkamaiAppActive(item) && getAkamaiAppError(item)"
            :title="syncAkamaiApp"
            class="sync-icon"
          >
            <inline-svg
              :src="iconFix"
              class="svg-icon"
              @click="onAkamaiAppSyncOpenModal(item)"
            />
          </div>
          <div
            :title="item.enabled ? disableAccount : enableAccount"
            class="enable-icon"
          >
            <inline-svg
              :src="item.enabled ? iconEnabled : iconDisabled"
              class="svg-icon"
              @click="onOpenModal(item)"
            />
          </div>
          <div class="icon-edit">
            <router-link
              :to="{ path: `accounts/${item.id}` }"
              :title="editAccount"
            >
              <inline-svg
                :src="iconEdit"
                class="svg-icon"
              >
              </inline-svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :currentPage="currentPage"
      :totalPages="totalPages"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="modalTitle"
      :onAccept="changeAccStatus"
      :onClose="onClose"
    />
    <NotificationModal
      ref="akamaiAppCreateModal"
      v-model="isAkamaiAppCreateModalOpen"
      :modal="isAkamaiAppCreateModalOpen"
      :bodyMessage="akamaiAppCreateBodyMessage"
      :onAccept="akamaiAppCreate"
      :onClose="onAkamaiAppCreateClose"
    />
    <NotificationModal
      ref="akamaiAppSyncModal"
      v-model="isAkamaiAppSyncModalOpen"
      :modal="isAkamaiAppSyncModalOpen"
      :title="'Sync Akamai App'"
      :bodyMessage="akamaiAppSyncBodyMessage"
      :onAccept="akamaiAppSync"
      :onClose="onAkamaiAppSyncClose"
    />
    <DeleteAccountModal
      ref="modal"
      v-model="isOpenDelete"
      :modal="isOpenDelete"
      :bodyMessage="bodyMessage"
      :title="titleRemoveAcc"
      :isDeleteAcc="true"
      :name="companyName"
      :accId="accId"
      :onClose="onCloseDelete"
      :getAccounts="getAccounts"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  SYNC_AKAMAI_APP_REQUEST,
  CREATE_AKAMAI_APP_REQUEST,
  EDIT_ACCOUNT_STATUS_REQUEST,
  GET_ALL_ACCOUNTS_REQUEST,
} from '../../store/actions/accountsActions/accounts';
import {
  SET_EDIT_FORM,
  SET_OVERFLOW,
} from '../../store/actions/common';
import Pagination from '../../components/common/Pagination.vue';
import Loader from '../../components/common/Loader.vue';
import NotificationModal from '../../components/NotificationModal.vue';
import Sorting from '../../components/common/Sorting.vue';
import iconEnabled from '../../assets/icons/icon-enabled-acc.svg';
import iconDisabled from '../../assets/icons/icon-disabled-acc.svg';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconFix from '../../assets/icons/icon-fix.svg';
import iconRemove from '../../assets/icons/icon-remove-role.svg';
import LiveSearch from '../../components/common/LiveSearch.vue';
import CONFIG from '../../constants/config';
import Button from '../../components/common/Button.vue';
import DeleteAccountModal from '../../components/DeleteAccountModal.vue';

export default {
  name: 'Accounts',
  components: {
    DeleteAccountModal,
    Pagination,
    LiveSearch,
    NotificationModal,
    Sorting,
    Loader,
    Button,
  },
  data() {
    const {
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editAccount,
    } = CONFIG.tooltips;
    return {
      paymentStatuses: CONFIG.paymentStatuses,
      enabledId: null,
      akamaiAccountCreateId: null,
      akamaiAppSyncId: null,
      isEnabled: false,
      isModalOpen: false,
      isAkamaiAppCreateModalOpen: false,
      isAkamaiAppSyncModalOpen: false,
      akamaiAppCreateBodyMessage: CONFIG.modals.akamaiAppCreateConfirmationMessage,
      akamaiAppSyncBodyMessage: CONFIG.modals.akamaiAppSyncConfirmationMessage,
      isHover: false,
      selectedId: null,
      bodyMessage: '',
      modalTitle: '',
      filter: '',
      sortedField: {
        name: 'name',
        industry: 'industry',
        enabled: 'enabled',
        userCount: 'userCount',
      },
      currentSortedField: 'a.name',
      currentSortBy: 'ASC',
      isSortedUp: false,
      isSortedDown: false,
      isOpenDelete: false,
      titleRemoveAcc: '',
      companyName: '',
      accId: '',
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editAccount,
      iconEnabled,
      iconEdit,
      iconDisabled,
      iconFix,
      iconRemove,
    };
  },
  computed: {
    ...mapState({
      accounts: (state) => state.accounts.accounts,
      pagination: (state) => state.accounts.pagination,
      currentPage: (state) => state.accounts.currentPage,
      isLoading: (state) => state.accounts.isLoading,
    }),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
    const params = {
      page: localStorage.getItem('currentPage') || 1,
      filterValue: '',
    };
    this.$store.dispatch(GET_ALL_ACCOUNTS_REQUEST, params);
  },
  methods: {
    getAccounts() {
      const params = {
        page: localStorage.getItem('currentPage') || 1,
        filterValue: '',
      };
      this.$store.dispatch(GET_ALL_ACCOUNTS_REQUEST, params);
    },
    onOpenModalForRemove(item) {
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isOpenDelete = true;
      this.enabledId = item.id;
      this.bodyMessage = 'This action cannot be undone. To confirm, please enter the Company Name of the account you wish to delete:';
      this.titleRemoveAcc = 'Delete Account';
      this.companyName = item.name;
      this.accId = item.id;
    },
    onCloseDelete() {
      this.isOpenDelete = false;
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onAkamaiAppCreateClose() {
      this.isAkamaiAppCreateModalOpen = false;
    },
    onAkamaiAppSyncClose() {
      this.isAkamaiAppSyncModalOpen = false;
    },
    handleSortUp(field) {
      const params = {
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_ACCOUNTS_REQUEST, params);
    },
    handleSortDown(field) {
      const params = {
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_ACCOUNTS_REQUEST, params);
    },
    onOpenModal(item) {
      const {
        disabledConfirmationMessage,
        enabledConfirmationMessage,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.isEnabled = item.enabled;
      this.enabledId = item.id;
      this.modalTitle = item.enabled ? 'Disable Account' : 'Enable Account';
      this.bodyMessage = item.enabled ? disabledConfirmationMessage : enabledConfirmationMessage;
    },
    onAkamaiAppCreateOpenModal(item) {
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isAkamaiAppCreateModalOpen = true;
      this.akamaiAccountCreateId = item.id;
    },
    onAkamaiAppSyncOpenModal(item) {
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isAkamaiAppSyncModalOpen = true;
      this.akamaiAppSyncId = item.akamaiApp.id;
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        page: params.page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ALL_ACCOUNTS_REQUEST, getParams);
    },
    getPaginationAction(page) {
      const params = {
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ALL_ACCOUNTS_REQUEST, params);
    },
    toCreateAcc() {
      this.$router.push('accounts/add');
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    changeAccStatus() {
      const accStatus = {
        enabled: !this.isEnabled,
        accountId: this.enabledId,
      };
      this.$store.dispatch(EDIT_ACCOUNT_STATUS_REQUEST, accStatus)
        .then(() => {
          const params = {
            page: localStorage.getItem('currentPage') || 1,
            filterValue: this.filter || '',
          };
          this.onSearch(params);
          this.$toasted.global.success({
            message: `The Account has been successfully ${this.isEnabled ? 'disabled' : 'enabled'}`,
          });
        });
    },
    akamaiAppCreate() {
      const params = {
        page: localStorage.getItem('currentPage') || 1,
        filterValue: this.filter || '',
      };
      this.$store.dispatch(CREATE_AKAMAI_APP_REQUEST, this.akamaiAccountCreateId)
        .then(() => {
          this.onSearch(params);
        })
        .catch(() => {
          this.$toasted.global.error({
            message: CONFIG.errorMessages.akamaiAppCreateError,
          });
        });
    },
    akamaiAppSync() {
      const params = {
        page: localStorage.getItem('currentPage') || 1,
        filterValue: this.filter || '',
      };
      this.$store.dispatch(SYNC_AKAMAI_APP_REQUEST, this.akamaiAppSyncId)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Akamai App has been successfully synced for this Account',
          });
          this.onSearch(params);
        })
        .catch(() => {
          this.$toasted.global.error({
            message: CONFIG.errorMessages.akamaiAppSyncError,
          });
        });
    },
    toCreateAccHandler() {
      this.toCreateAcc();
    },
    getAkamaiAppStatusClass(item) {
      if (!this.isAkamaiAppActive(item)) {
        return this.getAkamaiAppError(item) ? 'blocked' : 'failed';
      }
      return 'active';
    },
    isAkamaiAppActive(item) {
      return item
        && item.akamaiApp
        && item.akamaiApp.syncStatus === 'SYNC_STATUS_ACTIVATED';
    },
    getAkamaiAppError(item) {
      const hasError = item
        && item.akamaiApp
        && item.akamaiApp.errors
        && item.akamaiApp.errors.title;

      if (hasError) {
        return item.akamaiApp.errors.title;
      }

      return '';
    },
  },
};
</script>

<style scoped>
  .flex {
    align-items: center;
    display: flex;
    flex: 0;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 40px;
  }

  .main-wrapper {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 25px 30px;
    width: 100%;
  }

  .system {
    color: #4c5057;
  }

  .accounts-wrapper {
    border: 1px solid #43434d;
    height: 100%;
    overflow-y: auto;
  }

  .ac-wrapper-header {
    background: #282a2e;
    display: flex;
    padding: 13px 0;
  }

  .th-name {
    align-items: center;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
  }

  .td {
    font-size: 14px;
    padding: 15px 0;
    word-break: break-word;
  }

  .number {
    padding-left: 21px;
    width: 50px;
  }

  .company {
    padding-right: 15px;
    width: 10%;
  }

  .users-count {
    width: 10%;
  }

  .status {
    align-items: flex-start;
    display: flex;
    width: 11%;
  }

  .date {
    align-items: flex-start;
    display: flex;
    width: 7%;
  }

  .account {
    display: flex;
  }

  .account:hover {
    background: #383b40;
  }

  .status-ellipse {
    border-radius: 50%;
    height: 5px;
    margin-right: 6px;
    margin-top: 6px;
    width: 5px;
  }

  .status-ellipse.enabled,
  .status-ellipse.active {
    background: #1fee65;
  }

  .status-ellipse.disabled,
  .status-ellipse.blocked,
  .status-ellipse.pending {
    background: #fb3951;
  }

  .status-ellipse.failed {
    background: #fbf650;
  }

  .icons-wrapper {
    align-items: flex-start;
    display: flex;
    justify-content: flex-end;
    flex: 0.9;
  }

  .icons-wrapper div {
    padding: 10px;
  }

  .create-button {
    background: #2591cf;
    color: #fff;
    font-size: 16px;
    letter-spacing: normal;
    padding: 0 16px;
    text-transform: none;
  }

  .right-path {
    align-items: center;
    display: flex;
  }

  .empty-search {
    padding-top: 40px;
    text-align: center;
  }

  .btn-create-account-mobile {
    display: none;
  }

  .svg-icon {
    cursor: pointer;
  }

  @media all and (max-width: 1720px) {
    .status {
      width: 10%;
    }
  }

  @media all and (max-width: 1720px) {
    .users-count {
      width: 9%;
    }
  }

  @media all and (max-width: 1470px) {
    .users-count {
      width: 8%;
    }

    .pay {
      width: 8%;
    }
  }

  @media all and (max-width: 1400px) {
    .number {
      width: 30px;
    }

    .status {
      width: 9%;
    }

  }

  @media all and (max-width: 1170px) {
    .status {
      width: 9.5%;
    }
  }

  @media all and (max-width: 1100px) {
    .icons-wrapper div {
      padding: 10px 5px;
    }
  }

  @media all and (max-width: 1024px) {
    .btn-create-account-mobile {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
      min-height: 40px;
    }

    .btn-create-account-full {
      display: none;
    }

    .search-wrapper {
      margin-right: 0;
    }
  }

  @media all and (max-width: 900px) {
    .company {
      padding-right: 7px;
      width: 14%;
    }

    .number {
      width: 24px;
    }

    .status {
      width: 10%;
    }
  }
</style>
