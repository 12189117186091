<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="btn-add-mobile">
      <Button
        :title="'Add Custom Field'"
        :classType="'primary'"
        :eventFunction="toCreateFieldHandler"
      />
    </div>
    <div class="flex">
      <div class="left-path">
        <span class="system">
          System Management
        </span>
        <span>
          / Custom Fields
        </span>
      </div>
      <div class="right-path">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="255"
        />
        <div class="btn-add-full">
          <Button
            :title="'Add Custom Field'"
            :classType="'primary'"
            :eventFunction="toCreateFieldHandler"
          />
        </div>
      </div>
    </div>
    <div class="accounts-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`cf.${sortedField.name}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          name
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`cf.${sortedField.shortName}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          short name
        </div>
        <div class="th-name company">
          entry type
        </div>
        <div class="th-name company">
          structure
        </div>
        <div class="th-name company">
          context
        </div>
        <div class="th-name company">
          appearance
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`cf.${sortedField.enabled}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          Status
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`cf.${sortedField.createdAt}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          created
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`cf.${sortedField.updatedAt}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          updated
        </div>
      </div>
      <div
        v-if="!fields.length"
        class="empty-search"
      >
        No fields to display
      </div>
      <div
        v-for="(item, key) in fields"
        :key="item.id"
        class="account"
        @mouseover="onHoverElem(item)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div
          class="td company"
          :title="item.name"
        >
          {{ item.name }}
        </div>
        <div
          class="td company"
          :title="item.shortName"
        >
          {{ item.shortName }}
        </div>
        <div
          class="td company"
          :title="getEntryTypeLabel(item)"
        >
          {{ getEntryTypeLabel(item) }}
        </div>
        <div
          class="td company"
          :title="getStructureLabel(item)"
        >
          {{ getStructureLabel(item) }}
        </div>
        <div
          class="td company"
          :title="getContextLabel(item)"
        >
          {{ getContextLabel(item) }}
        </div>
        <div
          class="td company"
          :title="getAppearanceLabel(item)"
        >
          {{ getAppearanceLabel(item) }}
        </div>
        <div class="td company status">
          <div
            class="status-ellipse"
            :class="item.enabled ? 'enabled' : 'disabled'"
          >
          </div>
          <span v-if="item.enabled">
            Enabled
          </span>
          <span v-else>
            Disabled
          </span>
        </div>
        <div
          class="td company"
        >
          <span v-if="item.createdAt">
            {{ item.createdAt | timezone(getMainProfile.timezone) }}
          </span>
          <span v-else>-</span>
        </div>
        <div
          class="td company"
        >
          <span v-if="item.updatedAt">
            {{ item.updatedAt | timezone(getMainProfile.timezone) }}
          </span>
          <span v-else>-</span>
        </div>
        <div
          v-show="isHover && item.id === selectedId"
          class="icons-wrapper"
        >
          <div :title="item.enabled ? disableFieldTitle : enableFieldTitle">
            <inline-svg
              :src="item.enabled ? iconEnabled : iconDisabled"
              class="svg-icon"
              @click="onOpenModal(item)"
            >
            </inline-svg>
          </div>
          <div class="icon-edit">
            <router-link
              :to="{ path: `fields/${item.id}` }"
              :title="editFieldTitle"
            >
              <inline-svg
                :src="iconEdit"
                class="svg-icon"
              >
              </inline-svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :totalPages="totalPages"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="modalTitle"
      :onAccept="changeFieldStatus"
      :onClose="onClose"
    />
    <ErrorModal
      v-model="isModalErrorOpen"
      :modal="isModalErrorOpen"
      :bodyErrorMessage="bodyErrorMessage"
      :onClose="onModalErrorClose"
    />
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  CHANGE_CUSTOM_FIELD_STATUS_REQUEST,
  GET_CUSTOM_FIELD_OPTIONS_REQUEST,
  GET_CUSTOM_FIELDS_REQUEST,
} from '../../store/actions/fields/field';
import {
  SET_EDIT_FORM,
  SET_OVERFLOW,
} from '../../store/actions/common';
import CONFIG from '../../constants/config';

import iconEnabled from '../../assets/icons/icon-enabled-acc.svg';
import iconDisabled from '../../assets/icons/icon-disabled-acc.svg';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';

import Pagination from '../../components/common/Pagination.vue';
import Sorting from '../../components/common/Sorting.vue';
import LiveSearch from '../../components/common/LiveSearch.vue';
import Button from '../../components/common/Button.vue';
import Loader from '../../components/common/Loader.vue';
import NotificationModal from '../../components/NotificationModal.vue';
import ErrorModal from '../../components/ErrorModal.vue';

export default {
  name: 'CustomFieldList',
  components: {
    NotificationModal,
    Loader,
    Pagination,
    LiveSearch,
    Sorting,
    Button,
    ErrorModal,
  },
  data() {
    const {
      editFieldTitle,
      disableFieldTitle,
      enableFieldTitle,
    } = CONFIG.tooltips;
    return {
      iconEdit,
      iconEnabled,
      iconDisabled,
      isModalOpen: false,
      enabledId: null,
      bodyMessage: '',
      modalTitle: '',
      isEnabled: false,
      isHover: false,
      selectedId: null,
      filter: '',
      sortedField: {
        name: 'name',
        shortName: 'shortName',
        createdAt: 'createdAt',
        updatedAt: 'updatedAt',
        enabled: 'enabled',
      },
      currentSortedField: 'cf.name',
      currentSortBy: 'ASC',
      isSortedUp: false,
      isSortedDown: false,
      disableFieldTitle,
      enableFieldTitle,
      editFieldTitle,
      isModalErrorOpen: false,
      bodyErrorMessage: '',
    };
  },
  computed: {
    ...mapState({
      entryTypes: (state) => state.fields.entryTypes,
      contexts: (state) => state.fields.contexts,
      fields: (state) => state.fields.fields,
      pagination: (state) => state.fields.pagination,
      isLoading: (state) => state.fields.isLoading,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getMainProfile',
    ]),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    const params = {
      accountId: this.getCurrentAccountId,
      page: localStorage.getItem('fieldsPage') || 1,
      filterValue: '',
    };
    this.$store.dispatch(GET_CUSTOM_FIELD_OPTIONS_REQUEST)
      .then(() => {
        this.$store.dispatch(GET_CUSTOM_FIELDS_REQUEST, params);
      });
  },
  methods: {
    onModalErrorClose() {
      this.isModalErrorOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onOpenModal(field) {
      const {
        disabledFieldMessage,
        enabledFieldMessage,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.isEnabled = field.enabled;
      this.enabledId = field.id;
      this.modalTitle = field.enabled ? 'Disable Custom Field' : 'Enable Custom Field';
      this.bodyMessage = field.enabled ? disabledFieldMessage : enabledFieldMessage;
    },
    changeFieldStatus() {
      const {
        disabledFieldMessage,
        enabledFieldMessage,
      } = CONFIG.successMessages;
      const data = {
        accountId: this.getCurrentAccountId,
        fieldId: this.enabledId,
        enabled: !this.isEnabled,
      };

      this.$store.dispatch(CHANGE_CUSTOM_FIELD_STATUS_REQUEST, data)
        .then(() => {
          this.$toasted.global.success({
            message: this.isEnabled ? disabledFieldMessage : enabledFieldMessage,
          });
          const params = {
            accountId: this.getCurrentAccountId,
            page: localStorage.getItem('fieldsPage') || 1,
            filterValue: this.filter || '',
          };
          this.onSearch(params);
        })
        .catch((err) => {
          if (err) {
            this.$toasted.global.error({
              message: CONFIG.errorMessages.commonServerError,
            });
          }
        });
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    toCreateFieldHandler() {
      this.$router.push('fields/add');
    },
    handleSortUp(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_CUSTOM_FIELDS_REQUEST, params);
    },
    handleSortDown(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_CUSTOM_FIELDS_REQUEST, params);
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        accountId: this.getCurrentAccountId,
        page: params.page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_CUSTOM_FIELDS_REQUEST, getParams);
    },
    getPaginationAction(page) {
      const params = {
        accountId: this.getCurrentAccountId,
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_CUSTOM_FIELDS_REQUEST, params);
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    getOptionObject(item, key) {
      return this[`${key}s`].find((i) => i.code === item[key]);
    },
    getOptionChoiceObject(item, key, subKey) {
      const object = this.getOptionObject(item, key);

      if (object && object.choices) {
        return object.choices.find((i) => i.code === item[subKey]);
      }

      return null;
    },
    getEntryTypeLabel(item) {
      const entryType = this.getOptionObject(item, 'entryType');

      return entryType && entryType.name;
    },
    getStructureLabel(item) {
      const structure = this.getOptionChoiceObject(item, 'entryType', 'structure');

      return structure && structure.name;
    },
    getContextLabel(item) {
      const context = this.getOptionObject(item, 'context');

      return context && context.name;
    },
    getAppearanceLabel(item) {
      const appearance = this.getOptionChoiceObject(item, 'context', 'appearance');

      return appearance && appearance.name;
    },
  },
};
</script>

<style scoped>
  .flex {
    align-items: center;
    display: flex;
    flex: 0;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 40px;
  }

  .main-wrapper {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 25px 30px;
    width: 100%;
  }

  .system {
    color: #4c5057;
  }

  .accounts-wrapper {
    border: 1px solid #43434d;
    height: 100%;
    overflow-y: auto;
  }

  .ac-wrapper-header {
    background: #282a2e;
    display: flex;
    padding: 13px 0;
  }

  .th-name {
    align-items: center;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
  }

  .td {
    font-size: 14px;
    padding: 15px 0;
    word-break: break-word;
  }

  .number {
    padding-left: 21px;
    width: 30px;
  }

  .company {
    padding-left: 20px;
    width: 8%;
  }

  .status {
    align-items: flex-start;
    display: flex;
  }

  .status-ellipse {
    border-radius: 50%;
    height: 5px;
    margin-right: 6px;
    margin-top: 6px;
    width: 5px;
  }

  .status-ellipse.enabled {
    background: #1fee65;
  }

  .status-ellipse.disabled {
    background: #fb3951;
  }

  .account {
    display: flex;
  }

  .account:hover {
    background: #383b40;
  }

  .icons-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 0.85;
    justify-content: flex-end;
    padding-top: 10px;
  }

  .icon-edit {
    padding-left: 31px;
  }

  .right-path {
    align-items: center;
    display: flex;
  }

  .empty-search {
    padding-top: 40px;
    text-align: center;
  }

  .btn-add-mobile {
    display: none;
  }

  .svg-icon {
    cursor: pointer;
  }

  @media all and (max-width: 1500px) {
    .company {
      width: 7%;
    }
  }

  @media all and (max-width: 1366px) {
    .company {
      width: 7%;
    }
  }

  @media all and (max-width: 1250px) {
    .company {
      width: 6%;
    }
  }

  @media all and (max-width: 1024px) {
    .btn-add-mobile {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
      min-height: 40px;
    }

    .btn-add-full {
      display: none;
    }
  }
</style>
