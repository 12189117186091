import apiCall from '../../../utils/api/api';
import {
  GET_ALL_SERVERS,
  GET_ALL_SERVERS_REQUEST,
  GET_ALL_SERVERS_SUCCESS,
  GET_ALL_SERVERS_ERROR,
  GET_SERVER,
  GET_SERVER_REQUEST,
  GET_SERVER_SUCCESS,
  GET_SERVER_ERROR,
  ADD_SERVER,
  ADD_SERVER_REQUEST,
  ADD_SERVER_SUCCESS,
  ADD_SERVER_ERROR,
  EDIT_SERVER,
  EDIT_SERVER_REQUEST,
  EDIT_SERVER_SUCCESS,
  EDIT_SERVER_ERROR,
  SET_IS_VIEW_ONLY_FORM,
  SET_IS_VIEW_ONLY_FORM_SUCCESS,
} from '../../actions/servers/servers';

const state = {
  isLoading: false,
  isViewOnly: false,
  servers: [],
  pagination: {},
  server: {},
  currentPage: null,
  error: null,
  serverErrors: null,
};

const actions = {
  [SET_IS_VIEW_ONLY_FORM]: ({ commit }, value) => new Promise(() => {
    commit(SET_IS_VIEW_ONLY_FORM_SUCCESS, value);
  }),
  [GET_ALL_SERVERS]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_ALL_SERVERS_REQUEST);

    let url = '';
    if (params.allItems) {
      url = 'wowza-server/list?p=1&l=100';
    } else if (!params.sortFiled) {
      url = `wowza-server/list?p=${params.page}&l=15&filterField=ws.name&filterValue=${params.filterValue}`;
    } else {
      url = `wowza-server/list?p=${params.page}&l=15&filterField=ws.name&filterValue=${params.filterValue}&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url)
      .then((res) => {
        commit(GET_ALL_SERVERS_SUCCESS, res.data);
        localStorage.setItem('currentPage-server-list', res.data.pagination.page);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_ALL_SERVERS_ERROR, error);
        reject(error);
      });
  }),
  [GET_SERVER]: ({ commit }, id) => new Promise((resolve, reject) => {
    commit(GET_SERVER_REQUEST);

    apiCall.get(`wowza-server/${id}`)
      .then((res) => {
        commit(GET_SERVER_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_SERVER_ERROR, error);
        reject(error);
      });
  }),
  [ADD_SERVER]: ({ commit }, server) => new Promise((resolve, reject) => {
    commit(ADD_SERVER_REQUEST);

    apiCall.post('wowza-server', server.data)
      .then((resp) => {
        commit(ADD_SERVER_SUCCESS, resp.data);
        resolve();
      })
      .catch((err) => {
        let error;
        if (err.response.status === 402) {
          error = err.response.status;
        } else {
          error = err.response.data;
          commit(ADD_SERVER_ERROR, error);
        }
        reject(error);
      });
  }),
  [EDIT_SERVER]: ({ commit }, { id, data }) => new Promise((resolve, reject) => {
    commit(EDIT_SERVER_REQUEST);

    apiCall.put(`wowza-server/${id}`, data)
      .then((res) => {
        commit(EDIT_SERVER_SUCCESS, res.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_SERVER_ERROR, error);
        reject(error);
      });
  }),
};

const mutations = {
  [SET_IS_VIEW_ONLY_FORM_SUCCESS]: (state, payload) => {
    state.isViewOnly = payload;
  },
  [GET_ALL_SERVERS_REQUEST]: () => {
  },
  [GET_ALL_SERVERS_SUCCESS]: (state, payload) => {
    state.servers = payload.data;
    state.pagination = payload.pagination;
    state.currentPage = payload.page;
  },
  [GET_ALL_SERVERS_ERROR]: (state, payload) => {
    state.error = payload;
  },
  [GET_SERVER_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_SERVER_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.server = payload;
  },
  [GET_SERVER_ERROR]: (state, payload) => {
    state.isLoading = false;
    state.error = payload;
  },
  [ADD_SERVER_REQUEST]: () => {
    state.serverErrors = null;
    state.isLoading = true;
  },
  [ADD_SERVER_SUCCESS]: (state) => {
    state.isLoading = false;
  },
  [ADD_SERVER_ERROR]: (state, payload) => {
    state.isLoading = false;
    state.error = payload;
    state.serverErrors = payload.form_errors.errors;
  },
  [EDIT_SERVER_REQUEST]: () => {
    state.serverErrors = null;
    state.isLoading = true;
  },
  [EDIT_SERVER_SUCCESS]: (state) => {
    state.isLoading = false;
  },
  [EDIT_SERVER_ERROR]: (state, payload) => {
    state.isLoading = false;
    state.error = payload;
    state.serverErrors = payload.form_errors.errors;
  },
};

export default {
  state,
  actions,
  mutations,
};
