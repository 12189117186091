<template>
  <div class="analytics-page">
    <Loader :isVisible="isLoading" />
    <div class="analytics-overview">
      <AnalyticsFilters
        :onTimeFrameChange="onTimeFrameChange"
        :isCustomTimeframe="isCustomTimeframe"
        :timeframe="timeframe"
        :previousTimeframe="previousTimeframe"
        :onClearFilter="onClearFilter"
        :onSearch="onSearch"
        :search="search"
        :onFilterTypeChange="onFilterTypeChange"
        @selectCustomTimeframe="onSelectCustomTimeframe"
        @cancelSelectCustomTimeframe="onCancelSelectCustomTimeframe"
      />
      <AnalyticsTotalInfo
        :totalInfo="analyticsTotalInfo"
        :onReloadData="onReloadData"
        :isLoadingFailed="isLoadingTotalFailed"
      />
      <AnalyticsMostRequestedTitles
        :mediaItems="mediaItems"
        :onFilterSelect="onFilterSelect"
        :selectedFilter="search"
        :onReloadData="onReloadData"
        :isLoadingFailed="isLoadingMostRequestedFailed"
      />
      <AnalyticsGraphs
        ref="analyticsGraph"
        :deviceType="deviceType"
        :geolocationType="geolocationType"
        :pieType="pieType"
        :onDeviceChange="onDeviceChange"
        :usaMapData="usaMapData"
        :worldMapData="worldMapData"
        :onGeoLocationTypeChange="onGeoLocationTypeChange"
        :onPieTypeChange="onPieTypeChange"
        :onResizePie="onResizePie"
        :onReloadData="onReloadData"
        :isLoadingFailedMap="isLoadingMapFailed"
        :isLoadingFailedPie="isLoadingPieFailed"
      />
      <AnalyticsGeoAndDevices
        :selectedGeoType="selectedGeoType"
        :onSelectGeoType="onSelectGeoType"
        :selectedTab="geoAndDevicesTabSelected"
        :onSelectTab="onGeoSelectTab"
        :items="geoAndDevicesItems"
        :onReloadData="onReloadData"
        :isLoadingFailed="isLoadingSimpleTableFailed"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import {
  mapState,
  mapGetters,
} from 'vuex';
import Loader from '../../components/common/Loader.vue';
import AnalyticsGraphs from '../../components/analytics/AnalyticsGraphs.vue';
import AnalyticsFilters from '../../components/analytics/AnalyticsFilters.vue';
import AnalyticsTotalInfo from '../../components/analytics/AnalyticsTotalInfo.vue';
import AnalyticsGeoAndDevices from '../../components/analytics/AnalyticsGeoAndDevices.vue';
import AnalyticsMostRequestedTitles from '../../components/analytics/AnalyticsMostRequestedTitles.vue';
import CONFIG from '../../constants/config';

import {
  GET_MEDIA_TOTAL_REQUEST,
  GET_MOST_REQUESTED_REQUEST,
  GET_GEO_LOCATION_REQUEST,
  GET_BROWSER_REQUEST,
  GET_DEVICE_REQUEST,
  GET_GEO_LOCATION_BY_CITY_REQUEST,
  GET_GEO_LOCATION_BY_STATE_REQUEST,
} from '../../store/actions/analytic/analytic';

import countryConverter from '../../utils/country/converter';

export default {
  name: 'AnalyticsOverview',
  components: {
    Loader,
    AnalyticsGraphs,
    AnalyticsFilters,
    AnalyticsTotalInfo,
    AnalyticsGeoAndDevices,
    AnalyticsMostRequestedTitles,
  },
  data() {
    return {
      search: '',
      isLoading: false,
      mediaType: 'all',
      analyticsTotalInfo: {},
      mediaItems: [],
      selectedItem: '',
      selectedFilter: null,
      usaMapData: [],
      geolocationType: 'views',
      pieType: 'views',
      devicePieType: 'views',
      worldMapData: [],
      selectedGeoType: 'country',
      geoAndDevicesTabSelected: 'geo',
      geoAndDevicesItems: [],
      deviceType: CONFIG.analyticDeviceSortByList[1].value,
      fromDate: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
      toDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      timeframe: 'today',
      previousTimeframe: 'today',
      isLoadingTotalFailed: false,
      isLoadingMostRequestedFailed: false,
      isLoadingMapFailed: false,
      isLoadingPieFailed: false,
      isLoadingSimpleTableFailed: false,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.common.profile,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    isCustomTimeframe() {
      return this.timeframe === 'custom';
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    const tz = this.getTimezone();
    this.fromDate = moment().startOf('week').tz(tz).format('YYYY-MM-DD HH:mm:ss');
    this.toDate = moment().endOf('week').tz(tz).format('YYYY-MM-DD HH:mm:ss');
    this.setUpSettings();
    this.setLoader(3000);
  },
  methods: {
    onSelectGeoType(element) {
      this.selectedGeoType = element.target.value;
      let request = GET_GEO_LOCATION_REQUEST;
      const dataRequest = this.getDataRequest();

      switch (element.target.value) {
      case 'country':
        request = GET_GEO_LOCATION_REQUEST;
        break;
      case 'state_province':
        request = GET_GEO_LOCATION_BY_STATE_REQUEST;
        break;
      case 'city':
        request = GET_GEO_LOCATION_BY_CITY_REQUEST;
        break;
      default:
        request = GET_GEO_LOCATION_REQUEST;
        break;
      }
      this.geoAndDevicesItems = [];
      this.isLoading = true;
      this.$store.dispatch(request, dataRequest)
        .then((res) => {
          const result = (this.selectedGeoType === 'country') ? res[0] : res;
          this.geoAndDevicesItems = this.parseGeoLocationByType(result, element.target.value);
          this.isLoading = false;
        }).catch(() => {
          this.isLoading = false;
        });
    },
    setLoader(time = 1500) {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, time);
    },
    onGeoSelectTab(id, isNotStrict) {
      this.isLoadingSimpleTableFailed = false;
      this.setLoader(1500);
      const dataRequest = this.getDataRequest(isNotStrict);
      dataRequest.type = id;
      switch (id) {
      case 'geo':
        this.selectedGeoType = 'country';
        this.$store.dispatch(GET_GEO_LOCATION_REQUEST, dataRequest)
          .then((res) => {
            const parsedGeoData = this.parseGeoLocationByType(res[0], this.selectedGeoType);
            this.geoAndDevicesItems = [...parsedGeoData];
          })
          .catch(() => {
            this.isLoadingSimpleTableFailed = true;
          });
        break;
      case 'browsers':
        this.$store.dispatch(GET_BROWSER_REQUEST, dataRequest)
          .then((res) => {
            const parsedGeoData = this.parseGeoLocationByType(res, 'browser');
            this.geoAndDevicesItems = [...parsedGeoData];
          })
          .catch(() => {
            this.isLoadingSimpleTableFailed = true;
          });
        break;
      case 'device':
        this.$store.dispatch(GET_DEVICE_REQUEST, dataRequest)
          .then((res) => {
            const parsedGeoData = this.parseGeoLocationByType(res, 'device');
            this.geoAndDevicesItems = [...parsedGeoData];
          })
          .catch(() => {
            this.isLoadingSimpleTableFailed = true;
          });
        break;
      default:
        this.$store.dispatch(GET_GEO_LOCATION_REQUEST, dataRequest)
          .then((res) => {
            const parsedGeoData = this.parseGeoLocationByType(res[0], this.selectedGeoType);
            this.geoAndDevicesItems = [...parsedGeoData];
          });
        break;
      }
      this.geoAndDevicesTabSelected = id;
    },
    onSearch(value) {
      this.setLoader(1500);
      this.search = value;
      this.pieType = 'views';
      this.geolocationType = 'views';
      this.deviceType = 'device_type';
      this.selectedGeoType = 'country';
      this.geoAndDevicesTabSelected = 'geo';
      this.onHideAllReloaders();
      this.getAllRequests(true);
    },
    onPieTypeChange(value) {
      this.pieType = value;
      if (this.deviceType === 'browser') {
        this.getBrowsers();
      } else {
        this.getDevicesByPie();
      }
    },
    onFilterTypeChange(value) {
      this.setLoader(1500);
      this.pieType = 'views';
      this.geolocationType = 'views';
      this.deviceType = 'device_type';
      this.selectedGeoType = 'country';
      this.geoAndDevicesTabSelected = 'geo';
      this.mediaType = value;
      this.onHideAllReloaders();
      this.getAllRequests();
    },
    onGeoLocationTypeChange(value) {
      this.geolocationType = value;
      this.getGeoLocation();
    },
    onClearFilter() {
      this.setUpSettings();
      this.onHideAllReloaders();
      this.setLoader(3000);
    },
    onFilterSelect(title) {
      this.setLoader(1500);
      this.pieType = 'views';
      this.geolocationType = 'views';
      this.deviceType = 'device_type';
      this.selectedGeoType = 'country';
      this.geoAndDevicesTabSelected = 'geo';

      if (title === this.search) {
        this.search = '';
      } else {
        this.search = title;
      }
      this.getAllRequests();
    },
    setUpSettings() {
      this.mediaType = 'all';
      this.search = '';
      this.pieType = 'views';
      this.geolocationType = 'views';
      this.selectedGeoType = 'country';
      this.deviceType = 'device_type';
      this.geoAndDevicesTabSelected = 'geo';
      this.getAllRequests();
    },
    onTimeFrameChange(event) {
      this.timeframe = event.target.value;
      if (!this.isCustomTimeframe) {
        this.previousTimeframe = this.timeframe;
        this.onGetAnalitycsByTimeframe();
      }
    },
    onSelectCustomTimeframe(event) {
      if (this.previousTimeframe !== 'today') {
        this.previousTimeframe = 'today';
      }
      this.fromDate = moment(event.fromDate).format('YYYY-MM-DD 00:00:00');
      this.toDate = moment(event.toDate).format('YYYY-MM-DD HH:mm:00');
      this.onGetAnalitycsByTimeframe();
    },
    onCancelSelectCustomTimeframe() {
      this.timeframe = this.previousTimeframe;
      this.onGetAnalitycsByTimeframe();
    },
    onGetAnalitycsByTimeframe() {
      this.pieType = 'views';
      this.geolocationType = 'views';
      this.deviceType = 'device_type';
      this.selectedGeoType = 'country';
      this.geoAndDevicesTabSelected = 'geo';
      this.getAllRequests();
      this.onHideAllReloaders();
      this.setLoader(3000);
    },
    getAllRequests(isNotStrict = false) {
      this.getMediaTotal(isNotStrict);
      this.getMostRequestedList(isNotStrict);
      this.getGeoLocation(isNotStrict);
      this.getGeoLocationTable(isNotStrict);
      this.getDevicesByPie(isNotStrict);
    },
    getMediaTotal(isNotStrict) {
      const dataRequest = this.getDataRequest(isNotStrict);
      const result = {};
      this.isLoading = true;
      this.$store.dispatch(GET_MEDIA_TOTAL_REQUEST, dataRequest)
        .then((res) => {
          if (res.data) {
            const { metrics } = res.data[0];
            metrics.forEach((item) => {
              result[item.code] = item.values[0].data[0].value;
            });
          }
          this.analyticsTotalInfo = { ...result };
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoadingTotalFailed = true;
          this.isLoading = false;
        });
    },
    getTimezone() {
      let tz = moment.tz.guess();
      if (this.profile.timezone) {
        tz = this.profile.timezone;
      }
      return tz;
    },
    onDeviceChange(type) {
      this.deviceType = type;
      if (this.deviceType === 'browser') {
        this.getBrowsers();
      } else {
        this.getDevicesByPie();
      }
    },
    onResizePie() {
      if (this.deviceType === 'browser') {
        this.getBrowsers();
      } else {
        this.getDevicesByPie();
      }
    },
    getMostRequestedList(isNotStrict) {
      const dataRequest = this.getDataRequest(isNotStrict);
      this.isLoadingMostRequestedFailed = false;
      this.isLoading = true;
      this.$store.dispatch(GET_MOST_REQUESTED_REQUEST, dataRequest)
        .then((res) => {
          this.isLoading = false;
          if (res.data && res.data[0]) {
            const { metrics } = res.data[0];
            const newArray = [];
            metrics[0].values.forEach((item) => {
              newArray.push({ title: item.title });
            });

            newArray.forEach((item) => {
              metrics.forEach((metric) => {
                metric.values.forEach((value) => {
                  if (item.title === value.title) {
                    item[metric.code] = value.value;
                  }
                });
              });
            });
            this.mediaItems = newArray;
          }
        })
        .catch(() => {
          this.isLoadingMostRequestedFailed = true;
          this.isLoading = false;
        });
    },
    getDataRequest(isNotStrict) {
      const params = {
        accountId: this.getCurrentAccountId,
        data: {
          timezone: this.getTimezone(),
          timeframe: this.timeframe,
          accounts: [this.getCurrentAccountId],
          mediaItems: [],
          granularity: 'none',
          mediaType: this.mediaType,
          mediaItemName: isNotStrict ? `${this.search}%%` : this.search,
        },
      };

      if (this.isCustomTimeframe) {
        params.data.fromDate = this.fromDate;
        params.data.toDate = this.toDate;
      }

      return params;
    },
    getGeoLocation(isNotStrict) {
      this.isLoadingMapFailed = false;
      const dataRequest = this.getDataRequest(isNotStrict);
      this.isLoading = true;
      this.$store.dispatch(GET_GEO_LOCATION_REQUEST, dataRequest)
        .then((res) => {
          const result = this.getGeoByType(res, this.geolocationType);
          this.usaMapData = result.usaMapData;
          this.worldMapData = result.worldMapData;
          this.$refs.analyticsGraph.createMapChart(result.usaMapData, result.worldMapData);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoadingMapFailed = true;
          this.isLoading = false;
        });
    },
    getGeoLocationTable(isNotStrict) {
      this.isLoadingSimpleTableFailed = false;
      const dataRequest = this.getDataRequest(isNotStrict);
      this.isLoading = true;
      this.$store.dispatch(GET_GEO_LOCATION_REQUEST, dataRequest)
        .then((res) => {
          const parsedGeoData = this.parseGeoLocationByType(res[0], this.selectedGeoType);
          this.geoAndDevicesItems = parsedGeoData;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoadingSimpleTableFailed = true;
          this.isLoading = false;
        });
    },
    parseGeoLocationByType(res, type) {
      const newArray = [];
      if (res.data && res.data[0]) {
        const { metrics } = res.data[0];
        metrics[0].values.forEach((item) => {
          newArray.push({ [type]: item[type] });
        });

        newArray.forEach((item) => {
          metrics.forEach((metric) => {
            metric.values.forEach((value) => {
              if (item[type] === value[type]) {
                item[metric.code] = value.value;
              }
            });
          });
        });
      }
      return newArray;
    },
    getGeoByType(res, type) {
      const worldMapData = [];
      const usaMapData = [];
      res.forEach((data) => {
        if (data.data && data.data[0] && data.data[0].metrics) {
          const { metrics } = data.data[0];
          metrics.forEach((item) => {
            if (item.code === type && item.values) {
              item.values.forEach((value) => {
                if (value.country !== undefined) {
                  worldMapData.push({
                    id: countryConverter.getCountryIso2Code(value.country),
                    value: value.value,
                  });
                } else if (value.state_province) {
                  usaMapData.push({
                    id: `US-${countryConverter.getUSAStateCode(value.state_province)}`,
                    value: value.value,
                  });
                }
              });
            }
          });
        }
      });

      return {
        worldMapData,
        usaMapData,
      };
    },
    getBrowsers(isNotStrict) {
      const dataRequest = this.getDataRequest(isNotStrict);
      this.isLoadingPieFailed = false;
      this.isLoading = true;
      this.$store.dispatch(GET_BROWSER_REQUEST, dataRequest)
        .then((res) => {
          const data = [];
          if (res.data && res.data[0] && res.data[0].metrics) {
            const { metrics } = res.data[0];
            metrics.forEach((item) => {
              if (item.code === this.pieType && item.values) {
                item.values.forEach((value) => {
                  data.push({
                    browser: value.browser,
                    value: value.value,
                  });
                });
              }
            });
            data.sort((a, b) => b.value - a.value);
          }
          this.$refs.analyticsGraph.createBrowserPieChart(data);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoadingPieFailed = true;
          this.isLoading = false;
        });
    },
    getDevicesByPie(isNotStrict) {
      const dataRequest = this.getDataRequest(isNotStrict);
      dataRequest.data.type = 'device_type';
      this.isLoadingPieFailed = false;
      this.isLoading = true;
      this.$store.dispatch(GET_DEVICE_REQUEST, dataRequest)
        .then((res) => {
          const data = [];
          if (res.data && res.data[0] && res.data[0].metrics) {
            const { metrics } = res.data[0];
            metrics.forEach((item) => {
              if (item.code === this.pieType && item.values) {
                item.values.forEach((value) => {
                  data.push({
                    device: value.device_type,
                    value: value.value,
                  });
                });
              }
            });
            data.sort((a, b) => b.value - a.value);
          }
          this.$refs.analyticsGraph.createDevicePieChart(data);
          this.isLoading = false;
        }).catch(() => {
          this.isLoadingPieFailed = true;
          this.isLoading = false;
        });
    },
    onReloadData(name) {
      switch (name) {
      case 'total':
        this.isLoadingTotalFailed = false;
        this.getMediaTotal(false);
        break;
      case 'mostRequestedTitles':
        this.isLoadingMostRequestedFailed = false;
        this.getMostRequestedList(false);
        break;
      case 'map':
        this.isLoadingMapFailed = false;
        this.getGeoLocation();
        break;
      case 'pie':
        this.isLoadingPieFailed = false;
        if (this.deviceType === 'browser') {
          this.getBrowsers();
        } else {
          this.getDevicesByPie();
        }
        break;
      case 'simpleTable':
        this.isLoadingSimpleTableFailed = false;
        this.getGeoLocationTable();
        break;
      default: break;
      }
    },
    onHideAllReloaders() {
      this.isLoadingTotalFailed = false;
      this.isLoadingMostRequestedFailed = false;
      this.isLoadingMapFailed = false;
      this.isLoadingPieFailed = false;
      this.isLoadingSimpleTableFailed = false;
    },
  },
};
</script>
<style scoped>
  .analytics-page {
    margin: 10px 5px 50px;
    overflow-y: auto;
    padding: 30px 20px;
    width: 100%;
  }

  .analytics-overview {
    width: 100%;
  }
</style>
