<template>
  <div class="translate-wrapper">
    <ValidationObserver
      ref="validationObserver"
      v-slot="{ handleSubmit }"
    >
      <Loader :isVisible="isLoading" />
      <form
        class="contact-info"
        novalidate="novalidate"
        @submit.prevent="handleSubmit(submit)"
        @input="handleFormChange"
      >
        <MaterialInput
          v-model="formData.title"
          v-bind="formFields.title"
          :label="'Title'"
        />
        <MaterialInput
          v-model="formData.shortDescription"
          v-bind="formFields.shortDescription"
          :label="'Short Description'"
        />
        <MaterialTextArea
          v-model="formData.longDescription"
          v-bind="formFields.longDescription"
          :label="'Long Description'"
        />
        <div
          v-if="type !== 'season'"
          class="img-block"
        >
          <div class="poster-title">
            Title Image
          </div>
          <TitleSeriesImage
            v-if="folderLanguage"
            :key="formData.titleImg"
            ref="posterImage"
            :poster="formData.titleImg"
            :onPosterChange="handlePosterTitleChange"
            :isNeedRemove="true"
            :handleRemove="handleRemoveTitleImg"
            :externalErrorMessage="titleImageError"
          />
        </div>
        <div class="img-block">
          <div class="poster-title">
            Poster Image (16:9)
          </div>
          <PosterImage
            v-if="folderLanguage"
            :key="formData.image"
            ref="posterImage"
            :poster="formData.image"
            :onPosterChange="handlePosterChange"
            :isNeedRemove="true"
          />
        </div>
        <div class="img-block">
          <div class="wrapper-small">
            <div class="poster-title">
              Background Image
            </div>
            <PosterImage
              v-if="folderLanguage"
              :key="formData.backgroundImage"
              ref="posterImage"
              :poster="formData.backgroundImage"
              :onPosterChange="handleBackPosterChange"
              :typeOfPerson="'large'"
              :isNeedRemove="true"
              :imageType="'background image'"
            />
          </div>
        </div>
        <div class="img-block">
          <div class="poster-title">
            Poster Image (2:3)
          </div>
          <PosterImage
            v-if="folderLanguage"
            :key="formData.portraitImage"
            ref="portraitImage"
            :poster="formData.portraitImage"
            :onPosterChange="handlePortraitImageChange"
            :isNeedRemove="true"
            :isPoster2x3="true"
            class="poster-2x3-image"
          />
        </div>
        <div class="btn-wrapper">
          <div class="btn-wrapper-b">
            <Button
              type="submit"
              :title="'Apply Changes'"
              :classType="'primary'"
            />
            <Button
              :title="'Revert'"
              :classType="'warning'"
              :eventFunction="revertFolderLanguage"
            />
          </div>
          <div
            v-if="isEditedForm.length
              && isEditedForm.find(item => item.id === lang.id && !!item.value)"
            class="changes-wrapper"
          >
            <inline-svg
              :src="iconChanges"
              class="svg-icon"
            >
            </inline-svg>
            <span class="changes-text">
              Unsaved changes
            </span>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import MaterialTextArea from '../../inputs/MaterialTextArea.vue';
import PosterImage from '../../common/PosterImage/DragAndDropFileUploader.vue';
import Button from '../../common/Button.vue';
import iconChanges from '../../../assets/icons/icon-localization-changes.svg';
import TitleSeriesImage from '../../common/PosterImage/TitleSeriesImage.vue';

import {
  ADD_LANGUAGE_FOLDER_REQUEST,
  GET_LANGUAGE_FOLDER_REQUEST,
  INIT_FORM_CHANGES,
  SET_FORM_CHANGES,
} from '../../../store/actions/localization/localizationActions';
import Loader from '../../common/Loader.vue';

export default {
  name: 'TranslateLocalForm',
  components: {
    Loader,
    TitleSeriesImage,
    MaterialInput,
    MaterialTextArea,
    PosterImage,
    Button,
  },
  extends: BaseForm,
  props: {
    metadata: {
      type: Object,
      default: () => {},
    },
    lang: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
    enabledLanguages: {
      type: Array,
      default: () => [],
    },
    changedLanguage: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconChanges,
      submitEvent: ADD_LANGUAGE_FOLDER_REQUEST,
      successMessages: `The ${this.lang.name} Localization has been successfully updated`,
      isPosterSmallChanged: false,
      isPosterTitleChanged: false,
      isPosterLargeChanged: false,
      isPortraitImageChanged: false,
      titleImageError: '',
      formData: {
        title: '',
        titleImg: '',
        shortDescription: '',
        longDescription: '',
        image: '',
        backgroundImage: '',
        portraitImage: '',
      },
      formFields: {
        title: {
          type: 'text',
          name: 'title',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        shortDescription: {
          type: 'text',
          name: 'shortDescription',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        longDescription: {
          type: 'text',
          name: 'longDescription',
          cols: 30,
          rows: 10,
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 1800,
          },
        },
      },
      errorMapping: {
        title: [
          `${this.formPrefix}.title`,
        ],
        shortDescription: [
          `${this.formPrefix}.shortDescription`,
        ],
        longDescription: [
          `${this.formPrefix}.longDescription`,
        ],
      },
    };
  },
  computed: {
    ...mapState({
      folderLanguage: (state) => state.localization.folderLanguage,
      isEditedForm: (state) => state.localization.isEditedForm,
      isLoading: (state) => state.localization.isLoading,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  created() {
    const value = this.enabledLanguages.map(((item) => ({
      id: item.id,
      value: false,
    })));
    this.$store.dispatch(INIT_FORM_CHANGES, value);

    const params = {
      accountId: this.getCurrentAccountId,
      id: this.type === 'season' ? this.$route.params.seasonId : this.$route.params.id,
      type: this.type,
      langId: this.lang.id,
    };
    this.$store.dispatch(GET_LANGUAGE_FOLDER_REQUEST, params).then(() => {
      this.initFormData();
    });
  },
  beforeDestroy() {
    this.$store.dispatch(SET_FORM_CHANGES, []);
  },
  methods: {
    handleFormChange() {
      this.$emit('changedLanguage', { id: this.lang.id, value: true });
    },
    initFormData() {
      this.formData.title = this.folderLanguage.title;
      this.formData.shortDescription = this.folderLanguage.shortDescription;
      this.formData.longDescription = this.folderLanguage.longDescription;
      this.formData.image = this.folderLanguage.posterImage
        ? `${this.folderLanguage.posterImage}?height=360`
        : '';
      this.formData.backgroundImage = this.folderLanguage.backgroundImage
        ? `${this.folderLanguage.backgroundImage}?height=360`
        : '';
      this.formData.titleImg = this.folderLanguage.titleImage
        ? `${this.folderLanguage.titleImage}?height=220`
        : '';
      this.formData.portraitImage = this.folderLanguage.portraitImage
        ? `${this.folderLanguage.portraitImage}?height=360`
        : '';
    },
    handlePosterChange(img) {
      this.isPosterSmallChanged = true;
      this.handleFormChange();
      this.formData.image = img;
    },
    handlePortraitImageChange(img) {
      this.isPortraitImageChanged = true;
      this.handleFormChange();
      this.formData.portraitImage = img;
    },
    handlePosterTitleChange(img) {
      this.isPosterTitleChanged = true;
      this.handleFormChange();
      this.formData.titleImg = img;
      if (this.titleImageError) {
        this.titleImageError = '';
      }
    },
    handleBackPosterChange(img) {
      this.isPosterLargeChanged = true;
      this.handleFormChange();
      this.formData.backgroundImage = img;
    },
    handleRemoveTitleImg() {
      this.formData.titleImg = '';
      if (this.titleImageError) {
        this.titleImageError = '';
      }
    },
    revertFolderLanguage() {
      const params = {
        accountId: this.getCurrentAccountId,
        id: this.type === 'season' ? this.$route.params.seasonId : this.$route.params.id,
        type: this.type,
        langId: this.lang.id,
      };
      this.$store.dispatch(GET_LANGUAGE_FOLDER_REQUEST, params).then(() => {
        this.$emit('changedLanguage', { id: this.lang.id, value: false });
        this.$store.dispatch(SET_FORM_CHANGES, { id: this.lang.id, value: false });
        this.isPosterSmallChanged = false;
        this.initFormData();
      });
    },
    getRequestData() {
      const data = {
        title: this.formData.title,
        shortDescription: this.formData.shortDescription,
        longDescription: this.formData.longDescription,
        posterImage: this.formData.image,
        titleImage: this.formData.titleImg,
        backgroundImage: this.formData.backgroundImage,
        portraitImage: this.formData.portraitImage,
      };

      if (!this.isPosterSmallChanged) {
        delete data.posterImage;
      }

      if (!this.isPosterTitleChanged) {
        delete data.titleImage;
      }

      if (!this.isPosterLargeChanged) {
        delete data.backgroundImage;
      }

      if (!this.isPortraitImageChanged) {
        delete data.portraitImage;
      }

      return {
        accountId: this.getCurrentAccountId,
        id: this.type === 'season' ? this.$route.params.seasonId : this.$route.params.id,
        type: this.type,
        langId: this.lang.id,
        data,
      };
    },
    onSubmitSuccess() {
      this.isPosterSmallChanged = false;
      this.isPortraitImageChanged = false;
      this.$emit('changedLanguage', { id: this.lang.id, value: false });
      this.$store.dispatch(SET_FORM_CHANGES, { id: this.lang.id, value: false });
      this.$toasted.global.success({
        message: this.successMessages,
      });
    },
    onSubmitFail(error) {
      const titleErr = error.form_errors.children
        && error.form_errors.children.titleImage
        && error.form_errors.children.titleImage.errors;

      if (titleErr) {
        this.titleImageError = 'File MIME type should be PNG';
        return;
      }

      this.formErrors = this.getServerFormErrors(error);

      if (Object.entries(this.formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(this.formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.translate-wrapper {
  padding-left: 20px;
  padding-right: 428px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.btn-wrapper {
  display: flex;
  padding-top: 35px;
}

.changes-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #FB3951;
  border-radius: 2px;
  padding: 8px;
  color: #FB3951;
  margin-left: 20px;
}

.changes-text {
  padding-left: 5px;
}

.btn-wrapper-b {
  display: flex;
}

.img-block {
  margin-bottom: 20px;
}

.poster-2x3-image{
  height: 400px;
}

@media all and (max-width: 1670px) {
  .translate-wrapper {
    padding-right: 300px;
  }
}

@media all and (max-width: 1460px) {
  .translate-wrapper {
    padding-right: 200px;
  }
}

@media all and (max-width: 1300px) {
  .translate-wrapper {
    padding-right: 100px;
  }
}
</style>
