<template>
  <div>
    <div
      ref="fileform"
      class="file-drag-drop"
      :class="[isError ? 'error-form' : '', isDisabled ? 'disabled' : '']"
      :title="`Add ${assetTypeReq}`"
      @click="onFileInput"
    >
      <form class="file-form">
        <div
          class="drag-drop-zone"
          :class="[isDisabled ? 'disabled' : '']"
        >
          <inline-svg
            :src="iconUpload"
            class="svg"
          />
          <span class="drop-files">
            Drag & Drop
          </span>
          <span class="drop-files">
            or click to <span class="browser-link">browse</span>
          </span>
        </div>
      </form>
      <input
        ref="fileInput"
        type="file"
        class="input-hidden"
        :multiple="false"
        :accept="uploadingType"
        :disabled="isDisabled"
        @change="fileInputChange"
      />
    </div>
  </div>
</template>

<script>
import iconUpload from '../../assets/icons/icon-upload.svg';

export default {
  name: 'AlternativeAudioUploader',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    onFileUpload: {
      type: Function,
      default: () => {},
    },
    altType: {
      type: String,
      default: '',
    },
    assetTypeReq: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconUpload,
      dragAndDropCapable: false,
    };
  },
  computed: {
    uploadingType() {
      if (this.altType === 'supp_video') {
        return 'video/*';
      }

      if (this.altType === 'document' || this.altType === 'other') {
        return '/*';
      }

      if (this.altType === 'image') {
        return 'image/*';
      }

      return 'audio/*';
    },
  },
  mounted() {
    this.dragInit();
  },
  methods: {
    fileInputChange() {
      const { files } = this.$refs.fileInput;
      if (files.length) {
        this.onFileUpload(files);
      }
    },
    onFileInput() {
      this.$refs.fileInput.click();
    },
    dragInit() {
      this.dragAndDropCapable = this.determineDragAndDropCapable();
      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
          this.$refs.fileform.addEventListener(evt, (e) => {
            e.preventDefault();
            e.stopPropagation();
          }, false);
        });

        this.$refs.fileform.addEventListener('drop', this.dropHandle);
      }
    },
    dropHandle(e) {
      const file = e.dataTransfer.files[0];
      if (file) {
        this.onFileUpload(e.dataTransfer.files);
      }
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div');
      return (('draggable' in div)
          || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window
        && 'FileReader' in window;
    },
  },
};
</script>

<style scoped>
.modal-window {
  height: 453px;
}

.input-wrapper {
  margin-bottom: 152px;
}

.file-drag-drop {
  border: 1px dashed #a0a0a0;
  cursor: pointer;
  height: 230px;
  width: 460px;
}

.error-label {
  color: #fb3951;
  font-size: 12px;
  margin-bottom: 5px;
  text-align: left;
}

.error-form {
  border-color: #fb3951;
}

.drag-drop-zone {
  /*cursor: pointer;*/
  /*padding: 40px 0;*/
  text-align: center;
}

.drag-drop-zone .drop-files {
  display: block;
}

.drag-drop-zone .drop-files .browser-link {
  color: #2591cf;
}

.file-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.drop-files {
  color: #a0a0a0;
}

.input-hidden {
  display: none;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
