<template>
  <div
    v-if="modal"
    class="overlay"
  >
    <div
      ref="manuallyForm"
      :class="['wrapper-modal' , isOpen && 'hide']"
    >
      <div class="form-container">
        <div class="wrapper main-form-container">
          <div class="column column-1">
            <div class="title modal">
              Add Asset Type
            </div>
            <MaterialSelect
              v-if="assetRoleTypes.length"
              v-model="formData.assetType"
              v-bind="formFields.assetType"
              :options="assetRoleTypes"
              :withEmptyField="false"
            />
            <div
              v-else
              class="no-items"
            >
              No Items to display
            </div>
            <div :class="[isEdit ? 'edit-button-wrapper' : 'end-buttons']">
              <div class="btns-wrapper">
                <Button
                  :title="'Cancel'"
                  :classType="'warning'"
                  :eventFunction="backTo"
                />
                <Button
                  :title="'Add'"
                  :classType="'primary'"
                  :eventFunction="addAssetType"
                  :disabled="!assetRoleTypes.length || !formData.assetType"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CONFIG from '../constants/config';
import { SET_EDIT_FORM } from '../store/actions/common';
import Button from './common/Button.vue';
import MaterialSelect from './inputs/MaterialSelect.vue';

export default {
  name: 'AddAssetTypeModal',
  components: {
    Button,
    MaterialSelect,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    addAssetTypeHandler: {
      type: Function,
      default: () => {},
    },
    onSubmitManually: {
      type: Function,
      default: () => {},
    },
    formPrefix: {
      type: String,
      default: 'addAssetType',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Array,
      default: () => [],
    },
    assetRoleTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      formData: {
        assetType: '',
      },
      formFields: {
        assetType: {
          type: 'text',
          name: 'assetType',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.cms.isLoading,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  created() {
    this.formData.assetType = '';
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  methods: {
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
    },
    backTo() {
      this.onClose();
    },
    addAssetType() {
      this.addAssetTypeHandler(this.formData.assetType);
      this.onClose();
    },
  },
};
</script>

<style scoped>
.form-container {
  overflow-y: auto;
  width: 100%;
}

.wrapper {
  display: flex;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.wrapper-modal {
  background-color: #222;
  border-radius: 4px;
  display: block;
  left: 50%;
  max-width: 100%;
  outline: none;
  overflow: auto;
  overflow-wrap: break-word;
  padding: 30px;
  position: absolute;
  text-decoration: none;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.modal {
  color: #fff;
  margin-bottom: 30px;
}

.hide {
  overflow: hidden;
}

.no-items {
  margin-bottom: 20px;
}

.btns-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
</style>
