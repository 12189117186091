<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="btn-create-server-mobile">
      <Button
        :title="'Add Server'"
        :classType="'primary'"
        :eventFunction="createServerHandler"
      />
    </div>
    <div class="flex">
      <div class="left-path">
        <span class="system">
          System Management
        </span>
        <span>
          / Servers
        </span>
      </div>
      <div class="right-path">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="100"
        />
        <div class="btn-create-server-full">
          <Button
            :title="'Add Server'"
            :classType="'primary'"
            :eventFunction="createServerHandler"
          />
        </div>
      </div>
    </div>
    <div class="servers-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name server-name">
          <Sorting
            :sortField="`ws.${sortedField.name}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          server name
        </div>
        <div class="th-name accounts-count">
          <Sorting
            :sortField="`${sortedField.count}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          number of accounts
        </div>
      </div>
      <div
        v-if="!servers.length"
        class="empty-search"
      >
        No servers to display
      </div>
      <div
        v-for="(item, key) in servers"
        :key="item.id"
        class="server"
        @mouseover="onHoverElem(item.id)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div
          class="td server-name"
          :title="item.name"
        >
          {{ item.name }}
        </div>
        <div
          class="td accounts-count"
        >
          {{ item.accountCount }}
        </div>
        <div
          :class="['icons-wrapper', (isHover && (item.id === selectedId))
            ? ''
            : 'icons-wrapper-hide'
          ]"
        >
          <div
            v-show="!item.canEdit"
            class="icon-view-only"
            :title="viewOnlyServer"
            @click="showServerInfo(item)"
          >
            <inline-svg
              :src="iconViewOnly"
              class="svg-icon"
            >
            </inline-svg>
          </div>
          <div
            v-show="item.canEdit"
            class="icon-edit"
            :title="editServer"
            @click="showServerInfo(item)"
          >
            <inline-svg
              :src="iconEdit"
              class="svg-icon"
            >
            </inline-svg>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :currentPage="currentPage"
      :totalPages="totalPages"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  GET_ALL_SERVERS,
  SET_IS_VIEW_ONLY_FORM,
} from '../../store/actions/servers/servers';

import Pagination from '../../components/common/Pagination.vue';
import Loader from '../../components/common/Loader.vue';
import Sorting from '../../components/common/Sorting.vue';
import LiveSearch from '../../components/common/LiveSearch.vue';
import Button from '../../components/common/Button.vue';

import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconViewOnly from '../../assets/icons/icon-view-only.svg';
import iconSortUp from '../../assets/icons/icon-sort-arrow-up.svg';
import iconSortDown from '../../assets/icons/icon-sort-arrow-down.svg';
import CONFIG from '../../constants/config';

export default {
  name: 'WowzaServerList',
  components: {
    Pagination,
    LiveSearch,
    Sorting,
    Loader,
    Button,
  },
  data() {
    const {
      sort,
    } = CONFIG;
    const {
      viewOnlyServer,
      editServer,
    } = CONFIG.tooltips;
    return {
      isHover: false,
      selectedId: null,
      filter: '',
      sortedField: {
        name: 'name',
        count: 'count',
      },
      currentSortedField: 'ws.name',
      currentSortBy: sort.sortUp,
      iconEdit,
      iconViewOnly,
      iconSortUp,
      iconSortDown,
      viewOnlyServer,
      editServer,
    };
  },
  computed: {
    ...mapState({
      servers: (state) => state.servers.servers,
      pagination: (state) => state.servers.pagination,
      currentPage: (state) => state.servers.currentPage,
      isLoading: (state) => state.servers.isLoading,
    }),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    const params = {
      page: localStorage.getItem('currentPage-server-list') || 1,
      filterValue: '',
    };
    this.$store.dispatch(GET_ALL_SERVERS, params);
  },
  methods: {
    handleSortUp(field) {
      const params = {
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_SERVERS, params);
    },
    handleSortDown(field) {
      const params = {
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_SERVERS, params);
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        page: params.page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ALL_SERVERS, getParams);
    },
    getPaginationAction(page) {
      const params = {
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ALL_SERVERS, params);
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    onHoverElem(id) {
      this.selectedId = id;
      this.isHover = true;
    },
    createServerHandler() {
      this.$store.dispatch(SET_IS_VIEW_ONLY_FORM, false);
      this.$router.push('servers/add');
    },
    showServerInfo(item) {
      const isViewOnly = !item.canEdit;
      this.$store.dispatch(SET_IS_VIEW_ONLY_FORM, isViewOnly);
      this.$router.push(`servers/${item.id}`);
    },
  },
};
</script>

<style scoped>
  .flex {
    align-items: center;
    display: flex;
    flex: 0;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 40px;
  }

  .main-wrapper {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 25px 30px;
    width: 100%;
  }

  .system {
    color: #4c5057;
  }

  .servers-wrapper {
    border: 1px solid #43434d;
    height: 100%;
    overflow-y: auto;
  }

  .ac-wrapper-header {
    background: #282a2e;
    display: flex;
    padding: 13px 0;
  }

  .th-name {
    align-items: center;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
  }

  .server {
    display: flex;
  }

  .server:hover {
    background: #383b40;
  }

  .td {
    font-size: 14px;
    overflow: hidden;
    padding: 15px 0;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .number {
    padding-left: 21px;
    width: 67px;
  }

  .server-name,
  .accounts-count {
    margin-right: 5px;
    width: 30%;
  }

  .icons-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 0.95;
    justify-content: flex-end;
    min-width: 110px;
  }

  .icon-view-only,
  .icon-edit {
    padding: 10px 15px 0 10px;
  }

  .right-path {
    align-items: center;
    display: flex;
  }

  .empty-search {
    padding-top: 40px;
    text-align: center;
  }

  .svg-icon {
    cursor: pointer;
  }

  .icons-wrapper-hide {
    visibility: hidden;
  }

  .btn-create-server-mobile {
    display: none;
  }

  .left-path {
    margin-top: 5px;
  }

  @media all and (max-width: 1024px) {
    .btn-create-server-mobile {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
      min-height: 40px;
    }

    .btn-create-server-full {
      display: none;
    }

    .search-wrapper {
      margin-right: 0;
    }
  }
</style>
