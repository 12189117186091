export const ADD_LIVE_STREAM_REQUEST = 'ADD_LIVE_STREAM_REQUEST';
export const ADD_LIVE_STREAM_SUCCESS = 'ADD_LIVE_STREAM_SUCCESS';
export const ADD_LIVE_STREAM_ERROR = 'ADD_LIVE_STREAM_ERROR';

export const EDIT_LIVE_STREAM_REQUEST = 'EDIT_LIVE_STREAM_REQUEST';
export const EDIT_LIVE_STREAM_SUCCESS = 'EDIT_LIVE_STREAM_SUCCESS';
export const EDIT_LIVE_STREAM_ERROR = 'EDIT_LIVE_STREAM_ERROR';

export const EDIT_LIVE_STREAM_URL_REQUEST = 'EDIT_LIVE_STREAM_URL_REQUEST';
export const EDIT_LIVE_STREAM_URL_SUCCESS = 'EDIT_LIVE_STREAM_URL_SUCCESS';
export const EDIT_LIVE_STREAM_URL_ERROR = 'EDIT_LIVE_STREAM_URL_ERROR';

export const GET_FOLDERS_LIST_REQUEST = 'GET_FOLDERS_LIST_REQUEST';
export const GET_FOLDERS_LIST_SUCCESS = 'GET_FOLDERS_LIST_SUCCESS';
export const GET_FOLDERS_LIST_ERROR = 'GET_FOLDERS_LIST_ERROR';

export const GET_FB_LINK = 'GET_FB_LINK';
export const GET_FB_LINK_REQUEST = 'GET_FB_LINK_REQUEST';
export const GET_FB_LINK_SUCCESS = 'GET_FB_LINK_SUCCESS';
export const GET_FB_LINK_ERROR = 'GET_FB_LINK_ERROR';

export const GET_FB_DATA_SSE = 'GET_FB_DATA_SSE';
export const GET_FB_DATA_SSE_SUCCESS = 'GET_FB_DATA_SSE_SUCCESS';
export const FB_LINK_RESET = 'FB_LINK_RESET';
export const FB_LINK_RESET_SUCCESS = 'FB_LINK_RESET_SUCCESS';
export const GET_FB_LOGOUT = 'GET_FB_LOGOUT';
export const SET_FB_LINK_WINDOW = 'SET_FB_LINK_WINDOW';

export const ADD_DELIVERY_TARGET_REQUEST = 'ADD_DELIVERY_TARGET_REQUEST';
export const ADD_DELIVERY_TARGET_SUCCESS = 'ADD_DELIVERY_TARGET_SUCCESS';
export const ADD_DELIVERY_TARGET_ERROR = 'ADD_DELIVERY_TARGET_ERROR';

export const EDIT_DELIVERY_TARGET_REQUEST = 'EDIT_DELIVERY_TARGET_REQUEST';
export const EDIT_DELIVERY_TARGET_SUCCESS = 'EDIT_DELIVERY_TARGET_SUCCESS';
export const EDIT_DELIVERY_TARGET_ERROR = 'EDIT_DELIVERY_TARGET_ERROR';

export const DELETE_DELIVERY_TARGET_REQUEST = 'DELETE_DELIVERY_TARGET_REQUEST';
export const DELETE_DELIVERY_TARGET_SUCCESS = 'DELETE_DELIVERY_TARGET_SUCCESS';
export const DELETE_DELIVERY_TARGET_ERROR = 'DELETE_DELIVERY_TARGET_ERROR';

export const EDIT_DELIVERY_TARGET_STATE_REQUEST = 'EDIT_DELIVERY_TARGET_STATE_REQUEST';
export const EDIT_DELIVERY_TARGET_STATE_SUCCESS = 'EDIT_DELIVERY_TARGET_STATE_SUCCESS';
export const EDIT_DELIVERY_TARGET_STATE_ERROR = 'EDIT_DELIVERY_TARGET_STATE_ERROR';

export const GET_FB_ACCESS_TOKEN_REQUEST = 'GET_FB_ACCESS_TOKEN_REQUEST';
export const GET_FB_ACCESS_TOKEN_SUCCESS = 'GET_FB_ACCESS_TOKEN_SUCCESS';
export const GET_FB_ACCESS_TOKEN_ERROR = 'GET_FB_ACCESS_TOKEN_ERROR';

export const GET_FB_TARGET_REQUEST = 'GET_FB_TARGET_REQUEST';
export const GET_FB_TARGET_SUCCESS = 'GET_FB_TARGET_SUCCESS';
export const GET_FB_TARGET_ERROR = 'GET_FB_TARGET_ERROR';

export const RESET_FB_DATA_EDIT = 'RESET_FB_DATA_EDIT';
export const RESET_FB_DATA_EDIT_SUCCESS = 'RESET_FB_DATA_EDIT_SUCCESS';

export const ACTIVE_FB_DATA_EDIT = 'ACTIVE_FB_DATA_EDIT';
export const ACTIVE_FB_DATA_EDIT_SUCCESS = 'ACTIVE_FB_DATA_EDIT_SUCCESS';

export const CHANGE_DELIVERY_TARGET_REQUEST = 'CHANGE_DELIVERY_TARGET_REQUEST';
export const CHANGE_DELIVERY_TARGET_SUCCESS = 'CHANGE_DELIVERY_TARGET_SUCCESS';
