<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="login-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="title">
        Login Info
      </div>
      <div>
        <MaterialInput
          v-model="formData.currentPassword"
          v-bind="formFields.currentPassword"
          :disabled="disabled"
        />
      </div>
      <div class="inline-row">
        <div class="inline-block">
          <MaterialInput
            v-model="formData.password"
            v-bind="formFields.password"
            :disabled="disabled"
          />
        </div>
        <div class="inline-block">
          <MaterialInput
            v-model="formData.confirmPassword"
            v-bind="formFields.confirmPassword"
            :disabled="disabled"
          />
        </div>
      </div>
      <Button
        :title="'Save'"
        :classType="'primary'"
        :type="'submit'"
        :disabled="disabled"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import BaseForm from './BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import { EDIT_PROFILE_LOGIN_INFO } from '../../store/actions/user';
import { PASSWORD_REG_EXP } from '../../utils/validation/regExp';
import CONFIG from '../../constants/config';
import Button from '../common/Button.vue';

export default {
  name: 'ChangePasswordForm',
  components: {
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'changePassword',
    },
  },
  data() {
    return {
      formData: {
        currentPassword: '',
        password: '',
        confirmPassword: '',
      },
      formFields: {
        currentPassword: {
          type: 'password',
          name: 'currentPassword',
          autocomplete: 'new-password',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        password: {
          type: 'password',
          name: 'password',
          autocomplete: 'new-password',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            regex: PASSWORD_REG_EXP,
            max: 255,
          },
        },
        confirmPassword: {
          type: 'password',
          name: 'confirmPassword',
          autocomplete: 'new-password',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            confirmed: 'password',
            max: 255,
          },
        },
      },
      errorMapping: {
        currentPassword: [
          `${this.formPrefix}.currentPassword`,
        ],
        password: [
          `${this.formPrefix}.plainPassword.first`,
        ],
        confirmPassword: [
          `${this.formPrefix}.plainPassword.second`,
        ],
      },
      submitEvent: EDIT_PROFILE_LOGIN_INFO,
      successMessage: CONFIG.successMessages.editUserPasswordMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  methods: {
    getRequestData() {
      return {
        currentPassword: this.formData.currentPassword,
        plainPassword: {
          first: this.formData.password,
          second: this.formData.confirmPassword,
        },
      };
    },
  },
};
</script>

<style scoped>
  .inline-row {
    display: flex;
    justify-content: space-between;
  }

  .inline-block + .inline-block {
    margin-left: 25px;
  }

  .inline-block {
    width: 300px;
  }
</style>
