<!-- eslint-disable vue/valid-v-model -->
<template>
  <div
    v-if="modal"
    class="overlay"
  >
    <div
      v-model="modal"
      class="wrapper"
    >
      <p
        v-if="title"
        class="headline grey lighten-2 delete-title"
        primaryTitle
      >
        {{ title }}
      </p>
      <div class="localization-warning-wrapper">
        <inline-svg
          :src="iconWarning"
          class="svg-icon"
        >
        </inline-svg>
        <span class="localization-warning">
          {{ bodyMessage }}
        </span>
      </div>
      <DeleteAccForm
        :compName="name"
        :accId="accId"
        :onClose="closeModalhandler"
        :getAccounts="getAccs"
        :isEditDelete="isEditDelete"
        :isAPI="isAPI"
      />
    </div>
  </div>
</template>

<script>
import {
  SET_OVERFLOW,
} from '../store/actions/common';
import iconWarning from '../assets/icons/icon-warning-localization.svg';
import DeleteAccForm from './forms/DeleteAccForm.vue';

export default {
  name: 'DeleteAccountModal',
  components: {
    DeleteAccForm,
  },
  props: {
    modal: Boolean,
    title: {
      type: String,
      default: '',
    },
    bodyMessage: {
      type: String,
      default: '',
    },
    bodyHelfullMessage: {
      type: String,
      default: '',
    },
    onAccept: {
      type: Function,
      default: () => {},
      required: false,
    },
    onDecline: {
      type: Function,
      default: () => {},
      required: false,
    },
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
    confirmButtonTitle: {
      type: String,
      default: 'Delete',
    },
    cancelButtonTitle: {
      type: String,
      default: 'Cancel',
    },
    futureStandaloneEvents: {
      type: Array,
      default: () => [],
      required: false,
    },
    fallbackMedia: {
      type: Object,
      default: null,
      required: false,
    },
    notRecurringMediaItem: {
      type: Array,
      default: () => [],
      required: false,
    },
    recurringMediaItem: {
      type: Array,
      default: () => [],
      required: false,
    },
    isCombiningDeletion: {
      type: Boolean,
      default: false,
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
    isDeleteAcc: {
      type: Boolean,
      default: false,
    },
    isEditDelete: {
      type: Boolean,
      default: false,
    },
    isAPI: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    accId: {
      type: String,
      default: '',
    },
    getAccounts: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconWarning,
    };
  },
  methods: {
    closeModal() {
      if (this.onClose) {
        this.onClose();
        return;
      }
      this.$emit('input', false);
      this.$store.dispatch(SET_OVERFLOW, false);
    },
    closeModalhandler() {
      this.onDecline();
      this.closeModal();
    },
    getName(name) {
      return name.length > 45 ? `${name.slice(0, 45)}...` : name;
    },
    getAccs() {
      this.closeModal();
      this.getAccounts();
    },
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.wrapper {
  background-color: #222222;
  border-radius: 4px;
  display: block;
  left: 50%;
  max-width: 100%;
  outline: none;
  overflow-wrap: break-word;
  position: absolute;
  text-decoration: none;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 630px;
}

.headline {
  color: #fff;
}

.body-text {
  padding: 15px 20px;
}

.bodyTitle {
  padding-top: 7px;
}

.delete-title {
  line-height: 21px;
}

.headline {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  margin: 0;
  padding: 30px;
  word-break: break-all;
}

hr {
  border: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: thin 0 0;
  display: block;
  flex: 1 1 0;
  height: 0;
  max-height: 0;
  max-width: 100%;
  transition: inherit;
}

.futureStandaloneEvents {
  margin-top: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding-left: 1em;
  text-indent: -.7em;
  padding-bottom: 15px;
}

li::before {
  content: "• ";
  color: var(--darkgray);
}

.bodyHelfullMessage {
  line-height: 20px;
  padding: 0 20px;
}

a {
  text-decoration: none;
  color: #2591CF;
}

.scroll-wrapper {
  height: 165px;
  overflow: auto;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-bottom: 20px;
  width: 590px;
}

.localization-warning {
  flex: 0.98;
  font-size: 16px;
  color: #F2C94C;
  padding-left: 8px;
}
</style>
