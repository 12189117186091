<template>
  <div class="main-wrapper">
    <div class="main-title">
      Delivery Settings
    </div>
    <div class="main-row-wrapper">
      <div class="player-wrapper">
        <div class="title-wrapper">
          <div class="title">
            Preview and Embed
          </div>
        </div>
        <div v-if="settings.playerSource">
          <BitmovinPlayer
            :key="playerKey"
            :autoplay="true"
            :isPayback="false"
            :mediaItem="{
              playerSource: settings.playerSource
            }"
            :isOnlySoundControls="true"
            :isAddLive="true"
          />
          <div class="buttons-wrapper">
            <Button
              :title="'Embed'"
              :classType="'primary'"
              :eventFunction="onShowWidgetHandle"
            />
          </div>
        </div>
        <div v-else>
          <div class="no-stream-block">
            No current stream on Wowza
          </div>
        </div>
        <WidgetModal
          v-if="showWidgetModal"
          :modalData="widgetModalData"
          :onClose="onCloseWidgetModal"
          :dash="getManifest('dash')"
          :hls="getManifest('hls')"
        />
      </div>
      <ExternalDeliveryTargets
        :key="playerKey"
        :deliveryTargets="deliveryTargets"
        :isImmediateSave="true"
      />
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  GET_SCHEDULE,
} from '../../store/actions/schedule/schedule';
import { GET_WIDGET } from '../../store/actions/widgets/widget';
import { SET_EDIT_FORM } from '../../store/actions/common';
import CONFIG from '../../constants/config';

import BitmovinPlayer from '../../components/common/BitmovinPlayer.vue';
import Button from '../../components/common/Button.vue';
import WidgetModal from '../../components/common/WidgetModal.vue';
import ExternalDeliveryTargets from '../../components/liveStream/deliveryTargets/ExternalDeliveryTargets.vue';

export default {
  name: 'ScheduleDeliverySettings',
  components: {
    BitmovinPlayer,
    Button,
    WidgetModal,
    ExternalDeliveryTargets,
  },
  data() {
    return {
      showWidgetModal: false,
      widgetModalData: {},
      settings: {},
      deliveryTargets: [],
      widget: null,
      playerKey: 0,
    };
  },
  computed: {
    ...mapState({
      isEditedForm: (state) => state.common.isEditedForm,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
    ]),
    scheduleId() {
      return this.getCurrentAccount.schedule.id;
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    const params = {
      scheduleId: this.scheduleId,
      accountId: this.getCurrentAccountId,
    };

    this.$store.dispatch(GET_SCHEDULE, params)
      .then((res) => {
        this.settings = res;
        this.widget = res.widget;
        this.deliveryTargets = this.transformBEDeliveryTargets(res.deliveryTargets);
        this.playerKey += 1;
      });
  },
  methods: {
    onShowWidgetHandle() {
      if (this.widget) {
        this.openWidgetModal(this.widget);
      } else {
        this.generateWidget({
          accountId: this.getCurrentAccountId,
          data: {
            accountSchedule: this.scheduleId,
          },
        });
      }
    },
    openWidgetModal(data) {
      this.showWidgetModal = true;
      this.widgetModalData = data;
    },
    generateWidget(data) {
      this.$store.dispatch(GET_WIDGET, data)
        .then((res) => {
          this.widget = res;
          this.openWidgetModal(res);
        });
    },
    onCloseWidgetModal() {
      this.showWidgetModal = false;
      this.widgetModalData = {};
    },
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    getManifest(type) {
      if (this.settings && this.settings.playerSource && this.settings.playerSource[type]) {
        return this.settings.playerSource[type];
      }
      return '';
    },
    transformBEDeliveryTargets(deliveryTargets) {
      return deliveryTargets.map((item) => {
        if (item.type === 'facebook') {
          const result = {
            params: {},
            fbData: {},
          };
          Object.entries(item).forEach(([key, value]) => {
            result[key] = value;
          });
          Object.entries(item).forEach(([key, value]) => {
            result.params[key] = value;
          });
          Object.entries(item).forEach(([key, value]) => {
            result.fbData[key] = value;
          });
          return result;
        }

        return item;
      });
    },
  },
};
</script>

<style scoped>
 .main-wrapper {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 25px 30px;
    width: 100%;
  }

  .main-row-wrapper {
    display: flex;
  }

  .main-row-wrapper >>> .external-delivery-targets {
    margin: 0 20px 0 80px;
    max-width: 500px;
    width: 100%;
  }

 .player-wrapper {
    max-width: 500px;
    width: 100%;
  }

  .buttons-wrapper {
    margin-top: 20px;
  }

  .no-stream-block {
    height: 300px;
    width: 350px;
  }

  @media all and (max-width: 1150px) {
    .main-row-wrapper {
      display: block;
    }

    .main-row-wrapper >>> .external-delivery-targets {
      margin: 40px 0;
    }
  }
</style>
