<template>
  <div :class="[isSingleMedia ? 'single-media' : '']">
    <div class="analytics-title">
      <div>
        Most Requested Titles
      </div>
      <ResizedButton
        :resized="resized"
        :onResize="onResize"
      />
    </div>
    <div class="an-table">
      <div class="an-table-header">
        <div class="an-th-title">
          <SortingByColumn
            :sortField="sortField.title"
            :currentSortParams="currentSortParams"
            :handleSort="setCurrentSortParams"
          />
          Media title
        </div>
        <div class="an-th-plays">
          <SortingByColumn
            :sortField="sortField.views"
            :currentSortParams="currentSortParams"
            :handleSort="setCurrentSortParams"
          />
          Plays
        </div>
        <div class="an-th-viewers">
          <SortingByColumn
            :sortField="sortField.uniques"
            :currentSortParams="currentSortParams"
            :handleSort="setCurrentSortParams"
          />
          Unique Viewers
        </div>
        <div class="an-th-avg">
          <SortingByColumn
            :sortField="sortField.playtime"
            :currentSortParams="currentSortParams"
            :handleSort="setCurrentSortParams"
          />
          Avg. Play Time
        </div>
        <div class="an-th-total">
          <SortingByColumn
            :sortField="sortField.hours"
            :currentSortParams="currentSortParams"
            :handleSort="setCurrentSortParams"
          />
          Total Play Time
        </div>
        <div class="an-th-filter"></div>
      </div>
      <div class="an-table-body">
        <div
          v-for="(item, index) in sortedMediaItems"
          :key="`${item.views}_${item.uniques}_${index}`"
        >
          <div
            v-if="(!resized && index < 5) || resized"
            class="an-table-rows"
          >
            <div
              class="an-th-title"
              :title="item.title"
            >
              {{ item.title }}
            </div>
            <div class="an-th-plays">
              {{ item.views }}
            </div>
            <div class="an-th-viewers">
              {{ item.uniques }}
            </div>
            <div class="an-th-avg">
              {{ getFormatPlayTime(item.playtime) }}
            </div>
            <div class="an-th-total">
              {{ getFormatHourTime(item.hours) }}
            </div>
            <div class="an-th-filter">
              <div class="analytics-icon-filter">
                <inline-svg
                  :src="iconFilter"
                  class="svg"
                  :class="['svg', item.title === selectedFilter ? 'selected-filter-svg' : '']"
                  @click="onIconSelect(item.title)"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!mediaItems.length"
          class="empty-chart"
        >
          No Metrics to display
        </div>
      </div>
      <DataReloader
        name="mostRequestedTitles"
        :isHide="isLoadingFailed"
        :onReload="onReloadData"
      />
    </div>
  </div>
</template>
<script>
import iconMaxSize from '../../assets/icons/icon-maxsize.svg';
import iconMinSize from '../../assets/icons/icon-minsize.svg';
import iconFilter from '../../assets/icons/icon-filter.svg';
import ResizedButton from './ResizeButton.vue';
import SortingByColumn from '../common/SortingByColumn.vue';
import DataReloader from './DataReloader.vue';
import CONFIG from '../../constants/config';

export default {
  name: 'AnalyticsMostRequestedTitles',
  components: {
    ResizedButton,
    SortingByColumn,
    DataReloader,
  },
  props: {
    mediaItems: {
      type: Array,
      default: () => [],
    },
    onFilterSelect: {
      type: Function,
      default: () => {},
    },
    selectedFilter: {
      type: String,
      default: null,
    },
    isLoadingFailed: {
      type: Boolean,
      default: false,
    },
    onReloadData: {
      type: Function,
      default: () => {},
    },
    isSingleMedia: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconFilter,
      iconMaxSize,
      iconMinSize,
      resized: false,
      sortField: CONFIG.analyticSortField,
      currentSortParams: {
        field: 'uniques',
        order: CONFIG.sort.sortDown,
      },
    };
  },
  computed: {
    sortedMediaItems() {
      const sortingMediaItems = [...this.mediaItems];
      const sortingFieldName = this.currentSortParams.field;

      sortingMediaItems.sort((a, b) => {
        const firstValue = (typeof a[sortingFieldName] === 'string')
          ? a[sortingFieldName].toLowerCase()
          : a[sortingFieldName];

        const secondValue = (typeof b[sortingFieldName] === 'string')
          ? b[sortingFieldName].toLowerCase()
          : b[sortingFieldName];

        if (firstValue < secondValue) {
          return 1;
        }
        if (firstValue > secondValue) {
          return -1;
        }
        return 0;
      });

      if (this.currentSortParams.order === CONFIG.sort.sortUp) {
        return sortingMediaItems.reverse();
      }
      return sortingMediaItems;
    },
  },
  methods: {
    onResize() {
      this.resized = !this.resized;
    },
    onIconSelect(title) {
      this.onFilterSelect(title);
    },
    getFormatPlayTime(minutes) {
      if (minutes) {
        const hrs = this.getInteger(minutes / 60);
        const mins = this.getInteger(minutes - (hrs * 60));
        const secs = this.getInteger(this.getDecimal(minutes) * 60);
        return (!hrs) ? `${mins} min ${secs} sec` : `${hrs} ${hrs === 1 ? 'hr' : 'hrs'} ${mins} min`;
      }
      return minutes;
    },
    getFormatHourTime(hours) {
      if (hours) {
        const hrs = this.getInteger(hours);
        const fullMins = this.getDecimal(hours) * 60;
        const mins = this.getInteger(fullMins);
        const secs = this.getInteger(this.getDecimal(fullMins) * 60);
        if (!hrs) {
          return `${mins} min ${secs} sec`;
        }
        return (hrs === 1) ? `${hrs} hr ${mins} min` : `${hrs.toLocaleString('en')} hrs ${mins} min`;
      }
      return hours;
    },
    getInteger(value) {
      return Math.trunc(value);
    },
    getDecimal(value) {
      return (value - Math.trunc(value));
    },
    setCurrentSortParams(field) {
      if (this.currentSortParams.field === field) {
        this.currentSortParams.order = (this.currentSortParams.order === CONFIG.sort.sortUp)
          ? CONFIG.sort.sortDown
          : CONFIG.sort.sortUp;
      } else {
        this.currentSortParams.field = field;
        this.currentSortParams.order = CONFIG.sort.sortDown;
      }
    },
  },
};
</script>
<style scoped>
  .analytics-title {
    color: #2591cf;
    display: flex;
    font-size: 32px;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .an-table {
    position: relative;
    min-height: 120px;
  }

  .an-table-header,
  .an-table-rows {
    border-bottom: 1px solid #383b40;
    color: #ffffff;
    display: flex;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    padding: 5px 6px;
  }

  .an-table-header {
    font-weight: 700;
  }

  .an-table-header > div:not(:first-child),
  .an-table-rows > div:not(:first-child) {
    text-align: center;
  }

  .an-table-header > div:last-child,
  .an-table-header > div:nth-last-child(2),
  .an-table-rows > div:last-child,
  .an-table-rows > div:nth-last-child(2) {
    text-align: right;
  }

  .an-table-body {
    max-height: 650px;
    overflow-y: auto;
  }

  .an-th-title {
    width: 37%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .an-th-plays,
  .an-th-viewers,
  .an-th-avg,
  .an-th-total {
    width: 15%;
  }

  .an-th-filter {
    right: 0;
    width: 3%;
  }

  .empty-chart {
    color: #4c5057;
    padding-top: 40px;
    text-align: center;
  }

</style>
<style>
  .selected-filter-svg path {
    fill: #2591cf;
  }

  .single-media {
    display: none;
  }
</style>
