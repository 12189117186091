export const ADD_CUSTOM_FIELD_REQUEST = 'ADD_CUSTOM_FIELD_REQUEST';
export const ADD_CUSTOM_FIELD_SUCCESS = 'ADD_CUSTOM_FIELD_SUCCESS';
export const ADD_CUSTOM_FIELD_ERROR = 'ADD_CUSTOM_FIELD_ERROR';

export const GET_CUSTOM_FIELD_REQUEST = 'GET_CUSTOM_FIELD_REQUEST';
export const GET_CUSTOM_FIELD_SUCCESS = 'GET_CUSTOM_FIELD_SUCCESS';
export const GET_CUSTOM_FIELD_ERROR = 'GET_CUSTOM_FIELD_ERROR';

export const GET_CUSTOM_FIELDS_REQUEST = 'GET_CUSTOM_FIELDS_REQUEST';
export const GET_CUSTOM_FIELDS_SUCCESS = 'GET_CUSTOM_FIELDS_SUCCESS';
export const GET_CUSTOM_FIELDS_ERROR = 'GET_CUSTOM_FIELDS_ERROR';

export const EDIT_CUSTOM_FIELD_REQUEST = 'EDIT_CUSTOM_FIELD_REQUEST';
export const EDIT_CUSTOM_FIELD_SUCCESS = 'EDIT_CUSTOM_FIELD_SUCCESS';
export const EDIT_CUSTOM_FIELD_ERROR = 'EDIT_CUSTOM_FIELD_ERROR';

export const CHANGE_CUSTOM_FIELD_STATUS_REQUEST = 'CHANGE_CUSTOM_FIELD_STATUS_REQUEST';
export const CHANGE_CUSTOM_FIELD_STATUS_SUCCESS = 'CHANGE_CUSTOM_FIELD_STATUS_SUCCESS';
export const CHANGE_CUSTOM_FIELD_STATUS_ERROR = 'CHANGE_CUSTOM_FIELD_STATUS_ERROR';

export const GET_CUSTOM_FIELD_TYPES_REQUEST = 'GET_CUSTOM_FIELD_TYPES_REQUEST';
export const GET_CUSTOM_FIELD_TYPES_SUCCESS = 'GET_CUSTOM_FIELD_TYPES_SUCCESS';
export const GET_CUSTOM_FIELD_TYPES_ERROR = 'GET_CUSTOM_FIELD_TYPES_ERROR';

export const GET_CUSTOM_FIELD_CONTEXTS_REQUEST = 'GET_CUSTOM_FIELD_CONTEXTS_REQUEST';
export const GET_CUSTOM_FIELD_CONTEXTS_SUCCESS = 'GET_CUSTOM_FIELD_CONTEXTS_SUCCESS';
export const GET_CUSTOM_FIELD_CONTEXTS_ERROR = 'GET_CUSTOM_FIELD_CONTEXTS_ERROR';

export const GET_CUSTOM_FIELD_OPTIONS_REQUEST = 'GET_CUSTOM_FIELD_OPTIONS_REQUEST';
export const GET_CUSTOM_FIELD_OPTIONS_SUCCESS = 'GET_CUSTOM_FIELD_OPTIONS_SUCCESS';
export const GET_CUSTOM_FIELD_OPTIONS_ERROR = 'GET_CUSTOM_FIELD_OPTIONS_ERROR';

export const SET_CANCEL_TOKEN = 'SET_CANCEL_TOKEN';
