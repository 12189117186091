<template>
  <div class="data-source-block">
    <div class="bordered">
      <div class="wrapper main-form-container inner-bordered">
        <div class="title">
          {{ `${type === 'season' ? 'Season' : 'Playlist'}` }} Configurations
        </div>
        <div class="column column-1">
          <div
            v-if="formData.id"
            style="margin-bottom: 27px"
          >
            <FoldersManagement
              :selectedValue="formData.mediaFolder"
              v-bind="formFields.mediaFolder"
              :label="'Source Folder'"
              :required="true"
              :commonError="foldError"
              :onChange="onFoldersChange"
            />
          </div>
          <MaterialSelect
            v-model="formData.sortBy"
            v-bind="formFields.sortBy"
            :options="sortByOptions"
            :withEmptyField="false"
            :handleChange="changeSortBy"
          />
          <MaterialSelect
            v-model="formData.sortDirection"
            v-bind="formFields.sortDirection"
            :options="sortOrderOptions"
            :withEmptyField="false"
            :handleChange="changeSortOrder"
          />
          <MediaTagManagement
            v-if="typesOptions"
            :key="`includedTypes${formData.id}`"
            :selectedValue="formData.includeMediaTypes"
            :label="'Include Media Types'"
            :tagPlaceholder="''"
            :searchable="false"
            :onChange="onTagIncludedTypesChange"
            :customOptions="typesOptions"
            :isAudio="true"
          />
          <MediaTagManagement
            v-if="contentTypesOptions.length"
            :key="`includedTypes${formData.id}`"
            :selectedValue="formData.includeContentTypes"
            :label="'Include Content Types'"
            :tagPlaceholder="''"
            :searchable="false"
            :onChange="onTagContentTypesChange"
            :customOptions="contentTypesOptions"
            :isAudio="true"
          />
          <div class="toggle-wrapper">
            <div class="toggle-title">
              Sync with Source Folder
            </div>
            <ToggleButton
              v-model="formData.isSyncWithFolder"
              color="#2591CF"
              :sync="true"
              :labels="false"
              :disabled="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bordered">
      <div class="feeds-preview">
        <FeedResultsPreview
          :key="getKeyResults"
          :isNeedFixedItems="false"
          :mediaItems="mediaItems"
          :getMediaItems="getMediaItems"
          :isDynamicContent="true"
          :pagination="pagination.total"
          :dynamicContentLimit="formData.dynamicContentLimit"
          :defaultLength="defaultLength"
          :errorLiveEvents="errorLiveEvents"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FoldersManagement from '../../components/FoldersManagement.vue';
import MaterialSelect from '../../components/inputs/MaterialSelect.vue';
import MediaTagManagement from '../../components/media/MediaTagManagement.vue';
import FeedResultsPreview from '../../components/feed/FeedResultsPreview.vue';

export default {
  name: 'DataSourcePlaylist',
  components: {
    FeedResultsPreview,
    MediaTagManagement,
    MaterialSelect,
    FoldersManagement,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    formFields: {
      type: Object,
      default: () => {},
    },
    mediaItems: {
      type: Array,
      default: () => [],
    },
    getMediaItems: {
      type: Function,
      default: () => {},
    },
    clearFolderError: {
      type: Function,
      default: () => {},
    },
    pagination: {
      type: Number,
      default: 1,
    },
    errorLiveEvents: {
      type: Array,
      default: () => [],
    },
    sortByOptions: {
      type: Array,
      default: () => [],
    },
    sortOrderOptions: {
      type: Array,
      default: () => [],
    },
    typesOptions: {
      type: Array,
      default: () => [],
    },
    contentTypesOptions: {
      type: Array,
      default: () => [],
    },
    foldError: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultLength: 20,
      folderKey: 0,
      sortByKey: 0,
      sortOrderKey: 0,
    };
  },
  computed: {
    getKeyResults() {
      const {
        mediaFolder,
        includeMediaTypes,
        includeContentTypes,
        sortBy,
        sortDirection,
      } = this.formData;

      return mediaFolder
        + includeMediaTypes.length
        + includeContentTypes.length
        + sortBy
        + sortDirection
        + this.sortOrderKey
        + this.sortByKey
        + this.folderKey;
    },
  },
  methods: {
    onTagIncludedTypesChange(value) {
      this.formData.includeMediaTypes = value;
      this.getMediaItems(1);
    },
    onTagContentTypesChange(value) {
      this.formData.includeContentTypes = value;
      this.getMediaItems(1);
    },
    onFoldersChange(value) {
      this.formData.mediaFolder = value ? value.code : value;
      this.folderKey += 1;
      this.clearFolderError();
      this.getMediaItems(1);
    },
    changeSortBy() {
      this.sortByKey += 1;
      this.getMediaItems(1);
    },
    changeSortOrder() {
      this.sortOrderKey += 1;
      this.getMediaItems(1);
    },
  },
};
</script>

<style scoped>
.data-source-block {
  display: flex;
}

.bordered {
  border: 1px solid #383b40;
  height: calc(100vh - 270px);
  margin-right: 30px;
  width: 100%;
}

.inner-bordered {
  height: calc(100vh - 310px);
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
}

.bordered:last-child {
  margin-right: 0;
}

.toggle-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
  margin-top: 30px;
}

.toggle-title {
  margin-right: 10px;
}
</style>
