<template>
  <div>
    <div style="position: relative">
      <MaterialInput
        v-model="formData.countOfCache"
        v-bind="formFields.countOfCache"
      />
      <span class="info-cache">
        Best practice is to have a TTL value low
      </span>
    </div>
    <div class="radio-wrapper">
      <div class="radios">
        <div class="tab-title">
          Feed Format
        </div>
        <div class="radios-group">
          <div
            v-for="item in formData.feedFormats"
            id="group1"
            :key="item.code"
          >
            <MaterialRadio
              :label="item.name"
              :value="item.code"
              :formData="formData"
              :name="'group1'"
              :className="'radio-input'"
              :groupClass="'radio-itunes'"
              :isFeed="true"
              :isItunesType="false"
            />
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div
      v-if="isItunesFormat"
      class="itunes-wrapper"
    >
      <div>
        <div class="tab-title">
          iTunes File Types
        </div>
        <div class="radios-group itunes-types">
          <div
            v-for="item in formData.iTunesFileTypes"
            id="group3"
            :key="item.code"
          >
            <MaterialRadio
              :label="item.name"
              :value="item.code"
              :formData="formData"
              :name="'group3'"
              :className="'radio-input'"
              :groupClass="'radio-itunes'"
              :isFeed="true"
              :isItunesType="true"
            />
          </div>
          <div class="tooltip">
            <inline-svg
              :src="iconTooltip"
              class="svg-icon"
            >
            </inline-svg>
            <span class="tooltiptext">
              Select to add Supplemental Audio Assets with
              "Podcast Audio" Asset Role to the iTunes Feed
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="poster-title">
          iTunes Metadata
        </div>
        <div class="itunes-poster-recommendation">
          iTunes Cover Art 1400 x 1400 recommended
        </div>
        <PosterImage
          ref="posterImage"
          :key="formData.posterItunes"
          :poster="formData.posterItunes"
          :posterName="posterName"
          :isImagesOnly="true"
          :onPosterChange="handlePosterChange"
        />
        <div class="radios-group itunes-types radio-explicit">
          <div
            v-for="item in formData.explicitTypes"
            id="group2"
            :key="item.code"
          >
            <MaterialRadioExplicit
              v-if="isItunesFormat"
              :label="item.name"
              :value="item.code"
              :formData="formData"
              :className="'radio-input advanced-feed'"
              :name="'group2'"
              :groupClass="'radio-itunes'"
            />
          </div>
        </div>
      </div>
      <div class="selectors-block">
        <div class="keyword-block">
          <ItunesTagManagement
            :selectedValue="formData.keyWords"
            :label="'Keywords'"
            :tagPlaceholder="'Add this keyword'"
            :searchable="true"
            :onChange="onKeyWordsChange"
          />
        </div>
        <MaterialInput
          v-model="formData.iTunesOwnerEmail"
          v-bind="formFields.iTunesOwnerEmail"
        />
        <MaterialInput
          v-model="formData.iTunesOwnerName"
          v-bind="formFields.iTunesOwnerName"
        />
        <MaterialSelect
          v-model="formData.category"
          v-bind="formFields.category"
          :options="options"
          className="advanced-feed"
        />
        <MaterialSelect
          v-model="formData.subcategory"
          v-bind="formFields.subcategory"
          :options="optionsSubCat"
          :disabled="!formData.category || disabledSubCat"
          :title="disabledSubCat && 'No subcategories'"
          className="advanced-feed"
        />
        <MaterialInput
          v-model="formData.link"
          v-bind="formFields.link"
        />
        <MaterialInput
          v-model="formData.language"
          v-bind="formFields.language"
        />
        <MaterialInput
          v-model="formData.copyright"
          v-bind="formFields.copyright"
        />
        <MaterialInput
          v-model="formData.author"
          v-bind="formFields.author"
        />
        <MaterialSelect
          v-model="formData.type"
          v-bind="formFields.type"
          :options="formData.typeList"
          :withEmptyField="false"
          className="advanced-feed"
        />
      </div>
    </div>
    <div>
      <div class="tab-title">
        Feed security
      </div>
      <div>
        <div class="domain-checkbox">
          <MaterialCheckbox
            :toggle="toggleChangeDomain"
            :label="'Enable Domain Restriction'"
            :checked="formData.isEnabledDomain"
          />
        </div>
        <DomainTagManagement
          :key="`allowed${formData.id}`"
          :selectedValue="formData.allowedDomains"
          :label="'Allowed Domains'"
          :tagPlaceholder="'Add this domain'"
          :searchable="true"
          :onChange="onAllowedDomainChange"
          :disabled="!formData.isEnabledDomain"
          :errorMess="errorAllowed"
          :resetValidation="resetValidation"
        />
        <DomainTagManagement
          :key="`domain${formData.id}`"
          :selectedValue="formData.forbiddenDomains"
          :label="'Blacklisted Domains'"
          :tagPlaceholder="'Add this domain'"
          :searchable="true"
          :onChange="onBlockedDomainChange"
          :disabled="!formData.isEnabledDomain"
          :errorMess="errorBlocked"
          :resetValidation="resetValidation"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_ITUNES_CATERORIES_REQUEST,
  GET_ITUNES_SUBCATERORIES_REQUEST,
} from '../../store/actions/feedsActions/feedsActions';

import MaterialRadio from '../inputs/MaterialRadio.vue';
import BaseMaterialInput from '../inputs/BaseMaterialInput.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialCheckbox from '../inputs/MaterialCheckbox.vue';
import DomainTagManagement from './DomainTagManagement.vue';
import PosterImage from '../common/PosterImage/DragAndDropFileUploader.vue';
import ItunesTagManagement from './ItunesTagManagement.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';
import MaterialRadioExplicit from './FeedExplitRadioGroup.vue';

import iconTooltip from '../../assets/icons/icon-tooltip.svg';

export default {
  name: 'AdvancedFeed',
  components: {
    MaterialSelect,
    ItunesTagManagement,
    DomainTagManagement,
    MaterialRadio,
    MaterialInput,
    MaterialCheckbox,
    PosterImage,
    MaterialRadioExplicit,
  },
  extends: BaseMaterialInput,
  props: {
    handleResetValidation: {
      type: Function,
      default: () => {},
    },
    itemsErrors: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
    formFields: {
      type: Object,
      default: () => {},
    },
    isPosterItunesChanged: {
      type: Boolean,
      default: false,
      required: false,
    },
    removeSubcategories: {
      type: Function,
      default: () => {},
      required: false,
    },
    posterITunesChanged: {
      type: Function,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      fields: [
        this.formFields.feedFormats ? this.formFields.feedFormats.name : '',
        this.formFields.countOfCache ? this.formFields.countOfCache.name : '',
        this.formFields.allowedDomains ? this.formFields.allowedDomains.name : '',
        this.formFields.forbiddenDomains ? this.formFields.forbiddenDomains.name : '',
      ],
      options: [],
      optionsSub: [],
      subcategories: [],
      iconTooltip,
    };
  },
  computed: {
    isItunesFormat() {
      return this.formData.feedFormat === 'itunes';
    },
    optionsSubCat() {
      return this.subcategories.length
        && this.subcategories.find((item) => item.code === this.formData.category)
        && this.subcategories.find((item) => item.code === this.formData.category).name
          .map((i) => ({
            code: i.name,
            name: i.name,
          }));
    },
    disabledSubCat() {
      if (this.optionsSubCat
        && this.optionsSubCat.length === 1
        && this.optionsSubCat.map(((item) => !item.code))) {
        return true;
      }

      return false;
    },
    posterName() {
      const { posterItunes } = this.formData;
      return posterItunes.substring(posterItunes.lastIndexOf('/') + 1);
    },
    errorAllowed() {
      const key = 'feeds.allowedDomains';
      if (this.itemsErrors[key]
        && this.itemsErrors[key].length
      ) {
        return this.itemsErrors[key];
      }

      return null;
    },
    errorBlocked() {
      const key = 'feeds.forbiddenDomains';
      if (this.itemsErrors[key]
        && this.itemsErrors[key].length
      ) {
        return this.itemsErrors[key];
      }

      return null;
    },
  },
  created() {
    this.$store.dispatch(GET_ITUNES_CATERORIES_REQUEST)
      .then((res) => {
        this.options = res.data.map((item) => ({
          code: item.name,
          name: item.name,
        }));
      });

    this.$store.dispatch(GET_ITUNES_SUBCATERORIES_REQUEST)
      .then((res) => {
        this.subcategories = res.data;
      });
  },
  updated() {
    if (this.disabledSubCat) {
      this.removeSubcategories();
    }
  },
  methods: {
    handlePosterChange(img) {
      this.posterITunesChanged();
      this.formData.posterItunes = img;
    },
    onKeyWordsChange(value) {
      this.formData.keyWords = value;
    },
    toggleChangeDomain(isChecked) {
      if (isChecked !== this.formData.isEnabledDomain) {
        this.formData.isEnabledDomain = isChecked ? 1 : 0;
      }
    },
    onAllowedDomainChange(value) {
      this.formData.allowedDomains = value;
    },
    onBlockedDomainChange(value) {
      this.formData.forbiddenDomains = value;
    },
    resetValidation() {
      this.handleResetValidation();
    },
    hasErrors(errors) {
      let hasErrors = false;
      for (let i = 0; i < this.fields.length; i += 1) {
        if (errors[this.fields[i]] && errors[this.fields[i]].length) {
          hasErrors = true;
        }
      }

      return Boolean(hasErrors || this.errorAllowed || this.errorBlocked);
    },
    onFileInput() {
      this.$refs.fileInput.click();
    },
  },
};
</script>

<style scoped>
  .radios-group {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  .itunes-types {
    position: relative;
    width: 44%;
  }

  .radio-wrapper {
    padding-top: 30px;
  }

  .radios {
    display: flex;
    flex-direction: column;
  }

  .tab-title {
    padding-bottom: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .domain-checkbox {
    padding-bottom: 20px;
  }

  .poster-title {
    color: #fff;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .itunes-wrapper {
    margin-bottom: 20px;
  }

  .keyword-block {
    margin-bottom: 20px;
  }

  .radio-explicit {
    margin-top: 20px;
    width: 48%;
  }

  .radio-input {
    margin-left: 10px;
  }

  .info-cache {
    position: absolute;
    top: 80px;
    color: #828282;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .radio-itunes {
    display: flex;
    flex-direction: row-reverse;
  }

  hr {
    border: 1px solid #383B40;
    display: block;
    flex: 1 1 0;
    height: 0;
    max-height: 0;
    max-width: 100%;
    transition: inherit;
    margin-bottom: 25px;
  }

  .tooltip {
    position: absolute;
    display: inline-block;
    top: 1px;
    left: 104%;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 220px;
    top: 100%;
    left: 50%;
    margin-left: -90px;
    background-color: #ffffff;
    color: #000000;
    padding: 4px 6px;
    border-radius: 1px;
    font-size: 13px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .itunes-poster-recommendation {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #828282;
    padding-bottom: 10px;
  }
</style>
