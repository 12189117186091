<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="flex">
      <div class="right-path">
        <div class="left-path">
          <span class="system">
            Video Apps Engine
          </span>
          <span>
            / Navigation
          </span>
        </div>
      </div>
      <Button
        v-if="access"
        :title="'Add Navigation Item'"
        :classType="'primary'"
        :eventFunction="toCreate"
      />
    </div>
    <div
      v-if="getCurrentAccount.hasVideoAppsEngine && access"
      class="accounts-wrapper"
    >
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          navigation item page
        </div>
        <div class="th-name date">
          Last edited
        </div>
        <div class="th-name users-count">
          Modified by
        </div>
      </div>
      <div
        v-if="!navs.length"
        class="empty-search"
      >
        No items to display
      </div>
      <draggable
        v-model="navs"
        :move="checkMove"
        @start="dragging = true"
        @end="endOfDrag"
      >
        <transition-group>
          <div
            v-for="(item) in navs"
            :key="item.id"
            class="account"
            @mouseover="onHoverElem(item)"
            @mouseleave="isHover = false"
          >
            <div class="td number">
              {{ item.sortOrder }}
            </div>
            <div
              class="td company"
              :title="item.title"
            >
              {{ item.title }}
            </div>
            <div class="td date">
              <span v-if="item.updatedAt">
                {{ item.updatedAt | cmsDate(profile.timezone) }}
              </span>
              <span v-else>
                -
              </span>
            </div>
            <div class="td users-count">
              {{ item.updatedBy ? item.updatedBy.fullName : '-' }}
            </div>
            <div
              v-show="isHover && item.id === selectedId"
              class="icons-wrapper"
            >
              <div :title="'Delete Navigation Item'">
                <inline-svg
                  :src="iconRemove"
                  class="svg-icon remove"
                  @click="onOpenModalForRemove(item)"
                >
                </inline-svg>
              </div>
              <div class="icon-edit">
                <router-link
                  :to="{ path: `navigation/${item.id}` }"
                  :title="'Edit Navigation Item'"
                >
                  <inline-svg
                    :src="iconEdit"
                    class="svg-icon"
                  >
                  </inline-svg>
                </router-link>
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
    <div
      v-if="!getCurrentAccount.hasVideoAppsEngine && getCurrentAccount.tvAppsEnabled"
      class="not-access"
    >
      <div>
        {{ checkboxEnabledText }}
      </div>
    </div>
    <div
      v-if="getCurrentAccount.hasVideoAppsEngine && !access"
      class="not-access"
    >
      <div>
        {{ defaultScenario }}
      </div>
    </div>
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="'Delete Navigation Item'"
      :onAccept="onRemove"
      :onClose="onClose"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import draggable from 'vuedraggable';
import Loader from '../../../components/common/Loader.vue';
import iconEnabled from '../../../assets/icons/icon-enabled-acc.svg';
import iconDisabled from '../../../assets/icons/icon-disabled-acc.svg';
import iconEdit from '../../../assets/icons/icon-edit-acc.svg';
import iconFix from '../../../assets/icons/icon-fix.svg';
import NotificationModal from '../../../components/NotificationModal.vue';
import Button from '../../../components/common/Button.vue';
import CONFIG from '../../../constants/config';
import {
  EDIT_NAVIGATION_ORDER_REQUEST,
  GET_NAVIGATION_LIST_REQUEST,
  REMOVE_NAVIGATION_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';
import iconRemove from '../../../assets/icons/icon-remove-role.svg';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../../store/actions/common';

export default {
  name: 'NavigationList',
  components: {
    Loader,
    NotificationModal,
    Button,
    draggable,
  },
  data() {
    const {
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editSlider,
      removeSlider,
    } = CONFIG.tooltips;

    return {
      checkboxEnabledText: 'This module is not activated for your account. Please contact your account representative for assistance.',
      defaultScenario: 'Your user account does not have permission to access this module. Please contact your account administrator for assistance.',
      paymentStatuses: CONFIG.paymentStatuses,
      enabledId: null,
      akamaiAccountCreateId: null,
      akamaiAppSyncId: null,
      isEnabled: false,
      isModalOpen: false,
      isHover: false,
      selectedId: null,
      bodyMessage: '',
      filter: '',
      sortedField: {
        name: 'name',
        industry: 'industry',
        enabled: 'enabled',
      },
      navs: [],
      currentSortedField: 'a.name',
      currentSortBy: 'ASC',
      isSortedUp: false,
      isSortedDown: false,
      isSliderOpen: false,
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editSlider,
      removeSlider,
      iconEnabled,
      iconEdit,
      iconDisabled,
      iconFix,
      iconRemove,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
    ]),
    ...mapState({
      navigations: (state) => state.cms.navigationList,
      isLoading: (state) => state.cms.isLoading,
      profile: (state) => state.common.profile,
    }),
    access() {
      return this.getCurrentAccount
        && this.getCurrentAccount.hasVideoAppsEngine
        && this.profile.userAccountRoles
          .find((item) => item.account.id === this.getCurrentAccountId).role !== 'ROLE_MANAGER';
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    this.getNavigation();
  },
  methods: {
    checkMove() {
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    endOfDrag() {
      this.dragging = false;

      const params = {
        accountId: this.getCurrentAccountId,
        data: {
          sortOrder: this.navs.map((item) => item.id),
        },
      };
      this.$store.dispatch(EDIT_NAVIGATION_ORDER_REQUEST, params).then(() => {
        this.getNavigation();
        this.$toasted.global.success({
          message: 'Navigation Item order has been successfully updated',
        });
      });
    },
    toCreate() {
      this.$router.push('/cms/navigation/add');
    },
    onManuallyClose() {
      this.isSliderOpen = false;
    },
    onSubmitManually() {
      this.onManuallyClose();
      this.getNavigation();
    },
    getNavigation() {
      const params = {
        accountId: this.getCurrentAccountId,
      };
      this.$store.dispatch(GET_NAVIGATION_LIST_REQUEST, params).then((res) => {
        this.navs = res.data;
      });
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    onClose() {
      this.isModalOpen = false;
    },
    onOpenModalForRemove(item) {
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.enabledId = item.id;
      this.bodyMessage = 'Are you sure you want to delete this Navigation Item?';
    },
    onRemove() {
      const params = {
        accountId: this.getCurrentAccountId,
        navigationId: this.enabledId,
      };

      this.$store.dispatch(REMOVE_NAVIGATION_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Navigation Item has been successfully deleted',
          });

          this.getNavigation();
        })
        .catch((err) => {
          if (err.status === CONFIG.statuses.failedStatus) {
            this.$toasted.global.error({
              message: CONFIG.errorMessages.commonServerError,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.flex {
  align-items: center;
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 40px;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px 25px 25px 30px;
  width: 100%;
}

.system {
  color: #4c5057;
}

.accounts-wrapper {
  border: 1px solid #43434d;
  height: 100%;
  overflow-y: auto;
}

.ac-wrapper-header {
  background: #282a2e;
  display: flex;
  padding: 13px 0;
}

.th-name {
  align-items: center;
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
}

.td {
  font-size: 14px;
  padding: 15px 0;
  word-break: break-word;
}

.number {
  padding-left: 21px;
  width: 50px;
}

.company {
  padding-right: 15px;
  width: 20%;
}

.users-count {
  width: 15%;
}

.status {
  align-items: flex-start;
  display: flex;
  width: 11%;
}

.date {
  align-items: flex-start;
  display: flex;
  width: 25%;
}

.account {
  display: flex;
}

.format {
  text-transform: capitalize;
}

.status-ellipse {
  border-radius: 50%;
  height: 5px;
  margin-right: 6px;
  margin-top: 6px;
  width: 5px;
}

.status-ellipse.enabled,
.status-ellipse.active {
  background: #1fee65;
}

.status-ellipse.disabled,
.status-ellipse.blocked,
.status-ellipse.pending {
  background: #fb3951;
}

.status-ellipse.failed {
  background: #fbf650;
}

.icons-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  flex: 0.95;
}

.icons-wrapper div {
  padding: 10px;
}

.create-button {
  background: #2591cf;
  color: #fff;
  font-size: 16px;
  letter-spacing: normal;
  padding: 0 16px;
  text-transform: none;
}

.right-path {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.empty-search {
  padding-top: 40px;
  text-align: center;
}

.btn-create-account-mobile {
  display: none;
}

.svg-icon {
  cursor: pointer;
}

.not-access {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
}

.account:hover {
  background: #383b40;
  cursor: move;
}

@media all and (max-width: 1400px) {
  .number {
    width: 30px;
  }

  .status {
    width: 9%;
  }

}

@media all and (max-width: 1170px) {
  .status {
    width: 9.5%;
  }
}

@media all and (max-width: 1100px) {
  .icons-wrapper div {
    padding: 10px 5px;
  }
}

@media all and (max-width: 1024px) {
  .btn-create-account-mobile {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    min-height: 40px;
  }

  .search-wrapper {
    margin-right: 0;
  }
}

@media all and (max-width: 900px) {
  .company {
    padding-right: 7px;
    width: 14%;
  }

  .number {
    width: 24px;
  }

  .status {
    width: 10%;
  }
}
</style>
