<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="title modal">
        {{ isEdit ? 'Edit Row Item' : 'Add Row Item' }}
      </div>
      <MaterialInput
        v-model="formData.label"
        v-bind="formFields.label"
      />
      <div :class="['select-wrapper']">
        <MaterialSelect
          v-model="formData.itemType"
          v-bind="formFields.itemType"
          :options="optionsItemType"
          :withEmptyField="false"
        />
      </div>
      <div
        v-if="formData.itemType === 'page_link'"
        class="select-wrapper"
      >
        <MaterialSelect
          v-model="formData.pageTarget"
          v-bind="formFields.pageTarget"
          :options="subpages"
          :withEmptyField="false"
        />
      </div>
      <div
        v-if="formData.itemType === 'playlist_link'"
        style="margin-bottom: 20px"
      >
        <MaterialSelect
          v-model="formData.folderId"
          v-bind="formFields.folderId"
          :label="'Target'"
          :required="true"
          :options="playlistsOptions"
          :withEmptyField="false"
        />
      </div>
      <div>
        <div class="poster-title">
          Poster Image
        </div>
        <PosterImage
          ref="posterImage"
          :key="formData.poster"
          :poster="formData.poster"
          :posterName="posterName"
          :onPosterChange="handlePosterChange"
        />
      </div>
      <div class="wrapper-small">
        <div class="poster-title">
          Background Image
        </div>
        <PosterImage
          ref="posterImage"
          :key="formData.background"
          :poster="formData.background"
          :posterName="posterNameBackground"
          :onPosterChange="handlePosterBackChange"
          :typeOfPerson="'large'"
        />
      </div>
      <div
        v-if="enabledLanguages.length"
        style="margin-top: 20px"
      >
        <Button
          :title="'Localization'"
          :classType="'primary'"
          :srcSvg="iconFolderLocalization"
          :svgClass="'folder-localization-icon'"
          :eventFunction="toLocalization"
          :isReverseIcon="true"
          :disabled="!isEdit"
        />
      </div>
      <div
        v-if="!isEdit && enabledLanguages.length"
        class="localization-warning-wrapper"
      >
        <inline-svg
          :src="iconWarning"
          class="svg-icon"
        >
        </inline-svg>
        <span class="localization-warning">
          Localization will be available after the Row Item has been saved
        </span>
      </div>
      <div :class="[isEdit ? 'edit-button-wrapper' : 'end-buttons']">
        <Button
          v-if="isEdit"
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="onOpenModal"
        />
        <div style="display: flex">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="backToManually"
          />
          <Button
            :title="isEdit ? 'Apply' : 'Add'"
            :classType="'primary'"
            :type="'submit'"
          />
        </div>
      </div>
      <NotificationModal
        ref="modal"
        v-model="isModalOpen"
        :modal="isModalOpen"
        :bodyMessage="bodyMessage"
        :title="'Delete Row Item'"
        :onAccept="removeManuallyRow"
        :onClose="onManuallyClose"
      />
      <CmsManuallyLocalization
        v-if="enabledLanguages.length && manuallyItem.id"
        v-model="isOpenLocalization"
        :languages="enabledLanguages"
        :modal="isOpenLocalization"
        :manuallyItem="manuallyItem"
        :onClose="onCloseLocalizationModal"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import PosterImage from '../../common/PosterImage/DragAndDropFileUploader.vue';
import NotificationModal from '../../NotificationModal.vue';
import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';
import iconWarning from '../../../assets/icons/icon-warning-localization.svg';

import {
  CREATE_MANUALLY_ITEM_REQUEST,
  EDIT_MANUALLY_ITEM_REQUEST,
  GET_MANUALLY_ITEM_REQUEST,
  GET_MANUALLY_LIST_REQUEST,
  GET_SUBPAGES_REQUEST,
  REMOVE_MANUALLY_ITEM_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';
import {
  SET_EDIT_FORM,
  SET_OVERFLOW,
} from '../../../store/actions/common';
import { GET_LANGUAGES_REQUEST } from '../../../store/actions/localization/localizationActions';
import CmsManuallyLocalization from '../../localization/CmsManuallyLocalization.vue';
import { GET_PLAYLISTS_CMS_REQUEST } from '../../../store/actions/playlist/playlist';

export default {
  name: 'ManuallyForm',
  components: {
    CmsManuallyLocalization,
    MaterialSelect,
    MaterialInput,
    Button,
    PosterImage,
    NotificationModal,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'manuallyForm',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    openedLocalization: {
      type: Function,
      default: () => {},
    },
    onSubmitManually: {
      type: Function,
      default: () => {},
    },
    isEdItItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      playlistsOptions: [],
      optionsItemType: [
        {
          code: 'page_link',
          name: 'Page Link',
        },
        {
          code: 'playlist_link',
          name: 'Playlist Detail',
        },
      ],
      iconWarning,
      manuallyItem: {},
      iconFolderLocalization,
      isOpenLocalization: false,
      enabledLanguages: [],
      isModalOpen: false,
      bodyMessage: CONFIG.modals.deleteManuallyRowInCms,
      rowItemsList: [],
      cmsTabsList: {},
      isPosterChanged: false,
      isBackgroundChanged: false,
      foldError: '',
      formData: {
        label: '',
        itemType: '',
        pageTarget: '',
        poster: '',
        background: '',
        folderId: '',
        sortOrder: 0,
      },
      formFields: {
        label: {
          type: 'text',
          name: 'label',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 25,
          },
        },
        itemType: {
          type: 'text',
          name: 'itemType',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        pageTarget: {
          type: 'text',
          name: 'pageTarget',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        folderId: {
          type: 'text',
          name: 'folderId',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 25,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        folderId: [
          `${this.formPrefix}.targetPlaylist`,
        ],
        pageTarget: [
          `${this.formPrefix}.targetPage`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_MANUALLY_ITEM_REQUEST : CREATE_MANUALLY_ITEM_REQUEST,
      successMessage: this.isEdit
        ? 'The Row Item has been successfully updated'
        : 'The Row Item has been successfully added',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    ...mapState({
      subpages: (state) => state.cms.subpages.map((item) => ({
        code: item.id,
        name: item.name,
      })),
    }),
    posterName() {
      const { poster } = this.formData;
      return poster ? poster.substring(poster.lastIndexOf('/') + 1) : '';
    },
    posterNameBackground() {
      const { background } = this.formData;
      return background ? background.substring(background.lastIndexOf('/') + 1) : '';
    },
  },
  mounted() {
    const paramsGet = {
      accountId: this.getCurrentAccountId,
    };
    this.$store.dispatch(GET_SUBPAGES_REQUEST, paramsGet);
    this.$store.dispatch(GET_PLAYLISTS_CMS_REQUEST, paramsGet).then((res) => {
      this.playlistsOptions = res.data.map((item) => ({
        name: `${item.title} (${item.id})`,
        code: item.id,
      }));
    });

    const paramsGetList = {
      accountId: this.getCurrentAccountId,
      cmsRowId: this.$route.params.rowId,
    };
    this.$store.dispatch(GET_MANUALLY_LIST_REQUEST, paramsGetList).then((res) => {
      this.rowItemsList = res.data;
    });

    const paramsLang = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };
    this.$store.dispatch(GET_LANGUAGES_REQUEST, paramsLang).then((res) => {
      this.enabledLanguages = res.data;
    });

    if (this.isEdit && this.isEdItItem.id) {
      const params = {
        accountId: this.getCurrentAccountId,
        cmsRowItemsId: this.isEdItItem.id,
      };

      this.$store.dispatch(GET_MANUALLY_ITEM_REQUEST, params)
        .then((res) => this.initFormData(res))
        .catch((err) => {
          if (err.status === 404) {
            this.$router.push(`/cms/${this.$route.params.id}`);
          }
        });
    }
  },
  methods: {
    initFormData(item) {
      this.manuallyItem = item;
      this.formData.label = item.label;
      this.formData.itemType = item.type;
      this.formData.sortOrder = item.sortOrder;
      this.formData.poster = item.posterImage ? `${item.posterImage}?height=360` : '';
      this.formData.background = item.backgroundImage ? `${item.backgroundImage}?height=360` : '';

      if (item.type === 'playlist_link') {
        this.formData.folderId = item.targetPlaylist.id;
      } else {
        this.formData.pageTarget = item.targetPage.id;
      }
    },
    onCloseLocalizationModal() {
      this.isOpenLocalization = false;
      this.$emit('openedLocalization', false);
    },
    toLocalization() {
      this.isOpenLocalization = true;
      this.$emit('openedLocalization', true);
    },
    handleRecorderToggle() {
      if (!this.formData.isToggleRecord) {
        this.isRecorderToggleOpen = true;
      }
    },
    handlePosterChange(img) {
      this.isPosterChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.poster = img;
    },
    handlePosterBackChange(img) {
      this.isBackgroundChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.background = img;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    backToManually() {
      this.onClose();
    },
    onManuallyClose() {
      this.isModalOpen = false;
    },
    onOpenModal() {
      this.isModalOpen = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    onDeleteClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onFoldersChange(value) {
      this.formData.folderId = value ? value.code : value;
      this.foldError = '';
    },
    removeManuallyRow() {
      const params = {
        accountId: this.getCurrentAccountId,
        cmsRowItemsId: this.isEdItItem.id,
      };

      this.$store.dispatch(REMOVE_MANUALLY_ITEM_REQUEST, params)
        .then(() => {
          this.onSubmitManually();
          this.$toasted.global.success({
            message: 'The Row Item has been successfully deleted',
          });
        });
    },
    getRequestData() {
      const data = {
        row: this.$route.params.rowId,
        label: this.formData.label,
        type: this.formData.itemType,
        posterImage: this.formData.poster,
        backgroundImage: this.formData.background,
        targetPage: this.formData.pageTarget,
        sortOrder: this.rowItemsList.length ? this.rowItemsList.length + 1 : 1,
      };

      if (this.isEdit) {
        data.sortOrder = this.formData.sortOrder;
      }

      if (!this.isPosterChanged) {
        delete data.posterImage;
      }

      if (!this.isBackgroundChanged) {
        delete data.backgroundImage;
      }

      if (this.formData.itemType === 'playlist_link') {
        delete data.targetPage;
        data.targetPlaylist = this.formData.folderId;
      }

      return {
        data,
        accountId: this.getCurrentAccountId,
        cmsRowItemsId: this.isEdItItem && this.isEdItItem.id,
      };
    },
    onSubmitSuccess() {
      this.isPosterChanged = false;
      this.isBackgroundChanged = false;
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.onSubmitManually();
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      const folderError = error.form_errors
        && error.form_errors.children
        && error.form_errors.children.targetPlaylist
        && error.form_errors.children.targetPlaylist.errors
        && error.form_errors.children.targetPlaylist.errors[0].error;

      if (folderError) {
        this.foldError = folderError === 'NOT_SYNCHRONIZED_ERROR'
          ? 'The ID entered does not exist'
          : 'This field is required';
      }

      if ((error.form_errors && error.form_errors.children.row) || error.status === 404) {
        this.$router.push(`/cms/${this.$route.params.id}`);
        return;
      }

      if (formErrors.pageTarget) {
        formErrors.pageTarget[0] = 'This value is already used as a Navigation Item';
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>

.rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.toggles-wrapper {
  display: flex;
}

.toggle-wrapper,
.date-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
}

.toggle-wrapper {
  width: auto;
}

.toggle-title {
  margin-right: 10px;
}

.dynamic {
  margin-top: 30px;
  margin-bottom: 30px;
}

.edit-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.end-buttons {
  display: flex;
  margin-top: 35px;
  justify-content: flex-end;
}

.country-field {
  width: 300px;
}

.country-field:not(:last-child) {
  margin-right: 20px;
}

.wowza p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.wrapper-small {
  margin-top: 20px;
}

.flex {
  justify-content: space-between;
}

.person {
  margin-bottom: 20px;
}

.person p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.select-wrapper {
  width: 100%;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.localization-warning {
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}

.modal {
  color: inherit;
  margin-bottom: 30px;
}

.select-wrapper-folder {
  width: 100%;
}

.rows-folder {
  display: block;
}

@media all and (max-width: 1320px) {
  .flex {
    flex-wrap: wrap;
  }

  .country-field {
    margin-right: 0;
    width: 100%;
  }

  .country-field:not(:last-child) {
    margin-right: 0;
  }
}
</style>
