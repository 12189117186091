<template>
  <div class="form-container">
    <Loader :isVisible="isLoading" />
    <div class="left-path">
      <span class="system">
        Video Apps Engine / Pages
      </span>
      <span>
        / Edit {{ cmsPage.name }}
      </span>
    </div>
    <div class="title">
      Page Editor
    </div>
    <div>
      <ValidationObserver
        ref="validationObserver"
        v-slot="{ handleSubmit }"
      >
        <form
          class="contact-info"
          novalidate="novalidate"
          @submit.prevent="handleSubmit(submit)"
          @input="handleFormChange"
        >
          <div class="edit-button-wrapper">
            <Button
              :title="'Delete'"
              :classType="'transparent'"
              :eventFunction="deletePage"
              :disabled="cmsPage.distribution"
            />
            <Button
              :title="'Cancel'"
              :classType="'warning'"
              :eventFunction="backToCms"
            />
            <Button
              :title="'Apply'"
              :classType="'primary'"
              :type="'submit'"
            />
          </div>
          <Tabs
            :key="mode"
            :onSelectTab="handleSelectTab"
            :disabledTab="(!hasFeed && !hasFolder && !hasCategories) ? 'pageTabs': ''"
          >
            <Tab
              :id="'general'"
              name="General"
              :selected="mode === 'general'"
            >
              <div class="wrapper main-form-container">
                <div
                  v-if="cmsPage && cmsPage.id"
                  class="column column-1"
                >
                  <PageForm
                    :isEdit="!!$route.params.id"
                    :formData="formData"
                    :formFields="formFields"
                    :cmsPage="cmsPage"
                    :enabledLanguages="enabledLanguages"
                  />
                </div>
              </div>
            </Tab>
            <Tab
              v-if="cmsPage.format === 'rows'"
              :id="'layout'"
              name="Layout & Data"
              :selected="mode === 'layout'"
            >
              <RowsByPage
                :orderRows="formData.orderRows"
                @dragged="onEndOfDraggingRows"
              />
            </Tab>
            <Tab
              v-if="cmsPage.format === 'grid'"
              :id="'dataSource'"
              name="Data Source"
              :selected="mode === 'dataSource'"
              :className="[error ? 'error-tab' : 'tab']"
            >
              <GridDataSource
                ref="gridDataSource"
                :sortOptions="sortOptions"
                :groupOptions="groupOptions"
                :optionsContentType="optionsContentType"
                :formData="formData"
                :foldError="foldError"
                :formFields="formFields"
                :hasFeed="hasFeed"
                :hasFolder="hasFolder"
                :hasCategories="hasCategories"
                :categoriesError="categoriesError"
              />
            </Tab>
            <Tab
              v-if="cmsPage.format === 'grid'"
              :id="'pageTabs'"
              name="Page Tabs"
              :selected="mode === 'pageTabs'"
              :disabled="true"
            >
              <GridTabsByPage
                :orderTabs="formData.orderTabs"
                @dragged="onEndOfDraggingTabs"
              />
            </Tab>
          </Tabs>
        </form>
      </validationobserver>
      <NotificationModal
        ref="modal"
        v-model="isModalOpenDelete"
        :modal="isModalOpenDelete"
        :bodyMessage="bodyMessage"
        :title="'Delete Page'"
        :onAccept="removeCmsPage"
        :onClose="onCloseDelete"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CONFIG from '../../constants/config';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../store/actions/common';
import {
  EDIT_CMS_PAGE_REQUEST,
  GET_PAGE_REQUEST,
  REMOVE_PAGE_REQUEST,
} from '../../store/actions/cmsActions/cmsActions';

import BaseForm from '../../components/forms/BaseForm.vue';
import Loader from '../../components/common/Loader.vue';
import PageForm from '../../components/forms/cms/PageForm.vue';
import Tabs from '../../components/common/Tabs/Tabs.vue';
import Tab from '../../components/common/Tabs/Tab.vue';
import RowsByPage from './RowsByPage.vue';
import Button from '../../components/common/Button.vue';
import GridDataSource from './GridDataSource.vue';
import GridTabsByPage from './GridTabsByPage.vue';
import NotificationModal from '../../components/NotificationModal.vue';
import { GET_LANGUAGES_REQUEST } from '../../store/actions/localization/localizationActions';

export default {
  name: 'CMSPage',
  components: {
    GridTabsByPage,
    GridDataSource,
    NotificationModal,
    RowsByPage,
    Tab,
    Tabs,
    PageForm,
    Loader,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'cmsPage',
    },
  },
  data() {
    return {
      cmsPage: {},
      mode: sessionStorage.getItem('lastOpenedCMSTab') || 'general',
      sortOptions: [
        {
          code: 'chronological',
          name: 'Chronological',
        },
        {
          code: 'reverse_chronological',
          name: 'Reverse Chronological',
        },
        {
          code: 'alphabetical',
          name: 'Alphabetical',
        },
      ],
      groupContents: [
        {
          code: 'grouped',
          name: 'Grouped',
        },
        {
          code: 'ungrouped',
          name: 'Ungrouped',
        },
        {
          code: 'none',
          name: 'None',
        },
      ],
      groupCategories: [
        {
          code: 'grouped',
          name: 'Grouped',
        },
        {
          code: 'none',
          name: 'None',
        },
      ],
      optionsContentType: [
        {
          code: 'feed',
          name: 'Feed',
        },
        {
          code: 'folder',
          name: 'Folder',
        },
        {
          code: 'categories',
          name: 'Categories',
        },
      ],
      error: null,
      enabledLanguages: [],
      isModalOpenDelete: false,
      bodyMessage: CONFIG.modals.deleteCmsPage,
      hasFeed: '',
      hasFolder: '',
      hasCategories: '',
      foldError: '',
      formData: {
        id: '',
        name: '',
        label: '',
        format: '',
        headerType: 'none',
        isShowTabs: false,
        isShowItemTitles: false,
        contentType: 'feed',
        mediaFolder: '',
        feed: '',
        sortOption: 'chronological',
        groupContent: 'grouped',
        eventGroup: '',
        slider: '',
        distribution: false,
        orderRows: [],
        orderTabs: [],
        categories: [],
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 25,
          },
        },
        label: {
          type: 'text',
          name: 'label',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 25,
          },
        },
        headerType: {
          type: 'text',
          name: 'headerType',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        slider: {
          type: 'text',
          name: 'slider',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        eventGroup: {
          type: 'text',
          name: 'eventGroup',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        contentType: {
          type: 'text',
          name: 'contentType',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        feed: {
          type: 'text',
          name: 'feed',
          formPrefix: this.formPrefix,
          rules: {
            max: 25,
          },
        },
        mediaFolder: {
          type: 'text',
          name: 'mediaFolder',
          formPrefix: this.formPrefix,
          rules: {
            max: 25,
          },
        },
        categories: {
          name: 'categories',
          formPrefix: this.formPrefix,
          rules: {
            length: 1,
            required: true,
          },
        },
        sortOption: {
          type: 'text',
          name: 'sortOption',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        groupContent: {
          type: 'text',
          name: 'groupContent',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        label: [
          `${this.formPrefix}.label`,
        ],
        mediaFolder: [
          `${this.formPrefix}.grid.mediaFolder`,
        ],
        categories: [
          `${this.formPrefix}.grid.categories`,
        ],
        feed: [
          `${this.formPrefix}.grid.feed`,
        ],
      },
      submitEvent: EDIT_CMS_PAGE_REQUEST,
      successMessage: CONFIG.successMessages.editCmsPageMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
      cachedGroupContent: {
        default: 'grouped',
        folder: '',
        categories: '',
      },
      cachedSortOptions: {
        default: 'chronological',
        folder: '',
        categories: '',
      },
      groupOptions: [],
      categoriesError: '',
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    ...mapState({
      isLoading: (state) => state.cms.isLoading,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
  },
  watch: {
    'formData.contentType': {
      handler: 'handleDataSourceChange',
      deep: true,
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  mounted() {
    const paramsLang = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };
    this.$store.dispatch(GET_LANGUAGES_REQUEST, paramsLang).then((res) => {
      this.enabledLanguages = res.data;
    });

    const params = {
      accountId: this.getCurrentAccountId,
      cmsPageId: this.$route.params.id,
    };
    this.$store.dispatch(GET_PAGE_REQUEST, params).then((res) => {
      this.cmsPage = res;
      this.initFormData(res);
    });
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
    sessionStorage.removeItem('lastOpenedCMSTab');
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  methods: {
    initFormData(item) {
      this.formData.id = item.id;
      this.formData.name = item.name;
      this.formData.label = item.label;
      this.formData.format = this.capitalizeFirstLetter(item.format);
      this.formData.headerType = item.headerType;
      this.formData.distribution = item.distribution;

      if (this.cmsPage.format === 'grid') {
        this.formData.isShowTabs = item.showTabs === null ? true : item.showTabs;
        this.formData.isShowItemTitles = item.showItemTitles === null ? true : item.showItemTitles;
        if (item && item.grid) {
          if (item.grid.dataSource === 'feed') {
            this.formData.contentType = item.grid.dataSource;
            this.formData.feed = item.grid.feed.id;
            this.hasFeed = item.grid.feed.id;
          }

          if (item.grid.dataSource === 'folder') {
            this.cachedGroupContent.folder = item.grid.groupContent;
            this.cachedSortOptions.folder = item.grid.sortOption;
            this.formData.contentType = item.grid.dataSource;
            this.formData.mediaFolder = item.grid.mediaFolder.id;
            this.hasFolder = item.grid.mediaFolder.id;
            this.formData.sortOption = item.grid.sortOption;
            this.formData.groupContent = item.grid.groupContent;
          }

          if (item.grid.dataSource === 'categories') {
            this.cachedGroupContent.categories = item.grid.groupContent;
            this.cachedSortOptions.categories = item.grid.sortOption;
            this.formData.contentType = item.grid.dataSource;
            this.formData.categories = item.grid.categories;
            this.hasCategories = item.grid.categories?.[0]?.id;
            this.formData.sortOption = item.grid.sortOption;
            this.formData.groupContent = item.grid.groupContent;
          }
        }
      }

      if (this.cmsPage.format === 'live') {
        this.formData.eventGroup = item.standaloneEventGroup.id;
      }

      if (this.cmsPage.format === 'rows') {
        this.formData.slider = item.slider && item.slider.id;
      }
    },
    hasGridInfoError(errors) {
      return this.$refs.gridDataSource && this.$refs.gridDataSource.hasErrors(errors);
    },
    capitalizeFirstLetter(string) {
      if (string === 'live') {
        return 'Live Countdown/Player';
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    backToCms() {
      this.$router.push('/cms/pages');
    },
    onEndOfDraggingRows(value) {
      this.formData.orderRows = value.map((item) => item.id);
    },
    onEndOfDraggingTabs(value) {
      this.formData.orderTabs = value.map((item) => item.id);
    },
    handleSelectTab(id) {
      this.mode = id;
      sessionStorage.setItem('lastOpenedCMSTab', this.mode);
    },
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
      this.error = null;
    },
    handleDataSourceChange(value) {
      if (value === 'categories') {
        let groupContent = this.cachedGroupContent.categories;
        const isGroupContentInList = this.groupCategories
          .some((item) => item.code === groupContent);
        if (!isGroupContentInList) {
          groupContent = this.cachedGroupContent.default;
        }

        this.groupOptions = this.groupCategories;
        this.formData.groupContent = groupContent;
        this.formData.sortOption = this.cachedSortOptions.categories
          || this.cachedSortOptions.default;
      } else if (value === 'folder') {
        this.groupOptions = this.groupContents;
        this.formData.groupContent = this.cachedGroupContent.folder
          || this.cachedGroupContent.default;
        this.formData.sortOption = this.cachedSortOptions.folder
          || this.cachedSortOptions.default;
      }
    },
    deletePage() {
      this.isModalOpenDelete = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    onCloseDelete() {
      this.isModalOpenDelete = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    removeCmsPage() {
      const params = {
        accountId: this.getCurrentAccountId,
        pageId: this.$route.params.id,
      };

      this.$store.dispatch(REMOVE_PAGE_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Page has been successfully deleted',
          });
          setTimeout(() => {
            this.backToCms();
          }, CONFIG.routing.redirectFromAddAcc);
        })
        .catch((err) => {
          const {
            errorMessages,
            statuses,
          } = CONFIG;
          if (err.code === statuses.failedStatus) {
            this.$toasted.global.error({
              message: errorMessages.commonServerError,
            });
          }

          if (err.error === 'ITEM_HAS_DEPENDENCIES') {
            this.$toasted.global.error({
              message: 'The Page is used as a Navigation Item and cannot be deleted. Please delete the Navigation Item reference first',
            });
          }
        });
    },
    getRequestData() {
      this.foldError = '';
      this.categoriesError = '';

      const data = {
        name: this.formData.name,
        label: this.formData.label,
        headerType: this.formData.headerType,
        distribution: this.formData.distribution,
      };

      if (this.cmsPage.format === 'rows') {
        data.orderRows = this.formData.orderRows.length ? this.formData.orderRows : [];
        if (this.formData.headerType === 'slider') {
          data.slider = this.formData.slider;
        }
      }

      if (this.cmsPage.format === 'grid') {
        data.showTabs = this.formData.isShowTabs;
        data.showItemTitles = this.formData.isShowItemTitles;
        data.orderTabs = this.formData.orderTabs.length ? this.formData.orderTabs : [];

        if (this.formData.contentType === 'feed') {
          data.grid = {};
          data.grid.dataSource = this.formData.contentType;
          data.grid.feed = this.formData.feed;
        }

        if (this.formData.contentType === 'folder') {
          data.grid = {};
          data.grid.dataSource = this.formData.contentType;
          data.grid.mediaFolder = this.formData.mediaFolder;
          data.grid.sortOption = this.formData.sortOption;
          data.grid.groupContent = this.formData.groupContent;
        }

        if (this.formData.contentType === 'categories') {
          data.grid = {};
          data.grid.dataSource = this.formData.contentType;
          data.grid.categories = this.formData.categories.map((category) => category.id);
          data.grid.sortOption = this.formData.sortOption;
          data.grid.groupContent = this.formData.groupContent;
        }
      }

      if (this.cmsPage.format === 'live') {
        delete data.headerType;
        data.standaloneEventGroup = this.formData.eventGroup;
      }

      return {
        data,
        accountId: this.getCurrentAccountId,
        cmsPageId: this.$route.params.id,
      };
    },
    onSubmitFail(error) {
      this.formErrors = this.getServerFormErrors(error);
      this.itemsErrors = this.getServerFormCollectionErrors(error);
      this.error = this.formErrors.feed
        || this.formErrors.mediaFolder
        || this.formErrors.categories;
      const requiredMessage = 'This field is required';

      const folderError = error.form_errors
        && error.form_errors.children
        && error.form_errors.children.grid
        && error.form_errors.children.grid.children
        && error.form_errors.children.grid.children.mediaFolder
        && error.form_errors.children.grid.children.mediaFolder.errors[0].error === 'IS_BLANK_ERROR';

      if (folderError) {
        this.foldError = requiredMessage;
      }

      const categoriesError = error.form_errors.children?.grid?.children?.categories?.errors?.[0].error === 'IS_BLANK_ERROR';

      if (categoriesError) {
        this.categoriesError = 'At least one Category must be selected';
      }

      if (Object.entries(this.formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(this.formErrors);
      } else {
        if (error.form_errors
          && error.form_errors.children
          && error.form_errors.children.distribution) {
          this.$toasted.global.error({
            message: 'This page is linked from another object and cannot be deleted. Please delete the navigation reference first',
          });
          return;
        }

        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });

      if (this.formData.format === 'Grid') {
        if (this.formData.contentType === 'folder') {
          if (!this.hasFolder) {
            setTimeout(() => {
              this.$router.go(0);
            }, CONFIG.routing.redirectFromAddAcc);
            return;
          }
        }

        if (this.formData.contentType === 'feed') {
          if (!this.hasFeed) {
            setTimeout(() => {
              this.$router.go(0);
            }, CONFIG.routing.redirectFromAddAcc);
            return;
          }
        }

        if (this.formData.contentType === 'categories') {
          if (!this.hasCategories) {
            setTimeout(() => {
              this.$router.go(0);
            }, CONFIG.routing.redirectFromAddAcc);
            return;
          }
        }
      }

      setTimeout(() => {
        this.backToCms();
      }, CONFIG.routing.redirectFromAddAcc);
    },
  },
};
</script>

<style scoped>
.left-path {
  margin-bottom: 30px;
}

.system {
  color: #4c5057;
}

.form-container {
  padding: 25px 30px;
  overflow-y: auto;
  width: 100%;
}

.wrapper {
  display: flex;
}

.edit-button-wrapper {
  position: absolute;
  top: 75px;
  display: flex;
  right: 30px;
}
</style>
