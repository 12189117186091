<template>
  <div
    v-if="modal"
    class="overlay"
  >
    <div
      ref="manuallyForm"
      :class="['wrapper-modal' , isOpen && 'hide-overflow']"
    >
      <div class="form-container">
        <div class="wrapper">
          <div class="column column-1">
            <AddCategoryForm
              :onClose="onClose"
              :onSubmitManually="onSubmitManually"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CONFIG from '../../constants/config';
import { SET_EDIT_FORM } from '../../store/actions/common';

import AddCategoryForm from '../../components/forms/categories/AddCategoryForm.vue';

export default {
  name: 'AddCategoryModal',
  components: {
    AddCategoryForm,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onSubmitManually: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.cms.isLoading,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  methods: {
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
    },
  },
};
</script>

<style scoped>
.form-container {
  overflow-y: auto;
  width: 100%;
}

.wrapper {
  display: flex;
  padding: 30px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.wrapper-modal {
  background-color: #222222;
  border-radius: 4px;
  display: block;
  left: 50%;
  max-width: 100%;
  outline: none;
  overflow-wrap: break-word;
  position: absolute;
  text-decoration: none;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 630px;
  overflow: auto;
}

.hide-overflow {
  overflow: hidden;
}
</style>
