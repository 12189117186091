import countries from 'i18n-iso-countries';
import states from 'us-state-converter';
import en from 'i18n-iso-countries/langs/en.json';

const addCustomMapping = (lang, mapping) => {
  const customLang = {
    locale: lang.locale,
    countries: { ...lang.countries },
  };

  if (mapping) {
    Object.entries(mapping).forEach(([key, values]) => {
      let items = [];

      if (customLang.countries[key]) {
        items = customLang.countries[key] instanceof Array
          ? [...customLang.countries[key]]
          : [customLang.countries[key]];
      }

      values.forEach((value) => {
        items.push(value);
      });

      customLang.countries[key] = items;
    });
  }

  return customLang;
};

const mapping = {
  US: ['United States'],
  TW: ['Taiwan, Province of China'],
  KR: ['Korea, Republic of'],
  VN: ['Viet Nam'],
  SZ: ['Swaziland'],
  VI: ['Virgin Islands, U.s.'],
  MK: ['Macedonia, the Former Yugoslav Republic of'],
};

const customLocale = addCustomMapping(en, mapping);

countries.registerLocale(customLocale);

const usaStates = states();
usaStates.push({
  name: 'Oregon',
  usps: 'OR',
  demonym: 'Oregonian',
  iso: 'US-OR',
  altAbbr: ['Oreg'],
  uscg: 'OR',
});

const getCountryIso2Code = (name, language = 'en') => countries.getAlpha2Code(name, language);
const getUSAStateCode = (name) => {
  const state = usaStates
    .find((item) => item.name.toUpperCase() === name.toUpperCase());

  return state ? state.usps : name;
};

export default {
  getCountryIso2Code,
  getUSAStateCode,
};
