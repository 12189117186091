<template>
  <div class="wrapper">
    <FeedsForm :isEdit="!!$route.params.id" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FeedsForm from '../../components/forms/FeedsForm.vue';
import CONFIG from '../../constants/config';
import { SET_EDIT_FORM } from '../../store/actions/common';

export default {
  name: 'Feed',
  components: {
    FeedsForm,
  },
  computed: {
    ...mapState({
      isEditedForm: (state) => state.common.isEditedForm,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  methods: {
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
  },
};
</script>

<style scoped>
  .wrapper {
    overflow-y: auto;
    padding: 20px 15px 0 0;
    width: 100%;
  }
</style>
