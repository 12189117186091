/* eslint-disable camelcase */
import apiCall from '../../../utils/api/api';

import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_ERROR,
} from '../../actions/authActions/auth';
import CONFIG from '../../../constants/config';

const state = {
  token: localStorage.getItem('bc-c:key') || '',
  status: '',
};

const getters = {
  isAuthenticated: () => !!state.token,
  authStatus: () => state.status,
};

const actions = {
  [AUTH_REQUEST]: ({ commit }, user) => new Promise((resolve, reject) => {
    commit(AUTH_REQUEST);
    const { username, password } = user;

    const {
      grantType,
      clientId,
      clientSecret,
    } = CONFIG.auth.auth;

    const data = {
      grant_type: grantType,
      client_id: clientId,
      client_secret: clientSecret,
      username,
      password,
    };

    apiCall.post('oauth/v2/token', data)
      .then((resp) => {
        const { access_token, refresh_token } = resp.data;
        localStorage.clear();
        localStorage.setItem('bc-c:key', access_token);
        localStorage.setItem('bc-f:key', refresh_token);
        commit(AUTH_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response;
        commit(AUTH_ERROR, error);
        localStorage.clear();
        reject(error);
      });
  }),
  [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve) => {
    apiCall.post('user/logout', { refreshToken: localStorage.getItem('bc-f:key') }).then(() => {
      commit(AUTH_LOGOUT);
      localStorage.clear();
      sessionStorage.removeItem('parentsPath');
      resolve();
    });
  }),
};

const mutations = {
  [AUTH_REQUEST]: () => {
    state.status = 'loading';
  },
  [AUTH_SUCCESS]: (resp) => {
    state.status = 'success';
    state.token = resp.token;
    state.isAuthenticated = true;
  },
  [AUTH_ERROR]: (err) => {
    state.status = err;
    state.isAuthenticated = false;
  },
  [AUTH_LOGOUT]: () => {
    state.token = '';
    state.isAuthenticated = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
