export const SET_CATEGORY_STORE_PROPERTY = 'SET_CATEGORY_STORE_PROPERTY';

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

export const GET_CATEGORIES_FOR_CHOICE_REQUEST = 'GET_CATEGORIES_FOR_CHOICE_REQUEST';
export const GET_CATEGORIES_FOR_CHOICE_SUCCESS = 'GET_CATEGORIES_FOR_CHOICE_SUCCESS';
export const GET_CATEGORIES_FOR_CHOICE_ERROR = 'GET_CATEGORIES_FOR_CHOICE_ERROR';

export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';

export const GET_CATEGORY_IN_USE_STATUS_REQUEST = 'GET_CATEGORY_IN_USE_STATUS_REQUEST';
export const GET_CATEGORY_IN_USE_STATUS_SUCCESS = 'GET_CATEGORY_IN_USE_STATUS_SUCCESS';
export const GET_CATEGORY_IN_USE_STATUS_ERROR = 'GET_CATEGORY_IN_USE_STATUS_ERROR';

export const EDIT_CATEGORY_REQUEST = 'EDIT_CATEGORY_REQUEST';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_ERROR = 'EDIT_CATEGORY_ERROR';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR';

export const CHANGE_CATEGORY_STATUS_REQUEST = 'CHANGE_CATEGORY_STATUS_REQUEST';
export const CHANGE_CATEGORY_STATUS_SUCCESS = 'CHANGE_CATEGORY_STATUS_SUCCESS';
export const CHANGE_CATEGORY_STATUS_ERROR = 'CHANGE_CATEGORY_STATUS_ERROR';
