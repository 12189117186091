<template>
  <div class="flex">
    <div>
      <label
        :class="['btn upload-btn', isDisabled ? 'disabled-button' : '']"
        for="xml"
      >
        Ingest
      </label>
      <input
        v-if="!isDisabled"
        id="xml"
        ref="xml"
        accept="text/xml"
        class="input"
        type="file"
        @click="fileInputClick"
        @change="fileInputChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CONFIG from '../../constants/config';
import {
  UPLOAD_AS_BASE64_REQUEST,
  UPLOAD_XML_REQUEST,
} from '../../store/actions/media/mediaList';

export default {
  name: 'UploadXML',
  props: {
    folderId: {
      type: String,
      default: '',
    },
    getMediaList: {
      type: Function,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      preview: '',
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccount',
      'getCurrentAccountId',
      'getRootMediaFolderId',
    ]),
  },
  methods: {
    fileInputClick(e) {
      e.target.value = null;
    },
    fileInputChange() {
      const file = this.$refs.xml.files[0];

      this.$store.dispatch(UPLOAD_AS_BASE64_REQUEST, file)
        .then((res) => {
          const params = {
            accountId: this.getCurrentAccountId,
            data: {
              importData: res,
              mediaFolder: Number(this.folderId) === this.getRootMediaFolderId
                ? null
                : this.folderId,
            },
          };
          if (file.size < CONFIG.upload.maxFileSize) {
            this.$refs.xml.value = '';
            this.$store.dispatch(UPLOAD_XML_REQUEST, params)
              .then((res) => {
                this.$toasted.global.success({
                  message: `Imported ${res.count} items`,
                });
              })
              .catch(() => {
                this.$toasted.global.error({
                  message: CONFIG.errorMessages.commonServerError,
                });
              });
          }
        })
        .catch((err) => {
          this.$toasted.global.error({
            message: err,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i');

  .flex {
    display: flex;
    justify-content: flex-end;
  }

  .input {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
  }

  .btn:hover {
    opacity: 0.85;
  }

  .btn {
    align-items: center;
    background-color: #2591cf;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    height: 40px;
    justify-content: center;
    letter-spacing: 0.089em;
    line-height: 19px;
    outline: none;
    text-transform: capitalize;
    transition: all 100ms ease;
    width: 110px;
  }

  .upload-btn {
    margin-right: 15px;
  }

  .disabled-button {
    cursor: not-allowed;
    opacity: 0.7;
  }
</style>
