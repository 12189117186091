<template>
  <div :class="['search-wrapper', className]">
    <inline-svg
      :src="iconSearch"
      class="svg-icon search-icon"
    >
    </inline-svg>
    <input
      v-model="search"
      placeholder="Search"
      class="search-input"
      :style="inputStyle"
      :name="search"
      type="text"
      :maxlength="maxlength"
      @input="onInput"
    />
  </div>
</template>
<script>
import iconSearch from '../../assets/icons/icon-search.svg';

export default {
  name: 'LiveSearch',
  props: {
    onSearch: {
      type: Function,
      default: () => {},
    },
    maxlength: {
      type: Number,
      default: 255,
    },
    withDelay: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: '',
      iconSearch,
      timeout: null,
    };
  },
  computed: {
    inputStyle() {
      return {
        padding: '0',
      };
    },
  },
  methods: {
    onInput() {
      if (!(this.search.indexOf(' ') >= 0)
        || (this.search.indexOf(' ') >= 0
          && this.search.trim() !== '')) {
        const params = {
          page: 1,
          filterValue: this.search.trim(),
        };
        this.onSearch(params);
      }
    },
  },
};
</script>
<style>
  .search-wrapper {
    border: 1px solid #383b40;
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    height: 30px;
    margin-right: 25px;
    width: 370px;
  }

  .search-icon {
    margin: 6px 6px 0 10px;
  }

  .search-input {
    background-color: transparent;
    font-size: 14px;
  }

  .search-input:focus {
    outline: none;
  }

  .auto-width {
    margin-right: 0;
    width: auto;
  }
</style>
