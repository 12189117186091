/* eslint-disable no-shadow */
import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  USER_REQUEST,
  USER_ERROR,
  USER_SUCCESS,
  ADD_USER,
  EDIT_PROFILE_CONTACT_INFO,
  EDIT_PROFILE_LOGIN_INFO,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  USER_SEND_ACTIVATION_EMAIL_REQUEST,
  USER_SEND_ACTIVATION_EMAIL_SUCCESS,
  USER_SEND_ACTIVATION_EMAIL_ERROR,
  GET_USER_INFO,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
  EDIT_USER_INFO,
  EDIT_USER_INFO_REQUEST,
  EDIT_USER_INFO_SUCCESS,
  EDIT_USER_INFO_ERROR,
  ADD_USER_REQUEST,
  EDIT_PROFILE_CONTACT_INFO_REQUEST,
  EDIT_PROFILE_LOGIN_INFO_REQUEST,
  EDIT_PROFILE_LOGIN_INFO_SUCCESS,
  EDIT_PROFILE_CONTACT_INFO_SUCCESS,
  SIGN_IN_OTHER_USER_REQUEST,
  SIGN_IN_OTHER_USER_SUCCESS,
  SIGN_IN_OTHER_USER_ERROR,
  SET_LAST_USED_ACCOUNT,
  SET_LAST_USED_ACCOUNT_REQUEST,
  SET_LAST_USED_ACCOUNT_SUCCESS,
  SET_LAST_USED_ACCOUNT_ERROR,
  LINK_EXISTING_USER_WITH_ACCOUNT,
  LINK_EXISTING_USER_WITH_ACCOUNT_REQUEST,
  LINK_EXISTING_USER_WITH_ACCOUNT_SUCCESS,
  LINK_EXISTING_USER_WITH_ACCOUNT_ERROR,
  USER_SEND_ADMIN_ACTIVATION_EMAIL_REQUEST,
  USER_SEND_ADMIN_ACTIVATION_EMAIL_SUCCESS,
  USER_SEND_ADMIN_ACTIVATION_EMAIL_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_AS_ADMIN_REQUEST,
  DELETE_USER_AS_ADMIN_SUCCESS,
  DELETE_USER_AS_ADMIN_ERROR,
} from '../../actions/user';
import { AUTH_LOGOUT } from '../../actions/authActions/auth';
import { SET_CANCEL_TOKEN } from '../../actions/accountsActions/accounts';

const state = {
  status: '',
  profile: {},
  users: [],
  pagination: {},
  user: {},
  isLoading: false,
  source: null,
};

const actions = {
  [GET_USER_INFO]: ({ commit }, { accountId, userId }) => new Promise((resolve, reject) => {
    commit(GET_USER_INFO_REQUEST);

    let url = (accountId) ? `${accountId}/` : '';
    url += `user/${userId}`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_USER_INFO_SUCCESS, resp.data);
        resolve(resp.data);
      }).catch((err) => {
        const error = err.response.data;
        commit(GET_USER_INFO_ERROR);
        reject(error);
      });
  }),
  [EDIT_USER_INFO]: ({ commit }, { accountId, userId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_USER_INFO_REQUEST);

    let url = (accountId) ? `${accountId}/` : '';
    url += `user/${userId}`;

    apiCall.put(url, data)
      .then((resp) => {
        commit(EDIT_USER_INFO_SUCCESS, resp.data);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(EDIT_USER_INFO_ERROR);
        reject(error);
      });
  }),
  [ADD_USER]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(ADD_USER_REQUEST);

    const url = (accountId) ? `${accountId}/user` : 'user';

    apiCall.post(url, data)
      .then((resp) => {
        commit(USER_SUCCESS, resp.data);
        resolve();
      }).catch((err) => {
        let error;
        if (err.response.status === 402) {
          error = err.response.status;
        } else {
          error = err.response.data;
          commit(USER_ERROR);
        }
        reject(error);
      });
  }),
  [EDIT_PROFILE_CONTACT_INFO]: ({ commit }, user) => new Promise((resolve, reject) => {
    commit(EDIT_PROFILE_CONTACT_INFO_REQUEST);

    apiCall.put('user/profile', user)
      .then(() => {
        commit(EDIT_PROFILE_CONTACT_INFO_SUCCESS);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(USER_ERROR);
        reject(error);
      });
  }),
  [EDIT_PROFILE_LOGIN_INFO]: ({ commit }, user) => new Promise((resolve, reject) => {
    commit(EDIT_PROFILE_LOGIN_INFO_REQUEST);

    apiCall.put('user/password', user)
      .then(() => {
        commit(EDIT_PROFILE_LOGIN_INFO_SUCCESS);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(USER_ERROR);
        reject(error);
      });
  }),
  [GET_ALL_USERS_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    let url = '';
    if (params.accountId) url = `${params.accountId}`;

    if (!params.sortFiled) {
      url += `/user/list?p=${params.page}&l=15&filterField=fullName&filterValue=${params.filterValue}`;
    } else {
      url += `/user/list?p=${params.page}&l=15&filterField=fullName&filterValue=${params.filterValue}&s=${params.sortFiled}&d=${params.sortBy}`;
    }
    apiCall.get(
      url,
      {
        cancelToken: source.token,
        cache: false,
      },
    )
      .then((resp) => {
        commit(GET_ALL_USERS_SUCCESS, resp.data);
        localStorage.setItem('currentPage-user-list', resp.data.pagination.page);
        resolve();
      })
      .catch(() => {
        commit(GET_ALL_USERS_ERROR);
      });
  }),
  [USER_SEND_ACTIVATION_EMAIL_REQUEST]: ({ commit }, userId) => new Promise((resolve, reject) => {
    commit(USER_SEND_ACTIVATION_EMAIL_REQUEST);

    apiCall.post(`user/resend/${userId}`)
      .then((resp) => {
        commit(USER_SEND_ACTIVATION_EMAIL_SUCCESS, resp.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(USER_SEND_ACTIVATION_EMAIL_ERROR, error);
        reject(error);
      });
  }),
  [USER_SEND_ADMIN_ACTIVATION_EMAIL_REQUEST]:
    ({ commit }, params) => new Promise((resolve, reject) => {
      commit(USER_SEND_ADMIN_ACTIVATION_EMAIL_REQUEST);

      apiCall.put(`${params.accountId}/user/resend/${params.userId}`)
        .then((resp) => {
          commit(USER_SEND_ADMIN_ACTIVATION_EMAIL_SUCCESS, resp.data);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(USER_SEND_ADMIN_ACTIVATION_EMAIL_ERROR, error);
          reject(error);
        });
    }),
  [SIGN_IN_OTHER_USER_REQUEST]: ({ commit }, email) => new Promise((resolve, reject) => {
    commit(SIGN_IN_OTHER_USER_REQUEST);

    apiCall.get('user/info',
      {
        headers: {
          'X-Switch-User': email,
        },
      })
      .then((res) => {
        commit(SIGN_IN_OTHER_USER_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(SIGN_IN_OTHER_USER_ERROR, error);
        reject(error);
      });
  }),
  [SET_LAST_USED_ACCOUNT]: ({ commit }, accountId) => new Promise((resolve, reject) => {
    commit(SET_LAST_USED_ACCOUNT_REQUEST);

    apiCall.put(`${accountId}/user/set-last`)
      .then(() => {
        commit(SET_LAST_USED_ACCOUNT_SUCCESS);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(SET_LAST_USED_ACCOUNT_ERROR, error);
        reject(error);
      });
  }),
  [LINK_EXISTING_USER_WITH_ACCOUNT]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(LINK_EXISTING_USER_WITH_ACCOUNT_REQUEST);

    apiCall.put(`${accountId}/user/link-to-account`, data)
      .then(() => {
        commit(LINK_EXISTING_USER_WITH_ACCOUNT_SUCCESS);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(LINK_EXISTING_USER_WITH_ACCOUNT_ERROR, error);
        reject(error);
      });
  }),
  [DELETE_USER_REQUEST]:
    ({ commit }, { userId }) => new Promise((resolve, reject) => {
      commit(DELETE_USER_REQUEST);

      apiCall.delete(`user/${userId}`)
        .then(() => {
          commit(DELETE_USER_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(DELETE_USER_ERROR, error);
          reject(error);
        });
    }),
  [DELETE_USER_AS_ADMIN_REQUEST]:
    ({ commit }, { accountId, userId }) => new Promise((resolve, reject) => {
      commit(DELETE_USER_AS_ADMIN_REQUEST);

      apiCall.delete(`${accountId}/user-account-role/${userId}`)
        .then(() => {
          commit(DELETE_USER_AS_ADMIN_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(DELETE_USER_AS_ADMIN_ERROR, error);
          reject(error);
        });
    }),
};

const mutations = {
  [USER_REQUEST]: () => {
    state.status = 'loading';
  },
  [USER_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.status = 'success';
    state.profile = payload;
  },
  [USER_ERROR]: () => {
    state.isLoading = false;
    state.status = 'error';
  },
  [AUTH_LOGOUT]: () => {
    state.profile = {};
  },
  [GET_ALL_USERS_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.users = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
    state.isLoading = false;
  },
  [GET_USER_INFO_REQUEST]: () => {
    state.status = 'loading';
  },
  [GET_USER_INFO_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.user = payload;
  },
  [GET_USER_INFO_ERROR]: () => {
    state.status = 'error';
  },
  [EDIT_USER_INFO_REQUEST]: () => {
    state.isLoading = true;
    state.status = 'loading';
  },
  [EDIT_USER_INFO_SUCCESS]: (state) => {
    state.isLoading = false;
    state.status = 'success';
  },
  [EDIT_USER_INFO_ERROR]: () => {
    state.isLoading = false;
    state.status = 'error';
  },
  [USER_SEND_ACTIVATION_EMAIL_REQUEST]: () => {
    state.isLoading = true;
  },
  [USER_SEND_ACTIVATION_EMAIL_SUCCESS]: () => {
    state.isLoading = false;
  },
  [USER_SEND_ACTIVATION_EMAIL_ERROR]: () => {
    state.isLoading = false;
  },
  [USER_SEND_ADMIN_ACTIVATION_EMAIL_REQUEST]: () => {
    state.isLoading = true;
  },
  [USER_SEND_ADMIN_ACTIVATION_EMAIL_SUCCESS]: () => {
    state.isLoading = false;
  },
  [USER_SEND_ADMIN_ACTIVATION_EMAIL_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_USER_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_PROFILE_LOGIN_INFO_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_PROFILE_LOGIN_INFO_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_PROFILE_CONTACT_INFO_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_PROFILE_CONTACT_INFO_SUCCESS]: () => {
    state.isLoading = false;
  },
  [SIGN_IN_OTHER_USER_SUCCESS]: (state, payload) => {
    state.isOtherUserMode = true;
    localStorage.setItem('isOtherUserMode', true);
    localStorage.setItem('isOtherUserEmail', payload.email);
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
  },
  [GET_ALL_USERS_ERROR]: () => {
    state.isLoading = false;
  },
  [SET_LAST_USED_ACCOUNT_REQUEST]: () => {
    state.isLoading = true;
  },
  [SET_LAST_USED_ACCOUNT_SUCCESS]: () => {
    state.isLoading = false;
  },
  [SET_LAST_USED_ACCOUNT_ERROR]: () => {
    state.isLoading = false;
  },
  [LINK_EXISTING_USER_WITH_ACCOUNT_REQUEST]: () => {
    state.isLoading = true;
  },
  [LINK_EXISTING_USER_WITH_ACCOUNT_SUCCESS]: () => {
    state.isLoading = false;
  },
  [LINK_EXISTING_USER_WITH_ACCOUNT_ERROR]: () => {
    state.isLoading = false;
  },
  [SIGN_IN_OTHER_USER_REQUEST]: () => {},
  [DELETE_USER_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_USER_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_USER_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_USER_AS_ADMIN_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_USER_AS_ADMIN_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_USER_AS_ADMIN_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
