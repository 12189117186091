<template>
  <div :class="[windowHeight < 1000 ? 'scroll' : '']">
    <Loader :isVisible="isLoading" />
    <div>
      <div class="flex">
        <div class="eng-local-wrapper">
          <div class="pb-13">
            English (default)
          </div>
          <div class="eng-local-container">
            <MediaEngForm :media="media" />
          </div>
        </div>
        <div class="translate-local-wrapper">
          <Tabs
            v-if="enabledLanguages.length && formData.mode"
            :removeMarginMedia="true"
            :onSelectTab="handleSelectTab"
          >
            <Tab
              v-for="lang in enabledLanguages"
              :id="lang.name"
              :key="lang.id"
              :name="lang.name"
              :selected="formData.mode === lang.name"
              :isLocalisation="true"
              :className="[isEditedForm.length
                && isEditedForm.find(item => item.id === lang.id && !!item.value)
                ? 'error-tab'
                : 'tab']"
            >
              <TranslateMediaForm
                :lang="lang"
                :media="media"
                :enabledLanguages="enabledLanguages"
                :selected="formData.mode === lang.name"
                @changedLanguage="changedLanguage"
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import Tabs from '../common/Tabs/Tabs.vue';
import Tab from '../common/Tabs/Tab.vue';
import iconBackTo from '../../assets/icons/icon-back-to.svg';
import Loader from '../common/Loader.vue';
import MediaEngForm from '../forms/localization/MediaEngForm.vue';
import TranslateMediaForm from '../forms/localization/TranslateMediaForm.vue';
import { SET_FORM_CHANGES } from '../../store/actions/localization/localizationActions';

export default {
  name: 'MediaLocalization',
  components: {
    TranslateMediaForm,
    MediaEngForm,
    Loader,
    Tabs,
    Tab,
  },
  props: {
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
    media: {
      type: Object,
      default: () => {},
    },
    enabledLanguages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iconBackTo,
      windowHeight: window.outerHeight,
      formData: {
        mode: '',
        changedLanguage: {},
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.localization.isLoading,
      isEditedForm: (state) => state.localization.isEditedForm,
    }),
    ...mapGetters([
      'getLanguages',
    ]),
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.formData.mode = this.enabledLanguages[0].name;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    toFolderMeta() {
      this.onClose();
    },
    handleSelectTab(id) {
      this.formData.mode = id;
      this.$store.dispatch(SET_FORM_CHANGES, this.formData.changedLanguage);
    },
    onResize() {
      this.windowHeight = window.outerHeight;
    },
    changedLanguage(value) {
      this.formData.changedLanguage = value;
    },
  },
};
</script>

<style scoped lang="scss">
.overlay-modal {
  position: absolute;
  top: 50px;
  width: 100%;
  height: 95%;
  background: #222222;
  z-index: 999;
}

.scroll {
  overflow: auto;
}

.back-btn {
  padding-top: 20px;
  padding-left: 20px;
}

.main-tabs-container {
  padding: 10px 20px 30px;
}

.eng-local-wrapper {
  width: 40%;
}

.eng-local-container {
  border-top: 2px solid var(--gray);
  border-right: 2px solid var(--gray);
  height: 100vh;
}

.pb-13 {
  padding-bottom: 15px;
  line-height: 14px;
}

.translate-local-wrapper {
  width: 60%;
}

.flex {
  display: flex;
  align-items: flex-start;
}

@media all and (max-height: 930px)  {
  .eng-local-container {
    height: 71vh;
  }
}

@media all and (max-height: 890px)  {
  .eng-local-container {
    height: 70vh;
  }
}

@media all and (max-height: 830px)  {
  .eng-local-container {
    height: 76vh;
  }
}

@media all and (max-height: 790px)  {
  .eng-local-container {
    height: 90vh;
  }
}
</style>
