<template>
  <div></div>
</template>

<script>
import { GET_FB_ACCESS_TOKEN_REQUEST } from '../store/actions/liveEvent/liveEvent';

export default {
  name: 'FacebookConnector',
  data() {
    return {
    };
  },
  created() {
    localStorage.setItem('fb-code', this.$route.query.code);
    this.$store.dispatch(GET_FB_ACCESS_TOKEN_REQUEST, this.$route.query.code)
      .then(() => {
        window.close();
      })
      .catch((err) => {
        if (err) {
          window.close();
        }
      });
  },
};
</script>

<style scoped>

</style>
