<script>
import TagManagement from '../inputs/TagManagement.vue';

export default {
  name: 'ItunesTagManagement',
  extends: TagManagement,
  methods: {
    createTag(data) {
      const validation = this.validateSearch(data.trim());

      if (validation.isValid) {
        const tag = {
          code: this.selectedValue.length + 1,
          name: data,
        };

        this.addTag(tag);
      }
    },
    validateSearch(searchValue) {
      return {
        value: searchValue,
        isValid: true,
        message: '',
      };
    },
  },
};
</script>

<style scoped>

</style>
