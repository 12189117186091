<template>
  <div class="overlay-meta">
    <div :class="['overlay-modal', windowHeight < 1000 ? 'scroll' : '']">
      <Loader :isVisible="isLoading" />
      <div class="back-btn">
        <Button
          :className="'back-local'"
          :title="'Back to Media'"
          :srcSvg="iconBackTo"
          :eventFunction="toFolderMeta"
          :hasBorder="true"
        />
      </div>
      <div class="main-tabs-container">
        <Tabs>
          <Tab
            v-if="getCurrentAccount.brushfireProtectionEnabled"
            id="protection"
            :key="protKey"
            name="Protection"
            :selected="true"
            class="wrapper-general"
          >
            <FolderProtection />
          </Tab>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import Button from './common/Button.vue';
import Tabs from './common/Tabs/Tabs.vue';
import Tab from './common/Tabs/Tab.vue';
import iconBackTo from '../assets/icons/icon-back-to.svg';
import {
  SET_FORM_CHANGES,
} from '../store/actions/localization/localizationActions';
import Loader from './common/Loader.vue';
import FolderProtection from './Brushfire/FolderProtection.vue';

export default {
  name: 'FolderProtectionModal',
  components: {
    FolderProtection,
    Loader,
    Button,
    Tabs,
    Tab,
  },
  props: {
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
    metadata: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      iconBackTo,
      windowHeight: window.outerHeight,
      selectProtection: false,
      protKey: 0,
      formData: {
        mode: '',
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.localization.isLoading,
      isEditedForm: (state) => state.localization.isEditedForm,
    }),
    ...mapGetters([
      'getLanguages',
      'getCurrentAccount',
    ]),
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    toFolderMeta() {
      this.onClose();
    },
    handleSelectTab(id) {
      this.formData.mode = id;
      this.$store.dispatch(SET_FORM_CHANGES, this.formData.changedLanguage);
    },
    onResize() {
      this.windowHeight = window.outerHeight;
    },
  },
};
</script>

<style scoped lang="scss">
.overlay-modal {
  position: absolute;
  top: 50px;
  width: 100%;
  height: 95%;
  background: #222222;
  z-index: 999;
  overflow: auto;
}

.scroll {
  overflow: auto;
}

.back-btn {
  padding-top: 20px;
  padding-left: 20px;
}

.main-tabs-container {
  padding: 10px 20px 30px;
}

.eng-local-wrapper {
  width: 40%;
}

.eng-local-container {
  border-top: 2px solid var(--gray);
  border-right: 2px solid var(--gray);
  height: 102vh;
}

.eng-local-container-series {
  border-top: 2px solid var(--gray);
  border-right: 2px solid var(--gray);
  height: 100%;
}

.pb-13 {
  padding-bottom: 15px;
  line-height: 15px;
}

.translate-local-wrapper {
  width: 60%;
}

.flex {
  display: flex;
  align-items: flex-start;
}

.back-local {
  padding-left: 0;
}

.eng-local-wrapper-series {
  height: 100vh;
}

.overlay-meta {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

@media all and (max-height: 930px)  {
  .eng-local-container {
    height: 107vh;
  }
}

@media all and (max-height: 890px)  {
  .eng-local-container {
    height: 115vh;
  }
}

@media all and (max-height: 830px)  {
  .eng-local-container {
    height: 121vh;
  }
}

@media all and (max-height: 790px)  {
  .eng-local-container {
    height: 131vh;
  }
}
</style>
