<template>
  <div class="folders-tag-management">
    <div class="multiselectTitle">
      {{ label }}
      <span
        v-if="required"
        class="asterisk"
      >
        *
      </span>
    </div>
    <div
      :class="[commonError.length ? 'common-error' : '',
               isNotValidValue || error ? 'not-valid' : '']"
    >
      <MultiSelect
        v-model="value"
        :options="options"
        :tagPlaceholder="tagPlaceholder"
        :selectLabel="selectLabel"
        :selectedLabel="selectedLabel"
        :deselectLabel="deselectLabel"
        :placeholder="placeholder"
        label="name"
        trackBy="code"
        :multiple="multiple"
        :taggable="false"
        :blockKeys="['Delete']"
        :searchable="true"
        :disabled="disabled"
        :hideSelected="hideSelected"
        :optionsLimit="max"
        :maxHeight="350"
        :customLabel="customLabel"
        @search-change="onSearchChange"
        @input="onInput"
      >
        <template
          slot="tag"
          slot-scope="props"
        >
          <div class="multiselect__tag custom-multiselect__tag">
            <inline-svg
              :src="iconFolder"
              class="svg-icon add-icon"
            >
            </inline-svg>
            <span
              class="option-desc"
              :title="props.option.name"
            >
              <span class="option-title">
                {{ props.option.name }}
              </span>
            </span>
            <inline-svg
              :src="iconForFolder(props.option.collectionType
                ? props.option.collectionType
                : props.option.folderType)"
              class="svg-icon add-icon"
            >
            </inline-svg>
            <inline-svg
              v-if="props.option.hasProtection"
              :src="iconWhiteProtection"
              class="svg-icon add-icon"
            >
            </inline-svg>
            <i
              aria-hidden="true"
              class="multiselect__tag-icon"
              @click="props.remove(props.option)"
            ></i>
          </div>
        </template>
        <template
          slot="singleLabel"
          slot-scope="props"
        >
          <div class="option-selected-folder">
            <inline-svg
              :src="props.option.folderType === 'all-media' ? iconLock : iconFolder"
              class="svg-icon add-icon"
            >
            </inline-svg>
            <span
              class="option-desc"
              :title="props.option.name"
            >
              <span class="option-title">
                {{ props.option.name }}
              </span>
            </span>
            <inline-svg
              :src="iconForFolder(props.option.collectionType
                ? props.option.collectionType
                : props.option.folderType)"
              class="svg-icon add-icon"
            >
            </inline-svg>
            <inline-svg
              v-if="props.option.hasProtection"
              :src="iconProtection"
              class="svg-icon add-icon"
            >
            </inline-svg>
          </div>
        </template>
        <template
          slot="option"
          slot-scope="props"
        >
          <div class="custom-option">
            <div
              v-for="item in props.option.levelMarks && props.option.levelMarks.length - 2"
              :key="item"
            >
              <inline-svg
                :src="iconHasChild"
                class="svg-icon add-icon"
              >
              </inline-svg>
            </div>
            <div>
              <inline-svg
                :src="props.option.folderType === 'all-media' ? iconLock : iconFolder"
                class="svg-icon add-icon"
              >
              </inline-svg>
            </div>
            <div
              class="option-fold-name"
              :title="props.option.name"
            >
              {{ props.option.name }}
            </div>
            <inline-svg
              :src="iconForFolder(props.option.collectionType
                ? props.option.collectionType
                : props.option.folderType)"
              class="svg-icon add-icon"
            >
            </inline-svg>
            <div v-if="props.option.hasProtection">
              <inline-svg
                :src="iconProtection"
                class="svg-icon add-icon"
              >
              </inline-svg>
            </div>
          </div>
        </template>
        <span slot="noResult">
          No items to display
        </span>
      </MultiSelect>
      <span
        v-if="commonError.length || isNotValidValue || error"
        class="error-label"
      >
        {{ commonError.length ? commonError : error }}
      </span>
    </div>
  </div>
</template>
<script>
import MultiSelect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import iconFolder from '../assets/icons/media-folder-main.svg';
import iconHasChild from '../assets/icons/icon-folder-parent-option.svg';
import iconProtection from '../assets/icons/icon-brush-protection-folder.svg';
import iconSeries from '../assets/icons/icon-series-folder.svg';
import iconEpisode from '../assets/icons/icon-episode-folder.svg';
import iconEvent from '../assets/icons/icon-event-folder.svg';
import iconSeasons from '../assets/icons/icon-seasons-folder.svg';
import iconFeature from '../assets/icons/icon-feature-folder.svg';
import iconWhiteProtection from '../assets/icons/icon-white-brushfire.svg';
import iconLock from '../assets/icons/media-lock.svg';
import iconPlaylist from '../assets/icons/icon-playlist-folder.svg';
import { GET_FOLDERS_LIST_REQUEST } from '../store/actions/liveEvent/liveEvent';

export default {
  name: 'FoldersManagement',
  components: {
    MultiSelect,
  },
  props: {
    resetValidation: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    tagPlaceholder: {
      type: String,
      default: '',
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    searchable: {
      type: Boolean,
      default: false,
      required: false,
    },
    selectedValue: {
      type: Number,
      default: null,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    errorMess: {
      type: Array,
      default: () => [],
    },
    errorItems: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    isGrey: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideSelected: {
      type: Boolean,
      default: false,
      required: false,
    },
    commonError: {
      type: String,
      default: '',
    },
    selectLabel: {
      type: String,
      default: '',
    },
    selectedLabel: {
      type: String,
      default: '',
    },
    deselectLabel: {
      type: String,
      default: '',
    },
    customOptions: {
      type: Array,
      default: () => [],
    },
    isJustSearch: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isSeries: {
      type: Boolean,
      default: false,
    },
    isNeedAllMedia: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      iconFolder,
      iconHasChild,
      iconProtection,
      iconSeries,
      iconEpisode,
      iconEvent,
      iconSeasons,
      iconFeature,
      iconWhiteProtection,
      iconLock,
      iconPlaylist,
      value: [],
      options: this.getDefaultOptions(),
      isNotValidValue: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters([
      'getRootMediaFolderId',
    ]),
    error() {
      if (this.errorItems && this.errorItems.length) {
        return this.errorItems[0];
      }

      return this.errorMessage;
    },
  },
  created() {
    this.getTags();
  },
  methods: {
    initComponent() {
      if (this.selectedValue) {
        if (!this.multiple) {
          this.value = this.options.find((item) => item.code === this.selectedValue);
        } else {
          // eslint-disable-next-line array-callback-return
          this.options.map((opt) => {
            // eslint-disable-next-line array-callback-return,consistent-return
            this.selectedValue.map((selected) => {
              if (opt.code === selected.id) {
                return this.value.push(opt);
              }
            });
          });
        }
      }
    },
    getDefaultOptions() {
      return [];
    },
    onSearchChange(searchQuery) {
      this.resetValidation();

      const validation = this.validateSearch(searchQuery.trim());

      this.isNotValidValue = !validation.isValid;
      this.errorMessage = validation.message;

      if (validation.isValid) {
        this.processSearchValue(validation.value);
      }
    },
    validateSearch(searchValue) {
      return {
        value: searchValue,
        isValid: true,
        message: '',
      };
    },
    getTags() {
      let result = [];

      const data = {
        accountId: this.getCurrentAccountId,
        filterValue: this.filterValue,
      };

      this.$store.dispatch(GET_FOLDERS_LIST_REQUEST, data)
        .then((res) => {
          result = res.data.map((item) => ({
            id: item.id,
            levelMarks: item.levelMarks,
            hasProtection: item.hasProtection,
            folderType: item.folderType,
            collectionType: item.collectionType,
            code: item.id,
            name: `${this.getName(item.name)} (${item.id})`,
            titleName: item.name,
          }));

          if (this.isSeries) {
            this.options = result.filter((folder) => folder.collectionType === 'playlist');
          } else {
            this.options = result;
          }

          if (this.isNeedAllMedia) {
            this.options.unshift({
              id: this.getRootMediaFolderId,
              levelMarks: '--',
              folderType: 'all-media',
              code: this.getRootMediaFolderId,
              name: `All Media (${this.getRootMediaFolderId})`,
            });
            this.options = this.options
              .filter(((folder) => folder.code !== +this.$route.params.folderId));
          }

          this.initComponent();
        });

      return result;
    },
    processSearchValue(value) {
      this.filterValue = value;
    },
    onInput(value) {
      this.resetValidation();
      this.onChange(value);
    },
    customLabel(option) {
      return `${option.levelMarks} ${option.name}`;
    },
    iconForFolder(type) {
      if (type === 'Series') {
        return iconSeries;
      }

      if (type === 'season') {
        return iconSeasons;
      }

      if (type === 'feature') {
        return iconFeature;
      }

      if (type === 'Event') {
        return iconEvent;
      }

      if (type === 'playlist') {
        return iconPlaylist;
      }

      return '';
    },
    getName(name) {
      return name.length > 45 ? `${name.slice(0, 45)}...` : name;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
/* stylelint-disable */
.multiselectTitle {
  font-size: 16px;
  margin-bottom: 10px;
}

.multiselect__tags {
  background: #222 !important;
  border-color: transparent;
  border-radius: 4px;
}

.multiselect__tag {
  background: #e77925;
}

.multiselect__tag-icon::after,
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  color: #fff !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #e77925;
}

.multiselect__input {
  background: #222;
  border: none;
  padding-left: 0 !important;
  color: #fff;
}

.live-tag .multiselect__tags,
.live-tag .multiselect__input {
  background: #383b40 !important;
}

.multiselect__input::placeholder {
  color: #fff;
  font-size: 14px;
  padding: 0;
}

.multiselect__element {
  font-size: 14px;
  background-color: #383B40;
  color: #FFFFFF;
}

.multiselect__option--highlight,
.multiselect__option--highlight:after {
  background: #5e6368;
}

.folders-tag-management {
  margin-top: 5px;
}

.multiselect__content-wrapper {
  border: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--above .multiselect__content-wrapper {
  border-top: 0;
}

.not-valid .multiselect__content-wrapper {
  display: none;
}

.not-valid .multiselect__tags,
.common-error .multiselect__tags {
  border-bottom: 1px solid #fb3951;
}

.error-label {
  color: #fb3951;
  font-size: 12px;
  margin-bottom: 5px;
  text-align: left;
}

.live-tag {
  width: 335px;
  margin-top: 0;
  margin-left: -20px;
}

.asterisk {
  color: #fb3951;
}

.custom-option {
  align-items: center;
  display: flex;
}

.multiselect__single {
  background: initial;
  color: #FFFFFF;
}

.multiselect__option--selected {
  background: initial;
  color: #FFFFFF;
}

.option-fold-name {
  margin-left: 5px;
  margin-right: 5px;
}

.option-selected-folder {
  align-items: center;
  display: flex;
}

.option-desc {
  margin-left: 5px;
  margin-right: 5px;
}

.multiselect__tags-wrap {
  display: flex;
  flex-wrap: wrap;
}

.custom-multiselect__tag {
  align-items: center;
  display: flex;
  height: 22px;
}
</style>
