<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="add-feature-form"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="title modal">
        Add Feature
      </div>
      <div class="select-wrapper">
        <FoldersManagement
          :selectedValue="formData.mediaFolder"
          :label="'Source Folder'"
          :required="true"
          :commonError="foldError"
          :onChange="onFoldersChange"
        />
      </div>
      <MaterialInput
        v-model="formData.title"
        v-bind="formFields.title"
        :requiredFromBack="true"
      />
      <div class="select-wrapper">
        <MediaItemsManagement
          :selectedFolder="formData.mediaFolder"
          :selectedValue="formData.mediaItem"
          :label="'Select Feature Media'"
          :required="true"
          :commonError="mediaItemError"
          :onChange="onFeatureItemChange"
        />
      </div>
      <div class="buttons-wrapper">
        <div class="buttons-container">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="backToManually"
          />
          <Button
            :title="'Add'"
            :classType="'primary'"
            :type="'submit'"
            :disabled="isAddButtonDisabled"
          />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import FoldersManagement from '../../FoldersManagement.vue';
import MediaItemsManagement from '../../MediaItemsManagement.vue';

import { CREATE_FEATURE_REQUEST } from '../../../store/actions/features/features';

export default {
  name: 'AddFeatureForm',
  components: {
    FoldersManagement,
    MediaItemsManagement,
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'addFeatureForm',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onSubmitManually: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isModalOpen: false,
      bodyMessage: CONFIG.modals.deleteManuallyRowInCms,
      foldError: '',
      mediaItemError: '',
      formData: {
        mediaFolder: null,
        title: '',
        mediaItem: null,
      },
      formFields: {
        title: {
          type: 'text',
          name: 'title',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
      },
      errorMapping: {
        title: [
          `${this.formPrefix}.title`,
        ],
        mediaFolder: [
          `${this.formPrefix}.mediaFolder`,
        ],
      },
      submitEvent: CREATE_FEATURE_REQUEST,
      successMessage: 'The Feature has been successfully added',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    isAddButtonDisabled() {
      return !(this.formData.title && this.formData.mediaFolder && this.formData.mediaItem);
    },
  },
  methods: {
    backToManually() {
      this.onClose();
    },
    onFoldersChange(value) {
      this.formData.mediaFolder = value ? value.code : value;
      this.foldError = '';

      this.formData.title = value ? value.titleName : value;

      this.formData.mediaItem = null;
      this.mediaItemError = '';
    },
    onFeatureItemChange(value) {
      this.formData.mediaItem = value ? value.id : value;
      this.mediaItemError = '';
    },
    getRequestData() {
      const data = {
        title: this.formData.title,
        mediaFolder: this.formData.mediaFolder,
        mediaItem: this.formData.mediaItem,
      };

      return {
        data,
        accountId: this.getCurrentAccountId,
      };
    },
    onSubmitSuccess(res) {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      this.$router.push(`/collections/features/${res.id}`);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      const folderError = error.form_errors
        && error.form_errors.children
        && error.form_errors.children.mediaFolder
        && error.form_errors.children.mediaFolder.errors
        && error.form_errors.children.mediaFolder.errors[0].error;

      if (folderError && folderError === 'IS_BLANK_ERROR') {
        this.foldError = 'This field is required';
      }

      if (folderError && folderError === 'NOT_SYNCHRONIZED_ERROR') {
        this.foldError = 'This folder is already in use';
      }

      const mediaItemError = error.form_errors
        && error.form_errors.children
        && error.form_errors.children.mediaItem
        && error.form_errors.children.mediaItem.errors
        && error.form_errors.children.mediaItem.errors[0].error;

      if (mediaItemError && mediaItemError === 'IS_BLANK_ERROR') {
        this.foldError = 'This field is required';
      }

      if (mediaItemError && mediaItemError === 'NOT_SYNCHRONIZED_ERROR') {
        this.mediaItemError = 'This Media Item is already in use';
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>
.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}

.buttons-container {
  display: flex;
}

.title {
  color: inherit;
  margin-bottom: 30px;
}

.select-wrapper {
  margin-bottom: 30px;
}
</style>
