<template>
  <div class="credentials-form">
    <MaterialInput
      v-model="credentials.url"
      v-bind="formFields.url"
      :className="'dark-input'"
      :disabled="true"
    />
    <MaterialInput
      v-model="credentials.streamName"
      v-bind="formFields.streamName"
      :className="'dark-input'"
      :disabled="true"
    />
    <MaterialInput
      v-model="credentials.username"
      v-bind="formFields.username"
      :className="'dark-input'"
      :disabled="true"
    />
    <MaterialInput
      v-model="credentials.password"
      v-bind="formFields.password"
      :className="'dark-input'"
      :disabled="true"
    />
  </div>
</template>
<script>
import MaterialInput from '../inputs/MaterialInput.vue';

export default {
  name: 'LiveStreamCredentials',
  components: {
    MaterialInput,
  },
  props: {
    credentials: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formFields: {
        url: {
          type: 'text',
          name: 'Streaming URL/RTMP Address',
          allowCopy: true,
        },
        streamName: {
          type: 'text',
          name: 'Delivery Event Name',
          allowCopy: true,
        },
        username: {
          type: 'text',
          name: 'RTMP User Name',
          allowCopy: true,
        },
        password: {
          type: 'text',
          name: 'RTMP Password',
          allowCopy: true,
        },
      },
    };
  },
};
</script>
<style>
.credentials-form {
  font-size: 14px;
}
</style>
