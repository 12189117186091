<template>
  <li
    :class="['media-tree-item',
             level === 1 ? 'item-first' : '',
             isEditTvFolder ? 'item-checkbox' : '',
    ]"
  >
    <Loader
      :isVisible="loaded"
      :className="'inspector-loader'"
    />
    <div v-if=" level >= 3">
      <inline-svg
        :src="iconFolderTreeChild"
        class="svg-icon"
      >
      </inline-svg>
    </div>
    <div
      v-show="!isLock"
      class="left-line"
    >
    </div>
    <div
      v-show="!isSchedule"
      ref="editRef"
      class="icon-wrapper"
    >
      <div
        v-if="!isRead"
        :class="['item-box', isEditTvFolder ? 'item-box-tv' : '']"
        @click="showItemBox"
      >
        <inline-svg
          :src="isLock ? iconLock : iconItems"
          :class="['svg-icon', isLock ? 'icon-lock' : 'icon-items' ]"
          :title="!isLock ? 'More Details' : ''"
        >
        </inline-svg>
      </div>
      <div
        v-show="!isLock && isItemBox"
        class="items-box"
      >
        <div
          class="edit-box"
          @click="handleEditClick"
        >
          <inline-svg
            :src="iconEdit"
            class="svg-icon"
          >
          </inline-svg>
          <span class="edit-title">
            Edit
          </span>
        </div>
        <div
          class="edit-box"
          @click="handleRemoveClick"
        >
          <inline-svg
            :src="iconRemove"
            class="svg-icon"
          >
          </inline-svg>
          <span class="remove-title">
            Delete
          </span>
        </div>
        <div
          class="edit-box"
          @click="goToProtection"
        >
          <inline-svg
            :src="iconBrushire"
            class="svg-icon"
          >
          </inline-svg>
          <span class="remove-title">
            Protection
          </span>
        </div>
        <div
          v-if="isAbleJumpToCollection"
          class="edit-box"
          @click="jumpToCollection()"
        >
          <inline-svg
            :src="iconSource"
            class="svg-icon"
          >
          </inline-svg>
          <span class="remove-title">
            Jump to Collection
          </span>
        </div>
      </div>
    </div>
    <div :class="['element-wrapper', level === 1 ? 'first-elem' : '' ]">
      <MaterialCheckbox
        v-if="isShowCheckbox"
        :isShortLabel="true"
        :isAlternative="true"
        :toggle="toggleTvApps"
        :checked="tvAppsEnabled"
      />
      <div
        :class="['element', Number(selectedFolder) === id ? 'selected-folder' : '' ]"
        @click="selectFolder"
      >
        <div
          :class="['folder-name',
                   isEditTvFolder && name === 'All media' && 'all-folders-name',
                   name === 'All media' && 'folder-name-all']"
        >
          <inline-svg
            v-if="level !== 1"
            :src="getIcon"
            class="svg-icon"
          >
          </inline-svg>
          <drop
            :id="id"
            class="folder-title"
            :title="name"
            @drop="handleDrop"
          >
            {{ getTitle(name) }}
          </drop>
          <inline-svg
            :src="getIconMetadata"
            class="svg-icon icon-live-tv"
          >
          </inline-svg>
          <inline-svg
            v-if="hasProtection && getCurrentAccount.brushfireProtectionEnabled"
            :src="iconProtection"
            class="svg-icon icon-live-tv brushh"
            title="Protected"
          >
          </inline-svg>
          <inline-svg
            v-if="getCurrentAccount.tvAppsEnabled && tvAppsEnabled"
            :src="iconLiveTv"
            class="svg-icon icon-live-tv"
          >
          </inline-svg>
        </div>
      </div>
    </div>
    <FolderProtectionModal
      v-if="isFolderProtectionOpen"
      :onClose="onCloseLProtectionModal"
    />
  </li>
</template>
<script>
import { Drop } from 'vue-drag-drop';
import {
  mapState,
  mapGetters,
} from 'vuex';
import { MOVE_MEDIA_ITEM_REQUEST } from '../../../store/actions/media/mediaItem';
import CONFIG from '../../../constants/config';

import iconFolderMain from '../../../assets/icons/media-folder-main.svg';
import iconFolder from '../../../assets/icons/media-folder.svg';
import iconLock from '../../../assets/icons/media-lock.svg';
import iconItems from '../../../assets/icons/media-items.svg';
import iconFolderPlus from '../../../assets/icons/media-folder-plus.svg';
import iconFolderMinus from '../../../assets/icons/media-folder-minus.svg';
import iconRemove from '../../../assets/icons/icon-remove.svg';
import iconEdit from '../../../assets/icons/icon-edit.svg';
import iconMeta from '../../../assets/icons/icon-metadata.svg';
import iconLiveTv from '../../../assets/icons/icon-live-tv-folder.svg';
import iconProtection from '../../../assets/icons/icon-brush-protection-folder.svg';
import iconSeries from '../../../assets/icons/icon-series-folder.svg';
import iconEpisode from '../../../assets/icons/icon-episode-folder.svg';
import iconEvent from '../../../assets/icons/icon-event-folder.svg';
import iconSeasons from '../../../assets/icons/icon-seasons-folder.svg';
import iconFeature from '../../../assets/icons/icon-feature-folder.svg';
import iconChildFolder from '../../../assets/icons/icon-child-folder.svg';
import iconFolderTreeChild from '../../../assets/icons/icon-folder-tree-child.svg';
import iconBrushire from '../../../assets/icons/icon-brushfire-metada.svg';
import iconSource from '../../../assets/icons/icon-source-metadata.svg';
import iconPlaylist from '../../../assets/icons/icon-playlist-folder.svg';

import MaterialCheckbox from '../../inputs/MaterialCheckbox.vue';
import Loader from '../../common/Loader.vue';
import FolderProtectionModal from '../../FolderProtectionModal.vue';

const DELETE = 'delete';
const EDIT = 'edit';

export default {
  name: 'MediaTreeItem',
  components: {
    FolderProtectionModal,
    Loader,
    Drop,
    MaterialCheckbox,
  },
  props: {
    mediaList: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    isLock: {
      type: Boolean,
      default: false,
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: null,
    },
    selectFolder: {
      type: Function,
      default: () => {},
    },
    openModal: {
      type: Function,
      default: () => {},
    },
    parent: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    folderId: {
      type: String,
      default: '',
    },
    uncFolderId: {
      type: Number,
      default: null,
    },
    getMediaList: {
      type: Function,
      default: () => {},
    },
    isSchedule: {
      type: Boolean,
      default: false,
    },
    tvAppsEnabled: {
      type: Boolean,
      default: false,
    },
    toggleTvApps: {
      type: Function,
      default: () => {},
    },
    isEditTvFolder: {
      type: Boolean,
      default: false,
    },
    hasProtection: {
      type: Boolean,
      default: false,
    },
    metadata: {
      type: Object,
      default: () => {},
    },
    playlist: {
      type: Object,
      default: () => {},
    },
    season: {
      type: Object,
      default: () => {},
    },
    feature: {
      type: Object,
      default: () => {},
    },
    children: {
      type: Array,
      default: () => [],
    },
    collectionType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconFolderMain,
      iconFolder,
      iconLock,
      iconItems,
      iconFolderPlus,
      iconFolderMinus,
      iconEdit,
      iconRemove,
      iconMeta,
      iconLiveTv,
      iconProtection,
      iconSeries,
      iconEpisode,
      iconEvent,
      iconSeasons,
      iconFeature,
      iconChildFolder,
      iconFolderTreeChild,
      iconBrushire,
      iconSource,
      iconPlaylist,
      isItemBox: false,
      isFolderProtectionOpen: false,
    };
  },
  computed: {
    ...mapState({
      loaded: (state) => state.mediaTree.isLoadingMeta,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
      'getRootMediaFolderId',
      'isRead',
    ]),
    getIcon() {
      if (this.isLock) {
        return iconFolderMain;
      }

      if (this.hasChildren && this.isOpen) {
        return iconFolderMinus;
      }

      if (this.hasChildren && !this.isOpen) {
        return iconFolderPlus;
      }

      return iconFolder;
    },
    getIconMetadata() {
      if (this.collectionType === 'Series') {
        return iconSeries;
      }

      if (this.collectionType === 'season') {
        return iconSeasons;
      }

      if (this.collectionType === 'feature') {
        return iconFeature;
      }

      if (this.collectionType === 'Event') {
        return iconEvent;
      }

      if (this.collectionType === 'playlist') {
        return iconPlaylist;
      }

      return '';
    },
    selectedFolder() {
      return this.folderId;
    },
    isShowCheckbox() {
      return this.isEditTvFolder && this.level !== 1;
    },
    isAbleJumpToCollection() {
      return this.playlist || this.season || this.feature;
    },
  },
  created() {
    document.addEventListener('mousedown', this.clickOutside);
    if (this.tvAppsEnabled) {
      const arr = JSON.parse(localStorage.getItem('tvApps')) || [];
      const currentTvApp = arr.find((item) => item === this.is);
      if (!currentTvApp) {
        arr.push(this.id);
      }
      localStorage.setItem('tvApps', JSON.stringify([...new Set(arr)]));
    }
  },
  destroyed() {
    document.removeEventListener('mousedown', this.clickOutside);
  },
  methods: {
    getTitle(name) {
      return name.length > 20 ? `${name.slice(0, 20)}...` : name;
    },
    handleDrop(value, e) {
      const params = {
        accountId: this.getCurrentAccountId,
        rootMediaFolderId: this.getRootMediaFolderId,
        data: {
          mediaFolder: e.target.id,
          mediaItem: value,
        },
      };
      this.$store.dispatch(MOVE_MEDIA_ITEM_REQUEST, params)
        .then(() => {
          let page = localStorage.getItem('currentPage-media-list') || 1;
          if (this.mediaList.length === 1 && localStorage.getItem('currentPage-media-list') !== '1') {
            page = Number(localStorage.getItem('currentPage-media-list')) - 1;
          }
          this.getMediaList(page);
        })
        .catch(() => {
          this.$toasted.global.error({
            message: CONFIG.errorMessages.dndErrorMessage,
          });
        });
    },
    clickOutside(e) {
      if (this.$refs.editRef && !this.$refs.editRef.contains(e.target)) {
        this.isItemBox = false;
      }
    },
    showItemBox() {
      this.selectFolder();
      this.isItemBox = !this.isItemBox;
    },
    handleEditClick() {
      this.openModal(EDIT, {
        id: this.id,
        name: this.name,
        parent: this.parent,
      });
      this.isItemBox = false;
    },
    handleRemoveClick() {
      this.openModal(DELETE, {
        id: this.id,
        name: this.name,
        parent: this.parent.id,
        type: this.type,
        hasChildren: this.hasChildren,
        uncFolderId: this.uncFolderId,
      });
      this.isItemBox = false;
    },
    jumpToCollection() {
      let url = null;
      if (this.playlist && this.playlist.id) {
        url = `/collections/playlists/${this.playlist.id}`;
      } else if (this.season && this.season.series && this.season.id) {
        url = `/collections/series/${this.season.series.id}/seasons/${this.season.id}`;
      } else if (this.feature && this.feature.id) {
        url = `/collections/features/${this.feature.id}`;
      }

      if (url) {
        window.open(url, '_blank');
      }
    },
    onCloseLProtectionModal() {
      this.isFolderProtectionOpen = false;
    },
    goToProtection() {
      this.isFolderProtectionOpen = true;
    },
  },
};
</script>
<style scoped lang="scss">
  .element-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }

  .children-folders::after {
    height: 0;
  }

  .item-first .element-wrapper::after,
  .item-first .element-wrapper::before {
    display: none;
  }

  .media-tree-item {
    align-items: center;
    display: flex;
    padding: 5px 0 5px 0;
  }

  .media-tree-item-color {
    padding: 5px 0 5px 0;
  }

  .item-checkbox {
    padding-top: 0;
    padding-left: 0;
  }

  .item-checkbox:not(.item-first) .element {
    margin-left: 0;
    margin-bottom: -7px;
  }

  .media-tree-item:hover {
    background-color: transparent;
  }

  .icon-wrapper {
    left: 14px;
    position: absolute;
  }

  .icon-wrapper:hover {
    cursor: pointer;
  }

  .icon-wrapper-border {
    left: -21px;
  }

  .icon-items-tv {
    margin-left: -5px !important;
  }

  .icon-items {
    margin-left: 3px;
  }

  .folder-name {
    align-items: center;
    clear: both;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
  }

  .selected-folder {
    color: #22a5fd;
  }

  .items-box {
    background-color: #282a2e;
    position: absolute;
    z-index: 2;
  }

  .edit-box {
    align-items: center;
    display: flex;
    font-size: 14px;
    padding: 10px 20px;
  }

  .item-box {
    padding: 0 5px 1px 0;
  }

  .edit-box:hover {
    background-color: #3d3e4178;
  }

  .edit-title {
    padding-left: 5px;
  }

  .remove-title {
    padding-left: 10px;
  }

  .folder-title {
    float: right;
    padding-left: 5px;
    margin-top: 3px;
  }

  .spread {
    left: 20px;
    position: absolute;
  }

  .meta {
    height: 17px;
    width: 14px;
  }

  .icon-live-tv {
    margin-left: 10px;
    margin-top: 1px;
  }

  .brushh {
    cursor: default;
  }

  .plus-wrapper {
    margin-left: -10px;
    position: relative;
  }
  .plus-wrapper-tv {
    margin-right: 12px;
    margin-left: -10px !important;
    padding-left: 8px;
  }

  .plus-wrapper-tv::before {
    width: 0;
  }

  .plus-wrapper-tv::after {
    width: 0;
  }

  .not-main-folders {
    margin-left: 1px;
  }

  .not-main-folders-children {
    margin-left: 0;
  }

  .item-child {
    padding-left: 0;
  }

  .folders-child {
    margin-left: 1px;
  }

  .folders-child-a::before {
    width: 0;
  }

  .plus-wrapper-ch {
    margin-left: -2px;
  }

  .icon-lock {
    margin-left: -2px;
  }

  .plus-icon {
    padding-top: 12px;
  }

  .plus {
    cursor: pointer;
    margin-top: 1px;
    margin-bottom: -2px;
  }

  .not-main-folders-children-tv {
    margin-bottom: -7px;
  }

  .icon-items-margin-tv {
    margin-bottom: -7px;
  }

  .all-folders-name {
    margin-left: 8px;
  }

  .folder-name-all {
    text-transform: capitalize;
  }

  .first-elem {
    padding-left: 32px;
  }

  .item-box-tv {
    padding-top: 10px;
  }
</style>
