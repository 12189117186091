<template>
  <div class="overlay add-fixed-item">
    <div class="wrapper">
      <p
        class="headline grey lighten-2"
        primaryTitle
      >
        {{ titleModal }}
      </p>
      <div class="live-search">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="100"
        />
      </div>
      <div
        ref="fixedFeeds"
        class="results"
      >
        <Loader
          :isVisible="isLoading"
          :className="'inspector-loader'"
        />
        <div v-if="fixedItems.length">
          <div
            v-for="item in fixedItems"
            :key="item.id"
          >
            <div class="fixed-item-row">
              <div class="preview">
                <FeedPreviewItem
                  :item="item"
                  :isFixedMedia="isFixedMedia"
                />
              </div>
              <div class="button-item">
                <Button
                  :title="'Add'"
                  :smallSize="true"
                  :classType="'primary'"
                  :eventFunction="() => onAddFixedItem(item)"
                  :disabled="!addItemAllowed"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="empty-search"
        >
          {{ 'No items to display' }}
        </div>
      </div>
      <div class="button-wrapper">
        <Button
          :title="'Close'"
          :classType="'primary grey'"
          :eventFunction="onClose"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LiveSearch from '../common/LiveSearch.vue';
import { GET_FIXED_MEDIA_ITEMS, GET_LIVE_EVENTS_REQUEST } from '../../store/actions/feedsActions/feedsActions';
import FeedPreviewItem from './FeedPreviewItem.vue';
import Button from '../common/Button.vue';
import Loader from '../common/Loader.vue';

export default {
  name: 'FeedModal',
  components: {
    LiveSearch,
    FeedPreviewItem,
    Button,
    Loader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onSelectFixedItem: {
      type: Function,
      default: () => {},
    },
    selectedItems: {
      type: Array,
      default: null,
    },
    addItemAllowed: {
      type: Boolean,
      default: true,
    },
    itemTypes: {
      type: Array,
      default: () => [],
    },
    newSource: {
      type: Boolean,
      default: false,
    },
    isFixedMedia: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fixedItems: [],
      filterValue: '',
      currentPage: 1,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.feeds.isFixedItemsLoading,
      feedModalPagination: (state) => state.feeds.feedModalPagination,
    }),
    titleModal() {
      if (this.newSource) {
        return 'Select New Source';
      }

      if (!this.newSource && this.isFixedMedia) {
        return 'Add Fixed Media';
      }

      if (!this.newSource && !this.isFixedMedia) {
        return 'Add Fixed Event';
      }

      return '';
    },
  },
  mounted() {
    if (this.isFixedMedia) {
      this.getFixedItems(1);
    } else {
      this.getFixedEvents(1);
    }

    const listElm = this.$refs.fixedFeeds;
    listElm.addEventListener('scroll', this.getItems);
  },
  beforeDestroy() {
    const listElm = this.$refs.fixedFeeds;
    listElm.removeEventListener('scroll', this.getItems);
  },
  methods: {
    onSearch(params) {
      this.filterValue = params.filterValue;
      if (this.isFixedMedia) {
        this.getFixedItems(1);
      } else {
        this.getFixedEvents(1);
      }
      this.currentPage = 1;
    },
    getFixedItems(page) {
      const searchParams = {
        accountId: this.accountId,
        filterValue: this.filterValue,
        fixedItems: this.selectedItems,
        page,
        types: this.itemTypes,
      };
      this.$store.dispatch(GET_FIXED_MEDIA_ITEMS, searchParams)
        .then((res) => {
          this.fixedItems = res.data;
        });
    },
    getFixedEvents(page) {
      const searchParams = {
        accountId: this.accountId,
        filterValue: this.filterValue,
        fixedItems: this.selectedItems,
        page,
      };
      this.$store.dispatch(GET_LIVE_EVENTS_REQUEST, searchParams)
        .then((res) => {
          this.fixedItems = res.data;
        });
    },
    onAddFixedItem(item) {
      this.onSelectFixedItem(item);
      if (this.isFixedMedia) {
        this.getFixedItems(this.currentPage);
      } else {
        this.getFixedEvents(this.currentPage);
      }
    },
    getItems() {
      const total = Number(this.feedModalPagination.total);
      const defaultLength = 20;
      const listElm = this.$refs.fixedFeeds;
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.currentPage += 1;

        let maxPage = parseInt(total / defaultLength, 10);
        if (total % defaultLength > 0) {
          maxPage += 1;
        }

        if (maxPage >= this.currentPage) {
          if (this.isFixedMedia) {
            this.getFixedItems(this.currentPage);
          } else {
            this.getFixedEvents(this.currentPage);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .wrapper {
    background-color: #222;
    border-radius: 4px;
    display: block;
    height: 415px;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    padding: 30px;
    position: absolute;
    text-decoration: none;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding-bottom: 30px;
    word-break: break-all;
  }

  .empty-search {
    color: #4c5057;
    padding-top: 40px;
    text-align: center;
  }

  .results {
    height: 240px;
    overflow-x: hidden;
    padding-right: 5px;
  }

  .live-search {
    padding-bottom: 10px;
  }

  .fixed-item-row {
    display: flex;
  }

  .preview {
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 85%;
  }

  .button-item {
    margin: 0 0 0 25px;
    padding-top: 5px;
  }

  .add-fixed-item .search-wrapper {
    width: 100%;
  }
</style>
