<template>
  <div>
    <div
      v-for="(item, index) in items"
      :key="item.id"
    >
      <div class="person-role">
        <PeopleAndRolesRow
          :index="index"
          :contributorRole="item.contributorRole"
          :contributorPerson="item.contributorPerson"
          :withEmptyField="true"
          :roleList="roleList"
          :personList="personList"
          :onRemoveItem="onRemoveItem"
          :onChangePerson="onChangePerson"
          :onChangeRole="onChangeRole"
          :disabled="disabled"
          :formPrefix="formPrefix"
        />
      </div>
    </div>
    <div class="person-role-new">
      <div class="btn-row">
        <Button
          v-if="!isRead"
          :title="'Add'"
          :classType="'primary'"
          :type="'button'"
          :eventFunction="onAddItem"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  GET_PERSON_LIST,
  GET_ROLE_LIST,
} from '../../../store/actions/media/mediaItem';
import PeopleAndRolesRow from './PeopleAndRolesRow.vue';
import Button from '../../common/Button.vue';

export default {
  name: 'PeopleAndRoles',
  components: {
    PeopleAndRolesRow,
    Button,
  },
  props: {
    formPrefix: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    onRemoveItem: {
      type: Function,
      default: () => {},
    },
    onChangePerson: {
      type: Function,
      default: () => {},
    },
    onChangeRole: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      roleList: [],
      personList: [],
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
      'isRead',
    ]),
  },
  created() {
    this.getRoleList();
    this.getPersonList();
  },
  methods: {
    onAddItem() {
      const contributorRole = { id: null };
      const contributorPerson = { id: null };
      this.onChange({
        contributorRole,
        contributorPerson,
      });
    },
    getRoleList() {
      this.$store.dispatch(GET_ROLE_LIST, this.getCurrentAccountId)
        .then((res) => {
          this.roleList = res.data.map((i) => ({
            name: i.name,
            code: i.id,
          }));
        });
    },
    getPersonList() {
      this.$store.dispatch(GET_PERSON_LIST, this.getCurrentAccountId)
        .then((res) => {
          this.personList = res.data.map((item) => ({
            name: item.fullname,
            code: item.id,
          }));
        });
    },
  },
};
</script>
<style scoped>
  .btn-row {
    display: flex;
    justify-content: flex-end;
  }
</style>
