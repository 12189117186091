<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="edit-button-wrapper">
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backTo"
        />
        <Button
          :title="isEdit ? 'Apply' : 'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="breadcrumbs">
        <span class="system">
          System Management / Custom Fields
        </span>
        <span v-if="!isEdit">
          / Add Custom Field
        </span>
        <span
          v-else
        >
          / Edit {{ formData.name }}
        </span>
      </div>
      <div class="title">
        {{ isEdit ? 'Custom Field' : 'Add Custom Field' }}
      </div>
      <MaterialInput
        v-model="formData.name"
        v-bind="formFields.name"
      />
      <MaterialInput
        v-model="formData.shortName"
        v-bind="formFields.shortName"
      />
      <MaterialTextArea
        v-model="formData.description"
        v-bind="formFields.description"
      />
      <MaterialSelect
        v-model="formData.entryType"
        v-bind="formFields.entryType"
        :options="entryTypes"
        :disabled="isEdit"
      />
      <MaterialSelect
        :key="`entryType${formData.entryType}`"
        v-model="formData.structure"
        v-bind="formFields.structure"
        :options="structures"
        :disabled="isEdit"
      />
      <div
        v-if="selectionAvailable"
        class="selections-block"
      >
        <label>
          Selections
          <span class="asterisk">*</span>
        </label>
        <div
          v-for="(item, index) in formData.selections"
          :key="`selections${index}`"
        >
          <div class="field-selection-row">
            <FieldSelectionRow
              :index="index"
              :value="item.value"
              :name="item.name"
              :anchored="item.anchored"
              :onRemoveItem="onRemoveSelectionItem"
              :onChangeValue="onChangeSelectionValue"
              :onChangeName="onChangeSelectionName"
              :onChangeAnchored="onChangeSelectionAnchored"
              :formPrefix="formPrefix"
              :buttonsDisabled="!selectionDeleteButtonAvailable"
              :valuesDisabled="!selectionValuesAvailable"
              :disabled="item.id && isEdit"
              :valuesNumeric="integerEntryType"
            />
          </div>
        </div>
        <div class="field-selection-new">
          <div class="btn-row">
            <Button
              :title="'Add'"
              :classType="'primary'"
              :type="'button'"
              :eventFunction="onAddSelectionItem"
              :disabled="!selectionAddButtonAvailable"
            />
          </div>
        </div>
      </div>
      <MaterialCheckbox
        v-if="selectionAvailable"
        :toggle="toggleSorting"
        :label="'Sorting'"
        :checked="formData.sortSelection"
        class="checkbox"
        :isDisabled="isEdit"
      />
      <div
        v-if="entryType && structure"
      >
        <MaterialSelect
          v-if="(selectionAvailable && !selectionHasCustomValue) || booleanEntryType"
          v-model="formData.defaultValue"
          v-bind="formFields.defaultValue"
          :options="defaultSelectionOptions"
        />
        <MaterialCalendar
          v-else-if="dateEntryType"
          v-model="formData.defaultValue"
          v-bind="formFields.defaultValue"
          blockClassName="group"
        />
        <MaterialTime
          v-else-if="timeEntryType"
          v-model="formData.defaultValue"
          v-bind="formFields.defaultValue"
          :format="'HH:mm:ss'"
          class="field-row"
        />
        <MaterialDateTime
          v-else-if="dateTimeEntryType"
          v-model="formData.defaultValue"
          v-bind="formFields.defaultValue"
          blockClassName="group"
        />
        <MaterialInput
          v-else
          v-model="formData.defaultValue"
          v-bind="formFields.defaultValue"
          :rules="defaultValueRules"
        />
      </div>
      <MaterialInput
        v-model="formData.label"
        v-bind="formFields.label"
      />
      <MaterialSelect
        v-model="formData.context"
        v-bind="formFields.context"
        :options="contexts"
      />
      <MaterialSelect
        v-model="formData.appearance"
        v-bind="formFields.appearance"
        :options="appearances"
      />
      <MaterialCheckbox
        :toggle="toggleRequired"
        :label="'Required'"
        :checked="formData.required"
        class="checkbox"
      />
      <div
        v-if="isEdit"
        class="editor-info"
      >
        <div class="editor-info-title title">
          Editor Info
        </div>
        <div class="editor-info-items">
          <div class="editor-info-item">
            <div class="editor-item-title">
              Created:
            </div>
            <div class="editor-item-description">
              {{ originalData.createdAt | timezone(getMainProfile.timezone) }}
            </div>
          </div>

          <div class="editor-info-item">
            <div class="editor-item-title">
              Updated:
            </div>
            <div class="editor-item-description">
              {{ originalData.updatedAt | timezone(getMainProfile.timezone) }}
            </div>
          </div>

          <div class="editor-info-item">
            <div class="editor-item-title">
              Created By:
            </div>
            <div class="editor-item-description">
              {{ originalData.createdBy | userInfo }}
            </div>
          </div>

          <div class="editor-info-item">
            <div class="editor-item-title">
              Updated By:
            </div>
            <div class="editor-item-description">
              {{ originalData.updatedBy | userInfo }}
            </div>
          </div>

          <div class="editor-info-item">
            <div class="editor-item-title">
              Version:
            </div>
            <div class="editor-item-description">
              {{ originalData.version }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import moment from 'moment';
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  ADD_CUSTOM_FIELD_REQUEST,
  EDIT_CUSTOM_FIELD_REQUEST,
  GET_CUSTOM_FIELD_REQUEST,
  GET_CUSTOM_FIELD_OPTIONS_REQUEST,
} from '../../../store/actions/fields/field';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import MaterialTextArea from '../../inputs/MaterialTextArea.vue';
import MaterialCheckbox from '../../inputs/MaterialCheckbox.vue';
import MaterialCalendar from '../../inputs/MaterialCalendar.vue';
import MaterialTime from '../../inputs/MaterialTime.vue';
import MaterialDateTime from '../../inputs/MaterialDateTime.vue';
import Button from '../../common/Button.vue';
import FieldSelectionRow from '../../customFields/FieldSelectionRow.vue';

export default {
  name: 'CustomFieldForm',
  components: {
    FieldSelectionRow,
    MaterialTextArea,
    MaterialInput,
    MaterialSelect,
    MaterialCheckbox,
    MaterialCalendar,
    MaterialTime,
    MaterialDateTime,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'customField',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHasId: true,
      isMounted: false,
      formData: {
        name: '',
        shortName: '',
        description: '',
        entryType: '',
        structure: '',
        selections: [],
        sortSelection: false,
        defaultValue: '',
        label: '',
        context: '',
        appearance: '',
        required: false,
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        shortName: {
          type: 'text',
          name: 'shortName',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
            alpha_num: 'en',
          },
        },
        description: {
          type: 'text',
          name: 'description',
          formPrefix: this.formPrefix,
          cols: 30,
          rows: 10,
          rules: {
            max: 500,
          },
        },
        entryType: {
          type: 'text',
          name: 'entryType',
          formPrefix: this.formPrefix,
          withEmptyField: false,
          rules: {
            required: true,
          },
        },
        structure: {
          type: 'text',
          name: 'structure',
          formPrefix: this.formPrefix,
          withEmptyField: false,
          rules: {
            required: true,
          },
        },
        selections: {
          type: 'text',
          name: 'selections',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        sortSelection: {
          type: 'text',
          name: 'sortSelection',
          formPrefix: this.formPrefix,
        },
        defaultValue: {
          type: 'text',
          name: 'defaultValue',
          formPrefix: this.formPrefix,
          rules: {
            max: 255,
          },
        },
        label: {
          type: 'text',
          name: 'label',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        required: {
          type: 'text',
          name: 'required',
          formPrefix: this.formPrefix,
        },
        context: {
          type: 'text',
          name: 'context',
          formPrefix: this.formPrefix,
          withEmptyField: false,
          rules: {
            required: true,
          },
        },
        appearance: {
          type: 'text',
          name: 'appearance',
          formPrefix: this.formPrefix,
          withEmptyField: false,
          rules: {
            required: true,
          },
        },
      },
      originalData: {},
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        shortName: [
          `${this.formPrefix}.shortName`,
        ],
        description: [
          `${this.formPrefix}.description`,
        ],
        entryType: [
          `${this.formPrefix}.entryType`,
        ],
        structure: [
          `${this.formPrefix}.structure`,
        ],
        selections: [
          `${this.formPrefix}.selections.*`,
        ],
        sortSelection: [
          `${this.formPrefix}.sortSelection`,
        ],
        defaultValue: [
          `${this.formPrefix}.defaultValue`,
        ],
        label: [
          `${this.formPrefix}.label`,
        ],
        required: [
          `${this.formPrefix}.required`,
        ],
        context: [
          `${this.formPrefix}.context`,
        ],
        appearance: [
          `${this.formPrefix}.appearance`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_CUSTOM_FIELD_REQUEST : ADD_CUSTOM_FIELD_REQUEST,
      successMessage: this.isEdit
        ? CONFIG.successMessages.editCustomFieldMessage
        : CONFIG.successMessages.addCustomFieldMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      entryTypes: (state) => state.fields.entryTypes,
      contexts: (state) => state.fields.contexts,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getMainProfile',
    ]),
    structures() {
      const entryType = this.getEntryTypeObject();

      if (entryType && entryType.choices) {
        return entryType.choices;
      }

      return [];
    },
    appearances() {
      const context = this.getContextObject();

      if (context && context.choices) {
        return context.choices;
      }

      return [];
    },
    selectionHasCustomValue() {
      const custom = [
        'dropdown_custom',
        'list_custom',
        'radio_custom',
      ];

      return custom.includes(this.formData.structure);
    },
    selectionAvailable() {
      return this.structureOptions.selectable;
    },
    structureOptions() {
      const structure = this.getStructureObject();

      return structure ?? {};
    },
    selectionAddButtonAvailable() {
      return this.selectionAvailable
        && this.formData.selections.length < this.structureOptions.max
        && !this.booleanEntryType;
    },
    selectionDeleteButtonAvailable() {
      return this.selectionAvailable
        && this.formData.selections.length > this.structureOptions.min
        && !this.booleanEntryType;
    },
    selectionValuesAvailable() {
      return this.selectionAvailable && !this.booleanEntryType;
    },
    entryType() {
      return this.formData.entryType;
    },
    structure() {
      return this.formData.structure;
    },
    context() {
      return this.formData.context;
    },
    appearance() {
      return this.formData.appearance;
    },
    name() {
      return this.formData.name;
    },
    booleanEntryType() {
      return this.entryType === 'boolean';
    },
    dateEntryType() {
      return this.entryType === 'date';
    },
    timeEntryType() {
      return this.entryType === 'time';
    },
    dateTimeEntryType() {
      return this.entryType === 'datetime';
    },
    integerEntryType() {
      return this.entryType === 'integer';
    },
    durationEntryType() {
      return this.entryType === 'duration';
    },
    valueStructure() {
      return this.structure === 'value';
    },
    booleanSelection() {
      return this.booleanEntryType && !this.valueStructure;
    },
    defaultSelectionOptions() {
      if (this.selectionAvailable) {
        return this.formData.selections.map((item) => ({
          code: item.value,
          name: item.name ? item.name : item.value,
        }));
      }
      if (this.booleanEntryType && this.valueStructure) {
        return [{
          code: 'true',
          name: 'True',
        }, {
          code: 'false',
          name: 'False',
        }];
      }

      return [];
    },
    defaultValueRules() {
      const rules = { ...this.formFields.defaultValue.rules };

      if (this.integerEntryType || this.durationEntryType) {
        rules.numeric = true;
      }

      return rules;
    },
  },
  watch: {
    entryType(newValue, oldValue) {
      if (this.isMounted && newValue !== oldValue) {
        this.updateSelections(true);
      }
    },
    structure(newValue, oldValue) {
      if (this.isMounted && newValue !== oldValue) {
        this.updateSelections(false);
      }
    },
    name(newValue, oldValue) {
      if (
        this.isMounted
        && !this.isEdit
        && newValue !== oldValue
        && (!this.formData.shortName || this.formData.shortName === oldValue.replace(/([^a-z0-9]+)/gi, ''))
      ) {
        this.formData.shortName = newValue.replace(/([^a-z0-9]+)/gi, '');
      }
    },
    context(newValue, oldValue) {
      if (this.isMounted && newValue !== oldValue) {
        this.formData.appearance = '';
      }
    },
  },
  mounted() {
    this.$store.dispatch(GET_CUSTOM_FIELD_OPTIONS_REQUEST)
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          const {
            errorMessages,
            statuses,
          } = CONFIG;

          const params = {
            accountId: this.getCurrentAccountId,
            fieldId: this.$route.params.id,
          };

          this.$store.dispatch(GET_CUSTOM_FIELD_REQUEST, params)
            .then((res) => this.initFormData(res))
            .catch((err) => {
              if (err.error === errorMessages.unknown) {
                this.isHasId = false;
                this.$router.push(`/${statuses.notFound}`);
              }
            })
            .finally(() => {
              this.isMounted = true;
            });
        } else {
          this.isMounted = true;
        }
      });
  },
  methods: {
    initFormData(field) {
      this.originalData = { ...field };
      this.formData.name = field.name;
      this.formData.shortName = field.shortName;
      this.formData.description = field.description;
      this.formData.entryType = field.entryType;
      this.formData.structure = field.structure;
      this.formData.selections = field.selections;
      this.formData.sortSelection = field.sortSelection;
      this.formData.label = field.label;
      this.formData.required = field.required;
      this.formData.context = field.context;
      this.formData.appearance = field.appearance;

      if (field.defaultValue) {
        if (this.timeEntryType) {
          const date = moment(field.defaultValue, 'HH:mm:ss');
          if (date.isValid()) {
            this.formData.defaultValue = field.defaultValue;
          }
        } else if (this.dateTimeEntryType) {
          const date = moment(field.defaultValue, 'YYYY-MM-DD HH:mm:ss');
          if (date.isValid()) {
            this.formData.defaultValue = field.defaultValue;
          }
        } else if (this.dateEntryType) {
          const date = moment(field.defaultValue, 'YYYY-MM-DD');
          if (date.isValid()) {
            this.formData.defaultValue = date.toDate();
          }
        } else {
          this.formData.defaultValue = field.defaultValue;
        }
      } else {
        this.formData.defaultValue = field.defaultValue;
      }
    },
    getRequestData() {
      const data = {
        name: this.formData.name,
        shortName: this.formData.shortName,
        description: this.formData.description,
        entryType: this.formData.entryType,
        structure: this.formData.structure,
        selections: this.formData.selections.map((item) => ({
          value: item.value,
          name: item.name,
          anchored: item.anchored,
        })),
        sortSelection: this.formData.sortSelection,
        label: this.formData.label,
        required: this.formData.required,
        context: this.formData.context,
        appearance: this.formData.appearance,
      };

      if (this.formData.defaultValue) {
        if (this.dateEntryType) {
          const date = moment(this.formData.defaultValue);
          if (date.isValid()) {
            data.defaultValue = date.format('YYYY-MM-DD');
          }
        } else if (this.timeEntryType) {
          const date = moment(this.formData.defaultValue, 'HH:mm:ss');
          if (date.isValid()) {
            data.defaultValue = this.formData.defaultValue;
          }
        } else if (this.dateTimeEntryType) {
          const date = moment(this.formData.defaultValue);
          if (date.isValid()) {
            data.defaultValue = date.format('YYYY-MM-DD HH:mm:ss');
          }
        } else {
          data.defaultValue = this.formData.defaultValue;
        }
      }

      if (!data.entryType) {
        delete data.structure;
        delete data.defaultValue;
      }

      if (!this.selectionAvailable) {
        delete data.selections;
        delete data.sortSelection;
      }

      if (this.isEdit) {
        delete data.entryType;
        delete data.structure;
        delete data.sortSelection;
      }

      return {
        data,
        accountId: this.getCurrentAccountId,
        fieldId: this.$route.params.id,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.$router.push('/settings/fields');
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onAddSelectionItem() {
      this.formData.selections.push({
        value: '',
        name: '',
        anchored: false,
      });
    },
    onRemoveSelectionItem(index) {
      this.formData.selections.splice(index, 1);
    },
    onChangeSelectionValue(index, value) {
      this.formData.selections[index].value = value;
    },
    onChangeSelectionName(index, value) {
      this.formData.selections[index].name = value;
    },
    onChangeSelectionAnchored(index, value) {
      this.formData.selections[index].anchored = value;
    },
    toggleSorting(isChecked) {
      if (isChecked !== this.formData.sortSelection) {
        this.formData.sortSelection = isChecked;
      }
    },
    toggleRequired(isChecked) {
      if (isChecked !== this.formData.required) {
        this.formData.required = isChecked;
      }
    },
    getEntryTypeObject() {
      return this.entryTypes.find((item) => item.code === this.entryType);
    },
    getContextObject() {
      return this.contexts.find((item) => item.code === this.context);
    },
    getStructureObject() {
      const entryType = this.getEntryTypeObject();

      if (entryType && entryType.choices) {
        return entryType.choices.find((item) => item.code === this.formData.structure);
      }

      return null;
    },
    updateSelections(reset) {
      this.formData.defaultValue = '';

      const selections = [];

      if (this.booleanSelection) {
        selections.push({
          value: 'true',
          name: '',
          anchored: false,
        });
        selections.push({
          value: 'false',
          name: '',
          anchored: false,
        });
      } else if (this.selectionAvailable && (!this.formData.selections.length || reset)) {
        selections.push({
          value: '1',
          name: '1',
          anchored: false,
        });
        selections.push({
          value: '2',
          name: '2',
          anchored: false,
        });
      }

      if (selections.length || reset) {
        this.formData.selections = selections;
      }
    },
    backTo() {
      this.$router.push('/settings/fields');
    },
  },
};
</script>

<style scoped>
  .field-row {
    margin-right: 0;
  }

  .field-row,
  .selections-block {
    margin-bottom: 20px;
  }

  .checkbox {
    margin-bottom: 20px;
  }

  .asterisk {
    color: #fb3951;
  }

  .editor-info-item {
    padding: 5px 0;
  }

  .editor-item-title {
    display: inline-block;
  }

  .editor-item-description {
    display: inline-block;
    margin-left: 5px;
  }

  .edit-button-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 26px;
  }

  .breadcrumbs {
    margin-bottom: 25px;
  }

  .system {
    color: #4c5057;
  }
</style>
