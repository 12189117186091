import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  SET_CANCEL_TOKEN,
} from '../../actions/peopleActions/people';
import {
  ADD_ASSET_ROLE_ERROR,
  ADD_ASSET_ROLE_REQUEST,
  ADD_ASSET_ROLE_SUCCESS,
  CHANGE_ASSET_ROLE_STATUS_ERROR,
  CHANGE_ASSET_ROLE_STATUS_REQUEST,
  CHANGE_ASSET_ROLE_STATUS_SUCCESS,
  DELETE_ASSET_ROLE_ERROR,
  DELETE_ASSET_ROLE_REQUEST,
  DELETE_ASSET_ROLE_SUCCESS,
  EDIT_ASSET_ROLE_ERROR,
  EDIT_ASSET_ROLE_REQUEST,
  EDIT_ASSET_ROLE_SUCCESS,
  GET_ASSET_ROLE_ERROR,
  GET_ASSET_ROLE_ITEM_ERROR,
  GET_ASSET_ROLE_ITEM_REQUEST,
  GET_ASSET_ROLE_ITEM_SUCCESS,
  GET_ASSET_ROLE_REQUEST,
  GET_ASSET_ROLE_SUCCESS,
  GET_ASSET_ROLE_TYPES_ERROR,
  GET_ASSET_ROLE_TYPES_REQUEST,
  GET_ASSET_ROLE_TYPES_SUCCESS,
} from '../../actions/assetRoles/assetRolesActions';

const state = {
  isLoading: false,
  assetRolesList: [],
  pagination: {},
  assetRole: {},
  source: null,
  assetRoleTypes: [],
};

const getters = {
  assetTypes: () => state.assetRoleTypes,
};

const actions = {
  [GET_ASSET_ROLE_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    let url = `${params.accountId}/media/asset-role/list?p=${params.page}&l=15&filterField=ar.name&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_ASSET_ROLE_SUCCESS, resp.data);
        localStorage.setItem('assetRoles', resp.data.pagination.page);
        resolve();
      })
      .catch(() => {
        commit(GET_ASSET_ROLE_ERROR);
      });
  }),
  [GET_ASSET_ROLE_TYPES_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    const url = 'choice/asset-type';
    let dynamicParams = '';

    if (params && params.selectedTypes) {
      params.selectedTypes.forEach((item) => {
        dynamicParams += `&selectedTypes[]=${item.type}`;
      });
    }

    apiCall.get(`${url}?${dynamicParams}`)
      .then((resp) => {
        commit(GET_ASSET_ROLE_TYPES_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_ASSET_ROLE_TYPES_ERROR);
      });
  }),
  [ADD_ASSET_ROLE_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(ADD_ASSET_ROLE_REQUEST);

    apiCall.post(`${accountId}/media/asset-role`, data)
      .then((res) => {
        commit(ADD_ASSET_ROLE_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_ASSET_ROLE_ERROR, error);
        reject(error);
      });
  }),
  [GET_ASSET_ROLE_ITEM_REQUEST]:
    ({ commit }, { accountId, itemId }) => new Promise((resolve, reject) => {
      commit(GET_ASSET_ROLE_ITEM_REQUEST);

      const url = `${accountId}/media/asset-role/${itemId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_ASSET_ROLE_ITEM_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_ASSET_ROLE_ITEM_ERROR, error);
          reject(error);
        });
    }),
  [EDIT_ASSET_ROLE_REQUEST]:
    ({ commit }, { accountId, personId, data }) => new Promise((resolve, reject) => {
      commit(EDIT_ASSET_ROLE_REQUEST);

      apiCall.put(`${accountId}/media/asset-role/${personId}`, data)
        .then((resp) => {
          commit(EDIT_ASSET_ROLE_SUCCESS, resp);
          resolve(resp);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(EDIT_ASSET_ROLE_ERROR, error);
          reject(error);
        });
    }),
  [CHANGE_ASSET_ROLE_STATUS_REQUEST]:
    ({ commit }, { accountId, id, enabled }) => new Promise((resolve, reject) => {
      commit(CHANGE_ASSET_ROLE_STATUS_REQUEST);

      const url = `${accountId}/media/asset-role/${id}/enabled`;

      apiCall.put(url, { enabled })
        .then((res) => {
          commit(CHANGE_ASSET_ROLE_STATUS_SUCCESS);
          resolve(res.data);
        })
        .catch((err) => {
          const error = err.response.data.form_errors.children.enabled.errors.length;
          commit(CHANGE_ASSET_ROLE_STATUS_ERROR, error);
          reject(error);
        });
    }),
  [DELETE_ASSET_ROLE_REQUEST]:
    ({ commit }, { accountId, id }) => new Promise((resolve, reject) => {
      commit(DELETE_ASSET_ROLE_REQUEST);

      const url = `${accountId}/media/asset-role/${id}`;

      apiCall.delete(url)
        .then(() => {
          commit(DELETE_ASSET_ROLE_SUCCESS);
          resolve();
        })
        .catch((error) => {
          commit(DELETE_ASSET_ROLE_ERROR, error);
          reject(error);
        });
    }),
};

const mutations = {
  [GET_ASSET_ROLE_SUCCESS]: (state, payload) => {
    state.assetRolesList = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  [GET_ASSET_ROLE_TYPES_SUCCESS]: (state, payload) => {
    state.assetRoleTypes = payload.data.map((item) => ({
      code: item,
      name: item,
    }));
  },
  [ADD_ASSET_ROLE_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_ASSET_ROLE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_ASSET_ROLE_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_ASSET_ROLE_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_ASSET_ROLE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_ASSET_ROLE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ASSET_ROLE_ITEM_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_ASSET_ROLE_ITEM_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_ASSET_ROLE_ITEM_ERROR]: () => {
    state.isLoading = false;
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
  },
  [CHANGE_ASSET_ROLE_STATUS_REQUEST]: () => {
    state.isLoading = true;
  },
  [CHANGE_ASSET_ROLE_STATUS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CHANGE_ASSET_ROLE_STATUS_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_ASSET_ROLE_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_ASSET_ROLE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_ASSET_ROLE_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
