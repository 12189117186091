<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="title modal">
        Add Page
      </div>
      <MaterialInput
        v-model="formData.name"
        v-bind="formFields.name"
      />
      <MaterialInput
        v-model="formData.label"
        v-bind="formFields.label"
      />
      <MaterialSelect
        v-model="formData.format"
        v-bind="formFields.format"
        :options="optionsFormats"
        :withEmptyField="false"
      />
      <div :class="[isEdit ? 'edit-button-wrapper' : 'end-buttons']">
        <div style="display: flex">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="backToManually"
          />
          <Button
            :title="'Add'"
            :classType="'primary'"
            :type="'submit'"
          />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';
import iconWarning from '../../../assets/icons/icon-warning-localization.svg';

import {
  CREATE_PAGE_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';

export default {
  name: 'AddCmsPageForm',
  components: {
    MaterialSelect,
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'addPageForm',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    openedLocalization: {
      type: Function,
      default: () => {},
    },
    onSubmitManually: {
      type: Function,
      default: () => {},
    },
    isEdItItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsFormats: [
        {
          code: 'rows',
          name: 'Rows',
        },
        {
          code: 'grid',
          name: 'Grid',
        },
        {
          code: 'live',
          name: 'Live Countdown/Player',
        },
      ],
      iconWarning,
      manuallyItem: {},
      iconFolderLocalization,
      isOpenLocalization: false,
      enabledLanguages: [],
      isModalOpen: false,
      bodyMessage: CONFIG.modals.deleteManuallyRowInCms,
      rowItemsList: [],
      cmsTabsList: {},
      isPosterChanged: false,
      isBackgroundChanged: false,
      formData: {
        name: '',
        label: '',
        format: '',
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 25,
          },
        },
        label: {
          type: 'text',
          name: 'label',
          formPrefix: this.formPrefix,
          rules: {
            max: 25,
            required: true,
          },
        },
        format: {
          type: 'text',
          name: 'format',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        folderId: [
          `${this.formPrefix}.targetMediaFolder`,
        ],
      },
      submitEvent: CREATE_PAGE_REQUEST,
      successMessage: 'The Page has been successfully added',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  methods: {
    backToManually() {
      this.onClose();
    },
    onManuallyClose() {
      this.isModalOpen = false;
    },
    getRequestData() {
      const data = {
        name: this.formData.name,
        label: this.formData.label,
        format: this.formData.format,
      };

      return {
        data,
        accountId: this.getCurrentAccountId,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.onSubmitManually();
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      if ((error.form_errors && error.form_errors.children.row) || error.status === 404) {
        this.$router.push(`/cms/pages/${this.$route.params.id}`);
        return;
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>
.rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.toggles-wrapper {
  display: flex;
}

.toggle-wrapper,
.date-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
}

.toggle-wrapper {
  width: auto;
}

.toggle-title {
  margin-right: 10px;
}

.dynamic {
  margin-top: 30px;
  margin-bottom: 30px;
}

.edit-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.end-buttons {
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

.country-field {
  width: 300px;
}

.country-field:not(:last-child) {
  margin-right: 20px;
}

.wowza p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.wrapper-small {
  margin-top: 20px;
}

.flex {
  justify-content: space-between;
}

.person {
  margin-bottom: 20px;
}

.person p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.select-wrapper {
  width: 300px;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.localization-warning {
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}

.modal {
  color: inherit;
  margin-bottom: 30px;
}

@media all and (max-width: 1320px) {
  .flex {
    flex-wrap: wrap;
  }

  .country-field {
    margin-right: 0;
    width: 100%;
  }

  .country-field:not(:last-child) {
    margin-right: 0;
  }
}
</style>
