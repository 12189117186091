<template>
  <div :class="inspectorItem ? 'inspector-opened' : ''">
    <Loader :isVisible="isLoading || isLoadingAsset" />
    <div class="flex">
      <div class="left-path header-title">
        <h2
          v-if="mediaItem.name"
          :title="mediaItem.name"
          class="system"
        >
          {{ getTitle(mediaItem.name) }}
        </h2>
      </div>
      <div class="right-path">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="255"
        />
        <div class="btn-create-asset-full">
          <Button
            :title="'Add Asset'"
            :classType="'primary'"
            :eventFunction="openModal"
            :disabled="mediaItem.locked"
          />
        </div>
      </div>
    </div>
    <div class="assets-wrapper">
      <div class="assets-list-wrapper">
        <div class="ac-wrapper-header">
          <div class="th-name number">
            #
          </div>
          <div :class="['th-name filename', inspectorItem && 'inspector-padding']">
            <Sorting
              :sortField="sortedField.name"
              :currentSortedField="currentSortedField"
              :handleSortUp="handleSortUp"
              :handleSortDown="handleSortDown"
            />
            file name
          </div>
          <div :class="['th-name company', inspectorItem && 'inspector-list']">
            <Sorting
              :sortField="sortedField.type"
              :currentSortedField="currentSortedField"
              :handleSortUp="handleSortUp"
              :handleSortDown="handleSortDown"
            />
            asset type
          </div>
          <div :class="['th-name company format', inspectorItem && 'inspector-list']">
            <Sorting
              :sortField="sortedField.format"
              :currentSortedField="currentSortedField"
              :handleSortUp="handleSortUp"
              :handleSortDown="handleSortDown"
            />
            format
          </div>
          <div :class="['th-name company', inspectorItem && 'inspector-list']">
            <Sorting
              :sortField="sortedField.dimensions"
              :currentSortedField="currentSortedField"
              :handleSortUp="handleSortUp"
              :handleSortDown="handleSortDown"
            />
            dimension
          </div>
          <div :class="['th-name company', inspectorItem && 'inspector-list']">
            <Sorting
              :sortField="sortedField.filesize"
              :currentSortedField="currentSortedField"
              :handleSortUp="handleSortUp"
              :handleSortDown="handleSortDown"
            />
            file size
          </div>
          <div :class="['th-name company roles', inspectorItem && 'inspector-list']">
            <Sorting
              :sortField="sortedField.assetRole"
              :currentSortedField="currentSortedField"
              :handleSortUp="handleSortUp"
              :handleSortDown="handleSortDown"
            />
            asset roles
          </div>
        </div>
        <div
          v-if="!assets.length"
          class="empty-search"
        >
          No assets to display
        </div>
        <div
          v-for="(item, key) in assets"
          :key="item.key"
          class="asset"
          @mouseover="onHoverElem(item)"
          @mouseleave="isHover = false"
        >
          <div class="td number">
            {{ getCurrentNumber(key) }}
          </div>
          <div
            v-if="item.status === 'processing'"
            class="processing"
            :title="'processing'"
          >
            <inline-svg
              :src="iconProcessing"
              class="svg-icon icon-asset"
            >
            </inline-svg>
          </div>
          <div
            v-if="item.status === 'error'"
            class="processing"
          >
            <inline-svg
              :src="iconChanges"
              class="svg-icon icon-asset"
            >
            </inline-svg>
          </div>
          <div
            :class="['td filename', inspectorItem && 'inspector-padding']"
            :title="item.name"
          >
            {{ item.name }}
          </div>
          <div
            :class="['td company asset-type', inspectorItem && 'inspector-list']"
            :title="item.type"
          >
            {{ item.entityType === 'supplemental file'
              && item.type === 'audio'
              ? 'supplemental audio'
              : item.type }}
          </div>
          <div
            :class="['td company format', inspectorItem && 'inspector-list']"
            :title="item.format"
          >
            {{ item.format }}
          </div>
          <div
            :class="['td company', inspectorItem && 'inspector-list']"
            :title="item.dimensions"
          >
            {{ item.dimensions }}
          </div>
          <div :class="['td company', inspectorItem && 'inspector-list']">
            {{ item.filesize | fileSize }}
          </div>
          <div :class="['td company roles', inspectorItem && 'inspector-list']">
            {{ item.assetRole ? item.assetRole.name : '-' }}
          </div>
          <div
            v-show="isHover && item.key === selectedId"
            class="icons-wrapper"
          >
            <div
              v-if="item.assetRole && item.assetRole.shortName === 'mezzanine'
                || (item.entityType === 'supplemental file' && item.type === 'audio')"
              class="tooltip"
            >
              <inline-svg
                :src="iconTooltip"
                class="svg-icon"
              >
              </inline-svg>
              <span class="tooltiptext">
                {{ item.assetRole && item.assetRole.shortName === 'mezzanine'
                  ? mezzanineTooltip
                  : suppAudioTooltip }}
              </span>
            </div>
            <div
              v-if="item.status !== 'processing'"
              class="icon-edit"
            >
              <div :title="editAsset">
                <inline-svg
                  :src="iconEdit"
                  class="svg-icon"
                  @click="onInspectorOpen(item)"
                >
                </inline-svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="inspectorItem"
        class="inspector-wrapper"
      >
        <AssetInspector
          :key="`inspector${inspectorItem ? inspectorItem.key : ''}`"
          :source="inspectorItem"
          :closeInspector="onInspectorClose"
          :updateAssetList="updateAssetList"
          :hasDefaultAssets="assets.find((item) => item.type === 'alternate audio'
            && item.isDefault)"
        />
      </div>
    </div>
    <div v-if="assetModal">
      <AssetModal
        :modal="assetModal"
        :modalMode="modalMode"
        :modalData="modalData"
        :onClose="onClose"
        :onSuccess="onModalSuccess"
        :showLoader="!inspectorItem || inspectorItem.type !== 'subtitle'"
      />
    </div>
    <AddAssetChoice
      v-if="isChooseModal"
      :onClose="onCloseChoice"
      :toAlternative="toAlternative"
      :openSubModal="openSubModal"
      :openSuppModal="openSuppModal"
      :openSuppVideoModal="openSuppVideoModal"
      :openDOcumentModal="openDOcumentModal"
      :openOtherModal="openOtherModal"
      :openLinkedModal="openLinkedModal"
      :openImageModal="openImageModal"
      :mediaItem="mediaItem"
    />
    <AlternativeAudio
      v-if="isAlternative"
      :altType="altType"
      :onCloseAlt="onCloseAlt"
      :updateAssetList="updateAssetList"
      :hasDefaultAssets="assets.find((item) => item.type === 'alternate audio' && item.isDefault)"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  CLEAR_ASSET_LIST,
  GET_MEDIA_ITEM_ASSETS_REQUEST,
} from '../../../store/actions/media/mediaItem';
import {
  GET_PROCESSING_SSE_CLOSED,
  GET_PROCESSING_SSE_REQUEST,
} from '../../../store/actions/media/mediaAsset';
import { SET_EDIT_FORM } from '../../../store/actions/common';

import Sorting from '../../common/Sorting.vue';
import iconEdit from '../../../assets/icons/icon-edit-acc.svg';
import iconTooltip from '../../../assets/icons/icon-tooltip.svg';
import CONFIG from '../../../constants/config';
import Loader from '../../common/Loader.vue';
import LiveSearch from '../../common/LiveSearch.vue';
import Button from '../../common/Button.vue';
import AssetInspector from '../inspector/AssetInspector.vue';
import AssetModal from './modal/AssetModal.vue';
import AddAssetChoice from './modal/AddAssetChoice.vue';
import AlternativeAudio from './modal/AlternativeAudio.vue';
import iconProcessing from '../../../assets/icons/icon-processing-alt-audio.svg';
import iconChanges from '../../../assets/icons/icon-localization-changes.svg';

export default {
  name: 'AssetList',
  components: {
    AlternativeAudio,
    AddAssetChoice,
    LiveSearch,
    Button,
    AssetInspector,
    Sorting,
    Loader,
    AssetModal,
  },
  props: {
    mediaItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      iconTooltip,
      iconProcessing,
      iconChanges,
      isLoadingAsset: false,
      mezzanineTooltip: 'Some formats are not supported for browser playback. In this case, please, download your mezzanine file for local playback',
      suppAudioTooltip: 'Some formats are not supported for browser playback. In this case, please, download your supplemental audio file for local playback',
      assetModal: false,
      modalMode: '',
      modalData: {},
      isHover: false,
      selectedId: null,
      inspectorItem: null,
      altType: '',
      sortedField: {
        name: 'name',
        format: 'format',
        dimensions: 'dimensions',
        filesize: 'filesize',
        type: 'type',
        assetRole: 'assetRole',
      },
      filter: '',
      currentSortedField: 'name',
      currentSortBy: 'ASC',
      iconEdit,
      editAsset: CONFIG.tooltips.editAsset,
      isChooseModal: false,
      isAlternative: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.mediaItem.isLoading,
      originalAssets: (state) => state.mediaItem.assets,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    assets() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.isLoadingAsset = true;
      const assets = this.originalAssets.filter((item) => item.name.includes(this.filter));

      if (assets.length === 1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isLoadingAsset = false;
        return assets;
      }

      if (!assets.length
        && (this.mediaItem.type === 'livestream' || this.mediaItem.type === 'livestream_url')
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isLoadingAsset = false;
        return [];
      }

      if (!assets.length && this.filter) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isLoadingAsset = false;
        return [];
      }

      // eslint-disable-next-line array-callback-return,consistent-return
      assets.sort((a, b) => {
        const sortField = this.sortedField[this.currentSortedField];
        const fieldA = a[sortField];
        const fieldB = b[sortField];
        const sortingOrder = this.currentSortBy === CONFIG.sort.sortUp ? 1 : -1;

        if (sortField === this.sortedField.filesize) {
          this.isLoadingAsset = false;
          return sortingOrder * (fieldA - fieldB);
        }

        if (sortField === 'assetRole') {
          let fA = fieldA;
          if (fieldA) {
            fA = fieldA.name;
          } else {
            fA = '';
          }

          this.isLoadingAsset = false;
          return sortingOrder * fA.localeCompare(fieldB ? fieldB.name : '');
        }

        this.isLoadingAsset = false;
        return sortingOrder * fieldA.localeCompare(fieldB);
      });

      return assets;
    },
  },
  created() {
    this.updateAssetList();
    this.$store.dispatch(GET_PROCESSING_SSE_REQUEST);
  },
  destroyed() {
    this.$store.dispatch(GET_PROCESSING_SSE_CLOSED);
    this.$store.dispatch(CLEAR_ASSET_LIST);
  },
  methods: {
    getTitle(name) {
      return name.length > 50 ? `${name.slice(0, 50)}...` : name;
    },
    getMediaItemAssets(id) {
      const params = {
        accountId: this.getCurrentAccountId,
        itemId: id,
      };
      return this.$store.dispatch(GET_MEDIA_ITEM_ASSETS_REQUEST, params);
    },
    onSearch(params) {
      this.filter = params.filterValue;
    },
    handleSortUp(field) {
      this.currentSortedField = field;
      this.currentSortBy = CONFIG.sort.sortUp;
    },
    handleSortDown(field) {
      this.currentSortedField = field;
      this.currentSortBy = CONFIG.sort.sortDown;
    },
    onHoverElem(item) {
      this.selectedId = item.key;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      return key + 1;
    },
    onInspectorOpen(item) {
      this.inspectorItem = item;
    },
    onInspectorClose() {
      this.inspectorItem = null;
    },
    toAlternative() {
      this.isAlternative = true;
      this.onCloseChoice();
    },
    openModal() {
      this.isChooseModal = true;
    },
    openSubModal() {
      this.onCloseChoice();
      this.modalMode = 'add';
      this.modalData = {
        id: null,
        mediaItemId: this.mediaItem.id,
      };
      this.assetModal = true;
    },
    openSuppModal() {
      this.isAlternative = true;
      this.onCloseChoice();
      this.altType = 'supp';
    },
    openSuppVideoModal() {
      this.isAlternative = true;
      this.onCloseChoice();
      this.altType = 'supp_video';
    },
    openDOcumentModal() {
      this.isAlternative = true;
      this.onCloseChoice();
      this.altType = 'document';
    },
    openOtherModal() {
      this.isAlternative = true;
      this.onCloseChoice();
      this.altType = 'other';
    },
    openLinkedModal() {
      this.isAlternative = true;
      this.onCloseChoice();
      this.altType = 'linked-file';
    },
    openImageModal() {
      this.isAlternative = true;
      this.onCloseChoice();
      this.altType = 'image';
    },
    onCloseChoice() {
      this.isChooseModal = false;
      this.altType = '';
    },
    onCloseAlt() {
      this.isAlternative = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onClose() {
      this.assetModal = false;
      this.modalMode = '';
      this.modalData = {};
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onModalSuccess() {
      this.onClose();
      this.updateAssetList();
    },
    updateAssetList(updateInspector = false) {
      if (this.mediaItem && this.mediaItem.id) {
        const request = this.getMediaItemAssets(this.mediaItem.id);

        if (updateInspector && this.inspectorItem) {
          request.then(() => {
            const item = this.assets.find((item) => item.id === this.inspectorItem.id);

            this.onInspectorClose();
            this.$nextTick(() => {
              this.onInspectorOpen(item);
            });
          });
        }
      }
    },
  },
};
</script>

<style scoped>
  .assets-wrapper {
    display: flex;
    height: calc(100vh - 290px);
    width: 100%;
  }

  .left-path {
    width: calc(100% - 370px);
  }

  .flex {
    align-items: center;
    display: flex;
    flex: 0;
    justify-content: space-between;
    margin-bottom: 20px;
    min-height: 40px;
  }

  .header-title h2 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .right-path {
    align-items: center;
    display: flex;
  }

  .assets-list-wrapper {
    border: 1px solid #43434d;
    height: 100%;
    overflow-y: auto;
    width: 100%;
  }

  .inspector-opened .assets-list-wrapper {
    width: calc(100% - 615px);
  }

  .inspector-wrapper {
    padding-left: 5px;
    width: 615px;
  }

  .ac-wrapper-header {
    background: #282a2e;
    display: flex;
    padding: 13px 0;
  }

  .th-name {
    align-items: center;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
  }

  .td {
    font-size: 14px;
    padding: 15px 0;
    word-break: break-word;
  }

  .number {
    padding-left: 21px;
    width: 67px;
  }

  .filename {
    padding-right: 90px;
    width: 20%;
  }

  .company {
    padding-right: 15px;
    width: 10%;
  }

  .asset {
    display: flex;
    position: relative;
  }

  .asset:hover {
    background: #383b40;
  }

  .icons-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 0.8;
    justify-content: flex-end;
    padding-top: 10px;
  }

  .icons-wrapper div {
    padding-left: 31px;
  }

  .icons-wrapper div:first-child {
    padding-left: 0;
  }

  .icons-wrapper div {
    padding-left: 31px;
  }

  .empty-search {
    padding-top: 40px;
    text-align: center;
  }

  .svg-icon {
    cursor: pointer;
  }

  .icon-asset {
    cursor: default;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 180px;
    top: 100%;
    left: 50%;
    margin-left: -90px;
    background-color: #ffffff;
    color: #000000;
    padding: 4px 6px;
    border-radius: 1px;
    font-size: 13px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .processing {
    position: absolute;
    top: 15px;
    left: 60px;
    cursor: default;
  }

  .asset-type {
    text-transform: capitalize;
  }

  .format {
    width: 7%;
  }

  .roles {
    width: 9%;
  }

  .inspector-list {
    width: 9%;
    padding-right: 13px;
  }

  .inspector-padding {
    padding-right: 70px;
  }

  @media all and (max-width: 1400px) {
    .number {
      width: 40px;
    }

    .icons-wrapper div {
      padding-left: 20px;
    }

    .processing {
      left: 33px;
    }
  }

  @media all and (max-width: 1100px) {
    .icons-wrapper div {
      padding-left: 15px;
    }
  }

  @media all and (max-width: 900px) {
    .company {
      padding-right: 7px;
    }

    .icons-wrapper div {
      padding-left: 11px;
    }

    .number {
      width: 24px;
    }
  }
</style>
