<template>
  <div class="genres-wrapper">
    <div class="row row-head">
      <div class="row-item">
        #
      </div>
      <div class="row-item row-item-sortable">
        <Sorting
          :sortField="`g.${sortedField.name}`"
          :currentSortedField="sortFiled"
          :handleSortUp="handleSortUp"
          :handleSortDown="handleSortDown"
        />
        name
      </div>
      <div class="row-item">
        short name
      </div>
      <div class="row-item row-item-sortable">
        <Sorting
          :sortField="`g.${sortedField.updatedAt}`"
          :currentSortedField="sortFiled"
          :handleSortUp="handleSortUp"
          :handleSortDown="handleSortDown"
        />
        Last edited
      </div>
      <div class="row-item">
        Modified By
      </div>
      <div class="row-item">
        Status
      </div>
      <div></div>
    </div>
    <div
      v-if="!genres.length"
      class="empty-search"
    >
      No items to display
    </div>
    <div
      v-for="(item, key) in genres"
      :key="item.id"
      class="row"
    >
      <div class="row-item">
        {{ getCurrentNumber(key) }}
      </div>
      <div
        class="row-item"
        :title="item.name"
      >
        {{ item.name }}
      </div>
      <div
        class="row-item"
        :title="item.industry"
      >
        {{ item.shortName }}
      </div>
      <div class="row-item">
        {{ item.updatedAt | cmsDate(profile.timezone) }}
      </div>
      <div class="row-item">
        {{ (item.updatedBy && item.updatedBy.fullName) || '-' }}
      </div>
      <div class="row-item">
        <div
          class="status-ellipse"
          :class="item.enabled ? 'enabled' : 'disabled'"
        >
        </div>
        <span>
          {{ item.enabled ? 'Enabled' : 'Disabled' }}
        </span>
      </div>
      <div class="row-item row-item-onhover row-item-controls">
        <div
          v-if="isDeleteForItemAvailable(item)"
          :title="'Delete Genre'"
        >
          <inline-svg
            :src="iconRemove"
            class="svg-icon remove"
            @click="handleOpenModalForRemove(item)"
          >
          </inline-svg>
        </div>
        <div
          v-if="isAdmin"
          :title="item.enabled ? 'Disable Genre' : 'Enable Genre'"
          class="enable-icon"
        >
          <inline-svg
            :src="item.enabled ? iconEnabled : iconDisabled"
            class="svg-icon"
            @click="handleOpenToggleStatusModal(item)"
          />
        </div>
        <div
          v-if="isAdmin"
          class="icon-edit"
        >
          <router-link
            :to="{ path: `genres/${item.id}` }"
            :title="'Edit Genre'"
          >
            <inline-svg
              :src="iconEdit"
              class="svg-icon"
            >
            </inline-svg>
          </router-link>
        </div>
        <div
          v-if="isManager"
          class="view"
          :title="'View Genre'"
        >
          <router-link
            :to="{ path: `genres/${item.id}` }"
          >
            <inline-svg
              :src="iconViewOnly"
              class="svg-icon"
            >
            </inline-svg>
          </router-link>
        </div>
      </div>
    </div>

    <DeleteGenreInTwoSteps
      ref="deleteGenreInTwoSteps"
      @runAfterComplete="getGenres"
    />

    <NotificationModal
      ref="modal"
      v-model="bindsForModal.isModalOpen"
      :modal="bindsForModal.isModalOpen"
      v-bind="bindsForModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  SET_OVERFLOW,
  SET_EDIT_FORM,
} from '../../store/actions/common';
import {
  SET_GENRE_STORE_PROPERTY,
  GET_GENRES_REQUEST,
  GET_GENRE_IN_USE_STATUS_REQUEST,
  CHANGE_GENRE_STATUS_REQUEST,
} from '../../store/actions/genres/genresActions';
import DeleteGenreInTwoSteps from './DeleteGenreInTwoSteps.vue';
import NotificationModal from '../../components/NotificationModal.vue';
import Sorting from '../../components/common/Sorting.vue';
import iconEnabled from '../../assets/icons/icon-enabled-acc.svg';
import iconDisabled from '../../assets/icons/icon-disabled-acc.svg';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconRemove from '../../assets/icons/icon-remove-role.svg';
import iconViewOnly from '../../assets/icons/icon-view-only.svg';
import CONFIG from '../../constants/config';

export default {
  name: 'GenresList',
  components: {
    Sorting,
    DeleteGenreInTwoSteps,
    NotificationModal,
  },
  data() {
    return {
      selectedItem: null,
      bindsForModal: {
        isModalOpen: false,
        title: '',
        bodyMessage: '',
        isInUseAsset: false,
        warningMess: '',
        onAccept: () => {},
        onClose: () => {},
      },
      sortedField: {
        name: 'name',
        updatedAt: 'updatedAt',
      },
      iconEnabled,
      iconDisabled,
      iconRemove,
      iconEdit,
      iconViewOnly,
    };
  },
  computed: {
    ...mapState({
      genres: (state) => state.genres.genres,
      filterValue: (state) => state.genres.filterValue,
      sortFiled: (state) => state.genres.sortFiled,
      sortBy: (state) => state.genres.sortBy,
      pagination: (state) => state.genres.pagination,
      isLoading: (state) => state.accounts.isLoading,
      profile: (state) => state.common.profile,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'isManager',
      'isAdmin',
    ]),
  },
  created() {
    document.title = 'Genres';

    this.getGenres();
  },
  methods: {
    getGenres() {
      const params = {
        accountId: this.getCurrentAccountId,
        page: localStorage.getItem('genres') || 1,
      };

      this.$store.dispatch(GET_GENRES_REQUEST, params);
    },
    handleSortUp(field) {
      this.$store.commit(SET_GENRE_STORE_PROPERTY, {
        key: 'sortFiled',
        value: field,
      });

      this.$store.commit(SET_GENRE_STORE_PROPERTY, {
        key: 'sortBy',
        value: CONFIG.sort.sortUp,
      });

      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
      };

      this.$store.dispatch(GET_GENRES_REQUEST, params);
    },
    handleSortDown(field) {
      this.$store.commit(SET_GENRE_STORE_PROPERTY, {
        key: 'sortFiled',
        value: field,
      });
      this.$store.commit(SET_GENRE_STORE_PROPERTY, {
        key: 'sortBy',
        value: CONFIG.sort.sortDown,
      });

      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
      };

      this.$store.dispatch(GET_GENRES_REQUEST, params);
    },
    handleCloseModal() {
      this.$store.dispatch(SET_OVERFLOW, false);
      this.$store.dispatch(SET_EDIT_FORM, false);
      this.$nextTick(() => {
        this.resetBindsForModal();
      });
    },
    handleOpenToggleStatusModal(item) {
      this.selectedItem = item;

      if (item.enabled) {
        const paramsToGetStatus = {
          accountId: this.getCurrentAccountId,
          id: item.id,
        };

        this.$store.dispatch(GET_GENRE_IN_USE_STATUS_REQUEST, paramsToGetStatus)
          .then(this.processOpenToggleStatusModal);
      } else {
        this.processOpenToggleStatusModal({ isInUse: false });
      }
    },
    processOpenToggleStatusModal(response) {
      const { isInUse } = response;
      const {
        disableGenreTitle,
        disabledGenreConfirmationMessage,
        enableGenreTitle,
        enabledGenreConfirmationMessage,
        genreInUseWarningMessage,
      } = CONFIG.modals;

      if (this.selectedItem.enabled) {
        this.bindsForModal.title = disableGenreTitle;
        this.bindsForModal.bodyMessage = disabledGenreConfirmationMessage;
      } else {
        this.bindsForModal.title = enableGenreTitle;
        this.bindsForModal.bodyMessage = enabledGenreConfirmationMessage;
      }

      if (isInUse && this.selectedItem.enabled) {
        this.bindsForModal.isInUseAsset = true;
        this.bindsForModal.warningMess = genreInUseWarningMessage;
      }

      this.bindsForModal.onAccept = this.handleToggleStatus;
      this.bindsForModal.onClose = this.handleCloseModal;

      this.$store.dispatch(SET_OVERFLOW, true);
      this.bindsForModal.isModalOpen = true;
    },
    handleToggleStatus() {
      const params = {
        accountId: this.getCurrentAccountId,
        genreId: this.selectedItem.id,
        data: {
          enabled: !this.selectedItem.enabled,
        },
      };

      this.$store.dispatch(CHANGE_GENRE_STATUS_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: `The Genre has been successfully
            ${this.selectedItem.enabled ? 'disabled' : 'enabled'}`,
          });
          this.getGenres();
        });
    },
    resetBindsForModal() {
      this.bindsForModal = {
        isModalOpen: false,
        title: '',
        bodyMessage: '',
        isInUseAsset: false,
        warningMess: '',
        onAccept: () => {},
        onClose: () => {},
      };
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    isDeleteForItemAvailable(item) {
      // TODO: check if item can be deleted item.canDelete
      return this.isAdmin && !item.enabled;
    },
    handleOpenModalForRemove(item) {
      this.$refs.deleteGenreInTwoSteps.handleOpenModalForRemove(item);
    },
  },
};
</script>

<style scoped lang="scss">
.genres-wrapper {
  border: 1px solid #43434d;
  height: 100%;
  overflow-y: auto;
}

.row {
  display: grid;
  grid-template-columns:
    minmax(34px, 44px)
    minmax(200px, 2fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(90px, 1fr)
    minmax(90px, 1fr)
    140px;
  min-height: 50px;
  height: auto;
  font-size: 14px;
  font-weight: 400;

  &:hover:not(.row-head) {
    background: #383b40;
  }

  .row-item {
    padding: 16px 6px;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    line-height: 18px;
    word-break: break-word;

    &:first-child {
      justify-content: center;
    }

    &.row-item-onhover {
      display: none;
    }

    &.row-item-controls {
      justify-content: flex-end;
      padding: 16px 16px 0px 16px;
      gap: 16px;
    }
  }

  &:hover .row-item-onhover {
    display: flex;
  }
}

.row-head {
  background: #282A2E;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;

  & .row-item {
    align-items: center;
  }

  & .row-item-sortable {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.status-ellipse {
  border-radius: 50%;
  height: 5px;
  margin-right: 6px;
  margin-top: 6px;
  width: 5px;
}

.status-ellipse.enabled,
.status-ellipse.active {
  background: #1fee65;
}

.status-ellipse.disabled,
.status-ellipse.blocked,
.status-ellipse.pending {
  background: #fb3951;
}

.status-ellipse.failed {
  background: #fbf650;
}

.svg-icon {
  cursor: pointer;
}
</style>
