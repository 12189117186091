<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <Loader :isVisible="isTimezoneLoading || isScheduleLoading || isMediaItemLoading" />
    <form
      v-if="isLoaded"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="main-title">
        Scheduler Settings
      </div>
      <div class="main-row-wrapper">
        <div class="player-wrapper">
          <div class="title-wrapper">
            <div class="title">
              Fallback Media Item
            </div>
          </div>
          <div>
            <div>
              <p class="text">
                You can designate a non-live media item as a fallback to be played automatically
                if there are gaps in your daily schedule.
              </p>
              <div>
                <div class="fallback">
                  <div
                    v-if="fallbackMediaItem.id"
                    class="player-preview"
                  >
                    <BitmovinPlayer
                      :key="fallbackMediaItem.id"
                      :isPayback="false"
                      :mediaItem="fallbackMediaItem"
                    />
                    <inline-svg
                      :src="iconClose"
                      class="svg-icon"
                      @click="removeFallbackHandler"
                    >
                    </inline-svg>
                  </div>
                  <div
                    v-else
                    class="no-fallback-thumbnail"
                    :title="'Add'"
                    @click="toAddFallbackMediaHandler"
                  >
                    <span class="no-fallback-thumbnail-message">
                      Click to upload fallback media item
                    </span>
                  </div>
                </div>
                <div class="fallback-info">
                  <div class="fallback-title">
                    <span>
                      Title:
                    </span>
                    <span v-if="fallbackMediaItem.name">
                      {{ fallbackMediaItem.name }}
                    </span>
                    <span v-else>
                      N/A
                    </span>
                  </div>
                  <div>
                    <span>
                      Duration:
                    </span>
                    <span v-if="fallbackMediaItem.duration">
                      {{ fallbackMediaItem.duration | secToTime }}
                    </span>
                    <span v-else>
                      00:00:00
                    </span>
                  </div>
                </div>
              </div>
              <span
                v-if="fallbackItemError"
                class="error-label"
              >
                {{ fallbackItemError }}
              </span>
            </div>
          </div>
          <div v-if="showModal">
            <FallbackModal
              :onClose="onClose"
              :onAddFallback="onAddFallback"
            />
          </div>
          <NotificationModal
            ref="modal"
            v-model="showDeletedModal"
            :modal="showDeletedModal"
            :bodyMessage="bodyMessage"
            :onAccept="removeFallback"
            :onClose="closeDeletedModal"
          />
        </div>
        <div class="poster-wrapper">
          <div class="title-wrapper">
            <div class="title">
              Poster Image
            </div>
          </div>
          <div>
            <PosterImage
              ref="posterImage"
              :poster="formData.posterImage"
              :posterName="posterName"
              :onPosterChange="handlePosterChange"
              :handleRemove="handleRemove"
              :externalErrorMessage="posterError"
              :isHDPoster="true"
            />
          </div>
        </div>
      </div>
      <div class="main-row-wrapper">
        <div class="settings-wrapper">
          <div class="title-wrapper">
            <div class="title">
              Settings
            </div>
          </div>
          <div>
            <MaterialSelect
              v-model="formData.timezone"
              v-bind="formFields.timezone"
              :options="timezones"
            />
            <p class="text">
              This timezone will be used for scheduled future events.
              If timezone is empty account settings will be used: {{ getAccountTimezoneLabel() }}
            </p>
          </div>
        </div>
      </div>
      <div class="main-row-wrapper">
        <Button
          :title="'Save'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import {
  EDIT_SCHEDULE_SETTINGS,
  GET_SCHEDULE,
} from '../../store/actions/schedule/schedule';
import { GET_ALL_TIMEZONE_REQUEST } from '../../store/actions/timezoneActions/timezone';
import { GET_MEDIA_ITEM_REQUEST } from '../../store/actions/media/mediaItem';
import CONFIG from '../../constants/config';

import Loader from '../common/Loader.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';
import BitmovinPlayer from '../common/BitmovinPlayer.vue';
import Button from '../common/Button.vue';
import PosterImage from '../common/PosterImage/DragAndDropFileUploader.vue';
import FallbackModal from '../FallbackModal.vue';
import NotificationModal from '../NotificationModal.vue';
import BaseForm from './BaseForm.vue';

import iconClose from '../../assets/icons/icon-close-inspector.svg';

export default {
  name: 'ScheduleSettingsForm',
  components: {
    Loader,
    MaterialSelect,
    NotificationModal,
    FallbackModal,
    Button,
    PosterImage,
    BitmovinPlayer,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'scheduleSettings',
    },
  },
  data() {
    return {
      isPosterChanged: false,
      isLoaded: false,
      showModal: false,
      showDeletedModal: false,
      bodyMessage: CONFIG.modals.confirmRemoveFallback,
      fallbackMediaItem: {},
      posterError: '',
      fallbackItemError: '',
      formData: {
        fallbackMediaItem: null,
        posterImage: '',
        timezone: '',
      },
      formFields: {
        fallbackMediaItem: {
          name: 'fallbackMediaItem',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        posterImage: {
          name: 'posterImage',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        timezone: {
          type: 'text',
          name: 'timezone',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
      },
      errorMapping: {
        fallbackMediaItem: [
          `${this.formPrefix}.fallbackMediaItem`,
        ],
        posterImage: [
          `${this.formPrefix}.posterImage`,
        ],
        timezone: [
          `${this.formPrefix}.timezone`,
        ],
      },
      submitEvent: EDIT_SCHEDULE_SETTINGS,
      successMessage: CONFIG.successMessages.editScheduleSettingsMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
      iconClose,
    };
  },
  computed: {
    ...mapState({
      isTimezoneLoading: (state) => state.timezone.isLoading,
      isScheduleLoading: (state) => state.schedule.isLoading,
      isMediaItemLoading: (state) => state.mediaItem.isLoading,
      schedule: (state) => state.schedule.schedule,
      timezones: (state) => state.timezone.timezones,
    }),
    ...mapGetters([
      'getCurrentAccount',
      'getCurrentAccountId',
    ]),
    scheduleId() {
      return this.getCurrentAccount.schedule.id;
    },
    currentTimezone() {
      return this.getCurrentAccount.timezone;
    },
    posterName() {
      const url = this.formData.posterImage;
      return url.substring(url.lastIndexOf('/') + 1);
    },
  },
  mounted() {
    this.getScheduleSettings();
    this.getTimezones();
  },
  methods: {
    initFormData(response) {
      if (response.id) {
        this.formData.posterImage = response.posterImage ? `${response.posterImage}?height=360` : '';
        this.formData.timezone = response.timezone;
        this.formData.fallbackMediaItem = response.fallbackMediaItem
          ? response.fallbackMediaItem.id
          : null;

        this.fallbackMediaItem = response.fallbackMediaItem ?? {};
      }

      this.isLoaded = true;
    },
    getRequestData() {
      const { formData } = this;

      const data = {
        fallbackMediaItem: formData.fallbackMediaItem,
        timezone: formData.timezone,
      };

      if (this.isPosterChanged) {
        data.posterImage = this.formData.posterImage;
      }

      return {
        accountId: this.getCurrentAccountId,
        scheduleId: this.scheduleId,
        data,
      };
    },

    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      this.isPosterChanged = false;
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);
      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
        if (formErrors[this.formFields.posterImage.name]
          && formErrors[this.formFields.posterImage.name][0]
        ) {
          this.posterError = formErrors[this.formFields.posterImage.name][0];
        }
        if (formErrors[this.formFields.fallbackMediaItem.name]
          && formErrors[this.formFields.fallbackMediaItem.name][0]
        ) {
          this.fallbackItemError = formErrors[this.formFields.fallbackMediaItem.name][0];
        }
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    getScheduleSettings() {
      const params = {
        accountId: this.getCurrentAccountId,
        scheduleId: this.scheduleId,
      };
      this.$store.dispatch(GET_SCHEDULE, params)
        .then((response) => {
          this.initFormData(response);
        });
    },
    getTimezones() {
      this.$store.dispatch(GET_ALL_TIMEZONE_REQUEST);
    },
    toAddFallbackMediaHandler() {
      this.showModal = true;
    },
    onClose() {
      this.showModal = false;
    },
    onAddFallback(fallbackId) {
      this.fallbackItemError = '';

      if (fallbackId) {
        const params = {
          itemId: fallbackId,
          accountId: this.getCurrentAccountId,
        };
        this.$store.dispatch(GET_MEDIA_ITEM_REQUEST, params)
          .then((mediaItem) => {
            this.formData.fallbackMediaItem = mediaItem ? mediaItem.id : null;
            this.fallbackMediaItem = mediaItem ?? {};
            this.onClose();
          });
      } else {
        this.formData.fallbackMediaItem = null;
        this.fallbackMediaItem = {};
        this.onClose();
      }
    },
    removeFallbackHandler() {
      this.showDeletedModal = true;
    },
    removeFallback() {
      this.onAddFallback(null);
    },
    closeDeletedModal() {
      this.showDeletedModal = false;
    },
    handlePosterChange(img) {
      this.isPosterChanged = true;
      this.handleFormChange();
      this.formData.posterImage = img;
      this.posterError = '';
    },
    handleRemove() {
      this.formData.posterImage = '';
      this.posterError = '';
    },
    getAccountTimezoneLabel() {
      let timezone = '';

      if (this.timezones && this.timezones.length && this.currentTimezone) {
        timezone = this.timezones.find((item) => item.code === this.currentTimezone);
      }

      return timezone ? timezone.name : '';
    },
  },
};
</script>

<style scoped>
  .fallback {
    width: 100%;
  }

  .no-fallback-thumbnail {
    align-items: center;
    border: 1px dashed #a0a0a0;
    cursor: pointer;
    display: flex;
    height: 280px;
    justify-content: center;
    width: 500px;
  }

  .no-fallback-thumbnail-message {
    color: #a0a0a0;
  }

  .text {
    color: #4b4f55;
  }

  .title-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .fallback-info {
    margin-top: 20px;
  }

  .fallback-title {
    margin-bottom: 10px;
  }

  .main-row-wrapper {
    display: flex;
  }

  .main-row-wrapper .settings-wrapper {
    margin-left: 0;
    margin-top: 80px;
    max-width: 500px;
    width: 100%;
  }

  .poster-wrapper,
  .player-wrapper {
    max-width: 500px;
    width: 100%;
  }

  .poster-wrapper {
    margin: 0 20px 0 80px;
  }

  .player-preview {
    position: relative;
  }

  .svg-icon {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }

  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
  }

  @media all and (max-width: 1150px) {
    .main-row-wrapper {
      display: block;
    }

    .poster-wrapper {
      margin: 40px 0;
    }
  }
</style>
