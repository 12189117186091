<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="edit-button-wrapper">
        <Button
          v-if="isManager ? false : hasDelete"
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="onRemove"
          :disabled="disabledDelete"
        />
        <Button
          :title="isManager || (!formData.canEdit
            && !formData.canDisable
            && !formData.canDelete)
            ? 'Close'
            : 'Cancel'"
          :classType="'warning'"
          :eventFunction="backTo"
        />
        <Button
          v-if="isManager ? false : formData.canEdit"
          :title="isEdit ? 'Apply' : 'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="breadcrumbs">
        <span class="system">
          System Management / Asset Roles
        </span>
        <span v-if="!isEdit">
          / Add Asset Role
        </span>
        <span
          v-else
        >
          / {{ (isManager
            || (!formData.canEdit
              && !formData.canDisable
              && !formData.canDelete)) ? 'View' : 'Edit' }} {{ titleName }}
        </span>
      </div>
      <div class="title">
        {{ isEdit ? 'Asset Role Editor' : 'Add Asset Role' }}
      </div>
      <div>
        <MaterialInput
          v-model="formData.name"
          v-bind="formFields.name"
          :disabled="isManager ? true : !formData.canEdit"
          :requiredFromBack="true"
        />
      </div>
      <div>
        <MaterialInput
          v-model="formData.shortName"
          v-bind="formFields.shortName"
          :label="'Short Name'"
          :disabled="isEdit"
          :requiredFromBack="true"
        />
      </div>
      <div class="toggle-wrapper dynamic margin">
        <div class="toggle-title">
          Assign to More Than One Asset
        </div>
        <ToggleButton
          v-model="formData.isMultiAsset"
          color="#2591CF"
          :sync="true"
          :labels="false"
          :disabled="isManager ? true : isEdit"
        />
        <div class="tooltip enable-tooltip">
          <inline-svg
            :src="iconTooltip"
            class="svg-icon"
          >
          </inline-svg>
          <span class="tooltiptext">
            {{ !isEdit ? enableTooltipAdd : enableTooltipEdit }}
          </span>
        </div>
      </div>
      <div
        v-if="formData.isMultiAsset"
        class="toggle-wrapper dynamic unique"
      >
        <div class="toggle-title">
          Make Role Unique for Asset Type
        </div>
        <ToggleButton
          v-model="formData.isUniqueRole"
          color="#2591CF"
          :sync="true"
          :labels="false"
          :disabled="isManager ? true : isEdit"
        />
        <div class="tooltip enable-tooltip">
          <inline-svg
            :src="iconTooltip"
            class="svg-icon"
          >
          </inline-svg>
          <span class="tooltiptext">
            {{ isEdit ? uniqueTooltipEdit : uniqueTooltipAdd }}
          </span>
        </div>
      </div>
      <div class="types">
        <div :class="['title-assign-asset-role', isTypesError && 'error-title-assign-asset-role']">
          Select Asset Type(s) to Assign this Role <span style="color:#fb3951">*</span>
        </div>
        <div class="header">
          <div class="title">
            Asset Type(s)
          </div>
          <div class="header-btn">
            <a
              :class="['add-button', (formData.types && formData.types.length >= 8
                || (!formData.canEdit
                  && !formData.canDisable
                  && !formData.canDelete))
                ? 'disabled-add-button'
                : ''
              ]"
              @click="addTargets"
            >
              <inline-svg
                :src="iconAdd"
                :class="['svg-icon add-icon',
                         (formData.types && formData.types.length >= 8
                           || (!formData.canEdit
                             && !formData.canDisable
                             && !formData.canDelete))
                           && 'disabled-add-button']"
              >
              </inline-svg>
              <span class="btn-name">
                Add
              </span>
            </a>
          </div>
        </div>
        <div
          v-if="formData.types"
          class="asset-types"
        >
          <div
            v-for="(item, index) in formData.types"
            :key="item"
            class="asset-type"
          >
            <div>
              {{ item.type }}
            </div>
            <div
              v-if="item.inUse"
              class="tooltip"
            >
              <inline-svg
                :src="iconTooltip"
                class="svg-icon"
              >
              </inline-svg>
              <span class="tooltiptext">
                {{ `${item.type} Asset Type is currently in use
                 with this Asset Role and cannot be deleted` }}
              </span>
            </div>
            <div
              v-else
              class="remove-icon"
              @click="removeAssetType(index)"
            >
              <inline-svg
                :src="iconRemove"
                :class="['svg-icon',
                         (!formData.canEdit
                           && !formData.canDisable
                           && !formData.canDelete) && 'disabled-add-button' ]"
              >
              </inline-svg>
            </div>
          </div>
          <div
            v-if="isTypesError"
            class="localization-warning-wrapper"
          >
            <inline-svg
              :src="iconError"
              class="svg-icon"
            >
            </inline-svg>
            <span class="localization-warning">
              {{ isTypesError }}
            </span>
          </div>
        </div>
      </div>
    </form>
    <NotificationModal
      ref="modal"
      v-model="isModalOpenRemove"
      :modal="isModalOpenRemove"
      :title="'Delete Asset Role'"
      :bodyMessage="bodyMessage"
      :warningMess="warningMess"
      :isInUseAsset="inUse && deleteAfter"
      :isOrangeCancel="true"
      :cancelButtonTitle="inUse && deleteAfter ? 'OK' : 'Cancel'"
      :confirmButtonTitle="'Delete'"
      :isNeedToApply="!deleteAfter"
      :onAccept="removeAssetRole"
      :onClose="onClose"
    />
    <AddAssetTypeModal
      :key="assetRoleTypes.length"
      :modal="isModal"
      :onClose="closeAssetTypeModal"
      :addAssetTypeHandler="addAssetType"
      :types="formData.types"
      :assetRoleTypes="assetRoleTypes"
    />
  </ValidationObserver>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {
  ADD_ASSET_ROLE_REQUEST,
  DELETE_ASSET_ROLE_REQUEST,
  EDIT_ASSET_ROLE_REQUEST,
  GET_ASSET_ROLE_ITEM_REQUEST,
  GET_ASSET_ROLE_TYPES_REQUEST,
} from '../../../store/actions/assetRoles/assetRolesActions';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import NotificationModal from '../../NotificationModal.vue';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../../store/actions/common';
import iconAdd from '../../../assets/icons/media-add.svg';
import AddAssetTypeModal from '../../AddAssetTypeModal.vue';
import iconTooltip from '../../../assets/icons/icon-tooltip.svg';
import iconRemove from '../../../assets/icons/icon-remove-role.svg';
import iconError from '../../../assets/icons/icon-red-warning.svg';

export default {
  name: 'AssetRoleForm',
  components: {
    AddAssetTypeModal,
    NotificationModal,
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'assetRole',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bodyMessage: 'Are you sure you want to delete Asset Role?',
      isModalOpenRemove: false,
      itemsErrors: '',
      isReady: false,
      isHasId: true,
      titleName: '',
      iconAdd,
      iconTooltip,
      iconRemove,
      iconError,
      isModal: false,
      assetRoleTypes: [],
      assetTypesInUse: [],
      enableTooltipAdd: 'Enable this option if this role should have the ability to be associated with more than one Asset in a Media Assets list. Leave disabled to limit to a single Asset',
      enableTooltipEdit: 'This option determines if this Role has the ability to be associated with more than one Asset in a Media Assets list',
      uniqueTooltipAdd: 'Enable this option to limit the assignment to one Asset per Asset Type selected below. Leave disabled to allow assignment to more than one Asset per Asset Type selected below',
      uniqueTooltipEdit: 'This option determines if this Role has the ability to be associated with more than one Asset per Asset Type selected below',
      warningMess: '',
      inUse: '',
      deleteAfter: '',
      isTypesError: '',
      formData: {
        name: '',
        shortName: '',
        isMultiAsset: false,
        isUniqueRole: false,
        types: [],
        canEdit: true,
        canDelete: true,
        canDisable: true,
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        shortName: {
          type: 'text',
          name: 'shortName',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
            alpha_num: 'en',
          },
        },
        types: {
          type: 'text',
          name: 'types',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        shortName: [
          `${this.formPrefix}.shortName`,
        ],
        types: [
          `${this.formPrefix}.types`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_ASSET_ROLE_REQUEST : ADD_ASSET_ROLE_REQUEST,
      successMessage: this.isEdit
        ? CONFIG.successMessages.editAssetRoleMessage
        : CONFIG.successMessages.addAssetRoleMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
      'isManager',
    ]),
    name() {
      return this.formData.name;
    },
    shortName() {
      return this.formData.shortName;
    },
    hasDelete() {
      if (this.isEdit && this.formData.canEdit) {
        return this.isEdit;
      }

      if (!this.isEdit) {
        return false;
      }

      return !(this.isEdit
        && !this.formData.canDelete
        && !this.formData.canEdit
        && !this.formData.canDisable);
    },
    disabledDelete() {
      if (!this.formData.canDelete && this.deleteAfter) {
        return false;
      }

      if (this.formData.canDelete) {
        return false;
      }

      return true;
    },
  },
  watch: {
    name(newValue, oldValue) {
      if (
        !this.isEdit
        && newValue !== oldValue
      ) {
        this.formData.shortName = newValue.replace(/([^a-z0-9]+)/gi, '');
      }
    },
    shortName(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.formData.shortName = this.formData.shortName.charAt(0).toLowerCase()
          + this.formData.shortName.slice(1);
      }
    },
  },
  mounted() {
    if (this.isEdit) {
      const params = {
        accountId: this.getCurrentAccountId,
        itemId: this.$route.params.id,
      };
      this.$store.dispatch(GET_ASSET_ROLE_ITEM_REQUEST, params).then((res) => {
        this.initFormData(res);
      });
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    },
    onTagIncludedTypesChange(value) {
      this.formData.types = value;
    },
    initFormData(person) {
      this.formData.name = person.name;
      this.titleName = person.name;
      this.formData.shortName = person.shortName;
      this.formData.isMultiAsset = person.multiAsset;
      this.formData.types = person.assetTypesInUse;
      this.formData.canEdit = person.canEdit;
      this.formData.canDelete = person.canDelete;
      this.formData.canDisable = person.canDisable;
      this.assetTypesInUse = person.assetTypesInUse;
      this.inUse = person.isInUse;
      this.deleteAfter = person.deleteAfter;

      if (person.multiAsset) {
        this.formData.isUniqueRole = person.uniqueAssetType;
      }
    },
    getRequestData() {
      const data = {
        name: this.formData.name,
        shortName: this.formData.shortName,
        preNominal: this.formData.preNominal,
        multiAsset: this.formData.isMultiAsset,
        uniqueAssetType: this.formData.isUniqueRole,
        types: this.formData.types.map((item) => item.type),
      };

      if (!this.formData.isMultiAsset) {
        delete data.uniqueAssetType;
      }

      if (this.isEdit) {
        delete data.multiAsset;
        delete data.uniqueAssetType;
        delete data.shortName;
      }

      return {
        data,
        accountId: this.getCurrentAccountId,
        personId: this.$route.params.id,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.$router.push('/settings/asset-roles');
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const typesError = error.form_errors
        && error.form_errors.children.types
        && error.form_errors.children.types.errors
        && error.form_errors.children.types.errors[0].error;

      this.formErrors = this.getServerFormErrors(error);

      if (typesError === 'ASSET_TYPE_IS_USED') {
        this.itemsErrors = error.form_errors
          && error.form_errors.children.types
          && error.form_errors.children.types.errors
          && error.form_errors.children.types.errors[0].error_description;
      }

      if (typesError === 'IS_BLANK_ERROR') {
        this.itemsErrors = 'This field is required';
      }

      const isTypesError = error.form_errors
        && error.form_errors.children.types
        && error.form_errors.children.types.errors
        && error.form_errors.children.types.errors[0].error;

      if (isTypesError === 'IS_BLANK_ERROR') {
        this.isTypesError = 'At least one Asset Type must be selected';
      }

      // this.itemsErrors = this.getServerFormCollectionErrors(error);

      if (Object.entries(this.formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(this.formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    backTo() {
      this.$router.push('/settings/asset-roles');
    },
    resetValidation() {
      this.itemsErrors = {};
    },
    onClose() {
      this.isModalOpenRemove = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onRemove() {
      this.isModalOpenRemove = true;
      if (this.inUse && this.deleteAfter) {
        this.bodyMessage = '';
        this.warningMess = `You can only remove an Asset Role if it has been disabled for more than 1 week. Asset Role may be deleted after ${this.getDeleteAfter(this.deleteAfter)}`;
      }
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    getDeleteAfter(value) {
      const moment = extendMoment(Moment);
      const format = 'MMMM DD, [ at] hh:mm A';
      if (this.getMainProfile.timezone) {
        return moment(value).tz(this.getMainProfile.timezone).format(format);
      }
      return moment(value).format(format);
    },
    removeAssetRole() {
      const params = {
        accountId: this.getCurrentAccountId,
        id: this.$route.params.id,
      };

      this.$store.dispatch(DELETE_ASSET_ROLE_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Asset Role has been successfully deleted',
          });
          setTimeout(() => {
            this.$router.push('/settings/asset-roles');
          }, CONFIG.routing.redirectFromAddAcc);
        });
    },
    addTargets() {
      if ((this.formData.types && this.formData.types.length >= 8)
        || (!this.formData.canEdit
        && !this.formData.canDisable
        && !this.formData.canDelete)) {
        return;
      }

      const params = {};
      if (this.formData.types && this.formData.types.length) {
        params.selectedTypes = this.formData.types;
      }

      this.$store.dispatch(GET_ASSET_ROLE_TYPES_REQUEST, params).then((res) => {
        this.assetRoleTypes = res.data.map((item) => ({
          code: item,
          name: item,
        }));
        this.isModal = true;
      });
    },
    closeAssetTypeModal() {
      this.isModal = false;
    },
    addAssetType(assetType) {
      this.isTypesError = '';
      this.formData.types.push({
        type: assetType,
        inUse: false,
      });
    },
    removeAssetType(index) {
      if (!this.formData.canEdit
        && !this.formData.canDisable
        && !this.formData.canDelete) {
        return;
      }
      this.formData.types.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.country-field {
  width: 300px;
}

.country-field:not(:last-child) {
  margin-right: 20px;
}

.wowza p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.wrapper-small {
  margin-bottom: 20px;
}

.flex {
  justify-content: space-between;
}

.person {
  margin-bottom: 20px;
}

.person p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.edit-button-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 26px;
}

.breadcrumbs {
  margin-bottom: 25px;
}

.system {
  color: #4c5057;
}

.toggle-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
}

.toggle-title {
  margin-right: 10px;
}

.title {
  color: #4c5057;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 18px;
}

.header {
  display: flex;
  justify-content: space-between;
  min-width: 300px;
}

.external-delivery-targets {
  max-width: 500px;
}

.add-button {
  display: flex;
}

.btn-name {
  margin-left: 5px;
}

.add-button:hover {
  cursor: pointer;
}

.disabled-add-button:hover {
  cursor: not-allowed;
}

.title-assign-asset-role {
  margin-top: 30px;
  margin-bottom: 20px;
}

.asset-types {
  display: flex;
  flex-direction: column;
}

.asset-type {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  top: 100%;
  left: 50%;
  margin-left: -90px;
  background-color: #ffffff;
  color: #000000;
  padding: 4px 6px;
  border-radius: 1px;
  font-size: 13px;
  position: absolute;
  z-index: 5;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.enable-tooltip {
  display: flex;
  margin-left: 15px;
}

.svg-icon {
  cursor: pointer;
}

.remove-icon {
  cursor: pointer;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #fb3951;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.localization-warning {
  font-size: 14px;
  color: #fb3951;
  padding-left: 8px;
}

.error-title-assign-asset-role {
  color: #fb3951;
}

.margin {
  margin-bottom: 25px;
}

.unique {
  width: auto;
}

@media all and (max-width: 1320px) {
  .flex {
    flex-wrap: wrap;
  }

  .country-field {
    margin-right: 0;
    width: 100%;
  }

  .country-field:not(:last-child) {
    margin-right: 0;
  }
}
</style>
