<template>
  <div class="form-container">
    <Loader :isVisible="isLoading" />
    <div class="main wrapper main-form-container">
      <div class="column column-1">
        <AccountSettingsForm />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Loader from '../../components/common/Loader.vue';
import AccountSettingsForm from '../../components/forms/AccountSettingsForm.vue';
import CONFIG from '../../constants/config';
import { SET_EDIT_FORM } from '../../store/actions/common';

export default {
  name: 'AccountSettings',
  components: {
    AccountSettingsForm,
    Loader,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.accounts.isLoading,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  methods: {
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
    },
  },
};
</script>

<style scoped>
  .form-container {
    overflow-y: auto;
    width: 100%;
  }

  .wrapper {
    display: flex;
    height: auto;
  }
</style>
