import {
  GET_SUBTITLE_ASSET_REQUEST,
  GET_SUBTITLE_ASSET_SUCCESS,
  GET_SUBTITLE_ASSET_ERROR,
  ADD_SUBTITLE_ASSET_REQUEST,
  ADD_SUBTITLE_ASSET_SUCCESS,
  ADD_SUBTITLE_ASSET_ERROR,
  EDIT_SUBTITLE_ASSET_REQUEST,
  EDIT_SUBTITLE_ASSET_SUCCESS,
  EDIT_SUBTITLE_ASSET_ERROR,
  REPLACE_SUBTITLE_ASSET_REQUEST,
  REPLACE_SUBTITLE_ASSET_SUCCESS,
  REPLACE_SUBTITLE_ASSET_ERROR,
  DELETE_SUBTITLE_ASSET_REQUEST,
  DELETE_SUBTITLE_ASSET_SUCCESS,
  DELETE_SUBTITLE_ASSET_ERROR,
  GET_ASSET_LANGUAGE_REQUEST,
  GET_ASSET_LANGUAGE_SUCCESS,
  GET_ASSET_LANGUAGE_ERROR,
  ADD_SUBTITLE_SSE,
  ADD_ALT_AUDIO_URL_ASSET_REQUEST,
  ADD_ALT_AUDIO_URL_ASSET_SUCCESS,
  ADD_ALT_AUDIO_URL_ASSET_ERROR,
  ADD_ALT_AUDIO_ASSET_REQUEST,
  ADD_ALT_AUDIO_ASSET_SUCCESS,
  ADD_ALT_AUDIO_ASSET_ERROR,
  EDIT_ALT_AUDIO_ASSET_REQUEST,
  EDIT_ALT_AUDIO_ASSET_SUCCESS,
  EDIT_ALT_AUDIO_ASSET_ERROR,
  DELETE_ALT_AUDIO_ASSET_REQUEST,
  DELETE_ALT_AUDIO_ASSET_SUCCESS,
  DELETE_ALT_AUDIO_ASSET_ERROR,
  ADD_SUBTITLE_SSE_SUCCESS,
  ADD_SUPP_AUDIO_URL_ASSET_REQUEST,
  ADD_SUPP_AUDIO_URL_ASSET_SUCCESS,
  ADD_SUPP_AUDIO_URL_ASSET_ERROR,
  ADD_SUPP_AUDIO_ASSET_REQUEST,
  ADD_SUPP_AUDIO_ASSET_SUCCESS,
  ADD_SUPP_AUDIO_ASSET_ERROR,
  EDIT_SUPP_AUDIO_ASSET_REQUEST,
  EDIT_SUPP_AUDIO_ASSET_SUCCESS,
  EDIT_SUPP_AUDIO_ASSET_ERROR,
  DELETE_SUPP_AUDIO_ASSET_REQUEST,
  DELETE_SUPP_AUDIO_ASSET_SUCCESS,
  DELETE_SUPP_AUDIO_ASSET_ERROR,
  ADD_SUPP_VIDEO_URL_ASSET_REQUEST,
  ADD_SUPP_VIDEO_URL_ASSET_SUCCESS,
  ADD_SUPP_VIDEO_URL_ASSET_ERROR,
  ADD_SUPP_VIDEO_ASSET_REQUEST,
  ADD_SUPP_VIDEO_ASSET_SUCCESS,
  ADD_SUPP_VIDEO_ASSET_ERROR,
  EDIT_SUPP_VIDEO_ASSET_REQUEST,
  EDIT_SUPP_VIDEO_ASSET_SUCCESS,
  EDIT_SUPP_VIDEO_ASSET_ERROR,
  DELETE_SUPP_VIDEO_ASSET_REQUEST,
  DELETE_SUPP_VIDEO_ASSET_SUCCESS,
  DELETE_SUPP_VIDEO_ASSET_ERROR,
  GET_ASSET_ROLES_OPTIONS_REQUEST,
  GET_ASSET_ROLES_OPTIONS_SUCCESS,
  GET_ASSET_ROLES_OPTIONS_ERROR,
  ADD_DOCUMENT_ASSET_REQUEST,
  ADD_DOCUMENT_ASSET_SUCCESS,
  ADD_DOCUMENT_ASSET_ERROR,
  EDIT_DOCUMENT_ASSET_REQUEST,
  EDIT_DOCUMENT_ASSET_SUCCESS,
  EDIT_DOCUMENT_ASSET_ERROR,
  DELETE_DOCUMENT_ASSET_REQUEST,
  DELETE_DOCUMENT_ASSET_SUCCESS,
  DELETE_DOCUMENT_ASSET_ERROR,
  ADD_OTHER_ASSET_REQUEST,
  ADD_OTHER_ASSET_SUCCESS,
  ADD_OTHER_ASSET_ERROR,
  EDIT_OTHER_ASSET_REQUEST,
  EDIT_OTHER_ASSET_SUCCESS,
  EDIT_OTHER_ASSET_ERROR,
  DELETE_OTHER_ASSET_REQUEST,
  DELETE_OTHER_ASSET_SUCCESS,
  DELETE_OTHER_ASSET_ERROR,
  ADD_LINKED_FILE_ASSET_REQUEST,
  ADD_LINKED_FILE_ASSET_SUCCESS,
  ADD_LINKED_FILE_ASSET_ERROR,
  EDIT_LINKED_FILE_ASSET_REQUEST,
  EDIT_LINKED_FILE_ASSET_SUCCESS,
  EDIT_LINKED_FILE_ASSET_ERROR,
  DELETE_LINKED_FILE_ASSET_REQUEST,
  DELETE_LINKED_FILE_ASSET_SUCCESS,
  DELETE_LINKED_FILE_ASSET_ERROR,
  ADD_IMAGE_ASSET_REQUEST,
  ADD_IMAGE_ASSET_SUCCESS,
  ADD_IMAGE_ASSET_ERROR,
  EDIT_IMAGE_ASSET_REQUEST,
  EDIT_IMAGE_ASSET_SUCCESS,
  EDIT_IMAGE_ASSET_ERROR,
  DELETE_IMAGE_ASSET_REQUEST,
  DELETE_IMAGE_ASSET_SUCCESS,
  DELETE_IMAGE_ASSET_ERROR,
  EDIT_MEZZ_IMAGE_ASSET_REQUEST,
  EDIT_MEZZ_IMAGE_ASSET_SUCCESS,
  EDIT_MEZZ_IMAGE_ASSET_ERROR,
  EDIT_DEFAULT_SUPP_REQUEST,
  EDIT_DEFAULT_SUPP_SUCCESS,
  EDIT_DEFAULT_SUPP_ERROR,
} from '../../actions/media/mediaAsset';
import apiCall from '../../../utils/api/api';
import sseCall from '../../../utils/api/sse';

const state = {
  isLoading: false,
  asset: {},
  languageList: [],
  toastMess: null,
};

const getters = {
  getToastMess: () => state.toastMess,
};

const actions = {
  [GET_SUBTITLE_ASSET_REQUEST]:
  ({ commit }, { accountId, assetId }) => new Promise((resolve, reject) => {
    commit(GET_SUBTITLE_ASSET_REQUEST);

    apiCall.get(`${accountId}/media-collection/subtitle/${assetId}`)
      .then((resp) => {
        commit(GET_SUBTITLE_ASSET_SUCCESS, resp.data);
        // commit(ADD_SUBTITLE_SSE);
        resolve(resp.data);
      }).catch((err) => {
        commit(GET_SUBTITLE_ASSET_ERROR);
        reject(err);
      });
  }),
  [ADD_SUBTITLE_ASSET_REQUEST]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(ADD_SUBTITLE_ASSET_REQUEST);

    apiCall.post(`${accountId}/media-collection/subtitle`, data)
      .then((res) => {
        commit(ADD_SUBTITLE_ASSET_SUCCESS, res.data);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(ADD_SUBTITLE_ASSET_ERROR);
        reject(error);
      });
  }),
  [EDIT_SUBTITLE_ASSET_REQUEST]:
  ({ commit }, { accountId, assetId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_SUBTITLE_ASSET_REQUEST);

    apiCall.put(`${accountId}/media-collection/subtitle/${assetId}`, data)
      .then((res) => {
        commit(EDIT_SUBTITLE_ASSET_SUCCESS, res.data);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(EDIT_SUBTITLE_ASSET_ERROR);
        reject(error);
      });
  }),
  [EDIT_ALT_AUDIO_ASSET_REQUEST]:
    ({ commit }, params) => new Promise((resolve, reject) => {
      commit(EDIT_ALT_AUDIO_ASSET_REQUEST);

      apiCall.put(`${params.accountId}/media-collection/audio-track/${params.audioId}`, params.data)
        .then((res) => {
          commit(EDIT_ALT_AUDIO_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(EDIT_ALT_AUDIO_ASSET_ERROR);
          reject(error);
        });
    }),
  [REPLACE_SUBTITLE_ASSET_REQUEST]:
  ({ commit }, { accountId, assetId, data }) => new Promise((resolve, reject) => {
    commit(REPLACE_SUBTITLE_ASSET_REQUEST);
    delete data.mediaItem;

    apiCall.put(`${accountId}/media-collection/subtitle/${assetId}/replace`, data)
      .then((res) => {
        commit(REPLACE_SUBTITLE_ASSET_SUCCESS, res.data);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(REPLACE_SUBTITLE_ASSET_ERROR);
        reject(error);
      });
  }),
  [DELETE_SUBTITLE_ASSET_REQUEST]:
  ({ commit }, { accountId, assetId }) => new Promise((resolve, reject) => {
    commit(DELETE_SUBTITLE_ASSET_REQUEST);

    apiCall.delete(`${accountId}/media-collection/subtitle/${assetId}`)
      .then(() => {
        commit(DELETE_SUBTITLE_ASSET_SUCCESS);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(DELETE_SUBTITLE_ASSET_ERROR);
        reject(error);
      });
  }),
  [GET_ASSET_LANGUAGE_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_ASSET_LANGUAGE_REQUEST);
    apiCall.get('choice/language')
      .then((res) => {
        commit(GET_ASSET_LANGUAGE_SUCCESS, res.data);
        resolve(res.data);
      }).catch((err) => {
        commit(GET_ASSET_LANGUAGE_ERROR);
        reject(err);
      });
  }),
  [ADD_SUBTITLE_SSE]: ({ commit }) => new Promise((resolve) => {
    let count = 0;
    function connect() {
      count += 1;
      sseCall.getEventSource(['/toast'])
        .then((eventSource) => {
          eventSource.onmessage = (event) => {
            const res = JSON.parse(event.data);
            commit(ADD_SUBTITLE_SSE_SUCCESS, res);
            resolve();
          };

          eventSource.onerror = () => {
            if (count <= 20) {
              window.setTimeout(() => {
                connect();
              }, 5000);
            } else {
              eventSource.close();
            }
          };
        });
    }

    connect();
  }),
  [ADD_ALT_AUDIO_URL_ASSET_REQUEST]:
    ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
      commit(ADD_ALT_AUDIO_URL_ASSET_REQUEST);

      apiCall.post(`${accountId}/media-collection/audio-track/external-url`, data)
        .then((res) => {
          commit(ADD_ALT_AUDIO_URL_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(ADD_ALT_AUDIO_URL_ASSET_ERROR);
          reject(error);
        });
    }),
  [ADD_ALT_AUDIO_ASSET_REQUEST]:
    ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
      commit(ADD_ALT_AUDIO_ASSET_REQUEST);

      apiCall.post(`${accountId}/media-collection/audio-track/uploaded-file`, data)
        .then((res) => {
          commit(ADD_ALT_AUDIO_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(ADD_ALT_AUDIO_ASSET_ERROR);
          reject(error);
        });
    }),
  [DELETE_ALT_AUDIO_ASSET_REQUEST]:
    ({ commit }, { accountId, assetId }) => new Promise((resolve, reject) => {
      commit(DELETE_ALT_AUDIO_ASSET_REQUEST);

      apiCall.delete(`${accountId}/media-collection/audio-track/${assetId}`)
        .then(() => {
          commit(DELETE_ALT_AUDIO_ASSET_SUCCESS);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(DELETE_ALT_AUDIO_ASSET_ERROR);
          reject(error);
        });
    }),
  [ADD_SUPP_AUDIO_URL_ASSET_REQUEST]:
    ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
      commit(ADD_SUPP_AUDIO_URL_ASSET_REQUEST);

      apiCall.post(`${accountId}/media-collection/supplemental-file/audio/external-url`, data)
        .then((res) => {
          commit(ADD_SUPP_AUDIO_URL_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(ADD_SUPP_AUDIO_URL_ASSET_ERROR);
          reject(error);
        });
    }),
  [ADD_SUPP_AUDIO_ASSET_REQUEST]:
    ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
      commit(ADD_SUPP_AUDIO_ASSET_REQUEST);

      apiCall.post(`${accountId}/media-collection/supplemental-file/audio/uploaded-file`, data)
        .then((res) => {
          commit(ADD_SUPP_AUDIO_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(ADD_SUPP_AUDIO_ASSET_ERROR);
          reject(error);
        });
    }),
  [EDIT_SUPP_AUDIO_ASSET_REQUEST]:
    ({ commit }, params) => new Promise((resolve, reject) => {
      commit(EDIT_SUPP_AUDIO_ASSET_REQUEST);

      apiCall.put(`${params.accountId}/media-collection/supplemental-file/audio/${params.audioId}`, params.data)
        .then((res) => {
          commit(EDIT_SUPP_AUDIO_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(EDIT_SUPP_AUDIO_ASSET_ERROR);
          reject(error);
        });
    }),
  [DELETE_SUPP_AUDIO_ASSET_REQUEST]:
    ({ commit }, { accountId, assetId }) => new Promise((resolve, reject) => {
      commit(DELETE_SUPP_AUDIO_ASSET_REQUEST);

      apiCall.delete(`${accountId}/media-collection/supplemental-file/audio/${assetId}`)
        .then(() => {
          commit(DELETE_SUPP_AUDIO_ASSET_SUCCESS);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(DELETE_SUPP_AUDIO_ASSET_ERROR);
          reject(error);
        });
    }),
  [ADD_SUPP_VIDEO_URL_ASSET_REQUEST]:
    ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
      commit(ADD_SUPP_VIDEO_URL_ASSET_REQUEST);

      apiCall.post(`${accountId}/media-collection/supplemental-file/video/external-url`, data)
        .then((res) => {
          commit(ADD_SUPP_VIDEO_URL_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(ADD_SUPP_VIDEO_URL_ASSET_ERROR);
          reject(error);
        });
    }),
  [ADD_SUPP_VIDEO_ASSET_REQUEST]:
    ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
      commit(ADD_SUPP_VIDEO_ASSET_REQUEST);

      apiCall.post(`${accountId}/media-collection/supplemental-file/video/uploaded-file`, data)
        .then((res) => {
          commit(ADD_SUPP_VIDEO_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(ADD_SUPP_VIDEO_ASSET_ERROR);
          reject(error);
        });
    }),
  [EDIT_SUPP_VIDEO_ASSET_REQUEST]:
    ({ commit }, params) => new Promise((resolve, reject) => {
      commit(EDIT_SUPP_VIDEO_ASSET_REQUEST);

      apiCall.put(`${params.accountId}/media-collection/supplemental-file/video/${params.audioId}`, params.data)
        .then((res) => {
          commit(EDIT_SUPP_VIDEO_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(EDIT_SUPP_VIDEO_ASSET_ERROR);
          reject(error);
        });
    }),
  [DELETE_SUPP_VIDEO_ASSET_REQUEST]:
    ({ commit }, { accountId, assetId }) => new Promise((resolve, reject) => {
      commit(DELETE_SUPP_VIDEO_ASSET_REQUEST);

      apiCall.delete(`${accountId}/media-collection/supplemental-file/video/${assetId}`)
        .then(() => {
          commit(DELETE_SUPP_VIDEO_ASSET_SUCCESS);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(DELETE_SUPP_VIDEO_ASSET_ERROR);
          reject(error);
        });
    }),
  [GET_ASSET_ROLES_OPTIONS_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_ASSET_ROLES_OPTIONS_REQUEST);
    apiCall.get(`choice/asset-role/${params.accountId}/${params.mediaId}/${params.assetType}`)
      .then((res) => {
        commit(GET_ASSET_ROLES_OPTIONS_SUCCESS, res.data);
        resolve(res.data);
      }).catch((err) => {
        commit(GET_ASSET_ROLES_OPTIONS_ERROR);
        reject(err);
      });
  }),
  [ADD_DOCUMENT_ASSET_REQUEST]:
    ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
      commit(ADD_DOCUMENT_ASSET_REQUEST);

      apiCall.post(`${accountId}/media-collection/supplemental-file/document/uploaded-file`, data)
        .then((res) => {
          commit(ADD_DOCUMENT_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(ADD_DOCUMENT_ASSET_ERROR);
          reject(error);
        });
    }),
  [EDIT_DOCUMENT_ASSET_REQUEST]:
    ({ commit }, params) => new Promise((resolve, reject) => {
      commit(EDIT_DOCUMENT_ASSET_REQUEST);

      apiCall.put(`${params.accountId}/media-collection/supplemental-file/document/${params.audioId}`, params.data)
        .then((res) => {
          commit(EDIT_DOCUMENT_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(EDIT_DOCUMENT_ASSET_ERROR);
          reject(error);
        });
    }),
  [DELETE_DOCUMENT_ASSET_REQUEST]:
    ({ commit }, { accountId, assetId }) => new Promise((resolve, reject) => {
      commit(DELETE_DOCUMENT_ASSET_REQUEST);

      apiCall.delete(`${accountId}/media-collection/supplemental-file/document/${assetId}`)
        .then(() => {
          commit(DELETE_DOCUMENT_ASSET_SUCCESS);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(DELETE_DOCUMENT_ASSET_ERROR);
          reject(error);
        });
    }),
  [ADD_OTHER_ASSET_REQUEST]:
    ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
      commit(ADD_OTHER_ASSET_REQUEST);

      apiCall.post(`${accountId}/media-collection/supplemental-file/other/uploaded-file`, data)
        .then((res) => {
          commit(ADD_OTHER_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(ADD_OTHER_ASSET_ERROR);
          reject(error);
        });
    }),
  [EDIT_OTHER_ASSET_REQUEST]:
    ({ commit }, params) => new Promise((resolve, reject) => {
      commit(EDIT_OTHER_ASSET_REQUEST);

      apiCall.put(`${params.accountId}/media-collection/supplemental-file/other/${params.audioId}`, params.data)
        .then((res) => {
          commit(EDIT_OTHER_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(EDIT_OTHER_ASSET_ERROR);
          reject(error);
        });
    }),
  [DELETE_OTHER_ASSET_REQUEST]:
    ({ commit }, { accountId, assetId }) => new Promise((resolve, reject) => {
      commit(DELETE_OTHER_ASSET_REQUEST);

      apiCall.delete(`${accountId}/media-collection/supplemental-file/other/${assetId}`)
        .then(() => {
          commit(DELETE_OTHER_ASSET_SUCCESS);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(DELETE_OTHER_ASSET_ERROR);
          reject(error);
        });
    }),
  [ADD_LINKED_FILE_ASSET_REQUEST]:
    ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
      commit(ADD_LINKED_FILE_ASSET_REQUEST);

      apiCall.post(`${accountId}/media-collection/supplemental-file/linked-file/external-url`, data)
        .then((res) => {
          commit(ADD_LINKED_FILE_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(ADD_LINKED_FILE_ASSET_ERROR);
          reject(error);
        });
    }),
  [EDIT_LINKED_FILE_ASSET_REQUEST]:
    ({ commit }, params) => new Promise((resolve, reject) => {
      commit(EDIT_LINKED_FILE_ASSET_REQUEST);

      apiCall.put(`${params.accountId}/media-collection/supplemental-file/linked-file/${params.audioId}`, params.data)
        .then((res) => {
          commit(EDIT_LINKED_FILE_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(EDIT_LINKED_FILE_ASSET_ERROR);
          reject(error);
        });
    }),
  [DELETE_LINKED_FILE_ASSET_REQUEST]:
    ({ commit }, { accountId, assetId }) => new Promise((resolve, reject) => {
      commit(DELETE_LINKED_FILE_ASSET_REQUEST);

      apiCall.delete(`${accountId}/media-collection/supplemental-file/linked-file/${assetId}`)
        .then(() => {
          commit(DELETE_LINKED_FILE_ASSET_SUCCESS);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(DELETE_LINKED_FILE_ASSET_ERROR);
          reject(error);
        });
    }),
  [ADD_IMAGE_ASSET_REQUEST]:
    ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
      commit(ADD_IMAGE_ASSET_REQUEST);

      apiCall.post(`${accountId}/media-collection/supplemental-file/image/uploaded-file`, data)
        .then((res) => {
          commit(ADD_IMAGE_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(ADD_IMAGE_ASSET_ERROR);
          reject(error);
        });
    }),
  [EDIT_IMAGE_ASSET_REQUEST]:
    ({ commit }, params) => new Promise((resolve, reject) => {
      commit(EDIT_IMAGE_ASSET_REQUEST);

      apiCall.put(`${params.accountId}/media-collection/supplemental-file/image/${params.audioId}`, params.data)
        .then((res) => {
          commit(EDIT_IMAGE_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(EDIT_IMAGE_ASSET_ERROR);
          reject(error);
        });
    }),
  [DELETE_IMAGE_ASSET_REQUEST]:
    ({ commit }, { accountId, assetId }) => new Promise((resolve, reject) => {
      commit(DELETE_IMAGE_ASSET_REQUEST);

      apiCall.delete(`${accountId}/media-collection/supplemental-file/image/${assetId}`)
        .then(() => {
          commit(DELETE_IMAGE_ASSET_SUCCESS);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(DELETE_IMAGE_ASSET_ERROR);
          reject(error);
        });
    }),
  [EDIT_MEZZ_IMAGE_ASSET_REQUEST]:
    ({ commit }, params) => new Promise((resolve, reject) => {
      commit(EDIT_MEZZ_IMAGE_ASSET_REQUEST);

      apiCall.put(`${params.accountId}/media-collection/supplemental-file/${params.type}/${params.audioId}`, params.data)
        .then((res) => {
          commit(EDIT_MEZZ_IMAGE_ASSET_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(EDIT_MEZZ_IMAGE_ASSET_ERROR);
          reject(error);
        });
    }),
  [EDIT_DEFAULT_SUPP_REQUEST]:
    ({ commit }, params) => new Promise((resolve, reject) => {
      commit(EDIT_DEFAULT_SUPP_REQUEST);

      apiCall.put(`${params.accountId}/media-collection/source/${params.audioId}`, params.data)
        .then((res) => {
          commit(EDIT_DEFAULT_SUPP_SUCCESS, res.data);
          resolve();
        }).catch((err) => {
          const error = err.response.data;
          commit(EDIT_DEFAULT_SUPP_ERROR);
          reject(error);
        });
    }),
};

const mutations = {
  [GET_SUBTITLE_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_SUBTITLE_ASSET_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.asset = payload;
  },
  [GET_SUBTITLE_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_SUBTITLE_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_SUBTITLE_ASSET_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.asset = payload;
  },
  [ADD_SUBTITLE_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_SUBTITLE_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_SUBTITLE_ASSET_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.asset = payload;
  },
  [EDIT_SUBTITLE_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [REPLACE_SUBTITLE_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [REPLACE_SUBTITLE_ASSET_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.asset = payload;
  },
  [REPLACE_SUBTITLE_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_SUBTITLE_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_SUBTITLE_ASSET_SUCCESS]: () => {
    state.isLoading = false;
    state.asset = {};
  },
  [DELETE_SUBTITLE_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ASSET_LANGUAGE_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_ASSET_LANGUAGE_SUCCESS]: (state, payload) => {
    state.languageList = payload.data;
    state.isLoading = false;
  },
  [GET_ASSET_LANGUAGE_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_ALT_AUDIO_URL_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_ALT_AUDIO_URL_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_ALT_AUDIO_URL_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_ALT_AUDIO_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_ALT_AUDIO_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_ALT_AUDIO_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_ALT_AUDIO_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_ALT_AUDIO_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_ALT_AUDIO_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_ALT_AUDIO_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_ALT_AUDIO_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_ALT_AUDIO_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_SUBTITLE_SSE_SUCCESS]: (state, payload) => {
    state.toastMess = payload;
  },
  [DELETE_SUPP_AUDIO_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_SUPP_AUDIO_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_SUPP_AUDIO_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_SUPP_AUDIO_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_SUPP_AUDIO_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_SUPP_AUDIO_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_SUPP_AUDIO_URL_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_SUPP_AUDIO_URL_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_SUPP_AUDIO_URL_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_SUPP_AUDIO_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_SUPP_AUDIO_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_SUPP_AUDIO_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_SUPP_VIDEO_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_SUPP_VIDEO_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_SUPP_VIDEO_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_SUPP_VIDEO_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_SUPP_VIDEO_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_SUPP_VIDEO_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_SUPP_VIDEO_URL_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_SUPP_VIDEO_URL_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_SUPP_VIDEO_URL_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_SUPP_VIDEO_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_SUPP_VIDEO_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_SUPP_VIDEO_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_DOCUMENT_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_DOCUMENT_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_DOCUMENT_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_DOCUMENT_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_DOCUMENT_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_DOCUMENT_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_DOCUMENT_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_DOCUMENT_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_DOCUMENT_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_OTHER_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_OTHER_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_OTHER_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_OTHER_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_OTHER_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_OTHER_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_OTHER_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_OTHER_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_OTHER_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_LINKED_FILE_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LINKED_FILE_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LINKED_FILE_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_LINKED_FILE_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_LINKED_FILE_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_LINKED_FILE_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_LINKED_FILE_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_LINKED_FILE_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_LINKED_FILE_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_IMAGE_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_IMAGE_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_IMAGE_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_IMAGE_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_IMAGE_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_IMAGE_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_IMAGE_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_IMAGE_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_IMAGE_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_MEZZ_IMAGE_ASSET_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_MEZZ_IMAGE_ASSET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_MEZZ_IMAGE_ASSET_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_DEFAULT_SUPP_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_DEFAULT_SUPP_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_DEFAULT_SUPP_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
