<template>
  <div>
    <Loader :isVisible="isLoading" />
    <div
      v-if="access"
      class="right-path"
    >
      <LiveSearch
        :onSearch="onSearch"
        :maxlength="255"
      />
      <Button
        :title="'Add Slider'"
        :classType="'primary'"
        :eventFunction="toCreateSlider"
      />
    </div>
    <div
      v-if="getCurrentAccount.hasVideoAppsEngine && access"
      class="accounts-wrapper"
    >
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`cs.name`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          title
        </div>
        <div class="th-name date">
          Last edited
        </div>
        <div class="th-name users-count">
          Modified by
        </div>
        <div class="th-name users-count">
          № of banners
        </div>
        <div class="th-name status">
          status
        </div>
      </div>
      <div
        v-if="!slidersList.length"
        class="empty-search"
      >
        No items to display
      </div>
      <div
        v-for="(item, key) in slidersList"
        :key="item.id"
        class="account"
        @mouseover="onHoverElem(item)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div
          class="td company"
          :title="item.name"
        >
          {{ item.name }}
        </div>
        <div class="td date">
          <span v-if="item.updatedAt">
            {{ item.updatedAt | cmsDate(profile.timezone) }}
          </span>
          <span v-else>
            -
          </span>
        </div>
        <div class="td users-count">
          {{ item.updatedBy ? item.updatedBy.fullName : '-'}}
        </div>
        <div class="td users-count">
          {{ item.bannerCount }}
        </div>
        <div class="td status format">
          <div
            class="status-ellipse"
            :style="statusStyle(item.status)"
          >
          </div>
          {{ item.status === 'in_use' ? 'In use' : item.status }}
        </div>
        <div
          v-show="isHover && item.id === selectedId"
          class="icons-wrapper"
        >
          <div
            v-if="item.status === 'inactive'"
            :title="removeSlider"
          >
            <inline-svg
              :src="iconRemove"
              class="svg-icon remove"
              @click="onOpenModalForRemove(item)"
            >
            </inline-svg>
          </div>
          <div class="icon-edit">
            <router-link
              :to="{ path: `banners/slider/${item.id}` }"
              :title="editSlider"
            >
              <inline-svg
                :src="iconEdit"
                class="svg-icon"
              >
              </inline-svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!getCurrentAccount.hasVideoAppsEngine && getCurrentAccount.tvAppsEnabled"
      class="not-access"
    >
      <div>
        {{ checkboxEnabledText }}
      </div>
    </div>
    <div
      v-if="getCurrentAccount.hasVideoAppsEngine && !access"
      class="not-access"
    >
      <div>
        {{ defaultScenario }}
      </div>
    </div>
    <Pagination
      v-if="access"
      :action="getPaginationAction"
      :page="pagination.page"
      :currentPage="currentPage"
      :totalPages="totalPages"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="'Delete Slider'"
      :onAccept="onRemoveSlider"
      :onClose="onClose"
    />
    <AddSliderModal
      v-model="isSliderOpen"
      :modal="isSliderOpen"
      :onClose="onManuallyClose"
      :onSubmitManually="onSubmitManually"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Pagination from '../../../components/common/Pagination.vue';
import Loader from '../../../components/common/Loader.vue';
import iconEnabled from '../../../assets/icons/icon-enabled-acc.svg';
import iconDisabled from '../../../assets/icons/icon-disabled-acc.svg';
import iconEdit from '../../../assets/icons/icon-edit-acc.svg';
import iconFix from '../../../assets/icons/icon-fix.svg';
import NotificationModal from '../../../components/NotificationModal.vue';
import Button from '../../../components/common/Button.vue';
import LiveSearch from '../../../components/common/LiveSearch.vue';
import CONFIG from '../../../constants/config';
import {
  GET_SLIDERS_LIST_REQUEST,
  REMOVE_SLIDER_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';
import iconRemove from '../../../assets/icons/icon-remove-role.svg';
import { SET_OVERFLOW } from '../../../store/actions/common';
import AddSliderModal from './AddSliderModal.vue';
import Sorting from '../../../components/common/Sorting.vue';

export default {
  name: 'Sliders',
  components: {
    AddSliderModal,
    Pagination,
    Loader,
    NotificationModal,
    Button,
    LiveSearch,
    Sorting,
  },
  data() {
    const {
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editSlider,
      removeSlider,
    } = CONFIG.tooltips;

    return {
      checkboxEnabledText: 'This module is not activated for your account. Please contact your account representative for assistance.',
      defaultScenario: 'Your user account does not have permission to access this module. Please contact your account administrator for assistance.',
      paymentStatuses: CONFIG.paymentStatuses,
      enabledId: null,
      akamaiAccountCreateId: null,
      akamaiAppSyncId: null,
      isEnabled: false,
      isModalOpen: false,
      isHover: false,
      selectedId: null,
      bodyMessage: '',
      filter: '',
      currentSortedField: 'cs.name',
      currentSortBy: 'ASC',
      isSortedUp: false,
      isSortedDown: false,
      isSliderOpen: false,
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editSlider,
      removeSlider,
      iconEnabled,
      iconEdit,
      iconDisabled,
      iconFix,
      iconRemove,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
    ]),
    ...mapState({
      slidersList: (state) => state.cms.slidersList,
      pagination: (state) => state.cms.pagination,
      currentPage: (state) => state.cms.currentPage,
      isLoading: (state) => state.cms.isLoading,
      profile: (state) => state.common.profile,
    }),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    access() {
      return this.getCurrentAccount
        && this.getCurrentAccount.hasVideoAppsEngine
        && this.profile.userAccountRoles
          .find((item) => item.account.id === this.getCurrentAccountId).role !== 'ROLE_MANAGER';
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    this.getSliders();
  },
  methods: {
    onManuallyClose() {
      this.isSliderOpen = false;
    },
    onSubmitManually() {
      this.onManuallyClose();
      this.getSliders();
    },
    getSliders() {
      const params = {
        accountId: this.getCurrentAccountId,
        page: 1,
        filterValue: '',
      };
      this.$store.dispatch(GET_SLIDERS_LIST_REQUEST, params);
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        accountId: this.getCurrentAccountId,
        page: params.page,
        filterValue: this.filter,
      };
      this.$store.dispatch(GET_SLIDERS_LIST_REQUEST, getParams);
    },
    getPaginationAction(page) {
      const params = {
        accountId: this.getCurrentAccountId,
        page,
        filterValue: this.filter,
      };
      this.$store.dispatch(GET_SLIDERS_LIST_REQUEST, params);
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    toCreateSlider() {
      this.isSliderOpen = true;
    },
    statusStyle(status) {
      if (status === 'in_use') {
        return {
          backgroundColor: '#1FEE65',
        };
      }

      if (status === 'pending') {
        return {
          backgroundColor: '#F2C94C',
        };
      }

      return {
        backgroundColor: '#FB3951',
      };
    },
    onClose() {
      this.isModalOpen = false;
    },
    onOpenModalForRemove(item) {
      const {
        deleteSliderInCms,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.enabledId = item.id;
      this.bodyMessage = deleteSliderInCms;
    },
    handleSortUp(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_SLIDERS_LIST_REQUEST, params);
    },
    handleSortDown(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_SLIDERS_LIST_REQUEST, params);
    },
    onRemoveSlider() {
      const params = {
        accountId: this.getCurrentAccountId,
        sliderId: this.enabledId,
      };

      this.$store.dispatch(REMOVE_SLIDER_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Slider has been successfully deleted',
          });

          this.getSliders();
        })
        .catch((err) => {
          if (err.status === CONFIG.statuses.failedStatus) {
            this.$toasted.global.error({
              message: CONFIG.errorMessages.commonServerError,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.flex {
  align-items: center;
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 40px;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px 25px 25px 30px;
  width: 100%;
}

.system {
  color: #4c5057;
}

.accounts-wrapper {
  border: 1px solid #43434d;
  height: 65vh;
  overflow-y: auto;
}

.ac-wrapper-header {
  background: #282a2e;
  display: flex;
  padding: 13px 0;
}

.th-name {
  align-items: center;
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
}

.td {
  font-size: 14px;
  padding: 15px 0;
  word-break: break-word;
}

.number {
  padding-left: 21px;
  width: 50px;
}

.company {
  padding-right: 15px;
  width: 13%;
}

.users-count {
  width: 15%;
}

.status {
  align-items: flex-start;
  display: flex;
  width: 11%;
}

.date {
  align-items: flex-start;
  display: flex;
  width: 15%;
}

.account {
  display: flex;
}

.account:hover {
  background: #383b40;
}

.format {
  text-transform: capitalize;
}

.status-ellipse {
  border-radius: 50%;
  height: 5px;
  margin-right: 6px;
  margin-top: 6px;
  width: 5px;
}

.status-ellipse.enabled,
.status-ellipse.active {
  background: #1fee65;
}

.status-ellipse.disabled,
.status-ellipse.blocked,
.status-ellipse.pending {
  background: #fb3951;
}

.status-ellipse.failed {
  background: #fbf650;
}

.icons-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  flex: 0.95;
}

.icons-wrapper div {
  padding: 10px;
}

.create-button {
  background: #2591cf;
  color: #fff;
  font-size: 16px;
  letter-spacing: normal;
  padding: 0 16px;
  text-transform: none;
}

.right-path {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
}

.empty-search {
  padding-top: 40px;
  text-align: center;
}

.btn-create-account-mobile {
  display: none;
}

.svg-icon {
  cursor: pointer;
}

.not-access {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
}

@media all and (max-width: 1400px) {
  .number {
    width: 30px;
  }

  .status {
    width: 9%;
  }

}

@media all and (max-width: 1170px) {
  .status {
    width: 9.5%;
  }
}

@media all and (max-width: 1100px) {
  .icons-wrapper div {
    padding: 10px 5px;
  }
}

@media all and (max-width: 1024px) {
  .btn-create-account-mobile {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    min-height: 40px;
  }

  .search-wrapper {
    margin-right: 0;
  }
}

@media all and (max-width: 900px) {
  .company {
    padding-right: 7px;
    width: 14%;
  }

  .number {
    width: 24px;
  }

  .status {
    width: 10%;
  }
}

@media all and (max-height: 900px) {
  .accounts-wrapper {
    height: 57vh;
  }
}
</style>
