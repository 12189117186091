<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="login"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
    >
      <MaterialInput
        v-model="formData.email"
        v-bind="formFields.email"
      />
      <div class="buttons-in-wrapper">
        <div class="forgot">
          <router-link
            to="/login"
            class="back-to-sign-in"
          >
            Back to Sign In
          </router-link>
        </div>
        <Button
          :title="'Reset Password'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import BaseForm from './BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import CONFIG from '../../constants/config';
import { FORGOT_PASSWORD_REQUEST } from '../../store/actions/authActions/auth';
import Button from '../common/Button.vue';

export default {
  name: 'ForgetPasswordForm',
  components: {
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'forgetPassword',
    },
    isSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        email: '',
      },
      formFields: {
        email: {
          type: 'text',
          name: 'email',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            email: true,
            max: 255,
          },
        },
      },
      errorMapping: {
        email: [
          `${this.formPrefix}.email`,
        ],
      },
      submitEvent: FORGOT_PASSWORD_REQUEST,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  methods: {
    getRequestData() {
      return {
        email: this.formData.email,
      };
    },
    onSubmitSuccess() {
      this.$emit('update:isSubmitted', true);
    },
  },
};
</script>

<style scoped>
  .login {
    display: flex;
    flex-direction: column;
    padding: 2em 2em 0;
    width: 450px;
  }

  .buttons-in-wrapper {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
  }

  .back-to-sign-in {
    text-decoration: none;
  }
</style>
