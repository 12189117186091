<template>
  <button
    :class="['button', classType, className, smallSize ? 'small-btn' : '']"
    :type="[type]"
    :disabled="disabled"
    @click="doAction"
  >
    <inline-svg
      v-if="srcSvg"
      :src="srcSvg"
      :class="[isReverseIcon ? 'reverse' : '', svgClass]"
    >
    </inline-svg>
    <span
      v-if="title"
      :class="[
        'button-content',
        srcSvg && 'icon',
        isReverseIcon && 'content-reverse',
        hasBorder && 'hover-class',
        isBack && 'back-button-content'
      ]"
    >
      {{ title }}
    </span>
  </button>
</template>
<script>
export default {
  name: 'Button',
  props: {
    title: {
      type: String,
      default: '',
    },
    classType: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
    },
    className: {
      type: String,
      default: '',
    },
    eventFunction: {
      type: Function,
      default: () => {},
    },
    srcSvg: {
      type: String,
      default: '',
    },
    svgClass: {
      type: String,
      default: '',
    },
    isReverseIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    smallSize: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    isBack: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    doAction() {
      this.eventFunction();
    },
  },
};
</script>
<style scoped>
  .button {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 0 3px;
    min-width: 60px;
    position: relative;
  }

  .small-btn {
    height: 35px;
    padding: 0 15px;
  }

  .small-btn .button-content {
    font-size: 15px;
  }

  .button:hover {
    opacity: 0.85;
  }

  .button:last-child {
    margin-right: 0;
  }

  .button-content.icon {
    padding-left: 8px;
  }

  .reverse {
    position: absolute;
    right: 20px;
  }

  .button-content.content-reverse {
    padding-left: 0;
    padding-right: 28px;
  }

  .small-icon {
    min-width: 30px;
    padding: 0 10px;
  }

  .warning {
    background-color: #e77925;
  }

  .icon-role {
    height: 48px;
    width: 45px;
  }

  .folder-localization-icon {
    position: absolute;
    padding-left: 135px;
  }

  .loader {
    animation: loader-animate 1.5s linear infinite;
  }

  .hover-class:hover {
    border-bottom: 1px solid #FFFFFF;
  }

  .markers {
    height: 47px;
    min-width: 30px;
    padding: 0 10px;
  }

  .big-btn {
    width: 96px;
    height: 96px;
    border-radius: 12px;
  }

  .vr-sm {
    margin-left: 15px;
    height: 30px;
  }

  .back-button-content {
    text-transform: none;
  }

  @keyframes loader-animate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  .without-margin {
    margin: 0;
  }
</style>
