<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      v-if="isHasId"
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="edit-button-wrapper">
        <Button
          v-if="isEdit"
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="onOpenModalDelete"
        />
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backTo"
        />
        <Button
          :title="isEdit ? 'Apply' : 'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="breadcrumbs">
        <span class="system">
          {{ `System Management / ${isSuperAdmin ? 'Users' : 'Account Users'}` }}
        </span>
        <span v-if="!isEdit">
          {{ `/ ${isSuperAdmin ? 'Add User' : 'Add Account User'}` }}
        </span>
        <span
          v-else
        >
          / Edit {{ formData.firstName }} {{ formData.lastName }}
        </span>
      </div>
      <div class="contact-info">
        <div class="title">
          Contact Info
        </div>
        <div class="inline-row">
          <div class="inline-block">
            <MaterialInput
              v-model="formData.firstName"
              v-bind="formFields.firstName"
              :disabled="disabled"
            />
          </div>
          <div class="inline-block">
            <MaterialInput
              v-model="formData.lastName"
              v-bind="formFields.lastName"
              :disabled="disabled"
            />
          </div>
        </div>
        <div v-if="!isSuperAdmin">
          <MaterialSelect
            v-model="formData.userAccountRoleForAccount.role"
            v-bind="formFields.role"
            :withEmptyField="false"
            :options="roles"
            :disabled="disabled"
          />
        </div>
        <div>
          <MaterialInput
            v-model="formData.phone"
            v-bind="formFields.phone"
            :disabled="disabled"
          />
        </div>
        <div>
          <MaterialInput
            v-model="formData.email"
            v-bind="formFields.email"
            :disabled="disabled || isEdit"
          />
        </div>
        <div v-if="isSuperAdmin">
          <AccountsAndRoles
            :items="formData.userAccountRoles"
            v-bind="formFields"
            :formPrefix="formPrefix"
            :onRemoveItem="onRemoveItem"
            :onChange="onUserAccountRolesChange"
            :onChangeRole="onChangeRole"
            :onChangeAccount="onChangeAccount"
            :accountList="companies"
            :roleList="roles"
          />
        </div>
      </div>
    </form>
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :confirmButtonTitle="'Ok'"
      :isNeedCancel="false"
      :onAccept="addUserToAccount"
      :onClose="closeModal"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpenDelete"
      :modal="isModalOpenDelete"
      :bodyMessage="bodyMessageDelete"
      :title="'Delete User'"
      :onAccept="deleteUser"
      :onClose="closeModalDelete"
    />
  </ValidationObserver>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import BaseForm from './BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';
import AccountsAndRoles from './AccountsAndRoles.vue';
import Button from '../common/Button.vue';
import NotificationModal from '../NotificationModal.vue';
import {
  ADD_USER,
  DELETE_USER_AS_ADMIN_REQUEST,
  DELETE_USER_REQUEST,
  EDIT_USER_INFO,
  GET_USER_INFO,
  LINK_EXISTING_USER_WITH_ACCOUNT,
} from '../../store/actions/user';
import { GET_ACCOUNTS, SET_EDIT_FORM, SET_OVERFLOW } from '../../store/actions/common';
import CONFIG from '../../constants/config';

export default {
  name: 'UserForm',
  components: {
    MaterialInput,
    MaterialSelect,
    Button,
    AccountsAndRoles,
    NotificationModal,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'user',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHasId: true,
      isModalOpen: false,
      isModalOpenDelete: false,
      formData: {
        deletedId: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        userAccountRoles: [{
          account: null,
          role: 'ROLE_MANAGER',
        }],
        userAccountRoleForAccount: {},
      },
      formFields: {
        email: {
          type: 'text',
          name: 'email',
          formPrefix: this.formPrefix,
          rules: {
            email: true,
            required: true,
            max: 255,
          },
        },
        firstName: {
          type: 'text',
          name: 'firstName',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        lastName: {
          type: 'text',
          name: 'lastName',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        phone: {
          type: 'text',
          name: 'phone',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        role: {
          name: 'role',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        account: {
          name: 'account',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        email: [
          `${this.formPrefix}.email`,
        ],
        firstName: [
          `${this.formPrefix}.firstName`,
        ],
        lastName: [
          `${this.formPrefix}.lastName`,
        ],
        phone: [
          `${this.formPrefix}.phone`,
        ],
        userAccountRoles: [
          `${this.formPrefix}.userAccountRoles.*`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_USER_INFO : ADD_USER,
      successMessage: this.isEdit
        ? CONFIG.successMessages.editUserMessage
        : CONFIG.successMessages.addUserMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.common.profile,
      accounts: (state) => state.common.accounts,
    }),
    ...mapGetters([
      'isSuperAdmin',
      'getCurrentAccountId',
    ]),
    bodyMessageDelete() {
      const { deleteUserMessage, deleteUserAdminMessage } = CONFIG.modals;
      if (this.isSuperAdmin) {
        return deleteUserMessage;
      }
      return deleteUserAdminMessage;
    },
    roles() {
      return CONFIG.roleList.filter((item) => item.name !== CONFIG.roles.ROLE_SUPER_ADMIN);
    },
    companies() {
      const { data } = this.accounts;
      if (data) {
        return data
          .map((account) => ({ name: account.name, code: account.id }))
          .sort((item1, item2) => {
            const a = item1.name.toLowerCase();
            const b = item2.name.toLowerCase();

            if (a > b) {
              return 1;
            }
            if (a < b) {
              return -1;
            }
            return 0;
          });
      }
      return [];
    },
    bodyMessage() {
      return CONFIG.modals.addExistingUserToAccountMessage;
    },
  },
  mounted() {
    if (this.isSuperAdmin) {
      this.$store.dispatch(GET_ACCOUNTS);
    }

    if (this.isEdit && this.$route.params.id) {
      const {
        errorMessages,
        statuses,
      } = CONFIG;
      const params = {
        accountId: this.getCurrentAccountId,
        userId: this.$route.params.id,
      };
      this.$store.dispatch(GET_USER_INFO, params)
        .then((res) => this.initFormData(res))
        .catch((err) => {
          if (err.error === errorMessages.unknown) {
            this.isHasId = false;
            this.$router.push(`/${statuses.notFound}`);
          }
        });
    }
  },
  methods: {
    initFormData(response) {
      if (response.id) {
        this.formData.firstName = response.firstName;
        this.formData.lastName = response.lastName;
        this.formData.phone = response.phone;
        this.formData.email = response.email;
        // eslint-disable-next-line prefer-destructuring
        if (this.isSuperAdmin) {
          this.formData.userAccountRoles = this.prepareAccountsRoles(response.userAccountRoles);
          this.formData.deletedId = response.id;
        } else {
          this.formData.deletedId = response.userAccountRoleByAccount.id;
          this.formData.userAccountRoleForAccount = {
            role: response.userAccountRoleByAccount.role,
          };
        }
      }
    },
    prepareAccountsRoles(data) {
      return data.map((item) => ({ role: item.role, account: item.account.id }));
    },
    getRequestData() {
      const { formData } = this;

      if (this.isSuperAdmin) {
        delete formData.userAccountRoleForAccount;
      } else {
        delete formData.userAccountRoles;
      }

      delete formData.deletedId;

      return {
        accountId: this.getCurrentAccountId,
        userId: this.$route.params.id,
        data: {
          ...formData,
        },
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.$router.push('/settings/users');
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      const used = error
        && error.form_errors
      && error.form_errors.children
        && error.form_errors.children.email
      && error.form_errors.children.email.errors
      && error.form_errors.children.email.errors[0]
        && error.form_errors.children.email.errors[0].error === 'NOT_UNIQUE_ERROR';

      if (used) {
        this.isModalOpen = true;
      }

      if ((error.form_errors && error.form_errors.children.row) || error.status === 404) {
        this.$router.push(`/cms/pages/${this.$route.params.id}`);
        return;
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    onRemoveItem(index) {
      this.formData.userAccountRoles.splice(index, 1);
    },
    onUserAccountRolesChange(val) {
      this.formData.userAccountRoles.push(val);
    },
    onChangeRole(code, index) {
      this.formData.userAccountRoles[index].role = code;
    },
    onChangeAccount(code, index) {
      this.formData.userAccountRoles[index].account = code;
    },
    addUserToAccount() {
      this.isModalOpen = false;

      const params = {
        accountId: this.getCurrentAccountId,
        data: {
          email: this.formData.email,
          role: this.formData.userAccountRoleForAccount.role,
        },
      };
      this.$store.dispatch(LINK_EXISTING_USER_WITH_ACCOUNT, params)
        .then(() => this.onSubmitSuccess())
        .catch(() => {});
    },
    closeModal() {
      this.isModalOpen = false;
    },
    backTo() {
      this.$router.push('/settings/users');
    },
    onOpenModalDelete() {
      this.isModalOpenDelete = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    closeModalDelete() {
      this.isModalOpenDelete = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    deleteUser() {
      const params = {
        accountId: this.getCurrentAccountId,
        userId: this.formData.deletedId,
      };

      if (this.isSuperAdmin) {
        delete params.accountId;
      }

      this.$store.dispatch(
        this.isAdmin
          ? DELETE_USER_AS_ADMIN_REQUEST
          : DELETE_USER_REQUEST,
        params,
      )
        .then(() => {
          this.$toasted.global.success({
            message: 'The User has been successfully deleted',
          });
          setTimeout(() => {
            this.$router.push('/settings/users');
          }, CONFIG.routing.redirectFromAddAcc);
        })
        .catch((err) => {
          if (err.error === 'USER_CANNOT_DELETE_HIS_ROLE') {
            this.$toasted.global.error({
              message: 'User cannot delete himself',
            });
          }
        });
    },
  },
};
</script>

<style scoped>
  .inline-row {
    display: flex;
    justify-content: space-between;
  }

  .inline-block + .inline-block {
    margin-left: 25px;
  }

  .inline-block {
    width: 300px;
  }

  .edit-button-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 26px;
  }

  .breadcrumbs {
    margin-bottom: 25px;
  }

  .system {
    color: #4c5057;
  }
</style>
