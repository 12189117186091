<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="delete-genre-form"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="title modal">
        Delete Genre
      </div>
      <div class="warning-wrapper">
        <inline-svg
          :src="iconWarning"
          class="svg-icon"
        >
        </inline-svg>
        <span class="warning-message">
          {{ warningMess }}
        </span>
      </div>
      <div>
        <MaterialInput
          v-model="formData.name"
          v-bind="formFields.name"
          :label="'Genre Name'"
          :requiredFromBack="true"
          :commonError="nameError"
        />
      </div>
      <div class="buttons-wrapper">
        <div class="buttons-container">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="backToManually"
          />
          <Button
            :title="'Delete'"
            :classType="'primary'"
            :type="'submit'"
            :disabled="isDeleteButtonDisabled"
          />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import iconWarning from '../../../assets/icons/icon-warning-localization.svg';

import { DELETE_GENRE_REQUEST } from '../../../store/actions/genres/genresActions';

export default {
  name: 'DeleteGenreForm',
  components: {
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'deleteGenreForm',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onSubmitManually: {
      type: Function,
      default: () => {},
    },
    itemToDelete: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const { name } = this.itemToDelete;
    return {
      nameError: '',
      formData: {
        name: '',
      },
      formFields: {
        name: {
          type: 'text',
          name: 'Genre',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
            is_match_name: name,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
      },
      submitEvent: DELETE_GENRE_REQUEST,
      successMessage: 'The Genre has been successfully deleted',
      errorMessage: CONFIG.errorMessages.commonServerError,
      warningMess: CONFIG.modals.deleteGenreConfirmationNameMessage,
      iconWarning,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    isDeleteButtonDisabled() {
      return !(this.formData.name);
    },
    name() {
      return this.formData.name;
    },
  },
  methods: {
    backToManually() {
      this.onClose();
    },
    getRequestData() {
      return {
        genreId: this.itemToDelete.id,
        accountId: this.getCurrentAccountId,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      this.onSubmitManually();
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      const nameError = error.form_errors?.children?.name?.errors[0]?.error;

      if (nameError === 'IS_BLANK_ERROR') {
        this.nameError = 'This field is required';
      }

      if (nameError === 'NOT_UNIQUE_ERROR') {
        this.nameError = 'This name is already in use';
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>
.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}

.buttons-container {
  display: flex;
}

.title {
  color: inherit;
  margin-bottom: 30px;
}

.warning-wrapper {
  display: flex;
  align-items: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.warning-message {
  flex: 0.98;
  font-size: 16px;
  color: #F2C94C;
  padding-left: 8px;
}
</style>
