<template>
  <div class="wrapper">
    <ValidationObserver
      ref="validationObserver"
      v-slot="{ handleSubmit }"
    >
      <form
        novalidate="novalidate"
        @submit.prevent="handleSubmit(submit)"
        @input="handleFormChange"
      >
        <p
          class="headline grey lighten-2"
          primaryTitle
        >
          Add Media Folder
        </p>
        <div class="add-input">
          <MaterialInput
            v-model="formData.name"
            v-bind="formFields.name"
            className="tree-modal-inputs"
          />
        </div>
        <div class="button-wrapper">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="onCancelHandle"
          />
          <Button
            :title="'Save'"
            :type="'submit'"
            :classType="'primary'"
          />
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import BaseForm from '../../../forms/BaseForm.vue';
import MaterialInput from '../../../inputs/MaterialInput.vue';
import { POST_MEDIA_FOLDER } from '../../../../store/actions/media/mediaTree';
import CONFIG from '../../../../constants/config';
import Button from '../../../common/Button.vue';

export default {
  name: 'AddModalContent',
  components: {
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'addMediaFolder',
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    modalData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        name: '',
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
      },
      submitEvent: POST_MEDIA_FOLDER,
      successMessage: CONFIG.successMessages.addMediaFolderMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  methods: {
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      this.onCancel();
    },
    getRequestData() {
      return {
        data: {
          name: this.formData.name,
          parent: this.modalData.folder,
        },
        accountId: this.getCurrentAccountId,
      };
    },
    onCancelHandle() {
      this.onCancel();
    },
  },
};
</script>
<style scoped>
  .wrapper {
    background-color: #222;
    border-radius: 4px;
    display: block;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    padding: 30px;
    position: absolute;
    text-decoration: none;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .headline,
  .add-input {
    color: #fff;
  }

  .add-input {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    word-break: break-all;
    padding-bottom: 30px;
  }

  hr {
    border: solid;
    border-color: rgba(0, 0, 0, 0.12);
    border-width: thin 0 0;
    display: block;
    flex: 1 1 0;
    height: 0;
    max-height: 0;
    max-width: 100%;
    transition: inherit;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }
</style>
