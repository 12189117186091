<template>
  <div class="checks-wrapper">
    <div
      v-for="option in options"
      :key="option[tagTrackBy]"
      class="option"
    >
      <input
        :id="`${name}-${option[tagTrackBy]}`"
        v-model="valueModel"
        :value="option[tagTrackBy]"
        type="radio"
        class="radio"
        :name="name"
        :disabled="disabled"
      />
      <label
        :for="`${name}-${option[tagTrackBy]}`"
        class="label"
      >
        {{ option[tagLabel] }}
      </label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RadioList',
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: null,
    },
    name: {
      type: String,
      default: 'radio-group',
    },
    tagLabel: {
      type: String,
      default: 'name',
    },
    tagTrackBy: {
      type: String,
      default: 'code',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped>
  .checks-wrapper {
    display: flex;
    flex: 86%;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 10px;
    width: 100%;
  }

  .option {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 113px;
    position: relative;
    user-select: none;
  }

  .label {
    font-size: 14px;
    padding-left: 10px;
    margin-top: 2px;
  }

  .radio {
    width: auto;
    margin-top: 3px;
  }
</style>
