<template>
  <div class="flex">
    <Loader
      :isVisible="isLoading && mediaItem.type === 'vod'"
      :className="'inspector-loader'"
    />
    <div
      id="player"
      ref="player"
      class="player"
      :class="[isControls ? (isOnlySoundControls ? 'only-sound-controls' : '') : 'no-controls',
               mediaItem.type === 'audio' ? 'audio' : '']"
      @click.prevent="onClick"
    >
    </div>
  </div>
</template>

<script>
import 'bitmovin-player/bitmovinplayer-ui.css';
import {
  UIManager,
  SubtitleOverlay,
  SettingsPanel,
  SettingsPanelPage,
  SettingsPanelItem,
  VideoQualitySelectBox,
  PlaybackSpeedSelectBox,
  AudioQualitySelectBox,
  SubtitleSettingsPanelPage,
  SettingsPanelPageOpenButton,
  ControlBar,
  Container,
  PlaybackTimeLabel,
  PlaybackTimeLabelMode,
  SeekBar,
  SeekBarLabel,
  PlaybackToggleButton,
  VolumeToggleButton,
  VolumeSlider,
  Spacer,
  PictureInPictureToggleButton,
  AirPlayToggleButton,
  CastToggleButton,
  VRToggleButton,
  SettingsToggleButton,
  FullscreenToggleButton,
  UIContainer,
  BufferingOverlay,
  PlaybackToggleOverlay,
  CastStatusOverlay,
  TitleBar,
  RecommendationOverlay,
  Watermark,
  ErrorMessageOverlay,
  SubtitleSelectBox,
  AudioTrackSelectBox,
} from 'bitmovin-player/bitmovinplayer-ui';
import {
  Player,
  ErrorCode,
  PlayerEvent,
} from 'bitmovin-player/modules/bitmovinplayer-core';
import EngineBitmovinModule from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
import EngineNativeModule from 'bitmovin-player/modules/bitmovinplayer-engine-native';
import MseRendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
import HlsModule from 'bitmovin-player/modules/bitmovinplayer-hls';
import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr';
import ContainerTSModule from 'bitmovin-player/modules/bitmovinplayer-container-ts';
import ContainerMp4Module from 'bitmovin-player/modules/bitmovinplayer-container-mp4';
import ContainerWebMModule from 'bitmovin-player/modules/bitmovinplayer-container-webm';
import RemoteControlModule from 'bitmovin-player/modules/bitmovinplayer-remotecontrol';
import SubtitlesModule from 'bitmovin-player/modules/bitmovinplayer-subtitles';
import SubtitlesModuleCEA from 'bitmovin-player/modules/bitmovinplayer-subtitles-cea608';
import SubtitlesVTTModule from 'bitmovin-player/modules/bitmovinplayer-subtitles-vtt';
import SubtitlesNativeModuleModule from 'bitmovin-player/modules/bitmovinplayer-subtitles-native';
import PolyfillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill';
import StyleModule from 'bitmovin-player/modules/bitmovinplayer-style';
import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash';
import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml';
import Thumbnail from 'bitmovin-player/modules/bitmovinplayer-thumbnail';

import Loader from './Loader.vue';
import CONFIG from '../../constants/config';
import {
  SETUP_PLAYER,
  DESTROY_PLAYER,
} from '../../store/actions/media/mediaItem';

Player.addModule(EngineNativeModule);
Player.addModule(EngineBitmovinModule);
Player.addModule(MseRendererModule);
Player.addModule(HlsModule);
Player.addModule(AbrModule);
Player.addModule(ContainerTSModule);
Player.addModule(ContainerMp4Module);
Player.addModule(XmlModule);
Player.addModule(DashModule);
Player.addModule(ContainerWebMModule);
Player.addModule(SubtitlesModule);
Player.addModule(SubtitlesNativeModuleModule);
Player.addModule(SubtitlesVTTModule);
Player.addModule(SubtitlesModuleCEA);
Player.addModule(PolyfillModule);
Player.addModule(StyleModule);
Player.addModule(RemoteControlModule);
Player.addModule(Thumbnail);

export default {
  name: 'BitmovinPlayer',
  components: {
    Loader,
  },
  props: {
    isSchedule: {
      type: Boolean,
      default: false,
    },
    mediaItem: {
      type: Object,
      default: null,
    },
    isControls: {
      type: Boolean,
      default: true,
    },
    isOnlySoundControls: {
      type: Boolean,
      default: false,
    },
    isPayback: {
      type: Boolean,
      default: false,
    },
    isAddLive: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    markIn: {
      type: Number,
      default: null,
    },
    markOut: {
      type: Number,
      default: null,
    },
    duration: {
      type: Number,
      default: null,
    },
    onEventFinished: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    const {
      playerSource,
      type,
    } = this.mediaItem;

    return {
      isDestroy: false,
      player: null,
      isLoading: false,
      playerConfig: {
        key: CONFIG.player.bitmovinKey,
        remotecontrol: {
          type: 'googlecast',
          customReceiverConfig: {
            receiverStylesheetUrl: 'https://images.broadcastcloud.tv/broadcastcloud/chromecast/receiverStylesheetUrl.css',
          },
        },
        playback: {
          autoplay: this.autoplay,
        },
        logs: {
          bitmovin: false,
          level: 'off',
        },
        events: {
          [PlayerEvent.Error]: this.checkHasError,
        },
      },
      source: {
        dash: '',
        hls: '',
        progressive: type === 'vod'
          ? playerSource.progressive.reduce((prev, current) => (prev.type === 'video/mp4' && Number(prev.label) > Number(current.label)
            ? prev
            : current)).url
          : '',
        markers: null,
        thumbnailTrack: {
          url: '',
        },
        labeling: {},
      },
    };
  },
  mounted() {
    this.source = this.mediaItem.playerSource;
    this.setupPlayer();
  },
  beforeDestroy() {
    this.isDestroy = true;
    this.destroyPlayer();
  },
  methods: {
    setupPlayer() {
      this.isLoading = true;
      // eslint-disable-next-line no-undef
      const player = new Player(this.$refs.player, this.playerConfig);
      if (!this.isAddLive) {
        const LiveStreamRecovery = (player, source, maxErrorCount) => {
          const destroyErrorCount = 200;
          const errorEvent = 'error';
          const downloadSourceEvent = 'downloadfinished';
          const readyEvent = 'ready';
          let restarted = false;
          let consecutiveErrors = 0;

          player.on(errorEvent, () => {
            if (consecutiveErrors < maxErrorCount) {
              player.load(source).then(() => {
                player.play();
              });
              restarted = true;
            }
          });

          player.on(downloadSourceEvent, (data) => {
            if (!data.success) {
              consecutiveErrors += 1;
            } else {
              consecutiveErrors = 0;
            }

            if (this.isDestroy) {
              consecutiveErrors = destroyErrorCount;
            }
          });

          player.on(readyEvent, () => {
            if (restarted) {
              player.play();
              restarted = false;
            }
          });
        };
        const maxErrorCount = 30;
        // eslint-disable-next-line no-unused-vars
        const recovery = new LiveStreamRecovery(player, this.source, maxErrorCount);
      }

      player.on('subtitleadded', () => {
        if (localStorage.getItem('currentSub')) {
          const currentSub = Array.from(player.subtitles.list()).find((item) => item.lang === JSON.parse(localStorage.getItem('currentSub')).lang);
          player.subtitles.enable(currentSub.id);
        }
      });

      player.on('subtitleenabled', (e) => {
        localStorage.setItem('currentSub', JSON.stringify(e.subtitle));
      });

      player.on('audiochanged', (e) => {
        localStorage.setItem('currentTrack', JSON.stringify(e.targetAudio));
      });

      // eslint-disable-next-line no-unused-vars
      const uiManager = new UIManager(player, this.createUIContainer());
      player.load(this.source)
        .then(() => {
          this.isLoading = false;
          this.player = player;

          if (localStorage.getItem('currentTrack')) {
            const tracks = player.getAvailableAudio();
            const currentTrack = tracks.find((item) => item.lang === JSON.parse(localStorage.getItem('currentTrack')).lang);
            player.setAudio(currentTrack.id);
          }

          this.$store.dispatch(SETUP_PLAYER, player);

          if (this.mediaItem.type === 'livestream' || this.mediaItem.type === 'livestream_url') {
            player.play();
          }
        },
        (err) => {
          this.isLoading = false;
          if (err.code === '1201') {
            err.message = 'sdasdasdasd';
          }
          throw err;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    destroyPlayer() {
      if (this.player) {
        this.$store.dispatch(DESTROY_PLAYER);
        this.player.destroy();
      }
    },
    checkHasError(event) {
      if (event.code === ErrorCode.SOURCE_COULD_NOT_LOAD_MANIFEST) {
        this.onEventFinished();
      }
    },
    createUIContainer() {
      const subtitleOverlay = new SubtitleOverlay();

      const settingsPanel = new SettingsPanel({
        components: [
          new SettingsPanelPage({
            components: [
              new SettingsPanelItem('Video Quality', new VideoQualitySelectBox()),
              new SettingsPanelItem('Speed', new PlaybackSpeedSelectBox()),
              new SettingsPanelItem('Audio Quality', new AudioQualitySelectBox()),
            ],
          }),
        ],
        hidden: true,
      });

      const subtitleListBoxSettingsPanelPage = new SettingsPanelPage({
        components: [
          new SettingsPanelItem('Subtitles', new SubtitleSelectBox()),
        ],
      });

      const subtitleSettingsPanel = new SettingsPanel({
        components: [
          subtitleListBoxSettingsPanelPage,
        ],
        hidden: true,
        pageTransitionAnimation: true,
      });

      const subtitleSettingsPanelPage = new SubtitleSettingsPanelPage({
        settingsPanel: subtitleSettingsPanel,
        overlay: subtitleOverlay,
      });

      const subtitleSettingsOpenButton = new SettingsPanelPageOpenButton({
        targetPage: subtitleSettingsPanelPage,
        container: subtitleSettingsPanel,
        text: 'Settings',
        cssClasses: ['customization-open-button'],
      });

      subtitleListBoxSettingsPanelPage.addComponent(
        new SettingsPanelItem('Settings', subtitleSettingsOpenButton, {
          cssClasses: ['subtitle-customization-settings-panel-item'],
          hidden: !this.mediaItem.hasCompletedSubtitles,
        }),
      );
      subtitleSettingsPanel.addComponent(subtitleSettingsPanelPage);

      const audioTrackSettingsPanel = new SettingsPanel({
        components: [
          new SettingsPanelPage({
            components: [
              new SettingsPanelItem('Audio Tracks', new AudioTrackSelectBox()),
            ],
          }),
        ],
        hidden: true,
      });

      const controlBar = new ControlBar({
        components: [
          audioTrackSettingsPanel,
          subtitleSettingsPanel,
          settingsPanel,
          new Container({
            components: [
              new PlaybackTimeLabel(
                { timeLabelMode: PlaybackTimeLabelMode.CurrentTime, hideInLivePlayback: true },
              ),
              new SeekBar({ label: new SeekBarLabel() }),
              new PlaybackTimeLabel({ timeLabelMode: PlaybackTimeLabelMode.TotalTime, cssClasses: ['text-right'] }),
            ],
            cssClasses: ['controlbar-top'],
          }),
          new Container({
            components: [
              new PlaybackToggleButton(),
              new VolumeToggleButton(),
              new VolumeSlider(),
              new Spacer(),
              new PictureInPictureToggleButton(),
              new AirPlayToggleButton(),
              new CastToggleButton(),
              new VRToggleButton(),
              new SettingsToggleButton({
                settingsPanel: audioTrackSettingsPanel,
                cssClass: 'ui-audiotracksettingstogglebutton',
              }),
              new SettingsToggleButton({
                settingsPanel: subtitleSettingsPanel,
                cssClass: 'ui-subtitlesettingstogglebutton',
              }),
              new SettingsToggleButton({ settingsPanel }),
              new FullscreenToggleButton(),
            ],
            cssClasses: ['controlbar-bottom'],
          }),
        ],
      });

      return new UIContainer({
        components: [
          subtitleOverlay,
          new BufferingOverlay(),
          new PlaybackToggleOverlay(),
          new CastStatusOverlay(),
          controlBar,
          new TitleBar(),
          new RecommendationOverlay(),
          new Watermark(),
          new ErrorMessageOverlay(),
        ],
      });
    },
  },
};
</script>
<style scoped>
  .flex {
    display: flex;
    width: 100%;
  }

  .player >>> .bmpui-ui-uicontainer.bmpui-controls-shown .bmpui-ui-hugeplaybacktogglebutton:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .player >>> .bmpui-ui-errormessage-overlay .bmpui-ui-errormessage-label {
    font-size: 1em;
    word-break: break-word;
  }

  .no-controls >>> .bmpui-ui-controlbar {
    display: none !important;
  }

  .only-sound-controls >>> .bmpui-ui-settings-panel,
  .only-sound-controls >>> .bmpui-controlbar-top,
  .only-sound-controls >>> .bmpui-ui-playbacktogglebutton,
  .only-sound-controls >>> .bmpui-ui-piptogglebutton,
  .only-sound-controls >>> .bmpui-ui-airplaytogglebutton,
  .only-sound-controls >>> .bmpui-ui-casttogglebutton,
  .only-sound-controls >>> .bmpui-ui-vrtogglebutton,
  .only-sound-controls >>> .bmpui-ui-settingstogglebutton,
  .only-sound-controls >>> .bmpui-ui-fullscreentogglebutton {
    display: none !important;
  }

  .audio >>> .bitmovinplayer-poster {
    display: block !important;
  }
</style>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i');

.bmpui-subtitle-region-container {
  position: absolute !important;
  padding: 10px !important;
  bottom: 1em !important;
  left: 3em !important;
  right: 3em !important;
  top: auto !important;
}

.bmpui-ui-subtitle-label {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  background: rgba(0, 0, 0, 0.6) !important;
  outline: none !important;
  padding: 10px !important;
  width: auto !important;
  position: static !important;
  bottom: 2em !important;
}

.bmpui-ui-settingspanelpageopenbutton {
  padding-left: 10px!important;
}

.bmpui-ui-playbacktoggle-overlay .bmpui-ui-hugeplaybacktogglebutton {
  background-color: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none;
}
</style>

<style>
  [id^="bmpui-id"][class^="bmpui-ui"]:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
</style>
