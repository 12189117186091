export const ADD_LANGUAGE_REQUEST = 'ADD_LANGUAGE_REQUEST';
export const ADD_LANGUAGE_SUCCESS = 'ADD_LANGUAGE_SUCCESS';
export const ADD_LANGUAGE_ERROR = 'ADD_LANGUAGE_ERROR';

export const GET_LANGUAGES_REQUEST = 'GET_LANGUAGES_REQUEST';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_ERROR = 'GET_LANGUAGES_ERROR';

export const GET_LANGUAGES_SELECT_REQUEST = 'GET_LANGUAGES_SELECT_REQUEST';
export const GET_LANGUAGES_SELECT_SUCCESS = 'GET_LANGUAGES_SELECT_SUCCESS';
export const GET_LANGUAGES_SELECT_ERROR = 'GET_LANGUAGES_SELECT_ERROR';

export const GET_LANGUAGE_REQUEST = 'GET_LANGUAGE_REQUEST';
export const GET_LANGUAGE_SUCCESS = 'GET_LANGUAGE_SUCCESS';
export const GET_LANGUAGE_ERROR = 'GET_LANGUAGE_ERROR';

export const EDIT_LANGUAGE_REQUEST = 'EDIT_LANGUAGE_REQUEST';
export const EDIT_LANGUAGE_SUCCESS = 'EDIT_LANGUAGE_SUCCESS';
export const EDIT_LANGUAGE_ERROR = 'EDIT_LANGUAGE_ERROR';

export const REMOVE_LANGUAGE_REQUEST = 'REMOVE_LANGUAGE_REQUEST';
export const REMOVE_LANGUAGE_SUCCESS = 'REMOVE_LANGUAGE_SUCCESS';
export const REMOVE_LANGUAGE_ERROR = 'REMOVE_LANGUAGE_ERROR';

/*  Media Localization */

export const GET_LANGUAGE_MEDIA_REQUEST = 'GET_LANGUAGE_MEDIA_REQUEST';
export const GET_LANGUAGE_MEDIA_SUCCESS = 'GET_LANGUAGE_MEDIA_SUCCESS';
export const GET_LANGUAGE_MEDIA_ERROR = 'GET_LANGUAGE_MEDIA_ERROR';

export const ADD_LANGUAGE_MEDIA_REQUEST = 'ADD_LANGUAGE_MEDIA_REQUEST';
export const ADD_LANGUAGE_MEDIA_SUCCESS = 'ADD_LANGUAGE_MEDIA_SUCCESS';
export const ADD_LANGUAGE_MEDIA_ERROR = 'ADD_LANGUAGE_MEDIA_ERROR';

/* end */

/* Actions for folder localization */

export const GET_LANGUAGE_FOLDER_REQUEST = 'GET_LANGUAGE_FOLDER_REQUEST';
export const GET_LANGUAGE_FOLDER_SUCCESS = 'GET_LANGUAGE_FOLDER_SUCCESS';
export const GET_LANGUAGE_FOLDER_ERROR = 'GET_LANGUAGE_FOLDER_ERROR';

export const ADD_LANGUAGE_FOLDER_REQUEST = 'ADD_LANGUAGE_FOLDER_REQUEST';
export const ADD_LANGUAGE_FOLDER_SUCCESS = 'ADD_LANGUAGE_FOLDER_SUCCESS';
export const ADD_LANGUAGE_FOLDER_ERROR = 'ADD_LANGUAGE_FOLDER_ERROR';

/* end */

/* Actions for feed localization */
export const GET_LANGUAGE_FEED_REQUEST = 'GET_LANGUAGE_FEED_REQUEST';
export const GET_LANGUAGE_FEED_SUCCESS = 'GET_LANGUAGE_FEED_SUCCESS';
export const GET_LANGUAGE_FEED_ERROR = 'GET_LANGUAGE_FEED_ERROR';

export const ADD_LANGUAGE_FEED_REQUEST = 'ADD_LANGUAGE_FEED_REQUEST';
export const ADD_LANGUAGE_FEED_SUCCESS = 'ADD_LANGUAGE_FEED_SUCCESS';
export const ADD_LANGUAGE_FEED_ERROR = 'ADD_LANGUAGE_FEED_ERROR';
/* end */

export const INIT_FORM_CHANGES = 'INIT_FORM_CHANGES';
export const SET_FORM_CHANGES = 'SET_FORM_CHANGES';
export const SET_FORM_CHANGES_SUCCESS = 'SET_FORM_CHANGES_SUCCESS';

export const SET_CANCEL_TOKEN = 'SET_CANCEL_TOKEN';

/* cms general localization */

export const GET_LANGUAGE_GENERAL_REQUEST = 'GET_LANGUAGE_GENERAL_REQUEST';
export const GET_LANGUAGE_GENERAL_SUCCESS = 'GET_LANGUAGE_GENERAL_SUCCESS';
export const GET_LANGUAGE_GENERAL_ERROR = 'GET_LANGUAGE_GENERAL_ERROR';

export const ADD_LANGUAGE_GENERAL_REQUEST = 'ADD_LANGUAGE_GENERAL_REQUEST';
export const ADD_LANGUAGE_GENERAL_SUCCESS = 'ADD_LANGUAGE_GENERAL_SUCCESS';
export const ADD_LANGUAGE_GENERAL_ERROR = 'ADD_LANGUAGE_GENERAL_ERROR';

/* end */

/* cms rows localization */

export const GET_LANGUAGE_ROWS_REQUEST = 'GET_LANGUAGE_ROWS_REQUEST';
export const GET_LANGUAGE_ROWS_SUCCESS = 'GET_LANGUAGE_ROWS_SUCCESS';
export const GET_LANGUAGE_ROWS_ERROR = 'GET_LANGUAGE_ROWS_ERROR';

export const ADD_LANGUAGE_ROWS_REQUEST = 'ADD_LANGUAGE_ROWS_REQUEST';
export const ADD_LANGUAGE_ROWS_SUCCESS = 'ADD_LANGUAGE_ROWS_SUCCESS';
export const ADD_LANGUAGE_ROWS_ERROR = 'ADD_LANGUAGE_ROWS_ERROR';

/* end */

/* cms grid localization */

export const GET_LANGUAGE_GRID_REQUEST = 'GET_LANGUAGE_GRID_REQUEST';
export const GET_LANGUAGE_GRID_SUCCESS = 'GET_LANGUAGE_GRID_SUCCESS';
export const GET_LANGUAGE_GRID_ERROR = 'GET_LANGUAGE_GRID_ERROR';

export const ADD_LANGUAGE_GRID_REQUEST = 'ADD_LANGUAGE_GRID_REQUEST';
export const ADD_LANGUAGE_GRID_SUCCESS = 'ADD_LANGUAGE_GRID_SUCCESS';
export const ADD_LANGUAGE_GRID_ERROR = 'ADD_LANGUAGE_GRID_ERROR';

/* end */

/* cms row items */

export const GET_LANGUAGE_ROW_ITEMS_REQUEST = 'GET_LANGUAGE_ROW_ITEMS_REQUEST';
export const GET_LANGUAGE_ROW_ITEMS_SUCCESS = 'GET_LANGUAGE_ROW_ITEMS_SUCCESS';
export const GET_LANGUAGE_ROW_ITEMS_ERROR = 'GET_LANGUAGE_ROW_ITEMS_ERROR';

export const ADD_LANGUAGE_ROW_ITEMS_REQUEST = 'ADD_LANGUAGE_ROW_ITEMS_REQUEST';
export const ADD_LANGUAGE_ROW_ITEMS_SUCCESS = 'ADD_LANGUAGE_ROW_ITEMS_SUCCESS';
export const ADD_LANGUAGE_ROW_ITEMS_ERROR = 'ADD_LANGUAGE_ROW_ITEMS_ERROR';

/* end */

/* banners */

export const GET_LANGUAGE_BANNERS_REQUEST = 'GET_LANGUAGE_BANNERS_REQUEST';
export const GET_LANGUAGE_BANNERS_SUCCESS = 'GET_LANGUAGE_BANNERS_SUCCESS';
export const GET_LANGUAGE_BANNERS_ERROR = 'GET_LANGUAGE_BANNERS_ERROR';

export const ADD_LANGUAGE_BANNERS_REQUEST = 'ADD_LANGUAGE_BANNERS_REQUEST';
export const ADD_LANGUAGE_BANNERS_SUCCESS = 'ADD_LANGUAGE_BANNERS_SUCCESS';
export const ADD_LANGUAGE_BANNERS_ERROR = 'ADD_LANGUAGE_BANNERS_ERROR';

/* end */

/* Navigation */

export const GET_LANGUAGE_NAVIGATION_REQUEST = 'GET_LANGUAGE_NAVIGATION_REQUEST';
export const GET_LANGUAGE_NAVIGATION_SUCCESS = 'GET_LANGUAGE_NAVIGATION_SUCCESS';
export const GET_LANGUAGE_NAVIGATION_ERROR = 'GET_LANGUAGE_NAVIGATION_ERROR';

export const ADD_LANGUAGE_NAVIGATION_REQUEST = 'ADD_LANGUAGE_NAVIGATION_REQUEST';
export const ADD_LANGUAGE_NAVIGATION_SUCCESS = 'ADD_LANGUAGE_NAVIGATION_SUCCESS';
export const ADD_LANGUAGE_NAVIGATION_ERROR = 'ADD_LANGUAGE_NAVIGATION_ERROR';

/* end */

/* Events */

export const GET_LANGUAGE_EV_GROUP_REQUEST = 'GET_LANGUAGE_EV_GROUP_REQUEST';
export const GET_LANGUAGE_EV_GROUP_SUCCESS = 'GET_LANGUAGE_EV_GROUP_SUCCESS';
export const GET_LANGUAGE_EV_GROUP_ERROR = 'GET_LANGUAGE_EV_GROUP_ERROR';

export const ADD_LANGUAGE_EV_GROUP_REQUEST = 'ADD_LANGUAGE_EV_GROUP_REQUEST';
export const ADD_LANGUAGE_EV_GROUP_SUCCESS = 'ADD_LANGUAGE_EV_GROUP_SUCCESS';
export const ADD_LANGUAGE_EV_GROUP_ERROR = 'ADD_LANGUAGE_EV_GROUP_ERROR';

export const GET_LANGUAGE_SINGLE_EV_REQUEST = 'GET_LANGUAGE_SINGLE_EV_REQUEST';
export const GET_LANGUAGE_SINGLE_EV_SUCCESS = 'GET_LANGUAGE_SINGLE_EV_SUCCESS';
export const GET_LANGUAGE_SINGLE_EV_ERROR = 'GET_LANGUAGE_SINGLE_EV_ERROR';

export const ADD_LANGUAGE_SINGLE_EV_REQUEST = 'ADD_LANGUAGE_SINGLE_EV_REQUEST';
export const ADD_LANGUAGE_SINGLE_EV_SUCCESS = 'ADD_LANGUAGE_SINGLE_EV_SUCCESS';
export const ADD_LANGUAGE_SINGLE_EV_ERROR = 'ADD_LANGUAGE_SINGLE_EV_ERROR';

/* end  */

/* genres */

export const GET_LANGUAGE_GENRES_REQUEST = 'GET_LANGUAGE_GENRES_REQUEST';
export const GET_LANGUAGE_GENRES_SUCCESS = 'GET_LANGUAGE_GENRES_SUCCESS';
export const GET_LANGUAGE_GENRES_ERROR = 'GET_LANGUAGE_GENRES_ERROR';

export const ADD_LANGUAGE_GENRES_REQUEST = 'ADD_LANGUAGE_GENRES_REQUEST';
export const ADD_LANGUAGE_GENRES_SUCCESS = 'ADD_LANGUAGE_GENRES_SUCCESS';
export const ADD_LANGUAGE_GENRES_ERROR = 'ADD_LANGUAGE_GENRES_ERROR';

/* end */
