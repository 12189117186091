<template>
  <div class="wrapper">
    Note, you are now under "{{ profileName }}" account.
    <inline-svg
      :src="iconLogoutUnderUser"
      class="svg-icon"
      @click="logoutUnderOtherUser"
    >
    </inline-svg>
  </div>
</template>

<script>
import iconLogoutUnderUser from '../../assets/icons/icon-exit-under-other-user.svg';

export default {
  name: 'OtherUserInfo',
  props: {
    profileName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconLogoutUnderUser,
    };
  },
  methods: {
    logoutUnderOtherUser() {
      localStorage.removeItem('isOtherUserMode');
      localStorage.removeItem('isOtherUserEmail');
      document.location.reload(true);
    },
  },
};
</script>

<style scoped>
  .wrapper {
    align-items: center;
    background: #e77925;
    bottom: 0;
    color: #fff;
    display: flex;
    height: 45px;
    justify-content: space-between;
    padding: 0 21px;
    position: absolute;
    right: 0;
    width: 533px;
    z-index: 999;
  }

  .svg-icon {
    cursor: pointer;
  }
</style>
