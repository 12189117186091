<template>
  <div :class="['overlay-modal', windowHeight < 1000 ? 'scroll' : '']">
    <Loader :isVisible="isLoading" />
    <div class="back-btn">
      <Button
        :className="'back-local'"
        :title="backButtonText"
        :srcSvg="iconBackTo"
        :eventFunction="goBack"
        :hasBorder="true"
      />
    </div>
    <div class="main-tabs-container">
      <Tabs>
        <Tab
          v-if="enabledLanguages.length"
          id="localization"
          name="Localization"
          :selected="true"
          class="wrapper-general"
        >
          <div class="flex">
            <div class="eng-local-wrapper">
              <div class="pb-13">
                English (default)
              </div>
              <div
                :class="[type === 'season'
                  ? 'eng-local-container-season'
                  : 'eng-local-container']"
              >
                <FolderEngForm
                  :metadata="metadata"
                  :type="type"
                />
              </div>
            </div>
            <div class="translate-local-wrapper">
              <Tabs
                :removeMargin="true"
                :onSelectTab="handleSelectTab"
              >
                <Tab
                  v-for="lang in enabledLanguages"
                  :id="lang.name"
                  :key="lang.id"
                  :name="lang.name"
                  :selected="formData.mode === lang.name"
                  :isLocalisation="true"
                  :className="[isEditedForm.length
                    && isEditedForm.find(item => item.id === lang.id && !!item.value)
                    ? 'error-tab'
                    : 'tab']"
                >
                  <TranslateLocalForm
                    :lang="lang"
                    :metadata="metadata"
                    :enabledLanguages="enabledLanguages"
                    :selected="formData.mode === lang.name"
                    :type="type"
                    @changedLanguage="changedLanguage"
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import Button from '../common/Button.vue';
import Tabs from '../common/Tabs/Tabs.vue';
import Tab from '../common/Tabs/Tab.vue';
import FolderEngForm from '../forms/localization/FolderEngForm.vue';
import TranslateLocalForm from '../forms/localization/TranslateLocalForm.vue';
import iconBackTo from '../../assets/icons/icon-back-to.svg';
import {
  GET_LANGUAGES_REQUEST,
  SET_FORM_CHANGES,
} from '../../store/actions/localization/localizationActions';
import Loader from '../common/Loader.vue';

export default {
  name: 'FolderLocalization',
  components: {
    Loader,
    TranslateLocalForm,
    FolderEngForm,
    Button,
    Tabs,
    Tab,
  },
  props: {
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
    metadata: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconBackTo,
      windowHeight: window.outerHeight,
      enabledLanguages: [],
      protKey: 0,
      formData: {
        mode: '',
        changedLanguage: {},
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.localization.isLoading,
      isEditedForm: (state) => state.localization.isEditedForm,
    }),
    ...mapGetters([
      'getLanguages',
      'getCurrentAccount',
    ]),
    selectedProtection() {
      if (this.enabledLanguages.length) {
        return false;
      }
      return true;
    },
    backButtonText() {
      switch (this.type) {
      case 'playlist':
        return 'Back to Playlist Editor';
      case 'season':
        return 'Back to Season Editor';
      case 'series':
        return 'Back to Series Editor';
      case 'feature':
        return 'Back to Feature Editor';
      default:
        return 'Back';
      }
    },
  },
  created() {
    window.addEventListener('resize', this.onResize);
    const params = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };

    this.$store.dispatch(GET_LANGUAGES_REQUEST, params).then((res) => {
      this.enabledLanguages = res.data;
      this.formData.mode = res.data && res.data[0] && res.data[0].name;
      this.protKey += 1;
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    goBack() {
      this.onClose();
    },
    handleSelectTab(id) {
      this.formData.mode = id;
      this.$store.dispatch(SET_FORM_CHANGES, this.formData.changedLanguage);
    },
    onResize() {
      this.windowHeight = window.outerHeight;
    },
    changedLanguage(value) {
      this.formData.changedLanguage = value;
    },
  },
};
</script>

<style scoped lang="scss">
.overlay-modal {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 95%;
  background: #222222;
  z-index: 999;
  overflow: auto;
}

.scroll {
  overflow: auto;
}

.back-btn {
  padding-top: 20px;
  padding-left: 20px;
}

.main-tabs-container {
  padding: 10px 20px 30px;
}

.eng-local-wrapper {
  width: 40%;
}

.eng-local-container {
  border-top: 2px solid var(--gray);
  border-right: 2px solid var(--gray);
  height: 144vh;
}

.eng-local-container-season {
  border-top: 2px solid var(--gray);
  border-right: 2px solid var(--gray);
  height: 110vh;
}

.eng-local-container-series {
  border-top: 2px solid var(--gray);
  border-right: 2px solid var(--gray);
  height: 100%;
}

.pb-13 {
  padding-bottom: 15px;
  line-height: 15px;
}

.translate-local-wrapper {
  width: 60%;
}

.flex {
  display: flex;
  align-items: flex-start;
}

.back-local {
  padding-left: 0;
}

.eng-local-wrapper-series {
  height: 100vh;
}

@media all and (max-height: 950px)  {
  .eng-local-container-season {
    height: 114vh;
  }
}

@media all and (max-height: 910px)  {
  .eng-local-container-season {
    height: 118vh;
  }
}

@media all and (max-height: 860px)  {
  .eng-local-container-season {
    height: 124vh;
  }
}

@media all and (max-height: 930px)  {
  .eng-local-container {
    height: 149vh;
  }
}

@media all and (max-height: 890px)  {
  .eng-local-container {
    height: 159vh;
  }
}

@media all and (max-height: 830px)  {
  .eng-local-container {
    height: 171vh;
  }

  .eng-local-container-season {
    height: 130vh;
  }
}

@media all and (max-height: 790px)  {
  .eng-local-container {
    height: 181vh;
  }

  .eng-local-container-season {
    height: 136vh;
  }
}
</style>
