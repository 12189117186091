import { render, staticRenderFns } from "./GridTabsByPage.vue?vue&type=template&id=e665fd18&scoped=true&"
import script from "./GridTabsByPage.vue?vue&type=script&lang=js&"
export * from "./GridTabsByPage.vue?vue&type=script&lang=js&"
import style0 from "./GridTabsByPage.vue?vue&type=style&index=0&id=e665fd18&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e665fd18",
  null
  
)

export default component.exports