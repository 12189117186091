export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const ANONIM_AUTH_REQUEST = 'ANONIM_AUTH_REQUEST';
export const ANONIM_AUTH_SUCESS = 'ANONIM_AUTH_SUCESS';
export const ANONIM_AUTH_ERROR = 'ANONIM_AUTH_ERROR';

export const CHECK_RESET_TOKEN_REQUEST = 'CHECK_RESET_TOKEN_REQUEST';
export const CHECK_RESET_TOKEN_SUCCESS = 'CHECK_RESET_TOKEN_SUCCESS';
export const CHECK_RESET_TOKEN_ERROR = 'CHECK_RESET_TOKEN_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
