<template>
  <div>
    <div class="flex">
      <div class="left-path">
        <span class="system">
          Scheduled Events
        </span>
        <span>
          / Apps Event Group
        </span>
      </div>
    </div>
    <div class="title">
      {{ 'Apps Event Group' }}
    </div>
    <div class="form-wrap">
      <MaterialSelect
        :value="formData.tvAppsEventGroup"
        :label="'Event Group'"
        :withEmptyField="true"
        :options="eventGroupList"
        :handleChange="changeTvAppsEventGroup"
        @input="onSelectTvAppsEventGroup"
      />
      <Button
        :title="'Apply'"
        :classType="'primary'"
        :type="'submit'"
        :eventFunction="onSave"
      />
    </div>

    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :title="'Warning Message'"
      :cancelButtonTitle="'Close'"
      :confirmButtonTitle="'Confirm'"
      :bodyMessage="bodyMessage"
      :onAccept="onSave"
      :onClose="onClose"
    />
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  GET_ALL_EVENT_GROUPS,
  GET_EVENT_GROUP,
  EDIT_TV_APPS_EVENT_GROUP,
} from '../../store/actions/events/eventGroups';
import CONFIG from '../../constants/config';

import Button from '../common/Button.vue';
import NotificationModal from '../NotificationModal.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';

export default {
  name: 'TvAppsEventGroupForm',
  components: {
    MaterialSelect,
    Button,
    NotificationModal,
  },
  props: {
    handleFormChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isModalOpen: false,
      bodyMessage: CONFIG.modals.changeTvAppsEventGroup,
      successMessage: CONFIG.successMessages.editEventGroupMessage,
      formData: {
        tvAppsEventGroup: null,
        newTvAppsEventGroup: null,
      },
    };
  },
  computed: {
    ...mapState({
      eventGroups: (state) => state.eventGroups.eventGroups,
      eventGroup: (state) => state.eventGroups.eventGroup,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    currentTvAppsEventGroup() {
      const group = this.eventGroups
        .find((item) => item.tvAppsEnabled === true);
      return group ? group.id : null;
    },
    eventGroupList() {
      return this.eventGroups
        .map(({ name, id }) => {
          const str = (name.length > 100)
            ? `${name.slice(0, 100)}...`
            : name;

          return {
            name: str,
            code: id,
          };
        });
    },
  },
  mounted() {
    this.getAllGroups();
  },
  methods: {
    getAllGroups() {
      const params = {
        accountId: this.getCurrentAccountId,
        sortFiled: 'seg.name',
        sortBy: 'ASC',
      };
      this.$store.dispatch(GET_ALL_EVENT_GROUPS, params)
        .then(() => {
          this.formData.tvAppsEventGroup = this.currentTvAppsEventGroup;
          this.formData.newTvAppsEventGroup = this.currentTvAppsEventGroup;

          if (this.currentTvAppsEventGroup) {
            const params = {
              accountId: this.getCurrentAccountId,
              eventGroupId: this.currentTvAppsEventGroup,
            };

            this.$store.dispatch(GET_EVENT_GROUP, params);
          }
        });
    },
    onSelectTvAppsEventGroup(value) {
      this.formData.newTvAppsEventGroup = value;
    },
    changeTvAppsEventGroup() {
      if (this.formData.newTvAppsEventGroup !== this.currentTvAppsEventGroup) {
        if (this.eventGroup && this.eventGroup.currentStandaloneEvent) {
          this.isModalOpen = true;
        } else {
          this.formData.tvAppsEventGroup = this.formData.newTvAppsEventGroup;
        }
        this.handleFormChange(true);
      } else {
        this.formData.tvAppsEventGroup = this.formData.newTvAppsEventGroup;
        this.handleFormChange(false);
      }
    },
    onSave() {
      const params = {
        accountId: this.getCurrentAccountId,
        data: {
          standaloneEventGroup: this.formData.newTvAppsEventGroup,
        },
      };

      this.$store.dispatch(EDIT_TV_APPS_EVENT_GROUP, params)
        .then(() => {
          this.getAllGroups();
          this.handleFormChange(false);

          this.$toasted.global.success({
            message: this.successMessage,
          });
        });
    },
    onClose() {
      this.formData.newTvAppsEventGroup = this.formData.tvAppsEventGroup;
      this.handleFormChange(false);
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped>
  .flex {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 40px;
  }

  .left-path {
    width: 100%;
  }

  .system {
    color: #4c5057;
  }

  .form-wrap {
    margin-top: 20px;
    width: 100%;
    max-width: 800px;
  }
</style>
