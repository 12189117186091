export const GET_ALL_SERVERS = 'GET_ALL_SERVERS';
export const GET_ALL_SERVERS_REQUEST = 'GET_ALL_SERVERS_REQUEST';
export const GET_ALL_SERVERS_SUCCESS = 'GET_ALL_SERVERS_SUCCESS';
export const GET_ALL_SERVERS_ERROR = 'GET_ALL_SERVERS_ERROR';

export const GET_SERVER = 'GET_SERVER';
export const GET_SERVER_REQUEST = 'GET_SERVER_REQUEST';
export const GET_SERVER_SUCCESS = 'GET_SERVER_SUCCESS';
export const GET_SERVER_ERROR = 'GET_SERVER_ERROR';

export const ADD_SERVER = 'ADD_SERVER';
export const ADD_SERVER_REQUEST = 'ADD_SERVER_REQUEST';
export const ADD_SERVER_SUCCESS = 'ADD_SERVER_SUCCESS';
export const ADD_SERVER_ERROR = 'ADD_SERVER_ERROR';

export const EDIT_SERVER = 'EDIT_SERVER';
export const EDIT_SERVER_REQUEST = 'EDIT_SERVER_REQUEST';
export const EDIT_SERVER_SUCCESS = 'EDIT_SERVER_SUCCESS';
export const EDIT_SERVER_ERROR = 'EDIT_SERVER_ERROR';

export const SET_IS_VIEW_ONLY_FORM = 'SET_IS_VIEW_ONLY_FORM';
export const SET_IS_VIEW_ONLY_FORM_SUCCESS = 'SET_IS_VIEW_ONLY_FORM_SUCCESS';
