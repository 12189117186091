<script>
import { mapState } from 'vuex';
import { GET_MEDIA_LANGUAGE } from '../../store/actions/media/mediaItem';
import TagManagement from '../inputs/TagManagement.vue';
import { MEDIA_TAG_REG_EXP } from '../../utils/validation/regExp';

export default {
  name: 'LanguageTagManagement',
  extends: TagManagement,
  props: {
    languages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterValue: '',
    };
  },
  computed: {
    ...mapState({
      languageList: (state) => state.mediaItem.languageList,
    }),
  },
  created() {
    this.getTags();
  },
  methods: {
    getTags() {
      this.$store.dispatch(GET_MEDIA_LANGUAGE).then((res) => {
        this.options = res.data;
      });
    },
    validateSearch(searchValue) {
      const validation = {
        value: searchValue,
        isValid: true,
        message: '',
      };

      if (searchValue && !searchValue.match(MEDIA_TAG_REG_EXP)) {
        validation.isValid = false;
        validation.message = 'The Tag field may only contain alphabetic characters';
      } else if (searchValue.length > 50) {
        validation.isValid = false;
        validation.message = 'The field can have no more than 50 characters';
      }

      return validation;
    },
    processSearchValue(value) {
      this.filterValue = value;
      // this.getTags();
    },
    onInput(value) {
      this.resetValidation();
      const result = value.map((item) => (item.code));
      this.onChange(result);
    },
  },
};
</script>
