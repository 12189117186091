<template>
  <div class="upload-list-files">
    <div class="border">
      <div class="upload-name">
        {{ fileUpload.file.name }}
        <span>
          ({{ (fileUpload.file.size / bytesInMb).toFixed(2) }} MB)
        </span>
      </div>
      <ProgressBar :file="fileUpload" />
    </div>
  </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue';
import CONFIG from '../../constants/config';

export default {
  name: 'UploadListItem',
  components: {
    ProgressBar,
  },
  props: {
    fileUpload: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bytesInMb: CONFIG.upload.bytesInMb,
    };
  },
};
</script>

<style scoped>
  .upload-list-files {
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
    word-break: break-word;
  }

  .upload-name {
    font-size: 13px;
    padding-bottom: 12px;
  }

  .border {
    border-bottom: 1px solid rgba(67, 67, 77, 0.5);
  }
</style>
