<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="btn-create-account-mobile">
      <Button
        :title="'Add API Key'"
        :classType="'primary'"
        :eventFunction="toCreateAPIKeyHandler"
      />
    </div>
    <div class="flex">
      <div class="left-path">
        <span class="system">
          System Management
        </span>
        <span>
          / API Keys
        </span>
      </div>
      <div class="right-path">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="255"
        />
        <div class="btn-create-account-full">
          <Button
            :title="'Add API Key'"
            :classType="'primary'"
            :eventFunction="toCreateAPIKeyHandler"
          />
        </div>
      </div>
    </div>
    <div class="accounts-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`ak.${sortedField.name}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          api key name
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`a.${sortedField.account}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          account name
        </div>
        <div class="th-name users-count">
          <Sorting
            :sortField="`ak.${sortedField.createdAt}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          created
        </div>
        <div class="th-name users-count">
          <Sorting
            :defaultSortUp="false"
            :sortField="`ak.${sortedField.usedAt}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          last used
        </div>
        <div class="th-name status calls">
          calls per 24h
        </div>
        <div class="th-name status calls">
          status
        </div>
      </div>
      <div
        v-if="!apiKeys.length"
        class="empty-search"
      >
        No API Keys to display
      </div>
      <div
        v-for="(item, key) in apiKeys"
        :key="item.id"
        class="account"
        @mouseover="onHoverElem(item)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div
          class="td company"
          :title="item.name"
        >
          {{ item.name }}
        </div>
        <div
          class="td company"
          :title="item.account.name"
        >
          {{ item.account.name }}
        </div>
        <div class="td users-count">
          {{ item.createdAt | cmsDate(getMainProfile.timezone) }}
        </div>
        <div
          v-if=" item.usedAt"
          class="td users-count"
        >
          {{ item.usedAt | cmsDate(getMainProfile.timezone) }}
        </div>
        <div
          v-else
          class="td users-count"
        >
          -
        </div>
        <div class="td status">
          {{ item.usagePerDay }}
        </div>
        <div class="td status last-item">
          <div
            class="status-ellipse"
            :class="item.status === 'active' ? 'enabled' : 'disabled'"
          >
          </div>
          <span v-if="item.status === 'active'">
            Active
          </span>
          <span v-else>
            Inactive
          </span>
        </div>
        <div
          v-show="isHover && item.id === selectedId"
          class="icons-wrapper"
        >
          <div
            v-if="item.status === 'inactive'"
            :title="'Delete API Key'"
          >
            <inline-svg
              :src="iconRemove"
              class="svg-icon remove"
              @click="onOpenModalForRemove(item)"
            >
            </inline-svg>
          </div>
          <div
            :title="item.status === 'active' ? 'Deactivate API Key' : 'Activate API Key'"
            class="enable-icon"
          >
            <inline-svg
              :src="item.status === 'active' ? iconEnabled : iconDisabled"
              class="svg-icon"
              @click="onOpenModal(item)"
            />
          </div>
          <div class="icon-edit">
            <router-link
              :to="{ path: `api-keys/${item.id}` }"
              :title="'Edit API Key'"
            >
              <inline-svg
                :src="iconEdit"
                class="svg-icon"
              >
              </inline-svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :currentPage="currentPage"
      :totalPages="totalPages"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="modalTitle"
      :onAccept="changeKeyStatus"
      :onClose="onClose"
    />
    <DeleteAccountModal
      ref="modal"
      v-model="isOpenDelete"
      :modal="isOpenDelete"
      :bodyMessage="bodyMessage"
      :title="titleRemoveAcc"
      :isDeleteAcc="true"
      :name="companyName"
      :accId="accId"
      :onClose="onCloseDelete"
      :getAccounts="getAccounts"
      :isAPI="true"
    />
    <NotificationModal
      ref="modal"
      v-model="isNoDelete"
      :modal="isNoDelete"
      :title="'Delete API Key'"
      :bodyMessage="bodyMessage"
      :isInUseAsset="true"
      :warningMess="warningMessage"
      :onAccept="closeDeleteAfter"
      :confirmButtonTitle="'OK'"
      :isNeedCancel="false"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'moment-timezone';
import {
  SET_EDIT_FORM,
  SET_OVERFLOW,
} from '../../store/actions/common';
import Pagination from '../../components/common/Pagination.vue';
import Loader from '../../components/common/Loader.vue';
import NotificationModal from '../../components/NotificationModal.vue';
import Sorting from '../../components/common/Sorting.vue';
import iconEnabled from '../../assets/icons/icon-enabled-acc.svg';
import iconDisabled from '../../assets/icons/icon-disabled-acc.svg';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconFix from '../../assets/icons/icon-fix.svg';
import iconRemove from '../../assets/icons/icon-remove-role.svg';
import LiveSearch from '../../components/common/LiveSearch.vue';
import CONFIG from '../../constants/config';
import Button from '../../components/common/Button.vue';
import DeleteAccountModal from '../../components/DeleteAccountModal.vue';
import { EDIT_API_KEY_REQUEST, GET_API_KEYS_REQUEST } from '../../store/actions/api/api_keys';

export default {
  name: 'APIKeysList',
  components: {
    DeleteAccountModal,
    Pagination,
    LiveSearch,
    NotificationModal,
    Sorting,
    Loader,
    Button,
  },
  data() {
    const {
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editAccount,
    } = CONFIG.tooltips;
    return {
      paymentStatuses: CONFIG.paymentStatuses,
      enabledId: null,
      akamaiAccountCreateId: null,
      akamaiAppSyncId: null,
      isEnabled: false,
      isModalOpen: false,
      isNoDelete: false,
      isHover: false,
      selectedId: null,
      bodyMessage: '',
      modalTitle: '',
      warningMessage: '',
      name: '',
      status: '',
      filter: '',
      sortedField: {
        name: 'name',
        account: 'name',
        createdAt: 'createdAt',
        usedAt: 'usedAt',
      },
      currentSortedField: 'ak.usedAt',
      currentSortBy: 'DESC',
      isSortedUp: false,
      isSortedDown: false,
      isOpenDelete: false,
      titleRemoveAcc: '',
      companyName: '',
      accId: '',
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editAccount,
      iconEnabled,
      iconEdit,
      iconDisabled,
      iconFix,
      iconRemove,
    };
  },
  computed: {
    ...mapGetters([
      'getMainProfile',
    ]),
    ...mapState({
      apiKeys: (state) => state.apiKeys.apiKeys,
      pagination: (state) => state.apiKeys.pagination,
      isLoading: (state) => state.accounts.isLoading,
    }),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    this.handleSortDown('ak.usedAt', 1);
  },
  methods: {
    getAccounts() {
      const params = {
        page: localStorage.getItem('apiKeys') || 1,
        filterValue: '',
      };
      this.$store.dispatch(GET_API_KEYS_REQUEST, params);
    },
    onOpenModalForRemove(item) {
      if (item.status === 'inactive' && !item.deleteAfter) {
        this.$store.dispatch(SET_OVERFLOW, true);
        this.isOpenDelete = true;
        this.enabledId = item.id;
        this.bodyMessage = 'This action cannot be undone. To confirm, please enter the Name of the API Key you wish to delete';
        this.titleRemoveAcc = 'Delete API Key';
        this.companyName = item.name;
        this.accId = item.id;
      }

      if (item.status === 'inactive' && item.deleteAfter) {
        this.isNoDelete = true;
        this.enabledId = item.id;
        this.bodyMessage = '';
        this.warningMessage = `You can only remove a key if it has been inactive for more than one week. API key may be deleted after ${this.getDeleteAfter(item.deleteAfter)}`;
      }
    },
    getDeleteAfter(value) {
      const moment = extendMoment(Moment);
      const format = 'MMMM DD, hh:mm A';
      if (this.getMainProfile.timezone) {
        return moment(value).tz(this.getMainProfile.timezone).format(format);
      }
      return moment(value).format(format);
    },
    onCloseDelete() {
      this.isOpenDelete = false;
    },
    closeDeleteAfter() {
      this.isNoDelete = false;
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    handleSortUp(field) {
      const params = {
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_API_KEYS_REQUEST, params);
    },
    handleSortDown(field, page) {
      const params = {
        page: page || this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_API_KEYS_REQUEST, params);
    },
    onOpenModal(item) {
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.status = item.status;
      this.enabledId = item.id;
      this.name = item.name;
      this.modalTitle = item.status === 'active' ? 'Deactivate API Key' : 'Activate API Key';
      this.bodyMessage = item.status === 'active'
        ? 'Are you sure you want to make this API Key inactive?'
        : 'Are you sure you want to make this API Key active again?';
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        page: params.page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_API_KEYS_REQUEST, getParams);
    },
    getPaginationAction(page) {
      const params = {
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_API_KEYS_REQUEST, params);
    },
    toCreateAcc() {
      this.$router.push('api-keys/add');
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    changeKeyStatus() {
      const params = {
        id: this.enabledId,
        data: {
          name: this.name,
          status: this.status === 'active' ? 'inactive' : 'active',
        },
      };
      this.$store.dispatch(EDIT_API_KEY_REQUEST, params)
        .then(() => {
          const params = {
            page: localStorage.getItem('apiKeys') || 1,
            filterValue: this.filter || '',
          };
          this.onSearch(params);
          this.$toasted.global.success({
            message: `The API Key has been successfully ${this.status === 'active' ? 'deactivated' : 'activated'}`,
          });
        });
    },
    toCreateAPIKeyHandler() {
      this.toCreateAcc();
    },
  },
};
</script>

<style scoped>
.flex {
  align-items: center;
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 40px;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px 25px 25px 30px;
  width: 100%;
}

.system {
  color: #4c5057;
}

.accounts-wrapper {
  border: 1px solid #43434d;
  height: 100%;
  overflow-y: auto;
}

.ac-wrapper-header {
  background: #282a2e;
  display: flex;
  padding: 13px 0;
}

.th-name {
  align-items: center;
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
}

.td {
  font-size: 14px;
  padding: 15px 0;
  word-break: break-word;
}

.number {
  padding-left: 21px;
  width: 50px;
}

.company {
  padding-right: 15px;
  width: 12%;
}

.users-count {
  width: 12%;
}

.status {
  align-items: flex-start;
  display: flex;
  width: 11%;
}

.date {
  align-items: flex-start;
  display: flex;
  width: 7%;
}

.account {
  display: flex;
}

.account:hover {
  background: #383b40;
}

.status-ellipse {
  border-radius: 50%;
  height: 5px;
  margin-right: 6px;
  margin-top: 6px;
  width: 5px;
}

.status-ellipse.enabled,
.status-ellipse.active {
  background: #1fee65;
}

.status-ellipse.disabled,
.status-ellipse.blocked,
.status-ellipse.pending {
  background: #fb3951;
}

.status-ellipse.failed {
  background: #fbf650;
}

.icons-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  flex: 0.93;
}

.icons-wrapper div {
  padding: 10px;
}

.create-button {
  background: #2591cf;
  color: #fff;
  font-size: 16px;
  letter-spacing: normal;
  padding: 0 16px;
  text-transform: none;
}

.right-path {
  align-items: center;
  display: flex;
}

.empty-search {
  padding-top: 40px;
  text-align: center;
}

.btn-create-account-mobile {
  display: none;
}

.svg-icon {
  cursor: pointer;
}

.calls {
  align-items: center;
}

.last-item {
  width: 19%;
}

@media all and (max-width: 1720px) {
  .status {
    width: 10%;
  }
}

@media all and (max-width: 1720px) {
  .users-count {
    width: 9%;
  }
}

@media all and (max-width: 1470px) {
  .users-count {
    width: 8%;
  }

  .pay {
    width: 8%;
  }
}

@media all and (max-width: 1400px) {
  .number {
    width: 30px;
  }

  .status {
    width: 9%;
  }

}

@media all and (max-width: 1170px) {
  .status {
    width: 9.5%;
  }
}

@media all and (max-width: 1100px) {
  .icons-wrapper div {
    padding: 10px 5px;
  }
}

@media all and (max-width: 1024px) {
  .btn-create-account-mobile {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    min-height: 40px;
  }

  .btn-create-account-full {
    display: none;
  }

  .search-wrapper {
    margin-right: 0;
  }
}

@media all and (max-width: 900px) {
  .company {
    padding-right: 7px;
    width: 14%;
  }

  .number {
    width: 24px;
  }

  .status {
    width: 10%;
  }
}
</style>
