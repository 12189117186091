import * as axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  GET_ROLES_LIST_ERROR,
  SET_CANCEL_TOKEN,
} from '../../actions/roles/rolesActions';
import {
  CHANGE_MANUALLY_ITEMS_ORDERS_ERROR,
  CHANGE_MANUALLY_ITEMS_ORDERS_REQUEST,
  CHANGE_MANUALLY_ITEMS_ORDERS_SUCCESS,
  CREATE_BANNER_ERROR,
  CREATE_BANNER_REQUEST,
  CREATE_BANNER_SUCCESS,
  CREATE_MANUALLY_ITEM_ERROR,
  CREATE_MANUALLY_ITEM_REQUEST,
  CREATE_MANUALLY_ITEM_SUCCESS,
  CREATE_NAVIGATION_ERROR,
  CREATE_NAVIGATION_REQUEST,
  CREATE_NAVIGATION_SUCCESS,
  CREATE_PAGE_ERROR,
  CREATE_PAGE_REQUEST,
  CREATE_PAGE_SUCCESS,
  CREATE_ROW_ERROR,
  CREATE_ROW_REQUEST,
  CREATE_ROW_SUCCESS,
  CREATE_SLIDER_ERROR,
  CREATE_SLIDER_REQUEST,
  CREATE_SLIDER_SUCCESS,
  CREATE_TAB_ERROR,
  CREATE_TAB_REQUEST,
  CREATE_TAB_SUCCESS,
  CREATE_FILTERING_TAB_REQUEST,
  CREATE_FILTERING_TAB_SUCCESS,
  CREATE_FILTERING_TAB_ERROR,
  EDIT_ALL_NAVIGATIONS_ICONS_ERROR,
  EDIT_ALL_NAVIGATIONS_ICONS_REQUEST,
  EDIT_ALL_NAVIGATIONS_ICONS_SUCCESS,
  EDIT_BANNER_ERROR,
  EDIT_BANNER_REQUEST,
  EDIT_BANNER_SUCCESS,
  EDIT_CMS_PAGE_ERROR,
  EDIT_CMS_PAGE_REQUEST,
  EDIT_CMS_PAGE_SUCCESS,
  EDIT_MANUALLY_ITEM_ERROR,
  EDIT_MANUALLY_ITEM_REQUEST,
  EDIT_MANUALLY_ITEM_SUCCESS,
  EDIT_NAVIGATION_ERROR,
  EDIT_NAVIGATION_ORDER_ERROR,
  EDIT_NAVIGATION_ORDER_REQUEST,
  EDIT_NAVIGATION_ORDER_SUCCESS,
  EDIT_NAVIGATION_REQUEST,
  EDIT_NAVIGATION_SUCCESS,
  EDIT_ROW_ERROR,
  EDIT_ROW_REQUEST,
  EDIT_ROW_SUCCESS,
  EDIT_SLIDER_ERROR,
  EDIT_SLIDER_REQUEST,
  EDIT_SLIDER_SUCCESS,
  EDIT_TAB_ERROR,
  EDIT_TAB_REQUEST,
  EDIT_TAB_SUCCESS,
  EDIT_VAE_CONFIG_ERROR,
  EDIT_VAE_CONFIG_REQUEST,
  EDIT_VAE_CONFIG_SUCCESS,
  GET_BANNER_ERROR,
  GET_BANNER_REQUEST,
  GET_BANNER_SUCCESS,
  GET_BANNERS_LIST_ERROR,
  GET_BANNERS_LIST_REQUEST,
  GET_BANNERS_LIST_SLIDER_ERROR,
  GET_BANNERS_LIST_SLIDER_REQUEST,
  GET_BANNERS_LIST_SLIDER_SUCCESS,
  GET_BANNERS_LIST_SUCCESS,
  GET_CMS_PAGES_FOR_NAVIGATION_ERROR,
  GET_CMS_PAGES_FOR_NAVIGATION_REQUEST,
  GET_CMS_PAGES_FOR_NAVIGATION_SUCCESS,
  GET_EV_GROUPS_CMS_ERROR,
  GET_EV_GROUPS_CMS_REQUEST,
  GET_EV_GROUPS_CMS_SUCCESS,
  GET_MANUALLY_ITEM_ERROR,
  GET_MANUALLY_ITEM_REQUEST,
  GET_MANUALLY_ITEM_SUCCESS,
  GET_MANUALLY_LIST_ERROR,
  GET_MANUALLY_LIST_REQUEST,
  GET_MANUALLY_LIST_SUCCESS,
  GET_NAVIGATION_ERROR,
  GET_NAVIGATION_LIST_ERROR,
  GET_NAVIGATION_LIST_REQUEST,
  GET_NAVIGATION_LIST_SUCCESS,
  GET_NAVIGATION_REQUEST,
  GET_NAVIGATION_SUCCESS,
  GET_PAGE_ERROR,
  GET_PAGE_REQUEST,
  GET_PAGE_SUCCESS,
  GET_PAGES_LIST_ERROR,
  GET_PAGES_LIST_REQUEST,
  GET_PAGES_LIST_SUCCESS,
  GET_PERMISSION_ERROR,
  GET_PERMISSION_GROUP,
  GET_PERMISSION_SUCCESS,
  GET_ROW_ERROR,
  GET_ROW_REQUEST,
  GET_ROW_SUCCESS,
  GET_ROWS_LIST_ERROR,
  GET_ROWS_LIST_REQUEST,
  GET_ROWS_LIST_SUCCESS,
  GET_SLIDER_ERROR,
  GET_SLIDER_REQUEST,
  GET_SLIDER_SUCCESS,
  GET_SLIDERS_LIST_CMS_ERROR,
  GET_SLIDERS_LIST_CMS_REQUEST,
  GET_SLIDERS_LIST_CMS_SUCCESS,
  GET_SLIDERS_LIST_ERROR,
  GET_SLIDERS_LIST_REQUEST,
  GET_SLIDERS_LIST_SUCCESS,
  GET_SUBPAGES_ERROR,
  GET_SUBPAGES_REQUEST,
  GET_SUBPAGES_SUCCESS,
  GET_TAB_ERROR,
  GET_TAB_REQUEST,
  GET_TAB_SUCCESS,
  GET_TABS_LIST_ERROR,
  GET_TABS_LIST_REQUEST,
  GET_TABS_LIST_SUCCESS,
  GET_VAE_CONFIG_ERROR,
  GET_VAE_CONFIG_REQUEST,
  GET_VAE_CONFIG_SUCCESS,
  GET_VAE_ICONS_ERROR,
  GET_VAE_ICONS_REQUEST,
  GET_VAE_ICONS_SUCCESS,
  REMOVE_BANNER_ERROR,
  REMOVE_BANNER_REQUEST,
  REMOVE_BANNER_SUCCESS,
  REMOVE_MANUALLY_ITEM_ERROR,
  REMOVE_MANUALLY_ITEM_REQUEST,
  REMOVE_MANUALLY_ITEM_SUCCESS,
  REMOVE_NAVIGATION_ERROR,
  REMOVE_NAVIGATION_REQUEST,
  REMOVE_NAVIGATION_SUCCESS,
  REMOVE_PAGE_ERROR,
  REMOVE_PAGE_REQUEST,
  REMOVE_PAGE_SUCCESS,
  REMOVE_ROW_ERROR,
  REMOVE_ROW_REQUEST,
  REMOVE_ROW_SUCCESS,
  REMOVE_SLIDER_ERROR,
  REMOVE_SLIDER_REQUEST,
  REMOVE_SLIDER_SUCCESS,
  REMOVE_TAB_ERROR,
  REMOVE_TAB_REQUEST,
  REMOVE_TAB_SUCCESS,
  SET_SELECTED_MANUALLY_ITEM_REQUEST,
  SET_SELECTED_MANUALLY_ITEM_SUCCESS,
  SETUP_TV_ENGINE_ERROR,
  SETUP_TV_ENGINE_REQUEST,
  SETUP_TV_ENGINE_SUCCESS,
} from '../../actions/cmsActions/cmsActions';

const state = {
  isLoading: false,
  isLoadingPermissions: false,
  pagesList: [],
  pagination: {},
  source: null,
  cmsPage: {},
  rowsList: [],
  gridTabsList: [],
  subpages: [],
  selectedManuallyItem: null,
  bannersList: [],
  slidersList: [],
  slidersOptions: [],
  navigationList: [],
  navPages: [],
};

const actions = {
  [GET_PAGES_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    const url = `${params.accountId}/cms/page/list?p=${params.page}&l=15&filterField=cp.name&filterValue=${params.filterValue}`;

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_PAGES_LIST_SUCCESS, resp.data);
        localStorage.setItem('pagesPage', resp.data.pagination.page);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_PAGES_LIST_ERROR);
      });
  }),
  [GET_SUBPAGES_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    const url = `${params.accountId}/cms/page/list?excludeNavigations=0&filterField=cp.distribution&filterValue=1&s=cp.name&l=10000`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_SUBPAGES_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_SUBPAGES_ERROR);
      });
  }),
  [SETUP_TV_ENGINE_REQUEST]: ({ commit }, { accountId }) => new Promise((resolve, reject) => {
    commit(SETUP_TV_ENGINE_REQUEST);

    apiCall.post(`${accountId}/cms/page/setup-video-apps-engine`)
      .then(() => {
        commit(SETUP_TV_ENGINE_SUCCESS);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(SETUP_TV_ENGINE_ERROR, error);
        reject(error);
      });
  }),
  [GET_PAGE_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_PAGE_REQUEST);

    apiCall.get(`${params.accountId}/cms/page/${params.cmsPageId}`, { cache: false })
      .then((resp) => {
        commit(GET_PAGE_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_PAGE_ERROR, error);
        reject(error);
      });
  }),
  [CREATE_PAGE_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(CREATE_PAGE_REQUEST);

    apiCall.post(`/${accountId}/cms/page`, data)
      .then((res) => {
        commit(CREATE_PAGE_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_PAGE_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_CMS_PAGE_REQUEST]:
    ({ commit }, { accountId, cmsPageId, data }) => new Promise((resolve, reject) => {
      commit(EDIT_CMS_PAGE_REQUEST);

      apiCall.put(`${accountId}/cms/page/${cmsPageId}`, data)
        .then((resp) => {
          commit(EDIT_CMS_PAGE_SUCCESS, resp);
          resolve(resp);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(EDIT_CMS_PAGE_ERROR, error);
          reject(error);
        });
    }),
  [REMOVE_PAGE_REQUEST]:
    ({ commit }, { accountId, pageId }) => new Promise((resolve, reject) => {
      commit(REMOVE_PAGE_REQUEST);

      const url = `${accountId}/cms/page/${pageId}`;

      apiCall.delete(url)
        .then(() => {
          commit(REMOVE_PAGE_SUCCESS);
          resolve();
        })
        .catch((err) => {
          let error = '';
          if (err.response.data.form_errors) {
            error = err.response.data.form_errors
              && err.response.data.form_errors.children
              && err.response.data.form_errors.children.enabled.errors.length;
          }

          if (err.response.data.error) {
            error = err.response.data;
          }

          commit(REMOVE_PAGE_ERROR, error);
          reject(error);
        });
    }),
  [GET_ROWS_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    const url = `${params.accountId}/cms/row/page/${params.cmsPageId}?page=1&l=10000`;

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_ROWS_LIST_SUCCESS, resp.data);
        localStorage.setItem('rowsPage', resp.data.pagination.page);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_ROWS_LIST_ERROR);
      });
  }),
  [CREATE_ROW_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(CREATE_ROW_REQUEST);

    apiCall.post(`/${accountId}/cms/row`, data)
      .then((res) => {
        commit(CREATE_ROW_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_ROW_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [EDIT_ROW_REQUEST]: ({ commit }, { accountId, cmsRowId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_ROW_REQUEST);

    apiCall.put(`${accountId}/cms/row/${cmsRowId}`, data)
      .then((res) => {
        commit(EDIT_ROW_SUCCESS, res.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_ROW_ERROR, error);
        reject(error);
      });
  }),
  [GET_ROW_REQUEST]: ({ commit }, { accountId, cmsRowId }) => new Promise((resolve, reject) => {
    commit(GET_ROW_REQUEST);

    const url = `${accountId}/cms/row/${cmsRowId}`;

    apiCall.get(url)
      .then((res) => {
        commit(GET_ROW_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_ROW_ERROR, error);
        reject(error);
      });
  }),
  [REMOVE_ROW_REQUEST]:
    ({ commit }, { accountId, cmsRowId }) => new Promise((resolve, reject) => {
      commit(REMOVE_ROW_REQUEST);

      const url = `${accountId}/cms/row/${cmsRowId}`;

      apiCall.delete(url)
        .then(() => {
          commit(REMOVE_ROW_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data.form_errors.children.enabled.errors.length;
          commit(REMOVE_ROW_ERROR, error);
          reject(error);
        });
    }),
  [GET_TABS_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    const url = `${params.accountId}/cms/tab/page/${params.cmsPageId}?page=1&l=10000`;

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_TABS_LIST_SUCCESS, resp.data);
        localStorage.setItem('gridTabsPage', resp.data.pagination.page);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_TABS_LIST_ERROR);
      });
  }),
  [CREATE_TAB_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(CREATE_TAB_REQUEST);

    apiCall.post(`/${accountId}/cms/tab`, data)
      .then((res) => {
        commit(CREATE_TAB_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_TAB_ERROR, error);
        reject(error);
      });
  }),

  // eslint-disable-next-line max-len
  [CREATE_FILTERING_TAB_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(CREATE_FILTERING_TAB_REQUEST);

    apiCall.post(`/${accountId}/cms/tab/list/filter`, data)
      .then((res) => {
        commit(CREATE_FILTERING_TAB_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_FILTERING_TAB_ERROR, error);
        reject(error);
      });
  }),

  // eslint-disable-next-line max-len
  [EDIT_TAB_REQUEST]: ({ commit }, { accountId, cmsTabId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_TAB_REQUEST);

    apiCall.put(`${accountId}/cms/tab/${cmsTabId}`, data)
      .then((res) => {
        commit(EDIT_TAB_SUCCESS, res.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_TAB_ERROR, error);
        reject(error);
      });
  }),
  [GET_TAB_REQUEST]: ({ commit }, { accountId, cmsTabId }) => new Promise((resolve, reject) => {
    commit(GET_TAB_REQUEST);

    const url = `${accountId}/cms/tab/${cmsTabId}`;

    apiCall.get(url)
      .then((res) => {
        commit(GET_TAB_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_TAB_ERROR, error);
        reject(error);
      });
  }),
  [REMOVE_TAB_REQUEST]:
    ({ commit }, { accountId, tabId }) => new Promise((resolve, reject) => {
      commit(REMOVE_TAB_REQUEST);

      const url = `${accountId}/cms/tab/${tabId}`;

      apiCall.delete(url)
        .then(() => {
          commit(REMOVE_TAB_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data.form_errors.children.enabled.errors.length;
          commit(REMOVE_TAB_ERROR, error);
          reject(error);
        });
    }),
  [GET_MANUALLY_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    const url = `${params.accountId}/cms/row-item/row/${params.cmsRowId}?page=1&l=10000`;

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_MANUALLY_LIST_SUCCESS, resp.data);
        localStorage.setItem('manuallyRows', resp.data.pagination.page);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_MANUALLY_LIST_ERROR);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [CREATE_MANUALLY_ITEM_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(CREATE_MANUALLY_ITEM_REQUEST);

    apiCall.post(`/${accountId}/cms/row-item`, data)
      .then((res) => {
        commit(CREATE_MANUALLY_ITEM_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_MANUALLY_ITEM_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [GET_MANUALLY_ITEM_REQUEST]: ({ commit }, { accountId, cmsRowItemsId }) => new Promise((resolve, reject) => {
    commit(GET_MANUALLY_ITEM_REQUEST);

    const url = `${accountId}/cms/row-item/${cmsRowItemsId}`;

    apiCall.get(url)
      .then((res) => {
        commit(GET_MANUALLY_ITEM_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_MANUALLY_ITEM_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [EDIT_MANUALLY_ITEM_REQUEST]: ({ commit }, { accountId, cmsRowItemsId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_MANUALLY_ITEM_REQUEST);

    apiCall.put(`${accountId}/cms/row-item/${cmsRowItemsId}`, data)
      .then((res) => {
        commit(EDIT_MANUALLY_ITEM_SUCCESS, res.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_MANUALLY_ITEM_ERROR, error);
        reject(error);
      });
  }),
  [REMOVE_MANUALLY_ITEM_REQUEST]:
    ({ commit }, { accountId, cmsRowItemsId }) => new Promise((resolve, reject) => {
      commit(REMOVE_MANUALLY_ITEM_REQUEST);

      const url = `${accountId}/cms/row-item/${cmsRowItemsId}`;

      apiCall.delete(url)
        .then(() => {
          commit(REMOVE_MANUALLY_ITEM_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data.form_errors.children.enabled.errors.length;
          commit(REMOVE_MANUALLY_ITEM_ERROR, error);
          reject(error);
        });
    }),
  [SET_SELECTED_MANUALLY_ITEM_REQUEST]:
    ({ commit }, payload) => new Promise(() => {
      commit(SET_SELECTED_MANUALLY_ITEM_SUCCESS, payload);
    }),
  [CHANGE_MANUALLY_ITEMS_ORDERS_REQUEST]:
    ({ commit }, params) => new Promise((resolve, reject) => {
      commit(CHANGE_MANUALLY_ITEMS_ORDERS_REQUEST);

      const url = `${params.accountId}/cms/row-item/row/${params.cmsRowId}/sort-order`;
      const data = {
        order: params.order,
      };

      apiCall.put(url, data)
        .then(() => {
          commit(CHANGE_MANUALLY_ITEMS_ORDERS_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(CHANGE_MANUALLY_ITEMS_ORDERS_ERROR, error);
          reject(error);
        });
    }),
  [GET_BANNERS_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    let url = `${params.accountId}/cms/banner/list?p=${params.page}&l=15&filterField=cb.name&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_BANNERS_LIST_SUCCESS, resp.data);
        localStorage.setItem('banners', resp.data.pagination.page);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_BANNERS_LIST_ERROR);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [CREATE_BANNER_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(CREATE_BANNER_REQUEST);

    apiCall.post(`/${accountId}/cms/banner`, data)
      .then((res) => {
        commit(CREATE_BANNER_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_BANNER_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [EDIT_BANNER_REQUEST]: ({ commit }, { accountId, bannerId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_BANNER_REQUEST);

    apiCall.put(`${accountId}/cms/banner/${bannerId}`, data)
      .then((res) => {
        commit(EDIT_BANNER_SUCCESS, res.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_BANNER_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [GET_BANNER_REQUEST]: ({ commit }, { accountId, bannerId }) => new Promise((resolve, reject) => {
    commit(GET_BANNER_REQUEST);

    const url = `${accountId}/cms/banner/${bannerId}`;

    apiCall.get(url)
      .then((res) => {
        commit(GET_BANNER_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_BANNER_ERROR, error);
        reject(error);
      });
  }),
  [REMOVE_BANNER_REQUEST]:
    ({ commit }, { accountId, bannerId }) => new Promise((resolve, reject) => {
      commit(REMOVE_BANNER_REQUEST);

      const url = `${accountId}/cms/banner/${bannerId}`;

      apiCall.delete(url)
        .then(() => {
          commit(REMOVE_BANNER_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(REMOVE_BANNER_ERROR, error);
          reject(error);
        });
    }),
  [GET_SLIDERS_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    let url = `${params.accountId}/cms/slider/list?p=${params.page}&l=15&filterField=cs.name&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_SLIDERS_LIST_SUCCESS, resp.data);
        localStorage.setItem('sliders', resp.data.pagination.page);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_SLIDERS_LIST_ERROR);
        reject(error);
      });
  }),
  [GET_SLIDERS_LIST_CMS_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    const url = `${params.accountId}/cms/slider/list?p=1&l=10000&s=cs.name&d=ASC`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_SLIDERS_LIST_CMS_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_SLIDERS_LIST_CMS_ERROR);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [CREATE_SLIDER_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(CREATE_SLIDER_REQUEST);

    apiCall.post(`/${accountId}/cms/slider`, data)
      .then((res) => {
        commit(CREATE_SLIDER_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_SLIDER_ERROR, error);
        reject(error);
      });
  }),
  [REMOVE_SLIDER_REQUEST]:
    ({ commit }, { accountId, sliderId }) => new Promise((resolve, reject) => {
      commit(REMOVE_SLIDER_REQUEST);

      const url = `${accountId}/cms/slider/${sliderId}`;

      apiCall.delete(url)
        .then(() => {
          commit(REMOVE_SLIDER_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(REMOVE_SLIDER_ERROR, error);
          reject(error);
        });
    }),
  // eslint-disable-next-line max-len
  [GET_SLIDER_REQUEST]: ({ commit }, { accountId, sliderId }) => new Promise((resolve, reject) => {
    commit(GET_BANNER_REQUEST);

    const url = `${accountId}/cms/slider/${sliderId}`;

    apiCall.get(url)
      .then((res) => {
        commit(GET_SLIDER_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_SLIDER_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [EDIT_SLIDER_REQUEST]: ({ commit }, { accountId, sliderId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_SLIDER_REQUEST);

    apiCall.put(`${accountId}/cms/slider/${sliderId}`, data)
      .then((res) => {
        commit(EDIT_SLIDER_SUCCESS, res.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_SLIDER_ERROR, error);
        reject(error);
      });
  }),
  [GET_BANNERS_LIST_SLIDER_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    const url = `${params.accountId}/cms/banner/list?p=1&l=100000&s=cb.name&d=ASC`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_BANNERS_LIST_SLIDER_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_BANNERS_LIST_SLIDER_ERROR);
        reject(error);
      });
  }),
  [GET_NAVIGATION_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_NAVIGATION_LIST_REQUEST);

    const url = `${params.accountId}/cms/navigation-item/list?p=1&l=10000`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_NAVIGATION_LIST_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_NAVIGATION_LIST_ERROR);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [CREATE_NAVIGATION_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(CREATE_NAVIGATION_REQUEST);

    apiCall.post(`/${accountId}/cms/navigation-item`, data)
      .then((res) => {
        commit(CREATE_NAVIGATION_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_NAVIGATION_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [GET_NAVIGATION_REQUEST]: ({ commit }, { accountId, navigationId }) => new Promise((resolve, reject) => {
    commit(GET_NAVIGATION_REQUEST);

    const url = `${accountId}/cms/navigation-item/${navigationId}`;

    apiCall.get(url)
      .then((res) => {
        commit(GET_NAVIGATION_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_NAVIGATION_ERROR, error);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [EDIT_NAVIGATION_REQUEST]: ({ commit }, { accountId, navigationId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_NAVIGATION_REQUEST);

    apiCall.put(`${accountId}/cms/navigation-item/${navigationId}`, data)
      .then((res) => {
        commit(EDIT_NAVIGATION_SUCCESS, res.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_NAVIGATION_ERROR, error);
        reject(error);
      });
  }),
  [REMOVE_NAVIGATION_REQUEST]:
    ({ commit }, { accountId, navigationId }) => new Promise((resolve, reject) => {
      commit(REMOVE_NAVIGATION_REQUEST);

      const url = `${accountId}/cms/navigation-item/${navigationId}`;

      apiCall.delete(url)
        .then(() => {
          commit(REMOVE_NAVIGATION_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(REMOVE_NAVIGATION_ERROR, error);
          reject(error);
        });
    }),
  [GET_CMS_PAGES_FOR_NAVIGATION_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_CMS_PAGES_FOR_NAVIGATION_REQUEST);

    const url = `${params.accountId}/cms/page/list?excludeNavigations=0&filterField=cp.distribution&filterValue=1&s=cp.name&l=10000`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_CMS_PAGES_FOR_NAVIGATION_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_CMS_PAGES_FOR_NAVIGATION_ERROR);
        reject(error);
      });
  }),
  // eslint-disable-next-line max-len
  [EDIT_NAVIGATION_ORDER_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(EDIT_NAVIGATION_ORDER_REQUEST);

    apiCall.put(`${params.accountId}/cms/navigation-item/sort-order`, params.data)
      .then((res) => {
        commit(EDIT_NAVIGATION_ORDER_SUCCESS, res.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_NAVIGATION_ORDER_ERROR, error);
        reject(error);
      });
  }),
  [GET_PERMISSION_GROUP]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_PERMISSION_GROUP);

    const url = `choice/account/${params.accountId}/cms-permission-group`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_PERMISSION_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_PERMISSION_ERROR);
        reject(error);
      });
  }),
  [GET_EV_GROUPS_CMS_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_EV_GROUPS_CMS_REQUEST);

    const url = `choice/standalone-event-group/${params.accountId}`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_EV_GROUPS_CMS_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_EV_GROUPS_CMS_ERROR);
        reject(error);
      });
  }),
  [GET_VAE_CONFIG_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_VAE_CONFIG_REQUEST);

    const url = `${params.accountId}/vae/configuration/${params.vaeId}`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_VAE_CONFIG_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_VAE_CONFIG_ERROR);
        reject(error);
      });
  }),
  [EDIT_VAE_CONFIG_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(EDIT_VAE_CONFIG_REQUEST);

    apiCall.put(`${params.accountId}/vae/configuration/${params.vaeId}`, params.data)
      .then((res) => {
        commit(EDIT_VAE_CONFIG_SUCCESS, res.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_VAE_CONFIG_ERROR, error);
        reject(error);
      });
  }),
  [GET_VAE_ICONS_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_VAE_ICONS_REQUEST);

    const url = `choice/account/${params.accountId}/vae/icons`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_VAE_ICONS_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_VAE_ICONS_ERROR);
        reject(error);
      });
  }),
  [EDIT_ALL_NAVIGATIONS_ICONS_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(EDIT_ALL_NAVIGATIONS_ICONS_REQUEST);

    apiCall.put(`${params.accountId}/cms/navigation-item/icons`, params.data)
      .then((res) => {
        commit(EDIT_ALL_NAVIGATIONS_ICONS_SUCCESS, res.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_ALL_NAVIGATIONS_ICONS_ERROR, error);
        reject(error);
      });
  }),
};

const mutations = {
  [GET_PAGES_LIST_SUCCESS]: (state, payload) => {
    state.pagesList = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  [GET_SUBPAGES_SUCCESS]: (state, payload) => {
    state.subpages = payload.data;
  },
  [GET_TABS_LIST_SUCCESS]: (state, payload) => {
    state.gridTabsList = payload.data;
    state.source = null;
  },
  [GET_ROWS_LIST_SUCCESS]: (state, payload) => {
    state.rowsList = payload.data;
    state.source = null;
  },
  [CREATE_PAGE_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_PAGE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_PAGE_ERROR]: () => {
    state.isLoading = false;
  },
  [REMOVE_PAGE_REQUEST]: () => {
    state.isLoading = true;
  },
  [REMOVE_PAGE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [REMOVE_PAGE_ERROR]: () => {
    state.isLoading = false;
  },
  [CREATE_ROW_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_ROW_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_ROW_ERROR]: () => {
    state.isLoading = false;
  },
  [CREATE_TAB_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_TAB_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_TAB_ERROR]: () => {
    state.isLoading = false;
  },
  [CREATE_FILTERING_TAB_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_FILTERING_TAB_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_FILTERING_TAB_ERROR]: () => {
    state.isLoading = false;
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
  },
  [EDIT_CMS_PAGE_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_CMS_PAGE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_CMS_PAGE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_PAGE_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_PAGE_SUCCESS]: (state, payload) => {
    state.cmsPage = payload;
    state.isLoading = false;
  },
  [GET_PAGE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_TAB_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_TAB_SUCCESS]: (state) => {
    state.isLoading = false;
  },
  [GET_TAB_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_ROW_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_ROW_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_ROW_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_TAB_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_TAB_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_TAB_ERROR]: () => {
    state.isLoading = false;
  },
  [REMOVE_ROW_REQUEST]: () => {
    state.isLoading = true;
  },
  [REMOVE_ROW_SUCCESS]: () => {
    state.isLoading = false;
  },
  [REMOVE_ROW_ERROR]: () => {
    state.isLoading = false;
  },
  [REMOVE_TAB_REQUEST]: () => {
    state.isLoading = true;
  },
  [REMOVE_TAB_SUCCESS]: () => {
    state.isLoading = false;
  },
  [REMOVE_TAB_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ROLES_LIST_ERROR]: () => {
    state.isLoading = false;
  },
  [CREATE_MANUALLY_ITEM_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_MANUALLY_ITEM_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_MANUALLY_ITEM_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_MANUALLY_ITEM_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_MANUALLY_ITEM_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_MANUALLY_ITEM_ERROR]: () => {
    state.isLoading = false;
  },
  [REMOVE_MANUALLY_ITEM_REQUEST]: () => {
    state.isLoading = true;
  },
  [REMOVE_MANUALLY_ITEM_SUCCESS]: () => {
    state.isLoading = false;
  },
  [REMOVE_MANUALLY_ITEM_ERROR]: () => {
    state.isLoading = false;
  },
  [SET_SELECTED_MANUALLY_ITEM_SUCCESS]: (state, payload) => {
    state.selectedManuallyItem = payload;
  },
  [CHANGE_MANUALLY_ITEMS_ORDERS_REQUEST]: () => {
    state.isLoading = true;
  },
  [CHANGE_MANUALLY_ITEMS_ORDERS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CHANGE_MANUALLY_ITEMS_ORDERS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_MANUALLY_ITEM_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_MANUALLY_ITEM_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_MANUALLY_ITEM_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_BANNERS_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_BANNERS_LIST_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.bannersList = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  [GET_SLIDERS_LIST_CMS_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.slidersOptions = payload.data.map((item) => ({
      code: item.id,
      name: item.name,
    }));
  },
  [GET_BANNERS_LIST_ERROR]: () => {
    state.isLoading = false;
  },
  [CREATE_BANNER_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_BANNER_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_BANNER_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_BANNER_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_BANNER_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_BANNER_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_BANNER_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_BANNER_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_BANNER_ERROR]: () => {
    state.isLoading = false;
  },
  [REMOVE_BANNER_REQUEST]: () => {
    state.isLoading = true;
  },
  [REMOVE_BANNER_SUCCESS]: () => {
    state.isLoading = false;
  },
  [REMOVE_BANNER_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_SLIDERS_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_SLIDERS_LIST_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.slidersList = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  [GET_SLIDERS_LIST_ERROR]: () => {
    state.isLoading = false;
  },
  [CREATE_SLIDER_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_SLIDER_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_SLIDER_ERROR]: () => {
    state.isLoading = false;
  },
  [REMOVE_SLIDER_REQUEST]: () => {
    state.isLoading = true;
  },
  [REMOVE_SLIDER_SUCCESS]: () => {
    state.isLoading = false;
  },
  [REMOVE_SLIDER_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_SLIDER_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_SLIDER_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_SLIDER_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_SLIDER_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_SLIDER_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_SLIDER_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_NAVIGATION_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_NAVIGATION_LIST_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.navigationList = payload.data;
  },
  [GET_NAVIGATION_LIST_ERROR]: () => {
    state.isLoading = false;
  },
  [CREATE_NAVIGATION_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_NAVIGATION_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_NAVIGATION_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_NAVIGATION_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_NAVIGATION_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_NAVIGATION_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_NAVIGATION_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_NAVIGATION_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_NAVIGATION_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_CMS_PAGES_FOR_NAVIGATION_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_CMS_PAGES_FOR_NAVIGATION_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.navPages = payload.data;
  },
  [GET_CMS_PAGES_FOR_NAVIGATION_ERROR]: () => {
    state.isLoading = false;
  },
  [REMOVE_NAVIGATION_REQUEST]: () => {
    state.isLoading = true;
  },
  [REMOVE_NAVIGATION_SUCCESS]: () => {
    state.isLoading = false;
  },
  [REMOVE_NAVIGATION_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_PERMISSION_GROUP]: () => {
    state.isLoadingPermissions = true;
  },
  [GET_PERMISSION_SUCCESS]: () => {
    state.isLoadingPermissions = false;
  },
  [GET_PERMISSION_ERROR]: () => {
    state.isLoadingPermissions = false;
  },
  [GET_VAE_CONFIG_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_VAE_CONFIG_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_VAE_CONFIG_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_VAE_CONFIG_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_VAE_CONFIG_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_VAE_CONFIG_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_VAE_ICONS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_VAE_ICONS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_VAE_ICONS_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_ALL_NAVIGATIONS_ICONS_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_ALL_NAVIGATIONS_ICONS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_ALL_NAVIGATIONS_ICONS_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
