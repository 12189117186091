var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.error ? 'wrapper-error' : ''},[_c('label',{class:[
      'checkbox-container',
      _vm.isShortLabel
        ? 'short-label'
        : '',
      _vm.isDisabled
        ? 'disabled-checkbox'
        : '',
      _vm.error && 'failed',
      _vm.isMedia && 'remove-top-padding'
    ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueModel),expression:"valueModel"}],attrs:{"type":"checkbox","disabled":_vm.isDisabled},domProps:{"checked":Array.isArray(_vm.valueModel)?_vm._i(_vm.valueModel,null)>-1:(_vm.valueModel)},on:{"change":function($event){var $$a=_vm.valueModel,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.valueModel=$$a.concat([$$v]))}else{$$i>-1&&(_vm.valueModel=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.valueModel=$$c}}}}),_c('span',{class:_vm.checkmarkClasses}),_c('div',{class:['checkbox-label', _vm.isDisabled && 'disabled-label'],attrs:{"title":_vm.label}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.caption)?_c('span',{staticClass:"label-caption"},[_vm._v(" "+_vm._s(_vm.caption)+" ")]):_vm._e()])]),_c('transition',{attrs:{"name":"bounce"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"error-label"},[_vm._v(" "+_vm._s(_vm.error)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }