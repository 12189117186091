<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <div
      v-if="Object.keys(formErrors).length"
      class="error-message"
    >
      <div
        v-for="(messages, field) in formErrors"
        :key="field"
      >
        <div
          v-for="(message, index) in messages"
          :key="field + index"
        >
          {{ message }}
        </div>
      </div>
    </div>
    <form
      class="login"
      novalidate="novalidate"
      autocomplete="off"
      @submit.prevent="handleSubmit(submit)"
    >
      <MaterialInput
        v-model="formData.username"
        v-bind="formFields.username"
      />
      <MaterialInput
        v-model="formData.password"
        v-bind="formFields.password"
      />
      <div class="sign-in-wrapper">
        <div class="forgot">
          <router-link
            class="link-forgot"
            to="/forgot"
          >
            Forgot Password?
          </router-link>
        </div>
        <Button
          :classType="'primary'"
          :title="'Sign in'"
          :type="'submit'"
        />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import BaseForm from './BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import CONFIG from '../../constants/config';
import { AUTH_REQUEST } from '../../store/actions/authActions/auth';
import Button from '../common/Button.vue';

export default {
  name: 'LoginForm',
  components: {
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'authorization',
    },
  },
  data() {
    return {
      formData: {
        username: '',
        password: '',
      },
      formFields: {
        username: {
          id: 'login-input-username',
          type: 'text',
          name: 'username',
          autocomplete: 'new-password',
          formPrefix: this.formPrefix,
          rules: {
            email: true,
            required: true,
            max: 255,
          },
        },
        password: {
          id: 'login-input-password',
          type: 'password',
          name: 'password',
          autocomplete: 'new-password',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
      },
      formErrors: {},
      submitEvent: AUTH_REQUEST,
      successMessage: CONFIG.successMessages.editUserPasswordMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  methods: {
    onSubmitSuccess() {
      this.$router.go({ path: '/' });
    },
    onSubmitFail(error) {
      if (error.status === 500 || error.status === 502) {
        this.formErrors = {
          authorization: ['Something went wrong'],
        };
        return;
      }

      this.formErrors = this.getServerFormErrors(error.data);
    },
    getServerFormErrors(response) {
      return this.translateServerErrors(
        this.getErrorsFormResponse(response),
      );
    },
    getRequestData() {
      return {
        username: this.formData.username,
        password: this.formData.password,
      };
    },

    getErrorsFormResponse(response) {
      return {
        [this.formPrefix]: [response.error],
      };
    },
  },
};
</script>

<style scoped>
  .login {
    display: flex;
    flex-direction: column;
    padding: 1.8em 2em 0;
    width: 450px;
  }

  .forgot {
    color: #2591cf;
    padding-top: 30px;
  }

  .link-forgot {
    text-decoration: none;
  }

  .sign-in-wrapper {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
  }

  .error-message {
    color: #fb3951;
    text-align: center;
  }
</style>
