<template>
  <div v-if="isSupport">
    <ValidationObserver
      ref="validationObserver"
      v-slot="{ handleSubmit }"
    >
      <form
        class="support"
        novalidate="novalidate"
        @submit.prevent="handleSubmit(submit)"
        @input="handleFormChange"
      >
        <MaterialInput
          v-model="formData.subject"
          v-bind="formFields.subject"
        />
        <MaterialTextArea
          v-model="formData.description"
          v-bind="formFields.description"
        />
        <div class="buttons-wrapper">
          <router-link
            class="button primary grey"
            :to="enabled
              ? `/media/folder/${mediaFolderId}`
              : myProfile"
          >
            <span class="button-content">
              Cancel
            </span>
          </router-link>
          <Button
            :classType="'primary'"
            :title="'Send'"
            :type="'submit'"
          />
        </div>
      </form>
    </ValidationObserver>
  </div>
  <div v-else>
    <div class="noty-title">
      Your message has been successfully sent to the support team
    </div>
    <div class="buttons-after-send">
      <Button
        :classType="'primary grey'"
        :title="'Back to Support Form'"
        :eventFunction="backToSupportHandler"
      />
      <Button
        :classType="'primary'"
        :title="'Back to Media Page'"
        :eventFunction="toMediaHandler"
      />
    </div>
  </div>
</template>

<script>
import BaseForm from './BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialTextArea from '../inputs/MaterialTextArea.vue';
import CONFIG from '../../constants/config';
import { SEND_SUPPORT_REQUEST } from '../../store/actions/support';
import Button from '../common/Button.vue';

export default {
  name: 'SupportForm',
  components: {
    MaterialInput,
    MaterialTextArea,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'support',
    },
    mediaFolderId: {
      type: Number,
      default: null,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isSupport: true,
      myProfile: CONFIG.routing.myProfile,
      formData: {
        subject: '',
        description: '',
      },
      formFields: {
        subject: {
          type: 'text',
          name: 'subject',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        description: {
          name: 'description',
          formPrefix: this.formPrefix,
          cols: 30,
          rows: 10,
          rules: {
            required: true,
            max: 500,
          },
        },
      },
      errorMapping: {
        subject: [
          `${this.formPrefix}.subject`,
        ],
        description: [
          `${this.formPrefix}.description`,
        ],
      },
      submitEvent: SEND_SUPPORT_REQUEST,
      successMessage: CONFIG.support.successMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  methods: {
    getRequestData() {
      return {
        subject: this.formData.subject,
        description: this.formData.description,
      };
    },
    onSubmitSuccess() {
      this.isSupport = false;
    },
    backToSupport() {
      this.isSupport = true;
      this.formData.subject = '';
      this.formData.description = '';
    },
    toMedia() {
      if (this.enabled) {
        this.$router.push(`/media/folder/${this.mediaFolderId}`);
      } else {
        this.$router.push(this.myProfile);
      }
    },
    backToSupportHandler() {
      this.backToSupport();
    },
    toMediaHandler() {
      this.toMedia();
    },
  },
};
</script>

<style scoped>
  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .support {
    display: flex;
    flex-direction: column;
    padding: 2em 2em 0;
    width: 450px;
  }

  .noty-title {
    padding: 50px 13px 50px 0;
    text-align: center;
    width: 100%;
  }

  .back:not(:last-child) {
    margin-right: 20px;
  }

  .buttons-after-send {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
</style>
