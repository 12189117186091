<template>
  <div
    id="auto-dropdown-containter"
    class="picker-date"
    :class="blockClassName"
  >
    <ValidationProvider
      ref="validator"
      v-slot="{ errors, required }"
      :name="fieldName"
      :vid="name"
      :rules="rules"
      mode="passive"
    >
      <label class="label">
        {{ fieldLabel }}
        <span
          v-if="required"
          class="asterisk"
        >
          *
        </span>
      </label>
      <div
        class="input-wrapper"
        :class="[isClosed ? 'closed-picker' : '']"
      >
        <span ref="pickerTimeRef">
          <vue-timepicker
            v-model="valueModel"
            :disabled="disabled"
            :format="format"
            :hideClearButton="rules.required"
            :autoScroll="autoScroll"
            :manualInput="isManualInput"
            :class="[errors.length && 'failed']"
            :closeOnComplete="closeOnComplete"
            @change="onChangeTime"
            @blur="onLoseFocus"
            @close="onCloseTimepicker"
          >
          </vue-timepicker>
        </span>
        <transition name="bounce">
          <span
            v-if="errors.length"
            class="error-label"
          >
            {{ errors[0] }}
          </span>
        </transition>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';
import { ValidationProvider } from 'vee-validate';
import BaseMaterialInput from './BaseMaterialInput.vue';

import 'vue2-timepicker/dist/VueTimepicker.css';

export default {
  name: 'MaterialTime',
  components: {
    ValidationProvider,
    VueTimepicker,
  },
  extends: BaseMaterialInput,
  props: {
    label: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'HH:mm',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    closeOnComplete: {
      type: Boolean,
      default: false,
    },
    blockClassName: {
      type: String,
      default: '',
    },
    onChangeTime: {
      type: Function,
      default: () => {},
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    isManualInput: {
      type: Boolean,
      default: false,
    },
    onLoseFocus: {
      type: Function,
      default: () => {},
    },
    onCloseTimepicker: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isClosed: false,
    };
  },
  created() {
    document.addEventListener('mousedown', this.clickOutside);
  },
  destroyed() {
    document.removeEventListener('mousedown', this.clickOutside);
  },
  methods: {
    clickOutside(e) {
      if (this.$refs.pickerTimeRef && !this.$refs.pickerTimeRef.contains(e.target)) {
        this.isClosed = true;
      } else {
        this.isClosed = false;
      }
    },
  },
};
</script>

<style scoped>
  .label {
    margin-bottom: 11px;
  }

  .picker-date {
    min-width: 150px;
    /*margin-right: 30px;*/
  }

  .error-label {
    display: block;
  }

  .input-wrapper {
    font-size: 16px;
    margin-top: 10px;
  }

  .asterisk {
    color: #fb3951;
  }

  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .failed {
    border-bottom: 1px solid #fb3951 !important;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  .inspector-datetime {
    margin-right: 0;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
</style>

<style>
  .closed-picker .vue__time-picker .dropdown {
    display: none !important;
  }
</style>
