<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="edit-button-wrapper">
        <Button
          v-if="isEdit"
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="onOpenModal"
          :disabled="formData.status === 'active'"
        />
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backTo"
        />
        <Button
          :title="isEdit ? 'Apply' : 'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="breadcrumbs">
        <span class="system">
          System Management / API Keys
        </span>
        <span v-if="!isEdit">
          / Add API Key
        </span>
        <span
          v-else
        >
          / Edit {{ apiKey.name }}
        </span>
      </div>
      <div class="title">
        {{ isEdit ? 'Edit API Key' : 'Add API Key' }}
      </div>
      <MaterialInput
        v-model="formData.name"
        v-bind="formFields.name"
      />
      <MaterialSelect
        v-model="formData.account"
        v-bind="formFields.account"
        :label="'Select Account'"
        :withEmptyField="false"
        :options="accountsOptions"
        :disabled="isEdit"
      />
      <MaterialInput
        v-if="isEdit"
        :id="formData.id"
        v-model="formData.apiKey"
        v-bind="formFields.apiKey"
        :label="'API Key'"
        :readonly="true"
        :allowCopy="true"
        :blur="true"
      />
      <DeleteAccountModal
        ref="modal"
        v-model="isModalOpen"
        :modal="isModalOpen"
        :bodyMessage="
          'This action cannot be undone.' +
            ' To confirm, please enter the Name of the API Key you wish to delete'
        "
        :title="'Delete API Key'"
        :isDeleteAcc="true"
        :isEditDelete="true"
        :name="formData.name"
        :accId="formData.id"
        :onClose="onClose"
        :isAPI="true"
      />
      <CreatedAPIKeysModal
        v-if="!isEdit"
        ref="modal"
        v-model="isModalOpenCreated"
        :modal="isModalOpenCreated"
        :bodyMessage="'API Key Details'"
        :onAccept="onCloseCreated"
        :confirmButtonTitle="'Done'"
        :keyName="formData.name"
        :isNeedCancel="false"
      />
      <NotificationModal
        ref="modal"
        v-model="isNoDelete"
        :modal="isNoDelete"
        :title="'Delete API Key'"
        :isInUseAsset="true"
        :warningMess="warningMessage"
        :bodyMessage="bodyMessage"
        :onAccept="closeDeleteAfter"
        :confirmButtonTitle="'OK'"
        :isNeedCancel="false"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'moment-timezone';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import Button from '../../common/Button.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../../store/actions/common';
import MaterialInput from '../../inputs/MaterialInput.vue';
import {
  ADD_API_KEY_REQUEST,
  EDIT_API_KEY_REQUEST,
  GET_API_KEY_REQUEST,
  GET_ENABLED_ACCS_REQUEST,
} from '../../../store/actions/api/api_keys';
import CreatedAPIKeysModal from '../../api-keys/CreatedAPIKeysModal.vue';
import DeleteAccountModal from '../../DeleteAccountModal.vue';
import NotificationModal from '../../NotificationModal.vue';

export default {
  name: 'APIKeyForm',
  components: {
    CreatedAPIKeysModal,
    MaterialInput,
    MaterialSelect,
    Button,
    DeleteAccountModal,
    NotificationModal,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'apiKeyForm',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accountsOptions: [],
      isHasId: true,
      isModalOpen: false,
      isModalOpenCreated: false,
      isNoDelete: false,
      bodyMessage: CONFIG.modals.deleteLanguageMessage,
      warningMessage: '',
      enabledError: '',
      apiKey: null,
      formData: {
        id: '',
        name: '',
        account: '',
        apiKey: '',
        status: '',
        deleteAfter: '',
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 60,
          },
        },
        account: {
          type: 'text',
          name: 'account',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_API_KEY_REQUEST : ADD_API_KEY_REQUEST,
      successMessage: this.isEdit
        ? CONFIG.successMessages.editLocalizationMessage
        : CONFIG.successMessages.addLocalizationMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
      'getMainProfile',
    ]),
    ...mapState({
      // accountsOptions: (state) => state.common.accounts,
    }),
  },
  mounted() {
    this.$store.dispatch(GET_ENABLED_ACCS_REQUEST).then((res) => {
      this.accountsOptions = res.data.map((item) => ({
        code: item.id,
        name: item.name,
      }));
    });
    if (this.isEdit && this.$route.params.id) {
      const {
        errorMessages,
        statuses,
      } = CONFIG;

      const params = {
        id: this.$route.params.id,
      };

      this.$store.dispatch(GET_API_KEY_REQUEST, params)
        .then((res) => this.initFormData(res))
        .catch((err) => {
          if (err.error === errorMessages.unknown) {
            this.isHasId = false;
            this.$router.push(`/${statuses.notFound}`);
          }
        });
    }
  },
  methods: {
    initFormData(item) {
      this.apiKey = item;
      this.formData.name = item.name;
      this.formData.id = item.id;
      this.formData.account = item.account.id;
      this.formData.apiKey = item.value;
      this.formData.status = item.status;
      this.formData.deleteAfter = item.deleteAfter;
    },
    backTo() {
      this.$router.push('/settings/api-keys');
    },
    onOpenModal() {
      if (this.formData.status === 'inactive' && !this.formData.deleteAfter) {
        this.isModalOpen = true;
        this.$store.dispatch(SET_OVERFLOW, true);
      }

      if (this.formData.status === 'inactive' && this.formData.deleteAfter) {
        this.isNoDelete = true;
        this.bodyMessage = '';
        this.warningMessage = `You can only remove a key if it has been inactive for more than one week. API key may be deleted after ${this.getDeleteAfter(this.formData.deleteAfter)}`;
      }
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onCloseCreated() {
      this.isModalOpenCreated = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
      this.$router.push('/settings/api-keys');
    },
    closeDeleteAfter() {
      this.isNoDelete = false;
    },
    getDeleteAfter(value) {
      const moment = extendMoment(Moment);
      const format = 'MMMM DD, hh:mm A';
      if (this.getMainProfile.timezone) {
        return moment(value).tz(this.getMainProfile.timezone).format(format);
      }
      return moment(value).format(format);
    },
    getRequestData() {
      const data = {
        name: this.formData.name,
        account: this.formData.account,
      };

      if (this.isEdit) {
        delete data.account;
        data.status = this.formData.status;
      }

      return {
        data,
        id: this.$route.params.id,
      };
    },
    onSubmitSuccess() {
      if (!this.isEdit) {
        this.isModalOpenCreated = true;
        this.$toasted.global.success({
          message: 'The API Key has been successfully added',
        });
      } else {
        this.$toasted.global.success({
          message: 'The API Key has been successfully updated',
        });
        setTimeout(() => {
          this.$router.push('/settings/api-keys');
        }, CONFIG.routing.redirectFromAddAcc);
      }
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>
.status-checkbox {
  margin-bottom: 30px;
}

.checkbox {
  margin-top: 3px;
}

.button-wrapper {
  display: flex;
  align-items: center;
}

.edit-button-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 26px;
}

.breadcrumbs {
  margin-bottom: 25px;
}

.system {
  color: #4c5057;
}
</style>
