var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"fileform",staticClass:"file-drag-drop",class:[
      'file-drag-drop',
      _vm.isError || _vm.externalErrorMessage ? 'error-form' : '',
      _vm.isPosterInspector && 'file-drag-drop-inspector',
      _vm.is4kPoster && 'four-k-poster',
      _vm.is4kMobileBannerImage && 'four-k-mobile-poster',
      _vm.isImagesOnly && 'file-drag-drop-itunes',
      _vm.disabledText && 'disabled',
      _vm.isPoster2x3 && 'file-drag-drop-2x3' ],attrs:{"title":_vm.computedTitle},on:{"click":_vm.onFileInput}},[(!_vm.file)?_c('form',{class:['file-form']},[_c('div',{staticClass:"drag-drop-zone"},[(_vm.disabledText)?_c('span',{staticClass:"drop-files"},[_vm._v(" "+_vm._s(_vm.disabledText)+" ")]):_c('span',{staticClass:"drop-files itunes-img-text"},[_c('div',{staticClass:"file-form"},[_c('div',{staticClass:"itunes-drag-zone"},[_c('inline-svg',{staticClass:"svg",attrs:{"src":_vm.iconUpload}}),_c('span',{staticClass:"drop-files"},[_vm._v(" Drag & Drop ")]),_vm._m(0)],1)])])])]):_c('div',{class:['file-preview',
               _vm.isPosterInspector && _vm.isError && 'file-preview-inspector',
               _vm.isImagesOnly && 'file-preview-itunes',
               _vm.disabledText && 'disabled']},[_c('div',{staticClass:"preview"},[(_vm.preview)?_c('img',{ref:'preview',class:[_vm.isPosterInspector ? 'image-preview-inspector' : 'image-preview',
                   _vm.isImagesOnly && 'file-preview-itunes'],attrs:{"src":_vm.preview,"alt":_vm.file.name}}):_vm._e()]),(_vm.isNeedRemove)?_c('div',{staticClass:"remove-button",on:{"click":_vm.onRemove}},[_c('div',{staticClass:"btn",attrs:{"title":("Remove " + (_vm.is4kPoster
            ? 'Banner Image'
            : _vm.imageType === 'background image'
              ? 'Background Image'
              : _vm.isImagesOnly
                ? 'iTunes Cover Art'
                : 'Poster Image'))}},[_c('inline-svg',{attrs:{"src":_vm.iconRemove}})],1)]):_vm._e()]),_c('input',{ref:"fileInput",staticClass:"input-hidden",attrs:{"type":"file","accept":!_vm.isImagesOnly ? 'image/x-png,image/webp,image/jpeg' : 'image/x-png,image/jpeg',"disabled":_vm.disabled},on:{"change":_vm.fileInputChange}})]),(_vm.isError)?_c('span',{staticClass:"error-label"},[_vm._v(" "+_vm._s(_vm.validationMessage)+" ")]):(_vm.externalErrorMessage)?_c('span',{staticClass:"error-label"},[_vm._v(" "+_vm._s(_vm.externalErrorMessage)+" ")]):_vm._e(),(_vm.isModalOpen)?_c('NotificationModal',{attrs:{"modal":true,"title":'Replace Image',"bodyMessage":_vm.replaceMessage,"onAccept":_vm.handleAccept,"onClose":_vm.handleClose,"isOrangeCancel":true}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"drop-files"},[_vm._v(" or click to "),_c('span',{staticClass:"browser-link"},[_vm._v("browse")])])}]

export { render, staticRenderFns }