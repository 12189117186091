import apiCall from '../../../utils/api/api';
import {
  GET_MEDIA_TREE,
  GET_MEDIA_TREE_REQUEST,
  GET_MEDIA_TREE_SUCCESS,
  GET_MEDIA_TREE_ERROR,
  POST_MEDIA_FOLDER,
  POST_MEDIA_FOLDER_REQUEST,
  POST_MEDIA_FOLDER_SUCCESS,
  POST_MEDIA_FOLDER_ERROR,
  DELETE_MEDIA_FOLDER,
  DELETE_MEDIA_FOLDER_SUCCESS,
  DELETE_MEDIA_FOLDER_REQUEST,
  DELETE_MEDIA_FOLDER_ERROR,
  EDIT_MEDIA_FOLDER,
  EDIT_MEDIA_FOLDER_SUCCESS,
  EDIT_MEDIA_FOLDER_REQUEST,
  EDIT_MEDIA_FOLDER_ERROR,
  GET_ALLOWED_MEDIA_FOLDERS,
  GET_ALLOWED_MEDIA_FOLDERS_SUCCESS,
  GET_ALLOWED_MEDIA_FOLDERS_REQUEST,
  GET_ALLOWED_MEDIA_FOLDERS_ERROR,
  GET_METADATA_REQUEST,
  GET_METADATA_SUCCESS,
  GET_METADATA_ERROR,
  EDIT_METADATA_REQUEST,
  EDIT_METADATA_SUCCESS,
  EDIT_METADATA_ERROR,
  DESTROY_META_REQUEST,
  DESTROY_META_SUCCESS,
  DELETE_METADATA_REQUEST,
  DELETE_METADATA_SUCCESS,
  DELETE_METADATA_ERROR,
  EDIT_TV_APPS_LIST,
  EDIT_TV_APPS_LIST_SUCCESS,
  EDIT_TV_APPS_LIST_REQUEST,
  EDIT_TV_APPS_LIST_ERROR,
} from '../../actions/media/mediaTree';
import CONFIG from '../../../constants/config';

const state = {
  mediaTree: {},
  loaded: false,
  allowedMediaFolders: [],
  metadata: {},
  isLoadingMeta: false,
};

const actions = {
  [GET_MEDIA_TREE]: ({ commit }, accountId) => new Promise((resolve) => {
    commit(GET_MEDIA_TREE_REQUEST);
    apiCall.get(`${accountId}/media-collection/folder/tree`)
      .then((resp) => {
        commit(GET_MEDIA_TREE_SUCCESS, resp.data);
        resolve(resp.data);
      }).catch(() => {
        commit(GET_MEDIA_TREE_ERROR);
      });
  }),
  [POST_MEDIA_FOLDER]:
  ({ commit, dispatch }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(POST_MEDIA_FOLDER_REQUEST);

    apiCall.post(`${accountId}/media-collection/folder`, data)
      .then((resp) => {
        commit(POST_MEDIA_FOLDER_SUCCESS, resp.data);
        dispatch(GET_MEDIA_TREE, accountId);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(POST_MEDIA_FOLDER_ERROR);
        reject(error);
      });
  }),
  [GET_ALLOWED_MEDIA_FOLDERS]:
  ({ commit }, { accountId, folderId }) => new Promise((resolve, reject) => {
    commit(GET_ALLOWED_MEDIA_FOLDERS_REQUEST);

    apiCall.get(`/choice/accounts/${accountId}/media-folders/${folderId}/parents`)
      .then((resp) => {
        commit(GET_ALLOWED_MEDIA_FOLDERS_SUCCESS, resp.data);
        resolve(resp.data);
      }).catch((err) => {
        const error = err.response.data;
        commit(GET_ALLOWED_MEDIA_FOLDERS_ERROR);
        reject(error);
      });
  }),
  [DELETE_MEDIA_FOLDER]:
  ({ commit, dispatch }, { accountId, folderId }) => new Promise((resolve, reject) => {
    commit(DELETE_MEDIA_FOLDER_REQUEST);

    apiCall.delete(`${accountId}/media-collection/folder/${folderId}`)
      .then(() => {
        commit(DELETE_MEDIA_FOLDER_SUCCESS);
        dispatch(GET_MEDIA_TREE, accountId);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(DELETE_MEDIA_FOLDER_ERROR);
        reject(error);
      });
  }),
  [EDIT_MEDIA_FOLDER]:
  ({ commit, dispatch }, { accountId, folderId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_MEDIA_FOLDER_REQUEST);

    apiCall.put(`${accountId}/media-collection/folder/${folderId}`, data)
      .then(() => {
        commit(EDIT_MEDIA_FOLDER_SUCCESS);
        dispatch(GET_MEDIA_TREE, accountId);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(EDIT_MEDIA_FOLDER_ERROR);
        reject(error);
      });
  }),
  [EDIT_TV_APPS_LIST]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_TV_APPS_LIST_REQUEST);

    apiCall.put(`${accountId}/media-collection/folder/tv-apps-list`, data)
      .then(() => {
        commit(EDIT_TV_APPS_LIST_SUCCESS);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(EDIT_TV_APPS_LIST_ERROR);
        reject(error);
      });
  }),
  [GET_METADATA_REQUEST]:
  ({ commit }, { accountId, folderId }) => new Promise((resolve, reject) => {
    commit(GET_METADATA_REQUEST);

    const url = `${accountId}/media-collection/folder/${folderId}/metadata`;
    apiCall.get(url, {
      cache: false,
    })
      .then((resp) => {
        if (resp.status === CONFIG.statuses.noContent) {
          commit(GET_METADATA_SUCCESS, {});
        } else {
          commit(GET_METADATA_SUCCESS, resp.data);
        }
        resolve(resp);
      }).catch((err) => {
        commit(GET_METADATA_ERROR);
        const error = err.response.data;
        reject(error);
      });
  }),
  [EDIT_METADATA_REQUEST]:
  ({ commit }, { accountId, folderId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_METADATA_REQUEST);

    const url = `${accountId}/media-collection/folder/${folderId}/metadata`;
    apiCall.put(url, data)
      .then((resp) => {
        commit(EDIT_METADATA_SUCCESS, resp.data);
        resolve(resp.data);
      }).catch((err) => {
        const error = err.response.data;
        commit(EDIT_METADATA_ERROR);
        reject(error);
      });
  }),
  [DESTROY_META_REQUEST]: ({ commit }) => new Promise(() => {
    commit(DESTROY_META_SUCCESS);
  }),
  [DELETE_METADATA_REQUEST]:
  ({ commit }, { accountId, folderId }) => new Promise((resolve, reject) => {
    commit(DELETE_METADATA_REQUEST);

    apiCall.delete(`${accountId}/media-collection/folder/${folderId}/metadata`)
      .then(() => {
        commit(DELETE_METADATA_SUCCESS);
        resolve();
      }).catch((err) => {
        commit(DELETE_METADATA_ERROR);
        const error = err.response.data;
        reject(error);
      });
  }),
};

const mutations = {
  [GET_MEDIA_TREE_REQUEST]: () => {
    state.loaded = false;
  },
  [GET_MEDIA_TREE_SUCCESS]: (stateLocal, payload) => {
    state.loaded = true;
    state.mediaTree = payload;
  },
  [GET_MEDIA_TREE_ERROR]: () => {
    state.loaded = false;
  },
  [POST_MEDIA_FOLDER_REQUEST]: () => {
    state.loaded = false;
  },
  [POST_MEDIA_FOLDER_SUCCESS]: () => {
    state.loaded = true;
  },
  [POST_MEDIA_FOLDER_ERROR]: () => {
    state.loaded = false;
  },
  [DELETE_MEDIA_FOLDER_REQUEST]: () => {
    state.loaded = true;
  },
  [DELETE_MEDIA_FOLDER_SUCCESS]: () => {
    state.loaded = false;
  },
  [DELETE_MEDIA_FOLDER_ERROR]: () => {
    state.loaded = true;
  },
  [EDIT_MEDIA_FOLDER_REQUEST]: () => {
    state.loaded = true;
  },
  [EDIT_MEDIA_FOLDER_SUCCESS]: () => {
    state.loaded = false;
  },
  [EDIT_MEDIA_FOLDER_ERROR]: () => {
    state.loaded = true;
  },
  [GET_ALLOWED_MEDIA_FOLDERS_REQUEST]: () => {
    state.loaded = true;
  },
  [GET_ALLOWED_MEDIA_FOLDERS_SUCCESS]: (stateLocal, result) => {
    state.allowedMediaFolders = result;
    state.loaded = false;
  },
  [GET_ALLOWED_MEDIA_FOLDERS_ERROR]: () => {
    state.loaded = true;
  },
  [GET_METADATA_REQUEST]: () => {
    state.isLoadingMeta = true;
  },
  [GET_METADATA_ERROR]: () => {
    state.isLoadingMeta = false;
  },
  [GET_METADATA_SUCCESS]: (state, payload) => {
    state.metadata = payload;
    state.isLoadingMeta = false;
  },
  [EDIT_METADATA_REQUEST]: () => {
    state.isLoadingMeta = true;
  },
  [EDIT_METADATA_SUCCESS]: (state, payload) => {
    state.metadata = payload;
    state.isLoadingMeta = false;
  },
  [EDIT_METADATA_ERROR]: () => {
    state.isLoadingMeta = false;
  },
  [DESTROY_META_SUCCESS]: () => {
    state.metadata = {};
  },
  [DELETE_METADATA_REQUEST]: () => {
    state.isLoadingMeta = true;
  },
  [DELETE_METADATA_SUCCESS]: () => {
    state.isLoadingMeta = false;
    state.metadata = {};
  },
  [DELETE_METADATA_ERROR]: () => {
    state.isLoadingMeta = false;
  },
  [EDIT_TV_APPS_LIST_REQUEST]: () => {
    state.loaded = true;
  },
  [EDIT_TV_APPS_LIST_ERROR]: () => {
    state.loaded = true;
  },
  [EDIT_TV_APPS_LIST_SUCCESS]: (state) => {
    state.loaded = true;
  },
};

export default {
  state,
  actions,
  mutations,
};
