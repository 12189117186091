<template>
  <div class="checks-wrapper">
    <div
      v-for="option in valueModel"
      :key="option[tagTrackBy]"
      class="option"
      :class="className"
    >
      <input
        :id="`${name}-${option[tagTrackBy]}`"
        v-model="option.selected"
        type="checkbox"
        class="check"
        :disabled="disabled"
        @change="onChange"
      />
      <label
        :for="`${name}-${option[tagTrackBy]}`"
        class="label"
      >
        <span class="empty"></span>
        <span>
          {{ option[tagLabel] }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CheckboxList',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: null,
    },
    name: {
      type: String,
      default: 'checkbox-group',
    },
    tagLabel: {
      type: String,
      default: 'name',
    },
    tagTrackBy: {
      type: String,
      default: 'code',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
  },
  computed: {
    valueModel: {
      get() {
        return this.options.map((option) => ({
          [this.tagTrackBy]: option[this.tagTrackBy],
          [this.tagLabel]: option[this.tagLabel],
          selected: !!this.value
            .filter((item) => item[this.tagTrackBy] === option[this.tagTrackBy])
            .length,
        }));
      },
      set(values) {
        // this setter is not used due to everything is set via onChange
        this.$emit('input', values.filter((item) => item.selected));
      },
    },
  },
  methods: {
    onChange() {
      this.$emit('input', this.valueModel.filter((item) => item.selected));
    },
  },
};
</script>

<style scoped>
  .checks-wrapper {
    display: flex;
    flex: 86%;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 10px;
    width: 100%;
  }

  .option {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 117px;
    position: relative;
    user-select: none;
  }

  .option input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  .label {
    border: 1px solid #383b40;
    border-radius: 3px;
    box-sizing: border-box;
    height: 21px;
    width: 21px;
  }

  .option:hover input ~ .label {
    border: 1px solid #2591cf;
    cursor: pointer;
  }

  .option input:checked ~ .label {
    border: 1px solid #2591cf;
  }

  .label::after {
    content: '';
    display: none;
    position: absolute;
  }

  .option input:checked ~ .label::after {
    display: block;
  }

  .option .label::after {
    border: solid #2591cf;
    border-width: 0 2px 2px 0;
    height: 10px;
    left: 7px;
    top: 3px;
    transform: rotate(45deg);
    width: 6px;
  }

  .label {
    font-size: 14px;
    padding-left: 20px;
  }

  .empty {
    padding-left: 8px;
  }

  .inspector-checkbox label {
    background: #222;
  }
</style>
