import apiCall from '../../../utils/api/api';
import {
  GET_ALL_EVENTS,
  GET_ALL_EVENTS_REQUEST,
  GET_ALL_EVENTS_SUCCESS,
  GET_ALL_EVENTS_ERROR,
  GET_EVENT,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR,
  ADD_EVENT,
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_ERROR,
  EDIT_EVENT,
  EDIT_EVENT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_ERROR,
  DELETE_EVENT,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  GET_COUNT_EVENTS_IN_GROUP,
  GET_COUNT_EVENTS_IN_GROUP_REQUEST,
  GET_COUNT_EVENTS_IN_GROUP_SUCCESS,
  GET_COUNT_EVENTS_IN_GROUP_ERROR,
  GET_CUSTOM_FIELDS_EVENT_REQUEST,
  GET_CUSTOM_FIELDS_EVENT_SUCCESS,
  GET_CUSTOM_FIELDS_EVENT_ERROR,
} from '../../actions/events/events';

const state = {
  isLoading: false,
  events: [],
  event: {},
  pagination: {},
  error: null,
};

const actions = {
  [GET_ALL_EVENTS]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_ALL_EVENTS_REQUEST);

    let url = `${params.accountId}/standalone-event/list/`;
    url += (params.standaloneEventGroup) ? `${params.standaloneEventGroup}` : 'individual';
    url += `?p=${params.page}&l=15`;
    url += params.filterValue ? `&filterField=se.name&filterValue=${params.filterValue}` : '';
    url += params.sortFiled ? `&s=${params.sortFiled}&d=${params.sortBy}` : '';

    apiCall.get(url)
      .then((resp) => {
        commit(GET_ALL_EVENTS_SUCCESS, resp.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_ALL_EVENTS_ERROR, error);
        reject(error);
      });
  }),
  [GET_COUNT_EVENTS_IN_GROUP]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_COUNT_EVENTS_IN_GROUP_REQUEST);

    const url = `${params.accountId}/standalone-event/list/${params.id}?p=1&l=15&s=se.startDate&d=ASC`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_COUNT_EVENTS_IN_GROUP_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_COUNT_EVENTS_IN_GROUP_ERROR, error);
        reject(error);
      });
  }),
  [GET_EVENT]: ({ commit }, { accountId, eventId }) => new Promise((resolve, reject) => {
    commit(GET_EVENT_REQUEST);

    apiCall.get(`${accountId}/standalone-event/${eventId}`)
      .then((resp) => {
        commit(GET_EVENT_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_EVENT_ERROR, error);
        reject(error);
      });
  }),
  [ADD_EVENT]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(ADD_EVENT_REQUEST);

    apiCall.post(`${accountId}/standalone-event`, data)
      .then(() => {
        commit(ADD_EVENT_SUCCESS);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_EVENT_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_EVENT]: ({ commit }, { accountId, eventId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_EVENT_REQUEST);

    apiCall.put(`${accountId}/standalone-event/${eventId}`, data)
      .then((resp) => {
        commit(EDIT_EVENT_SUCCESS, resp.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_EVENT_ERROR, error);
        reject(error);
      });
  }),
  [DELETE_EVENT]:
  ({ commit }, { accountId, eventId }) => new Promise((resolve, reject) => {
    commit(DELETE_EVENT_REQUEST);

    apiCall.delete(`${accountId}/standalone-event/${eventId}`)
      .then((resp) => {
        commit(DELETE_EVENT_SUCCESS, resp.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(DELETE_EVENT_ERROR, error);
        reject(error);
      });
  }),
  [GET_CUSTOM_FIELDS_EVENT_REQUEST]:
    ({ commit }, { accountId }) => new Promise((resolve, reject) => {
      commit(GET_CUSTOM_FIELDS_EVENT_REQUEST);

      apiCall.get(`${accountId}/standalone-event/custom-field`)
        .then((resp) => {
          commit(GET_CUSTOM_FIELDS_EVENT_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_CUSTOM_FIELDS_EVENT_ERROR, error);
          reject(error);
        });
    }),
};

const mutations = {
  [GET_ALL_EVENTS_REQUEST]: () => {
    state.error = null;
    state.isLoading = true;
  },
  [GET_ALL_EVENTS_SUCCESS]: (state, payload) => {
    state.events = payload.data;
    state.pagination = payload.pagination;
    state.isLoading = false;
  },
  [GET_ALL_EVENTS_ERROR]: (state, payload) => {
    state.error = payload;
    state.isLoading = false;
  },
  [GET_EVENT_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_EVENT_SUCCESS]: (state, payload) => {
    state.event = payload;
    state.isLoading = false;
  },
  [GET_EVENT_ERROR]: (state, payload) => {
    state.error = payload;
    state.isLoading = false;
  },
  [ADD_EVENT_REQUEST]: () => {
    state.error = null;
    state.isLoading = true;
  },
  [ADD_EVENT_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_EVENT_ERROR]: (state, payload) => {
    state.error = payload;
    state.isLoading = false;
  },
  [EDIT_EVENT_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_EVENT_SUCCESS]: (state, payload) => {
    state.event = payload;
    state.isLoading = false;
  },
  [EDIT_EVENT_ERROR]: (state, payload) => {
    state.error = payload;
    state.isLoading = false;
  },
  [DELETE_EVENT_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_EVENT_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_EVENT_ERROR]: (state, payload) => {
    state.error = payload;
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
