<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="buttons-wrapper">
      <Button
        :title="'Add Sorting Tab'"
        :classType="'primary'"
        :eventFunction="createSortingTab"
      />
      <Button
        :title="'Add Filtering Tab'"
        :classType="'primary'"
        :eventFunction="createFilteringTab"
      />
    </div>
    <div class="accounts-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          tab label
        </div>
        <div class="th-name date">
          tab function
        </div>
      </div>
      <div
        v-if="!tabs.length"
        class="empty-search"
      >
        No Tabs to display
      </div>
      <draggable
        v-model="tabs"
        :move="checkMove"
        @start="dragging = true"
        @end="endOfDrag"
      >
        <transition-group>
          <div
            v-for="(item) in tabs"
            :key="item.id"
            class="account"
            @mouseover="onHoverElem(item)"
            @mouseleave="isHover = false"
          >
            <div class="td number">
              {{ item.sortOrder }}
            </div>
            <div
              class="td company"
              :title="item.name"
            >
              {{ item.name }}
            </div>
            <div class="td date">
              {{ item.tabFunction }}
            </div>
            <div
              v-show="isHover && item.id === selectedId"
              class="icons-wrapper"
            >
              <div :title="'Delete Tab'">
                <inline-svg
                  :src="iconRemove"
                  class="svg-icon remove"
                  @click="onOpenModalForRemove(item)"
                >
                </inline-svg>
              </div>
              <div class="icon-edit">
                <router-link
                  :to="{ path: `${$route.params.id}/tabs/${item.id}`}"
                  :title="'Edit Tab'"
                >
                  <inline-svg
                    :src="iconEdit"
                    class="svg-icon"
                  >
                  </inline-svg>
                </router-link>
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="'Delete Tab'"
      :onAccept="removeRow"
      :onClose="onClose"
    />
    <AddFilteringTabModal
      v-model="isAddingFilteringTabOpen"
      :modal="isAddingFilteringTabOpen"
      :onClose="handleCloseAddingFilteringTab"
      :onSubmitManually="handleSubmitAddingFilteringTab"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import draggable from 'vuedraggable';
import Loader from '../../components/common/Loader.vue';
import iconEnabled from '../../assets/icons/icon-enabled-acc.svg';
import iconDisabled from '../../assets/icons/icon-disabled-acc.svg';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconFix from '../../assets/icons/icon-fix.svg';
import CONFIG from '../../constants/config';
import {
  GET_TABS_LIST_REQUEST,
  REMOVE_TAB_REQUEST,
} from '../../store/actions/cmsActions/cmsActions';
import Button from '../../components/common/Button.vue';
import iconRemove from '../../assets/icons/icon-remove-role.svg';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../store/actions/common';
import NotificationModal from '../../components/NotificationModal.vue';
import AddFilteringTabModal from './AddFilteringTabModal.vue';

export default {
  name: 'GridTabsByPage',
  components: {
    Button,
    Loader,
    NotificationModal,
    draggable,
    AddFilteringTabModal,
  },
  props: {
    orderTabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const {
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editCMSPage,
    } = CONFIG.tooltips;

    return {
      dragging: false,
      tabs: [],
      iconRemove,
      paymentStatuses: CONFIG.paymentStatuses,
      enabledId: null,
      akamaiAccountCreateId: null,
      akamaiAppSyncId: null,
      isAddingFilteringTabOpen: false,
      isEnabled: false,
      isModalOpen: false,
      isAkamaiAppCreateModalOpen: false,
      isAkamaiAppSyncModalOpen: false,
      akamaiAppCreateBodyMessage: CONFIG.modals.akamaiAppCreateConfirmationMessage,
      akamaiAppSyncBodyMessage: CONFIG.modals.akamaiAppSyncConfirmationMessage,
      isHover: false,
      selectedId: null,
      bodyMessage: '',
      filter: '',
      currentSortedField: 'a.name',
      currentSortBy: 'ASC',
      isSortedUp: false,
      isSortedDown: false,
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editCMSPage,
      iconEnabled,
      iconEdit,
      iconDisabled,
      iconFix,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
    ]),
    ...mapState({
      rowsList: (state) => state.cms.rowsList,
      pagination: (state) => state.cms.pagination,
      isLoading: (state) => state.cms.isLoading,
      profile: (state) => state.common.profile,
    }),
  },
  created() {
    this.tabs = this.orderTabs;
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    this.getList();
  },
  methods: {
    getList() {
      const params = {
        accountId: this.getCurrentAccountId,
        cmsPageId: this.$route.params.id,
      };
      this.$store.dispatch(GET_TABS_LIST_REQUEST, params).then((res) => {
        this.tabs = res.data;
      });
    },
    endOfDrag() {
      this.dragging = false;
      this.$emit('dragged', this.tabs);
    },
    onOpenModalForRemove(item) {
      const {
        deleteTabInCms,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.enabledId = item.id;
      this.bodyMessage = deleteTabInCms;
    },
    checkMove() {
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    createSortingTab() {
      this.$router.push(`${this.$route.path}/tabs/add`);
    },
    createFilteringTab() {
      this.isAddingFilteringTabOpen = true;
    },
    handleCloseAddingFilteringTab() {
      this.isAddingFilteringTabOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    handleSubmitAddingFilteringTab() {
      this.isAddingFilteringTabOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
      this.getList();
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    removeRow() {
      const params = {
        accountId: this.getCurrentAccountId,
        tabId: this.enabledId,
      };

      this.$store.dispatch(REMOVE_TAB_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Tab has been successfully deleted',
          });
          const params = {
            accountId: this.getCurrentAccountId,
            cmsPageId: this.$route.params.id,
          };
          this.$store.dispatch(GET_TABS_LIST_REQUEST, params).then((res) => {
            this.tabs = res.data;
          });
        });
    },
  },
};
</script>

<style scoped>
.flex {
  align-items: center;
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 40px;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttons-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  margin-bottom: 30px;
}

.system {
  color: #4c5057;
}

.accounts-wrapper {
  border: 1px solid #43434d;
  height: 65vh;
  overflow-y: auto;
}

.ac-wrapper-header {
  background: #282a2e;
  display: flex;
  padding: 13px 0;
}

.th-name {
  align-items: center;
  display: flex;
  font-size: 12px;
  text-transform: uppercase !important;
}

.td {
  font-size: 14px;
  padding: 15px 0;
  word-break: break-word;
}

.number {
  padding-left: 21px;
  width: 50px;
}

.company {
  padding-right: 15px;
  width: 20%;
}

.users-count {
  width: 15%;
}

.status {
  align-items: flex-start;
  display: flex;
  width: 11%;
}

.date {
  align-items: flex-start;
  display: flex;
  width: 15%;
  text-transform: capitalize;
}

.account {
  display: flex;
}

.account:hover {
  background: #383b40;
  cursor: move;
}

.format {
  text-transform: capitalize;
}

.status-ellipse {
  border-radius: 50%;
  height: 5px;
  margin-right: 6px;
  margin-top: 6px;
  width: 5px;
}

.status-ellipse.enabled,
.status-ellipse.active {
  background: #1fee65;
}

.status-ellipse.disabled,
.status-ellipse.blocked,
.status-ellipse.pending {
  background: #fb3951;
}

.status-ellipse.failed {
  background: #fbf650;
}

.icons-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  flex: 0.97;
}

.icons-wrapper div {
  padding: 10px;
}

.create-button {
  background: #2591cf;
  color: #fff;
  font-size: 16px;
  letter-spacing: normal;
  padding: 0 16px;
  text-transform: none;
}

.right-path {
  align-items: center;
  display: flex;
}

.empty-search {
  padding-top: 40px;
  text-align: center;
}

.btn-create-account-mobile {
  display: none;
}

.svg-icon {
  cursor: pointer;
}

.not-access {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
}

@media all and (max-width: 1400px) {
  .number {
    width: 30px;
  }

  .status {
    width: 9%;
  }

}

@media all and (max-width: 1170px) {
  .status {
    width: 9.5%;
  }
}

@media all and (max-width: 1100px) {
  .icons-wrapper div {
    padding: 10px 5px;
  }
}

@media all and (max-width: 1024px) {
  .btn-create-account-mobile {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    min-height: 40px;
  }

  .search-wrapper {
    margin-right: 0;
  }
}

@media all and (max-width: 900px) {
  .company {
    padding-right: 7px;
    width: 14%;
  }

  .number {
    width: 24px;
  }

  .status {
    width: 10%;
  }
}

@media all and (max-height: 940px) {
  .accounts-wrapper {
    height: 63vh;
  }
}

@media all and (max-height: 899px) {
  .accounts-wrapper {
    height: 62vh;
  }
}

@media all and (max-height: 845px) {
  .accounts-wrapper {
    height: 60vh;
  }
}

@media all and (max-height: 800px) {
  .accounts-wrapper {
    height: 59vh;
  }
}

@media all and (max-height: 770px) {
  .accounts-wrapper {
    height: 52vh;
  }
}
</style>
