import apiCall from '../../../utils/api/api';
import sseCall from '../../../utils/api/sse';
import {
  GET_SCHEDULE,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_ERROR,
  GET_SCHEDULE_INTERVAL,
  GET_SCHEDULE_INTERVAL_REQUEST,
  GET_SCHEDULE_INTERVAL_SUCCESS,
  GET_SCHEDULE_INTERVAL_ERROR,
  EDIT_SCHEDULE_INTERVAL,
  EDIT_SCHEDULE_INTERVAL_REQUEST,
  EDIT_SCHEDULE_INTERVAL_SUCCESS,
  EDIT_SCHEDULE_INTERVAL_ERROR,
  PUBLISH_SCHEDULE_SSE,
  PUBLISH_SCHEDULE_SSE_SUCCESS,
  EDIT_SCHEDULE_SETTINGS,
  EDIT_SCHEDULE_SETTINGS_REQUEST,
  EDIT_SCHEDULE_SETTINGS_SUCCESS,
  EDIT_SCHEDULE_SETTINGS_ERROR,
  GET_NEW_SCHEDULE_ITEM_REQUEST,
  GET_NEW_SCHEDULE_ITEM_SUCCESS,
  GET_NEW_SCHEDULE_ITEM_ERROR,
} from '../../actions/schedule/schedule';

const state = {
  isLoading: false,
  schedule: {},
  isPublishDisabled: false,
  isPublishInProgress: false,
};

const actions = {
  [GET_SCHEDULE]: ({ commit }, { accountId, scheduleId }) => new Promise((resolve, reject) => {
    commit(GET_SCHEDULE_REQUEST);

    apiCall.get(`${accountId}/account-schedule/${scheduleId}`)
      .then((resp) => {
        commit(GET_SCHEDULE_SUCCESS, resp.data);
        resolve(resp.data);
      }).catch((err) => {
        const error = err.response.data;
        commit(GET_SCHEDULE_ERROR);
        reject(error);
      });
  }),
  [GET_SCHEDULE_INTERVAL]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(GET_SCHEDULE_INTERVAL_REQUEST);

    const {
      accountId,
      scheduleId,
      startDate,
      finishDate,
    } = data;

    apiCall.get(`${accountId}/account-schedule/${scheduleId}/media/search?startDate=${startDate}&finishDate=${finishDate}`)
      .then((resp) => {
        commit(GET_SCHEDULE_INTERVAL_SUCCESS, resp.data);
        resolve(resp.data);
      }).catch((err) => {
        const error = err.response.data;
        commit(GET_SCHEDULE_INTERVAL_ERROR);
        reject(error);
      });
  }),
  [EDIT_SCHEDULE_INTERVAL]:
  ({ commit, dispatch }, { accountId, scheduleId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_SCHEDULE_INTERVAL_REQUEST);

    apiCall.put(`${accountId}/account-schedule/${scheduleId}/media`, data)
      .then((resp) => {
        commit(EDIT_SCHEDULE_INTERVAL_SUCCESS, resp.data);
        dispatch(PUBLISH_SCHEDULE_SSE);
        resolve(resp.data);
      }).catch((err) => {
        const error = err.response.data;
        commit(EDIT_SCHEDULE_INTERVAL_ERROR);
        reject(error);
      });
  }),
  [PUBLISH_SCHEDULE_SSE]: ({ commit }) => new Promise(() => {
    let count = 0;
    function connect() {
      count += 1;
      sseCall.getEventSource(['/account-schedule/synchronize'])
        .then((eventSource) => {
          eventSource.onmessage = () => {
            commit(PUBLISH_SCHEDULE_SSE_SUCCESS, false);
          };

          eventSource.onerror = () => {
            if (count <= 20) {
              window.setTimeout(() => {
                connect();
              }, 5000);
            } else {
              eventSource.close();
            }
          };
        });
    }

    connect();
  }),
  [EDIT_SCHEDULE_SETTINGS]:
  ({ commit }, { accountId, scheduleId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_SCHEDULE_SETTINGS_REQUEST);

    apiCall.put(`${accountId}/account-schedule/${scheduleId}`, data)
      .then((resp) => {
        commit(EDIT_SCHEDULE_SETTINGS_SUCCESS, resp.data);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(EDIT_SCHEDULE_SETTINGS_ERROR);
        reject(error);
      });
  }),
  [GET_NEW_SCHEDULE_ITEM_REQUEST]:
  ({ commit }, { accountId, scheduleId }) => new Promise((resolve, reject) => {
    commit(GET_NEW_SCHEDULE_ITEM_REQUEST);

    apiCall.get(`${accountId}/account-schedule/${scheduleId}/media/new`)
      .then((resp) => {
        commit(GET_NEW_SCHEDULE_ITEM_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_NEW_SCHEDULE_ITEM_ERROR, error);
        reject(error);
      });
  }),
};

const mutations = {
  [GET_SCHEDULE_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_SCHEDULE_SUCCESS]: (state, data) => {
    state.schedule = data;
    state.isPublishDisabled = !data.syncAvailable;
    state.isPublishInProgress = data.syncInProgress;
    state.isLoading = false;
  },
  [GET_SCHEDULE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_SCHEDULE_INTERVAL_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_SCHEDULE_INTERVAL_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_SCHEDULE_INTERVAL_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_SCHEDULE_INTERVAL_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_SCHEDULE_INTERVAL_SUCCESS]: (state, data) => {
    state.isPublishInProgress = data.syncInProgress;
    state.isLoading = false;
  },
  [EDIT_SCHEDULE_INTERVAL_ERROR]: () => {
    state.isLoading = false;
  },
  [PUBLISH_SCHEDULE_SSE_SUCCESS]: (state, data) => {
    state.isPublishInProgress = data;
  },
  [EDIT_SCHEDULE_SETTINGS_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_SCHEDULE_SETTINGS_SUCCESS]: (state, data) => {
    state.schedule = data;
    state.isLoading = false;
  },
  [EDIT_SCHEDULE_SETTINGS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_NEW_SCHEDULE_ITEM_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_NEW_SCHEDULE_ITEM_SUCCESS]: (state) => {
    state.isLoading = false;
  },
  [GET_NEW_SCHEDULE_ITEM_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
