<template>
  <div class="schedule main-body-right">
    <div
      v-if="isAccountActive"
      class="wrap"
    >
      <div class="system">
        Schedule
      </div>
      <div class="nav">
        <div class="sub-nav">
          <inline-svg
            :src="iconFolder"
            class="svg-icon"
          >
          </inline-svg>
          Linear Live (24/7)
          <router-link
            :to="setCalendarLink"
            tag="div"
            class="router"
            :activeClass="'active'"
            :event="moving"
          >
            Calendar
          </router-link>
          <router-link
            to="/schedule/settings"
            tag="div"
            class="router"
            :activeClass="'active'"
            :event="moving"
          >
            Scheduler Settings
          </router-link>
          <router-link
            to="/schedule/delivery-settings"
            tag="div"
            class="router"
            :activeClass="'active'"
            :event="moving"
          >
            Delivery Settings
          </router-link>
        </div>
        <div
          class="sub-nav"
        >
          <inline-svg
            :src="iconFolder"
            class="svg-icon"
          >
          </inline-svg>
          Scheduled Events
          <router-link
            to="/schedule/event-group"
            tag="div"
            class="router"
            :activeClass="'active'"
            :event="moving"
          >
            Event Groups
          </router-link>
          <router-link
            to="/schedule/standalone-event"
            tag="div"
            class="router"
            :activeClass="'active'"
            :event="moving"
          >
            Standalone
          </router-link>
          <router-link
            v-if="getCurrentAccount.tvAppsEnabled"
            to="/schedule/apps-event-group"
            tag="div"
            class="router"
            :activeClass="'active'"
          >
            Apps Event Group
          </router-link>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import moment from 'moment';
import {
  mapGetters,
} from 'vuex';
import iconFolder from '../../assets/icons/icon-folder.svg';

export default {
  name: 'ScheduleNavigation',
  data() {
    return {
      iconFolder,
    };
  },
  computed: {
    ...mapGetters([
      'isAccountActive',
      'getCurrentAccount',
    ]),
    moving() {
      return this.isAccountActive ? 'click' : '';
    },
    setCalendarLink() {
      return `/schedule?view=week&date=${moment().format('YYYY-MM-DD')}`;
    },
  },
};
</script>

<style scoped>
  .main-body-right {
    display: flex !important;
    padding: 0;
  }

  .nav {
    background: transparent;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }

 .sub-nav {
    margin-bottom: 40px;
    font-size: 1.2em;
  }

  .sub-nav .svg-icon {
    margin-right: 10px;
  }

  .system {
    border-bottom: 1px solid #383b40;
    padding: 30px 0 25px 25px;
  }

  .wrap {
    border-right: 1px solid #383b40;
    flex-direction: column;
    min-width: 330px;
    height: 100vh;
  }

  .router {
    position: relative;
    width: 100%;
    cursor: pointer;
    padding: 25px 0 0 35px;
    font-size: 16px;
  }

  .router:hover {
    color: #2591cf;
  }

  .router::before {
    background-color: #4c5057;
    bottom: 10px;
    content: '';
    left: 8px;
    display: block;
    height: 40px;
    position: absolute;
    width: 2px;
  }

  .router::after {
    background-color: #4c5057;
    content: '';
    display: block;
    height: 2px;
    left: 8px;
    position: absolute;
    top: 35px;
    transform: translateY(-50%);
    width: 15px;
  }

  .active {
    color: #2591cf;
  }

  @media all and (max-width: 1200px) {
    .wrap {
      display: none;
    }
  }
</style>
