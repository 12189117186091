var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"feeds-form"},[(_vm.isInitialized)?_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"left-path"},[_c('span',{staticClass:"system"},[_vm._v(" Collections ")]),_c('span',{staticClass:"system"},[_vm._v(" / Feeds ")]),(!_vm.isEdit)?_c('span',[_vm._v(" / Add Feed ")]):_c('span',[_vm._v(" / Edit "+_vm._s(_vm.feed ? _vm.getName(_vm.feed.name) : '')+" ")])]),_c('div',{staticClass:"apply-btn-container"},[_c('Button',{attrs:{"title":'Apply',"classType":'primary add-feed',"type":'button',"eventFunction":_vm.saveFeed}})],1)]),_c('div',{staticClass:"feeds-form-wrapper"},[_c('div',{staticClass:"feeds-filter"},[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
return [_c('form',{attrs:{"novalidate":"novalidate"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"input":_vm.handleFormChange}},[_c('div',{staticClass:"feeds-wrapper"},[_c('div',{staticClass:"tabs-container"},[_c('div',{staticClass:"local-wrapper"},[_c('div',{staticClass:"title"},[_vm._v(" Feed Settings ")]),(_vm.enabledLanguages.length)?_c('div',[_c('Button',{attrs:{"title":'Localization',"classType":'primary',"className":'wide-btn',"srcSvg":_vm.iconFolderLocalization,"isReverseIcon":true,"eventFunction":_vm.toFeedLocalization,"disabled":!_vm.isEdit}})],1):_vm._e()]),_c('Tabs',{attrs:{"onSelectTab":_vm.handleSelectTab,"remove":_vm.formData.mode === 'content'}},[_c('Tab',{staticClass:"wrapper-general",attrs:{"id":"general","name":"General Info","selected":true,"hasError":_vm.hasGeneralInfoError(errors)
                      || _vm.hasCustomFieldErrors(_vm.getSortedFields('feed_settings_general'), errors)}},[_c('GeneralInfoFeed',{ref:"generalInfoFeed",attrs:{"formData":_vm.formData,"formFields":_vm.formFields,"isEdit":_vm.isEdit,"enabledLanguages":_vm.enabledLanguages}}),(_vm.hasSortedFields('feed_settings_general'))?_c('div',{staticClass:"custom-field-wrapper"},[_c('div',{staticClass:"custom-field-title"},[_vm._v(" Custom fields ")]),_vm._l((_vm.getSortedFields('feed_settings_general')),function(item){return _c('FieldValueRow',{key:("fieldValue" + (item.field.id)),attrs:{"field":item.field,"fieldName":_vm.getFieldName(item.field),"formPrefix":_vm.formPrefix,"inputClassName":'feed-input',"selectClassName":'feed-select',"calendarClassName":'feed-calendar',"datetimeClassName":'feed-datetime',"checkboxClassName":'feed-checkbox'},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})})],2):_vm._e()],1),_c('Tab',{staticClass:"wrapper-content",attrs:{"id":"content","name":"Content","selected":false,"hasError":_vm.hasContentError(errors)
                      || _vm.hasCustomFieldErrors(_vm.getSortedFields('feed_settings_content'), errors)}},[_c('ContentFeed',{ref:"contentFeed",attrs:{"formData":_vm.formData,"dynamicContentLimit":Number(_vm.formData.dynamicContentLimit),"sortBy":_vm.formData.sortBy,"sortOrder":_vm.formData.sortOrder,"formFields":_vm.formFields,"itemsErrors":_vm.formErrors,"getMediaItems":_vm.getMediaItems,"resetDynamicItems":_vm.resetDynamicItems,"handleResetValidation":_vm.handleResetValidation}}),(_vm.hasSortedFields('feed_settings_content'))?_c('div',{staticClass:"custom-field-wrapper"},[_c('div',{staticClass:"custom-field-title"},[_vm._v(" Custom fields ")]),_vm._l((_vm.getSortedFields('feed_settings_content')),function(item){return _c('FieldValueRow',{key:("fieldValue" + (item.field.id)),attrs:{"field":item.field,"fieldName":_vm.getFieldName(item.field),"formPrefix":_vm.formPrefix,"inputClassName":'feed-input',"selectClassName":'feed-select',"calendarClassName":'feed-calendar',"datetimeClassName":'feed-datetime',"checkboxClassName":'feed-checkbox'},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})})],2):_vm._e()],1),_c('Tab',{staticClass:"wrapper-format",attrs:{"id":"formats","name":"Formats","selected":false,"hasError":_vm.hasFormatsError(errors)
                      || _vm.hasCustomFieldErrors(_vm.getSortedFields('feed_settings_formats'), errors)}},[_c('FormatsFeed',{ref:"formatsFeed",attrs:{"formData":_vm.formData,"formFields":_vm.formFields}}),(_vm.hasSortedFields('feed_settings_formats'))?_c('div',{staticClass:"custom-field-wrapper"},[_c('div',{staticClass:"custom-field-title"},[_vm._v(" Custom fields ")]),_vm._l((_vm.getSortedFields('feed_settings_formats')),function(item){return _c('FieldValueRow',{key:("fieldValue" + (item.field.id)),attrs:{"field":item.field,"fieldName":_vm.getFieldName(item.field),"formPrefix":_vm.formPrefix,"inputClassName":'feed-input',"selectClassName":'feed-select',"calendarClassName":'feed-calendar',"datetimeClassName":'feed-datetime',"checkboxClassName":'feed-checkbox'},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})})],2):_vm._e()],1),_c('Tab',{staticClass:"wrapper-advanced",attrs:{"id":"advanced","name":"Advanced","selected":false,"hasError":_vm.hasAdvancedError(errors)
                      || _vm.hasCustomFieldErrors(_vm.getSortedFields('feed_settings_advanced'), errors)}},[_c('AdvancedFeed',{ref:"advancedFeed",attrs:{"formData":_vm.formData,"formFields":_vm.formFields,"itemsErrors":_vm.itemsErrors,"handleResetValidation":_vm.handleResetValidation,"isPosterItunesChanged":_vm.isPosterItunesChanged,"removeSubcategories":_vm.removeSubcategories,"posterITunesChanged":_vm.posterITunesChanged}}),(_vm.hasSortedFields('feed_settings_advanced'))?_c('div',{staticClass:"custom-field-wrapper"},[_c('div',{staticClass:"custom-field-title"},[_vm._v(" Custom fields ")]),_vm._l((_vm.getSortedFields('feed_settings_advanced')),function(item){return _c('FieldValueRow',{key:("fieldValue" + (item.field.id)),attrs:{"field":item.field,"fieldName":_vm.getFieldName(item.field),"formPrefix":_vm.formPrefix,"inputClassName":'feed-input',"selectClassName":'feed-select',"calendarClassName":'feed-calendar',"datetimeClassName":'feed-datetime',"checkboxClassName":'feed-checkbox'},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})})],2):_vm._e()],1)],1)],1)])])]}}],null,false,2789150326)})],1),_c('div',{staticClass:"feeds-preview"},[_c('FeedResultsPreview',{key:_vm.getKeyResults,attrs:{"mediaItems":_vm.mediaItems,"fixedItems":_vm.formData.fixedItems,"getMediaItems":_vm.getMediaItems,"onSelectFixedItem":_vm.onSelectFixedItem,"onChangeFixedItem":_vm.onChangeFixedItem,"onRemoveFixedItem":_vm.onRemoveFixedItem,"isDynamicContent":Boolean(_vm.formData.isDynamicContent),"pagination":_vm.pagination.total,"dynamicContentLimit":Number(_vm.formData.dynamicContentLimit),"defaultLength":_vm.defaultLength,"errorLiveEvents":_vm.errorLiveEvents}})],1)])]):_vm._e(),(_vm.isNotFoundFeed)?_c('div',[_vm._m(0)]):_vm._e(),_c('Loader',{attrs:{"isVisible":_vm.isLoading}}),(_vm.isLocalOpen)?_c('FeedLocalization',{attrs:{"feed":_vm.formData,"onClose":_vm.onCloseLocalizationModal}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"not-found-wrapper"},[_c('h2',[_vm._v("Ooops... Feed is not found.")])])}]

export { render, staticRenderFns }