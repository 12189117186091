<template>
  <div class="main-wrapper">
    <ScheduleSettingsForm />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { SET_EDIT_FORM } from '../../store/actions/common';
import CONFIG from '../../constants/config';

import ScheduleSettingsForm from '../../components/forms/ScheduleSettingsForm.vue';

export default {
  name: 'ScheduleSettings',
  components: {
    ScheduleSettingsForm,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      isEditedForm: (state) => state.common.isEditedForm,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
  },
  methods: {
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
  },
};
</script>

<style scoped>
  .main-wrapper {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 25px 30px;
    width: 100%;
    flex: 1;
    height: 87vh;
    overflow: auto;
  }
</style>
