<template>
  <div class="wrapper">
    <ValidationObserver
      ref="validationObserver"
      v-slot="{ handleSubmit }"
    >
      <Loader :isVisible="!isLoaded && showLoader" />
      <form
        v-if="isLangInitialized"
        novalidate="novalidate"
        @submit.prevent="handleSubmit(submit)"
      >
        <p class="headline grey lighten-2">
          {{ formTitle }}
        </p>
        <div class="edit-wrapper">
          <MaterialInput
            v-if="altType !== 'document' && altType !== 'other' && altType !== 'image'"
            v-model="formData.audioUrl"
            v-bind="formFields.audioUrl"
            :label="altType === 'linked-file' ? 'Source URL' : ''"
            :disabled="urlDisabled"
            :requiredFromBack="true"
          />
          <div v-if="altType !== 'linked-file'">
            <div v-if="!uploadedFiles.length">
              <div
                class="upload-text"
                :class="!!formData.audioUrl ? 'disabled-upload-text' : ''"
              >
                {{ dragTitle }}
              </div>
              <AlternativeAudioUploader
                :onFileUpload="onFileUpload"
                :isDisabled="!!formData.audioUrl"
                :altType="altType"
                :assetTypeReq="assetTypeReq"
              />
            </div>
            <div
              v-else
              style="margin-bottom: 20px"
            >
              <ProgressAssetBar
                :file="uploadedFiles.length && uploadedFiles[0]"
                :isAsset="true"
                :error="docOtherErr"
                :content="formData.content"
                :clearContent="clearContent"
              />
            </div>
          </div>
          <div
            v-if="errorMess"
            class="changes-wrapper"
          >
            <inline-svg
              :src="iconChanges"
              class="svg-icon"
            >
            </inline-svg>
            <span class="changes-text">
              {{ errorMess }}
            </span>
          </div>
          <span class="line"></span>
          <MaterialInput
            v-if="!altType"
            v-model="formData.label"
            v-bind="formFields.label"
            className="tree-modal-inputs"
          />
          <MaterialSelect
            v-model="formData.assetRole"
            v-bind="formFields.assetRole"
            :options="assetRolesOptions"
            className="tree-modal-inputs"
            :disabled="assetRolesOptions.length === 1
              && assetRolesOptions[0].code === 'No-items'"
          />
          <div
            v-if="formatError"
            class="format-error"
          >
            <inline-svg
              :src="iconWarning"
              class="svg-icon format-icon"
            >
            </inline-svg>
            {{ formatError }}
          </div>
          <MaterialSelect
            v-model="formData.language"
            v-bind="formFields.language"
            :options="languages"
            className="tree-modal-inputs"
          />
          <div
            v-if="assetTypeReq === 'Alternate Audio'"
            class="checkbox"
          >
            <MaterialCheckbox
              :toggle="toggleDefaultTrack"
              :label="'Default Audio Track'"
              :checked="formData.isDefaultTrack"
            />
          </div>
        </div>
        <div class="button-wrapper">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="onCancelHandle"
          />
          <Button
            v-if="!isNotFound"
            :title="'Add'"
            :classType="'primary'"
            :type="'submit'"
            :disabled="buttonDisabled"
          />
        </div>
      </form>
    </ValidationObserver>
    <NotificationModal
      ref="eventWarningModal"
      v-model="isShowWarningModal"
      :modal="isShowWarningModal"
      :bodyMessage="bodyMessage"
      :cancelButtonTitle="'Cancel'"
      :confirmButtonTitle="'Continue'"
      :onAccept="onContinue"
      :onClose="closeModal"
      :isOrangeCancel="true"
    />
    <NotificationModal
      ref="eventWarningModal"
      v-model="isShowMoreOneModal"
      :modal="isShowMoreOneModal"
      :bodyMessage="bodyMessageMoreOne"
      :cancelButtonTitle="'Cancel'"
      :confirmButtonTitle="'Continue'"
      :onAccept="onContinueMoreOne"
      :onClose="closeModalMoreOne"
      :isOrangeCancel="true"
    />
    <NotificationModal
      ref="defaultModal"
      v-model="isShowDefaultModal"
      :modal="isShowDefaultModal"
      :bodyMessage="bodyMessageDefaultModal"
      :onAccept="onContinueDefault"
      :onClose="closeModalDefault"
      :title="'Reassign Default Audio Track'"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import CONFIG from '../../../../constants/config';
import {
  GET_ASSET_LANGUAGE_REQUEST,
  ADD_ALT_AUDIO_URL_ASSET_REQUEST,
  ADD_ALT_AUDIO_ASSET_REQUEST,
  ADD_SUPP_AUDIO_URL_ASSET_REQUEST,
  ADD_SUPP_AUDIO_ASSET_REQUEST,
  ADD_SUPP_VIDEO_ASSET_REQUEST,
  ADD_SUPP_VIDEO_URL_ASSET_REQUEST,
  GET_ASSET_ROLES_OPTIONS_REQUEST,
  ADD_DOCUMENT_ASSET_REQUEST,
  ADD_OTHER_ASSET_REQUEST,
  ADD_LINKED_FILE_ASSET_REQUEST,
  ADD_IMAGE_ASSET_REQUEST,
} from '../../../../store/actions/media/mediaAsset';
import {
  CLEAR_ASSET_FILES,
  FILE_APPEND_REQUEST,
  GENERATE_UPLOAD_URL_REQUEST,
  SEND_TRANSCODE_REQUEST,
  SET_UPLOADING_FILES,
} from '../../../../store/actions/UploadingActions/upload';
import { SET_EDIT_FORM } from '../../../../store/actions/common';

import BaseForm from '../../../forms/BaseForm.vue';
import MaterialInput from '../../../inputs/MaterialInput.vue';
import MaterialSelect from '../../../inputs/MaterialSelect.vue';
import Button from '../../../common/Button.vue';
import Loader from '../../../common/Loader.vue';
import ProgressAssetBar from '../../../common/ProgressAssetBar.vue';
import AlternativeAudioUploader from '../../../common/AlternativeAudioUploader.vue';
import NotificationModal from '../../../NotificationModal.vue';
import iconChanges from '../../../../assets/icons/icon-localization-changes.svg';
import iconWarning from '../../../../assets/icons/icon-warning-red.svg';
import MaterialCheckbox from '../../../inputs/MaterialCheckbox.vue';

export default {
  name: 'AlternativeAudioForm',
  components: {
    ProgressAssetBar,
    AlternativeAudioUploader,
    MaterialInput,
    MaterialSelect,
    Button,
    Loader,
    NotificationModal,
    MaterialCheckbox,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'addSubtitleAsset',
    },
    onCloseAlt: {
      type: Function,
      default: () => {},
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
    modalMode: {
      type: String,
      default: '',
    },
    modalData: {
      type: Object,
      default: () => {},
    },
    showLoader: {
      type: Boolean,
      default: true,
    },
    updateAssetList: {
      type: Function,
      default: () => {},
    },
    altType: {
      type: String,
      default: '',
    },
    hasDefaultAssets: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconChanges,
      iconWarning,
      formatError: '',
      isError: false,
      errorMess: '',
      isNotFound: false,
      isAssetInitialized: false,
      isLangInitialized: false,
      hasSubtitles: false,
      uploaderError: '',
      docOtherErr: '',
      data: null,
      assetRolesOptions: [],
      isShowWarningModal: false,
      hasContinue: false,
      bodyMessage: '',
      isShowMoreOneModal: false,
      hasContinueMoreOne: false,
      bodyMessageMoreOne: '',
      urlDisabled: false,
      isShowDefaultModal: false,
      hasContinueDefault: false,
      bodyMessageDefaultModal: 'This action will remove the "Default Audio Track" option from its previously assigned audio file. Do you wish to continue?',
      formData: {
        audioUrl: null,
        language: '',
        label: '',
        content: null,
        isDefaultTrack: false,
      },
      formFields: {
        audioUrl: {
          type: 'text',
          name: 'audioUrl',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 4096,
          },
        },
        label: {
          type: 'text',
          name: 'label',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 50,
          },
        },
        language: {
          name: 'language',
          formPrefix: this.formPrefix,
          rules: {
            required: !this.altType,
          },
        },
        content: {
          type: 'text',
          name: 'content',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 500000,
          },
        },
        assetRole: {
          type: 'text',
          name: 'assetRole',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
      },
      errorMapping: {
        label: [
          `${this.formPrefix}.label`,
        ],
        audioUrl: [
          `${this.formPrefix}.externalUrl`,
        ],
        language: [
          `${this.formPrefix}.language`,
        ],
        content: [
          `${this.formPrefix}.content`,
        ],
      },
      submitEvent: this.getSubmitEventType(),
      successMessage: this.getSuccessMessageRequest(),
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.mediaAsset.isLoading,
      languages: (state) => state.mediaAsset.languageList,
      uploadedFiles: (state) => state.upload.assetFiles,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getAppConfig',
    ]),
    isLoaded() {
      return !this.isLoading && this.isAssetInitialized && this.isLangInitialized;
    },
    buttonDisabled() {
      if (this.formData.audioUrl) {
        return !this.formData.audioUrl;
      }

      if (this.formData.content) {
        return !this.formData.content;
      }

      return true;
    },
    formTitle() {
      if (this.altType === 'supp') {
        return 'Supplemental Audio Upload';
      }

      if (this.altType === 'supp_video') {
        return 'Video Upload';
      }

      if (this.altType === 'document') {
        return 'Document Upload';
      }

      if (this.altType === 'other') {
        return 'Other Asset Type Upload';
      }

      if (this.altType === 'linked-file') {
        return 'Linked File Upload';
      }

      if (this.altType === 'image') {
        return 'Image Upload';
      }

      return 'Alternate Audio Upload';
    },
    dragTitle() {
      if (this.altType === 'supp') {
        return 'OR Use Drag & Drop area to upload the Supplemental Audio file:';
      }

      if (this.altType === 'supp_video') {
        return 'OR Use Drag & Drop area to upload the Video file:';
      }

      if (this.altType === 'document') {
        return 'Use Drag & Drop area to upload the Document:';
      }

      if (this.altType === 'other') {
        return 'Use Drag & Drop area to upload the file:';
      }

      if (this.altType === 'image') {
        return 'Use Drag & Drop area to upload the Image:';
      }

      return 'OR use Drag & Drop area to upload the Alternate Audio file:';
    },
    assetTypeReq() {
      if (this.altType === 'supp') {
        return 'Supplemental Audio';
      }

      if (this.altType === 'supp_video') {
        return 'Video';
      }

      if (this.altType === 'document') {
        return 'Document';
      }

      if (this.altType === 'other') {
        return 'Other';
      }

      if (this.altType === 'linked-file') {
        return 'Linked file';
      }

      if (this.altType === 'image') {
        return 'Image';
      }

      return 'Alternate Audio';
    },
  },
  created() {
    this.$store.dispatch(GET_ASSET_LANGUAGE_REQUEST)
      .then(() => {
        this.isLangInitialized = true;
      });
    this.isAssetInitialized = true;

    const assetRolesParams = {
      accountId: this.getCurrentAccountId,
      mediaId: this.$route.params.mediaId,
      assetType: this.assetTypeReq,
    };
    this.$store.dispatch(GET_ASSET_ROLES_OPTIONS_REQUEST, assetRolesParams).then((res) => {
      this.assetRolesOptions = res.data.map((item) => ({
        code: item.id,
        name: item.name,
        multiAsset: item.multiAsset,
        uniqueAssetType: item.uniqueAssetType,
        enabled: item.enabled,
        usedAssetId: item.usedAssetId,
        usedAssetType: item.usedAssetType,
      }));
      this.assetRolesOptions = this.assetRolesOptions.filter((item) => item.enabled);

      if (!this.assetRolesOptions.length) {
        this.assetRolesOptions = [{
          code: 'No-items',
          name: 'No items to display',
        }];

        this.formData.assetRole = this.assetRolesOptions[0].code;
      }
    });
  },
  destroyed() {
    this.$store.dispatch(CLEAR_ASSET_FILES);
  },
  methods: {
    getSubmitEventType() {
      if (this.altType === 'document') {
        return ADD_DOCUMENT_ASSET_REQUEST;
      }

      if (this.altType === 'other') {
        return ADD_OTHER_ASSET_REQUEST;
      }

      if (this.altType === 'linked-file') {
        return ADD_LINKED_FILE_ASSET_REQUEST;
      }

      if (this.altType === 'image') {
        return ADD_IMAGE_ASSET_REQUEST;
      }

      if (this.urlDisabled) {
        if (this.altType === 'supp') {
          return ADD_SUPP_AUDIO_ASSET_REQUEST;
        }

        if (this.altType === 'supp_video') {
          return ADD_SUPP_VIDEO_ASSET_REQUEST;
        }

        return ADD_ALT_AUDIO_ASSET_REQUEST;
      }

      if (this.altType === 'supp') {
        return ADD_SUPP_AUDIO_URL_ASSET_REQUEST;
      }

      if (this.altType === 'supp_video') {
        return ADD_SUPP_VIDEO_URL_ASSET_REQUEST;
      }

      return ADD_ALT_AUDIO_URL_ASSET_REQUEST;
    },
    toggleDefaultTrack(isChecked) {
      if (isChecked !== this.formData.isDefaultTrack) {
        this.formData.isDefaultTrack = isChecked ? 1 : 0;
      }
    },
    clearContent() {
      this.formData.content = null;
      this.urlDisabled = false;
      this.docOtherErr = '';
      this.errorMess = '';
      this.submitEvent = this.getSubmitEventType();
    },
    onSubmitSuccess() {
      this.$store.dispatch(SET_EDIT_FORM, false);
      let mess;
      if (this.altType === 'supp') {
        mess = 'The Supplemental Audio asset has been successfully added';
      }

      if (this.altType === 'supp_video') {
        mess = 'The Video asset has been successfully added';
      }

      if (this.altType !== 'supp' && this.altType !== 'supp_video') {
        mess = 'The Alternate Audio asset has been successfully added';
      }

      if (this.altType === 'document') {
        mess = 'The Document asset has been successfully added';
      }

      if (this.altType === 'other') {
        mess = 'The asset of type "Other" has been successfully added';
      }

      if (this.altType === 'linked-file') {
        mess = 'The Linked File asset has been successfully added';
      }

      if (this.altType === 'image') {
        mess = 'The Image asset has been successfully added';
      }

      this.$toasted.global.success({
        message: mess,
      });
      this.onSuccess(this.modalMode);
      this.onCancelHandle();
      this.updateAssetList();
    },
    onSubmitFail(error) {
      this.$store.dispatch(SET_EDIT_FORM, false);
      const formErrors = this.getServerFormErrors(error);

      this.isError = error.form_errors
        && error.form_errors.children
      && error.form_errors.children.externalUrl
      && error.form_errors.children.externalUrl.errors
      && error.form_errors.children.externalUrl.errors[0].error;

      const uploadError = error.form_errors
        && error.form_errors.children
        && error.form_errors.children.uploadedFile
        && error.form_errors.children.uploadedFile.errors
        && error.form_errors.children.uploadedFile.errors[0].error;

      const formatError = error
        && error.form_errors
        && error.form_errors.children
        && error.form_errors.children.assetRole
        && error.form_errors.children.assetRole.errors
        && error.form_errors.children.assetRole.errors[0].error;

      if (formatError === 'INVALID_AUDIO_FORMAT') {
        this.formatError = 'File format is invalid. Supported audio formats for the Podcast Audio: MP3, AAC';
        return;
      }

      if (this.isError === 'DURATION_MISMATCH' || uploadError === 'DURATION_MISMATCH') {
        this.errorMess = 'The audio file duration does not match the duration of the source video';
        return;
      }

      if (this.isError === 'INVALID_MEDIA_TYPE' || uploadError === 'INVALID_MEDIA_TYPE') {
        this.errorMess = 'The provided file does not appear to be the correct format for this asset type';
        return;
      }

      if (uploadError === 'INVALID_IMAGE_TYPE') {
        this.errorMess = 'The provided file does not appear to be an image. Please select an image file';
        return;
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        if (!this.hasSubtitles
          && formErrors[this.formFields.content.name]
          && formErrors[this.formFields.content.name][0]
        ) {
          this.uploaderError = formErrors[this.formFields.content.name][0];
        }

        if (formErrors.mediaItem && formErrors.mediaItem[0]) {
          this.$toasted.global.error({
            message: formErrors.mediaItem[0],
          });
        }
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else if (error.error === CONFIG.errorMessages.lockedError) {
        this.$toasted.global.error({
          message: CONFIG.errorMessages.lockedMessage,
        });
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    getRequestData() {
      this.formatError = '';
      const type = this.assetRolesOptions.find((item) => item.code === this.formData.assetRole);

      if ((type && type.usedAssetId && type.name === 'Translation Master' && !this.hasContinue)
        || (type && type.usedAssetId && type.name === 'Preview Clip' && !this.hasContinue)) {
        this.bodyMessage = `This action will remove this ${type.name === 'Translation Master' ? 'Translation Master' : 'Preview Clip'} Asset Role from its previously assigned ${this.assetTypeReq} Asset within this Media Item. Do you wish to continue?`;
        this.isShowWarningModal = true;
        return null;
      }

      if (type
        && type.usedAssetId
        && type.uniqueAssetType
        && !this.hasContinue) {
        this.bodyMessage = `This action will remove this ${type.name} Asset Role from its previously assigned ${this.assetTypeReq} Asset within this Media Item. Do you wish to continue?`;
        this.isShowWarningModal = true;
        return null;
      }

      if (type
        && type.usedAssetId
        && type.usedAssetType
        && !type.multiAsset
        && !this.hasContinue) {
        this.bodyMessage = `This action will remove this ${type.name} Asset Role from its previously assigned ${type.usedAssetType} Asset within this Media Item. Do you wish to continue?`;
        this.isShowWarningModal = true;
        return null;
      }

      if (type
        && !this.hasContinue
        && !type.multiAsset
        && (type.name !== 'Translation Master' || type.name !== 'Preview Clip')
        && type.usedAssetId
        && !this.hasContinueMoreOne) {
        this.bodyMessageMoreOne = `This action will remove this ${type.name} Asset Role from its previously assigned ${this.assetTypeReq} Asset within this Media Item. Do you wish to continue?`;
        this.isShowMoreOneModal = true;
        return null;
      }

      if (this.assetTypeReq === 'Alternate Audio'
        && this.hasDefaultAssets
        && this.formData.isDefaultTrack
        && !this.hasContinueDefault) {
        this.isShowDefaultModal = true;
        return null;
      }

      const data = {
        label: this.formData.label,
        language: this.formData.language,
        mediaItem: this.$route.params.mediaId,
        assetRole: this.formData.assetRole === 'No-items' ? '' : this.formData.assetRole,
      };

      if (this.formData.audioUrl) {
        data.externalUrl = this.formData.audioUrl;
      }

      if (this.formData.content) {
        data.uploadedFile = this.formData.content.id;
      }

      if (this.altType === 'supp'
        || this.altType === 'supp_video'
        || this.altType === 'document'
        || this.altType === 'other'
        || this.altType === 'linked-file'
        || this.altType === 'image') {
        delete data.label;
        if (!this.formData.language) {
          delete data.language;
        }
      }

      if (this.assetTypeReq === 'Alternate Audio') {
        data.isDefault = this.formData.isDefaultTrack;
      }

      return {
        accountId: this.getCurrentAccountId,
        data,
      };
    },
    onCancelHandle() {
      this.clearContent();
      this.onCloseAlt();
    },
    handleFileChange(subtitles) {
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.content = subtitles;
      this.hasSubtitles = !!subtitles;
      this.uploaderError = '';
    },
    closeModal() {
      this.isShowWarningModal = false;
    },
    closeModalMoreOne() {
      this.isShowMoreOneModal = false;
    },
    closeModalDefault() {
      this.isShowDefaultModal = false;
    },
    onContinue() {
      this.hasContinue = true;
      this.submit();
      this.isShowWarningModal = false;
    },
    onContinueMoreOne() {
      this.hasContinueMoreOne = true;
      this.submit();
      this.isShowMoreOneModal = false;
    },
    onContinueDefault() {
      this.hasContinueDefault = true;
      this.submit();
      this.isShowMoreOneModal = false;
    },
    getSuccessMessageRequest() {
      const {
        successMessages,
      } = CONFIG;
      return successMessages.addSubtitleAsset;
    },
    async onFileUpload(files) {
      this.docOtherErr = '';
      this.errorMess = '';
      let event;
      this.urlDisabled = true;

      if (this.altType === 'supp') {
        event = ADD_SUPP_AUDIO_ASSET_REQUEST;
      }

      if (this.altType === 'supp_video') {
        event = ADD_SUPP_VIDEO_ASSET_REQUEST;
      }

      if (this.altType !== 'supp' && this.altType !== 'supp_video') {
        event = ADD_ALT_AUDIO_ASSET_REQUEST;
      }

      if (this.altType === 'document') {
        event = ADD_DOCUMENT_ASSET_REQUEST;
      }

      if (this.altType === 'other') {
        event = ADD_OTHER_ASSET_REQUEST;
      }

      if (this.altType === 'linked-file') {
        event = ADD_LINKED_FILE_ASSET_REQUEST;
      }

      if (this.altType === 'image') {
        event = ADD_IMAGE_ASSET_REQUEST;
      }

      this.submitEvent = event;
      const chunkSize = Number(this.getAppConfig.mediaItem.uploading.maxPartSize);
      const {
        errorTypeFormat,
        invalidFileFormat,
        failedUpload,
        errorTypeSize,
        failedSizeUpload,
      } = CONFIG.errorMessages.upload;
      this.$toasted.global.success({
        message: this.$tc(
          CONFIG.successMessages.filesPreparedForUpload,
          files.length,
          { count: files.length },
        ),
      });

      await this.$store.dispatch(SET_UPLOADING_FILES, files);

      // eslint-disable-next-line no-restricted-syntax
      for (const file of Array.from(files)) {
        const params = {
          accountId: this.getCurrentAccountId,
          fileData: {
            file,
          },
          isAsset: true,
        };

        if (Number(this.folderId) !== this.getRootMediaFolderId) {
          params.fileData.folder = Number(this.folderId);
        }

        if (this.altType === 'document') {
          params.fileType = 'document';
        }

        if (this.altType === 'other') {
          params.fileType = 'other';
        }

        if (this.altType === 'image') {
          params.fileType = 'image';
        }

        // eslint-disable-next-line no-await-in-loop
        await this.$store.dispatch(GENERATE_UPLOAD_URL_REQUEST, params)
          .then(async (res) => {
            let chunkCounter = 0;
            let loaded = 0;

            const appendFile = (params) => this.$store.dispatch(FILE_APPEND_REQUEST, params)
              .then((data) => {
                this.data = data;
                if (data.totalSize <= chunkSize ? data.id : data.id && data.isLastChunk) {
                  const params = {
                    accountId: this.getCurrentAccountId,
                    itemId: data.id,
                    isAsset: true,
                  };
                  this.$store.dispatch(SEND_TRANSCODE_REQUEST, params)
                    .then(() => {
                      this.formData.content = this.data;
                    })
                    .catch((err) => {
                      console.log(err);
                      this.$toasted.global.error({
                        message: '',
                      });
                    });
                }
              })
              .catch((err) => {
                throw err;
              });

            for (let i = 0; i < res.uploadUrl.length; i += 1) {
              chunkCounter += 1;
              const numberofChunks = Math.ceil(res.file.size / chunkSize);
              const start = 0;
              const chunkEnd = Math.min(start + chunkSize, res.file.size);
              let chunk = res.file.slice(start, chunkEnd);

              if (i !== 0) {
                loaded += chunkSize;
              }

              if (loaded <= res.file.size) {
                chunk = file.slice(loaded, loaded + chunkSize);
              } else {
                loaded = res.file.size;
              }

              chunk.name = file.name || res.file.name;
              const params = {
                file: res.file.size <= chunkSize ? res.file : chunk,
                id: res.id,
                uploadUrl: res.uploadUrl[i],
                numberofChunks,
                chunkCounter,
                totalSize: res.file.size,
                isAsset: true,
              };

              if (res.file.size > chunkSize && (i + 1 === res.uploadUrl.length)) {
                params.isLastChunk = true;
              }

              // eslint-disable-next-line no-await-in-loop
              await appendFile(params);
            }
          })
          .catch((err) => {
            const docOtherErr = err.data
              && err.data.form_errors
              && err.data.form_errors.children
              && err.data.form_errors.children.fileLength
              && err.data.form_errors.children.fileLength.errors
              && err.data.form_errors.children.fileLength.errors[0]
              && err.data.form_errors.children.fileLength.errors[0].error;

            if (docOtherErr && docOtherErr === 'NOT_IN_RANGE_ERROR') {
              this.docOtherErr = `Unable to complete file upload (Max file size: ${this.altType === 'image' ? '10MB' : '2GB'})`;
              return;
            }

            if (err === errorTypeFormat) {
              this.$toasted.global.error({
                message: invalidFileFormat,
              });
            } else if (err.status === CONFIG.statuses.badRequest) {
              this.$toasted.global.error({
                message: failedUpload,
              });
            } else if (err === errorTypeSize) {
              this.$toasted.global.error({
                message: failedSizeUpload,
              });
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.wrapper {
  background-color: #222;
  border-radius: 4px;
  display: block;
  max-width: 100%;
  outline: none;
  overflow-wrap: break-word;
  padding: 30px;
  position: absolute;
  top: 100px;
  text-decoration: none;
  width: 630px;
  max-height: 79%;
  overflow-y: auto;
  overflow-x: hidden;
}

.headline,
.edit-wrapper {
  color: #fff;
}

.edit-wrapper {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.375rem;
}

.headline {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  margin: 0;
  padding-bottom: 30px;
  word-break: break-all;
}

hr {
  border: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: thin 0 0;
  display: block;
  flex: 1 1 0;
  height: 0;
  max-height: 0;
  max-width: 100%;
  transition: inherit;
}

.button-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.button-wrapper button:first-child {
  margin-right: 15px;
}

.edit-wrapper input,
.edit-wrapper select {
  padding: 13px;
}

.upload-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 20px;
}

.line {
  display: flex;
  border: 2px solid #383B40;
  width: 109%;
  margin-top: 34px;
  margin-bottom: 20px;
  margin-left: -26px;
}

.disabled-upload-text {
  opacity: 0.5;
}

.changes-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #FB3951;
  border-radius: 2px;
  padding: 8px;
  color: #FB3951;
  margin-top: 20px;
}

.no-items-block {
  margin-bottom: 20px;
}

.no-items {
  font-size: 16px;
}

.format-error {
  color: #fb3951;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.format-icon {
  padding-right: 7px;
}

@media all and (max-height: 950px) {
  .wrapper {
    max-height: 70%;
  }
}
</style>
