<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="add-feature-form"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="title modal">
        Add Genre
      </div>
      <div>
        <MaterialInput
          v-model="formData.name"
          v-bind="formFields.name"
          :label="'Name'"
          :requiredFromBack="true"
          :commonError="nameError"
        />
      </div>
      <div>
        <MaterialInput
          v-model="formData.shortName"
          v-bind="formFields.shortName"
          :label="'Short Name'"
          :requiredFromBack="true"
          :commonError="shortNameError"
        />
      </div>
      <div class="buttons-wrapper">
        <div class="buttons-container">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="backToManually"
          />
          <Button
            :title="'Add'"
            :classType="'primary'"
            :type="'submit'"
            :disabled="isAddButtonDisabled"
          />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';

import { ADD_GENRE_REQUEST } from '../../../store/actions/genres/genresActions';

export default {
  name: 'AddGenreForm',
  components: {
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'addGenreForm',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onSubmitManually: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      nameError: '',
      shortNameError: '',
      formData: {
        name: '',
        shortName: null,
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        shortName: {
          type: 'text',
          name: 'shortName',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
            alpha_num: 'en',
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        shortName: [
          `${this.formPrefix}.shortName`,
        ],
      },
      submitEvent: ADD_GENRE_REQUEST,
      successMessage: 'The Genre has been successfully added',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    isAddButtonDisabled() {
      return !(this.formData.name && this.formData.shortName);
    },
    name() {
      return this.formData.name;
    },
    shortName() {
      return this.formData.shortName;
    },
  },
  watch: {
    'formData.name': {
      handler: 'handleNameChange',
    },
    'formData.shortName': {
      handler: 'handleShortNameChange',
    },
  },
  methods: {
    handleNameChange(newValue, oldValue) {
      if (
        !this.isEdit
        && newValue !== oldValue
      ) {
        this.formData.shortName = newValue.replace(/([^a-z0-9]+)/gi, '');
      }
    },
    handleShortNameChange(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.formData.shortName = this.formData.shortName.charAt(0).toLowerCase()
          + this.formData.shortName.slice(1);
      }
    },
    backToManually() {
      this.onClose();
    },
    getRequestData() {
      const data = {
        name: this.formData.name,
        shortName: this.formData.shortName,
      };

      return {
        data,
        accountId: this.getCurrentAccountId,
      };
    },
    onSubmitSuccess(res) {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      this.onClose();
      this.$router.push(`/settings/genres/${res.id}`);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      const shortNameError = error.form_errors.children?.shortName?.errors[0]?.error;

      if (shortNameError === 'IS_BLANK_ERROR') {
        this.shortNameError = 'This field is required';
      }

      if (shortNameError === 'NOT_UNIQUE_ERROR') {
        this.shortNameError = 'This short name is already in use';
      }

      const nameError = error.form_errors.children?.name?.errors[0]?.error;

      if (nameError === 'IS_BLANK_ERROR') {
        this.nameError = 'This field is required';
      }

      if (nameError === 'NOT_UNIQUE_ERROR') {
        this.nameError = 'This name is already in use';
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>
.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}

.buttons-container {
  display: flex;
}

.title {
  color: inherit;
  margin-bottom: 30px;
}
</style>
