export const FILE_APPEND_REQUEST = 'FILE_APPEND_REQUEST';
export const FILE_APPEND_SUCCESS = 'FILE_APPEND_SUCCESS';
export const FILE_APPEND_ERROR = 'FILE_APPEND_ERROR';

export const GENERATE_UPLOAD_URL_REQUEST = 'GENERATE_UPLOAD_URL_REQUEST';
export const GENERATE_UPLOAD_URL_IS_START = 'GENERATE_UPLOAD_URL_IS_START';
export const GENERATE_UPLOAD_URL_SUCCESS = 'GENERATE_UPLOAD_URL_SUCCESS';
export const GENERATE_UPLOAD_URL_ERROR = 'GENERATE_UPLOAD_URL_ERROR';

export const SEND_TRANSCODE_REQUEST = 'SEND_TRANSCODE_REQUEST';
export const SEND_TRANSCODE_SUCCESS = 'SEND_TRANSCODE_SUCCESS';
export const SEND_TRANSCODE_ERROR = 'SEND_TRANSCODE_ERROR';

export const CHANGE_UPLOAD_PROGRESS = 'CHANGE_UPLOAD_PROGRESS';

export const ABORT_UPLOADING_REQUEST = 'ABORT_UPLOADING_REQUEST';

export const SET_CANCEL_REQUEST = 'SET_CANCEL_REQUEST';

export const REMOVE_UPLOAD_REQUEST = 'REMOVE_UPLOAD_REQUEST';

export const SEND_TRANSCODE_URL_REQUEST = 'SEND_TRANSCODE_URL_REQUEST';

export const TRANSCODE_URL_REQUEST = 'TRANSCODE_URL_REQUEST';
export const TRANSCODE_URL_SUCCESS = 'TRANSCODE_URL_SUCCESS';
export const TRANSCODE_URL_ERROR = 'TRANSCODE_URL_ERROR';

export const SET_CURRENT_CHUNK = 'SET_CURRENT_CHUNK';

export const SET_UPLOADING_FILES = 'SET_UPLOADING_FILES';
export const SET_UPLOADING_FILES_SUCCESS = 'SET_UPLOADING_FILES_SUCCESS';
export const SET_UPLOADING_FILES_ERROR = 'SET_UPLOADING_FILES_ERROR';

export const GENERATE_UPLOAD_URL_ASSET_REQUEST = 'GENERATE_UPLOAD_URL_ASSET_REQUEST';
export const GENERATE_UPLOAD_URL_IS_ASSET_START = 'GENERATE_UPLOAD_URL_IS_ASSET_START';
export const GENERATE_UPLOAD_URL_ASSET_SUCCESS = 'GENERATE_UPLOAD_URL_ASSET_SUCCESS';
export const GENERATE_UPLOAD_URL_ASSET_ERROR = 'GENERATE_UPLOAD_URL_ASSET_ERROR';

export const REMOVE_UPLOAD_ASSET_REQUEST = 'REMOVE_UPLOAD_ASSET_REQUEST';

export const ABORT_UPLOADING_ASSET_REQUEST = 'ABORT_UPLOADING_ASSET_REQUEST';

export const CHANGE_UPLOAD_ASSET_PROGRESS = 'CHANGE_UPLOAD_ASSET_PROGRESS';

export const CLEAR_ASSET_FILES = 'CLEAR_ASSET_FILES';

export const SET_CANCEL_ASSET_REQUEST = 'SET_CANCEL_ASSET_REQUEST';
