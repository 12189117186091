export const GET_ROLES_LIST_REQUEST = 'GET_ROLES_LIST_REQUEST';
export const GET_ROLES_LIST_SUCCESS = 'GET_ROLES_LIST_SUCCESS';
export const GET_ROLES_LIST_ERROR = 'GET_ROLES_LIST_ERROR';

export const ADD_CUSTOM_ROLE_REQUEST = 'ADD_CUSTOM_ROLE_REQUEST';
export const ADD_CUSTOM_ROLE_SUCCESS = 'ADD_CUSTOM_ROLE_SUCCESS';
export const ADD_CUSTOM_ROLE_ERROR = 'ADD_CUSTOM_ROLE_ERROR';

export const EDIT_CUSTOM_ROLE_REQUEST = 'EDIT_CUSTOM_ROLE_REQUEST';
export const EDIT_CUSTOM_ROLE_SUCCESS = 'EDIT_CUSTOM_ROLE_SUCCESS';
export const EDIT_CUSTOM_ROLE_ERROR = 'EDIT_CUSTOM_ROLE_ERROR';

export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_ERROR = 'GET_ROLE_ERROR';

export const DELETE_CUSTOM_ROLE_REQUEST = 'DELETE_CUSTOM_ROLE_REQUEST';
export const DELETE_CUSTOM_ROLE_SUCCESS = 'DELETE_CUSTOM_ROLE_SUCCESS';
export const DELETE_CUSTOM_ROLE_ERROR = 'DELETE_CUSTOM_ROLE_ERROR';

export const SET_CANCEL_TOKEN = 'SET_CANCEL_TOKEN';

export const DISABLE_CUSTOM_ROLE_REQUEST = 'DISABLE_CUSTOM_ROLE_REQUEST';
export const DISABLE_CUSTOM_ROLE_SUCCESS = 'DISABLE_CUSTOM_ROLE_SUCCESS';
export const DISABLE_CUSTOM_ROLE_ERROR = 'DISABLE_CUSTOM_ROLE_ERROR';
