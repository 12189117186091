import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  SET_CANCEL_TOKEN,
} from '../../actions/peopleActions/people';
import {
  CREATE_SERIES_ERROR,
  CREATE_SERIES_REQUEST,
  CREATE_SERIES_SUCCESS,
  DELETE_SERIES_ERROR,
  DELETE_SERIES_REQUEST,
  DELETE_SERIES_SUCCESS,
  EDIT_SERIES_ERROR,
  EDIT_SERIES_REQUEST,
  EDIT_SERIES_SUCCESS,
  GET_SERIES_ERROR,
  GET_SERIES_LIST_ERROR,
  GET_SERIES_LIST_REQUEST,
  GET_SERIES_LIST_SUCCESS,
  GET_SERIES_REQUEST,
  GET_SERIES_SUCCESS,
} from '../../actions/series/series';

const state = {
  isLoading: false,
  seriesList: [],
  pagination: {},
  series: {},
  source: null,
};

const actions = {
  [GET_SERIES_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    let url = `${params.accountId}/media-collection/series?p=${params.page}&l=15&filterField=s.title&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_SERIES_LIST_SUCCESS, resp.data);
        localStorage.setItem('series', resp.data.pagination.page);
        resolve();
      })
      .catch(() => {
        commit(GET_SERIES_LIST_ERROR);
      });
  }),
  [CREATE_SERIES_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(CREATE_SERIES_REQUEST);

    apiCall.post(`${accountId}/media-collection/series`, data)
      .then((res) => {
        commit(CREATE_SERIES_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_SERIES_ERROR, error);
        reject(error);
      });
  }),
  [GET_SERIES_REQUEST]: ({ commit }, { accountId, seriesId }) => new Promise(
    (resolve, reject) => {
      commit(GET_SERIES_REQUEST);

      const url = `${accountId}/media-collection/series/${seriesId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_SERIES_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_SERIES_ERROR, error);
          reject(error);
        });
    },
  ),
  [EDIT_SERIES_REQUEST]:
    ({ commit }, { accountId, seriesId, data }) => new Promise((resolve, reject) => {
      commit(EDIT_SERIES_REQUEST);

      apiCall.put(`${accountId}/media-collection/series/${seriesId}`, data)
        .then((resp) => {
          commit(EDIT_SERIES_SUCCESS, resp);
          resolve(resp);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(EDIT_SERIES_ERROR, error);
          reject(error);
        });
    }),
  [DELETE_SERIES_REQUEST]:
    ({ commit }, { accountId, seriesId }) => new Promise((resolve, reject) => {
      commit(DELETE_SERIES_REQUEST);

      apiCall.delete(`${accountId}/media-collection/series/${seriesId}`)
        .then((resp) => {
          commit(DELETE_SERIES_SUCCESS, resp.data);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(DELETE_SERIES_ERROR, error);
          reject(error);
        });
    }),
};

const mutations = {
  [GET_SERIES_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_SERIES_LIST_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.seriesList = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  [CREATE_SERIES_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_SERIES_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_SERIES_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_SERIES_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_SERIES_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_SERIES_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_SERIES_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_SERIES_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_SERIES_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_SERIES_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_SERIES_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.series = payload;
  },
  [GET_SERIES_ERROR]: () => {
    state.isLoading = false;
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
  },
};

export default {
  state,
  actions,
  mutations,
};
