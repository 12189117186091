<template>
  <div>
    <select
      v-model="selectValue"
      :required="required"
      :disabled="disabled"
      class="main-select"
      :class="className"
      v-bind="ariaInput"
      @change="onSelectChange"
    >
      <option
        v-if="withEmptyField"
        value=""
      >
      </option>
      <option
        v-for="item in options"
        :key="item.code"
        :value="item.code"
      >
        {{ item.name }}
      </option>
      <option
        :key="customKey"
        :value="customKey"
      >
        Custom value
      </option>
    </select>
    <input
      v-if="selectValue === customKey"
      v-model="inputValue"
      :type="type"
      :disabled="disabled"
      :maxlength="maxlength"
      :class="className"
      v-bind="ariaInput"
      @input="onInputChange"
    />
  </div>
</template>

<script>

export default {
  name: 'SelectCustomValue',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return [
          'url',
          'text',
          'password',
          'tel',
          'search',
          'number',
          'email',
        ].includes(value);
      },
    },
    options: {
      type: Array,
      default: null,
    },
    ariaInput: {
      type: Object,
      default: () => {},
    },
    className: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
    withEmptyField: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customKey: this.initCustomKey(),
      selectValue: this.initSelectValue(),
      inputValue: this.initInputValue(),
    };
  },
  methods: {
    initSelectValue() {
      return this.options.some((i) => i.code === this.value) || !this.value
        ? this.value
        : this.initCustomKey();
    },
    initInputValue() {
      return this.value && !this.options.some((i) => i.code === this.value)
        ? this.value
        : '';
    },
    initCustomKey() {
      const max = Math.max(...this.options.map((item) => item.code.length), 0);
      return `customKey-${'i'.repeat(max)}`;
    },
    onSelectChange() {
      this.updateModelValue();
    },
    onInputChange() {
      this.updateModelValue();
    },
    updateModelValue() {
      let value = this.inputValue;

      if (this.selectValue !== this.customKey) {
        value = this.selectValue;
      }

      this.$emit('input', value);
    },
  },
};
</script>

<style scoped>
  select {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-appearance: media-slider;
    appearance: none;
    background: #383b40;
    border: 0;
    border-radius: 0;
    height: 48px;
  }

  select:focus,
  input:focus {
    outline: none;
  }

  input {
    background: #383b40;
    border: 0;
    border-radius: 0;
    height: 48px;
    margin-top: 10px;
    padding: 15px;
  }

  select.inspector-select {
    background: #222;
  }

  input.inspector-input {
    background: #222;
  }

  select.feed-select {
    max-width: 100%;
  }
</style>
