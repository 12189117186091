<template>
  <div class="media-tag-management">
    <div class="multiselectTitle">
      {{ label }}
      <span
        v-if="required"
        class="asterisk"
      >
        *
      </span>
    </div>
    <div
      :class="[commonError.length ? 'common-error' : '',
               isNotValidValue || error ? 'not-valid' : '']"
    >
      <MultiSelect
        v-model="value"
        :options="options"
        :tagPlaceholder="tagPlaceholder"
        :placeholder="placeholder"
        :selectLabel="selectLabel"
        :selectedLabel="selectedLabel"
        :deselectLabel="deselectLabel"
        label="name"
        trackBy="code"
        :multiple="true"
        :taggable="true"
        :blockKeys="['Delete']"
        :searchable="searchable"
        :disabled="disabled"
        :hideSelected="hideSelected"
        :optionsLimit="max"
        :customLabel="customLabel"
        @tag="createTag"
        @search-change="onSearchChange"
        @input="onInput"
      />
      <span
        v-if="commonError.length || isNotValidValue || error"
        class="error-label"
      >
        {{ commonError.length ? commonError : error }}
      </span>
    </div>
  </div>
</template>
<script>
import MultiSelect from 'vue-multiselect';
import iconFolder from '../../assets/icons/icon-folder.svg';

export default {
  name: 'TagManagement',
  components: {
    MultiSelect,
  },
  props: {
    resetValidation: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    tagPlaceholder: {
      type: String,
      default: '',
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    searchable: {
      type: Boolean,
      default: false,
      required: false,
    },
    selectedValue: {
      type: Array,
      default: null,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    errorMess: {
      type: Array,
      default: () => [],
    },
    errorItems: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    isGrey: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideSelected: {
      type: Boolean,
      default: false,
      required: false,
    },
    commonError: {
      type: String,
      default: '',
    },
    customOptions: {
      type: Array,
      default: () => [],
    },
    isJustSearch: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 1000,
    },
    selectLabel: {
      type: String,
      default: '',
    },
    selectedLabel: {
      type: String,
      default: '',
    },
    deselectLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconFolder,
      value: [],
      options: this.getDefaultOptions(),
      isNotValidValue: false,
      errorMessage: '',
    };
  },
  computed: {
    error() {
      if (Array.isArray(this.errorMess) && this.errorMess.length) {
        const errors = [];

        this.value.forEach((value, index) => {
          if (this.errorMess[index]) {
            errors.push(value.name);
          }
        });

        if (errors.length) {
          return (`Domain(s) ${errors.map((item) => ` '${item}'`)} should not match the domain specified in the "Allowed Domains" field.`);
        }
      }

      if (this.errorItems && this.errorItems.length) {
        return this.errorItems[0];
      }

      return this.errorMessage;
    },
  },
  created() {
    this.initComponent();
  },
  methods: {
    initComponent() {
      if (this.selectedValue) {
        const items = this.selectedValue.map((item) => ({
          code: item.id,
          name: item.name,
        }));

        items.forEach((item) => {
          this.addTag(item);
        });
      }
    },
    getDefaultOptions() {
      return [];
    },
    // eslint-disable-next-line no-unused-vars
    createTag(data) {
    },
    addTag(tag) {
      this.options.push(tag);
      this.value.push(tag);

      this.onInput(this.value);
    },
    onSearchChange(searchQuery) {
      this.resetValidation();

      const validation = this.validateSearch(searchQuery.trim());

      this.isNotValidValue = !validation.isValid;
      this.errorMessage = validation.message;

      if (validation.isValid) {
        this.processSearchValue(validation.value);
      }
    },
    // eslint-disable-next-line no-unused-vars
    processSearchValue(value) {
    },
    validateSearch(searchValue) {
      return {
        value: searchValue,
        isValid: true,
        message: '',
      };
    },
    onInput(value) {
      this.resetValidation();
      this.onChange(value);
    },
    customLabel(option) {
      return option.name;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  /* stylelint-disable */
  .multiselectTitle {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .multiselect__tags {
    background: #222 !important;
    border-color: transparent;
    border-radius: 4px;
  }

  .multiselect__tag {
    background: #e77925;
  }

  .multiselect__tag-icon::after,
  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    color: #fff !important;
  }

  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: #e77925;
  }

  .multiselect__input {
    background: #222;
    border: none;
    padding-left: 0 !important;
    color: #fff;
  }

  .live-tag .multiselect__tags,
  .live-tag .multiselect__input {
    background: #383b40 !important;
  }

  .multiselect__input::placeholder {
    color: #fff;
    font-size: 14px;
    padding: 0;
  }

  .multiselect__element {
    font-size: 14px;
  }

  .multiselect__option--highlight,
  .multiselect__option--highlight:after {
    background: #e77925;
  }

  .media-tag-management {
    margin-top: 27px;
  }

  .multiselect__content-wrapper {
    border: 0;
  }

  .multiselect--above .multiselect__content-wrapper {
    border-top: 0;
  }

  .not-valid .multiselect__content-wrapper {
    display: none;
  }

  .not-valid .multiselect__tags,
  .common-error .multiselect__tags {
    border-bottom: 1px solid #fb3951;
  }

  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
  }

  .live-tag {
    width: 335px;
    margin-top: 0;
    margin-left: -20px;
  }

  .asterisk {
    color: #fb3951;
  }
</style>
