<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="flex">
      <div class="left-path">
        <span class="system">
          Scheduled Events / {{ isIndividualEvent ? 'Event Groups /' : '' }}
        </span>
        <span :title="eventGroupName">
          {{ isIndividualEvent ? `${getName(eventGroupName)} Individual Events` : 'Standalone' }}
        </span>
      </div>
      <div class="right-path">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="255"
        />
        <Button
          v-if="isIndividualEvent"
          :title="'Back'"
          :classType="'transparent'"
          :eventFunction="onBack"
        />
      </div>
    </div>
    <div class="table-wrapper">
      <div class="table-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name icons-type"></div>
        <div class="th-name event-title">
          <Sorting
            :sortField="`se.${sortedField.name}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          title
        </div>
        <div class="th-name date-time">
          <Sorting
            :key="dateKey"
            :sortField="`se.${sortedField.startDate}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          airdate / time
        </div>
        <div class="th-name duration">
          duration
        </div>
        <div class="th-name status">
          status
        </div>
        <div
          v-if="getCurrentAccount.brushfireProtectionEnabled"
          class="th-name status"
        >
          protection
        </div>
      </div>
      <div
        v-if="!events.length"
        class="empty-search"
      >
        No Events to display
      </div>
      <div
        v-for="(item, key) in events"
        :key="item.id"
        class="event"
        @mouseover="onHoverElem(item)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div class="td icons-type">
          <inline-svg
            :src="item.mediaItem.type === 'vod' ? vodEvent : liveEvent"
            class="svg-icon"
          >
          </inline-svg>
        </div>
        <div
          class="td event-title"
          :title="item.name"
        >
          {{ item.name }}
        </div>
        <div class="td date-time">
          {{ parseDateTime(item.startDate) }}
        </div>
        <div class="td duration">
          {{ item.duration ? setMarkerAsDate(item.duration) : '-' }}
        </div>
        <div class="td status">
          <div
            class="status-ellipse"
            :style="{ background: getStatus(item).color }"
          >
          </div>
          <span>
            {{ getStatus(item).name }}
          </span>
        </div>
        <div
          v-if="getCurrentAccount.brushfireProtectionEnabled"
          class="brush-icons"
        >
          <inline-svg
            v-if="item.hasProtection"
            :src="iconProtection"
            class="svg-icon group-icon"
          >
          </inline-svg>
          <div
            v-else
          >
            -
          </div>
        </div>
        <div
          v-show="isHover && item.id === selectedId"
          class="icons-wrapper"
        >
          <div
            class="icon-wrap"
            :title="removeEventTitle"
          >
            <inline-svg
              :src="iconRemove"
              class="svg-icon"
              @click="onRemoveEvent"
            >
            </inline-svg>
          </div>
          <div
            class="icon-wrap"
            :title="generateWidgetTitle"
            @click="onShowWidget(item)"
          >
            <inline-svg
              :src="iconCode"
              class="svg-icon"
            >
            </inline-svg>
          </div>
          <div
            class="icon-wrap"
            :title="editEventTitle"
            @click="onShowEditEvent(item)"
          >
            <inline-svg
              :src="iconEdit"
              class="svg-icon"
            >
            </inline-svg>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :totalPages="totalPages"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :title="'Delete Event'"
      :cancelButtonTitle="'Close'"
      :confirmButtonTitle="'Confirm'"
      :bodyMessage="bodyMessage"
      :onAccept="onDeleteEvent"
      :onClose="onClose"
    />
    <WidgetModal
      v-if="isShowWidgetModal"
      :autoplay="true"
      :modalData="widgetModalData"
      :onClose="onCloseWidgetModal"
      :isEvent="true"
      :getEvents="getEventList"
    />
  </div>
</template>

<script>
import moment from 'moment';
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  GET_ALL_EVENTS,
  DELETE_EVENT,
} from '../../store/actions/events/events';
import { GET_EVENT_GROUP } from '../../store/actions/events/eventGroups';

import { GET_WIDGET } from '../../store/actions/widgets/widget';

import CONFIG from '../../constants/config';

import iconRemove from '../../assets/icons/icon-remove-role.svg';
import iconCode from '../../assets/icons/icon-code.svg';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import vodEvent from '../../assets/icons/vod-event.svg';
import liveEvent from '../../assets/icons/live-event.svg';
import iconProtection from '../../assets/icons/icon-brush-protection-events.svg';

import Loader from '../../components/common/Loader.vue';
import Button from '../../components/common/Button.vue';
import Sorting from '../../components/common/Sorting.vue';
import LiveSearch from '../../components/common/LiveSearch.vue';
import NotificationModal from '../../components/NotificationModal.vue';
import Pagination from '../../components/common/Pagination.vue';
import WidgetModal from '../../components/common/WidgetModal.vue';

export default {
  name: 'StandaloneEvent',
  components: {
    Loader,
    Pagination,
    LiveSearch,
    Sorting,
    NotificationModal,
    WidgetModal,
    Button,
  },
  data() {
    const {
      removeEventTitle,
      generateWidgetTitle,
      editEventTitle,
    } = CONFIG.tooltips;
    return {
      iconRemove,
      iconCode,
      iconEdit,
      vodEvent,
      liveEvent,
      iconProtection,
      isModalOpen: false,
      bodyMessage: CONFIG.modals.deleteEventMessage,
      isShowWidgetModal: false,
      widgetModalData: null,
      isHover: false,
      selectedId: null,
      filter: '',
      sortedField: {
        name: 'name',
        startDate: 'startDate',
      },
      currentSortedField: '',
      currentSortBy: 'ASC',
      removeEventTitle,
      generateWidgetTitle,
      editEventTitle,
      eventGroupName: '',
      dateKey: 1,
      successDeleteMessage: CONFIG.successMessages.deleteEventMessage,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.events.isLoading,
      events: (state) => state.events.events,
      eventGroup: (state) => state.eventGroups.eventGroup,
      pagination: (state) => state.events.pagination,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
      'getMainProfile',
    ]),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    isIndividualEvent() {
      return Boolean(this.$route.params.id);
    },
    getTimezone() {
      return (this.getMainProfile.timezone) ? this.getMainProfile.timezone : moment.tz.guess();
    },
    standaloneEventGroup() {
      return (this.isIndividualEvent) ? Number(this.$route.params.id) : null;
    },
  },
  watch: {
    isIndividualEvent() {
      this.getEventList();
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
    this.getEventList();
  },
  methods: {
    getStatus(item) {
      switch (item.status) {
      case 'in_progress':
        return {
          name: 'In progress',
          color: '#E77925',
        };
      case 'completed':
        return {
          name: 'Complete',
          color: '#2591CF',
        };
      case 'up_next':
        return {
          name: 'Up Next',
          color: '#fb3951',
        };
      case 'ready':
        return {
          name: 'Ready',
          color: '#1FEE65',
        };
      default:
        return {};
      }
    },
    parseDateTime(value) {
      return moment.utc(value).tz(this.getTimezone).format('YYYY-MM-DD hh:mm A');
    },
    getEventList() {
      const params = {
        accountId: this.getCurrentAccountId,
        page: localStorage.getItem('eventsPage') || 1,
        filterValue: '',
        // sortFiled: this.currentSortedField,
        // sortBy: CONFIG.sort.sortDown,
        standaloneEventGroup: this.standaloneEventGroup,
      };

      this.$store.dispatch(GET_ALL_EVENTS, params);

      if (this.isIndividualEvent) {
        const params = {
          accountId: this.getCurrentAccountId,
          eventGroupId: this.standaloneEventGroup,
        };
        this.$store.dispatch(GET_EVENT_GROUP, params)
          .then((res) => {
            this.eventGroupName = res.name;
          });
      }
    },
    handleSortUp(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
        standaloneEventGroup: this.standaloneEventGroup,
      };
      this.currentSortedField = field;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_EVENTS, params);
      this.dateKey += 1;
    },
    handleSortDown(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
        standaloneEventGroup: this.standaloneEventGroup,
      };
      this.currentSortedField = field;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_EVENTS, params);
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        accountId: this.getCurrentAccountId,
        page: params.page,
        filterValue: this.filter,
        // sortFiled: this.currentSortedField,
        // sortBy: this.currentSortBy,
        standaloneEventGroup: this.standaloneEventGroup,
      };
      this.$store.dispatch(GET_ALL_EVENTS, getParams);
    },
    getPaginationAction(page) {
      const params = {
        accountId: this.getCurrentAccountId,
        page,
        filterValue: this.filter,
        // sortFiled: this.currentSortedField,
        // sortBy: this.currentSortBy,
        standaloneEventGroup: this.standaloneEventGroup,
      };
      this.$store.dispatch(GET_ALL_EVENTS, params);
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    onShowWidget(item) {
      if (item.widget) {
        this.openWidgetModal(item.widget);
      } else {
        this.generateWidget({
          accountId: this.getCurrentAccountId,
          data: {
            standaloneEvent: item.id,
          },
        }, item);
      }
    },
    generateWidget(data, item) {
      this.$store.dispatch(GET_WIDGET, data)
        .then((res) => {
          item.widget = res;
          this.openWidgetModal(res);
        });
    },
    openWidgetModal(data) {
      this.widgetModalData = data;
      this.isShowWidgetModal = true;
    },
    onCloseWidgetModal() {
      this.isShowWidgetModal = false;
    },
    onRemoveEvent() {
      this.isModalOpen = true;
    },
    onDeleteEvent() {
      const params = {
        accountId: this.getCurrentAccountId,
        eventId: this.selectedId,
      };

      this.$store.dispatch(DELETE_EVENT, params)
        .then(() => {
          this.$toasted.global.success({
            message: this.successDeleteMessage,
          });
          this.getEventList();
        });
    },
    onClose() {
      this.isModalOpen = false;
    },
    setMarkerAsDate(value) {
      return moment.utc(value * 1000).format('HH:mm:ss');
    },
    onShowEditEvent(item) {
      if (this.isIndividualEvent) {
        this.$router.push(`/schedule/event-group/individual/${item.id}`);
      } else {
        this.$router.push(`/schedule/standalone-event/${item.id}`);
      }
    },
    onBack() {
      this.$router.push('/schedule/event-group');
    },
    getName(name) {
      return name.length > 50 ? `${name.slice(0, 50)}...` : name;
    },
  },
};
</script>

<style scoped>
  .main-wrapper {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 25px 30px;
    width: 100%;
    overflow: scroll;
  }

  .flex {
    align-items: center;
    display: flex;
    flex: 0;
    justify-content: space-between;
    flex-flow: wrap;
    min-height: 40px;
    margin-bottom: 20px;
  }

  .system {
    color: #4c5057;
  }

  .right-path {
    align-items: center;
    display: flex;
  }

  .table-wrapper {
    border: 1px solid #43434d;
    flex: 1;
    max-height: 80vh;
    overflow-y: auto;
  }

  .table-wrapper-header {
    background: #282a2e;
    display: flex;
    padding: 13px 0;
  }

  .event {
    display: flex;
  }

  .event:hover {
    background: #383b40;
  }

  .th-name {
    align-items: center;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
  }

  .td {
    font-size: 14px;
    padding: 15px 0;
    word-break: break-word;
  }

  .number {
    padding-left: 21px;
    width: 35px;
  }

  .icons-type {
    padding-left: 10px;
    width: 20px;
  }

  .event-title {
    padding-left: 20px;
    width: 25%;
  }

  .date-time,
  .duration,
  .status {
    align-items: flex-start;
    display: flex;
    padding-left: 20px;
    width: 10%;
  }

  .status-ellipse {
    border-radius: 50%;
    height: 5px;
    margin-right: 6px;
    margin-top: 6px;
    width: 5px;
  }

  .icons-wrapper {
    display: flex;
    flex: 0.95;
    justify-content: flex-end;
    align-self: center;
  }

  .icon-wrap {
    padding: 0 15px;
  }

  .empty-search {
    padding-top: 40px;
    text-align: center;
  }

  .svg-icon {
    cursor: pointer;
  }

  .brush-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    padding-left: 20px;
  }

  .group-icon {
    cursor: default;
  }

  @media all and (max-width: 1200px) {
    .search-wrapper {
      margin-right: 0;
    }
  }
</style>
