import apiCall from '../../../utils/api/api';

import {
  ANONIM_AUTH_ERROR,
  ANONIM_AUTH_SUCESS,
  ANONIM_AUTH_REQUEST,
  CHECK_RESET_TOKEN_REQUEST,
  CHECK_RESET_TOKEN_SUCCESS,
  CHECK_RESET_TOKEN_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from '../../actions/authActions/auth';
import CONFIG from '../../../constants/config';

const state = {
  token: localStorage.getItem('bc-anon:key') || '',
  status: '',
  isLoading: false,
};

const actions = {
  [ANONIM_AUTH_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(ANONIM_AUTH_REQUEST);
    const {
      grantType,
      clientId,
      clientSecret,
    } = CONFIG.auth.anonAuth;

    const data = {
      grant_type: grantType,
      client_id: clientId,
      client_secret: clientSecret,
    };

    apiCall.post('oauth/v2/token', data)
      .then((resp) => {
        const responseData = resp.data;
        localStorage.setItem('bc-anon:key', responseData.access_token);
        localStorage.setItem('expires_in', responseData.expires_in);
        localStorage.setItem('role', responseData.scope);
        commit(ANONIM_AUTH_SUCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ANONIM_AUTH_ERROR, error);
        localStorage.clear();
        reject(error);
      });
  }),
  [CHECK_RESET_TOKEN_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    const data = {
      token: params.id,
    };

    commit(CHECK_RESET_TOKEN_REQUEST);
    apiCall.post('forget-password/check-token', data)
      .then((resp) => {
        commit(CHECK_RESET_TOKEN_SUCCESS, resp.status);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CHECK_RESET_TOKEN_ERROR, error);
        localStorage.clear();
        reject(error);
      });
  }),
  [FORGOT_PASSWORD_REQUEST]: ({ commit }, email) => new Promise((resolve, reject) => {
    commit(FORGOT_PASSWORD_REQUEST);
    apiCall.post('forget-password/request', email)
      .then((resp) => {
        commit(FORGOT_PASSWORD_SUCCESS, resp.status);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(FORGOT_PASSWORD_ERROR, error);
        reject(error);
      });
  }),
  [RESET_PASSWORD_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(RESET_PASSWORD_REQUEST);
    apiCall.post('forget-password/reset', data)
      .then((resp) => {
        commit(RESET_PASSWORD_SUCCESS, resp.status);
        resolve(resp);
        localStorage.clear();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(RESET_PASSWORD_ERROR, error);
        reject(error);
      });
  }),
};

const mutations = {
  [ANONIM_AUTH_REQUEST]: () => {
    state.status = 'loading';
    state.isLoading = true;
  },
  [ANONIM_AUTH_SUCESS]: (resp) => {
    state.status = 'success';
    state.isLoading = false;
    state.token = resp.token;
  },
  [ANONIM_AUTH_ERROR]: (err) => {
    state.isLoading = false;
    state.status = err;
  },
  [RESET_PASSWORD_REQUEST]: () => {
    state.isLoading = true;
    state.status = 'loading';
  },
  [RESET_PASSWORD_SUCCESS]: () => {
    state.isLoading = false;
    state.status = 'success';
  },
  [RESET_PASSWORD_ERROR]: (err) => {
    state.isLoading = false;
    state.status = err;
  },
  [FORGOT_PASSWORD_REQUEST]: () => {
    state.isLoading = true;
    state.status = 'loading';
  },
  [FORGOT_PASSWORD_SUCCESS]: () => {
    state.isLoading = false;
    state.status = 'success';
  },
  [FORGOT_PASSWORD_ERROR]: (err) => {
    state.isLoading = false;
    state.status = err;
  },
  [CHECK_RESET_TOKEN_REQUEST]: () => {
    state.isLoading = true;
    state.status = 'loading';
  },
  [CHECK_RESET_TOKEN_SUCCESS]: () => {
    state.isLoading = false;
    state.status = 'success';
  },
  [CHECK_RESET_TOKEN_ERROR]: (err) => {
    state.isLoading = false;
    state.status = err;
  },
};

export default {
  state,
  actions,
  mutations,
};
