<template>
  <div class="analytics-total">
    <div class="plays">
      <div>Plays</div>
      <div class="total-value">
        {{ totalInfo.views }}
      </div>
    </div>
    <div class="unique-viewers">
      <div>Unique Viewers</div>
      <div class="total-value">
        {{ totalInfo.uniques }}
      </div>
    </div>
    <div class="view-time">
      <div>Total View Time</div>
      <div class="total-value">
        {{ getFormatHourTime(totalInfo.hours) }}
      </div>
    </div>

    <DataReloader
      name="total"
      :isHide="isLoadingFailed"
      :onReload="onReloadData"
    />
  </div>
</template>

<script>
import DataReloader from './DataReloader.vue';

export default {
  name: 'AnalyticsTotalInfo',
  components: {
    DataReloader,
  },
  props: {
    totalInfo: {
      type: Object,
      default: () => {},
    },
    isLoadingFailed: {
      type: Boolean,
      default: false,
    },
    onReloadData: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    getFormatHourTime(hours) {
      if (hours) {
        const hrs = this.getInteger(hours);
        const fullMins = this.getDecimal(hours) * 60;
        const mins = this.getInteger(fullMins);
        const secs = this.getInteger(this.getDecimal(fullMins) * 60);
        if (!hrs) {
          return `${mins} min ${secs} sec`;
        }
        return (hrs === 1) ? `${hrs} hr ${mins} min` : `${hrs.toLocaleString('en')} hrs ${mins} min`;
      }
      return hours;
    },
    getInteger(value) {
      return Math.trunc(value);
    },
    getDecimal(value) {
      return (value - Math.trunc(value));
    },
  },
};
</script>
<style scoped>
  .analytics-total {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
  }

  .plays,
  .view-time,
  .unique-viewers {
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: #303030;
    color: #2591CF;
    font-size: 30px;
    font-weight: 400;
    padding: 40px 10px;
    border-radius: 4px;
    width: 370px;
    height: calc(370px * 0.5625);
  }

  .unique-viewers {
    margin: 0 20px;
  }

  .total-value {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    margin-top: 10px;
  }
</style>
