<script>
import { ValidationObserver } from 'vee-validate';
import { SET_EDIT_FORM } from '../../store/actions/common';

export default {
  name: 'BaseForm',
  components: {
    ValidationObserver,
  },
  props: {
    formPrefix: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {},
      formFields: {},
      errorMapping: {},
      submitEvent: '',
      successMessage: '',
      errorMessage: '',
      validationObserver: 'validationObserver',
    };
  },
  methods: {
    handleFormChange() {
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    getResult() {
      this.$store.dispatch(SET_EDIT_FORM, false);
      return this.getRequestData();
    },
    submit() {
      this.$store.dispatch(this.submitEvent, this.getResult())
        .then((response) => this.onSubmitSuccess(response))
        .catch((error) => this.onSubmitFail(error));
    },
    // eslint-disable-next-line no-unused-vars
    onSubmitSuccess(response) {
      this.$toasted.global.success({
        message: this.successMessage,
      });
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);
      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
        this.onSubmitFailServer();
      } else {
        this.onSubmitFailWowzaServer();
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    onSubmitFailServer() {
      return {};
    },
    onSubmitFailWowzaServer() {
      return {};
    },
    getRequestData() {
      return {};
    },
    getServerFormErrors(response) {
      const serverErrors = this.getErrorsFormResponse(response);
      const errors = {};

      Object.entries(this.errorMapping).forEach(([feField, beFields]) => {
        let feErrors = [];

        beFields.forEach((key) => {
          if (serverErrors[key] instanceof Array) {
            feErrors = feErrors.concat(serverErrors[key]);
          }
        });

        if (feErrors.length) {
          errors[feField] = feErrors;
        }
      });

      Object.entries(this.errorMapping).forEach(([feField, beFields]) => {
        beFields.forEach((key) => {
          if (key.slice(-2) === '.*') {
            const prefix = key.substring(0, key.length - 2);

            Object.entries(serverErrors).forEach(([field, value]) => {
              if (field.indexOf(prefix) === 0) {
                errors[field.replace(prefix, feField)] = value;
              }
            });
          }
        });
      });

      return this.translateServerErrors(errors);
    },
    getServerFormCollectionErrors(response) {
      const serverErrors = this.getErrorsFormCollectionResponse(response);

      return this.translateServerCollectionErrors(serverErrors);
    },
    getErrorsFormCollectionResponse(response) {
      const serverErrors = response.form_errors;
      let errors = {};

      if (serverErrors) {
        errors = this.getCollectionFieldErrors(this.formPrefix, serverErrors, errors);
      }

      return errors;
    },
    getCollectionFieldErrors(key, data, errors) {
      if (data.errors instanceof Array) {
        errors[key] = data.errors.map((value) => value.error);
      }

      if (data.children) {
        Object.entries(data.children).forEach(([childKey, childData]) => {
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(childKey)) {
            errors = this.getCollectionFieldErrors(`${key}.${childKey}`, childData, errors);
          } else if (childData.errors instanceof Array) {
            if (!errors[key]) {
              errors[key] = [];
            }

            errors[key][childKey] = childData.errors.map((value) => value.error);
          }
        });
      }

      return errors;
    },
    translateServerCollectionErrors(fieldErrors) {
      const translations = {};

      Object.entries(fieldErrors).forEach(([key, errors]) => {
        translations[key] = [];
        errors.forEach((error, index) => {
          translations[key][index] = this.translateServerError(key, error);
        });
      });

      return translations;
    },

    getErrorsFormResponse(response) {
      const serverErrors = response.form_errors;
      let errors = {};

      if (serverErrors) {
        errors = this.getFieldErrors(this.formPrefix, serverErrors, errors);
      }

      return errors;
    },
    getFieldErrors(key, data, errors) {
      if (data.errors instanceof Array) {
        errors[key] = data.errors.map((value) => value.error);
      }

      if (data.children) {
        Object.entries(data.children).forEach(([childKey, childData]) => {
          errors = this.getFieldErrors(`${key}.${childKey}`, childData, errors);
        });
      }

      return errors;
    },
    translateServerErrors(fieldErrors) {
      const translations = {};

      Object.entries(fieldErrors).forEach(([key, errors]) => {
        translations[key] = [];
        errors.forEach((error) => {
          translations[key].push(this.translateServerError(key, error));
        });
      });

      return translations;
    },
    translateServerError(field, error) {
      const formFieldRuleKey = `validation.forms.${this.formPrefix}.fields.${field}.backend.${error}`;
      const defaultFormRuleKey = `validation.forms.${this.formPrefix}.backend.${error}`;
      const defaultFormFieldRuleKey = `validation.forms.default.fields.${field}.backend.${error}`;
      const defaultRuleKey = `validation.forms.default.backend.${error}`;

      if (this.$te(formFieldRuleKey)) {
        return this.$t(formFieldRuleKey);
      }
      if (this.$te(defaultFormRuleKey)) {
        return this.$t(defaultFormRuleKey);
      }
      if (this.$te(defaultFormFieldRuleKey)) {
        return this.$t(defaultFormFieldRuleKey);
      }
      if (this.$te(defaultRuleKey)) {
        return this.$t(defaultRuleKey);
      }

      return this.$t('validation.forms.default.backend.default');
    },
  },
};
</script>
