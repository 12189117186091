<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, classes, required, ariaInput, ariaMsg }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    mode="passive"
  >
    <div
      class="group"
      :class="[classes]"
    >
      <label>
        {{ fieldLabel }}
        <span
          v-if="required"
          class="asterisk"
        >
          *
        </span>
      </label>
      <span class="select-wrapper">
        <select
          v-model="valueModel"
          :name="fieldName"
          :required="required"
          :disabled="disabled"
          :class="['main-select', className]"
          v-bind="ariaInput"
          @change="handleChange"
        >
          <option
            v-if="withEmptyField"
            value=""
          >
          </option>
          <option
            v-for="item in options"
            :key="item.code"
            :value="item.code"
            :title="item.name.length > 55 && item.name"
          >
            {{ getOptionName(item.name) }}
          </option>
        </select>
      </span>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
          v-bind="ariaMsg"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import BaseMaterialInput from './BaseMaterialInput.vue';

export default {
  name: 'MaterialSelect',
  extends: BaseMaterialInput,
  props: {
    options: {
      type: Array,
      default: null,
    },
    withEmptyField: {
      type: Boolean,
      default: true,
    },
    handleChange: {
      type: Function,
      default: () => {},
    },
    className: {
      type: String,
      default: '',
    },
  },
  methods: {
    getOptionName(name) {
      return name.length > 55 ? `${name.slice(0, 55)}...` : name;
    },
  },
};
</script>

<style scoped>
  select {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-appearance: media-slider;
    appearance: none;
    background: #383b40;
    border: 0;
    border-radius: 0;
    height: 48px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  select {
    appearance: none !important;
    -webkit-appearance: none !important;
  }

  .select-wrapper {
    position: relative;
    margin-top: 10px;
  }

  .select-wrapper::after {
    color: #71757c;
    content: '\f107';
    font-family: 'Font Awesome 5 Free';
    font-size: 25px;
    font-weight: 600;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 11px;
  }

  select:focus {
    outline: none;
  }

  .asterisk {
    color: #fb3951;
  }

  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .group.failed {
    margin-bottom: 10px;
  }

  .group.failed select {
    border-bottom: 1px solid #fb3951;
  }

  .group.failed .select-wrapper {
    border-bottom: 1px solid #fb3951;
  }

  label {
    font-size: 16px;
    color: #fff;
    font-weight: normal;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  .tree-modal-inputs {
    padding: 13px;
    padding-right: 45px;
  }

  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .asterisk {
    color: #fb3951;
  }

  .flex-select {
    max-width: 100%;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .inspector-select {
    background: #222;
  }

  .inspector-role-select {
    background: #222;
    color: #fff;
    width: 220px;
  }

  .feed-select {
    max-width: 100%;
  }

  .advanced-feed {
    max-width: 100%;
  }

  .report-select {
    appearance: none;
    background: #222;
    border: 1px solid #383b40;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    height: 100%;
    max-height: 30px;
    outline: none;
    padding: 6px;
    width: 140px;
  }

  .svg {
    cursor: pointer;
    fill: #383b40;
    position: relative;
    right: 15px;
    top: 13px;
  }

  .col-account-role {
    width: 250px;
  }

  select.inspector-input {
    background: #222;
  }
</style>
