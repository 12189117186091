<template>
  <div class="form-container">
    <Loader :isVisible="isLoading" />
    <div class="left-path">
      <span class="system">
        Collections / Series
      </span>
      <span>
        / Edit {{ series.title }}
      </span>
    </div>
    <div class="title">
      Series Editor
    </div>
    <div>
      <ValidationObserver
        ref="validationObserver"
        v-slot="{ handleSubmit }"
      >
        <form
          class="contact-info"
          novalidate="novalidate"
          @submit.prevent="handleSubmit(submit)"
          @input="handleFormChange"
        >
          <div class="edit-button-wrapper">
            <Button
              :title="'Delete'"
              :classType="'transparent'"
              :eventFunction="deleteSeries"
            />
            <Button
              :title="'Cancel'"
              :classType="'warning'"
              :eventFunction="backToSeries"
            />
            <Button
              :title="'Apply'"
              :classType="'primary'"
              :type="'submit'"
            />
          </div>
          <Tabs
            :onSelectTab="handleSelectTab">
            <Tab
              :id="'general'"
              name="General"
              :selected="mode === 'general'"
              :className="[tabErrors.general ? 'error-tab' : 'tab']"
            >
              <div
                v-if="formData.id"
                class="wrapper main-form-container"
              >
                <div class="column column-1">
                  <MetaDataForm
                    :formData="formData"
                    :formFields="formFields"
                    :type="'series'"
                    :titleImageError="titleImageError"
                    :isPosterSmallChanged="isPosterSmallChanged"
                    :isPosterLargeChanged="isPosterLargeChanged"
                    :isPosterTitleChanged="isPosterTitleChanged"
                    :isCategoryManagementAvailabe="true"
                    :isGenreManagementAvailabe="true"
                    :handleRemoveTitleImg="handleRemoveTitleImg"
                    :handleRemove="handleRemove"
                    :handleRemovePoster="handleRemovePoster"
                    :handlePosterChange="handlePosterChange"
                    :handleTitleChange="handleTitleChange"
                    :handlePosterBackChange="handlePosterBackChange"
                    :handleCategoryChange="handleCategoryChange"
                    :handleGenreChange="handleGenreChange"
                    :handleRemovePortraitImage="handleRemovePortraitImage"
                    :handleChangePortraitImage="handleChangePortraitImage"
                  />
                </div>
              </div>
            </Tab>
            <Tab
              :id="'seasons'"
              name="Seasons"
              :selected="mode === 'seasons'"
              :className="[tabErrors.seasons ? 'error-tab' : 'tab']"
            >
              <SeasonsList
                :sortOrderSeason="formData.sortOrderSeason"
                @dragged="onEndOfDraggingRows"
              />
            </Tab>
          </Tabs>
        </form>
      </validationobserver>
      <NotificationModal
        ref="modal"
        v-model="isModalOpenDelete"
        :modal="isModalOpenDelete"
        :bodyMessage="bodyMessage"
        :title="'Delete Series'"
        :warningMess="warningMess"
        :isInUseAsset="true"
        :onAccept="removeSeries"
        :onClose="onCloseDelete"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CONFIG from '../../../constants/config';
import {
  SET_EDIT_FORM,
  SET_OVERFLOW,
} from '../../../store/actions/common';
import {
  DELETE_SERIES_REQUEST,
  EDIT_SERIES_REQUEST,
  GET_SERIES_REQUEST,
} from '../../../store/actions/series/series';

import BaseForm from '../BaseForm.vue';
import Loader from '../../common/Loader.vue';
import Tabs from '../../common/Tabs/Tabs.vue';
import Tab from '../../common/Tabs/Tab.vue';
import Button from '../../common/Button.vue';
import NotificationModal from '../../NotificationModal.vue';
import MetaDataForm from '../MetaDataForm.vue';
import SeasonsList from '../../../views/seasons/SeasonsList.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';

export default {
  name: 'SeriesEditForm',
  components: {
    SeasonsList,
    MetaDataForm,
    NotificationModal,
    Tab,
    Tabs,
    Loader,
    Button,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'playlistEditForm',
    },
  },
  data() {
    return {
      warningMess: 'Deleting a Series and its Seasons will not result in deleting the Seasons\' Source Folders and their Media Items',
      titleImageError: '',
      isPosterSmallChanged: false,
      isPosterLargeChanged: false,
      isPosterTitleChanged: false,
      isPortraitImageChanged: false,
      series: {},
      mode: sessionStorage.getItem('lastOpenedSeriesTab') || 'general',
      error: null,
      enabledLanguages: [],
      isModalOpenDelete: false,
      bodyMessage: CONFIG.modals.deleteSeriesMessage,
      hasFeed: '',
      hasFolder: '',
      foldError: '',
      mediaItems: null,
      errorLiveEvents: [],
      defaultLength: 20,
      tabErrors: {
        general: false,
        seasons: false,
      },
      formData: {
        id: '',
        title: '',
        folderType: '',
        shortDescription: '',
        longDescription: '',
        image: '',
        backgroundImage: '',
        titleImg: '',
        portraitImage: '',
        sortOrderSeason: [],
        customFields: [],
        categories: [],
        genres: [],
      },
      formFields: {
        title: {
          type: 'text',
          name: 'title',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        shortDescription: {
          type: 'text',
          name: 'shortDescription',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        longDescription: {
          type: 'text',
          name: 'longDescription',
          cols: 30,
          rows: 10,
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 1800,
          },
        },
      },
      errorMapping: {
        title: [
          `${this.formPrefix}.title`,
        ],
        shortDescription: [
          `${this.formPrefix}.shortDescription`,
        ],
        longDescription: [
          `${this.formPrefix}.longDescription`,
        ],
      },
      submitEvent: EDIT_SERIES_REQUEST,
      successMessage: CONFIG.successMessages.editSeriesMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    ...mapState({
      isLoading: (state) => state.series.isLoading,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  mounted() {
    const params = {
      accountId: this.getCurrentAccountId,
      seriesId: this.$route.params.id,
    };
    this.$store.dispatch(GET_SERIES_REQUEST, params).then((res) => {
      this.series = res;
      this.initFormData(res);
    });
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  methods: {
    initFormData(metadata) {
      this.formData.id = metadata.id;
      this.formData.title = metadata.title ? metadata.title : this.folderName;
      this.formData.shortDescription = metadata.shortDescription;
      this.formData.longDescription = metadata.longDescription;
      this.formData.categories = metadata.categories;
      this.formData.genres = metadata.genres;
      this.formData.image = metadata.posterImage ? `${metadata.posterImage}?height=360` : '';
      this.formData.titleImg = metadata.titleImage ? `${metadata.titleImage}?height=360` : '';
      this.formData.backgroundImage = metadata.backgroundImage ? `${metadata.backgroundImage}?height=360` : '';
      this.formData.portraitImage = metadata.portraitImage ? `${metadata.portraitImage}?height=360` : '';
      this.formData.customFields = metadata.customFields;
      this.setCustomFields(metadata.customFields);
    },
    handleSelectTab(id) {
      sessionStorage.setItem('lastOpenedSeriesTab', id);
      if (id === 'general') {
        this.tabErrors.general = false;
      }

      if (id === 'seasons') {
        this.tabErrors.seasons = false;
      }
    },
    backToSeries() {
      this.$router.push('/collections/series');
    },
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
      this.error = null;
    },
    deleteSeries() {
      this.isModalOpenDelete = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    onCloseDelete() {
      this.isModalOpenDelete = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    removeSeries() {
      const params = {
        accountId: this.getCurrentAccountId,
        seriesId: this.$route.params.id,
      };

      this.$store.dispatch(DELETE_SERIES_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Series has been successfully deleted',
          });
          this.backToSeries();
        });
    },
    handleRemoveTitleImg() {
      this.formData.titleImg = '';
      if (this.titleImageError) {
        this.titleImageError = '';
      }
    },
    handleRemove() {
      this.formData.backgroundImage = '';
    },
    handleRemovePoster() {
      this.formData.image = '';
    },
    handlePosterChange(img) {
      this.isPosterSmallChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.image = img;
    },
    handleTitleChange(img) {
      this.isPosterTitleChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.titleImg = img;
      if (this.titleImageError) {
        this.titleImageError = '';
      }
    },
    handlePosterBackChange(img) {
      this.isPosterLargeChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.backgroundImage = img;
    },
    handleRemovePortraitImage() {
      this.formData.portraitImage = '';
    },
    handleChangePortraitImage(img) {
      this.isPortraitImageChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.portraitImage = img;
    },
    handleCategoryChange(value) {
      this.formData.categories = value;
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    handleGenreChange(value) {
      this.formData.genres = value;
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    onEndOfDraggingRows(value) {
      this.formData.sortOrderSeason = value.map((item) => item.id);
    },
    getRequestData() {
      const { formData } = this;
      const data = {
        title: formData.title,
        shortDescription: formData.shortDescription,
        longDescription: formData.longDescription,
        sortOrderSeasons: this.formData.sortOrderSeason.length ? this.formData.sortOrderSeason : [],
      };

      if (this.isPosterSmallChanged) {
        data.posterImage = this.formData.image;
        this.isPosterSmallChanged = false;
      }

      if (this.isPosterLargeChanged) {
        data.backgroundImage = this.formData.backgroundImage;
        this.isPosterLargeChanged = false;
      }

      if (this.isPosterTitleChanged) {
        data.titleImage = this.formData.titleImg;
        this.isPosterTitleChanged = false;
      }

      if (this.isPortraitImageChanged) {
        data.portraitImage = this.formData.portraitImage;
        this.isPortraitImageChanged = false;
      }

      data.fields = this.getFieldsData();
      data.categories = formData.categories.map((item) => item.id);
      data.genres = formData.genres.map((item) => item.id);

      return {
        seriesId: this.$route.params.id,
        accountId: this.getCurrentAccountId,
        data,
      };
    },
    onSubmitFail(error) {
      const titleErr = error.form_errors.children
        && error.form_errors.children.titleImage
        && error.form_errors.children.titleImage.errors;

      if (titleErr) {
        this.titleImageError = 'File MIME type should be PNG';
        return;
      }

      if (error.form_errors.children && error.form_errors.children.title) {
        this.tabErrors.general = true;
      }

      this.formErrors = this.getServerFormErrors(error);
      this.itemsErrors = this.getServerFormCollectionErrors(error);

      if (Object.entries(this.formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(this.formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.backToSeries();
      }, CONFIG.routing.redirectFromAddAcc);
    },
  },
};
</script>

<style scoped>
.left-path {
  margin-bottom: 30px;
  margin-right: 360px;
}

.system {
  color: #4c5057;
}

.form-container {
  padding: 25px 30px;
  overflow-y: auto;
  width: 100%;
}

.wrapper {
  display: flex;
}

.edit-button-wrapper {
  position: absolute;
  top: 75px;
  display: flex;
  right: 30px;
}
</style>
