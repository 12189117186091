<template>
  <div>
    <div class="row-account-role">
      <MaterialSelect
        :value="account"
        :options="accountList"
        :withEmptyField="false"
        :handleChange="handleChangeAccount"
        :className="'col-account-role'"
        v-bind="formFields.account"
      />
      <MaterialSelect
        :value="roleInAccount"
        :options="roleList"
        :withEmptyField="false"
        :handleChange="handleChangeRole"
        :className="'col-account-role'"
        v-bind="formFields.role"
      />
      <div class="trash-btn">
        <div class="icon-remove">
          <inline-svg
            :src="iconRemove"
            :class="['svg-icon', isDeleteBtnDisabled && 'disabled-delete']"
            @click="handleRemoveItem"
          >
          </inline-svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MaterialSelect from '../inputs/MaterialSelect.vue';
import iconRemove from '../../assets/icons/icon-remove-target.svg';

export default {
  name: 'AccountsAndRolesRow',
  components: {
    MaterialSelect,
  },
  props: {
    formPrefix: {
      type: String,
      default: null,
    },
    roleInAccount: {
      type: String,
      default: '',
    },
    account: {
      type: Number,
      default: null,
    },
    roleList: {
      type: Array,
      default: null,
    },
    accountList: {
      type: Array,
      default: () => [],
    },
    onRemoveItem: {
      type: Function,
      default: () => {},
    },
    onChangeRole: {
      type: Function,
      default: () => {},
    },
    onChangeAccount: {
      type: Function,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    isDeleteBtnDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconRemove,
      formFields: {
        role: {
          type: 'text',
          name: `userAccountRoles.${this.index}.role`,
          label: 'Role',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        account: {
          type: 'text',
          name: `userAccountRoles.${this.index}.account`,
          label: 'Account',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
    };
  },
  methods: {
    handleChangeRole(val) {
      const index = val.target.selectedIndex;
      this.onChangeRole(this.roleList[index].code, this.index);
    },
    handleChangeAccount(val) {
      const index = val.target.selectedIndex;
      this.onChangeAccount(this.accountList[index].code, this.index);
    },
    handleRemoveItem() {
      if (!this.isDeleteBtnDisabled) {
        this.onRemoveItem(this.index);
      }
    },
  },
};
</script>
<style scoped>
  .trash-btn {
    margin-top: 40px;
  }

  .row-account-role {
    display: flex;
    justify-content: space-between;
  }

  .disabled-delete {
    cursor: not-allowed;
  }
</style>
