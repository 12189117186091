<template>
  <div class="schedule-media-tree">
    <div class="wrap">
      <div class="system">
        <div class="system-title">
          Media
        </div>
      </div>
      <div class="nav sub-nav">
        <MediaTree
          :folderId="String(folderId)"
          :isSchedule="true"
          :onFolderChange="onFolderChange"
        />
      </div>
    </div>
    <div class="preview">
      <LiveSearch
        :onSearch="onSearch"
        :maxlength="100"
        className="auto-width"
      />
      <div
        v-if="mediaList.length"
        class="media-items"
      >
        <div
          v-for="item in mediaList"
          :key="item.id"
        >
          <div
            class="item-preview"
            draggable="true"
            @dragstart="onEventDragStart($event, item)"
          >
            <img
              class="item-img"
              height="24px"
              width="32px"
              :alt="item.name"
              :src="getPoster(item)"
            />
            <div
              :title="item.name"
              class="item-title"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div
          v-if="totalItems > numberOfDisplayedItems"
          class="show-more"
          @click="onShowMore"
        >
          Show more...
        </div>
      </div>
      <div
        v-else
        class="empty-search"
      >
        No media to display
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import MediaTree from '../media/tree/MediaTree.vue';
import { GET_FIXED_MEDIA_ITEMS } from '../../store/actions/feedsActions/feedsActions';
import { GET_MEDIA_TREE } from '../../store/actions/media/mediaTree';
import posterSelector from '../../utils/poster/selector';
import LiveSearch from '../common/LiveSearch.vue';
import Preview from '../../assets/img/Preview2.png';
import LivePreviw from '../../assets/img/livePreviw.jpg';
import CONFIG from '../../constants/config';

export default {
  name: 'ScheduleMediaTree',
  components: {
    MediaTree,
    LiveSearch,
  },
  data() {
    return {
      posterSelector,
      Preview,
      LivePreviw,
      mediaList: [],
      page: 1,
      folderId: '',
      filterValue: '',
      sortFiled: 'mi.name',
      sortBy: 'ASC',
      isLiveEvents: false,
    };
  },
  computed: {
    ...mapState({
      pagination: (state) => state.feeds.feedModalPagination,
    }),
    ...mapGetters([
      'getCurrentAccount',
      'getCurrentAccountId',
      'getRootMediaFolderId',
    ]),
    scheduleId() {
      return this.getCurrentAccount.schedule.id;
    },
    totalItems() {
      return this.pagination.total;
    },
    numberOfDisplayedItems() {
      return this.pagination.limit;
    },
    isHideChildren() {
      return this.folderId !== this.getRootMediaFolderId;
    },
  },
  created() {
    this.$store.dispatch(GET_MEDIA_TREE, this.getCurrentAccountId);
    this.folderId = sessionStorage.getItem('folderScheduleId') || this.getRootMediaFolderId;
    this.getMediaList();
  },
  methods: {
    onEventDragStart(e, draggable) {
      const newElement = { ...draggable };
      newElement.duration /= 60;
      if (newElement.type === 'livestream') {
        newElement.duration = 60;
      }
      newElement.title = newElement.name;
      newElement.isExternal = true;
      newElement.durationTime = newElement.duration;
      e.dataTransfer.setData('event', JSON.stringify(newElement));
      e.dataTransfer.setData('cursor-grab-at', e.offsetY);
    },
    onSearch(params) {
      const {
        filterValue,
        page,
      } = params;
      this.filterValue = filterValue;
      this.page = page;
      this.getMediaList();
    },
    onFolderChange(value) {
      this.folderId = value;
      sessionStorage.setItem('folderScheduleId', value);
      this.getMediaList();
    },
    onShowMore() {
      if (this.totalItems > this.numberOfDisplayedItems) {
        this.page += 1;
      }
      this.getMediaList();
    },
    getPoster(item) {
      if (item.type === 'vod') {
        if (item.status === 'transcoding' || item.status === CONFIG.transcoding.error) {
          return Preview;
        }
        return item.posterImage ? `${item.posterImage}?height=60` : Preview;
      }

      return item.posterImage ? `${item.posterImage}?height=60` : LivePreviw;
    },
    getMediaList() {
      const data = {
        accountId: this.getCurrentAccountId,
        folders: [this.folderId],
        page: this.page,
        filterValue: this.filterValue,
        sortFiled: this.sortFiled,
        sortBy: this.sortBy,
        isHideChildren: this.isHideChildren,
        isLiveEvents: this.isLiveEvents,
        types: [
          'vod',
          'livestream',
        ],
      };

      this.$store.dispatch(GET_FIXED_MEDIA_ITEMS, data)
        .then((mediaList) => {
          this.mediaList = mediaList.data;
        });
    },
  },
};
</script>
<style scoped>
  .nav {
    background: transparent;
    display: flex;
    flex-direction: column;
    margin-left: -20px;
    padding: 0;
    width: 305px;
  }

  .system {
    border-bottom: 1px solid #383b40;
    display: flex;
    justify-content: space-between;
    padding: 30px 0 25px 25px;
  }

  .system-title {
    font-size: 16px;
  }

  .schedule-media-tree {
    display: flex;
  }

  .item-preview {
    display: flex;
    margin-bottom: 10px;
  }

  .preview {
    padding: 46px 20px 20px;
    width: 240px;
  }

  .schedule-media-tree .item-title {
    font-size: 14px;
    margin-left: 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .empty-search {
    margin-top: 40px;
    text-align: center;
  }

  .wrap {
    border-right: 1px solid #383b40;
    flex-direction: column;
  }

  .media-items {
    height: calc(100vh - 240px);
    margin-top: 15px;
    overflow-y: auto;
  }

  .item-preview:hover {
    cursor: pointer;
  }

  .show-more {
    color: #2591cf;
    cursor: pointer;
    float: right;
    font-size: 14px;
  }
</style>
