<template>
  <ValidationObserver
    ref="validationObserver"
    @input="handleFormChange"
  >
    <div :class="['main-wrap', formData.isToggleRecurrence ? 'main-wrap-rec' : '']">
      <div class="btn-row">
        <div class="btn-apply">
          <Button
            :title="'Apply'"
            :classType="'primary'"
            :className="'apply-btn'"
            :eventFunction="applyInspector"
            :smallSize="true"
            :disabled="!isNewDates"
          />
        </div>
        <div class="close-btn">
          <inline-svg
            :src="iconClose"
            class="svg-icon"
            @click="handleCloseInspector"
          >
          </inline-svg>
        </div>
      </div>
      <div class="player-area">
        <div>
          <div
            v-if="mediaItem.playerSource"
            class="player"
          >
            <BitmovinPlayer
              :mediaItem="mediaItem"
              :isSchedule="true"
              :duration="mediaItem.duration"
            />
          </div>
        </div>
      </div>
      <div class="media-information">
        <MaterialInput
          v-model="formData.title"
          v-bind="formFields.title"
          :className="'inspector-input'"
        />
        <div class="date-row">
          <MaterialTime
            v-model="formData.startTime"
            v-bind="formFields.startTime"
            :label="'Start Time'"
            :format="'HH:mm:ss'"
          />
          <MaterialTime
            v-model="formData.duration"
            v-bind="formFields.duration"
            :label="'Duration'"
            :format="'HH:mm:ss'"
            :disabled="mediaItem.type === 'vod'"
          />
        </div>
        <MaterialTextArea
          v-model="formData.description"
          v-bind="formFields.description"
          :className="'inspector-input'"
        />
      </div>
      <div class="recurrence-wrapper">
        <div class="toggle-title">
          Recurrence Enabled
        </div>
        <ToggleButton
          v-model="formData.isToggleRecurrence"
          color="#2591CF"
          :sync="true"
          :labels="false"
        />
      </div>
      <div
        v-if="formData.isToggleRecurrence"
        class="recurrence-block"
      >
        <div class="recurrence-repeat">
          <div class="repeat-title">
            Repeat Frequency
          </div>
          <div class="repeat-box">
            <MaterialRadio
              :label="'Repeat Daily'"
              :value="'daily'"
              :formData="formData.repeat"
              :name="'group1'"
              :className="'radio-input-rec'"
              :onChange="onRepeatChange"
            />
            <MaterialRadio
              :label="'Repeat Weekly'"
              :value="'weekly'"
              :formData="formData.repeat"
              :name="'group1'"
              :className="'radio-input-rec'"
              :onChange="onRepeatChange"
            />
          </div>
        </div>
        <div class="recurrence-range">
          <div class="range-title">
            Range of Recurrence
          </div>
          <div class="range-wrapper">
            <div class="range-start-wrapper">
              <div class="range-start">
                Start
              </div>
              <div class="range-date">
                {{ getFormattedCurrentDate }}
              </div>
            </div>
            <div class="group-range">
              <MaterialRadio
                :label="'No End'"
                :value="'noEnd'"
                :formData="formData.range"
                :name="'group2'"
                :className="'radio-input'"
                :onChange="onRangeChange"
              />
              <div class="end-by">
                <MaterialRadio
                  :label="'End By'"
                  :value="'endBy'"
                  :formData="formData.range"
                  :name="'group2'"
                  :className="'radio-input'"
                  :onChange="onRangeChange"
                />
                <div
                  v-if="formData.range.value === 'endBy'"
                  :key="formData.finishDate"
                  class="end-by-date"
                >
                  <MaterialCalendar
                    v-model="formData.finishDate"
                    v-bind="formFields.finishDate"
                    :label="''"
                    :disabledDates="disabledDates"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="hasSortedFields()"
        class="custom-field-wrapper"
      >
        <div class="custom-field-title">
          Custom fields
        </div>
        <FieldValueRow
          v-for="item in getSortedFields()"
          :key="`fieldValue${item.field.id}`"
          v-model="item.fieldValue"
          :field="item.field"
          :fieldName="getFieldName(item.field)"
          :formPrefix="formPrefix"
          :inputClassName="'inspector-input'"
          :selectClassName="'inspector-select'"
          :calendarClassName="'inspector-calendar'"
          :datetimeClassName="'inspector-datetime'"
          :checkboxClassName="'inspector-checkbox'"
        />
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import moment from 'moment';
import { ValidationObserver } from 'vee-validate';
import BitmovinPlayer from '../common/BitmovinPlayer.vue';
import iconClose from '../../assets/icons/icon-close-inspector.svg';
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialTextArea from '../inputs/MaterialTextArea.vue';
import MaterialTime from '../inputs/MaterialTime.vue';
import BaseForm from '../forms/BaseForm.vue';
import { SET_EDIT_FORM } from '../../store/actions/common';
import Button from '../common/Button.vue';
import { IS_TIME_VALID } from '../../utils/validation/regExp';
import MaterialRadio from '../inputs/MaterialRadio.vue';
import MaterialCalendar from '../inputs/MaterialCalendar.vue';
import CustomFieldFormMixin from '../forms/customFields/CustomFieldFormMixin.vue';

export default {
  name: 'ScheduleInspector',
  components: {
    BitmovinPlayer,
    MaterialInput,
    MaterialCalendar,
    MaterialTextArea,
    MaterialTime,
    ValidationObserver,
    MaterialRadio,
    Button,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    mediaItem: {
      type: Object,
      default: null,
    },
    onInspectorClose: {
      type: Function,
      default: () => {},
    },
    onApplyInspector: {
      type: Function,
      default: () => {},
    },
    selectedElement: {
      type: Object,
      default: null,
    },
    formPrefix: {
      type: String,
      default: 'schedule',
    },
    selectedDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconClose,
      formData: {
        duration: null,
        startTime: null,
        title: '',
        description: '',
        isToggleRecurrence: false,
        finishDate: null,
        startDate: null,
        range: { value: 'noEnd', name: 'noEnd' },
        repeat: { value: 'daily', name: 'daily' },
      },
      formFields: {
        title: {
          type: 'text',
          name: 'title',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        startTime: {
          type: 'time',
          name: 'startTime',
          formPrefix: this.formPrefix,
          rules: {
            regex: IS_TIME_VALID,
            required: true,
          },
        },
        duration: {
          type: 'text',
          name: 'duration',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        description: {
          name: 'description',
          formPrefix: this.formPrefix,
          cols: 30,
          rows: 10,
          rules: {
            required: false,
            max: 500,
          },
        },
      },
      disabledDates: {},
      errorMapping: {
        title: [
          `${this.formPrefix}.name`,
        ],
        startTime: [
          `${this.formPrefix}.startDate`,
          `${this.formPrefix}.startTime`,
        ],
        finishDate: [
          `${this.formPrefix}.finishDate`,
        ],
        duration: [
          `${this.formPrefix}.duration`,
        ],
        description: [
          `${this.formPrefix}.description`,
        ],
      },
    };
  },
  computed: {
    getFormattedCurrentDate() {
      let currentDate = this.selectedDate;

      if (this.selectedElement.recurringStartDate) {
        currentDate = this.selectedElement.recurringStartDate;
      }

      return moment(currentDate).format('MMMM DD');
    },
    isNewDates() {
      return this.selectedDate >= moment().format('YYYY-MM-DD');
    },
  },
  created() {
    const {
      formData,
      selectedElement,
    } = this;
    formData.title = selectedElement.title;
    formData.description = selectedElement.description;
    formData.duration = this.convertDuration(!selectedElement.itemDuration
      ? 3600
      : selectedElement.itemDuration);
    formData.startTime = this.setMarkerAsDate(selectedElement.start);
    formData.finishDate = moment(selectedElement.finishDate).toDate();
    formData.isToggleRecurrence = selectedElement.recurring;
    if (selectedElement.recurring) {
      formData.range = (selectedElement.finishDate === null)
        ? { value: 'noEnd', name: 'noEnd' }
        : { value: 'endBy', name: 'endBy' };

      formData.repeat = selectedElement.recurringType;
      if (selectedElement.endDate !== null) {
        this.formData.finishDate = moment(selectedElement.finishDate).toDate();
      }
    }
    this.setCustomFields(selectedElement.customFields);
    this.disabledDates = {
      to: moment(this.selectedDate).toDate(),
    };
  },
  mounted() {
    const {
      isError,
      error,
    } = this.selectedElement;
    if (isError) {
      const formErrors = this.getServerFormErrors({
        form_errors: {
          children: error,
        },
      });
      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      }
    }
  },
  methods: {
    onRangeChange(e) {
      this.formData.range = {
        name: e.target.value,
        value: e.target.value,
      };

      if (e.target.value === 'noEnd') {
        this.formData.finishDate = null;
      }

      if (e.target.value === 'endBy') {
        this.formData.finishDate = moment(this.selectedElement.start).add(30, 'day').toDate();
      }
    },
    onRepeatChange(e) {
      this.formData.repeat = {
        name: e.target.value,
        value: e.target.value,
      };
    },
    applyInspector() {
      this.$refs[this.validationObserver].validate()
        .then((isValid) => {
          if (isValid) {
            const date = (this.selectedElement.start).split(' ');
            const isChangedStartTime = (
              this.selectedElement.start !== `${date[0]} ${this.formData.startTime}`);

            this.selectedElement.title = this.formData.title;
            this.selectedElement.description = this.formData.description;
            // eslint-disable-next-line operator-assignment
            this.selectedElement.duration = this.formData.duration;
            this.selectedElement.itemDuration = this.getDurationAsSec(this.formData.duration);
            this.selectedElement.start = `${date[0]} ${this.formData.startTime}`;
            this.selectedElement.end = moment(this.selectedElement.start, 'YYYY-MM-DD HH:mm:ss')
              .add(this.selectedElement.itemDuration, 'seconds')
              .format('YYYY-MM-DD HH:mm:ss');
            this.selectedElement.recurring = this.formData.isToggleRecurrence;
            if (this.formData.isToggleRecurrence) {
              this.selectedElement.recurringType = this.formData.repeat;
            }
            this.selectedElement.selectedRange = this.formData.range.value;
            this.selectedElement.endDate = this.formData.range.value === 'noEnd' ? null : moment(this.formData.finishDate).format('YYYY-MM-DD');
            this.resetValidation();
            this.onApplyInspector(this.selectedElement, isChangedStartTime);
            this.handleCloseInspector();
          }
        });
    },
    handleFormChange() {
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    pad(num) {
      return (`0${num}`).slice(-2);
    },
    convertDuration(value) {
      return moment.utc(value * 1000).format('HH:mm:ss');
    },
    handleCloseInspector() {
      this.onInspectorClose();
    },
    setMarkerAsDate(value) {
      return moment(value).format('HH:mm:ss');
    },
    resetValidation() {
      this.selectedElement.isError = false;
      this.$refs[this.validationObserver].setErrors({});
    },
    getDurationAsSec(value) {
      return moment.duration(value).asSeconds();
    },
  },
};
</script>
<style scoped>
  .btn-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .close-btn {
    margin-right: -5px;
  }

  .close-btn .svg-icon {
    cursor: pointer;
    height: 30px;
    width: 30px;
  }

  .media-information {
    margin-top: 20px;
  }

  .date-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .recurrence-wrapper {
    display: flex;
  }

  .toggle-title {
    margin-right: 20px;
  }

  .main-wrap-rec {
    height: 1350px;
  }

  .recurrence-block {
    margin-top: 10px;
  }

  .range-title {
    margin-bottom: 10px;
  }

  .repeat-title {
    margin-bottom: 10px;
  }

  .range-wrapper {
    display: flex;
  }

  .range-start,
  .repeat-box {
    margin: 0 20px;
  }

  .range-date {
    color: #2491cf;
  }

  .range-start-wrapper {
    display: flex;
    margin-right: 20px;
  }

  .end-by {
    display: flex;
  }

  .end-by-date {
    margin-top: -13px;
  }

  .custom-field-wrapper {
    margin-top: 20px;
  }

  .custom-field-title {
    font-size: 16px;
    margin-bottom: 20px;
  }
</style>
<style>
  .recurrence-block .group {
    width: 170px;
  }

  .recurrence-range .group {
    width: 120px;
  }

  .end-by .vdp-datepicker .picker {
    background-color: #222;
    margin-top: -5px;
  }

  .end-by .vdp-datepicker__calendar {
    right: 0;
  }
</style>
