var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"validator",attrs:{"name":_vm.fieldName,"vid":_vm.name,"rules":_vm.rules,"mode":_vm.mode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
var required = ref.required;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
return [_c('div',{staticClass:"group",class:classes},[_c('label',{attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.fieldLabel)+" "),((required && !_vm.hideAsterisk) || _vm.requiredFromBack)?_c('span',{staticClass:"asterisk"},[_vm._v(" * ")]):_vm._e()]),((_vm.type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueModel),expression:"valueModel"}],class:[_vm.className,
         { 'has-right-icon': _vm.allowCopy },
         _vm.blur && 'blurry-text',
         _vm.readonly && 'read'],attrs:{"id":_vm.id,"name":_vm.fieldName,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"readonly":_vm.readonly,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.valueModel)?_vm._i(_vm.valueModel,null)>-1:(_vm.valueModel)},on:{"input":_vm.onChange,"click":_vm.blurred,"change":function($event){var $$a=_vm.valueModel,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.valueModel=$$a.concat([$$v]))}else{$$i>-1&&(_vm.valueModel=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.valueModel=$$c}}}},'input',ariaInput,false)):((_vm.type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueModel),expression:"valueModel"}],class:[_vm.className,
         { 'has-right-icon': _vm.allowCopy },
         _vm.blur && 'blurry-text',
         _vm.readonly && 'read'],attrs:{"id":_vm.id,"name":_vm.fieldName,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"readonly":_vm.readonly,"type":"radio"},domProps:{"checked":_vm._q(_vm.valueModel,null)},on:{"input":_vm.onChange,"click":_vm.blurred,"change":function($event){_vm.valueModel=null}}},'input',ariaInput,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.valueModel),expression:"valueModel"}],class:[_vm.className,
         { 'has-right-icon': _vm.allowCopy },
         _vm.blur && 'blurry-text',
         _vm.readonly && 'read'],attrs:{"id":_vm.id,"name":_vm.fieldName,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"readonly":_vm.readonly,"type":_vm.type},domProps:{"value":(_vm.valueModel)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.valueModel=$event.target.value},_vm.onChange],"click":_vm.blurred}},'input',ariaInput,false)),(_vm.allowCopy)?_c('inline-svg',{staticClass:"svg-icon",attrs:{"src":_vm.iconCopy},on:{"click":_vm.onCopy}}):_vm._e(),(_vm.labelUnderField)?_c('label',{staticClass:"example-label"},[_vm._v(" "+_vm._s(_vm.labelUnderField)+" ")]):_vm._e(),_c('transition',{attrs:{"name":"bounce"}},[(errors.length)?_c('span',_vm._b({staticClass:"error-label"},'span',ariaMsg,false),[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(_vm.redLine)?_c('span',{staticClass:"red-line"}):_vm._e()])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }