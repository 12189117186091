<template>
  <div class="sort-box">
    <label
      for="select"
      class="sort-by"
    >
      {{ label }}
    </label>
    <div class="select-wrapper">
      <select
        id="select"
        v-model="valueModel"
        :name="fieldName"
        :required="required"
        :disabled="disabled"
        :class="['search-select', className]"
        @change="handleChange"
      >
        <option
          v-if="withEmptyField"
          value=""
        >
        </option>
        <option
          v-for="item in options"
          :key="item.code"
          :value="item.code"
        >
          {{ item.name }}
        </option>
      </select>
      <inline-svg
        :src="iconSortDown"
        class="svg"
      />
    </div>
  </div>
</template>

<script>
import iconSortDown from '../../assets/icons/icon-sort-arrow-down.svg';
import BaseMaterialInput from './BaseMaterialInput.vue';

export default {
  name: 'MaterialOpacitySelect',
  extends: BaseMaterialInput,
  props: {
    options: {
      type: Array,
      default: null,
    },
    withEmptyField: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    handleChange: {
      type: Function,
      default: () => {},
    },
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconSortDown,
    };
  },
};
</script>

<style scoped>
  .sort-box {
    align-items: center;
    display: flex;
  }

  .sort-by {
    font-size: 14px;
    padding-right: 5px;
  }

  .select-wrapper {
    color: #fff;
    display: flex;
    font-size: 14px;
  }

  .search-select {
    appearance: none;
    background: #222;
    border: 1px solid #383b40;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    height: 100%;
    max-height: 30px;
    outline: none;
    padding: 6px;
    width: 120px;
  }

  .sorting-wrapper {
    display: flex;
    flex-direction: column;
  }

  .select-option {
    background: #383b40;
    color: #fff;
    font-size: 1em;
  }

  .svg {
    cursor: pointer;
    fill: #383b40;
    position: relative;
    right: 15px;
    top: 13px;
  }
</style>
