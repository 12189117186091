<template>
  <div>
    <NotificationModal
      ref="modal"
      v-model="bindsForModal.isModalOpen"
      :modal="bindsForModal.isModalOpen"
      v-bind="bindsForModal"
    />

    <DeleteCategoryModal
      v-model="isConfirmationDeleteModalOpen"
      :modal="isConfirmationDeleteModalOpen"
      :onClose="handleCloseConfirmationDeleleteModal"
      :onSubmitManually="handleSubmitDelete"
      :itemToDelete="selectedItem"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  SET_OVERFLOW,
  SET_EDIT_FORM,
} from '../../store/actions/common';
import {
  GET_CATEGORY_IN_USE_STATUS_REQUEST,
  DELETE_CATEGORY_REQUEST,
} from '../../store/actions/categories/categoriesActions';
import NotificationModal from '../../components/NotificationModal.vue';
import DeleteCategoryModal from './DeleteCategoryModal.vue';
import CONFIG from '../../constants/config';

export default {
  name: 'DeleteCategoryInTwoSteps',
  components: {
    NotificationModal,
    DeleteCategoryModal,
  },
  data() {
    return {
      isConfirmationDeleteModalOpen: false,
      selectedItem: null,
      bindsForModal: {
        isModalOpen: false,
        title: '',
        bodyMessage: '',
        isInUseAsset: false,
        warningMess: '',
        onAccept: () => {},
        onClose: () => {},
      },
    };
  },
  computed: {
    ...mapState({
      categoty: (state) => state.categories.categoty,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  methods: {
    handleOpenModalForRemove(item) {
      this.selectedItem = item;
      const paramsToGetStatus = {
        accountId: this.getCurrentAccountId,
        id: this.selectedItem.id,
      };

      this.$store.dispatch(GET_CATEGORY_IN_USE_STATUS_REQUEST, paramsToGetStatus)
        .then(this.processOpenModalForRemove);
    },
    processOpenModalForRemove(response) {
      const { isInUse } = response;
      const {
        deleteCategoryTitle,
        deleteCategoryConfirmationMessage,
        categoryInUseWarningMessage,
      } = CONFIG.modals;

      if (!isInUse) {
        this.bindsForModal.title = deleteCategoryTitle;
        this.bindsForModal.bodyMessage = deleteCategoryConfirmationMessage;
        this.bindsForModal.onAccept = this.removeCategory;
        this.bindsForModal.onClose = this.handleCloseModal;
      } else {
        this.bindsForModal.title = deleteCategoryTitle;
        this.bindsForModal.bodyMessage = deleteCategoryConfirmationMessage;
        this.bindsForModal.isInUseAsset = true;
        this.bindsForModal.warningMess = categoryInUseWarningMessage;
        this.bindsForModal.onAccept = this.handleOpenConfirmationDeleleteModal;
        this.bindsForModal.onClose = this.handleCloseModal;
      }

      this.$store.dispatch(SET_OVERFLOW, true);
      this.bindsForModal.isModalOpen = true;
    },
    removeCategory() {
      const params = {
        accountId: this.getCurrentAccountId,
        categoryId: this.selectedItem.id,
      };

      this.$store.dispatch(DELETE_CATEGORY_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Category has been successfully deleted',
          });
          this.$emit('runAfterComplete');
        })
        .catch(() => {
          this.$toasted.global.error({
            message: 'The Category has not been deleted',
          });
        });
    },
    handleOpenConfirmationDeleleteModal() {
      this.isConfirmationDeleteModalOpen = true;
    },
    handleCloseConfirmationDeleleteModal() {
      this.isConfirmationDeleteModalOpen = false;
      this.handleCloseModal();
    },
    handleSubmitDelete() {
      this.isConfirmationDeleteModalOpen = false;
      this.handleCloseModal();
      this.$emit('runAfterComplete');
    },
    handleCloseModal() {
      this.bindsForModal.isModalOpen = false;
      this.$store.dispatch(SET_OVERFLOW, false);
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
  },
};
</script>
