<template>
  <div class="overlay">
    <div class="wrapper">
      <div class="wrapper-two">
        <form
          novalidate="novalidate"
          @submit.prevent="handleSubmit()"
        >
          <p
            class="headline grey lighten-2"
            primaryTitle
          >
            Embed Options
          </p>
          <div class="preview-block">
            <MaterialSelect
              v-model="formData.type"
              v-bind="formFields.type"
              :options="types"
              :withEmptyField="false"
            />
            <div
              v-if="isMediaWidget"
              class="status-checkbox"
            >
              <div class="checkbox">
                <MaterialCheckbox
                  :toggle="toggleChangeLegacyId"
                  :label="'Use Legacy ID'"
                  :checked="formData.isLegacy"
                  :isDisabled="!openMediaItem.legacyId || disabledCheckbox"
                />
              </div>
            </div>
            <div class="toggle-wrapper dynamic">
              <div class="toggle-title">
                Custom Player Color
              </div>
              <ToggleButton
                v-model="formData.hasColor"
                color="#2591CF"
                :sync="true"
                :labels="false"
              />
            </div>
            <div
              v-if="formData.hasColor"
              class="colors-wrapper"
            >
              <div
                class="color-block"
                :style="colorStyle"
              ></div>
              <div class="color-input-wrapper">
                <div class="tag-input">
                  #
                </div>
                <input
                  :value="formData.color"
                  maxlength="6"
                  type="text"
                  class="color-input"
                  @input="colorCHange"
                />
                <div
                  class="reload-btn"
                  :title="'Back to default color'"
                >
                  <Button
                    :classType="'primary vr-sm'"
                    :srcSvg="iconReload"
                    :eventFunction="reloadColor"
                    :smallSize="true"
                  />
                </div>
              </div>
            </div>
            <PublicIdForm
              :modalData="modalDataState"
              :changeWidgetId="changeWidgetId"
              :getMediaList="getMediaList"
              :getFeedsList="getFeedsList"
              :isFeed="isFeed"
              :isMediaWidget="isMediaWidget"
              :isGroupEvents="isGroupEvents"
              :getGroupEvents="getGroupEvents"
              :getEvents="getEvents"
              :isEvent="isEvent"
            />
            <MaterialTextArea
              v-model="preview"
              v-bind="formFields.preview"
              :className="'widget-area'"
            />
          </div>
          <div class="button-wrapper">
            <Button
              :title="'Close'"
              :classType="'warning'"
              :eventFunction="onClose"
            />
            <Button
              :title="'Copy'"
              :classType="'primary'"
              :eventFunction="onCopy"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MaterialTextArea from '../inputs/MaterialTextArea.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';
import Button from './Button.vue';
import MaterialCheckbox from '../inputs/MaterialCheckbox.vue';
import iconReload from '../../assets/icons/icon-reload-brushfire.svg';

import CONFIG from '../../constants/config';
import { GET_MEDIA_ITEM_REQUEST } from '../../store/actions/media/mediaItem';
import { NUMBER_INPUT_REG_EXP } from '../../utils/validation/regExp';
import PublicIdForm from '../forms/PublicIdForm.vue';

const WIDGET_TYPE_DIRECT_LINK = 'direct_link';
const WIDGET_TYPE_IFRAME = 'iframe';
const WIDGET_TYPE_EMBED_SCRIPT = 'embed_script';
const WIDGET_TYPE_DASH = 'embed_dash';
const WIDGET_TYPE_HLS = 'embed_hls';

export default {
  name: 'WidgetModal',
  components: {
    PublicIdForm,
    MaterialSelect,
    MaterialTextArea,
    Button,
    MaterialCheckbox,
  },
  props: {
    autoplay: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    handleSubmit: {
      type: Function,
      default: () => {},
    },
    getMediaList: {
      type: Function,
      default: () => {},
    },
    getFeedsList: {
      type: Function,
      default: () => {},
    },
    getGroupEvents: {
      type: Function,
      default: () => {},
    },
    getEvents: {
      type: Function,
      default: () => {},
    },
    openInspector: {
      type: Function,
      default: () => {},
    },
    formPrefix: {
      type: String,
      default: 'widgetPreview',
    },
    dash: {
      type: String,
      default: '',
    },
    hls: {
      type: String,
      default: '',
    },
    legacyId: {
      type: String,
      default: '',
    },
    isMediaWidget: {
      type: Boolean,
      default: false,
    },
    isFeed: {
      type: Boolean,
      default: false,
    },
    isGroupEvents: {
      type: Boolean,
      default: false,
    },
    isEvent: {
      type: Boolean,
      default: false,
    },
    mediaItemId: {
      type: Number,
      default: null,
    },
  },
  data() {
    const types = [
      {
        code: WIDGET_TYPE_DIRECT_LINK,
        name: 'Direct link',
      },
      {
        code: WIDGET_TYPE_IFRAME,
        name: 'iframe',
      },
      {
        code: WIDGET_TYPE_EMBED_SCRIPT,
        name: 'Embed script',
      },
    ];

    if (this.dash) {
      types.push({
        code: WIDGET_TYPE_DASH,
        name: 'DASH Manifest',
      });
    }

    if (this.hls) {
      types.push({
        code: WIDGET_TYPE_HLS,
        name: 'HLS Manifest',
      });
    }

    return {
      modalDataState: null,
      disabledCheckbox: false,
      openMediaItem: {},
      iconReload,
      widgId: '',
      formData: {
        isLegacy: false,
        type: WIDGET_TYPE_DIRECT_LINK,
        color: '2591CF',
        hasColor: false,
      },
      formFields: {
        type: {
          name: 'type',
          formPrefix: this.formPrefix,
          rules: {
            regex: NUMBER_INPUT_REG_EXP,
          },
        },
        color: {
          name: 'color',
          formPrefix: this.formPrefix,
          withEmptyField: false,
          rules: {
            regex: NUMBER_INPUT_REG_EXP,
          },
        },
        preview: {
          name: 'preview',
          formPrefix: this.formPrefix,
          disabled: true,
          cols: 30,
          rows: 10,
          allowCopy: true,
        },
      },
      types,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    preview() {
      return this.generatePreview();
    },
    colorStyle() {
      if (this.formData.color) {
        return {
          background: `#${this.formData.color}`,
        };
      }

      return {
        background: '#2591CF',
      };
    },
  },
  created() {
    this.modalDataState = this.modalData;
    this.widgId = this.modalDataState.uniqueId;
    if (this.isMediaWidget) {
      const params = {
        accountId: this.getCurrentAccountId,
        itemId: this.mediaItemId,
      };
      this.$store.dispatch(GET_MEDIA_ITEM_REQUEST, params)
        .then((res) => {
          this.openMediaItem = res;
        });
    }
  },
  methods: {
    changeWidgetId(value) {
      this.modalDataState = value;
      this.widgId = value.uniqueId;
      this.openInspector(this.openMediaItem.id);
    },
    colorCHange(e) {
      let { value } = e.target;
      value = value.replace(/[^a-zA-Z0-9]/g, '');
      this.formData.color = value;
    },
    generatePreview() {
      switch (this.formData.type) {
      case WIDGET_TYPE_DIRECT_LINK:
        return this.getDirectLink(this.widgId, true);
      case WIDGET_TYPE_IFRAME:
        return this.getIframe(this.widgId);
      case WIDGET_TYPE_EMBED_SCRIPT:
        return this.getEmbedScript(this.widgId);
      case WIDGET_TYPE_DASH:
        return this.getManifest(this.dash);
      case WIDGET_TYPE_HLS:
        return this.getManifest(this.hls);
      default:
        return '';
      }
    },
    getPlayerLocation() {
      return `${process.env.VUE_APP_PLAYER_SCHEMA}://${process.env.VUE_APP_PLAYER_HOST}`;
    },
    getDirectLink(uniqueId, direct = false) {
      this.disabledCheckbox = false;
      const url = !this.formData.isLegacy
        ? `${this.getPlayerLocation()}/${uniqueId}/default?autoplay=${this.autoplay}&debug=false&direct=${direct}`
        : `${this.getPlayerLocation()}/legacy/${this.getCurrentAccountId}/default?autoplay=${this.autoplay}&debug=false&direct=${direct}&legacyId=${this.legacyId}`;
      if (this.formData.hasColor) {
        return `${url}&color=${this.formData.color || '2591CF'}`;
      }
      return url;
    },
    getIframe(uniqueId) {
      this.disabledCheckbox = false;
      return `<iframe src="${this.getDirectLink(uniqueId)}" width="960px" height="540px" frameBorder="0" seamless="seamless" allowFullScreen allow="autoplay" scrolling="no"></iframe>`;
    },
    getEmbedScript(uniqueId) {
      this.disabledCheckbox = false;
      const scriptParts = [];

      scriptParts.push(`<link href="${this.getPlayerLocation()}/css/embed-player.css" rel="stylesheet">`);

      if (!this.formData.isLegacy) {
        scriptParts.push(`<div data-ikonik-player-type="default" data-ikonik-player-id="${uniqueId}" data-ikonik-player-autoplay="${this.autoplay}" data-ikonik-player-debug="false"  data-ikonik-player-color="${this.formData.color}"></div>`);
      } else {
        scriptParts.push(`<div data-ikonik-player-type="default" data-ikonik-player-accountId="${this.getCurrentAccountId}" data-ikonik-player-autoplay="${this.autoplay}" data-ikonik-player-debug="false" data-ikonik-player-legacyId="${this.legacyId}" data-ikonik-player-color="${this.formData.color || '2591CF'}"></div>`);
      }
      // eslint-disable-next-line no-useless-escape
      scriptParts.push(`<script type="text/javascript" src="${this.getPlayerLocation()}/js/embed-player.js"><\/script>`);

      return scriptParts.join('\r\n');
    },
    getManifest(link) {
      this.toggleChangeLegacyId(false);
      this.disabledCheckbox = true;
      return link;
    },
    toggleChangeLegacyId(isChecked) {
      if (isChecked !== this.formData.isLegacy) {
        this.formData.isLegacy = isChecked ? 1 : 0;
      }
    },
    onCopy() {
      const {
        errorMessages,
        successMessages,
      } = CONFIG;

      this.$copyText(this.generatePreview())
        .then(() => {
          this.$toasted.global.success({
            message: successMessages.copyToClipboard,
          });
        })
        .catch(() => {
          this.$toasted.global.error({
            message: errorMessages.copyToClipboard,
          });
        });
    },
    reloadColor() {
      this.formData.color = '2591CF';
    },
  },
};
</script>

<style scoped>
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .wrapper {
    background-color: #222;
    border-radius: 4px;
    display: block;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    padding: 0 10px;
    position: absolute;
    text-decoration: none;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .wrapper-two {
    padding: 30px 20px;
  }

  .headline,
  .preview-block {
    color: #fff;
  }

  .preview-block {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding-bottom: 30px;
    word-break: break-all;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }

  .status-checkbox {
    margin-bottom: 70px;
    margin-top: 30px;
    position: relative;
  }

  .checkbox {
    margin-top: 3px;
  }

  .toggle-wrapper {
    align-items: center;
    display: flex;
    margin-right: 30px;
    width: 17vw;
  }

  .toggle-title {
    margin-right: 10px;
  }

  .color-block {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-right: 6px;
  }

  .tag-input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background: #2D2F33;
    color: rgba(255, 255, 255, 0.6);
  }

  .color-input-wrapper {
    display: flex;
    width: 130px;
  }

  .color-input {
    height: 30px;
    border-radius: 4px;
  }

  .colors-wrapper {
    display: flex;
    margin-bottom: 20px;
  }

  .reload-btn {
    display: flex;
    width: 1px;
  }

  .dynamic {
    margin-bottom: 20px;
  }
</style>
