<template>
  <div>
    <span>File uploading</span>
    <div class="cont">
      <div
        v-if=" !content && !error"
        class="back-wrapper"
      >
        <div class="back">
        </div>
        <div
          class="progress"
          :style="{width: progressWidth}"
        >
        </div>
      </div>
      <div
        v-if="content && file.progress >= maxUploadPerCent"
        class="status"
      >
        The File has been successfully uploaded
      </div>
      <div
        v-if="error"
        class="status error-status"
      >
        {{ error }}
      </div>
      <button
        class="btn"
        @click="onAbort"
      >
        <inline-svg
          :src="iconRemove"
        >
        </inline-svg>
      </button>
    </div>
  </div>
</template>

<script>
import {
  ABORT_UPLOADING_ASSET_REQUEST,
  REMOVE_UPLOAD_ASSET_REQUEST,
} from '../../store/actions/UploadingActions/upload';
import iconRemove from '../../assets/icons/icon-remove-upload.svg';
import iconUploaded from '../../assets/icons/icon-file-uploaded.svg';
import CONFIG from '../../constants/config';

export default {
  name: 'ProgressAssetBar',
  props: {
    file: {
      type: Object,
      default: null,
    },
    content: {
      type: Object,
      default: null,
    },
    error: {
      type: String,
      default: '',
    },
    clearContent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconRemove,
      iconUploaded,
      maxUploadPerCent: CONFIG.upload.maxUploadPerCent,
    };
  },
  computed: {
    statusStyle() {
      const {
        failedStatus,
        cancelStatus,
        successStatus,
        uploadedColor,
        failedColor,
      } = CONFIG.upload;
      if (this.file.status === cancelStatus || this.file.status === failedStatus) {
        return {
          color: failedColor,
        };
      }
      if (this.file.status === successStatus) {
        return {
          color: uploadedColor,
        };
      }

      return null;
    },
    disabled() {
      return !this.file.progress;
    },
    status() {
      if (this.file.status === 'Canceled') {
        return 'File canceled';
      }

      if (this.file.status === 'Uploaded') {
        return 'File uploaded';
      }

      return '';
    },
    progressWidth() {
      return this.file.progress >= this.maxUploadPerCent ? `${this.maxUploadPerCent}%` : `${this.file.progress}%`;
    },
  },
  destroyed() {
    if (this.file.status === 'Uploaded' || this.file.progress >= CONFIG.upload.maxUploadPerCent) {
      this.$store.dispatch(REMOVE_UPLOAD_ASSET_REQUEST, this.file.id);
    }
  },
  methods: {
    onAbort() {
      const {
        id,
        progress,
        status,
      } = this.file;

      if (this.error && !progress && !status) {
        this.$store.dispatch(REMOVE_UPLOAD_ASSET_REQUEST, id);
      }

      if (progress < CONFIG.upload.maxUploadPerCent && !status) {
        this.$store.dispatch(ABORT_UPLOADING_ASSET_REQUEST, id);
        this.$store.dispatch(REMOVE_UPLOAD_ASSET_REQUEST, id);
      }

      if (progress >= CONFIG.upload.maxUploadPerCent) {
        this.$store.dispatch(REMOVE_UPLOAD_ASSET_REQUEST, id);
      }

      this.clearContent();
    },
  },
};
</script>

<style scoped>
.cont {
  border-radius: 5px;
  height: 6px;
  position: relative;
  margin-top: 20px;
  width: 94%;
}

.back-wrapper {
  height: 100%;
}

.back {
  background: #313438;
  border-radius: 5px;
  height: 100%;
}

.progress {
  background: #2591CF;
  border-radius: 3px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.status {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1FEE65;
}

.error-status {
  color: #FB3951;
}

.canceled {
  color: #FB3951;
}

.btn {
  position: absolute;
  right: -46px;
  top: -12px;
}
</style>
