<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div>
      <span class="system">
        Video Apps Engine
        / Pages
        / Edit {{ cmsPage && cmsPage.name }}
        / Edit {{ (rowItem && rowItem.heading) || '' }}
      </span>
      <span>
        / Row Items
      </span>
    </div>
    <div class="title manually">
      Row Items
    </div>
    <div class="back-btn">
      <Button
        :title="'Back to Page Editor'"
        :className="'small-icon go-back'"
        :eventFunction="backToPage"
        :smallSize="true"
        :srcSvg="iconBackTo"
        :hasBorder="true"
        :isBack="true"
      />
    </div>
    <div class="btns-wrapper">
      <Button
        :title="'Add Row Item'"
        :classType="'primary'"
        :eventFunction="createRowItem"
      />
      <div class="flex-between">
        <Button
          :title="'Apply'"
          :classType="'primary'"
          :eventFunction="apply"
        />
      </div>
    </div>
    <div class="accounts-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          row item label
        </div>
      </div>
      <div
        v-if="!rowItems.length"
        class="empty-search"
      >
        No Row Items to display
      </div>
      <draggable
        v-model="rowItems"
        :move="checkMove"
        @start="dragging = true"
        @end="endOfDrag"
      >
        <transition-group>
          <div
            v-for="(item) in rowItems"
            :key="item.id"
            class="account"
            @mouseover="onHoverElem(item)"
            @mouseleave="isHover = false"
          >
            <div class="td number">
              {{ item.sortOrder }}
            </div>
            <div
              class="td company"
              :title="item.label"
            >
              {{ item.label }}
            </div>
            <div
              v-show="isHover && item.id === selectedId"
              class="icons-wrapper"
            >
              <div
                v-if="!item.distribution"
                :title="'Delete Row Item'"
              >
                <inline-svg
                  :src="iconRemove"
                  class="svg-icon remove"
                  @click="onOpenModalForRemove(item)"
                >
                </inline-svg>
              </div>
              <div
                class="icon-edit"
                :title="'Edit Item Row'"
                @click="toEditManuallyItem(item)"
              >
                <inline-svg
                  :src="iconEdit"
                  class="svg-icon"
                >
                </inline-svg>
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="'Delete Row Item'"
      :onAccept="removeRow"
      :onClose="onClose"
    />
    <ManuallyRowModal
      v-model="isManuallyOpen"
      :modal="isManuallyOpen"
      :onClose="onManuallyClose"
      :onSubmitManually="onSubmitManually"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import draggable from 'vuedraggable';
import Loader from '../../components/common/Loader.vue';
import iconEnabled from '../../assets/icons/icon-enabled-acc.svg';
import iconDisabled from '../../assets/icons/icon-disabled-acc.svg';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconFix from '../../assets/icons/icon-fix.svg';
import iconManual from '../../assets/icons/icon-events.svg';
import iconBackTo from '../../assets/icons/icon-back-to.svg';
import CONFIG from '../../constants/config';
import {
  CHANGE_MANUALLY_ITEMS_ORDERS_REQUEST,
  GET_MANUALLY_LIST_REQUEST,
  GET_PAGE_REQUEST,
  GET_ROW_REQUEST,
  REMOVE_MANUALLY_ITEM_REQUEST,
  SET_SELECTED_MANUALLY_ITEM_REQUEST,
} from '../../store/actions/cmsActions/cmsActions';
import Button from '../../components/common/Button.vue';
import iconRemove from '../../assets/icons/icon-remove-role.svg';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../store/actions/common';
import NotificationModal from '../../components/NotificationModal.vue';
import ManuallyRowModal from './ManuallyRowModal.vue';

export default {
  name: 'ManuallyRows',
  components: {
    ManuallyRowModal,
    Button,
    Loader,
    NotificationModal,
    draggable,
  },
  props: {
    orderRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const {
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editCMSPage,
    } = CONFIG.tooltips;

    return {
      cmsPage: null,
      rowItem: null,
      dragging: false,
      rowItems: [],
      isManuallyOpen: false,
      iconRemove,
      iconManual,
      iconBackTo,
      paymentStatuses: CONFIG.paymentStatuses,
      enabledId: null,
      akamaiAccountCreateId: null,
      akamaiAppSyncId: null,
      isEnabled: false,
      isModalOpen: false,
      isAkamaiAppCreateModalOpen: false,
      isAkamaiAppSyncModalOpen: false,
      akamaiAppCreateBodyMessage: CONFIG.modals.akamaiAppCreateConfirmationMessage,
      akamaiAppSyncBodyMessage: CONFIG.modals.akamaiAppSyncConfirmationMessage,
      isHover: false,
      selectedId: null,
      bodyMessage: '',
      filter: '',
      sortedField: {
        name: 'name',
        industry: 'industry',
        enabled: 'enabled',
      },
      currentSortedField: 'a.name',
      currentSortBy: 'ASC',
      isSortedUp: false,
      isSortedDown: false,
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editCMSPage,
      iconEnabled,
      iconEdit,
      iconDisabled,
      iconFix,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
    ]),
    ...mapState({
      pagination: (state) => state.cms.pagination,
      isLoading: (state) => state.cms.isLoading,
      profile: (state) => state.common.profile,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
  },
  mounted() {
    sessionStorage.setItem('lastOpenedCMSTab', 'layout');
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    const paramsPage = {
      accountId: this.getCurrentAccountId,
      cmsPageId: this.$route.params.id,
    };
    this.$store.dispatch(GET_PAGE_REQUEST, paramsPage).then((res) => {
      this.cmsPage = res;
    });

    const paramsRows = {
      accountId: this.getCurrentAccountId,
      cmsRowId: this.$route.params.rowId,
    };

    this.$store.dispatch(GET_ROW_REQUEST, paramsRows)
      .then((res) => {
        this.rowItem = res;
      });

    const params = {
      accountId: this.getCurrentAccountId,
      cmsRowId: this.$route.params.rowId,
    };
    this.$store.dispatch(GET_MANUALLY_LIST_REQUEST, params)
      .then((res) => {
        this.rowItems = res.data;
      })
      .catch((err) => {
        if (err.status === 404) {
          this.$router.push(`/cms/${this.$route.params.id}`);
        }
      });
  },
  methods: {
    endOfDrag() {
      this.dragging = false;
    },
    onOpenModalForRemove(item) {
      const {
        deleteManuallyRowInCms,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.enabledId = item.id;
      this.bodyMessage = deleteManuallyRowInCms;
    },
    apply() {
      const params = {
        accountId: this.getCurrentAccountId,
        cmsRowId: this.$route.params.rowId,
        order: this.rowItems.map((item) => item.id),
      };
      this.$store.dispatch(CHANGE_MANUALLY_ITEMS_ORDERS_REQUEST, params)
        .then(() => {
          this.$store.dispatch(SET_EDIT_FORM, false);
          this.$toasted.global.success({
            message: 'Row Items order has been successfully updated',
          });
        })
        .catch((err) => {
          if (err) {
            this.$router.push(`/cms/${this.$route.params.id}`);
          }
        });
    },
    backToPage() {
      if (this.isEditedForm) {
        if (window.confirm(CONFIG.confirmationLeaveMessage)) {
          this.$router.push(`/cms/pages/${this.$route.params.id}`);
          this.$store.dispatch(SET_EDIT_FORM, false);
        }
      } else {
        this.$router.push(`/cms/pages/${this.$route.params.id}`);
      }
    },
    checkMove() {
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    toEditManuallyItem(item) {
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.$store.dispatch(SET_SELECTED_MANUALLY_ITEM_REQUEST, item);
      this.isManuallyOpen = true;
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onManuallyClose() {
      this.$store.dispatch(SET_SELECTED_MANUALLY_ITEM_REQUEST, null);
      this.isManuallyOpen = false;
    },
    onSubmitManually() {
      this.onManuallyClose();
      const params = {
        accountId: this.getCurrentAccountId,
        cmsRowId: this.$route.params.rowId,
      };
      this.$store.dispatch(GET_MANUALLY_LIST_REQUEST, params).then((res) => {
        this.rowItems = res.data;
      });
    },
    createRowItem() {
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.$store.dispatch(SET_SELECTED_MANUALLY_ITEM_REQUEST, null);
      this.isManuallyOpen = true;
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    removeRow() {
      const params = {
        accountId: this.getCurrentAccountId,
        cmsRowItemsId: this.enabledId,
      };

      this.$store.dispatch(REMOVE_MANUALLY_ITEM_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Row Item has been successfully deleted',
          });
          const params = {
            accountId: this.getCurrentAccountId,
            cmsRowId: this.$route.params.rowId,
          };
          this.$store.dispatch(GET_MANUALLY_LIST_REQUEST, params).then((res) => {
            this.rowItems = res.data;
          });
        });
    },
  },
};
</script>

<style scoped>
.flex {
  align-items: center;
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 40px;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 30px;
}

.system {
  color: #4c5057;
}

.accounts-wrapper {
  border: 1px solid #43434d;
  height: 65vh;
  overflow-y: auto;
}

.ac-wrapper-header {
  background: #282a2e;
  display: flex;
  padding: 13px 0;
}

.th-name {
  align-items: center;
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
}

.td {
  font-size: 14px;
  padding: 15px 0;
  word-break: break-word;
}

.number {
  padding-left: 21px;
  width: 50px;
}

.company {
  padding-right: 15px;
  width: 13%;
}

.users-count {
  width: 15%;
  text-transform: capitalize;
}

.status {
  align-items: flex-start;
  display: flex;
  width: 11%;
}

.date {
  align-items: flex-start;
  display: flex;
  width: 15%;
}

.account {
  display: flex;
}

.account:hover {
  background: #383b40;
  cursor: move;
}

.format {
  text-transform: capitalize;
}

.status-ellipse {
  border-radius: 50%;
  height: 5px;
  margin-right: 6px;
  margin-top: 6px;
  width: 5px;
}

.status-ellipse.enabled,
.status-ellipse.active {
  background: #1fee65;
}

.status-ellipse.disabled,
.status-ellipse.blocked,
.status-ellipse.pending {
  background: #fb3951;
}

.status-ellipse.failed {
  background: #fbf650;
}

.icons-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  flex: 0.98;
}

.icons-wrapper div {
  padding: 10px;
}

.create-button {
  background: #2591cf;
  color: #fff;
  font-size: 16px;
  letter-spacing: normal;
  padding: 0 16px;
  text-transform: none;
}

.right-path {
  align-items: center;
  display: flex;
}

.empty-search {
  padding-top: 40px;
  text-align: center;
}

.btn-create-account-mobile {
  display: none;
}

.svg-icon {
  cursor: pointer;
}

.not-access {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
}

.manually {
  padding-top: 35px;
  padding-bottom: 30px;
  margin-bottom: 0;
}

.back-btn {
  padding-bottom: 30px;
}

.btns-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.flex-between {
 display: flex;
}

.go-back {
  padding-left: 0;
  text-transform: none;
}

@media all and (max-width: 1400px) {
  .number {
    width: 30px;
  }

  .status {
    width: 9%;
  }

}

@media all and (max-width: 1170px) {
  .status {
    width: 9.5%;
  }
}

@media all and (max-width: 1100px) {
  .icons-wrapper div {
    padding: 10px 5px;
  }
}

@media all and (max-width: 1024px) {
  .btn-create-account-mobile {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    min-height: 40px;
  }

  .search-wrapper {
    margin-right: 0;
  }
}

@media all and (max-width: 900px) {
  .company {
    padding-right: 7px;
    width: 14%;
  }

  .number {
    width: 24px;
  }

  .status {
    width: 10%;
  }
}

@media all and (max-height: 940px) {
  .accounts-wrapper {
    height: 63vh;
  }
}

@media all and (max-height: 899px) {
  .accounts-wrapper {
    height: 62vh;
  }
}

@media all and (max-height: 845px) {
  .accounts-wrapper {
    height: 60vh;
  }
}

@media all and (max-height: 800px) {
  .accounts-wrapper {
    height: 59vh;
  }
}

@media all and (max-height: 770px) {
  .accounts-wrapper {
    height: 52vh;
  }
}
</style>
