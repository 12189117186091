import { render, staticRenderFns } from "./AddSeriesForm.vue?vue&type=template&id=0fff39e6&scoped=true&"
import script from "./AddSeriesForm.vue?vue&type=script&lang=js&"
export * from "./AddSeriesForm.vue?vue&type=script&lang=js&"
import style0 from "./AddSeriesForm.vue?vue&type=style&index=0&id=0fff39e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fff39e6",
  null
  
)

export default component.exports