import {
  MAIN_INFORMATION,
  MAIN_INFORMATION_SSE,
  MAIN_INFORMATION_REQUEST,
  MAIN_INFORMATION_SUCCESS,
  MAIN_INFORMATION_ERROR,
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  SET_OVERFLOW,
  SET_OVERFLOW_SUCCESS,
  SET_EDIT_FORM,
  SET_EDIT_FORM_SUCCESS,
  GET_APP_CONFIG,
  GET_APP_CONFIG_SUCCESS,
} from '../../actions/common';
import {
  GET_CONTRIBUTOR_ACCOUNTS_ADD_ERROR,
  GET_CONTRIBUTOR_ACCOUNTS_ADD_REQUEST,
  GET_CONTRIBUTOR_ACCOUNTS_ADD_SUCCESS,
  GET_CONTRIBUTOR_ACCOUNTS_EDIT_ERROR,
  GET_CONTRIBUTOR_ACCOUNTS_EDIT_REQUEST,
  GET_CONTRIBUTOR_ACCOUNTS_EDIT_SUCCESS,
} from '../../actions/accountsActions/accounts';
import apiCall from '../../../utils/api/api';
import sseCall from '../../../utils/api/sse';
import menuItems from '../../constants/menuItems';

const state = {
  isLoading: false,
  profile: {},
  role: '',
  accountsRoles: [],
  accounts: [],
  menuItems,
  isOverflow: false,
  isEditedForm: false,
  appConfig: null,
};

const getters = {
  getMenuItems: () => state.menuItems,
  getMainProfile: () => state.profile,
  getUserTimezone: () => state.profile.timezone,
  role: () => state.role,
  isSuperAdmin: () => state.role === 'ROLE_SUPER_ADMIN',
  isAdmin: () => state.role === 'ROLE_ADMIN',
  isRead: () => state.role === 'ROLE_READ_ONLY',
  isManager: () => state.role === 'ROLE_MANAGER',
  getAppConfig: () => state.appConfig,
  getCurrentAccount: () => {
    if (state.accountsRoles.length && !state.profile.lastUsedAccount) {
      return state.accountsRoles[0].account;
    }
    if (state.profile.lastUsedAccount) {
      return state.profile.lastUsedAccount;
    }
    return {};
  },
  isAccountActive: () => {
    if (getters.isSuperAdmin()) {
      return state.profile.enabled;
    }
    return getters.getCurrentAccount().enabled
      && getters.getCurrentAccount().subscription.paymentStatus === 'active';
  },
  getCurrentAccountId: () => {
    if (getters.getCurrentAccount() && !getters.isSuperAdmin()) {
      return getters.getCurrentAccount().id;
    }
    return null;
  },
  getRootMediaFolderId() {
    if (getters.getCurrentAccountId() && !getters.isSuperAdmin()) {
      return getters.getCurrentAccount().rootMediaFolder.id;
    }
    return 0;
  },
  isThemeBasic: () => state.currentAccount?.vaeConfiguration?.theme === 'church',
  isThemeME: () => state.currentAccount?.vaeConfiguration?.theme === 'media_and_entertainment',
};

const actions = {
  [MAIN_INFORMATION]: ({ commit, dispatch }) => new Promise((resolve, reject) => {
    commit(MAIN_INFORMATION_REQUEST);

    let headers;
    if (localStorage.getItem('isOtherUserMode')) {
      headers = {};
    }

    apiCall.get('user/profile', { headers })
      .then((resp) => {
        commit(MAIN_INFORMATION_SUCCESS, resp.data);
        dispatch(MAIN_INFORMATION_SSE, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  }),
  [MAIN_INFORMATION_SSE]: ({ commit }, { id }) => new Promise(() => {
    let count = 0;
    function connect() {
      count += 1;
      sseCall.getEventSource([`/user/info/${id}`])
        .then((eventSource) => {
          eventSource.reconnectInterval = 5000;
          eventSource.onmessage = (event) => {
            commit(MAIN_INFORMATION_SUCCESS, JSON.parse(event.data));
          };
          eventSource.onerror = () => {
            if (count <= 20) {
              window.setTimeout(() => {
                connect();
              }, 5000);
            } else {
              eventSource.close();
            }
          };
        });
    }

    connect();
  }),
  [GET_ACCOUNTS]: ({ commit }) => new Promise((resolve) => {
    apiCall.get('choice/account')
      .then((resp) => {
        commit(GET_ACCOUNTS_SUCCESS, resp.data);
        resolve(resp.data);
      });
  }),
  [SET_OVERFLOW]: ({ commit }, value) => new Promise(() => {
    commit(SET_OVERFLOW_SUCCESS, value);
  }),
  [SET_EDIT_FORM]: ({ commit }, value) => new Promise(() => {
    commit(SET_EDIT_FORM_SUCCESS, value);
  }),
  [GET_APP_CONFIG]: ({ commit }) => new Promise((resolve) => {
    apiCall.get('config/front-app')
      .then((resp) => {
        commit(GET_APP_CONFIG_SUCCESS, resp.data);
        resolve();
      });
  }),
  [GET_CONTRIBUTOR_ACCOUNTS_ADD_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    apiCall.get('choice/account/contributor-candidate')
      .then((resp) => {
        commit(GET_CONTRIBUTOR_ACCOUNTS_ADD_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_CONTRIBUTOR_ACCOUNTS_ADD_ERROR, error);
        reject(error);
      });
  }),
  [GET_CONTRIBUTOR_ACCOUNTS_EDIT_REQUEST]:
    ({ commit }, params) => new Promise((resolve, reject) => {
      apiCall.get(`choice/account/contributor-candidate/${params.accountId}`)
        .then((resp) => {
          commit(GET_CONTRIBUTOR_ACCOUNTS_EDIT_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_CONTRIBUTOR_ACCOUNTS_EDIT_ERROR, error);
          reject(error);
        });
    }),
};

const mutations = {
  [MAIN_INFORMATION_REQUEST]: () => {
    state.isLoading = false;
  },
  [MAIN_INFORMATION_SUCCESS]: (stateLocal, payload) => {
    state.profile = payload;
    state.accountsRoles = payload.userAccountRoles;
    state.currentAccount = payload.lastUsedAccount;

    if (state.profile.lastUsedAccount === null && state.accountsRoles.length) {
      state.role = state.accountsRoles[0].role;
    }
    if (state.profile.lastUsedAccount !== null) {
      const { id } = state.profile.lastUsedAccount;
      state.role = state.accountsRoles.find((acc) => acc.account.id === id).role;
    }

    state.isLoading = true;
  },
  [MAIN_INFORMATION_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ACCOUNTS_SUCCESS]: (stateLocal, payload) => {
    state.accounts = payload;
  },
  [SET_OVERFLOW_SUCCESS]: (stateLocal, payload) => {
    state.isOverflow = payload;
  },
  [SET_EDIT_FORM_SUCCESS]: (stateLocal, payload) => {
    state.isEditedForm = payload;
  },
  [GET_APP_CONFIG_SUCCESS]: (stateLocal, payload) => {
    state.appConfig = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
