<template>
  <div>
    <Loader :isVisible="isLoading" />
    <div
      class="add-button"
      @click="openAddModal"
    >
      <inline-svg
        :src="iconAdd"
        :class="'svg'"
      >
      </inline-svg>
      <span style="padding-left: 8px">Add Brushfire Protection</span>
    </div>
    <AddBrushfireModal
      v-model="isOpen"
      :modal="isOpen"
      :onClose="onManuallyClose"
      :onSubmitManually="onSubmitEv"
      :isEdit="isEdit"
      :isFolder="true"
      :editItem="editItem"
    />
    <div
      v-if="brushfireList && brushfireList.length"
      class="container"
    >
      <div
        v-for="item in brushfireList"
        :key="key || item.id"
        :class="['brushfire-item', item.open && 'hidden-info']"
      >
        <div
          :id="item.id"
          style="cursor: pointer"
          @click="openBrushfireInfo(item)"
        >
          <inline-svg
            :src="iconArrow"
            :class="['svg arrow', item.open && 'open-arrow']"
          >
          </inline-svg>
          <span>Brushfire Event ID: <span>{{ item.eventId }}</span></span>
        </div>
        <div
          v-show="item.open"
          class="info"
        >
          <div class="mini-title">
            Allowed Attendee Types:
          </div>
          <ul class="item-types">
            <li
              v-for="(type, index) in item.types"
              :key="index"
            >
              {{ type.name }}
            </li>
          </ul>
          <div style="margin-bottom: 15px">
            Source Information
          </div>
          <div class="url-label">
            Event Information URL:
            <span>
              <a
                class="link"
                :href="item.registrationUrl"
              >{{ item.registrationUrl }}</a>
            </span>
          </div>
          <div class="url-label">
            Event Support email: <span style="color: #FFFFFF">{{ item.contactEmail }}</span>
          </div>
          <div class="mini-title-qr">
            Event Information QR-Code:
          </div>
          <img
            :src="item.registrationQrCodeUrl"
            alt="qr"
          />
        </div>
        <div :class="['icons-wrapper', item.open && 'hidden-icons']">
          <div :title="'Edit Brushfire Event'">
            <inline-svg
              :src="iconEdit"
              class="svg-icon edit"
              @click="onOpenModalForEdit(item)"
            >
            </inline-svg>
          </div>
          <div :title="'Remove Brushfire Event'">
            <inline-svg
              :src="iconRemove"
              class="svg-icon remove"
              @click="onOpenModalForRemove(item)"
            >
            </inline-svg>
          </div>
        </div>
      </div>
    </div>
    <NotificationModal
      ref="modal"
      v-model="isOpenDelete"
      :modal="isOpenDelete"
      :title="'Delete Brushfire Protection'"
      :bodyMessage="bodyMessage"
      :onAccept="removeBrushfire"
      :onClose="onClose"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import iconAdd from '../../assets/icons/icon-add-brushfire.svg';
import AddBrushfireModal from './AddBrushFireModal.vue';
import Loader from '../common/Loader.vue';
import {
  DELETE_BRUSHFIRE_REQUEST, GET_BRUSHFIRE_EVENT_TYPES_REQUEST,
  GET_BRUSHFIRE_FOLDER_LIST_REQUEST,
} from '../../store/actions/brushfire/brushfire';

import iconRemove from '../../assets/icons/icon-remove-role.svg';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconArrow from '../../assets/icons/icon-arrow-brushfire-lisst.svg';
import NotificationModal from '../NotificationModal.vue';
import CONFIG from '../../constants/config';
import { SET_OVERFLOW } from '../../store/actions/common';

export default {
  name: 'FolderProtection',
  components: {
    AddBrushfireModal,
    Loader,
    NotificationModal,
  },
  data() {
    return {
      iconAdd,
      iconRemove,
      iconEdit,
      iconArrow,
      isOpen: false,
      isEdit: false,
      editItem: null,
      isOpenDelete: false,
      enabledId: null,
      isOpenInfo: false,
      selectedId: null,
      info: [],
      brushfireEventTypes: [],
      key: 0,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    ...mapState({
      isLoading: (state) => state.brushfire.isLoading,
      brushfireList: (state) => state.brushfire.brushfireList.map((brush) => ({
        ...brush,
        open: false,
      })),
      pagination: (state) => state.brushfire.pagination,
    }),
  },
  mounted() {
    const params = {
      accountId: this.getCurrentAccountId,
      folderId: this.$route.params.folderId,
    };
    this.$store.dispatch(GET_BRUSHFIRE_FOLDER_LIST_REQUEST, params);
  },
  methods: {
    openAddModal() {
      this.isOpen = true;
    },
    onManuallyClose() {
      this.isOpen = false;
      this.isEdit = false;
    },
    onSubmitEv() {
      this.isOpen = false;
      this.isEdit = false;
      const params = {
        accountId: this.getCurrentAccountId,
        folderId: this.$route.params.folderId,
      };
      this.$store.dispatch(GET_BRUSHFIRE_FOLDER_LIST_REQUEST, params);
    },
    onOpenModalForEdit(item) {
      this.isEdit = true;
      this.editItem = item;
      this.isOpen = true;
    },
    onClose() {
      this.isOpenDelete = false;
    },
    onOpenModalForRemove(item) {
      const {
        deleteBrushfireMessage,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isOpenDelete = true;
      this.enabledId = item.id;
      this.bodyMessage = deleteBrushfireMessage;
    },
    removeBrushfire() {
      const params = {
        accountId: this.getCurrentAccountId,
        brushfireEventId: this.enabledId,
      };

      this.$store.dispatch(DELETE_BRUSHFIRE_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Brushfire Event has been successfully deleted',
          });

          const params = {
            accountId: this.getCurrentAccountId,
            folderId: this.$route.params.folderId,
          };
          this.$store.dispatch(GET_BRUSHFIRE_FOLDER_LIST_REQUEST, params);
        });
    },
    openBrushfireInfo(item) {
      if (!item.open) {
        const params = {
          brushfireEventId: item.eventId,
        };

        this.$store.dispatch(GET_BRUSHFIRE_EVENT_TYPES_REQUEST, params).then((res) => {
          this.brushfireEventTypes = res.data.data;

          // eslint-disable-next-line array-callback-return
          this.brushfireList.map((brush) => {
            if (brush.id === item.id) {
              brush.types = brush.types.map((e) => {
                const temp = this.brushfireEventTypes.find((element) => element.id === e);
                if (temp) {
                  return {
                    id: temp.id,
                    name: temp.name,
                  };
                }
                return e;
              });
              brush.open = !brush.open;
              this.key += 1;
            }
          });
        });
      } else {
        // eslint-disable-next-line array-callback-return
        this.brushfireList.map((brush) => {
          if (brush.id === item.id) {
            brush.open = !brush.open;
            this.key += 1;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.add-button {
  display: flex;
  cursor: pointer;
  color: #2591CF;
  margin-bottom: 40px;
}

.brushfire-item {
  position: relative;
  padding-bottom: 30px;
  padding-top: 20px;
}

.icons-wrapper {
  display: flex;
  position: absolute;
  top: 17px;
  left: 270px;
}

.svg-icon {
  cursor: pointer;
}

.edit {
  margin-right: 20px;
}

.arrow {
  margin-right: 14px;
  cursor: pointer;
}

.open-arrow {
  transform: rotate(90deg);
}

.hidden-info {
  background: #1C1C1C;
  margin-left: -20px;
  padding-left: 20px;
  margin-right: -20px;
}

.hidden-icons {
  padding-left: 20px;
}

.item-types {
  list-style-type: disc;
  font-size: 14px;
  padding-left: 25px;
}

.mini-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding-top: 20px;
}

.info {
  padding-left: 20px;
}

.url-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin-bottom: 10px;
}

.link {
  text-decoration: none;
}

.mini-title-qr {
  color: rgba(255, 255, 255, 0.6);
  padding-bottom: 14px;
}
</style>
