<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="edit-button-wrapper">
        <Button
          v-if="isEdit"
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="onOpenModal"
        />
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backTo"
        />
        <Button
          :title="isEdit ? 'Apply' : 'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="breadcrumbs">
        <span class="system">
          System Management / Roles
        </span>
        <span v-if="!isEdit">
          / Add Role
        </span>
        <span
          v-else
        >
          / Edit {{ formData.title }}
        </span>
      </div>
      <div class="title">
        {{ isEdit ? 'Custom Role' : 'Add Role' }}
      </div>
      <MaterialInput
        v-model="formData.title"
        v-bind="formFields.title"
      />
      <MaterialTextArea
        v-model="formData.description"
        v-bind="formFields.description"
      />
      <div class="status-checkbox">
        <span>
          Status
        </span>
        <div class="checkbox">
          <MaterialCheckbox
            :toggle="toggleChangeStatusRole"
            :label="'Enabled'"
            :isShortLabel="true"
            :checked="formData.enabled"
            :error="enabledError"
          />
        </div>
      </div>
      <NotificationModal
        ref="modal"
        v-model="isModalOpen"
        :modal="isModalOpen"
        :bodyMessage="bodyMessage"
        :title="'Delete Custom Role'"
        :onAccept="removeRole"
        :onClose="onClose"
      />
      <ErrorModal
        v-model="isModalErrorOpen"
        :modal="isModalErrorOpen"
        :bodyErrorMessage="bodyErrorMessage"
        :onClose="onModalErrorClose"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ADD_CUSTOM_ROLE_REQUEST,
  DELETE_CUSTOM_ROLE_REQUEST,
  EDIT_CUSTOM_ROLE_REQUEST,
  GET_ROLE_REQUEST,
} from '../../store/actions/roles/rolesActions';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../store/actions/common';
import CONFIG from '../../constants/config';

import BaseForm from './BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import Button from '../common/Button.vue';
import MaterialTextArea from '../inputs/MaterialTextArea.vue';
import MaterialCheckbox from '../inputs/MaterialCheckbox.vue';
import NotificationModal from '../NotificationModal.vue';
import ErrorModal from '../ErrorModal.vue';

export default {
  name: 'PersonForm',
  components: {
    MaterialTextArea,
    MaterialInput,
    MaterialCheckbox,
    Button,
    NotificationModal,
    ErrorModal,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'customRole',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHasId: true,
      enabledError: '',
      isModalOpen: false,
      isModalErrorOpen: false,
      bodyMessage: CONFIG.modals.deleteCustomRoleMessage,
      bodyErrorMessage: '',
      formData: {
        title: '',
        description: '',
        enabled: true,
      },
      formFields: {
        title: {
          type: 'text',
          name: 'title',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 50,
          },
        },
        description: {
          type: 'text',
          name: 'description',
          formPrefix: this.formPrefix,
          cols: 30,
          rows: 10,
          rules: {
            required: false,
            max: 255,
          },
        },
      },
      errorMapping: {
        title: [
          `${this.formPrefix}.name`,
        ],
        description: [
          `${this.formPrefix}.description`,
        ],
        enabled: [
          `${this.formPrefix}.enabled`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_CUSTOM_ROLE_REQUEST : ADD_CUSTOM_ROLE_REQUEST,
      successMessage: this.isEdit
        ? CONFIG.successMessages.editCustomRoleMessage
        : CONFIG.successMessages.addCustomRoleMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  mounted() {
    if (this.isEdit && this.$route.params.id) {
      const {
        errorMessages,
        statuses,
      } = CONFIG;

      const params = {
        accountId: this.getCurrentAccountId,
        roleId: this.$route.params.id,
      };

      this.$store.dispatch(GET_ROLE_REQUEST, params)
        .then((res) => this.initFormData(res))
        .catch((err) => {
          if (err.error === errorMessages.unknown) {
            this.isHasId = false;
            this.$router.push(`/${statuses.notFound}`);
          }
        });
    }
  },
  methods: {
    initFormData(role) {
      this.formData.title = role.name;
      this.formData.description = role.description;
      this.formData.enabled = role.enabled;
    },

    toggleChangeStatusRole(isChecked) {
      if (isChecked !== this.formData.enabled) {
        this.formData.enabled = isChecked ? 1 : 0;
        this.enabledError = '';
      }
    },
    getRequestData() {
      const data = {
        name: this.formData.title,
        description: this.formData.description,
        enabled: !!this.formData.enabled,
      };

      if (!this.isEdit) {
        data.account = this.accountId;
      }

      return {
        data,
        accountId: this.getCurrentAccountId,
        roleId: this.$route.params.id,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.$router.push('/settings/roles');
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);
      this.enabledError = formErrors.enabled
        && formErrors.enabled[0]
        && CONFIG.modals.errorDisabledRole;

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    backTo() {
      this.$router.push('/settings/roles');
    },
    onOpenModal() {
      this.isModalOpen = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onErrorModalOpened() {
      this.isModalErrorOpen = true;
      this.bodyErrorMessage = CONFIG.modals.errorDisabledRole;
    },
    onModalErrorClose() {
      this.isModalErrorOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    removeRole() {
      const params = {
        accountId: this.getCurrentAccountId,
        roleId: this.$route.params.id,
      };

      this.$store.dispatch(DELETE_CUSTOM_ROLE_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: CONFIG.successMessages.removedRoleMessage,
          });
          setTimeout(() => {
            this.backTo();
          }, CONFIG.routing.redirectFromAddAcc);
        })
        .catch((err) => {
          const {
            errorMessages,
            statuses,
          } = CONFIG;
          if (err.code === statuses.failedStatus) {
            this.$toasted.global.error({
              message: errorMessages.commonServerError,
            });
          }

          if (err.error === 'ITEM_HAS_DEPENDENCIES') {
            this.onErrorModalOpened();
          }
        });
    },
  },
};
</script>

<style scoped>
  .status-checkbox {
    margin-bottom: 30px;
    width: 90px;
  }

  .checkbox {
    margin-top: 3px;
  }

  .edit-button-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 26px;
  }

  .breadcrumbs {
    margin-bottom: 25px;
  }

  .system {
    color: #4c5057;
  }
</style>
