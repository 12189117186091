<template>
  <div class="overlay">
    <ValidationObserver
      ref="validationObserver"
    >
      <div class="wrapper modal-window">
        <p class="headline grey lighten-2">
          {{ isReplace ? 'Replace Media' : 'Media upload' }}
        </p>
        <div class="preview-block">
          <div>
            <Tabs
              :smallStyle="true"
              :onSelectTab="handleSelectTab"
            >
              <Tab
                :id="'file'"
                name="File Upload"
                :selected="true"
              >
                <div
                  ref="fileform"
                  class="file-drag-drop"
                  :class="isError ? 'error-form' : ''"
                  :title="'Add Media'"
                  @click="onFileInput"
                >
                  <form class="file-form">
                    <div class="drag-drop-zone">
                      <inline-svg
                        :src="iconUpload"
                        class="svg"
                      />
                      <span class="drop-files">
                        Drag & Drop
                      </span>
                      <span class="drop-files">
                        or click to <span class="browser-link">browse</span>
                      </span>
                    </div>
                  </form>
                  <input
                    ref="fileInput"
                    type="file"
                    class="input-hidden"
                    :multiple="!isReplace"
                    accept="video/*,.mkv,audio/*"
                    @change="fileInputChange"
                  />
                </div>
              </Tab>
              <Tab
                :id="'url'"
                name="URL"
                :selected="false"
              >
                <div class="input-wrapper">
                  <MaterialInput
                    v-model="formData.uploadUrl"
                    v-bind="formFields.uploadUrl"
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
        <div
          v-if="isError"
          class="preview-block"
        >
          <span class="error-label">
            {{ validationMessage }}
          </span>
        </div>
        <div
          v-if="isUrlTab"
          class="button-wrapper is-url-tab"
        >
          <Button
            :title="'Close'"
            :classType="'warning'"
            :eventFunction="onClose"
          />
          <Button
            :title="isReplace? 'Continue' : 'Add'"
            :classType="'primary'"
            :className="'apply-btn'"
            :eventFunction="isReplace ? replaceUrl : editMedia"
          />
        </div>
        <div
          v-else
          class="button-wrapper"
        >
          <Button
            :title="'Close'"
            :classType="'warning'"
            :eventFunction="onClose"
          />
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Button from './Button.vue';
import iconUpload from '../../assets/icons/icon-upload.svg';
import Tabs from './Tabs/Tabs.vue';
import Tab from './Tabs/Tab.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import {
  SEND_TRANSCODE_URL_REQUEST,
} from '../../store/actions/UploadingActions/upload';

import CONFIG from '../../constants/config';
import { REPLACE_URL_REQUEST } from '../../store/actions/replaceActions/replace';

const {
  failedUpload,
} = CONFIG.errorMessages.upload;

export default {
  name: 'DragAndDropMediaUploaderModal',
  components: {
    ValidationObserver,
    MaterialInput,
    Button,
    Tabs,
    Tab,
  },
  props: {
    onFileUpload: {
      type: Function,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    folderId: {
      type: String,
      default: '',
    },
    getMediaList: {
      type: Function,
      default: () => {},
    },
    isReplace: {
      type: Boolean,
      default: false,
    },
    mediaItemId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dragAndDropCapable: false,
      isError: false,
      validationMessage: '',
      isUrlTab: false,
      iconUpload,
      formPrefix: '',
      formFields: {
        uploadUrl: {
          type: 'text',
          name: 'Input Source URL',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 4096,
            is_valid_url: true,
          },
        },
      },
      formData: {
        uploadUrl: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccount',
      'getCurrentAccountId',
      'getRootMediaFolderId',
    ]),
  },
  beforeDestroy() {
    ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
      this.$refs.fileform.removeEventListener(evt, this.preventDefault, false);
    });
    this.$refs.fileform.removeEventListener('drop', this.dropHandle);
  },
  mounted() {
    this.dragInit();
  },
  methods: {
    handleSelectTab(id) {
      this.isUrlTab = id === 'url';
    },
    editMedia() {
      this.$refs.validationObserver.validate()
        .then((isValid) => {
          if (isValid) {
            const params = {
              accountId: this.getCurrentAccountId,
              data: {
                url: this.formData.uploadUrl,
                mediaFolder: Number(this.folderId) === this.getRootMediaFolderId
                  ? null
                  : Number(this.folderId),
              },
            };
            this.$store.dispatch(SEND_TRANSCODE_URL_REQUEST, params)
              .then(() => {
                this.onClose();
                this.$toasted.global.success({
                  message: 'The new source file URL has been successfully added to the system',
                });
                this.getMediaList(localStorage.getItem('currentPage-media-list') || 1);
              })
              .catch(() => {
                this.$toasted.global.error({
                  message: failedUpload,
                });
              });
          }
        });
    },
    replaceUrl() {
      this.$refs.validationObserver.validate()
        .then((isValid) => {
          if (isValid) {
            const params = {
              accountId: this.getCurrentAccountId,
              mediaId: this.mediaItemId,
              url: this.formData.uploadUrl,
            };
            this.$store.dispatch(REPLACE_URL_REQUEST, params)
              .then(() => {
                this.onClose();
                this.$toasted.global.success({
                  message: 'File has been successfully replaced',
                });
                this.getMediaList(localStorage.getItem('currentPage-media-list') || 1);
              })
              .catch(() => {
                this.$toasted.global.error({
                  message: failedUpload,
                });
              });
          }
        });
    },
    dragInit() {
      this.dragAndDropCapable = this.determineDragAndDropCapable();
      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
          this.$refs.fileform.addEventListener(evt, this.preventDefault, false);
        });
        this.$refs.fileform.addEventListener('drop', this.dropHandle);
      }
    },
    preventDefault(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    dropHandle(e) {
      const { files } = e.dataTransfer;
      if (files.length) {
        this.onFileUpload(files);
      }
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div');
      return (('draggable' in div)
        || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window
        && 'FileReader' in window;
    },
    fileInputChange() {
      const { files } = this.$refs.fileInput;
      if (files.length) {
        this.onFileUpload(files);
      }
    },
    onFileInput() {
      this.$refs.fileInput.click();
    },
  },
};
</script>
<style scoped>
  .input-wrapper {
    margin-bottom: 70px;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .wrapper {
    background-color: #222;
    border-radius: 4px;
    display: block;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    padding: 30px;
    position: absolute;
    text-decoration: none;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .headline,
  .preview-block {
    color: #fff;
  }

  .is-url-tab {
    bottom: 30px;
    position: absolute;
    right: 30px;
  }

  .preview-block {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375rem;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    word-break: break-all;
    padding-bottom: 15px;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
  }

  .file-drag-drop {
    border: 1px dashed #a0a0a0;
    cursor: pointer;
    height: 230px;
    width: 100%;
  }

  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
  }

  .error-form {
    border-color: #fb3951;
  }

  .drag-drop-zone {
    /*cursor: pointer;*/
    /*padding: 40px 0;*/
    text-align: center;
  }

  .drag-drop-zone .drop-files {
    display: block;
  }

  .drag-drop-zone .drop-files .browser-link {
    color: #2591cf;
  }

  .file-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .drop-files {
    color: #a0a0a0;
  }

  .input-hidden {
    display: none;
  }
</style>
