<template>
  <div>
    <div
      :class="['date-wrapper',
               isAddLive && 'no-space',
               isInspector && 'date-wrapper-inspector']"
    >
      <div :class="[isAddLive && 'margin', isInspector && 'inspector-width']">
        <MaterialCalendar2
          v-model="availabilityExpiration.avDate"
          v-bind="formFields.avDate"
          :className="[!isAddLive && 'inspector-calendar']"
          :clearable="true"
        />
      </div>
      <div :class="['time-block', isInspector && 'inspector-width']">
        <MaterialTime
          v-model="availabilityExpiration.avTime"
          v-bind="formFields.avTime"
          :blockClassName="[!isAddLive && 'inspector-datetime no-picker']"
          :isManualInput="false"
          :onChangeTime="onChangeTime"
          :disabled="!availabilityExpiration.avDate"
          :closeOnComplete="true"
          :required="availabilityExpiration.avDate"
        />
      </div>
    </div>
    <div
      :class="['date-wrapper',
               isAddLive && 'no-space',
               isInspector && 'date-wrapper-inspector']"
    >
      <div :class="[isAddLive && 'margin', isInspector && 'inspector-width']">
        <MaterialCalendar2
          v-model="availabilityExpiration.expDate"
          v-bind="formFields.expDate"
          :className="[!isAddLive && 'inspector-calendar']"
          :clearable="true"
        />
      </div>
      <div :class="['time-block', isInspector && 'inspector-width']">
        <MaterialTime
          v-model="availabilityExpiration.expTime"
          v-bind="formFields.expTime"
          :blockClassName="[!isAddLive && 'inspector-datetime no-picker']"
          :isManualInput="false"
          :onChangeTime="onChangeTimeExp"
          :disabled="!availabilityExpiration.expDate"
          :required="availabilityExpiration.expDate"
          :closeOnComplete="true"
        />
      </div>
    </div>
    <div :class="[isAddLive && 'state-block']">
      <MaterialInput
        v-model="state"
        v-bind="formFields.avState"
        :className="[!isAddLive && 'inspector-input']"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import MaterialCalendar2 from '../../inputs/MaterialCalendar2.vue';
import MaterialTime from '../../inputs/MaterialTime.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';

export default {
  name: 'Availability',
  components: {
    MaterialInput,
    MaterialCalendar2,
    MaterialTime,
  },
  props: {
    formPrefix: {
      type: String,
      default: 'availability',
    },
    availabilityExpiration: {
      type: Object,
      default: () => {},
    },
    mediaItem: {
      type: Object,
      default: () => {},
    },
    isAddLive: {
      type: Boolean,
      default: false,
    },
    isInspector: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parseTimeForBE: '',
      formData: {
        avState: '',
      },
      formFields: {
        avDate: {
          type: 'date',
          valueType: 'YYYY-MM-DD',
          name: 'avDate',
          format: 'DD MMM YYYY',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        avTime: {
          type: 'time',
          name: 'avTime',
          format: 'hh:mm A',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        expDate: {
          type: 'date',
          valueType: 'YYYY-MM-DD',
          name: 'expDate',
          format: 'DD MMM YYYY',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        expTime: {
          type: 'time',
          name: 'expTime',
          format: 'hh:mm A',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        avState: {
          type: 'text',
          name: 'Availability State',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
      },
    };
  },
  computed: {
    state() {
      if (this.mediaItem
        && this.mediaItem.expirationAt
        && moment(this.mediaItem.expirationAt) < moment()
      ) {
        return 'Expired';
      }

      if (this.mediaItem
        && ((this.mediaItem.availableFrom && moment(this.mediaItem.availableFrom) > moment())
        || (this.mediaItem.availableFrom && this.mediaItem.expirationAt
        && moment(this.mediaItem.availableFrom) > moment()
        && moment(this.mediaItem.expirationAt) > moment()))
      ) {
        return 'Planned';
      }

      return 'Available';
    },
  },
  mounted() {
  },
  methods: {
    onChangeTime(t) {
      if (!t.data.HH || !t.data.mm) {
        this.availabilityExpiration.avTime = '';
      } else {
        this.availabilityExpiration.avTime = t.data;
      }
    },
    onChangeTimeExp(t) {
      if (!t.data.HH || !t.data.mm) {
        this.availabilityExpiration.expTime = '';
      } else {
        this.availabilityExpiration.expTime = t.data;
      }
    },
    onCloseTimepicker() {
      if (!this.availabilityExpiration.avTime.data) {
        this.availabilityExpiration.avTime = '12:00 AM';
      } else {
        const { A, hh, mm } = this.availabilityExpiration.avTime.data;
        if (!A) {
          this.availabilityExpiration.avTime.data.A = 'AM';
        }
        if (!hh) {
          this.availabilityExpiration.avTime.data.hh = '12';
          this.availabilityExpiration.avTime.data.HH = '00';
        }
        if (!mm) {
          this.availabilityExpiration.avTime.data.mm = '00';
        }
        this.availabilityExpiration.avTime = `${this.availabilityExpiration.avTime.data.hh}:${this.availabilityExpiration.avTime.data.mm} ${this.availabilityExpiration.avTime.data.A}`;
      }
    },
    onCloseTimepickerExp() {
      if (!this.availabilityExpiration.expTime.data) {
        this.availabilityExpiration.expTime = '12:00 AM';
      } else {
        const { A, hh, mm } = this.availabilityExpiration.expTime.data;
        if (!A) {
          this.availabilityExpiration.expTime.data.A = 'AM';
        }
        if (!hh) {
          this.availabilityExpiration.expTime.data.hh = '12';
          this.availabilityExpiration.expTime.data.HH = '00';
        }
        if (!mm) {
          this.availabilityExpiration.expTime.data.mm = '00';
        }
        this.availabilityExpiration.expTime = `${this.availabilityExpiration.expTime.data.hh}:${this.availabilityExpiration.expTime.data.mm} ${this.availabilityExpiration.expTime.data.A}`;
      }
    },
  },
};
</script>

<style scoped>
.date-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 700px;
}

.date-wrapper-inspector {
  width: auto;
}

.margin {
  margin-right: 30px;
  width: 100%;
}

.no-space {
  justify-content: normal;
}

.state-block {
  width: 100%;
  max-width: 700px;
}

.time-block {
  width: 100%;
}

.inspector-width {
  width: 220px;
}
</style>
