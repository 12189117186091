<template>
  <div class="form-container">
    <MaterialInput
      v-model="feed.feedName"
      v-bind="feed.feedName"
      :label="'Feed Title'"
      :disabled="true"
    />
    <MaterialTextArea
      v-model="feed.feedDescription"
      v-bind="feed.feedDescription"
      :label="'Description'"
      :disabled="true"
    />
    <div class="poster-title">
      Poster Image
    </div>
    <PosterImage
      ref="posterImage"
      :poster="feed.poster ? `${feed.poster}?height=360` : ''"
      :isNeedRemove="false"
      :disabled="true"
      :disabledText="'No Image to Display'"
      :shouldHideTitle="true"
    />
  </div>
</template>

<script>
import MaterialInput from '../../inputs/MaterialInput.vue';
import MaterialTextArea from '../../inputs/MaterialTextArea.vue';
import PosterImage from '../../common/PosterImage/DragAndDropFileUploader.vue';

export default {
  name: 'FeedEngForm',
  components: {
    MaterialInput,
    MaterialTextArea,
    PosterImage,
  },
  props: {
    feed: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss">
.form-container {
  padding: 32px 70px 20px 0;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}
</style>
