export const GET_SUBTITLE_ASSET_REQUEST = 'GET_SUBTITLE_ASSET_REQUEST';
export const GET_SUBTITLE_ASSET_SUCCESS = 'GET_SUBTITLE_ASSET_SUCCESS';
export const GET_SUBTITLE_ASSET_ERROR = 'GET_SUBTITLE_ASSET_ERROR';

export const ADD_SUBTITLE_ASSET_REQUEST = 'ADD_SUBTITLE_ASSET_REQUEST';
export const ADD_SUBTITLE_ASSET_SUCCESS = 'ADD_SUBTITLE_ASSET_SUCCESS';
export const ADD_SUBTITLE_ASSET_ERROR = 'ADD_SUBTITLE_ASSET_ERROR';

export const EDIT_SUBTITLE_ASSET_REQUEST = 'EDIT_SUBTITLE_ASSET_REQUEST';
export const EDIT_SUBTITLE_ASSET_SUCCESS = 'EDIT_SUBTITLE_ASSET_SUCCESS';
export const EDIT_SUBTITLE_ASSET_ERROR = 'EDIT_SUBTITLE_ASSET_ERROR';

export const REPLACE_SUBTITLE_ASSET_REQUEST = 'REPLACE_SUBTITLE_ASSET_REQUEST';
export const REPLACE_SUBTITLE_ASSET_SUCCESS = 'REPLACE_SUBTITLE_ASSET_SUCCESS';
export const REPLACE_SUBTITLE_ASSET_ERROR = 'REPLACE_SUBTITLE_ASSET_ERROR';

export const DELETE_SUBTITLE_ASSET_REQUEST = 'DELETE_SUBTITLE_ASSET_REQUEST';
export const DELETE_SUBTITLE_ASSET_SUCCESS = 'DELETE_SUBTITLE_ASSET_SUCCESS';
export const DELETE_SUBTITLE_ASSET_ERROR = 'DELETE_SUBTITLE_ASSET_ERROR';

export const GET_ASSET_LANGUAGE_REQUEST = 'GET_ASSET_LANGUAGE_REQUEST';
export const GET_ASSET_LANGUAGE_SUCCESS = 'GET_ASSET_LANGUAGE_SUCCESS';
export const GET_ASSET_LANGUAGE_ERROR = 'GET_ASSET_LANGUAGE_ERROR';

export const ADD_SUBTITLE_SSE = 'ADD_SUBTITLE_SSE';
export const ADD_SUBTITLE_SSE_SUCCESS = 'ADD_SUBTITLE_SSE_SUCCESS';

export const ADD_ALT_AUDIO_URL_ASSET_REQUEST = 'ADD_ALT_AUDIO_URL_ASSET_REQUEST';
export const ADD_ALT_AUDIO_URL_ASSET_SUCCESS = 'ADD_ALT_AUDIO_URL_ASSET_SUCCESS';
export const ADD_ALT_AUDIO_URL_ASSET_ERROR = 'ADD_ALT_AUDIO_URL_ASSET_ERROR';

export const ADD_ALT_AUDIO_ASSET_REQUEST = 'ADD_ALT_AUDIO_ASSET_REQUEST';
export const ADD_ALT_AUDIO_ASSET_SUCCESS = 'ADD_ALT_AUDIO_ASSET_SUCCESS';
export const ADD_ALT_AUDIO_ASSET_ERROR = 'ADD_ALT_AUDIO_ASSET_ERROR';

export const EDIT_ALT_AUDIO_ASSET_REQUEST = 'EDIT_ALT_AUDIO_ASSET_REQUEST';
export const EDIT_ALT_AUDIO_ASSET_SUCCESS = 'EDIT_ALT_AUDIO_ASSET_SUCCESS';
export const EDIT_ALT_AUDIO_ASSET_ERROR = 'EDIT_ALT_AUDIO_ASSET_ERROR';

export const DELETE_ALT_AUDIO_ASSET_REQUEST = 'DELETE_ALT_AUDIO_ASSET_REQUEST';
export const DELETE_ALT_AUDIO_ASSET_SUCCESS = 'DELETE_ALT_AUDIO_ASSET_SUCCESS';
export const DELETE_ALT_AUDIO_ASSET_ERROR = 'DELETE_ALT_AUDIO_ASSET_ERROR';

export const GET_PROCESSING_SSE_REQUEST = 'GET_PROCESSING_SSE_REQUEST';
export const GET_PROCESSING_SSE_SUCCESS = 'GET_PROCESSING_SSE_SUCCESS';
export const GET_PROCESSING_SSE_ERROR = 'GET_PROCESSING_SSE_ERROR';

export const GET_PROCESSING_SSE_CLOSED = 'GET_PROCESSING_SSE_CLOSED';

export const ADD_SUPP_AUDIO_URL_ASSET_REQUEST = 'ADD_SUPP_AUDIO_URL_ASSET_REQUEST';
export const ADD_SUPP_AUDIO_URL_ASSET_SUCCESS = 'ADD_SUPP_AUDIO_URL_ASSET_SUCCESS';
export const ADD_SUPP_AUDIO_URL_ASSET_ERROR = 'ADD_SUPP_AUDIO_URL_ASSET_ERROR';

export const ADD_SUPP_AUDIO_ASSET_REQUEST = 'ADD_SUPP_AUDIO_ASSET_REQUEST';
export const ADD_SUPP_AUDIO_ASSET_SUCCESS = 'ADD_SUPP_AUDIO_ASSET_SUCCESS';
export const ADD_SUPP_AUDIO_ASSET_ERROR = 'ADD_SUPP_AUDIO_ASSET_ERROR';

export const EDIT_SUPP_AUDIO_ASSET_REQUEST = 'EDIT_SUPP_AUDIO_ASSET_REQUEST';
export const EDIT_SUPP_AUDIO_ASSET_SUCCESS = 'EDIT_SUPP_AUDIO_ASSET_SUCCESS';
export const EDIT_SUPP_AUDIO_ASSET_ERROR = 'EDIT_SUPP_AUDIO_ASSET_ERROR';

export const DELETE_SUPP_AUDIO_ASSET_REQUEST = 'DELETE_SUPP_AUDIO_ASSET_REQUEST';
export const DELETE_SUPP_AUDIO_ASSET_SUCCESS = 'DELETE_SUPP_AUDIO_ASSET_SUCCESS';
export const DELETE_SUPP_AUDIO_ASSET_ERROR = 'DELETE_SUPP_AUDIO_ASSET_ERROR';

export const ADD_SUPP_VIDEO_URL_ASSET_REQUEST = 'ADD_SUPP_VIDEO_URL_ASSET_REQUEST';
export const ADD_SUPP_VIDEO_URL_ASSET_SUCCESS = 'ADD_SUPP_VIDEO_URL_ASSET_SUCCESS';
export const ADD_SUPP_VIDEO_URL_ASSET_ERROR = 'ADD_SUPP_VIDEO_URL_ASSET_ERROR';

export const ADD_SUPP_VIDEO_ASSET_REQUEST = 'ADD_SUPP_VIDEO_ASSET_REQUEST';
export const ADD_SUPP_VIDEO_ASSET_SUCCESS = 'ADD_SUPP_VIDEO_ASSET_SUCCESS';
export const ADD_SUPP_VIDEO_ASSET_ERROR = 'ADD_SUPP_VIDEO_ASSET_ERROR';

export const EDIT_SUPP_VIDEO_ASSET_REQUEST = 'EDIT_SUPP_VIDEO_ASSET_REQUEST';
export const EDIT_SUPP_VIDEO_ASSET_SUCCESS = 'EDIT_SUPP_VIDEO_ASSET_SUCCESS';
export const EDIT_SUPP_VIDEO_ASSET_ERROR = 'EDIT_SUPP_VIDEO_ASSET_ERROR';

export const DELETE_SUPP_VIDEO_ASSET_REQUEST = 'DELETE_SUPP_VIDEO_ASSET_REQUEST';
export const DELETE_SUPP_VIDEO_ASSET_SUCCESS = 'DELETE_SUPP_VIDEO_ASSET_SUCCESS';
export const DELETE_SUPP_VIDEO_ASSET_ERROR = 'DELETE_SUPP_VIDEO_ASSET_ERROR';

export const GET_ASSET_ROLES_OPTIONS_REQUEST = 'GET_ASSET_ROLES_OPTIONS_REQUEST';
export const GET_ASSET_ROLES_OPTIONS_SUCCESS = 'GET_ASSET_ROLES_OPTIONS_SUCCESS';
export const GET_ASSET_ROLES_OPTIONS_ERROR = 'GET_ASSET_ROLES_OPTIONS_ERROR';

export const ADD_DOCUMENT_ASSET_REQUEST = 'ADD_DOCUMENT_ASSET_REQUEST';
export const ADD_DOCUMENT_ASSET_SUCCESS = 'ADD_DOCUMENT_ASSET_SUCCESS';
export const ADD_DOCUMENT_ASSET_ERROR = 'ADD_DOCUMENT_ASSET_ERROR';

export const EDIT_DOCUMENT_ASSET_REQUEST = 'EDIT_DOCUMENT_ASSET_REQUEST';
export const EDIT_DOCUMENT_ASSET_SUCCESS = 'EDIT_DOCUMENT_ASSET_SUCCESS';
export const EDIT_DOCUMENT_ASSET_ERROR = 'EDIT_DOCUMENT_ASSET_ERROR';

export const DELETE_DOCUMENT_ASSET_REQUEST = 'DELETE_DOCUMENT_ASSET_REQUEST';
export const DELETE_DOCUMENT_ASSET_SUCCESS = 'DELETE_DOCUMENT_ASSET_SUCCESS';
export const DELETE_DOCUMENT_ASSET_ERROR = 'DELETE_DOCUMENT_ASSET_ERROR';

export const ADD_OTHER_ASSET_REQUEST = 'ADD_OTHER_ASSET_REQUEST';
export const ADD_OTHER_ASSET_SUCCESS = 'ADD_OTHER_ASSET_SUCCESS';
export const ADD_OTHER_ASSET_ERROR = 'ADD_OTHER_ASSET_ERROR';

export const EDIT_OTHER_ASSET_REQUEST = 'EDIT_OTHER_ASSET_REQUEST';
export const EDIT_OTHER_ASSET_SUCCESS = 'EDIT_OTHER_ASSET_SUCCESS';
export const EDIT_OTHER_ASSET_ERROR = 'EDIT_OTHER_ASSET_ERROR';

export const DELETE_OTHER_ASSET_REQUEST = 'DELETE_OTHER_ASSET_REQUEST';
export const DELETE_OTHER_ASSET_SUCCESS = 'DELETE_OTHER_ASSET_SUCCESS';
export const DELETE_OTHER_ASSET_ERROR = 'DELETE_OTHER_ASSET_ERROR';

export const ADD_LINKED_FILE_ASSET_REQUEST = 'ADD_LINKED_FILE_ASSET_REQUEST';
export const ADD_LINKED_FILE_ASSET_SUCCESS = 'ADD_LINKED_FILE_ASSET_SUCCESS';
export const ADD_LINKED_FILE_ASSET_ERROR = 'ADD_LINKED_FILE_ASSET_ERROR';

export const EDIT_LINKED_FILE_ASSET_REQUEST = 'EDIT_LINKED_FILE_ASSET_REQUEST';
export const EDIT_LINKED_FILE_ASSET_SUCCESS = 'EDIT_LINKED_FILE_ASSET_SUCCESS';
export const EDIT_LINKED_FILE_ASSET_ERROR = 'EDIT_LINKED_FILE_ASSET_ERROR';

export const DELETE_LINKED_FILE_ASSET_REQUEST = 'DELETE_LINKED_FILE_ASSET_REQUEST';
export const DELETE_LINKED_FILE_ASSET_SUCCESS = 'DELETE_LINKED_FILE_ASSET_SUCCESS';
export const DELETE_LINKED_FILE_ASSET_ERROR = 'DELETE_LINKED_FILE_ASSET_ERROR';

export const ADD_IMAGE_ASSET_REQUEST = 'ADD_IMAGE_ASSET_REQUEST';
export const ADD_IMAGE_ASSET_SUCCESS = 'ADD_IMAGE_ASSET_SUCCESS';
export const ADD_IMAGE_ASSET_ERROR = 'ADD_IMAGE_ASSET_ERROR';

export const EDIT_IMAGE_ASSET_REQUEST = 'EDIT_IMAGE_ASSET_REQUEST';
export const EDIT_IMAGE_ASSET_SUCCESS = 'EDIT_IMAGE_ASSET_SUCCESS';
export const EDIT_IMAGE_ASSET_ERROR = 'EDIT_IMAGE_ASSET_ERROR';

export const DELETE_IMAGE_ASSET_REQUEST = 'DELETE_IMAGE_ASSET_REQUEST';
export const DELETE_IMAGE_ASSET_SUCCESS = 'DELETE_IMAGE_ASSET_SUCCESS';
export const DELETE_IMAGE_ASSET_ERROR = 'DELETE_IMAGE_ASSET_ERROR';

export const EDIT_MEZZ_IMAGE_ASSET_REQUEST = 'EDIT_MEZZ_IMAGE_ASSET_REQUEST';
export const EDIT_MEZZ_IMAGE_ASSET_SUCCESS = 'EDIT_MEZZ_IMAGE_ASSET_SUCCESS';
export const EDIT_MEZZ_IMAGE_ASSET_ERROR = 'EDIT_MEZZ_IMAGE_ASSET_ERROR';

export const EDIT_DEFAULT_SUPP_REQUEST = 'EDIT_DEFAULT_SUPP_REQUEST';
export const EDIT_DEFAULT_SUPP_SUCCESS = 'EDIT_DEFAULT_SUPP_SUCCESS';
export const EDIT_DEFAULT_SUPP_ERROR = 'EDIT_DEFAULT_SUPP_ERROR';
