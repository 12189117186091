// see http://www.webvtt.org/
// https://quuz.org/webvtt/

const convertSrtCue = (caption) => {
  let cue = '';
  const s = caption.split(/\n/);

  // concatenate multi-line string separated in array into one
  while (s.length > 3) {
    for (let i = 3; i < s.length; i += 1) {
      s[2] = `${s[2]}\n${s[i]}`;
    }
    s.splice(3, s.length - 3);
  }

  let line = 0;

  // detect identifier
  if (!s[0].match(/\d+:\d+:\d+/) && s[1].match(/\d+:\d+:\d+/)) {
    cue = `${cue}${s[0].match(/\w+/)}\n`;
    line += 1;
  }

  // get time strings
  if (s[line].match(/\d+:\d+:\d+/)) {
    // convert time string
    const m = s[1].match(/(\d+):(\d+):(\d+)(?:,(\d+))?\s*--?>\s*(\d+):(\d+):(\d+)(?:,(\d+))?/);
    if (m) {
      cue = `${cue}${m[1]}:${m[2]}:${m[3]}.${m[4]} --> ${m[5]}:${m[6]}:${m[7]}.${m[8]}\n`;
      line += 1;
    } else {
      // Unrecognized time-string
      return '';
    }
  } else {
    // file format error or comment lines
    return '';
  }

  // get cue text
  if (s[line]) {
    cue = `${cue}${s[line]}\n\n`;
  }

  return cue;
};

const convert = (data) => {
  // remove dos newlines
  let srt = data.replace(/\r+/g, '');
  // trim white space start and end
  srt = srt.replace(/^\s+|\s+$/g, '');

  // get cues
  const cueList = srt.split('\n\n');
  let result = '';

  if (cueList.length > 0) {
    result = `${result}WEBVTT\n\n`;
    for (let i = 0; i < cueList.length; i += 1) {
      result = `${result}${convertSrtCue(cueList[i])}`;
    }
  }

  return result;
};

export default { convert };
