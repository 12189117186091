import apiCall from '../../../utils/api/api';
import {
  ADD_API_KEY_ERROR,
  ADD_API_KEY_REQUEST,
  ADD_API_KEY_SUCCESS,
  DELETE_API_KEY_ERROR,
  DELETE_API_KEY_REQUEST,
  DELETE_API_KEY_SUCCESS,
  EDIT_API_KEY_ERROR,
  EDIT_API_KEY_REQUEST,
  EDIT_API_KEY_SUCCESS,
  GET_API_KEY_ERROR,
  GET_API_KEY_REQUEST,
  GET_API_KEY_SUCCESS,
  GET_API_KEYS_ADMIN_ERROR,
  GET_API_KEYS_ADMIN_REQUEST,
  GET_API_KEYS_ADMIN_SUCCESS,
  GET_API_KEYS_ERROR,
  GET_API_KEYS_REQUEST,
  GET_API_KEYS_SUCCESS,
  GET_ENABLED_ACCS_ERROR,
  GET_ENABLED_ACCS_REQUEST,
  GET_ENABLED_ACCS_SUCCESS,
} from '../../actions/api/api_keys';

const state = {
  status: '',
  states: [],
  subscriptionTypes: [],
  subscriptionPlans: [],
  apiKeys: [],
  pagination: {},
  isLoading: false,
  source: null,
  adminAPIKeys: [],
  createdAPIKey: {},
};

const actions = {
  [ADD_API_KEY_REQUEST]: ({ commit }, { data }) => new Promise((resolve, reject) => {
    commit(ADD_API_KEY_REQUEST);

    apiCall.post('api-key', data)
      .then((resp) => {
        commit(ADD_API_KEY_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_API_KEY_ERROR, error);
        reject(error);
      });
  }),
  [GET_API_KEYS_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    let url = `api-key?p=${params.page}&l=15&filterField=ak.name&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      if (params.sortFiled === 'ak.usedAt') {
        params.sortFiled = 'usedAt';
      }

      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_API_KEYS_SUCCESS, resp.data);
        localStorage.setItem('apiKeys', resp.data.pagination.page);
        resolve();
      })
      .catch(() => {
        commit(GET_API_KEYS_ERROR);
      });
  }),
  [GET_API_KEYS_ADMIN_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    commit(GET_API_KEYS_ADMIN_REQUEST);
    const url = `${params.accountId}/api-key?filterField=ak.name&s=usedAt&d=ASC`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_API_KEYS_ADMIN_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_API_KEYS_ADMIN_ERROR);
      });
  }),
  [GET_API_KEY_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_API_KEY_REQUEST);

    apiCall.get(`api-key/${params.id}`)
      .then((resp) => {
        commit(GET_API_KEY_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_API_KEY_ERROR, error);
        reject(error);
      });
  }),
  [GET_ENABLED_ACCS_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_ENABLED_ACCS_REQUEST);

    apiCall.get('choice/account/enabled')
      .then((resp) => {
        commit(GET_ENABLED_ACCS_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_ENABLED_ACCS_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_API_KEY_REQUEST]: ({ commit }, { id, data }) => new Promise((resolve, reject) => {
    commit(EDIT_API_KEY_REQUEST);

    apiCall.put(`api-key/${id}`, data)
      .then((resp) => {
        commit(EDIT_API_KEY_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_API_KEY_ERROR, error);
        reject(error);
      });
  }),
  [DELETE_API_KEY_REQUEST]:
    ({ commit }, { apiId, name }) => new Promise((resolve, reject) => {
      commit(DELETE_API_KEY_REQUEST);

      const url = `api-key/${apiId}?name=${name}`;

      apiCall.delete(url)
        .then(() => {
          commit(DELETE_API_KEY_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(DELETE_API_KEY_ERROR, error);
          reject(error);
        });
    }),
};

const mutations = {
  [ADD_API_KEY_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_API_KEY_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.createdAPIKey = payload.data;
  },
  [ADD_API_KEY_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_API_KEY_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_API_KEY_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_API_KEY_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_API_KEY_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_API_KEY_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_API_KEY_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_API_KEY_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_API_KEY_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_API_KEY_ERROR]: () => {
    state.isLoading = false;
  },
  // eslint-disable-next-line no-shadow
  [GET_API_KEYS_SUCCESS]: (state, payload) => {
    state.apiKeys = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  [GET_API_KEYS_ADMIN_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_API_KEYS_ADMIN_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.adminAPIKeys = payload.data;
  },
  [GET_API_KEYS_ADMIN_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
