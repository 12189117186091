<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      v-if="isReady"
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="edit-button-wrapper">
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backTo"
        />
        <Button
          :title="isEdit ? 'Apply' : 'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="breadcrumbs">
        <span class="system">
          System Management / People
        </span>
        <span v-if="!isEdit">
          / Add Person
        </span>
        <span
          v-else
        >
          / Edit {{ formData.firstName }} {{ formData.lastName }}
        </span>
      </div>
      <div class="title">
        {{ isEdit ? 'Person' : 'Add Person' }}
      </div>
      <div>
        <MaterialInput
          v-model="formData.firstName"
          v-bind="formFields.firstName"
        />
      </div>
      <div>
        <MaterialInput
          v-model="formData.lastName"
          v-bind="formFields.lastName"
        />
      </div>
      <div class="flex">
        <div class="country-field">
          <MaterialInput
            v-model="formData.preNominal"
            v-bind="formFields.preNominal"
          />
        </div>
        <div class="country-field">
          <MaterialInput
            v-model="formData.postNominal"
            v-bind="formFields.postNominal"
          />
        </div>
      </div>
      <div>
        <MaterialInput
          v-model="formData.shortBio"
          v-bind="formFields.shortBio"
        />
      </div>
      <div>
        <MaterialTextArea
          v-model="formData.longBio"
          v-bind="formFields.longBio"
        />
      </div>
      <div
        v-if="isEdit"
        class="person"
      >
        <p>ID</p>
        <p>{{ person.uniqueId }}</p>
      </div>
      <div class="wrapper-small">
        <div class="poster-title">
          Profile Image Small
        </div>
        <PosterImage
          ref="posterImage"
          :poster="formData.posterSmall"
          :onPosterChange="handlePosterSmallChange"
          :typeOfPerson="'small'"
          :handleRemove="handleRemove"
          :imageType="smallImg"
          :isHDPoster="true"
        />
      </div>
      <div class="wrapper-small">
        <div class="poster-title">
          Profile Image Large
        </div>
        <PosterImage
          ref="posterImage"
          :poster="formData.posterLarge"
          :onPosterChange="handlePosterLargeChange"
          :typeOfPerson="'large'"
          :handleRemove="handleRemove"
          :imageType="largeImg"
          :isHDPoster="true"
        />
      </div>
      <div
        v-if="hasSortedFields()"
        class="wrapper-small"
      >
        <div class="poster-title">
          Custom fields
        </div>
        <FieldValueRow
          v-for="item in getSortedFields()"
          :key="`fieldValue${item.field.id}`"
          v-model="item.fieldValue"
          :field="item.field"
          :fieldName="getFieldName(item.field)"
          :formPrefix="formPrefix"
        />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  ADD_PERSON_REQUEST,
  DESTROY_PERSON_REQUEST,
  EDIT_PERSON_REQUEST,
  GET_PERSON_REQUEST,
} from '../../store/actions/peopleActions/people';
import { SET_EDIT_FORM } from '../../store/actions/common';
import CONFIG from '../../constants/config';

import BaseForm from './BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import Button from '../common/Button.vue';
import MaterialTextArea from '../inputs/MaterialTextArea.vue';
import PosterImage from '../common/PosterImage/DragAndDropFileUploader.vue';
import CustomFieldFormMixin from './customFields/CustomFieldFormMixin.vue';

export default {
  name: 'PersonForm',
  components: {
    MaterialTextArea,
    MaterialInput,
    Button,
    PosterImage,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'person',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isReady: false,
      isHasId: true,
      isPosterSmallChanged: false,
      isPosterLargeChanged: false,
      images: {
        small: null,
        large: null,
      },
      smallImg: 'small profile image',
      largeImg: 'large profile image',
      formData: {
        firstName: '',
        lastName: '',
        preNominal: '',
        postNominal: '',
        shortBio: '',
        longBio: '',
        posterSmall: '',
        posterLarge: 'poster',
      },
      formFields: {
        firstName: {
          type: 'text',
          name: 'firstName',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 100,
          },
        },
        lastName: {
          type: 'text',
          name: 'lastName',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 100,
          },
        },
        preNominal: {
          type: 'text',
          name: 'preNominal',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 100,
          },
        },
        postNominal: {
          type: 'text',
          name: 'postNominal',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 100,
          },
        },
        shortBio: {
          type: 'text',
          name: 'shortBio',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 100,
          },
        },
        longBio: {
          type: 'text',
          name: 'longBio',
          formPrefix: this.formPrefix,
          cols: 30,
          rows: 10,
          rules: {
            required: false,
            max: 500,
          },
        },
      },
      errorMapping: {
        firstName: [
          `${this.formPrefix}.firstName`,
        ],
        lastName: [
          `${this.formPrefix}.lastName`,
        ],
        preNominal: [
          `${this.formPrefix}.preNominal`,
        ],
        postNominal: [
          `${this.formPrefix}.postNominal`,
        ],
        shortBio: [
          `${this.formPrefix}.shortBio`,
        ],
        longBio: [
          `${this.formPrefix}.longBio`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_PERSON_REQUEST : ADD_PERSON_REQUEST,
      successMessage: this.isEdit
        ? CONFIG.successMessages.editPersonMessage
        : CONFIG.successMessages.addPersonMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      person: (state) => state.people.person,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  mounted() {
    const {
      errorMessages,
      statuses,
    } = CONFIG;

    const params = {
      accountId: this.getCurrentAccountId,
      personId: this.$route.params.id,
    };

    this.$store.dispatch(GET_PERSON_REQUEST, params)
      .then((res) => this.initFormData(res))
      .catch((err) => {
        if (err.error === errorMessages.unknown) {
          this.isHasId = false;
          this.isReady = true;
          this.$router.push(`/${statuses.notFound}`);
        }
      });
  },
  destroyed() {
    this.$store.dispatch(DESTROY_PERSON_REQUEST);
  },
  methods: {
    getFieldName(field) {
      return `fields.${field.id}.value`;
    },
    initFormData(person) {
      this.formData.firstName = person.firstName;
      this.formData.lastName = person.lastName;
      this.formData.preNominal = person.preNominal;
      this.formData.postNominal = person.postNominal;
      this.formData.shortBio = person.shortBio;
      this.formData.longBio = person.longBio;
      this.formData.posterSmall = person.profileImageSmall ? `${person.profileImageSmall}?height=360` : '';
      this.formData.posterLarge = person.profileImageLarge ? `${person.profileImageLarge}?height=360` : '';
      this.setCustomFields(person.customFields);
      this.isReady = true;
    },
    getPosterImage(posters) {
      return posters && posters.length && posters[0] && posters[0].url ? posters[0].url : '';
    },
    handlePosterSmallChange(img) {
      this.isPosterSmallChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.posterSmall = img;
    },
    handlePosterLargeChange(img) {
      this.isPosterLargeChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.posterLarge = img;
    },
    handleRemove(flag) {
      if (flag) {
        this.formData.posterSmall = '';
      } else {
        this.formData.posterLarge = '';
      }
    },
    getRequestData() {
      const data = {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        preNominal: this.formData.preNominal,
        postNominal: this.formData.postNominal,
        shortBio: this.formData.shortBio,
        longBio: this.formData.longBio,
        fields: [],
      };

      if (this.isEdit) {
        if (this.isPosterSmallChanged) {
          data.profileImageSmall = this.formData.posterSmall;
          this.isPosterSmallChanged = false;
        }

        if (this.isPosterLargeChanged) {
          data.profileImageLarge = this.formData.posterLarge;
          this.isPosterLargeChanged = false;
        }
      } else {
        data.profileImageSmall = this.formData.posterSmall;
        data.profileImageLarge = this.formData.posterLarge !== 'poster' ? this.formData.posterLarge : '';
      }

      data.fields = this.getFieldsData();

      return {
        data,
        accountId: this.getCurrentAccountId,
        personId: this.$route.params.id,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.$router.push('/settings/people');
      }, CONFIG.routing.redirectFromAddAcc);
    },
    backTo() {
      this.$router.push('/settings/people');
    },
  },
};
</script>

<style scoped>
  .country-field {
    width: 300px;
  }

  .country-field:not(:last-child) {
    margin-right: 20px;
  }

  .wowza p:last-child {
    background: #383b40;
    margin-top: 10px;
    padding: 15px;
  }

  .poster-title {
    color: #fff;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .wrapper-small {
    margin-bottom: 20px;
  }

  .flex {
    justify-content: space-between;
  }

  .person {
    margin-bottom: 20px;
  }

  .person p:last-child {
    background: #383b40;
    margin-top: 10px;
    padding: 15px;
  }

  .edit-button-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 26px;
  }

  .breadcrumbs {
    margin-bottom: 25px;
  }

  .system {
    color: #4c5057;
  }

  @media all and (max-width: 1320px) {
    .flex {
      flex-wrap: wrap;
    }

    .country-field {
      margin-right: 0;
      width: 100%;
    }

    .country-field:not(:last-child) {
      margin-right: 0;
    }
  }
</style>
