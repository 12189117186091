import apiCall from '../../../utils/api/api';
import {
  EDIT_WIDGET_ERROR,
  EDIT_WIDGET_REQUEST,
  EDIT_WIDGET_SUCCESS,
  GET_WIDGET,
  GET_WIDGET_ERROR,
  GET_WIDGET_REQUEST,
  GET_WIDGET_SUCCESS,
} from '../../actions/widgets/widget';

const state = {
  isLoading: false,
};

const actions = {
  [GET_WIDGET]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(GET_WIDGET_REQUEST);

    apiCall.post(`${accountId}/widget`, data)
      .then((resp) => {
        commit(GET_WIDGET_SUCCESS, resp.data);
        resolve(resp.data);
      }).catch((err) => {
        const error = err.response.data;
        commit(GET_WIDGET_ERROR);
        reject(error);
      });
  }),
  [EDIT_WIDGET_REQUEST]:
    ({ commit }, { accountId, widgetId, data }) => new Promise((resolve, reject) => {
      commit(EDIT_WIDGET_REQUEST);

      apiCall.put(`${accountId}/widget/${widgetId}`, data)
        .then((resp) => {
          commit(EDIT_WIDGET_SUCCESS, resp.data);
          resolve(resp.data);
        }).catch((err) => {
          const error = err.response.data;
          commit(EDIT_WIDGET_ERROR);
          reject(error);
        });
    }),
};

const mutations = {
  [GET_WIDGET_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_WIDGET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_WIDGET_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_WIDGET_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_WIDGET_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_WIDGET_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
