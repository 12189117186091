<template>
  <div class="wrapper">
    <ValidationObserver
      ref="validationObserver"
      v-slot="{ handleSubmit }"
    >
      <form
        novalidate="novalidate"
        @submit.prevent="handleSubmit(submit)"
      >
        <p
          class="headline grey lighten-2"
          primaryTitle
        >
          Edit Media Folder
        </p>
        <div
          class="edit-wrapper"
        >
          <MaterialInput
            v-model="formData.name"
            v-bind="formFields.name"
            className="tree-modal-inputs"
            :requiredFromBack="true"
          />
          <div style="margin-bottom: 20px">
            <FoldersManagement
              :selectedValue="formData.parent"
              :customOptions="parentFolders"
              v-bind="formFields.parent"
              :label="'Parent Media Folder'"
              :required="true"
              :isNeedAllMedia="true"
              :commonError="foldError"
              :onChange="onFoldersChange"
            />
          </div>
        </div>
        <div class="button-wrapper">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="onCancelHandle"
          />
          <Button
            :title="'Save'"
            :classType="'primary'"
            :type="'submit'"
          />
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import {
  EDIT_MEDIA_FOLDER,
} from '../../../../store/actions/media/mediaTree';
import CONFIG from '../../../../constants/config';
import Button from '../../../common/Button.vue';
import BaseForm from '../../../forms/BaseForm.vue';
import MaterialInput from '../../../inputs/MaterialInput.vue';
import FoldersManagement from '../../../FoldersManagement.vue';

export default {
  name: 'EditModalContent',
  components: {
    FoldersManagement,
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'editMediaFolder',
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    modalData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      parentFolders: [],
      foldError: '',
      formData: {
        name: this.modalData.name,
        parent: this.modalData.parent.id,
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        parent: {
          name: 'parent',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        parent: [
          `${this.formPrefix}.parent`,
        ],
      },
      submitEvent: EDIT_MEDIA_FOLDER,
      successMessage: CONFIG.successMessages.editMediaFolderMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  methods: {
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      this.onCancel();
    },
    getRequestData() {
      return {
        data: {
          name: this.formData.name,
          parent: this.formData.parent,
        },
        folderId: this.modalData.id,
        accountId: this.getCurrentAccountId,
      };
    },
    onCancelHandle() {
      this.onCancel();
    },
    onFoldersChange(value) {
      this.formData.parent = value ? value.code : value;
      this.foldError = '';
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      const folderInvalidError = error.form_errors
        && error.form_errors.children
        && error.form_errors.children.parent
        && error.form_errors.children.parent.errors
        && error.form_errors.children.parent.errors[0].error;

      if (folderInvalidError && folderInvalidError === 'MEDIA_FOLDER_PARENT_TYPE_INVALID') {
        this.foldError = 'This value is invalid';
        return;
      }

      const folderError = error.form_errors
        && error.form_errors.children
        && error.form_errors.children.parent
        && error.form_errors.children.parent.errors
        && error.form_errors.children.parent.errors[1].error;

      if (folderError) {
        this.foldError = folderError === 'NOT_SYNCHRONIZED_ERROR'
          ? 'The ID entered does not exist'
          : 'This field is required';
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>
<style scoped>
  .wrapper {
    background-color: #222;
    border-radius: 4px;
    display: block;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    padding: 30px;
    position: absolute;
    text-decoration: none;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 550px;
  }

  .headline,
  .edit-wrapper {
    color: #fff;
  }

  .edit-wrapper {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375rem;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding-bottom: 30px;
    word-break: break-all;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }

  .edit-wrapper input,
  .edit-wrapper select {
    padding: 13px;
  }
</style>
