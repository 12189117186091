<template>
  <div class="main wrapper form-wrapper">
    <Loader :isVisible="isLoading" />
    <AddLiveStreamForm :handleFormChange="handleFormChange" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AddLiveStreamForm from '../../components/forms/AddLiveStreamForm.vue';
import Loader from '../../components/common/Loader.vue';
import CONFIG from '../../constants/config';
import { SET_EDIT_FORM } from '../../store/actions/common';

export default {
  name: 'AddLive',
  components: {
    AddLiveStreamForm,
    Loader,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.liveStream.isLoading,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
  },
  methods: {
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
    },
  },
};
</script>

<style scoped>
  .form-wrapper {
    /*height: 100%;*/
    margin-right: 5px;
    overflow-y: auto;
    width: 100%;
  }
</style>
