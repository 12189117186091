<template>
  <div
    v-if="modal"
    class="overlay"
  >
    <div class="wrapper-modal">
      <div class="form-container">
        <div class="wrapper">
          <div class="column column-1">
            <div class="title modal">
              Field Localization
            </div>
            <div class="back-btn">
              <Button
                :className="'back-local'"
                :title="titleButton"
                :srcSvg="iconBackTo"
                :eventFunction="back"
              />
            </div>
            <Tabs
              :removeMargin="true"
              :onSelectTab="handleSelectTab"
            >
              <Tab
                v-for="lang in languages"
                :id="lang.name"
                :key="lang.id"
                :name="lang.name"
                :selected="formData.mode === lang.name"
                :isLocalisation="true"
                :className="[isEditedForm.length
                  && isEditedForm.find(item => item.id === lang.id && !!item.value)
                  ? 'error-tab'
                  : 'tab']"
              >
                <NavigationForm
                  :lang="lang"
                  :languages="languages"
                  :selected="formData.mode === lang.name"
                  @changedLanguage="changedLanguage"
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Tabs from '../common/Tabs/Tabs.vue';
import Tab from '../common/Tabs/Tab.vue';
import Button from '../common/Button.vue';
import iconBackTo from '../../assets/icons/icon-back-to.svg';
import { SET_FORM_CHANGES } from '../../store/actions/localization/localizationActions';
import NavigationForm from '../forms/localization/NavigationForm.vue';

export default {
  name: 'NavigationLocalization',
  components: {
    NavigationForm,
    Tab,
    Tabs,
    Button,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    languages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iconBackTo,
      formData: {
        mode: '',
        changedLanguage: {},
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.localization.isLoading,
      isEditedForm: (state) => state.localization.isEditedForm,
    }),
    ...mapGetters([
      'getLanguages',
    ]),
    titleButton() {
      return 'Back to Navigation Item Editor';
    },
  },
  created() {
    this.formData.mode = this.languages.length && this.languages[0].name;
  },
  methods: {
    toFolderMeta() {
      this.onClose();
    },
    handleSelectTab(id) {
      this.formData.mode = id;
      this.$store.dispatch(SET_FORM_CHANGES, this.formData.changedLanguage);
    },
    back() {
      this.onClose();
    },
    changedLanguage(value) {
      this.formData.changedLanguage = value;
    },
  },
};
</script>

<style scoped>
.form-container {
  overflow-y: auto;
  width: 100%;
}

.wrapper {
  display: flex;
  padding: 30px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.wrapper-modal {
  background-color: #222222;
  border-radius: 4px;
  display: block;
  left: 50%;
  max-width: 100%;
  outline: none;
  overflow-wrap: break-word;
  position: absolute;
  text-decoration: none;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 630px;
  overflow: auto;
}

.back-btn {
  padding-bottom: 20px;
}

.modal {
  color: inherit;
  margin-bottom: 30px;
}

.back-local {
  padding-left: 0;
}
</style>
