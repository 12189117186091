<template>
  <div
    v-if="uploadedFiles && uploadedFiles.length"
    ref="uploadIndicatorRef"
    class="wrapper"
  >
    <div
      class="inner"
      @click="isOpen = !isOpen"
    >
      <inline-svg
        :src="iconUpload"
        :class="['svg', {'svg-icon-anim': processingFiles.length}]"
      >
      </inline-svg>
      <span class="text">
        Files Uploading
      </span>
    </div>
    <div
      v-if="isOpen"
      ref="uploadListRef"
      class="upload-list"
    >
      <div class="upload-list-title">
        <div>
          {{ processingFiles.length }}
          <span v-if="processingFiles.length === 1">
            file
          </span>
          <span v-else>
            files
          </span>
          <span>
            uploading
          </span>
        </div>
      </div>
      <div
        v-for="item in uploadedFiles"
        :key="item.id"
      >
        <UploadListItem :fileUpload="item" />
      </div>
      <div class="queue">
        <inline-svg
          :src="iconQueue"
        >
        </inline-svg>
        <span class="queue-length">
          {{ uploadingFiles.length - uploadedFiles.length }}
        </span>
        <span v-if="uploadingFiles.length - uploadedFiles.length === 1">
          file
        </span>
        <span v-else>
          files
        </span>
        <span style="margin-left: 4px">
          in queue
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import iconUpload from '../../assets/icons/icon-spinner-upload.svg';
import UploadListItem from './UploadListItem.vue';
import CONFIG from '../../constants/config';
import iconQueue from '../../assets/icons/icon-queue-upload.svg';

export default {
  name: 'UploadList',
  components: {
    UploadListItem,
  },
  data() {
    return {
      iconQueue,
      iconUpload,
      files: [],
      isOpen: false,
    };
  },
  computed: {
    ...mapState({
      uploadedFiles: (state) => state.upload.files,
      isUploading: (state) => state.upload.isUploading,
      processingFiles: (state) => state.upload.files.filter((item) => (
        item.progress < CONFIG.upload.maxUploadPerCent && !item.status
      )),
      uploadingFiles: (state) => state.upload.allUploadingFiles,
    }),
  },
  created() {
    document.addEventListener('mousedown', this.clickOutside);
  },
  destroyed() {
    document.removeEventListener('mousedown', this.clickOutside);
  },
  methods: {
    clickOutside(e) {
      if (this.$refs.uploadListRef
          && !this.$refs.uploadListRef.contains(e.target)
          && !this.$refs.uploadIndicatorRef.contains(e.target)
      ) {
        this.isOpen = false;
      }
    },
  },
};
</script>

<style scoped>
  .wrapper {
    padding-right: 10px;
    position: relative;
  }

  .inner {
    align-items: center;
    background: #383b40;
    cursor: pointer;
    display: flex;
    min-width: 145px;
    padding: 13px;
  }

  .text {
    color: #e77925;
    font-size: 14px;
    padding-left: 10px;
  }

  .svg-icon-anim {
    animation: spinner 4s linear infinite;
  }

  .upload-list {
    background: #282a2e;
    border-radius: 3px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    font-size: 16px;
    max-height: 500px;
    overflow: auto;
    position: absolute;
    right: 10px;
    width: 450px;
  }

  .upload-list-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(67, 67, 77, 0.5);
    color: #fff;
    padding: 16px 25px;
    font-size: 18px;
  }

  .upload-list::-webkit-scrollbar-track {
    background-color: #222;
  }

  .upload-list::-webkit-scrollbar {
    width: 5px;
  }

  .upload-list::-webkit-scrollbar-thumb {
    background-color: #4c5057;
    border-radius: 5px;
  }

  .queue {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 16px 25px;
    padding-bottom: 23px;
  }

  .queue-length {
    margin-left: 10px;
    margin-right: 5px;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);

    }

    50% {
      transform: rotate(720deg);
    }

    100% {
      transform: rotate(1080deg);
    }
  }

   @media all and (max-width: 1500px) {
    .inner {
      min-width: 130px;
    }
  }
</style>
