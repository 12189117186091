export const GET_BRUSHFIRE_EVENTS_REQUEST = 'GET_BRUSHFIRE_EVENTS_REQUEST';
export const GET_BRUSHFIRE_EVENTS_SUCCESS = 'GET_BRUSHFIRE_EVENTS_SUCCESS';
export const GET_BRUSHFIRE_EVENTS_ERROR = 'GET_BRUSHFIRE_EVENTS_ERROR';

export const GET_BRUSHFIRE_EVENT_TYPES_REQUEST = 'GET_BRUSHFIRE_EVENT_TYPES_REQUEST';
export const GET_BRUSHFIRE_EVENT_TYPES_SUCCESS = 'GET_BRUSHFIRE_EVENT_TYPES_SUCCESS';
export const GET_BRUSHFIRE_EVENT_TYPES_ERROR = 'GET_BRUSHFIRE_EVENT_TYPES_ERROR';

export const GET_BRUSHFIRE_EVENT_META_REQUEST = 'GET_BRUSHFIRE_EVENT_META_REQUEST';
export const GET_BRUSHFIRE_EVENT_META_SUCCESS = 'GET_BRUSHFIRE_EVENT_META_SUCCESS';
export const GET_BRUSHFIRE_EVENT_META_ERROR = 'GET_BRUSHFIRE_EVENT_META_ERROR';

export const CREATE_BRUSHFIRE_REQUEST = 'CREATE_BRUSHFIRE_REQUEST';
export const CREATE_BRUSHFIRE_SUCCESS = 'CREATE_BRUSHFIRE_SUCCESS';
export const CREATE_BRUSHFIRE_ERROR = 'CREATE_BRUSHFIRE_ERROR';

export const GET_BRUSHFIRE_FOLDER_LIST_REQUEST = 'GET_BRUSHFIRE_FOLDER_LIST_REQUEST';
export const GET_BRUSHFIRE_FOLDER_LIST_SUCCESS = 'GET_BRUSHFIRE_FOLDER_LIST_SUCCESS';
export const GET_BRUSHFIRE_FOLDER_LIST_ERROR = 'GET_BRUSHFIRE_FOLDER_LIST_ERROR';

export const GET_BRUSHFIRE_REQUEST = 'GET_BRUSHFIRE_REQUEST';
export const GET_BRUSHFIRE_SUCCESS = 'GET_BRUSHFIRE_SUCCESS';
export const GET_BRUSHFIRE_ERROR = 'GET_BRUSHFIRE_ERROR';

export const EDIT_BRUSHFIRE_REQUEST = 'EDIT_BRUSHFIRE_REQUEST';
export const EDIT_BRUSHFIRE_SUCCESS = 'EDIT_BRUSHFIRE_SUCCESS';
export const EDIT_BRUSHFIRE_ERROR = 'EDIT_BRUSHFIRE_ERROR';

export const DELETE_BRUSHFIRE_REQUEST = 'DELETE_BRUSHFIRE_REQUEST';
export const DELETE_BRUSHFIRE_SUCCESS = 'DELETE_BRUSHFIRE_SUCCESS';
export const DELETE_BRUSHFIRE_ERROR = 'DELETE_BRUSHFIRE_ERROR';

export const GET_BRUSHFIRE_VIDEO_LIST_REQUEST = 'GET_BRUSHFIRE_VIDEO_LIST_REQUEST';
export const GET_BRUSHFIRE_VIDEO_LIST_SUCCESS = 'GET_BRUSHFIRE_VIDEO_LIST_SUCCESS';
export const GET_BRUSHFIRE_VIDEO_LIST_ERROR = 'GET_BRUSHFIRE_VIDEO_LIST_ERROR';

export const GET_BRUSHFIRE_VIDEO_REQUEST = 'GET_BRUSHFIRE_VIDEO_REQUEST';
export const GET_BRUSHFIRE_VIDEO_SUCCESS = 'GET_BRUSHFIRE_VIDEO_SUCCESS';
export const GET_BRUSHFIRE_VIDEO_ERROR = 'GET_BRUSHFIRE_VIDEO_ERROR';

export const EDIT_BRUSHFIRE_VIDEO_REQUEST = 'EDIT_BRUSHFIRE_VIDEO_REQUEST';
export const EDIT_BRUSHFIRE_VIDEO_SUCCESS = 'EDIT_BRUSHFIRE_VIDEO_SUCCESS';
export const EDIT_BRUSHFIRE_VIDEO_ERROR = 'EDIT_BRUSHFIRE_VIDEO_ERROR';

export const DELETE_BRUSHFIRE_VIDEO_REQUEST = 'DELETE_BRUSHFIRE_VIDEO_REQUEST';
export const DELETE_BRUSHFIRE_VIDEO_SUCCESS = 'DELETE_BRUSHFIRE_VIDEO_SUCCESS';
export const DELETE_BRUSHFIRE_VIDEO_ERROR = 'DELETE_BRUSHFIRE_VIDEO_ERROR';

export const CREATE_BRUSHFIRE_VIDEO_REQUEST = 'CREATE_BRUSHFIRE_VIDEO_REQUEST';
export const CREATE_BRUSHFIRE_VIDEO_SUCCESS = 'CREATE_BRUSHFIRE_VIDEO_SUCCESS';
export const CREATE_BRUSHFIRE_VIDEO_ERROR = 'CREATE_BRUSHFIRE_VIDEO_ERROR';

export const IGNORE_BRUSHFIRE_REQUEST = 'IGNORE_BRUSHFIRE_REQUEST';
export const IGNORE_BRUSHFIRE_SUCCESS = 'IGNORE_BRUSHFIRE_SUCCESS';
export const IGNORE_BRUSHFIRE_ERROR = 'IGNORE_BRUSHFIRE_ERROR';

export const GET_BRUSHFIRE_EVENT_GROUP_LIST_REQUEST = 'GET_BRUSHFIRE_EVENT_GROUP_LIST_REQUEST';
export const GET_BRUSHFIRE_EVENT_GROUP_LIST_SUCCESS = 'GET_BRUSHFIRE_EVENT_GROUP_LIST_SUCCESS';
export const GET_BRUSHFIRE_EVENT_GROUP_LIST_ERROR = 'GET_BRUSHFIRE_EVENT_GROUP_LIST_ERROR';

export const GET_BRUSHFIRE_EVENT_STANDALONE_LIST_REQUEST = 'GET_BRUSHFIRE_EVENT_STANDALONE_LIST_REQUEST';
export const GET_BRUSHFIRE_EVENT_STANDALONE_LIST_SUCCESS = 'GET_BRUSHFIRE_EVENT_STANDALONE_LIST_SUCCESS';
export const GET_BRUSHFIRE_EVENT_STANDALONE_LIST_ERROR = 'GET_BRUSHFIRE_EVENT_STANDALONE_LIST_ERROR';

export const REFRESH_QR_CODE_REQUEST = 'REFRESH_QR_CODE_REQUEST';
export const REFRESH_QR_CODE_SUCCESS = 'REFRESH_QR_CODE_SUCCESS';
export const REFRESH_QR_CODE_ERROR = 'REFRESH_QR_CODE_ERROR';
