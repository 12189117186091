import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  ADD_FEED_ERROR,
  ADD_FEED_REQUEST,
  ADD_FEED_SUCCESS,
  GET_ALL_FEEDS_ERROR,
  GET_ALL_FEEDS_REQUEST,
  GET_ALL_FEEDS_SUCCESS,
  GET_MEDIA_ITEMS,
  GET_MEDIA_ITEMS_ERROR,
  GET_MEDIA_ITEMS_SUCCESS,
  SET_CANCEL_TOKEN,
  GET_FIXED_MEDIA_ITEMS,
  GET_FIXED_MEDIA_ITEMS_REQUEST,
  GET_FIXED_MEDIA_ITEMS_SUCCESS,
  GET_FIXED_MEDIA_ITEMS_ERROR,
  GET_FEED,
  GET_FEED_REQUEST,
  GET_FEED_SUCCESS,
  GET_FEED_ERROR,
  EDIT_FEED,
  EDIT_FEED_REQUEST,
  EDIT_FEED_SUCCESS,
  EDIT_FEED_ERROR,
  GET_ITUNES_CATERORIES_REQUEST,
  GET_ITUNES_CATERORIES_SUCCESS,
  GET_ITUNES_CATERORIES_ERROR,
  GET_ITUNES_SUBCATERORIES_REQUEST,
  GET_ITUNES_SUBCATERORIES_SUCCESS,
  GET_ITUNES_SUBCATERORIES_ERROR,
  GET_LIVE_EVENTS_REQUEST,
  GET_LIVE_EVENTS_SUCCESS,
  GET_LIVE_EVENTS_ERROR,
} from '../../actions/feedsActions/feedsActions';

const state = {
  isLoading: false,
  feeds: [],
  isFixedItemsLoading: false,
  mediaItems: [],
  pagination: {},
  mediaItemsPagination: {},
  feedModalPagination: {},
  feed: {},
  feedsFixedEvents: [],
};

const actions = {
  [ADD_FEED_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(ADD_FEED_REQUEST);

    apiCall.post(`${accountId}/feed`, data)
      .then((res) => {
        commit(ADD_FEED_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_FEED_ERROR, error);
        reject(error);
      });
  }),
  [GET_MEDIA_ITEMS]: ({ commit }, params) => new Promise((resolve, reject) => {
    let dynamicParams = '';
    if (params.isDynamicContent) {
      if (params.matchFolders) {
        params.matchFolders.forEach((folder) => {
          dynamicParams += `&folders[]=${folder}`;
        });
      }

      if (params.includedTags) {
        params.includedTags.forEach((tag) => {
          dynamicParams += `&includeWorkflowTags[]=${tag}`;
        });
      }

      if (params.excludedTags) {
        params.excludedTags.forEach((tag) => {
          dynamicParams += `&excludeWorkflowTags[]=${tag}`;
        });
      }

      if (params.includedSearchTags) {
        params.includedSearchTags.forEach((tag) => {
          dynamicParams += `&includeSearchTags[]=${tag}`;
        });
      }

      if (params.excludedSearchTags) {
        params.excludedSearchTags.forEach((tag) => {
          dynamicParams += `&excludeSearchTags[]=${tag}`;
        });
      }

      if (params.types) {
        params.types.forEach((tag) => {
          if (tag === 'livestream') {
            tag = 'livestream&types[]=livestream_url';
          }
          dynamicParams += `&types[]=${tag}`;
        });
      }

      if (params.fixedItems) {
        params.fixedItems.forEach((item) => {
          dynamicParams += `&excludeItems[]=${item.id}`;
        });
      }
    }
    const url = `${params.accountId}/media-collection/item/search?p=${params.page}&l=${params.allLength}&s=${params.sortBy}&d=${params.sortOrder}&checkAvailability=${params.checkAvailability}${dynamicParams}`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_MEDIA_ITEMS_SUCCESS, resp.data);
        resolve(resp.data.data);
      }).catch((error) => {
        commit(GET_MEDIA_ITEMS_ERROR);
        reject(error);
      });
  }),
  [GET_FIXED_MEDIA_ITEMS]: ({ commit }, params) => new Promise((resolve, reject) => {
    let searchParams = '';

    if (params.folders) {
      params.folders.forEach((folder) => {
        searchParams += `&folders[]=${folder}`;
      });
    }

    if (params.fixedItems) {
      params.fixedItems.forEach((item) => {
        searchParams += `&excludeItems[]=${item.id}`;
      });
    }

    if (params.types) {
      params.types.forEach((item) => {
        searchParams += `&types[]=${item}`;
      });
    }

    if (params.isHideChildren) {
      searchParams += '&showChildren=0';
    }
    commit(GET_FIXED_MEDIA_ITEMS_REQUEST);

    if (state.source) {
      state.source.cancel();
    }

    const url = `${params.accountId}/media-collection/item/search?p=1&l=${params.page * 20}&s=title&d=ASC&filterField=title&filterValue=${params.filterValue}${searchParams}`;

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });
    apiCall.get(url,
      {
        cancelToken: source.token,
        cache: false,
      })
      .then((resp) => {
        commit(GET_FIXED_MEDIA_ITEMS_SUCCESS, resp);
        resolve(resp.data);
      }).catch((error) => {
        commit(GET_FIXED_MEDIA_ITEMS_ERROR);
        reject(error);
      });
  }),
  [GET_ALL_FEEDS_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    let url = `${params.accountId}/feed/list?p=${params.page}&l=15&filterField=search&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_ALL_FEEDS_SUCCESS, resp.data);
        localStorage.setItem('feedsPage', resp.data.pagination.page);
        resolve();
      })
      .catch(() => {
        commit(GET_ALL_FEEDS_ERROR);
      });
  }),
  [GET_FEED]: ({ commit }, { accountId, feedId }) => new Promise((resolve, reject) => {
    commit(GET_FEED_REQUEST);

    let url = `${accountId}/feed/`;
    url += (feedId) ? `${feedId}` : 'new';

    apiCall.get(url)
      .then((res) => {
        commit(GET_FEED_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_FEED_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_FEED]: ({ commit }, { accountId, feedId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_FEED_REQUEST);

    apiCall.put(`${accountId}/feed/${feedId}`, data)
      .then((res) => {
        commit(EDIT_FEED_SUCCESS, res.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_FEED_ERROR, error);
        reject(error);
      });
  }),
  [GET_ITUNES_CATERORIES_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_ITUNES_CATERORIES_REQUEST);

    apiCall.get('choice/itunes-category')
      .then((res) => {
        commit(GET_ITUNES_CATERORIES_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_ITUNES_CATERORIES_ERROR, error);
        reject(error);
      });
  }),
  [GET_ITUNES_SUBCATERORIES_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_ITUNES_SUBCATERORIES_REQUEST);

    apiCall.get('choice/itunes-subcategory')
      .then((res) => {
        commit(GET_ITUNES_SUBCATERORIES_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_ITUNES_SUBCATERORIES_ERROR, error);
        reject(error);
      });
  }),
  [GET_LIVE_EVENTS_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    let searchParams = '';
    if (params.fixedItems) {
      params.fixedItems.forEach((item) => {
        searchParams += `&excludeItems[]=${item.id}`;
      });
    }

    commit(GET_LIVE_EVENTS_REQUEST);

    apiCall.get(`choice/feed-standalone-event/${params.accountId}/list?p=1&l=${params.page * 20}&filterField=se.name&filterValue=${params.filterValue}${searchParams}`)
      .then((res) => {
        commit(GET_LIVE_EVENTS_SUCCESS, res);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_LIVE_EVENTS_ERROR, error);
        reject(error);
      });
  }),
};

const mutations = {
  [ADD_FEED_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_FEED_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_FEED_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ALL_FEEDS_SUCCESS]: (state, payload) => {
    state.feeds = payload.data;
    state.pagination = payload.pagination;
  },
  [GET_MEDIA_ITEMS_SUCCESS]: (state, payload) => {
    state.mediaItems = payload;
    state.mediaItemsPagination = payload.pagination;
    state.isLoading = false;
  },
  [GET_MEDIA_ITEMS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ALL_FEEDS_SUCCESS]: (state, payload) => {
    state.feeds = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
    state.isFixedItemsLoading = true;
  },
  [GET_FEED_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [GET_FEED_SUCCESS]: (state, payload) => {
    state.feed = payload.data;
    state.isLoading = false;
  },
  [GET_FEED_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_FEED_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [EDIT_FEED_SUCCESS]: (state) => {
    state.feed = null;
    state.isLoading = false;
  },
  [EDIT_FEED_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ALL_FEEDS_SUCCESS]: (state, payload) => {
    state.feeds = payload.data;
    state.pagination = payload.pagination;
  },
  [GET_FIXED_MEDIA_ITEMS_REQUEST]: () => {
    state.isFixedItemsLoading = true;
  },
  [GET_FIXED_MEDIA_ITEMS_REQUEST]: () => {
    state.isFixedItemsLoading = true;
  },
  [GET_FIXED_MEDIA_ITEMS_SUCCESS]: (stateLocal, payload) => {
    state.isFixedItemsLoading = false;
    state.source = null;
    state.mediaList = payload.data.data;
    state.feedModalPagination = payload.data.pagination;
  },
  [GET_FIXED_MEDIA_ITEMS_ERROR]: () => {
    state.isFixedItemsLoading = false;
  },
  [GET_ALL_FEEDS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ITUNES_CATERORIES_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [GET_ITUNES_CATERORIES_SUCCESS]: (state) => {
    state.isLoading = false;
  },
  [GET_ITUNES_CATERORIES_ERROR]: (state) => {
    state.isLoading = false;
  },
  [GET_ITUNES_SUBCATERORIES_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [GET_ITUNES_SUBCATERORIES_SUCCESS]: (state) => {
    state.isLoading = false;
  },
  [GET_ITUNES_SUBCATERORIES_ERROR]: (state) => {
    state.isLoading = false;
  },
  [GET_LIVE_EVENTS_REQUEST]: (state) => {
    state.isFixedItemsLoading = true;
  },
  [GET_LIVE_EVENTS_SUCCESS]: (state, payload) => {
    state.isFixedItemsLoading = false;
    state.feedsFixedEvents = payload.data.data;
    state.feedModalPagination = payload.data.pagination;
  },
  [GET_LIVE_EVENTS_ERROR]: (state) => {
    state.isFixedItemsLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
