export const ADD_API_KEY_REQUEST = 'ADD_API_KEY_REQUEST';
export const ADD_API_KEY_SUCCESS = 'ADD_API_KEY_SUCCESS';
export const ADD_API_KEY_ERROR = 'ADD_API_KEY_ERROR';

export const GET_API_KEYS_REQUEST = 'GET_API_KEYS_REQUEST';
export const GET_API_KEYS_SUCCESS = 'GET_API_KEYS_SUCCESS';
export const GET_API_KEYS_ERROR = 'GET_API_KEYS_ERROR';

export const GET_API_KEY_REQUEST = 'GET_API_KEY_REQUEST';
export const GET_API_KEY_SUCCESS = 'GET_API_KEY_SUCCESS';
export const GET_API_KEY_ERROR = 'GET_API_KEY_ERROR';

export const EDIT_API_KEY_REQUEST = 'EDIT_API_KEY_REQUEST';
export const EDIT_API_KEY_SUCCESS = 'EDIT_API_KEY_SUCCESS';
export const EDIT_API_KEY_ERROR = 'EDIT_API_KEY_ERROR';

export const DELETE_API_KEY_REQUEST = 'DELETE_API_KEY_REQUEST';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_ERROR = 'DELETE_API_KEY_ERROR';

export const GET_API_KEYS_ADMIN_REQUEST = 'GET_API_KEYS_ADMIN_REQUEST';
export const GET_API_KEYS_ADMIN_SUCCESS = 'GET_API_KEYS_ADMIN_SUCCESS';
export const GET_API_KEYS_ADMIN_ERROR = 'GET_API_KEYS_ADMIN_ERROR';

export const GET_ENABLED_ACCS_REQUEST = 'GET_ENABLED_ACCS_REQUEST';
export const GET_ENABLED_ACCS_SUCCESS = 'GET_ENABLED_ACCS_SUCCESS';
export const GET_ENABLED_ACCS_ERROR = 'GET_ENABLED_ACCS_ERROR';
