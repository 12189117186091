<!-- eslint-disable vue/valid-v-model -->
<template>
  <div
    v-if="modal"
    class="overlay"
  >
    <div
      v-model="modal"
      class="wrapper"
    >
      <p
        v-if="title"
        class="headline grey lighten-2"
        primaryTitle
      >
        {{ title }}
      </p>
      <p
        v-else
        class="headline grey lighten-2"
      ></p>
      <div
        v-if="isInUseAsset"
        class="localization-warning-wrapper"
      >
        <inline-svg
          :src="iconWarning"
          class="svg-icon"
        >
        </inline-svg>
        <span class="localization-warning">
          {{ warningMess }}
        </span>
      </div>
      <div
        class="body-text"
      >
        {{ bodyMessage }}
      </div>
      <div
        v-if="isCombiningDeletion && bodyHelfullMessage"
        class="delition-wrapper"
      >
        <div class="scroll-wrapper">
          <div v-if="futureStandaloneEvents && futureStandaloneEvents.length">
            <ul
              v-for="standEvent in futureStandaloneEvents"
              :key="standEvent[1].id"
              class="futureStandaloneEvents"
            >
              <li class="deletion-event">
                <div class="router-deletion-event">
                  <router-link
                    :to="standEventLink(standEvent)"
                    :title="standEvent[1].standaloneEventGroup
                      ? standEvent[1].standaloneEventGroup.name
                      : standEvent[1].name"
                    target="_blank"
                  >
                    {{ getName(standEvent[1].standaloneEventGroup
                      ? standEvent[1].standaloneEventGroup.name
                      : standEvent[1].name) }}
                  </router-link>
                </div>
                <div
                  class="icon-block"
                  @click="goToEvent(standEvent)"
                >
                  <inline-svg
                    :src="iconNewWindow"
                    class="svg-icon"
                  >
                  </inline-svg>
                </div>
              </li>
            </ul>
          </div>
          <div v-if="notRecurringMediaItem && notRecurringMediaItem.length">
            <ul
              v-for="item in notRecurringMediaItem"
              :key="item.id"
            >
              <li class="deletion-event">
                <div class="router-deletion-event">
                  <router-link
                    :to="`/schedule?view=day&date=${item.startDate}`"
                    target="_blank"
                  >
                    Linear Live
                  </router-link>
                </div>
                <div
                  class="icon-block"
                  @click="goToLinearLive(item)"
                >
                  <inline-svg
                    :src="iconNewWindow"
                    class="svg-icon"
                  >
                  </inline-svg>
                </div>
              </li>
            </ul>
          </div>
          <div v-if="recurringMediaItem && recurringMediaItem.length">
            <ul
              v-for="item in recurringMediaItem"
              :key="item.id"
            >
              <li class="deletion-event">
                <div class="router-deletion-event">
                  <router-link
                    :to="`/schedule?view=day&date=${item.startDate}`"
                    target="_blank"
                  >
                    Linear Live (recurring)
                  </router-link>
                </div>
                <div
                  class="icon-block"
                  @click="goToLinearLive(item)"
                >
                  <inline-svg
                    :src="iconNewWindow"
                    class="svg-icon"
                  >
                  </inline-svg>
                </div>
              </li>
            </ul>
          </div>
          <div v-if="fallbackMedia">
            <ul>
              <li class="deletion-event">
                <div class="router-deletion-event">
                  <router-link
                    to="/schedule/settings"
                    target="_blank"
                  >
                    Linear Live Fallback
                  </router-link>
                </div>
                <div
                  class="icon-block"
                  @click="goToFallback"
                >
                  <inline-svg
                    :src="iconNewWindow"
                    class="svg-icon"
                  >
                  </inline-svg>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        v-if="bodyHelfullMessage"
        class="bodyHelfullMessage"
      >
        {{ bodyHelfullMessage }}
      </div>
      <div class="button-wrapper">
        <Button
          v-if="isNeedCancel"
          :title="cancelButtonTitle"
          :classType="isOrangeCancel && isNeedToApply
            ? 'warning'
            : !isNeedToApply
              ? 'primary'
              : 'warning'"
          :eventFunction="closeModalhandler"
        />
        <Button
          v-if="isNeedToApply"
          :title="confirmButtonTitle"
          :classType="'primary'"
          :eventFunction="acceptHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  SET_OVERFLOW,
} from '../store/actions/common';
import Button from './common/Button.vue';
import iconWarning from '../assets/icons/icon-warning-localization.svg';
import iconNewWindow from '../assets/icons/icon-new-window.svg';

export default {
  name: 'NotificationModal',
  components: {
    Button,
  },
  props: {
    modal: Boolean,
    title: {
      type: String,
      default: '',
    },
    bodyMessage: {
      type: String,
      default: '',
    },
    bodyHelfullMessage: {
      type: String,
      default: '',
    },
    onAccept: {
      type: Function,
      default: () => {},
      required: false,
    },
    onDecline: {
      type: Function,
      default: () => {},
      required: false,
    },
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
    confirmButtonTitle: {
      type: String,
      default: 'Yes',
    },
    cancelButtonTitle: {
      type: String,
      default: 'No',
    },
    futureStandaloneEvents: {
      type: Array,
      default: () => [],
      required: false,
    },
    fallbackMedia: {
      type: Object,
      default: null,
      required: false,
    },
    notRecurringMediaItem: {
      type: Array,
      default: () => [],
      required: false,
    },
    recurringMediaItem: {
      type: Array,
      default: () => [],
      required: false,
    },
    isCombiningDeletion: {
      type: Boolean,
      default: false,
    },
    isNeedCancel: {
      type: Boolean,
      default: true,
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
    isDeleteAcc: {
      type: Boolean,
      default: false,
    },
    isOrangeCancel: {
      type: Boolean,
      default: false,
    },
    isInUseAsset: {
      type: Boolean,
      default: false,
    },
    warningMess: {
      type: String,
      default: '',
    },
    isNeedToApply: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      iconWarning,
      iconNewWindow,
    };
  },
  methods: {
    closeModal() {
      if (this.onClose) {
        this.onClose();
        return;
      }
      this.$emit('input', false);
      this.$store.dispatch(SET_OVERFLOW, false);
    },
    accept() {
      this.onAccept();
      if (!this.isWarning) {
        this.closeModal();
      }
    },
    closeModalhandler() {
      this.onDecline();
      this.closeModal();
    },
    acceptHandler() {
      this.accept();
    },
    standEventLink(standEvent) {
      return standEvent && standEvent[1].standaloneEventGroup
        ? `/schedule/event-group/individual/${standEvent[1].id}`
        : `/schedule/standalone-event/${standEvent[1].id}`;
    },
    getName(name) {
      return name.length > 45 ? `${name.slice(0, 45)}...` : name;
    },
    goToEvent(standEvent) {
      window.open(this.standEventLink(standEvent), '_blank');
    },
    goToFallback() {
      window.open('/schedule/settings', '_blank');
    },
    goToLinearLive(item) {
      window.open(`/schedule?view=day&date=${item.startDate}`, '_blank');
    },
  },
};
</script>

<style scoped>
.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .wrapper {
    background-color: #222222;
    border-radius: 4px;
    display: block;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    position: absolute;
    text-decoration: none;
    top: 45%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 440px;
  }

  .headline {
    color: #fff;
  }

  .body-text {
    font-size: 16px;
    padding: 15px 0;
  }

  .bodyTitle {
    padding-top: 7px;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding-bottom: 15px;
    word-break: break-all;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }

  .delition-wrapper {
    padding: 15px 0;
    padding-top: 0;
  }

  .futureStandaloneEvents {
    margin-top: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding-left: 1em;
    text-indent: -.7em;
    padding-bottom: 15px;
  }

  .deletion-event {
    display: flex;
    align-items: flex-start;
  }

  .icon-block {
    display: flex;
    margin-right: 5px;
    cursor: pointer;
  }

  .router-deletion-event {
    width: 100%;
    padding-left: 15px;
  }

  li::before {
    content: "• ";
    color: var(--darkgray);
  }

  .bodyHelfullMessage {
    line-height: 20px;
    padding-bottom: 15px;
  }

  a {
    text-decoration: none;
    color: #2591CF;
  }

  .scroll-wrapper {
    /*height: 165px;*/
    overflow: auto;
    max-height: 165px;
  }

.localization-warning-wrapper {
  display: flex;
  align-items: flex-start;
  /*margin-left: 20px;*/
  margin-bottom: 10px;
}

.localization-warning {
  flex: 0.98;
  font-size: 16px;
  color: #F2C94C;
  padding-left: 8px;
}
</style>
