<template>
  <div>
    <div
      ref="fileform"
      class="file-drag-drop"
      :class="[
        'file-drag-drop',
        isError || externalErrorMessage ? 'error-form' : '',
        isPosterInspector && 'file-drag-drop-inspector',
        is4kPoster && 'four-k-poster',
        is4kMobileBannerImage && 'four-k-mobile-poster',
        isImagesOnly && 'file-drag-drop-itunes',
        disabledText && 'disabled',
        isPoster2x3 && 'file-drag-drop-2x3',
      ]"
      :title="computedTitle"
      @click="onFileInput"
    >
      <form
        v-if="!file"
        :class="['file-form']"
      >
        <div class="drag-drop-zone">
          <span
            v-if="disabledText"
            class="drop-files"
          >
            {{ disabledText }}
          </span>
          <span
            v-else
            class="drop-files itunes-img-text"
          >
            <div class="file-form">
              <div class="itunes-drag-zone">
                <inline-svg
                  :src="iconUpload"
                  class="svg"
                />
                <span class="drop-files">
                  Drag & Drop
                </span>
                <span class="drop-files">
                  or click to <span class="browser-link">browse</span>
                </span>
              </div>
            </div>
          </span>
        </div>
      </form>
      <div
        v-else
        :class="['file-preview',
                 isPosterInspector && isError && 'file-preview-inspector',
                 isImagesOnly && 'file-preview-itunes',
                 disabledText && 'disabled']"
      >
        <div class="preview">
          <img
            v-if="preview"
            :ref="'preview'"
            :src="preview"
            :alt="file.name"
            :class="[isPosterInspector ? 'image-preview-inspector' : 'image-preview',
                     isImagesOnly && 'file-preview-itunes']"
          />
        </div>
        <div
          v-if="isNeedRemove"
          class="remove-button"
          @click="onRemove"
        >
          <div
            class="btn"
            :title="`Remove ${is4kPoster
              ? 'Banner Image'
              : imageType === 'background image'
                ? 'Background Image'
                : isImagesOnly
                  ? 'iTunes Cover Art'
                  : 'Poster Image'}`"
          >
            <inline-svg :src="iconRemove">
            </inline-svg>
          </div>
        </div>
      </div>
      <input
        ref="fileInput"
        type="file"
        class="input-hidden"
        :accept="!isImagesOnly ? 'image/x-png,image/webp,image/jpeg' : 'image/x-png,image/jpeg'"
        :disabled="disabled"
        @change="fileInputChange"
      />
    </div>
    <span
      v-if="isError"
      class="error-label"
    >
      {{ validationMessage }}
    </span>
    <span
      v-else-if="externalErrorMessage"
      class="error-label"
    >
      {{ externalErrorMessage }}
    </span>
    <NotificationModal
      v-if="isModalOpen"
      :modal="true"
      :title="'Replace Image'"
      :bodyMessage="replaceMessage"
      :onAccept="handleAccept"
      :onClose="handleClose"
      :isOrangeCancel="true"
    />
  </div>
</template>
<script>
import iconRemove from '../../../assets/icons/icon-remove-image.svg';
import iconUpload from '../../../assets/icons/icon-upload.svg';
import NotificationModal from '../../NotificationModal.vue';

export default {
  name: 'DragAndDropFileUploader',
  components: {
    NotificationModal,
  },
  props: {
    isImagesOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    onPosterChange: {
      type: Function,
      default: () => {},
    },
    poster: {
      type: String,
      default: '',
    },
    posterName: {
      type: String,
      default: '',
    },
    externalErrorMessage: {
      type: String,
      default: '',
    },
    handleError: {
      type: Function,
      default: () => {},
    },
    handleRemove: {
      type: Function,
      default: () => {},
      required: false,
    },
    typeOfPerson: {
      type: String,
      default: '',
    },
    imageType: {
      type: String,
      default: 'poster image',
    },
    isPosterInspector: {
      type: Boolean,
      default: false,
      required: false,
    },
    isHDPoster: {
      type: Boolean,
      default: false,
    },
    isNeedRemove: {
      type: Boolean,
      default: true,
    },
    is4kPoster: {
      type: Boolean,
      default: false,
    },
    is4kMobileBannerImage: {
      type: Boolean,
      default: false,
    },
    disabledText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    isPoster2x3: {
      type: Boolean,
      default: false,
    },
    shouldHideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      aspectRatio: 16 / 9,
      acceptRatioITunes: 1,
      isModalOpen: false,
      isRemove: false,
      isReplace: false,
      tempFile: null,
      iconRemove,
      iconUpload,
      dragAndDropCapable: false,
      file: null,
      uploadPercentage: 0,
      preview: '',
      isError: false,
      validationMessage: '',
      fileName: '',
      replaceMessage: `Are you sure you want to replace this ${this.imageType}?`,
    };
  },
  computed: {
    computedTitle() {
      if (this.shouldHideTitle) {
        return '';
      }

      if (this.is4kPoster) {
        return 'Add Banner Image';
      }

      if (this.is4kMobileBannerImage) {
        return 'Add Banner Mobile Image';
      }

      if (this.imageType === 'background image') {
        return 'Add Background Image';
      }

      if (this.isImagesOnly) {
        return 'Add iTunes Cover Art';
      }

      return `Add Poster Image (${this.isPoster2x3 ? '2:3' : '16:9'})`;
    },
  },
  mounted() {
    this.dragInit();
    this.preview = this.poster;
    this.file = this.poster;
    this.fileName = this.posterName;
  },
  methods: {
    handleAccept() {
      this.isModalOpen = false;
      if (this.tempFile) {
        this.fileName = this.tempFile.name;
        this.getImagePreviews(this.tempFile);
        this.tempFile = null;
      }
    },
    isValid() {
      return !this.isError;
    },
    handleClose() {
      this.isModalOpen = false;
      this.$refs.fileInput.value = null;
      this.tempFile = null;
    },
    dragInit() {
      this.dragAndDropCapable = this.determineDragAndDropCapable();
      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
          this.$refs.fileform.addEventListener(evt, (e) => {
            e.preventDefault();
            e.stopPropagation();
          }, false);
        });

        this.$refs.fileform.addEventListener('drop', this.dropHandle);
      }
    },
    dropHandle(e) {
      if (this.file && this.preview) {
        this.isModalOpen = true;
        this.tempFile = e.dataTransfer.files[0];
        return;
      }
      this.file = e.dataTransfer.files[0];
      this.fileName = this.file.name;
      this.getImagePreviews(this.file);
    },
    onRemove(e) {
      if (this.disabled) {
        e.preventDefault();
        return;
      }

      this.file = null;
      this.fileName = '';
      this.$refs.fileInput.value = null;
      this.isRemove = true;
      this.isError = false;
      this.validationMessage = '';
      this.preview = '';

      if (this.typeOfPerson && this.typeOfPerson === 'small') {
        this.handleRemove(true);
      }
      if (this.typeOfPerson && this.typeOfPerson === 'large') {
        this.handleRemove(false);
      }

      this.onPosterChange(this.preview);
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div');
      return (('draggable' in div)
        || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window
        && 'FileReader' in window;
    },
    getImagePreviews(file) {
      if (this.validateFile(file)) {
        this.isError = false;
        this.validationMessage = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.preview = reader.result;
          this.afterLoadValidation();
        }, false);
        reader.readAsDataURL(file);
      } else {
        this.isError = true;
      }
    },
    validateFile(file) {
      if (!this.isImagesOnly && !(/\.(jpe?g|png)$/i.test(file.name))) {
        this.validationMessage = 'File MIME type should be PNG or JPEG';
        return false;
      }

      if (this.isImagesOnly && !(/\.(jpe?g|png)$/i.test(file.name))) {
        this.validationMessage = 'File MIME type should be PNG or JPEG';
        return false;
      }

      if (((file.size / 1024) / 1024) > 10) {
        this.validationMessage = 'File size can be no more than 10 MB';
        return false;
      }

      return true;
    },
    afterLoadValidation() {
      const minAspect = (parseFloat(this.aspectRatio.toFixed(2)) - parseFloat(0.1)).toFixed(2);
      const maxAspect = (parseFloat(this.aspectRatio.toFixed(2)) + parseFloat(0.1)).toFixed(2);

      const img = new Image();
      img.src = this.preview;
      img.onload = () => {
        if (this.isPoster2x3) {
          this.aspectRatio = 2 / 3;
          const minAspect = (parseFloat(this.aspectRatio.toFixed(2)) - parseFloat(0.1)).toFixed(2);
          const maxAspect = (parseFloat(this.aspectRatio.toFixed(2)) + parseFloat(0.1)).toFixed(2);
          const aspect = (img.width / img.height).toFixed(2);
          if (img.height < 1080) {
            this.isError = true;
            this.preview = '';
            this.validationMessage = 'Min height should be 1080px';
          }
          if (aspect < minAspect || aspect > maxAspect) {
            this.isError = true;
            this.preview = '';
            this.validationMessage = 'Aspect Ratio should be 2:3';
          } else {
            this.onPosterChange(this.preview);
          }
          return;
        }

        if (!this.isImagesOnly) {
          const aspect = (img.width / img.height).toFixed(2);

          if (this.is4kMobileBannerImage) {
            if (+aspect !== 3) {
              this.isError = true;
              this.preview = '';
              this.validationMessage = 'Aspect Ratio should be 3:1';
              return;
            }

            if (img.height < 480) {
              this.isError = true;
              this.preview = '';
              this.validationMessage = 'Min height should be 480px';
              return;
            }

            this.onPosterChange(this.preview);
          }

          if (!this.is4kPoster && !this.is4kMobileBannerImage) {
            if (aspect < minAspect || aspect > maxAspect) {
              this.isError = true;
              this.preview = '';
              this.validationMessage = 'Aspect Ratio should be 16:9';
              return;
            }
          } else if (+aspect !== 4) {
            this.isError = true;
            this.preview = '';
            this.validationMessage = 'Aspect Ratio should be 4:1';
            return;
          }

          if (this.isHDPoster) {
            if (img.height < 720) {
              this.isError = true;
              this.preview = '';
              this.validationMessage = 'Min height should be 720px';
            } else {
              this.onPosterChange(this.preview);
            }
          } else if (!this.is4kPoster && img.height < 1080) {
            this.isError = true;
            this.preview = '';
            this.validationMessage = 'Min height should be 1080px';
          } else if (this.is4kPoster && +aspect === 4 && img.height < 480) {
            this.isError = true;
            this.preview = '';
            this.validationMessage = 'Min height should be 480px';
          } else {
            this.onPosterChange(this.preview);
          }
        }

        if (this.isImagesOnly) {
          if (img.height < 1400
            || img.height > 3000
            || img.width < 1400
            || img.width > 3000) {
            this.isError = true;
            this.preview = '';
            this.validationMessage = 'Minimum: 1400 x 1400 pixels.\n'
            + 'Maximum: 3000 x 3000 pixels';
          } else if (this.isImagesOnly && (img.width.toFixed(2) / img.height.toFixed(2) !== 1)) {
            this.isError = true;
            this.preview = '';
            this.validationMessage = 'Aspect Ratio should be 1:1';
          } else {
            this.onPosterChange(this.preview);
          }
        }
      };
    },
    fileInputChange() {
      const { files } = this.$refs.fileInput;
      if (files[0]) {
        if (this.file && this.preview) {
          this.isModalOpen = true;
          this.tempFile = files[0];
          return;
        }
        this.file = files[0];
        this.fileName = this.file.name;
        this.getImagePreviews(this.file);
      }
    },
    onFileInput() {
      if (!this.isRemove) {
        this.$refs.fileInput.click();
        return;
      }
      this.isRemove = false;
    },
    getName(name) {
      return name.length > 35 ? `${name.slice(0, 35)}...` : name;
    },
  },
};
</script>
<style scoped>
  .file-drag-drop {
    border: 1px dashed #a0a0a0;
    height: 260px;
    width: 460px;
    cursor: pointer;
  }

  .file-drag-drop-itunes {
    height: 460px;
  }

  .file-drag-drop-inspector {
    width: 520px;
  }

  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
  }

  .error-form {
    border-color: #fb3951;
  }

  .error-form-inspector {
    border-color: #fb3951;
  }

  .drag-drop-zone {
    /*cursor: pointer;*/
    /*padding: 112px 0;*/
    text-align: center;
  }

  .itunes-drag-zone {
    cursor: pointer;
    padding: 21px 0;
    text-align: center;
  }

  .file-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .file-preview {
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
  }

  .drop-files {
    color: #a0a0a0;
  }

  .file-name {
    font-size: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
  }

  .preview {
    position: relative;
  }

  .file-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 3px;
    width: max-content;
  }

  .remove-button {
    width: 44px;
    height: 44px;
    background: #2591CF;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-hidden {
    display: none;
  }

  .itunes-img-text {
    display: flex;
    flex-direction: column;
  }

  .file-preview-inspector {
    padding: 106px 0;
  }

  .four-k-poster {
    width: auto !important;
    height: 162px;
  }

  .four-k-mobile-poster {
    width: 80% !important;
    height: auto !important;
    aspect-ratio: 3/1 auto;
  }

  .image-preview {
    width: 100%;
    height: 100%;
  }

  .image-preview-inspector {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .file-preview-itunes {
    height: 100%;
  }

  .file-drag-drop-2x3 {
    height: calc(100% - 10px);
    width: auto !important;
    aspect-ratio: 2/3;
  }

  .disabled {
    cursor: not-allowed;
  }
</style>
