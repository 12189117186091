<template>
  <div>
    <div
      ref="fileform"
      class="file-drag-drop"
      :class="[
        isError || externalError ? 'error-form' : '',
      ]"
      :title="'Add Subtitle'"
      @click="onFileInput"
    >
      <div
        v-if="!fileName"
        class="file-form"
      >
        <div class="drag-drop-zone">
          <inline-svg
            :src="iconUpload"
            class="svg"
          />
          <span class="drop-files">
            Drag & Drop
          </span>
          <span class="drop-files">
            or click to <span class="browser-link">browse</span>
          </span>
        </div>
      </div>
      <div
        v-else
        class="file-preview"
        :class="[isError ? 'file-preview-inspector' : '']"
      >
        <div class="preview">
          <div class="file-footer">
            <div class="file-name">
              {{ fileName }}
            </div>
            <div class="remove-btn">
              <div
                class="btn"
                title="Remove Subtitle"
                @click.stop="onRemove"
              >
                <inline-svg
                  :src="iconRemove"
                >
                </inline-svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input
        ref="fileInput"
        type="file"
        class="input-hidden"
        accept=".vtt,.srt"
        :disabled="disabled"
        @change="fileInputChange"
      />
    </div>
    <span
      v-if="isError"
      class="error-label"
    >
      {{ validationMessage }}
    </span>
    <span
      v-else-if="externalError"
      class="error-label"
    >
      {{ externalError }}
    </span>
  </div>
</template>
<script>
import iconRemove from '../../../assets/icons/icon-remove-upload.svg';
import iconUpload from '../../../assets/icons/icon-upload.svg';
import SrtToVttFormatConverter from '../../../utils/subtitle/srtToVttFormatConverter';

export default {
  name: 'AssetUploader',
  components: {
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    onFileChange: {
      type: Function,
      default: () => {},
    },
    getFileName: {
      type: Function,
      default: () => {},
    },
    originalName: {
      type: String,
      default: '',
    },
    subtitles: {
      type: String,
      default: '',
    },
    externalError: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tempFile: null,
      dragAndDropCapable: false,
      file: null,
      preview: '',
      isError: false,
      validationMessage: '',
      fileName: '',
      iconRemove,
      iconUpload,
    };
  },
  mounted() {
    this.dragInit();
    this.preview = this.subtitles;
    this.fileName = this.originalName;
  },
  methods: {
    isValid() {
      return !this.isError;
    },
    handleClose() {
      this.$refs.fileInput.value = null;
      this.tempFile = null;
    },
    dragInit() {
      this.dragAndDropCapable = this.determineDragAndDropCapable();
      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
          this.$refs.fileform.addEventListener(evt, (e) => {
            e.preventDefault();
            e.stopPropagation();
          }, false);
        });

        this.$refs.fileform.addEventListener('drop', this.dropHandle);
      }
    },
    dropHandle(e) {
      if (!this.file && !this.preview) {
        this.file = e.dataTransfer.files[0];
        this.fileName = this.file.name;
        this.getSubtitlePreviews(this.file);
      }
    },
    onRemove(e) {
      if (this.disabled) {
        e.preventDefault();
        return;
      }

      this.file = null;
      this.fileName = '';
      this.$refs.fileInput.value = null;
      this.isError = false;
      this.validationMessage = '';
      this.preview = '';

      this.onFileChange(this.preview);
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div');
      return (('draggable' in div)
        || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window
        && 'FileReader' in window;
    },
    getSubtitlePreviews(file) {
      if (this.validateFile(file)) {
        this.isError = false;
        this.validationMessage = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          if (reader.result.includes('�')) {
            this.isError = true;
            this.validationMessage = 'Please upload a file in UTF-8 encoding';
            return;
          }

          this.afterLoadValidation(
            this.convertInputFile(reader.result),
          );
        }, false);
        reader.readAsText(file);
      } else {
        this.isError = true;
      }
    },
    validateFile(file) {
      if (!(/\.(vtt|srt)$/i.test(file.name))) {
        this.validationMessage = `${file.name} is not a valid subtitle WebVTT/SRT file.`;
        return false;
      }

      if (((file.size / 1024) / 1024) > 1) {
        this.validationMessage = 'File size can be no more than 1 MB';
        return false;
      }

      return true;
    },
    convertInputFile(result) {
      if ((/\.(srt)$/i.test(this.fileName))) {
        return SrtToVttFormatConverter.convert(result);
      }

      return result;
    },
    afterLoadValidation(result) {
      this.preview = result;
      this.onFileChange(this.preview);
    },
    fileInputChange() {
      const { files } = this.$refs.fileInput;
      if (files[0] && !this.file && !this.preview) {
        this.file = files[0];
        this.getFileName(this.file);
        this.fileName = this.file.name;
        this.getSubtitlePreviews(this.file);
      }
    },
    onFileInput() {
      if (!this.file && !this.preview) {
        this.$refs.fileInput.click();
      }
    },
  },
};
</script>
<style>
  .file-drag-drop {
    border: 1px dashed #a0a0a0;
    height: 230px;
    width: 460px;
    cursor: pointer;
  }

  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
  }

  .error-form {
    border-color: #fb3951;
  }

  .error-form-inspector {
    border-color: #fb3951;
  }

  .drag-drop-zone {
    /*padding: 112px 0;*/
    text-align: center;
  }

  .file-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .file-preview {
    display: flex;
    justify-content: center;
    /*padding: 20px 0;*/
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .drop-files {
    color: #a0a0a0;
  }

  .file-name {
    font-size: 14px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
  }

  .preview {
    /*width: 300px;*/
  }

  .file-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 3px;
  }

  .remove-btn {
    cursor: pointer;
    margin-right: -10px;
    margin-top: -5px;
  }

  .input-hidden {
    display: none;
  }

  .itunes-img-text {
    display: flex;
    flex-direction: column;
  }

  .file-preview-inspector {
    padding: 106px 0;
  }

  .drag-drop-zone {
    /*cursor: pointer;*/
    /*padding: 40px 0;*/
    text-align: center;
  }

  .drag-drop-zone .drop-files {
    display: block;
  }

  .drag-drop-zone .drop-files .browser-link {
    color: #2591cf;
  }

  .drop-files {
    color: #a0a0a0;
  }

  @media all and (max-height: 950px) {
    .file-drag-drop {
      width: 81%;
    }
  }
</style>
