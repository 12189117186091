var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"feed-items"},[_c('div',{class:['feed-item', _vm.isSelected && 'active-preview']},[_c('div',{staticClass:"img-feed-container"},[_c('img',{staticClass:"img-feed",attrs:{"src":_vm.posterRender}}),(_vm.item.hasProtection && _vm.getCurrentAccount.brushfireProtectionEnabled)?_c('inline-svg',{staticClass:"svg-icon brush",attrs:{"src":_vm.iconProtection}}):_vm._e(),(_vm.item.contentType === 'Movie')?_c('inline-svg',{class:['svg-icon content-icons',
                 _vm.item.hasProtection
                   && _vm.getCurrentAccount.brushfireProtectionEnabled
                   && 'hasBrush'],attrs:{"src":_vm.iconMovie}}):_vm._e(),(_vm.item.contentType === 'Event')?_c('inline-svg',{class:['svg-icon content-icons',
                 _vm.item.hasProtection
                   && _vm.getCurrentAccount.brushfireProtectionEnabled
                   && 'hasBrush'],attrs:{"src":_vm.iconEvent}}):_vm._e(),(_vm.item.contentType === 'Trailer')?_c('inline-svg',{class:['svg-icon content-icons',
                 _vm.item.hasProtection
                   && _vm.getCurrentAccount.brushfireProtectionEnabled
                   && 'hasBrush'],attrs:{"src":_vm.iconTrailer}}):_vm._e(),(_vm.item.contentType === 'Extra')?_c('inline-svg',{class:['svg-icon content-icons',
                 _vm.item.hasProtection
                   && _vm.getCurrentAccount.brushfireProtectionEnabled
                   && 'hasBrush'],attrs:{"src":_vm.iconExtra}}):_vm._e(),(_vm.item.contentType === 'Episode' && !_vm.item.episodeNumber)?_c('inline-svg',{class:['svg-icon content-icons',
                 _vm.item.hasProtection
                   && _vm.getCurrentAccount.brushfireProtectionEnabled
                   && 'hasBrush'],attrs:{"src":_vm.iconEmptyEpisode}}):_vm._e(),(_vm.item.contentType === 'Episode' && _vm.item.episodeNumber)?_c('div',{class:['brush episode-wrapper',
                 _vm.item.episodeNumber && 'episode-number-wrapper',
                 _vm.item.hasProtection
                   && _vm.getCurrentAccount.brushfireProtectionEnabled
                   && 'hasBrush']},[_c('inline-svg',{staticClass:"svg-icon",attrs:{"src":_vm.iconEpisode}}),(_vm.item.episodeNumber)?_c('div',{staticClass:"episode-number"},[_vm._v(" "+_vm._s(_vm.item.episodeNumber)+" ")]):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"title-container"},[_c('div',{staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('div',{staticClass:"title-item",class:_vm.itemClass,attrs:{"title":_vm.item.name}},[_vm._v(" "+_vm._s(_vm.getTitle(_vm.item.name))+" ")]),_c('div',{staticClass:"description",class:{ error: _vm.error },attrs:{"title":_vm.item.description}},[_vm._v(" "+_vm._s(_vm.item.description ? _vm.getName(_vm.item.description) : null)+" ")]),(!_vm.item.type)?_c('div',{staticClass:"live-time",class:_vm.itemClass},[_vm._v(" "+_vm._s(_vm.parseDateTime(_vm.item.startDate))+" - "+_vm._s(_vm.parseDateTime(_vm.item.finishDate))+" ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }