<template>
  <div></div>
</template>
<script>

export default {
  name: 'FacebookLogout',
  created() {
    setTimeout(() => {
      window.close();
    }, 500);
  },
};
</script>
