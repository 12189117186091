<!-- eslint-disable vue/attribute-hyphenation-->
<template>
  <div :class="[isInspector ? 'schedule-calendar-main' : '', 'schedule-calendar-component']">
    <div class="toolbar">
      <div class="zoom-wrapper">
        <div
          v-if="activeView === 'day'"
          class="go-back-wrapper"
          @click="goToWeek"
        >
          <inline-svg
            :src="arrowBack"
            class="svg"
          />
          <div>Back to week</div>
        </div>
        <ScheduleZoom
          :timeCellHeight="timeCellHeight"
          :onTimeCellHeightChange="onTimeCellHeightChange"
        />
      </div>
      <div class="calendar-row">
        <div class="title-wrapper">
          <div class="prev-icon">
            <inline-svg
              :src="arrowBack"
              class="svg-icon"
              @click="$refs.vuecal.previous()"
            >
            </inline-svg>
          </div>
          <div
            :key="selectedDate.toString()"
            class="date-title"
          >
            <span v-if="activeView === 'week'">
              {{ getFormatWeek }}
            </span>
            <span
              v-else-if="activeView === 'day'"
              :class="[isToday ? 'title-today' : '']"
            >
              {{ getFormatDay }}
            </span>
          </div>
          <div class="small-cal-wrapper">
            <div
              ref="calendarRefBtn"
              class="cal-icon"
              @click="toggleCalendarClick"
            >
              <inline-svg
                :src="iconCalendar"
                class="svg"
              />
            </div>
            <div
              v-show="isShowCalendar"
              ref="calendarRef"
              class="small-cal"
            >
              <MaterialCalendar2
                :value="selectedDate"
                :type="'date'"
                :valueType="'YYYY-MM-DD'"
                inputClass="picker"
                :inline="true"
                :onChange="onCalendarSelected"
              />
            </div>
          </div>
          <div
            title="Today"
            class="today-btn"
          >
            <inline-svg
              :src="iconToday"
              class="svg-icon"
              @click="setToday"
            >
            </inline-svg>
          </div>
          <div class="next-icon">
            <inline-svg
              :src="arrowNext"
              class="svg-icon"
              @click="onNextBtn"
            >
            </inline-svg>
          </div>
        </div>
      </div>
      <div class="select-wrapper">
        <div
          v-if="activeView === 'week'"
          class="select-box"
        >
          <div>
            <select
              id="searchSelect"
              v-model="activeView"
              class="search-select"
              @change="onSelectChange"
            >
              <option
                v-for="item in activeViewList"
                :key="item.id"
                :value="item.value"
                class="select-option"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <inline-svg
            :src="iconSortDown"
            class="svg"
          />
        </div>
        <div
          v-else
          class="btn-row"
        >
          <Button
            v-if="isTodayAndFutureDate"
            :title="nextThreeDays ? 'Save & Publish' : 'Save'"
            :classType="'primary'"
            :disabled="!isEditedForm"
            :eventFunction="isToday ? onPublishClick : onSaveClick"
          />
        </div>
      </div>
    </div>
    <div :class="['schedule-inspector-wrapper', isInspector ? 'inspector-view' : '']">
      <div
        :class="['scheduler', 'scheduler-' + activeView]"
      >
        <vue-cal
          ref="vuecal"
          :disableViews="['years', 'year', 'month']"
          :timeStep="timeStep"
          :timeCellHeight="timeCellHeight"
          :events="items"
          :hideViewSelector="true"
          :startWeekOnSunday="true"
          :active-view.sync="activeView"
          :onEventClick="onEventClick"
          :selected-date="selectedDate"
          :snap-to-time="snapToTime"
          :editableEvents="{
            title: false,
            drag: isDraggable,
            resize: false,
            delete: isDayMode,
            create: false
          }"
          hide-view-selector
          hide-title-bar
          @view-change="onViewChangeByClick"
          @cell-dblclick="onCellDblClick"
          @cell-click="onCellClick"
          @event-drop="onEventDrop"
        >
          <template v-slot:title="{ }">
            <div class="calendar-row">
            </div>
          </template>
          <template
            v-slot:event="{ event }"
          >
            <div
              v-if="() => isVisibleEvent(event)"
              :key="event.end.toString()"
              :class="['event-container',
                       event.isError ? 'error-item' : '']"
              @drop="onCustomDrop($event, event)"
              @dragover="onCustomDragOver"
              @dragleave="onCustomDragLeave"
            >
              <div
                :title="event.title"
                class="event-header"
              >
                <div
                  class="title-recurrent"
                  :class="{'has-close-button': isDayMode && !event.isPrevious}"
                >
                  <div
                    class="vuecal__event-title"
                    :title="event.title"
                  >
                    {{ event.title }}
                  </div>
                  <div
                    v-if="event.recurring"
                    class="recurrent-wrap"
                  >
                    <inline-svg
                      :src="iconRecurrent"
                      class="svg-icon"
                    >
                    </inline-svg>
                  </div>
                </div>

                <div
                  v-show="isDayMode && !event.isPrevious"
                  class="icon-close-wrapper"
                >
                  <inline-svg
                    :src="iconClose"
                    class="svg-icon"
                    data-type="remove"
                    @click="onRemoveClick(event)"
                  >
                  </inline-svg>
                </div>
              </div>
              <small class="vuecal__event-time">
                <div
                  v-if="isDayMode && (event.daysCount > 1)"
                  class="multiple-day"
                >
                  multi-day
                </div>
                <span>
                  {{ event.start.formatTime("HH:mm") }} - {{ event.end.formatTime("HH:mm") }}
                </span><br />
              </small>
            </div>
          </template>
        </vue-cal>
      </div>
    </div>
    <div
      v-if="isInspector"
      :key="mediaItem.id"
      class="schedule-inspector"
    >
      <ScheduleInspector
        :mediaItem="mediaItem"
        :selectedElement="selectedElement"
        :selectedDate="selectedDate"
        :onInspectorClose="onInspectorClose"
        :onApplyInspector="onApplyInspector"
      />
    </div>
    <div v-if="isConflictErrors">
      <ConflictError
        :error="conflictErrors"
        :date="date"
        :onAccept="onConflictAccept"
        :onClose="onCloseConflict"
      />
    </div>
    <div v-if="isRemoveModal">
      <RemoveNotification
        :error="conflictErrors"
        :onAccept="onRemove"
        :onClose="onCloseRemoveModal"
        :isRecurrent="currentRemoveEvent.recurring"
      />
    </div>
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="'Publish Schedule'"
      :onAccept="onSaveClick"
      :onClose="onCloseModal"
    />
  </div>
</template>
<script>
import VueCal from 'vue-cal';
import moment from 'moment';
import 'vue-cal/dist/vuecal.css';
import {
  mapState,
  mapGetters,
} from 'vuex';
// eslint-disable-next-line import/extensions
import 'vue-cal/dist/drag-and-drop.js';
import iconSortDown from '../../assets/icons/icon-sort-arrow-down.svg';
import arrowNext from '../../assets/icons/icon-arrow-next.svg';
import arrowBack from '../../assets/icons/icon-arrow-prev.svg';
import iconCalendar from '../../assets/icons/icon-calendar.svg';
import iconClose from '../../assets/icons/icon-close-inspector.svg';
import iconToday from '../../assets/icons/icon-today.svg';
import iconRecurrent from '../../assets/icons/icon-recurent-item.svg';
import Button from '../common/Button.vue';
import { GET_MEDIA_ITEM_REQUEST } from '../../store/actions/media/mediaItem';
import CONFIG from '../../constants/config';
import ScheduleInspector from './ScheduleInspector.vue';
import ScheduleZoom from './ScheduleZoom.vue';
import NotificationModal from '../NotificationModal.vue';
import ConflictError from './ConflictError.vue';
import RemoveNotification from './RemoveNotification.vue';
import MaterialCalendar2 from '../inputs/MaterialCalendar2.vue';

export default {
  name: 'ScheduleCalendar',
  components: {
    VueCal,
    ScheduleInspector,
    ScheduleZoom,
    Button,
    NotificationModal,
    ConflictError,
    RemoveNotification,
    MaterialCalendar2,
  },
  props: {
    view: {
      type: String,
      default: 'week',
    },
    date: {
      type: String,
      default: null,
    },
    onViewChange: {
      type: Function,
      default: () => {},
    },
    getConflictErrors: {
      type: Function,
      default: () => {},
    },
    conflictErrors: {
      type: Array,
      default: () => [],
    },
    onDateChange: {
      type: Function,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
    conflicts: {
      type: Array,
      default: () => [],
    },
    onAddEvent: {
      type: Function,
      default: () => {},
    },
    onSaveDay: {
      type: Function,
      default: () => {},
    },
    onRemoveEvent: {
      type: Function,
      default: () => {},
    },
    onExcludeEvent: {
      type: Function,
      default: () => {},
    },
    conflictTime: {
      type: Object,
      default: () => {},
    },
    isConflictErrors: {
      type: Boolean,
      default: false,
    },
    onCloseConflictModal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconSortDown,
      arrowNext,
      arrowBack,
      iconCalendar,
      iconClose,
      iconToday,
      iconRecurrent,
      timeCellHeight: 40,
      timeStep: 30,
      snapToTime: 10,
      isShowCalendar: false,
      activeView: 'week',
      selectedDate: null,
      isInspector: false,
      mediaItem: [],
      selectedElement: {},
      isModalOpen: false,
      isRemoveModal: false,
      currentRemoveEvent: {},
      bodyMessage: CONFIG.schedule.publishMessage,
      activeViewList: [
        {
          name: 'Week',
          value: 'week',
        },
        {
          name: 'Day',
          value: 'day',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.common.profile,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    isDayMode() {
      return this.activeView === 'day';
    },
    isDraggable() {
      return (this.activeView === 'day' && !this.isInspector);
    },
    getFormatWeek() {
      const startDate = moment(this.selectedDate).startOf('week');
      const endDate = moment(this.selectedDate).endOf('week');
      return `${moment(startDate).format('MMMM D')} - ${moment(endDate).format('D')}, ${moment(endDate).format('YYYY')}`;
    },
    getFormatDay() {
      const startDate = this.selectedDate;
      return `${moment(startDate).format('MMMM D')}, ${moment(startDate).format('YYYY')}`;
    },
    isToday() {
      return (this.selectedDate === moment().format('YYYY-MM-DD'));
    },
    isTodayAndFutureDate() {
      return moment(this.selectedDate).isSameOrAfter(moment().format('YYYY-MM-DD'));
    },
    nextThreeDays() {
      return this.isToday
        || (this.selectedDate === moment().add(1, 'd').format('YYYY-MM-DD'))
        || (this.selectedDate === moment().add(2, 'd').format('YYYY-MM-DD'));
    },
  },
  created() {
    this.selectedDate = this.date;
    this.activeView = this.view;
  },
  mounted() {
    document.addEventListener('mousedown', this.clickOutside);
  },
  destroyed() {
    document.removeEventListener('mousedown', this.clickOutside);
  },
  methods: {
    onCloseConflict() {
      this.onCloseConflictModal();
    },
    onConflictAccept() {
      this.onSaveDay();
    },
    onCloseModal() {
      this.isModalOpen = false;
    },
    onTimeCellHeightChange(value) {
      this.timeCellHeight = Number(value);
    },
    onNextBtn() {
      this.$refs.vuecal.next();
    },
    onSaveClick() {
      this.onSaveDay();
    },
    onPublishClick() {
      this.isModalOpen = true;
    },
    onViewChangeByClick(date) {
      const currentTz = moment.tz.guess();
      if (moment(date.startDate).isValid()) {
        const currentDate = moment(date.startDate).tz(currentTz).format('YYYY-MM-DD');
        if ((this.$route.query.view !== date.view) || (this.$route.query.date !== currentDate)) {
          this.$router.push({ path: 'schedule', query: { view: date.view, date: currentDate } });
        }
      }
    },
    onCellDblClick(date) {
      const currentTz = moment.tz.guess();
      if (this.activeView === 'week') {
        this.$router.push({ path: 'schedule', query: { view: 'day', date: moment(date).tz(currentTz).format('YYYY-MM-DD') } });
      }
    },
    getTimezone() {
      let tz = moment.tz.guess();
      if (this.profile.timezone) {
        tz = this.profile.timezone;
      }
      return tz;
    },
    onCellClick(value) {
      const date = (value.split) ? value.date : value;
      if (this.activeView === 'week') {
        this.selectedDate = moment(date).tz(moment.tz.guess()).format('YYYY-MM-DD');
      }
    },
    onCalendarSelected(event) {
      this.selectedDate = moment(event).tz(moment.tz.guess()).format('YYYY-MM-DD');
      this.$router.push({ path: 'schedule', query: { view: this.view, date: this.selectedDate } });
      this.onDateChange(this.selectedDate);
      this.isShowCalendar = false;
    },
    clickOutside(e) {
      if (this.$refs.calendarRef
        && !this.$refs.calendarRef.contains(e.target)
        && !this.$refs.calendarRefBtn.contains(e.target)) {
        this.isShowCalendar = false;
      }
    },
    toggleCalendarClick() {
      this.isShowCalendar = !this.isShowCalendar;
    },
    onSelectChange() {
      this.onViewChange(this.activeView);
      this.$router.push({ path: 'schedule', query: { view: this.activeView, date: moment(this.selectedDate).format('YYYY-MM-DD') } });
    },
    goToWeek() {
      this.$router.push({ path: 'schedule', query: { view: 'week', date: moment(this.selectedDate).format('YYYY-MM-DD') } });
    },
    onRemoveClick(event) {
      this.isRemoveModal = true;
      this.currentRemoveEvent = event;

      if (this.isInspector && (this.selectedElement.id === event.id)) {
        this.isInspector = false;
      }
    },
    onCloseRemoveModal() {
      this.isRemoveModal = false;
      this.currentRemoveEvent = {};
    },
    onRemove(type) {
      const event = this.currentRemoveEvent;
      this.onCloseRemoveModal();
      if (event.recurring && type.value === 'one') {
        this.onExcludeEvent(event);
      } else {
        this.onRemoveEvent(event);
      }
    },
    setToday() {
      const {
        view,
        date,
      } = this.$router.history.current.query;

      this.selectedDate = moment().format('YYYY-MM-DD');

      if (view !== this.activeView || date !== moment().format('YYYY-MM-DD')) {
        this.$router.push({ path: 'schedule', query: { view: this.activeView, date: moment().format('YYYY-MM-DD') } });
      }
    },
    onEventDrop(element) {
      this.onAddEvent(element);
    },
    onCustomDrop(event, item) {
      const data = JSON.parse(event.dataTransfer.getData('event'));

      // eslint-disable-next-line no-underscore-dangle
      if (data._eid !== item._eid) {
        this.onCustomDragLeave(event);
        event.preventDefault();
        event.stopPropagation();

        if (data.start) {
          data.start = new Date(data.start);
        }
        if (data.end) {
          data.end = new Date(data.end);
        }

        const oldDate = moment(data.start, 'YYYY-MM-DD HH:mm:ss').toDate();

        data.start = moment(item.end, 'YYYY-MM-DD HH:mm:ss').toDate();
        data.end = moment(data.start, 'YYYY-MM-DD HH:mm:ss')
          .add(data.duration, 'seconds')
          .toDate();

        this.onAddEvent({
          event: data,
          external: data.isExternal,
          oldDate,
          newDate: moment(item.end, 'YYYY-MM-DD HH:mm:ss').toDate(),
        });
      }
    },
    onCustomDragOver(event) {
      event.target.closest('.vuecal__event').classList.add('drag-over-test');
    },
    onCustomDragLeave(event) {
      event.target.closest('.vuecal__event').classList.remove('drag-over-test');
    },
    onEventClick(element, e) {
      if (e.target.closest('svg[data-type="remove"]')) {
        return;
      }
      if (!this.isDayMode || element.isPrevious) {
        return;
      }
      const currentTz = moment.tz.guess();
      const selectedElement = this.items.find(
        (item) => (item.id === element.id) && (item.start === moment(element.start).tz(currentTz).format('YYYY-MM-DD HH:mm:ss')),
      );
      if (selectedElement) {
        const params = {
          accountId: this.getCurrentAccountId,
          itemId: element.id,
        };

        this.$store.dispatch(GET_MEDIA_ITEM_REQUEST, params)
          .then((res) => {
            this.mediaItem = res;
            this.selectedElement = this.items.find(
              (item) => (item.id === element.id) && (item.start === moment(element.start).tz(currentTz).format('YYYY-MM-DD HH:mm:ss')),
            );
            this.isInspector = true;
          })
          .catch(() => {
            this.$toasted.global.error({
              message: CONFIG.errorMessages.noVideoMessage,
            });
          });
      }
    },
    onApplyInspector(item, isChangedStartTime) {
      if (isChangedStartTime) {
        this.getConflictErrors(item);
      }
    },
    onInspectorClose() {
      this.isInspector = false;
    },
    isVisibleEvent(event) {
      const tz = this.getTimezone();
      const currentDay = moment(event.start).tz(tz).format('YYYY-MM-DD');
      return event.excludedDays && !event.excludedDays.includes(currentDay);
    },
  },
};
</script>
<style scoped>
  .scheduler-day >>> .vuecal__event {
    max-width: 90%;
  }

  .scheduler-day >>> .vuecal__event.drag-over-test {
    border: 2px dashed #2491cf;
  }

  .event-container {
    cursor: pointer;
    height: 100%;
  }

  .select-wrapper {
    color: #fff;
    display: flex;
    font-size: 14px;
    justify-content: flex-end;
  }

  .select-box {
    display: flex;
  }

  .search-select {
    appearance: none;
    background: #222;
    border: 1px solid #383b40;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    height: 100%;
    max-height: 30px;
    outline: none;
    padding: 6px;
    width: 92px;
  }

  .schedule-calendar-component .svg {
    cursor: pointer;
    fill: #383b40;
    position: relative;
    right: 15px;
    top: 13px;
  }

  .small-cal {
    position: absolute;
    z-index: 20;
  }

  .schedule-calendar-component .cal-icon {
    cursor: pointer;
    margin-left: 30px;
    margin-top: -17px;
  }

  .schedule-calendar-component .prev-icon {
    margin-right: 15px;
  }

  .calendar-row {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
  }

  .small-cal-wrapper {
    display: flex;
  }

  .small-cal-wrapper-title {
    margin-left: 20px;
    padding-right: 5px;
    padding-top: 3px;
  }

  .schedule-calendar-component .toolbar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 20px 0;
  }

  .schedule-calendar-component .svg-icon {
    cursor: pointer;
    height: 20px;
  }

  .event-header {
    display: flex;
    justify-content: space-between;
  }

  .title-wrapper {
    display: flex;
  }

  .today-btn {
    margin-right: 10px;
  }

  .go-back-wrapper {
    color: #b2b2b2;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 20px;
    margin-left: 45px;
  }

  .go-back-wrapper .svg {
    height: 15px;
    right: 5px;
    top: 1px;
    width: 15px;
  }

  .schedule-calendar-main {
    min-width: 450px;
    width: calc(100vw - 570px - 575px);
  }

  @media all and (max-width: 1200px) {
    .schedule-calendar-main {
      min-width: 450px;
      width: auto;
    }
  }

  .schedule-calendar-main .date-title {
    text-align: center;
  }

  .schedule-inspector {
    background-color: #383b40;
    height: 89%;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 45px;
    width: 530px;
    z-index: 10;
  }

  .multiple-day {
    background-color: #2491cf;
    border: 1px solid #2491cf;
    border-radius: 4px;
    padding: 2px 5px;
    width: 50px;
  }

  .btn-row {
    display: flex;
  }

  .title-recurrent {
    display: flex;
    width: calc(100% - 15px);
  }

  .title-recurrent.has-close-button{
    width: calc(100% - 35px);
  }

  .recurrent-wrap {
    margin-left: 10px;
  }
</style>
<style>
  .weekday-label {
    display: flex;
    flex-direction: column-reverse;
    font-size: 18px;
    font-weight: bold;
  }

  .weekday-label .full {
    font-size: 16px;
    font-weight: normal;
  }

  .vuecal__heading {
    background-color: #282a2e;
    border: 1px solid #383b40;
    border-bottom-style: none;
    color: #b4b4b6;
    height: 65px;
  }
  .vuecal__weekdays-headings {
      border-bottom-color: #383b40;
  }

  .vuecal--week-view .today,
  .title-today {
    color: #2491cf;
  }
  .vuecal__body {
    height: 75vh;
  }

  .vuecal__no-event {
    display: none;
  }

  .vuecal__time-column .vuecal__time-cell-line:before {
    border-top: 1px solid #383b40;
  }

  .vuecal__cell:before {
    border: 1px solid #383b40;
  }

  .vuecal__now-line {
    border-top: 2px solid #c34646;
    opacity: 1;
  }

  .vuecal__cell--current, .vuecal__cell--today {
    background-color: transparent;
  }

  .vuecal__all-day {
    padding-right: 0 !important;
  }

  .vuecal {
    box-shadow: none;
  }

  .vuecal__cell-events .vuecal__event {
    background-color: #303235;
    border-left: 3px solid #2591cf;
    color: #fff;
    padding: 5px 0 5px 10px;
    text-align: left;
  }

  .vuecal__cell-events .vuecal__event-title {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .vuecal__cell-events .vuecal__event-time {
    font-size: 12px;
  }

  .vuecal__cell--selected {
    background-color: rgba(255, 255, 255, 0.05);
    z-index: 2;
  }

  .vuecal__event-delete {
    display: none;
  }

  .schedule-inspector .vue__time-picker input.display-time {
    background-color: #222;
  }

  .schedule-inspector .picker-date {
    margin-right: 0;
    width: auto;
  }

  .vuecal__cell-events .previous {
    background-image: repeating-linear-gradient(
      45deg, transparent, transparent 10px,#222323 0, #222323 20px
    );
    border-left: 0;
  }

  .vuecal__cell-events .conflict {
    background-image: repeating-linear-gradient(
      45deg, transparent, transparent 10px,#222323 0, #222323 20px
    );
    border-left: 0;
  }

  .schedule-inspector .contact-info .failed {
    width: 87px;
  }
</style>
