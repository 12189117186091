<script>
import { ValidationProvider } from 'vee-validate';
import CONFIG from '../../constants/config';
import iconCopy from '../../assets/icons/icon-copy.svg';

export default {
  name: 'BaseMaterialInput',
  components: {
    ValidationProvider,
  },
  props: {
    formPrefix: {
      type: String,
      default: 'default',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    autocomplete: {
      type: String,
      default: undefined,
    },
    allowCopy: {
      type: Boolean,
      default: false,
    },
    labelUnderField: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconCopy,
    };
  },
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.type === 'number' && !value) {
          return;
        }

        if (this.type === 'number'
          && (value < this.rules.min_value
          || value > this.rules.max_value)
        ) {
          return;
        }
        this.$emit('input', value);
      },
    },
    fieldName() {
      return `${this.formPrefix}.${this.name}`;
    },
    fieldLabel() {
      const labelKey = `validation.forms.${this.formPrefix}.names.${this.name}`;
      const defaultKey = `validation.forms.default.names.${this.name}`;

      if (this.label) {
        return this.label;
      }
      if (this.$te(labelKey)) {
        return this.$t(labelKey);
      }
      if (this.$te(defaultKey)) {
        return this.$t(defaultKey);
      }

      return this.name;
    },
    maxlength() {
      return this.rules && this.rules.max ? this.rules.max : undefined;
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetValidation();
      }
    },
  },
  methods: {
    resetValidation() {
      if (this.$refs.validator) {
        this.$refs.validator.reset();
      }
    },
    onCopy() {
      const {
        errorMessages,
        successMessages,
      } = CONFIG;

      this.$copyText(this.valueModel)
        .then(() => {
          this.$toasted.global.success({
            message: successMessages.copyToClipboard,
          });
        })
        .catch(() => {
          this.$toasted.global.error({
            message: errorMessages.copyToClipboard,
          });
        });
    },
  },
};
</script>
