export const ADD_ASSET_ROLE_REQUEST = 'ADD_ASSET_ROLE_REQUEST';
export const ADD_ASSET_ROLE_SUCCESS = 'ADD_ASSET_ROLE_SUCCESS';
export const ADD_ASSET_ROLE_ERROR = 'ADD_ASSET_ROLE_ERROR';

export const GET_ASSET_ROLE_REQUEST = 'GET_ASSET_ROLE_REQUEST';
export const GET_ASSET_ROLE_SUCCESS = 'GET_ASSET_ROLE_SUCCESS';
export const GET_ASSET_ROLE_ERROR = 'GET_ASSET_ROLE_ERROR';

export const EDIT_ASSET_ROLE_REQUEST = 'EDIT_ASSET_ROLE_REQUEST';
export const EDIT_ASSET_ROLE_SUCCESS = 'EDIT_ASSET_ROLE_SUCCESS';
export const EDIT_ASSET_ROLE_ERROR = 'EDIT_ASSET_ROLE_ERROR';

export const DELETE_ASSET_ROLE_REQUEST = 'DELETE_ASSET_ROLE_REQUEST';
export const DELETE_ASSET_ROLE_SUCCESS = 'DELETE_ASSET_ROLE_SUCCESS';
export const DELETE_ASSET_ROLE_ERROR = 'DELETE_ASSET_ROLE_ERROR';

export const GET_ASSET_ROLE_ITEM_REQUEST = 'GET_ASSET_ROLE_ITEM_REQUEST';
export const GET_ASSET_ROLE_ITEM_SUCCESS = 'GET_ASSET_ROLE_ITEM_SUCCESS';
export const GET_ASSET_ROLE_ITEM_ERROR = 'GET_ASSET_ROLE_ITEM_ERROR';

export const GET_ASSET_ROLE_TYPES_REQUEST = 'GET_ASSET_ROLE_TYPES_REQUEST';
export const GET_ASSET_ROLE_TYPES_SUCCESS = 'GET_ASSET_ROLE_TYPES_SUCCESS';
export const GET_ASSET_ROLE_TYPES_ERROR = 'GET_ASSET_ROLE_TYPES_ERROR';

export const CHANGE_ASSET_ROLE_STATUS_REQUEST = 'CHANGE_ASSET_ROLE_STATUS_REQUEST';
export const CHANGE_ASSET_ROLE_STATUS_SUCCESS = 'CHANGE_ASSET_ROLE_STATUS_SUCCESS';
export const CHANGE_ASSET_ROLE_STATUS_ERROR = 'CHANGE_ASSET_ROLE_STATUS_ERROR';
