var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay"},[_c('div',{class:['wrapper',
             _vm.mediaItem.type === 'audio' && 'wrapper-audio',
             (_vm.mediaItem.type === 'livestream' || _vm.mediaItem.type === 'livestream_url')
               && '']},[_c('div',{staticClass:"choose-asset-title"},[_vm._v(" Choose Asset Type ")]),_c('div',{class:['flex',
               _vm.mediaItem.type !== 'vod' ? 'cent' : '',
               (_vm.mediaItem.type === 'livestream' || _vm.mediaItem.type === 'livestream_url')
                 && '']},[(_vm.mediaItem.type !== 'audio')?_c('div',{staticClass:"big-btn-wrap"},[_c('Button',{attrs:{"eventFunction":_vm.toSuppVideo,"smallSize":false,"classType":'primary big-btn',"srcSvg":_vm.iconSuppVideo}}),_c('div',{staticClass:"big-btn-text"},[_vm._v(" Video ")])],1):_vm._e(),(_vm.mediaItem.type === 'vod')?_c('div',{staticClass:"big-btn-wrap left"},[_c('Button',{attrs:{"eventFunction":_vm.toDo,"smallSize":false,"classType":'primary big-btn',"srcSvg":_vm.iconAlternative}}),_c('span',{staticClass:"a"},[_vm._v("A")]),_c('div',{staticClass:"big-btn-text"},[_vm._v(" Alternate Audio ")])],1):_vm._e(),(_vm.mediaItem.type !== 'livestream' && _vm.mediaItem.type !== 'livestream_url')?_c('div',{staticClass:"big-btn-wrap"},[_c('Button',{attrs:{"eventFunction":_vm.toSub,"smallSize":false,"classType":'primary big-btn',"srcSvg":_vm.iconSub}}),_c('div',{staticClass:"big-btn-text"},[_vm._v(" Subtitle ")])],1):_vm._e(),(_vm.mediaItem.type === 'audio' || _vm.mediaItem.type === 'vod')?_c('div',{class:['big-btn-wrap',
                 _vm.mediaItem.type === 'vod' && 'marg-20' ]},[_c('Button',{attrs:{"eventFunction":_vm.toSuppAudio,"smallSize":false,"classType":'primary big-btn',"srcSvg":_vm.iconSuppAudio}}),_c('div',{staticClass:"big-btn-text"},[_vm._v(" Supplemental Audio ")])],1):_vm._e(),_c('div',{class:['big-btn-wrap',
                 _vm.mediaItem.type === 'vod' && 'marg-20']},[_c('Button',{attrs:{"eventFunction":_vm.toImage,"smallSize":false,"classType":'primary big-btn',"srcSvg":_vm.iconImage}}),_c('div',{staticClass:"big-btn-text"},[_vm._v(" Image ")])],1),_c('div',{class:['big-btn-wrap']},[_c('Button',{attrs:{"eventFunction":_vm.toDocument,"smallSize":false,"classType":'primary big-btn',"srcSvg":_vm.iconDocument}}),_c('div',{staticClass:"big-btn-text"},[_vm._v(" Document ")])],1),_c('div',{class:['big-btn-wrap']},[_c('Button',{attrs:{"eventFunction":_vm.toLinked,"smallSize":false,"classType":'primary big-btn',"srcSvg":_vm.iconLinked}}),_c('div',{staticClass:"big-btn-text"},[_vm._v(" Linked File ")])],1),_c('div',{class:['big-btn-wrap',
                 _vm.mediaItem.type !== 'audio' && 'big-btn-wrap-vod' ]},[_c('Button',{attrs:{"eventFunction":_vm.toOther,"smallSize":false,"classType":'primary big-btn',"srcSvg":_vm.iconOther}}),_c('div',{staticClass:"big-btn-text"},[_vm._v(" Other ")])],1)]),_c('div',{class:['cancel-wrap',
               (_vm.mediaItem.type === 'livestream' || _vm.mediaItem.type === 'livestream_url')
                 && '']},[_c('Button',{attrs:{"title":'Cancel',"classType":'warning',"eventFunction":_vm.backTo}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }