<template>
  <div class="translate-wrapper">
    <ValidationObserver
      ref="validationObserver"
      v-slot="{ handleSubmit }"
    >
      <form
        class="contact-info"
        novalidate="novalidate"
        @submit.prevent="handleSubmit(submit)"
        @input="handleFormChange"
      >
        <MaterialInput
          v-model="formData.heading"
          v-bind="formFields.heading"
        />
        <div class="btn-wrapper">
          <div class="btn-wrapper-b">
            <Button
              type="submit"
              :title="'Apply Changes'"
              :classType="'primary'"
            />
            <Button
              :title="'Revert'"
              :classType="'warning'"
              :eventFunction="revertLanguage"
            />
          </div>
          <div
            v-if="isEditedForm.length
              && isEditedForm.find(item => item.id === lang.id && !!item.value)"
            class="changes-wrapper"
          >
            <inline-svg
              :src="iconChanges"
              class="svg-icon"
            >
            </inline-svg>
            <span class="changes-text">
              Unsaved changes
            </span>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import iconChanges from '../../../assets/icons/icon-localization-changes.svg';

import {
  ADD_LANGUAGE_ROWS_REQUEST,
  GET_LANGUAGE_ROWS_REQUEST,
  INIT_FORM_CHANGES,
  SET_FORM_CHANGES,
} from '../../../store/actions/localization/localizationActions';

export default {
  name: 'RowHeadingForm',
  components: {
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'cmsRowHeadingLocalization',
    },
    lang: {
      type: Object,
      default: () => {},
    },
    cmsPage: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
    languages: {
      type: Array,
      default: () => [],
    },
    changedLanguage: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconChanges,
      submitEvent: ADD_LANGUAGE_ROWS_REQUEST,
      successMessages: `The ${this.lang.name} Localization has been successfully updated`,
      isPosterSmallChanged: false,
      formData: {
        heading: '',
      },
      formFields: {
        heading: {
          type: 'text',
          name: 'heading',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
      },
      errorMapping: {
        heading: [
          `${this.formPrefix}.heading`,
        ],
      },
    };
  },
  computed: {
    ...mapState({
      isEditedForm: (state) => state.localization.isEditedForm,
      cmsRowLanguage: (state) => state.localization.cmsRowLanguage,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  created() {
    const value = this.languages.map(((item) => ({
      id: item.id,
      value: false,
    })));
    this.$store.dispatch(INIT_FORM_CHANGES, value);

    const params = {
      accountId: this.getCurrentAccountId,
      cmsRowId: this.$route.params.rowId,
      langId: this.lang.id,
    };
    this.$store.dispatch(GET_LANGUAGE_ROWS_REQUEST, params).then(() => {
      this.initFormData();
    });
  },
  beforeDestroy() {
    this.$store.dispatch(SET_FORM_CHANGES, []);
  },
  methods: {
    handleFormChange() {
      this.$emit('changedLanguage', { id: this.lang.id, value: true });
    },
    initFormData() {
      this.formData.heading = this.cmsRowLanguage.heading;
    },
    revertLanguage() {
      const params = {
        accountId: this.getCurrentAccountId,
        cmsRowId: this.$route.params.rowId,
        langId: this.lang.id,
      };
      this.$store.dispatch(GET_LANGUAGE_ROWS_REQUEST, params).then(() => {
        this.$emit('changedLanguage', { id: this.lang.id, value: false });
        this.$store.dispatch(SET_FORM_CHANGES, { id: this.lang.id, value: false });
        this.initFormData();
      });
    },
    getRequestData() {
      const data = {
        heading: this.formData.heading,
      };

      return {
        accountId: this.getCurrentAccountId,
        cmsRowId: this.$route.params.rowId,
        langId: this.lang.id,
        data,
      };
    },
    onSubmitSuccess() {
      this.$emit('changedLanguage', { id: this.lang.id, value: false });
      this.$store.dispatch(SET_FORM_CHANGES, { id: this.lang.id, value: false });
      this.$toasted.global.success({
        message: this.successMessages,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.translate-wrapper {
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 35px;
}

.changes-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #FB3951;
  border-radius: 2px;
  padding: 8px;
  color: #FB3951;
  margin-left: 20px;
}

.changes-text {
  padding-left: 5px;
}

.btn-wrapper-b {
  display: flex;
}

@media all and (max-width: 1670px) {
  .translate-wrapper {
    padding-right: 300px;
  }
}

@media all and (max-width: 1460px) {
  .translate-wrapper {
    padding-right: 200px;
  }
}

@media all and (max-width: 1300px) {
  .translate-wrapper {
    padding-right: 100px;
  }
}
</style>
