import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  DESTROY_PERSON_SUCCESS,
  GET_PEOPLE_ERROR,
  SET_CANCEL_TOKEN,
} from '../../actions/peopleActions/people';
import {
  ADD_LANGUAGE_BANNERS_ERROR,
  ADD_LANGUAGE_BANNERS_REQUEST,
  ADD_LANGUAGE_BANNERS_SUCCESS,
  ADD_LANGUAGE_ERROR,
  ADD_LANGUAGE_EV_GROUP_ERROR,
  ADD_LANGUAGE_EV_GROUP_REQUEST,
  ADD_LANGUAGE_EV_GROUP_SUCCESS,
  ADD_LANGUAGE_FEED_ERROR,
  ADD_LANGUAGE_FEED_REQUEST,
  ADD_LANGUAGE_FEED_SUCCESS,
  ADD_LANGUAGE_FOLDER_ERROR,
  ADD_LANGUAGE_FOLDER_REQUEST,
  ADD_LANGUAGE_FOLDER_SUCCESS,
  ADD_LANGUAGE_GENERAL_ERROR,
  ADD_LANGUAGE_GENERAL_REQUEST,
  ADD_LANGUAGE_GENERAL_SUCCESS,
  ADD_LANGUAGE_GRID_ERROR,
  ADD_LANGUAGE_GRID_REQUEST,
  ADD_LANGUAGE_GRID_SUCCESS,
  ADD_LANGUAGE_MEDIA_ERROR,
  ADD_LANGUAGE_MEDIA_REQUEST,
  ADD_LANGUAGE_MEDIA_SUCCESS,
  ADD_LANGUAGE_NAVIGATION_ERROR,
  ADD_LANGUAGE_NAVIGATION_REQUEST,
  ADD_LANGUAGE_NAVIGATION_SUCCESS,
  ADD_LANGUAGE_REQUEST,
  ADD_LANGUAGE_ROW_ITEMS_ERROR,
  ADD_LANGUAGE_ROW_ITEMS_REQUEST,
  ADD_LANGUAGE_ROW_ITEMS_SUCCESS,
  ADD_LANGUAGE_ROWS_ERROR,
  ADD_LANGUAGE_ROWS_REQUEST,
  ADD_LANGUAGE_ROWS_SUCCESS,
  ADD_LANGUAGE_SINGLE_EV_ERROR,
  ADD_LANGUAGE_SINGLE_EV_REQUEST,
  ADD_LANGUAGE_SINGLE_EV_SUCCESS,
  ADD_LANGUAGE_SUCCESS,
  EDIT_LANGUAGE_ERROR,
  EDIT_LANGUAGE_REQUEST,
  EDIT_LANGUAGE_SUCCESS,
  GET_LANGUAGE_BANNERS_ERROR,
  GET_LANGUAGE_BANNERS_REQUEST,
  GET_LANGUAGE_BANNERS_SUCCESS,
  GET_LANGUAGE_GENRES_REQUEST,
  GET_LANGUAGE_GENRES_SUCCESS,
  GET_LANGUAGE_GENRES_ERROR,
  ADD_LANGUAGE_GENRES_REQUEST,
  ADD_LANGUAGE_GENRES_SUCCESS,
  ADD_LANGUAGE_GENRES_ERROR,
  GET_LANGUAGE_ERROR,
  GET_LANGUAGE_EV_GROUP_ERROR,
  GET_LANGUAGE_EV_GROUP_REQUEST,
  GET_LANGUAGE_EV_GROUP_SUCCESS,
  GET_LANGUAGE_FEED_ERROR,
  GET_LANGUAGE_FEED_REQUEST,
  GET_LANGUAGE_FEED_SUCCESS,
  GET_LANGUAGE_FOLDER_ERROR,
  GET_LANGUAGE_FOLDER_REQUEST,
  GET_LANGUAGE_FOLDER_SUCCESS,
  GET_LANGUAGE_GENERAL_ERROR,
  GET_LANGUAGE_GENERAL_REQUEST,
  GET_LANGUAGE_GENERAL_SUCCESS,
  GET_LANGUAGE_GRID_ERROR,
  GET_LANGUAGE_GRID_REQUEST,
  GET_LANGUAGE_GRID_SUCCESS,
  GET_LANGUAGE_MEDIA_ERROR,
  GET_LANGUAGE_MEDIA_REQUEST,
  GET_LANGUAGE_MEDIA_SUCCESS,
  GET_LANGUAGE_NAVIGATION_ERROR,
  GET_LANGUAGE_NAVIGATION_REQUEST,
  GET_LANGUAGE_NAVIGATION_SUCCESS,
  GET_LANGUAGE_REQUEST,
  GET_LANGUAGE_ROW_ITEMS_ERROR,
  GET_LANGUAGE_ROW_ITEMS_REQUEST,
  GET_LANGUAGE_ROW_ITEMS_SUCCESS,
  GET_LANGUAGE_ROWS_ERROR,
  GET_LANGUAGE_ROWS_REQUEST,
  GET_LANGUAGE_ROWS_SUCCESS,
  GET_LANGUAGE_SINGLE_EV_ERROR,
  GET_LANGUAGE_SINGLE_EV_REQUEST,
  GET_LANGUAGE_SINGLE_EV_SUCCESS,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGES_ERROR,
  GET_LANGUAGES_REQUEST,
  GET_LANGUAGES_SELECT_ERROR,
  GET_LANGUAGES_SELECT_REQUEST,
  GET_LANGUAGES_SELECT_SUCCESS,
  GET_LANGUAGES_SUCCESS,
  INIT_FORM_CHANGES,
  REMOVE_LANGUAGE_ERROR,
  REMOVE_LANGUAGE_REQUEST,
  REMOVE_LANGUAGE_SUCCESS,
  SET_FORM_CHANGES,
  SET_FORM_CHANGES_SUCCESS,
} from '../../actions/localization/localizationActions';

const state = {
  isLoading: false,
  languages: [],
  pagination: {},
  language: {},
  source: null,
  mediaLanguage: {},
  languagesOptions: [],
  folderLanguage: {},
  isEditedForm: [],
  feedLanguage: {},
  cmsGeneralLanguage: {},
  cmsRowLanguage: {},
  cmsGridLanguage: {},
  cmsManuallyLanguage: {},
  bannerLanguage: {},
  navigationLanguage: {},
  evGroupLanguage: {},
  singleEvLanguage: {},
};

const getters = {
  getLanguages: () => state.languages.filter((item) => !!item.distribution),
};

const actions = {
  [GET_LANGUAGES_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    let url = `${params.accountId}/language?p=${params.page}&l=${params.limit || 15}&filterField=l.name&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url)
      .then((resp) => {
        commit(GET_LANGUAGES_SUCCESS, resp.data);
        localStorage.setItem('localizationPage', resp.data.pagination.page);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_LANGUAGES_ERROR);
      });
  }),
  [GET_LANGUAGE_MEDIA_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    const url = `${params.accountId}/media-collection/item/${params.mediaId}/language/${params.langId}`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_LANGUAGE_MEDIA_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_LANGUAGE_MEDIA_ERROR);
      });
  }),
  [ADD_LANGUAGE_MEDIA_REQUEST]: ({ commit }, {
    accountId, mediaId, langId, data,
  }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_MEDIA_REQUEST);
    const url = `${accountId}/media-collection/item/${mediaId}/language/${langId}`;

    apiCall.put(url, data)
      .then((res) => {
        commit(ADD_LANGUAGE_MEDIA_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_MEDIA_ERROR, error);
        reject(error);
      });
  }),
  [GET_LANGUAGE_FOLDER_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    const url = `${params.accountId}/media-collection/${params.type}/${params.id}/language/${params.langId}`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_LANGUAGE_FOLDER_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_LANGUAGE_FOLDER_ERROR);
      });
  }),
  [GET_LANGUAGE_FEED_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    const url = `${params.accountId}/feed/${params.feedId}/language/${params.langId}`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_LANGUAGE_FEED_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_LANGUAGE_FEED_ERROR);
      });
  }),
  // eslint-disable-next-line max-len
  [ADD_LANGUAGE_FOLDER_REQUEST]: ({ commit }, {
    accountId, type, id, langId, data,
  }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_FOLDER_REQUEST);
    const url = `${accountId}/media-collection/${type}/${id}/language/${langId}`;

    apiCall.put(url, data)
      .then((res) => {
        commit(ADD_LANGUAGE_FOLDER_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_FOLDER_ERROR, error);
        reject(error);
      });
  }),
  [ADD_LANGUAGE_FEED_REQUEST]: ({ commit }, {
    accountId, feedId, langId, data,
  }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_FEED_REQUEST);
    const url = `${accountId}/feed/${feedId}/language/${langId}`;

    apiCall.put(url, data)
      .then((res) => {
        commit(ADD_LANGUAGE_FEED_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_FEED_ERROR, error);
        reject(error);
      });
  }),
  [GET_LANGUAGES_SELECT_REQUEST]: ({ commit }) => new Promise((resolve) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    const url = '/choice/language';

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_LANGUAGES_SELECT_SUCCESS, resp.data);
        resolve();
      })
      .catch(() => {
        commit(GET_LANGUAGES_SELECT_ERROR);
      });
  }),
  [ADD_LANGUAGE_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_REQUEST);

    apiCall.post(`${accountId}/language`, data)
      .then((res) => {
        commit(ADD_LANGUAGE_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_ERROR, error);
        reject(error);
      });
  }),
  [GET_LANGUAGE_REQUEST]: ({ commit }, { accountId, langId }) => new Promise((resolve, reject) => {
    commit(GET_LANGUAGE_REQUEST);

    const url = `${accountId}/language/${langId}`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_LANGUAGE_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_LANGUAGE_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_LANGUAGE_REQUEST]:
    ({ commit }, { accountId, langId, data }) => new Promise((resolve, reject) => {
      commit(EDIT_LANGUAGE_REQUEST);

      apiCall.put(`${accountId}/language/${langId}`, data)
        .then((resp) => {
          commit(EDIT_LANGUAGE_SUCCESS, resp);
          resolve(resp);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(EDIT_LANGUAGE_ERROR, error);
          reject(error);
        });
    }),
  [REMOVE_LANGUAGE_REQUEST]:
    ({ commit }, { accountId, langId }) => new Promise((resolve, reject) => {
      commit(REMOVE_LANGUAGE_REQUEST);

      const url = `${accountId}/language/${langId}`;

      apiCall.delete(url)
        .then(() => {
          commit(REMOVE_LANGUAGE_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data.form_errors.children.enabled.errors.length;
          commit(REMOVE_LANGUAGE_ERROR, error);
          reject(error);
        });
    }),
  [GET_LANGUAGE_GENERAL_REQUEST]:
    ({ commit }, { accountId, langId, cmsPageId }) => new Promise((resolve, reject) => {
      commit(GET_LANGUAGE_GENERAL_REQUEST);

      const url = `${accountId}/cms/page/${cmsPageId}/language/${langId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_LANGUAGE_GENERAL_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_LANGUAGE_GENERAL_ERROR, error);
          reject(error);
        });
    }),
  [ADD_LANGUAGE_GENERAL_REQUEST]: ({ commit }, {
    accountId, cmsPageId, langId, data,
  }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_GENERAL_REQUEST);
    const url = `${accountId}/cms/page/${cmsPageId}/language/${langId}`;

    apiCall.put(url, data)
      .then((res) => {
        commit(ADD_LANGUAGE_GENERAL_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_GENERAL_ERROR, error);
        reject(error);
      });
  }),
  [GET_LANGUAGE_ROWS_REQUEST]:
    ({ commit }, { accountId, langId, cmsRowId }) => new Promise((resolve, reject) => {
      commit(GET_LANGUAGE_ROWS_REQUEST);

      const url = `${accountId}/cms/row/${cmsRowId}/language/${langId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_LANGUAGE_ROWS_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_LANGUAGE_ROWS_ERROR, error);
          reject(error);
        });
    }),
  [ADD_LANGUAGE_ROWS_REQUEST]: ({ commit }, {
    accountId, cmsRowId, langId, data,
  }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_ROWS_REQUEST);
    const url = `${accountId}/cms/row/${cmsRowId}/language/${langId}`;

    apiCall.put(url, data)
      .then((res) => {
        commit(ADD_LANGUAGE_ROWS_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_ROWS_ERROR, error);
        reject(error);
      });
  }),
  [GET_LANGUAGE_GRID_REQUEST]:
    ({ commit }, { accountId, langId, cmsTabId }) => new Promise((resolve, reject) => {
      commit(GET_LANGUAGE_GRID_REQUEST);

      const url = `${accountId}/cms/tab/${cmsTabId}/language/${langId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_LANGUAGE_GRID_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_LANGUAGE_GRID_ERROR, error);
          reject(error);
        });
    }),
  [ADD_LANGUAGE_GRID_REQUEST]: ({ commit }, {
    accountId, cmsTabId, langId, data,
  }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_GRID_REQUEST);
    const url = `${accountId}/cms/tab/${cmsTabId}/language/${langId}`;

    apiCall.put(url, data)
      .then((res) => {
        commit(ADD_LANGUAGE_GRID_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_GRID_ERROR, error);
        reject(error);
      });
  }),
  [GET_LANGUAGE_ROW_ITEMS_REQUEST]:
    ({ commit }, { accountId, langId, cmsRowItemsId }) => new Promise((resolve, reject) => {
      commit(GET_LANGUAGE_ROW_ITEMS_REQUEST);

      const url = `${accountId}/cms/row-item/${cmsRowItemsId}/language/${langId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_LANGUAGE_ROW_ITEMS_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_LANGUAGE_ROW_ITEMS_ERROR, error);
          reject(error);
        });
    }),
  [ADD_LANGUAGE_ROW_ITEMS_REQUEST]: ({ commit }, {
    accountId, cmsRowItemsId, langId, data,
  }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_ROW_ITEMS_REQUEST);
    const url = `${accountId}/cms/row-item/${cmsRowItemsId}/language/${langId}`;

    apiCall.put(url, data)
      .then((res) => {
        commit(ADD_LANGUAGE_ROW_ITEMS_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_ROW_ITEMS_ERROR, error);
        reject(error);
      });
  }),
  [INIT_FORM_CHANGES]: ({ commit }, value) => new Promise(() => {
    commit(INIT_FORM_CHANGES, value);
  }),
  [SET_FORM_CHANGES]: ({ commit }, value) => new Promise(() => {
    commit(SET_FORM_CHANGES_SUCCESS, value);
  }),
  [GET_LANGUAGE_BANNERS_REQUEST]:
    ({ commit }, { accountId, langId, bannerId }) => new Promise((resolve, reject) => {
      commit(GET_LANGUAGE_BANNERS_REQUEST);

      const url = `${accountId}/cms/banner/${bannerId}/language/${langId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_LANGUAGE_BANNERS_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_LANGUAGE_BANNERS_ERROR, error);
          reject(error);
        });
    }),
  [ADD_LANGUAGE_BANNERS_REQUEST]: ({ commit }, {
    accountId, bannerId, langId, data,
  }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_BANNERS_REQUEST);
    const url = `${accountId}/cms/banner/${bannerId}/language/${langId}`;

    apiCall.put(url, data)
      .then((res) => {
        commit(ADD_LANGUAGE_BANNERS_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_BANNERS_ERROR, error);
        reject(error);
      });
  }),
  [GET_LANGUAGE_GENRES_REQUEST]:
    ({ commit }, { accountId, langId, genreId }) => new Promise((resolve, reject) => {
      commit(GET_LANGUAGE_GENRES_REQUEST);

      const url = `${accountId}/taxonomy/genre/${genreId}/language/${langId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_LANGUAGE_GENRES_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_LANGUAGE_GENRES_ERROR, error);
          reject(error);
        });
    }),
  [ADD_LANGUAGE_GENRES_REQUEST]: ({ commit }, {
    accountId, genreId, langId, data,
  }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_GENRES_REQUEST);
    const url = `${accountId}/taxonomy/genre/${genreId}/language/${langId}`;

    apiCall.put(url, data)
      .then((res) => {
        commit(ADD_LANGUAGE_GENRES_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_GENRES_ERROR, error);
        reject(error);
      });
  }),
  [GET_LANGUAGE_NAVIGATION_REQUEST]:
    ({ commit }, { accountId, langId, navId }) => new Promise((resolve, reject) => {
      commit(GET_LANGUAGE_NAVIGATION_REQUEST);

      const url = `${accountId}/cms/navigation-item/${navId}/language/${langId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_LANGUAGE_NAVIGATION_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_LANGUAGE_NAVIGATION_ERROR, error);
          reject(error);
        });
    }),
  [ADD_LANGUAGE_NAVIGATION_REQUEST]: ({ commit }, {
    accountId, navId, langId, data,
  }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_NAVIGATION_REQUEST);
    const url = `${accountId}/cms/navigation-item/${navId}/language/${langId}`;

    apiCall.put(url, data)
      .then((res) => {
        commit(ADD_LANGUAGE_NAVIGATION_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_NAVIGATION_ERROR, error);
        reject(error);
      });
  }),
  [GET_LANGUAGE_EV_GROUP_REQUEST]:
    ({ commit }, { accountId, langId, evGroupId }) => new Promise((resolve, reject) => {
      commit(GET_LANGUAGE_EV_GROUP_REQUEST);

      const url = `${accountId}/standalone-event-group/${evGroupId}/language/${langId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_LANGUAGE_EV_GROUP_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_LANGUAGE_EV_GROUP_ERROR, error);
          reject(error);
        });
    }),
  [ADD_LANGUAGE_EV_GROUP_REQUEST]: ({ commit }, {
    accountId, evGroupId, langId, data,
  }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_EV_GROUP_REQUEST);

    const url = `${accountId}/standalone-event-group/${evGroupId}/language/${langId}`;

    apiCall.put(url, data)
      .then((res) => {
        commit(ADD_LANGUAGE_EV_GROUP_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_EV_GROUP_ERROR, error);
        reject(error);
      });
  }),
  [GET_LANGUAGE_SINGLE_EV_REQUEST]:
    ({ commit }, { accountId, langId, evGroupId }) => new Promise((resolve, reject) => {
      commit(GET_LANGUAGE_SINGLE_EV_REQUEST);

      const url = `${accountId}/standalone-event/${evGroupId}/language/${langId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_LANGUAGE_SINGLE_EV_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_LANGUAGE_SINGLE_EV_ERROR, error);
          reject(error);
        });
    }),
  [ADD_LANGUAGE_SINGLE_EV_REQUEST]: ({ commit }, {
    accountId, evGroupId, langId, data,
  }) => new Promise((resolve, reject) => {
    commit(ADD_LANGUAGE_SINGLE_EV_REQUEST);

    const url = `${accountId}/standalone-event/${evGroupId}/language/${langId}`;

    apiCall.put(url, data)
      .then((res) => {
        commit(ADD_LANGUAGE_SINGLE_EV_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LANGUAGE_SINGLE_EV_ERROR, error);
        reject(error);
      });
  }),
};

const mutations = {
  [GET_LANGUAGES_SUCCESS]: (state, payload) => {
    state.languages = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  [GET_LANGUAGES_SELECT_SUCCESS]: (state, payload) => {
    state.languagesOptions = payload.data;
  },
  [ADD_LANGUAGE_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LANGUAGE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_LANGUAGE_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_LANGUAGE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_LANGUAGE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_LANGUAGE_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_LANGUAGE_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.language = payload;
  },
  [GET_LANGUAGE_ERROR]: () => {
    state.isLoading = false;
  },
  [DESTROY_PERSON_SUCCESS]: () => {
    state.person = {};
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
  },
  [REMOVE_LANGUAGE_REQUEST]: () => {
    state.isLoading = true;
  },
  [REMOVE_LANGUAGE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [REMOVE_LANGUAGE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_LANGUAGE_MEDIA_SUCCESS]: (state, payload) => {
    state.mediaLanguage = payload;
  },
  [ADD_LANGUAGE_MEDIA_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LANGUAGE_MEDIA_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_MEDIA_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_PEOPLE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_LANGUAGE_FOLDER_SUCCESS]: (state, payload) => {
    state.folderLanguage = payload;
  },
  [GET_LANGUAGE_FEED_SUCCESS]: (state, payload) => {
    state.feedLanguage = payload;
  },
  [ADD_LANGUAGE_FOLDER_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LANGUAGE_FOLDER_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_FOLDER_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_FEED_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LANGUAGE_FEED_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_FEED_ERROR]: () => {
    state.isLoading = false;
  },
  [INIT_FORM_CHANGES]: (state, payload) => {
    state.isEditedForm = payload;
  },
  [GET_LANGUAGE_GENERAL_SUCCESS]: (state, payload) => {
    state.cmsGeneralLanguage = payload;
  },
  [GET_LANGUAGE_ROWS_SUCCESS]: (state, payload) => {
    state.cmsRowLanguage = payload;
  },
  [GET_LANGUAGE_GRID_SUCCESS]: (state, payload) => {
    state.cmsGridLanguage = payload;
  },
  [GET_LANGUAGE_ROW_ITEMS_SUCCESS]: (state, payload) => {
    state.cmsManuallyLanguage = payload;
  },
  [ADD_LANGUAGE_GENERAL_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LANGUAGE_GENERAL_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_GENERAL_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_ROWS_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LANGUAGE_ROWS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_ROWS_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_GRID_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LANGUAGE_GRID_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_GRID_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_ROW_ITEMS_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LANGUAGE_ROW_ITEMS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_ROW_ITEMS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_LANGUAGE_BANNERS_SUCCESS]: (state, payload) => {
    state.bannerLanguage = payload;
  },
  [ADD_LANGUAGE_BANNERS_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LANGUAGE_BANNERS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_BANNERS_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_NAVIGATION_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LANGUAGE_NAVIGATION_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_NAVIGATION_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_LANGUAGE_NAVIGATION_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_LANGUAGE_BANNERS_SUCCESS]: (state, payload) => {
    state.navigationLanguage = payload;
    state.isLoading = false;
  },
  [GET_LANGUAGE_NAVIGATION_ERROR]: () => {
    state.isLoading = true;
  },
  [GET_LANGUAGE_FOLDER_REQUEST]: () => {
    state.isLoading = true;
  },
  [SET_FORM_CHANGES_SUCCESS]: (state, payload) => {
    state.isEditedForm = state.isEditedForm && state.isEditedForm.map((item) => {
      if (item.id === payload.id) {
        return {
          id: item.id,
          value: payload.value,
        };
      }
      return item;
    });
  },
  [GET_LANGUAGE_EV_GROUP_SUCCESS]: (state, payload) => {
    state.evGroupLanguage = payload;
  },
  [GET_LANGUAGE_SINGLE_EV_SUCCESS]: (state, payload) => {
    state.singleEvLanguage = payload;
  },
  [ADD_LANGUAGE_SINGLE_EV_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LANGUAGE_SINGLE_EV_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_SINGLE_EV_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_LANGUAGE_GENRES_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_LANGUAGE_GENRES_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_LANGUAGE_GENRES_ERROR]: () => {
    state.isLoading = false;
  },

  [ADD_LANGUAGE_GENRES_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LANGUAGE_GENRES_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_LANGUAGE_GENRES_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
