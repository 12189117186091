<template>
  <div :class="['an-tabs', className]">
    <div
      v-for="item in items"
      :key="item.code"
      :class="['an-tab', selectedItem === item.code ? 'an-tab-selected' : '']"
      @click="onItemSelect(item.code)"
    >
      {{ item.title }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'TabsButton',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    onItemSelect: {
      type: Function,
      default: () => {},
    },
    selectedItem: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
  .an-tabs {
    display: flex;
  }

  .an-tab {
    background-color: #3e3f40;
    color: #a0a0a0;
    cursor: pointer;
    padding: 10px 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
  }

  .an-tab-selected {
    background-color: #2591cf;
    border-bottom: 0;
    color: #fff;
  }

  .an-tab:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .an-tab:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .an-tab:not(:last-child) {
    margin-right: 1px;
  }

  .geo-and-devices {
    margin-bottom: 15px;
  }
</style>
