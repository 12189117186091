export const ADD_FEED_REQUEST = 'ADD_FEED_REQUEST';
export const ADD_FEED_SUCCESS = 'ADD_FEED_SUCCESS';
export const ADD_FEED_ERROR = 'ADD_FEED_ERROR';

export const GET_ALL_FEEDS_REQUEST = 'GET_ALL_FEEDS_REQUEST';
export const GET_ALL_FEEDS_SUCCESS = 'GET_ALL_FEEDS_SUCCESS';
export const GET_ALL_FEEDS_ERROR = 'GET_ALL_FEEDS_ERROR';

export const GET_MEDIA_ITEMS = 'GET_MEDIA_ITEMS';
export const GET_MEDIA_ITEMS_SUCCESS = 'GET_MEDIA_ITEMS_SUCCESS';
export const GET_MEDIA_ITEMS_ERROR = 'GET_MEDIA_ITEMS_ERROR';

export const SET_CANCEL_TOKEN = 'SET_CANCEL_TOKEN';
export const GET_FIXED_MEDIA_ITEMS = 'GET_FIXED_MEDIA_ITEMS';
export const GET_FIXED_MEDIA_ITEMS_REQUEST = 'GET_FIXED_MEDIA_ITEMS_REQUEST';
export const GET_FIXED_MEDIA_ITEMS_SUCCESS = 'GET_FIXED_MEDIA_ITEMS_SUCCESS';
export const GET_FIXED_MEDIA_ITEMS_ERROR = 'GET_FIXED_MEDIA_ITEMS_ERROR';

export const GET_FEED = 'GET_FEED';
export const GET_FEED_REQUEST = 'GET_FEED_REQUEST';
export const GET_FEED_SUCCESS = 'GET_FEED_SUCCESS';
export const GET_FEED_ERROR = 'GET_FEED_ERROR';

export const EDIT_FEED = 'EDIT_FEED';
export const EDIT_FEED_REQUEST = 'EDIT_FEED_REQUEST';
export const EDIT_FEED_SUCCESS = 'EDIT_FEED_SUCCESS';
export const EDIT_FEED_ERROR = 'EDIT_FEED_ERROR';

export const GET_ITUNES_CATERORIES_REQUEST = 'GET_ITUNES_CATERORIES_REQUEST';
export const GET_ITUNES_CATERORIES_SUCCESS = 'GET_ITUNES_CATERORIES_SUCCESS';
export const GET_ITUNES_CATERORIES_ERROR = 'GET_ITUNES_CATERORIES_ERROR';

export const GET_ITUNES_SUBCATERORIES_REQUEST = 'GET_ITUNES_SUBCATERORIES_REQUEST';
export const GET_ITUNES_SUBCATERORIES_SUCCESS = 'GET_ITUNES_SUBCATERORIES_SUCCESS';
export const GET_ITUNES_SUBCATERORIES_ERROR = 'GET_ITUNES_SUBCATERORIES_ERROR';

export const GET_LIVE_EVENTS_REQUEST = 'GET_LIVE_EVENTS_REQUEST';
export const GET_LIVE_EVENTS_SUCCESS = 'GET_LIVE_EVENTS_SUCCESS';
export const GET_LIVE_EVENTS_ERROR = 'GET_LIVE_EVENTS_ERROR';
