<template>
  <div
    v-if="items"
    class="mobile-drawer-container"
  >
    <div
      v-for="item in items"
      :key="item.title"
      link
    >
      <div class="items-list">
        <router-link
          :to="item.link === '/media/folder/all' ? `/media/folder/${mediaFolderId}` : item.link"
          :class="['navigation-link',
                   { 'router-link-active': item.activeLink && item.activeLink(currentPage) }
          ]"
          :event="moving"
        >
          <div
            class="list-item-icon"
            :title="item.title"
          >
            <inline-svg
              :src="item.icon"
              class="svg-icon"
            >
            </inline-svg>
          </div>
          <div class="list-item-content">
            <p class="item-title-header">
              {{ item.title }}
            </p>
          </div>
        </router-link>
      </div>
      <div class="sub-list-container">
        <div
          v-for="i in item.subItems"
          :key="i.title"
        >
          <div
            v-if="onCheck(i)"
            class="sub-item"
          >
            <router-link
              class="navigation-link"
              :to="i.link"
              :event="moving"
            >
              <div class="subL-list-item">
                <p class="item-title-header">
                  {{ i.title }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import { AUTH_LOGOUT } from '../../../store/actions/authActions/auth';

export default {
  name: 'MobileMenuDrawer',
  props: {
    mediaFolderId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState({
      isEditedForm: (state) => state.common.isEditedForm,
      menuItems: (state) => state.common.menuItems,
      role: (state) => state.common.role,
    }),
    ...mapGetters([
      'isAccountActive',
      'getCurrentAccount',
    ]),
    items() {
      if (this.role) {
        return this.menuItems.filter((item) => {
          if (item.subItems) {
            item.subItems = item.subItems.filter((i) => i.roles.includes(this.role) || i.roles.includes('*'));
          }
          return item.roles.includes(this.role) || item.roles.includes('*');
        });
      }
      return null;
    },
    moving() {
      return this.isAccountActive ? 'click' : '';
    },
    currentPage() {
      return this.$route.path;
    },
  },
  methods: {
    onCheck(item) {
      if (item.check) {
        if (item.link === '/schedule/apps-event-group') {
          return item.check === this.getCurrentAccount.tvAppsEnabled;
        }
      }
      return true;
    },
    logout() {
      if (this.isEditedForm) {
        this.$router.push('/login').catch(() => {
          this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push(
            {
              path: '/login',
              force: true,
            },
          ));
        });
      } else {
        this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.go(
          {
            path: '/login',
            force: true,
          },
        ));
      }

      this.closeDropDown();
    },
  },
};
</script>
<style scoped>
  .mobile-drawer-container {
    margin-top: 10px;
  }

  .router-link-active {
    color: #fff !important;
  }

  .router-link-active .svg-icon path {
    fill: #2591cf;
  }

  .navigation-link {
    align-items: center;
    color: rgba(255, 255, 255, 0.65);
    display: flex;
    font-size: 14px;
    text-decoration: none;
    width: 100%;
  }

  .items-list {
    align-items: center;
    display: flex;
    outline: none;
    padding: 0 25px;
    position: relative;
  }

  .list-item-icon {
    width: 30px;
  }

  .item-title-header {
    align-self: center;
    flex: 1 1 100%;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .items-list:hover,
  .sub-item:hover {
    background-color: #383b4026;
  }

  .sub-item {
    padding-left: 55px;
  }

  .list-item-content {
    align-items: center;
    align-self: center;
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 5px 0;
  }
</style>
