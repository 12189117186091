export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_ALL_EVENTS_REQUEST = 'GET_ALL_EVENTS_REQUEST';
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
export const GET_ALL_EVENTS_ERROR = 'GET_ALL_EVENTS_ERROR';

export const GET_EVENT = 'GET_EVENT';
export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR';

export const ADD_EVENT = 'ADD_EVENT';
export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_ERROR = 'ADD_EVENT_ERROR';

export const EDIT_EVENT = 'EDIT_EVENT';
export const EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_ERROR = 'EDIT_EVENT_ERROR';

export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';

export const GET_COUNT_EVENTS_IN_GROUP = 'GET_COUNT_EVENTS_IN_GROUP';
export const GET_COUNT_EVENTS_IN_GROUP_REQUEST = 'GET_COUNT_EVENTS_IN_GROUP_REQUEST';
export const GET_COUNT_EVENTS_IN_GROUP_SUCCESS = 'GET_COUNT_EVENTS_IN_GROUP_SUCCESS';
export const GET_COUNT_EVENTS_IN_GROUP_ERROR = 'GET_COUNT_EVENTS_IN_GROUP_ERROR';

export const GET_CUSTOM_FIELDS_EVENT_REQUEST = 'GET_CUSTOM_FIELDS_EVENT_REQUEST';
export const GET_CUSTOM_FIELDS_EVENT_SUCCESS = 'GET_CUSTOM_FIELDS_EVENT_SUCCESS';
export const GET_CUSTOM_FIELDS_EVENT_ERROR = 'GET_CUSTOM_FIELDS_EVENT_ERROR';
