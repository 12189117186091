<template>
  <div class="form-container">
    <MaterialInput
      v-model="evGroup.name"
      v-bind="evGroup.name"
      :label="isGroup ? 'Event Group Title' : 'Event Title'"
      :disabled="true"
    />
    <MaterialTextArea
      v-if="!isGroup"
      v-model="evGroup.description"
      v-bind="evGroup.description"
      :label="'Event Description'"
      :disabled="true"
    />
    <div v-if="!isIndividualEvent">
      <div class="poster-title">
        Countdown & Preview Image
      </div>
      <PosterImage
        ref="posterImage"
        :poster="evGroup.backgroundUrl ? `${evGroup.backgroundUrl}?height=360` : ''"
        :isNeedRemove="false"
        :disabled="true"
        :disabledText="'No Image to Display'"
      />
    </div>
  </div>
</template>

<script>
import MaterialInput from '../../inputs/MaterialInput.vue';
import PosterImage from '../../common/PosterImage/DragAndDropFileUploader.vue';
import MaterialTextArea from '../../inputs/MaterialTextArea.vue';

export default {
  name: 'EvGroupEngForm',
  components: {
    MaterialInput,
    MaterialTextArea,
    PosterImage,
  },
  props: {
    evGroup: {
      type: Object,
      default: () => {},
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    isIndividualEvent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.form-container {
  padding: 32px 70px 20px 0;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}
</style>
