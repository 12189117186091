<script>
import { mapGetters } from 'vuex';
import {
  GET_MEDIA_TAGS,
  ADD_MEDIA_TAG,
  GET_SEARCH_TAGS_REQUEST,
  ADD_SEARCH_TAGS_REQUEST,
} from '../../store/actions/media/mediaTag';
import TagManagement from '../inputs/TagManagement.vue';
import { MEDIA_TAG_REG_EXP } from '../../utils/validation/regExp';

export default {
  name: 'MediaTagManagement',
  extends: TagManagement,
  props: {
    isAudio: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isJustSearch: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      filterValue: '',
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  created() {
    if (!this.isAudio) {
      this.getTags();
    } else {
      this.options = this.customOptions;
    }
  },
  methods: {
    createTag(value) {
      if (this.isJustSearch) {
        return;
      }

      const request = this.isSearch ? ADD_SEARCH_TAGS_REQUEST : ADD_MEDIA_TAG;
      const data = {
        accountId: this.getCurrentAccountId,
        name: value,
      };

      if (!this.isNotValidValue) {
        this.$store.dispatch(request, data)
          .then((res) => {
            const tag = {
              code: res.id,
              name: value,
            };

            this.addTag(tag);
          });
      }
    },
    getTags() {
      let result = [];
      const request = this.isSearch ? GET_SEARCH_TAGS_REQUEST : GET_MEDIA_TAGS;

      const data = {
        accountId: this.getCurrentAccountId,
        filterValue: this.filterValue,
      };

      this.$store.dispatch(request, data)
        .then((res) => {
          result = res.data.map((item) => ({
            code: item.id,
            name: item.name,
          }));
          this.options = result;
        });

      return result;
    },
    validateSearch(searchValue) {
      const validation = {
        value: searchValue,
        isValid: true,
        message: '',
      };

      if (searchValue && !searchValue.match(MEDIA_TAG_REG_EXP)) {
        validation.isValid = false;
        validation.message = 'The Tag field may only contain alphabetic characters';
      } else if (searchValue.length > 50) {
        validation.isValid = false;
        validation.message = 'The field can have no more than 50 characters';
      }

      return validation;
    },
    processSearchValue(value) {
      this.filterValue = value;
      if (!this.isAudio) {
        // this.getTags();
      }
    },
    onInput(value) {
      this.resetValidation();
      const result = value.map((item) => (item.code));
      this.onChange(result);
    },
  },
};
</script>
