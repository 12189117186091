<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <div
      v-if="cmsPage && cmsPage.name"
      class="breadcrumbs"
    >
      <span class="system">
        Video Apps Engine / Pages
      </span>
      <span v-if="!isEdit">
        / {{ cmsPage.name }} / Add Sorting Tab
      </span>
      <span
        v-else
        :title="formData.rowHeading"
      >
        / {{ cmsPage.name }} / Edit {{ getName(formData.name) }}
      </span>
    </div>
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="title">
        {{ pageTitle }}
      </div>
      <div class="edit-button-wrapper">
        <Button
          v-if="isEdit"
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="onOpenModal"
        />
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backToCms"
        />
        <Button
          :title="isEdit ? 'Apply' : 'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="localization-wrapper">
        <div class="label">
          <MaterialInput
            v-model="formData.name"
            v-bind="formFields.name"
          />
        </div>
        <div v-if="enabledLanguages.length">
          <Button
            :title="'Localization'"
            :classType="'primary'"
            :srcSvg="iconFolderLocalization"
            :svgClass="'folder-localization-icon'"
            :eventFunction="toLocalization"
            :isReverseIcon="true"
            :disabled="!isEdit"
          />
        </div>
      </div>
      <div
        v-if="isGenreManagementAvailabe"
        class="genres-management-wrapper"
      >
        <GenresManagement
          label="Select Genres"
          :selectedValue="formData.genres"
          :onChange="handleGenreChange"
          :commonError="genresError"
          :required="true"
        />
      </div>
      <div
        v-if="!isEdit && enabledLanguages.length"
        class="localization-warning-wrapper"
      >
        <inline-svg
          :src="iconWarning"
          class="svg-icon"
        >
        </inline-svg>
        <span class="localization-warning">
          Localization will be available after the Tab has been saved
        </span>
      </div>
      <div class="rows">
        <div class="select-wrapper">
          <MaterialSelect
            v-model="formData.tabFunction"
            v-bind="formFields.tabFunction"
            :options="optionsTabFunc"
            :withEmptyField="false"
            :disabled="true"
          />
        </div>
        <div class="select-wrapper">
          <MaterialSelect
            v-model="formData.sortOption"
            v-bind="formFields.sortOption"
            :options="sortOptions"
            :withEmptyField="false"
          />
        </div>
      </div>
      <NotificationModal
        ref="modal"
        v-model="isModalOpen"
        :modal="isModalOpen"
        :bodyMessage="bodyMessage"
        :title="'Delete Tab'"
        :onAccept="removeRow"
        :onClose="onClose"
      />
      <CmsGeneralLocalization
        v-if="enabledLanguages.length"
        v-model="isOpenLocalization"
        :languages="enabledLanguages"
        :modal="isOpenLocalization"
        :isGridTranslate="true"
        :onClose="onCloseLocalizationModal"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import NotificationModal from '../../NotificationModal.vue';
import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';
import iconWarning from '../../../assets/icons/icon-warning-localization.svg';
import CmsGeneralLocalization from '../../localization/CmsGeneralLocalization.vue';
import GenresManagement from '../../inputs/GenresManagement.vue';

import {
  CREATE_TAB_REQUEST,
  EDIT_TAB_REQUEST,
  GET_PAGE_REQUEST,
  GET_TAB_REQUEST,
  GET_TABS_LIST_REQUEST,
  REMOVE_TAB_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../../store/actions/common';
import { GET_LANGUAGES_REQUEST } from '../../../store/actions/localization/localizationActions';

export default {
  name: 'GridTabForm',
  components: {
    MaterialSelect,
    MaterialInput,
    Button,
    NotificationModal,
    CmsGeneralLocalization,
    GenresManagement,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'gridTabForm',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortOptions: [
        {
          code: 'chronological',
          name: 'Chronological',
        },
        {
          code: 'reverse_chronological',
          name: 'Reverse Chronological',
        },
        {
          code: 'alphabetical',
          name: 'Alphabetical',
        },
      ],
      optionsTabFunc: [
        {
          code: 'sort',
          name: 'Sort',
        },
        {
          code: 'filter',
          name: 'Filter',
        },
      ],
      iconWarning,
      iconFolderLocalization,
      enabledLanguages: [],
      isModalOpen: false,
      isOpenLocalization: false,
      bodyMessage: CONFIG.modals.deleteTabInCms,
      rowsList: [],
      cmsTabsList: {},
      cmsPage: {},
      formData: {
        name: '',
        sortOption: 'chronological',
        tabFunction: 'sort',
        sortOrder: 0,
        genres: [],
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 25,
          },
        },
        sortOption: {
          type: 'text',
          name: 'sortOption',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        tabFunction: {
          type: 'text',
          name: 'tabFunction',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        genres: [
          `${this.formPrefix}.genres`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_TAB_REQUEST : CREATE_TAB_REQUEST,
      successMessage: this.isEdit
        ? 'The Tab has been successfully updated'
        : 'The Tab has been successfully added',
      errorMessage: CONFIG.errorMessages.commonServerError,
      genresError: '',
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    pageTitle() {
      if (!this.formData.tabFunction) {
        return '';
      }

      if (!this.isEdit) {
        return 'Add Sorting Tab';
      }

      const { tabFunction } = this.formData;
      const tabFunctionName = tabFunction === 'sort' ? 'Sorting' : 'Filtering';

      return `${tabFunctionName} Tab Editor`;
    },
    isGenreManagementAvailabe() {
      return this.formData.tabFunction === 'filter';
    },
  },
  mounted() {
    const paramsGet = {
      accountId: this.getCurrentAccountId,
      cmsPageId: this.$route.params.id,
    };
    this.$store.dispatch(GET_TABS_LIST_REQUEST, paramsGet).then((res) => {
      this.cmsTabsList = res.data;
    });

    const paramsLang = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };
    this.$store.dispatch(GET_LANGUAGES_REQUEST, paramsLang).then((res) => {
      this.enabledLanguages = res.data;
    });

    const paramsForCms = {
      accountId: this.getCurrentAccountId,
      cmsPageId: this.$route.params.id,
    };
    this.$store.dispatch(GET_PAGE_REQUEST, paramsForCms).then((res) => {
      this.cmsPage = res;
    });

    if (this.isEdit && this.$route.params.tabId) {
      const params = {
        accountId: this.getCurrentAccountId,
        cmsTabId: this.$route.params.tabId,
      };

      this.$store.dispatch(GET_TAB_REQUEST, params)
        .then((res) => this.initFormData(res));
    }
  },
  methods: {
    initFormData(item) {
      this.formData.name = item.name;
      this.formData.sortOption = item.sortOption;
      this.formData.tabFunction = item.tabFunction;
      this.formData.sortOrder = item.sortOrder;

      if (item.tabFunction === 'filter') {
        this.formData.genres = item.genres;
      }
    },
    handleRecorderToggle() {
      if (!this.formData.isToggleRecord) {
        this.isRecorderToggleOpen = true;
      }
    },
    handleGenreChange(value) {
      this.formData.genres = value;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.genresError = '';
    },
    onCloseLocalizationModal() {
      this.isOpenLocalization = false;
    },
    toLocalization() {
      this.isOpenLocalization = true;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    backToCms() {
      this.$router.push(`/cms/pages/${this.$route.params.id}`);
    },
    onOpenModal() {
      this.isModalOpen = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    getName(name) {
      return name.length > 45 ? `${name.slice(0, 45)}...` : name;
    },
    removeRow() {
      const params = {
        accountId: this.getCurrentAccountId,
        tabId: this.$route.params.tabId,
      };

      this.$store.dispatch(REMOVE_TAB_REQUEST, params)
        .then(() => {
          setTimeout(() => {
            this.backToCms();
          }, CONFIG.routing.redirectFromAddAcc);
          this.$toasted.global.success({
            message: 'The Tab has been successfully deleted',
          });
        });
    },
    getRequestData() {
      const data = {
        grid: this.cmsPage.grid && this.cmsPage.grid.id,
        name: this.formData.name,
        tabFunction: this.formData.tabFunction,
        sortOption: this.formData.sortOption,
        sortOrder: this.cmsTabsList.length ? this.cmsTabsList.length + 1 : 1,
      };

      if (this.isEdit) {
        data.sortOrder = this.formData.sortOrder;
      }

      if (this.formData.tabFunction === 'filter') {
        data.genres = this.formData.genres.map((genre) => genre.id);
      }

      return {
        data,
        accountId: this.getCurrentAccountId,
        cmsPageId: this.$route.params.id,
        cmsTabId: this.$route.params.tabId,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.backToCms();
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      const genresError = error.form_errors?.children?.genres?.errors?.[0].error === 'IS_BLANK_ERROR';

      if (genresError) {
        this.genresError = 'At least one Genre must be selected';
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>

.rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.toggles-wrapper {
  display: flex;
}

.toggle-wrapper,
.date-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
}

.toggle-wrapper {
  width: auto;
}

.toggle-title {
  margin-right: 10px;
}

.dynamic {
  margin-top: 30px;
  margin-bottom: 30px;
}

.edit-button-wrapper {
  position: absolute;
  top: 75px;
  display: flex;
  right: 30px;
}

.country-field {
  width: 300px;
}

.country-field:not(:last-child) {
  margin-right: 20px;
}

.wowza p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.wrapper-small {
  margin-bottom: 20px;
}

.flex {
  justify-content: space-between;
}

.person {
  margin-bottom: 20px;
}

.person p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.select-wrapper {
  width: 300px;
}

.system {
  color: #4c5057;
}

.breadcrumbs {
  margin-bottom: 25px;
}

.localization-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  width: 400px;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 12px;
}

.localization-warning {
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}

.genres-management-wrapper {
  margin-bottom: 20px;
}

@media all and (max-width: 1320px) {
  .flex {
    flex-wrap: wrap;
  }

  .country-field {
    margin-right: 0;
    width: 100%;
  }

  .country-field:not(:last-child) {
    margin-right: 0;
  }
}
</style>
