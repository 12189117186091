<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="btn-create-account-mobile">
      <Button
        :title="'Add Asset Role'"
        :classType="'primary'"
        :eventFunction="toCreateAccHandler"
      />
    </div>
    <div class="flex">
      <div class="left-path">
        <span class="system">
          System Management
        </span>
        <span>
          / Asset Roles
        </span>
      </div>
      <div class="right-path">
        <div :style="isManager ? {marginRight: '-22px'} :null ">
          <LiveSearch
            :onSearch="onSearch"
            :maxlength="255"
          />
        </div>
        <div
          v-if="!isManager"
          class="btn-create-account-full"
        >
          <Button
            :title="'Add Asset Role'"
            :classType="'primary'"
            :eventFunction="toCreateAccHandler"
          />
        </div>
      </div>
    </div>
    <div class="accounts-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`ar.${sortedField.name}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          name
        </div>
        <div class="th-name company">
          short name
        </div>
        <div class="th-name users-count">
          <Sorting
            :sortField="`ar.${sortedField.updatedAt}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          last edited
        </div>
        <div class="th-name users-count">
          Modified By
        </div>
        <div class="th-name status">
          <Sorting
            :sortField="`ar.${sortedField.enabled}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          status
        </div>
      </div>
      <div
        v-if="!assetRoles.length"
        class="empty-search"
      >
        No items to display
      </div>
      <div
        v-for="(item, key) in assetRoles"
        :key="item.id"
        class="account"
        @mouseover="onHoverElem(item)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div
          class="td company"
          :title="item.name"
        >
          {{ item.name }}
        </div>
        <div
          class="td company"
          :title="item.industry"
        >
          {{ item.shortName }}
        </div>
        <div class="td users-count">
          {{ item.updatedAt | cmsDate(profile.timezone) }}
        </div>
        <div class="td users-count">
          {{ (item.updatedBy && item.updatedBy.fullName) || '-' }}
        </div>
        <div class="td status">
          <div
            class="status-ellipse"
            :class="item.enabled ? 'enabled' : 'disabled'"
          >
          </div>
          <span v-if="item.enabled">
            Enabled
          </span>
          <span v-else>
            Disabled
          </span>
        </div>
        <div
          v-show="isHover && item.id === selectedId"
          class="icons-wrapper"
        >
          <div
            v-if="isManager ? false : (item.canDelete || item.deleteAfter)"
            :title="'Delete Asset Role'"
          >
            <inline-svg
              :src="iconRemove"
              class="svg-icon remove"
              @click="onOpenModalForRemove(item)"
            >
            </inline-svg>
          </div>
          <div
            v-if="isManager ? false : item.canDisable"
            :title="item.enabled ? 'Disable Asset Role' : 'Enable Asset Role'"
            class="enable-icon"
          >
            <inline-svg
              :src="item.enabled ? iconEnabled : iconDisabled"
              class="svg-icon"
              @click="onOpenModal(item)"
            />
          </div>
          <div
            v-if="isManager ? false : item.canEdit"
            class="icon-edit"
          >
            <router-link
              :to="{ path: `asset-roles/${item.id}` }"
              :title="'Edit Asset Role'"
            >
              <inline-svg
                :src="iconEdit"
                class="svg-icon"
              >
              </inline-svg>
            </router-link>
          </div>
          <div
            v-if="isManager ? true : !item.canEdit && !item.canDelete && !item.canDisable"
            class="view"
            :title="'View Asset Role'"
          >
            <router-link
              :to="{ path: `asset-roles/${item.id}` }"
            >
              <inline-svg
                :src="iconViewOnly"
                class="svg-icon"
              >
              </inline-svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :totalPages="totalPages"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :title="isEnabled ? 'Disable Asset Role' : 'Enable Asset Role'"
      :isInUseAsset="!isEnabled ? false : inUse"
      :warningMess="!isEnabled ? '' : 'This Asset Role is already in use'"
      :bodyMessage="inUse ? bodyMessageInUse : bodyMessage"
      :onAccept="changeAccStatus"
      :onClose="onClose"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpenRemove"
      :modal="isModalOpenRemove"
      :title="'Delete Asset Role'"
      :bodyMessage="bodyMessage"
      :warningMess="warningMess"
      :isInUseAsset="inUse && deleteAfter"
      :isOrangeCancel="true"
      :cancelButtonTitle="inUse && deleteAfter ? 'OK' : 'Cancel'"
      :confirmButtonTitle="'Delete'"
      :isNeedToApply="!deleteAfter"
      :onAccept="removeAssetRole"
      :onClose="onCloseDelete"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {
  SET_EDIT_FORM,
  SET_OVERFLOW,
} from '../../store/actions/common';
import {
  CHANGE_ASSET_ROLE_STATUS_REQUEST,
  DELETE_ASSET_ROLE_REQUEST,
  GET_ASSET_ROLE_REQUEST,
} from '../../store/actions/assetRoles/assetRolesActions';
import Pagination from '../../components/common/Pagination.vue';
import Loader from '../../components/common/Loader.vue';
import NotificationModal from '../../components/NotificationModal.vue';
import Sorting from '../../components/common/Sorting.vue';
import iconEnabled from '../../assets/icons/icon-enabled-acc.svg';
import iconDisabled from '../../assets/icons/icon-disabled-acc.svg';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconFix from '../../assets/icons/icon-fix.svg';
import iconRemove from '../../assets/icons/icon-remove-role.svg';
import LiveSearch from '../../components/common/LiveSearch.vue';
import CONFIG from '../../constants/config';
import Button from '../../components/common/Button.vue';
import iconViewOnly from '../../assets/icons/icon-view-only.svg';

export default {
  name: 'AssetRoles',
  components: {
    Pagination,
    LiveSearch,
    NotificationModal,
    Sorting,
    Loader,
    Button,
  },
  data() {
    const {
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editAccount,
    } = CONFIG.tooltips;
    return {
      paymentStatuses: CONFIG.paymentStatuses,
      enabledId: null,
      akamaiAccountCreateId: null,
      akamaiAppSyncId: null,
      isEnabled: false,
      isModalOpen: false,
      isModalOpenRemove: false,
      isHover: false,
      selectedId: null,
      bodyMessage: '',
      bodyMessageInUse: 'Are you sure you want to disable this Asset Role?',
      filter: '',
      inUse: '',
      warningMess: '',
      deleteAfter: '',
      sortedField: {
        name: 'name',
        updatedAt: 'updatedAt',
        enabled: 'enabled',
      },
      currentSortedField: '',
      currentSortBy: 'ASC',
      isSortedUp: false,
      isSortedDown: false,
      isOpenDelete: false,
      titleRemoveAcc: '',
      companyName: '',
      accId: '',
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editAccount,
      iconEnabled,
      iconEdit,
      iconDisabled,
      iconFix,
      iconRemove,
      iconViewOnly,
    };
  },
  computed: {
    ...mapState({
      assetRoles: (state) => state.assetRoles.assetRolesList,
      pagination: (state) => state.assetRoles.pagination,
      isLoading: (state) => state.accounts.isLoading,
      profile: (state) => state.common.profile,
    }),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    ...mapGetters([
      'getCurrentAccountId',
      'isManager',
      'getMainProfile',
    ]),
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    this.getAssetRoles();
  },
  methods: {
    getAssetRoles() {
      const params = {
        accountId: this.getCurrentAccountId,
        page: localStorage.getItem('assetRoles') || 1,
        filterValue: '',
      };
      this.$store.dispatch(GET_ASSET_ROLE_REQUEST, params);
    },
    onCloseDelete() {
      this.isModalOpenRemove = false;
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    handleSortUp(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ASSET_ROLE_REQUEST, params);
    },
    handleSortDown(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ASSET_ROLE_REQUEST, params);
    },
    onOpenModal(item) {
      const {
        disabledRoleConfirmationMessage,
        enabledRoleConfirmationMessage,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.inUse = item.isInUse;
      this.isEnabled = item.enabled;
      this.enabledId = item.id;
      this.bodyMessageInUse = `Are you sure you want to ${item.enabled ? 'disable' : 'enable'} this Asset Role?`;
      if (item.isInUse && !item.enabled) {
        this.bodyMessageInUse = `Are you sure you want to ${item.enabled ? 'disable' : 'enable'} this Asset Role and restore all of its previous connections?`;
      }
      this.bodyMessage = item.enabled
        ? disabledRoleConfirmationMessage
        : enabledRoleConfirmationMessage;
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        accountId: this.getCurrentAccountId,
        page: params.page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ASSET_ROLE_REQUEST, getParams);
    },
    getPaginationAction(page) {
      const params = {
        accountId: this.getCurrentAccountId,
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ASSET_ROLE_REQUEST, params);
    },
    toCreateAcc() {
      this.$router.push('asset-roles/add');
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    changeAccStatus() {
      const accStatus = {
        accountId: this.getCurrentAccountId,
        enabled: !this.isEnabled,
        id: this.enabledId,
      };
      this.$store.dispatch(CHANGE_ASSET_ROLE_STATUS_REQUEST, accStatus)
        .then(() => {
          const params = {
            page: localStorage.getItem('assetRoles') || 1,
            filterValue: this.filter || '',
          };
          this.onSearch(params);
          this.$toasted.global.success({
            message: `The Asset Role has been successfully ${this.isEnabled ? 'disabled' : 'enabled'}`,
          });
        });
    },
    toCreateAccHandler() {
      this.toCreateAcc();
    },
    onOpenModalForRemove(item) {
      const {
        deleteAssetRoleMessage,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isRemoveOption = true;
      this.isModalOpenRemove = true;
      this.isEnabled = item.enabled;
      this.enabledId = item.id;
      this.bodyMessage = deleteAssetRoleMessage;
      this.inUse = item.isInUse;
      this.deleteAfter = item.deleteAfter;
      if (item.isInUse && item.deleteAfter) {
        this.bodyMessage = '';
        this.warningMess = `You can only remove an Asset Role if it has been disabled for more than 1 week. Asset Role may be deleted after ${this.getDeleteAfter(item.deleteAfter)}`;
      }
    },
    removeAssetRole() {
      const params = {
        accountId: this.getCurrentAccountId,
        id: this.enabledId,
      };

      this.$store.dispatch(DELETE_ASSET_ROLE_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Asset Role has been successfully deleted',
          });
          this.getAssetRoles();
        });
    },
    getDeleteAfter(value) {
      const moment = extendMoment(Moment);
      const format = 'MMMM DD, [ at] hh:mm A';
      if (this.getMainProfile.timezone) {
        return moment(value).tz(this.getMainProfile.timezone).format(format);
      }
      return moment(value).format(format);
    },
  },
};
</script>

<style scoped>
.flex {
  align-items: center;
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 40px;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px 25px 25px 30px;
  width: 100%;
}

.system {
  color: #4c5057;
}

.accounts-wrapper {
  border: 1px solid #43434d;
  height: 100%;
  overflow-y: auto;
}

.ac-wrapper-header {
  background: #282a2e;
  display: flex;
  padding: 13px 0;
}

.th-name {
  align-items: center;
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
}

.td {
  font-size: 14px;
  padding: 15px 0;
  word-break: break-word;
}

.number {
  padding-left: 21px;
  width: 50px;
}

.company {
  padding-right: 15px;
  width: 17%;
}

.users-count {
  width: 15%;
}

.status {
  align-items: flex-start;
  display: flex;
  width: 15%;
}

.date {
  align-items: flex-start;
  display: flex;
  width: 7%;
}

.account {
  display: flex;
}

.account:hover {
  background: #383b40;
}

.status-ellipse {
  border-radius: 50%;
  height: 5px;
  margin-right: 6px;
  margin-top: 6px;
  width: 5px;
}

.status-ellipse.enabled,
.status-ellipse.active {
  background: #1fee65;
}

.status-ellipse.disabled,
.status-ellipse.blocked,
.status-ellipse.pending {
  background: #fb3951;
}

.status-ellipse.failed {
  background: #fbf650;
}

.icons-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  flex: 0.9;
}

.icons-wrapper div {
  padding: 10px;
}

.create-button {
  background: #2591cf;
  color: #fff;
  font-size: 16px;
  letter-spacing: normal;
  padding: 0 16px;
  text-transform: none;
}

.right-path {
  align-items: center;
  display: flex;
}

.empty-search {
  padding-top: 40px;
  text-align: center;
}

.btn-create-account-mobile {
  display: none;
}

.svg-icon {
  cursor: pointer;
}

.view {
  margin-top: 4px;
}

@media all and (max-width: 1720px) {
  .status {
    width: 10%;
  }
}

@media all and (max-width: 1720px) {
  .users-count {
    width: 9%;
  }
}

@media all and (max-width: 1470px) {
  .users-count {
    width: 8%;
  }

  .pay {
    width: 8%;
  }
}

@media all and (max-width: 1400px) {
  .number {
    width: 30px;
  }

  .status {
    width: 9%;
  }

}

@media all and (max-width: 1170px) {
  .status {
    width: 9.5%;
  }
}

@media all and (max-width: 1100px) {
  .icons-wrapper div {
    padding: 10px 5px;
  }
}

@media all and (max-width: 1024px) {
  .btn-create-account-mobile {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    min-height: 40px;
  }

  .btn-create-account-full {
    display: none;
  }

  .search-wrapper {
    margin-right: 0;
  }
}

@media all and (max-width: 900px) {
  .company {
    padding-right: 7px;
    width: 14%;
  }

  .number {
    width: 24px;
  }

  .status {
    width: 10%;
  }
}
</style>
