<template>
  <div
    v-if="needTVApps"
    class="flex"
  >
    <div
      v-for="item in needTVApps"
      :key="item.title"
      class="items-list"
      link
    >
      <router-link
        :to="goToLink(item)"
        :class="['navigation-link',
                 { 'router-link-active': item.activeLink && item.activeLink(currentPage) }
        ]"
        :event="moving"
      >
        <div
          class="list-item-icon"
          :title="item.title"
        >
          <inline-svg
            :src="item.icon"
            class="svg-icon"
          >
          </inline-svg>
        </div>
        <div class="list-item-content">
          <p class="item-title-header">
            {{ item.title }}
          </p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {
  mapGetters,
  mapState,
} from 'vuex';

export default {
  name: 'NavigationDrawer',
  props: {
    mediaFolderId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.common.menuItems,
      role: (state) => state.common.role,
    }),
    ...mapGetters([
      'isAccountActive',
      'getUserTimezone',
      'getCurrentAccount',
    ]),
    items() {
      if (this.role) {
        return this.menuItems.filter((item) => item.roles.includes(this.role) || item.roles.includes('*'));
      }
      return null;
    },
    needTVApps() {
      if (this.getCurrentAccount
        && !this.getCurrentAccount.tvAppsEnabled
        && !this.getCurrentAccount.hasVideoAppsEngine) {
        return this.items && this.items.filter((item) => item.link !== '/cms');
      }
      return this.items;
    },
    moving() {
      return this.isAccountActive ? 'click' : '';
    },
    currentPage() {
      return this.$route.path;
    },
  },
  methods: {
    goToLink({ link }) {
      if (link === '/media/folder/all') {
        return `/media/folder/${this.mediaFolderId}`;
      }

      if (link === '/schedule') {
        return this.getUserTimezone !== null
          ? `${link}?view=week&date=${moment.utc().tz(this.getUserTimezone).format('YYYY-MM-DD')}`
          : `${link}?view=week&date=${moment.utc().format('YYYY-MM-DD')}`;
      }

      if (this.$route.path === '/cms') {
        this.$router.push('/cms/pages');
      }

      return link;
    },
  },
};
</script>
<style>
  .flex {
    display: flex;
  }

  .router-link-active {
    color: #fff !important;
  }

  .router-link-active .svg-icon path {
    fill: #2591cf;
  }

  .navigation-link {
    align-items: center;
    color: rgba(255, 255, 255, 0.65);
    display: flex;
    font-size: 14px;
    text-decoration: none;
    width: 100%;
  }

  .list-item-icon {
    width: 30px;
  }

  .item-title-header {
    align-self: center;
    flex: 1 1 100%;
    font-size: 14px;
  }

  .items-list {
    align-items: center;
    display: flex;
    outline: none;
    padding: 0 25px;
    position: relative;
  }

  .list-item-content {
    align-items: center;
    align-self: center;
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 12px 0;
  }

  @media all and (max-width: 1400px) {
    .items-list {
      padding: 0 15px;
    }
  }
</style>
