<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="genre-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="edit-button-wrapper">
        <Button
          v-if="isAdmin"
          title="Delete"
          :classType="'transparent'"
          :eventFunction="handleOpenModalForRemove"
          :disabled="isDeleteBtnDisabled"
        />
        <Button
          :title="isAdmin ? 'Cancel' : 'Close'"
          :classType="'warning'"
          :eventFunction="handleGoBack"
        />
        <Button
          v-if="isAdmin"
          title="Apply"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="breadcrumbs">
        <span class="system">
          System Management / Genres
        </span>
        <span>
          / Edit {{ formData.name }}
        </span>
      </div>
      <div class="title">
        Genre Editor
      </div>
      <div class="name-lacalization-wrapper">
        <MaterialInput
          v-model="formData.name"
          v-bind="formFields.name"
          :requiredFromBack="true"
          :disabled="!isAdmin"
        />
        <Button
          title="Localization"
          classType="primary"
          className="localization-btn"
          :srcSvg="iconFolderLocalization"
          :isReverseIcon="true"
          :eventFunction="handleOpenLocalization"
          :disabled="!isAdmin || enabledLanguages.length === 0"
        />
      </div>
      <MaterialInput
        v-model="formData.shortName"
        v-bind="formFields.shortName"
      />
      <MaterialTextArea
        v-model="formData.description"
        v-bind="formFields.description"
        :disabled="!isAdmin"
      />

      <div
        class="genre-image-wrapper"
      >
        <div class="poster-title">
          Poster Image (16:9)
        </div>
        <PosterImage
          ref="posterImage"
          :key="formData.posterImage"
          :poster="formData.posterImage"
          :disabled="!isAdmin"
          :externalErrorMessage="posterImageError ||''"
          :onPosterChange="handlePosterImageChange"
        />
      </div>

      <DeleteGenreInTwoSteps
        ref="deleteGenreInTwoSteps"
        @runAfterComplete="handleGoBack"
      />

      <GenreLocalization
        v-if="enabledLanguages.length"
        v-model="isOpenLocalization"
        :languages="enabledLanguages"
        :genre="genre"
        :modal="isOpenLocalization"
        :onClose="handleCloseLocalization"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  EDIT_GENRE_REQUEST,
  GET_GENRE_REQUEST,
} from '../../../store/actions/genres/genresActions';
import { SET_EDIT_FORM } from '../../../store/actions/common';
import { GET_LANGUAGES_REQUEST } from '../../../store/actions/localization/localizationActions';
import CONFIG from '../../../constants/config';
import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import DeleteGenreInTwoSteps from '../../../views/Genres/DeleteGenreInTwoSteps.vue';
import MaterialTextArea from '../../inputs/MaterialTextArea.vue';
import PosterImage from '../../common/PosterImage/DragAndDropFileUploader.vue';
import GenreLocalization from '../../localization/GenreLocalization.vue';

import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';

export default {
  name: 'EditGenreForm',
  components: {
    MaterialTextArea,
    MaterialInput,
    Button,
    DeleteGenreInTwoSteps,
    PosterImage,
    GenreLocalization,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'genreForm',
    },
  },
  data() {
    return {
      isOpenLocalization: false,
      iconFolderLocalization,
      enabledLanguages: [],
      formData: {
        name: '',
        description: '',
        shortName: '',
        posterImage: '',
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          label: 'Name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        shortName: {
          type: 'text',
          name: 'shortName',
          label: 'Short Name',
          formPrefix: this.formPrefix,
          disabled: true,
          rules: {
            required: true,
          },
        },
        description: {
          type: 'text',
          name: 'description',
          label: 'Description',
          formPrefix: this.formPrefix,
          cols: 30,
          rows: 10,
          rules: {
            required: false,
            max: 1800,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        description: [
          `${this.formPrefix}.description`,
        ],
        shortName: [
          `${this.formPrefix}.shortName`,
        ],
        posterImage: [
          `${this.formPrefix}.posterImage`,
        ],
      },
      submitEvent: EDIT_GENRE_REQUEST,
      successMessage: CONFIG.successMessages.editGenreMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
      posterImageError: null,
      isPosterImageChanged: false,
    };
  },
  computed: {
    ...mapState({
      genre: (state) => state.genres.genre,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'isManager',
      'isAdmin',
    ]),
    isDeleteBtnDisabled() {
      return this.genre?.enabled;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getGenre();
    }

    const paramsLang = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };
    this.$store.dispatch(GET_LANGUAGES_REQUEST, paramsLang).then((res) => {
      this.enabledLanguages = res.data;
    });
  },
  methods: {
    getGenre() {
      const {
        errorMessages,
        statuses,
      } = CONFIG;

      const params = {
        accountId: this.getCurrentAccountId,
        genreId: this.$route.params.id,
      };

      this.$store.dispatch(GET_GENRE_REQUEST, params)
        .then((res) => this.initFormData(res))
        .catch((err) => {
          if (err.error === errorMessages.unknown) {
            this.$router.push(`/${statuses.notFound}`);
          }
        });
    },
    initFormData(genre) {
      this.formData.name = genre.name;
      this.formData.description = genre.description;
      this.formData.shortName = genre.shortName;
      this.formData.posterImage = genre.posterImage;
    },
    handleOpenLocalization() {
      this.isOpenLocalization = true;
    },
    handleCloseLocalization() {
      this.isOpenLocalization = false;
    },
    getRequestData() {
      const data = {
        name: this.formData.name,
        description: this.formData.description,
        posterImage: this.formData.posterImage,
      };

      return {
        data,
        accountId: this.getCurrentAccountId,
        genreId: this.$route.params.id,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.handleGoBack();
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    handleGoBack() {
      this.$router.push('/settings/genres');
    },
    handleOpenModalForRemove() {
      this.$refs.deleteGenreInTwoSteps.handleOpenModalForRemove(this.genre);
    },
    handlePosterImageChange(img) {
      this.isPosterImageChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.posterImage = img;
      if (this.posterImageError) {
        this.posterImageError = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .edit-button-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 26px;
  }

  .breadcrumbs {
    margin-bottom: 25px;
  }

  .system {
    color: #4c5057;
  }

  .name-lacalization-wrapper {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 160px;
    gap: 10px;
    justify-content: space-between;

    .localization-btn {
      width: 100%;
      height: 48px;
      position: relative;
      top: 29px;
    }
  }

  .poster-title {
    margin-bottom: 10px;
  }
</style>
