<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="filtering-tab"
      novalidate="novalidate"
      @submit.prevent="validateCheckboxes(handleSubmit)"
      @input="handleFormChange"
    >
      <div class="title modal">
        Generate Tabs Based on Genres
      </div>
      <div class="checkboxes-wrapper">
        <div class="select-all-container">
          <MaterialCheckbox
            :toggle="toggleSelectAll"
            :checked="formData.isSelectedAll"
            :required="true"
            label="Select All Genres"
            :isFailed="hasError"
          />
        </div>
        <div class="genres-container">
          <MaterialCheckbox
            v-for="genre in formData.genres"
            :key="genre.id"
            :toggle="toggleSelectGenre(genre)"
            :checked="genre.isSelected"
            :label="genre.name"
            :isFailed="hasError"
            :caption="genre.isInUse ? 'in use' : ''"
          />
        </div>
      </div>
      <div class="model-footer">
        <transition name="bounce">
          <div class="error-wrapper">
            <template v-if="hasError">
              <inline-svg
                :src="iconWarning"
                class="svg-icon"
              >
              </inline-svg>
              <span
                class="error-label"
              >
                At least one option must be selected
              </span>
            </template>
          </div>
        </transition>
        <div class="buttons-wrapper">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="backToManually"
          />
          <Button
            :title="'Add'"
            :classType="'primary'"
            :type="'submit'"
          />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import CONFIG from '../../../constants/config';
import { GET_GENRES_FOR_CHOICE_REQUEST } from '../../../store/actions/genres/genresActions';

import BaseForm from '../BaseForm.vue';
import Button from '../../common/Button.vue';
import MaterialCheckbox from '../../inputs/MaterialCheckbox.vue';
import iconWarning from '../../../assets/icons/icon-warning-red.svg';

import {
  CREATE_FILTERING_TAB_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';

export default {
  name: 'AddFilteringTabForm',
  components: {
    MaterialCheckbox,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'addPageForm',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    openedLocalization: {
      type: Function,
      default: () => {},
    },
    onSubmitManually: {
      type: Function,
      default: () => {},
    },
    isEdItItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      iconWarning,
      hasError: false,
      formData: {
        isSelectedAll: false,
        genres: [],
      },
      submitEvent: CREATE_FILTERING_TAB_REQUEST,
      successMessage: 'The Page has been successfully added',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      cmsPage: (state) => state.cms.cmsPage,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  created() {
    this.getGenres();
  },
  methods: {
    getGenres() {
      const params = {
        accountId: this.getCurrentAccountId,
      };
      this.$store.dispatch(GET_GENRES_FOR_CHOICE_REQUEST, params).then((res) => {
        this.formData.genres = res.data.map((genre) => ({
          id: genre.id,
          name: genre.name,
          isSelected: false,
          isInUse: genre.isInUseInTabs,
        }));
      });
    },
    backToManually() {
      this.onClose();
    },
    toggleSelectAll(isChecked) {
      this.formData.isSelectedAll = isChecked;
      this.formData.genres.forEach((genre) => {
        genre.isSelected = isChecked;
      });
      this.$refs[this.validationObserver].reset();
      this.hasError = false;
    },
    toggleSelectGenre(genre) {
      return (isChecked) => {
        genre.isSelected = isChecked;
        this.formData.isSelectedAll = this.formData.genres.every((genre) => genre.isSelected);
        this.$refs[this.validationObserver].reset();
        this.hasError = false;
      };
    },
    validateCheckboxes(handleSubmit) {
      return new Promise((resolve) => {
        const hasSelestedGeners = this.formData.genres.some((genre) => genre.isSelected);
        if (!hasSelestedGeners) {
          this.hasError = true;
          return;
        }

        handleSubmit(this.submit);
        resolve();
      });
    },
    getRequestData() {
      const data = {
        grid: this.cmsPage.grid && this.cmsPage.grid.id,
        genres: this.formData.genres.filter((genre) => genre.isSelected).map((genre) => genre.id),
      };

      return {
        data,
        accountId: this.getCurrentAccountId,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.onSubmitManually();
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      if ((error.form_errors && error.form_errors.children.row) || error.status === 404) {
        this.$router.push(`/cms/pages/${this.$route.params.id}`);
        return;
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>
.modal {
  color: inherit;
  margin-bottom: 20px;
}

.checkboxes-wrapper {
  margin-bottom: 20px;
}

.genres-container {
  margin-top: 20px;
  padding: 0px 6px 10px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  height: 300px;
  overflow: auto;
}

.model-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.error-wrapper {
  padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.error-wrapper .error-label {
  margin: 0;
}

.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}
</style>
