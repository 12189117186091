<template>
  <div>
    <div>
      <MaterialInput
        v-model="formData.feedName"
        v-bind="formFields.feedName"
      />
    </div>
    <div>
      <MaterialTextArea
        v-model="formData.feedDescription"
        v-bind="formFields.feedDescription"
      />
    </div>
    <div v-if="formData.exportUrl">
      <MaterialInput
        v-model="formData.exportUrl"
        :allowCopy="true"
        :label="'Export'"
        :disabled="true"
      />
    </div>
    <div class="checkbox-wrapper">
      <MaterialCheckbox
        :toggle="toggleEnabled"
        :label="'Enabled'"
        :checked="formData.isEnabled"
      />
    </div>
    <div>
      <div class="poster-title">
        Poster Image
      </div>
      <PosterImage
        ref="posterImage"
        :key="formData.poster"
        :poster="formData.poster"
        :posterName="posterName"
        :onPosterChange="handlePosterChange"
      />
    </div>
    <div
      v-if="!isEdit && enabledLanguages.length"
      class="localization-warning-wrapper"
    >
      <inline-svg
        :src="iconWarning"
        class="svg-icon"
      >
      </inline-svg>
      <span class="localization-warning">
        Localization will be available after the Feed has been saved
      </span>
    </div>
  </div>
</template>

<script>
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialTextArea from '../inputs/MaterialTextArea.vue';
import MaterialCheckbox from '../inputs/MaterialCheckbox.vue';
import PosterImage from '../common/PosterImage/DragAndDropFileUploader.vue';
import iconWarning from '../../assets/icons/icon-warning-localization.svg';

export default {
  name: 'GeneralInfoFeed',
  components: {
    MaterialInput,
    MaterialTextArea,
    MaterialCheckbox,
    PosterImage,
  },
  props: {
    formFields: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    enabledLanguages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iconWarning,
      fields: [
        this.formFields.feedName ? this.formFields.feedName.name : '',
        this.formFields.feedName ? this.formFields.feedDescription.name : '',
      ],
    };
  },
  computed: {
    posterName() {
      const { poster } = this.formData;
      return poster.substring(poster.lastIndexOf('/') + 1);
    },
  },
  methods: {
    toggleEnabled(isChecked) {
      if (isChecked !== this.formData.isEnabled) {
        this.formData.isEnabled = isChecked ? 1 : 0;
      }
    },
    handlePosterChange(img) {
      this.formData.poster = img;
    },
    hasErrors(errors) {
      let hasErrors = false;
      for (let i = 0; i < this.fields.length; i += 1) {
        if (errors[this.fields[i]] && errors[this.fields[i]].length) {
          hasErrors = true;
        }
      }

      return hasErrors;
    },
  },
};
</script>

<style scoped>
  .poster-title {
    color: #fff;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .checkbox-wrapper {
    padding-bottom: 20px;
  }

  .localization-warning-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #F2C94C;
    border-radius: 4px;
    padding: 10px;
    margin-left: 2px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .localization-warning {
    font-size: 14px;
    color: #F2C94C;
    padding-left: 8px;
  }
</style>
