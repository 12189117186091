<template>
  <div class="sort-box">
    <label
      for="searchSelect"
      class="sort-by"
    >
      Sort by:
    </label>
    <div class="select-wrapper">
      <select
        id="searchSelect"
        v-model="sortBy"
        class="search-select"
        @change="onChange"
      >
        <option
          v-for="item in sortByList"
          :key="item.value"
          :value="item.value"
          class="select-option"
        >
          {{ item.name }}
        </option>
      </select>
      <inline-svg
        :src="iconSortDown"
        class="svg"
      />
    </div>
    <div class="sorting-wrapper">
      <Sorting
        :defaultSortUp="false"
        :sortField="currentSortedField"
        :currentSortedField="currentSortedField"
        :handleSortUp="handleSortUp"
        :handleSortDown="handleSortDown"
      />
    </div>
  </div>
</template>
<script>
import Sorting from '../common/Sorting.vue';
import iconSortDown from '../../assets/icons/icon-sort-arrow-down.svg';

export default {
  name: 'MediaSort',
  components: {
    Sorting,
  },
  props: {
    sortByList: {
      type: Array,
      default: null,
    },
    handleSortUp: {
      type: Function,
      default: () => {},
    },
    handleSortDown: {
      type: Function,
      default: () => {},
    },
    currentSortedField: {
      type: String,
      default: '',
    },
    handleSortTypeChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconSortDown,
      sortBy: this.currentSortedField,
    };
  },
  methods: {
    onChange() {
      this.handleSortTypeChange(this.sortBy);
    },
  },
};
</script>
<style scoped>
  .sort-box {
    align-items: center;
    display: flex;
  }

  .sort-by {
    font-size: 14px;
    padding-right: 5px;
  }

  .select-wrapper {
    color: #fff;
    display: flex;
    font-size: 14px;
  }

  .search-select {
    appearance: none;
    background: #222;
    border: 1px solid #383b40;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    height: 100%;
    max-height: 30px;
    outline: none;
    padding: 6px;
    width: 92px;
  }

  .sorting-wrapper {
    display: flex;
    flex-direction: column;
  }

  .select-option {
    background: #383b40;
    color: #fff;
    font-size: 1em;
  }

  .svg {
    cursor: pointer;
    fill: #383b40;
    position: relative;
    right: 15px;
    top: 13px;
  }

  @media all and (max-width: 1024px) {
    .sort-by {
      display: none;
    }
  }
</style>
