<template>
  <div>
    <MaterialInput
      v-model="formData.name"
      v-bind="formFields.name"
    />
    <div class="localization-wrapper">
      <div class="label">
        <MaterialInput
          v-model="formData.label"
          v-bind="formFields.label"
        />
      </div>
      <div v-if="enabledLanguages.length">
        <Button
          :title="'Localization'"
          :classType="'primary'"
          :srcSvg="iconFolderLocalization"
          :svgClass="'folder-localization-icon'"
          :eventFunction="toLocalization"
          :isReverseIcon="true"
        />
      </div>
    </div>
    <MaterialInput
      v-model="formData.format"
      v-bind="formFields.format"
      :label="'Page Structure'"
      :requiredFromBack="true"
      :disabled="true"
    />
    <div
      v-if="formData.format === 'Live Countdown/Player'"
      class="select-wrapper"
    >
      <MaterialSelect
        v-model="formData.eventGroup"
        v-bind="formFields.eventGroup"
        :options="cmsEventGroup"
        :withEmptyField="false"
        :handleChange="handleChangeEvent"
      />
    </div>
    <div v-else>
      <div class="rows">
        <div class="select-wrapper">
          <MaterialSelect
            v-model="formData.headerType"
            v-bind="formFields.headerType"
            :options="cmsPage.format === 'rows' ? optionsRows : options"
            :label="'Header Type'"
            :withEmptyField="false"
            :disabled="cmsPage.format === 'grid'"
          />
        </div>
        <div
          v-if="formData.headerType === 'slider'"
          :title="!slidersOptions.length ? 'No items to display' : ''"
          class="select-wrapper"
        >
          <MaterialSelect
            v-model="formData.slider"
            v-bind="formFields.slider"
            :options="slidersOptions"
            :withEmptyField="false"
            :disabled="!slidersOptions.length"
          />
        </div>
      </div>
    </div>
    <div
      v-if="formData.format === 'Grid'"
      class="toggles-wrapper"
    >
      <div class="toggle-title">
        Show Tabs
      </div>
      <div class="toggle-wrapper">
        <ToggleButton
          v-model="formData.isShowTabs"
          color="#2591CF"
          :sync="true"
          :labels="false"
        />
      </div>
      <div class="toggle-wrapper">
        <div class="toggle-title">
          Show Item Titles
        </div>
        <ToggleButton
          v-model="formData.isShowItemTitles"
          color="#2591CF"
          :sync="true"
          :labels="false"
        />
      </div>
    </div>
    <div>
      <MaterialCheckbox
        :label="'Enable for Distribution'"
        :checked="formData.distribution"
        :toggle="toggleEnabled"
        :isDisabled="cmsPage.navigationItem && cmsPage.navigationItem.id"
      />
    </div>
    <div
      v-if="cmsPage.navigationItem && cmsPage.navigationItem.id"
      class="localization-warning-wrapper"
    >
      <inline-svg
        :src="iconWarning"
        class="svg-icon"
      >
      </inline-svg>
      <span class="localization-warning">
        Page is used as a Navigation Item
      </span>
    </div>
    <CmsGeneralLocalization
      v-if="enabledLanguages.length"
      v-model="isOpen"
      :languages="enabledLanguages"
      :cmsPage="cmsPage"
      :modal="isOpen"
      :isPageTranslate="true"
      :onClose="onClose"
    />
    <NotificationModal
      ref="modal"
      v-model="isOpenWarning"
      :modal="isOpenWarning"
      :bodyMessage="bodyMessage"
      :isWarning="true"
      :cancelButtonTitle="'Close'"
      :confirmButtonTitle="'Confirm'"
      :onAccept="continueEditing"
      :onClose="onCloseWarning"
    />
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import MaterialInput from '../../inputs/MaterialInput.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import Button from '../../common/Button.vue';
import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';
import CmsGeneralLocalization from '../../localization/CmsGeneralLocalization.vue';
import NotificationModal from '../../NotificationModal.vue';
import MaterialCheckbox from '../../inputs/MaterialCheckbox.vue';
import {
  GET_EV_GROUPS_CMS_REQUEST,
  GET_SLIDERS_LIST_CMS_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';
import iconWarning from '../../../assets/icons/icon-warning-localization.svg';

export default {
  name: 'PageForm',
  components: {
    CmsGeneralLocalization,
    MaterialSelect,
    NotificationModal,
    Button,
    MaterialInput,
    MaterialCheckbox,
  },
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    cmsPage: {
      type: Object,
      default: () => {},
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
    formFields: {
      type: Object,
      default: () => {},
    },
    enabledLanguages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      iconFolderLocalization,
      iconWarning,
      options: [
        {
          code: 'none',
          name: 'None',
        },
        {
          code: 'hero_slider',
          name: 'Hero Slider',
        },
      ],
      optionsRows: [
        {
          code: 'none',
          name: 'None',
        },
        {
          code: 'slider',
          name: 'Slider',
        },
      ],
      cmsEventGroup: [],
      isReady: false,
      isHasId: true,
      isOpenWarning: false,
      bodyMessage: 'Changing Event Group will terminate any current event in progress on TV apps. Would you like to proceed?',
    };
  },
  computed: {
    ...mapState({
      slidersOptions: (state) => state.cms.slidersOptions,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  created() {
    const params = {
      accountId: this.getCurrentAccountId,
    };
    this.$store.dispatch(GET_EV_GROUPS_CMS_REQUEST, params).then((res) => {
      this.cmsEventGroup = res.data.map((item) => ({
        code: item.id,
        name: item.name,
      }));
    });

    if (this.cmsPage.format === 'rows') {
      const params = {
        accountId: this.getCurrentAccountId,
      };
      this.$store.dispatch(GET_SLIDERS_LIST_CMS_REQUEST, params);
    }
  },
  methods: {
    toLocalization() {
      this.isOpen = true;
    },
    handleRecorderToggle() {
      if (!this.formData.isToggleRecord) {
        this.isRecorderToggleOpen = true;
      }
    },
    backToCms() {
      this.$router.push('/cms/pages');
    },
    onClose() {
      this.isOpen = false;
    },
    onCloseWarning() {
      this.isOpenWarning = false;
      this.formData.eventGroup = this.cmsPage.standaloneEventGroup.id;
    },
    // eslint-disable-next-line consistent-return
    handleChangeEvent() {
      if (this.cmsPage.standaloneEventGroup && !this.cmsPage.standaloneEventGroup.eventsCompleted) {
        this.isOpenWarning = true;
      }
    },
    continueEditing() {
      this.isOpenWarning = false;
    },
    toggleEnabled() {
      this.formData.distribution = !this.formData.distribution;
    },
  },
};
</script>

<style scoped>
.rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.toggles-wrapper {
  display: flex;
}

.toggle-wrapper,
.date-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
}

.toggle-wrapper {
  width: auto;
}

.toggle-title {
  margin-right: 10px;
}

.country-field {
  width: 300px;
}

.country-field:not(:last-child) {
  margin-right: 20px;
}

.wowza p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.wrapper-small {
  margin-bottom: 20px;
}

.flex {
  justify-content: space-between;
}

.person {
  margin-bottom: 20px;
}

.person p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.select-wrapper {
  width: 300px;
}

.localization-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  width: 400px;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.localization-warning {
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}

@media all and (max-width: 1320px) {
  .flex {
    flex-wrap: wrap;
  }

  .country-field {
    margin-right: 0;
    width: 100%;
  }

  .country-field:not(:last-child) {
    margin-right: 0;
  }
}
</style>
