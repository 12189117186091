<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, classes, required, ariaInput, ariaMsg }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    mode="passive"
  >
    <div
      class="group"
      :class="[classes]"
    >
      <label>
        {{ fieldLabel }}
        <span
          v-if="required"
          class="asterisk"
        >
          *
        </span>
      </label>
      <span class="select-wrapper">
        <SelectCustomValue
          v-model="valueModel"
          :name="fieldName"
          :required="required"
          :disabled="disabled"
          :className="className"
          :ariaInput="ariaInput"
          :maxlength="maxlength"
          :withEmptyField="withEmptyField"
          :options="options"
          :type="type"
        />
      </span>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
          v-bind="ariaMsg"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import MaterialSelect from './MaterialSelect.vue';
import SelectCustomValue from './SelectCustomValue.vue';

export default {
  name: 'MaterialSelectCustomValue',
  components: {
    SelectCustomValue,
  },
  extends: MaterialSelect,
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
