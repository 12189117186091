<template>
  <div
    class="overlay"
  >
    <div
      class="wrapper"
    >
      <div class="body-text">
        <div v-if="isRecurrent">
          <!-- eslint-disable max-len -->
          <div>
            You’re changing a repeating event.
            Do you want to remove only this occurrence, or change the time for this and all future occurrences?
          </div>
          <div class="remove-type-wrap">
            <MaterialRadio
              :label="'This time only'"
              :value="'one'"
              :formData="removeType"
              :name="'group3'"
              :className="'radio-input'"
              :onChange="onTypeChange"
            />
            <MaterialRadio
              :label="'All times'"
              :value="'all'"
              :formData="removeType"
              :name="'group3'"
              :className="'radio-input'"
              :onChange="onTypeChange"
            />
          </div>
        </div>
        <div v-else>
          Are you sure you want to remove this Media Item?
        </div>
      </div>
      <hr />
      <div class="button-wrapper">
        <Button
          :title="'Cancel'"
          :classType="'primary grey'"
          :eventFunction="closeModal"
        />
        <Button
          :title="'Remove'"
          :classType="'primary'"
          :eventFunction="acceptHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../common/Button.vue';
import MaterialRadio from '../inputs/MaterialRadio.vue';

export default {
  name: 'RemoveNotification',
  components: {
    Button,
    MaterialRadio,
  },
  props: {
    onAccept: {
      type: Function,
      default: () => {},
      required: false,
    },
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
    error: {
      type: Array,
      default: () => [],
    },
    isRecurrent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      removeType: {
        name: 'one',
        value: 'one',
      },
    };
  },
  methods: {
    onTypeChange(e) {
      this.removeType = {
        name: e.target.value,
        value: e.target.value,
      };
    },
    onItemClick(item) {
      const routeData = this.$router.resolve(`schedule?view=day&date=${item.date}&startTime=${item.startTime}&finishTime=${item.finishTime}`);
      window.open(routeData.href, '_blank');
    },
    closeModal() {
      this.onClose();
    },
    accept() {
      this.onAccept(this.removeType);
      this.closeModal();
    },
    acceptHandler() {
      this.accept();
    },
  },
};
</script>

<style scoped>
  .wrapper {
    background-color: #383b40;
    border-radius: 4px;
    display: block;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    position: absolute;
    text-decoration: none;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .headline,
  .edit-wrapper {
    color: #fff;
  }

  .edit-wrapper {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375rem;
    padding: 13px 16px;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding: 13px 16px;
    word-break: break-all;
  }

  hr {
    border: solid;
    border-color: rgba(0, 0, 0, 0.12);
    border-width: thin 0 0;
    display: block;
    flex: 1 1 0;
    height: 0;
    max-height: 0;
    max-width: 100%;
    transition: inherit;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }

  .lighten-2 {
    color: #fff;
    font-size: 16px;
  }

  .body-text {
    color: #fff;
    display: flex;
    font-size: 16px;
    padding: 40px 55px;
  }

  .link {
    color: #2591cf;
    cursor: pointer;
    margin-right: 20px;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .remove-type-wrap {
    margin-top: 20px;
  }
</style>
<style>
  .remove-type-wrap .group {
    width: 180px;
  }
</style>
