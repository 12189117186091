<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <div class="breadcrumbs">
      <span class="system">
        Video Apps Engine / Banners /
      </span>
      <span v-if="!isEdit">
        Add Banner
      </span>
      <span
        v-else
      >
        Edit {{ banner ? getName(banner.name) : '' }}
      </span>
    </div>
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="title">
        {{ isEdit ? 'Banner Editor' : 'Add Banner' }}
      </div>
      <div class="edit-button-wrapper">
        <Button
          v-if="isEdit && banner"
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="onOpenModal"
          :disabled="banner.status !== 'inactive'"
        />
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backToCms"
        />
        <Button
          :title="isEdit ? 'Apply' : 'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <MaterialInput
        v-model="formData.label"
        v-bind="formFields.label"
      />
      <div :class="['select-wrapper']">
        <MaterialSelect
          v-model="formData.itemType"
          v-bind="formFields.itemType"
          :options="optionsItemType"
          :withEmptyField="false"
        />
      </div>
      <div
        v-if="formData.itemType === 'page_link'"
        class="select-wrapper"
      >
        <MaterialSelect
          v-model="formData.pageTarget"
          v-bind="formFields.pageTarget"
          :options="subpages"
          :withEmptyField="false"
        />
      </div>
      <div
        v-if="formData.itemType === 'playlist_link'"
        style="margin-bottom: 20px"
      >
        <MaterialSelect
          v-model="formData.folderId"
          v-bind="formFields.folderId"
          :label="'Target'"
          :required="true"
          :withEmptyField="false"
          :options="playlistsOptions"
        />
      </div>
      <div class="banner-image-wrapper">
        <div class="poster-title">
          Banner Image (3840х960)
          <span class="asterisk">
            *
          </span>
        </div>
        <PosterImage
          ref="posterImage"
          :key="formData.poster"
          :poster="formData.poster"
          :is4kPoster="true"
          :externalErrorMessage="posterError ? 'This field is required' : ''"
          :onPosterChange="handlePosterChange"
        />
      </div>
      <div
        v-if="isThemeBasic"
        class="banner-image-wrapper"
      >
        <div class="poster-title">
          Banner Mobile Image (2880x960)
        </div>
        <PosterImage
          ref="posterImage"
          :key="formData.mobileBannerImage"
          :poster="formData.mobileBannerImage"
          :is4kMobileBannerImage="true"
          :externalErrorMessage="posterError ? 'This field is required' : ''"
          :onPosterChange="handleMobileBannerImageChange"
        />
      </div>
      <div class="localization-wrapper">
        <div v-if="enabledLanguages.length">
          <Button
            :title="'Localization'"
            :classType="'primary'"
            :srcSvg="iconFolderLocalization"
            :svgClass="'folder-localization-icon'"
            :eventFunction="toLocalization"
            :isReverseIcon="true"
            :disabled="!isEdit"
          />
        </div>
      </div>
      <div
        v-if="!isEdit
          && enabledLanguages.length"
        class="localization-warning-wrapper"
      >
        <inline-svg
          :src="iconWarning"
          class="svg-icon"
        >
        </inline-svg>
        <span class="localization-warning">
          Localization will be available after the Banner has been saved
        </span>
      </div>
      <NotificationModal
        ref="modal"
        v-model="isModalOpen"
        :modal="isModalOpen"
        :bodyMessage="bodyMessage"
        :title="'Delete Banner'"
        :onAccept="removeBanner"
        :onClose="onClose"
      />
      <BannerLocalization
        v-if="enabledLanguages.length"
        v-model="isOpenLocalization"
        :languages="enabledLanguages"
        :banner="banner"
        :modal="isOpenLocalization"
        :onClose="onCloseLocalizationModal"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import NotificationModal from '../../NotificationModal.vue';
import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';
import PosterImage from '../../common/PosterImage/DragAndDropFileUploader.vue';
import iconWarning from '../../../assets/icons/icon-warning-localization.svg';

import {
  CREATE_BANNER_REQUEST,
  EDIT_BANNER_REQUEST,
  GET_BANNER_REQUEST,
  GET_SUBPAGES_REQUEST,
  REMOVE_BANNER_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../../store/actions/common';
import { GET_LANGUAGES_REQUEST } from '../../../store/actions/localization/localizationActions';
import BannerLocalization from '../../localization/BannerLocalization.vue';
import { GET_PLAYLISTS_CMS_REQUEST } from '../../../store/actions/playlist/playlist';

export default {
  name: 'BannerForm',
  components: {
    BannerLocalization,
    MaterialSelect,
    MaterialInput,
    Button,
    NotificationModal,
    PosterImage,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'bannerForm',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playlistsOptions: [],
      optionsItemType: [
        {
          code: 'page_link',
          name: 'Page Link',
        },
        {
          code: 'playlist_link',
          name: 'Playlist Detail',
        },
      ],
      iconWarning,
      iconFolderLocalization,
      isOpenLocalization: false,
      isModalOpen: false,
      bodyMessage: CONFIG.modals.deleteBannerInCms,
      rowsList: [],
      cmsPage: {},
      enabledLanguages: [],
      banner: null,
      posterError: null,
      mobileBannerImageError: null,
      isMobileBannerImageChanged: false,
      foldError: '',
      formData: {
        label: '',
        itemType: '',
        pageTarget: '',
        poster: '',
        mobileBannerImage: '',
        background: '',
        folderId: '',
        sortOrder: 0,
      },
      formFields: {
        label: {
          type: 'text',
          name: 'label',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        itemType: {
          type: 'text',
          name: 'itemType',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        pageTarget: {
          type: 'text',
          name: 'pageTarget',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        folderId: {
          type: 'text',
          name: 'folderId',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 25,
          },
        },
      },
      errorMapping: {
        label: [
          `${this.formPrefix}.name`,
        ],
        folderId: [
          `${this.formPrefix}.targetPlaylist`,
        ],
        pageTarget: [
          `${this.formPrefix}.targetPage`,
        ],
        poster: [
          `${this.formPrefix}.bannerImage`,
        ],
        mobileBannerImage: [
          `${this.formPrefix}.mobileBannerImage`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_BANNER_REQUEST : CREATE_BANNER_REQUEST,
      successMessage: this.isEdit
        ? 'The Banner has been successfully updated'
        : 'The Banner has been successfully added',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
      'isThemeBasic',
    ]),
    ...mapState({
      subpages: (state) => state.cms.subpages.map((item) => ({
        code: item.id,
        name: item.name,
      })),
    }),
  },
  mounted() {
    const paramsGet = {
      accountId: this.getCurrentAccountId,
    };
    this.$store.dispatch(GET_SUBPAGES_REQUEST, paramsGet);
    this.$store.dispatch(GET_PLAYLISTS_CMS_REQUEST, paramsGet).then((res) => {
      this.playlistsOptions = res.data.map((item) => ({
        name: `${item.title} (${item.id})`,
        code: item.id,
      }));
    });

    const paramsLang = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };
    this.$store.dispatch(GET_LANGUAGES_REQUEST, paramsLang).then((res) => {
      this.enabledLanguages = res.data;
    });

    if (this.isEdit && this.$route.params.id) {
      const params = {
        accountId: this.getCurrentAccountId,
        bannerId: this.$route.params.id,
      };

      this.$store.dispatch(GET_BANNER_REQUEST, params)
        .then((res) => this.initFormData(res));
    }
  },
  methods: {
    initFormData(item) {
      this.banner = item;
      this.formData.label = item.name;
      this.formData.itemType = item.type;
      this.formData.poster = item.bannerImage ? `${item.bannerImage}?height=360` : '';
      if (item.type === 'page_link') {
        this.formData.pageTarget = item.targetPage.id;
      }

      if (item.type === 'playlist_link') {
        this.formData.folderId = item.targetPlaylist.id;
      }

      if (this.isThemeBasic) {
        this.formData.mobileBannerImage = item.mobileBannerImage ? `${item.mobileBannerImage}?height=360` : '';
      }
    },
    handlePosterChange(img) {
      this.isPosterChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.poster = img;
      if (this.posterError) {
        this.posterError = false;
      }
    },
    handleMobileBannerImageChange(img) {
      this.isMobileBannerImageChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.mobileBannerImage = img;
      if (this.mobileBannerImageError) {
        this.mobileBannerImageError = false;
      }
    },
    toLocalization() {
      this.isOpenLocalization = true;
    },
    handleRecorderToggle() {
      if (!this.formData.isToggleRecord) {
        this.isRecorderToggleOpen = true;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    backToCms() {
      this.$router.push('/cms/banners');
    },
    getName(name) {
      return name.length > 45 ? `${name.slice(0, 45)}...` : name;
    },
    onOpenModal() {
      this.isModalOpen = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onCloseLocalizationModal() {
      this.isOpenLocalization = false;
    },
    onFoldersChange(value) {
      this.formData.folderId = value ? value.code : value;
      this.foldError = '';
    },
    removeBanner() {
      const params = {
        accountId: this.getCurrentAccountId,
        bannerId: this.$route.params.id,
      };

      this.$store.dispatch(REMOVE_BANNER_REQUEST, params)
        .then(() => {
          setTimeout(() => {
            this.backToCms();
          }, CONFIG.routing.redirectFromAddAcc);
          this.$toasted.global.success({
            message: 'The Banner has been successfully deleted',
          });
        })
        .catch((err) => {
          if (err.status === CONFIG.statuses.failedStatus) {
            this.$toasted.global.error({
              message: CONFIG.errorMessages.commonServerError,
            });
          }
        });
    },
    getRequestData() {
      const data = {
        name: this.formData.label,
        type: this.formData.itemType,
        dataSource: this.formData.contentType,
        bannerImage: this.formData.poster,
        targetPage: this.formData.pageTarget,
      };

      if (!this.isPosterChanged) {
        delete data.bannerImage;
      }

      if (this.formData.itemType === 'playlist_link') {
        delete data.targetPage;
        data.targetPlaylist = this.formData.folderId;
      }

      if (this.isThemeBasic && this.isMobileBannerImageChanged) {
        data.mobileBannerImage = this.formData.mobileBannerImage;
      }

      return {
        data,
        accountId: this.getCurrentAccountId,
        bannerId: this.$route.params.id,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.backToCms();
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);
      this.posterError = error.form_errors.children
        && error.form_errors.children.bannerImage
        && error.form_errors.children.bannerImage.errors;

      const folderError = error.form_errors
        && error.form_errors.children
        && error.form_errors.children.targetPlaylist
        && error.form_errors.children.targetPlaylist.errors
        && error.form_errors.children.targetPlaylist.errors[0].error;

      if (folderError) {
        this.foldError = folderError === 'NOT_SYNCHRONIZED_ERROR'
          ? 'The ID entered does not exist'
          : 'This field is required';
      }

      if (formErrors.pageTarget) {
        formErrors.pageTarget[0] = 'This value is already used as a Navigation Item';
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>

.rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.toggles-wrapper {
  display: flex;
}

.toggle-wrapper,
.date-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
}

.toggle-wrapper {
  width: auto;
}

.toggle-title {
  margin-right: 10px;
}

.dynamic {
  margin-top: 30px;
  margin-bottom: 30px;
}

.edit-button-wrapper {
  position: absolute;
  top: 75px;
  display: flex;
  right: 30px;
}

.country-field {
  width: 300px;
}

.country-field:not(:last-child) {
  margin-right: 20px;
}

.wowza p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.banner-image-wrapper {
  margin-bottom: 20px;
}

.wrapper-small {
  margin-bottom: 20px;
}

.flex {
  justify-content: space-between;
}

.person {
  margin-bottom: 20px;
}

.person p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.select-wrapper {
  width: 100%;
}

.system {
  color: #4c5057;
}

.breadcrumbs {
  margin-bottom: 25px;
}

.localization-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
}

.label {
  width: 400px;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 20px;
}

.localization-warning {
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}

.select-wrapper-folder {
  width: 100%;
}

.rows-folder {
  display: block;
}

.asterisk {
  color: #fb3951;
}

@media all and (max-width: 1320px) {
  .flex {
    flex-wrap: wrap;
  }

  .country-field {
    margin-right: 0;
    width: 100%;
  }

  .country-field:not(:last-child) {
    margin-right: 0;
  }
}
</style>
