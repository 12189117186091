export const MENU_ITEMS = 'MENU_ITEMS';
export const MAIN_INFORMATION = 'MAIN_INFORMATION';
export const MAIN_INFORMATION_SSE = 'MAIN_INFORMATION_SSE';
export const MAIN_INFORMATION_REQUEST = 'MAIN_INFORMATION_REQUEST';
export const MAIN_INFORMATION_SUCCESS = 'MAIN_INFORMATION_SUCCESS';
export const MAIN_INFORMATION_ERROR = 'MAIN_INFORMATION_ERROR';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const SET_OVERFLOW = 'SET_OVERFLOW';
export const SET_OVERFLOW_SUCCESS = 'SET_OVERFLOW_SUCCESS';
export const SET_EDIT_FORM = 'SET_EDIT_FORM';
export const SET_EDIT_FORM_SUCCESS = 'SET_EDIT_FORM_SUCCESS';

export const GET_APP_CONFIG = 'GET_APP_CONFIG';
export const GET_APP_CONFIG_SUCCESS = 'GET_APP_CONFIG_SUCCESS';
export const GET_APP_CONFIG_ERROR = 'GET_APP_CONFIG_ERROR';
