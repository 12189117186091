<template>
  <div class="flex">
    <Loader :isVisible="isLoading" />
    <div class="forgot-password-block">
      <h3>Forgot Password</h3>
      <ForgetPasswordForm
        v-if="!isSubmitted"
        :isSubmitted.sync="isSubmitted"
      />
      <div
        v-else
        class="reset-message"
      >
        <p>
          We have sent an email to the email address you have entered.
          Please check your email address and follow the link provided.
        </p>
        <div class="forgot">
          <router-link
            to="/login"
            class="back-to-sign-in"
          >
            Back to Sign In
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Loader from '../../components/common/Loader.vue';
import {
  ANONIM_AUTH_REQUEST,
} from '../../store/actions/authActions/auth';
import CONFIG from '../../constants/config';
import ForgetPasswordForm from '../../components/forms/ForgetPasswordForm.vue';

export default {
  name: 'ForgotPassword',
  components: {
    ForgetPasswordForm,
    Loader,
  },
  data() {
    return {
      isSubmitted: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.anonimAuth.isLoading,
    }),
  },
  mounted() {
    this.$store.dispatch(ANONIM_AUTH_REQUEST)
      .catch(() => {
        this.$toasted.global.error({
          message: CONFIG.errorMessages.commonServerError,
        });
      });
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
  },
};
</script>

<style scoped>
  .flex {
    display: flex;
    justify-content: center;
  }

  .forgot-password-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5%;
    padding: 60px;
  }

  h3 {
    text-align: center;
  }

  .forgot {
    padding-top: 30px;
  }

  .back-to-sign-in {
    text-decoration: none;
  }

  .reset-message {
    padding: 2em 2em 0;
    text-align: center;
    width: 430px;
  }
</style>
