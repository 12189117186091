<template>
  <div class="feeds-form">
    <div v-if="isInitialized">
      <div class="flex">
        <div class="left-path">
          <span class="system">
            Collections
          </span>
          <span class="system">
            / Feeds
          </span>
          <span v-if="!isEdit">
            / Add Feed
          </span>
          <span
            v-else
          >
            / Edit {{ feed ? getName(feed.name) : '' }}
          </span>
        </div>
        <div class="apply-btn-container">
          <Button
            :title="'Apply'"
            :classType="'primary add-feed'"
            :type="'button'"
            :eventFunction="saveFeed"
          />
        </div>
      </div>
      <div class="feeds-form-wrapper">
        <div class="feeds-filter">
          <ValidationObserver
            ref="validationObserver"
            v-slot="{ handleSubmit, errors }"
          >
            <form
              novalidate="novalidate"
              @submit.prevent="handleSubmit(submit)"
              @input="handleFormChange"
            >
              <div class="feeds-wrapper">
                <div class="tabs-container">
                  <div class="local-wrapper">
                    <div class="title">
                      Feed Settings
                    </div>
                    <div v-if="enabledLanguages.length">
                      <Button
                        :title="'Localization'"
                        :classType="'primary'"
                        :className="'wide-btn'"
                        :srcSvg="iconFolderLocalization"
                        :isReverseIcon="true"
                        :eventFunction="toFeedLocalization"
                        :disabled="!isEdit"
                      />
                    </div>
                  </div>
                  <Tabs
                    :onSelectTab="handleSelectTab"
                    :remove="formData.mode === 'content'"
                  >
                    <Tab
                      id="general"
                      name="General Info"
                      :selected="true"
                      :hasError="hasGeneralInfoError(errors)
                        || hasCustomFieldErrors(getSortedFields('feed_settings_general'), errors)"
                      class="wrapper-general"
                    >
                      <GeneralInfoFeed
                        ref="generalInfoFeed"
                        :formData="formData"
                        :formFields="formFields"
                        :isEdit="isEdit"
                        :enabledLanguages="enabledLanguages"
                      />
                      <div
                        v-if="hasSortedFields('feed_settings_general')"
                        class="custom-field-wrapper"
                      >
                        <div class="custom-field-title">
                          Custom fields
                        </div>
                        <FieldValueRow
                          v-for="item in getSortedFields('feed_settings_general')"
                          :key="`fieldValue${item.field.id}`"
                          v-model="item.fieldValue"
                          :field="item.field"
                          :fieldName="getFieldName(item.field)"
                          :formPrefix="formPrefix"
                          :inputClassName="'feed-input'"
                          :selectClassName="'feed-select'"
                          :calendarClassName="'feed-calendar'"
                          :datetimeClassName="'feed-datetime'"
                          :checkboxClassName="'feed-checkbox'"
                        />
                      </div>
                    </Tab>
                    <Tab
                      id="content"
                      name="Content"
                      :selected="false"
                      :hasError="hasContentError(errors)
                        || hasCustomFieldErrors(getSortedFields('feed_settings_content'), errors)"
                      class="wrapper-content"
                    >
                      <ContentFeed
                        ref="contentFeed"
                        :formData="formData"
                        :dynamicContentLimit="Number(formData.dynamicContentLimit)"
                        :sortBy="formData.sortBy"
                        :sortOrder="formData.sortOrder"
                        :formFields="formFields"
                        :itemsErrors="formErrors"
                        :getMediaItems="getMediaItems"
                        :resetDynamicItems="resetDynamicItems"
                        :handleResetValidation="handleResetValidation"
                      />
                      <div
                        v-if="hasSortedFields('feed_settings_content')"
                        class="custom-field-wrapper"
                      >
                        <div class="custom-field-title">
                          Custom fields
                        </div>
                        <FieldValueRow
                          v-for="item in getSortedFields('feed_settings_content')"
                          :key="`fieldValue${item.field.id}`"
                          v-model="item.fieldValue"
                          :field="item.field"
                          :fieldName="getFieldName(item.field)"
                          :formPrefix="formPrefix"
                          :inputClassName="'feed-input'"
                          :selectClassName="'feed-select'"
                          :calendarClassName="'feed-calendar'"
                          :datetimeClassName="'feed-datetime'"
                          :checkboxClassName="'feed-checkbox'"
                        />
                      </div>
                    </Tab>
                    <Tab
                      id="formats"
                      name="Formats"
                      :selected="false"
                      :hasError="hasFormatsError(errors)
                        || hasCustomFieldErrors(getSortedFields('feed_settings_formats'), errors)"
                      class="wrapper-format"
                    >
                      <FormatsFeed
                        ref="formatsFeed"
                        :formData="formData"
                        :formFields="formFields"
                      />
                      <div
                        v-if="hasSortedFields('feed_settings_formats')"
                        class="custom-field-wrapper"
                      >
                        <div class="custom-field-title">
                          Custom fields
                        </div>
                        <FieldValueRow
                          v-for="item in getSortedFields('feed_settings_formats')"
                          :key="`fieldValue${item.field.id}`"
                          v-model="item.fieldValue"
                          :field="item.field"
                          :fieldName="getFieldName(item.field)"
                          :formPrefix="formPrefix"
                          :inputClassName="'feed-input'"
                          :selectClassName="'feed-select'"
                          :calendarClassName="'feed-calendar'"
                          :datetimeClassName="'feed-datetime'"
                          :checkboxClassName="'feed-checkbox'"
                        />
                      </div>
                    </Tab>
                    <Tab
                      id="advanced"
                      name="Advanced"
                      :selected="false"
                      :hasError="hasAdvancedError(errors)
                        || hasCustomFieldErrors(getSortedFields('feed_settings_advanced'), errors)"
                      class="wrapper-advanced"
                    >
                      <AdvancedFeed
                        ref="advancedFeed"
                        :formData="formData"
                        :formFields="formFields"
                        :itemsErrors="itemsErrors"
                        :handleResetValidation="handleResetValidation"
                        :isPosterItunesChanged="isPosterItunesChanged"
                        :removeSubcategories="removeSubcategories"
                        :posterITunesChanged="posterITunesChanged"
                      />
                      <div
                        v-if="hasSortedFields('feed_settings_advanced')"
                        class="custom-field-wrapper"
                      >
                        <div class="custom-field-title">
                          Custom fields
                        </div>
                        <FieldValueRow
                          v-for="item in getSortedFields('feed_settings_advanced')"
                          :key="`fieldValue${item.field.id}`"
                          v-model="item.fieldValue"
                          :field="item.field"
                          :fieldName="getFieldName(item.field)"
                          :formPrefix="formPrefix"
                          :inputClassName="'feed-input'"
                          :selectClassName="'feed-select'"
                          :calendarClassName="'feed-calendar'"
                          :datetimeClassName="'feed-datetime'"
                          :checkboxClassName="'feed-checkbox'"
                        />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="feeds-preview">
          <FeedResultsPreview
            :key="getKeyResults"
            :mediaItems="mediaItems"
            :fixedItems="formData.fixedItems"
            :getMediaItems="getMediaItems"
            :onSelectFixedItem="onSelectFixedItem"
            :onChangeFixedItem="onChangeFixedItem"
            :onRemoveFixedItem="onRemoveFixedItem"
            :isDynamicContent="Boolean(formData.isDynamicContent)"
            :pagination="pagination.total"
            :dynamicContentLimit="Number(formData.dynamicContentLimit)"
            :defaultLength="defaultLength"
            :errorLiveEvents="errorLiveEvents"
          />
        </div>
      </div>
    </div>
    <div v-if="isNotFoundFeed">
      <div class="not-found-wrapper">
        <h2>Ooops... Feed is not found.</h2>
      </div>
    </div>
    <Loader :isVisible="isLoading" />
    <FeedLocalization
      v-if="isLocalOpen"
      :feed="formData"
      :onClose="onCloseLocalizationModal"
    />
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  ADD_FEED_REQUEST,
  GET_MEDIA_ITEMS,
  GET_FEED,
  EDIT_FEED,
} from '../../store/actions/feedsActions/feedsActions';
import { NUMBER_INPUT_REG_EXP } from '../../utils/validation/regExp';
import { SET_EDIT_FORM } from '../../store/actions/common';
import { GET_LANGUAGES_REQUEST } from '../../store/actions/localization/localizationActions';
import CONFIG from '../../constants/config';

import Tabs from '../common/Tabs/Tabs.vue';
import Tab from '../common/Tabs/Tab.vue';
import Button from '../common/Button.vue';
import BaseForm from './BaseForm.vue';
import GeneralInfoFeed from '../feed/GeneralInfoFeed.vue';
import ContentFeed from '../feed/ContentFeed.vue';
import FormatsFeed from '../feed/FormatsFeed.vue';
import AdvancedFeed from '../feed/AdvancedFeed.vue';
import FeedResultsPreview from '../feed/FeedResultsPreview.vue';
import Loader from '../common/Loader.vue';
import CustomFieldFormMixin from './customFields/CustomFieldFormMixin.vue';
import iconFolderLocalization from '../../assets/icons/icon-folder-localization.svg';
import FeedLocalization from '../localization/FeedLocalization.vue';

export default {
  name: 'FeedsForm',
  components: {
    FeedLocalization,
    Loader,
    FormatsFeed,
    ContentFeed,
    GeneralInfoFeed,
    AdvancedFeed,
    Tabs,
    Tab,
    Button,
    FeedResultsPreview,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'feeds',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      feed: null,
      isLocalOpen: false,
      iconFolderLocalization,
      enabledLanguages: [],
      isInitialized: false,
      id: null,
      initPoster: null,
      mediaItems: [],
      page: 1,
      defaultLength: 20,
      itemsErrors: {},
      formErrors: {},
      successMessage: this.isEdit
        ? CONFIG.successMessages.editFeedMessage
        : CONFIG.successMessages.addFeedMessage,
      isPosterItunesChanged: false,
      noSubcategories: false,
      isNotFoundFeed: false,
      formData: {
        iTunesOwnerEmail: '',
        iTunesOwnerName: '',
        link: '',
        language: '',
        copyright: '',
        author: '',
        type: 'episodic',
        typeList: CONFIG.iTunesMetadataTypeList,
        mode: 'general',
        feedName: '',
        feedDescription: '',
        exportUrl: '',
        isEnabled: true,
        poster: '',
        posterItunes: '',
        isDynamicContent: false,
        matchFolders: [],
        includedTags: [],
        excludedTags: [],
        includedSearchTags: [],
        excludedSearchTags: [],
        includedTypes: [],
        dynamicContentLimit: 100,
        sortByList: [
          {
            code: 'air_date',
            name: 'Airdate',
          },
          {
            code: 'title',
            name: 'Title',
          },
          {
            code: 'plays',
            name: 'Most Watched',
          },
        ],
        sortOrderList: [
          {
            code: 'ASC',
            name: 'Ascending',
          },
          {
            code: 'DESC',
            name: 'Descending',

          },
        ],
        sortBy: 'air_date',
        sortOrder: 'DESC',
        live: {
          isDash: false,
          isHls: false,
          isPosterLive: true,
        },
        liveRequests: [],
        vod: {
          isDash: false,
          isHls: false,
          isPosterVOD: true,
          isH264: true,
        },
        audio: {
          isAac: false,
          isHls: false,
          isMp3: false,
          isDolby: false,
          isDts: false,
          isPosterAudio: false,
        },
        feedFormat: 'rss',
        feedFormats: CONFIG.feedFormatsAdvanced,
        iTunesFileTypes: CONFIG.iTunesFileTypes,
        iTunesFileType: 'video',
        countOfCache: 60,
        isEnabledDomain: false,
        allowedDomains: [],
        forbiddenDomains: [],
        fixedItems: [],
        keyWords: [],
        category: '',
        subcategory: '',
        explicit: 'explicit',
        explicitTypes: CONFIG.explicitITunes,
        errorLiveEvents: [],
      },
      formFields: {
        sortBy: {
          type: 'text',
          name: 'Sort By',
          formPrefix: this.formPrefix,
        },
        sortOrder: {
          type: 'text',
          name: 'Sort Order',
          formPrefix: this.formPrefix,
        },
        feedName: {
          type: 'text',
          name: 'feedName',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        allowedDomains: {
          type: 'text',
          name: 'allowedDomains',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        forbiddenDomains: {
          type: 'text',
          name: 'forbiddenDomains',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        includedTags: {
          type: 'text',
          name: 'includedTags',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        excludedTags: {
          type: 'text',
          name: 'excludedTags',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        dynamicContentLimit: {
          type: 'number',
          name: 'dynamicContentLimit',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            min_value: 1,
            max_value: 3000,
            regex: NUMBER_INPUT_REG_EXP,
          },
        },
        countOfCache: {
          type: 'number',
          name: 'countOfCache',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            min_value: 0,
            max_value: 315360000,
            regex: NUMBER_INPUT_REG_EXP,
          },
        },
        feedDescription: {
          name: 'Description',
          formPrefix: this.formPrefix,
          cols: 30,
          rows: 10,
          rules: {
            required: false,
            max: 500,
          },
        },
        iTunesOwnerEmail: {
          type: 'text',
          name: 'ownerEmail',
          formPrefix: this.formPrefix,
          rules: {
            email: true,
            required: false,
            max: 255,
          },
        },
        iTunesOwnerName: {
          type: 'text',
          name: 'ownerName',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 100,
          },
        },
        category: {
          type: 'text',
          name: 'category',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        subcategory: {
          type: 'text',
          name: 'subcategory',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        link: {
          type: 'text',
          name: 'link',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        language: {
          type: 'text',
          name: 'language',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        copyright: {
          type: 'text',
          name: 'copyright',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 500,
          },
        },
        author: {
          type: 'text',
          name: 'author',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        type: {
          type: 'text',
          name: 'type',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
      },
      submitEvent: this.isEdit ? EDIT_FEED : ADD_FEED_REQUEST,
      errorMapping: {
        feedName: [
          `${this.formPrefix}.name`,
        ],
        feedDescription: [
          `${this.formPrefix}.description`,
        ],
        allowedDomains: [
          `${this.formPrefix}.allowedDomains`,
        ],
        forbiddenDomains: [
          `${this.formPrefix}.forbiddenDomains`,
        ],
        dynamicContentLimit: [
          `${this.formPrefix}.dynamicContentLimit`,
        ],
        countOfCache: [
          `${this.formPrefix}.cacheTtl`,
        ],
        includedTags: [
          `${this.formPrefix}.includeWorkflowTags`,
        ],
        excludedTags: [
          `${this.formPrefix}.excludeWorkflowTags`,
        ],
        includedSearchTags: [
          `${this.formPrefix}.includeSearchTags`,
        ],
        excludedSearchTags: [
          `${this.formPrefix}.excludeSearchTags`,
        ],
      },
    };
  },
  computed: {
    ...mapState({
      pagination: (state) => state.feeds.mediaItemsPagination,
      isLoading: (state) => state.feeds.isLoading,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    getKeyResults() {
      const {
        dynamicContentLimit,
        matchFolders,
        includedTags,
        excludedTags,
        sortBy,
        sortOrder,
        isDynamicContent,
      } = this.formData;

      return dynamicContentLimit + matchFolders
        + includedTags.length + excludedTags.length + sortBy + sortOrder + isDynamicContent;
    },
  },
  created() {
    const param = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };
    this.$store.dispatch(GET_LANGUAGES_REQUEST, param).then((res) => {
      this.enabledLanguages = res.data;
    });

    const params = {
      accountId: this.getCurrentAccountId,
      feedId: this.$route.params.id,
    };

    this.$store.dispatch(GET_FEED, params)
      .then((res) => {
        this.setEditFeed(res);
      })
      .catch(() => {
        this.isNotFoundFeed = true;
      });
  },
  methods: {
    setEditFeed(feed) {
      if (feed.id) {
        this.formData.id = feed.id;
        this.feed = feed;
        this.formData.feedName = feed.name;
        this.formData.feedDescription = feed.description;
        this.formData.exportUrl = feed.exportUrl;
        this.formData.isEnabled = feed.enabled;
        this.formData.poster = feed.posterImage ? `${feed.posterImage}?height=360` : '';
        this.formData.isDynamicContent = feed.dynamicContentEnabled;
        this.formData.dynamicContentLimit = feed.dynamicContentLimit;
        this.formData.sortBy = feed.dynamicSortBy;
        this.formData.sortOrder = feed.dynamicOrderBy;
        this.formData.countOfCache = feed.cacheTtl;
        this.formData.isEnabledDomain = feed.domainRestrictionEnabled;
        this.formData.matchFolders = feed.mediaFolders;
        this.formData.includedTags = feed.includeWorkflowTags;
        this.formData.excludedTags = feed.excludeWorkflowTags;
        this.formData.includedSearchTags = feed.includeSearchTags;
        this.formData.excludedSearchTags = feed.excludeSearchTags;
        this.formData.includedTypes = feed.includeMediaTypes
          && feed.includeMediaTypes.length
          ? feed.includeMediaTypes.map((item) => ({
            id: item,
            name: item === 'livestream' ? 'live' : item,
          }))
          : [];
        // eslint-disable-next-line array-callback-return,consistent-return
        this.formData.fixedItems = feed.feedFixedItems.map(((item) => {
          if (item.mediaItem) {
            return item.mediaItem;
          }

          if (item.standaloneEvent) {
            return item.standaloneEvent;
          }
        }));
        this.formData.vod = this.getVodSources(feed.vodSources);
        this.formData.live = this.getStreamSources(feed.streamSources);
        this.formData.audio = this.getAudioSources(!feed.audioSources ? [] : feed.audioSources);
        this.formData.feedFormat = feed.format;
        this.initPoster = feed.posterImage ? `${feed.posterImage}?height=360` : '';
        this.formData.allowedDomains = feed.allowedDomains.map((item) => ({
          name: item,
          id: item,
        }));
        this.formData.forbiddenDomains = feed.forbiddenDomains.map((item) => ({
          name: item,
          id: item,
        }));

        if (feed.itunesMetadata) {
          this.formData.iTunesFileType = feed.itunesMetadata.feedFormat;
          this.formData.keyWords = feed.itunesMetadata.keywords.map((item) => ({
            name: item,
            id: item,
          }));
          this.formData.iTunesOwnerName = feed.itunesMetadata.ownerName;
          this.formData.iTunesOwnerEmail = feed.itunesMetadata.ownerEmail;
          this.formData.category = feed.itunesMetadata.category;
          this.formData.subcategory = feed.itunesMetadata.subcategory;
          this.formData.explicit = feed.itunesMetadata.explicit;
          this.formData.posterItunes = feed.itunesMetadata.coverImage
            ? `${feed.itunesMetadata.coverImage}?height=360`
            : '';
          this.formData.link = feed.itunesMetadata.link;
          this.formData.language = feed.itunesMetadata.language;
          this.formData.copyright = feed.itunesMetadata.copyright;
          this.formData.author = feed.itunesMetadata.author;
          this.formData.type = feed.itunesMetadata.type;
        }

        if (this.formData.isDynamicContent) {
          this.getMediaItems(1);
        }
      }

      this.setCustomFields(feed.customFields);
      this.isInitialized = true;
    },
    getName(name) {
      return name.length > 45 ? `${name.slice(0, 45)}...` : name;
    },
    toFeedLocalization() {
      this.isLocalOpen = true;
    },
    onCloseLocalizationModal() {
      this.isLocalOpen = false;
    },
    getVodSources(data) {
      return {
        isDash: data.includes('mpeg-dash'),
        isHls: data.includes('hls'),
        isPosterVOD: data.includes('poster'),
        isH264: data.includes('h264'),
      };
    },
    getAudioSources(data) {
      return {
        isAac: data.includes('aac'),
        isHls: data.includes('hls'),
        isMp3: data.includes('mp3'),
        isDolby: data.includes('isDolby'),
        isDts: data.includes('dts'),
        isPosterAudio: data.includes('poster'),
      };
    },
    getStreamSources(data) {
      return {
        isDash: data.includes('mpeg-dash'),
        isHls: data.includes('hls'),
        isPosterLive: data.includes('poster'),
      };
    },
    removeSubcategories() {
      this.noSubcategories = true;
      this.formData.subcategory = '';
    },
    posterITunesChanged() {
      this.isPosterItunesChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    onRemoveFixedItem(index) {
      this.formData.fixedItems.splice(index, 1);
      if (this.formData.isDynamicContent) {
        this.getMediaItems(1);
      }
    },
    onChangeFixedItem(collection) {
      this.formData.fixedItems = collection;
    },
    onSelectFixedItem(item) {
      this.formData.fixedItems.push(item);
    },
    saveFeed() {
      this.$refs.validationObserver.handleSubmit()
        .then(() => {
          this.submit();
        });
    },
    getMediaItems(page) {
      const { fixedItems } = this.formData;
      const allLength = page * this.defaultLength;
      const { dynamicContentLimit } = this.formData;

      let folders = this.formData.matchFolders;
      let included = this.formData.includedTags;
      let excluded = this.formData.excludedTags;
      let includedSearch = this.formData.includedSearchTags;
      let excludedSearch = this.formData.excludedSearchTags;
      let types = this.formData.includedTypes;

      if (this.formData.matchFolders[0] && this.formData.matchFolders[0].name) {
        folders = this.formData.matchFolders.map((item) => item.id);
      }

      if (this.formData.includedTags[0] && this.formData.includedTags[0].name) {
        included = this.formData.includedTags.map((item) => item.id);
      }

      if (this.formData.excludedTags[0] && this.formData.excludedTags[0].name) {
        excluded = this.formData.excludedTags.map((item) => item.id);
      }

      if (this.formData.includedSearchTags[0] && this.formData.includedSearchTags[0].name) {
        includedSearch = this.formData.includedSearchTags.map((item) => item.id);
      }

      if (this.formData.excludedSearchTags[0] && this.formData.excludedSearchTags[0].name) {
        excludedSearch = this.formData.excludedSearchTags.map((item) => item.id);
      }

      if (this.formData.includedTypes[0] && this.formData.includedTypes[0].name) {
        types = this.formData.includedTypes.map((item) => item.id);
      }

      const params = {
        accountId: this.getCurrentAccountId,
        page: 1,
        allLength: dynamicContentLimit < allLength
          ? dynamicContentLimit : allLength,
        isDynamicContent: this.formData.isDynamicContent,
        matchFolders: folders,
        includedTags: included,
        excludedTags: excluded,
        includedSearchTags: includedSearch,
        excludedSearchTags: excludedSearch,
        types,
        sortBy: this.formData.sortBy,
        sortOrder: this.formData.sortOrder,
        fixedItems,
        checkAvailability: true,
      };
      this.$store.dispatch(GET_MEDIA_ITEMS, params)
        .then((res) => {
          this.mediaItems = res;
        });
    },
    resetDynamicItems() {
      this.mediaItems = null;
    },
    handleResetValidation() {
      this.itemsErrors = {};
      this.formErrors = {};
    },
    handleSelectTab(id) {
      this.formData.mode = id;
    },
    // eslint-disable-next-line consistent-return
    onSubmitFail(error) {
      this.formErrors = this.getServerFormErrors(error);
      this.itemsErrors = this.getServerFormCollectionErrors(error);

      if (Object.entries(this.formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(this.formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }

      let err = error.form_errors.children.feedFixedItems.children;
      if (!err.isArray) {
        err = Object.entries(err);
        this.errorLiveEvents = err.map((item) => ({
          id: item.length && item[1].children.standaloneEvent.errors[0].invalid_value,
        }));
      } else {
        this.errorLiveEvents = err.map((item) => ({
          id: item.children.standaloneEvent.errors[0].invalid_value,
        }));
      }

      if (this.errorLiveEvents.length) {
        this.$forceUpdate();
      }
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.$router.push('/collections/feeds');
      }, CONFIG.routing.redirectFromAddAcc);
    },
    getRequestData() {
      let feedMediaItems = [];
      const { fixedItems } = this.formData;
      let items = [];

      if (this.isEdit) {
        // eslint-disable-next-line no-plusplus
        for (let i = fixedItems.length - 1; i >= 0; i--) {
          if (fixedItems[i].status === 'completed' && fixedItems[i].startDate) {
            fixedItems.splice(i, 1);
          }
        }

        items = fixedItems;
      } else {
        items = fixedItems;
      }

      if (fixedItems && items.length) {
        feedMediaItems = items.map(((item) => {
          const itemKeys = Object.keys(item);

          if (itemKeys.includes('type')) {
            return {
              mediaItem: item.id,
            };
          }

          return {
            standaloneEvent: item.id,
          };
        }));
      }

      const vodReq = [
        this.formData.vod.isDash && 'mpeg-dash',
        this.formData.vod.isHls && 'hls',
        this.formData.vod.isH264 && 'h264',
        this.formData.vod.isPosterVOD && 'poster',
      ];
      const liveReq = [
        this.formData.live.isDash && 'mpeg-dash',
        this.formData.live.isHls && 'hls',
        this.formData.live.isPosterLive && 'poster',
      ];
      const audioReq = [
        this.formData.audio.isAac && 'aac',
        this.formData.audio.isMp3 && 'mp3',
        this.formData.audio.isHls && 'hls',
        this.formData.audio.isDolby && 'dolby',
        this.formData.audio.isDts && 'dts',
        this.formData.audio.isPosterAudio && 'poster',
      ];
      const vodReqChecked = vodReq.filter((item) => !!item);
      const liveReqChecked = liveReq.filter((item) => !!item);
      const audioReqChecked = audioReq.filter((item) => !!item);

      const allowedReq = this.formData.allowedDomains.map((item) => item.name);
      const forbiddenReq = this.formData.forbiddenDomains.map((item) => item.name);
      const keywordsReq = this.formData.keyWords.map((item) => item.name);

      const itunesMetadata = {
        keywords: keywordsReq,
        ownerName: this.formData.iTunesOwnerName,
        ownerEmail: this.formData.iTunesOwnerEmail,
        category: this.formData.category,
        subcategory: this.formData.subcategory,
        explicit: this.formData.explicit,
        link: this.formData.link,
        language: this.formData.language,
        copyright: this.formData.copyright,
        author: this.formData.author,
        type: this.formData.type,
        feedFormat: this.formData.iTunesFileType,
      };

      if (!this.formData.category) {
        delete itunesMetadata.subcategory;
      }

      if (this.isPosterItunesChanged) {
        itunesMetadata.coverImage = this.formData.posterItunes;
        this.isPosterItunesChanged = false;
      }

      const data = {
        name: this.formData.feedName,
        description: this.formData.feedDescription,
        enabled: !!this.formData.isEnabled,
        dynamicContentEnabled: !!this.formData.isDynamicContent,
        dynamicContentLimit: Number(this.formData.dynamicContentLimit),
        dynamicSortBy: this.formData.sortBy,
        dynamicOrderBy: this.formData.sortOrder,
        mediaFolders: this.formData.matchFolders.map((item) => item.id),
        feedFixedItems: feedMediaItems,
        includeWorkflowTags: this.formData.includedTags,
        excludeWorkflowTags: this.formData.excludedTags,
        includeSearchTags: this.formData.includedSearchTags,
        excludeSearchTags: this.formData.excludedSearchTags,
        includeMediaTypes: this.formData.includedTypes,
        vodSources: vodReqChecked,
        streamSources: liveReqChecked,
        audioSources: audioReqChecked,
        format: this.formData.feedFormat,
        cacheTtl: parseInt(this.formData.countOfCache, 10),
        domainRestrictionEnabled: !!this.formData.isEnabledDomain,
        allowedDomains: allowedReq,
        forbiddenDomains: forbiddenReq,
        itunesMetadata: this.formData.feedFormat === 'itunes' ? itunesMetadata : {},
        fields: this.getFieldsData(),
      };

      if (this.isEdit) {
        if (this.formData.poster !== this.initPoster) {
          data.posterImage = this.formData.poster;
        }

        return {
          accountId: this.getCurrentAccountId,
          feedId: this.formData.id,
          data,
        };
      }

      data.posterImage = this.formData.poster;

      return {
        accountId: this.getCurrentAccountId,
        data,
      };
    },
    hasGeneralInfoError(errors) {
      return this.$refs.generalInfoFeed && this.$refs.generalInfoFeed.hasErrors(errors);
    },
    hasContentError(errors) {
      return this.$refs.contentFeed && this.$refs.contentFeed.hasErrors(errors);
    },
    hasFormatsError(errors) {
      return this.$refs.formatsFeed && this.$refs.formatsFeed.hasErrors(errors);
    },
    hasAdvancedError(errors) {
      return this.$refs.advancedFeed && this.$refs.advancedFeed.hasErrors(errors);
    },
  },
};
</script>

<style scoped>
  .local-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .feeds-form {
    overflow: hidden;
  }

  .feeds-form-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .feeds-preview {
    border: 1px solid #383b40;
    height: calc(100vh - 140px);
    margin: 10px 10px 10px 20px;
    min-width: 370px;
    width: 100%;
  }

  .feeds-filter {
    border: 1px solid #383b40;
    height: calc(100vh - 140px);
    margin: 10px 10px 10px 20px;
    width: 100%;
  }

  .feeds-wrapper {
    display: flex;
    width: 100%;
  }

  .wrapper-advanced {
    height: calc(100vh - 300px);
    overflow: auto;
    padding-right: 20px;
  }

  .wrapper-format,
  .wrapper-content,
  .wrapper-general {
    height: calc(100vh - 270px);
    overflow: auto;
    padding-right: 20px;
  }

  .custom-field-wrapper {
    margin-top: 20px;
  }

  .custom-field-title {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .add-feed {
    float: right;
    margin-right: 12px;
  }

  .tabs-container {
    padding: 10px 20px;
    width: 100%;
  }

  .title {
    margin-bottom: 0;
  }

  .apply-btn-container {
    margin-right: 12px;
  }

  .not-found-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 50vh;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left-path {
    width: 100%;
    margin-left: 20px;
  }

  .system {
    color: #4c5057;
  }

  @media all and (max-width: 1200px) {
    .feeds-form-wrapper {
      display: block;
    }

    .feeds-preview {
      min-width: 370px;
      width: auto;
    }

    .apply-btn-container {
      display: flex;
      justify-content: flex-end;
    }

    .feeds-filter {
      width: auto;
    }

    .feeds-preview {
      height: calc(100vh - 70px);
      width: auto;
    }
  }
</style>
