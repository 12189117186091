<template>
  <div>
    <div
      ref="fileform"
      class="file-drag-drop"
      :class="[
        'file-drag-drop',
        isError || externalErrorMessage ? 'error-form' : '',
        isPosterInspector && 'file-drag-drop-inspector',
        is4kPoster && 'four-k-poster',
        disabledText && 'disabled'
      ]"
      :title="computedTitle"
      @click="onFileInput"
    >
      <form
        v-if="!file"
        class="file-form"
      >
        <div class="drag-drop-zone">
          <span
            v-if="disabledText"
            class="drop-files"
          >
            {{ disabledText }}
          </span>
          <span
            v-else
            class="drop-files itunes-img-text"
          >
            <div class="file-form">
              <div class="itunes-drag-zone">
                <inline-svg
                  :src="iconUpload"
                  class="svg"
                />
                <span class="drop-files">
                  Drag & Drop
                </span>
                <span class="drop-files">
                  or click to <span class="browser-link">browse</span>
                </span>
              </div>
            </div>
          </span>
        </div>
      </form>
      <div
        v-else
        :class="['file-preview', isPosterInspector && isError && 'file-preview-inspector']"
      >
        <div class="preview">
          <img
            v-if="preview"
            :ref="'preview'"
            :src="preview"
            :alt="file.name"
            :class="['image-preview']"
          />
          <div
            v-if="isNeedRemove"
            :class="['remove-btn', isError && 'remove-btn-error']"
          >
            <div
              class="btn"
              title="Remove Title Image"
              @click="onRemove"
            >
              <inline-svg :src="iconRemove">
              </inline-svg>
            </div>
          </div>
        </div>
      </div>
      <input
        ref="fileInput"
        type="file"
        class="input-hidden"
        :accept="'image/x-png'"
        :disabled="disabled"
        @change="fileInputChange"
      />
    </div>
    <span
      v-if="isError"
      class="error-label"
    >
      {{ validationMessage }}
    </span>
    <span
      v-else-if="externalErrorMessage"
      class="error-label"
    >
      {{ externalErrorMessage }}
    </span>
    <NotificationModal
      v-if="isModalOpen"
      :modal="true"
      :title="'Replace Title Image'"
      :bodyMessage="replaceMessage"
      :onAccept="handleAccept"
      :onClose="handleClose"
    />
  </div>
</template>
<script>
import iconRemove from '../../../assets/icons/icon-remove-image.svg';
import iconUpload from '../../../assets/icons/icon-upload.svg';
import NotificationModal from '../../NotificationModal.vue';

export default {
  name: 'TitleSeriesImage',
  components: {
    NotificationModal,
  },
  props: {
    isImagesOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    onPosterChange: {
      type: Function,
      default: () => {},
    },
    poster: {
      type: String,
      default: '',
    },
    posterName: {
      type: String,
      default: '',
    },
    externalErrorMessage: {
      type: String,
      default: '',
    },
    handleError: {
      type: Function,
      default: () => {},
    },
    handleRemove: {
      type: Function,
      default: () => {},
      required: false,
    },
    typeOfPerson: {
      type: String,
      default: '',
    },
    imageType: {
      type: String,
      default: 'poster image',
    },
    isPosterInspector: {
      type: Boolean,
      default: false,
      required: false,
    },
    isHDPoster: {
      type: Boolean,
      default: false,
    },
    isNeedRemove: {
      type: Boolean,
      default: true,
    },
    is4kPoster: {
      type: Boolean,
      default: false,
    },
    disabledText: {
      type: String,
      default: '',
    },
    shouldHideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalOpen: false,
      isRemove: false,
      isReplace: false,
      tempFile: null,
      iconRemove,
      iconUpload,
      dragAndDropCapable: false,
      file: null,
      uploadPercentage: 0,
      preview: '',
      isError: false,
      validationMessage: '',
      fileName: '',
      replaceMessage: 'Are you sure you want to replace this title image?',
    };
  },
  computed: {
    computedTitle() {
      if (this.shouldHideTitle) {
        return '';
      }

      return 'Add Title Image';
    },
  },
  mounted() {
    this.dragInit();
    this.preview = this.poster;
    this.file = this.poster;
    this.fileName = this.posterName;
  },
  methods: {
    handleAccept() {
      this.isModalOpen = false;
      if (this.tempFile) {
        this.fileName = this.tempFile.name;
        this.getImagePreviews(this.tempFile);
        this.tempFile = null;
      }
    },
    isValid() {
      return !this.isError;
    },
    handleClose() {
      this.isModalOpen = false;
      this.$refs.fileInput.value = null;
      this.tempFile = null;
    },
    dragInit() {
      this.dragAndDropCapable = this.determineDragAndDropCapable();
      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
          this.$refs.fileform.addEventListener(evt, (e) => {
            e.preventDefault();
            e.stopPropagation();
          }, false);
        });

        this.$refs.fileform.addEventListener('drop', this.dropHandle);
      }
    },
    dropHandle(e) {
      if (this.file && this.preview) {
        this.isModalOpen = true;
        this.tempFile = e.dataTransfer.files[0];
        return;
      }
      this.file = e.dataTransfer.files[0];
      this.fileName = this.file.name;
      this.getImagePreviews(this.file);
    },
    onRemove(e) {
      if (this.disabled) {
        e.preventDefault();
        return;
      }

      this.file = null;
      this.fileName = '';
      this.$refs.fileInput.value = null;
      this.isRemove = true;
      this.isError = false;
      this.validationMessage = '';
      this.preview = '';
      this.handleRemove();
      this.onPosterChange(this.preview);
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div');
      return (('draggable' in div)
          || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window
        && 'FileReader' in window;
    },
    getImagePreviews(file) {
      if (this.validateFile(file)) {
        this.isError = false;
        this.validationMessage = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.preview = reader.result;
          this.afterLoadValidation();
        }, false);
        reader.readAsDataURL(file);
      } else {
        this.isError = true;
      }
    },
    validateFile(file) {
      if (!this.isImagesOnly && !(/\.(png)$/i.test(file.name))) {
        this.validationMessage = 'File format should be PNG';
        return false;
      }

      if (this.isImagesOnly && !(/\.(png)$/i.test(file.name))) {
        this.validationMessage = 'File format should be PNG';
        return false;
      }

      if (((file.size / 1024) / 1024) > 10) {
        this.validationMessage = 'File size can be no more than 10 MB';
        return false;
      }

      return true;
    },
    afterLoadValidation() {
      const img = new Image();
      img.src = this.preview;
      img.onload = () => {
        if (img.height < 145) {
          this.isError = true;
          this.preview = '';
          this.validationMessage = 'Min height should be 145px';
        }

        if (img.width < 100) {
          this.isError = true;
          this.preview = '';
          this.validationMessage = 'Min width should be 100px';
        }

        this.onPosterChange(this.preview);
      };
    },
    fileInputChange() {
      const { files } = this.$refs.fileInput;
      if (files[0]) {
        if (this.file && this.preview) {
          this.isModalOpen = true;
          this.tempFile = files[0];
          return;
        }
        this.file = files[0];
        this.fileName = this.file.name;
        this.getImagePreviews(this.file);
      }
    },
    onFileInput() {
      if (!this.isRemove) {
        this.$refs.fileInput.click();
        return;
      }
      this.isRemove = false;
    },
  },
};
</script>
<style scoped>
.file-drag-drop {
  border: 1px dashed #a0a0a0;
  height: 260px;
  width: 340px;
  cursor: pointer;
}

.file-drag-drop-inspector {
  /*height: auto;*/
  width: 520px;
}

.error-label {
  color: #fb3951;
  font-size: 12px;
  margin-bottom: 5px;
  text-align: left;
}

.error-form {
  border-color: #fb3951;
}

.error-form-inspector {
  border-color: #fb3951;
}

.drag-drop-zone {
  /*cursor: pointer;*/
  /*padding: 112px 0;*/
  text-align: center;
}

.file-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.file-preview {
  display: flex;
  justify-content: center;
  /*padding: 20px 0;*/
  text-align: center;
  height: 100%;
}

.drop-files {
  color: #a0a0a0;
}

.file-name {
  font-size: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
}

.preview {
  width: 100%;
  height: auto;
  position: relative;
}

.file-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
}

.remove-btn {
  width: 44px;
  height: 44px;
  background: #2591CF;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-hidden {
  display: none;
}

.itunes-img-text {
  display: flex;
  flex-direction: column;
}

.file-preview-inspector {
  padding: 106px 0;
}

.four-k-poster {
  width: auto !important;
  height: auto !important;
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.disabled {
  cursor: not-allowed;
}
</style>
