<template>
  <div class="form-container">
    <MaterialInput
      v-model="metadata.title"
      v-bind="metadata.title"
      :label="'Title'"
      :disabled="true"
    />
    <MaterialInput
      v-model="metadata.shortDescription"
      v-bind="metadata.shortDescription"
      :label="'Short Description'"
      :disabled="true"
    />
    <MaterialTextArea
      v-model="metadata.longDescription"
      v-bind="metadata.longDescription"
      :label="'Long Description'"
      :disabled="true"
    />
    <div
      v-if="type !== 'season'"
      class="img-block"
    >
      <div class="poster-title">
        Title Image
      </div>
      <TitleSeriesImage
        ref="posterImage"
        :poster="metadata.titleImg"
        :disabled="true"
        :isNeedRemove="false"
        :disabledText="'No Image to Display'"
        :shouldHideTitle="true"
      />
    </div>
    <div class="img-block">
      <div class="poster-title">
        Poster Image (16:9)
      </div>
      <PosterImage
        ref="posterImage"
        :poster="metadata.image"
        :isNeedRemove="false"
        :disabled="true"
        :disabledText="'No Image to Display'"
        :shouldHideTitle="true"
      />
    </div>
    <div class="img-block">
      <div class="wrapper-small">
        <div class="poster-title">
          Background Image
        </div>
        <PosterImage
          ref="posterImage"
          :poster="metadata.backgroundImage"
          :isNeedRemove="false"
          :disabled="true"
          :disabledText="'No Image to Display'"
          :shouldHideTitle="true"
        />
      </div>
    </div>
    <div class="img-block">
      <div class="poster-title">
        Poster Image (2:3)
      </div>
      <PosterImage
        ref="portraitImage"
        :poster="metadata.portraitImage"
        :isNeedRemove="false"
        :disabled="true"
        :disabledText="'No Image to Display'"
        :shouldHideTitle="true"
        :isPoster2x3="true"
        class="poster-2x3-image"
      />
    </div>
  </div>
</template>

<script>
import MaterialInput from '../../inputs/MaterialInput.vue';
import MaterialTextArea from '../../inputs/MaterialTextArea.vue';
import PosterImage from '../../common/PosterImage/DragAndDropFileUploader.vue';
import TitleSeriesImage from '../../common/PosterImage/TitleSeriesImage.vue';

export default {
  name: 'FolderEngForm',
  components: {
    TitleSeriesImage,
    MaterialInput,
    MaterialTextArea,
    PosterImage,
  },
  props: {
    metadata: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.form-container {
  padding: 32px 70px 20px 0;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.img-block {
  margin-bottom: 20px;
}

.poster-2x3-image{
  height: 400px;
}
</style>
