import apiCall from '../../../utils/api/api';
import {
  SEND_SUPPORT_ERROR,
  SEND_SUPPORT_REQUEST,
  SEND_SUPPORT_SUCCESS,
} from '../../actions/support';

const state = {
  isLoading: false,
};

const actions = {
  [SEND_SUPPORT_REQUEST]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(SEND_SUPPORT_REQUEST);
    apiCall.post('support/request', data)
      .then((resp) => {
        commit(SEND_SUPPORT_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(SEND_SUPPORT_ERROR, error);
        reject(error);
      });
  }),
};

const mutations = {
  [SEND_SUPPORT_REQUEST]: () => {
    state.isLoading = true;
  },
  [SEND_SUPPORT_SUCCESS]: () => {
    state.isLoading = false;
  },
  [SEND_SUPPORT_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
