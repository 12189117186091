import * as axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  ADD_CUSTOM_ROLE_ERROR,
  ADD_CUSTOM_ROLE_REQUEST,
  ADD_CUSTOM_ROLE_SUCCESS,
  DISABLE_CUSTOM_ROLE_ERROR,
  DISABLE_CUSTOM_ROLE_REQUEST,
  DISABLE_CUSTOM_ROLE_SUCCESS,
  DELETE_CUSTOM_ROLE_ERROR,
  DELETE_CUSTOM_ROLE_REQUEST,
  DELETE_CUSTOM_ROLE_SUCCESS,
  EDIT_CUSTOM_ROLE_ERROR,
  EDIT_CUSTOM_ROLE_REQUEST,
  EDIT_CUSTOM_ROLE_SUCCESS,
  GET_ROLE_ERROR,
  GET_ROLE_REQUEST,
  GET_ROLE_SUCCESS,
  GET_ROLES_LIST_ERROR,
  GET_ROLES_LIST_REQUEST,
  GET_ROLES_LIST_SUCCESS,
  SET_CANCEL_TOKEN,
} from '../../actions/roles/rolesActions';

const state = {
  isLoading: false,
  roles: [],
  pagination: {},
  source: null,
};

const actions = {
  [GET_ROLES_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    let url = `${params.accountId}/contributor-roles/list?p=${params.page}&l=15&filterField=cr.name&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_ROLES_LIST_SUCCESS, resp.data);
        localStorage.setItem('rolesPage', resp.data.pagination.page);
        resolve();
      })
      .catch(() => {
        commit(GET_ROLES_LIST_ERROR);
      });
  }),
  [ADD_CUSTOM_ROLE_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(ADD_CUSTOM_ROLE_REQUEST);

    apiCall.post(`${accountId}/contributor-roles`, data)
      .then((res) => {
        commit(ADD_CUSTOM_ROLE_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_CUSTOM_ROLE_ERROR, error);
        reject(error);
      });
  }),
  [DELETE_CUSTOM_ROLE_REQUEST]:
  ({ commit }, { accountId, roleId }) => new Promise((resolve, reject) => {
    commit(DELETE_CUSTOM_ROLE_REQUEST);

    apiCall.delete(`${accountId}/contributor-roles/${roleId}`)
      .then((res) => {
        commit(DELETE_CUSTOM_ROLE_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(DELETE_CUSTOM_ROLE_ERROR, error);
        reject(error);
      });
  }),
  [GET_ROLE_REQUEST]: ({ commit }, { accountId, roleId }) => new Promise((resolve, reject) => {
    commit(GET_ROLE_REQUEST);

    apiCall.get(`${accountId}/contributor-roles/${roleId}`)
      .then((resp) => {
        commit(GET_ROLE_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_ROLE_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_CUSTOM_ROLE_REQUEST]:
  ({ commit }, { accountId, roleId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_CUSTOM_ROLE_REQUEST);

    apiCall.put(`${accountId}/contributor-roles/${roleId}`, data)
      .then((resp) => {
        commit(EDIT_CUSTOM_ROLE_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_CUSTOM_ROLE_ERROR, error);
        reject(error);
      });
  }),
  [DISABLE_CUSTOM_ROLE_REQUEST]:
  ({ commit }, { accountId, roleId, enabled }) => new Promise((resolve, reject) => {
    commit(DISABLE_CUSTOM_ROLE_REQUEST);

    const url = `${accountId}/contributor-roles/${roleId}/disable`;

    apiCall.put(url, { enabled })
      .then((res) => {
        commit(DISABLE_CUSTOM_ROLE_SUCCESS);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data.form_errors.children.enabled.errors.length;
        commit(DISABLE_CUSTOM_ROLE_ERROR, error);
        reject(error);
      });
  }),
};

const mutations = {
  [GET_ROLES_LIST_SUCCESS]: (state, payload) => {
    state.roles = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  [ADD_CUSTOM_ROLE_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_CUSTOM_ROLE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_CUSTOM_ROLE_ERROR]: () => {
    state.isLoading = false;
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
  },
  [EDIT_CUSTOM_ROLE_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_CUSTOM_ROLE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_CUSTOM_ROLE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ROLE_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_ROLE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_ROLE_ERROR]: () => {
    state.isLoading = false;
  },
  [DISABLE_CUSTOM_ROLE_REQUEST]: () => {
    state.isLoading = true;
  },
  [DISABLE_CUSTOM_ROLE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DISABLE_CUSTOM_ROLE_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_CUSTOM_ROLE_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_CUSTOM_ROLE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_CUSTOM_ROLE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ROLES_LIST_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
