export const CREATE_SERIES_REQUEST = 'CREATE_SERIES_REQUEST';
export const CREATE_SERIES_SUCCESS = 'CREATE_SERIES_SUCCESS';
export const CREATE_SERIES_ERROR = 'CREATE_SERIES_ERROR';

export const GET_SERIES_REQUEST = 'GET_SERIES_REQUEST';
export const GET_SERIES_SUCCESS = 'GET_SERIES_SUCCESS';
export const GET_SERIES_ERROR = 'GET_SERIES_ERROR';

export const GET_SERIES_LIST_REQUEST = 'GET_SERIES_LIST_REQUEST';
export const GET_SERIES_LIST_SUCCESS = 'GET_SERIES_LIST_SUCCESS';
export const GET_SERIES_LIST_ERROR = 'GET_SERIES_LIST_ERROR';

export const EDIT_SERIES_REQUEST = 'EDIT_SERIES_REQUEST';
export const EDIT_SERIES_SUCCESS = 'EDIT_SERIES_SUCCESS';
export const EDIT_SERIES_ERROR = 'EDIT_SERIES_ERROR';

export const DELETE_SERIES_REQUEST = 'DELETE_SERIES_REQUEST';
export const DELETE_SERIES_SUCCESS = 'DELETE_SERIES_SUCCESS';
export const DELETE_SERIES_ERROR = 'DELETE_SERIES_ERROR';
