// eslint-disable-next-line no-useless-escape, import/prefer-default-export
export const PASSWORD_REG_EXP = /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[\!\@\#\$\%\^\&\*\(\)\_\+\-\=\{\[\]\}\:\;\"'\\\|\,\<\.\>\/\?\`\~]).{8,}$/;

// eslint-disable-next-line no-useless-escape, import/prefer-default-export
export const DOMAIN_REG_EXP = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

export const MEDIA_TAG_REG_EXP = /^[A-Za-z\d\s]*$/;

export const NUMBER_INPUT_REG_EXP = /[0-9]|\./;

export const IS_TIME_VALID = /([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])/;

// eslint-disable-next-line no-useless-escape, import/prefer-default-export
export const URL_REG_EXP = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
