export const ADD_PERSON_REQUEST = 'ADD_PERSON_REQUEST';
export const ADD_PERSON_SUCCESS = 'ADD_PERSON_SUCCESS';
export const ADD_PERSON_ERROR = 'ADD_PERSON_ERROR';

export const GET_PEOPLE_REQUEST = 'GET_PEOPLE_REQUEST';
export const GET_PEOPLE_SUCCESS = 'GET_PEOPLE_SUCCESS';
export const GET_PEOPLE_ERROR = 'GET_PEOPLE_ERROR';

export const GET_PERSON_REQUEST = 'GET_PERSON_REQUEST';
export const GET_PERSON_SUCCESS = 'GET_PERSON_SUCCESS';
export const GET_PERSON_ERROR = 'GET_PERSON_ERROR';

export const EDIT_PERSON_REQUEST = 'EDIT_PERSON_REQUEST';
export const EDIT_PERSON_SUCCESS = 'EDIT_PERSON_SUCCESS';
export const EDIT_PERSON_ERROR = 'EDIT_PERSON_ERROR';

export const DESTROY_PERSON_REQUEST = 'DESTROY_PERSON_REQUEST';
export const DESTROY_PERSON_SUCCESS = 'DESTROY_PERSON_SUCCESS';

export const SET_CANCEL_TOKEN = 'SET_CANCEL_TOKEN';

export const CHANGE_PERSON_STATUS_REQUEST = 'CHANGE_PERSON_STATUS_REQUEST';
export const CHANGE_PERSON_STATUS_SUCCESS = 'CHANGE_PERSON_STATUS_SUCCESS';
export const CHANGE_PERSON_STATUS_ERROR = 'CHANGE_PERSON_STATUS_ERROR';
