<template>
  <div class="schedule">
    <div ref="mobileTree">
      <div
        v-if="isMobileTree"
        class="mobile-media-tree-slider"
      >
        <div class="media-tree-wrap">
          <div class="schedule-mobile">
            <ScheduleMediaTree />
          </div>
        </div>
      </div>
    </div>
    <div v-if="view === 'day'">
      <div
        ref="mobileTreeBtn"
        class="mobile-folder"
      >
        <a
          @click="toggleMobileTree"
        >
          <inline-svg
            :src="iconMobileFolder"
            class="svg-icon"
          />
        </a>
      </div>
      <div
        class="media-tree-wrap"
      >
        <div class="schedule-media-tree-wrap">
          <ScheduleMediaTree />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="wrap">
        <div class="system">
          Schedule
        </div>
        <div class="nav">
          <div class="sub-nav">
            <inline-svg
              :src="iconFolder"
              class="svg-icon"
            >
            </inline-svg>
            Linear Live (24/7)
            <router-link
              :to="setCalendarLink"
              tag="div"
              class="router active"
            >
              Calendar
            </router-link>
            <router-link
              to="/schedule/settings"
              tag="div"
              class="router"
            >
              Scheduler Settings
            </router-link>
            <router-link
              to="/schedule/delivery-settings"
              tag="div"
              class="router"
            >
              Delivery Settings
            </router-link>
          </div>
          <div
            class="sub-nav"
          >
            <inline-svg
              :src="iconFolder"
              class="svg-icon"
            >
            </inline-svg>
            Scheduled Events
            <router-link
              to="/schedule/event-group"
              tag="div"
              class="router"
            >
              Event Groups
            </router-link>
            <router-link
              to="/schedule/standalone-event"
              tag="div"
              class="router"
              exact
            >
              Standalone
            </router-link>
            <router-link
              v-if="getCurrentAccount.tvAppsEnabled"
              to="/schedule/apps-event-group"
              tag="div"
              class="router"
            >
              Apps Event Group
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div :class="['schedule-container', tz ? 'current-tz' : 'other-tz']">
      <ScheduleCalendar
        :key="view+date.toString()"
        :view="view"
        :date="date"
        :items="tempItems"
        :conflicts="conflicts"
        :isConflictErrors="isConflictErrors"
        :conflictErrors="conflictErrors"
        :onCloseConflictModal="onCloseConflictModal"
        :onViewChange="onViewChange"
        :onDateChange="onDateChange"
        :onAddEvent="onAddEvent"
        :onSaveDay="onSaveDay"
        :onRemoveEvent="onRemoveEvent"
        :onExcludeEvent="onExcludeEvent"
        :getConflictErrors="getConflictErrors"
        :conflictTime="{startTime, finishTime}"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {
  mapState,
  mapGetters,
} from 'vuex';
import {
  GET_SCHEDULE_INTERVAL,
  EDIT_SCHEDULE_INTERVAL,
  GET_SCHEDULE,
  GET_NEW_SCHEDULE_ITEM_REQUEST,
} from '../../store/actions/schedule/schedule';
import { SET_EDIT_FORM } from '../../store/actions/common';
import CONFIG from '../../constants/config';

import ScheduleMediaTree from '../../components/schedule/ScheduleMediaTree.vue';
import ScheduleCalendar from '../../components/schedule/ScheduleCalendar.vue';
import BaseForm from '../../components/forms/BaseForm.vue';
import CustomFieldFormMixin from '../../components/forms/customFields/CustomFieldFormMixin.vue';

import iconMobileFolder from '../../assets/icons/mobile-tree-folder.svg';
import iconFolder from '../../assets/icons/icon-folder.svg';

export default {
  name: 'Schedule',
  components: {
    ScheduleMediaTree,
    ScheduleCalendar,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  data() {
    return {
      iconMobileFolder,
      iconFolder,
      view: 'week',
      date: null,
      isMobileTree: false,
      defaultCustomFields: [],
      items: [],
      excludedItems: [],
      tempItems: [],
      formErrors: [],
      conflicts: [],
      conflictErrors: [],
      isConflictErrors: false,
      startTime: null,
      finishTime: null,
      tz: null,
      errorMessage: CONFIG.errorMessages.commonServerError,
      conflictErrorMessage: CONFIG.errorMessages.scheduleConflictsError,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.common.profile,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
    ...mapGetters([
      'isSuperAdmin',
      'getCurrentAccount',
      'getCurrentAccountId',
    ]),
    scheduleId() {
      return this.getCurrentAccount.schedule.id;
    },
    isToday() {
      return (this.date === moment().format('YYYY-MM-DD'));
    },
    submitMessage() {
      return this.isToday
        ? CONFIG.schedule.successSaveAndStartPublish
        : CONFIG.schedule.successSave;
    },
    setCalendarLink() {
      return `/schedule?view=week&date=${moment().format('YYYY-MM-DD')}`;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query.view': function () {
      this.updateParams();
    },
    // eslint-disable-next-line func-names
    '$route.query.date': function () {
      this.updateParams();
    },
  },
  created() {
    document.addEventListener('mousedown', this.clickOutside);
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    if (this.isValidQuery()) {
      this.view = this.$route.query.view;
      this.date = this.$route.query.date;

      this.startTime = this.$route.query.startTime;
      this.finishTime = this.$route.query.finishTime;
    }

    const { view } = this.$route.query;
    const tz = this.getTimezone();
    moment.tz.setDefault(tz);
    const data = {
      accountId: this.getCurrentAccountId,
      scheduleId: this.scheduleId,
      startDate: moment(this.date).startOf(view).utc().format('YYYY-MM-DD HH:mm:ss'),
      finishDate: moment(this.date).endOf(view).utc().format('YYYY-MM-DD HH:mm:ss'),
    };

    if (this.view === 'day') {
      this.getPublishInformation();
    }

    this.getEvents(data);
  },
  destroyed() {
    document.removeEventListener('mousedown', this.clickOutside);
    sessionStorage.removeItem('folderScheduleId');
    sessionStorage.removeItem('parentsSchedulePath');
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.fullPath);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  methods: {
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    clickOutside(e) {
      if (this.isMobileTree && (this.$refs.mobileTree
        && !this.$refs.mobileTree.contains(e.target)
        && !this.$refs.mobileTreeBtn.contains(e.target))) {
        this.isMobileTree = false;
      }
    },
    toggleMobileTree() {
      this.isMobileTree = !this.isMobileTree;
    },
    updateParams() {
      this.tempItems = [];
      this.view = this.$route.query.view;
      this.date = this.$route.query.date;
      this.startTime = this.$route.query.startTime;
      this.finishTime = this.$route.query.finishTime;

      if (this.view === 'day') {
        this.getPublishInformation();
      }
      const data = {
        accountId: this.getCurrentAccountId,
        scheduleId: this.scheduleId,
        startDate: moment(this.date).startOf(this.view).utc().format('YYYY-MM-DD HH:mm:ss'),
        finishDate: moment(this.date).endOf(this.view).utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      this.getEvents(data);
    },
    getPublishInformation() {
      const params = {
        scheduleId: this.scheduleId,
        accountId: this.getCurrentAccountId,
      };
      this.$store.dispatch(GET_SCHEDULE, params);
    },
    isValidQuery() {
      const {
        view,
        date,
      } = this.$route.query;
      const isValidDate = moment(date, 'YYYY-MM-DD', true).isValid();
      const defaultValue = (view === 'week' || view === 'day') ? view : 'week';
      if ((view === 'week' || view === 'day') && isValidDate) {
        return true;
      }
      if (isValidDate) {
        this.$router.push({ path: '/schedule', query: { view: defaultValue, date } });
      } else {
        this.$router.push({ path: '/schedule', query: { view: defaultValue, date: moment().format('YYYY-MM-DD') } });
      }
      return false;
    },
    onViewChange(value) {
      this.view = value;

      const data = {
        accountId: this.getCurrentAccountId,
        scheduleId: this.scheduleId,
        startDate: moment(this.date).startOf(value).utc().format('YYYY-MM-DD HH:mm:ss'),
        finishDate: moment(this.date).endOf(value).utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      this.getEvents(data);
    },
    onDateChange(value) {
      this.date = value;
    },
    getTimezone() {
      let tz = moment.tz.guess();
      this.tz = tz;
      if (this.profile.timezone) {
        tz = this.profile.timezone;
        this.tz = null;
      }
      return tz;
    },
    getEvents(data) {
      this.$store.dispatch(GET_NEW_SCHEDULE_ITEM_REQUEST, data)
        .then((res) => {
          this.defaultCustomFields = res.customFields;

          this.$store.dispatch(GET_SCHEDULE_INTERVAL, data)
            .then((response) => {
              const result = [];
              const res = this.extractEventsFromResponse(response);

              res.current.forEach((item) => {
                const timelineItem = this.getItemInfo(item);
                result.push(timelineItem);
              });
              res.previous.forEach((item) => {
                const timelineItem = this.getItemInfo(item, true);
                result.push(timelineItem);
              });
              if ((this.startTime && (this.startTime.length > 0))
                && (this.finishTime && (this.finishTime.length > 0))) {
                const conflict = {
                  id: 1,
                  isConflict: true,
                  isExternal: false,
                  title: 'Current conflict item',
                  duration: moment(this.finishTime, 'HH:mm:ss')
                    .subtract(moment(this.startTime, 'HH:mm:ss')
                      .diff(moment().startOf('day'), 'seconds'), 'seconds'),
                  itemDuration: moment(this.finishTime, 'HH:mm:ss')
                    .subtract(moment(this.startTime, 'HH:mm:ss')
                      .diff(moment().startOf('day'), 'seconds'), 'seconds'),
                  description: '',
                  start: `${this.date} ${this.startTime}`,
                  end: `${this.date} ${this.finishTime}`,
                  isError: false,
                  isPrevious: true,
                  class: 'conflict',
                  draggable: false,
                  error: {},
                };
                result.push(conflict);
              }
              this.tempItems = [...result];
            });
        });
    },
    getItemInfo(item, isPrevious = false) {
      const start = this.getStartDate(item.startDateTimeTz, isPrevious);
      const endDate = this.getEndDate(item.finishDateTimeTz);
      const recurringType = item.recurring ? this.getRepeatType(item) : 'daily';

      const result = {
        id: item.mediaItem.id,
        isExternal: false,
        title: item.name,
        duration: item.duration,
        itemDuration: item.duration,
        description: item.description,
        recurring: item.recurring,
        start,
        end: endDate,
        isError: false,
        isPrevious,
        class: isPrevious ? 'previous' : '',
        draggable: !isPrevious,
        error: {},
        excludedDays: item.excludedDays || [],
        recurringType: { name: recurringType, value: recurringType },
        customFields: item.customFields || [],
      };
      if (result.recurring) {
        result.recurringStartDate = item.recurringStartDate;
        result.finishDate = item.finishDate || null;
      }

      return result;
    },
    getEndDate(finish) {
      const tz = this.getTimezone();
      return moment(finish).tz(tz).format('YYYY-MM-DD HH:mm:ss');
    },
    getStartDate(start, isPrevious) {
      const tz = this.getTimezone();
      return isPrevious
        ? moment(start).startOf('day').tz(tz).format('YYYY-MM-DD HH:mm:ss')
        : moment(start).tz(tz).format('YYYY-MM-DD HH:mm:ss');
    },
    getRepeatType(item) {
      let count = 0;
      Object.entries(item).forEach(([key, value]) => {
        if (key.includes('every') && value) {
          count += 1;
        }
      });
      return count > 1 ? 'daily' : 'weekly';
    },
    onAddEvent(element) {
      this.$store.dispatch(SET_EDIT_FORM, true);
      const { event } = element;
      const tz = moment.tz.guess();
      if (event.id) {
        if (event.isExternal) {
          const newEvent = {
            id: event.id,
            isExternal: false,
            title: event.title,
            recurring: false,
            start: moment(event.start).tz(tz).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(event.start, 'YYYY-MM-DD HH:mm:ss').tz(tz)
              .add(event.durationTime * 60, 'seconds')
              .format('YYYY-MM-DD HH:mm:ss'),
            description: event.description,
            duration: event.isExternal ? event.durationTime : event.duration,
            itemDuration: event.isExternal ? event.durationTime * 60 : event.itemDuration,
            isError: false,
            error: {},
            excludedDays: [],
            finishDate: null,
            customFields: this.copyDefaultField(),
          };
          this.tempItems.push(newEvent);
        } else {
          this.tempItems.forEach((item) => {
            const oldDate = moment(element.oldDate).tz(tz).format('YYYY-MM-DD HH:mm:ss');
            const itemStart = moment(item.start).format('YYYY-MM-DD HH:mm:ss');
            if ((item.id === event.id) && (oldDate === itemStart)) {
              item.start = moment(event.start).tz(tz).format('YYYY-MM-DD HH:mm:ss');
              item.end = moment(item.start, 'YYYY-MM-DD HH:mm:ss')
                .add(item.itemDuration, 'seconds')
                .format('YYYY-MM-DD HH:mm:ss');

              this.getConflictErrors(item);

              const finishDate = moment(item.start, 'YYYY-MM-DD HH:mm:ss').add(item.itemDuration, 'seconds').format('YYYY-MM-DD HH:mm:ss');
              if (moment(finishDate, 'YYYY-MM-DD HH:mm:ss') > moment(item.end, 'YYYY-MM-DD HH:mm:ss')) {
                item.isNextDay = true;
                item.nextEnd = finishDate;
              }
            }
          });
        }
      } else {
        this.tempItems = [...this.tempItems];
      }
    },
    copyDefaultField() {
      const data = [];

      if (this.defaultCustomFields && this.defaultCustomFields.length) {
        this.defaultCustomFields.forEach((item) => {
          data.push({ ...item });
        });
      }

      return data;
    },
    getConflictErrors(element) {
      const itemsForDelete = [];
      this.conflicts.forEach((item) => {
        if ((item.item.id === element.id)
          && (item.item.start === element.start)) {
          delete element.error.startDate;
          if (Object.keys(element.error).length <= 0) {
            element.isError = false;
          }
          itemsForDelete.push(item);
        }

        item.value.forEach((valueItem) => {
          if ((valueItem.id === element.id) && (valueItem.start === element.start)) {
            item.value.splice(item.value.indexOf(valueItem), 1);
          }
          if (item.value.length === 0) {
            delete item.item.error.startDate;
            if (Object.keys(item.item.error).length <= 0) {
              item.item.isError = false;
            }
          }
        });
      });
    },
    onRemoveEvent(event) {
      this.$store.dispatch(SET_EDIT_FORM, true);
      const currentTz = moment.tz.guess();
      const time = moment(event.start).tz(currentTz).format('YYYY-MM-DD HH:mm:ss');
      this.tempItems.forEach((item, index) => {
        if ((item.id === event.id) && (item.start === time)) {
          this.tempItems.splice(index, 1);
        }
      });
    },
    onExcludeEvent(event) {
      this.$store.dispatch(SET_EDIT_FORM, true);
      const currentTz = moment.tz.guess();
      const time = moment(event.start).tz(currentTz).format('YYYY-MM-DD HH:mm:ss');
      this.tempItems.forEach((item, index) => {
        if ((item.id === event.id) && (item.start === time)) {
          item.excludedDays.push(moment(time).utc().format('YYYY-MM-DD'));
          this.excludedItems.push(item);
          this.tempItems.splice(index, 1);
        }
      });
    },
    onSaveDay() {
      const result = [];
      const currentTz = this.getTimezone();
      const allEvents = this.tempItems.concat(this.excludedItems);
      allEvents.forEach((item) => {
        const fields = item.fields && item.fields.length
          ? item.fields
          : this.getCustomFieldsData(item.customFields);
        if (!item.isPrevious) {
          const resultItem = {
            name: item.title,
            description: item.description,
            currentDate: moment(item.start).tz(currentTz).utc().format('YYYY-MM-DD'),
            startDate: moment(item.start).tz(currentTz).utc().format('YYYY-MM-DD'),
            startTime: moment(item.start).tz(currentTz).utc().format('HH:mm:ss'),
            mediaItem: item.id,
            accountSchedule: this.scheduleId,
            recurring: item.recurring,
            duration: item.itemDuration,
            fields,
          };
          if (item.recurring) {
            resultItem.startDate = item.recurringStartDate || resultItem.startDate;
            const date = moment(resultItem.currentDate);
            resultItem.startTime = moment(item.start)
              .year(date.year())
              .month(date.month())
              .day(date.day())
              .tz(currentTz)
              .utc()
              .format('HH:mm:ss');
            if (item.selectedRange && item.selectedRange === 'noEnd') {
              resultItem.finishDate = null;
            } else {
              resultItem.finishDate = item.endDate || item.finishDate;
            }
            resultItem.excludedDays = item.excludedDays;

            if (item.recurringType.value === 'daily') {
              resultItem.everyMon = true;
              resultItem.everyTue = true;
              resultItem.everyWed = true;
              resultItem.everyThu = true;
              resultItem.everyFri = true;
              resultItem.everySat = true;
              resultItem.everySun = true;
            }
            if (item.recurringType.value === 'weekly') {
              const day = moment(item.start).utc().format('ddd');
              resultItem[`every${day}`] = true;
            }
          }
          result.push(resultItem);
        }
      });

      const data = {
        accountId: this.getCurrentAccountId,
        scheduleId: this.scheduleId,
        data: {
          schedule: result,
          startDate: moment(this.date).startOf(this.view).utc().format('YYYY-MM-DD HH:mm:ss'),
          finishDate: moment(this.date).endOf(this.view).utc().format('YYYY-MM-DD HH:mm:ss'),
        },
      };
      this.$store.dispatch(EDIT_SCHEDULE_INTERVAL, data)
        .then(() => {
          this.conflictErrors = [];
          this.$toasted.global.success({
            message: this.submitMessage,
          });
          this.$store.dispatch(SET_EDIT_FORM, false);
          this.clearErrors();
          this.clearConflicts();
          this.excludedItems = [];
        })
        .catch((error) => {
          this.onSubmitFail(error);
          const errorMessage = this.isConflictError(error)
            ? this.conflictErrorMessage : this.errorMessage;
          this.$toasted.global.error({
            message: errorMessage,
          });
        });
    },
    clearConflicts() {
      // eslint-disable-next-line array-callback-return,consistent-return
      const result = this.tempItems.filter((item) => {
        if (!item.isConflict) {
          return item;
        }
      });
      this.tempItems = result;
      const query = { ...this.$route.query };
      delete query.startTime;
      delete query.finishTime;
      this.$router.replace({ query });
    },
    clearErrors() {
      this.tempItems.forEach((item) => {
        if (item.isError) {
          item.error = '';
          item.isError = false;
        }
      });
    },
    onSubmitFail(error) {
      const generalErrors = error.form_errors ? error.form_errors.children : null;
      if (generalErrors && generalErrors) {
        this.formErrors = generalErrors.schedule.children;
        const tempErrorList = [];
        const allEvents = this.tempItems.concat(this.excludedItems);
        const currentEvents = allEvents.filter((item) => !item.isPrevious);

        Object.entries(this.formErrors).forEach(([field, value]) => {
          currentEvents.forEach((item, index) => {
            if (index === Number(field)) {
              item.error = value.children;
              item.isError = true;
              tempErrorList.push({ item, value: [] });

              if (this.excludedItems.includes(item)) {
                this.tempItems.push(item);
                this.excludedItems = this.excludedItems.filter((value) => value !== item);
              }
            }
          });
        });
        this.checkConflictErrors(tempErrorList);
        this.isConflictErrors = false;
        if (this.checkRecurringConflictErrors(error)) {
          this.isConflictErrors = true;
        }
      }
    },
    onCloseConflictModal() {
      this.isConflictErrors = false;
    },
    checkRecurringConflictErrors(error) {
      let isConflict = false;
      let formErrors = null;
      const generalErrors = error.form_errors ? error.form_errors.children : null;
      if (generalErrors && generalErrors.schedule) {
        formErrors = generalErrors.schedule.children;

        // eslint-disable-next-line no-unused-vars
        Object.entries(formErrors).forEach(([field, value]) => {
          if (value.children && value.children.startDate) {
            const { startDate } = value.children;
            const errors = startDate.errors.filter((item) => item.error === 'SCHEDULE_BEYOND_INTERVAL_INVALID');
            if (errors && errors.length) {
              this.conflictErrors = errors[0].payload;
              isConflict = true;
            }
          }
        });
      }
      return isConflict;
    },
    checkConflictErrors(errorObject) {
      errorObject.forEach((errorItem) => {
        this.tempItems.forEach((i) => {
          if (moment(i.start).isBetween(errorItem.item.start, errorItem.item.end)
            || moment(errorItem.item.start).isBetween(i.start, i.end)) {
            errorItem.value.push(i);
          }
        });
      });
      this.conflicts = errorObject;
    },
    isConflictError(error) {
      let isConflict = false;
      const generalErrors = error.form_errors ? error.form_errors.children : null;
      if (generalErrors && generalErrors) {
        this.formErrors = generalErrors.schedule.children;

        // eslint-disable-next-line no-unused-vars
        Object.entries(this.formErrors).forEach(([field, value]) => {
          if (value.children.startDate) {
            isConflict = true;
          }
        });
      }
      return isConflict;
    },
    extractEventsFromResponse(response) {
      if (this.view === 'day') {
        const ids = [];
        const events = {
          current: [],
          previous: response.previous,
        };

        response.current.forEach((item) => {
          if (!ids.includes(item.id)) {
            events.current.push(item);
            ids.push(item.id);
          }
        });

        return events;
      }

      return response;
    },
  },
};
</script>

<style scoped>
  .wrap {
    border-right: 1px solid #383b40;
    flex-direction: column;
    height: 100vh;
    min-width: 330px;
  }

  .system {
    border-bottom: 1px solid #383b40;
    padding: 30px 0 25px 25px;
  }

  .nav {
    background: transparent;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }

  .sub-nav {
    margin-bottom: 40px;
    font-size: 1.2em;
  }

  .sub-nav .svg-icon {
    margin-right: 10px;
  }

  .schedule {
    display: flex;
  }

  .schedule-container {
    padding: 20px;
    width: 100%;
  }

  .media-tree-wrap {
    border-right: 1px solid #383b40;
    flex-direction: column;
    min-width: 570px;
  }

  .router {
    position: relative;
    width: 100%;
    cursor: pointer;
    padding: 25px 0 0 35px;
    font-size: 16px;
  }

  .router:hover {
    color: #2591cf;
  }

  .router::before {
    background-color: #4c5057;
    bottom: 10px;
    content: '';
    left: 8px;
    display: block;
    height: 40px;
    position: absolute;
    width: 2px;
  }

  .router::after {
    background-color: #4c5057;
    content: '';
    display: block;
    height: 2px;
    left: 8px;
    position: absolute;
    top: 35px;
    transform: translateY(-50%);
    width: 15px;
  }

  .mobile-folder,
  .mobile-media-tree-slider {
    display: none;
  }

  @media all and (max-width: 1200px) {
    .schedule-media-tree-wrap {
      display: none;
    }

    .schedule {
      display: block;
    }

    .schedule-container {
      padding-top: 0;
      width: auto;
    }

    .mobile-folder {
      display: block;
      margin: 20px 0 0 20px;
    }

    .search-wrapper {
      margin-right: 10px;
    }

    .mobile-folder {
      cursor: pointer;
    }

    .mobile-media-tree-slider {
      background-color: #222;
      border: 1px solid #383b40;
      border-left: 0;
      display: block;
      margin-top: 50px;
      position: absolute;
      width: auto;
      z-index: 9;
    }

    .tree-container {
      height: calc(100vh - 270px);
    }

    .wrap {
      display: none;
    }
  }

  .active {
    color: #2591cf !important;
  }
</style>
<style>
  .other-tz .vuecal__now-line {
    display: none;
  }
</style>
