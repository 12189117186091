<template>
  <div class="wrapper">
    <Loader :isVisible="isLoading" />
    <div class="asset-inspector">
      <div class="player-wrapper">
        <div class="btn-row">
          <div class="btn-row-left">
            <Button
              :title="'Direct Link'"
              :classType="'primary'"
              :eventFunction="onGetDirectLinkHandle"
              :smallSize="true"
            />
          </div>
          <div class="btn-row-right">
            <Button
              v-if="deleteShow"
              :title="'Delete'"
              :classType="'transparent'"
              :smallSize="true"
              :eventFunction="onOpenDeleteModalHandle"
              :disabled="mediaItem.locked
                || (source.status === 'processing')
                || !source.canDelete"
            />
            <Button
              v-if="replaceShow"
              :title="'Replace'"
              :classType="'warning'"
              :smallSize="true"
              :eventFunction="onOpenReplaceModalHandle"
              :disabled="!isSubtitleType || mediaItem.locked"
            />
            <Button
              v-if="appyShow"
              :title="'Apply'"
              :classType="'primary'"
              :className="'apply-btn'"
              :smallSize="true"
              :eventFunction="submitAssetForm"
              :disabled="mediaItem.locked
                || (isAltAudio && (source.status === 'error' || source.status === 'processing'))
                || !source.canEdit"
            />
            <div class="close-btn">
              <inline-svg
                :src="iconClose"
                class="svg-icon"
                @click="handleCloseInspector"
              >
              </inline-svg>
            </div>
          </div>
        </div>
        <div class="player-area">
          <div>
            <div
              v-if="(isVideoType || isAudioType || isAltAudio || isDefaultSuppAudio || isMezzAudio)
                && !error"
              class="player"
            >
              <BitmovinPlayer :mediaItem="playerSource" />
            </div>
            <div
              v-else-if="isPosterType && !error"
              class="poster-image"
            >
              <PosterImage
                ref="posterImage"
                :poster="source.url"
                :posterName="source.name"
                :disabled="!source.isAnyPoster"
                :isPosterInspector="true"
                :imageType="'Mezzanine Image'"
                :isPoster2x3="isPoster2x3"
                :onPosterChange="handlePosterChange"
                :externalErrorMessage="imageError"
                :isNeedRemove="source.isAnyPoster"
                class="poster-2x3-image"
              />
            </div>
            <div
              v-if="error"
              class="error-block player"
            >
              <h3>
                This file format is incompatible with the current browser.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="fields-wrapper">
        <MaterialTextArea
          v-model="formData.url"
          v-bind="formFields.url"
          :className="'inspector-input'"
        />
        <MaterialInput
          v-if="isMezzImage || (!source.canEdit && !source.canDelete)"
          v-model="assetTypeRole"
          :label="'Asset Role'"
          :className="'inspector-input'"
          :disabled="true"
        />
        <div
          v-if="isAltAudio
            || isSuppAudio
            || isSuppVideo
            || isDocument
            || isOther
            || isLinkedFile
            || isImageAsset
            || isDefaultSuppAudio
            || isDefaultSuppVideo"
        >
          <ValidationObserver ref="validationObserver">
            <form
              novalidate="novalidate"
              class="custom-dates-form"
              @submit.prevent="handleSubmit(submit)"
              @input="handleFormChange"
            >
              <MaterialInput
                v-if="isAltAudio"
                v-model="formData.label"
                v-bind="formFields.label"
                :className="'inspector-input'"
              />
              <MaterialSelect
                v-if="(isImageAsset ?
                  (isImageAsset && source.entityType !== 'poster' && !source.isAnyPoster)
                  : true)"
                v-model="formData.assetRole"
                v-bind="formFields.assetRole"
                :options="assetRolesOptions"
                className="inspector-select"
                :disabled="assetRolesOptions.length === 1
                  && assetRolesOptions[0].code === 'No-items'"
              />
              <div
                v-if="formatError"
                class="format-error"
              >
                <inline-svg
                  :src="iconWarning"
                  class="svg-icon format-icon"
                >
                </inline-svg>
                {{ formatError }}
              </div>
              <MaterialSelect
                v-if="isAltAudio
                  || isSuppAudio
                  || isSuppVideo
                  || isDocument
                  || isOther
                  || isLinkedFile
                  || (isImageAsset && !source.isAnyPoster)
                  || isDefaultSuppAudio"
                v-model="formData.language"
                v-bind="formFields.language"
                :options="languages"
                :className="'inspector-select'"
              />
              <div
                v-if="isAltAudio"
                class="checkbox"
              >
                <MaterialCheckbox
                  :toggle="toggleDefaultTrack"
                  :label="'Default Audio Track'"
                  :isAlternative="true"
                  :checked="formData.isDefault"
                  :isDisabled="!!source.isDefault"
                />
              </div>
            </form>
          </ValidationObserver>
        </div>
        <SubtitleAssetForm
          v-if="isSubtitleType"
          ref="subtitleAssetForm"
          :assetData="assetData"
          :source="source"
          :onSuccess="handleFormSaveSuccess"
          :close="handleCloseInspector"
        />
      </div>
      <div class="info-wrapper">
        <div class="info-title title">
          File Information:
        </div>
        <div class="info-items">
          <div class="info-item">
            <div class="item-title">
              Title:
            </div>
            <div class="item-description type-description">
              {{ fileName }}
            </div>
          </div>
          <div class="info-item">
            <div class="item-title">
              Type:
            </div>
            <div :class="['item-description type-description', isImageAsset && 'format-image']">
              {{ source.entityType === 'supplemental file'
                && source.type === 'audio'
                ? 'Supplemental Audio'
                : source.type }}
            </div>
          </div>
        </div>
        <div
          v-if="source.format"
          class="info-items"
        >
          <div class="info-item">
            <div class="item-title">
              Format:
            </div>
            <div :class="['item-description']">
              {{ source.format || '-' }}
            </div>
          </div>
        </div>
        <div
          v-if="source.language && isSubtitleType"
          class="info-items"
        >
          <div class="info-item">
            <div class="item-title">
              Language:
            </div>
            <div class="item-description">
              {{ source.language }}
            </div>
          </div>
        </div>
        <div
          v-if="source.codec"
          class="info-items"
        >
          <div class="info-item">
            <div class="item-title">
              Codec:
            </div>
            <div class="item-description">
              {{ source.codec }}
            </div>
          </div>
        </div>
        <div
          v-if="source.dimensions"
          class="info-items"
        >
          <div class="info-item">
            <div class="item-title">
              Dimension:
            </div>
            <div class="item-description">
              {{ source.dimensions }}
            </div>
          </div>
        </div>
        <div class="info-items">
          <div class="info-item">
            <div class="item-title">
              File Size:
            </div>
            <div
              v-if="source.filesize"
              class="item-description"
            >
              {{ source.filesize | fileSize }}
            </div>
            <div
              v-else
              class="item-description"
            >
              -
            </div>
          </div>
        </div>
        <div
          v-if="source.bitrate"
          class="info-items"
        >
          <div class="info-item">
            <div class="item-title">
              Bitrate:
            </div>
            <div class="item-description">
              {{ source.bitrate | fileSize(true, 2, 'ps') }}
            </div>
          </div>
        </div>
        <div
          v-if="source.duration"
          class="info-items"
        >
          <div class="info-item">
            <div class="item-title">
              Duration:
            </div>
            <div class="item-description">
              {{ source.duration | duration }}
            </div>
          </div>
        </div>
        <div
          v-if="source.framerate && isVideoType"
          class="info-items"
        >
          <div class="info-item">
            <div class="item-title">
              Frame Rate:
            </div>
            <div class="item-description">
              {{ source.framerate }}
            </div>
          </div>
        </div>
        <div
          v-if="(source.framerate && isAudioType)
            || isSuppAudio
            || isSuppVideo
            || isAltAudio
            || isDefaultSuppAudio"
          class="info-items"
        >
          <div class="info-item">
            <div class="item-title">
              {{ isSuppVideo ? 'Frame Rate:' : 'Sample Rate:' }}
            </div>
            <div class="item-description">
              {{ source.framerate || source.frameRate }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="assetModal">
        <AssetModal
          :modal="assetModal"
          :modalMode="modalMode"
          :modalData="assetData"
          :source="source"
          :onClose="onClose"
          :onSuccess="handleModalSaveSuccess"
          :showLoader="false"
        />
      </div>
      <NotificationModal
        ref="modal"
        v-model="isModalRemoveOpen"
        :modal="isModalRemoveOpen"
        :bodyMessage="bodyMessage"
        :title="removingTitle"
        :onAccept="removeAudioAsset"
        :onClose="onCloseRemove"
        :isOrangeCancel="true"
      />
      <NotificationModal
        ref="eventWarningModal"
        v-model="isShowWarningModal"
        :modal="isShowWarningModal"
        :bodyMessage="bodyMessageAsset"
        :cancelButtonTitle="'Cancel'"
        :confirmButtonTitle="'Continue'"
        :onAccept="onContinue"
        :onClose="closeModal"
        :isOrangeCancel="true"
      />
      <NotificationModal
        ref="eventWarningModal"
        v-model="isShowMoreOneModal"
        :modal="isShowMoreOneModal"
        :bodyMessage="bodyMessageMoreOne"
        :cancelButtonTitle="'Cancel'"
        :confirmButtonTitle="'Continue'"
        :onAccept="onContinueMoreOne"
        :onClose="closeModalMoreOne"
        :isOrangeCancel="true"
      />
      <NotificationModal
        ref="defaultModal"
        v-model="isShowDefaultModal"
        :modal="isShowDefaultModal"
        :bodyMessage="bodyMessageDefaultModal"
        :onAccept="onContinueDefault"
        :onClose="closeModalDefault"
        :title="'Reassign Default Audio Track'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { SET_EDIT_FORM } from '../../../store/actions/common';
import CONFIG from '../../../constants/config';

import BitmovinPlayer from '../../common/BitmovinPlayer.vue';
import Button from '../../common/Button.vue';
import PosterImage from '../../common/PosterImage/DragAndDropFileUploader.vue';
import MaterialTextArea from '../../inputs/MaterialTextArea.vue';
import SubtitleAssetForm from '../../forms/assets/SubtitleAssetForm.vue';
import AssetModal from '../asset/modal/AssetModal.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import Loader from '../../common/Loader.vue';
import NotificationModal from '../../NotificationModal.vue';
import BaseForm from '../../forms/BaseForm.vue';
import iconClose from '../../../assets/icons/icon-close-inspector.svg';
import iconWarning from '../../../assets/icons/icon-warning-red.svg';
import MaterialCheckbox from '../../inputs/MaterialCheckbox.vue';
import {
  DELETE_ALT_AUDIO_ASSET_REQUEST,
  DELETE_DOCUMENT_ASSET_REQUEST,
  DELETE_IMAGE_ASSET_REQUEST,
  DELETE_LINKED_FILE_ASSET_REQUEST,
  DELETE_OTHER_ASSET_REQUEST,
  DELETE_SUPP_AUDIO_ASSET_REQUEST,
  DELETE_SUPP_VIDEO_ASSET_REQUEST,
  EDIT_ALT_AUDIO_ASSET_REQUEST,
  EDIT_DEFAULT_SUPP_REQUEST,
  EDIT_DOCUMENT_ASSET_REQUEST,
  EDIT_IMAGE_ASSET_REQUEST,
  EDIT_LINKED_FILE_ASSET_REQUEST,
  EDIT_MEZZ_IMAGE_ASSET_REQUEST,
  EDIT_OTHER_ASSET_REQUEST,
  EDIT_SUPP_AUDIO_ASSET_REQUEST,
  EDIT_SUPP_VIDEO_ASSET_REQUEST,
  GET_ASSET_LANGUAGE_REQUEST,
  GET_ASSET_ROLES_OPTIONS_REQUEST,
} from '../../../store/actions/media/mediaAsset';

export default {
  name: 'AssetInspector',
  components: {
    Loader,
    MaterialSelect,
    MaterialInput,
    AssetModal,
    SubtitleAssetForm,
    BitmovinPlayer,
    Button,
    PosterImage,
    MaterialTextArea,
    NotificationModal,
    MaterialCheckbox,
  },
  extends: BaseForm,
  props: {
    source: {
      type: Object,
      default: () => {},
    },
    closeInspector: {
      type: Function,
      default: () => {},
    },
    updateAssetList: {
      type: Function,
      default: () => {},
    },
    formPrefix: {
      type: String,
      default: 'supplemental',
    },
    hasDefaultAssets: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      iconClose,
      iconWarning,
      error: false,
      isModalRemoveOpen: false,
      bodyMessage: '',
      playerSource: this.getPlayerSource(),
      assetModal: false,
      modalMode: '',
      assetRolesOptions: [],
      assetTypeRole: '',
      isShowWarningModal: false,
      isShowMoreOneModal: false,
      hasContinueMoreOne: false,
      bodyMessageMoreOne: '',
      bodyMessageAsset: '',
      imageError: '',
      isPosterChanged: false,
      formatError: '',
      isShowDefaultModal: false,
      hasContinueDefault: false,
      bodyMessageDefaultModal: 'This action will remove the "Default Audio Track" option from its previously assigned audio file. Do you wish to continue?',
      assetData: {
        id: this.source.id,
        name: this.source.name,
      },
      formData: {
        url: this.source.url,
        language: this.source.language,
        label: this.source.label,
        assetRole: this.source.assetRole && this.source.assetRole.id,
        poster: this.source.url ? `${this.source.url}?height=360` : '',
        isDefault: this.source.isDefault,
      },
      formFields: {
        url: {
          name: 'url',
          label: 'Link Url',
          disabled: true,
          cols: 30,
          rows: 10,
          allowCopy: true,
        },
        label: {
          type: 'text',
          name: 'label',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 50,
          },
        },
        language: {
          name: 'language',
          formPrefix: this.formPrefix,
          rules: {
            required: this.source.type === 'alternate audio',
          },
        },
        assetRole: {
          type: 'text',
          name: 'assetRole',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
      },
      errorMapping: {
        language: [
          `${this.formPrefix}.language`,
        ],
        label: [
          `${this.formPrefix}.label`,
        ],
      },
    };
  },
  computed: {
    ...mapState({
      mediaItem: (state) => state.mediaItem.mediaItem,
      languages: (state) => state.mediaAsset.languageList,
      isLoading: (state) => state.mediaAsset.isLoading,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    isVideoType() {
      return this.source.type === 'video' || this.source.type === 'mezzanine';
    },
    isAudioType() {
      return this.source.type === 'audio';
    },
    isPosterType() {
      return this.source.type === 'image';
    },
    isSubtitleType() {
      return this.source.type === 'subtitle';
    },
    isAltAudio() {
      return this.source.type === 'alternate audio';
    },
    isDocument() {
      return this.source.type === 'document';
    },
    isOther() {
      return this.source.type === 'other';
    },
    isLinkedFile() {
      return this.source.type === 'linked-file';
    },
    isImageAsset() {
      return this.source.type === 'image' && !this.source.isAnyPoster;
    },
    isSuppAudio() {
      return this.source.entityType === 'supplemental file'
        && this.source.type === 'audio';
    },
    isMezzAudio() {
      return this.source.entityType === 'source'
        && this.source.type === 'supplemental audio'
        && this.source.assetRole.name === 'Mezzanine';
    },
    isDefaultSuppAudio() {
      return this.source.entityType === 'source'
        && this.source.type === 'supplemental audio'
        && this.source.canEdit;
    },
    isDefaultSuppVideo() {
      return this.source.entityType === 'source'
        && this.source.type === 'video'
        && this.source.canEdit;
    },
    isSuppVideo() {
      return this.source.entityType === 'supplemental file'
        && this.source.type === 'video';
    },
    isMezzImage() {
      return this.source.entityType === 'supplemental file'
        && this.source.type === 'image'
        && this.source.isAnyPoster;
    },
    isPoster2x3() {
      return this.source.entityType === 'supplemental file'
        && this.source.assetRole
        && this.source.assetRole.shortName === 'mezzanineImage2x3'
        && this.source.type === 'image'
        && this.source.isAnyPoster;
    },
    disabledApply() {
      if (!this.isSubtitleType || this.mediaItem.locked) {
        return true;
      }

      if (!this.isAltAudio || this.mediaItem.locked) {
        return true;
      }
      return false;
    },
    fileName() {
      if (this.isSubtitleType) {
        return this.source.fileName || `${this.source.label}_${this.source.language}.vtt`;
      }

      if (this.isAltAudio) {
        return this.source.fileName || `${this.source.label}_${this.source.language}.mp3`;
      }

      return this.source.name;
    },
    replaceShow() {
      if (this.mediaItem.locked) {
        return false;
      }

      if (this.isAltAudio) {
        return false;
      }

      if (this.isSuppVideo) {
        return false;
      }

      if (this.isDocument) {
        return false;
      }

      if (this.isOther) {
        return false;
      }

      if (this.isLinkedFile) {
        return false;
      }

      if (this.isImageAsset) {
        return false;
      }

      if (!this.isSubtitleType) {
        return false;
      }

      return !this.isSuppAudio;
    },
    appyShow() {
      if (this.mediaItem.locked) {
        return false;
      }

      if (!this.source.canEdit) {
        return false;
      }

      if (this.isAltAudio && (this.source.status === 'error'
        || this.source.status === 'processing')) {
        return false;
      }

      return true;
    },
    deleteShow() {
      if (this.mediaItem.locked) {
        return false;
      }

      if (!this.source.canDelete) {
        return false;
      }

      if (this.source.status === 'processing') {
        return false;
      }

      return true;
    },
    assetTypeReq() {
      if (this.source.type === 'video') {
        return 'Video';
      }

      if (this.source.type === 'alternate audio') {
        return 'Alternate Audio';
      }

      if (this.source.type === 'audio' && this.source.entityType === 'supplemental file') {
        return 'Supplemental Audio';
      }

      if (this.source.type === 'supplemental audio' && this.source.entityType === 'source') {
        return 'Supplemental Audio';
      }

      if (this.source.type === 'document') {
        return 'Document';
      }

      if (this.source.type === 'other') {
        return 'Other';
      }

      if (this.source.type === 'linked-file') {
        return 'Linked file';
      }

      if (this.source.type === 'image') {
        return 'Image';
      }

      return '';
    },
    removingTitle() {
      if (this.source.type === 'other') {
        return 'Delete Asset of Type "Other"';
      }

      if (this.source.type === 'document') {
        return 'Delete Document';
      }

      if (this.source.type === 'linked-file') {
        return 'Delete Linked File';
      }

      if (this.source.type === 'image') {
        return 'Delete Image';
      }

      if (this.isSuppAudio) {
        return 'Delete Supplemental Audio';
      }

      if (this.isAltAudio) {
        return 'Delete Alternate Audio';
      }

      return '';
    },
  },
  created() {
    if (this.isAltAudio
      || this.isSuppAudio
      || this.isSuppVideo
      || this.isDocument
      || this.isOther
      || this.isLinkedFile
      || this.isImageAsset
      || this.isDefaultSuppAudio
      || this.isDefaultSuppVideo) {
      this.$store.dispatch(GET_ASSET_LANGUAGE_REQUEST);

      const assetRolesParams = {
        accountId: this.getCurrentAccountId,
        mediaId: this.$route.params.mediaId,
        assetType: this.assetTypeReq,
      };
      this.$store.dispatch(GET_ASSET_ROLES_OPTIONS_REQUEST, assetRolesParams).then((res) => {
        this.assetRolesOptions = res.data.map((item) => ({
          code: item.id,
          name: item.name,
          multiAsset: item.multiAsset,
          uniqueAssetType: item.uniqueAssetType,
          enabled: item.enabled,
          usedAssetId: item.usedAssetId,
          usedAssetType: item.usedAssetType,
        }));
        this.assetRolesOptions = this.assetRolesOptions
          .filter((item) => item.enabled
            || (this.source.assetRole && this.source.assetRole.id === item.code));

        if (!this.assetRolesOptions.length) {
          this.assetRolesOptions = [{
            code: 'No-items',
            name: 'No items to display',
          }];

          this.formData.assetRole = this.assetRolesOptions[0].code;
        }
      });
    }

    this.assetTypeRole = this.source.assetRole && this.source.assetRole.name;
  },
  methods: {
    handleFormChange() {
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    getPlayerSource() {
      let playerSource = null;

      switch (this.source.format) {
      case 'hls':
        playerSource = { hls: this.source.url };

        break;
      case 'mpeg-dash':
        playerSource = { dash: this.source.url };

        break;
      case 'mp4':
      case 'mp3':
      case 'webm':
      case 'mezzanine':
        playerSource = { progressive: this.source.url };

        break;
      default:
        playerSource = { progressive: this.source.url };
      }

      if (playerSource) {
        return {
          playerSource,
        };
      }

      this.error = true;

      return {};
    },
    handleModalSaveSuccess() {
      this.onClose();
      this.handleCloseInspector();
      this.updateAssetList();
    },
    handleFormSaveSuccess() {
      this.handleCloseInspector();
      this.updateAssetList();
    },
    handleCloseInspector() {
      this.closeInspector();
    },
    onGetDirectLinkHandle() {
      const {
        errorMessages,
        successMessages,
      } = CONFIG;

      this.$copyText(this.source.url)
        .then(() => {
          this.$toasted.global.success({
            message: successMessages.copyToClipboard,
          });
        })
        .catch(() => {
          this.$toasted.global.error({
            message: errorMessages.copyToClipboard,
          });
        });
    },
    closeModal() {
      this.isShowWarningModal = false;
    },
    closeModalMoreOne() {
      this.isShowMoreOneModal = false;
    },
    onContinue() {
      this.hasContinue = true;
      this.submitAssetForm();
      this.isShowWarningModal = false;
    },
    onContinueMoreOne() {
      this.hasContinueMoreOne = true;
      this.submitAssetForm();
      this.isShowMoreOneModal = false;
    },
    submitAssetForm() {
      const type = this.assetRolesOptions.find((item) => item.code === this.formData.assetRole);

      if (type
        && (!this.source.assetRole || this.source.assetRole.id !== type.code)
        && ((type.usedAssetId && type.name === 'Translation Master' && !this.hasContinue)
        || (type.usedAssetId && type.name === 'Preview Clip' && !this.hasContinue))) {
        this.bodyMessageAsset = `This action will remove this ${type.name === 'Translation Master' ? 'Translation Master' : 'Preview Clip'} Asset Role from its previously assigned ${this.assetTypeReq} Asset within this Media Item. Do you wish to continue?`;
        this.isShowWarningModal = true;
        return null;
      }

      if (type
        && (!this.source.assetRole || this.source.assetRole.id !== type.code)
        && type.uniqueAssetType
        && type.usedAssetId
        && !this.hasContinue) {
        this.bodyMessageAsset = `This action will remove this ${type.name} Asset Role from its previously assigned ${this.assetTypeReq} Asset within this Media Item. Do you wish to continue?`;
        this.isShowWarningModal = true;
        return null;
      }

      if (type
        && (!this.source.assetRole || this.source.assetRole.id !== type.code)
        && type.usedAssetType
        && type.usedAssetId
        && !type.multiAsset
        && !this.hasContinue) {
        this.bodyMessageAsset = `This action will remove this ${type.name} Asset Role from its previously assigned ${type.usedAssetType} Asset within this Media Item. Do you wish to continue?`;
        this.isShowWarningModal = true;
        return null;
      }

      if (type
        && !this.hasContinue
        && (type.name !== 'Translation Master' || type.name !== 'Preview Clip')
        && !type.multiAsset
        && (!this.source.assetRole || this.source.assetRole.id !== type.code)
        && type.usedAssetId
        && !this.hasContinueMoreOne) {
        this.bodyMessageMoreOne = `This action will remove this ${type.name} Asset Role from its previously assigned ${this.assetTypeReq} Asset within this Media Item. Do you wish to continue?`;
        this.isShowMoreOneModal = true;
        return null;
      }

      if (this.isAltAudio
        && this.hasDefaultAssets
        && (this.hasDefaultAssets.id !== this.source.id)
        && this.formData.isDefault
        && !this.hasContinueDefault) {
        this.isShowDefaultModal = true;
        return null;
      }

      if (this.isSubtitleType && this.$refs.subtitleAssetForm) {
        this.$refs.subtitleAssetForm.saveAsset();
      }

      if (this.isAltAudio) {
        this.editAltAudio();
      }

      if (this.isSuppAudio) {
        this.editSuppAudio();
      }

      if (this.isSuppVideo) {
        this.editSuppVideo();
      }

      if (this.isDocument) {
        this.editDocument();
      }

      if (this.isOther) {
        this.editOther();
      }

      if (this.isLinkedFile) {
        this.editLinked();
      }

      if (this.isMezzImage) {
        this.editMezzImage();
      }

      if (this.isImageAsset) {
        this.editImageAsset();
      }

      if (this.isDefaultSuppAudio || this.isDefaultSuppVideo) {
        this.editDefaultSupp();
      }

      return null;
    },
    onOpenDeleteModalHandle() {
      if (this.isAltAudio) {
        this.isModalRemoveOpen = true;
        this.bodyMessage = 'Are you sure you want to delete this Alternate Audio?';
      }

      if (this.isSuppAudio) {
        this.isModalRemoveOpen = true;
        this.bodyMessage = 'Are you sure you want to delete this Supplemental Audio?';
      }

      if (this.isSuppVideo) {
        this.isModalRemoveOpen = true;
        this.bodyMessage = 'Are you sure you want to delete this Video?';
      }

      if (this.isOther) {
        this.isModalRemoveOpen = true;
        this.bodyMessage = 'Are you sure you want to delete this asset?';
        return;
      }

      if (this.isDocument) {
        this.isModalRemoveOpen = true;
        this.bodyMessage = 'Are you sure you want to delete this Document?';
        return;
      }

      if (this.isLinkedFile) {
        this.isModalRemoveOpen = true;
        this.bodyMessage = 'Are you sure you want to delete this Linked File Reference?';
        return;
      }

      if (this.isImageAsset) {
        this.isModalRemoveOpen = true;
        this.bodyMessage = 'Are you sure you want to delete this Image?';
        return;
      }

      if (!this.isAltAudio && !this.isSuppAudio && !this.isSuppVideo) {
        this.assetModal = true;
        this.modalMode = 'delete';
      }
    },
    onOpenReplaceModalHandle() {
      this.assetModal = true;
      this.modalMode = 'replace';
    },
    onClose() {
      this.assetModal = false;
      this.modalMode = '';
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onCloseRemove() {
      this.isModalRemoveOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    removeAudioAsset() {
      const params = {
        accountId: this.getCurrentAccountId,
        assetId: this.source.id,
      };
      let req;
      let mess;

      if (this.isSuppAudio) {
        req = DELETE_SUPP_AUDIO_ASSET_REQUEST;
        mess = 'The Supplemental Audio asset has been successfully deleted';
      }

      if (this.isSuppVideo) {
        req = DELETE_SUPP_VIDEO_ASSET_REQUEST;
        mess = 'The Video asset has been successfully deleted';
      }

      if (!this.isSuppAudio && !this.isSuppVideo) {
        req = DELETE_ALT_AUDIO_ASSET_REQUEST;
        mess = 'The Alternate Audio asset has been successfully deleted';
      }

      if (this.isDocument) {
        req = DELETE_DOCUMENT_ASSET_REQUEST;
        mess = 'The Document asset has been successfully deleted';
      }

      if (this.isOther) {
        req = DELETE_OTHER_ASSET_REQUEST;
        mess = 'The asset of type "Other" has been successfully deleted';
      }

      if (this.isLinkedFile) {
        req = DELETE_LINKED_FILE_ASSET_REQUEST;
        mess = 'The Linked File asset has been successfully deleted';
      }

      if (this.isImageAsset) {
        req = DELETE_IMAGE_ASSET_REQUEST;
        mess = 'The Image asset has been successfully deleted';
      }

      this.$store.dispatch(req, params).then(() => {
        this.handleCloseInspector();
        this.$toasted.global.success({
          message: mess,
        });
        this.updateAssetList();
      });
    },
    editAltAudio() {
      const params = {
        accountId: this.getCurrentAccountId,
        audioId: this.source.id,
        data: {
          language: this.formData.language,
          label: this.formData.label,
          assetRole: this.formData.assetRole === 'No-items' ? '' : this.formData.assetRole,
          isDefault: this.formData.isDefault,
        },
      };
      this.$store.dispatch(EDIT_ALT_AUDIO_ASSET_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Alternate Audio asset has been successfully updated',
          });
          this.updateAssetList();
          this.handleCloseInspector();
        })
        .catch((error) => {
          this.onSubmitFail(error);
        });
    },
    editSuppAudio() {
      this.formatError = '';
      const params = {
        accountId: this.getCurrentAccountId,
        audioId: this.source.id,
        data: {
          language: this.formData.language,
          assetRole: this.formData.assetRole === 'No-items' ? '' : this.formData.assetRole,
        },
      };
      this.$store.dispatch(EDIT_SUPP_AUDIO_ASSET_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Supplemental Audio asset has been successfully updated',
          });
          this.updateAssetList();
          this.handleCloseInspector();
        })
        .catch((error) => {
          this.onSubmitFail(error);
        });
    },
    editSuppVideo() {
      const params = {
        accountId: this.getCurrentAccountId,
        audioId: this.source.id,
        data: {
          language: this.formData.language,
          assetRole: this.formData.assetRole === 'No-items' ? '' : this.formData.assetRole,
        },
      };
      this.$store.dispatch(EDIT_SUPP_VIDEO_ASSET_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Video Asset has been successfully updated',
          });
          this.updateAssetList();
          this.handleCloseInspector();
        })
        .catch((error) => {
          this.onSubmitFail(error);
        });
    },
    editDocument() {
      const params = {
        accountId: this.getCurrentAccountId,
        audioId: this.source.id,
        data: {
          language: this.formData.language,
          assetRole: this.formData.assetRole === 'No-items' ? '' : this.formData.assetRole,
        },
      };
      this.$store.dispatch(EDIT_DOCUMENT_ASSET_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Document Asset has been successfully updated',
          });
          this.updateAssetList();
          this.handleCloseInspector();
        })
        .catch((error) => {
          this.onSubmitFail(error);
        });
    },
    editOther() {
      const params = {
        accountId: this.getCurrentAccountId,
        audioId: this.source.id,
        data: {
          language: this.formData.language,
          assetRole: this.formData.assetRole === 'No-items' ? '' : this.formData.assetRole,
        },
      };
      this.$store.dispatch(EDIT_OTHER_ASSET_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The asset of type "Other" has been successfully updated',
          });
          this.updateAssetList();
          this.handleCloseInspector();
        })
        .catch((error) => {
          this.onSubmitFail(error);
        });
    },
    editLinked() {
      const params = {
        accountId: this.getCurrentAccountId,
        audioId: this.source.id,
        data: {
          language: this.formData.language,
          assetRole: this.formData.assetRole === 'No-items' ? '' : this.formData.assetRole,
        },
      };
      this.$store.dispatch(EDIT_LINKED_FILE_ASSET_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Linked File asset has been successfully updated',
          });
          this.updateAssetList();
          this.handleCloseInspector();
        })
        .catch((error) => {
          this.onSubmitFail(error);
        });
    },
    editImageAsset() {
      const params = {
        accountId: this.getCurrentAccountId,
        audioId: this.source.id,
        data: {
          language: this.formData.language,
          assetRole: this.formData.assetRole === 'No-items' ? '' : this.formData.assetRole,
        },
      };
      this.$store.dispatch(EDIT_IMAGE_ASSET_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Image asset has been successfully updated',
          });
          this.updateAssetList();
          this.handleCloseInspector();
        })
        .catch((error) => {
          this.onSubmitFail(error);
        });
    },
    editMezzImage() {
      const params = {
        accountId: this.getCurrentAccountId,
        audioId: this.source.id,
        type: this.isPoster2x3 ? 'poster-2x3' : 'poster',
        data: {
          assetRole: this.formData.assetRole,
          posterImage: this.formData.poster,
        },
      };

      if (!this.isPosterChanged) {
        delete params.data.posterImage;
      }

      this.$store.dispatch(EDIT_MEZZ_IMAGE_ASSET_REQUEST, params)
        .then(() => {
          this.isPosterChanged = false;
          this.$toasted.global.success({
            message: 'The Mezzanine Image asset has been successfully updated',
          });
          this.updateAssetList();
          this.handleCloseInspector();
        })
        .catch((error) => {
          this.onSubmitFail(error);
        });
    },
    editDefaultSupp() {
      const params = {
        accountId: this.getCurrentAccountId,
        audioId: this.source.id,
        data: {
          assetRole: this.formData.assetRole,
        },
      };

      if (this.isDefaultSuppAudio) {
        params.data.language = this.formData.language;
      }

      this.$store.dispatch(EDIT_DEFAULT_SUPP_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: `The ${this.assetTypeReq} asset has been successfully updated`,
          });
          this.updateAssetList();
          this.handleCloseInspector();
        })
        .catch((error) => {
          this.onSubmitFail(error);
        });
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      const imgError = error
        && error.form_errors
        && error.form_errors.children
        && error.form_errors.children.posterImage
        && error.form_errors.children.posterImage.errors
        && error.form_errors.children.posterImage.errors[0].error;

      const formatError = error
        && error.form_errors
        && error.form_errors.children
        && error.form_errors.children.assetRole
        && error.form_errors.children.assetRole.errors
        && error.form_errors.children.assetRole.errors[0].error;

      if (imgError === 'INVALID_MIME_TYPE_ERROR') {
        this.imageError = 'File MIME type should be PNG or JPEG';
      }

      if (formatError === 'INVALID_AUDIO_FORMAT') {
        this.formatError = 'File format is invalid. Supported audio formats for the Podcast Audio: MP3, AAC';
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    handlePosterChange(img) {
      this.isPosterChanged = true;
      this.formData.poster = img;
      if (this.imageError) {
        this.imageError = '';
      }
    },
    toggleDefaultTrack(isChecked) {
      if (isChecked !== this.formData.isDefault) {
        this.formData.isDefault = isChecked ? 1 : 0;
      }
    },
    closeModalDefault() {
      this.isShowDefaultModal = false;
    },
    onContinueDefault() {
      this.hasContinueDefault = true;
      this.submitAssetForm();
      this.isShowMoreOneModal = false;
    },
  },
};
</script>

<style scoped>
  .wrapper {
    background: #383b40;
    height: 100%;
    overflow-y: auto;
  }

  .asset-inspector {
    padding: 15px;
  }

  .player-wrapper {
    padding-bottom: 15px;
  }

  .player {
    height: auto;
    margin-left: 5px;
    width: 528px;
  }

  .svg-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
  }

  .error-block {
    align-items: center;
    background-color: #000;
    display: flex;
    height: 225px;
    justify-content: center;
  }

  .btn-row-right {
    display: flex;
  }

  .btn-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .close-btn {
    margin-left: 15px;
  }

  .info-wrapper,
  .fields-wrapper {
    font-size: 14px;
    padding-left: 5px;
    width: 530px;
  }

  .poster-image {
    margin-left: 5px;
  }

  .player-area {
    display: flex;
  }

  .info-item {
    padding: 5px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    margin-bottom: 10px;
  }

  .item-title {
    display: inline-block;
    text-align: right;
    opacity: 0.6;
  }

  .item-description {
    display: inline-block;
    margin-left: 5px;
  }

  .type-description {
    text-transform: lowercase;
  }

  .no-items-block {
    margin-bottom: 20px;
  }

  .no-items {
    font-size: 16px;
  }

  .format-image {
    text-transform: capitalize;
  }

  .format-error {
    color: #fb3951;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .format-icon {
    padding-right: 7px;
  }

  .checkbox {
    margin-bottom: 20px;
  }

  .poster-2x3-image {
    height: 310px;
  }
</style>
