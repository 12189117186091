<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, required }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    mode="passive"
  >
    <div :class="[blockClassName]">
      <div :class="['label', classNameLabel]">
        {{ fieldLabel }}
        <span
          v-if="required"
          class="asterisk"
        >
          *
        </span>
      </div>
      <datepicker
        ref="calendar2"
        v-model="valueModel"
        :format="format"
        :valueType="valueType"
        :disabledDate="disabledDate"
        :editable="editable"
        :clearable="clearable"
        :inputClass="className"
        :disabled="disabled"
        :inline="inline"
        :class="errors.length && 'failed-time'"
        @change="onChange"
      >
        <slot>
        </slot>
      </datepicker>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import Datepicker from 'vue2-datepicker';
import { ValidationProvider } from 'vee-validate';
import BaseMaterialInput from './BaseMaterialInput.vue';

export default {
  name: 'MaterialCalendar2',
  components: {
    Datepicker,
    ValidationProvider,
  },
  extends: BaseMaterialInput,
  props: {
    blockClassName: {
      type: String,
      default: 'picker-date',
    },
    format: {
      type: String,
      default: 'DD MMM YYYY',
      required: false,
    },
    classNameLabel: {
      type: String,
      default: '',
    },
    disabledDate: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
    valueType: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
  .label {
    font-size: 16px;
    margin-bottom: 11px;
  }

  .mx-datepicker-popup {
    z-index: 1000;
  }

  .mx-icon-calendar {
    display: none;
  }

  .mx-calendar {
    width: 280px;
    background: var(--main-bg);
    font-size: 15px;
    padding: 10px;
    border: 1px solid var(--gray);
  }

  .mx-calendar-header {
    height: 40px;
  }

  .mx-calendar-header,
  .mx-calendar-content .mx-date-row,
  .mx-calendar-content thead tr {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .mx-calendar-header i {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }

  .mx-btn-icon-double-left {
    order: 0;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid var(--white);
    margin-left: 10px;
  }

  i.mx-icon-double-left {
    position: relative;
    width: 0;
    height: 0;
    right: 11px;
    top: -8px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid var(--white);
  }

  .mx-btn-icon-double-left:hover,
  .mx-btn-icon-double-left:hover i.mx-icon-double-left {
    border-right-color: var(--blue);
  }

  .mx-calendar-header-label {
    font-size: 18px;
    font-weight: normal;
    order: 3;
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    color: var(--white);
  }

  .mx-calendar-header-label > .mx-btn {
    font-size: 18px;
    font-weight: normal;
  }

  .mx-calendar-header-label > .mx-btn:hover {
    color: var(--blue);
  }

  .mx-btn-icon-double-right {
    order: 5;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid var(--white);
    margin-right: 8px;
  }

  i.mx-icon-double-right {
    position: relative;
    width: 0;
    height: 0;
    right: 5px;
    top: -8px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid var(--white);
  }

  .mx-btn-icon-double-right:hover,
  .mx-btn-icon-double-right:hover i.mx-icon-double-right {
    border-left-color: var(--blue);
  }

  .mx-btn-icon-left .mx-icon-left {
    border-right-color: var(--white)
  }

  .mx-btn-icon-left:hover .mx-icon-left {
    border-right-color: var(--blue)
  }

  .mx-btn-icon-right {
    order: 4;
  }

  .mx-btn-icon-right .mx-icon-right {
    border-left-color: var(--white)
  }

  .mx-btn-icon-right:hover .mx-icon-right {
    border-left-color: var(--blue)
  }

  .mx-calendar-content .mx-table {
    width: 100%;
  }

  .mx-calendar-content .mx-table tr {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
   }

  .mx-calendar-content .mx-table td {
     flex: 1;
     color: var(--white);
   }

  .mx-calendar-content .cell,
  .mx-calendar-content th {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
  }

  .mx-calendar-content th {
    color: var(--blue);
    font-weight: normal;
    font-size: 85%;
  }

  .mx-calendar-content .mx-date-row {
    color: var(--white);
  }

  .mx-calendar-content .cell.today:not(.active) {
    color: var(--blue);
  }

  .mx-calendar-content .cell.active {
    background: var(--blue);
  }

  .mx-calendar-content .cell:hover {
    background: var(--gray);
  }

  .mx-calendar-content .cell.disabled {
    cursor: not-allowed;
  }

  .mx-calendar-content .cell.disabled div {
    color: var(--white);
    opacity: 0.3;
  }

  .mx-calendar-content .cell.not-current-month div {
    color: var(--white);
    opacity: 0.3;
  }

  .mx-calendar-decade-separator::before {
    display: inline-block;
    content: "-";
    margin: 0 10px;
  }

  .mx-datepicker {
    position: relative;
  }

  .failed-time {
    border-bottom: 1px solid #fb3951;
  }

  .mx-icon-clear {
    position: absolute;
    right: 4px;
    top: 15px;
    cursor: pointer;

    svg {
      fill: white;
    }

    :hover {
      opacity: 0.6;
    }
  }
</style>
