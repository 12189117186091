<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="login"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
    >
      <div>
        <MaterialInput
          v-model="formData.password"
          v-bind="formFields.password"
        />
      </div>
      <div>
        <MaterialInput
          v-model="formData.confirmPassword"
          v-bind="formFields.confirmPassword"
        />
      </div>
      <div class="reset-passwor-block">
        <div class="link">
          <router-link
            class="link-to"
            to="/login"
          >
            Back to Sign In
          </router-link>
        </div>
        <Button
          :title="buttonTitle"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import BaseForm from './BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import CONFIG from '../../constants/config';
import { RESET_PASSWORD_REQUEST } from '../../store/actions/authActions/auth';
import { PASSWORD_REG_EXP } from '../../utils/validation/regExp';
import Button from '../common/Button.vue';

export default {
  name: 'ResetPasswordForm',
  components: {
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'resetPassword',
    },
    isSubmitted: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: 'Reset Password',
    },
  },
  data() {
    return {
      formData: {
        password: '',
        confirmPassword: '',
      },
      formFields: {
        password: {
          type: 'password',
          name: 'password',
          autocomplete: 'new-password',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            regex: PASSWORD_REG_EXP,
            max: 255,
          },
        },
        confirmPassword: {
          type: 'password',
          name: 'confirmPassword',
          autocomplete: 'new-password',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            confirmed: 'password',
            max: 255,
          },
        },
      },
      errorMapping: {
        password: [
          `${this.formPrefix}.password.first`,
        ],
        confirmPassword: [
          `${this.formPrefix}.password.second`,
        ],
      },
      submitEvent: RESET_PASSWORD_REQUEST,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  methods: {
    getRequestData() {
      return {
        password: {
          first: this.formData.password,
          second: this.formData.confirmPassword,
        },
        token: this.$route.params.id,
      };
    },
    onSubmitSuccess() {
      this.$emit('update:isSubmitted', true);
    },
  },
};
</script>

<style scoped>
  .login {
    display: flex;
    flex-direction: column;
    padding: 2em 2em 0;
    width: 450px;
  }

  .reset-passwor-block {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .link-to {
    text-decoration: none;
  }
</style>
