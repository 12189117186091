<!-- eslint-disable vue/valid-v-model -->
<template>
  <div
    v-if="modal"
    class="overlay"
  >
    <div
      v-model="modal"
      class="wrapper"
    >
      <div class="body-text">
        {{ bodyErrorMessage }}
      </div>
      <div class="button-wrapper">
        <Button
          :title="'Ok'"
          :classType="'primary'"
          :eventFunction="closeModalhandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  SET_OVERFLOW,
} from '../store/actions/common';
import Button from './common/Button.vue';

export default {
  name: 'ErrorModal',
  components: {
    Button,
  },
  props: {
    modal: Boolean,
    bodyErrorMessage: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
  },
  methods: {
    closeModal() {
      if (this.onClose) {
        this.onClose();
        return;
      }

      this.$emit('input', false);
      this.$store.dispatch(SET_OVERFLOW, false);
    },
    closeModalhandler() {
      this.closeModal();
    },
  },
};
</script>

<style scoped>
  .wrapper {
    background-color: #383b40;
    border-radius: 4px;
    display: block;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    padding: 30px;
    position: absolute;
    text-decoration: none;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .headline,
  .edit-wrapper {
    color: #fff;
  }

  .edit-wrapper {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375rem;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    word-break: break-all;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }

  .lighten-2 {
    color: #fff;
    font-size: 16px;
  }

  .body-text {
    color: #fff;
    font-size: 16px;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }
</style>
