<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="flex">
      <div class="left-path">
        <span class="system">
          System Management
        </span>
        <span>
          / Account API Keys
        </span>
      </div>
    </div>
    <div class="title">
      Account API Keys
    </div>
    <div v-if="adminAPIKeys && adminAPIKeys.length">
      <div class="link-to">
        Link to API:
      </div>
      <div>
        <a
          class="link"
          :href="`${domain}/api/docs`"
          target="_blank"
        >
          {{ `${domain}/api/docs` }}
        </a>
      </div>
      <div
        v-if="adminAPIKeys.length"
        class="api-keys-admin-wrapper"
      >
        <div
          v-for="(item) in adminAPIKeys"
          :key="item.id"
          class="account"
          @mouseover="onHoverElem(item)"
          @mouseleave="isHover = false"
        >
          <div
            v-if="item.status === 'inactive'"
            class="info"
            :title="'This API key has been disabled. Please contact support'"
          >
            <inline-svg
              :src="iconWarning"
              class="svg-icon"
            >
            </inline-svg>
          </div>
          <MaterialInput
            :id="item.id"
            v-model="item.value"
            :label="`API Key Name: ${item.name}`"
            :readonly="true"
            :allowCopy="true"
            :blur="true"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="info-for-admin"
    >
      Please contact your Account Manager to add this feature to your account.
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import { GET_API_KEYS_ADMIN_REQUEST } from '../../store/actions/api/api_keys';
import MaterialInput from '../../components/inputs/MaterialInput.vue';
import Loader from '../../components/common/Loader.vue';
import iconWarning from '../../assets/icons/icon-warning-localization.svg';

export default {
  name: 'AdminAPIKeys',
  components: { MaterialInput, Loader },
  data() {
    return {
      hoverId: '',
      domain: '',
      iconWarning,
    };
  },
  computed: {
    ...mapGetters([
      'getMainProfile',
      'getCurrentAccountId',
    ]),
    ...mapState({
      adminAPIKeys: (state) => state.apiKeys.adminAPIKeys,
      isLoading: (state) => state.apiKeys.isLoading,
    }),
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    this.domain = document.location.origin;

    const params = {
      accountId: this.getCurrentAccountId,
    };
    this.$store.dispatch(GET_API_KEYS_ADMIN_REQUEST, params);
  },
  methods: {
    onHoverElem(item) {
      this.hoverId = item.id;
    },
  },
};
</script>

<style scoped>
.main-wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px 25px 25px 30px;
  width: 100%;
  overflow: auto;
}

.flex {
  align-items: center;
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 40px;
}

.system {
  color: #4c5057;
}

.link-to {
  margin-top: 30px;
  margin-bottom: 15px;
}

.link {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2591CF;
}

.api-keys-admin-wrapper {
  margin-top: 30px;
  width: 600px;
}

.info-for-admin {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 40px;
  text-align: center;
}

.info {
  position: absolute;
  right: -40px;
  padding-top: 40px;
}

.account {
  position: relative;
}
</style>
