import Vue from 'vue';
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import Moment from 'moment';
import 'moment-timezone';
import { extendMoment } from 'moment-range';
import ToggleButton from 'vue-js-toggle-button';
import { InlineSvgPlugin } from 'vue-inline-svg';
import Toasted from 'vue-toasted';
import VueClipboard from 'vue-clipboard2';
import CONFIG from './constants/config';

import App from './App.vue';

import router from './router';
import store from './store/store';

import '@babel/polyfill';
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import i18n from './i18n';
import './vee-validate';

Array.clean = (deleteValue) => {
  for (let i = 0; i < this.length; i += 1) {
    if (this[i] === deleteValue) {
      this.splice(i, 1);
      i -= 1;
    }
  }
  return this;
};

Vue.config.productionTip = false;

Vue.use(ToggleButton);
const moment = extendMoment(Moment);
Vue.use(mapGetters);
Vue.use(mapState);
Vue.use(mapActions);
Vue.use(InlineSvgPlugin);
Vue.use(VueClipboard);
Vue.use(Toasted, {
  iconPack: 'fontawesome',
  theme: 'toasted-primary',
  position: 'bottom-right',
  className: 'toast',
  duration: CONFIG.alert.timeout,
  action:
    {
      text: 'Close',
      onClick: (e, toastObject) => {
        toastObject.goAway(0);
      },
    },
});

const optionsError = {
  type: 'error',
  icon: 'fa-exclamation-triangle',
};

const optionsErrorInfinity = {
  type: 'error',
  icon: 'fa-exclamation-triangle',
  duration: null,
};

const optionsSuccess = {
  type: 'success',
  icon: 'check',
};

const optionsInfo = {
  type: 'info',
  icon: '',
  duration: null,
  className: 'info-toast',
};

Vue.toasted.register('error',
  (payload) => payload.message,
  optionsError);

Vue.toasted.register('inf',
  (payload) => payload.message,
  optionsErrorInfinity);

Vue.toasted.register('success',
  (payload) => payload.message,
  optionsSuccess);

Vue.toasted.register('info',
  (payload) => payload.message,
  optionsInfo);

store.allGetters = Reflect.ownKeys(store.getters);
// eslint-disable-next-line no-underscore-dangle
store.allActions = Reflect.ownKeys(store._actions);

Vue.mixin({
  computed: {
    ...mapGetters(store.allGetters),
  },
  methods: {
    ...mapActions(store.allActions),
  },
});

Vue.filter('timezone', (value, timezone) => {
  if (timezone) {
    return moment(value).tz(timezone).format(CONFIG.date.format);
  }

  return moment(value).format(CONFIG.date.format);
});

Vue.filter('date', (value, format = CONFIG.date.dateFormat) => moment(value).format(format));

Vue.filter('cmsDate', (value, timezone, format = 'YYYY-MM-DD hh:mm A') => {
  if (timezone) {
    return moment(value).tz(timezone).format(format);
  }
  return moment(value).format(format);
});

Vue.filter('centConverter', (value) => value / 100);

Vue.filter('money', (value, currency = 'usd') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  });

  return formatter.format(value);
});

Vue.filter('secToTime', (value) => {
  const date = new Date(0);
  date.setSeconds(value);
  return date.toISOString().substr(11, 8);
});

Vue.filter('fileSize', (value, si = true, dp = 2, postfix = '') => {
  const basis = si ? 1000 : 1024;

  const siUnits = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const siIUnits = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const units = si ? siUnits : siIUnits;
  let bytes = 0;
  let key = 0;

  for (let i = 0; i < units.length; i += 1) {
    key = i;
    bytes = value / (basis ** i);

    if (bytes < basis) {
      break;
    }
  }

  if (bytes) {
    return `${Math.abs(bytes).toFixed(dp)} ${units[key]}${postfix}`;
  }
  return '';
});

Vue.filter('duration', (value, format = 'HH:mm:ss') => {
  if (value) {
    return Moment.utc(value * 1000).format(format);
  }
  return '';
});

Vue.filter('userInfo', (user) => (user ? `${user.firstName} ${user.lastName}` : 'n/a'));

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
