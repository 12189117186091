<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      v-if="isFormAvailable()"
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="edit-button-wrapper">
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backTo"
        />
        <Button
          :title="'Apply'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="breadcrumbs">
        <span class="system">
          System Management
        </span>
        <span>
          / Account Settings
        </span>
      </div>
      <div class="title">
        Account Settings
      </div>
      <div>
        <MaterialSelect
          v-model="formData.timezone"
          v-bind="formFields.timezone"
          :options="timezones"
        />
        <p class="text">
          This timezone will be used for scheduled future events and recordings.
        </p>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import BaseForm from './BaseForm.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';
import CONFIG from '../../constants/config';
import {
  EDIT_ACCOUNT_SETTINGS_REQUEST,
  GET_ACCOUNT_SETTINGS_REQUEST,
} from '../../store/actions/accountsActions/accounts';
import { GET_ALL_TIMEZONE_REQUEST } from '../../store/actions/timezoneActions/timezone';
import Button from '../common/Button.vue';

export default {
  name: 'AccountSettingsForm',
  components: {
    MaterialSelect,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'accountSettings',
    },
  },
  data() {
    return {
      formData: {
        timezone: '',
      },
      formFields: {
        timezone: {
          name: 'timezone',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        timezone: [
          `${this.formPrefix}.timezone`,
        ],
      },
      submitEvent: EDIT_ACCOUNT_SETTINGS_REQUEST,
      successMessage: CONFIG.successMessages.editAccountMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      timezones: (state) => state.timezone.timezones,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  mounted() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    const {
      errorMessages,
      statuses,
    } = CONFIG;

    this.$store.dispatch(GET_ALL_TIMEZONE_REQUEST);
    this.$store.dispatch(GET_ACCOUNT_SETTINGS_REQUEST, this.getCurrentAccountId)
      .then((res) => this.initFormData(res))
      .catch((err) => {
        if (err.error === errorMessages.unknown) {
          this.$router.push(`/${statuses.notFound}`);
        }
      });
  },
  methods: {
    backTo() {
      this.$router.push('users');
    },
    isFormAvailable() {
      return this.timezones
        && this.timezones.length;
    },
    initFormData(account) {
      this.formData.timezone = account.timezone;
    },
    getRequestData() {
      const formData = {
        timezone: this.formData.timezone,
      };

      return {
        accountId: this.getCurrentAccountId,
        data: formData,
      };
    },
  },
};
</script>

<style scoped>
  .btn-container {
    margin-bottom: 20px;
  }

  .text {
    color: #4b4f55;
  }

  .edit-button-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 26px;
  }

  .breadcrumbs {
    margin-bottom: 25px;
  }

  .system {
    color: #4c5057;
  }
</style>
