<template>
  <div class="list-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="btn-feed-mobile">
      <Button
        :title="'Add Season'"
        :classType="'primary'"
        :eventFunction="toCreateHandler"
      />
    </div>
    <div class="flex">
      <div class="right-path">
        <div class="btn-feed-full">
          <Button
            :title="'Add Season'"
            :classType="'primary'"
            :eventFunction="toCreateHandler"
          />
        </div>
      </div>
    </div>
    <div class="accounts-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          title
        </div>
        <div class="icons-name"></div>
      </div>
      <div
        v-if="!seasons.length"
        class="empty-search"
      >
        No Items to display
      </div>
      <draggable
        v-model="seasons"
        :move="checkMove"
        @start="dragging = true"
        @end="endOfDrag"
      >
        <transition-group>
          <div
            v-for="(item) in seasons"
            :key="item.id"
            class="account"
            @mouseover="onHoverElem(item)"
            @mouseleave="isHover = false"
          >
            <div class="td number">
              {{ item.sortOrder }}
            </div>
            <div
              class="td company"
              :title="item.title"
            >
              {{ item.title }}
            </div>
            <div class="feed-wrapper-icons">
              <div
                v-show="isHover && item.id === selectedId"
                :class="['icons-wrapper', (isHover && (item.id === selectedId))
                  ? ''
                  : 'icons-wrapper-hide'
                ]"
              >
                <div class="icon-edit">
                  <div
                    :title="'Go to Source'"
                    @click="goToMediaFolder(item)"
                  >
                    <inline-svg
                      :src="iconSource"
                      class="svg-icon source-icon"
                    >
                    </inline-svg>
                  </div>
                </div>
                <div
                  class="icon-edit"
                  :title="'Delete Season'"
                >
                  <inline-svg
                    :src="iconRemove"
                    class="svg-icon remove"
                    @click="onOpenModalForRemove(item)"
                  >
                  </inline-svg>
                </div>
                <div class="icon-edit">
                  <router-link
                    :to="{ path: `${$route.params.id}/seasons/${item.id}` }"
                    :title="editSeason"
                  >
                    <inline-svg
                      :src="iconEdit"
                      class="svg-icon"
                    >
                    </inline-svg>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
    <AddSeasonsModal
      v-model="isAddingOpen"
      :modal="isAddingOpen"
      :onClose="onManuallyClose"
      :onSubmitManually="onSubmitManually"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :title="'Delete Season'"
      :bodyMessage="bodyMessage"
      :warningMess="warningMess"
      :isInUseAsset="true"
      :onAccept="removeSeason"
      :onClose="onClose"
    />
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import draggable from 'vuedraggable';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../store/actions/common';
import CONFIG from '../../constants/config';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconCode from '../../assets/icons/icon-code.svg';
import iconSource from '../../assets/icons/icon-folder-source.svg';
import iconRemove from '../../assets/icons/icon-remove-role.svg';

import Button from '../../components/common/Button.vue';
import NotificationModal from '../../components/NotificationModal.vue';
import Loader from '../../components/common/Loader.vue';
import {
  DELETE_SEASONS_REQUEST,
  GET_SEASONS_LIST_REQUEST,
} from '../../store/actions/seasons/seasons';
import AddSeasonsModal from './AddSeasonsModal.vue';

export default {
  name: 'SeasonsList',
  components: {
    AddSeasonsModal,
    Loader,
    NotificationModal,
    Button,
    draggable,
  },
  props: {
    sortOrderSeason: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      seasons: [],
      dragging: false,
      editSeason: 'Edit Season',
      enabledId: null,
      isEnabled: false,
      isModalOpen: false,
      isHover: false,
      selectedId: null,
      bodyMessage: '',
      warningMess: 'Deleting a Season will not result in deleting the Source Folder and its Media Items',
      iconEdit,
      iconCode,
      iconSource,
      iconRemove,
      modalData: {},
      showModal: false,
      isAddingOpen: false,
    };
  },
  computed: {
    ...mapState({
      pagination: (state) => state.seasons.pagination,
      isLoading: (state) => state.seasons.isLoading,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getMainProfile',
    ]),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    this.getSeasonsList();
  },
  methods: {
    getSeasonsList() {
      const params = {
        accountId: this.getCurrentAccountId,
        seriesId: this.$route.params.id,
        page: 1,
      };
      this.$store.dispatch(GET_SEASONS_LIST_REQUEST, params).then((res) => {
        this.seasons = res;
      });
    },
    toCreateHandler() {
      this.isAddingOpen = true;
    },
    endOfDrag() {
      this.dragging = false;
      this.$emit('dragged', this.seasons);
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    onClose() {
      this.isModalOpen = false;
    },
    onManuallyClose() {
      this.isAddingOpen = false;
    },
    onSubmitManually() {
      this.onManuallyClose();
      this.getSeriesList();
    },
    goToMediaFolder(item) {
      sessionStorage.setItem('parentsPath', item.mediaFolder.pathIds.toString());
      window.open(`/media/folder/${item.mediaFolder.id}`, '_blank');
      // this.$router.push(`/media/folder/${item.mediaFolder.id}`);
    },
    checkMove() {
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    onOpenModalForRemove(item) {
      const {
        deleteSeasonMessage,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.enabledId = item.id;
      this.bodyMessage = deleteSeasonMessage;
    },
    removeSeason() {
      const params = {
        accountId: this.getCurrentAccountId,
        seasonsId: this.enabledId,
      };

      this.$store.dispatch(DELETE_SEASONS_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Season has been successfully deleted',
          });
          this.getSeasonsList();
        });
    },
  },
};
</script>

<style scoped>
.list-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.add-feed {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
}

.accounts-wrapper {
  border: 1px solid #43434d;
  height: 63vh;
  overflow-y: auto;
}

.ac-wrapper-header {
  background: #282a2e;
  display: flex;
  padding: 13px 0;
}

.th-name {
  align-items: center;
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
}

.td {
  font-size: 14px;
  padding: 15px 0;
  word-break: break-word;
}

.number {
  padding-left: 21px;
  width: 67px;
}

.company {
  width: 20%;
}

.company-id {
  padding-left: 15px;
  width: 15%;
}

.users-count {
  margin-left: 30px;
  text-align: left;
  width: 15%;
}

.seasons {
  width: 31%;
  text-align: left;
}

.icons-name {
  width: 120px;
}

.account {
  display: flex;
}

.account:hover {
  background: #383b40;
  cursor: move;
}

.icons-wrapper {
  align-items: flex-start;
  display: flex;
  /*min-width: 100px;*/
  padding: 9px;
  padding-left: 40px;
}

.feed-wrapper-icons {
  margin-right: 5px;
  min-width: 120px;
  align-items: flex-start;
  display: flex;
  flex: 0.99;
  justify-content: flex-end;
}

.svg-icon {
  cursor: pointer;
}

.icon-edit {
  margin-left: 31px;
}

.create-button {
  background: #2591cf;
  color: #fff;
  font-size: 16px;
  letter-spacing: normal;
  padding: 0 16px;
  text-transform: none;
}

.right-path {
  align-items: center;
  display: flex;
}

.empty-search {
  padding-top: 40px;
  text-align: center;
}

.icons-wrapper-hide {
  visibility: hidden;
}

.btn-feed-mobile {
  display: none;
}

.flex {
  align-items: center;
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 40px;
}

.right-path {
  align-items: center;
  display: flex;
}

.system {
  color: #4c5057;
}

@media all and (max-width: 1900px) {
  .seasons {
    width: 29%;
  }
}

@media all and (max-width: 1750px) {
  .seasons {
    width: 27%;
  }
}

@media all and (max-width: 1650px) {
  .seasons {
    width: 25%;
  }
}

@media all and (max-width: 1550px) {
  .seasons {
    width: 23%;
  }
}

@media all and (max-width: 1450px) {
  .seasons {
    width: 21%;
  }
}

@media all and (max-width: 1368px) {
  .seasons {
    width: 19%;
  }
}

@media all and (max-width: 1024px) {
  .btn-feed-mobile {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    min-height: 40px;
  }

  .btn-feed-full {
    display: none;
  }
}
</style>
