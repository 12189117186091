<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <div class="breadcrumbs">
      <span class="system">
        Video Apps Engine / Pages
      </span>
      <span v-if="!isEdit">
        / {{ cmsPage.name }} / Add Row
      </span>
      <span
        v-else
        :title="formData.rowHeading"
      >
        / {{ cmsPage.name }}
        / {{ row.feed ? 'Edit Row Feed id -' : 'Edit' }}
        {{ row.feed ? row.feed.id : getName(formData.rowHeading) }}
      </span>
    </div>
    <form
      v-if="formData.contentType"
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="title">
        {{ isEdit ? 'Row Editor' : 'Add Row' }}
      </div>
      <div class="edit-button-wrapper">
        <Button
          v-if="isEdit"
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="onOpenModal"
        />
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backToCms"
        />
        <Button
          :title="isEdit ? 'Apply' : 'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="rows">
        <div class="select-wrapper">
          <MaterialSelect
            v-model="formData.rowType"
            v-bind="formFields.rowType"
            :options="options"
            :label="'Row Type'"
            :withEmptyField="false"
            :disabled="true"
          />
        </div>
        <div class="select-wrapper">
          <MaterialSelect
            v-model="formData.contentType"
            v-bind="formFields.contentType"
            :options="optionsContentType"
            :withEmptyField="false"
          />
        </div>
      </div>
      <MaterialInput
        v-if="formData.contentType === 'feed'"
        v-model="formData.feed"
        v-bind="formFields.feed"
      />
      <div
        v-if="formData.contentType === 'folder'"
        style="margin-bottom: 20px"
      >
        <FoldersManagement
          :selectedValue="formData.mediaFolder"
          v-bind="formFields.mediaFolder"
          :label="'Source Folder'"
          :required="true"
          :commonError="foldError"
          :onChange="onFoldersChange"
        />
      </div>
      <div class="categories-management-wrapper">
        <CategoriesManagement
          v-if="formData.contentType === 'categories'"
          label="Select Categories"
          :selectedValue="formData.categories"
          :onChange="handleCategoryChange"
          :commonError="categoriesError"
          :required="true"
        />
      </div>
      <div v-if="formData.contentType === 'system_generated'">
        <MaterialSelect
          v-model="formData.systemLogic"
          v-bind="formFields.systemLogic"
          :options="systemLogics"
          :withEmptyField="false"
        />
      </div>
      <div
        v-if="formData.contentType === 'feed'"
        class="toggle-wrapper dynamic"
      >
        <div class="toggle-title">
          Dynamic Title Source
        </div>
        <ToggleButton
          v-model="formData.isDynamicTitle"
          color="#2591CF"
          :sync="true"
          :labels="false"
        />
      </div>
      <div
        v-if="!formData.isDynamicTitle || formData.contentType !== 'feed'"
        class="localization-wrapper"
      >
        <div class="label">
          <MaterialInput
            v-model="formData.rowHeading"
            v-bind="formFields.rowHeading"
            :requiredFromBack="true"
          />
        </div>
        <div v-if="enabledLanguages.length">
          <Button
            :title="'Localization'"
            :classType="'primary'"
            :srcSvg="iconFolderLocalization"
            :svgClass="'folder-localization-icon'"
            :eventFunction="toLocalization"
            :isReverseIcon="true"
            :disabled="!isEdit"
          />
        </div>
      </div>
      <div
        v-if="(!formData.isDynamicTitle || formData.contentType !== 'feed')
          && !isEdit
          && enabledLanguages.length"
        class="localization-warning-wrapper"
      >
        <inline-svg
          :src="iconWarning"
          class="svg-icon"
        >
        </inline-svg>
        <span class="localization-warning">
          Localization will be available after the Row has been saved
        </span>
      </div>
      <div
        v-if="formData.contentType !== 'categories'"
        class="toggles-wrapper dynamic"
      >
        <div class="toggle-title">
          Show Row Heading
        </div>
        <div class="toggle-wrapper">
          <ToggleButton
            v-model="formData.isShowRowHeading"
            color="#2591CF"
            :sync="true"
            :labels="false"
          />
        </div>
        <div class="toggle-wrapper">
          <div class="toggle-title">
            Show Item Titles
          </div>
          <ToggleButton
            v-model="formData.isShowItemTitle"
            color="#2591CF"
            :sync="true"
            :labels="false"
          />
        </div>
      </div>
      <div
        v-if="formData.contentType !== 'categories'"
        class="rows"
      >
        <div class="select-wrapper">
          <MaterialSelect
            v-model="formData.endOfRail"
            v-bind="formFields.endOfRail"
            :options="optionsEnd"
            :label="'End of Row Option'"
            :withEmptyField="false"
            :disabled="true"
          />
        </div>
        <div class="select-wrapper">
          <MaterialInput
            v-model="formData.maxItemsInRails"
            v-bind="formFields.maxItemsInRails"
          />
        </div>
      </div>
      <div class="rows">
        <div
          v-if="formData.contentType !== 'categories'"
          class="select-wrapper"
        >
          <MaterialSelect
            v-model="formData.railsLayout"
            v-bind="formFields.railsLayout"
            :options="optionsRailsLayout"
            :withEmptyField="false"
          />
        </div>
        <div
          v-if="formData.contentType === 'categories'"
          class="select-wrapper"
        >
          <MaterialSelect
            v-model="formData.groupContentBy"
            v-bind="formFields.groupContentBy"
            :options="groupCategories"
            :withEmptyField="false"
          />
        </div>
        <div
          v-if="isSortOptionsAvailable"
          class="select-wrapper"
        >
          <MaterialSelect
            v-model="formData.sortOption"
            v-bind="formFields.sortOption"
            :options="sortOptions"
            :withEmptyField="false"
          />
        </div>
      </div>
      <div
        v-if="formData.contentType === 'folder'"
        class="select-wrapper"
      >
        <MaterialSelect
          v-model="formData.groupContentBy"
          v-bind="formFields.groupContentBy"
          :options="groupContents"
          :withEmptyField="false"
        />
      </div>
      <NotificationModal
        ref="modal"
        v-model="isModalOpen"
        :modal="isModalOpen"
        :bodyMessage="bodyMessage"
        :title="'Delete Row'"
        :onAccept="removeRow"
        :onClose="onClose"
      />
      <CmsGeneralLocalization
        v-if="enabledLanguages.length"
        v-model="isOpenLocalization"
        :languages="enabledLanguages"
        :modal="isOpenLocalization"
        :isRowTranslate="true"
        :onClose="onCloseLocalizationModal"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import NotificationModal from '../../NotificationModal.vue';
import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';
import CmsGeneralLocalization from '../../localization/CmsGeneralLocalization.vue';
import iconWarning from '../../../assets/icons/icon-warning-localization.svg';
import FoldersManagement from '../../FoldersManagement.vue';
import CategoriesManagement from '../../inputs/CategoriesManagement.vue';

import {
  CREATE_ROW_REQUEST,
  EDIT_ROW_REQUEST,
  GET_PAGE_REQUEST,
  GET_ROW_REQUEST,
  GET_ROWS_LIST_REQUEST,
  REMOVE_ROW_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';
import { NUMBER_INPUT_REG_EXP } from '../../../utils/validation/regExp';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../../store/actions/common';
import { GET_LANGUAGES_REQUEST } from '../../../store/actions/localization/localizationActions';

export default {
  name: 'RowForm',
  components: {
    FoldersManagement,
    MaterialSelect,
    MaterialInput,
    Button,
    NotificationModal,
    CmsGeneralLocalization,
    CategoriesManagement,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'rowForm',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [
        {
          code: 'rail',
          name: 'Rail',
        },
      ],
      optionsContentType: [
        {
          code: 'feed',
          name: 'Feed',
        },
        {
          code: 'folder',
          name: 'Folder',
        },
        {
          code: 'manual',
          name: 'Manual',
        },
        {
          code: 'system_generated',
          name: 'System Generated',
        },
        {
          code: 'categories',
          name: 'Categories',
        },
      ],
      optionsEnd: [
        {
          code: 'none',
          name: 'None',
        },
      ],
      optionsRailsLayout: [
        {
          code: '2-up',
          name: '2-up',
        },
        {
          code: '3-up',
          name: '3-up',
        },
        {
          code: '4-up',
          name: '4-up',
        },
        {
          code: '5-up',
          name: '5-up',
        },
      ],
      groupCategories: [
        {
          code: 'grouped',
          name: 'Grouped',
        },
        {
          code: 'none',
          name: 'None',
        },
      ],
      sortOptions: [
        {
          code: 'chronological',
          name: 'Chronological',
        },
        {
          code: 'reverse_chronological',
          name: 'Reverse Chronological',
        },
        {
          code: 'alphabetical',
          name: 'Alphabetical',
        },
      ],
      groupContents: [
        {
          code: 'grouped',
          name: 'Grouped',
        },
        {
          code: 'ungrouped',
          name: 'Ungrouped',
        },
        {
          code: 'none',
          name: 'None',
        },
      ],
      systemLogics: [
        {
          code: 'continue_watching',
          name: 'Continue Watching logic',
        },
        {
          code: 'up_next_episode',
          name: 'Up Next Episode logic',
        },
      ],
      iconWarning,
      iconFolderLocalization,
      isOpenLocalization: false,
      isModalOpen: false,
      bodyMessage: CONFIG.modals.deleteRowInCms,
      rowsList: [],
      cmsPage: {},
      enabledLanguages: [],
      row: {},
      foldError: '',
      categoriesError: '',
      formData: {
        rowType: 'rail',
        contentType: this.isEdit ? '' : 'feed',
        feed: '',
        mediaFolder: '',
        isDynamicTitle: true,
        rowHeading: '',
        isShowRowHeading: true,
        isShowItemTitle: true,
        endOfRail: 'none',
        maxItemsInRails: 25,
        railsLayout: '4-up',
        sortOption: 'chronological',
        groupContentBy: 'grouped',
        systemLogic: 'continue_watching',
        sortOrder: 0,
        categories: [],
      },
      formFields: {
        rowType: {
          type: 'text',
          name: 'rowType',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        contentType: {
          type: 'text',
          name: 'contentType',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        feed: {
          type: 'text',
          name: 'feed',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 25,
          },
        },
        mediaFolder: {
          type: 'text',
          name: 'mediaFolder',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 25,
          },
        },
        categories: {
          name: 'categories',
          formPrefix: this.formPrefix,
          rules: {
            length: 1,
            required: true,
          },
        },
        rowHeading: {
          type: 'text',
          name: 'rowHeading',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        endOfRail: {
          type: 'text',
          name: 'endOfRail',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        maxItemsInRails: {
          type: 'number',
          name: 'maxItemsInRails',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            min_value: 1,
            max_value: 100,
            regex: NUMBER_INPUT_REG_EXP,
          },
        },
        railsLayout: {
          type: 'text',
          name: 'railsLayout',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        sortOption: {
          type: 'text',
          name: 'sortOption',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        groupContentBy: {
          type: 'text',
          name: 'groupContentBy',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        systemLogic: {
          type: 'text',
          name: 'systemLogic',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        feed: [
          `${this.formPrefix}.feed`,
        ],
        label: [
          `${this.formPrefix}.label`,
        ],
        mediaFolder: [
          `${this.formPrefix}.mediaFolder`,
        ],
        categories: [
          `${this.formPrefix}.categories`,
        ],
        rowHeading: [
          `${this.formPrefix}.heading`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_ROW_REQUEST : CREATE_ROW_REQUEST,
      successMessage: this.isEdit
        ? 'The Row has been successfully updated'
        : 'The Row has been successfully added',
      errorMessage: CONFIG.errorMessages.commonServerError,
      cachedGroupContent: {
        default: 'grouped',
        folder: '',
        categories: '',
      },
      cachedSortOptions: {
        default: 'chronological',
        folder: '',
        categories: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    isSortOptionsAvailable() {
      return ['folder', 'categories'].includes(this.formData.contentType);
    },
  },
  watch: {
    'formData.contentType': {
      handler: 'handleDataSourceChange',
      deep: true,
    },
  },
  mounted() {
    const paramsGet = {
      accountId: this.getCurrentAccountId,
      cmsPageId: this.$route.params.id,
    };
    this.$store.dispatch(GET_ROWS_LIST_REQUEST, paramsGet).then((res) => {
      this.rowsList = res.data;
    });

    const paramsLang = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };
    this.$store.dispatch(GET_LANGUAGES_REQUEST, paramsLang).then((res) => {
      this.enabledLanguages = res.data;
    });

    const params = {
      accountId: this.getCurrentAccountId,
      cmsPageId: this.$route.params.id,
    };
    this.$store.dispatch(GET_PAGE_REQUEST, params).then((res) => {
      this.cmsPage = res;
    });

    if (this.isEdit && this.$route.params.rowId) {
      const params = {
        accountId: this.getCurrentAccountId,
        cmsRowId: this.$route.params.rowId,
      };

      this.$store.dispatch(GET_ROW_REQUEST, params)
        .then((res) => this.initFormData(res));
    }
  },
  methods: {
    initFormData(item) {
      this.row = item;
      this.formData.contentType = item.dataSource;
      this.formData.rowHeading = item.heading;
      this.formData.maxItemsInRails = item.rowMaxItems;
      this.formData.railsLayout = item.rowLayout;
      this.formData.isShowRowHeading = item.showHeading;
      this.formData.isShowItemTitle = item.showItemTitles;
      this.formData.sortOrder = item.sortOrder;

      if (item.dataSource === 'feed') {
        this.formData.feed = item.feed.id;
        this.formData.isDynamicTitle = item.sourceTitle;
      }

      if (item.dataSource === 'folder') {
        this.cachedGroupContent.folder = item.groupContent;
        this.cachedSortOptions.folder = item.sortOption;
        this.formData.sortOption = item.sortOption;
        this.formData.groupContentBy = item.groupContent;
        this.formData.mediaFolder = item.mediaFolder.id;
      }

      if (item.dataSource === 'system_generated') {
        this.formData.systemLogic = item.systemLogic;
      }

      if (item.dataSource === 'categories') {
        this.cachedGroupContent.categories = item.groupContent;
        this.cachedSortOptions.categories = item.sortOption;
        this.formData.categories = item.categories;
        this.formData.sortOption = item.sortOption;
        this.formData.groupContentBy = item.groupContent;
      }
    },
    toLocalization() {
      this.isOpenLocalization = true;
    },
    handleRecorderToggle() {
      if (!this.formData.isToggleRecord) {
        this.isRecorderToggleOpen = true;
      }
    },
    handleCategoryChange(value) {
      this.formData.categories = value;
      this.$store.dispatch(SET_EDIT_FORM, value);
      this.categoriesError = '';
    },
    handleDataSourceChange(value) {
      if (value === 'categories') {
        let groupContentBy = this.cachedGroupContent.categories;
        const isGroupContentByInList = this.groupCategories
          .some((item) => item.code === groupContentBy);
        if (!isGroupContentByInList) {
          groupContentBy = this.cachedGroupContent.default;
        }

        this.formData.groupContentBy = groupContentBy;
        this.formData.sortOption = this.cachedSortOptions.categories
          || this.cachedSortOptions.default;
      } else if (value === 'folder') {
        this.formData.groupContentBy = this.cachedGroupContent.folder
          || this.cachedGroupContent.default;
        this.formData.sortOption = this.cachedSortOptions.folder
          || this.cachedSortOptions.default;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    backToCms() {
      this.$router.push(`/cms/pages/${this.$route.params.id}`);
    },
    getName(name) {
      return name.length > 45 ? `${name.slice(0, 45)}...` : name;
    },
    onOpenModal() {
      this.isModalOpen = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onCloseLocalizationModal() {
      this.isOpenLocalization = false;
    },
    onFoldersChange(value) {
      this.formData.mediaFolder = value ? value.code : value;
      this.foldError = '';
    },
    removeRow() {
      const params = {
        accountId: this.getCurrentAccountId,
        cmsRowId: this.$route.params.rowId,
      };

      this.$store.dispatch(REMOVE_ROW_REQUEST, params)
        .then(() => {
          setTimeout(() => {
            this.backToCms();
          }, CONFIG.routing.redirectFromAddAcc);
          this.$toasted.global.success({
            message: 'The Row has been successfully deleted',
          });
        });
    },
    getRequestData() {
      const data = {
        page: +this.$route.params.id,
        type: this.formData.rowType,
        dataSource: this.formData.contentType,
        showHeading: this.formData.isShowRowHeading,
        showItemTitles: this.formData.isShowItemTitle,
        heading: this.formData.rowHeading,
        rowLayout: this.formData.railsLayout,
        rowMaxItems: this.formData.maxItemsInRails,
        endRowOption: this.formData.endOfRail,
        sortOrder: this.rowsList.length ? this.rowsList.length + 1 : 1,
      };

      if (this.isEdit) {
        data.sortOrder = this.formData.sortOrder;
      }

      if (this.formData.contentType === 'folder') {
        data.sortOption = this.formData.sortOption;
        data.groupContent = this.formData.groupContentBy;
        data.mediaFolder = this.formData.mediaFolder;
      }

      if (this.formData.contentType === 'system_generated') {
        data.systemLogic = this.formData.systemLogic;
      }

      if (this.formData.contentType === 'feed') {
        data.feed = this.formData.feed;
        data.sourceTitle = this.formData.isDynamicTitle;

        if (this.formData.isDynamicTitle) {
          delete data.heading;
        }
      }

      if (this.formData.contentType === 'categories') {
        data.sortOption = this.formData.sortOption;
        data.groupContent = this.formData.groupContentBy;
        data.categories = this.formData.categories.map((category) => category.id);
      }

      return {
        data,
        accountId: this.getCurrentAccountId,
        cmsPageId: this.$route.params.id,
        cmsRowId: this.$route.params.rowId,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.backToCms();
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);
      const requiredMessage = 'This field is required';

      const folderError = error.form_errors
        && error.form_errors.children
        && error.form_errors.children.mediaFolder
        && error.form_errors.children.mediaFolder.errors
        && error.form_errors.children.mediaFolder.errors[0].error === 'IS_BLANK_ERROR';

      if (folderError) {
        this.foldError = requiredMessage;
      }

      const categoriesError = error.form_errors?.children?.categories?.errors?.[0].error === 'IS_BLANK_ERROR';

      if (categoriesError) {
        this.categoriesError = 'At least one Category must be selected';
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>

.rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.toggles-wrapper {
  display: flex;
}

.toggle-wrapper,
.date-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
}

.toggle-wrapper {
  width: auto;
}

.toggle-title {
  margin-right: 10px;
}

.dynamic {
  margin-top: 30px;
  margin-bottom: 30px;
}

.edit-button-wrapper {
  position: absolute;
  top: 75px;
  display: flex;
  right: 30px;
}

.country-field {
  width: 300px;
}

.country-field:not(:last-child) {
  margin-right: 20px;
}

.wowza p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.wrapper-small {
  margin-bottom: 20px;
}

.flex {
  justify-content: space-between;
}

.person {
  margin-bottom: 20px;
}

.person p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.select-wrapper {
  width: 300px;
}

.system {
  color: #4c5057;
}

.breadcrumbs {
  margin-bottom: 25px;
}

.localization-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  width: 400px;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 20px;
}

.localization-warning {
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}

.categories-management-wrapper {
  margin-bottom: 20px;
  margin-top: 5px;
}

@media all and (max-width: 1320px) {
  .flex {
    flex-wrap: wrap;
  }

  .country-field {
    margin-right: 0;
    width: 100%;
  }

  .country-field:not(:last-child) {
    margin-right: 0;
  }
}
</style>
