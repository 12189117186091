<template>
  <div class="data-source-block">
    <div class="bordered">
      <div class="wrapper main-form-container inner-bordered">
        <div class="title">
          Feature Configurations
        </div>
        <div class="column column-1">
          <div
            style="margin-bottom: 27px"
          >
            <FoldersManagement
              :selectedValue="formData.mediaFolder"
              v-bind="formFields.mediaFolder"
              :label="'Source Folder'"
              :required="true"
              :commonError="foldError"
              :onChange="onFoldersChange"
            />
          </div>
          <div
            style="margin-bottom: 27px"
          >
            <MediaItemsManagement
              :selectedFolder="formData.mediaFolder"
              :selectedValue="formData.mediaItem"
              :label="'Select Feature Media'"
              :required="true"
              :commonError="mediaItemError"
              :onChange="onFeatureItemChange"
            />
          </div>
          <MaterialSelect
            v-model="formData.sortBy"
            v-bind="formFields.sortBy"
            :options="sortByOptions"
            :withEmptyField="false"
            :handleChange="changeSortBy"
          />
          <MaterialSelect
            v-model="formData.sortDirection"
            v-bind="formFields.sortDirection"
            :options="sortOrderOptions"
            :withEmptyField="false"
            :handleChange="changeSortOrder"
          />
          <div class="toggle-wrapper">
            <div class="toggle-title">
              Sync with Source Folder
            </div>
            <ToggleButton
              v-model="formData.isSyncWithFolder"
              color="#2591CF"
              :sync="true"
              :labels="false"
              :disabled="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bordered">
      <div class="feeds-preview">
        <FeedResultsPreview
          :key="getKeyResults"
          :isNeedFixedItems="false"
          :mediaItems="mediaItems"
          :getMediaItems="getMediaItems"
          :isDynamicContent="true"
          :pagination="pagination.total"
          :dynamicContentLimit="formData.dynamicContentLimit"
          :defaultLength="defaultLength"
          :errorLiveEvents="errorLiveEvents"
          :isLoading="isLoadingMedia"
          :shouldUseLoader="true"
          title="Dynamic Items/Extras & Trailers"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FoldersManagement from '../../components/FoldersManagement.vue';
import MediaItemsManagement from '../../components/MediaItemsManagement.vue';
import MaterialSelect from '../../components/inputs/MaterialSelect.vue';
import FeedResultsPreview from '../../components/feed/FeedResultsPreview.vue';

export default {
  name: 'DataSourceFeature',
  components: {
    FeedResultsPreview,
    MaterialSelect,
    FoldersManagement,
    MediaItemsManagement,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    formFields: {
      type: Object,
      default: () => {},
    },
    mediaItems: {
      type: Array,
      default: () => [],
    },
    getMediaItems: {
      type: Function,
      default: () => {},
    },
    clearFolderError: {
      type: Function,
      default: () => {},
    },
    clearMediaItemError: {
      type: Function,
      default: () => {},
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    errorLiveEvents: {
      type: Array,
      default: () => [],
    },
    sortByOptions: {
      type: Array,
      default: () => [],
    },
    sortOrderOptions: {
      type: Array,
      default: () => [],
    },
    foldError: {
      type: String,
      default: '',
    },
    mediaItemError: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    isLoadingMedia: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultLength: 20,
      folderKey: 0,
      sortByKey: 0,
      sortOrderKey: 0,
    };
  },
  computed: {
    getKeyResults() {
      const {
        mediaFolder,
        sortBy,
        sortDirection,
      } = this.formData;

      return mediaFolder
        + sortBy
        + sortDirection
        + this.sortOrderKey
        + this.sortByKey
        + this.folderKey;
    },
  },
  methods: {
    onFoldersChange(value) {
      this.formData.mediaFolder = value ? value.code : value;
      this.folderKey += 1;
      this.clearFolderError();
      this.getMediaItems(1);
    },
    onFeatureItemChange(value) {
      this.formData.mediaItem = value ? value.id : value;
      this.clearMediaItemError();
    },
    changeSortBy() {
      this.sortByKey += 1;
      this.getMediaItems(1);
    },
    changeSortOrder() {
      this.sortOrderKey += 1;
      this.getMediaItems(1);
    },
  },
};
</script>

<style scoped>
.data-source-block {
  display: flex;
}

.bordered {
  border: 1px solid #383b40;
  height: calc(100vh - 270px);
  margin-right: 30px;
  width: 100%;
}

.inner-bordered {
  height: calc(100vh - 310px);
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
}

.bordered:last-child {
  margin-right: 0;
}

.toggle-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
  margin-top: 30px;
}

.toggle-title {
  margin-right: 10px;
}
</style>
