<template>
  <div class="analytics-geo-devices">
    <div
      :class="[isResizedBlock ? 'graphs-full-row' : 'graphs-row']"
    >
      <div class="an-map-block">
        <div class="map-header">
          <TabsButton
            :onItemSelect="onMapTabSelect"
            :selectedItem="geolocationType"
            :items="mapTabs"
          />
          <ResizeButton
            :resized="resizedMap"
            :onResize="onResize"
            :className="'map'"
          />
        </div>
        <div class="an-reload">
          <div
            v-if="!worldMapData.length && !usaMapData.length"
            class="empty-chart"
          >
            No Metrics to display
          </div>
          <div
            v-show="worldMapData.length || usaMapData.length"
            :key="'mapChart'"
            ref="mapChart"
            :class="['map-chart', resizedMap ? 'map-chart-resized' : '']"
            class="map-chart"
          ></div>

          <DataReloader
            name="map"
            :isHide="isLoadingFailedMap"
            :onReload="onReloadData"
          />
        </div>
      </div>
      <div class="an-chart-block">
        <div class="pie-header">
          <TabsButton
            :onItemSelect="onPiTabSelect"
            :selectedItem="deviceType"
            :items="pieTabs"
          />
          <div
            v-if="pieType"
            class="analytic-filter"
          >
            <div
              :key="pieTabSelected"
              class="select-wrapper"
            >
              <select
                id="searchSelect"
                :value="pieType"
                class="search-select"
                @change="onDeviceFilterChange"
              >
                <option
                  v-for="item in pieTypeList"
                  :key="item.value"
                  :value="item.value"
                  class="select-option"
                >
                  {{ item.name }}
                </option>
              </select>
              <inline-svg
                :src="iconSortDown"
                class="svg"
              />
            </div>
            <ResizeButton
              :resized="resizedPie"
              :onResize="onResizePieGraph"
              :className="'pie'"
            />
          </div>
        </div>
        <div class="an-reload">
          <div
            v-if="(!browserData.length && deviceType === 'browser')
              || (!deviceData.length && deviceType === 'device_type')"
            class="empty-chart"
          >
            No Metrics to display
          </div>
          <div
            v-show="(browserData.length && deviceType === 'browser')
              || (deviceData.length && deviceType === 'device_type')"
            :key="'pieChart'"
            ref="pieChart"
            :class="['pie-chart', resizedPie ? 'pie-chart-resized' : '']"
          ></div>

          <DataReloader
            name="pie"
            :isHide="isLoadingFailedPie"
            :onReload="onReloadData"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4GeoDataWorldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4GeoDataUsaLow from '@amcharts/amcharts4-geodata/usaLow';
import Am4themesDark from '@amcharts/amcharts4/themes/dark';
import Am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import iconFilter from '../../assets/icons/icon-filter.svg';
import iconMaxSize from '../../assets/icons/icon-maxsize.svg';
import iconMinSize from '../../assets/icons/icon-minsize.svg';
import CONFIG from '../../constants/config';
import iconSortDown from '../../assets/icons/icon-sort-arrow-down.svg';
import TabsButton from './TabsButton.vue';
import ResizeButton from './ResizeButton.vue';
import DataReloader from './DataReloader.vue';

export default {
  name: 'AnalyticsGraphs',
  components: {
    ResizeButton,
    TabsButton,
    DataReloader,
  },
  props: {
    onDeviceChange: {
      type: Function,
      default: () => {},
    },
    deviceType: {
      type: String,
      default: null,
    },
    pieType: {
      type: String,
      default: null,
    },
    geolocationType: {
      type: String,
      default: null,
    },
    onGeoLocationTypeChange: {
      type: Function,
      default: () => {},
    },
    onPieTypeChange: {
      type: Function,
      default: () => {},
    },
    onResizePie: {
      type: Function,
      default: () => {},
    },
    onResizeMap: {
      type: Function,
      default: () => {},
    },
    isLoadingFailedMap: {
      type: Boolean,
      default: false,
    },
    isLoadingFailedPie: {
      type: Boolean,
      default: false,
    },
    onReloadData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconFilter,
      iconMaxSize,
      iconMinSize,
      resizedMap: false,
      resizedPie: false,
      iconSortDown,
      browserData: [],
      deviceData: [],
      usaMapData: [],
      worldMapData: [],
      isResizedBlock: false,
      deviceSortByList: CONFIG.analyticDeviceSortByList,
      mapTabs: [
        {
          title: 'Plays',
          code: 'views',
        },
        {
          title: 'Uniques',
          code: 'uniques',
        },
      ],
      mapTabSelected: 'views',
      pieTabs: [
        {
          title: 'Devices',
          code: 'device_type',
        },
        {
          title: 'Browsers',
          code: 'browser',
        },
      ],
      pieTabSelected: 'device_type',
      pieTypeList: [
        {
          name: 'Plays',
          value: 'views',
        },
        {
          name: 'Uniques',
          value: 'uniques',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.common.profile,
      isLoading: (state) => state.analytic.isLoading,
    }),
  },
  mounted() {
    am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);
    am4core.useTheme(Am4themesDark);
    am4core.useTheme(Am4themesAnimated);
  },
  methods: {
    onResize() {
      this.resizedMap = !this.resizedMap;
      this.isResizedBlock = this.resizedMap || this.resizedPie;
      this.onResizeMap(this.resizedMap);
    },
    onResizePieGraph() {
      this.resizedPie = !this.resizedPie;
      this.isResizedBlock = this.resizedMap || this.resizedPie;
      this.onResizePie(this.resizedPie);
    },
    onMapTabSelect(value) {
      this.onGeoLocationTypeChange(value);
    },
    onPiTabSelect(value) {
      this.onDeviceChange(value);
    },
    onDeviceFilterChange(element) {
      this.onPieTypeChange(element.target.value);
    },
    createBrowserPieChart(browserData) {
      this.browserData = browserData;
      const chart = am4core.create(this.$refs.pieChart, am4charts.PieChart);
      const pieSeries = chart.series.push(new am4charts.PieSeries());
      if (!this.resizedPie) {
        const data = [];
        // eslint-disable-next-line array-callback-return
        this.browserData.map((item, index) => {
          if (index > 4) {
            data.push({
              browser: 'Other',
              value: this.browserData.reduce((a, b, index) => index > 4 && a + b.value, 0),
            });
            return;
          }

          data.push(item);
        });
        chart.data = data.splice(0, 6);
      } else {
        chart.data = browserData;
      }
      chart.radius = am4core.percent(90);
      chart.innerRadius = am4core.percent(50);

      chart.legend = new am4charts.Legend();
      chart.legend.position = 'left';
      chart.legend.valign = 'top';

      pieSeries.colors.step = 3;
      pieSeries.dataFields.value = 'value';
      pieSeries.dataFields.category = 'browser';
      pieSeries.labels.template.maxWidth = 100;
      pieSeries.labels.template.truncate = true;
      pieSeries.ticks.template.disabled = true;
      pieSeries.labels.template.disabled = true;
      pieSeries.labels.template.tooltipText = '{category}: {value.percent.formatNumber(\'#.#\')}% ({value.value})';
    },
    createDevicePieChart(deviceData) {
      this.deviceData = deviceData;
      const chart = am4core.create(this.$refs.pieChart, am4charts.PieChart);
      const pieSeries = chart.series.push(new am4charts.PieSeries());

      if (!this.resizedPie) {
        const data = [];
        // eslint-disable-next-line array-callback-return
        this.deviceData.map((item, index) => {
          if (index > 4) {
            data.push({
              browser: 'Other',
              value: this.deviceData.reduce((a, b, index) => index > 4 && a + b.value, 0),
            });
            return;
          }

          data.push(item);
        });
        chart.data = data.splice(0, 6);
      } else {
        chart.data = deviceData;
      }
      chart.radius = am4core.percent(90);
      chart.innerRadius = am4core.percent(50);

      chart.legend = new am4charts.Legend();
      chart.legend.position = 'left';
      chart.legend.valign = 'top';

      pieSeries.dataFields.value = 'value';
      pieSeries.dataFields.category = 'device';
      pieSeries.colors.step = 3;
      pieSeries.labels.template.maxWidth = 100;
      pieSeries.labels.template.truncate = true;
      pieSeries.ticks.template.disabled = true;
      pieSeries.labels.template.disabled = true;
      pieSeries.labels.template.tooltipText = '{category}: {value.percent.formatNumber(\'#.#\')}% ({value.value})';
    },
    createMapChart(usaMapData, worldMapData) {
      this.usaMapData = usaMapData;
      this.worldMapData = worldMapData;
      const chart = am4core.create(this.$refs.mapChart, am4maps.MapChart);

      chart.geodata = am4GeoDataWorldLow;

      chart.projection = new am4maps.projections.Miller();

      chart.colors.step = 3;

      chart.zoomControl = new am4maps.ZoomControl();
      chart.zoomControl.layout = 'horizontal';
      chart.zoomControl.minusButton.width = 30;
      chart.zoomControl.plusButton.width = 30;
      chart.zoomControl.minusButton.insertAfter(chart.zoomControl.plusButton);
      chart.zoomControl.plusButton.marginRight = 5;
      chart.zoomControl.minusButton.marginRight = 5;

      const homeButton = new am4core.Button();
      homeButton.events.on('hit', () => {
        chart.goHome();
      });

      const analyticMapIconsProps = {
        iconHome: CONFIG.analyticMapIconHome,
        color: '#ffffff',
      };
      homeButton.icon = new am4core.Sprite();
      homeButton.contentValign = 'top';
      homeButton.padding(6, 7, 7, 7);
      homeButton.height = 30;
      homeButton.icon.fill = analyticMapIconsProps.color;
      homeButton.icon.path = analyticMapIconsProps.iconHome;
      homeButton.parent = chart.zoomControl;

      const worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
      worldSeries.exclude = ['AQ'];
      worldSeries.useGeodata = true;
      const polygonTemplate = worldSeries.mapPolygons.template;
      polygonTemplate.tooltipText = '{name} {value}';
      polygonTemplate.fill = am4core.color('#aaa');
      polygonTemplate.propertyFields.disabled = 'disabled';
      polygonTemplate.states.create('hover');
      worldSeries.data = [...this.worldMapData];

      let lastSelected;
      polygonTemplate.events.on('hit', (ev) => {
        if (lastSelected) {
          lastSelected.isActive = false;
        }
        ev.target.series.chart.zoomToMapObject(ev.target);
        if (lastSelected !== ev.target) {
          lastSelected = ev.target;
        }
      });

      const usaSeries = chart.series.push(new am4maps.MapPolygonSeries());
      usaSeries.geodata = am4GeoDataUsaLow;
      usaSeries.useGeodata = true;
      usaSeries.data = [...this.usaMapData];
      const usaPolygonTemplate = usaSeries.mapPolygons.template;
      usaPolygonTemplate.tooltipText = '{name} {value}';
      usaPolygonTemplate.fill = am4core.color('#ccc');
      usaPolygonTemplate.states.create('hover');

      let usaLastSelected;
      usaPolygonTemplate.events.on('hit', (ev) => {
        if (usaLastSelected) {
          usaLastSelected.isActive = false;
        }
        ev.target.series.chart.zoomToMapObject(ev.target);
        if (usaLastSelected !== ev.target) {
          usaLastSelected = ev.target;
        }
      });

      const maxWorldData = Math.max(...worldSeries.data.map((item) => item.value ?? 0));
      const maxUsaData = Math.max(...usaSeries.data.map((item) => item.value ?? 0));
      const max = Math.max(maxWorldData, maxUsaData);
      const scaleStart = 1;
      const scaleLength = 1.3;

      worldSeries.heatRules.push({
        property: 'fill',
        target: worldSeries.mapPolygons.template,
        min: chart.colors.getIndex(0).brighten(scaleStart),
        max: chart.colors.getIndex(0).brighten(scaleStart - ((scaleLength * maxWorldData) / max)),
      });

      usaSeries.heatRules.push({
        property: 'fill',
        target: usaSeries.mapPolygons.template,
        min: chart.colors.getIndex(0).brighten(scaleStart),
        max: chart.colors.getIndex(0).brighten(scaleStart - ((scaleLength * maxUsaData) / max)),
      });
    },
  },
};
</script>
<style scoped>
  .analytics-geo-devices {
    margin: 40px 0;
  }

  .select-wrapper {
    color: #fff;
    display: flex;
    font-size: 14px;
    margin-right: 10px;
    margin-top: 5px;
  }

  .search-select {
    appearance: none;
    background: #222;
    border: 1px solid #383b40;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    height: 100%;
    max-height: 30px;
    outline: none;
    padding: 6px;
    width: 92px;
  }

  .svg {
    cursor: pointer;
    fill: #383b40;
    position: relative;
    right: 15px;
    top: 13px;
  }

  .empty-chart {
    color: #4c5057;
    padding-top: 40px;
    text-align: center;
  }

  .graphs-row {
    display: flex;
    justify-content: space-between;
  }

  .an-chart-block {
    width: 100%;
    margin-left: 50px;
  }

  .an-map-block {
    width: 100%;
  }

  .an-reload {
    position: relative;
    min-height: 120px;
  }

  .analytics-geo-devices .select-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .map-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .pie-header {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    margin-bottom: 15px;
  }

  .analytic-filter {
    display: flex;
    justify-content: flex-end;
  }

  .graphs-full-row .an-chart-block {
    margin-left: 0;
  }

  .graphs-full-row .pie-header {
    padding-left: 0;
  }
</style>
<style>
  .an-chart-block .pie,
  .an-map-block .map {
    margin-top: 10px;
  }

  .map-chart,
  .pie-chart {
    height: 300px;
  }

  .map-chart [aria-labelledby^="id"][aria-labelledby$="title"] {
    display: none;
  }

  .map-chart-resized {
    height: 600px;
    margin-bottom: 50px;
  }

  .pie-chart-resized {
    height: 600px;
    margin-bottom: 50px;
    margin-top: 30px;
  }
</style>
