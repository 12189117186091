<template>
  <div>
    <div>
      Reference the following formats
    </div>
    <div class="formats-wrapper">
      <div>
        <div class="video-type-title">
          Live Events
        </div>
        <div>
          <MaterialCheckbox
            :toggle="toggleDash"
            :label="'MPEG-DASH'"
            :checked="formData.live.isDash"
          />
          <MaterialCheckbox
            :toggle="toggleHls"
            :label="'HLS'"
            :checked="formData.live.isHls"
          />
          <MaterialCheckbox
            :toggle="togglePosterLive"
            :label="'Poster Image'"
            :checked="formData.live.isPosterLive"
          />
        </div>
      </div>
      <div>
        <div class="video-type-title">
          Audio Media
        </div>
        <div>
          <MaterialCheckbox
            :toggle="toggleAac"
            :label="'AAC'"
            :checked="formData.audio.isAac"
          />
          <MaterialCheckbox
            :toggle="toggleAudioHls"
            :label="'HLS'"
            :checked="formData.audio.isHls"
          />
          <MaterialCheckbox
            :toggle="togglePosterAudio"
            :label="'Poster Image'"
            :checked="formData.audio.isPosterAudio"
          />
        </div>
      </div>
      <div>
        <div class="video-type-title">
          VOD
        </div>
        <div>
          <MaterialCheckbox
            :toggle="toggleDashVOD"
            :label="'MPEG-DASH'"
            :checked="formData.vod.isDash"
          />
          <MaterialCheckbox
            :toggle="toggleHlsVOD"
            :label="'HLS'"
            :checked="formData.vod.isHls"
          />
          <MaterialCheckbox
            :toggle="toggleH264"
            :label="'Progressive h264'"
            :checked="formData.vod.isH264"
          />
          <MaterialCheckbox
            :toggle="togglePosterVOD"
            :label="'Poster Image'"
            :checked="formData.vod.isPosterVOD"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialCheckbox from '../inputs/MaterialCheckbox.vue';

export default {
  name: 'FormatsFeed',
  components: {
    MaterialCheckbox,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    formFields: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    toggleDash(isChecked) {
      if (isChecked !== this.formData.live.isDash) {
        this.formData.live.isDash = isChecked ? 1 : 0;
      }
    },
    toggleHls(isChecked) {
      if (isChecked !== this.formData.live.isHls) {
        this.formData.live.isHls = isChecked ? 1 : 0;
      }
    },
    toggleDashVOD(isChecked) {
      if (isChecked !== this.formData.vod.isDash) {
        this.formData.vod.isDash = isChecked ? 1 : 0;
      }
    },
    toggleHlsVOD(isChecked) {
      if (isChecked !== this.formData.vod.isHls) {
        this.formData.vod.isHls = isChecked ? 1 : 0;
      }
    },
    toggleH264(isChecked) {
      if (isChecked !== this.formData.vod.isH264) {
        this.formData.vod.isH264 = isChecked ? 1 : 0;
      }
    },
    togglePosterVOD(isChecked) {
      if (isChecked !== this.formData.vod.isPosterVOD) {
        this.formData.vod.isPosterVOD = isChecked ? 1 : 0;
      }
    },
    togglePosterLive(isChecked) {
      if (isChecked !== this.formData.live.isPosterLive) {
        this.formData.live.isPosterLive = isChecked ? 1 : 0;
      }
    },
    toggleAudioHls(isChecked) {
      if (isChecked !== this.formData.audio.isHls) {
        this.formData.audio.isHls = isChecked ? 1 : 0;
      }
    },
    togglePosterAudio(isChecked) {
      if (isChecked !== this.formData.audio.isPosterAudio) {
        this.formData.audio.isPosterAudio = isChecked ? 1 : 0;
      }
    },
    toggleAac(isChecked) {
      if (isChecked !== this.formData.audio.isAac) {
        this.formData.audio.isAac = isChecked ? 1 : 0;
      }
    },
    hasErrors() {
      return false;
    },
  },
};
</script>

<style scoped>
  .formats-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
  }

  .video-type-title {
    font-size: 15px;
    padding-bottom: 20px;
  }
</style>
