<template>
  <div class="settings main-body-right">
    <div class="wrap">
      <div class="system">
        Collections
      </div>
      <div class="nav sub-nav">
        <router-link
          to="/collections/feeds"
          tag="div"
          class="router"
          :activeClass="'active'"
          :event="moving"
        >
          Feeds
        </router-link>
      </div>
      <div class="nav sub-nav">
        <router-link
          to="/collections/playlists"
          tag="div"
          class="router"
          :activeClass="'active'"
          :event="moving"
        >
          Playlists
        </router-link>
      </div>
      <div class="nav sub-nav">
        <router-link
          to="/collections/series"
          tag="div"
          class="router"
          :activeClass="'active'"
          :event="moving"
        >
          Series
        </router-link>
      </div>
      <div class="nav sub-nav">
        <router-link
          to="/collections/features"
          tag="div"
          class="router"
          :activeClass="'active'"
          :event="moving"
        >
          Features
        </router-link>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Feeds',
  computed: {
    ...mapGetters([
      'isAccountActive',
    ]),
    moving() {
      return this.isAccountActive ? 'click' : '';
    },
  },
};
</script>

<style scoped>
  .main-body-right {
    display: flex !important;
    padding: 0;
  }

  .nav {
    background: transparent;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }

  .system {
    border-bottom: 1px solid #383b40;
    padding: 30px 0 25px 25px;
  }

  .wrap {
    border-right: 1px solid #383b40;
    flex-direction: column;
    min-width: 330px;
  }

  .router {
    position: relative;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
  }

  .router:hover {
    color: #2591cf;
  }

  .active {
    color: #2591cf;
  }

  @media all and (max-width: 1200px) {
    .wrap {
      display: none;
    }
  }
</style>
