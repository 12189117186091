<template>
  <div class="overlay add-fixed-item">
    <div class="wrapper">
      <p
        class="headline grey lighten-2"
        primaryTitle
      >
        Add Fallback Media Item
      </p>
      <div class="live-search">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="100"
        />
      </div>
      <div
        ref="fixedFeeds"
        class="results"
      >
        <Loader
          :isVisible="isLoading"
          :className="'inspector-loader'"
        />
        <div v-if="vodMedia && vodMedia.length">
          <div
            v-for="item in vodMedia"
            :key="item.id"
          >
            <div
              class="fixed-item-row"
              @click="selectItemFallback(item.id)"
            >
              <FeedPreviewItem
                :item="item"
                :isSelected="isSelected && selectedId === item.id"
              />
              <div class="preview">
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="empty-search"
        >
          No media to display
        </div>
      </div>
      <div class="button-wrapper">
        <Button
          :title="'Add'"
          :classType="'primary'"
          :eventFunction="onAddFallbackHandler"
          :disabled="!selectedId"
        />
        <Button
          :title="'Close'"
          :classType="'primary grey'"
          :eventFunction="onCloseHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import Loader from './common/Loader.vue';
import LiveSearch from './common/LiveSearch.vue';
import Button from './common/Button.vue';
import { GET_FIXED_MEDIA_ITEMS } from '../store/actions/feedsActions/feedsActions';
import FeedPreviewItem from './feed/FeedPreviewItem.vue';

export default {
  name: 'FallbackModal',
  components: {
    FeedPreviewItem,
    Button,
    LiveSearch,
    Loader,
  },
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    onAddFallback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      vodMedia: [],
      filterValue: '',
      currentPage: 1,
      selectedId: null,
      isSelected: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.feeds.isFixedItemsLoading,
      feedModalPagination: (state) => state.feeds.feedModalPagination,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  mounted() {
    this.getMediaItems(1);
    const listElm = this.$refs.fixedFeeds;
    listElm.addEventListener('scroll', this.getItems);
  },
  beforeDestroy() {
    const listElm = this.$refs.fixedFeeds;
    listElm.removeEventListener('scroll', this.getItems);
  },
  methods: {
    getItems() {
      const total = Number(this.feedModalPagination.total);
      const defaultLength = 20;
      const listElm = this.$refs.fixedFeeds;
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.currentPage += 1;

        let maxPage = parseInt(total / defaultLength, 10);
        if (total % defaultLength > 0) {
          maxPage += 1;
        }

        if (maxPage >= this.currentPage) {
          this.getMediaItems(this.currentPage);
        }
      }
    },
    onSearch(params) {
      this.filterValue = params.filterValue;
      this.getMediaItems(1);
      this.currentPage = 1;
    },
    getMediaItems(page) {
      const searchParams = {
        accountId: this.getCurrentAccountId,
        filterValue: this.filterValue,
        page,
        types: ['vod'],
      };
      this.$store.dispatch(GET_FIXED_MEDIA_ITEMS, searchParams)
        .then((res) => {
          this.vodMedia = res.data;
        });
    },
    selectItemFallback(itemId) {
      this.selectedId = itemId;
      this.isSelected = true;
    },
    onAddFallbackHandler() {
      this.onAddFallback(this.selectedId);
    },
    onCloseHandler() {
      this.onClose();
    },
  },
};
</script>

<style scoped>
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .wrapper {
    background-color: #222;
    border-radius: 4px;
    display: block;
    height: 430px;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    padding: 0 20px;
    position: absolute;
    text-decoration: none;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding: 13px 16px;
    word-break: break-all;
  }

  .empty-search {
    color: #4c5057;
    padding-top: 40px;
    text-align: center;
  }

  .results {
    height: 240px;
    margin: 15px 20px 10px;
    overflow-x: hidden;
    padding-right: 5px;
  }

  .live-search {
    margin: 10px 20px;
  }

  .fixed-item-row {
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }

  .preview {
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 85%;
  }

  .button-item {
    margin: 0 0 0 25px;
    padding-top: 5px;
  }

  .add-fixed-item .search-wrapper {
    width: 100%;
  }
</style>
