<template>
  <div class="coundown-wrapper">
    <div
      v-if="isShowBackground || isShowImage"
      :class="'poster-image'"
    >
      <div
        class="overlay-text"
      >
        <div
          v-if="isPostEventActionBtn"
          class="icon-play"
          :title="'play'"
          @click="onShowVideo"
        >
          <inline-svg
            :src="iconPlay"
            class="svg-icon"
          >
          </inline-svg>
        </div>
        <p
          v-if="eventText"
          :title="eventText"
        >
          <small v-if="timeBeforeStart">Next Up:</small>
          {{ getName(eventText) }}
        </p>
        <p v-if="timeBeforeStart">
          {{ countdownText }}
        </p>
      </div>
      <img
        :ref="'preview'"
        :src="background"
        :alt="''"
      />
    </div>
    <div
      v-if="isShowPlayer"
      class="player"
    >
      <BitmovinPlayer
        :key="formData.id"
        :autoplay="isAutoplay"
        :mediaItem="isGroup ? eventGroup.currentStandaloneEvent : event"
        :onEventFinished="onEventFinished"
      />
    </div>
    <Button
      v-if="isGroup || formData.canEdit"
      :title="'Set Background'"
      class="upload-button"
      :classType="'primary'"
      :eventFunction="onOpenUploadModal"
    />
    <ImageUploader
      v-if="isUploadBackgroundModalOpen"
      :onClose="onClose"
      :onFileUpload="onSelectImage"
    />
  </div>
</template>

<script>
import moment from 'moment';
import {
  mapState,
  mapGetters,
} from 'vuex';
import * as workerInterval from 'worker-interval';
import Button from '../common/Button.vue';
import BitmovinPlayer from '../common/BitmovinPlayer.vue';
import ImageUploader from '../common/DragAndDropImageUploaderModal.vue';
import iconPlay from '../../assets/icons/icon-play-circle.svg';

export default {
  name: 'Countdown',
  components: {
    Button,
    ImageUploader,
    BitmovinPlayer,
  },
  props: {
    onFileUpload: {
      type: Function,
      default: () => {},
    },
    backgroundImage: {
      type: [String, File],
      default: null,
    },
    updatePlayerSource: {
      type: Function,
      default: () => {},
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconPlay,
      isUploadBackgroundModalOpen: false,
      playerKey: 0,
      timeBeforeStart: null,
      startCounterId: null,
      countdownText: '',
      isShowBackground: false,
      isShowPlayer: false,
      isAutoplay: true,
    };
  },
  computed: {
    ...mapState({
      event: (state) => state.events.event,
      eventGroup: (state) => state.eventGroups.eventGroup,
    }),
    ...mapGetters([
      'getMainProfile',
    ]),
    formData() {
      if (this.isGroup) {
        return this.eventGroup;
      }
      if (!this.isGroup) {
        return this.event;
      }
      return {};
    },
    getTimezone() {
      return (this.getMainProfile.timezone) ? this.getMainProfile.timezone : moment.tz.guess();
    },
    getTimeBeforeStart() {
      const start = (this.isGroup && this.formData.currentStandaloneEvent)
        ? moment.utc(this.formData.currentStandaloneEvent.startDate).tz(this.getTimezone)
        : moment.utc(this.formData.startDate).tz(this.getTimezone);
      const now = moment.utc().tz(this.getTimezone);
      return (start.diff(now) > 0) ? moment(start.diff(now)).unix() : null;
    },
    getTimeBeforeFinish() {
      const end = (this.isGroup && this.formData.currentStandaloneEvent)
        ? moment.utc(this.formData.currentStandaloneEvent.finishDate).tz(this.getTimezone)
        : moment.utc(this.formData.finishDate).tz(this.getTimezone);
      const now = moment.utc().tz(this.getTimezone);

      return (end.diff(now) > 0) ? moment(end.diff(now)).unix() : null;
    },
    isEventInProgress() {
      return !this.getTimeBeforeStart && this.getTimeBeforeFinish;
    },
    isEventFinished() {
      return !this.getTimeBeforeStart && !this.getTimeBeforeFinish;
    },
    eventText() {
      if (this.isEventFinished) {
        return this.formData.postEventMessage;
      }
      if (this.isGroup) {
        return this.formData.currentStandaloneEvent.name;
      }
      return this.formData.name;
    },
    isPostEventActionBtn() {
      return (this.formData.hasPostEventPresentVideo && this.isEventFinished) && !this.isGroup;
    },
    isShowImage() {
      return (this.formData.backgroundUrl || this.backgroundImage) && !this.isShowPlayer;
    },
    background() {
      return this.backgroundImage || this.formData.backgroundUrl;
    },
  },
  watch: {
    timeBeforeStart(newValue) {
      if (newValue <= 0) {
        if (this.startCounterId) {
          workerInterval.clearInterval(this.startCounterId);
        }
        this.updatePlayerSource();
        this.onShowVideo(true, false);
      } else {
        this.getCountdownText(newValue);
      }
    },
    formData() {
      if (this.isGroup && this.getTimeBeforeStart) {
        this.init();
      }
    },
  },
  created() {
    window.addEventListener('focus', this.setFocusOnTab);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.setFocusOnTab);
  },
  methods: {
    init() {
      this.timeBeforeStart = this.getTimeBeforeStart;

      if (this.timeBeforeStart) {
        this.setCountdownCounter();
      }
      if (this.isEventInProgress) {
        this.onShowVideo(true, false);
      }
    },
    setCountdownCounter() {
      this.startCounterId = workerInterval.setInterval(() => {
        if (this.timeBeforeStart > 0) {
          this.timeBeforeStart -= 1;
        }
      }, 1000);
    },
    getCountdownText(time) {
      const t = moment.duration(time, 'seconds');
      const secs = t.seconds();
      const mins = t.minutes();
      const hrs = t.hours();
      const days = Math.floor(t.asDays());

      let str = '';
      str += `${days} ${days === 1 ? 'day ' : 'days '}`;
      str += `${hrs} ${hrs === 1 ? 'hour ' : 'hours '}`;
      str += `${mins} ${mins === 1 ? 'minute ' : 'minutes '}`;
      str += `${secs} ${secs === 1 ? 'second' : 'seconds'}`;

      this.countdownText = str;
    },
    onEventFinished() {
      this.updatePlayerSource();
      this.onShowVideo(false, true);
    },
    onShowVideo(pl = true, bg = false) {
      this.isShowPlayer = pl;
      this.isShowBackground = bg;
    },
    onOpenUploadModal() {
      this.isUploadBackgroundModalOpen = true;
    },
    onClose() {
      this.isUploadBackgroundModalOpen = false;
    },
    onSelectImage(event) {
      this.onFileUpload(event);
      this.onClose();
    },
    setFocusOnTab() {
      this.$forceUpdate();
    },
    getName(name) {
      return name.length > 45 ? `${name.slice(0, 45)}...` : name;
    },
  },
};
</script>

<style scoped>
  .flex {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 40px;
  }

  .system {
    color: #4c5057;
  }

  .right-path {
    display: flex;
    justify-content: space-between;
  }

  .inline-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    max-width: 800px;
  }

  .inline-row > * {
    flex: 1;
    margin-right: 20px;
    max-width: 400px;
  }

  .tabs-details > div {
    width: 100%;
    max-width: 800px;
  }
  .poster-image,
  .player {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 100%;
  }
  .overlay-text {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    font-size: 30px;
    width: 800px;
    text-align: center;
  }
  .icon-play {
    margin: 15px 0;
  }
  .overlay-text small {
    font-size: 20px;
  }

  .svg-icon:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
</style>
