<template>
  <div :class="[customClass, smallStyle ? 'small' : '']">
    <div :class="['tabs', remove ? 'tabss' : '', isLong && 'long-tabs']">
      <ul :class="classNameUl">
        <li
          v-for="tab in tabs"
          :key="tab.name"
          :class="{ 'is-active': tab.isActive, 'has-error': getTabHasError(tab) }"
        >
          <a
            :class="[tab.className, removeMargin ? 'big-tab' : '']"
            @click="selectTab(tab)"
          >
            {{ tab.name }}
          </a>
        </li>
      </ul>
    </div>
    <div class="tabs-details">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Tabs',
  components: {
  },
  props: {
    onSelectTab: {
      type: Function,
      default: () => {},
    },
    selectedValue: {
      type: Array,
      default: null,
    },
    smallStyle: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    handleChange: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledTab: {
      type: String,
      default: '',
    },
    removeMargin: {
      type: Boolean,
      default: false,
    },
    removeMarginMedia: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    isLong: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabs: [],
    };
  },
  computed: {
    classNameUl() {
      if (this.removeMargin) {
        return 'remove-margin';
      }

      if (this.removeMarginMedia) {
        return 'remove-margin-media';
      }

      return '';
    },
  },
  created() {
    this.tabs = this.$children;
  },
  destroyed() {
    this.hrefRemoved();
  },
  methods: {
    hrefRemoved() {
      window.location.hash = '';
    },
    selectTab(selectedTab) {
      if (this.disabled) {
        return;
      }

      if (selectedTab && (this.disabledTab === selectedTab.id)) {
        return;
      }

      this.handleChange(selectedTab.name);
      this.tabs.forEach((tab) => {
        tab.isActive = (tab.name === selectedTab.name);
        this.onSelectTab(selectedTab.id);
      });
    },
    getTabHasError(tab) {
      return tab && tab.hasError;
    },
  },
};
</script>
<style>
  .tabs {
    justify-content: space-between;
    margin-bottom: 15px;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }

  .long-tabs {
    width: calc(100vw - 390px);
  }

  .tabss {
    margin-bottom: 0;
  }

  .tabs ul {
    border-bottom: 2px solid #4b4f55;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;
  }

  .tabs li {
    display: block;
  }

  .tabs a {
    border-bottom: 0;
    color: #a0a0a0;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    justify-content: center;
    line-height: 15px;
    margin-bottom: -1px;
    margin-right: 40px;
    padding: 0 0 13px;
    text-decoration: none;
    vertical-align: top;
  }

  .small .tabs a {
    font-size: 12px;
    margin-right: 20px;
  }

  .tabs .is-active a {
    border-bottom: 2px solid #2591cf;
    color: #fff;
    cursor: pointer;
  }

  .error-tab {
    color: #fb3951 !important;
    border-color: #fb3951 !important;
  }

  .tabs .has-error a {
    color: #fb3951;
  }

  .remove-margin {
    margin-top: 1px;
  }

  .remove-margin-media {
    margin-top: 0;
  }

  .big-tab {
    font-size: 16px !important;
  }

  @media all and (max-width: 1200px) {
    .long-tabs {
      width: calc(100vw - 60px);
    }
  }
</style>
