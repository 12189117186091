import apiCall from '../../../utils/api/api';
import {
  GET_ALL_EVENT_GROUPS,
  GET_ALL_EVENT_GROUPS_REQUEST,
  GET_ALL_EVENT_GROUPS_SUCCESS,
  GET_ALL_EVENT_GROUPS_ERROR,
  GET_EVENT_GROUP,
  GET_EVENT_GROUP_REQUEST,
  GET_EVENT_GROUP_SUCCESS,
  GET_EVENT_GROUP_ERROR,
  ADD_EVENT_GROUP,
  ADD_EVENT_GROUP_REQUEST,
  ADD_EVENT_GROUP_SUCCESS,
  ADD_EVENT_GROUP_ERROR,
  EDIT_EVENT_GROUP,
  EDIT_EVENT_GROUP_REQUEST,
  EDIT_EVENT_GROUP_SUCCESS,
  EDIT_EVENT_GROUP_ERROR,
  EDIT_TV_APPS_EVENT_GROUP,
  EDIT_TV_APPS_EVENT_GROUP_REQUEST,
  EDIT_TV_APPS_EVENT_GROUP_SUCCESS,
  EDIT_TV_APPS_EVENT_GROUP_ERROR,
  DELETE_EVENT_GROUP,
  DELETE_EVENT_GROUP_REQUEST,
  DELETE_EVENT_GROUP_SUCCESS,
  DELETE_EVENT_GROUP_ERROR,
  GET_CMS_EVENT_GROUPS_REQUEST,
  GET_CMS_EVENT_GROUPS_SUCCESS,
  GET_CMS_EVENT_GROUPS_ERROR,
  GET_CUSTOM_FIELDS_EVENT_GROUPS_REQUEST,
  GET_CUSTOM_FIELDS_EVENT_GROUPS_SUCCESS,
  GET_CUSTOM_FIELDS_EVENT_GROUPS_ERROR,
} from '../../actions/events/eventGroups';

const state = {
  isLoading: false,
  eventGroups: [],
  eventGroup: {},
  pagination: {},
  cmsEventGroup: [],
  error: null,
};

const actions = {
  [GET_ALL_EVENT_GROUPS]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_ALL_EVENT_GROUPS_REQUEST);

    let url = `${params.accountId}/standalone-event-group/list`;
    url += params.page ? `?p=${params.page}&l=15` : '?p=1&l=1000';
    url += params.filterValue ? `&filterField=seg.name&filterValue=${params.filterValue}` : '';
    url += params.sortFiled ? `&s=${params.sortFiled}&d=${params.sortBy}` : '';

    apiCall.get(url)
      .then((resp) => {
        commit(GET_ALL_EVENT_GROUPS_SUCCESS, resp.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_ALL_EVENT_GROUPS_ERROR, error);
        reject(error);
      });
  }),
  [GET_CMS_EVENT_GROUPS_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_CMS_EVENT_GROUPS_REQUEST);

    const url = `${params.accountId}/standalone-event-group/list?p=1&l=10000&s=seg.name&d=ASC`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_CMS_EVENT_GROUPS_SUCCESS, resp.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_CMS_EVENT_GROUPS_ERROR, error);
        reject(error);
      });
  }),
  [GET_EVENT_GROUP]: ({ commit }, { accountId, eventGroupId }) => new Promise((resolve, reject) => {
    commit(GET_EVENT_GROUP_REQUEST);

    apiCall.get(`${accountId}/standalone-event-group/${eventGroupId}`)
      .then((resp) => {
        commit(GET_EVENT_GROUP_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_EVENT_GROUP_ERROR, error);
        reject(error);
      });
  }),
  [ADD_EVENT_GROUP]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(ADD_EVENT_GROUP_REQUEST);

    apiCall.post(`${accountId}/standalone-event-group`, data)
      .then((resp) => {
        commit(ADD_EVENT_GROUP_SUCCESS, resp.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_EVENT_GROUP_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_EVENT_GROUP]:
  ({ commit }, { accountId, eventGroupId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_EVENT_GROUP_REQUEST);

    apiCall.put(`${accountId}/standalone-event-group/${eventGroupId}`, data)
      .then((resp) => {
        commit(EDIT_EVENT_GROUP_SUCCESS, resp.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_EVENT_GROUP_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_TV_APPS_EVENT_GROUP]:
    ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
      commit(EDIT_TV_APPS_EVENT_GROUP_REQUEST);

      apiCall.put(`${accountId}/standalone-event-group/tv-apps-group`, data)
        .then((resp) => {
          commit(EDIT_TV_APPS_EVENT_GROUP_SUCCESS, resp.data);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(EDIT_TV_APPS_EVENT_GROUP_ERROR, error);
          reject(error);
        });
    }),
  [DELETE_EVENT_GROUP]:
  ({ commit }, { accountId, eventGroupId }) => new Promise((resolve, reject) => {
    commit(DELETE_EVENT_GROUP_REQUEST);

    apiCall.delete(`${accountId}/standalone-event-group/${eventGroupId}`)
      .then((resp) => {
        commit(DELETE_EVENT_GROUP_SUCCESS, resp.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(DELETE_EVENT_GROUP_ERROR, error);
        reject(error);
      });
  }),
  [GET_CUSTOM_FIELDS_EVENT_GROUPS_REQUEST]:
    ({ commit }, { accountId }) => new Promise((resolve, reject) => {
      commit(GET_CUSTOM_FIELDS_EVENT_GROUPS_REQUEST);

      apiCall.get(`${accountId}/standalone-event-group/custom-field`)
        .then((resp) => {
          commit(GET_CUSTOM_FIELDS_EVENT_GROUPS_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_CUSTOM_FIELDS_EVENT_GROUPS_ERROR, error);
          reject(error);
        });
    }),
};

const mutations = {
  [GET_ALL_EVENT_GROUPS_REQUEST]: () => {
    state.error = null;
    state.isLoading = true;
  },
  [GET_ALL_EVENT_GROUPS_SUCCESS]: (state, payload) => {
    state.eventGroups = payload.data;
    state.pagination = payload.pagination;
    state.isLoading = false;
  },
  [GET_ALL_EVENT_GROUPS_ERROR]: (state, payload) => {
    state.isLoading = false;
    state.error = payload;
  },
  [GET_EVENT_GROUP_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_EVENT_GROUP_SUCCESS]: (state, payload) => {
    state.eventGroup = payload;
    state.isLoading = false;
  },
  [GET_EVENT_GROUP_ERROR]: (state, payload) => {
    state.error = payload;
    state.isLoading = false;
  },
  [ADD_EVENT_GROUP_REQUEST]: () => {},
  [ADD_EVENT_GROUP_SUCCESS]: () => {},
  [ADD_EVENT_GROUP_ERROR]: () => {},
  [EDIT_EVENT_GROUP_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_EVENT_GROUP_SUCCESS]: (state, payload) => {
    state.eventGroup = payload;
    state.isLoading = false;
  },
  [EDIT_EVENT_GROUP_ERROR]: (state, payload) => {
    state.error = payload;
    state.isLoading = false;
  },
  [EDIT_TV_APPS_EVENT_GROUP_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_TV_APPS_EVENT_GROUP_SUCCESS]: (state, payload) => {
    state.eventGroup = payload;
    state.isLoading = false;
  },
  [EDIT_TV_APPS_EVENT_GROUP_ERROR]: (state, payload) => {
    state.error = payload;
    state.isLoading = false;
  },
  [DELETE_EVENT_GROUP_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_EVENT_GROUP_SUCCESS]: () => {
    state.eventGroup = {};
    state.isLoading = false;
  },
  [DELETE_EVENT_GROUP_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_CMS_EVENT_GROUPS_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.cmsEventGroup = payload.data.map((item) => ({
      code: item.id,
      name: item.name,
    }));
  },
};

export default {
  state,
  actions,
  mutations,
};
