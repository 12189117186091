<template>
  <div class="wrapper">
    <ValidationObserver
      ref="validationObserver"
    >
      <Loader :isVisible="isLoading" />
      <form
        v-if="!isNotFound && isFormInitialized"
        novalidate="novalidate"
        @submit.prevent="saveAsset"
        @input="handleFormChange"
      >
        <div class="title"></div>
        <div class="edit-wrapper">
          <MaterialInput
            v-model="formData.label"
            v-bind="formFields.label"
            :className="'inspector-input'"
            :requiredFromBack="true"
          />
          <MaterialSelect
            v-model="formData.assetRole"
            v-bind="formFields.assetRole"
            :options="assetRolesOptions"
            :disabled="assetRolesOptions.length === 1
              && assetRolesOptions[0].code === 'No-items'"
            className="inspector-input"
          />
          <MaterialTextArea
            v-model="formData.content"
            v-bind="formFields.content"
            :className="'inspector-input widget-area'"
            :allowCopy="true"
          />
        </div>
      </form>
      <div
        v-if="isNotFound"
        class="title"
      >
        Ooops... Asset not found.
      </div>
    </ValidationObserver>
    <NotificationModal
      ref="eventWarningModal"
      v-model="isShowWarningModal"
      :modal="isShowWarningModal"
      :bodyMessage="bodyMessageAsset"
      :cancelButtonTitle="'Cancel'"
      :confirmButtonTitle="'Continue'"
      :onAccept="onContinue"
      :onClose="closeModal"
      :isOrangeCancel="true"
    />
    <NotificationModal
      ref="eventWarningModal"
      v-model="isShowMoreOneModal"
      :modal="isShowMoreOneModal"
      :bodyMessage="bodyMessageMoreOne"
      :cancelButtonTitle="'Cancel'"
      :confirmButtonTitle="'Continue'"
      :onAccept="onContinueMoreOne"
      :onClose="closeModalMoreOne"
      :isOrangeCancel="true"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import CONFIG from '../../../constants/config';
import {
  GET_SUBTITLE_ASSET_REQUEST,
  EDIT_SUBTITLE_ASSET_REQUEST,
  GET_ASSET_ROLES_OPTIONS_REQUEST,
} from '../../../store/actions/media/mediaAsset';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import MaterialTextArea from '../../inputs/MaterialTextArea.vue';
import Loader from '../../common/Loader.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import NotificationModal from '../../NotificationModal.vue';

export default {
  name: 'SubtitleAssetForm',
  components: {
    MaterialInput,
    MaterialTextArea,
    Loader,
    MaterialSelect,
    NotificationModal,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'editSubtitleAsset',
    },
    assetData: {
      type: Object,
      default: () => {},
    },
    source: {
      type: Object,
      default: () => {},
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isNotFound: false,
      isFormInitialized: false,
      assetRolesOptions: [],
      bodyMessageAsset: 'This action will remove this Translation Master Asset Role from its previously assigned Subtitle Asset within this Media Item. Do you wish to continue?',
      isShowWarningModal: false,
      isShowMoreOneModal: false,
      hasContinueMoreOne: false,
      bodyMessageMoreOne: '',
      formData: {
        label: '',
        content: '',
        assetRole: '',
      },
      formFields: {
        label: {
          type: 'text',
          name: 'label',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 50,
          },
        },
        assetRole: {
          type: 'text',
          name: 'assetRole',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        content: {
          type: 'text',
          name: 'content',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 500000,
          },
        },
      },
      errorMapping: {
        label: [
          `${this.formPrefix}.label`,
        ],
        content: [
          `${this.formPrefix}.content`,
        ],
      },
      submitEvent: EDIT_SUBTITLE_ASSET_REQUEST,
      successMessage: CONFIG.successMessages.editSubtitleAsset,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.mediaAsset.isLoading,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  created() {
    const assetRolesParams = {
      accountId: this.getCurrentAccountId,
      mediaId: this.$route.params.mediaId,
      assetType: 'Subtitle',
    };

    this.$store.dispatch(GET_ASSET_ROLES_OPTIONS_REQUEST, assetRolesParams).then((res) => {
      this.assetRolesOptions = res.data.map((item) => ({
        code: item.id,
        name: item.name,
        multiAsset: item.multiAsset,
        enabled: item.enabled,
        usedAssetId: item.usedAssetId,
        uniqueAssetType: item.uniqueAssetType,
        usedAssetType: item.usedAssetType,
      }));
      this.assetRolesOptions = this.assetRolesOptions
        .filter((item) => item.enabled
          || (this.source.assetRole && this.source.assetRole.id === item.code));

      if (!this.assetRolesOptions.length) {
        this.assetRolesOptions = [{
          code: 'No-items',
          name: 'No items to display',
        }];

        this.formData.assetRole = this.assetRolesOptions[0].code;
      }
    });

    if (this.assetData.id) {
      const params = {
        accountId: this.getCurrentAccountId,
        assetId: this.assetData.id,
      };
      this.$store.dispatch(GET_SUBTITLE_ASSET_REQUEST, params)
        .then((res) => this.initFormData(res))
        .catch(() => {
          this.isNotFound = true;
        });
    } else {
      this.isFormInitialized = true;
    }
  },
  methods: {
    initFormData(response) {
      if (response.id) {
        this.formData.label = response.label;
        this.formData.content = response.content;
        this.formData.assetRole = response.assetRole && response.assetRole.id;
      }

      this.isFormInitialized = true;
    },
    closeModal() {
      this.isShowWarningModal = false;
    },
    onContinueMoreOne() {
      this.hasContinueMoreOne = true;
      this.saveAsset();
      this.isShowMoreOneModal = false;
      this.close();
    },
    onContinue() {
      this.hasContinue = true;
      this.saveAsset();
      this.isShowWarningModal = false;
      this.close();
    },
    closeModalMoreOne() {
      this.isShowMoreOneModal = false;
    },
    getRequestData() {
      const type = this.assetRolesOptions.find((item) => item.code === this.formData.assetRole);

      if (type
        && (!this.source.assetRole || this.source.assetRole.id !== type.code)
        && ((type.usedAssetId && type.name === 'Translation Master' && !this.hasContinue)
        || (type.usedAssetId && type.name === 'Preview Clip' && !this.hasContinue))) {
        this.isShowWarningModal = true;
        return null;
      }

      if (type
        && !this.hasContinue
        && !type.multiAsset
        && !type.usedAssetType
        && (type.name !== 'Translation Master' || type.name !== 'Preview Clip')
        && (!this.source.assetRole || this.source.assetRole.id !== type.code)
        && type.usedAssetId
        && !this.hasContinueMoreOne) {
        this.bodyMessageMoreOne = `This action will remove this ${type.name} Asset Role from its previously assigned Subtitle Asset within this Media Item. Do you wish to continue?`;
        this.isShowMoreOneModal = true;
        return null;
      }

      if (type
        && type.usedAssetId
        && type.uniqueAssetType
        && !this.hasContinue) {
        this.bodyMessageAsset = `This action will remove this ${type.name} Asset Role from its previously assigned Subtitle Asset within this Media Item. Do you wish to continue?`;
        this.isShowWarningModal = true;
        return null;
      }

      if (type
        && type.usedAssetId
        && type.usedAssetType
        && !type.multiAsset
        && !this.hasContinue) {
        this.bodyMessageAsset = `This action will remove this ${type.name} Asset Role from its previously assigned ${type.usedAssetType} Asset within this Media Item. Do you wish to continue?`;
        this.isShowWarningModal = true;
        return null;
      }

      return {
        accountId: this.getCurrentAccountId,
        assetId: this.assetData.id,
        data: {
          label: this.formData.label,
          content: this.formData.content,
          assetRole: this.formData.assetRole === 'No-items' ? '' : this.formData.assetRole,
        },
      };
    },
    saveAsset() {
      return this.$refs.validationObserver.handleSubmit(this.submit);
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      this.onSuccess();
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);
      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else if (error.error === CONFIG.errorMessages.lockedError) {
        this.$toasted.global.error({
          message: CONFIG.errorMessages.lockedMessage,
        });
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>
<style scoped>
  .title {
    color: #fff;
  }
</style>
