<template>
  <div
    v-if="field.enabled"
    class="row-wrapper"
  >
    <component
      :is="getComponent()"
      v-model="valueModel"
      v-bind="getComponentProps()"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { NUMBER_INPUT_REG_EXP } from '../../utils/validation/regExp';

import MaterialCheckboxItem from '../inputs/MaterialCheckboxItem.vue';
import MaterialToggleItem from '../inputs/MaterialToggleItem.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';
import MaterialSelectCustomValue from '../inputs/MaterialSelectCustomValue.vue';
import MaterialMultiSelect from '../inputs/MaterialMultiSelect.vue';
import MaterialMultiCheckbox from '../inputs/MaterialMultiCheckbox.vue';
import MaterialRadioList from '../inputs/MaterialRadioList.vue';
import MaterialRadioCustomList from '../inputs/MaterialRadioCustomList.vue';
import MaterialCalendar from '../inputs/MaterialCalendar.vue';
import MaterialTime from '../inputs/MaterialTime.vue';
import MaterialDateTime from '../inputs/MaterialDateTime.vue';

export default {
  name: 'FieldValueRow',
  components: {
    MaterialCheckboxItem,
    MaterialToggleItem,
    MaterialInput,
    MaterialSelect,
    MaterialMultiSelect,
    MaterialMultiCheckbox,
    MaterialRadioList,
    MaterialCalendar,
    MaterialTime,
    MaterialDateTime,
  },
  props: {
    formPrefix: {
      type: String,
      default: null,
    },
    field: {
      type: Object,
      default: () => {},
    },
    fieldName: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Array],
      default: '',
    },
    inputClassName: {
      type: String,
      default: '',
    },
    selectClassName: {
      type: String,
      default: '',
    },
    calendarClassName: {
      type: String,
      default: '',
    },
    datetimeClassName: {
      type: String,
      default: '',
    },
    checkboxClassName: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valueModel: {
      get() {
        let returnValue;

        switch (this.getComponent().name) {
        case MaterialCheckboxItem.name:
        case MaterialToggleItem.name:
          returnValue = this.value === 'true' || this.value === true;
          break;
        case MaterialCalendar.name:
          returnValue = this.value ? moment(this.value).toDate() : '';
          break;
        case MaterialTime.name:
          returnValue = moment(this.value, 'HH:mm:ss').isValid() ? this.value : '';
          break;
        default:
          returnValue = this.value;
          break;
        }

        return returnValue;
      },
      set(value) {
        let returnValue;

        switch (this.getComponent().name) {
        case MaterialCheckboxItem.name:
        case MaterialToggleItem.name:
          returnValue = value === true ? 'true' : 'false';
          break;
        case MaterialCalendar.name:
          returnValue = !value ? '' : moment(value).format('YYYY-MM-DD');
          break;
        case MaterialTime.name:
          if (!moment(value, 'HH:mm:ss').isValid()) {
            return;
          }
          returnValue = value;
          break;
        default:
          returnValue = value;
        }

        this.$emit('input', returnValue);
      },
    },
  },
  methods: {
    getComponent() {
      const entryTypeStructures = {
        boolean: {
          value: MaterialCheckboxItem,
          dropdown: MaterialSelect,
          radio: MaterialRadioList,
          toggle: MaterialToggleItem,
        },
        date: {
          value: MaterialCalendar,
        },
        time: {
          value: MaterialTime,
        },
        datetime: {
          value: MaterialDateTime,
        },
        duration: {
          value: MaterialInput,
        },
        link: {
          value: MaterialInput,
        },
        integer: {
          value: MaterialInput,
          checkbox: MaterialMultiCheckbox,
          dropdown: MaterialSelect,
          dropdown_custom: MaterialSelectCustomValue,
          list: MaterialMultiSelect,
          list_custom: MaterialMultiSelect,
          radio: MaterialRadioList,
          radio_custom: MaterialRadioCustomList,
        },
        string: {
          value: MaterialInput,
          checkbox: MaterialMultiCheckbox,
          dropdown: MaterialSelect,
          dropdown_custom: MaterialSelectCustomValue,
          list: MaterialMultiSelect,
          list_custom: MaterialMultiSelect,
          radio: MaterialRadioList,
          radio_custom: MaterialRadioCustomList,
        },
      };

      const {
        entryType,
        structure,
      } = this.field;

      return entryTypeStructures[entryType] && entryTypeStructures[entryType][structure]
        ? entryTypeStructures[entryType][structure]
        : null;
    },
    getTitle(name) {
      return name.length > 30 ? `${name.slice(0, 30)}...` : name;
    },
    getComponentProps() {
      const defaultProps = {
        name: this.fieldName,
        label: this.field.label,
        formPrefix: this.formPrefix,
        disabled: this.disabled,
      };
      const defaultRules = {
        required: this.field.required,
      };

      const selections = this.field.selections ? this.field.selections : [];
      const options = selections.map((item) => ({
        ...item,
        code: item.value,
      }));

      if (this.field.sortSelection) {
        options.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      }

      options.sort((a, b) => {
        if (a.anchored && !b.anchored) {
          return -1;
        }
        if (!a.anchored && b.anchored) {
          return 1;
        }
        return 0;
      });

      const trueOption = options.filter((item) => item.code === 'true');
      const falseOption = options.filter((item) => item.code === 'false');

      const toggleLabels = {
        checked: trueOption && trueOption[0] ? this.getTitle(trueOption[0].name) : 'sync',
        unchecked: falseOption && falseOption[0] ? this.getTitle(falseOption[0].name) : 'sync',
      };

      const customProps = {
        boolean: {
          value: {
            props: {
              className: this.checkboxClassName,
            },
            rules: {
              max: 255,
            },
          },
          dropdown: {
            props: {
              options,
              className: this.selectClassName,
            },
            rules: {
              max: 255,
            },
          },
          radio: {
            props: {
              options,
            },
            rules: {
              max: 255,
            },
          },
          toggle: {
            props: {
              labels: toggleLabels,
            },
            rules: {
              max: 255,
            },
          },
        },
        date: {
          value: {
            props: {
              blockClassName: 'group',
              className: this.calendarClassName,
            },
          },
        },
        time: {
          value: {
            props: {
              blockClassName: 'group',
              format: 'HH:mm:ss',
            },
          },
        },
        datetime: {
          value: {
            props: {
              blockClassName: 'group',
              className: this.datetimeClassName,
            },
          },
        },
        duration: {
          value: {
            props: {
              type: 'number',
              className: this.inputClassName,
            },
          },
        },
        link: {
          value: {
            props: {
              type: 'text',
              className: this.inputClassName,
            },
            rules: {
              max: 4096,
            },
          },
        },
        integer: {
          value: {
            props: {
              type: 'number',
              className: this.inputClassName,
            },
            rules: {
              regex: NUMBER_INPUT_REG_EXP,
              numeric: true,
            },
          },
          checkbox: {
            props: {
              options,
              className: this.checkboxClassName,
            },
            rules: {
            },
          },
          dropdown: {
            props: {
              options,
              className: this.selectClassName,
            },
            rules: {
              regex: NUMBER_INPUT_REG_EXP,
              numeric: true,
            },
          },
          dropdown_custom: {
            props: {
              options,
              type: 'number',
              className: `${this.selectClassName} ${this.inputClassName}`,
            },
            rules: {
              regex: NUMBER_INPUT_REG_EXP,
              numeric: true,
            },
          },
          list: {
            props: {
              searchable: true,
              taggable: false,
              options,
            },
            rules: {
            },
          },
          list_custom: {
            props: {
              searchable: true,
              taggable: true,
              options,
              tagRules: {
                regex: NUMBER_INPUT_REG_EXP,
                numeric: true,
              },
            },
            rules: {
            },
          },
          radio: {
            props: {
              options,
            },
            rules: {
              regex: NUMBER_INPUT_REG_EXP,
              numeric: true,
            },
          },
          radio_custom: {
            props: {
              options,
              type: 'number',
              className: this.inputClassName,
            },
            rules: {
              regex: NUMBER_INPUT_REG_EXP,
              numeric: true,
            },
          },
        },
        string: {
          value: {
            props: {
              type: 'text',
              className: this.inputClassName,
            },
            rules: {
              max: 255,
            },
          },
          checkbox: {
            props: {
              options,
              className: this.checkboxClassName,
            },
            rules: {
              max: 255,
            },
          },
          dropdown: {
            props: {
              options,
              className: this.selectClassName,
            },
            rules: {
              max: 255,
            },
          },
          dropdown_custom: {
            props: {
              options,
              className: `${this.selectClassName} ${this.inputClassName}`,
            },
            rules: {
              max: 255,
            },
          },
          list: {
            props: {
              searchable: true,
              taggable: false,
              options,
            },
            rules: {
            },
          },
          list_custom: {
            props: {
              searchable: true,
              taggable: true,
              options,
              tagRules: {
                max: 255,
              },
            },
            rules: {
            },
          },
          radio: {
            props: {
              options,
            },
            rules: {
              max: 255,
            },
          },
          radio_custom: {
            props: {
              options,
              className: this.inputClassName,
            },
            rules: {
              max: 255,
            },
          },
        },
      };

      const {
        entryType,
        structure,
      } = this.field;

      let props = {};
      let rules = {};

      if (
        customProps[entryType]
        && customProps[entryType][structure]
        && customProps[entryType][structure]
      ) {
        props = customProps[entryType][structure].props
          ? customProps[entryType][structure].props
          : {};
        rules = customProps[entryType][structure].props
          ? customProps[entryType][structure].rules
          : {};
      }

      return {
        ...defaultProps,
        ...props,
        rules: {
          ...defaultRules,
          ...rules,
        },
      };
    },
  },
};
</script>
