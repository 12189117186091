<template>
  <div class="flex">
    <Loader :isVisible="isLoading" />
    <div
      v-if="hasResetToken"
      class="border"
    >
      <h3>Reset Password</h3>
      <ResetPasswordForm
        v-if="!isSubmitted"
        :isSubmitted.sync="isSubmitted"
      />
      <div
        v-else
        class="login"
      >
        <div class="text">
          Your password was successfully reset.
          Please sign in with the new password.
        </div>
        <div class="link reset-link">
          <router-link
            class="link-to"
            to="/login"
          >
            Back to Sign In
          </router-link>
        </div>
      </div>
    </div>
    <div
      v-else
      class="login login-error"
    >
      <div
        v-if="errorMessage"
        class="error-message border"
      >
        {{ errorMessage }}
        <div class="link">
          <router-link
            class="link-to"
            to="/login"
          >
            Back to Sign In
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Loader from '../../components/common/Loader.vue';
import {
  CHECK_RESET_TOKEN_REQUEST,
  ANONIM_AUTH_REQUEST,
} from '../../store/actions/authActions/auth';
import CONFIG from '../../constants/config';
import ResetPasswordForm from '../../components/forms/ResetPasswordForm.vue';

export default {
  name: 'ResetPassword',
  components: {
    ResetPasswordForm,
    Loader,
  },
  data() {
    return {
      isSubmitted: false,
      hasResetToken: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.anonimAuth.isLoading,
    }),
  },
  async created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
    const { params } = this.$route;
    const { invalidAnonTokenError } = CONFIG.errorMessages;
    if (!localStorage.getItem('bc-anon:key')) {
      await this.$store.dispatch(ANONIM_AUTH_REQUEST);
    }
    await this.$store.dispatch(CHECK_RESET_TOKEN_REQUEST, params)
      .then(() => {
        this.hasResetToken = true;
      }).catch(() => {
        this.errorMessage = invalidAnonTokenError;
      });
  },
};
</script>

<style scoped>
  .flex {
    display: flex;
    justify-content: center;
  }

  .login {
    display: flex;
    flex-direction: column;
    padding: 2em 2em 0;
    width: 450px;
  }

  .login-error {
    width: 630px;
  }

  .border {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5%;
    padding: 60px;
  }

  h3 {
    text-align: center;
  }

  .link {
    padding-top: 2em;
  }

  .error-message {
    color: #e77925;
    text-align: center;
  }

  .link-to {
    text-decoration: none;
  }

  .reset-link {
    text-align: center;
  }
</style>
