<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="title modal">
        {{ isEdit ? 'Edit' : 'Add' }} Brushfire Protection
      </div>
      <MaterialSelect
        v-if="brushfireEvents && brushfireEvents.length"
        v-model="formData.eventId"
        v-bind="formFields.eventId"
        :options="brushfireEvents"
        :handleChange="handleChangeBrushfire"
        :withEmptyField="false"
      />
      <div v-if="formData.eventId">
        <div>
          <div
            v-if="brushfireEventTypes && brushfireEventTypes.length"
            class="reload-btn"
            :title="'Refresh Attendee Types'"
          >
            <Button
              :classType="'primary'"
              :srcSvg="iconReload"
              :eventFunction="reloadTypes"
              :smallSize="true"
            />
          </div>
          <div class="mini-title">
            Allowed Attendee Types
          </div>
          <div>
            <div
              :class="error ? 'wrapper-error' : 'wrapper'"
              style="margin-bottom: 15px"
            >
              <label
                :class="[
                  'checkbox-container',
                  error && 'failed',
                ]"
              >
                <input
                  v-model="allSelected"
                  type="checkbox"
                  @change="selectAll"
                />
                <span :class="['checkmark', isTypesErrors && 'failed']"></span>
                <div
                  :title="'All Attendee Types'"
                  :class="['checkbox-label']"
                >
                  All Attendee Types
                </div>
              </label>
            </div>
            <div :class="[brushfireEventTypes.length > 3 && 'types-wrapper']">
              <div
                v-for="item in brushfireEventTypes"
                :key="item.id"
                class="check-wrapper"
              >
                <div :class="isTypesErrors ? 'wrapper-error' : 'wrapper'">
                  <label
                    :class="[
                      'checkbox-container',
                    ]"
                  >
                    <input
                      v-model="formData.brushfireTypes"
                      :value="item.id"
                      type="checkbox"
                      number
                      @change="toggleBrushfireTypes"
                    />
                    <span :class="['checkmark', isTypesErrors && 'failed']"></span>
                    <div
                      :title="item.name"
                      :class="['checkbox-label']"
                    >
                      {{ item.name }}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <transition
            v-if="isTypesErrors"
            name="bounce"
          >
            <span
              v-show="isTypesErrors"
              class="error-label"
            >
              At least one option must be selected
            </span>
          </transition>
        </div>
        <div class="source-info">
          <MaterialInput
            v-model="formData.url"
            v-bind="formFields.url"
          />
          <MaterialInput
            v-model="formData.email"
            v-bind="formFields.email"
          />
          <div>
            <div
              v-if="formData.url"
              style="bottom: auto"
              class="reload-btn"
              :title="'Refresh QR-Code'"
            >
              <Button
                :classType="'primary'"
                :srcSvg="iconReload"
                :eventFunction="reloadQR"
                :smallSize="true"
              />
            </div>
            <p>
              Event Information QR-Code:
            </p>
            <img
              v-if="formData.url"
              :src="`${env}/util/qrcode?content=${formData.url}`"
              alt="QR"
            />
          </div>
        </div>
      </div>
      <div :class="[isEdit ? 'edit-button-wrapper' : 'end-buttons']">
        <Button
          v-if="isEdit"
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="onOpenModal"
        />
        <div style="display: flex">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="backTo"
          />
          <Button
            :title="isEdit ? 'Apply' : 'Add'"
            :classType="'primary'"
            :type="'submit'"
            :disabled="!formData.eventId"
          />
        </div>
      </div>
      <NotificationModal
        ref="modal"
        v-model="isModalOpen"
        :modal="isModalOpen"
        :bodyMessage="bodyMessage"
        :onAccept="removeBrushfire"
        :onClose="onManuallyClose"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import Button from '../../common/Button.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import NotificationModal from '../../NotificationModal.vue';
import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';
import iconWarning from '../../../assets/icons/icon-warning-localization.svg';
import iconReload from '../../../assets/icons/icon-reload-brushfire.svg';

import { SET_EDIT_FORM, SET_OVERFLOW } from '../../../store/actions/common';
import {
  CREATE_BRUSHFIRE_REQUEST,
  DELETE_BRUSHFIRE_REQUEST,
  EDIT_BRUSHFIRE_REQUEST,
  GET_BRUSHFIRE_EVENT_META_REQUEST,
  GET_BRUSHFIRE_EVENT_TYPES_REQUEST,
  GET_BRUSHFIRE_EVENTS_REQUEST,
  GET_BRUSHFIRE_REQUEST,
  REFRESH_QR_CODE_REQUEST,
} from '../../../store/actions/brushfire/brushfire';
import MaterialInput from '../../inputs/MaterialInput.vue';

export default {
  name: 'AddBrushFireForm',
  components: {
    MaterialInput,
    MaterialSelect,
    Button,
    NotificationModal,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'brushfireForm',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    openedLocalization: {
      type: Function,
      default: () => {},
    },
    onSubmitManually: {
      type: Function,
      default: () => {},
    },
    isEdItItem: {
      type: Object,
      default: () => {},
    },
    editItem: {
      type: Object,
      default: () => {},
    },
    mediaItem: {
      type: Object,
      default: () => {},
    },
    isFolder: {
      type: Boolean,
      default: false,
    },
    isMediaItem: {
      type: Boolean,
      default: false,
    },
    isEventGroup: {
      type: Boolean,
      default: false,
    },
    isStandalone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconReload,
      brushfireEventTypes: [],
      allSelected: false,
      iconWarning,
      iconFolderLocalization,
      isOpenLocalization: false,
      enabledLanguages: [],
      isModalOpen: false,
      bodyMessage: CONFIG.modals.deleteBrushfireMessage,
      isTypesErrors: null,
      img: '',
      env: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_HOST}`,
      formData: {
        eventId: '',
        brushfireType: false,
        brushfireTypes: [],
        email: '',
        url: '',
        qrcode: '',
      },
      formFields: {
        eventId: {
          type: 'text',
          name: 'eventId',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        email: {
          type: 'text',
          name: 'email',
          formPrefix: this.formPrefix,
          rules: {
            email: true,
            max: 255,
            required: true,
          },
        },
        url: {
          type: 'text',
          name: 'url',
          formPrefix: this.formPrefix,
          rules: {
            is_valid_url: true,
            max: 4096,
            required: true,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_BRUSHFIRE_REQUEST : CREATE_BRUSHFIRE_REQUEST,
      successMessage: this.isEdit
        ? 'The Brushfire Event has been successfully updated'
        : 'The Brushfire Event has been successfully added',
      errorMessage: 'The Brushfire Event Title & ID selected has been deleted',
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    ...mapState({
      subpages: (state) => state.cms.subpages.map((item) => ({
        code: item.id,
        name: item.name,
      })),
      brushfireEvents: (state) => state.brushfire.brushfireEvents.map((item) => ({
        code: item.eventNumber,
        name: item.title.length > 50
          ? `${item.title.slice(0, 50)}... (${item.eventNumber})`
          : `${item.title} (${item.eventNumber})`,
      })),
      isDelete() {
        if (this.isStandalone) {
          return this.isEdit && !this.editItem.standaloneEventGroup;
        }

        if (this.isMediaItem) {
          return this.isEdit && !this.editItem.mediaFolder;
        }

        return true;
      },
    }),
  },
  mounted() {
    this.$store.dispatch(GET_BRUSHFIRE_EVENTS_REQUEST);

    if (this.isEdit && this.editItem.id) {
      this.formData.brushfireTypes = [];
      const params = {
        accountId: this.getCurrentAccountId,
        brushfireEventId: this.editItem.id,
      };

      this.$store.dispatch(GET_BRUSHFIRE_REQUEST, params)
        .then((res) => this.initFormData(res));
    }
  },
  methods: {
    initFormData(item) {
      this.formData.eventId = item.eventId;
      this.formData.brushfireTypes = item.types;
      this.handleChangeBrushfire();
    },
    handleChangeBrushfire() {
      const params = {
        brushfireEventId: this.formData.eventId,
      };

      if (this.formData.eventId) {
        this.$store.dispatch(GET_BRUSHFIRE_EVENT_TYPES_REQUEST, params).then((res) => {
          this.brushfireEventTypes = res.data.data;

          this.allSelected = true;
          this.selectAll();

          if (this.isEdit) {
            this.formData.email = this.editItem.contactEmail;
            this.formData.url = this.editItem.registrationUrl;
            this.formData.qrcode = this.editItem.registrationQrCode;
          }

          if (this.isEdit && (this.editItem.eventId === this.formData.eventId)) {
            this.allSelected = false;
            this.selectAll();
            this.formData.brushfireTypes = this.editItem.types[0].name
              ? this.editItem.types.map((item) => item.id)
              : this.editItem.types;

            if (this.formData.brushfireTypes.length === this.brushfireEventTypes.length) {
              this.allSelected = true;
              this.selectAll();
            }

            this.formData.email = this.editItem.contactEmail;
            this.formData.url = this.editItem.registrationUrl;
            this.formData.qrcode = this.editItem.registrationQrCode;
          } else {
            this.$store.dispatch(GET_BRUSHFIRE_EVENT_META_REQUEST, params).then((res) => {
              this.formData.email = res.data.data.contactEmail;
              this.formData.url = res.data.data.registrationUrl;
              this.formData.qrcode = res.data.data.registrationQrCode;
            });
          }
        });
      }
    },
    async selectAll() {
      if (this.allSelected) {
        this.formData.brushfireTypes = this.brushfireEventTypes.map((u) => u.id);
        this.isTypesErrors = false;
      } else {
        this.formData.brushfireTypes = [];
      }
    },
    onCloseLocalizationModal() {
      this.isOpenLocalization = false;
      this.$emit('openedLocalization', false);
    },
    toLocalization() {
      this.isOpenLocalization = true;
      this.$emit('openedLocalization', true);
    },
    handleRecorderToggle() {
      if (!this.formData.isToggleRecord) {
        this.isRecorderToggleOpen = true;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    backTo() {
      this.onClose();
    },
    onManuallyClose() {
      this.isModalOpen = false;
    },
    onOpenModal() {
      this.isModalOpen = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    onDeleteClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    removeBrushfire() {
      const params = {
        accountId: this.getCurrentAccountId,
        brushfireEventId: this.editItem.id,
      };

      this.$store.dispatch(DELETE_BRUSHFIRE_REQUEST, params)
        .then(() => {
          this.onSubmitManually();
          this.$toasted.global.success({
            message: 'The Brushfire Event has been successfully deleted',
          });
        });
    },
    toggleBrushfireTypes() {
      this.isTypesErrors = null;
      this.allSelected = this.brushfireEventTypes.length === this.formData.brushfireTypes.length;
    },
    reloadTypes() {
      const params = {
        brushfireEventId: this.formData.eventId,
      };

      this.$store.dispatch(GET_BRUSHFIRE_EVENT_TYPES_REQUEST, params).then((res) => {
        this.brushfireEventTypes = res.data.data;

        this.allSelected = true;
        this.selectAll();
      });
    },
    reloadQR() {
      const params = {
        url: this.formData.url,
      };
      this.$store.dispatch(REFRESH_QR_CODE_REQUEST, params);
    },
    getRequestData() {
      const data = {
        eventId: this.formData.eventId,
        types: this.formData.brushfireTypes,
        mediaFolder: this.isFolder ? this.$route.params.folderId : null,
        standaloneEvent: this.isStandalone ? this.$route.params.id : null,
        standaloneEventGroup: this.isEventGroup ? this.$route.params.id : null,
        mediaItem: this.isMediaItem ? this.mediaItem.id : null,
        contactEmail: this.formData.email,
        registrationUrl: this.formData.url,
      };

      return {
        data,
        accountId: this.getCurrentAccountId,
        brushfireEventId: this.editItem ? this.editItem.id : '',
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.onSubmitManually();
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      this.isTypesErrors = error.form_errors.children.types
        && error.form_errors.children.types.errors;

      const deletedEvent = error.form_errors.children.eventId.errors;

      if (deletedEvent) {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>
.rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.toggles-wrapper {
  display: flex;
}

.toggle-wrapper,
.date-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
}

.toggle-wrapper {
  width: auto;
}

.toggle-title {
  margin-right: 10px;
}

.dynamic {
  margin-top: 30px;
  margin-bottom: 30px;
}

.edit-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.end-buttons {
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

.country-field {
  width: 300px;
}

.country-field:not(:last-child) {
  margin-right: 20px;
}

.wowza p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.wrapper-small {
  margin-top: 20px;
}

.flex {
  justify-content: space-between;
}

.person {
  margin-bottom: 20px;
}

.person p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.select-wrapper {
  width: 300px;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.localization-warning {
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}

.modal {
  color: inherit;
  margin-bottom: 30px;
}

@media all and (max-width: 1320px) {
  .flex {
    flex-wrap: wrap;
  }

  .country-field {
    margin-right: 0;
    width: 100%;
  }

  .country-field:not(:last-child) {
    margin-right: 0;
  }
}

.checkbox-container {
  cursor: pointer;
  display: flex;
  font-size: 14px;
  margin-right: 25px;
  padding-top: 10px;
  position: relative;
  user-select: none;
}

.checkbox-container input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkmark {
  border: 1px solid #383b40;
  border-radius: 3px;
  box-sizing: border-box;
  height: 21px;
  margin-right: 6px;
  margin-top: -2px;
  width: 21px;
}

.checkbox-container:hover input ~ .checkmark {
  border: 1px solid #2591cf;
}

.disabled-checkbox:hover input ~ .checkmark {
  border: 1px solid #383b40;
  cursor: not-allowed;
}

.disabled-checkbox:hover {
  cursor: not-allowed;
}

.checkbox-container input:checked ~ .checkmark {
  border: 1px solid #2591cf;
}

.checkmark::after {
  content: '';
  display: none;
  position: absolute;
}

.checkbox-container input:checked ~ .checkmark::after {
  display: block;
}

.checkbox-container .checkmark::after {
  border: solid #2591cf;
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 7px;
  top: 10px;
  transform: rotate(45deg);
  width: 6px;
}

.short-label {
  margin-right: 5px;
}

.failed {
  border: 1px solid #fb3951;
}

.wrapper-error {
  width: 260px;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

.checkbox-label {
  display: -webkit-box;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.disabled-label {
  color: rgba(255, 255, 255, 0.55);
}

.mini-title {
  padding-top: 10px;
  padding-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.types-wrapper {
  height: 110px;
  overflow: auto;
}

.check-wrapper {
  margin-left: 10px;
  margin-bottom: 10px;
}

.source-info {
  margin-top: 20px;
}

.reload-btn {
  display: flex;
  position: absolute;
  left: 260px
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

</style>
