<template>
  <div class="flex">
    <div class="button-wrapper">
      <Button
        :title="'Upload'"
        class="upload-button"
        :classType="'primary'"
        :eventFunction="openUploadModal"
        :disabled="isDisabled"
      />
      <DragAndDropMediaUploaderModal
        v-if="showUploadModal"
        :getMediaList="getMediaList"
        :folderId="folderId"
        :onClose="onCloseUploadModal"
        :onFileUpload="onFileUpload"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  GENERATE_UPLOAD_URL_REQUEST,
  FILE_APPEND_REQUEST,
  SEND_TRANSCODE_REQUEST,
  SET_UPLOADING_FILES,
} from '../../store/actions/UploadingActions/upload';
import CONFIG from '../../constants/config';

import Button from './Button.vue';
import DragAndDropMediaUploaderModal from './DragAndDropMediaUploaderModal.vue';

export default {
  name: 'UploadMedia',
  components: {
    Button,
    DragAndDropMediaUploaderModal,
  },
  props: {
    folderId: {
      type: String,
      default: '',
    },
    getMediaList: {
		  type: Function,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showUploadModal: false,
      videoId: null,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccount',
      'getRootMediaFolderId',
      'getAppConfig',
    ]),
  },
  methods: {
    openUploadModal() {
      this.showUploadModal = true;
    },
    onCloseUploadModal() {
      this.showUploadModal = false;
    },
    async onFileUpload(files) {
      const chunkSize = Number(this.getAppConfig.mediaItem.uploading.maxPartSize);
      const {
        errorTypeFormat,
        invalidFileFormat,
        failedUpload,
        errorTypeSize,
        failedSizeUpload,
      } = CONFIG.errorMessages.upload;
      this.$toasted.global.success({
        message: this.$tc(
          CONFIG.successMessages.filesPreparedForUpload,
          files.length,
          { count: files.length },
        ),
      });

      await this.$store.dispatch(SET_UPLOADING_FILES, files);

      // eslint-disable-next-line no-restricted-syntax
      for (const file of Array.from(files)) {
        const params = {
          accountId: this.getCurrentAccountId,
          fileData: {
            file,
          },
        };

        if (Number(this.folderId) !== this.getRootMediaFolderId) {
          params.fileData.folder = Number(this.folderId);
        }

        // eslint-disable-next-line no-await-in-loop
        await this.$store.dispatch(GENERATE_UPLOAD_URL_REQUEST, params)
          .then(async (res) => {
            let chunkCounter = 0;
            let loaded = 0;

            const appendFile = (params) => {
              const {
                invalidTranscode,
              } = CONFIG.errorMessages.upload;
              return this.$store.dispatch(FILE_APPEND_REQUEST, params)
                .then((data) => {
                  if (data.totalSize <= chunkSize ? data.id : data.id && data.isLastChunk) {
                    const params = {
                      accountId: this.getCurrentAccountId,
                      itemId: data.id,
                    };
                    this.$store.dispatch(SEND_TRANSCODE_REQUEST, params)
                      .then(() => {
                        this.getMediaList(localStorage.getItem('currentPage-media-list') || 1);
                      })
                      .catch(() => {
                        this.$toasted.global.error({
                          message: invalidTranscode,
                        });
                      });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            };

            for (let i = 0; i < res.uploadUrl.length; i += 1) {
              chunkCounter += 1;
              const numberofChunks = Math.ceil(res.file.size / chunkSize);
              const start = 0;
              const chunkEnd = Math.min(start + chunkSize, res.file.size);
              let chunk = res.file.slice(start, chunkEnd);

              if (i !== 0) {
                loaded += chunkSize;
              }

              if (loaded <= res.file.size) {
                chunk = file.slice(loaded, loaded + chunkSize);
              } else {
                loaded = res.file.size;
              }

              chunk.name = file.name;
              const params = {
                file: res.file.size <= chunkSize ? res.file : chunk,
                id: res.id,
                uploadUrl: res.uploadUrl[i],
                numberofChunks,
                chunkCounter,
                totalSize: res.file.size,
              };

              if (res.file.size > chunkSize && (i + 1 === res.uploadUrl.length)) {
                params.isLastChunk = true;
              }

              // eslint-disable-next-line no-await-in-loop
              await appendFile(params);
            }
          })
          .catch((err) => {
            if (err === errorTypeFormat) {
              this.$toasted.global.error({
                message: invalidFileFormat,
              });
            } else if (err.status === CONFIG.statuses.badRequest) {
              this.$toasted.global.error({
                message: failedUpload,
              });
            } else if (err === errorTypeSize) {
              this.$toasted.global.error({
                message: failedSizeUpload,
              });
            }
          });
      }
    },
  },
};
</script>

<style scoped>
  .flex {
    display: flex;
    justify-content: flex-end;
  }

  .button-wrapper >>> .upload-button {
    margin-right: 15px;
  }
</style>
