<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, classes, required, ariaInput, ariaMsg }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    mode="passive"
  >
    <div
      class="group"
      :class="groupClass"
    >
      <label class="label">
        {{ label }}
        <span
          v-if="required"
          class="asterisk"
        >
          *
        </span>
      </label>
      <div
        v-if="isFeed"
        style="position: relative"
      >
        <input
          v-model="feedValue"
          :value="value"
          type="radio"
          :name="name"
          :disabled="disabled"
          :class="['rad', className]"
          :required="required"
          v-bind="ariaInput"
          :checked="isChecked"
          @change="handleChange"
        />
        <span class="radio-button__control"></span>
      </div>
      <div
        v-else
        style="position: relative"
      >
        <input
          :value="value"
          type="radio"
          :name="name"
          :disabled="disabled"
          :class="['rad', className]"
          :required="required"
          v-bind="ariaInput"
          :checked="isChecked"
          @change="onChange"
        />
        <span class="radio-button__control"></span>
      </div>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
          v-bind="ariaMsg"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import BaseMaterialInput from './BaseMaterialInput.vue';

export default {
  name: 'MaterialRadio',
  components: {
    ValidationProvider,
  },
  extends: BaseMaterialInput,
  props: {
    className: {
      type: String,
      default: '',
    },
    groupClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    formData: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: '',
    },
    isFeed: {
      type: Boolean,
      default: false,
    },
    isItunesType: {
      type: Boolean,
      default: false,
    },
    isLiveRadio: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    isChecked() {
      if (this.isFeed) {
        if (this.isFeed && this.isItunesType) {
          return this.formData.iTunesFileType === this.value;
        }

        if (this.isFeed && !this.isItunesType) {
          return this.formData.feedFormat === this.value;
        }
      }

      if (this.isLiveRadio) {
        return this.formData.mode === this.value;
      }

      return this.formData.value === this.value;
    },
    feedValue() {
      if (this.isFeed && this.isItunesType) {
        return this.formData.iTunesFileType;
      }

      if (this.isFeed && !this.isItunesType) {
        return this.formData.feedFormat;
      }

      return '';
    },
  },
  methods: {
    handleChange() {
      if (this.isFeed && this.isItunesType) {
        this.formData.iTunesFileType = this.value;
      }

      if (this.isFeed && !this.isItunesType) {
        this.formData.feedFormat = this.value;
      }
    },
  },
};
</script>

<style scoped>
  .group {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    width: 120px;
  }

  .rad {
    /*width: 50px;*/
    width: 10px;
    opacity: 0;
    position: absolute;
    top: 4px;
    right: 16px;
    z-index: 44;
    cursor: pointer;
  }

  .advanced-feed {
    margin-right: 10px;
  }

  .radio-itunes {
    display: flex;
    flex-direction: row-reverse;
    width: auto;
    /*margin-left: -16px;*/
  }

  .label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  input[type=radio] {
    box-sizing: border-box;
    padding: 0;
  }

  input {
    font-size: 1rem;
    line-height: 1.5;
    padding: 11px 23px;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0;
    outline: 0;
    background-color: transparent;
  }

  input:disabled {
    cursor: not-allowed;
  }

  .radio-button__control {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 12px;
    vertical-align: middle;
    background-color: inherit;
    color: #2591CF;
    border: 2px solid #fff;
    border-radius: 24px;
  }

  .rad:checked+.radio-button__control:after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    background-color: #2591CF;
    border-radius: 12px;
  }

  .rad:checked+.radio-button__control {
    border-color: #2591CF;
  }

  .radio-button__control {
    transform: scale(0.75);
  }
</style>
