export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';

export const EDIT_PROFILE_CONTACT_INFO = 'EDIT_PROFILE_CONTACT_INFO';
export const EDIT_PROFILE_CONTACT_INFO_REQUEST = 'EDIT_PROFILE_CONTACT_INFO_REQUEST';
export const EDIT_PROFILE_CONTACT_INFO_SUCCESS = 'EDIT_PROFILE_CONTACT_INFO_SUCCESS';

export const EDIT_PROFILE_LOGIN_INFO = 'EDIT_PROFILE_LOGIN_INFO';
export const EDIT_PROFILE_LOGIN_INFO_REQUEST = 'EDIT_PROFILE_LOGIN_INFO_REQUEST';
export const EDIT_PROFILE_LOGIN_INFO_SUCCESS = 'EDIT_PROFILE_LOGIN_INFO_SUCCESS';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const USER_SEND_ACTIVATION_EMAIL_REQUEST = 'USER_SEND_ACTIVATION_EMAIL_REQUEST';
export const USER_SEND_ACTIVATION_EMAIL_SUCCESS = 'USER_SEND_ACTIVATION_EMAIL_SUCCESS';
export const USER_SEND_ACTIVATION_EMAIL_ERROR = 'USER_SEND_ACTIVATION_EMAIL_ERROR';
export const USER_SEND_ADMIN_ACTIVATION_EMAIL_REQUEST = 'USER_SEND_ADMIN_ACTIVATION_EMAIL_REQUEST';
export const USER_SEND_ADMIN_ACTIVATION_EMAIL_SUCCESS = 'USER_SEND_ADMIN_ACTIVATION_EMAIL_SUCCESS';
export const USER_SEND_ADMIN_ACTIVATION_EMAIL_ERROR = 'USER_SEND_ADMIN_ACTIVATION_EMAIL_ERROR';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';
export const EDIT_USER_INFO = 'EDIT_USER_INFO';
export const EDIT_USER_INFO_REQUEST = 'EDIT_USER_INFO_REQUEST';
export const EDIT_USER_INFO_SUCCESS = 'EDIT_USER_INFO_SUCCESS';
export const EDIT_USER_INFO_ERROR = 'EDIT_USER_INFO_ERROR';

export const SIGN_IN_OTHER_USER_REQUEST = 'SIGN_IN_OTHER_USER_REQUEST';
export const SIGN_IN_OTHER_USER_SUCCESS = 'SIGN_IN_OTHER_USER_SUCCESS';
export const SIGN_IN_OTHER_USER_ERROR = 'SIGN_IN_OTHER_USER_ERROR';

export const SET_CANCEL_TOKEN = 'SET_CANCEL_TOKEN';

export const SET_LAST_USED_ACCOUNT = 'SET_LAST_USED_ACCOUNT';
export const SET_LAST_USED_ACCOUNT_REQUEST = 'SET_LAST_USED_ACCOUNT_REQUEST';
export const SET_LAST_USED_ACCOUNT_SUCCESS = 'SET_LAST_USED_ACCOUNT_SUCCESS';
export const SET_LAST_USED_ACCOUNT_ERROR = 'SET_LAST_USED_ACCOUNT_ERROR';

export const LINK_EXISTING_USER_WITH_ACCOUNT = 'LINK_EXISTING_USER_WITH_ACCOUNT';
export const LINK_EXISTING_USER_WITH_ACCOUNT_REQUEST = 'LINK_EXISTING_USER_WITH_ACCOUNT_REQUEST';
export const LINK_EXISTING_USER_WITH_ACCOUNT_SUCCESS = 'LINK_EXISTING_USER_WITH_ACCOUNT_SUCCESS';
export const LINK_EXISTING_USER_WITH_ACCOUNT_ERROR = 'LINK_EXISTING_USER_WITH_ACCOUNT_ERROR';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const DELETE_USER_AS_ADMIN_REQUEST = 'DELETE_USER_AS_ADMIN_REQUEST';
export const DELETE_USER_AS_ADMIN_SUCCESS = 'DELETE_USER_AS_ADMIN_SUCCESS';
export const DELETE_USER_AS_ADMIN_ERROR = 'DELETE_USER_AS_ADMIN_ERROR';
