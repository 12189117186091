<template>
  <div>
    <div
      class="menu-item"
      @click="toProfile"
    >
      My Profile
    </div>
    <div
      v-if="!isSuperAdmin"
      class="menu-item"
      @click="toSupport"
    >
      Support
    </div>
    <div
      v-if="isAdmin"
      class="menu-item"
      @click="toBilling"
    >
      Billing
    </div>
    <div
      v-if="showUsage()"
      class="menu-item"
      @click="toUsage"
    >
      Usage
    </div>
    <div
      class="menu-item"
      @click="logout"
    >
      Logout
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { AUTH_LOGOUT } from '../../store/actions/authActions/auth';

export default {
  name: 'UserDropdown',
  computed: {
    ...mapState({
      profile: (state) => state.common.profile,
      isEditedForm: (state) => state.common.isEditedForm,
      role: (state) => state.common.role,
    }),
    ...mapGetters([
      'isSuperAdmin',
      'isAdmin',
    ]),
  },
  methods: {
    closeDropDown() {
      this.$emit('input', false);
    },
    logout() {
      if (this.isEditedForm) {
        this.$router.push('/login').catch(() => {
          this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push(
            {
              path: '/login',
              force: true,
            },
          ));
        });
      } else {
        this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.go(
          {
            path: '/login',
            force: true,
          },
        ));
      }

      this.closeDropDown();
    },
    showUsage() {
      return document.body.clientWidth <= 1025;
    },
    toProfile() {
      const route = '/settings/user-profile';
      if (this.$router.currentRoute.path !== route) {
        this.$router.push(route);
      }
      this.closeDropDown();
    },
    toSupport() {
      const route = '/support';
      if (this.$router.currentRoute.path !== route) {
        this.$router.push(route);
      }
      this.closeDropDown();
    },
    toBilling() {
      const route = '/billing';
      if (this.$router.currentRoute.path !== route) {
        this.$router.push(route);
      }

      if (this.userAccountRoles.account.subscription.type === 'offline') {
        this.toUsage();
        return;
      }

      this.$router.push('/billing');
      this.closeDropDown();
    },
    toUsage() {
      const route = '/billing/usage';
      if (this.$router.currentRoute.path !== route) {
        this.$router.push(route);
      }
      this.closeDropDown();
    },
  },
};
</script>

<style scoped>
  .menu-item {
    border-top: 1px solid #000;
    font-size: 0.9em;
    font-weight: 500;
    padding: 10px 25px;
    text-align: left;
  }

  .menu-item:hover {
    background-color: #2591cf !important;
    cursor: pointer;
  }
</style>
