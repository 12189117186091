import EventSourcePolyfill from 'eventsource';
import apiCall from './api';

const getEventUrl = (topics) => {
  const subdomain = String(Date.now());
  const sseUrl = `${process.env.VUE_APP_SSE_SCHEMA}://${subdomain}.${process.env.VUE_APP_SSE_HOST}/.well-known/mercure`;
  const url = new URL(sseUrl);

  topics.forEach((topic) => {
    url.searchParams.append('topic', topic);
  });

  return url;
};

const createEventSource = (jwt, topics) => {
  const url = getEventUrl(topics);

  return new EventSourcePolyfill(url.toString(), {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

const getJwtToken = () => apiCall.get('jwt/token').then((resp) => resp.data.jwt_token);

const getEventSource = (topics) => getJwtToken().then((jwt) => createEventSource(jwt, topics));

export default { getEventSource, getJwtToken };
