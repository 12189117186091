<template>
  <div class="list-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="btn-feed-mobile">
      <Button
        :title="'Add Feed'"
        :classType="'primary'"
        :eventFunction="toCreateHandler"
      />
    </div>
    <div class="flex">
      <div class="left-path">
        <span class="system">
          Collections
        </span>
        <span>
          / Series
        </span>
      </div>
      <div class="right-path">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="255"
        />
        <div class="btn-feed-full">
          <Button
            :title="'Add Series'"
            :classType="'primary'"
            :eventFunction="toCreateHandler"
          />
        </div>
      </div>
    </div>
    <div class="accounts-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`s.${sortedField.title}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          title
        </div>
        <div class="th-name company-id">
          <Sorting
            :sortField="`s.${sortedField.updatedAt}`"
            :currentSortedField="currentSortedField"
            :defaultSortUp="false"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          last edited
        </div>
        <div class="th-name users-count">
          modified by
        </div>
        <div class="th-name seasons">
          seasons
        </div>
        <div class="icons-name"></div>
      </div>
      <div
        v-if="!series.length"
        class="empty-search"
      >
        No Items to display
      </div>
      <div
        v-for="(item, key) in series"
        :key="item.id"
        class="account"
        @mouseover="onHoverElem(item)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div
          class="td company"
          :title="item.title"
        >
          {{ item.title }}
        </div>
        <div class="td company-id">
          <span v-if="item.updatedAt">
            {{ item.updatedAt | timezone(getMainProfile.timezone) }}
          </span>
          <span v-else>-</span>
        </div>
        <div class="td users-count">
          {{ item.updatedBy ? item.updatedBy.fullName : '-' }}
        </div>
        <div class="td seasons">
          {{ item.seasonsNumber || '-' }}
        </div>
        <div class="feed-wrapper-icons">
          <div
            v-show="isHover && item.id === selectedId"
            :class="['icons-wrapper', (isHover && (item.id === selectedId))
              ? ''
              : 'icons-wrapper-hide'
            ]"
          >
            <div
              class="icon-edit"
              :title="'Delete Series'"
            >
              <inline-svg
                :src="iconRemove"
                class="svg-icon remove"
                @click="onOpenModalForRemove(item)"
              >
              </inline-svg>
            </div>
            <div class="icon-edit">
              <router-link
                :to="{ path: `series/${item.id}` }"
                :title="editSeries"
              >
                <inline-svg
                  :src="iconEdit"
                  class="svg-icon"
                >
                </inline-svg>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddSeriesModal
      v-model="isAddingOpen"
      :modal="isAddingOpen"
      :onClose="onManuallyClose"
      :onSubmitManually="onSubmitManually"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :title="'Delete Series'"
      :bodyMessage="bodyMessage"
      :warningMess="warningMess"
      :isInUseAsset="true"
      :onAccept="removeSeries"
      :onClose="onClose"
    />
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :totalPages="totalPages"
    />
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import { SET_OVERFLOW } from '../../store/actions/common';
import { DELETE_SERIES_REQUEST, GET_SERIES_LIST_REQUEST } from '../../store/actions/series/series';
import CONFIG from '../../constants/config';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconCode from '../../assets/icons/icon-code.svg';
import iconSource from '../../assets/icons/icon-folder-source.svg';
import iconRemove from '../../assets/icons/icon-remove-role.svg';

import Button from '../../components/common/Button.vue';
import Sorting from '../../components/common/Sorting.vue';
import Pagination from '../../components/common/Pagination.vue';
import LiveSearch from '../../components/common/LiveSearch.vue';
import NotificationModal from '../../components/NotificationModal.vue';
import AddSeriesModal from './AddSeriesModal.vue';
import Loader from '../../components/common/Loader.vue';

export default {
  name: 'SeriesList',
  components: {
    Loader,
    AddSeriesModal,
    NotificationModal,
    Button,
    Sorting,
    Pagination,
    LiveSearch,
  },
  data() {
    return {
      editSeries: 'Edit Series',
      enabledId: null,
      isEnabled: false,
      isModalOpen: false,
      isHover: false,
      selectedId: null,
      bodyMessage: '',
      warningMess: 'Deleting a Series and its Seasons will not result in deleting the Seasons\' Source Folders and their Media Items',
      filter: '',
      sortedField: {
        title: 'title',
        updatedAt: 'updatedAt',
      },
      currentSortedField: 's.updatedAt',
      currentSortBy: 'DESC',
      isSortedUp: false,
      isSortedDown: false,
      iconEdit,
      iconCode,
      iconSource,
      iconRemove,
      modalData: {},
      showModal: false,
      isAddingOpen: false,
    };
  },
  computed: {
    ...mapState({
      series: (state) => state.series.seriesList,
      pagination: (state) => state.series.pagination,
      isLoading: (state) => state.series.isLoading,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getMainProfile',
    ]),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
    sessionStorage.removeItem('lastOpenedSeriesTab');

    this.getSeriesList();
  },
  methods: {
    getSeriesList() {
      const params = {
        accountId: this.getCurrentAccountId,
        page: localStorage.getItem('series') || 1,
        filterValue: '',
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_SERIES_LIST_REQUEST, params);
    },
    toCreateHandler() {
      this.isAddingOpen = true;
    },
    handleSortUp(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_SERIES_LIST_REQUEST, params);
    },
    handleSortDown(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_SERIES_LIST_REQUEST, params);
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        accountId: this.getCurrentAccountId,
        page: params.page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_SERIES_LIST_REQUEST, getParams);
    },
    getPaginationAction(page) {
      const params = {
        accountId: this.getCurrentAccountId,
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_SERIES_LIST_REQUEST, params);
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    onClose() {
      this.isModalOpen = false;
    },
    onManuallyClose() {
      this.isAddingOpen = false;
    },
    onSubmitManually() {
      this.onManuallyClose();
      this.getSeriesList();
    },
    onOpenModalForRemove(item) {
      const {
        deleteSeriesMessage,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.enabledId = item.id;
      this.bodyMessage = deleteSeriesMessage;
    },
    removeSeries() {
      const params = {
        accountId: this.getCurrentAccountId,
        seriesId: this.enabledId,
      };

      this.$store.dispatch(DELETE_SERIES_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Series has been successfully deleted',
          });
          this.getSeriesList();
        });
    },
  },
};
</script>

<style scoped>
.list-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 25px 25px 25px 30px;
  width: 100%;
}

.add-feed {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
}

.accounts-wrapper {
  border: 1px solid #43434d;
  height: 89%;
  overflow-y: auto;
}

.ac-wrapper-header {
  background: #282a2e;
  display: flex;
  padding: 13px 0;
}

.th-name {
  align-items: center;
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
}

.td {
  font-size: 14px;
  padding: 15px 0;
  word-break: break-word;
}

.number {
  padding-left: 21px;
  width: 67px;
}

.company {
  width: 20%;
}

.company-id {
  padding-left: 15px;
  width: 15%;
}

.users-count {
  margin-left: 30px;
  text-align: left;
  width: 15%;
}

.seasons {
  width: 31%;
  text-align: left;
}

.icons-name {
  width: 120px;
}

.account {
  display: flex;
}

.account:hover {
  background: #383b40;
}

.icons-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 0.95;
  justify-content: flex-end;
  min-width: 100px;
  padding: 9px;
  padding-left: 40px;
}

.feed-wrapper-icons {
  margin-right: 5px;
  min-width: 120px;
}

.svg-icon {
  cursor: pointer;
}

.icon-edit {
  margin-left: 31px;
}

.create-button {
  background: #2591cf;
  color: #fff;
  font-size: 16px;
  letter-spacing: normal;
  padding: 0 16px;
  text-transform: none;
}

.right-path {
  align-items: center;
  display: flex;
}

.empty-search {
  padding-top: 40px;
  text-align: center;
}

.icons-wrapper-hide {
  visibility: hidden;
}

.btn-feed-mobile {
  display: none;
}

.flex {
  align-items: center;
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 40px;
}

.right-path {
  align-items: center;
  display: flex;
}

.system {
  color: #4c5057;
}

@media all and (max-width: 1900px) {
  .seasons {
    width: 29%;
  }
}

@media all and (max-width: 1750px) {
  .seasons {
    width: 27%;
  }
}

@media all and (max-width: 1650px) {
  .seasons {
    width: 25%;
  }
}

@media all and (max-width: 1550px) {
  .seasons {
    width: 23%;
  }
}

@media all and (max-width: 1450px) {
  .seasons {
    width: 21%;
  }
}

@media all and (max-width: 1368px) {
  .seasons {
    width: 19%;
  }
}

@media all and (max-width: 1024px) {
  .btn-feed-mobile {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    min-height: 40px;
  }

  .btn-feed-full {
    display: none;
  }
}
</style>
