import Vue from 'vue';
import Router from 'vue-router';
import moment from 'moment';
import { MAIN_INFORMATION } from './store/actions/common';
import { AUTH_LOGOUT } from './store/actions/authActions/auth';

import Settings from './views/settings/Settings.vue';
import Accounts from './views/accounts/Accounts.vue';
import AddFormAccount from './views/accounts/Account.vue';
import Profile from './views/profile/Profile.vue';
import UserList from './views/user/UserList.vue';
import User from './views/user/User.vue';
import Media from './views/media/Media.vue';
import FacebookLogout from './views/common/FacebookLogout.vue';
import WowzaServerList from './views/wowzaServer/WowzaServerList.vue';
import WowzaServer from './views/wowzaServer/WowzaServer.vue';
import Login from './views/Login.vue';
import Support from './views/Support.vue';
import store from './store/store';
import CreatePassword from './views/password/CreatePassword.vue';
import ResetPassword from './views/password/ResetPassword.vue';
import ForgotPassword from './views/password/ForgotPassword.vue';
import PageNotFound from './components/common/PageNotFound.vue';
import AddLive from './views/AddLive/AddLive.vue';
import Feeds from './views/Feeds/Feeds.vue';
import Feed from './views/Feeds/Feed.vue';
import FeedsList from './views/Feeds/FeedsList.vue';
import Analytics from './views/analytics/Analytics.vue';
import AnalyticsOverview from './views/analytics/AnalyticsOverview.vue';
import PeopleList from './views/people/PeopleList.vue';
import Person from './views/people/Person.vue';
import Roles from './views/customRoles/Roles.vue';
import CustomRole from './views/customRoles/CustomRole.vue';
import Billing from './views/billing/Billing.vue';
import Schedule from './views/schedule/Schedule.vue';
import Usage from './views/Usage/Usage.vue';
import ScheduleDeliverySettings from './views/schedule/ScheduleDeliverySettings.vue';
import ScheduleSettings from './views/schedule/ScheduleSettings.vue';
import CustomFieldList from './views/CustomField/CustomFieldList.vue';
import CustomField from './views/CustomField/CustomField.vue';
import AccountSettings from './views/accounts/AccountSettings.vue';
import ScheduleNavigation from './views/schedule/ScheduleNavigation.vue';
import EventGroups from './views/events/EventGroups.vue';
import StandaloneEvents from './views/events/StandaloneEvents.vue';
import EditStandaloneEvent from './views/events/EditStandaloneEvent.vue';
import EditIndividualEvent from './views/events/EditIndividualEvent.vue';
import EditEventGroup from './views/events/EditEventGroup.vue';
import EditAppsEventGroup from './views/events/EditAppsEventGroup.vue';
import LocalizationsList from './views/localization/LocalizationsList.vue';
import Localization from './views/localization/Localization.vue';
import MainPages from './views/CMS/MainPages.vue';
import CmsWrapper from './views/CMS/CmsWrapper.vue';
import CMSPage from './views/CMS/CMSPage.vue';
import RowByPage from './views/CMS/RowByPage.vue';
import GridTab from './views/CMS/GridTab.vue';
import ManuallyRows from './views/CMS/ManuallyRows.vue';
import Banners from './views/CMS/Banners/Banners.vue';
import Banner from './views/CMS/Banners/Banner.vue';
import SliderEditor from './views/CMS/Banners/SliderEditor.vue';
import NavigationList from './views/CMS/Navigation/NavigationList.vue';
import Navigation from './views/CMS/Navigation/Navigation.vue';
import MetaInformationWindow from './components/media/inspector/MetaInformationWindow.vue';
import AssetRoles from './views/AssetRoles/AssetRoles.vue';
import AssetRole from './views/AssetRoles/AssetRole.vue';
import APIKeysList from './views/API/APIKeysList.vue';
import APIKey from './views/API/APIKey.vue';
import AdminAPIKeys from './views/API/AdminAPIKeys.vue';
import FacebookConnector from './components/FacebookConnector.vue';
import CmsGlobalSettings from './views/CMS/Global Settings/CmsGlobalSettings.vue';
import PlaylistList from './views/playlist/PlaylistList.vue';
import PlaylistEditForm from './components/forms/playlist/PlaylistEditForm.vue';
import SeriesList from './views/series/SeriesList.vue';
import SeriesEditForm from './components/forms/series/SeriesEditForm.vue';
import SeasonEditForm from './components/forms/seasons/SeasonEditForm.vue';
import FeaturesList from './views/features/FeaturesList.vue';
import FeatureEditForm from './components/forms/features/FeatureEditForm.vue';
import Categories from './views/Categories/Categories.vue';
import Category from './views/Categories/Category.vue';
import Genres from './views/Genres/Genres.vue';
import Genre from './views/Genres/Genre.vue';

Vue.use(Router);

const hasMetaValue = (to, key, search) => {
  const list = to.matched.filter((record) => record.meta[key]);
  let hasValue = true;

  list.forEach((item) => {
    if (hasValue && !item.meta[key].includes(search)) {
      hasValue = false;
    }
  });

  return hasValue;
};

const getSubscriptionType = () => {
  const account = store.getters.getCurrentAccount;

  if (account) {
    return account.subscription.type;
  }

  return '';
};

const processBeforeEnterGuard = (to) => {
  const authRequired = to.matched.some((record) => record.meta.authRequired);
  const nonAuthRequired = to.matched.some((record) => record.meta.nonAuthRequired);
  const enabledRequired = to.matched.some((record) => record.meta.enabledRequired);
  const roleRequired = to.matched.some((record) => record.meta.hasRole);
  const subscriptionTypeRequired = to.matched.some((record) => record.meta.subscriptionType);
  let path = null;
  if (authRequired && !store.getters.isAuthenticated) {
    path = '/login';
  } else if (nonAuthRequired && store.getters.isAuthenticated) {
    path = '/';
  } else if (enabledRequired && !store.getters.getMainProfile.enabled) {
    path = '/settings/user-profile';
  } else if (roleRequired && !hasMetaValue(to, 'hasRole', store.getters.role)) {
    path = '/settings/user-profile';
  } else if (subscriptionTypeRequired && !hasMetaValue(to, 'subscriptionType', getSubscriptionType())) {
    path = '/';
  }

  return path;
};

const beforeEnterGuard = (to, from, next) => {
  const authRequired = to.matched.some((record) => record.meta.authRequired);
  let path = null;
  if (authRequired && !store.getters.getMainProfile.id) {
    store.dispatch(MAIN_INFORMATION).then(() => {
      path = processBeforeEnterGuard(to);

      if (path) {
        next(path);
      } else {
        next();
      }
    }, () => {
      store.commit(AUTH_LOGOUT);
      next('/login');
    });
  } else {
    path = processBeforeEnterGuard(to);

    if (path) {
      next(path);
    } else {
      next();
    }
  }
};

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/media/folder',
      meta: {
        authRequired: true,
      },
      beforeEnter: beforeEnterGuard,
    },
    {
      path: '/facebook/callback',
      component: FacebookConnector,
      meta: {
        authRequired: true,
      },
      beforeEnter: beforeEnterGuard,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        nonAuthRequired: true,
      },
      beforeEnter: beforeEnterGuard,
    },
    {
      path: '/reset/:id',
      name: 'reset',
      component: ResetPassword,
      meta: {
        nonAuthRequired: true,
      },
      beforeEnter: beforeEnterGuard,
    },
    {
      path: '/activate/:id',
      name: 'activate',
      component: CreatePassword,
      meta: {
        nonAuthRequired: true,
      },
      beforeEnter: beforeEnterGuard,
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: ForgotPassword,
      meta: {
        nonAuthRequired: true,
      },
      beforeEnter: beforeEnterGuard,
    },
    {
      path: '/media/folder',
      name: 'media',
      component: Media,
      props: true,
      meta: {
        authRequired: true,
        enabledRequired: true,
        hasRole: [
          'ROLE_ADMIN',
          'ROLE_MANAGER',
          'ROLE_READ_ONLY',
        ],
      },
      beforeEnter: beforeEnterGuard,
    },
    {
      path: '/media/folder/:folderId',
      name: 'media',
      component: Media,
      props: true,
      meta: {
        authRequired: true,
        enabledRequired: true,
        hasRole: [
          'ROLE_ADMIN',
          'ROLE_MANAGER',
          'ROLE_READ_ONLY',
        ],
      },
      beforeEnter: beforeEnterGuard,
      children: [
        {
          path: 'add-live',
          name: 'addLive',
          component: AddLive,
        },
      ],
    },
    {
      path: '/media/folder/:folderId/more/:mediaId',
      name: 'More Details',
      component: MetaInformationWindow,
      meta: {
        authRequired: true,
        enabledRequired: true,
        hasRole: [
          'ROLE_ADMIN',
          'ROLE_MANAGER',
          'ROLE_READ_ONLY',
        ],
      },
      beforeEnter: beforeEnterGuard,
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
      meta: {
        authRequired: true,
        hasRole: [
          'ROLE_ADMIN',
          'ROLE_MANAGER',
          'ROLE_READ_ONLY',
        ],
      },
      beforeEnter: beforeEnterGuard,
    },
    {
      path: '/collections',
      name: 'collections',
      component: Feeds,
      meta: {
        authRequired: true,
        enabledRequired: true,
        hasRole: [
          'ROLE_ADMIN',
          'ROLE_MANAGER',
        ],
      },
      beforeEnter: beforeEnterGuard,
      children: [
        {
          path: '/collections/feeds',
          name: 'Feeds',
          component: FeedsList,
        },
        {
          path: '/collections/feeds/add',
          name: 'Add Feed',
          component: Feed,
        },
        {
          path: '/collections/feeds/:id',
          name: 'Feed',
          component: Feed,
        },
        {
          path: '/collections/playlists',
          name: 'Playlists',
          component: PlaylistList,
        },
        {
          path: '/collections/playlists/:id',
          name: 'Playlist',
          component: PlaylistEditForm,
        },
        {
          path: '/collections/series',
          name: 'Series',
          component: SeriesList,
        },
        {
          path: '/collections/series/:id',
          name: 'Series',
          component: SeriesEditForm,
        },
        {
          path: '/collections/series/:id/seasons/:seasonId',
          name: 'Series',
          component: SeasonEditForm,
        },
        {
          path: '/collections/features',
          name: 'Features',
          component: FeaturesList,
        },
        {
          path: '/collections/features/:id',
          name: 'Feature',
          component: FeatureEditForm,
        },
      ],
    },
    {
      path: '/cms',
      name: 'Pages',
      component: CmsWrapper,
      meta: {
        authRequired: true,
        enabledRequired: true,
        hasRole: [
          'ROLE_ADMIN',
          'ROLE_MANAGER',
        ],
      },
      beforeEnter: beforeEnterGuard,
      children: [
        {
          path: '/cms/pages',
          name: 'Pages',
          component: MainPages,
        },
        {
          path: '/cms/banners',
          name: 'Banners & Sliders',
          component: Banners,
          hasRole: [
            'ROLE_ADMIN',
            'ROLE_MANAGER',
          ],
        },
        {
          path: '/cms/banners/add',
          name: 'Banners & Sliders',
          component: Banner,
          hasRole: [
            'ROLE_ADMIN',
            'ROLE_MANAGER',
          ],
        },
        {
          path: '/cms/banners/:id',
          name: 'Banners & Sliders',
          component: Banner,
          hasRole: [
            'ROLE_ADMIN',
            'ROLE_MANAGER',
          ],
        },
        {
          path: '/cms/banners/slider/:id',
          name: 'Banners & Sliders',
          component: SliderEditor,
          hasRole: [
            'ROLE_ADMIN',
            'ROLE_MANAGER',
          ],
        },
        {
          path: '/cms/navigation',
          name: 'Navigation',
          component: NavigationList,
          hasRole: [
            'ROLE_ADMIN',
          ],
        },
        {
          path: '/cms/navigation/add',
          name: 'Navigation',
          component: Navigation,
          hasRole: [
            'ROLE_ADMIN',
          ],
        },
        {
          path: '/cms/navigation/:id',
          name: 'Navigation',
          component: Navigation,
          hasRole: [
            'ROLE_ADMIN',
          ],
        },
        {
          path: '/cms/global-configurations',
          name: 'General Configuration',
          component: CmsGlobalSettings,
          hasRole: [
            'ROLE_ADMIN',
          ],
        },
        {
          path: 'pages/:id',
          name: 'Pages',
          component: CMSPage,
          hasRole: [
            'ROLE_ADMIN',
            'ROLE_MANAGER',
          ],
        },
        {
          path: '/cms/pages/:id/rows/add',
          name: 'create a row',
          component: RowByPage,
          hasRole: [
            'ROLE_ADMIN',
            'ROLE_MANAGER',
          ],
        },
        {
          path: '/cms/pages/:id/rows/:rowId',
          name: 'edit a row',
          component: RowByPage,
          hasRole: [
            'ROLE_ADMIN',
            'ROLE_MANAGER',
          ],
        },
        {
          path: '/cms/pages/:id/:rowId/manually',
          name: 'Pages',
          component: ManuallyRows,
          hasRole: [
            'ROLE_ADMIN',
            'ROLE_MANAGER',
          ],
        },
        {
          path: '/cms/pages/:id/tabs/add',
          name: 'create a tab',
          component: GridTab,
          hasRole: [
            'ROLE_ADMIN',
            'ROLE_MANAGER',
          ],
        },
        {
          path: '/cms/pages/:id/tabs/:tabId',
          name: 'edit a tab',
          component: GridTab,
          hasRole: [
            'ROLE_ADMIN',
            'ROLE_MANAGER',
          ],
        },
      ],
    },
    {
      path: '/analytics',
      name: 'analytics',
      component: Analytics,
      meta: {
        authRequired: true,
        enabledRequired: true,
        hasRole: [
          'ROLE_ADMIN',
          'ROLE_MANAGER',
          'ROLE_READ_ONLY',
        ],
      },
      beforeEnter: beforeEnterGuard,
      children: [
        {
          path: '/analytics/overview',
          name: 'Analytics',
          component: AnalyticsOverview,
        },
      ],
    },
    {
      path: '/schedule',
      name: 'calendar',
      component: Schedule,
      props: true,
      meta: {
        authRequired: true,
        enabledRequired: true,
        hasRole: [
          'ROLE_ADMIN',
          'ROLE_MANAGER',
        ],
      },
      beforeEnter: beforeEnterGuard,
    },
    {
      path: '/schedule/calendar',
      redirect: `/schedule?view=week&date=${moment().format('YYYY-MM-DD')}`,
    },
    {
      path: '/schedule',
      name: 'calendar',
      component: ScheduleNavigation,
      meta: {
        authRequired: true,
      },
      beforeEnter: beforeEnterGuard,
      children: [
        {
          path: 'settings',
          name: 'Scheduler Settings',
          component: ScheduleSettings,
          meta: {
            authRequired: true,
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'delivery-settings',
          name: 'Delivery Settings',
          component: ScheduleDeliverySettings,
          meta: {
            authRequired: true,
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'event-group',
          name: 'Event Groups',
          component: EventGroups,
          meta: {
            authRequired: true,
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'event-group/:id',
          name: 'Event Group',
          component: EditEventGroup,
          meta: {
            authRequired: true,
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'event-group/event-list/:id',
          name: 'Individual Events',
          component: StandaloneEvents,
          meta: {
            authRequired: true,
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'event-group/individual/:id',
          name: 'Individual',
          component: EditIndividualEvent,
          meta: {
            authRequired: true,
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'standalone-event',
          name: 'standalone',
          component: StandaloneEvents,
          meta: {
            authRequired: true,
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'standalone-event/:id',
          name: 'standalone',
          component: EditStandaloneEvent,
          meta: {
            authRequired: true,
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'apps-event-group',
          name: 'Apps Event Group',
          component: EditAppsEventGroup,
          meta: {
            authRequired: true,
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
      ],
    },
    {
      path: '/media/folder/delete',
      name: 'delete',
      component: Media,
      meta: {
        authRequired: true,
        enabledRequired: true,
        hasRole: [
          'ROLE_ADMIN',
          'ROLE_MANAGER',
        ],
      },
      beforeEnter: beforeEnterGuard,
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      redirect: '/settings/users',
      meta: {
        authRequired: true,
      },
      beforeEnter: beforeEnterGuard,
      children: [
        {
          path: 'users',
          name: 'Account Users',
          component: UserList,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_SUPER_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'user-profile',
          name: 'user profile settings',
          component: Profile,
          props: true,
        },
        {
          path: 'users/add',
          name: 'user page add',
          component: User,
          props: true,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_SUPER_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'users/:id',
          name: 'user page',
          component: User,
          props: true,
          beforeEnter: (to, from, next) => {
            if (store.getters.getMainProfile.id === Number(to.params.id)) {
              next('/settings/user-profile');
            } else {
              beforeEnterGuard(to, from, next);
            }
          },
        },
        {
          path: 'roles',
          name: 'Roles',
          component: Roles,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'roles/add',
          name: 'CustomRole',
          component: CustomRole,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'roles/:id',
          name: 'CustomRole',
          component: CustomRole,
          props: true,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'accounts',
          name: 'Accounts',
          component: Accounts,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_SUPER_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'accounts/add',
          name: 'add Form Account',
          component: AddFormAccount,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_SUPER_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'accounts/:id',
          name: 'account',
          component: AddFormAccount,
          props: true,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_SUPER_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'people',
          name: 'people',
          component: PeopleList,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'people/add',
          name: 'person',
          component: Person,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'people/:id',
          name: 'person',
          component: Person,
          props: true,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'fields',
          name: 'Custom Fields',
          component: CustomFieldList,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'fields/add',
          name: 'Add Custom Field',
          component: CustomField,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'fields/:id',
          name: 'Edit Custom Field',
          component: CustomField,
          props: true,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'account',
          name: 'Account Settings',
          component: AccountSettings,
          props: true,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'localization',
          name: 'Localization',
          component: LocalizationsList,
          props: true,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'localization/add',
          name: 'Localization add',
          component: Localization,
          props: true,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'localization/:id',
          name: 'Localization edit',
          component: Localization,
          props: true,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'asset-roles',
          name: 'Asset Roles',
          component: AssetRoles,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'asset-roles/add',
          name: 'Asset Roles',
          component: AssetRole,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'asset-roles/:id',
          name: 'Asset Roles',
          component: AssetRole,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'servers',
          name: 'servers',
          component: WowzaServerList,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_SUPER_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'servers/add',
          name: 'wowza server page add',
          component: WowzaServer,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_SUPER_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'servers/:id',
          name: 'server page',
          component: WowzaServer,
          props: true,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_SUPER_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'api-keys',
          name: 'API Keys',
          component: APIKeysList,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_SUPER_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'api-keys/add',
          name: 'API Keys',
          component: APIKey,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_SUPER_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'api-keys/:id',
          name: 'API Keys',
          component: APIKey,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_SUPER_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'api-keys-admin',
          name: 'Account API Keys',
          component: AdminAPIKeys,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'categories',
          name: 'Categories',
          component: Categories,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'categories/:id',
          name: 'Categories',
          component: Category,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'genres',
          name: 'Genres',
          component: Genres,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
        {
          path: 'genres/:id',
          name: 'Genres',
          component: Genre,
          meta: {
            enabledRequired: true,
            hasRole: [
              'ROLE_ADMIN',
              'ROLE_MANAGER',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
      ],
    },
    {
      path: '/billing',
      name: 'billing',
      component: Billing,
      meta: {
        authRequired: true,
        hasRole: [
          'ROLE_ADMIN',
        ],
      },
      beforeEnter: beforeEnterGuard,
      children: [
        {
          path: 'usage',
          name: 'usage',
          component: Usage,
          meta: {
            hasRole: [
              'ROLE_ADMIN',
            ],
          },
          beforeEnter: beforeEnterGuard,
        },
      ],
    },
    {
      path: '/facebook-logout',
      name: 'facebook',
      component: FacebookLogout,
      meta: {
        authRequired: true,
        enabledRequired: true,
      },
      beforeEnter: beforeEnterGuard,
    },
    {
      path: '*',
      name: 'page not found',
      component: PageNotFound,
      props: true,
    },
  ],
});
