import moment from 'moment';
import common from '../common/common';
import {
  GET_MEDIA_ITEM_REQUEST,
  GET_MEDIA_ITEM_ERROR,
  GET_MEDIA_ITEM_SUCCESS,
  GET_MEDIA_ITEM_TRANSCODING_SUCCESS,
  REMOVE_MEDIA_ITEM_REQUEST,
  REMOVE_MEDIA_ITEM_SUCCESS,
  REMOVE_MEDIA_ITEM_ERROR,
  SETUP_PLAYER,
  SETUP_PLAYER_SUCCESS,
  SET_MARK_IN,
  SET_MARK_IN_SUCCESS,
  SET_MARK_OUT,
  SET_MARK_OUT_SUCCESS,
  EDIT_MEDIA_ITEM_REQUEST,
  EDIT_MEDIA_ITEM_SUCCESS,
  EDIT_MEDIA_ITEM_ERROR,
  CLEAR_MARKERS,
  DESTROY_PLAYER,
  DESTROY_PLAYER_SUCCESS,
  MOVE_MEDIA_ITEM_REQUEST,
  MOVE_MEDIA_ITEM_ERROR,
  APPROVE_MEDIA_ITEM,
  APPROVE_MEDIA_ITEM_SUCCESS,
  APPROVE_MEDIA_ITEM_REQUEST,
  APPROVE_MEDIA_ITEM_ERROR,
  GET_MEDIA_LANGUAGE,
  GET_MEDIA_LANGUAGE_REQUEST,
  GET_MEDIA_LANGUAGE_SUCCESS,
  GET_MEDIA_LANGUAGE_ERROR,
  LOCKED_MEDIA_REQUEST,
  LOCKED_MEDIA_SUCCESS,
  LOCKED_MEDIA_ERROR,
  GET_PERSON_LIST,
  GET_PERSON_LIST_REQUEST,
  GET_PERSON_LIST_SUCCESS,
  GET_ROLE_LIST,
  GET_ROLE_LIST_REQUEST,
  GET_ROLE_LIST_SUCCESS,
  GET_MEDIA_ITEM_ASSETS_REQUEST,
  GET_MEDIA_ITEM_ASSETS_ERROR,
  GET_MEDIA_ITEM_ASSETS_SUCCESS,
  CLEAR_ASSET_LIST,
} from '../../actions/media/mediaItem';
import apiCall from '../../../utils/api/api';
import { GET_ROLE_ERROR } from '../../actions/roles/rolesActions';
import { GET_PERSON_ERROR } from '../../actions/peopleActions/people';
import {
  GET_PROCESSING_SSE_CLOSED,
  GET_PROCESSING_SSE_REQUEST,
  GET_PROCESSING_SSE_SUCCESS,
} from '../../actions/media/mediaAsset';
import sseCall from '../../../utils/api/sse';

const state = {
  isLoading: false,
  mediaItem: {},
  player: null,
  markers: {},
  languageList: [],
  personList: [],
  roleList: [],
  assets: [],
};

let sseSource;

const convertPayload = (payload) => {
  const timezone = common.getters.getUserTimezone();

  if (payload.airDate) {
    const airDate = timezone
      ? moment(payload.airDate).tz(timezone)
      : moment(payload.airDate);

    if (airDate.isValid()) {
      payload.airDate = airDate.format();
    }
  }

  return payload;
};

const actions = {
  [GET_MEDIA_ITEM_REQUEST]:
  ({ commit }, { accountId, itemId }) => new Promise((resolve, reject) => {
    commit(GET_MEDIA_ITEM_REQUEST);

    apiCall.get(`${accountId}/media-collection/item/${itemId}`)
      .then((resp) => {
        commit(GET_MEDIA_ITEM_SUCCESS, resp.data);
        resolve(resp.data);
      }).catch((err) => {
        commit(GET_MEDIA_ITEM_ERROR);
        reject(err);
      });
  }),
  [GET_MEDIA_ITEM_ASSETS_REQUEST]:
  ({ commit }, { accountId, itemId }) => new Promise((resolve, reject) => {
    commit(GET_MEDIA_ITEM_ASSETS_REQUEST);

    apiCall.get(`${accountId}/media-collection/item/${itemId}/assets`)
      .then((resp) => {
        commit(GET_MEDIA_ITEM_ASSETS_SUCCESS, resp.data);
        resolve(resp.data);
      }).catch((err) => {
        commit(GET_MEDIA_ITEM_ASSETS_ERROR);
        reject(err);
      });
  }),
  [LOCKED_MEDIA_REQUEST]:
  ({ commit }, { accountId, itemId, locked }) => new Promise((resolve, reject) => {
    commit(LOCKED_MEDIA_REQUEST);

    const url = `${accountId}/media-collection/item/${itemId}/lock`;

    apiCall.put(url, { locked })
      .then((res) => {
        commit(LOCKED_MEDIA_SUCCESS, res.data);
        resolve();
      }).catch((err) => {
        commit(LOCKED_MEDIA_ERROR);
        reject(err);
      });
  }),
  [REMOVE_MEDIA_ITEM_REQUEST]:
  ({ commit }, { accountId, itemId }) => new Promise((resolve, reject) => {
    commit(REMOVE_MEDIA_ITEM_REQUEST);

    apiCall.delete(`${accountId}/media-collection/item/${itemId}`)
      .then(() => {
        commit(REMOVE_MEDIA_ITEM_SUCCESS);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(REMOVE_MEDIA_ITEM_ERROR);
        reject(error);
      });
  }),
  [EDIT_MEDIA_ITEM_REQUEST]:
  ({ commit }, { accountId, itemId, params }) => new Promise((resolve, reject) => {
    commit(EDIT_MEDIA_ITEM_REQUEST);
    const data = {
      name: params.mediaItem.name,
      description: params.mediaItem.description,
      contentType: params.mediaItem.contentType,
      airDate: params.airDate,
      languages: params.selectedLanguage,
      workflowMediaTags: params.workflowMediaTags,
      searchMediaTags: params.searchTags,
      posterImage: params.posterImage,
      mediaItemContributors: params.mediaItemContributors,
      fields: params.fields,
      legacyId: params.legacyId,
      availableFrom: params.availableFrom,
      expirationAt: params.expirationAt,
    };

    if (params.mediaItem.contentType === 'Episode') {
      data.episodeNumber = params.mediaItem.episodeNumber;
    }

    if (
      params.mediaItem.type === 'vod'
      && params.mediaItem.markIn !== null
      && params.mediaItem.markOut !== null
    ) {
      const {
        markIn,
        markOut,
      } = state.markers;

      data.markIn = markIn >= 0 ? markIn : params.mediaItem.markIn;
      data.markOut = markOut >= 0 ? markOut : params.mediaItem.markOut;
    }

    apiCall.put(`${accountId}/media-collection/item/${itemId}/inspector`, data)
      .then((res) => {
        commit(EDIT_MEDIA_ITEM_SUCCESS, res.data);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(EDIT_MEDIA_ITEM_ERROR);
        reject(error);
      });
  }),
  [MOVE_MEDIA_ITEM_REQUEST]:
  ({ commit }, { accountId, rootMediaFolderId, data }) => new Promise((resolve, reject) => {
    const url = `${accountId}/media-collection/item/move`;

    let id;

    if (data.mediaFolder !== '0' && rootMediaFolderId !== Number(data.mediaFolder)) {
      id = data.mediaFolder;
    } else if (rootMediaFolderId === Number(data.mediaFolder) || data.mediaFolder === '0') {
      id = null;
    }

    const postData = {
      mediaFolder: id,
      mediaItems: [data.mediaItem.id],
    };

    apiCall.post(url, postData)
      .then(() => {
        resolve();
      }).catch((err) => {
        const error = err.response;
        commit(MOVE_MEDIA_ITEM_ERROR);
        reject(error);
      });
  }),
  [SETUP_PLAYER]: ({ commit }, player) => new Promise(() => {
    commit(SETUP_PLAYER_SUCCESS, player);
  }),
  [SET_MARK_IN]: ({ commit }, time) => new Promise(() => {
    commit(SET_MARK_IN_SUCCESS, time);
  }),
  [SET_MARK_OUT]: ({ commit }, time) => new Promise(() => {
    commit(SET_MARK_OUT_SUCCESS, time);
  }),
  [CLEAR_MARKERS]: ({ commit }) => new Promise(() => {
    commit(CLEAR_MARKERS);
  }),
  [CLEAR_ASSET_LIST]: ({ commit }) => new Promise(() => {
    commit(CLEAR_ASSET_LIST);
  }),
  [DESTROY_PLAYER]: ({ commit }, player) => new Promise(() => {
    commit(DESTROY_PLAYER_SUCCESS, player);
  }),
  [APPROVE_MEDIA_ITEM]:
  ({ commit }, { accountId, itemId, data }) => new Promise((resolve, reject) => {
    commit(APPROVE_MEDIA_ITEM_REQUEST);

    apiCall.put(`${accountId}/media-collection/item/${itemId}/approve`, data)
      .then((res) => {
        commit(APPROVE_MEDIA_ITEM_SUCCESS, res.data);
        resolve();
      }).catch((err) => {
        reject(err);
      });
  }),
  [GET_MEDIA_LANGUAGE]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_MEDIA_LANGUAGE_REQUEST);
    apiCall.get('choice/language')
      .then((res) => {
        commit(GET_MEDIA_LANGUAGE_SUCCESS, res.data);
        resolve(res.data);
      }).catch((err) => {
        commit(GET_MEDIA_LANGUAGE_ERROR);
        reject(err);
      });
  }),
  [GET_ROLE_LIST]: ({ commit }, accountId) => new Promise((resolve, reject) => {
    commit(GET_ROLE_LIST_REQUEST);

    apiCall.get(`choice/accounts/${accountId}/contributor-roles`)
      .then((res) => {
        commit(GET_ROLE_LIST_SUCCESS, res.data);
        resolve(res.data);
      }).catch((err) => {
        commit(GET_ROLE_ERROR);
        reject(err);
      });
  }),
  [GET_PERSON_LIST]: ({ commit }, accountId) => new Promise((resolve, reject) => {
    commit(GET_PERSON_LIST_REQUEST);

    apiCall.get(`choice/accounts/${accountId}/contributor-people`)
      .then((res) => {
        commit(GET_PERSON_LIST_SUCCESS, res.data);
        resolve(res.data);
      }).catch((err) => {
        commit(GET_PERSON_ERROR);
        reject(err);
      });
  }),
  [GET_PROCESSING_SSE_REQUEST]: ({ commit }) => new Promise((resolve) => {
    let count = 0;
    function connect() {
      count += 1;
      const url = '/media-collection/assets';
      sseSource = sseCall.getEventSource([url]);

      sseSource.then((eventSource) => {
        eventSource.onmessage = (event) => {
          const res = JSON.parse(event.data);
          resolve(res);
          commit(GET_PROCESSING_SSE_SUCCESS, res);
        };

        eventSource.onerror = () => {
          if (count <= 20) {
            window.setTimeout(() => {
              connect();
            }, 5000);
          } else {
            eventSource.close();
          }
        };
      });
    }

    connect();
  }),
  [GET_PROCESSING_SSE_CLOSED]: () => new Promise(() => {
    sseSource.then((eventSource) => {
      eventSource.close();
    });
  }),
};

const mutations = {
  [GET_MEDIA_ITEM_REQUEST]: () => {
    state.isLoading = true;
  },
  [SETUP_PLAYER_SUCCESS]: (state, payload) => {
    state.player = payload;
  },
  [SET_MARK_IN_SUCCESS]: (state, payload) => {
    state.markers.markIn = payload;
  },
  [SET_MARK_OUT_SUCCESS]: (state, payload) => {
    state.markers.markOut = payload;
  },
  [EDIT_MEDIA_ITEM_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_MEDIA_ITEM_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.markers = {};
    state.mediaItem = convertPayload(payload);
  },
  [EDIT_MEDIA_ITEM_ERROR]: () => {
    state.isLoading = false;
  },
  [SETUP_PLAYER_SUCCESS]: (state, payload) => {
    state.player = payload;
  },
  [SET_MARK_IN_SUCCESS]: (state, payload) => {
    if (payload === Math.floor(state.mediaItem.duration)) {
      state.markers.markIn = state.mediaItem.duration;
    } else {
      state.markers.markIn = payload;
    }
  },
  [SET_MARK_OUT_SUCCESS]: (state, payload) => {
    if (payload === Math.floor(state.mediaItem.duration)) {
      state.markers.markOut = state.mediaItem.duration;
    } else {
      state.markers.markOut = payload;
    }
  },
  [CLEAR_MARKERS]: (state) => {
    state.markers = {
      markIn: 0,
      markOut: state.mediaItem.duration,
    };
  },
  [GET_MEDIA_ITEM_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.mediaItem = convertPayload(payload);
  },
  [GET_MEDIA_ITEM_TRANSCODING_SUCCESS]: (state, payload) => {
    state.isLoading = false;

    if (state.mediaItem.id === payload.id) {
      state.mediaItem = Object.assign(state.mediaItem, convertPayload(payload));
    }
  },
  [GET_MEDIA_ITEM_ERROR]: () => {
    state.isLoading = false;
  },
  [REMOVE_MEDIA_ITEM_REQUEST]: () => {
    state.isLoading = true;
  },
  [REMOVE_MEDIA_ITEM_SUCCESS]: () => {
    state.isLoading = false;
  },
  [REMOVE_MEDIA_ITEM_ERROR]: () => {
    state.isLoading = false;
  },
  [DESTROY_PLAYER_SUCCESS]: () => {
    state.player = null;
  },
  [LOCKED_MEDIA_REQUEST]: () => {
    state.isLoading = true;
  },
  [LOCKED_MEDIA_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.markers = {};
    state.mediaItem = convertPayload(payload);
  },
  [LOCKED_MEDIA_ERROR]: () => {
    state.isLoading = false;
  },
  [APPROVE_MEDIA_ITEM_REQUEST]: () => {
    state.isLoading = true;
  },
  [APPROVE_MEDIA_ITEM_SUCCESS]: (state, payload) => {
    state.mediaItem = convertPayload(payload);
    state.isLoading = false;
  },
  [APPROVE_MEDIA_ITEM_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_MEDIA_LANGUAGE_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_MEDIA_LANGUAGE_SUCCESS]: (state, payload) => {
    state.languageList = payload.data;
    state.isLoading = false;
  },
  [GET_MEDIA_LANGUAGE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_PERSON_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_PERSON_LIST_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.personList = payload;
  },
  [GET_PERSON_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ROLE_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_ROLE_LIST_SUCCESS]: (state, payload) => {
    state.roleList = payload;
    state.isLoading = false;
  },
  [GET_ROLE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_MEDIA_ITEM_ASSETS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_MEDIA_ITEM_ASSETS_SUCCESS]: (state, payload) => {
    state.assets = payload.assets;
    state.assets.forEach((item, index) => {
      item.key = `${item.type}-${index}`;
      item.name = item.name ? item.name : '';
      item.format = item.format ? item.format : '';
      item.dimensions = item.dimensions ? item.dimensions : '';
      item.filesize = item.filesize ? item.filesize : 0;
      item.type = item.type === 'video' && item.format === 'mezzanine' ? 'mezzanine' : item.type;
    });
    state.isLoading = false;
  },
  [GET_PROCESSING_SSE_SUCCESS]: (state, payload) => {
    state.assets.map((item) => {
      if (item.id === payload.id) {
        item.status = payload.status;
        item.canDelete = payload.canDelete;
        item.canEdit = payload.canEdit;
        item.url = payload.url;
      }

      return item;
    });
  },
  [GET_MEDIA_ITEM_ASSETS_ERROR]: () => {
    state.isLoading = false;
  },
  [CLEAR_ASSET_LIST]: () => {
    state.assets = [];
  },
};

export default {
  state,
  actions,
  mutations,
};
