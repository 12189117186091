<template>
  <div class="inside-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="flex">
      <div class="left-path">
        <span class="main-title">
          Usage summary
        </span>
      </div>
      <div class="right-path">
        <div class="report-wrapper">
          <span>
            Report type:
          </span>
          <MaterialOpacitySelect
            v-model="reportType"
            :options="options"
          />
        </div>
      </div>
    </div>
    <div
      v-if="reportType === 'historical'"
      class="calendar-wrapper"
    >
      <MaterialCalendar
        v-model="formData.startDate"
        :format="'MMMM yyyy'"
        :minimumView="'month'"
        :label="'From:'"
        :blockClassName="'monthly'"
        :classNameLabel="'monthly-label'"
        :className="'month-picker'"
      />
      <MaterialCalendar
        v-model="formData.finishDate"
        :format="'MMMM yyyy'"
        :minimumView="'month'"
        :label="'To:'"
        :blockClassName="'monthly'"
        :classNameLabel="'monthly-label'"
        :className="'month-picker'"
      />
      <Button
        :title="'Apply'"
        :classType="'primary'"
        :eventFunction="getReport"
      />
    </div>
    <BandwichItem
      :key="`bandwidth${chartKey}`"
      :data="reports"
    />
    <StorageItem
      :key="`storage${chartKeyStorage}`"
      :data="storage"
    />
  </div>
</template>

<script>
import moment from 'moment';
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  GET_CDN_REPORT_REQUEST,
  GET_STORAGE_REPORT_REQUEST,
} from '../../store/actions/billing/billing';
import CONFIG from '../../constants/config';

import MaterialOpacitySelect from '../../components/inputs/MaterialOpacitySelect.vue';
import MaterialCalendar from '../../components/inputs/MaterialCalendar.vue';
import Button from '../../components/common/Button.vue';
import BandwichItem from './BandwichItem.vue';
import StorageItem from './StorageItem.vue';
import Loader from '../../components/common/Loader.vue';

export default {
  name: 'Usage',
  components: {
    Loader,
    StorageItem,
    MaterialCalendar,
    MaterialOpacitySelect,
    BandwichItem,
    Button,
  },
  data() {
    return {
      formData: {
        startDate: new Date(),
        finishDate: new Date(),
      },
      chartKey: 0,
      chartKeyStorage: 0,
      successMessage: '',
      reports: [],
      storage: [],
      reportType: 'current',
      options: [
        {
          code: 'current',
          name: 'Current Month',
        },
        {
          code: 'historical',
          name: 'Historical',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.billing.isLoading,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  watch: {
    reportType(val) {
      if (val === 'current') {
        this.getCurrentMonthReport();
      }
    },
  },
  created() {
    this.getCurrentMonthReport();
  },
  methods: {
    getCurrentMonthReport() {
      const today = new Date();
      this.getChangedMonth(today);
      const month = this.getChangedMonth(today);
      const year = today.getFullYear();
      const lastDayOfMonth = new Date(today.getFullYear(), month, 0);
      const startDate = `${year}-${month}-01`;
      const finishDate = `${moment(lastDayOfMonth).format('YYYY-MM-DD')} 23:59:59`;

      const params = {
        accountId: this.getCurrentAccountId,
        startDate,
        finishDate,
      };

      this.getReportData(params);
    },
    changeChartKey() {
      this.chartKey += 1;
      this.chartKeyStorage += 1;
    },
    changedStart(date) {
      const month = this.getChangedMonth(date);
      const year = date.getFullYear();
      return `${year}-${month}-01`;
    },
    getChangedMonth(date) {
      const month = date.getMonth() + 1;
      if (month < 10) {
        return `0${month}`;
      }

      return month;
    },
    changedEnd(date) {
      const month = date.getMonth() + 1;
      const lastDayOfMonth = new Date(date.getFullYear(), month, 0);
      return `${moment(lastDayOfMonth).format('YYYY-MM-DD')} 23:59:59`;
    },
    getReport() {
      const params = {
        accountId: this.getCurrentAccountId,
        startDate: this.changedStart(this.formData.startDate),
        finishDate: this.changedEnd(this.formData.finishDate),
      };

      this.getReportData(params);
    },
    getReportData(params) {
      const cdnPromise = this.$store.dispatch(GET_CDN_REPORT_REQUEST, params)
        .then((res) => {
          this.reports = res;
          this.changeChartKey();
        })
        .catch((err) => {
          if (err.data.error !== CONFIG.errorMessages.unknown) {
            throw err;
          }
        });

      const storagePromise = this.$store.dispatch(GET_STORAGE_REPORT_REQUEST, params)
        .then((res) => {
          this.storage = res;
          this.changeChartKey();
        })
        .catch((err) => {
          if (err.data.error !== CONFIG.errorMessages.unknown) {
            throw err;
          }
        });

      Promise.all([cdnPromise, storagePromise])
        .catch((err) => {
          this.getError(err);
        });
    },
    getError(err) {
      const {
        reportIntervalIsLongType,
        reportIntervalStartGreaterThanNowType,
        reportIntervalStartGreaterThanEndType,
        reportIntervalStartGreaterThanEndMessage,
        reportIntervalStartGreaterThanNowMessage,
        reportIntervalIsLongMessage,
        commonServerError,
      } = CONFIG.errorMessages;
      let message;

      switch (err.data.error) {
      case reportIntervalIsLongType:
        message = reportIntervalIsLongMessage;
        break;
      case reportIntervalStartGreaterThanNowType:
        message = reportIntervalStartGreaterThanNowMessage;
        break;
      case reportIntervalStartGreaterThanEndType:
        message = reportIntervalStartGreaterThanEndMessage;
        break;
      default:
        message = commonServerError;
        break;
      }

      this.$toasted.global.error({
        message,
      });
    },
  },
};
</script>

<style scoped>
  .inside-wrapper {
    padding: 25px 25px 25px 30px;
    width: 100%;
  }

  .flex {
    align-items: center;
    display: flex;
    flex: 0;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 40px;
  }

  .right-path {
    align-items: center;
    display: flex;
  }

  .report-wrapper {
    align-items: center;
    display: flex;
  }

  .calendar-wrapper {
    display: flex;
  }
</style>
