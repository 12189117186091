export const GET_MEDIA_ITEM_REQUEST = 'GET_MEDIA_ITEM_REQUEST';
export const GET_MEDIA_ITEM_SUCCESS = 'GET_MEDIA_ITEM_SUCCESS';
export const GET_MEDIA_ITEM_TRANSCODING_SUCCESS = 'GET_MEDIA_ITEM_TRANSCODING_SUCCESS';
export const GET_MEDIA_ITEM_ERROR = 'GET_MEDIA_ITEM_ERROR';

export const REMOVE_MEDIA_ITEM_REQUEST = 'REMOVE_MEDIA_ITEM_REQUEST';
export const REMOVE_MEDIA_ITEM_SUCCESS = 'REMOVE_MEDIA_ITEM_SUCCESS';
export const REMOVE_MEDIA_ITEM_ERROR = 'REMOVE_MEDIA_ITEM_ERROR';

export const EDIT_MEDIA_ITEM_REQUEST = 'EDIT_MEDIA_ITEM_REQUEST';
export const EDIT_MEDIA_ITEM_SUCCESS = 'EDIT_MEDIA_ITEM_SUCCESS';
export const EDIT_MEDIA_ITEM_ERROR = 'EDIT_MEDIA_ITEM_ERROR';

export const SETUP_PLAYER = 'SETUP_PLAYER_REQUEST';
export const SETUP_PLAYER_SUCCESS = 'SETUP_PLAYER_SUCCESS';

export const SET_MARK_IN = 'SET_MARK_IN';
export const SET_MARK_OUT = 'SET_MARK_OUT';
export const SET_MARK_IN_SUCCESS = 'SET_MARK_IN_SUCCESS';
export const SET_MARK_OUT_SUCCESS = 'SET_MARK_OUT_SUCCESS';
export const CLEAR_MARKERS = 'CLEAR_MARKERS';
export const DESTROY_PLAYER = 'DESTROY_PLAYER';
export const DESTROY_PLAYER_SUCCESS = 'DESTROY_PLAYER_SUCCESS';

export const MOVE_MEDIA_ITEM_REQUEST = 'MOVE_MEDIA_ITEM_REQUEST';
export const MOVE_MEDIA_ITEM_SUCCESS = 'MOVE_MEDIA_ITEM_SUCCESS';
export const MOVE_MEDIA_ITEM_ERROR = 'MOVE_MEDIA_ITEM_ERROR';

export const APPROVE_MEDIA_ITEM = 'APPROVE_MEDIA_ITEM';
export const APPROVE_MEDIA_ITEM_SUCCESS = 'APPROVE_MEDIA_ITEM_SUCCESS';

export const APPROVE_MEDIA_ITEM_REQUEST = 'APPROVE_MEDIA_ITEM_REQUEST';
export const APPROVE_MEDIA_ITEM_ERROR = 'APPROVE_MEDIA_ITEM_ERROR';

export const GET_MEDIA_LANGUAGE = 'GET_MEDIA_LANGUAGE';
export const GET_MEDIA_LANGUAGE_REQUEST = 'GET_MEDIA_LANGUAGE_REQUEST';
export const GET_MEDIA_LANGUAGE_SUCCESS = 'GET_MEDIA_LANGUAGE_SUCCESS';
export const GET_MEDIA_LANGUAGE_ERROR = 'GET_MEDIA_LANGUAGE_ERROR';

export const LOCKED_MEDIA_REQUEST = 'LOCKED_MEDIA_REQUEST';
export const LOCKED_MEDIA_SUCCESS = 'LOCKED_MEDIA_SUCCESS';
export const LOCKED_MEDIA_ERROR = 'LOCKED_MEDIA_ERROR';

export const GET_PERSON_LIST = 'GET_PERSON_LIST';
export const GET_PERSON_LIST_REQUEST = 'GET_PERSON_LIST_REQUEST';
export const GET_PERSON_LIST_SUCCESS = 'GET_PERSON_LIST_SUCCESS';
export const GET_ROLE_LIST = 'GET_ROLE_LIST';
export const GET_ROLE_LIST_REQUEST = 'GET_ROLE_LIST_REQUEST';
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_SUCCESS';

export const GET_MEDIA_ITEM_ASSETS_REQUEST = 'GET_MEDIA_ITEM_ASSETS_REQUEST';
export const GET_MEDIA_ITEM_ASSETS_SUCCESS = 'GET_MEDIA_ITEM_ASSETS_SUCCESS';
export const GET_MEDIA_ITEM_ASSETS_ERROR = 'GET_MEDIA_ITEM_ASSETS_ERROR';

export const CLEAR_ASSET_LIST = 'CLEAR_ASSET_LIST';
