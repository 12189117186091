import {
  GET_MEDIA_TAGS,
  GET_MEDIA_TAGS_REQUEST,
  GET_MEDIA_TAGS_SUCCESS,
  GET_MEDIA_TAGS_ERROR,
  ADD_MEDIA_TAG,
  ADD_MEDIA_TAG_REQUEST,
  ADD_MEDIA_TAG_SUCCESS,
  ADD_MEDIA_TAG_ERROR,
  GET_SEARCH_TAGS_REQUEST,
  GET_SEARCH_TAGS_SUCCESS,
  GET_SEARCH_TAGS_ERROR,
  ADD_SEARCH_TAGS_REQUEST,
  ADD_SEARCH_TAGS_SUCCESS,
  ADD_SEARCH_TAGS_ERROR,
} from '../../actions/media/mediaTag';
import apiCall from '../../../utils/api/api';

const state = {
  isLoading: false,
  mediaTags: {},
  searchTags: {},
};

const actions = {
  [GET_MEDIA_TAGS]: ({ commit }, { accountId, filterValue }) => new Promise((resolve) => {
    commit(GET_MEDIA_TAGS_REQUEST);

    const url = `${accountId}/media-collection/workflow-tag/list?p=1&l=10000&filterField=mt.name&filterValue=${filterValue}&s=mt.name&d=DESC`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_MEDIA_TAGS_SUCCESS, resp.data);
        resolve(resp.data);
      }).catch(() => {
        commit(GET_MEDIA_TAGS_ERROR);
      });
  }),
  [ADD_MEDIA_TAG]: ({ commit }, { accountId, name }) => new Promise((resolve) => {
    commit(ADD_MEDIA_TAG_REQUEST);

    const url = `${accountId}/media-collection/workflow-tag`;

    apiCall.post(url, { name })
      .then((res) => {
        commit(ADD_MEDIA_TAG_SUCCESS);
        resolve(res.data);
      }).catch(() => {
        commit(ADD_MEDIA_TAG_ERROR);
      });
  }),
  [GET_SEARCH_TAGS_REQUEST]: ({ commit }, { accountId, filterValue }) => new Promise((resolve) => {
    commit(GET_SEARCH_TAGS_REQUEST);

    const url = `${accountId}/media-collection/search-tag/list?p=1&l=10000&filterField=mt.name&filterValue=${filterValue}&s=mt.name&d=DESC`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_SEARCH_TAGS_SUCCESS, resp.data);
        resolve(resp.data);
      }).catch(() => {
        commit(GET_SEARCH_TAGS_ERROR);
      });
  }),
  [ADD_SEARCH_TAGS_REQUEST]: ({ commit }, { accountId, name }) => new Promise((resolve) => {
    commit(ADD_SEARCH_TAGS_REQUEST);

    const url = `${accountId}/media-collection/search-tag`;

    apiCall.post(url, { name })
      .then((res) => {
        commit(ADD_SEARCH_TAGS_SUCCESS);
        resolve(res.data);
      }).catch(() => {
        commit(ADD_SEARCH_TAGS_ERROR);
      });
  }),
};

const mutations = {
  [GET_MEDIA_TAGS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_MEDIA_TAGS_SUCCESS]: (stateLocal, payload) => {
    state.mediaTags = payload.data;
    state.isLoading = false;
  },
  [GET_MEDIA_TAGS_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_MEDIA_TAG_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_MEDIA_TAG_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_MEDIA_TAG_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_SEARCH_TAGS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_SEARCH_TAGS_SUCCESS]: (stateLocal, payload) => {
    state.searchTags = payload.data;
    state.isLoading = false;
  },
  [GET_SEARCH_TAGS_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_SEARCH_TAGS_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_SEARCH_TAGS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_SEARCH_TAGS_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
