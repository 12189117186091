export const SET_GENRE_STORE_PROPERTY = 'SET_GENRE_STORE_PROPERTY';

export const GET_GENRES_REQUEST = 'GET_GENRES_REQUEST';
export const GET_GENRES_SUCCESS = 'GET_GENRES_SUCCESS';
export const GET_GENRES_ERROR = 'GET_GENRES_ERROR';

export const GET_GENRES_FOR_CHOICE_REQUEST = 'GET_GENRES_FOR_CHOICE_REQUEST';
export const GET_GENRES_FOR_CHOICE_SUCCESS = 'GET_GENRES_FOR_CHOICE_SUCCESS';
export const GET_GENRES_FOR_CHOICE_ERROR = 'GET_GENRES_FOR_CHOICE_ERROR';

export const ADD_GENRE_REQUEST = 'ADD_GENRE_REQUEST';
export const ADD_GENRE_SUCCESS = 'ADD_GENRE_SUCCESS';
export const ADD_GENRE_ERROR = 'ADD_GENRE_ERROR';

export const GET_GENRE_IN_USE_STATUS_REQUEST = 'GET_GENRE_IN_USE_STATUS_REQUEST';
export const GET_GENRE_IN_USE_STATUS_SUCCESS = 'GET_GENRE_IN_USE_STATUS_SUCCESS';
export const GET_GENRE_IN_USE_STATUS_ERROR = 'GET_GENRE_IN_USE_STATUS_ERROR';

export const EDIT_GENRE_REQUEST = 'EDIT_GENRE_REQUEST';
export const EDIT_GENRE_SUCCESS = 'EDIT_GENRE_SUCCESS';
export const EDIT_GENRE_ERROR = 'EDIT_GENRE_ERROR';

export const DELETE_GENRE_REQUEST = 'DELETE_GENRE_REQUEST';
export const DELETE_GENRE_SUCCESS = 'DELETE_GENRE_SUCCESS';
export const DELETE_GENRE_ERROR = 'DELETE_GENRE_ERROR';

export const GET_GENRE_REQUEST = 'GET_GENRE_REQUEST';
export const GET_GENRE_SUCCESS = 'GET_GENRE_SUCCESS';
export const GET_GENRE_ERROR = 'GET_GENRE_ERROR';

export const CHANGE_GENRE_STATUS_REQUEST = 'CHANGE_GENRE_STATUS_REQUEST';
export const CHANGE_GENRE_STATUS_SUCCESS = 'CHANGE_GENRE_STATUS_SUCCESS';
export const CHANGE_GENRE_STATUS_ERROR = 'CHANGE_GENRE_STATUS_ERROR';
