<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      v-if="isHasId"
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="edit-button-wrapper">
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backTo"
        />
        <Button
          v-if="!isEdit"
          :title="'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="breadcrumbs">
        <span class="system">
          System Management / Servers
        </span>
        <span v-if="!isEdit">
          / Add Server
        </span>
        <span
          v-else
        >
          / Edit {{ formData.name }}
        </span>
      </div>
      <div class="contact-info">
        <div class="title">
          {{ getFormTitle }}
        </div>
        <div>
          <MaterialInput
            v-model="formData.name"
            v-bind="formFields.name"
            :disabled="isViewOnly"
          />
        </div>
        <div>
          <MaterialInput
            v-model="formData.username"
            v-bind="formFields.username"
            :disabled="isViewOnly"
          />
        </div>
        <div>
          <MaterialInput
            v-model="formData.password"
            v-bind="formFields.password"
            :disabled="isViewOnly"
          />
        </div>
        <div>
          <MaterialInput
            v-model="formData.restUrl"
            v-bind="formFields.restUrl"
            :disabled="isViewOnly"
          />
        </div>
        <div>
          <MaterialInput
            v-model="formData.httpProviderUrl"
            v-bind="formFields.httpProviderUrl"
            :disabled="isViewOnly"
          />
        </div>
        <div>
          <MaterialInput
            v-model="formData.ingestUrl"
            v-bind="formFields.ingestUrl"
            :disabled="isViewOnly"
          />
        </div>
        <div>
          <MaterialInput
            v-model="formData.originHostname"
            v-bind="formFields.originHostname"
            :disabled="isViewOnly"
          />
        </div>
        <div>
          <MaterialInput
            v-model="formData.originHttpPort"
            v-bind="formFields.originHttpPort"
            :disabled="isViewOnly"
          />
        </div>
        <div>
          <MaterialInput
            v-model="formData.originHttpsPort"
            v-bind="formFields.originHttpsPort"
            :disabled="isViewOnly"
          />
        </div>
      </div>
      <NotificationServerErrorsModal
        v-model="isModalOpen"
        :modal="isModalOpen"
        :bodyMessages="serverErrors"
        :onClose="closeModal"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex';
import BaseForm from './BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import {
  ADD_SERVER,
  EDIT_SERVER,
  GET_SERVER,
} from '../../store/actions/servers/servers';
import CONFIG from '../../constants/config';
import Button from '../common/Button.vue';
import NotificationServerErrorsModal from '../NotificationServerErrorsModal.vue';

export default {
  name: 'WowzaServerForm',
  components: {
    MaterialInput,
    Button,
    NotificationServerErrorsModal,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'server',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHasId: true,
      isModalOpen: false,
      formData: {
        name: '',
        username: '',
        password: '',
        httpProviderUrl: '',
        restUrl: '',
        ingestUrl: '',
        originHostname: '',
        originHttpPort: '',
        originHttpsPort: '',
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        username: {
          type: 'text',
          name: 'username',
          autocomplete: 'new-password',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 64,
          },
        },
        password: {
          type: 'password',
          name: 'password',
          autocomplete: 'new-password',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 64,
          },
        },
        httpProviderUrl: {
          type: 'url',
          name: 'httpProviderUrl',
          labelUnderField: 'E.g. http://example.com:8086',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
            is_valid_url: true,
          },
        },
        restUrl: {
          type: 'url',
          name: 'restUrl',
          labelUnderField: 'E.g. http://example.com:8087/v2',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
            is_valid_url: true,
          },
        },
        ingestUrl: {
          type: 'url',
          name: 'ingestUrl',
          labelUnderField: 'E.g. rtmp://example.com:1935',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        originHostname: {
          type: 'text',
          name: 'originHostname',
          labelUnderField: 'E.g. example.com',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        originHttpPort: {
          type: 'text',
          name: 'originHttpPort',
          labelUnderField: 'E.g. 80',
          formPrefix: this.formPrefix,
          rules: {
            max: 5,
            required: true,
            numeric: true,
            max_value: 65535,
          },
        },
        originHttpsPort: {
          type: 'text',
          name: 'originHttpsPort',
          labelUnderField: 'E.g. 443',
          formPrefix: this.formPrefix,
          rules: {
            max: 5,
            required: true,
            numeric: true,
            max_value: 65535,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        username: [
          `${this.formPrefix}.username`,
        ],
        password: [
          `${this.formPrefix}.password`,
        ],
        httpProviderUrl: [
          `${this.formPrefix}.httpProviderUrl`,
        ],
        restUrl: [
          `${this.formPrefix}.restUrl`,
        ],
        ingestUrl: [
          `${this.formPrefix}.ingestUrl`,
        ],
        originHostname: [
          `${this.formPrefix}.originHostname`,
        ],
        originHttpPort: [
          `${this.formPrefix}.originHttpPort`,
        ],
        originHttpsPort: [
          `${this.formPrefix}.originHttpsPort`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_SERVER : ADD_SERVER,
      successMessage: this.isEdit
        ? CONFIG.successMessages.editServerMessage
        : CONFIG.successMessages.addServerMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      server: (state) => state.servers.server,
      isLoading: (state) => state.servers.isLoading,
      isViewOnly: (state) => state.servers.isViewOnly,
      serverErrors: (state) => state.servers.serverErrors,
    }),
    getFormTitle() {
      if (this.isViewOnly) {
        return 'View Server Info';
      }
      if (this.isEdit) {
        return 'Edit Server';
      }
      return 'Add Server';
    },
  },
  mounted() {
    if (this.isEdit && this.$route.params.id) {
      const {
        errorMessages,
        statuses,
      } = CONFIG;
      this.$store.dispatch(GET_SERVER, this.$route.params.id)
        .then((res) => this.initFormData(res))
        .catch((err) => {
          if (err.error === errorMessages.unknown) {
            this.isHasId = false;
            this.$router.push(`/${statuses.notFound}`);
          }
        });
    }
  },
  methods: {
    initFormData(response) {
      if (response.id) {
        this.formData.name = response.name;
        this.formData.username = response.username;
        this.formData.password = response.password;
        this.formData.httpProviderUrl = response.httpProviderUrl;
        this.formData.restUrl = response.restUrl;
        this.formData.ingestUrl = response.ingestUrl;
        this.formData.originHostname = response.originHostname;
        this.formData.originHttpPort = response.originHttpPort;
        this.formData.originHttpsPort = response.originHttpsPort;
      }
    },
    getRequestData() {
      return {
        id: this.$route.params.id,
        data: {
          name: this.formData.name,
          username: this.formData.username,
          password: this.formData.password,
          httpProviderUrl: this.formData.httpProviderUrl,
          restUrl: this.formData.restUrl,
          ingestUrl: this.formData.ingestUrl,
          originHostname: this.formData.originHostname,
          originHttpPort: this.formData.originHttpPort,
          originHttpsPort: this.formData.originHttpsPort,
        },
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.$router.push('/settings/servers');
      }, CONFIG.redirectTimeout);
    },
    closeModal() {
      this.isModalOpen = false;
    },
    onSubmitFailWowzaServer() {
      this.isModalOpen = true;
    },
    backTo() {
      this.$router.push('/settings/servers');
    },
  },
};
</script>

<style scoped>
  .inline-row {
    display: flex;
    justify-content: space-between;
  }

  .inline-block + .inline-block {
    margin-left: 25px;
  }

  .inline-block {
    width: 300px;
  }

  .edit-button-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 26px;
  }

  .breadcrumbs {
    margin-bottom: 25px;
  }

  .system {
    color: #4c5057;
  }
</style>
