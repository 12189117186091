<template>
  <div class="flex">
    <Loader :isVisible="isLoading" />
    <div
      v-if="hasResetToken"
      class="border"
    >
      <h3>Create Password</h3>
      <ResetPasswordForm
        v-if="!isSubmitted"
        :isSubmitted.sync="isSubmitted"
        :buttonTitle="'Create password'"
      />
      <div
        v-else
        class="login"
      >
        <div class="text">
          Your password has been created successfully.
          You may log in to Broadcast Cloud
          using your email address and the password you have created.
          <div class="create-password-block">
            <div class="link">
              <router-link
                class="link-to"
                to="/login"
              >
                Back to Sign In
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="login login-error"
    >
      <div
        v-if="errorMessage"
        class="error-message border"
      >
        {{ errorMessage }}
        <div class="link">
          <router-link to="/login">
            Back to Sign In
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResetPassword from './ResetPassword.vue';

export default {
  name: 'CreatePassword',
  extends: ResetPassword,
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
  },
};
</script>
<style>
  .create-password-block {
    text-align: center;
  }
</style>
