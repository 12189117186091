import apiCall from '../../../utils/api/api';
import {
  ADD_LIVE_STREAM_REQUEST,
  ADD_LIVE_STREAM_SUCCESS,
  ADD_LIVE_STREAM_ERROR,
  EDIT_LIVE_STREAM_REQUEST,
  EDIT_LIVE_STREAM_SUCCESS,
  EDIT_LIVE_STREAM_ERROR,
  GET_FOLDERS_LIST_REQUEST,
  GET_FOLDERS_LIST_SUCCESS,
  GET_FOLDERS_LIST_ERROR,
  GET_FB_LINK,
  GET_FB_LINK_REQUEST,
  GET_FB_LINK_SUCCESS,
  GET_FB_LINK_ERROR,
  GET_FB_DATA_SSE,
  GET_FB_DATA_SSE_SUCCESS,
  FB_LINK_RESET,
  GET_FB_LOGOUT,
  SET_FB_LINK_WINDOW,
  ADD_DELIVERY_TARGET_REQUEST,
  ADD_DELIVERY_TARGET_SUCCESS,
  ADD_DELIVERY_TARGET_ERROR,
  EDIT_DELIVERY_TARGET_REQUEST,
  EDIT_DELIVERY_TARGET_SUCCESS,
  EDIT_DELIVERY_TARGET_ERROR,
  DELETE_DELIVERY_TARGET_REQUEST,
  DELETE_DELIVERY_TARGET_SUCCESS,
  DELETE_DELIVERY_TARGET_ERROR,
  EDIT_DELIVERY_TARGET_STATE_REQUEST,
  EDIT_DELIVERY_TARGET_STATE_SUCCESS,
  EDIT_DELIVERY_TARGET_STATE_ERROR,
  GET_FB_ACCESS_TOKEN_REQUEST,
  GET_FB_ACCESS_TOKEN_SUCCESS,
  GET_FB_ACCESS_TOKEN_ERROR,
  GET_FB_TARGET_REQUEST,
  GET_FB_TARGET_SUCCESS,
  GET_FB_TARGET_ERROR,
  RESET_FB_DATA_EDIT,
  FB_LINK_RESET_SUCCESS,
  RESET_FB_DATA_EDIT_SUCCESS,
  ACTIVE_FB_DATA_EDIT,
  ACTIVE_FB_DATA_EDIT_SUCCESS,
  CHANGE_DELIVERY_TARGET_REQUEST,
  CHANGE_DELIVERY_TARGET_SUCCESS,
} from '../../actions/liveEvent/liveEvent';
import sseCall from '../../../utils/api/sse';
import { EDIT_METADATA_ERROR } from '../../actions/media/mediaTree';
import { GET_MEDIA_ITEM_SUCCESS } from '../../actions/media/mediaItem';

const state = {
  isLoading: false,
  isImmediateLoading: false,
  live: {},
  folders: [],
  fbLink: null,
  fbData: null,
  fbLinkWindow: null,
  hasResetFb: false,
  isDeliveryChanged: false,
};

const getters = {
  fbDataComputed: () => state.fbData,
};

const actions = {
  [ADD_LIVE_STREAM_REQUEST]:
  ({ commit }, { accountId, mode, data }) => new Promise((resolve, reject) => {
    commit(ADD_LIVE_STREAM_REQUEST);

  	const url = mode === 'transcode'
      ? `${accountId}/media-collection/item/livestream`
      : `${accountId}/media-collection/item/livestream-url`;

    apiCall.post(url, data)
      .then((res) => {
        commit(ADD_LIVE_STREAM_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_LIVE_STREAM_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_LIVE_STREAM_REQUEST]:
  ({ commit }, { accountId, itemId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_LIVE_STREAM_REQUEST);

    apiCall.put(`${accountId}/media-collection/item/${itemId}/stream`, data)
      .then((res) => {
        commit(EDIT_LIVE_STREAM_SUCCESS, res.data);
        commit(GET_MEDIA_ITEM_SUCCESS, res.data);
        resolve();
      }).catch((err) => {
        const error = err.response.data;
        commit(EDIT_LIVE_STREAM_ERROR);
        reject(error);
      });
  }),
  [GET_FOLDERS_LIST_REQUEST]: ({ commit }, { accountId }) => new Promise((resolve) => {
    commit(GET_FOLDERS_LIST_REQUEST);

    const url = `choice/accounts/${accountId}/media-folders`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_FOLDERS_LIST_SUCCESS, resp.data.data);
        resolve(resp.data);
      }).catch(() => {
        commit(GET_FOLDERS_LIST_ERROR);
      });
  }),
  [GET_FB_LINK]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_FB_LINK_REQUEST);

    apiCall.post('/facebook-delivery-target/login-url')
      .then((res) => {
        commit(GET_FB_LINK_SUCCESS, res.data);
        // dispatch(GET_FB_ACCESS_TOKEN_REQUEST);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_FB_LINK_ERROR, error);
        reject(error);
      });
  }),
  [GET_FB_LOGOUT]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(GET_FB_LINK_REQUEST);

    apiCall.post('/facebook-delivery-target/logout-url', data)
      .then((res) => {
        commit(GET_FB_LINK_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_FB_LINK_ERROR, error);
        reject(error);
      });
  }),
  [GET_FB_ACCESS_TOKEN_REQUEST]: ({ commit }, fbCode) => new Promise((resolve, reject) => {
    commit(GET_FB_ACCESS_TOKEN_REQUEST);

    apiCall.post(`facebook-delivery-target/access-token/${fbCode}`)
      .then((res) => {
        commit(GET_FB_ACCESS_TOKEN_SUCCESS, res.data);
        localStorage.setItem('fb-Data', JSON.stringify(res.data));
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_FB_ACCESS_TOKEN_ERROR, error);
        reject(error);
      });
  }),
  [GET_FB_DATA_SSE]: ({ commit }) => new Promise(() => {
    let count = 0;
    function connect() {
      count += 1;
      sseCall.getEventSource(['/facebook/data'])
        .then((eventSource) => {
          eventSource.onmessage = (event) => {
            commit(GET_FB_DATA_SSE_SUCCESS, JSON.parse(event.data));
            eventSource.close();
          };

          eventSource.onerror = () => {
            if (count <= 20) {
              window.setTimeout(() => {
                connect();
              }, 5000);
            } else {
              eventSource.close();
            }
          };
        });
    }

    connect();
  }),
  [FB_LINK_RESET]: ({ commit }) => new Promise(() => {
    commit(FB_LINK_RESET_SUCCESS);
  }),
  [RESET_FB_DATA_EDIT]: ({ commit }) => new Promise(() => {
    commit(RESET_FB_DATA_EDIT_SUCCESS);
  }),
  [ACTIVE_FB_DATA_EDIT]: ({ commit }) => new Promise(() => {
    commit(ACTIVE_FB_DATA_EDIT_SUCCESS);
  }),
  [CHANGE_DELIVERY_TARGET_REQUEST]: ({ commit }, value) => new Promise(() => {
    commit(CHANGE_DELIVERY_TARGET_SUCCESS, value);
  }),
  [SET_FB_LINK_WINDOW]: ({ commit }, data) => new Promise(() => {
    commit(SET_FB_LINK_WINDOW, data);
  }),
  [ADD_DELIVERY_TARGET_REQUEST]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(ADD_DELIVERY_TARGET_REQUEST);

    apiCall.post(`${accountId}/media-collection/delivery-target`, data)
      .then((res) => {
        commit(ADD_DELIVERY_TARGET_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_DELIVERY_TARGET_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_DELIVERY_TARGET_REQUEST]:
  ({ commit }, { accountId, deliveryTargetId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_DELIVERY_TARGET_REQUEST);

    apiCall.put(`${accountId}/media-collection/delivery-target/${deliveryTargetId}`, data)
      .then((res) => {
        commit(EDIT_DELIVERY_TARGET_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        error.status = err.response.status;
        commit(EDIT_DELIVERY_TARGET_ERROR, error);
        reject(error);
      });
  }),
  [DELETE_DELIVERY_TARGET_REQUEST]:
  ({ commit }, { accountId, deliveryTargetId }) => new Promise((resolve, reject) => {
    commit(DELETE_DELIVERY_TARGET_REQUEST);

    apiCall.delete(`${accountId}/media-collection/delivery-target/${deliveryTargetId}`)
      .then((res) => {
        commit(DELETE_DELIVERY_TARGET_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        error.status = err.response.status;
        commit(DELETE_DELIVERY_TARGET_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_DELIVERY_TARGET_STATE_REQUEST]:
  ({ commit }, { accountId, deliveryTargetId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_DELIVERY_TARGET_STATE_REQUEST);

    apiCall.put(`${accountId}/media-collection/delivery-target/${deliveryTargetId}/state`, data)
      .then((res) => {
        commit(EDIT_DELIVERY_TARGET_STATE_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        error.status = err.response.status;
        commit(EDIT_DELIVERY_TARGET_STATE_ERROR, error);
        reject(error);
      });
  }),
  [GET_FB_TARGET_REQUEST]:
    ({ commit }, { accountId, deliveryTargetId }) => new Promise((resolve, reject) => {
      commit(GET_FB_TARGET_REQUEST);

      apiCall.get(`choice/account/${accountId}/media-item-delivery-target/${deliveryTargetId}/facebook-data`)
        .then((res) => {
          commit(GET_FB_TARGET_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          const error = err.response.data;
          error.status = err.response.status;
          commit(GET_FB_TARGET_ERROR, error);
          reject(error);
        });
    }),
};

const mutations = {
  [ADD_LIVE_STREAM_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_LIVE_STREAM_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.live = payload;
  },
  [ADD_LIVE_STREAM_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_FOLDERS_LIST_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [GET_FOLDERS_LIST_SUCCESS]: (state, payload) => {
    state.folders = payload;
    state.isLoading = false;
  },
  [GET_FB_LINK_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_FB_LINK_SUCCESS]: (state, payload) => {
    state.fbLink = payload;
    state.isLoading = false;
  },
  [GET_FB_LINK_ERROR]: (state) => {
    state.isLoading = false;
  },
  [GET_FB_DATA_SSE_SUCCESS]: (state, payload) => {
    state.fbData = payload;
    state.isLoading = false;
  },
  [FB_LINK_RESET_SUCCESS]: (state) => {
    state.fbData = null;
    localStorage.removeItem('fb-Data');
  },
  [RESET_FB_DATA_EDIT_SUCCESS]: (state) => {
    state.hasResetFb = true;
  },
  [ACTIVE_FB_DATA_EDIT_SUCCESS]: (state) => {
    state.hasResetFb = false;
  },
  [SET_FB_LINK_WINDOW]: (state, payload) => {
    state.fbLinkWindow = payload;
  },
  [EDIT_LIVE_STREAM_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [EDIT_METADATA_ERROR]: (state) => {
    state.isLoading = false;
  },
  [EDIT_LIVE_STREAM_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.live = payload;
  },
  [EDIT_LIVE_STREAM_ERROR]: (state) => {
    state.isLoading = false;
  },
  [ADD_DELIVERY_TARGET_REQUEST]: () => {
    state.isImmediateLoading = true;
  },
  [ADD_DELIVERY_TARGET_SUCCESS]: () => {
    state.isImmediateLoading = false;
  },
  [ADD_DELIVERY_TARGET_ERROR]: () => {
    state.isImmediateLoading = false;
  },
  [EDIT_DELIVERY_TARGET_REQUEST]: () => {
    state.isImmediateLoading = true;
  },
  [EDIT_DELIVERY_TARGET_SUCCESS]: () => {
    state.isImmediateLoading = false;
  },
  [EDIT_DELIVERY_TARGET_ERROR]: () => {
    state.isImmediateLoading = false;
  },
  [DELETE_DELIVERY_TARGET_REQUEST]: () => {
    state.isImmediateLoading = true;
  },
  [DELETE_DELIVERY_TARGET_SUCCESS]: () => {
    state.isImmediateLoading = false;
  },
  [DELETE_DELIVERY_TARGET_ERROR]: () => {
    state.isImmediateLoading = false;
  },
  [EDIT_DELIVERY_TARGET_STATE_REQUEST]: () => {
    state.isImmediateLoading = true;
  },
  [EDIT_DELIVERY_TARGET_STATE_SUCCESS]: () => {
    state.isImmediateLoading = false;
  },
  [EDIT_DELIVERY_TARGET_STATE_ERROR]: () => {
    state.isImmediateLoading = false;
  },
  [GET_FB_ACCESS_TOKEN_SUCCESS]: (state, payload) => {
    state.fbData = payload;
  },
  [GET_FB_TARGET_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [GET_FB_TARGET_SUCCESS]: (state) => {
    state.isLoading = false;
  },
  [GET_FB_TARGET_ERROR]: (state) => {
    state.isLoading = false;
  },
  [CHANGE_DELIVERY_TARGET_SUCCESS]: (state, payload) => {
    state.isDeliveryChanged = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
