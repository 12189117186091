export const GET_MEDIA_LIST = 'GET_MEDIA_LIST';
export const GET_MEDIA_LIST_REQUEST = 'GET_MEDIA_LIST_REQUEST';
export const GET_MEDIA_LIST_SUCCESS = 'GET_MEDIA_LIST_SUCCESS';
export const GET_MEDIA_LIST_ERROR = 'GET_MEDIA_LIST_ERROR';
export const SET_CANCEL_TOKEN = 'SET_CANCEL_TOKEN';

export const GET_TRANSCODING_PROGRESS_SSE_REQUEST = 'GET_TRANSCODING_PROGRESS_SSE_REQUEST';
export const GET_TRANSCODING_PROGRESS_SSE_SUCCESS = 'GET_TRANSCODING_PROGRESS_SSE_SUCCESS';
export const GET_TRANSCODING_PROGRESS_SSE_CLOSED = 'GET_TRANSCODING_PROGRESS_SSE_CLOSED';

export const UPLOAD_XML_REQUEST = 'UPLOAD_XML_REQUEST';
export const UPLOAD_XML_SUCCESS = 'UPLOAD_XML_SUCCESS';
export const UPLOAD_XML_ERROR = 'UPLOAD_XML_ERROR';

export const UPLOAD_AS_BASE64_REQUEST = 'UPLOAD_AS_BASE64_REQUEST';
