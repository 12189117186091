export const GET_MEDIA_TAGS = 'GET_MEDIA_TAGS';
export const GET_MEDIA_TAGS_REQUEST = 'GET_MEDIA_TAGS_REQUEST';
export const GET_MEDIA_TAGS_SUCCESS = 'GET_MEDIA_TAGS_SUCCESS';
export const GET_MEDIA_TAGS_ERROR = 'GET_MEDIA_TAGS_ERROR';

export const ADD_MEDIA_TAG = 'ADD_MEDIA_TAG';
export const ADD_MEDIA_TAG_REQUEST = 'ADD_MEDIA_TAG_REQUEST';
export const ADD_MEDIA_TAG_SUCCESS = 'ADD_MEDIA_TAG_SUCCESS';
export const ADD_MEDIA_TAG_ERROR = 'ADD_MEDIA_TAG_ERROR';

export const GET_SEARCH_TAGS_REQUEST = 'GET_SEARCH_TAGS_REQUEST';
export const GET_SEARCH_TAGS_SUCCESS = 'GET_SEARCH_TAGS_SUCCESS';
export const GET_SEARCH_TAGS_ERROR = 'GET_SEARCH_TAGS_ERROR';

export const ADD_SEARCH_TAGS_REQUEST = 'ADD_SEARCH_TAGS_REQUEST';
export const ADD_SEARCH_TAGS_SUCCESS = 'ADD_SEARCH_TAGS_SUCCESS';
export const ADD_SEARCH_TAGS_ERROR = 'ADD_SEARCH_TAGS_ERROR';
