import apiCall from '../../../utils/api/api';
import {
  GET_MEDIA_ITEMS_BY_TYPES_REQUEST,
  GET_MEDIA_ITEMS_BY_TYPES_SUCCESS,
  GET_MEDIA_ITEMS_BY_TYPES_ERROR,
} from '../../actions/media/mediaItems';

const state = {
  isLoading: false,
};

const actions = {
  [GET_MEDIA_ITEMS_BY_TYPES_REQUEST]:
  ({ commit }, { accountId, folderId, contentTypes }) => new Promise((resolve, reject) => {
    commit(GET_MEDIA_ITEMS_BY_TYPES_REQUEST);

    let dynamicParams = '';

    if (contentTypes) {
      contentTypes.forEach((tag, index) => {
        if (index === 0) {
          dynamicParams += `contentTypes[]=${tag}`;
          return;
        }

        dynamicParams += `&contentTypes[]=${tag}`;
      });
    }

    const url = `/choice/accounts/${accountId}/media-folders/${folderId}/media-items?${dynamicParams}`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_MEDIA_ITEMS_BY_TYPES_SUCCESS, resp.data.data);
        resolve(resp.data.data);
      }).catch((error) => {
        commit(GET_MEDIA_ITEMS_BY_TYPES_ERROR);
        reject(error);
      });
  }),
};

const mutations = {
  [GET_MEDIA_ITEMS_BY_TYPES_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_MEDIA_ITEMS_BY_TYPES_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_MEDIA_ITEMS_BY_TYPES_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
