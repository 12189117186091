export const GET_SCHEDULE = 'GET_SCHEDULE';
export const GET_SCHEDULE_REQUEST = 'GET_SCHEDULE_REQUEST';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_ERROR = 'GET_SCHEDULE_ERROR';

export const GET_SCHEDULE_INTERVAL = 'GET_SCHEDULE_INTERVAL';
export const GET_SCHEDULE_INTERVAL_REQUEST = 'GET_SCHEDULE_INTERVAL_REQUEST';
export const GET_SCHEDULE_INTERVAL_SUCCESS = 'GET_SCHEDULE_INTERVAL_SUCCESS';
export const GET_SCHEDULE_INTERVAL_ERROR = 'GET_SCHEDULE_INTERVAL_ERROR';

export const EDIT_SCHEDULE_INTERVAL = 'EDIT_SCHEDULE_INTERVAL';
export const EDIT_SCHEDULE_INTERVAL_REQUEST = 'EDIT_SCHEDULE_INTERVAL_REQUEST';
export const EDIT_SCHEDULE_INTERVAL_SUCCESS = 'EDIT_SCHEDULE_INTERVAL_SUCCESS';
export const EDIT_SCHEDULE_INTERVAL_ERROR = 'EDIT_SCHEDULE_INTERVAL_ERROR';

export const PUBLISH_SCHEDULE_SSE = 'PUBLISH_SCHEDULE_SSE';
export const PUBLISH_SCHEDULE_SSE_SUCCESS = 'PUBLISH_SCHEDULE_SSE_SUCCESS';

export const EDIT_SCHEDULE_SETTINGS = 'EDIT_SCHEDULE_SETTINGS';
export const EDIT_SCHEDULE_SETTINGS_REQUEST = 'EDIT_SCHEDULE_SETTINGS_REQUEST';
export const EDIT_SCHEDULE_SETTINGS_SUCCESS = 'EDIT_SCHEDULE_SETTINGS_SUCCESS';
export const EDIT_SCHEDULE_SETTINGS_ERROR = 'EDIT_SCHEDULE_SETTINGS_ERROR';

export const GET_NEW_SCHEDULE_ITEM_REQUEST = 'GET_NEW_SCHEDULE_ITEM_REQUEST';
export const GET_NEW_SCHEDULE_ITEM_SUCCESS = 'GET_NEW_SCHEDULE_ITEM_SUCCESS';
export const GET_NEW_SCHEDULE_ITEM_ERROR = 'GET_NEW_SCHEDULE_ITEM_ERROR';
