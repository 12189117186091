<template>
  <div>
    <ValidationObserver
      ref="validationObserverRTMP"
      v-slot="{ handleSubmit }"
    >
      <form
        novalidate="novalidate"
        @submit.prevent="handleSubmit(submit)"
        @input="handleFormChange"
      >
        <MaterialInput
          v-model="formData.streamKey"
          v-bind="formFields.streamKey"
        />
        <MaterialInput
          v-model="formData.streamUrl"
          v-bind="formFields.streamUrl"
        />
        <div class="button-wrapper">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="onCancelHandle"
          />
          <Button
            :title="editDeliveryTarget ? 'Apply' : 'Add'"
            type="submit"
            :classType="'primary'"
          />
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import CONFIG from '../../../constants/config';
import Button from '../../common/Button.vue';

export default {
  name: 'YoutubeRTMPForm',
  components: {
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'addDeliveryTarget',
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    editDeliveryTarget: {
      type: Object,
      default: null,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    itemErrors: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      validationObserver: 'validationObserverRTMP',
      formData: {
        streamKey: this.getValueDeliveryFromTarget('streamName'),
        streamUrl: this.getStreamUrl(),
      },
      formFields: {
        streamKey: {
          type: 'text',
          name: 'streamKey',
          formPrefix: this.formPrefix,
          rules: {
            max: 500,
            required: true,
          },
        },
        streamUrl: {
          type: 'text',
          name: 'streamUrl',
          formPrefix: this.formPrefix,
          rules: {
            max: 255,
            required: true,
          },
        },
      },
      errorMapping: {
        streamKey: [
          `${this.formPrefix}.streamName`,
        ],
        streamUrl: [
          `${this.formPrefix}.appName`,
          `${this.formPrefix}.host`,
          `${this.formPrefix}.port`,
          `${this.formPrefix}.username`,
          `${this.formPrefix}.password`,
          `${this.formPrefix}.accountSchedule`,
          `${this.formPrefix}.mediaItem`,
        ],
      },
      successMessage: CONFIG.successMessages.addMediaFolderMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  mounted() {
    const formErrors = this.getServerFormErrors({ form_errors: this.itemErrors });
    this.$refs[this.validationObserver].setErrors(formErrors);
  },
  methods: {
    getStreamUrl() {
      const appName = this.getValueDeliveryFromTarget('appName');
      const host = this.getValueDeliveryFromTarget('host');

      if (appName && host) {
        return `rtmp://${host}/${appName}`;
      }

      return '';
    },
    getValueDeliveryFromTarget(key, defaultValue = '') {
      return this.editDeliveryTarget && this.editDeliveryTarget[key]
        ? this.editDeliveryTarget[key]
        : defaultValue;
    },
    submit() {
      const {
        streamUrl,
        streamKey,
      } = this.formData;

      let host = '';
      let appName = '';

      if (streamUrl) {
        const urlParts = streamUrl.split('://');
        let url = streamUrl;

        if (urlParts.length > 1) {
          url = urlParts[urlParts.length - 1];
        }

        host = url;
        appName = '';
        const pathParts = url.split('/');

        if (pathParts.length > 1) {
          appName = pathParts[pathParts.length - 1];
          host = pathParts.slice(0, -1).join('/');
        }
      }

      const data = {
        streamName: streamKey,
        appName,
        host,
        port: '1935',
        id: this.getValueDeliveryFromTarget('id'),
      };

      this.onSubmit(data);
    },
    onCancelHandle() {
      this.onCancel();
    },
  },
};
</script>
<style scoped>
  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }

  hr {
    border: 0 solid rgba(0, 0, 0, 0.12);
    border-top-width: thin;
    display: block;
    flex: 1 1 0;
    height: 0;
    max-height: 0;
    max-width: 100%;
    transition: inherit;
  }
</style>
