import apiCall from '../../../utils/api/api';
import {
  GET_TIMEZONE_ERROR,
  GET_TIMEZONE_REQUEST,
  GET_TIMEZONE_SUCCESS,
  GET_ALL_TIMEZONE_REQUEST,
  GET_ALL_TIMEZONE_SUCCESS,
  GET_ALL_TIMEZONE_ERROR,
} from '../../actions/timezoneActions/timezone';

const state = {
  isLoading: false,
  times: [],
  timezones: [],
};

const actions = {
  [GET_TIMEZONE_REQUEST]: ({ commit }) => new Promise(() => {
    commit(GET_TIMEZONE_REQUEST);

    const url = 'choice/timezone';

    apiCall.get(url)
      .then((resp) => {
        commit(GET_TIMEZONE_SUCCESS, resp.data.data);
      }).catch(() => {
        commit(GET_TIMEZONE_ERROR);
      });
  }),
  [GET_ALL_TIMEZONE_REQUEST]: ({ commit }) => new Promise(() => {
    commit(GET_ALL_TIMEZONE_REQUEST);

    const url = 'choice/all-timezone';

    apiCall.get(url)
      .then((resp) => {
        commit(GET_ALL_TIMEZONE_SUCCESS, resp.data.data);
      }).catch(() => {
        commit(GET_ALL_TIMEZONE_ERROR);
      });
  }),
};

const mutations = {
  [GET_TIMEZONE_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_TIMEZONE_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.times = payload;
  },
  [GET_TIMEZONE_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ALL_TIMEZONE_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_ALL_TIMEZONE_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.timezones = payload;
  },
  [GET_ALL_TIMEZONE_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
