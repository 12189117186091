<template>
  <div>
    <div class="row-person-role">
      <MaterialSelect
        v-model="contributorRole.id"
        :options="roleList"
        :withEmptyField="true"
        :className="'inspector-role-select'"
        :handleChange="handleChangeRole"
        v-bind="formFields.contributorRole"
        :disabled="disabled"
      />
      <MaterialSelect
        v-model="contributorPerson.id"
        :options="personList"
        :withEmptyField="true"
        :handleChange="handleChangePerson"
        :className="'inspector-role-select'"
        v-bind="formFields.contributorPerson"
        :disabled="disabled"
      />
      <div
        v-if="!isRead"
        class="trash-btn"
      >
        <Button
          :title="''"
          :classType="'primary'"
          :eventFunction="handleRemoveItem"
          :srcSvg="iconTrash"
          :smallSize="true"
          :className="'small-icon icon-role'"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import Button from '../../common/Button.vue';
import iconTrash from '../../../assets/icons/icon-remove.svg';

export default {
  name: 'PeopleAndRoles',
  components: {
    MaterialSelect,
    Button,
  },
  props: {
    formPrefix: {
      type: String,
      default: null,
    },
    contributorRole: {
      type: Object,
      default: null,
    },
    contributorPerson: {
      type: Object,
      default: null,
    },
    roleList: {
      type: Array,
      default: null,
    },
    personList: {
      type: Array,
      default: () => [],
    },
    onRemoveItem: {
      type: Function,
      default: () => {},
    },
    onChangeRole: {
      type: Function,
      default: () => {},
    },
    onChangePerson: {
      type: Function,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconTrash,
      formFields: {
        contributorRole: {
          type: 'text',
          name: `mediaItemContributors.${this.index}.contributorRole`,
          label: 'Role',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        contributorPerson: {
          type: 'text',
          name: `mediaItemContributors.${this.index}.contributorPerson`,
          label: 'Person',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'isRead',
    ]),
  },
  methods: {
    handleChangeRole(val) {
      const index = val.target.selectedIndex;
      this.onChangeRole(this.roleList[index - 1].code, this.index);
    },
    handleChangePerson(val) {
      const index = val.target.selectedIndex;
      this.onChangePerson(this.personList[index - 1].code, this.index);
    },
    handleRemoveItem() {
      this.onRemoveItem(this.index);
    },
  },
};
</script>
<style scoped>
  .trash-btn {
    margin-top: 25px;
  }

  .row-person-role {
    display: flex;
    justify-content: space-between;
  }
</style>
