<template>
  <div class="list-wrapper">
    <div class="btn-feed-mobile">
      <Button
        :title="'Add Feed'"
        :classType="'primary'"
        :eventFunction="toCreateFeedHandler"
      />
    </div>
    <div class="flex">
      <div class="left-path">
        <span class="system">
          Collections
        </span>
        <span>
          / Feeds
        </span>
      </div>
      <div class="right-path">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="255"
        />
        <div class="btn-feed-full">
          <Button
            :title="'Add Feed'"
            :classType="'primary'"
            :eventFunction="toCreateFeedHandler"
          />
        </div>
      </div>
    </div>
    <div class="accounts-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`f.${sortedField.name}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          feed name
        </div>
        <div class="th-name company-id">
          feed id
        </div>
        <div class="th-name users-count">
          feed length
        </div>
        <div class="th-name status">
          status
        </div>
        <div class="icons-name"></div>
      </div>
      <div
        v-if="!feeds.length"
        class="empty-search"
      >
        No Feeds to display
      </div>
      <div
        v-for="(item, key) in feeds"
        :key="item.id"
        class="account"
        @mouseover="onHoverElem(item)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div
          class="td company"
          :title="item.name"
        >
          {{ item.name }}
        </div>
        <div
          class="td company-id"
          :title="item.uniqueId"
        >
          {{ item.uniqueId }}
        </div>
        <div class="td users-count">
          {{ item.feedLength }}
        </div>
        <div class="td status">
          <div
            class="status-ellipse"
            :style="item.enabled ? {background: '#1fee65'} : {background: '#fb3951'}"
          >
          </div>
          <span v-if="item.enabled">
            Enabled
          </span>
          <span v-else>
            Disabled
          </span>
        </div>
        <div class="feed-wrapper-icons">
          <div
            v-show="isHover && item.id === selectedId"
            :class="['icons-wrapper', (isHover && (item.id === selectedId))
              ? ''
              : 'icons-wrapper-hide'
            ]"
          >
            <div
              class="icon-edit"
              title="Embed"
            >
              <div
                :title="generateFeedWidget"
                @click="showWidget(item)"
              >
                <inline-svg
                  :src="iconCode"
                  class="svg-icon"
                >
                </inline-svg>
              </div>
            </div>
            <div class="icon-edit">
              <router-link
                :to="{ path: `feeds/${item.id}` }"
                :title="editFeed"
              >
                <inline-svg
                  :src="iconEdit"
                  class="svg-icon"
                >
                </inline-svg>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :totalPages="totalPages"
    />
    <WidgetModal
      v-if="showModal"
      :modalData="modalData"
      :onClose="onClose"
      :isFeed="true"
      :getFeedsList="getFeedsList"
    />
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import { GET_ALL_FEEDS_REQUEST } from '../../store/actions/feedsActions/feedsActions';
import { GET_WIDGET } from '../../store/actions/widgets/widget';
import CONFIG from '../../constants/config';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconCode from '../../assets/icons/icon-code.svg';

import Button from '../../components/common/Button.vue';
import Sorting from '../../components/common/Sorting.vue';
import Pagination from '../../components/common/Pagination.vue';
import LiveSearch from '../../components/common/LiveSearch.vue';
import WidgetModal from '../../components/common/WidgetModal.vue';

export default {
  name: 'FeedsList',
  components: {
    WidgetModal,
    Button,
    Sorting,
    Pagination,
    LiveSearch,
  },
  data() {
    return {
      generateFeedWidget: CONFIG.tooltips.generateFeedWidget,
      editFeed: CONFIG.tooltips.editFeed,
      enabledId: null,
      isEnabled: false,
      isModalOpen: false,
      isHover: false,
      selectedId: null,
      bodyMessage: '',
      filter: '',
      sortedField: {
        name: 'name',
      },
      currentSortedField: 'f.name',
      currentSortBy: 'ASC',
      isSortedUp: false,
      isSortedDown: false,
      iconEdit,
      iconCode,
      modalData: {},
      showModal: false,
    };
  },
  computed: {
    ...mapState({
      feeds: (state) => state.feeds.feeds,
      pagination: (state) => state.feeds.pagination,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    const params = {
      accountId: this.getCurrentAccountId,
      page: localStorage.getItem('feedsPage') || 1,
      filterValue: '',
    };
    this.$store.dispatch(GET_ALL_FEEDS_REQUEST, params);
  },
  methods: {
    getFeedsList() {
      const params = {
        accountId: this.getCurrentAccountId,
        page: localStorage.getItem('feedsPage') || 1,
        filterValue: '',
      };
      this.$store.dispatch(GET_ALL_FEEDS_REQUEST, params);
    },
    toCreateFeedHandler() {
      this.$router.push('feeds/add');
    },
    handleSortUp(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_FEEDS_REQUEST, params);
    },
    handleSortDown(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_FEEDS_REQUEST, params);
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        accountId: this.getCurrentAccountId,
        page: params.page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ALL_FEEDS_REQUEST, getParams);
    },
    getPaginationAction(page) {
      const params = {
        accountId: this.getCurrentAccountId,
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ALL_FEEDS_REQUEST, params);
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    showWidget(item) {
      if (item.widget) {
        this.openModal(item.widget);
      } else {
        this.generateWidget({
          accountId: this.getCurrentAccountId,
          data: {
            feed: item.id,
          },
        }, item);
      }
    },
    generateWidget(data, item) {
      this.$store.dispatch(GET_WIDGET, data)
        .then((res) => {
          item.widget = res;
          this.openModal(res);
        });
    },
    openModal(data) {
      this.modalData = data;
      this.showModal = true;
    },
    onClose() {
      this.showModal = false;
      this.modalData = {};
    },
  },
};
</script>

<style scoped>
  .list-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 25px 25px 25px 30px;
    width: 100%;
  }

  .add-feed {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
  }

  .accounts-wrapper {
    border: 1px solid #43434d;
    height: 89%;
    overflow-y: auto;
  }

  .ac-wrapper-header {
    background: #282a2e;
    display: flex;
    padding: 13px 0;
  }

  .th-name {
    align-items: center;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
  }

  .td {
    font-size: 14px;
    padding: 15px 0;
    word-break: break-word;
  }

  .number {
    padding-left: 21px;
    width: 67px;
  }

  .company {
    width: 20%;
  }

  .company-id {
    padding-left: 15px;
    width: 38%;
  }

  .users-count {
    margin-left: 30px;
    text-align: left;
    width: 10%;
  }

  .status {
    align-items: flex-start;
    display: flex;
    margin-left: 30px;
    text-align: center;
    width: 10%;
  }

  .icons-name {
    width: 120px;
  }

  .account {
    display: flex;
  }

  .account:hover {
    background: #383b40;
  }

  .status-ellipse {
    border-radius: 50%;
    height: 5px;
    margin-right: 6px;
    margin-top: 6px;
    width: 5px;
  }

  .icons-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 0.95;
    justify-content: flex-end;
    min-width: 100px;
    padding: 9px;
    padding-left: 40px;
  }

  .feed-wrapper-icons {
    margin-right: 5px;
    min-width: 120px;
  }

  .svg-icon {
    cursor: pointer;
  }

  .icon-edit {
    margin-left: 31px;
  }

  .create-button {
    background: #2591cf;
    color: #fff;
    font-size: 16px;
    letter-spacing: normal;
    padding: 0 16px;
    text-transform: none;
  }

  .right-path {
    align-items: center;
    display: flex;
  }

  .empty-search {
    padding-top: 40px;
    text-align: center;
  }

  .icons-wrapper-hide {
    visibility: hidden;
  }

  .btn-feed-mobile {
    display: none;
  }

  .flex {
    align-items: center;
    display: flex;
    flex: 0;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 40px;
  }

  .right-path {
    align-items: center;
    display: flex;
  }

  .system {
    color: #4c5057;
  }

  @media all and (max-width: 1850px) {
    .users-count {
      width: 7%;
    }

    .status {
      width: 7%;
    }
  }

  @media all and (max-width: 1600px) {
    .company-id {
      width: 29%;
    }
  }

  @media all and (max-width: 1024px) {
    .btn-feed-mobile {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
      min-height: 40px;
    }

    .btn-feed-full {
      display: none;
    }

    .search-wrapper {
      margin-right: 0;
    }
  }
</style>
