import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user/user';
import auth from './modules/auth/auth';
import anonimAuth from './modules/auth/anonimAuth';
import common from './modules/common/common';
import accounts from './modules/accounts/accounts';
import support from './modules/support/support';
import mediaTree from './modules/media/mediaTree';
import mediaList from './modules/media/mediaList';
import mediaItem from './modules/media/mediaItem';
import mediaItems from './modules/media/mediaItems';
import mediaAsset from './modules/media/mediaAsset';
import upload from './modules/upload/upload';
import liveStream from './modules/liveStream/liveStream';
import mediaTag from './modules/media/mediaTag';
import timezone from './modules/timezone/timezone';
import feeds from './modules/feeds/feeds';
import people from './modules/people/people';
import widgets from './modules/widgets/widgets';
import roles from './modules/roles/roles';
import billing from './modules/billing/billing';
import analytic from './modules/analytic/analytic';
import schedule from './modules/schedule/schedule';
import fields from './modules/customField/fields';
import servers from './modules/servers/servers';
import events from './modules/events/events';
import eventGroups from './modules/events/eventGroups';
import localization from './modules/localization/localization';
import cms from './modules/cms/cms';
import brushfire from "./modules/brushfire/brushfire";
import assetRoles from "./modules/assetRoles/assetRoles";
import apiKeys from "./modules/api_keys/api_keys";
import playlist from './modules/playlist/playlist';
import series from './modules/series/series';
import seasons from "./modules/seasons/seasons";
import features from './modules/features/features';
import categories from './modules/categories/categories';
import genres from './modules/genres/genres';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    user,
    auth,
    common,
    anonimAuth,
    accounts,
    support,
    mediaTree,
    mediaList,
    mediaItem,
    mediaItems,
    mediaAsset,
    upload,
    liveStream,
		mediaTag,
		timezone,
    feeds,
    people,
    widgets,
    roles,
    billing,
    analytic,
    schedule,
    fields,
    servers,
    events,
    eventGroups,
    localization,
    cms,
    brushfire,
    assetRoles,
    apiKeys,
    playlist,
    series,
    seasons,
    features,
    categories,
    genres,
  },
  strict: false,
})
