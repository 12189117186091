<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, required }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    mode="passive"
  >
    <div :class="[blockClassName, errors.length && 'failed']">
      <div :class="['label', classNameLabel]">
        {{ fieldLabel }}
        <span
          v-if="required"
          class="asterisk"
        >
          *
        </span>
      </div>
      <datepicker
        ref="calendar"
        v-model="valueModel"
        :inputClass="`${className} picker`"
        :minimumView="minimumView"
        :typeable="isManualInput"
        :format="format"
        :disabled="disabled"
        :disabledDates="disabledDates"
        :highlighted="highlightedDates"
        :clearButton="true"
      >
      </datepicker>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ValidationProvider } from 'vee-validate';
import BaseMaterialInput from './BaseMaterialInput.vue';

export default {
  name: 'MaterialCalendar',
  components: {
    Datepicker,
    ValidationProvider,
  },
  extends: BaseMaterialInput,
  props: {
    value: {
      type: [Date, String],
      default: '',
    },
    blockClassName: {
      type: String,
      default: 'picker-date',
    },
    className: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minimumView: {
      type: String,
      default: 'day',
    },
    format: {
      type: String,
      default: 'dd MMM yyyy',
      required: false,
    },
    classNameLabel: {
      type: String,
      default: '',
    },
    disabledDates: {
      type: Object,
      default: () => {},
    },
    highlightedDates: {
      type: Object,
      default: () => {},
    },
    isManualInput: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
  .label {
    font-size: 16px;
    margin-bottom: 11px;
  }

  .failed .month-picker {
    border-bottom: 1px solid #fb3951;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .asterisk {
    color: #fb3951;
  }

  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .monthly {
    align-items: center;
    background: #222;
    display: flex;
    margin-right: 20px;
  }

  .monthly-label {
    margin-bottom: 0;
    margin-right: 5px;
  }
</style>
