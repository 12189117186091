<template>
  <div class="overlay">
    <ValidationObserver
      ref="validationObserver"
    >
      <div class="wrapper modal-window">
        <p class="headline grey lighten-2">
          Set Background
        </p>
        <p class="text">
          Upload an image to change the countdown background. 1920x1080 recommended
        </p>
        <div class="preview-block">
          <div
            ref="fileform"
            class="file-drag-drop"
            :class="isError ? 'error-form' : ''"
            @click="onFileInput"
          >
            <form class="file-form">
              <div
                class="drag-drop-zone"
                :title="'Add'"
              >
                <inline-svg
                  :src="iconUpload"
                  class="svg"
                />
                <span class="drop-files">
                  Drag & Drop
                </span>
                <span class="drop-files">
                  or click to <span class="browser-link">browse</span>
                </span>
              </div>
              <div
                v-if="fileName"
                class="file-footer"
              >
                {{ fileName }}
              </div>
            </form>
            <input
              ref="fileInput"
              type="file"
              class="input-hidden"
              :accept="'image/x-png,image/jpeg'"
              @change="fileInputChange"
            />
          </div>
          <div v-if="isError">
            <span class="error-label">
              {{ validationMessage }}
            </span>
          </div>
        </div>
        <div class="button-wrapper">
          <Button
            :title="'Close'"
            :classType="'primary grey'"
            :eventFunction="onClose"
          />
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Button from './Button.vue';
import iconUpload from '../../assets/icons/icon-upload.svg';
import iconRemove from '../../assets/icons/icon-remove-upload.svg';

export default {
  name: 'DragAndDropMediaUploaderModal',
  components: {
    ValidationObserver,
    Button,
  },
  props: {
    onFileUpload: {
      type: Function,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      aspectRatio: 16 / 9,
      dragAndDropCapable: false,
      isError: false,
      preview: '',
      file: null,
      fileName: '',
      validationMessage: '',
      iconUpload,
      iconRemove,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccount',
      'getCurrentAccountId',
    ]),
  },
  beforeDestroy() {
    ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
      this.$refs.fileform.removeEventListener(evt, this.preventDefault, false);
    });
    this.$refs.fileform.removeEventListener('drop', this.dropHandle);
  },
  mounted() {
    this.dragInit();
  },
  methods: {
    dragInit() {
      this.dragAndDropCapable = this.determineDragAndDropCapable();
      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
          this.$refs.fileform.addEventListener(evt, this.preventDefault, false);
        });
        this.$refs.fileform.addEventListener('drop', this.dropHandle);
      }
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div');
      return (('draggable' in div)
        || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window
        && 'FileReader' in window;
    },
    preventDefault(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    dropHandle(e) {
      this.file = e.dataTransfer.files[0];
      this.fileName = this.file.name;
      this.getImagePreviews(this.file);
    },
    fileInputChange() {
      const { files } = this.$refs.fileInput;
      if (files[0]) {
        this.file = files[0];
        this.fileName = this.file.name;
        this.getImagePreviews(this.file);
      }
    },
    getImagePreviews(file) {
      if (this.validateFile(file)) {
        this.isError = false;
        this.validationMessage = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.preview = reader.result;
          this.afterLoadValidation();
        }, false);
        reader.readAsDataURL(file);
      } else {
        this.isError = true;
      }
    },
    validateFile(file) {
      if (!(/\.(jpe?g|png)$/i.test(file.name))) {
        this.validationMessage = 'File format is invalid';
        return false;
      }

      if (((file.size / 1024) / 1024) > 10) {
        this.validationMessage = 'File size can be no more than 10 MB';
        return false;
      }

      return true;
    },
    afterLoadValidation() {
      const minAspect = (parseFloat(this.aspectRatio.toFixed(2)) - parseFloat(0.1)).toFixed(2);
      const maxAspect = (parseFloat(this.aspectRatio.toFixed(2)) + parseFloat(0.1)).toFixed(2);

      const img = new Image();
      img.src = this.preview;
      img.onload = () => {
        const aspect = (img.width / img.height).toFixed(2);
        if (aspect < minAspect || aspect > maxAspect) {
          this.isError = true;
          this.preview = '';
          this.validationMessage = 'Aspect Ratio should be 16:9';
          return;
        }
        if (img.height < 1080) {
          this.isError = true;
          this.preview = '';
          this.validationMessage = 'Min height should be 1080px';
        } else {
          this.onFileUpload(this.preview);
        }
      };
    },
    onFileInput() {
      this.$refs.fileInput.click();
    },
  },
};
</script>
<style scoped>
  .modal-window {

    height: auto;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .wrapper {
    background-color: #222;
    border-radius: 4px;
    display: block;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    padding: 0 10px;
    position: absolute;
    text-decoration: none;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .headline,
  .preview-block {
    color: #fff;
  }

  .is-url-tab {
    bottom: 0;
    position: absolute;
    right: 0;
  }

  .preview-block {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    padding: 10px 15px;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding: 13px 16px;
    word-break: break-all;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px 15px;
  }

  .file-drag-drop {
    border: 1px dashed #a0a0a0;
    cursor: copy;
    height: 230px;
    width: 100%;
  }

  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
  }

  .error-form {
    border-color: #fb3951;
  }

  .drag-drop-zone {
    /*cursor: pointer;*/
    /*padding: 40px 0;*/
    text-align: center;
  }

  .drag-drop-zone .drop-files {
    display: block;
  }

  .drag-drop-zone .drop-files .browser-link {
    color: #2591cf;
  }

  .file-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .file-footer {
   position: absolute;
   bottom: 105px;
   left: 40px;
  }

  .drop-files {
    color: #a0a0a0;
  }

  .input-hidden {
    display: none;
  }

  .text {
    margin: 10px 15px;
  }
</style>
