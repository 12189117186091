<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      v-if="getCurrentAccount
        && getCurrentAccount.vaeConfiguration
        && getCurrentAccount.vaeConfiguration.theme
        && getCurrentAccount.vaeConfiguration.theme === 'church'"
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="breadcrumbs">
        <span class="system">
          Video Apps Engine
        </span>
        <span>
          / General Configuration
        </span>
      </div>
      <div
        v-if="getCurrentAccount.hasVideoAppsEngine && access"
        class="edit-button-wrapper"
      >
        <Button
          :title="'Apply'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div
        v-if="getCurrentAccount.hasVideoAppsEngine && access"
        class="title"
      >
        App General Configuration
      </div>
      <div v-if="getCurrentAccount.hasVideoAppsEngine && access">
        <Tabs
          :smallStyle="true"
          :isLong="true"
        >
          <Tab
            name="General"
            :selected="true"
            :hasError="generalError"
            :className="generalError ? 'error-tab' : 'tab'"
          >
            <div v-if="themesOptions.length">
              <MaterialSelect
                v-model="formData.theme"
                v-bind="formFields.theme"
                :options="themesOptions"
                :label="'Select Navigation Layout'"
                :withEmptyField="false"
              />
            </div>
          </Tab>
        </Tabs>
      </div>
      <div
        v-if="!getCurrentAccount.hasVideoAppsEngine && getCurrentAccount.tvAppsEnabled"
        class="not-access"
      >
        <div>
          {{ checkboxEnabledText }}
        </div>
      </div>
      <div
        v-if="getCurrentAccount.hasVideoAppsEngine && !access"
        class="not-access"
      >
        <div>
          {{ defaultScenario }}
        </div>
      </div>
      <SwitchToLeftThemeModal
        ref="modal"
        v-model="isModalOpen"
        :modal="isModalOpen"
        :title="'Switch to Left Navigation Layout'"
        :navList="navList"
        :onAccept="onAddNavIcons"
        :onClose="onCloseModal"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import Button from '../../common/Button.vue';
import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';
import Tabs from '../../common/Tabs/Tabs.vue';
import Tab from '../../common/Tabs/Tab.vue';
import BaseForm from '../BaseForm.vue';

import {
  EDIT_ALL_NAVIGATIONS_ICONS_REQUEST,
  EDIT_VAE_CONFIG_REQUEST,
  GET_NAVIGATION_LIST_REQUEST,
  GET_VAE_CONFIG_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';
import CONFIG from '../../../constants/config';
import SwitchToLeftThemeModal from '../../common/SwitchToLeftThemeModal.vue';

export default {
  name: 'GlobalSettingsForm',
  components: {
    SwitchToLeftThemeModal,
    MaterialSelect,
    Button,
    Tabs,
    Tab,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    formPrefix: {
      type: String,
      default: 'navigationForm',
    },
  },
  data() {
    return {
      isModalOpen: false,
      checkboxEnabledText: 'This module is not activated for your account. Please contact your account representative for assistance.',
      defaultScenario: 'Your user account does not have permission to access this module. Please contact your account administrator for assistance.',
      generalError: null,
      permissionsGroupOptions: [],
      isOpen: false,
      iconFolderLocalization,
      enabledLanguages: [],
      tabErrors: {
        general: false,
      },
      themesOptions: [],
      navList: [],
      formData: {
        theme: '',
      },
      formFields: {
        theme: {
          type: 'text',
          name: 'Theme',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      bodyMessage: 'Are you sure you want to delete this Navigation Item?',
      errorMapping: {
        pageTarget: [
          `${this.formPrefix}.targetPage`,
        ],
      },
      submitEvent: EDIT_VAE_CONFIG_REQUEST,
      successMessage: 'The Layout has been successfully updated',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.cms.isLoading,
      profile: (state) => state.common.profile,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
    ]),
    access() {
      return this.getCurrentAccount
        && this.getCurrentAccount.hasVideoAppsEngine
        && this.profile.userAccountRoles
          .find((item) => item.account.id === this.getCurrentAccountId).role !== 'ROLE_MANAGER';
    },
  },
  created() {
    if (this.getCurrentAccount
      && this.getCurrentAccount.vaeConfiguration
      && this.getCurrentAccount.vaeConfiguration.theme
      && this.getCurrentAccount.vaeConfiguration.theme === 'media_and_entertainment') {
      this.$router.push('/');
      return;
    }

    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    const vaeId = this.getCurrentAccount.vaeConfiguration.id;
    const params = {
      accountId: this.getCurrentAccountId,
      vaeId,
    };
    this.$store.dispatch(GET_VAE_CONFIG_REQUEST, params).then((res) => {
      this.themesOptions = res.availableNavigationLayouts.map((item) => ({
        code: item,
        name: item === 'top_navigation' ? 'Top Navigation' : 'Left Navigation',
      }));
      this.formData.theme = res.navigationLayout;
    });
  },
  methods: {
    onOpenModal() {
      this.isModalOpen = true;
    },
    onCloseModal() {
      this.isModalOpen = false;
    },
    getRequestData() {
      const data = {
        navigationLayout: this.formData.theme,
      };
      return {
        data,
        accountId: this.getCurrentAccountId,
        vaeId: this.getCurrentAccount.vaeConfiguration.id,
      };
    },
    onAddNavIcons(data) {
      const params = {
        accountId: this.getCurrentAccountId,
        data: {
          navigationItems: data,
        },
      };
      this.$store.dispatch(EDIT_ALL_NAVIGATIONS_ICONS_REQUEST, params).then(() => {
        this.submit();
      });
    },
    onSubmitSuccess() {
      this.generalError = false;
      this.$toasted.global.success({
        message: this.successMessage,
      });
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      const generalError = error.form_errors.children
        && error.form_errors.children.navigationLayout.errors;
      if (generalError) {
        this.generalError = generalError;
        if (generalError[0].error === 'VAE_THEME_REQUIRES_FILLED_ICONS_NAVIGATION_ITEMS') {
          const params = {
            accountId: this.getCurrentAccountId,
          };
          this.$store.dispatch(GET_NAVIGATION_LIST_REQUEST, params).then((res) => {
            this.navList = res.data.filter((item) => !item.icon);
            this.onOpenModal();
          });
        }
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      }
    },
  },
};
</script>

<style scoped>
.not-access {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20%;
  padding-left: 50%;
  width: 120%;
}

.rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.toggles-wrapper {
  display: flex;
}

.toggle-wrapper,
.date-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
}

.toggle-wrapper {
  width: auto;
}

.toggle-title {
  margin-right: 10px;
}

.dynamic {
  margin-top: 30px;
  margin-bottom: 30px;
}

.edit-button-wrapper {
  position: absolute;
  top: 75px;
  display: flex;
  right: 30px;
}

.country-field {
  width: 300px;
}

.country-field:not(:last-child) {
  margin-right: 20px;
}

.wowza p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.wrapper-small {
  margin-bottom: 20px;
}

.flex {
  justify-content: space-between;
}

.person {
  margin-bottom: 20px;
}

.person p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.select-wrapper {
  width: 300px;
}

.system {
  color: #4c5057;
}

.breadcrumbs {
  margin-bottom: 25px;
}

.localization-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.label {
  width: 400px;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 20px;
}

.localization-warning {
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}

@media all and (max-width: 1320px) {
  .flex {
    flex-wrap: wrap;
  }

  .country-field {
    margin-right: 0;
    width: 100%;
  }

  .country-field:not(:last-child) {
    margin-right: 0;
  }
}
</style>
