<template>
  <div class="settings main-body-right">
    <div class="page-wrapper">
      <div class="wrap">
        <div class="system">
          {{ title }}
        </div>
        <div class="nav sub-nav">
          <router-link
            v-if="isAdmin && isOnline"
            to="/billing"
            tag="div"
            class="router"
            :activeClass="$route.path !== '/billing/usage' && 'active'"
          >
            Billing
          </router-link>
          <router-link
            v-if="isAdmin"
            to="/billing/usage"
            tag="div"
            class="router"
            :activeClass="'active'"
          >
            Usage
          </router-link>
        </div>
      </div>
      <Loader :isVisible="isLoaded" />
      <router-view />
      <div
        v-if="$route.path !== '/billing/usage'"
        class="list-wrapper"
      >
        <div class="search-container">
          <div class="warning-text">
            <div
              v-if="hasBanner()"
              class="banner-block"
            >
              <div
                class="banner-type"
                :class="subscription.paymentStatus"
              >
                <div class="warning-block">
                  <inline-svg
                    :src="iconWarning"
                    class="svg-icon warning-svg"
                  >
                  </inline-svg>
                  <p v-if="hasPendingBanner()">
                    Welcome to the Broadcast Cloud system.
                    Please make your initial payment to begin using your account by
                    <span
                      class="link"
                      @click="toCreateCheckoutHandler"
                    >clicking here</span>.
                  </p>
                  <p v-if="hasBlockedBanner()">
                    We have been unable to process payment using the payment method
                    on file for your Broadcast account for "{{ getCurrentAccount.name }}".
                    We understand that this can be a normal part of a payment method
                    lifecycle and that your card may have been replaced or updated.
                    To update your payment method,
                    <span
                      class="link"
                      @click="toCreateCheckoutHandler"
                    >click here</span>.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="live-search-block">
            <LiveSearch
              :onSearch="onSearch"
              :maxlength="100"
            />
            <div
              v-if="!hasBanner()"
              class="button-block"
            >
              <Button
                v-if="isCheckoutAvailable()"
                :title="'Checkout'"
                :classType="'primary'"
                :eventFunction="toCreateCheckoutHandler"
              />
              <Button
                v-if="isPaymentUpdateAvailable()"
                :title="'Update Payment Info'"
                :classType="'primary'"
                :eventFunction="toUpdatePaymentMethodHandler"
              />
            </div>
          </div>
        </div>
        <div class="accounts-wrapper">
          <div class="ac-wrapper-header">
            <div class="th-name number">
              #
            </div>
            <div class="th-name unique-id">
              <Sorting
                :sortField="`i.${sortedField.uniqueId}`"
                :currentSortedField="currentSortedField"
                :handleSortUp="handleSortUp"
                :handleSortDown="handleSortDown"
              />
              invoice id
            </div>
            <div class="th-name price">
              price
            </div>
            <div class="th-name status">
              <Sorting
                :sortField="`i.${sortedField.status}`"
                :currentSortedField="currentSortedField"
                :handleSortUp="handleSortUp"
                :handleSortDown="handleSortDown"
              />
              status
            </div>
            <div class="th-name date">
              <Sorting
                :sortField="`i.${sortedField.created}`"
                :currentSortedField="currentSortedField"
                :handleSortUp="handleSortUp"
                :handleSortDown="handleSortDown"
                :defaultSortUp="false"
              />
              created
            </div>
            <div class="th-name date">
              paid
            </div>
          </div>
          <div
            v-if="!invoices.length"
            class="empty-search"
          >
            No Invoices to display
          </div>
          <div
            v-for="(item, key) in invoices"
            :key="item.id"
            class="account"
          >
            <div class="td number">
              {{ getCurrentNumber(key) }}
            </div>
            <div
              class="td unique-id"
              :title="item.uniqueId"
            >
              {{ item.uniqueId }}
            </div>
            <div
              class="td price"
              :title="item.amount | centConverter | money"
            >
              {{ item.amount | centConverter | money }}
            </div>
            <div class="td status">
              <div
                class="status-ellipse"
                :class="item.status"
              >
              </div>
              <span>
                {{ invoiceStatuses[item.status] }}
              </span>
            </div>
            <div class="td date">
              <span v-if="item.createdAtOnPaymentSystem">
                {{ item.createdAtOnPaymentSystem | timezone(getMainProfile.timezone) }}
              </span>
              <span v-else>-</span>
            </div>
            <div class="td date">
              <span v-if="item.paidAtOnPaymentSystem">
                {{ item.paidAtOnPaymentSystem | timezone(getMainProfile.timezone) }}
              </span>
              <span v-else>-</span>
            </div>
          </div>
        </div>
        <Pagination
          :action="getPaginationAction"
          :page="pagination.page"
          :totalPages="totalPages"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import {
  CREATE_CHECKOUT_REQUEST,
  UPDATE_PAYMENT_METHOD_REQUEST,
  GET_ALL_INVOICES_REQUEST,
} from '../../store/actions/billing/billing';

import Button from '../../components/common/Button.vue';
import Loader from '../../components/common/Loader.vue';
import Pagination from '../../components/common/Pagination.vue';
import Sorting from '../../components/common/Sorting.vue';
import LiveSearch from '../../components/common/LiveSearch.vue';
import iconWarning from '../../assets/icons/icon-warning.svg';
import CONFIG from '../../constants/config';

export default {
  name: 'Billing',
  components: {
    Button,
    Loader,
    Pagination,
    Sorting,
    LiveSearch,
  },
  data() {
    return {
      title: '',
      paymentStatuses: CONFIG.paymentStatuses,
      invoiceStatuses: CONFIG.invoiceStatuses,
      filter: '',
      sortedField: {
        uniqueId: 'uniqueId',
        status: 'status',
        created: 'createdAtOnPaymentSystem',
      },
      currentSortedField: 'i.createdAtOnPaymentSystem',
      currentSortBy: 'DESC',
      iconWarning,
    };
  },
  computed: {
    ...mapState({
      isLoaded: (state) => state.billing.isLoading,
      invoices: (state) => state.billing.invoices,
      pagination: (state) => state.billing.pagination,
    }),
    ...mapGetters([
      'getMainProfile',
      'getCurrentAccount',
      'getCurrentAccountId',
      'isAdmin',
    ]),
    subscription() {
      return this.getCurrentAccount.subscription;
    },
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    isOnline() {
      return this.getCurrentAccount.subscription.type === 'online';
    },
  },
  created() {
    if (!this.isOnline) {
      this.$router.push('/billing/usage');
      return;
    }

    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
    this.title = document.title;

    const params = {
      accountId: this.getCurrentAccountId,
      page: localStorage.getItem('invoicesPage') || 1,
      filterValue: this.filter,
      sortFiled: this.currentSortedField,
      sortBy: this.currentSortBy,
    };
    this.$store.dispatch(GET_ALL_INVOICES_REQUEST, params);
    this.processStatusMessage();
    this.processCheckoutRedirect();
  },
  methods: {
    showSuccessStatusMessage(message) {
      this.$toasted.global.success({
        message,
      });
    },
    showErrorStatusMessage(message) {
      this.$toasted.global.error({
        message,
      });
    },
    processStatusMessage() {
      const {
        status,
        type,
      } = this.$route.query;
      const {
        statuses,
        types,
      } = CONFIG.subscriptionRedirect;

      if (status === statuses.success) {
        if (type === types.create) {
          this.showSuccessStatusMessage('Subscription has been successfully created');
        }
        if (type === types.update) {
          this.showSuccessStatusMessage('Payment method has been successfully updated');
        }
      } else if (status === statuses.cancel) {
        if (type === types.create) {
          this.showErrorStatusMessage('Subscription has not been created');
        }
        if (type === types.update) {
          this.showErrorStatusMessage('Payment method has not been updated');
        }
      }

      if (type && status) {
        this.$router.replace('/billing');
      }
    },
    hasBanner() {
      return this.hasPendingBanner() || this.hasBlockedBanner();
    },
    hasPendingBanner() {
      return this.subscription.paymentStatus === 'pending';
    },
    hasBlockedBanner() {
      return this.subscription.paymentStatus === 'blocked';
    },
    processCheckoutRedirect() {
      const { redirect } = this.$route.query;
      const { types } = CONFIG.subscriptionRedirect;

      if (redirect) {
        if (redirect === types.create && this.isCheckoutAvailable()) {
          this.toCreateCheckoutHandler();
        } else {
          this.$router.replace('/billing');
        }
      }
    },
    isCheckoutAvailable() {
      return this.subscription.type === CONFIG.subscriptionTypes.online
        && this.subscription.checkoutAllowed
        && !this.subscription.hasOnlineSubscription;
    },
    isPaymentUpdateAvailable() {
      return this.subscription.type === CONFIG.subscriptionTypes.online
        && this.subscription.hasOnlineSubscription;
    },
    toCreateCheckoutHandler() {
      if (this.isCheckoutAvailable()) {
        this.$store.dispatch(CREATE_CHECKOUT_REQUEST, this.getCurrentAccountId)
          .then((data) => {
            const request = {
              sessionId: data.uniqueId,
            };
            loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
              .then((stripe) => {
                stripe.redirectToCheckout(request).then(() => {
                  this.$toasted.global.error({
                    message: CONFIG.errorMessages.subscriptionCreateNotAvailable,
                  });
                });
              });
          })
          .catch(() => {
            this.$toasted.global.error({
              message: CONFIG.errorMessages.subscriptionCreateNotAvailable,
            });
          });
      }
    },
    toUpdatePaymentMethodHandler() {
      if (this.isPaymentUpdateAvailable()) {
        this.$store.dispatch(UPDATE_PAYMENT_METHOD_REQUEST, this.getCurrentAccountId)
          .then((data) => {
            const request = {
              sessionId: data.uniqueId,
            };
            loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
              .then((stripe) => {
                stripe.redirectToCheckout(request).then(() => {
                  this.$toasted.global.error({
                    message: CONFIG.errorMessages.subscriptionUpdateNotAvailable,
                  });
                });
              });
          })
          .catch(() => {
            this.$toasted.global.error({
              message: CONFIG.errorMessages.subscriptionCreateNotAvailable,
            });
          });
      }
    },
    getPaginationAction(page) {
      const params = {
        accountId: this.getCurrentAccountId,
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ALL_INVOICES_REQUEST, params);
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    handleSortUp(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_INVOICES_REQUEST, params);
    },
    handleSortDown(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_INVOICES_REQUEST, params);
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        accountId: this.getCurrentAccountId,
        page: params.page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ALL_INVOICES_REQUEST, getParams);
    },
  },
};
</script>

<style scoped>
  .main-body-right {
    height: calc(100% + 3px);
    overflow-y: auto;
  }

  .page-wrapper {
    display: flex;
    width: calc(100% - 58px);
  }

  .list-wrapper {
    padding: 33px 29px;
    width: 100%;
  }

  .live-search-block {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
  }

  .accounts-wrapper {
    border: 1px solid #43434d;
    max-height: calc(100vh - 250px);
    min-height: calc(100vh - 350px);
    overflow-y: auto;
  }

  .ac-wrapper-header {
    background: #282a2e;
    display: flex;
    padding: 13px 0;
  }

  .banner-block .banner-type {
    border: 1px solid #e77925;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 21px;
  }

  .banner-block .banner-type p {
    margin: 0;
  }

  .link {
    color: #e77925;
    cursor: pointer;
    text-decoration: underline;
  }

  .th-name {
    align-items: center;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
  }

  .td {
    font-size: 14px;
    padding: 15px 0;
    word-break: break-word;
  }

  .number {
    padding-left: 21px;
    width: 67px;
  }

  .unique-id {
    width: 30%;
  }

  .price {
    width: 20%;
  }

  .date {
    width: 10%;
  }

  .status {
    align-items: flex-start;
    display: flex;
    width: 20%;
  }

  .status-ellipse.success {
    background: #1fee65;
  }

  .status-ellipse.canceled {
    background: #fb3951;
  }

  .status-ellipse.pending {
    background: #fbf650;
  }

  .account {
    display: flex;
  }

  .account:hover {
    background: #383b40;
  }

  .status-ellipse {
    border-radius: 50%;
    height: 5px;
    margin-right: 6px;
    margin-top: 6px;
    width: 5px;
  }

  .empty-search {
    padding-top: 40px;
    text-align: center;
  }

  .warning-svg {
    margin-right: 10px;
    margin-top: -5px;
    min-height: 20px;
    min-width: 20px;
  }

  .warning-block {
    display: flex;
  }

  .warning-text {
    margin-bottom: 20px;
  }

  .live-search-block .search-wrapper {
    margin-right: 0;
  }

  .button-block {
    margin-left: 25px;
  }

  .nav {
    background: transparent;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }

  .wrap {
    border-right: 1px solid #383b40;
    flex-direction: column;
    min-width: 330px;
  }

  .system {
    border-bottom: 1px solid #383b40;
    padding: 30px 0 25px 25px;
  }

  .router:hover {
    color: #2591cf;
  }

  .active {
    color: #2591cf;
  }

  .router {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 35px;
    width: fit-content;
  }

  @media all and (max-width: 1100px) {
    .wrap {
      min-width: 250px;
    }
  }

  @media all and (max-width: 1024px) {
    .wrap {
      display: none;
    }
  }
</style>
