<template>
  <div class="categories-management">
    <div class="title">
      {{ label }}
      <span
        v-if="required"
        class="asterisk"
      >
        *
      </span>
    </div>
    <div
      :class="[commonError.length ? 'common-error' : '',
               isNotValidValue ? 'not-valid' : '']"
    >
      <MultiSelect
        v-model="selectedValue"
        :options="options"
        :placeholder="placeholder"
        :selectLabel="selectLabel"
        :selectedLabel="selectedLabel"
        :deselectLabel="deselectLabel"
        label="categories"
        trackBy="id"
        :multiple="true"
        :taggable="false"
        :blockKeys="['Delete']"
        :searchable="searchable"
        :disabled="disabled"
        :hideSelected="hideSelected"
        :optionsLimit="max"
        :customLabel="customLabel"
        @search-change="onSearchChange"
        @input="onInput"
      >
        <template
          slot="tag"
          slot-scope="props"
        >
          <span
            v-if="categories.length > 0"
            :class="selectedCategoryCalasses(props.option)"
            :title="selectedCategoryTitle(props.option)"
          >
            <span>
              {{ customLabel(props.option) }}
            </span>
            <i
              aria-hidden="true"
              tabindex="1"
              class="multiselect__tag-icon"
              @click="props.remove(props.option)"
            >
            </i>
          </span>
          <span v-else></span>
        </template>
      </MultiSelect>
      <span
        v-if="commonError.length"
        class="error-label"
      >
        {{ commonError }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MultiSelect from 'vue-multiselect';
import { GET_CATEGORIES_FOR_CHOICE_REQUEST } from '../../store/actions/categories/categoriesActions';

export default {
  name: 'CategoriesManagement',
  components: {
    MultiSelect,
  },
  props: {
    resetValidation: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      default: 'Select Categories',
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    searchable: {
      type: Boolean,
      default: true,
      required: false,
    },
    selectedValue: {
      type: Array,
      default: () => [],
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    hideSelected: {
      type: Boolean,
      default: false,
      required: false,
    },
    max: {
      type: Number,
      default: 1000,
    },
    selectLabel: {
      type: String,
      default: '',
    },
    selectedLabel: {
      type: String,
      default: '',
    },
    deselectLabel: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    commonError: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: [],
      categories: [],
      isNotValidValue: false,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    options() {
      return this.categories.map((category) => ({
        id: category.id,
      }));
    },
  },
  created() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      const params = {
        accountId: this.getCurrentAccountId,
      };
      this.$store.dispatch(GET_CATEGORIES_FOR_CHOICE_REQUEST, params).then((res) => {
        this.categories = res.data;
      });
    },
    onSearchChange(searchQuery) {
      this.resetValidation();

      const validation = this.validateSearch(searchQuery.trim());

      this.isNotValidValue = !validation.isValid;
    },
    validateSearch(searchValue) {
      return {
        value: searchValue,
        isValid: true,
        message: '',
      };
    },
    onInput(value) {
      this.resetValidation();
      this.onChange(value);
    },
    customLabel(option, withDisabled = false) {
      const category = this.categories.find((category) => category.id === option.id);
      let label = category?.name || '';

      if (withDisabled && !category?.enabled) {
        label = `${label} (disabled)`;
      }

      return label;
    },
    selectedCategoryCalasses(option) {
      const category = this.categories.find((item) => item.id === option.id);
      return {
        multiselect__tag: true,
        'multiselect__tag--disabled': !category?.enabled,
      };
    },
    selectedCategoryTitle(option) {
      const category = this.categories.find((item) => item.id === option.id);
      return category?.enabled ? '' : 'This Category is disabled';
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
.categories-management {
  .title {
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--white);
    font-weight: normal;
  }

  .multiselect {
    box-sizing: border-box;
  }

  .multiselect__tags {
    background: #222 !important;
    border-color: transparent;
    border-radius: 4px;
  }

  .multiselect__tag {
    background: #e77925;
    cursor: pointer;

    &--disabled {
      background: #4c5057;

      .multiselect__tag-icon:focus,
      .multiselect__tag-icon:hover {
        background: #ff6a6a;
      }
    }
  }

  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: var(--darkorange);
  }

  .asterisk {
    color: #fb3951;
  }
}
</style>
