<template>
  <div>
    <Button
      v-if="isEditTvFolder"
      :title="'Apply'"
      :classType="'primary apply-btn'"
      :eventFunction="onSaveTvAppsFolders"
    />
    <div
      v-if="mediaTree.data"
      class="tree-container"
    >
      <MediaTreeElement
        :id="allMedia.id"
        :name="allMedia.name"
        :hasProtection="allMedia.hasProtection"
        :isLock="true"
        :hasChildren="allMedia.length > 0"
        :level="allMedia.level"
        :children="allMedia.children"
        :type="allMedia.type"
        :openModal="openModal"
        :parent="allMedia.parent"
        :path="allMedia.path"
        :playlist="allMedia.playlist"
        :feature="allMedia.feature"
        :folderId="folderId"
        :uncFolderId="0"
        :getMediaList="getMediaList"
        :mediaList="mediaList"
        :isSchedule="isSchedule"
        :onFolderChange="onFolderChange"
        :isEditTvFolder="isEditTvFolder"
        :toggleTvAppsFolder="toggleTvAppsFolder"
      />
      <MediaTreeElement
        :id="0"
        :name="'Uncategorized'"
        :isLock="true"
        :hasChildren="false"
        :level="1"
        :type="'UNCATEGORIZED'"
        :openModal="openModal"
        :parent="null"
        :folderId="folderId"
        :getMediaList="getMediaList"
        :mediaList="mediaList"
        :isSchedule="isSchedule"
        :onFolderChange="onFolderChange"
      />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import {
  EDIT_TV_APPS_LIST,
} from '../../../store/actions/media/mediaTree';
import MediaTreeElement from './MediaTreeElement.vue';
import Button from '../../common/Button.vue';

export default {
  name: 'MediaTree',
  components: {
    MediaTreeElement,
    Button,
  },
  props: {
    mediaList: {
      type: Array,
      default: () => [],
    },
    getMediaList: {
      type: Function,
      default: () => {},
    },
    openModal: {
      type: Function,
      default: () => {},
    },
    folderId: {
      type: String,
      default: '',
    },
    isSchedule: {
      type: Boolean,
      default: false,
    },
    onFolderChange: {
      type: Function,
      default: () => {},
    },
    isEditTvFolder: {
      type: Boolean,
      default: false,
    },
    onCloseEditingTvFolders: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      // tvAppsFolders: JSON.parse(localStorage.getItem('tvApps')) || [],
      allMedia: {
        id: null,
        name: '',
        level: null,
        children: [],
        type: null,
        parent: {},
        hasProtection: false,
      },
      result: {},
    };
  },
  computed: {
    ...mapState({
      mediaTree: (state) => state.mediaTree.mediaTree,
      allMediaProp: (state) => state.mediaTree.mediaTree.data,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    tvAppsFolders() {
      return JSON.parse(localStorage.getItem('tvApps'));
    },
  },
  watch: {
    allMediaProp() {
      this.allMedia = this.allMediaProp;
    },
  },
  methods: {
    onSaveTvAppsFolders() {
      const params = {
        accountId: this.getCurrentAccountId,
        data: {
          mediaFolders: this.tvAppsFolders,
        },
      };
      this.$store.dispatch(EDIT_TV_APPS_LIST, params)
        .then(() => {
          localStorage.setItem('tvApps', JSON.stringify([...new Set(this.tvAppsFolders)]));
          this.onCloseEditingTvFolders();
        });
    },
    toggleTvAppsFolder(id, enabled) {
      this.onSearchForderById(this.allMedia.children, id);

      this.toggleTvFolderInList(id, enabled);
      this.result.tvAppsEnabled = enabled;

      if (this.result.children.length) {
        this.replaceTvAppsEnable(this.result.children, enabled);
      }
    },
    toggleTvFolderInList(id, enabled) {
      if (this.tvAppsFolders.includes(id)) {
        if (enabled) {
          return;
        }
        const index = this.tvAppsFolders.indexOf(id);
        this.tvAppsFolders.splice(index, 1);
      } else {
        if (!enabled) {
          return;
        }
        this.tvAppsFolders.push(id);
      }
    },
    onSearchForderById(list, id) {
      list.find((item) => {
        if (item.children.length) {
          this.onSearchForderById(item.children, id);
        }

        if (item.id === id) {
          this.result = item;
        }

        return item.id === id;
      });
    },
    replaceTvAppsEnable(list, enabled) {
      list.forEach((item) => {
        this.toggleTvFolderInList(item.id, enabled);
        item.tvAppsEnabled = enabled;

        if (item.children.length) {
          this.replaceTvAppsEnable(item.children, enabled);
        }
      });
    },
  },
};
</script>

<style scoped>
  .tree-container {
    height: calc(100vh - 200px);
    margin: 15px 10px 10px 5px;
    overflow-y: auto;
  }

  .tree-container > .tree-element {
    position: relative;
  }

  .apply-btn {
    width: 80px;
    margin: 10px 20px 0 20px;
  }
</style>
