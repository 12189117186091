<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'Tab',
  components: {
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    className: {
      type: Array,
      default: () => [],
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  mounted() {
    this.isActive = this.selected;
  },
};
</script>
