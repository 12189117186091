<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div style="margin-bottom: 30px">
      <Button
        :title="'Add Banner'"
        :classType="'primary'"
        :eventFunction="createRow"
      />
    </div>
    <div class="accounts-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          banner title
        </div>
        <div class="th-name date">
          banner type
        </div>
      </div>
      <div
        v-if="!rows.length"
        class="empty-search"
      >
        No items to display
      </div>
      <draggable
        v-model="rows"
        :move="checkMove"
        @start="dragging = true"
        @end="endOfDrag"
      >
        <transition-group>
          <div
            v-for="(item, index) in rows"
            :key="item.id"
            class="account"
            @mouseover="onHoverElem(item)"
            @mouseleave="isHover = false"
          >
            <div class="td number">
              {{ index + 1 }}
            </div>
            <div
              class="td company"
              :title="item.name"
            >
              {{ item.name }}
            </div>
            <div class="td date">
              {{ item.type === 'page_link' ? 'Page Link' : 'Playlist Detail' }}
            </div>
            <div
              v-show="isHover && item.id === selectedId"
              class="icons-wrapper"
            >
              <div :title="'Remove Banner'">
                <inline-svg
                  :src="iconRemove"
                  class="svg-icon remove"
                  @click="onOpenModalForRemove(item)"
                >
                </inline-svg>
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="'Remove Banner'"
      :onAccept="removeRow"
      :onClose="onClose"
    />
    <AddBannerBySliderModal
      v-model="isModalAddOpen"
      :modal="isModalAddOpen"
      :bodyMessage="'Add Banner'"
      :banners="rows"
      :onAccept="addBanner"
      :onClose="onCloseAddModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import draggable from 'vuedraggable';
import Loader from '../../../components/common/Loader.vue';
import iconEnabled from '../../../assets/icons/icon-enabled-acc.svg';
import iconDisabled from '../../../assets/icons/icon-disabled-acc.svg';
import iconEdit from '../../../assets/icons/icon-edit-acc.svg';
import iconFix from '../../../assets/icons/icon-fix.svg';
import iconManual from '../../../assets/icons/icon-events.svg';
import CONFIG from '../../../constants/config';
import Button from '../../../components/common/Button.vue';
import iconRemove from '../../../assets/icons/icon-remove-role.svg';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../../store/actions/common';
import NotificationModal from '../../../components/NotificationModal.vue';
import AddBannerBySliderModal from './AddBannerBySliderModal.vue';

export default {
  name: 'BannersBySlider',
  components: {
    AddBannerBySliderModal,
    Button,
    Loader,
    NotificationModal,
    draggable,
  },
  props: {
    orderRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const {
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editCMSPage,
    } = CONFIG.tooltips;

    return {
      dragging: false,
      rows: [],
      iconRemove,
      iconManual,
      paymentStatuses: CONFIG.paymentStatuses,
      enabledId: null,
      akamaiAccountCreateId: null,
      akamaiAppSyncId: null,
      isEnabled: false,
      isModalOpen: false,
      isHover: false,
      selectedId: null,
      bodyMessage: '',
      filter: '',
      sortedField: {
        name: 'name',
        industry: 'industry',
        enabled: 'enabled',
      },
      currentSortedField: 'a.name',
      currentSortBy: 'ASC',
      isSortedUp: false,
      isSortedDown: false,
      isModalAddOpen: false,
      createAkamaiApp,
      syncAkamaiApp,
      enableAccount,
      disableAccount,
      editCMSPage,
      iconEnabled,
      iconEdit,
      iconDisabled,
      iconFix,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
    ]),
    ...mapState({
      isLoading: (state) => state.cms.isLoading,
      profile: (state) => state.common.profile,
    }),
  },
  created() {
    this.rows = this.orderRows;
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
  },
  methods: {
    endOfDrag() {
      this.dragging = false;
      this.$emit('dragged', this.rows);
    },
    onOpenModalForRemove(item) {
      const {
        deleteBannerInCmsBySliders,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.enabledId = item.id;
      this.bodyMessage = deleteBannerInCmsBySliders;
    },
    toManuallyRows(item) {
      this.$router.push(`${this.$route.path}/${item.id}/manually`);
    },
    checkMove() {
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onCloseAddModal() {
      this.isModalAddOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    createRow() {
      this.isModalAddOpen = true;
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    removeRow() {
      this.rows = this.rows.filter((item) => item.id !== this.selectedId);
      this.$emit('added', this.rows);
    },
    addBanner(banner) {
      this.rows.push(banner);
      this.$emit('added', this.rows);
    },
  },
};
</script>

<style scoped>
.flex {
  align-items: center;
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 40px;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.system {
  color: #4c5057;
}

.accounts-wrapper {
  border: 1px solid #43434d;
  height: 65vh;
  overflow-y: auto;
}

.ac-wrapper-header {
  background: #282a2e;
  display: flex;
  padding: 13px 0;
}

.th-name {
  align-items: center;
  display: flex;
  font-size: 12px;
  text-transform: uppercase !important;
}

.td {
  font-size: 14px;
  padding: 15px 0;
  word-break: break-word;
}

.number {
  padding-left: 21px;
  width: 50px;
}

.company {
  padding-right: 15px;
  width: 13%;
}

.users-count {
  width: 15%;
  text-transform: capitalize;
}

.status {
  align-items: flex-start;
  display: flex;
  width: 11%;
}

.date {
  align-items: flex-start;
  display: flex;
  width: 15%;
}

.account {
  display: flex;
}

.account:hover {
  background: #383b40;
  cursor: move;
}

.format {
  text-transform: capitalize;
}

.status-ellipse {
  border-radius: 50%;
  height: 5px;
  margin-right: 6px;
  margin-top: 6px;
  width: 5px;
}

.status-ellipse.enabled,
.status-ellipse.active {
  background: #1fee65;
}

.status-ellipse.disabled,
.status-ellipse.blocked,
.status-ellipse.pending {
  background: #fb3951;
}

.status-ellipse.failed {
  background: #fbf650;
}

.icons-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  flex: 0.98;
}

.icons-wrapper div {
  padding: 10px;
}

.create-button {
  background: #2591cf;
  color: #fff;
  font-size: 16px;
  letter-spacing: normal;
  padding: 0 16px;
  text-transform: none;
}

.right-path {
  align-items: center;
  display: flex;
}

.empty-search {
  padding-top: 40px;
  text-align: center;
}

.btn-create-account-mobile {
  display: none;
}

.svg-icon {
  cursor: pointer;
}

.not-access {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
}

@media all and (max-width: 1400px) {
  .number {
    width: 30px;
  }

  .status {
    width: 9%;
  }

}

@media all and (max-width: 1170px) {
  .status {
    width: 9.5%;
  }
}

@media all and (max-width: 1100px) {
  .icons-wrapper div {
    padding: 10px 5px;
  }
}

@media all and (max-width: 1024px) {
  .btn-create-account-mobile {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    min-height: 40px;
  }

  .search-wrapper {
    margin-right: 0;
  }
}

@media all and (max-width: 900px) {
  .company {
    padding-right: 7px;
    width: 14%;
  }

  .number {
    width: 24px;
  }

  .status {
    width: 10%;
  }
}

@media all and (max-height: 940px) {
  .accounts-wrapper {
    height: 63vh;
  }
}

@media all and (max-height: 899px) {
  .accounts-wrapper {
    height: 62vh;
  }
}

@media all and (max-height: 845px) {
  .accounts-wrapper {
    height: 60vh;
  }
}

@media all and (max-height: 800px) {
  .accounts-wrapper {
    height: 59vh;
  }
}

@media all and (max-height: 770px) {
  .accounts-wrapper {
    height: 52vh;
  }
}
</style>
