<template>
  <div
    id="app"
    :class="isOverflow ? 'modal-open' : ''"
  >
    <SiteHeader
      ref="siteHeader"
      isAuthenticated
      profileName
      :accountsList="accountsList"
      :changeCurrentAccount="changeCurrentAccount"
      :toggleMobileMenu="toggleMobileMenu"
    />
    <div class="main-container">
      <div ref="mobileDrawer">
        <div
          v-if="isOpenMobileMenu"
          class="mobile-drawer"
        >
          <MobileMenuDrawer
            v-if="isAuthenticated"
            :mediaFolderId="getRootMediaFolderId"
          />
        </div>
      </div>
      <div
        v-if="isAuthenticated && isLoaded || !isAuthenticated"
        class="main-body"
      >
        <router-view :key="$route.name" />
      </div>
      <div
        v-else
        class="main-body"
      >
      </div>
      <div v-if="isOtherUserMode && profileName">
        <OtherUserInfo :profileName="profileName" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import SiteHeader from './components/SiteHeader.vue';
import OtherUserInfo from './components/common/OtherUserInfo.vue';
import MobileMenuDrawer from './components/common/navigation/MobileMenuDrawer.vue';
import CONFIG from './constants/config';
import { GET_APP_CONFIG, MAIN_INFORMATION } from './store/actions/common';
import { SET_LAST_USED_ACCOUNT } from './store/actions/user';
import { ADD_SUBTITLE_SSE } from './store/actions/media/mediaAsset';

export default {
  name: 'App',
  components: {
    MobileMenuDrawer,
    SiteHeader,
    OtherUserInfo,
  },
  data() {
    return {
      isOpenMobileMenu: false,
      isOtherUserMode: !!localStorage.getItem('isOtherUserMode'),
      publishMessage: CONFIG.schedule.successPublish,
    };
  },
  computed: {
    ...mapState({
      profileName: (state) => state.common.profile.firstName,
      accountsRoles: (state) => state.common.accountsRoles,
      isOverflow: (state) => state.common.isOverflow,
      isLoaded: (state) => state.common.isLoading,
      isPublishInProgress: (state) => state.schedule.isPublishInProgress,
      toastMess: (state) => state.mediaAsset.toastMess,
    }),
    ...mapGetters([
      'isAuthenticated',
      'isSuperAdmin',
      'isAccountActive',
      'getCurrentAccount',
      'getCurrentAccountId',
      'getRootMediaFolderId',
    ]),
    accountsList() {
      if (!this.isSuperAdmin) {
        return this.accountsRoles
          .map(({ account }) => {
            const str = (account.name.length > 25)
              ? `${account.name.slice(0, 25)}...`
              : account.name;

            return {
              name: str,
              code: account.id,
            };
          })
          .sort((item1, item2) => {
            const a = item1.name.toLowerCase();
            const b = item2.name.toLowerCase();

            if (a > b) {
              return 1;
            }
            if (a < b) {
              return -1;
            }
            return 0;
          });
      }
      return [];
    },
  },
  watch: {
    isPublishInProgress(value, oldValue) {
      if (!value && oldValue) {
        this.$toasted.global.success({
          message: this.publishMessage,
        });
      }
    },
    toastMess(value) {
      if (value.accountId === this.getCurrentAccountId) {
        if (value.type === 'error') {
          this.$toasted.global.error({
            message: value.message,
          });
        } else {
          this.$toasted.global.success({
            message: value.message,
          });
        }
      }
    },
  },
  created() {
    if (this.isAuthenticated && !this.isLoaded) {
      this.$store.dispatch(MAIN_INFORMATION)
        .then(() => {
          if (!this.isSuperAdmin) {
            this.$store.dispatch(ADD_SUBTITLE_SSE);
            this.$store.dispatch(SET_LAST_USED_ACCOUNT, this.getCurrentAccountId);
          }

          if (!this.isAccountActive) {
            this.redirectDisabledAccount();
          }
        });
    }

    if (this.isAuthenticated) {
      document.addEventListener('mousedown', this.clickOutside);
      this.$store.dispatch(GET_APP_CONFIG);
    }
  },
  destroyed() {
    document.removeEventListener('mousedown', this.clickOutside);
  },
  methods: {
    clickOutside(e) {
      if (this.$refs.mobileDrawer
        && !this.$refs.mobileDrawer.contains(e.target)
        && !this.$refs.siteHeader.$refs.mobileIcon.contains(e.target)) {
        this.isOpenMobileMenu = false;
      }
    },
    toggleMobileMenu() {
      this.isOpenMobileMenu = !this.isOpenMobileMenu;
    },
    changeCurrentAccount(accountId) {
      if (!this.isSuperAdmin) {
        this.$store.dispatch(SET_LAST_USED_ACCOUNT, accountId)
          .then(() => this.$store.dispatch(MAIN_INFORMATION))
          .then(() => {
            if (this.isAccountActive) {
              this.$router.push(`/media/folder/${this.getRootMediaFolderId}`);
            } else {
              this.redirectDisabledAccount();
            }
          });
      }
    },
    redirectDisabledAccount() {
      this.$router.push('/settings/user-profile')
        .catch(() => {});

      this.$toasted.global.error({
        message: CONFIG.errorMessages.disableAccMessage,
      });
    },
  },
};
</script>
<style scoped>
/* stylelint-disable */
  .modal-open {
    overflow: hidden;
  }

  .mobile-drawer {
    display: none;
  }

  .main-container {
    background-color: #222;
  }

  @media all and (max-width: 1200px) {
    .mobile-drawer {
      background-color: #222;
      border-right: 1px solid #383b40;
      display: block;
      height: calc(100vh - 60px);
      position: absolute;
      width: 310px;
      overflow: auto;
      z-index: 12;
    }
  }
</style>
<style lang="scss">
  @import 'assets/styles/theme.css';
  @import 'assets/styles/style.css';
</style>
