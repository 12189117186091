<template>
  <div class="overlay">
    <SubtitleAssetModalContent
      v-if="modalType === 'subtitle'"
      :modalMode="modalMode"
      :modalData="modalData"
      :onCancel="onClose"
      :onSuccess="onSuccess"
      :showLoader="showLoader"
      :source="source"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SubtitleAssetModalContent from './SubtitleAssetModalContent.vue';
import { SET_EDIT_FORM } from '../../../../store/actions/common';

export default {
  name: 'AssetModal',
  components: {
    SubtitleAssetModalContent,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: null,
    },
    source: {
      type: Object,
      default: null,
    },
    modalMode: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
    showLoader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modalType: 'subtitle',
    };
  },
  computed: {
    ...mapState({
      isEditedForm: (state) => state.common.isEditedForm,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  methods: {
    preventNav(event) {
      if (this.modal && this.isEditedForm) {
        event.returnValue = 'You have unfinished changes!';
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
    },
  },
};
</script>
<style>
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
