<template>
  <div class="overlay">
    <div class="wrapper">
      <ValidationObserver
        ref="validationObserver"
        v-slot="{ handleSubmit }"
      >
        <form
          novalidate="novalidate"
          @submit.prevent="handleSubmit(submit)"
          @input="handleFormChange"
        >
          <p
            class="headline grey lighten-2"
            primaryTitle
          >
            Add Event Group
          </p>
          <div class="add-input">
            <MaterialInput
              v-model="formData.name"
              v-bind="formFields.name"
              className="tree-modal-inputs"
            />
          </div>
          <div class="button-wrapper">
            <Button
              :title="'Cancel'"
              :classType="'primary warning'"
              :eventFunction="onCancelHandle"
            />
            <Button
              :title="'Add'"
              :type="'submit'"
              :classType="'primary'"
            />
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseForm from '../forms/BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import { ADD_EVENT_GROUP } from '../../store/actions/events/eventGroups';
import CONFIG from '../../constants/config';
import Button from '../common/Button.vue';

export default {
  name: 'AddEventGroupModal',
  components: {
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'eventGroup',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onAccept: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        name: '',
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
      },
      submitEvent: ADD_EVENT_GROUP,
      successMessage: CONFIG.successMessages.addEventGroupMessage,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  methods: {
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      this.onAccept();
    },
    getRequestData() {
      return {
        data: {
          name: this.formData.name,
        },
        accountId: this.getCurrentAccountId,
      };
    },
    onCancelHandle() {
      this.onClose();
    },
  },
};
</script>
<style scoped>
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .wrapper {
    background-color: #222;
    border-radius: 4px;
    display: block;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    padding: 0 10px;
    position: absolute;
    text-decoration: none;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .headline,
  .add-input {
    color: #fff;
  }

  .add-input {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    padding-right: 30px;
    padding-left: 30px;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding: 30px;
    word-break: break-all;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 10px 30px;
    padding-bottom: 30px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }
</style>
