<template>
  <div class="site-toolbar">
    <div class="left-header-wrapper">
      <div
        ref="mobileIcon"
        class="mobile-icon"
      >
        <inline-svg
          v-if="isAuthenticated"
          :src="menuIcon"
          class="svg-icon mobile-menu-icon"
          @click="toggleMobileMenu"
        >
        </inline-svg>
      </div>
      <div class="site-toolbar-title">
        {{ title }}
        <span class="text-uppercase">tm</span>
      </div>
      <div class="navigation-drawer">
        <NavigationDrawer
          v-if="isAuthenticated"
          :mediaFolderId="getRootMediaFolderId"
        />
      </div>
    </div>
    <div
      v-if="isAuthenticated"
      class="user-info"
    >
      <UploadList />
      <div
        v-if="!isSuperAdmin && isLoading && accountsList.length > 1"
        class="current-account"
      >
        <MaterialSelect
          v-model="selectedAccountId"
          :withEmptyField="false"
          :options="accountsList"
          :handleChange="handleChangeCurrentAccount"
          :className="'select-wrapper'"
        />
      </div>
      <span v-if="profile">
        Welcome, {{ profile.firstName }}
      </span>
    </div>
    <div
      v-if="isAuthenticated"
      class="icon-container"
      @click="settingsOpen = !settingsOpen"
    >
      <i
        class="fas fa-user"
      ></i>
    </div>
    <div
      v-if="settingsOpen"
      ref="dropDownRef"
      class="login-menu"
    >
      <UserDropdown v-model="settingsOpen" />
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import NavigationDrawer from './common/NavigationDrawer.vue';
import UserDropdown from './common/UserDropdown.vue';
import UploadList from './common/UploadList.vue';
import menuIcon from '../assets/icons/menu-icon.svg';
import MaterialSelect from './inputs/MaterialSelect.vue';

export default {
  name: 'SiteHeader',
  components: {
    NavigationDrawer,
    UserDropdown,
    UploadList,
    MaterialSelect,
  },
  props: {
    toggleMobileMenu: {
      type: Function,
      default: () => {},
    },
    accountsList: {
      type: Array,
      default: () => [],
    },
    changeCurrentAccount: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedAccountId: null,
      menuIcon,
      settingsOpen: false,
      title: 'Broadcast Cloud',
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.common.profile,
      isLoading: (state) => state.common.isLoading,
    }),
    ...mapGetters([
      'isAuthenticated',
      'isSuperAdmin',
      'getCurrentAccount',
      'getCurrentAccountId',
      'getRootMediaFolderId',
    ]),
  },
  watch: {
    getCurrentAccountId(newAccountId) {
      this.selectedAccountId = newAccountId;
    },
  },
  created() {
    if (this.isAuthenticated) {
      document.addEventListener('mousedown', this.clickOutside);
    }
  },
  destroyed() {
    document.removeEventListener('mousedown', this.clickOutside);
  },
  methods: {
    clickOutside(e) {
      if (this.$refs.dropDownRef && !this.$refs.dropDownRef.contains(e.target)) {
        this.settingsOpen = false;
      }
    },
    handleChangeCurrentAccount() {
      this.changeCurrentAccount(this.selectedAccountId);
    },
  },
};
</script>
<style>
  .site-toolbar {
    align-items: center;
    background-color: #282a2e;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
    color: #fff;
    contain: layout;
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    padding: 0 16px;
    position: relative;
    transition: transform 0.2s
      cubic-bezier(0.4, 0, 0.2, 1),
      background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
      left 0.2s cubic-bezier(0.4, 0, 0.2, 1),
      right 0.2s cubic-bezier(0.4, 0, 0.2, 1),
      box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1),
      max-width 0.25s cubic-bezier(0.4, 0, 0.2, 1),
      width 0.25s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-transform 0.2s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 20;
  }

  .icon-container {
    align-items: center;
    background: #c4c4c4;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    margin: 0 10px 0 12px;
    width: 30px;
  }

  .site-toolbar-title {
    cursor: default;
    display: flex;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5;
    overflow: hidden;
    padding-right: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .login-menu {
    background-color: #212121;
    position: absolute;
    right: 0;
    top: 50px;
  }

  .user-info {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    justify-content: flex-end;
    margin-left: auto;
  }

  .user-info .current-account {
    margin-right: 10px;
  }

  .user-info .current-account select {
    padding: 7px 17px 7px 10px;
    width: 240px;
    height: auto;
  }

  .left-header-wrapper {
    align-items: center;
    display: flex;
  }

  .text-uppercase {
    font-size: 8px;
    padding: 5px 0 0 2px;
    text-transform: uppercase;
  }

  .mobile-icon {
    display: none;
  }

  .mobile-menu-icon {
    padding: 0 20px 0 10px;
  }

  @media all and (max-width: 1500px) {
    .user-info .current-account select {
      width: 170px;
    }
  }

  @media all and (max-width: 1200px) {
    .navigation-drawer {
      display: none;
    }

    .mobile-icon {
      cursor: pointer;
      display: block;
    }
  }
</style>
