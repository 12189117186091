<template>
  <div class="form-container">
    <Loader :isVisible="isLoading" />
    <div class="left-path">
      <span class="system">
        Video Apps Engine / Sliders
      </span>
      <span>
        / Edit {{ slider.name }}
      </span>
    </div>
    <div class="title">
      Slider Editor
    </div>
    <div>
      <ValidationObserver
        ref="validationObserver"
        v-slot="{ handleSubmit }"
      >
        <form
          class="contact-info"
          novalidate="novalidate"
          @submit.prevent="handleSubmit(submit)"
          @input="handleFormChange"
        >
          <div class="edit-button-wrapper">
            <Button
              v-if="$route.params.id"
              :title="'Delete'"
              :classType="'transparent'"
              :eventFunction="onOpenModal"
              :disabled="slider.status !== 'inactive'"
            />
            <Button
              :title="'Cancel'"
              :classType="'warning'"
              :eventFunction="backToCms"
            />
            <Button
              :title="'Apply'"
              :classType="'primary'"
              :type="'submit'"
            />
          </div>
          <Tabs :onSelectTab="handleSelectTab">
            <Tab
              :id="'general'"
              name="General"
              :selected="mode === 'general'"
              :className="[error ? 'error-tab' : 'tab']"
            >
              <div class="wrapper main-form-container">
                <div class="column column-1">
                  <MaterialInput
                    v-model="formData.name"
                    v-bind="formFields.name"
                    :requiredFromBack="true"
                  />
                </div>
              </div>
            </Tab>
            <Tab
              :id="'layout'"
              name="Layout & Data"
              :selected="mode === 'layout'"
            >
              <BannersBySlider
                :key="formData.orderRows.length"
                :orderRows="formData.orderRows"
                @dragged="onEndOfDraggingRows"
                @added="onAddedToRows"
              />
            </Tab>
          </Tabs>
        </form>
      </validationobserver>
    </div>
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="'Delete Slider'"
      :onAccept="removeSlider"
      :onClose="onClose"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CONFIG from '../../../constants/config';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../../store/actions/common';
import {
  EDIT_SLIDER_REQUEST,
  GET_SLIDER_REQUEST,
  REMOVE_SLIDER_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';

import BaseForm from '../../../components/forms/BaseForm.vue';
import Loader from '../../../components/common/Loader.vue';
import Tabs from '../../../components/common/Tabs/Tabs.vue';
import Tab from '../../../components/common/Tabs/Tab.vue';
import Button from '../../../components/common/Button.vue';
import MaterialInput from '../../../components/inputs/MaterialInput.vue';
import BannersBySlider from './BannersBySlider.vue';
import NotificationModal from '../../../components/NotificationModal.vue';

export default {
  name: 'SliderEditor',
  components: {
    BannersBySlider,
    MaterialInput,
    NotificationModal,
    Tab,
    Tabs,
    Loader,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'addSliderForm',
    },
  },
  data() {
    return {
      slider: {},
      mode: sessionStorage.getItem('lastOpenedSliderPage') || 'general',
      error: null,
      enabledLanguages: [],
      isModalOpen: false,
      bodyMessage: 'Are you sure you want to delete this Slider?',
      formData: {
        name: '',
        orderRows: [],
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            max: 255,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
      },
      submitEvent: EDIT_SLIDER_REQUEST,
      successMessage: 'The Slider has been successfully updated',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    ...mapState({
      isLoading: (state) => state.cms.isLoading,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  mounted() {
    const params = {
      accountId: this.getCurrentAccountId,
      sliderId: this.$route.params.id,
    };
    this.$store.dispatch(GET_SLIDER_REQUEST, params).then((res) => {
      this.slider = res;
      this.initFormData(res);
    });
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
    sessionStorage.removeItem('lastOpenedSliderPage');
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  methods: {
    initFormData(item) {
      this.formData.name = item.name;
      this.formData.orderRows = item.banners;
    },
    onOpenModal() {
      this.isModalOpen = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    removeSlider() {
      const params = {
        accountId: this.getCurrentAccountId,
        sliderId: this.$route.params.id,
      };

      this.$store.dispatch(REMOVE_SLIDER_REQUEST, params)
        .then(() => {
          setTimeout(() => {
            this.backToCms();
          }, CONFIG.routing.redirectFromAddAcc);
          this.$toasted.global.success({
            message: 'The Slider has been successfully deleted',
          });
        })
        .catch((err) => {
          if (err.status === CONFIG.statuses.failedStatus) {
            this.$toasted.global.error({
              message: CONFIG.errorMessages.commonServerError,
            });
          }
        });
    },
    hasGridInfoError(errors) {
      return this.$refs.gridDataSource && this.$refs.gridDataSource.hasErrors(errors);
    },
    backToCms() {
      this.$router.push('/cms/banners');
      sessionStorage.setItem('lastOpenedBanners', 'sliders');
    },
    onEndOfDraggingRows(value) {
      this.formData.orderRows = value.map((item) => item.id);
    },
    onAddedToRows(value) {
      this.formData.orderRows = value;
    },
    handleSelectTab(id) {
      this.mode = id;
      sessionStorage.setItem('lastOpenedSliderPage', this.mode);
    },
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
      this.error = null;
    },
    getRequestData() {
      const orders = this.formData.orderRows.map((order) => {
        if (order.name) {
          return order.id;
        }

        return order;
      });
      const data = {
        name: this.formData.name,
        banners: this.formData.orderRows.length ? orders : [],
      };

      return {
        data,
        accountId: this.getCurrentAccountId,
        sliderId: this.$route.params.id,
      };
    },
    onSubmitFail(error) {
      this.formErrors = this.getServerFormErrors(error);
      this.itemsErrors = this.getServerFormCollectionErrors(error);
      this.error = this.formErrors;

      if (Object.entries(this.formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(this.formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.backToCms();
      }, CONFIG.routing.redirectFromAddAcc);
    },
  },
};
</script>

<style scoped>
.left-path {
  margin-bottom: 30px;
}

.system {
  color: #4c5057;
}

.form-container {
  padding: 25px 30px;
  overflow-y: auto;
  width: 100%;
}

.wrapper {
  display: flex;
}

.edit-button-wrapper {
  position: absolute;
  top: 75px;
  display: flex;
  right: 30px;
}
</style>
