<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="contact-info">
        <div class="title">
          Contact Info
        </div>
        <div class="inline-row">
          <div class="inline-block">
            <MaterialInput
              v-model="formData.firstName"
              v-bind="formFields.firstName"
              :disabled="disabled"
            />
          </div>
          <div class="inline-block">
            <MaterialInput
              v-model="formData.lastName"
              v-bind="formFields.lastName"
              :disabled="disabled"
            />
          </div>
        </div>
        <div v-if="isSuperAdmin">
          <MaterialInput
            v-model="formData.role"
            v-bind="formFields.role"
            :disabled="true"
          />
        </div>
        <div v-else>
          <div
            v-for="item of getUserAccountRoles"
            :key="item.id"
            class="inline-row"
          >
            <div class="inline-block">
              <MaterialSelect
                :value="item.account"
                :options="accountsList"
                v-bind="formFields.account"
                :disabled="true"
              />
            </div>
            <div class="inline-block">
              <MaterialSelect
                :value="item.role"
                :options="roleList"
                v-bind="formFields.role"
                :disabled="true"
              />
            </div>
          </div>
        </div>
        <div>
          <MaterialInput
            v-model="formData.phone"
            v-bind="formFields.phone"
            :disabled="disabled"
          />
        </div>
        <div>
          <MaterialInput
            v-model="formData.email"
            v-bind="formFields.email"
            :disabled="disabled"
          />
        </div>
        <div>
          <MaterialSelect
            v-model="formData.timezone"
            v-bind="formFields.timezone"
            :options="timezones"
            :disabled="disabled"
          />
        </div>
      </div>
      <Button
        :title="'Save'"
        :classType="'primary'"
        :type="'submit'"
        :disabled="disabled"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { EDIT_PROFILE_CONTACT_INFO } from '../../store/actions/user';

import BaseForm from './BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';
import Button from '../common/Button.vue';

import CONFIG from '../../constants/config';

export default {
  name: 'ProfileForm',
  components: {
    MaterialInput,
    Button,
    MaterialSelect,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'profile',
    },
    timezones: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        role: '',
        timezone: '',
      },
      formFields: {
        email: {
          type: 'text',
          name: 'email',
          formPrefix: this.formPrefix,
          rules: {
            email: true,
            required: true,
            max: 255,
          },
        },
        firstName: {
          type: 'text',
          name: 'firstName',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        lastName: {
          type: 'text',
          name: 'lastName',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        phone: {
          type: 'text',
          name: 'phone',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        role: {
          type: 'text',
          name: 'role',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        account: {
          type: 'text',
          name: 'account',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        timezone: {
          type: 'text',
          name: 'Time Zone',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
      },
      errorMapping: {
        email: [
          `${this.formPrefix}.email`,
        ],
        firstName: [
          `${this.formPrefix}.firstName`,
        ],
        lastName: [
          `${this.formPrefix}.lastName`,
        ],
        phone: [
          `${this.formPrefix}.phone`,
        ],
        userAccountRoles: [
          `${this.formPrefix}.userAccountRoles.*`,
        ],
        timezone: [
          `${this.formPrefix}.timezone`,
        ],
      },
      submitEvent: EDIT_PROFILE_CONTACT_INFO,
      successMessage: CONFIG.successMessages.editUserProfileMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
      roleList: CONFIG.roleList,
    };
  },
  computed: {
    ...mapState({
      accountsRoles: (state) => state.common.accountsRoles,
    }),
    ...mapGetters([
      'isSuperAdmin',
      'getMainProfile',
    ]),
    accountsList() {
      if (!this.isSuperAdmin) {
        return this.accountsRoles.map((item) => (
          { name: item.account.name, code: item.account.id }
        ));
      }
      return [];
    },
    getUserAccountRoles() {
      return this.accountsRoles
        .map((item) => ({
          id: item.id,
          role: item.role,
          account: item.account.id,
          accountName: item.account.name,
        }))
        .sort((item1, item2) => {
          const a = item1.accountName.toLowerCase();
          const b = item2.accountName.toLowerCase();

          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        });
    },
  },
  mounted() {
    this.initFormData();
  },
  methods: {
    initFormData() {
      if (this.getMainProfile.id) {
        this.formData.firstName = this.getMainProfile.firstName;
        this.formData.lastName = this.getMainProfile.lastName;
        this.formData.phone = this.getMainProfile.phone;
        this.formData.email = this.getMainProfile.email;
        this.formData.timezone = this.getMainProfile.timezone;
      }

      if (this.isSuperAdmin) {
        this.formData.role = this.roleList
          .find((item) => item.code === this.accountsRoles[0].role)
          .name;
      }
    },
    getRequestData() {
      return {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        phone: this.formData.phone,
        email: this.formData.email,
        timezone: this.formData.timezone,
      };
    },
  },
};
</script>

<style scoped>
  .contact-info {
    margin-bottom: 30px;
  }

  .inline-row {
    display: flex;
    justify-content: space-between;
  }

  .inline-block + .inline-block {
    margin-left: 25px;
  }

  .inline-block {
    width: 300px;
  }
</style>
