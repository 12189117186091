<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, required }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    mode="passive"
  >
    <div
      :class="[
        'picker-datetime',
        blockClassName,
        errors.length && 'failed',
        isClosed ? 'closed-picker' : ''
      ]"
    >
      <div
        class="label"
        :class="classNameLabel"
      >
        {{ fieldLabel }}
        <span
          v-if="required"
          class="asterisk"
        >
          *
        </span>
      </div>
      <div class="field-wrapper">
        <datepicker
          v-model="dateModel"
          :inputClass="`${className} picker`"
          :minimumView="minimumView"
          :format="dateFormat"
          :disabled="disabled"
          :disabledDates="disabledDates"
          class="picker-date"
          :clearButton="true"
        >
        </datepicker>
        <span ref="pickerTimeRef">
          <vue-timepicker
            v-model="timeModel"
            :disabled="disabled"
            :format="timeFormat"
            class="time-picker"
            @change="onChangeTime"
          >
          </vue-timepicker>
        </span>
      </div>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import VueTimepicker from 'vue2-timepicker';
import { ValidationProvider } from 'vee-validate';
import BaseMaterialInput from './BaseMaterialInput.vue';
import 'vue2-timepicker/dist/VueTimepicker.css';

export default {
  name: 'MaterialDateTime',
  components: {
    Datepicker,
    VueTimepicker,
    ValidationProvider,
  },
  extends: BaseMaterialInput,
  props: {
    value: {
      type: [Date, String],
      default: '',
    },
    blockClassName: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minimumView: {
      type: String,
      default: 'day',
    },
    dateFormat: {
      type: String,
      default: 'dd MMM yyyy',
      required: false,
    },
    timeFormat: {
      type: String,
      default: 'HH:mm:ss',
    },
    classNameLabel: {
      type: String,
      default: '',
    },
    disabledDates: {
      type: Object,
      default: () => {},
    },
    onChangeTime: {
      type: Function,
      default: () => {},
    },
    onChangeDate: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isClosed: false,
    };
  },
  computed: {
    dateModel: {
      get() {
        const dateTime = moment(this.value);

        if (dateTime.isValid()) {
          return dateTime.toDate();
        }

        return '';
      },
      set(value) {
        if (!value) {
          this.$emit('input', '');
        }

        const date = value ? moment(value).format('YYYY-MM-DD') : '';
        const time = this.timeModel || '00:00:00';
        const dateTime = moment(`${date}T${time}+00:00`);

        if (dateTime.isValid()) {
          this.$emit('input', dateTime.utc().format('YYYY-MM-DD HH:mm:ss'));
        }
      },
    },
    timeModel: {
      get() {
        const dateTime = moment(this.value);

        if (dateTime.isValid()) {
          return dateTime.format(this.timeFormat);
        }

        return '';
      },
      set(value) {
        if (!value) {
          this.$emit('input', '');
        }

        const date = moment(this.dateModel).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD');
        const dateTime = moment(`${date}T${value}+00:00`);

        if (dateTime.isValid()) {
          this.$emit('input', dateTime.utc().format('YYYY-MM-DD HH:mm:ss'));
        }
      },
    },
  },
  created() {
    document.addEventListener('mousedown', this.clickOutside);
  },
  destroyed() {
    document.removeEventListener('mousedown', this.clickOutside);
  },
  methods: {
    clickOutside(e) {
      this.isClosed = this.$refs.pickerTimeRef && !this.$refs.pickerTimeRef.contains(e.target);
    },
  },
};
</script>

<style scoped>
  .picker-datetime {
    margin-right: 30px;
    width: 100%;
  }

  .label {
    margin-bottom: 11px;
  }

  .failed .month-picker {
    border-bottom: 1px solid #fb3951;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .asterisk {
    color: #fb3951;
  }

  .picker-datetime >>> .inspector-datetime {
    background-color: #222;
  }

  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .monthly {
    align-items: center;
    background: #222;
    display: flex;
    margin-right: 20px;
  }

  .monthly-label {
    margin-bottom: 0;
    margin-right: 5px;
  }

  .field-wrapper {
    display: flex;
    font-size: 16px;
    justify-content: space-between;
  }

  .time-picker {
    width: 100%;
  }

  .date-picker {
    width: 49%;
  }
</style>
<style>
  .closed-picker .vue__time-picker .dropdown {
    display: none !important;
  }

  .vue__time-picker .time-picker-overlay {
    position: absolute;
  }
</style>
