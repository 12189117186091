<template>
  <div>
    <div
      ref="fileform"
      class="file-drag-drop"
      :class="[isError ? 'error-form' : '', isDisabled ? 'disabled' : '']"
      :title="'Add Subtitles'"
      @click="onFileInput"
    >
      <form class="file-form">
        <div
          class="drag-drop-zone"
          :class="[isDisabled ? 'disabled' : '']"
        >
          <inline-svg
            :src="iconUpload"
            class="svg"
          />
          <span class="drop-files">
            Drag & Drop
          </span>
          <span class="drop-files">
            or click to <span class="browser-link">browse</span>
          </span>
        </div>
      </form>
      <input
        ref="fileInput"
        type="file"
        class="input-hidden"
        :multiple="true"
        accept=".vtt,.srt"
        :disabled="isDisabled"
        @change="fileInputChange"
      />
    </div>
    <div class="localization-warning-wrapper">
      <inline-svg
        :src="iconWarning"
        class="svg-icon"
      >
      </inline-svg>
      <span class="localization-warning">
        File name structure must be: file name + underscore + language code  E.g. File name_en.
        Please note that the same language code must not be repeated for
        different subtitle files of the same media.
      </span>
    </div>
  </div>
</template>

<script>
import iconUpload from '../../../../assets/icons/icon-upload.svg';
import iconWarning from '../../../../assets/icons/icon-warning-localization.svg';

export default {
  name: 'MultiSubtitles',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    onFileChange: {
      type: Function,
      default: () => {},
    },
    getTitle: {
      type: Function,
      default: () => {},
    },
    subtitles: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconUpload,
      iconWarning,
      tempFile: null,
      dragAndDropCapable: false,
      file: null,
      preview: '',
      isError: false,
      validationMessage: '',
      fileName: '',
    };
  },
  mounted() {
    this.dragInit();
  },
  methods: {
    fileInputChange() {
      const { files } = this.$refs.fileInput;
      if (files.length) {
        // eslint-disable-next-line no-plusplus
        this.onFileChange(files);
      }
    },
    onFileInput() {
      this.$refs.fileInput.click();
    },
    dragInit() {
      this.dragAndDropCapable = this.determineDragAndDropCapable();
      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
          this.$refs.fileform.addEventListener(evt, (e) => {
            e.preventDefault();
            e.stopPropagation();
          }, false);
        });

        this.$refs.fileform.addEventListener('drop', this.dropHandle);
      }
    },
    dropHandle(e) {
      this.onFileChange(e.dataTransfer.files);
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div');
      return (('draggable' in div)
          || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window
        && 'FileReader' in window;
    },
  },
};
</script>

<style scoped>
.file-drag-drop {
  border: 1px dashed #a0a0a0;
  cursor: pointer;
  height: 230px;
  width: 80%;
}

.error-label {
  color: #fb3951;
  font-size: 12px;
  margin-bottom: 5px;
  text-align: left;
}

.error-form {
  border-color: #fb3951;
}

.drag-drop-zone {
  /*cursor: pointer;*/
  /*padding: 40px 0;*/
  text-align: center;
}

.drag-drop-zone .drop-files {
  display: block;
}

.drag-drop-zone .drop-files .browser-link {
  color: #2591cf;
}

.file-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.drop-files {
  color: #a0a0a0;
}

.input-hidden {
  display: none;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.localization-warning {
  flex: 0.98;
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}
</style>
