<template>
  <div>
    <div
      v-for="(item, index) in items"
      :key="item.id"
    >
      <div class="account-role">
        <AccontsAndRolesRow
          :index="index"
          :roleInAccount="item.role"
          :account="item.account"
          :roleList="roleList"
          :accountList="accountList"
          :onRemoveItem="onRemoveItem"
          :onChangeAccount="onChangeAccount"
          :onChangeRole="onChangeRole"
          :formPrefix="formPrefix"
          :isDeleteBtnDisabled="isDeleteBtnDisabled"
        />
      </div>
    </div>
    <div class="account-role-new">
      <div class="btn-row">
        <a
          class="add-button"
          @click="onAddItem"
        >
          <inline-svg
            :src="iconAdd"
            class="svg-icon add-icon"
          >
          </inline-svg>
          <span class="btn-name">
            Add
          </span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import AccontsAndRolesRow from './AccountsAndRolesRow.vue';
import iconAdd from '../../assets/icons/media-add.svg';

export default {
  name: 'AccountsAndRoles',
  components: {
    AccontsAndRolesRow,
  },
  props: {
    formPrefix: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    onRemoveItem: {
      type: Function,
      default: () => {},
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    onChangeAccount: {
      type: Function,
      default: () => {},
    },
    onChangeRole: {
      type: Function,
      default: () => {},
    },
    roleList: {
      type: Array,
      default: () => [],
    },
    accountList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iconAdd,
    };
  },
  computed: {
    isDeleteBtnDisabled() {
      return this.items.length < 2;
    },
  },
  methods: {
    onAddItem() {
      const role = 'ROLE_MANAGER';
      const account = null;
      this.onChange({
        role,
        account,
      });
    },
  },
};
</script>
<style scoped>
  .btn-row {
    margin-top: 10px;
  }

  .add-button {
    align-items: center;
    display: flex;
  }

  .add-icon {
    cursor: pointer;
  }

  .btn-name {
    cursor: pointer;
    margin-left: 5px;
  }
</style>
