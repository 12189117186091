<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, classes, required, ariaInput, ariaMsg }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    mode="passive"
  >
    <div
      class="group"
      :class="classes"
    >
      <div class="checkbox-container">
        <div
          class="toggle-btn"
        >
          <ToggleButton
            v-model="valueModel"
            color="#2591CF"
            :disabled="disabled"
            :sync="true"
            :labels="labels"
            :width="width"
          />
        </div>
        <div class="item-title">
          {{ fieldLabel }}
          <span
            v-if="required && !hideAsterisk"
            class="asterisk"
          >
            *
          </span>
        </div>
      </div>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
          v-bind="ariaMsg"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>
<script>
import BaseMaterialInput from './BaseMaterialInput.vue';

export default {
  name: 'MaterialToggleItem',
  extends: BaseMaterialInput,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Object,
      default: () => {},
    },
    hideAsterisk: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    width() {
      const checkedLength = this.labels.checked ? this.labels.checked : '';
      const uncheckedLength = this.labels.unchecked ? this.labels.unchecked : '';

      return 40 + 6 * Math.max(checkedLength.length, uncheckedLength.length);
    },
  },
};
</script>
<style scoped>
  .checkbox-container {
    display: flex;
  }

  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
</style>
