<template>
  <div class="wrapper">
    <h2>Ooops... Something goes wrong.</h2>
    <div class="link-block">
      <router-link
        to="/"
        class="link"
      >
        Back on main page
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style scoped>
  .wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .link-block {
    padding-top: 30px;
  }

  .link {
    color: #22a5fd !important;
    text-decoration: none;
  }
</style>
