<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="edit-button-wrapper">
        <Button
          v-if="isEdit"
          :title="'Delete'"
          :classType="'transparent'"
          :eventFunction="onOpenModal"
          :disabled="!!formData.distribution"
        />
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backToLocalization"
        />
        <Button
          :title="isEdit ? 'Apply' : 'Add'"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="breadcrumbs">
        <span class="system">
          System Management / Localization
        </span>
        <span v-if="!isEdit">
          / Add Language
        </span>
        <span
          v-else
        >
          / Edit {{ formData.code }}
        </span>
      </div>
      <div class="title">
        {{ isEdit ? 'Edit Language' : 'Add Language' }}
      </div>
      <MaterialSelect
        v-model="formData.code"
        v-bind="formFields.code"
        :label="'Select Language'"
        :withEmptyField="false"
        :options="languagesOptions"
        :disabled="isEdit"
      />
      <div class="status-checkbox">
        <div class="checkbox">
          <MaterialCheckbox
            :toggle="toggleChangeDistribution"
            :label="'Enabled for Distribution'"
            :checked="formData.distribution"
            :error="enabledError"
          />
        </div>
      </div>
      <NotificationModal
        ref="modal"
        v-model="isModalOpen"
        :modal="isModalOpen"
        :bodyMessage="bodyMessage"
        :title="'Delete Language'"
        :onAccept="removeLanguage"
        :onClose="onClose"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CONFIG from '../../constants/config';

import BaseForm from './BaseForm.vue';
import Button from '../common/Button.vue';
import MaterialCheckbox from '../inputs/MaterialCheckbox.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';
import NotificationModal from '../NotificationModal.vue';
import {
  ADD_LANGUAGE_REQUEST,
  EDIT_LANGUAGE_REQUEST,
  GET_LANGUAGE_REQUEST,
  GET_LANGUAGES_SELECT_REQUEST,
  REMOVE_LANGUAGE_REQUEST,
} from '../../store/actions/localization/localizationActions';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../store/actions/common';

export default {
  name: 'LocalizationForm',
  components: {
    MaterialSelect,
    MaterialCheckbox,
    Button,
    NotificationModal,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'language',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHasId: true,
      isModalOpen: false,
      bodyMessage: CONFIG.modals.deleteLanguageMessage,
      enabledError: '',
      formData: {
        code: '',
        distribution: false,
      },
      formFields: {
        code: {
          type: 'text',
          name: 'code',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        code: [
          `${this.formPrefix}.code`,
        ],
      },
      submitEvent: this.isEdit ? EDIT_LANGUAGE_REQUEST : ADD_LANGUAGE_REQUEST,
      successMessage: this.isEdit
        ? CONFIG.successMessages.editLocalizationMessage
        : CONFIG.successMessages.addLocalizationMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    ...mapState({
      languagesOptions: (state) => state.localization.languagesOptions,
    }),
  },
  mounted() {
    this.$store.dispatch(GET_LANGUAGES_SELECT_REQUEST);
    if (this.isEdit && this.$route.params.id) {
      const {
        errorMessages,
        statuses,
      } = CONFIG;

      const params = {
        accountId: this.getCurrentAccountId,
        langId: this.$route.params.id,
      };

      this.$store.dispatch(GET_LANGUAGE_REQUEST, params)
        .then((res) => this.initFormData(res))
        .catch((err) => {
          if (err.error === errorMessages.unknown) {
            this.isHasId = false;
            this.$router.push(`/${statuses.notFound}`);
          }
        });
    }
  },
  methods: {
    initFormData(item) {
      this.formData.code = item.code;
      this.formData.distribution = item.distribution;
    },
    backToLocalization() {
      this.$router.push('/settings/localization');
    },
    toggleChangeDistribution(isChecked) {
      if (isChecked !== this.formData.distribution) {
        this.formData.distribution = isChecked ? 1 : 0;
        this.enabledError = '';
      }
    },
    onOpenModal() {
      this.isModalOpen = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    removeLanguage() {
      const params = {
        accountId: this.getCurrentAccountId,
        langId: this.$route.params.id,
      };

      this.$store.dispatch(REMOVE_LANGUAGE_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: CONFIG.successMessages.removedLocalizationMessage,
          });
          setTimeout(() => {
            this.$router.push('/settings/localization');
          }, CONFIG.routing.redirectFromAddAcc);
        });
    },
    getRequestData() {
      const data = {
        code: this.formData.code,
        distribution: !!this.formData.distribution,
      };

      if (!this.isEdit) {
        data.account = this.accountId;
      }

      return {
        data,
        accountId: this.getCurrentAccountId,
        langId: this.$route.params.id,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.$router.push('/settings/localization');
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>
.status-checkbox {
  margin-bottom: 30px;
}

.checkbox {
  margin-top: 3px;
}

.button-wrapper {
  display: flex;
  align-items: center;
}

.edit-button-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 26px;
}

.breadcrumbs {
  margin-bottom: 25px;
}

.system {
  color: #4c5057;
}
</style>
