<template>
  <div :class="error ? 'wrapper-error' : ''">
    <label
      :class="[
        'checkbox-container',
        isShortLabel
          ? 'short-label'
          : '',
        isDisabled
          ? 'disabled-checkbox'
          : '',
        error && 'failed',
        isMedia && 'remove-top-padding'
      ]"
    >
      <input
        v-model="valueModel"
        type="checkbox"
        :disabled="isDisabled"
      />
      <span
        :class="checkmarkClasses"
      ></span>
      <div
        :title="label"
        :class="['checkbox-label', isDisabled && 'disabled-label']"
      >
        {{ label }}
        <span
          v-if="caption"
          class="label-caption"
        >
          {{ caption }}
        </span>
      </div>
    </label>
    <transition name="bounce">
      <span
        v-show="error"
        class="error-label"
      >
        {{ error }}
      </span>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'MaterialCheckbox',
  props: {
    isShortLabel: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
    toggle: {
      type: Function,
      default: () => {},
    },
    checked: {
      type: [Boolean, Number],
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isMedia: {
      type: Boolean,
      default: false,
    },
    isAppsEngine: {
      type: Boolean,
      default: false,
    },
    isAlternative: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
      required: false,
    },
    isFailed: {
      type: Boolean,
      default: false,
    },
    caption: {
      type: String,
      default: '',
    },
  },
  computed: {
    valueModel: {
      get() {
        return this.checked;
      },
      set(value) {
        this.toggle(value);
      },
    },
    checkmarkClasses() {
      return {
        checkmark: true,
        'media-checkmark': this.isMedia,
        'alt-checkmark': this.isAlternative,
        'failed-checkmark': this.isFailed,
      };
    },
  },
};
</script>
<style scoped>
  .checkbox-container {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    margin-right: 25px;
    padding-top: 10px;
    position: relative;
    user-select: none;
  }

  .remove-top-padding {
    margin-top: -4px;
    padding-top: 0;
  }

  .checkbox-container input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  .checkmark {
    border: 1px solid #383b40;
    border-radius: 3px;
    box-sizing: border-box;
    height: 21px;
    margin-right: 6px;
    margin-top: -2px;
    width: 21px;
    min-width: 21px;
  }

  .checkbox-container:hover input ~ .checkmark {
    border: 1px solid #2591cf;
  }

  .disabled-checkbox:hover input ~ .checkmark {
    border: 1px solid #383b40;
    cursor: not-allowed;
  }

  .disabled-checkbox:hover {
    cursor: not-allowed;
  }

  .checkbox-container input:checked ~ .checkmark {
    border: 1px solid #2591cf;
  }

  .checkmark::after {
    content: '';
    display: none;
    position: absolute;
  }

  .checkbox-container input:checked ~ .checkmark::after {
    display: block;
  }

  .media-checkmark::after {
    top: 1px !important;
  }

  .checkbox-container .checkmark::after {
    border: solid #2591cf;
    border-width: 0 2px 2px 0;
    height: 10px;
    left: 7px;
    top: 10px;
    transform: rotate(45deg);
    width: 6px;
  }

  .short-label {
    margin-right: 5px;
  }

  .failed {
    border-bottom: 1px solid #fb3951;
    padding-bottom: 3px;
  }

  .wrapper-error {
    width: 260px;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  .checkbox-label {
    display: -webkit-box;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .disabled-label {
    color: rgba(255, 255, 255, 0.55);
  }

  .label-caption {
    color: #828282;
    font-size: 14px;
    margin-left: 5px;
    font-style: italic;
    font-weight: 400;
  }

  .alt-checkmark {
    border: 1px solid #fff;
  }

  .alt-checkmark:hover {
    border: 1px solid #2591cf !important;
  }

  .input:checked ~ .alt-checkmark {
    border: 1px solid #2591cf;
  }

  .disabled-checkbox:hover input ~ .alt-checkmark {
    border: 1px solid #2591cf;
    cursor: not-allowed;
  }

  .failed-checkmark {
    border: 1px solid #fb3951;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
</style>
