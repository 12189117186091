export const GET_ALL_EVENT_GROUPS = 'GET_ALL_EVENT_GROUPS';
export const GET_ALL_EVENT_GROUPS_REQUEST = 'GET_ALL_EVENT_GROUPS_REQUEST';
export const GET_ALL_EVENT_GROUPS_SUCCESS = 'GET_ALL_EVENT_GROUPS_SUCCESS';
export const GET_ALL_EVENT_GROUPS_ERROR = 'GET_ALL_EVENT_GROUPS_ERROR';

export const GET_CMS_EVENT_GROUPS_REQUEST = 'GET_CMS_EVENT_GROUPS_REQUEST';
export const GET_CMS_EVENT_GROUPS_SUCCESS = 'GET_CMS_EVENT_GROUPS_SUCCESS';
export const GET_CMS_EVENT_GROUPS_ERROR = 'GET_CMS_EVENT_GROUPS_ERROR';

export const GET_EVENT_GROUP = 'GET_EVENT_GROUP';
export const GET_EVENT_GROUP_REQUEST = 'GET_EVENT_GROUP_REQUEST';
export const GET_EVENT_GROUP_SUCCESS = 'GET_EVENT_GROUP_SUCCESS';
export const GET_EVENT_GROUP_ERROR = 'GET_EVENT_GROUP_ERROR';

export const ADD_EVENT_GROUP = 'ADD_EVENT_GROUP';
export const ADD_EVENT_GROUP_REQUEST = 'ADD_EVENT_GROUP_REQUEST';
export const ADD_EVENT_GROUP_SUCCESS = 'ADD_EVENT_GROUP_SUCCESS';
export const ADD_EVENT_GROUP_ERROR = 'ADD_EVENT_GROUP_ERROR';

export const EDIT_EVENT_GROUP = 'EDIT_EVENT_GROUP';
export const EDIT_EVENT_GROUP_REQUEST = 'EDIT_EVENT_GROUP_REQUEST';
export const EDIT_EVENT_GROUP_SUCCESS = 'EDIT_EVENT_GROUP_SUCCESS';
export const EDIT_EVENT_GROUP_ERROR = 'EDIT_EVENT_GROUP_ERROR';

export const DELETE_EVENT_GROUP = 'DELETE_EVENT_GROUP';
export const DELETE_EVENT_GROUP_REQUEST = 'DELETE_EVENT_GROUP_REQUEST';
export const DELETE_EVENT_GROUP_SUCCESS = 'DELETE_EVENT_GROUP_SUCCESS';
export const DELETE_EVENT_GROUP_ERROR = 'DELETE_EVENT_GROUP_ERROR';

export const EDIT_TV_APPS_EVENT_GROUP = 'EDIT_TV_APPS_EVENT_GROUP';
export const EDIT_TV_APPS_EVENT_GROUP_REQUEST = 'EDIT_TV_APPS_EVENT_GROUP_REQUEST';
export const EDIT_TV_APPS_EVENT_GROUP_SUCCESS = 'EDIT_TV_APPS_EVENT_GROUP_SUCCESS';
export const EDIT_TV_APPS_EVENT_GROUP_ERROR = 'EDIT_TV_APPS_EVENT_GROUP_ERROR';

export const GET_CUSTOM_FIELDS_EVENT_GROUPS_REQUEST = 'GET_CUSTOM_FIELDS_EVENT_GROUPS_REQUEST';
export const GET_CUSTOM_FIELDS_EVENT_GROUPS_SUCCESS = 'GET_CUSTOM_FIELDS_EVENT_GROUPS_SUCCESS';
export const GET_CUSTOM_FIELDS_EVENT_GROUPS_ERROR = 'GET_CUSTOM_FIELDS_EVENT_GROUPS_ERROR';
