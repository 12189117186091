import { extend, configure } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import i18n from './i18n';

const getFormFieldName = (form, field) => {
  const formFieldKey = `validation.forms.${form}.names.${field}`;
  const defaultFieldKey = `validation.forms.default.names.${field}`;

  if (i18n.te(formFieldKey)) {
    return i18n.t(formFieldKey);
  }
  if (i18n.te(defaultFieldKey)) {
    return i18n.t(defaultFieldKey);
  }

  return field;
};

const getFormFieldMessage = (form, field, values) => {
  /* eslint-disable-next-line no-underscore-dangle */
  const formFieldRuleKey = `validation.forms.${form}.fields.${field}.rules.${values._rule_}`;
  /* eslint-disable-next-line no-underscore-dangle */
  const defaultFormRuleKey = `validation.forms.${form}.rules.${values._rule_}`;
  /* eslint-disable-next-line no-underscore-dangle */
  const defaultFormFieldRuleKey = `validation.forms.default.fields.${field}.rules.${values._rule_}`;
  /* eslint-disable-next-line no-underscore-dangle */
  const defaultRuleKey = `validation.forms.default.rules.${values._rule_}`;

  if (i18n.te(formFieldRuleKey)) {
    return i18n.t(formFieldRuleKey, values);
  }
  if (i18n.te(defaultFormRuleKey)) {
    return i18n.t(defaultFormRuleKey, values);
  }
  if (i18n.te(defaultFormFieldRuleKey)) {
    return i18n.t(defaultFormFieldRuleKey, values);
  }
  if (i18n.te(defaultRuleKey)) {
    return i18n.t(defaultRuleKey, values);
  }

  return i18n.t('validation.forms.default.rules.default', values);
};

configure({
  defaultMessage: (field, values) => {
    const [
      formKey,
      fieldKey,
    ] = field.split('.');

    /* eslint-disable-next-line no-underscore-dangle */
    values._field_ = getFormFieldName(formKey, fieldKey);

    return getFormFieldMessage(formKey, fieldKey, values);
  },
});

Object.entries(rules).forEach(([rule, validation]) => {
  extend(rule, {
    ...validation,
  });
});

extend('required_if_not', {
  ...rules.required_if,
  validate: (value, args) => Boolean(args.target || value),
});

extend('is_valid_url', {
  ...rules.regex,
  validate: (value) => {
    let url;

    try {
      url = new URL(value);
    } catch (e) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  },
});

extend('is_match_name', {
  ...rules.is,
  validate: (value, args) => value === args.other,
});
