<template>
  <div class="overlay">
    <Loader :isVisible="isloading" />
    <div v-if="modalMode === 'delete'">
      <DeleteModalContent
        :modalData="modalData"
        :onCancel="onClose"
      />
    </div>
    <div v-if="modalMode === 'add'">
      <AddModalContent
        :modalData="modalData"
        :onCancel="onClose"
      />
    </div>
    <div v-if="modalMode === 'edit'">
      <EditModalContent
        :modalData="modalData"
        :onCancel="onClose"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AddModalContent from './AddModalContent.vue';
import DeleteModalContent from './DeleteModalContent.vue';
import EditModalContent from './EditModalContent.vue';
import { SET_EDIT_FORM } from '../../../../store/actions/common';
import Loader from '../../../common/Loader.vue';

export default {
  name: 'TreeModal',
  components: {
    Loader,
    AddModalContent,
    DeleteModalContent,
    EditModalContent,
  },
  props: {
    modal: Boolean,
    title: {
      type: String,
      default: '',
    },
    modalData: {
      type: Object,
      default: null,
    },
    modalMode: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      isEditedForm: (state) => state.common.isEditedForm,
      isLoading: (state) => state.mediaTree.loaded,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  methods: {
    preventNav(event) {
      if (this.modal && this.isEditedForm) {
        event.returnValue = 'You have unfinished changes!';
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
    },
  },
};
</script>
<style>
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }
</style>
