<template>
  <div class="settings main-body-right">
    <div
      class="wrap tree-box"
    >
      <div class="system">
        <div class="system-title">
          Media
        </div>
        <a
          v-if="!isRead && getCurrentAccount.tvAppsEnabled"
          :class="['add-to-tv', { 'active' : isEditTvFolder }]"
          @click="onAddMediaToTv"
        >
          <inline-svg
            :src="isEditTvFolder ? iconAddToTvWhite : iconAddToTvBlue"
            :title="'TV Apps Distribution'"
            class="svg-icon"
          >
          </inline-svg>
        </a>
        <a
          v-if="!isRead"
          :class="['add-button', isDisabled ? 'disabled-add-button' : '']"
          :disabled="isDisabled"
          @click="addMedia"
        >
          <inline-svg
            :src="iconAdd"
            class="svg-icon add-icon"
          >
          </inline-svg>
          Add
        </a>
      </div>
      <div class="nav sub-nav ">
        <MediaTree
          :openModal="openModal"
          :folderId="folderId"
          :getMediaList="getMediaList"
          :mediaList="mediaList"
          :isEditTvFolder="isEditTvFolder"
          :onCloseEditingTvFolders="onCloseEditingTvFolders"
        />
      </div>
    </div>
    <router-view />
    <div
      v-if="!isAddLive"
      class="right-panel"
    >
      <div v-if="!notFoundMediaPage">
        <div class="btn-top-container">
          <div
            ref="mobileTreeBtn"
            class="mobile-folder"
          >
            <a
              @click="toggleMobileTree"
            >
              <inline-svg
                :src="iconMobileFolder"
                class="svg-icon"
              />
            </a>
          </div>
          <div
            v-if="!isRead"
            class="search-box"
          >
            <UploadXML
              :folderId="folderId"
              :getMediaList="getMediaList"
              :isDisabled="isDisabled"
            />
            <UploadMedia
              :folderId="folderId"
              :getMediaList="getMediaList"
              :isDisabled="isDisabled"
            />
            <Button
              :title="'Add Live Stream'"
              :classType="'primary'"
              :eventFunction="toAddLiveStreamHandler"
              :disabled="isDisabled"
            />
          </div>
        </div>
        <div ref="mobileTree">
          <div
            v-if="isMobileTree"
            class="mobile-media-tree-slider"
          >
            <div class="system">
              <div class="system-title">
                Media
              </div>
              <a
                v-if="getCurrentAccount.tvAppsEnabled"
                :class="['add-to-tv', { 'active' : isEditTvFolder }]"
                @click="onAddMediaToTv"
              >
                <inline-svg
                  :src="isEditTvFolder ? iconAddToTvWhite : iconAddToTvBlue"
                  :title="'TV Apps Distribution'"
                  class="svg-icon"
                >
                </inline-svg>
              </a>
              <a
                :class="['add-button', isDisabled ? 'disabled-add-button' : '']"
                :disabled="isDisabled"
                @click="addMedia"
              >
                <inline-svg
                  :src="iconAdd"
                  class="svg-icon add-icon"
                >
                </inline-svg>
                Add
              </a>
            </div>
            <div class="nav sub-nav ">
              <MediaTree
                :openModal="openModal"
                :folderId="folderId"
                :getMediaList="getMediaList"
                :mediaList="mediaList"
                :isEditTvFolder="isEditTvFolder"
                :onCloseEditingTvFolders="onCloseEditingTvFolders"
              />
            </div>
          </div>
          <div class="search">
            <div class="search-checkbox-container">
              <div class="filters-wrapper">
                <div class="show-filter-text">
                  Show:
                </div>
                <div style="width: 360px;">
                  <MediaTagManagement
                    :selectedValue="includedTypes"
                    :label="''"
                    :tagPlaceholder="''"
                    :searchable="false"
                    :onChange="onTagIncludedTypesChange"
                    :customOptions="typesOptions"
                    :isAudio="true"
                    :isSmall="true"
                  />
                </div>
              </div>
              <div style="margin-left: 440px">
                <MaterialCheckbox
                  :toggle="toggleShowChildren"
                  :label="'Only Parent Media'"
                  :checked="!isShowChildren && Number(folderId) !== 0"
                  :isDisabled="(Number(folderId) === 0)"
                  :isMedia="true"
                />
              </div>
            </div>
            <div class="search-container-media">
              <LiveSearch
                :onSearch="onSearch"
                :maxlength="100"
              />
              <MediaSort
                v-model="currentSortedField"
                :sortByList="sortByList"
                :currentSortedField="currentSortedField"
                :handleSortUp="handleSortUp"
                :handleSortDown="handleSortDown"
                :handleSortTypeChange="handleSortTypeChange"
              />
            </div>
          </div>
        </div>
        <div>
          <MediaList
            :mediaList="mediaList"
            :getMediaList="getMediaList"
            :isLoader="isLoading"
            :page="pagination.page"
            :hidePagination="hidePagination"
            :showPagination="showPagination"
          />
        </div>
        <div
          v-if="!isHidePagination"
          class="pagination-wrapper"
        >
          <Pagination
            :action="getMediaList"
            :page="pagination.page || 1"
            :currentPage="currentPage"
            :totalPages="totalPages"
          />
        </div>
      </div>
      <div v-else>
        <div class="not-found-wrapper">
          <h2>Ooops... Folder not found.</h2>
        </div>
      </div>
      <div ref="treeModal">
        <div v-if="modal">
          <TreeModal
            :modal="modal"
            :modalMode="modalMode"
            :modalData="modalData"
            :onClose="onClose"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { SET_EDIT_FORM } from '../../store/actions/common';
import {
  GET_MEDIA_TREE,
} from '../../store/actions/media/mediaTree';
import {
  GET_MEDIA_LIST,
  GET_TRANSCODING_PROGRESS_SSE_CLOSED,
  GET_TRANSCODING_PROGRESS_SSE_REQUEST,
} from '../../store/actions/media/mediaList';

import CONFIG from '../../constants/config';
import iconSortDown from '../../assets/icons/icon-sort-arrow-down.svg';
import iconAdd from '../../assets/icons/media-add-folder.svg';
import iconMobileFolder from '../../assets/icons/mobile-tree-folder.svg';
import iconAddToTvBlue from '../../assets/icons/icon-settings-blue.svg';
import iconAddToTvWhite from '../../assets/icons/icon-settings-white.svg';

import MediaTree from '../../components/media/tree/MediaTree.vue';
import TreeModal from '../../components/media/tree/modal/TreeModal.vue';
import UploadMedia from '../../components/common/UploadMedia.vue';
import MediaList from '../../components/media/MediaList.vue';
import Pagination from '../../components/common/Pagination.vue';
import LiveSearch from '../../components/common/LiveSearch.vue';
import MediaSort from '../../components/media/MediaSort.vue';
import MaterialCheckbox from '../../components/inputs/MaterialCheckbox.vue';
import Button from '../../components/common/Button.vue';
import UploadXML from '../../components/media/UploadXML.vue';
import MediaTagManagement from '../../components/media/MediaTagManagement.vue';

export default {
  name: 'Media',
  components: {
    MediaTagManagement,
    UploadXML,
    MaterialCheckbox,
    MediaTree,
    TreeModal,
    UploadMedia,
    MediaList,
    Pagination,
    LiveSearch,
    MediaSort,
    Button,
  },
  props: {
    folderId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      typesOptions: [
        {
          code: 'vod',
          name: 'VoD',
        },
        {
          code: 'audio',
          name: 'Audio Only',
        },
        {
          code: 'livestream',
          name: 'Live Events',
        },
      ],
      mediaTree: {},
      iconAdd,
      iconMobileFolder,
      isHidePagination: false,
      modal: false,
      titleModal: '',
      modalMode: '',
      modalData: {},
      mediaList: [],
      filter: '',
      isLiveEvents: false,
      isAudio: false,
      isShowChildren: 1,
      currentSortedField: 'mi.airDate',
      currentSortBy: CONFIG.sort.sortDown,
      sortByList: CONFIG.sortByMediaList,
      iconSortDown,
      notFoundMediaPage: false,
      isMobileTree: false,
      isEditTvFolder: false,
      iconAddToTvBlue,
      iconAddToTvWhite,
      includedTypes: null,
    };
  },
  computed: {
    ...mapState({
      pagination: (state) => state.mediaList.pagination,
      isLoading: (state) => state.mediaList.isLoading,
      currentPage: (state) => state.mediaList.currentPage,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
      'getRootMediaFolderId',
      'isRead',
    ]),
    isDisabled() {
      return Number(this.folderId) === 0;
    },
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    isAddLive() {
      return this.$route.name === CONFIG.stream.routeToStream;
    },
  },
  watch: {
    folderId() {
      this.getMediaList(1);
    },
  },
  created() {
    localStorage.setItem('tvApps', JSON.stringify([]));
    if (!this.$route.params.folderId) {
      this.$router.push(`/media/folder/${this.getRootMediaFolderId}`);
    }

    if (+this.$route.params.folderId === this.getRootMediaFolderId) {
      sessionStorage.setItem('parentsPath', '');
    }

    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    this.getMediaTree();
    this.$store.dispatch(GET_TRANSCODING_PROGRESS_SSE_REQUEST);
    document.addEventListener('mousedown', this.clickOutside);
  },
  destroyed() {
    this.$store.dispatch(GET_TRANSCODING_PROGRESS_SSE_CLOSED);
    document.removeEventListener('mousedown', this.clickOutside);
  },
  beforeRouteUpdate(to, from, next) {
    if (this.modal) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (this.modal) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  methods: {
    onTagIncludedTypesChange(value) {
      this.includedTypes = value;
      this.getMediaList(1);
    },
    clickOutside(e) {
      if (this.$refs.mobileTree
        && !this.$refs.mobileTree.contains(e.target)
        && !this.$refs.mobileTreeBtn.contains(e.target)
        && !this.$refs.treeModal.contains(e.target)) {
        this.isMobileTree = false;
      }
    },
    toggleMobileTree() {
      this.isMobileTree = !this.isMobileTree;
    },
    getMediaTree() {
      this.$store.dispatch(GET_MEDIA_TREE, this.getCurrentAccountId)
        .then((resp) => {
          this.mediaTree = resp;

          if (this.folderId === 'all') {
            const { id } = this.mediaTree.data.ALL;
            this.$router.push(`/media/folder/${id}`);
          } else {
            this.getMediaList(localStorage.getItem('currentPage-media-list') || 1);
          }
        });
    },
    toggleShowChildren(isChecked) {
      const value = !isChecked;
      if (value !== this.isShowChildren) {
        this.isShowChildren = value ? 1 : 0;
        this.getMediaList(1);
      }
    },
    handleSortUp(field) {
      this.currentSortBy = CONFIG.sort.sortUp;
      this.currentSortedField = field;
      this.getMediaList(1);
    },
    handleSortDown(field) {
      this.currentSortBy = CONFIG.sort.sortDown;
      this.currentSortedField = field;
      this.getMediaList(1);
    },
    onSelectChange() {
      return null;
    },
    hidePagination() {
      this.isHidePagination = true;
    },
    showPagination() {
      this.isHidePagination = false;
    },
    onSearch(params) {
      this.filter = params.filterValue;
      this.getMediaList(1);
    },
    openModal(mode, data) {
      this.modalMode = mode;
      this.modalData = data;
      this.modal = true;
    },
    addMedia() {
      if (!this.isDisabled) {
        this.modalMode = 'add';
        this.modalData = {
          folder: this.folderId,
        };
        this.modal = true;
      }
    },
    onClose() {
      this.modal = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    getMediaList(page) {
      const params = {
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
        isShowChildren: this.isShowChildren,
        types: this.includedTypes,
      };

      const data = {
        accountId: this.getCurrentAccountId,
        folderId: this.folderId,
        params,
      };
      this.$store.dispatch(GET_MEDIA_LIST, data)
        .then((mediaList) => {
          this.notFoundMediaPage = false;
          this.mediaList = mediaList.data;
        }).catch((err) => {
          if (err.response && (err.response.status === 404 || err.response.status === 403)) {
            this.notFoundMediaPage = true;
          }
        });
    },
    toAddLiveStream() {
      if (!this.isDisabled) {
        this.$router.push(`${this.folderId}/add-live`);
      }
    },
    handleSortTypeChange(result) {
      this.currentSortedField = result;
      this.getMediaList(1);
    },
    toAddLiveStreamHandler() {
      if (!this.isDisabled) {
        this.toAddLiveStream();
      }
    },
    onAddMediaToTv() {
      this.isEditTvFolder = true;
    },
    onCloseEditingTvFolders() {
      this.isEditTvFolder = false;
      this.getMediaTree();
    },
  },
};
</script>
<style scoped>
  .system {
    border-bottom: 1px solid #383b40;
    display: flex;
    justify-content: space-between;
    padding: 30px 0 25px 25px;
  }

  .system-title {
    font-size: 16px;
  }

  .add-button {
    margin-right: 20px;
  }

  .add-button:hover {
    cursor: pointer;
  }

  .add-icon {
    vertical-align: middle;
  }
  .add-to-tv {
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
  }
  .wrap {
    border-right: 1px solid #383b40;
    flex-direction: column;
    min-width: 330px;
  }

  .tree-box {
    display: inline-block;
    padding-bottom: 20px;
    white-space: nowrap;
  }

  .main-body-right {
    display: flex !important;
    padding: 0 !important;
  }

  .nav {
    background: transparent;
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 325px;
  }

  .disabled-add-button:hover {
    cursor: not-allowed;
  }

  .right-panel {
    overflow-y: auto;
    padding: 10px 15px;
    position: relative;
    width: 100%;
  }

  .search-box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    width: 100%;
  }

  .add-live {
    background-color: #2591cf;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 16px;
    height: 40px;
    letter-spacing: normal;
    line-height: 22px;
    margin-left: 15px;
    padding: 10px 30px;
    text-decoration: none;
    text-transform: none;
    transition: all 100ms ease;
  }

  .search {
    align-items: center;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .disabled-button {
    cursor: not-allowed;
  }

  .media-breadcrumbs {
    margin-right: 30px;
    max-width: 35%;
  }

  .not-found-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 50vh;
  }

  .search-container-media {
    display: flex;
  }

  .btn-top-container {
    display: flex;
  }

  .mobile-folder,
  .mobile-media-tree-slider {
    display: none;
  }

  .search-checkbox-container {
    display: flex;
  }

  .filters-wrapper {
    /*min-width: 280px;*/
    /*max-width: 360px;*/
    display: flex;
    align-items: flex-end;
    /*margin-right: 40px;*/
    position: absolute;
    bottom: -3px;
  }

  .show-filter-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  @media all and (max-width: 1200px) {
    .tree-box {
      display: none;
    }

    .mobile-folder {
      display: block;
    }

    .search-wrapper {
      margin-right: 10px;
    }

    .mobile-folder {
      cursor: pointer;
    }

    .mobile-media-tree-slider {
      background-color: #222;
      border: 1px solid #383b40;
      border-left: 0;
      display: block;
      margin-left: -20px;
      position: absolute;
      width: 320px;
      z-index: 9;
    }

    .tree-container {
      height: calc(100vh - 200px);
    }
  }
</style>
