<template>
  <div>
    <div class="checks-wrapper">
      <div
        v-for="option in options"
        :key="option[tagTrackBy]"
        class="option"
      >
        <input
          :id="`${name}-${option[tagTrackBy]}`"
          v-model="selectValue"
          :value="option[tagTrackBy]"
          type="radio"
          class="radio"
          :name="name"
          :disabled="disabled"
          @change="onSelectChange"
        />
        <label
          :for="`${name}-${option[tagTrackBy]}`"
          class="label"
        >
          {{ option[tagLabel] }}
        </label>
      </div>
      <div
        :key="customKey"
        class="option"
      >
        <input
          :id="customKey"
          v-model="selectValue"
          :value="customKey"
          type="radio"
          class="radio"
          :name="name"
          :disabled="disabled"
          @change="onSelectChange"
        />
        <label
          :for="customKey"
          class="label"
        >
          Custom value
        </label>
      </div>
    </div>
    <div class="input-wrapper">
      <input
        v-if="selectValue === customKey"
        v-model="inputValue"
        :type="type"
        :disabled="disabled"
        :maxlength="maxlength"
        :class="className"
        v-bind="ariaInput"
        @input="onInputChange"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'RadioCustomList',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return [
          'url',
          'text',
          'password',
          'tel',
          'search',
          'number',
          'email',
        ].includes(value);
      },
    },
    options: {
      type: Array,
      default: null,
    },
    name: {
      type: String,
      default: 'radio-group',
    },
    tagLabel: {
      type: String,
      default: 'name',
    },
    tagTrackBy: {
      type: String,
      default: 'code',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ariaInput: {
      type: Object,
      default: () => {},
    },
    className: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      customKey: this.initCustomKey(),
      selectValue: this.initSelectValue(),
      inputValue: this.initInputValue(),
    };
  },
  methods: {
    initSelectValue() {
      return this.options.some((i) => i.code === this.value) || !this.value
        ? this.value
        : this.initCustomKey();
    },
    initInputValue() {
      return this.value && !this.options.some((i) => i.code === this.value)
        ? this.value
        : '';
    },
    initCustomKey() {
      const max = Math.max(...this.options.map((item) => item.code.length), 0);
      return `${this.name}-customKey-${'i'.repeat(max)}`;
    },
    onSelectChange() {
      this.updateModelValue();
    },
    onInputChange() {
      this.updateModelValue();
    },
    updateModelValue() {
      let value = this.inputValue;

      if (this.selectValue !== this.customKey) {
        value = this.selectValue;
      }

      this.$emit('input', value);
    },
  },
};
</script>

<style scoped>
  .checks-wrapper {
    display: flex;
    flex: 86%;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 10px;
    width: 100%;
  }

  .option {
    cursor: pointer;
    display: flex;
    /*flex: 20%;*/
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 113px;
    position: relative;
    user-select: none;
  }

  .label {
    font-size: 14px;
    padding-left: 10px;
  }

  .radio {
    width: auto;
  }

  .input-wrapper input:focus {
    outline: none;
  }

  .input-wrapper input {
    background: #383b40;
    border: 0;
    border-radius: 0;
    height: 48px;
    margin-top: 10px;
    padding: 15px;
  }

  .input-wrapper input.inspector-input {
    background: #222;
  }
</style>
