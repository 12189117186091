import iconMedia from '../../assets/icons/icon-media.svg';
import iconSetting from '../../assets/icons/icon-settings.svg';
import iconFeed from '../../assets/icons/icon-feed.svg';
import iconAnalytics from '../../assets/icons/icon-analytics.svg';
import iconSchedule from '../../assets/icons/icon-schedule.svg';
import iconCMS from '../../assets/icons/icon-cms.svg';

export default [
  {
    title: 'Media',
    icon: iconMedia,
    link: '/media/folder/all',
    roles: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_READ_ONLY'],
    activeLink: (page) => page.includes('media'),
  },
  {
    title: 'Schedule',
    icon: iconSchedule,
    link: '/schedule',
    roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    subItems: [
      {
        title: 'Scheduler Settings',
        link: '/schedule/settings',
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
      {
        title: 'Delivery Settings',
        link: '/schedule/delivery-settings',
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
      {
        title: 'Event Groups',
        link: '/schedule/event-group',
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
      {
        title: 'Standalone',
        link: '/schedule/standalone-event',
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
      {
        title: 'Apps Event Group',
        link: '/schedule/apps-event-group',
        check: true,
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
    ],
    activeLink: (page) => page.includes('schedule'),
  },
  {
    title: 'Collections',
    icon: iconFeed,
    link: '/collections/feeds',
    roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    subItems: [
      {
        title: 'Feeds',
        link: '/collections/feeds',
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
      {
        title: 'Playlists',
        link: '/collections/playlists',
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
      {
        title: 'Features',
        link: '/collections/features',
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
    ],
    activeLink: (page) => page.includes('collections'),
  },
  {
    title: 'Video Apps Engine',
    icon: iconCMS,
    link: '/cms',
    roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    subItems: [
      {
        title: 'Pages',
        link: '/cms/pages',
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
      {
        title: 'Banners & Sliders',
        link: '/cms/banners',
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
      {
        title: 'Navigation',
        link: '/cms/navigation',
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
      {
        title: 'General Configuration',
        link: '/cms/global-configurations',
        roles: ['ROLE_ADMIN'],
      },
    ],
  },
  {
    title: 'Analytics',
    icon: iconAnalytics,
    link: '/analytics/overview',
    roles: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_READ_ONLY'],
  },
  {
    title: 'System Management',
    icon: iconSetting,
    link: '/settings',
    roles: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPER_ADMIN'],
    subItems: [
      {
        title: 'Account Users',
        link: '/settings/users',
        roles: ['ROLE_ADMIN'],
      },
      {
        title: 'Users',
        link: '/settings/users',
        roles: ['ROLE_SUPER_ADMIN'],
      },
      {
        title: 'Accounts',
        link: '/settings/accounts',
        roles: ['ROLE_SUPER_ADMIN'],
      },
      {
        title: 'API Keys',
        link: '/settings/api-keys',
        roles: ['ROLE_SUPER_ADMIN'],
      },
      {
        title: 'Servers',
        link: '/settings/servers',
        roles: ['ROLE_SUPER_ADMIN'],
      },
      {
        title: 'People',
        link: '/settings/people',
        roles: ['ROLE_ADMIN'],
      },
      {
        title: 'Roles',
        link: '/settings/roles',
        roles: ['ROLE_ADMIN'],
      },
      {
        title: 'Custom Fields',
        link: '/settings/fields',
        roles: ['ROLE_ADMIN'],
      },
      {
        title: 'Account Settings',
        link: '/settings/account',
        roles: ['ROLE_ADMIN'],
      },
      {
        title: 'Localization',
        link: '/settings/localization',
        roles: ['ROLE_ADMIN'],
      },
      {
        title: 'Asset Roles',
        link: '/settings/asset-roles',
        roles: ['ROLE_ADMIN'],
      },
      {
        title: 'Account API Keys',
        link: '/settings/api-keys-admin',
        roles: ['ROLE_ADMIN'],
      },
      {
        title: 'Categories',
        link: '/settings/categories',
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
      {
        title: 'Genres',
        link: '/settings/Genres',
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
    ],
  },
  {
    title: 'Dashboard',
    icon: 'fas fa-tachometer-alt',
    link: '/dashboard',
    roles: ['SUPERADMIN'],
  },
  {
    title: 'accounts',
    icon: 'fas fa-user-friends',
    link: '/accounts',
    roles: ['SUPERADMIN'],
  },
  {
    title: 'Settings',
    icon: 'fas fa-wrench',
    link: '/settings/advanced',
    roles: ['SUPERADMIN'],
  },
];
