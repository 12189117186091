<template>
  <div>
    <ValidationObserver
      ref="validationObserverRTMP"
      v-slot="{ handleSubmit }"
    >
      <form
        :key="updateKey"
        novalidate="novalidate"
        @submit.prevent="handleSubmit(submit)"
        @input="handleFormChange"
      >
        <MaterialInput
          v-model="formData.facebookTitle"
          v-bind="formFields.facebookTitle"
        />
        <MaterialInput
          v-model="formData.facebookDescription"
          v-bind="formFields.facebookDescription"
        />
        <div
          v-if="editDeliveryTarget && editDeliveryTarget.id ? (fbEditData && fbData) : fbData"
          class="localization-warning-wrapper"
        >
          <inline-svg
            :src="iconWarning"
            class="svg-icon"
          >
          </inline-svg>
          <span class="localization-warning">
            Token will expire on {{ tokenExpiredAt(fbData && fbData.facebookAccessTokenExpiresAt) }}
          </span>
        </div>
        <div class="login-text">
          <span :class="[{'error-item' : !isLoggedIntoFB}]">
            {{ facebookStatus }}
          </span>
        </div>
        <div
          v-if="tokenError"
          class="error-item"
        >
          Facebook access token is invalid
        </div>
        <FacebookButton
          :title="isLoggedIntoFB ? 'Log out' : 'Login to Facebook'"
          :handleClick="handleFbClick"
        />
        <div v-if="fbData || (editDeliveryTarget && editDeliveryTarget.params)">
          <MaterialSelect
            v-model="formData.facebookDestType"
            v-bind="formFields.facebookDestType"
            :withEmptyField="false"
            :options="facebookDestType"
            :disabled="!isLoggedIntoFB"
          />
          <MaterialSelect
            v-if="formData.facebookDestType === 'timeline'"
            v-model="formData.facebookPrivacy"
            v-bind="formFields.facebookPrivacy"
            :withEmptyField="false"
            :disabled="!isLoggedIntoFB"
            :options="facebookPrivacy"
          />
          <MaterialSelect
            v-if="formData.facebookDestType === 'account' && fbAccounts.length"
            v-model="formData.facebookAccount"
            v-bind="formFields.facebookAccount"
            :withEmptyField="false"
            :disabled="!isLoggedIntoFB"
            :options="fbAccounts"
          />
          <div v-if="formData.facebookDestType === 'account' && !fbAccounts.length">
            No accounts to display
          </div>
          <MaterialSelect
            v-if="formData.facebookDestType === 'group' && fbGroups.length"
            v-model="formData.facebookGroup"
            v-bind="formFields.facebookGroup"
            :withEmptyField="false"
            :disabled="!isLoggedIntoFB"
            :options="fbGroups"
          />
          <div v-if="formData.facebookDestType === 'group' && !fbGroups.length">
            No groups to display
          </div>
        </div>
        <div class="button-wrapper">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="onCancelHandle"
          />
          <Button
            :title="editDeliveryTarget ? 'Apply' : 'Add'"
            type="submit"
            :disabled="isDisable"
            :classType="'primary'"
          />
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'moment-timezone';
import { mapGetters, mapState } from 'vuex';
import {
  GET_FB_LINK,
  FB_LINK_RESET,
  GET_FB_LOGOUT,
  RESET_FB_DATA_EDIT, ACTIVE_FB_DATA_EDIT,
} from '../../../store/actions/liveEvent/liveEvent';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import CONFIG from '../../../constants/config';
import Button from '../../common/Button.vue';
import FacebookButton from '../../common/FacebookButton.vue';
import iconWarning from '../../../assets/icons/icon-warning-localization.svg';

export default {
  name: 'FacebookDeliveryTargetForm',
  components: {
    MaterialInput,
    MaterialSelect,
    Button,
    FacebookButton,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'facebookDeliveryTarget',
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    editDeliveryTarget: {
      type: Object,
      default: null,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    itemErrors: {
      type: Object,
      default: null,
    },
    fbEditData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      iconWarning,
      validationObserver: 'validationObserverRTMP',
      facebookDestType: CONFIG.facebookDeliveryTargets.facebookDestType,
      facebookPrivacy: CONFIG.facebookDeliveryTargets.facebookPrivacy,
      facebookStatus: CONFIG.facebookDeliveryTargets.loginStatus.login,
      openedWindow: null,
      tokenError: false,
      updateKey: 0,
      fbData: localStorage.getItem('fb-Data')
        ? JSON.parse(localStorage.getItem('fb-Data'))
        : this.fbDataComputed,
      formData: {
        facebookTitle: '',
        facebookDescription: '',
        facebookDestType: '',
        facebookDestId: '',
        facebookPrivacy: '',
        facebookGroup: '',
        facebookAccount: '',
        facebookDestName: '',
      },
      formFields: {
        facebookTitle: {
          type: 'text',
          name: 'facebookTitle',
          formPrefix: this.formPrefix,
          rules: {
            max: 255,
            required: true,
          },
        },
        facebookDescription: {
          type: 'text',
          name: 'facebookDescription',
          formPrefix: this.formPrefix,
          rules: {
            max: 500,
            required: true,
          },
        },
        facebookDestType: {
          type: 'text',
          name: 'facebookDestType',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        facebookPrivacy: {
          type: 'text',
          name: 'facebookPrivacy',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        facebookGroup: {
          type: 'text',
          name: 'facebookGroup',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        facebookAccount: {
          type: 'text',
          name: 'facebookAccount',
          formPrefix: this.formPrefix,
          rules: {
            required: true,

          },
        },
      },
      errorMapping: {
        facebookEncryptedAccessToken: [
          `${this.formPrefix}.facebookEncryptedAccessToken`,
        ],
        facebookTitle: [
          `${this.formPrefix}.facebookTitle`,
          `${this.formPrefix}.accountSchedule`,
          `${this.formPrefix}.mediaItem`,
        ],
        facebookDescription: [
          `${this.formPrefix}.facebookDescription`,
        ],
        facebookDestType: [
          `${this.formPrefix}.facebookDestType`,
        ],
        facebookDestId: [
          `${this.formPrefix}.facebookDestId`,
        ],
      },
      successMessage: CONFIG.successMessages.addMediaFolderMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  watch: {
    fbData(val) {
      this.updateData(val);
      // this.openedWindow.close();
      // this.enableChangeUser = true;
      this.tokenError = false;
    },
  },
  computed: {
    ...mapGetters([
      'getMainProfile',
      'fbDataComputed',
    ]),
    ...mapState({
      // fbDataComputed: (state) => state.liveStream.fbData,
      fbLinkWindow: (state) => state.liveStream.fbLinkWindow,
    }),
    // fbData() {
    //   if (this.fbDataComputed) {
    //     return this.fbDataComputed;
    //   }
    //
    //   if (localStorage.getItem('fb-Data')) {
    //     return JSON.parse(localStorage.getItem('fb-Data'));
    //   }
    //
    //   return null;
    // },
    fbGroups() {
      const data = this.fbData || this.editDeliveryTarget.fbData || this.editDeliveryTarget.params;
      if (data) {
        if (data.facebookUserData) {
          const { groups } = data.facebookUserData;
          return groups.map((item) => ({
            name: item.name,
            code: item.id,
          }));
        }

        if (this.editDeliveryTarget && this.editDeliveryTarget.facebookDestType === 'group') {
          return [{
            name: this.editDeliveryTarget.facebookDestName,
            code: this.editDeliveryTarget.facebookDestId,
          }];
        }

        if (this.editDeliveryTarget
          && !this.editDeliveryTarget.fbData
          && this.editDeliveryTarget.params.facebookDestType === 'group') {
          return [{
            name: this.editDeliveryTarget.params.facebookDestName,
            code: this.editDeliveryTarget.params.facebookDestId,
          }];
        }
      }

      return [];
    },
    fbAccounts() {
      const data = this.fbData || this.editDeliveryTarget.fbData || this.editDeliveryTarget.params;
      if (data) {
        if (data.facebookUserData) {
          const { accounts } = data.facebookUserData;
          return accounts.map((item) => ({
            name: item.name,
            code: item.id,
          }));
        }
        if (this.editDeliveryTarget && this.editDeliveryTarget.facebookDestType === 'account') {
          return [{
            name: this.editDeliveryTarget.facebookDestName,
            code: this.editDeliveryTarget.facebookDestId,
          }];
        }
        if (this.editDeliveryTarget && !this.editDeliveryTarget.fbData && this.editDeliveryTarget.params.facebookDestType === 'account') {
          return [{
            name: this.editDeliveryTarget.params.facebookDestName,
            code: this.editDeliveryTarget.params.facebookDestId,
          }];
        }
      }

      return [];
    },
    isLoggedIntoFB() {
      return this.editDeliveryTarget && this.editDeliveryTarget.id
        ? (this.fbEditData && this.fbData && this.fbData.facebookEncryptedAccessToken)
        : this.fbData && this.fbData.facebookEncryptedAccessToken;
    },
    isDisable() {
      if (!this.fbData && !this.editDeliveryTarget) {
        return true;
      }

      if (!this.fbData && this.editDeliveryTarget) {
        return !this.editDeliveryTarget.params;
      }

      if ((this.formData.facebookDestType === 'group' && !this.fbGroups.length)
        || (this.formData.facebookDestType === 'account' && !this.fbAccounts.length)) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    window.addEventListener('storage', (event) => {
      if (event.key === 'fb-Data') {
        this.fbData = JSON.parse(event.newValue);
        this.updateData(JSON.parse(event.newValue));
      }
    });

    const formErrors = this.getServerFormErrors({ form_errors: this.itemErrors });
    this.$refs[this.validationObserver].setErrors(formErrors);
    this.tokenError = formErrors && formErrors.facebookEncryptedAccessToken;

    if (this.editDeliveryTarget && this.editDeliveryTarget.params) {
      const { params } = this.editDeliveryTarget;
      this.fbData = this.fbEditData ? this.fbEditData : this.editDeliveryTarget.fbData;
      this.updateData(this.fbData);
      this.formData.facebookTitle = params.facebookTitle;
      this.formData.facebookDescription = params.facebookDescription;
      this.formData.facebookEncryptedAccessToken = params.facebookEncryptedAccessToken;
      this.formData.facebookDestType = params.facebookDestType;

      if (params.facebookDestType === 'timeline') {
        this.formData.facebookDestId = params.facebookDestId;
        this.formData.facebookPrivacy = params.facebookPrivacy;
      }

      if (params.facebookDestType === 'group' && this.fbGroups.length) {
        this.formData.facebookDestId = params.facebookDestId;
        this.formData.facebookDestName = params.facebookDestName;
        this.formData.facebookGroup = this.fbGroups.find(
          (item) => Number(item.code) === Number(params.facebookDestId),
        ).code;
      }

      if (params.facebookDestType === 'account' && this.fbAccounts.length) {
        this.formData.facebookDestId = params.facebookDestId;
        this.formData.facebookDestName = params.facebookDestName;
        this.formData.facebookAccount = this.fbAccounts.find(
          (item) => Number(item.code) === Number(params.facebookDestId),
        ).code;
      }
    }

    if (this.fbData && this.fbData.facebookUserData) {
      this.formData.facebookEncryptedAccessToken = this.fbData.facebookEncryptedAccessToken;
      this.facebookStatus = this.fbData.facebookUserData.name
        + CONFIG.facebookDeliveryTargets.loginStatus.logged;
    }
  },
  methods: {
    updateData(fbData) {
      if (fbData) {
        if (!this.formData.facebookDestType) {
          this.formData.facebookDestType = CONFIG.facebookDeliveryTargets.facebookDestType[0].code;

          if (!this.formData.facebookPrivacy) {
            this.formData.facebookPrivacy = CONFIG.facebookDeliveryTargets.facebookPrivacy[0].code;
          }

          if (!this.formData.facebookDestId) {
            this.formData.facebookDestId = fbData.facebookUserData
              ? fbData.facebookUserData.id
              : null;
          }
        }

        this.formData.facebookEncryptedAccessToken = fbData.facebookEncryptedAccessToken;

        if (fbData.facebookUserData) {
          this.facebookStatus = fbData.facebookUserData.name
            + CONFIG.facebookDeliveryTargets.loginStatus.logged;
        } else {
          this.facebookStatus = CONFIG.facebookDeliveryTargets.loginStatus.login;
        }
      }
    },
    handleFbClick() {
      this.facebookStatus = CONFIG.facebookDeliveryTargets.loginStatus.process;
      if (this.isLoggedIntoFB) {
        this.$store.dispatch(ACTIVE_FB_DATA_EDIT);
        const data = {
          facebookEncryptedAccessToken: this.formData.facebookEncryptedAccessToken,
          callbackUrl: `${process.env.VUE_APP_FRONTEND_SCHEMA}://${process.env.VUE_APP_FRONTEND_HOST}/facebook-logout`,
        };
        this.$store.dispatch(GET_FB_LOGOUT, data).then((res) => {
          this.openedWindow = window.open(res, '', 'popup');
          this.$store.dispatch(RESET_FB_DATA_EDIT);
          this.$store.dispatch(FB_LINK_RESET);
          this.logoutFb();
        });
      } else {
        this.$store.dispatch(GET_FB_LINK).then((res) => {
          this.editDeliveryTarget = {};
          this.updateKey += 1;
          this.openedWindow = window.open(res, '', 'popup');
        });
      }
    },
    submit() {
      const params = {};
      const responce = {};
      params.facebookEncryptedAccessToken = this.formData.facebookEncryptedAccessToken;
      params.facebookTitle = this.formData.facebookTitle;
      params.facebookDescription = this.formData.facebookDescription;
      params.facebookDestType = this.formData.facebookDestType;
      params.facebookAccessTokenExpiredAt = this.formData.facebookAccessTokenExpiredAt;

      if (this.formData.facebookDestType === 'timeline') {
        params.facebookDestId = this.fbData
          ? (this.fbData.facebookUserData && this.fbData.facebookUserData.id)
          : null;
        params.facebookPrivacy = this.formData.facebookPrivacy;
      }

      if (this.formData.facebookDestType === 'account') {
        params.facebookDestId = this.formData.facebookAccount;
        params.facebookDestName = this.formData.facebookDestName;
      }

      if (this.formData.facebookDestType === 'group') {
        params.facebookDestId = this.formData.facebookGroup;
        params.facebookDestName = this.formData.facebookDestName;
      }

      if (!params.facebookDestId && this.formData.facebookDestId) {
        params.facebookDestId = this.formData.facebookDestId;
      }

      responce.params = params;
      responce.fbData = this.fbData;
      responce.type = 'facebook';

      if (this.editDeliveryTarget && this.editDeliveryTarget.id) {
        responce.id = this.editDeliveryTarget.id;
      }

      this.onSubmit(responce).then(() => {
        this.$store.dispatch(FB_LINK_RESET);
      });
    },
    onCancelHandle() {
      this.$store.dispatch(FB_LINK_RESET);
      localStorage.removeItem('fb-Data');
      this.onCancel();
    },
    tokenExpiredAt(value) {
      const moment = extendMoment(Moment);
      const format = 'MMMM DD,  [ at] hh:mm A';
      if (this.getMainProfile.timezone) {
        return moment(value).tz(this.getMainProfile.timezone).format(format);
      }
      return moment(value).format(format);
    },
    logoutFb() {
      this.fbData = null;
      localStorage.removeItem('fb-Data');
      localStorage.setItem('isNeedFbData', 'false');
      this.formData.facebookEncryptedAccessToken = null;
      this.facebookDestType = CONFIG.facebookDeliveryTargets.facebookDestType;
      this.facebookPrivacy = CONFIG.facebookDeliveryTargets.facebookPrivacy;
      this.facebookStatus = CONFIG.facebookDeliveryTargets.loginStatus.login;
      // this.editDeliveryTarget = this.editDeliveryTarget ? {} : null;
      this.updateKey += 1;
    },
  },
};
</script>
<style scoped>
  .error-item {
    color: #fb3951;
  }

  .login-text {
    margin-bottom: 5px;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }

  .checkmark {
    display: inline-block;
    height: 20px;
    margin-bottom: -2px;
    transform: rotate(45deg);
    width: 20px;
  }

  .checkmark-stem {
    background-color: #ccc;
    height: 9px;
    left: 11px;
    position: absolute;
    top: 6px;
    width: 3px;
  }

  .checkmark-kick {
    background-color: #ccc;
    height: 3px;
    left: 8px;
    position: absolute;
    top: 12px;
    width: 3px;
  }

  .localization-warning-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .localization-warning {
    font-size: 16px;
    color: #F2C94C;
    padding-left: 8px;
  }
</style>
