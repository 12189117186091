import apiCall from '../../../utils/api/api';
import {
  SET_CANCEL_TOKEN,
} from '../../actions/peopleActions/people';
import {
  CREATE_SEASONS_ERROR,
  CREATE_SEASONS_REQUEST,
  CREATE_SEASONS_SUCCESS,
  DELETE_SEASONS_ERROR,
  DELETE_SEASONS_REQUEST,
  DELETE_SEASONS_SUCCESS,
  EDIT_SEASONS_ERROR,
  EDIT_SEASONS_REQUEST,
  EDIT_SEASONS_SUCCESS,
  GET_SEASONS_ERROR,
  GET_SEASONS_LIST_ERROR,
  GET_SEASONS_LIST_REQUEST,
  GET_SEASONS_LIST_SUCCESS,
  GET_SEASONS_REQUEST,
  GET_SEASONS_SUCCESS,
} from '../../actions/seasons/seasons';

const state = {
  isLoading: false,
  seasonsList: [],
  pagination: {},
  seasons: {},
  source: null,
};

const actions = {
  [GET_SEASONS_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    const url = `${params.accountId}/media-collection/series/${params.seriesId}/season?p=${params.page}&l=10000&filterField=s.title`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_SEASONS_LIST_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_SEASONS_LIST_ERROR);
      });
  }),
  [CREATE_SEASONS_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(CREATE_SEASONS_REQUEST);

    apiCall.post(`${accountId}/media-collection/season`, data)
      .then((res) => {
        commit(CREATE_SEASONS_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_SEASONS_ERROR, error);
        reject(error);
      });
  }),
  [GET_SEASONS_REQUEST]: ({ commit }, { accountId, seasonsId }) => new Promise(
    (resolve, reject) => {
      commit(GET_SEASONS_REQUEST);

      const url = `${accountId}/media-collection/season/${seasonsId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_SEASONS_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_SEASONS_ERROR, error);
          reject(error);
        });
    },
  ),
  [EDIT_SEASONS_REQUEST]:
    ({ commit }, { accountId, seasonsId, data }) => new Promise((resolve, reject) => {
      commit(EDIT_SEASONS_REQUEST);

      apiCall.put(`${accountId}/media-collection/season/${seasonsId}`, data)
        .then((resp) => {
          commit(EDIT_SEASONS_SUCCESS, resp);
          resolve(resp);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(EDIT_SEASONS_ERROR, error);
          reject(error);
        });
    }),
  [DELETE_SEASONS_REQUEST]:
    ({ commit }, { accountId, seasonsId }) => new Promise((resolve, reject) => {
      commit(DELETE_SEASONS_REQUEST);

      apiCall.delete(`${accountId}/media-collection/season/${seasonsId}`)
        .then((resp) => {
          commit(DELETE_SEASONS_SUCCESS, resp.data);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(DELETE_SEASONS_ERROR, error);
          reject(error);
        });
    }),
};

const mutations = {
  [GET_SEASONS_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_SEASONS_LIST_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.seasonsList = payload;
  },
  [CREATE_SEASONS_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_SEASONS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_SEASONS_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_SEASONS_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_SEASONS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_SEASONS_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_SEASONS_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_SEASONS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_SEASONS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_SEASONS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_SEASONS_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.seasons = payload;
  },
  [GET_SEASONS_ERROR]: () => {
    state.isLoading = false;
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
  },
};

export default {
  state,
  actions,
  mutations,
};
