<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="btn-add-mobile">
      <Button
        :title="'Add Event Group'"
        :classType="'primary'"
        :eventFunction="onAddEventGroup"
      />
    </div>
    <div class="flex">
      <div class="left-path">
        <span class="system">
          Scheduled Events
        </span>
        <span>
          / Event Groups
        </span>
      </div>
      <div class="right-path">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="255"
        />
        <div class="btn-add-full">
          <Button
            :title="'Add Event Group'"
            :classType="'primary'"
            :eventFunction="onAddEventGroup"
          />
        </div>
      </div>
    </div>
    <div class="table-wrapper">
      <div class="table-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name event-group-title">
          <Sorting
            :sortField="`seg.${sortedField.name}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          title
        </div>
        <div class="th-name next-event">
          next event
        </div>
        <div class="th-name future-event">
          future events
        </div>
        <div class="th-name future-event">
          status
        </div>
        <div
          v-if="getCurrentAccount.brushfireProtectionEnabled"
          class="th-name future-event"
        >
          protection
        </div>
      </div>
      <div
        v-if="!eventGroups.length"
        class="empty-search"
      >
        No Event Groups to display
      </div>
      <div
        v-for="(item, key) in eventGroups"
        :key="item.id"
        class="event-group"
        @mouseover="onHoverElem(item)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div
          class="td event-group-title"
          :title="item.name"
        >
          <inline-svg
            v-if="item.tvAppsEnabled"
            :src="iconLiveTv"
            class="svg-icon tv-apps-icon"
          >
          </inline-svg>
          {{ item.name }}
        </div>
        <div class="td next-event">
          {{ parseDateTime(item.nextStartDate) }}
        </div>
        <div class="td future-event">
          {{ item.futureEventCount }}
        </div>
        <div class="td future-event status">
          <div
            class="status-ellipse"
            :style="{ background: getStatus(item.status).color }"
          >
          </div>
          {{ getStatus(item.status).name || '-' }}
        </div>
        <div
          v-if="getCurrentAccount.brushfireProtectionEnabled"
          class="brush-icons"
        >
          <inline-svg
            v-if="item.hasProtection"
            :src="iconProtection"
            class="svg-icon group-icon"
          >
          </inline-svg>
          <div
            v-else
          >
            -
          </div>
        </div>
        <div
          v-show="isHover && item.id === selectedId"
          class="icons-wrapper"
        >
          <div
            v-if="!item.isAppsEventGroup"
            class="icon-wrap"
            :title="removeEventGroupTitle"
          >
            <inline-svg
              :src="iconRemove"
              class="svg-icon"
              @click="onRemoveEventGroup()"
            >
            </inline-svg>
          </div>
          <div
            class="icon-wrap"
            :title="generateWidgetTitle"
            @click="onShowWidget(item)"
          >
            <inline-svg
              :src="iconCode"
              class="svg-icon"
            >
            </inline-svg>
          </div>
          <div class="icon-wrap">
            <router-link
              :to="{ path: `event-group/${item.id}` }"
              :title="editEventGroupTitle"
            >
              <inline-svg
                :src="iconEdit"
                class="svg-icon"
              >
              </inline-svg>
            </router-link>
          </div>
          <div
            class="icon-wrap"
            :title="standaloneEventsTitle"
            @click="onShowEventsByGroup(item)"
          >
            <inline-svg
              :src="iconEvents"
              class="svg-icon"
            >
            </inline-svg>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :totalPages="totalPages"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :title="'Delete Event Group'"
      :cancelButtonTitle="'Close'"
      :confirmButtonTitle="'Confirm'"
      :bodyMessage="bodyMessage"
      :onAccept="deleteEventGroup"
      :onClose="onClose"
    />
    <WidgetModal
      v-if="isShowWidgetModal"
      :autoplay="true"
      :modalData="widgetModalData"
      :onClose="onCloseWidgetModal"
      :isGroupEvents="true"
      :getGroupEvents="getGroupEvents"
    />
    <AddEventGroupModal
      v-if="isShowAddEventGroupModal"
      :onAccept="addEventGroup"
      :onClose="onClose"
    />
  </div>
</template>

<script>
import moment from 'moment';
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  GET_ALL_EVENT_GROUPS,
  DELETE_EVENT_GROUP,
} from '../../store/actions/events/eventGroups';
import { GET_WIDGET } from '../../store/actions/widgets/widget';
import CONFIG from '../../constants/config';

import iconLiveTv from '../../assets/icons/icon-live-tv.svg';
import iconRemove from '../../assets/icons/icon-remove-role.svg';
import iconCode from '../../assets/icons/icon-code.svg';
import iconEvents from '../../assets/icons/icon-events.svg';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconProtection from '../../assets/icons/icon-brush-protection-events.svg';

import Loader from '../../components/common/Loader.vue';
import Sorting from '../../components/common/Sorting.vue';
import LiveSearch from '../../components/common/LiveSearch.vue';
import Button from '../../components/common/Button.vue';
import NotificationModal from '../../components/NotificationModal.vue';
import Pagination from '../../components/common/Pagination.vue';
import WidgetModal from '../../components/common/WidgetModal.vue';
import AddEventGroupModal from '../../components/events/AddEventGroupModal.vue';

export default {
  name: 'EventGroups',
  components: {
    Loader,
    Pagination,
    LiveSearch,
    Sorting,
    Button,
    NotificationModal,
    WidgetModal,
    AddEventGroupModal,
  },
  data() {
    const {
      removeEventGroupTitle,
      generateWidgetTitle,
      editEventGroupTitle,
      standaloneEventsTitle,
    } = CONFIG.tooltips;
    return {
      iconRemove,
      iconCode,
      iconEdit,
      iconEvents,
      iconLiveTv,
      iconProtection,
      isShowAddEventGroupModal: false,
      isModalOpen: false,
      bodyMessage: CONFIG.modals.deleteEventGroupMessage,
      successDeleteMessage: CONFIG.successMessages.deleteEventGroupMessage,
      isShowWidgetModal: false,
      widgetModalData: null,
      isHover: false,
      selectedId: null,
      filter: '',
      sortedField: {
        name: 'name',
      },
      currentSortedField: '',
      currentSortBy: 'ASC',
      removeEventGroupTitle,
      generateWidgetTitle,
      editEventGroupTitle,
      standaloneEventsTitle,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.eventGroups.isLoading,
      eventGroups: (state) => state.eventGroups.eventGroups,
      pagination: (state) => state.eventGroups.pagination,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getMainProfile',
      'getCurrentAccount',
    ]),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    getTimezone() {
      return (this.getMainProfile.timezone) ? this.getMainProfile.timezone : moment.tz.guess();
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    const params = {
      accountId: this.getCurrentAccountId,
      page: localStorage.getItem('eventGroupsPage') || 1,
      filterValue: '',
    };
    this.$store.dispatch(GET_ALL_EVENT_GROUPS, params);
  },
  methods: {
    getGroupEvents() {
      const params = {
        accountId: this.getCurrentAccountId,
        page: localStorage.getItem('eventGroupsPage') || 1,
        filterValue: '',
      };
      this.$store.dispatch(GET_ALL_EVENT_GROUPS, params);
    },
    getStatus(status) {
      switch (status) {
      case 'in_progress':
        return {
          name: 'In progress',
          color: '#E77925',
        };
      case 'completed':
        return {
          name: 'Completed',
          color: '#2591CF',
        };
      case 'up_next':
        return {
          name: 'Ready',
          color: '#1FEE65',
        };
      case 'ready':
        return {
          name: 'Ready',
          color: '#1FEE65',
        };
      default:
        return {};
      }
    },
    parseDateTime(value) {
      return value ? moment.utc(value).tz(this.getTimezone).format('YYYY-MM-DD hh:mm A') : '-';
    },
    handleSortUp(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = field;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_EVENT_GROUPS, params);
    },
    handleSortDown(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = field;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_EVENT_GROUPS, params);
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        accountId: this.getCurrentAccountId,
        page: params.page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ALL_EVENT_GROUPS, getParams);
    },
    getPaginationAction(page) {
      const params = {
        accountId: this.getCurrentAccountId,
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ALL_EVENT_GROUPS, params);
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    onAddEventGroup() {
      this.isShowAddEventGroupModal = true;
    },
    addEventGroup() {
      this.isShowAddEventGroupModal = false;
      const params = {
        accountId: this.getCurrentAccountId,
        page: localStorage.getItem('eventGroupsPage') || 1,
        filterValue: '',
      };
      this.$store.dispatch(GET_ALL_EVENT_GROUPS, params);
    },
    onShowWidget(item) {
      if (item.widget) {
        this.openWidgetModal(item.widget);
      } else {
        this.generateWidget({
          accountId: this.getCurrentAccountId,
          data: {
            standaloneEventGroup: item.id,
          },
        }, item);
      }
    },
    generateWidget(data, item) {
      this.$store.dispatch(GET_WIDGET, data)
        .then((res) => {
          item.widget = res;
          this.openWidgetModal(res);
        });
    },
    openWidgetModal(data) {
      this.widgetModalData = data;
      this.isShowWidgetModal = true;
    },
    onCloseWidgetModal() {
      this.isShowWidgetModal = false;
    },
    onRemoveEventGroup() {
      this.isModalOpen = true;
    },
    deleteEventGroup() {
      const params = {
        accountId: this.getCurrentAccountId,
        eventGroupId: this.selectedId,
      };

      this.$store.dispatch(DELETE_EVENT_GROUP, params)
        .then(() => {
          this.$toasted.global.success({
            message: this.successDeleteMessage,
          });
          const params = {
            accountId: this.getCurrentAccountId,
            page: localStorage.getItem('eventGroupsPage') || 1,
            filterValue: '',
          };

          this.$store.dispatch(GET_ALL_EVENT_GROUPS, params);
        });
    },
    onClose() {
      this.isModalOpen = false;
      this.isShowAddEventGroupModal = false;
    },
    onShowEventsByGroup(item) {
      this.$router.push(`event-group/event-list/${item.id}`);
    },
  },
};
</script>

<style scoped>
  .main-wrapper {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 25px 30px;
    width: 100%;
  }

  .btn-add-mobile {
    display: none;
  }

  .flex {
    align-items: center;
    display: flex;
    flex: 0;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 40px;
  }

  .system {
    color: #4c5057;
  }

  .right-path {
    align-items: center;
    display: flex;
  }

  .table-wrapper {
    border: 1px solid #43434d;
    flex: 1;
    max-height: 80vh;
    overflow-y: auto;
  }

  .table-wrapper-header {
    background: #282a2e;
    display: flex;
    padding: 13px 0;
  }

  .event-group {
    display: flex;
  }

  .event-group:hover {
    background: #383b40;
  }

  .th-name {
    align-items: center;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
  }

  .td {
    font-size: 14px;
    padding: 15px 0;
    word-break: break-word;
  }

  .number {
    padding-left: 21px;
    width: 67px;
  }

  .event-group-title {
    padding-left: 20px;
    width: 25%;
    position: relative;
  }

  .tv-apps-icon {
    position: absolute;
    left: -5px;
  }

  .next-event,
  .future-event {
    padding-left: 20px;
    width: 10%;
  }

  .icons-wrapper {
    display: flex;
    flex: 0.95;
    justify-content: flex-end;
    align-self: center;
  }

  .icon-wrap {
    padding: 0 15px;
  }

  .empty-search {
    padding-top: 40px;
    text-align: center;
  }

  .svg-icon {
    cursor: pointer;
  }

  .status-ellipse {
    border-radius: 50%;
    height: 5px;
    margin-right: 6px;
    margin-top: 6px;
    width: 5px;
  }

  .status {
    display: flex;
    text-transform: capitalize;
  }

  .brush-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    padding-left: 20px;
  }

  .group-icon {
    cursor: default;
  }

  @media all and (max-width: 1400px) {
    .event-group-title {
      width: 25%;
    }

    .next-event,
    .future-event {
      padding-left: 20px;
      width: 9%;
    }
  }

  @media all and (max-width: 1200px) {
    .btn-add-mobile {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
      min-height: 40px;
    }

    .btn-add-full {
      display: none;
    }

    .search-wrapper {
      margin-right: 0;
    }

    .event-group-title {
      width: 20%;
    }
  }
</style>
