import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import CONFIG from '../../constants/config';

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_HOST}`,
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false }),
});

const {
  grantType,
  clientId,
  clientSecret,
} = CONFIG.auth.refresh;

let isRefreshingTokenInProcess = false;
let refreshTokenRequest = null;

function getAccessToken() {
  const { scopeRole } = CONFIG.auth.anonAuth;

  let token = 'Bearer '.concat(localStorage.getItem('bc-c:key'));
  if ((localStorage.getItem('role') === scopeRole) || (localStorage.getItem('role') === 'undefined')) {
    token = 'Bearer '.concat(localStorage.getItem('bc-anon:key'));
  }
  return token;
}

axiosInstance.interceptors.request.use((request) => {
  if (localStorage.getItem('isOtherUserEmail')
    && !!localStorage.getItem('isOtherUserMode')
    && request.method !== 'patch'
    && request.url !== 'oauth/v2/token'
  ) {
    request.headers.common['X-Switch-User'] = localStorage.getItem('isOtherUserEmail');
    request.headers['Cache-Control'] = 'no-cache';
  }

  if (request.method === 'patch') {
    return request;
  }

  request.headers.Authorization = getAccessToken();
  return request;
});

function createAxiosResponseInterceptor() {
  isRefreshingTokenInProcess = false;
  refreshTokenRequest = null;
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 429) {
        localStorage.clear();
        window.location.href = '/login';
        return Promise.reject(error);
      }

      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      if (isRefreshingTokenInProcess) {
        return refreshTokenRequest;
      }

      isRefreshingTokenInProcess = true;

      axiosInstance.interceptors.response.eject(interceptor);

      const data = {
        grant_type: grantType,
        client_id: clientId,
        client_secret: clientSecret,
        refresh_token: localStorage.getItem('bc-f:key'),
      };

      refreshTokenRequest = axiosInstance.post('oauth/v2/token', data)
        .then((response) => {
          localStorage.setItem('bc-c:key', response.data.access_token);
          localStorage.setItem('bc-f:key', response.data.refresh_token);
          error.response.config.headers.Authorization = `Bearer ${response.data.access_token}`;
          return axiosInstance(error.response.config);
        })
        .catch((err) => {
          Promise.reject(err);
        })
      // localStorage.clear();
      // window.location.href = '/login';
      // eslint-disable-next-line implicit-arrow-linebreak
        .finally(createAxiosResponseInterceptor);

      return refreshTokenRequest;
    },
  );
}

createAxiosResponseInterceptor();

export default axiosInstance;
