<template>
  <div :class="['wrapper', isDelete() && 'delete-wrapper']">
    <ValidationObserver
      ref="validationObserver"
      v-slot="{ handleSubmit }"
    >
      <Loader :isVisible="!isLoaded && showLoader" />
      <form
        v-if="isAssetInitialized && isLangInitialized"
        novalidate="novalidate"
        @submit.prevent="handleSubmit(submit)"
      >
        <p class="headline grey lighten-2">
          {{ modalTitle }}
        </p>
        <div
          v-if="isAdd()"
          class="toggle-wrapper"
        >
          <div class="toggle-title">
            Multiselect
          </div>
          <ToggleButton
            v-model="formData.isMulti"
            color="#2591CF"
            :sync="true"
            :labels="false"
          />
        </div>
        <div
          v-if="isDelete()"
          class="edit-wrapper delete-text"
        >
          Are you sure you want to delete this asset?
        </div>
        <div
          v-else-if="isNotFound"
          class="edit-wrapper"
        >
          Ooops... Asset not found.
        </div>
        <div
          v-else
          class="edit-wrapper"
        >
          <div class="upload-text">
            Use Drag & Drop area to upload the Subtitle:
          </div>
          <AssetUploader
            v-if="!formData.isMulti"
            :onFileChange="handleFileChange"
            :getFileName="getFileName"
            :originalName="modalData.name"
            :subtitles="formData.content"
            :externalError="uploaderError"
          />
          <div
            v-if="!formData.isMulti && hasSubtitles"
            style="margin-top: 5px"
          >
            <MaterialTextArea
              v-model="formData.content"
              v-bind="formFields.content"
              :className="'widget-area'"
              :allowCopy="true"
            />
          </div>
          <div style="margin-top: 20px">
            <MaterialInput
              v-if="!formData.isMulti"
              v-model="formData.label"
              v-bind="formFields.label"
              className="tree-modal-inputs"
            />
          </div>
          <MaterialSelect
            v-if="!formData.isMulti"
            v-model="formData.assetRole"
            v-bind="formFields.assetRole"
            :options="assetRolesOptions"
            className="tree-modal-inputs"
          />
          <MaterialSelect
            v-if="!formData.isMulti"
            v-model="formData.language"
            v-bind="formFields.language"
            :options="languages"
            className="tree-modal-inputs"
          />
          <MultiSubtitles
            v-if="formData.isMulti"
            :onFileChange="onFileUpload"
            :getTitle="getTitle"
            :subtitles="formData.content"
          />
        </div>
        <div :class="['button-wrapper']">
          <Button
            :title="isDelete() ? 'No' : 'Cancel'"
            :classType="[isDelete() ? 'primary grey' : 'warning', formData.isMulti && 'multi']"
            :eventFunction="onCancelHandle"
          />
          <Button
            v-if="!isNotFound && !formData.isMulti"
            :title="submitButtonTitle"
            :classType="'primary'"
            :type="'submit'"
          />
        </div>
      </form>
    </ValidationObserver>
    <NotificationModal
      ref="eventWarningModal"
      v-model="isShowWarningModal"
      :modal="isShowWarningModal"
      :bodyMessage="bodyMessage"
      :cancelButtonTitle="'Cancel'"
      :confirmButtonTitle="'Continue'"
      :onAccept="onContinue"
      :onClose="closeModal"
      :isOrangeCancel="true"
    />
    <NotificationModal
      ref="eventWarningModal"
      v-model="isShowMoreOneModal"
      :modal="isShowMoreOneModal"
      :bodyMessage="bodyMessageMoreOne"
      :cancelButtonTitle="'Cancel'"
      :confirmButtonTitle="'Continue'"
      :onAccept="onContinueMoreOne"
      :onClose="closeModalMoreOne"
      :isOrangeCancel="true"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import CONFIG from '../../../../constants/config';
import {
  GET_SUBTITLE_ASSET_REQUEST,
  ADD_SUBTITLE_ASSET_REQUEST,
  REPLACE_SUBTITLE_ASSET_REQUEST,
  DELETE_SUBTITLE_ASSET_REQUEST,
  GET_ASSET_LANGUAGE_REQUEST,
  GET_ASSET_ROLES_OPTIONS_REQUEST,
} from '../../../../store/actions/media/mediaAsset';
import { SET_EDIT_FORM } from '../../../../store/actions/common';

import BaseForm from '../../../forms/BaseForm.vue';
import MaterialInput from '../../../inputs/MaterialInput.vue';
import MaterialSelect from '../../../inputs/MaterialSelect.vue';
import MaterialTextArea from '../../../inputs/MaterialTextArea.vue';
import Button from '../../../common/Button.vue';
import Loader from '../../../common/Loader.vue';
import AssetUploader from '../AssetUploader.vue';
import MultiSubtitles from './MultiSubtitles.vue';
import SrtToVttFormatConverter from '../../../../utils/subtitle/srtToVttFormatConverter';
import NotificationModal from '../../../NotificationModal.vue';

export default {
  name: 'SubtitleAssetModalContent',
  components: {
    MultiSubtitles,
    AssetUploader,
    MaterialInput,
    MaterialSelect,
    MaterialTextArea,
    Button,
    Loader,
    NotificationModal,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'addSubtitleAsset',
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
    modalMode: {
      type: String,
      default: '',
    },
    modalData: {
      type: Object,
      default: () => {},
    },
    source: {
      type: Object,
      default: () => {},
    },
    showLoader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isNotFound: false,
      isAssetInitialized: false,
      isLangInitialized: false,
      hasSubtitles: false,
      isShowWarningModal: false,
      hasContinue: false,
      bodyMessage: 'This action will remove this Translation Master Asset Role from its previously assigned Subtitle Asset within this Media Item. Do you wish to continue?',
      uploaderError: '',
      assetRolesOptions: [],
      isShowMoreOneModal: false,
      hasContinueMoreOne: false,
      bodyMessageMoreOne: '',
      formData: {
        language: '',
        label: '',
        content: '',
        isMulti: false,
        fileName: '',
        assetRole: '',
      },
      formFields: {
        label: {
          type: 'text',
          name: 'label',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 50,
          },
        },
        language: {
          name: 'language',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        assetRole: {
          name: 'assetRole',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        content: {
          type: 'text',
          name: 'content',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 500000,
          },
        },
      },
      errorMapping: {
        label: [
          `${this.formPrefix}.label`,
        ],
        mediaItem: [
          `${this.formPrefix}.mediaItem`,
        ],
        language: [
          `${this.formPrefix}.language`,
        ],
        content: [
          `${this.formPrefix}.content`,
        ],
        assetRole: [
          `${this.formPrefix}.assetRole`,
        ],
      },
      submitEvent: this.getSubmitEventType(),
      successMessage: this.getSuccessMessageRequest(),
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.mediaAsset.isLoading,
      languages: (state) => state.mediaAsset.languageList,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getAppConfig',
    ]),
    isLoaded() {
      return !this.isLoading && this.isAssetInitialized && this.isLangInitialized;
    },
    modalTitle() {
      if (this.isReplace()) {
        return 'Replace Subtitle';
      }

      if (this.isDelete()) {
        return 'Delete Subtitle';
      }

      if (this.isAddSupp()) {
        return 'Supplemental Audio Upload';
      }

      return 'Add Subtitle';
    },
    submitButtonTitle() {
      if (this.isReplace()) {
        return 'Replace';
      }
      if (this.isDelete()) {
        return 'Yes';
      }
      return 'Add';
    },
  },
  created() {
    this.$store.dispatch(GET_ASSET_LANGUAGE_REQUEST)
      .then(() => {
        this.isLangInitialized = true;
      });

    const assetRolesParams = {
      accountId: this.getCurrentAccountId,
      mediaId: this.$route.params.mediaId,
      assetType: 'Subtitle',
    };
    this.$store.dispatch(GET_ASSET_ROLES_OPTIONS_REQUEST, assetRolesParams).then((res) => {
      this.assetRolesOptions = res.data.map((item) => ({
        code: item.id,
        name: item.name,
        multiAsset: item.multiAsset,
        enabled: item.enabled,
        usedAssetId: item.usedAssetId,
        uniqueAssetType: item.uniqueAssetType,
        usedAssetType: item.usedAssetType,
      }));
      this.assetRolesOptions = this.assetRolesOptions.filter((item) => item.enabled);
    });

    if (this.isReplace() && this.modalData.id) {
      const params = {
        accountId: this.getCurrentAccountId,
        assetId: this.modalData.id,
      };

      this.$store.dispatch(GET_SUBTITLE_ASSET_REQUEST, params)
        .then((res) => this.initFormData(res))
        .catch(() => {
          this.isNotFound = true;
        })
        .finally(() => {
          this.isAssetInitialized = true;
        });
    } else {
      this.isAssetInitialized = true;
    }
  },
  methods: {
    initFormData(response) {
      if (response.id) {
        this.formData.label = response.label;
        this.formData.language = response.language;
        this.formData.content = response.content;
        this.formData.fileName = response.fileName;
        this.formData.assetRole = response.assetRole && response.assetRole.id;
      }

      this.isAssetInitialized = true;
      this.hasSubtitles = !!response.content;
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      this.onSuccess(this.modalMode);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        if (!this.hasSubtitles
          && formErrors[this.formFields.content.name]
          && formErrors[this.formFields.content.name][0]
        ) {
          this.uploaderError = formErrors[this.formFields.content.name][0];
        }
        if (formErrors.mediaItem && formErrors.mediaItem[0]) {
          this.$toasted.global.error({
            message: formErrors.mediaItem[0],
          });
        }
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else if (error.error === CONFIG.errorMessages.lockedError) {
        this.$toasted.global.error({
          message: CONFIG.errorMessages.lockedMessage,
        });
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    getRequestData() {
      const type = this.assetRolesOptions.find((item) => item.code === this.formData.assetRole);

      const data = {
        label: this.formData.label,
        language: this.formData.language,
        content: this.formData.content,
        mediaItem: this.modalData.mediaItemId,
        fileName: this.formData.fileName || `${this.formData.label}_${this.formData.language}.vtt`,
        assetRole: this.formData.assetRole,
      };

      if (type
        && (!this.source || !this.source.assetRole || this.source.assetRole.id !== type.code)
        && type.usedAssetId
        && type.name === 'Translation Master'
        && !this.hasContinue) {
        this.isShowWarningModal = true;
        return null;
      }

      if (type
        && !this.hasContinue
        && !type.usedAssetType
        && !type.multiAsset
        && type.name !== 'Translation Master'
        && (!this.source || !this.source.assetRole || this.source.assetRole.id !== type.code)
        && type.usedAssetId
        && !this.hasContinueMoreOne) {
        this.bodyMessageMoreOne = `This action will remove this ${type.name} Asset Role from its previously assigned Subtitle Asset within this Media Item. Do you wish to continue?`;
        this.isShowMoreOneModal = true;
        return null;
      }

      if (type
        && (!this.source || !this.source.assetRole || this.source.assetRole.id !== type.code)
        && type.uniqueAssetType
        && type.usedAssetId
        && !this.hasContinue) {
        this.bodyMessage = `This action will remove this ${type.name} Asset Role from its previously assigned Subtitle Asset within this Media Item. Do you wish to continue?`;
        this.isShowWarningModal = true;
        return null;
      }

      if (type
        && (!this.source || !this.source.assetRole || this.source.assetRole.id !== type.code)
        && type.usedAssetType
        && type.usedAssetId
        && !type.multiAsset
        && !this.hasContinue) {
        this.bodyMessage = `This action will remove this ${type.name} Asset Role from its previously assigned ${type.usedAssetType} Asset within this Media Item. Do you wish to continue?`;
        this.isShowWarningModal = true;
        return null;
      }

      return {
        assetId: this.modalData.id,
        accountId: this.getCurrentAccountId,
        data,
      };
    },
    onCancelHandle() {
      this.onCancel();
    },
    closeModal() {
      this.isShowWarningModal = false;
    },
    closeModalMoreOne() {
      this.isShowMoreOneModal = false;
    },
    onContinue() {
      this.hasContinue = true;
      this.submit();
      this.isShowWarningModal = false;
    },
    onContinueMoreOne() {
      this.hasContinueMoreOne = true;
      this.submit();
      this.isShowMoreOneModal = false;
    },
    handleFileChange(subtitles) {
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.content = subtitles;
      this.hasSubtitles = !!subtitles;
      this.uploaderError = '';
    },
    isReplace() {
      return this.modalMode === 'replace';
    },
    isDelete() {
      return this.modalMode === 'delete';
    },
    isAdd() {
      return this.modalMode === 'add';
    },
    isAddSupp() {
      return this.modalMode === 'supp';
    },
    assetTypeForParams() {
      if (this.isAddSupp) {
        return 'Supplemental Audio';
      }
      return 'Subtitle';
    },
    getSubmitEventType() {
      if (this.isReplace()) {
        return REPLACE_SUBTITLE_ASSET_REQUEST;
      }
      if (this.isDelete()) {
        return DELETE_SUBTITLE_ASSET_REQUEST;
      }
      return ADD_SUBTITLE_ASSET_REQUEST;
    },
    getSuccessMessageRequest() {
      const {
        successMessages,
      } = CONFIG;

      if (this.isReplace()) {
        return successMessages.replaceSubtitleAsset;
      }
      if (this.isDelete()) {
        return successMessages.deleteSubtitleAsset;
      }
      return successMessages.addSubtitleAsset;
    },
    async onFileUpload(files) {
      this.$toasted.global.success({
        message: `${Array.from(files).length} subtitle ${Array.from(files).length === 1 ? 'file has' : 'files have'} been successfully added`,
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const file of Array.from(files)) {
        let last = false;
        if (Array.from(files).length - 1 === Array.from(files).indexOf(file)) {
          last = true;
        }
        // eslint-disable-next-line no-await-in-loop
        await this.getSubtitlePreviews(file, last);
      }
    },
    getTitle(file) {
      this.formData.fileName = file.name;
      this.formData.language = file.name.split('_')[1]
        && file.name.split('_')[1].substring(0, file.name.split('_')[1].length - 4);
    },
    getFileName(file) {
      this.formData.fileName = file.name;
    },
    async addSub(file, last) {
      this.getTitle(file);
      const {
        errorTypeFormat,
        invalidFileFormat,
        failedUpload,
        errorTypeSize,
        failedSizeUpload,
      } = CONFIG.errorMessages.upload;
      const params = {
        assetId: this.modalData.id,
        accountId: this.getCurrentAccountId,
        data: {
          fileName: this.formData.fileName,
          language: this.formData.language,
          content: this.formData.content,
          mediaItem: this.modalData.mediaItemId,
        },
      };
      await this.$store.dispatch(ADD_SUBTITLE_ASSET_REQUEST, params)
        .then(() => {
          if (last) {
            this.$toasted.global.success({
              message: this.successMessage,
            });
            this.onSuccess(this.modalMode);
          }
        })
        .catch((err) => {
          const langErr = err.form_errors
            && err.form_errors.children
            && err.form_errors.children.language
            && err.form_errors.children.language.errors;
          const contentErr = err.form_errors
            && err.form_errors.children
            && err.form_errors.children.content
            && err.form_errors.children.content.errors;

          if (langErr || contentErr) {
            this.$toasted.global.inf({
              message: `Unable to upload ${file.name}`,
            });
          }

          this.onCancelHandle();

          if (err === errorTypeFormat) {
            this.$toasted.global.error({
              message: invalidFileFormat,
            });
          } else if (err.status === CONFIG.statuses.badRequest) {
            this.$toasted.global.error({
              message: failedUpload,
            });
          } else if (err === errorTypeSize) {
            this.$toasted.global.error({
              message: failedSizeUpload,
            });
          }
        });
    },
    getSubtitlePreviews(file, last) {
      this.validationMessage = '';
      return new Promise((res) => {
        const reader = new FileReader();
        reader.addEventListener('load', async () => {
          this.formData.content = reader.result;
          const resp = await this.addSub(file, last);
          res(resp);
        }, false);
        reader.readAsText(file);
      });
    },
    validateFile(file) {
      if (!(/\.(vtt|srt)$/i.test(file.name))) {
        this.validationMessage = `${file.name} is not a valid subtitle WebVTT/SRT file.`;
        return false;
      }

      if (((file.size / 1024) / 1024) > 1) {
        this.validationMessage = 'File size can be no more than 1 MB';
        return false;
      }

      return true;
    },
    convertInputFile(result) {
      if ((/\.(srt)$/i.test(this.fileName))) {
        return SrtToVttFormatConverter.convert(result);
      }

      return result;
    },
    afterLoadValidation(result) {
      this.formData.content = result;
    },
  },
};
</script>
<style scoped>
  .wrapper {
    background-color: #222;
    border-radius: 4px;
    display: block;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    padding: 30px;
    position: absolute;
    top: 100px;
    text-decoration: none;
    width: 630px;
    max-height: 79%;
    overflow-y: auto;
  }

  .delete-wrapper {
    top: 35%;
  }

  .headline,
  .edit-wrapper {
    color: #fff;
  }

  .edit-wrapper {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375rem;
  }

  .delete-text {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding-bottom: 20px;
    word-break: break-all;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }

  .multi {
    margin-right: 0 !important;
  }

  .edit-wrapper input,
  .edit-wrapper select {
    padding: 13px;
  }

  .toggle-wrapper {
    align-items: center;
    display: flex;
    margin-right: 30px;
    width: auto;
    padding: 10px 0;
  }

  .toggle-title {
    margin-right: 10px;
  }

  .upload-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 20px;
  }

  @media all and (max-height: 950px) {
    .wrapper {
      max-height: 70%;
    }
  }
</style>
