<template>
  <div :class="['analytics-icon', className]">
    <inline-svg
      v-show="!resized"
      :src="iconMaxSize"
      class="svg"
      @click="onResize"
    />
    <inline-svg
      v-show="resized"
      :src="iconMinSize"
      class="svg"
      @click="onResize"
    />
  </div>
</template>
<script>
import iconMaxSize from '../../assets/icons/icon-maxsize.svg';
import iconMinSize from '../../assets/icons/icon-minsize.svg';

export default {
  name: 'AnalyticsMostRequestedTitles',
  props: {
    className: {
      type: String,
      default: null,
    },
    onResize: {
      type: Function,
      default: () => {},
    },
    resized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconMaxSize,
      iconMinSize,
    };
  },
  methods: {},
};
</script>
<style>
  .analytics-icon .svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  .analytics-icon-filter .svg {
    cursor: pointer;
    height: 17px;
    width: 17px;
  }
</style>
