<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="title modal">
        Add Season
      </div>
      <div class="folders-wrapper">
        <FoldersManagement
          :selectedValue="formData.mediaFolder"
          v-bind="formFields.mediaFolder"
          :label="'Source Folder'"
          :required="true"
          :commonError="foldError"
          :onChange="onFoldersChange"
        />
      </div>
      <MaterialInput
        v-model="formData.title"
        v-bind="formFields.title"
        :requiredFromBack="true"
      />
      <div :class="[isEdit ? 'edit-button-wrapper' : 'end-buttons']">
        <div style="display: flex">
          <Button
            :title="'Cancel'"
            :classType="'warning'"
            :eventFunction="backToManually"
          />
          <Button
            :title="'Add'"
            :classType="'primary'"
            :type="'submit'"
            :disabled="!formData.title"
          />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import FoldersManagement from '../../FoldersManagement.vue';
import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';
import iconWarning from '../../../assets/icons/icon-warning-localization.svg';

import { CREATE_SEASONS_REQUEST } from '../../../store/actions/seasons/seasons';

export default {
  name: 'AddSeasonForm',
  components: {
    FoldersManagement,
    MaterialInput,
    Button,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'addPlaylistForm',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    openedLocalization: {
      type: Function,
      default: () => {},
    },
    onSubmitManually: {
      type: Function,
      default: () => {},
    },
    isEdItItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      iconWarning,
      iconFolderLocalization,
      isOpenLocalization: false,
      enabledLanguages: [],
      isModalOpen: false,
      bodyMessage: CONFIG.modals.deleteManuallyRowInCms,
      foldError: '',
      formData: {
        mediaFolder: null,
        title: '',
      },
      formFields: {
        title: {
          type: 'text',
          name: 'title',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
      },
      errorMapping: {
        title: [
          `${this.formPrefix}.title`,
        ],
        mediaFolder: [
          `${this.formPrefix}.mediaFolder`,
        ],
      },
      submitEvent: CREATE_SEASONS_REQUEST,
      successMessage: 'The Season has been successfully added',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    ...mapState({
      seasons: (state) => state.seasons.seasonsList,
    }),
  },
  methods: {
    backToManually() {
      this.onClose();
    },
    onManuallyClose() {
      this.isModalOpen = false;
    },
    onFoldersChange(value) {
      this.formData.mediaFolder = value ? value.code : value;
      this.formData.title = value.titleName;
      this.foldError = '';
    },
    getRequestData() {
      const data = {
        title: this.formData.title,
        mediaFolder: this.formData.mediaFolder,
        series: this.$route.params.id,
        sortOrder: this.seasons.length ? this.seasons.length + 1 : 1,
      };

      return {
        data,
        accountId: this.getCurrentAccountId,
      };
    },
    onSubmitSuccess(res) {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      this.$router.push(`/collections/series/${this.$route.params.id}/seasons/${res.id}`);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      const folderError = error.form_errors
        && error.form_errors.children
        && error.form_errors.children.mediaFolder
        && error.form_errors.children.mediaFolder.errors
        && error.form_errors.children.mediaFolder.errors[0].error;

      if (folderError && folderError === 'IS_BLANK_ERROR') {
        this.foldError = 'This field is required';
      }

      if (folderError && folderError === 'NOT_SYNCHRONIZED_ERROR') {
        this.foldError = 'This folder is already in use';
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>
.rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.toggles-wrapper {
  display: flex;
}

.toggle-wrapper,
.date-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
}

.toggle-wrapper {
  width: auto;
}

.toggle-title {
  margin-right: 10px;
}

.dynamic {
  margin-top: 30px;
  margin-bottom: 30px;
}

.edit-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.end-buttons {
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

.country-field {
  width: 300px;
}

.country-field:not(:last-child) {
  margin-right: 20px;
}

.wowza p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.wrapper-small {
  margin-top: 20px;
}

.flex {
  justify-content: space-between;
}

.person {
  margin-bottom: 20px;
}

.person p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.select-wrapper {
  width: 300px;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.localization-warning {
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}

.modal {
  color: inherit;
  margin-bottom: 30px;
}

.folders-wrapper {
  margin-bottom: 30px;
}

@media all and (max-width: 1320px) {
  .flex {
    flex-wrap: wrap;
  }

  .country-field {
    margin-right: 0;
    width: 100%;
  }

  .country-field:not(:last-child) {
    margin-right: 0;
  }
}
</style>
