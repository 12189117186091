<template>
  <div class="analytics-geo-devices">
    <div class="geo-header-row">
      <TabsButton
        :onItemSelect="onSelectTab"
        :selectedItem="selectedTab"
        :items="tabItems"
        :className="'geo-and-devices'"
      />
      <ResizedButton
        :resized="resized"
        :onResize="onResize"
      />
    </div>
    <AnalyticsSimpleTable
      :mediaItems="items"
      :resized="resized"
      :withSelect="selectedTab === 'geo'"
      :selectedTab="selectedTab"
      :selectedType="selectedGeoType"
      :onSelectType="onSelectGeoType"
      :onReloadData="onReloadData"
      :isLoadingFailed="isLoadingFailed"
    />
  </div>
</template>
<script>
import iconFilter from '../../assets/icons/icon-filter.svg';
import iconMaxSize from '../../assets/icons/icon-maxsize.svg';
import iconMinSize from '../../assets/icons/icon-minsize.svg';
import AnalyticsSimpleTable from './AnalyticsSimpleTable.vue';
import TabsButton from './TabsButton.vue';
import ResizedButton from './ResizeButton.vue';

export default {
  name: 'AnalyticsGeoAndDevices',
  components: {
    TabsButton,
    ResizedButton,
    AnalyticsSimpleTable,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedTab: {
      type: String,
      default: 'geo',
    },
    onSelectTab: {
      type: Function,
      default: () => {},
    },
    selectedGeoType: {
      type: String,
      default: 'country',
    },
    onSelectGeoType: {
      type: Function,
      default: () => {},
    },
    isLoadingFailed: {
      type: Boolean,
      default: false,
    },
    onReloadData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconFilter,
      iconMaxSize,
      iconMinSize,
      resized: false,
      tabItems: [{
        title: 'Geo',
        code: 'geo',
      }, {
        title: 'Devices',
        code: 'device',
      },
      {
        title: 'Browsers',
        code: 'browsers',
      },
      ],
    };
  },
  methods: {
    onResize() {
      this.resized = !this.resized;
    },
  },
};
</script>
<style scoped>
  .analytics-icon .svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  .analytics-icon-filter .svg {
    cursor: pointer;
    height: 17px;
    width: 17px;
  }

  .geo-header-row {
    display: flex;
    justify-content: space-between;
  }
</style>
<style>
  .analytics-geo-devices .tabs li {
    background-color: #3e3f40;
  }

  .geo-header-row .analytics-icon {
    margin-top: 15px;
  }

  .analytics-geo-devices .tabs a {
    font-size: 16px;
    font-weight: normal;
    margin-right: 0;
    padding: 10px 25px;
  }

  .analytics-geo-devices .tabs ul {
    border-bottom: 0;
  }

  .analytics-geo-devices .is-active a {
    background-color: #2591cf;
    border-bottom: 0;
    cursor: pointer;
  }

  .analytics-geo-devices .tabs {
    margin-bottom: 0;
  }

  .analytics-row {
    padding-top: 30px;
    position: absolute;
    right: 20px;
  }
</style>
