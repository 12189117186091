<template>
  <div class="settings main-body-right">
    <div
      v-if="isAccountActive"
      class="wrap"
    >
      <div class="system">
        System Management
      </div>
      <div class="nav sub-nav">
        <router-link
          v-if="isSuperAdmin || isAdmin"
          to="/settings/users"
          tag="div"
          class="router settings-item"
          :activeClass="'active'"
          :event="moving"
        >
          {{ `${isSuperAdmin ? 'Users' : ' Account Users'}` }}
        </router-link>
        <div
          v-if="isAdmin"
          class="tree-wrapper"
        >
          <div class="tree settings-item">
            <inline-svg
              :src="iconContributors"
              class="svg"
            />
            <div class="tree-label">
              Contributors
            </div>
          </div>
          <div class="tree-items">
            <router-link
              v-if="isAdmin"
              to="/settings/people"
              tag="div"
              class="tree-item settings-item router"
              :activeClass="'active'"
              :event="moving"
            >
              People
            </router-link>
            <router-link
              v-if="isAdmin"
              to="/settings/roles"
              tag="div"
              class="tree-item settings-item router"
              :activeClass="'active'"
              :event="moving"
            >
              Roles
            </router-link>
          </div>
        </div>
        <router-link
          v-if="isSuperAdmin"
          to="/settings/accounts"
          tag="div"
          class="router settings-item"
          :activeClass="'active'"
          :event="moving"
        >
          Accounts
        </router-link>
        <router-link
          v-if="isSuperAdmin"
          to="/settings/servers"
          tag="div"
          class="router settings-item"
          :activeClass="'active'"
          :event="moving"
        >
          Servers
        </router-link>
        <router-link
          v-if="isAdmin"
          to="/settings/fields"
          tag="div"
          class="router settings-item"
          :activeClass="'active'"
          :event="moving"
        >
          Custom Fields
        </router-link>
        <router-link
          v-if="isAdmin"
          to="/settings/account"
          tag="div"
          class="router settings-item"
          :activeClass="'active'"
          :event="moving"
        >
          Account Settings
        </router-link>
        <router-link
          v-if="isAdmin"
          to="/settings/localization"
          tag="div"
          class="router settings-item"
          :activeClass="'active'"
          :event="moving"
        >
          Localization
        </router-link>
        <router-link
          v-if="isAdmin || isManager"
          to="/settings/asset-roles"
          tag="div"
          class="router settings-item"
          :activeClass="'active'"
          :event="moving"
        >
          Asset Roles
        </router-link>
        <router-link
          v-if="isSuperAdmin"
          to="/settings/api-keys"
          tag="div"
          class="router settings-item"
          :activeClass="'active'"
          :event="moving"
        >
          API Keys
        </router-link>
        <router-link
          v-if="isAdmin"
          to="/settings/api-keys-admin"
          tag="div"
          class="router settings-item"
          :activeClass="'active'"
          :event="moving"
        >
          Account API Keys
        </router-link>
        <div
          v-if="isAdmin || isManager"
          class="tree-wrapper"
        >
          <div class="tree settings-item">
            <inline-svg
              :src="iconTaxonomy"
              class="svg"
            />
            <div class="tree-label">
              Taxonomy
            </div>
          </div>
          <div class="tree-items">
            <router-link
              v-if="isAdmin || isManager"
              to="/settings/categories"
              tag="div"
              class="tree-item settings-item router"
              :activeClass="'active'"
              :event="moving"
            >
              Categories
            </router-link>
            <router-link
              v-if="isAdmin || isManager"
              to="/settings/genres"
              tag="div"
              class="tree-item settings-item router"
              :activeClass="'active'"
              :event="moving"
            >
              Genres
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import iconContributors from '../../assets/icons/icon-contributors.svg';
import iconTaxonomy from '../../assets/icons/icon-taxonomy.svg';

export default {
  name: 'Settings',
  data() {
    return {
      iconContributors,
      iconTaxonomy,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.common.role,
    }),
    ...mapGetters([
      'isSuperAdmin',
      'isAdmin',
      'isManager',
      'isAccountActive',
    ]),
    moving() {
      return this.isAccountActive ? 'click' : '';
    },
  },
};
</script>

<style scoped lang="scss">
  .main-body-right {
    display: flex !important;
    padding: 0;
  }

  .nav {
    background: transparent;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }

  .system {
    border-bottom: 1px solid #383b40;
    padding: 30px 0 25px 25px;
  }

  .wrap {
    border-right: 1px solid #383b40;
    flex-direction: column;
    min-width: 330px;
  }

  .router {
    cursor: pointer;
    display: inline-block;
    width: fit-content;
  }

  .router:hover {
    color: #2591cf;
  }

  .active {
    color: #2591cf;
  }

  .settings-item {
    margin-bottom: 35px;
  }

  .tree {
    position: relative;
    display: flex;
  }

  .tree-label {
    padding-left: 8px;
  }

  .tree-items {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
  }

  .tree-item {
    position: relative;

    &:before {
      background-color: #4c5057;
      top: -44px;
      content: '';
      display: block;
      height: 55px;
      left: -31px;
      position: absolute;
      width: 2px;
    }

    &:after {
      background-color: #4c5057;
      content: '';
      display: block;
      height: 2px;
      left: -30px;
      position: absolute;
      top: 10px;
      transform: translateY(-50%);
      width: 22px;
    }

    &:first-child {
      &:before {
        height: 44px;
        top: -33px;
      }
    }
  }

  @media all and (max-width: 1200px) {
    .wrap {
      display: none;
    }
  }
</style>
