<template>
  <div class="content-feed-wrapper">
    <div class="checkbox-wrapper">
      <MaterialCheckbox
        :toggle="toggleDynamicEdited"
        :label="'Include Dynamic Content'"
        :checked="formData.isDynamicContent"
      />
    </div>
    <div style="margin-bottom: 20px">
      <FoldersManagement
        :key="`matchFolders${formData.id}`"
        :selectedValue="formData.matchFolders"
        :label="'Match Folders'"
        :required="false"
        :multiple="true"
        :onChange="onFoldersChange"
        :disabled="!formData.isDynamicContent"
      />
    </div>
    <div class="exclude-wrapper">
      <MediaTagManagement
        :key="`includedTypes${formData.id}`"
        :selectedValue="formData.includedTypes"
        :label="'Include Media Types'"
        :tagPlaceholder="''"
        :searchable="false"
        :onChange="onTagIncludedTypesChange"
        :customOptions="typesOptions"
        :isAudio="true"
        :disabled="!formData.isDynamicContent"
      />
    </div>
    <div class="filters">
      <div
        class="arrow-filter-block"
        @click="changeFilters"
      >
        <inline-svg
          :src="iconArrow"
          :class="['svg arrow', isOpenFilter && 'open-arrow']"
        >
        </inline-svg>
        <span class="title-filter">
          Filter by Tags
        </span>
      </div>
      <div v-show="isOpenFilter">
        <MediaTagManagement
          :key="`includedTags${formData.id}`"
          :selectedValue="formData.includedTags"
          :label="'Include Workflow Tags'"
          :tagPlaceholder="''"
          :searchable="true"
          :onChange="onTagIncludedChange"
          :disabled="!formData.isDynamicContent"
          :errorItems="errorIncludeTags"
          :resetValidation="resetValidation"
        />
        <div class="exclude-wrapper">
          <MediaTagManagement
            :key="`excludedTags${formData.id}`"
            :selectedValue="formData.excludedTags"
            :label="'Exclude Workflow Tags'"
            :tagPlaceholder="''"
            :searchable="true"
            :onChange="onTagExcludedChange"
            :disabled="!formData.isDynamicContent"
            :errorItems="errorExcludedTags"
            :resetValidation="resetValidation"
          />
        </div>
        <div class="search-tags">
          <MediaTagManagement
            :key="`includedSearchTags${formData.id}`"
            :selectedValue="formData.includedSearchTags"
            :label="'Include Search Tags'"
            :tagPlaceholder="''"
            :searchable="true"
            :onChange="onTagIncludedSearchChange"
            :disabled="!formData.isDynamicContent"
            :errorItems="errorIncludeSearchTags"
            :resetValidation="resetValidation"
            :isSearch="true"
          />
        </div>
        <div class="exclude-wrapper">
          <MediaTagManagement
            :key="`excludedSearchTags${formData.id}`"
            :selectedValue="formData.excludedSearchTags"
            :label="'Exclude Search Tags'"
            :tagPlaceholder="''"
            :searchable="true"
            :onChange="onTagExcludedSearchChange"
            :disabled="!formData.isDynamicContent"
            :errorItems="errorExcludedSearchTags"
            :resetValidation="resetValidation"
            :isSearch="true"
          />
        </div>
      </div>
    </div>
    <div style="margin-top: 30px">
      <MaterialInput
        v-model="formData.dynamicContentLimit"
        v-bind="formFields.dynamicContentLimit"
        :disabled="!formData.isDynamicContent"
        :handleChange="handleChangeContent"
      />
    </div>
    <MaterialSelect
      v-model="formData.sortBy"
      v-bind="formFields.sortBy"
      :options="formData.sortByList"
      :withEmptyField="false"
      :disabled="!formData.isDynamicContent"
      :handleChange="handleChangeContent"
      :className="'flex-select'"
    />
    <MaterialSelect
      v-model="formData.sortOrder"
      v-bind="formFields.sortOrder"
      :options="formData.sortOrderList"
      :withEmptyField="false"
      :disabled="!formData.isDynamicContent"
      :handleChange="handleChangeContent"
      :className="'flex-select'"
    />
  </div>
</template>

<script>
import MaterialCheckbox from '../inputs/MaterialCheckbox.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';
import MediaTagManagement from '../media/MediaTagManagement.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import iconArrow from '../../assets/icons/icon-arrow-brushfire-lisst.svg';
import FoldersManagement from '../FoldersManagement.vue';

export default {
  name: 'ContentFeed',
  components: {
    FoldersManagement,
    MaterialCheckbox,
    MaterialSelect,
    MediaTagManagement,
    MaterialInput,
  },
  props: {
    handleResetValidation: {
      type: Function,
      default: () => {},
    },
    getMediaItems: {
      type: Function,
      default: () => {},
    },
    itemsErrors: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
    formFields: {
      type: Object,
      default: () => {},
    },
    resetDynamicItems: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isOpenFilter: false,
      iconArrow,
      typesOptions: [
        {
          code: 'vod',
          name: 'vod',
        },
        {
          code: 'audio',
          name: 'audio',
        },
        {
          code: 'livestream',
          name: 'live',
        },
      ],
      fields: [
        this.formFields.matchFolders ? this.formFields.matchFolders.name : '',
        this.formFields.includedTags ? this.formFields.includedTags.name : '',
        this.formFields.excludedTags ? this.formFields.excludedTags.name : '',
        this.formFields.dynamicContentLimit ? this.formFields.dynamicContentLimit.name : '',
        this.formFields.sortBy ? this.formFields.sortBy.name : '',
        this.formFields.sortOrder ? this.formFields.sortOrder.name : '',
      ],
    };
  },
  computed: {
    errorExcludedTags() {
      return this.itemsErrors.excludedTags && this.itemsErrors.excludedTags.length
        ? ['Exclude Workflow Tags should be different from the Include Workflow Tags']
        : null;
    },
    errorExcludedSearchTags() {
      return this.itemsErrors.excludedSearchTags && this.itemsErrors.excludedSearchTags.length
        ? ['Exclude Search Tags should be different from the Include Search Tags']
        : null;
    },
    errorIncludeTags() {
      return this.itemsErrors.includedTags && this.itemsErrors.includedTags.length
        ? ['Exclude Workflow Tags should be different from the Include Workflow Tags']
        : null;
    },
    errorIncludeSearchTags() {
      return this.itemsErrors.includedSearchTags && this.itemsErrors.includedSearchTags.length
        ? ['Exclude Search Tags should be different from the Include Search Tags']
        : null;
    },
  },
  watch: {
    sortOrder(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.getMediaItems(1);
      }
    },
    sortBy(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.getMediaItems(1);
      }
    },
    dynamicContentLimit(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.getMediaItems(1);
      }
    },
  },
  methods: {
    changeFilters() {
      this.isOpenFilter = !this.isOpenFilter;
    },
    handleChangeContent() {
      this.getMediaItems(1);
    },
    toggleDynamicEdited(isChecked) {
      if (isChecked !== this.formData.isDynamicContent) {
        this.formData.isDynamicContent = isChecked ? 1 : 0;
        if (isChecked) {
          this.getMediaItems(1);
        } else {
          this.resetDynamicItems();
        }
      }
    },
    onTagIncludedChange(value) {
      this.formData.includedTags = value;
      this.getMediaItems(1);
    },
    onTagIncludedSearchChange(value) {
      this.formData.includedSearchTags = value;
      this.getMediaItems(1);
    },
    onTagExcludedChange(value) {
      this.formData.excludedTags = value;
      this.getMediaItems(1);
    },
    onTagExcludedSearchChange(value) {
      this.formData.excludedSearchTags = value;
      this.getMediaItems(1);
    },
    onTagIncludedTypesChange(value) {
      this.formData.includedTypes = value;
      this.getMediaItems(1);
    },
    onFoldersChange(value) {
      this.formData.matchFolders = value;
      this.getMediaItems(1);
    },
    resetValidation() {
      this.handleResetValidation();
    },
    hasErrors(errors) {
      let hasErrors = false;
      for (let i = 0; i < this.fields.length; i += 1) {
        if (errors[this.fields[i]] && errors[this.fields[i]].length) {
          hasErrors = true;
        }
      }

      return Boolean(hasErrors || this.errorExcludedTags || this.errorIncludeTags);
    },
  },
};
</script>

<style scoped>
  .checkbox-wrapper {
    padding-bottom: 20px;
  }

  .exclude-wrapper {
    padding-bottom: 30px;
  }

  .audio {
    margin-top: -30px;
  }

  .search-tags {
    margin-top: -20px;
  }

  .filters {
    border-bottom: 1px solid #383B40;
    border-top: 1px solid #383B40;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .arrow {
    margin-right: 14px;
    cursor: pointer;
  }

  .open-arrow {
    transform: rotate(90deg);
  }

  .title-filter {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
  }

  .arrow-filter-block {
    display: inline-block;
    padding-left: 5px;
  }
</style>
