<template>
  <div class="media-items-management">
    <div class="multiselectTitle">
      {{ label }}
      <span
        v-if="required"
        class="asterisk"
      >
        *
      </span>
    </div>
    <div
      :class="[commonError.length ? 'common-error' : '',
               isNotValidValue || error ? 'not-valid' : '']"
    >
      <MultiSelect
        v-model="value"
        :options="options"
        tagPlaceholder=""
        selectLabel=""
        selectedLabel=""
        deselectLabel=""
        placeholder=""
        label="name"
        trackBy="id"
        :multiple="false"
        :taggable="false"
        :blockKeys="['Delete']"
        :searchable="true"
        :disabled="false"
        :hideSelected="false"
        :optionsLimit="max"
        :maxHeight="350"
        @search-change="onSearchChange"
        @input="onInput"
      />
      <span
        v-if="commonError.length || isNotValidValue || error"
        class="error-label"
      >
        {{ commonError.length ? commonError : error }}
      </span>
    </div>
  </div>
</template>
<script>
import MultiSelect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import {
  GET_MEDIA_ITEMS_BY_TYPES_REQUEST,
} from '../store/actions/media/mediaItems';

export default {
  name: 'MediaItemsManagement',
  components: {
    MultiSelect,
  },
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
    selectedValue: {
      type: Number,
      default: null,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    errorItems: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    commonError: {
      type: String,
      default: '',
    },
    selectedFolder: {
      type: Number,
      default: -1,
      required: true,
    },
  },
  data() {
    return {
      value: [],
      options: this.getDefaultOptions(),
      isNotValidValue: false,
      errorMessage: '',
      max: 1000,
    };
  },
  computed: {
    ...mapGetters([
      'getRootMediaFolderId',
    ]),
    error() {
      if (this.errorItems && this.errorItems.length) {
        return this.errorItems[0];
      }

      return this.errorMessage;
    },
  },
  watch: {
    selectedFolder: {
      handler: 'getMediaItems',
      deep: true,
    },
  },
  created() {
    this.getMediaItems();
  },
  methods: {
    initComponent() {
      if (this.selectedValue) {
        this.value = this.options.find((item) => item.id === this.selectedValue);
      } else {
        this.value = null;
      }
    },
    getDefaultOptions() {
      return [];
    },
    onSearchChange(searchQuery) {
      const validation = this.validateSearch(searchQuery.trim());

      this.isNotValidValue = !validation.isValid;
      this.errorMessage = validation.message;

      if (validation.isValid) {
        this.processSearchValue(validation.value);
      }
    },
    validateSearch(searchValue) {
      return {
        value: searchValue,
        isValid: true,
        message: '',
      };
    },
    getMediaItems() {
      if (!this.selectedFolder || this.selectedFolder === -1) {
        this.value = null;
        this.options = [];
        return [];
      }

      let result = [];

      const params = {
        accountId: this.getCurrentAccountId,
        folderId: this.selectedFolder,
        contentTypes: ['Movie', 'Event', 'Episode', 'NULL'],
      };

      this.$store.dispatch(GET_MEDIA_ITEMS_BY_TYPES_REQUEST, params)
        .then((res) => {
          result = res.map((item) => ({
            id: item.id,
            name: `${this.getName(item.name)} (${item.id})`,
          }));

          this.options = result;

          this.initComponent();
        });

      return result;
    },
    processSearchValue(value) {
      this.filterValue = value;
    },
    onInput(value) {
      this.onChange(value);
    },
    getName(name) {
      return name.length > 45 ? `${name.slice(0, 45)}...` : name;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

/* stylelint-disable */
.media-items-management {
  margin-top: 5px;
}

.not-valid .multiselect__tags,
.common-error .multiselect__tags {
  border-bottom: 1px solid var(--red);
}

.error-label {
  color: var(--red);
  font-size: 12px;
  margin-bottom: 5px;
  text-align: left;
}

.asterisk {
  color: var(--red);
}
</style>
