var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"folders-tag-management"},[_c('div',{staticClass:"multiselectTitle"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"asterisk"},[_vm._v(" * ")]):_vm._e()]),_c('div',{class:[_vm.commonError.length ? 'common-error' : '',
             _vm.isNotValidValue || _vm.error ? 'not-valid' : '']},[_c('MultiSelect',{attrs:{"options":_vm.options,"tagPlaceholder":_vm.tagPlaceholder,"selectLabel":_vm.selectLabel,"selectedLabel":_vm.selectedLabel,"deselectLabel":_vm.deselectLabel,"placeholder":_vm.placeholder,"label":"name","trackBy":"code","multiple":_vm.multiple,"taggable":false,"blockKeys":['Delete'],"searchable":true,"disabled":_vm.disabled,"hideSelected":_vm.hideSelected,"optionsLimit":_vm.max,"maxHeight":350,"customLabel":_vm.customLabel},on:{"search-change":_vm.onSearchChange,"input":_vm.onInput},scopedSlots:_vm._u([{key:"tag",fn:function(props){return [_c('div',{staticClass:"multiselect__tag custom-multiselect__tag"},[_c('inline-svg',{staticClass:"svg-icon add-icon",attrs:{"src":_vm.iconFolder}}),_c('span',{staticClass:"option-desc",attrs:{"title":props.option.name}},[_c('span',{staticClass:"option-title"},[_vm._v(" "+_vm._s(props.option.name)+" ")])]),_c('inline-svg',{staticClass:"svg-icon add-icon",attrs:{"src":_vm.iconForFolder(props.option.collectionType
              ? props.option.collectionType
              : props.option.folderType)}}),(props.option.hasProtection)?_c('inline-svg',{staticClass:"svg-icon add-icon",attrs:{"src":_vm.iconWhiteProtection}}):_vm._e(),_c('i',{staticClass:"multiselect__tag-icon",attrs:{"aria-hidden":"true"},on:{"click":function($event){return props.remove(props.option)}}})],1)]}},{key:"singleLabel",fn:function(props){return [_c('div',{staticClass:"option-selected-folder"},[_c('inline-svg',{staticClass:"svg-icon add-icon",attrs:{"src":props.option.folderType === 'all-media' ? _vm.iconLock : _vm.iconFolder}}),_c('span',{staticClass:"option-desc",attrs:{"title":props.option.name}},[_c('span',{staticClass:"option-title"},[_vm._v(" "+_vm._s(props.option.name)+" ")])]),_c('inline-svg',{staticClass:"svg-icon add-icon",attrs:{"src":_vm.iconForFolder(props.option.collectionType
              ? props.option.collectionType
              : props.option.folderType)}}),(props.option.hasProtection)?_c('inline-svg',{staticClass:"svg-icon add-icon",attrs:{"src":_vm.iconProtection}}):_vm._e()],1)]}},{key:"option",fn:function(props){return [_c('div',{staticClass:"custom-option"},[_vm._l((props.option.levelMarks && props.option.levelMarks.length - 2),function(item){return _c('div',{key:item},[_c('inline-svg',{staticClass:"svg-icon add-icon",attrs:{"src":_vm.iconHasChild}})],1)}),_c('div',[_c('inline-svg',{staticClass:"svg-icon add-icon",attrs:{"src":props.option.folderType === 'all-media' ? _vm.iconLock : _vm.iconFolder}})],1),_c('div',{staticClass:"option-fold-name",attrs:{"title":props.option.name}},[_vm._v(" "+_vm._s(props.option.name)+" ")]),_c('inline-svg',{staticClass:"svg-icon add-icon",attrs:{"src":_vm.iconForFolder(props.option.collectionType
              ? props.option.collectionType
              : props.option.folderType)}}),(props.option.hasProtection)?_c('div',[_c('inline-svg',{staticClass:"svg-icon add-icon",attrs:{"src":_vm.iconProtection}})],1):_vm._e()],2)]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(" No items to display ")])]),(_vm.commonError.length || _vm.isNotValidValue || _vm.error)?_c('span',{staticClass:"error-label"},[_vm._v(" "+_vm._s(_vm.commonError.length ? _vm.commonError : _vm.error)+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }