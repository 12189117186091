<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="public-wrapper">
        <div style="width: 100%">
          <MaterialInput
            v-model="formData.uniqueId"
            v-bind="formFields.uniqueId"
            :disabled="isRead"
          />
        </div>
        <div style="margin-top: 10px">
          <Button
            :smallSize="true"
            :className="'public-btn'"
            type="'submit'"
            :classType="'primary'"
            :title="''"
            :srcSvg="iconDisc"
            :disabled="
              isRead
                ? true
                : (!formData.uniqueId || formData.uniqueId === modalData.uniqueId)"
          />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import CONFIG from '../../constants/config';

import BaseForm from './BaseForm.vue';
import Button from '../common/Button.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import { EDIT_WIDGET_REQUEST } from '../../store/actions/widgets/widget';
import iconDisc from '../../assets/icons/icon-disc.svg';

export default {
  name: 'PublicIdForm',
  components: {
    Button,
    MaterialInput,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'publicId',
    },
    modalData: {
      type: Object,
      default: null,
    },
    changeWidgetId: {
      type: Function,
      default: () => {},
    },
    getMediaList: {
      type: Function,
      default: () => {},
    },
    getFeedsList: {
      type: Function,
      default: () => {},
    },
    getGroupEvents: {
      type: Function,
      default: () => {},
    },
    getEvents: {
      type: Function,
      default: () => {},
    },
    isFeed: {
      type: Boolean,
      default: false,
    },
    isGroupEvents: {
      type: Boolean,
      default: false,
    },
    isMediaWidget: {
      type: Boolean,
      default: false,
    },
    isEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timestamp: '',
      isModalOpen: false,
      enabledError: '',
      iconDisc,
      formData: {
        uniqueId: '',
      },
      formFields: {
        uniqueId: {
          type: 'text',
          name: 'uniqueId',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 60,
          },
        },
      },
      errorMapping: {
        uniqueId: [
          `${this.formPrefix}.uniqueId`,
        ],
      },
      submitEvent: EDIT_WIDGET_REQUEST,
      successMessage: 'The Public Id has been successfully updated',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
      'isRead',
    ]),
  },
  mounted() {
    this.formData.uniqueId = this.modalData.uniqueId;
  },
  methods: {
    getRequestData() {
      const data = {
        uniqueId: this.formData.uniqueId,
      };

      return {
        accountId: this.getCurrentAccountId,
        widgetId: this.modalData.id,
        data,
      };
    },
    onSubmitSuccess(res) {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      this.changeWidgetId(res);

      if (this.isMediaWidget) {
        this.getMediaList(1);
      }

      if (this.isFeed) {
        this.getFeedsList();
      }

      if (this.isGroupEvents) {
        this.getGroupEvents();
      }

      if (this.isEvent) {
        this.getEvents();
      }
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped>
.status-checkbox {
  margin-bottom: 30px;
}

.checkbox {
  margin-top: 3px;
}

.button-wrapper {
  display: flex;
  align-items: center;
}

.edit-button-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 26px;
}

.breadcrumbs {
  margin-bottom: 25px;
}

.system {
  color: #4c5057;
}

.delition-wrapper {
  padding: 15px 20px;
  padding-top: 0;
}

.input-wrapper {
  margin-left: 30px;
  margin-right: 30px;
}

.delete-info {
  margin-right: 30px;
  margin-left: 30px;
}

.deleted-link {
  color: #2591CF;
}

.button-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}

.button-wrapper button:first-child {
  margin-right: 15px;
}

.public-wrapper {
  display: flex;
  align-items: center;
}

.public-btn {
  min-width: 40px;
}
</style>
