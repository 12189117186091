export const GET_PAGES_LIST_REQUEST = 'GET_PAGES_LIST_REQUEST';
export const GET_PAGES_LIST_SUCCESS = 'GET_PAGES_LIST_SUCCESS';
export const GET_PAGES_LIST_ERROR = 'GET_PAGES_LIST_ERROR';

export const SETUP_TV_ENGINE_REQUEST = 'SETUP_TV_ENGINE_REQUEST';
export const SETUP_TV_ENGINE_SUCCESS = 'SETUP_TV_ENGINE_SUCCESS';
export const SETUP_TV_ENGINE_ERROR = 'SETUP_TV_ENGINE_ERROR';

export const GET_PAGE_REQUEST = 'GET_PAGE_REQUEST';
export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';
export const GET_PAGE_ERROR = 'GET_PAGE_ERROR';

export const CREATE_PAGE_REQUEST = 'CREATE_PAGE_REQUEST';
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS';
export const CREATE_PAGE_ERROR = 'CREATE_PAGE_ERROR';

export const EDIT_CMS_PAGE_REQUEST = 'EDIT_CMS_PAGE_REQUEST';
export const EDIT_CMS_PAGE_SUCCESS = 'EDIT_CMS_PAGE_SUCCESS';
export const EDIT_CMS_PAGE_ERROR = 'EDIT_CMS_PAGE_ERROR';

export const REMOVE_PAGE_REQUEST = 'REMOVE_PAGE_REQUEST';
export const REMOVE_PAGE_SUCCESS = 'REMOVE_PAGE_SUCCESS';
export const REMOVE_PAGE_ERROR = 'REMOVE_PAGE_ERROR';

export const GET_ROWS_LIST_REQUEST = 'GET_ROWS_LIST_REQUEST';
export const GET_ROWS_LIST_SUCCESS = 'GET_ROWS_LIST_SUCCESS';
export const GET_ROWS_LIST_ERROR = 'GET_ROWS_LIST_ERROR';

export const GET_ROW_REQUEST = 'GET_ROW_REQUEST';
export const GET_ROW_SUCCESS = 'GET_ROW_SUCCESS';
export const GET_ROW_ERROR = 'GET_ROW_ERROR';

export const EDIT_ROW_REQUEST = 'EDIT_ROW_REQUEST';
export const EDIT_ROW_SUCCESS = 'EDIT_ROW_SUCCESS';
export const EDIT_ROW_ERROR = 'EDIT_ROW_ERROR';

export const CREATE_ROW_REQUEST = 'CREATE_ROW_REQUEST';
export const CREATE_ROW_SUCCESS = 'CREATE_ROW_SUCCESS';
export const CREATE_ROW_ERROR = 'CREATE_ROW_ERROR';

export const REMOVE_ROW_REQUEST = 'REMOVE_ROW_REQUEST';
export const REMOVE_ROW_SUCCESS = 'REMOVE_ROW_SUCCESS';
export const REMOVE_ROW_ERROR = 'REMOVE_ROW_ERROR';

export const GET_TABS_LIST_REQUEST = 'GET_TABS_LIST_REQUEST';
export const GET_TABS_LIST_SUCCESS = 'GET_TABS_LIST_SUCCESS';
export const GET_TABS_LIST_ERROR = 'GET_TABS_LIST_ERROR';

export const GET_TAB_REQUEST = 'GET_TAB_REQUEST';
export const GET_TAB_SUCCESS = 'GET_TAB_SUCCESS';
export const GET_TAB_ERROR = 'GET_TAB_ERROR';

export const EDIT_TAB_REQUEST = 'EDIT_TAB_REQUEST';
export const EDIT_TAB_SUCCESS = 'EDIT_TAB_SUCCESS';
export const EDIT_TAB_ERROR = 'EDIT_TAB_ERROR';

export const CREATE_TAB_REQUEST = 'CREATE_TAB_REQUEST';
export const CREATE_TAB_SUCCESS = 'CREATE_TAB_SUCCESS';
export const CREATE_TAB_ERROR = 'CREATE_TAB_ERROR';

export const CREATE_FILTERING_TAB_REQUEST = 'CREATE_FILTERING_TAB_REQUEST';
export const CREATE_FILTERING_TAB_SUCCESS = 'CREATE_FILTERING_TAB_SUCCESS';
export const CREATE_FILTERING_TAB_ERROR = 'CREATE_FILTERING_TAB_ERROR';

export const REMOVE_TAB_REQUEST = 'REMOVE_TAB_REQUEST';
export const REMOVE_TAB_SUCCESS = 'REMOVE_TAB_SUCCESS';
export const REMOVE_TAB_ERROR = 'REMOVE_TAB_ERROR';

export const GET_MANUALLY_LIST_REQUEST = 'GET_MANUALLY_LIST_REQUEST';
export const GET_MANUALLY_LIST_SUCCESS = 'GET_MANUALLY_LIST_SUCCESS';
export const GET_MANUALLY_LIST_ERROR = 'GET_MANUALLY_LIST_ERROR';

export const GET_MANUALLY_ITEM_REQUEST = 'GET_MANUALLY_ITEM_REQUEST';
export const GET_MANUALLY_ITEM_SUCCESS = 'GET_MANUALLY_ITEM_SUCCESS';
export const GET_MANUALLY_ITEM_ERROR = 'GET_MANUALLY_ITEM_ERROR';

export const EDIT_MANUALLY_ITEM_REQUEST = 'EDIT_MANUALLY_ITEM_REQUEST';
export const EDIT_MANUALLY_ITEM_SUCCESS = 'EDIT_MANUALLY_ITEM_SUCCESS';
export const EDIT_MANUALLY_ITEM_ERROR = 'EDIT_MANUALLY_ITEM_ERROR';

export const CREATE_MANUALLY_ITEM_REQUEST = 'CREATE_MANUALLY_ITEM_REQUEST';
export const CREATE_MANUALLY_ITEM_SUCCESS = 'CREATE_MANUALLY_ITEM_SUCCESS';
export const CREATE_MANUALLY_ITEM_ERROR = 'CREATE_MANUALLY_ITEM_ERROR';

export const REMOVE_MANUALLY_ITEM_REQUEST = 'REMOVE_MANUALLY_ITEM_REQUEST';
export const REMOVE_MANUALLY_ITEM_SUCCESS = 'REMOVE_MANUALLY_ITEM_SUCCESS';
export const REMOVE_MANUALLY_ITEM_ERROR = 'REMOVE_MANUALLY_ITEM_ERROR';

export const GET_SUBPAGES_REQUEST = 'GET_SUBPAGES_REQUEST';
export const GET_SUBPAGES_SUCCESS = 'GET_SUBPAGES_SUCCESS';
export const GET_SUBPAGES_ERROR = 'GET_SUBPAGES_ERROR';

export const SET_SELECTED_MANUALLY_ITEM_REQUEST = 'SET_SELECTED_MANUALLY_ITEM_REQUEST';
export const SET_SELECTED_MANUALLY_ITEM_SUCCESS = 'SET_SELECTED_MANUALLY_ITEM_SUCCESS';

export const CHANGE_MANUALLY_ITEMS_ORDERS_REQUEST = 'CHANGE_MANUALLY_ITEMS_ORDERS_REQUEST';
export const CHANGE_MANUALLY_ITEMS_ORDERS_SUCCESS = 'CHANGE_MANUALLY_ITEMS_ORDERS_SUCCESS';
export const CHANGE_MANUALLY_ITEMS_ORDERS_ERROR = 'CHANGE_MANUALLY_ITEMS_ORDERS_ERROR';

export const GET_BANNERS_LIST_REQUEST = 'GET_BANNERS_LIST_REQUEST';
export const GET_BANNERS_LIST_SUCCESS = 'GET_BANNERS_LIST_SUCCESS';
export const GET_BANNERS_LIST_ERROR = 'GET_BANNERS_LIST_ERROR';

export const GET_BANNER_REQUEST = 'GET_BANNER_REQUEST';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_ERROR = 'GET_BANNER_ERROR';

export const EDIT_BANNER_REQUEST = 'EDIT_BANNER_REQUEST';
export const EDIT_BANNER_SUCCESS = 'EDIT_BANNER_SUCCESS';
export const EDIT_BANNER_ERROR = 'EDIT_BANNER_ERROR';

export const CREATE_BANNER_REQUEST = 'CREATE_BANNER_REQUEST';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_ERROR = 'CREATE_BANNER_ERROR';

export const REMOVE_BANNER_REQUEST = 'REMOVE_BANNER_REQUEST';
export const REMOVE_BANNER_SUCCESS = 'REMOVE_BANNER_SUCCESS';
export const REMOVE_BANNER_ERROR = 'REMOVE_BANNER_ERROR';

export const GET_SLIDERS_LIST_REQUEST = 'GET_SLIDERS_LIST_REQUEST';
export const GET_SLIDERS_LIST_SUCCESS = 'GET_SLIDERS_LIST_SUCCESS';
export const GET_SLIDERS_LIST_ERROR = 'GET_SLIDERS_LIST_ERROR';

export const GET_SLIDERS_LIST_CMS_REQUEST = 'GET_SLIDERS_LIST_CMS_REQUEST';
export const GET_SLIDERS_LIST_CMS_SUCCESS = 'GET_SLIDERS_LIST_CMS_SUCCESS';
export const GET_SLIDERS_LIST_CMS_ERROR = 'GET_SLIDERS_LIST_CMS_ERROR';

export const GET_SLIDER_REQUEST = 'GET_SLIDER_REQUEST';
export const GET_SLIDER_SUCCESS = 'GET_SLIDER_SUCCESS';
export const GET_SLIDER_ERROR = 'GET_SLIDER_ERROR';

export const EDIT_SLIDER_REQUEST = 'EDIT_SLIDER_REQUEST';
export const EDIT_SLIDER_SUCCESS = 'EDIT_SLIDER_SUCCESS';
export const EDIT_SLIDER_ERROR = 'EDIT_SLIDER_ERROR';

export const CREATE_SLIDER_REQUEST = 'CREATE_SLIDER_REQUEST';
export const CREATE_SLIDER_SUCCESS = 'CREATE_SLIDER_SUCCESS';
export const CREATE_SLIDER_ERROR = 'CREATE_SLIDER_ERROR';

export const REMOVE_SLIDER_REQUEST = 'REMOVE_SLIDER_REQUEST';
export const REMOVE_SLIDER_SUCCESS = 'REMOVE_SLIDER_SUCCESS';
export const REMOVE_SLIDER_ERROR = 'REMOVE_SLIDER_ERROR';

export const GET_BANNERS_LIST_SLIDER_REQUEST = 'GET_BANNERS_LIST_SLIDER_REQUEST';
export const GET_BANNERS_LIST_SLIDER_SUCCESS = 'GET_BANNERS_LIST_SLIDER_SUCCESS';
export const GET_BANNERS_LIST_SLIDER_ERROR = 'GET_BANNERS_LIST_SLIDER_ERROR';

export const GET_NAVIGATION_LIST_REQUEST = 'GET_NAVIGATION_LIST_REQUEST';
export const GET_NAVIGATION_LIST_SUCCESS = 'GET_NAVIGATION_LIST_SUCCESS';
export const GET_NAVIGATION_LIST_ERROR = 'GET_NAVIGATION_LIST_ERROR';

export const GET_NAVIGATION_REQUEST = 'GET_NAVIGATION_REQUEST';
export const GET_NAVIGATION_SUCCESS = 'GET_NAVIGATION_SUCCESS';
export const GET_NAVIGATION_ERROR = 'GET_NAVIGATION_ERROR';

export const EDIT_NAVIGATION_REQUEST = 'EDIT_NAVIGATION_REQUEST';
export const EDIT_NAVIGATION_SUCCESS = 'EDIT_NAVIGATION_SUCCESS';
export const EDIT_NAVIGATION_ERROR = 'EDIT_NAVIGATION_ERROR';

export const CREATE_NAVIGATION_REQUEST = 'CREATE_NAVIGATION_REQUEST';
export const CREATE_NAVIGATION_SUCCESS = 'CREATE_NAVIGATION_SUCCESS';
export const CREATE_NAVIGATION_ERROR = 'CREATE_NAVIGATION_ERROR';

export const REMOVE_NAVIGATION_REQUEST = 'REMOVE_NAVIGATION_REQUEST';
export const REMOVE_NAVIGATION_SUCCESS = 'REMOVE_NAVIGATION_SUCCESS';
export const REMOVE_NAVIGATION_ERROR = 'REMOVE_NAVIGATION_ERROR';

export const GET_CMS_PAGES_FOR_NAVIGATION_REQUEST = 'GET_CMS_PAGES_FOR_NAVIGATION_REQUEST';
export const GET_CMS_PAGES_FOR_NAVIGATION_SUCCESS = 'GET_CMS_PAGES_FOR_NAVIGATION_SUCCESS';
export const GET_CMS_PAGES_FOR_NAVIGATION_ERROR = 'GET_CMS_PAGES_FOR_NAVIGATION_ERROR';

export const EDIT_NAVIGATION_ORDER_REQUEST = 'EDIT_NAVIGATION_ORDER_REQUEST';
export const EDIT_NAVIGATION_ORDER_SUCCESS = 'EDIT_NAVIGATION_ORDER_SUCCESS';
export const EDIT_NAVIGATION_ORDER_ERROR = 'EDIT_NAVIGATION_ORDER_ERROR';

export const GET_PERMISSION_GROUP = 'GET_PERMISSION_GROUP';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_ERROR = 'GET_PERMISSION_ERROR';

export const GET_EV_GROUPS_CMS_REQUEST = 'GET_EV_GROUPS_CMS_REQUEST';
export const GET_EV_GROUPS_CMS_SUCCESS = 'GET_EV_GROUPS_CMS_SUCCESS';
export const GET_EV_GROUPS_CMS_ERROR = 'GET_EV_GROUPS_CMS_ERROR';

export const GET_VAE_CONFIG_REQUEST = 'GET_VAE_CONFIG_REQUEST';
export const GET_VAE_CONFIG_SUCCESS = 'GET_VAE_CONFIG_SUCCESS';
export const GET_VAE_CONFIG_ERROR = 'GET_VAE_CONFIG_ERROR';

export const EDIT_VAE_CONFIG_REQUEST = 'EDIT_VAE_CONFIG_REQUEST';
export const EDIT_VAE_CONFIG_SUCCESS = 'EDIT_VAE_CONFIG_SUCCESS';
export const EDIT_VAE_CONFIG_ERROR = 'EDIT_VAE_CONFIG_ERROR';

export const GET_VAE_ICONS_REQUEST = 'GET_VAE_ICONS_REQUEST';
export const GET_VAE_ICONS_SUCCESS = 'GET_VAE_ICONS_SUCCESS';
export const GET_VAE_ICONS_ERROR = 'GET_VAE_ICONS_ERROR';

export const EDIT_ALL_NAVIGATIONS_ICONS_REQUEST = 'EDIT_ALL_NAVIGATIONS_ICONS_REQUEST';
export const EDIT_ALL_NAVIGATIONS_ICONS_SUCCESS = 'EDIT_ALL_NAVIGATIONS_ICONS_SUCCESS';
export const EDIT_ALL_NAVIGATIONS_ICONS_ERROR = 'EDIT_ALL_NAVIGATIONS_ICONS_ERROR';
