<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, classes, required, ariaInput, ariaMsg }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    mode="passive"
  >
    <div
      class="group"
      :class="[classes]"
    >
      <label>
        {{ fieldLabel }}
        <span
          v-if="required"
          class="asterisk"
        >
          *
        </span>
      </label>
      <span class="select-wrapper">
        <MultiSelect
          v-model="valueModel"
          :options="optionModel"
          :tagPlaceholder="tagErrors.length ? tagErrors[0] : tagPlaceholder"
          :placeholder="placeholder"
          :label="tagLabel"
          :trackBy="tagTrackBy"
          :multiple="true"
          :taggable="taggable"
          :blockKeys="['Delete']"
          :searchable="searchable"
          :disabled="disabled"
          @search-change="onSearchChange"
          @tag="createTag"
        />
      </span>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
          v-bind="ariaMsg"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import { validate } from 'vee-validate';
import MultiSelect from 'vue-multiselect';
import BaseMaterialInput from './BaseMaterialInput.vue';

export default {
  name: 'MaterialMultiSelect',
  components: {
    MultiSelect,
  },
  extends: BaseMaterialInput,
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: null,
    },
    handleChange: {
      type: Function,
      default: () => {},
    },
    className: {
      type: String,
      default: '',
    },
    tagPlaceholder: {
      type: String,
      default: 'Add',
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    tagLabel: {
      type: String,
      default: 'name',
    },
    tagTrackBy: {
      type: String,
      default: 'code',
    },
    searchable: {
      type: Boolean,
      default: true,
      required: false,
    },
    taggable: {
      type: Boolean,
      default: false,
      required: false,
    },
    tagRules: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionModel: this.initOptions(),
      tagErrors: [],
    };
  },
  computed: {
    valueModel: {
      get() {
        return this.optionModel.filter((item) => this.value.includes(item[this.tagTrackBy]));
      },
      set(value) {
        this.$emit('input', value.map((item) => item[this.tagTrackBy]));
      },
    },
  },
  methods: {
    initOptions() {
      const options = [...this.options];
      this.value.forEach((item) => {
        const hasItem = options.some((i) => i.code === item);

        if (!hasItem) {
          options.push({
            code: item,
            name: item,
          });
        }
      });

      return options;
    },
    onSearchChange(data) {
      this.tagErrors = [];

      validate(data, this.tagRules).then((result) => {
        if (!result.valid) {
          this.tagErrors = result.errors;
        }
      });
    },
    createTag(data) {
      validate(data, this.tagRules).then((result) => {
        if (result.valid) {
          this.addTag({
            code: data,
            name: data,
          });
        }
      });
    },
    addTag(tag) {
      this.optionModel = [...this.optionModel, tag];
      this.valueModel = [...this.valueModel, tag];
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
  }

  label {
    font-size: 16px;
  }

  .asterisk {
    color: #fb3951;
  }

  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .select-wrapper {
    margin-top: 10px;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
</style>
