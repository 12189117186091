<template>
  <div
    v-if="isHide"
    class="reloader-overlay"
  >
    <div class="reloader-wrapper">
      <span>Data is outdated</span>
      <Button
        :classType="'primary'"
        :srcSvg="iconReload"
        :eventFunction="handleReload"
      />
    </div>
  </div>
</template>

<script>
import iconReload from '../../assets/icons/icon-reload.svg';
import Button from '../common/Button.vue';

export default {
  name: 'DataReloader',
  components: {
    Button,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    isHide: {
      type: Boolean,
      default: false,
    },
    onReload: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconReload,
    };
  },
  methods: {
    handleReload() {
      this.onReload(this.name);
    },
  },
};
</script>

<style scoped>
  .reloader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(2px) brightness(60%);
    z-index: 9;
    filter: none !important;
  }

  .reloader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .reloader-wrapper span {
    font-size: 24px;
    color: #FB3951;
    margin-bottom: 10px;
  }

  .reloader-wrapper .button {
    width: 60px;
    min-height: 60px;
    padding: 0;
  }

</style>
