<template>
  <div class="overlay">
    <div
      :class="['wrapper',
               mediaItem.type === 'audio' && 'wrapper-audio',
               (mediaItem.type === 'livestream' || mediaItem.type === 'livestream_url')
                 && '']"
    >
      <div class="choose-asset-title">
        Choose Asset Type
      </div>
      <div
        :class="['flex',
                 mediaItem.type !== 'vod' ? 'cent' : '',
                 (mediaItem.type === 'livestream' || mediaItem.type === 'livestream_url')
                   && '']"
      >
        <div
          v-if="mediaItem.type !== 'audio'"
          class="big-btn-wrap"
        >
          <Button
            :eventFunction="toSuppVideo"
            :smallSize="false"
            :classType="'primary big-btn'"
            :srcSvg="iconSuppVideo"
          />
          <div class="big-btn-text">
            Video
          </div>
        </div>
        <div
          v-if="mediaItem.type === 'vod'"
          class="big-btn-wrap left"
        >
          <Button
            :eventFunction="toDo"
            :smallSize="false"
            :classType="'primary big-btn'"
            :srcSvg="iconAlternative"
          />
          <span class="a">A</span>
          <div class="big-btn-text">
            Alternate Audio
          </div>
        </div>
        <div
          v-if="mediaItem.type !== 'livestream' && mediaItem.type !== 'livestream_url'"
          class="big-btn-wrap"
        >
          <Button
            :eventFunction="toSub"
            :smallSize="false"
            :classType="'primary big-btn'"
            :srcSvg="iconSub"
          />
          <div class="big-btn-text">
            Subtitle
          </div>
        </div>
        <div
          v-if="mediaItem.type === 'audio' || mediaItem.type === 'vod'"
          :class="['big-btn-wrap',
                   mediaItem.type === 'vod' && 'marg-20',
          ]"
        >
          <Button
            :eventFunction="toSuppAudio"
            :smallSize="false"
            :classType="'primary big-btn'"
            :srcSvg="iconSuppAudio"
          />
          <div class="big-btn-text">
            Supplemental Audio
          </div>
        </div>
        <div
          :class="['big-btn-wrap',
                   mediaItem.type === 'vod' && 'marg-20']"
        >
          <Button
            :eventFunction="toImage"
            :smallSize="false"
            :classType="'primary big-btn'"
            :srcSvg="iconImage"
          />
          <div class="big-btn-text">
            Image
          </div>
        </div>
        <div
          :class="['big-btn-wrap']"
        >
          <Button
            :eventFunction="toDocument"
            :smallSize="false"
            :classType="'primary big-btn'"
            :srcSvg="iconDocument"
          />
          <div class="big-btn-text">
            Document
          </div>
        </div>
        <div :class="['big-btn-wrap']">
          <Button
            :eventFunction="toLinked"
            :smallSize="false"
            :classType="'primary big-btn'"
            :srcSvg="iconLinked"
          />
          <div class="big-btn-text">
            Linked File
          </div>
        </div>
        <div
          :class="['big-btn-wrap',
                   mediaItem.type !== 'audio' && 'big-btn-wrap-vod',]"
        >
          <Button
            :eventFunction="toOther"
            :smallSize="false"
            :classType="'primary big-btn'"
            :srcSvg="iconOther"
          />
          <div class="big-btn-text">
            Other
          </div>
        </div>
      </div>
      <div
        :class="['cancel-wrap',
                 (mediaItem.type === 'livestream' || mediaItem.type === 'livestream_url')
                   && '']"
      >
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backTo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { SET_EDIT_FORM } from '../../../../store/actions/common';
import Button from '../../../common/Button.vue';
import iconAlternative from '../../../../assets/icons/icon-alternative-audio.svg';
import iconSub from '../../../../assets/icons/icon-subtitle.svg';
import iconSuppAudio from '../../../../assets/icons/icon-supplemental-audio.svg';
import iconSuppVideo from '../../../../assets/icons/icon-video-supp-file.svg';
import iconDocument from '../../../../assets/icons/icon-document-asset.svg';
import iconOther from '../../../../assets/icons/icon-other-asset.svg';
import iconLinked from '../../../../assets/icons/icon-linked-file-asset.svg';
import iconImage from '../../../../assets/icons/icon-image-asset.svg';

export default {
  name: 'AddAssetChoice',
  components: {
    Button,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    mediaItem: {
      type: Object,
      default: null,
    },
    modalMode: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    toAlternative: {
      type: Function,
      default: () => {},
    },
    openSubModal: {
      type: Function,
      default: () => {},
    },
    openSuppModal: {
      type: Function,
      default: () => {},
    },
    openSuppVideoModal: {
      type: Function,
      default: () => {},
    },
    openDOcumentModal: {
      type: Function,
      default: () => {},
    },
    openOtherModal: {
      type: Function,
      default: () => {},
    },
    openLinkedModal: {
      type: Function,
      default: () => {},
    },
    openImageModal: {
      type: Function,
      default: () => {},
    },
    showLoader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modalType: 'subtitle',
      iconAlternative,
      iconSub,
      iconSuppAudio,
      iconSuppVideo,
      iconDocument,
      iconOther,
      iconLinked,
      iconImage,
    };
  },
  computed: {
    ...mapState({
      isEditedForm: (state) => state.common.isEditedForm,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  methods: {
    preventNav(event) {
      if (this.modal && this.isEditedForm) {
        event.returnValue = 'You have unfinished changes!';
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
    },
    backTo() {
      this.onClose();
    },
    toDo() {
      this.toAlternative();
    },
    toSub() {
      this.openSubModal();
    },
    toSuppAudio() {
      this.openSuppModal();
    },
    toSuppVideo() {
      this.openSuppVideoModal();
    },
    toDocument() {
      this.openDOcumentModal();
    },
    toOther() {
      this.openOtherModal();
    },
    toLinked() {
      this.openLinkedModal();
    },
    toImage() {
      this.openImageModal();
    },
  },
};
</script>
<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  background: #222222;
  height: auto;
  overflow-y: auto;
  padding: 30px;
  position: absolute;
  border-radius: 4px;
  width: 405px;
}

.choose-asset-title {
  font-size: 16px;
  font-weight: bold;
}

.wrapper-audio {
  width: 405px;
}

.wrapper-live {
  width: auto;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 30px;
}

.big-btn-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.big-btn-text {
  padding-top: 10px;
  width: 76%;
  text-align: center;
}

.cancel-wrap {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.left {
  position: relative;
}

.a {
  position: absolute;
  left: 16px;
  top: 8px
}

.flex-live {
  padding-left: 20px;
  justify-content: center;
  align-items: center;
}

.centr {
  justify-content: center;
}

.marg-20 {
  margin-left: -20px;
}

.big-btn-wrap-vod {
  margin-right: 151px;
}
</style>
