<template>
  <div class="group pagination-wrapper">
    <div
      v-if="totalPages && page"
      class="select-group"
    >
      <button
        :class="['change-pages', page === 1 ? '' : 'change-pages-active']"
        @click="showPreviousPage"
      >
        <inline-svg
          :src="iconArrow"
          class="icon"
        >
        </inline-svg>
      </button>
      <span
        v-if="totalPages"
        class="select-wrapper"
      >
        <select
          v-model="page"
          @change="setPage"
        >
          <option
            v-for="item in pages"
            :key="item"
            :value="item"
          >
            {{ item }}
          </option>
        </select>
        <inline-svg
          :src="iconSelect"
          class="svg-icon"
        >
        </inline-svg>
      </span>
      <button
        :class="['change-pages', page === totalPages ? '' : 'change-pages-active']"
        @click="showNextPage"
      >
        <inline-svg
          :src="iconArrow"
          class="icon icon-able-next"
        >
        </inline-svg>
      </button>
    </div>
  </div>
</template>

<script>
import iconSelect from '../../assets/icons/icon-for-select.svg';
import iconArrow from '../../assets/icons/icon-arrow.svg';

export default {
  name: 'Pagination',
  props: {
    totalPages: {
      type: Number,
      default: null,
    },
    currentPage: {
      type: Number,
      default: null,
    },
    page: {
      type: Number,
      default: null,
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconSelect,
      iconArrow,
    };
  },
  computed: {
    pages() {
      return this.totalPages && Array.from({ length: this.totalPages }, (v, k) => k + 1);
    },
  },
  methods: {
    setPage() {
      this.action(this.page);
    },
    showPreviousPage() {
      if (this.page !== 1) {
        this.action(this.page - 1);
      }
    },
    showNextPage() {
      if (this.page !== this.totalPages) {
        this.action(this.page + 1);
      }
    },
  },
};
</script>

<style scoped>
  select {
    appearance: none;
    background: #222;
    border: 1px solid #383b40;
    border-radius: 3px;
    font-size: 14px;
    height: 70%;
    padding: 5px;
    width: 45px;
  }

  .select-wrapper {
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
  }

  .svg-icon {
    pointer-events: none;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
  }

  select:focus {
    outline: none;
  }

  .group {
    display: flex;
    flex-direction: row;
  }

  .select-group {
    align-items: center;
    display: flex;
    margin-top: 13px;
  }

  .change-pages {
    align-items: center;
    background-color: #43434c;
    border-radius: 50%;
    cursor: not-allowed;
    display: flex;
    height: 30px;
    justify-content: center;
    outline: 0;
    position: relative;
    width: 30px;
  }

  .change-pages-active {
    background-color: #2591cf;
    cursor: pointer;
  }

  .change-pages-active .icon {
    fill-opacity: 1;
  }

  .icon {
    fill-opacity: 0.5;
    position: relative;
  }

  .icon-able-next {
    transform: rotate(180deg);
  }

  .pagination-wrapper {
    height: 50px;
  }
</style>
