export const CREATE_SEASONS_REQUEST = 'CREATE_SEASONS_REQUEST';
export const CREATE_SEASONS_SUCCESS = 'CREATE_SEASONS_SUCCESS';
export const CREATE_SEASONS_ERROR = 'CREATE_SEASONS_ERROR';

export const GET_SEASONS_REQUEST = 'GET_SEASONS_REQUEST';
export const GET_SEASONS_SUCCESS = 'GET_SEASONS_SUCCESS';
export const GET_SEASONS_ERROR = 'GET_SEASONS_ERROR';

export const GET_SEASONS_LIST_REQUEST = 'GET_SEASONS_LIST_REQUEST';
export const GET_SEASONS_LIST_SUCCESS = 'GET_SEASONS_LIST_SUCCESS';
export const GET_SEASONS_LIST_ERROR = 'GET_SEASONS_LIST_ERROR';

export const EDIT_SEASONS_REQUEST = 'EDIT_SEASONS_REQUEST';
export const EDIT_SEASONS_SUCCESS = 'EDIT_SEASONS_SUCCESS';
export const EDIT_SEASONS_ERROR = 'EDIT_SEASONS_ERROR';

export const DELETE_SEASONS_REQUEST = 'DELETE_SEASONS_REQUEST';
export const DELETE_SEASONS_SUCCESS = 'DELETE_SEASONS_SUCCESS';
export const DELETE_SEASONS_ERROR = 'DELETE_SEASONS_ERROR';
