export const CREATE_CHECKOUT_REQUEST = 'CREATE_CHECKOUT_REQUEST';
export const CREATE_CHECKOUT_SUCCESS = 'CREATE_CHECKOUT_SUCCESS';
export const CREATE_CHECKOUT_ERROR = 'CREATE_CHECKOUT_ERROR';

export const UPDATE_PAYMENT_METHOD_REQUEST = 'UPDATE_PAYMENT_METHOD_REQUEST';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_ERROR = 'UPDATE_PAYMENT_METHOD_ERROR';

export const INVOICES_REQUEST_SET_CANCEL_TOKEN = 'INVOICES_REQUEST_SET_CANCEL_TOKEN';
export const GET_ALL_INVOICES_REQUEST = 'GET_ALL_INVOICES_REQUEST';
export const GET_ALL_INVOICES_SUCCESS = 'GET_ALL_INVOICES_SUCCESS';
export const GET_ALL_INVOICES_ERROR = 'GET_ALL_INVOICES_ERROR';

export const GET_CDN_REPORT_REQUEST = 'GET_CDN_REPORT_REQUEST';
export const GET_CDN_REPORT_SUCCESS = 'GET_CDN_REPORT_SUCCESS';
export const GET_CDN_REPORT_ERROR = 'GET_CDN_REPORT_ERROR';

export const GET_STORAGE_REPORT_REQUEST = 'GET_STORAGE_REPORT_REQUEST';
export const GET_STORAGE_REPORT_SUCCESS = 'GET_STORAGE_REPORT_SUCCESS';
export const GET_STORAGE_REPORT_ERROR = 'GET_STORAGE_REPORT_ERROR';
