<template>
  <div class="checks-wrapper">
    <div
      v-for="day in week"
      :key="day.code"
      :class="['day', error && 'failed-day', disabled && 'disabled']"
    >
      <input
        :id="day.code"
        v-model="day.recurring"
        type="checkbox"
        class="check"
        :disabled="disabled"
      />
      <label
        :for="day.code"
        class="label-day"
      >
        <span class="empty">
        </span>
        {{ day.code }}
      </label>
    </div>
    <transition name="bounce">
      <span
        v-if="error"
        class="error-label"
      >
        {{ error }}
      </span>
    </transition>
  </div>
</template>

<script>
import BaseMaterialInput from './BaseMaterialInput.vue';

export default {
  name: 'MaterialDayCheckbox',
  extends: BaseMaterialInput,
  props: {
    week: {
      type: Array,
      default: null,
    },
    error: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
  .checks-wrapper {
    display: flex;
    flex: 86%;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 10px;
    width: 100%;
  }

  .day {
    cursor: pointer;
    display: flex;
    flex: 20%;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 50px;
    position: relative;
    user-select: none;
  }

  .day input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  .label-day {
    border: 1px solid #383b40;
    border-radius: 3px;
    box-sizing: border-box;
    height: 21px;
    width: 21px;
  }

  .day:hover input ~ .label-day {
    border: 1px solid #2591cf;
    cursor: pointer;
  }

  .disabled:hover input ~ .label-day {
    border: 1px solid #383b40;
    cursor: not-allowed;
  }

  .disabled:hover {
    cursor: not-allowed;
  }

  .day input:checked ~ .label-day {
    border: 1px solid #2591cf;
  }

  .label-day::after {
    content: '';
    display: none;
    position: absolute;
  }

  .day input:checked ~ .label-day::after {
    display: block;
  }

  .day .label-day::after {
    border: solid #2591cf;
    border-width: 0 2px 2px 0;
    height: 10px;
    left: 7px;
    top: 3px;
    transform: rotate(45deg);
    width: 6px;
  }

  .label-day {
    font-size: 14px;
    padding-left: 20px;
  }

  .empty {
    padding-left: 6px;
  }

  .error-label {
    display: flex;
    flex: 80%;
  }

  .failed-day:nth-child(7) {
    border-bottom: 1px solid #fb3951;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
</style>
