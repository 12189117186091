<template>
  <div>
    <MaterialInput
      v-model="mediaItem.name"
      v-bind="formFields.title"
      :disabled="mediaItem.locked"
      :className="'inspector-input'"
    />
    <div
      v-if="mediaItem.type === 'vod'"
      class="inline-row"
    >
      <MaterialInput
        v-model="duration"
        v-bind="formFields.duration"
        :disabled="true"
        :className="'inspector-input'"
      />
      <MaterialInput
        v-model="totalRunTime"
        v-bind="formFields.runTime"
        :disabled="true"
        :className="'inspector-input'"
      />
    </div>
    <MaterialTextArea
      v-model="mediaItem.description"
      v-bind="formFields.description"
      :disabled="mediaItem.locked"
      :className="'inspector-input'"
    />
    <MaterialSelect
      v-model="mediaItem.contentType"
      v-bind="formFields.contentType"
      :withEmptyField="true"
      :options="options"
      :className="'inspector-select'"
      :disabled="mediaItem.locked"
    />
    <div v-if="mediaItem.contentType === 'Episode'">
      <MaterialInput
        v-model="mediaItem.episodeNumber"
        v-bind="formFields.order"
        :className="'inspector-input'"
        :disabled="mediaItem.locked"
      />
    </div>
    <div class="inline-row">
      <MaterialCalendar2
        v-model="date"
        v-bind="formFields.date"
        :className="'inspector-calendar'"
        :isManualInput="false"
        :disabled="mediaItem.locked"
      />
      <MaterialTime
        :value="time"
        v-bind="formFields.time"
        :blockClassName="'inspector-datetime'"
        :isManualInput="false"
        :onChangeTime="onChangeTime"
        :disabled="mediaItem.locked"
      />
    </div>
    <div v-if="mediaItem.id">
      <LanguageTagManagement
        :selectedValue="languages"
        :label="'Language'"
        :tagPlaceholder="'Search ...'"
        :searchable="false"
        :onChange="onLanguageChange"
        :required="false"
        :disabled="mediaItem.locked"
        :commonError="languageError"
      />
    </div>
    <div class="legacy-id-wrapper">
      <MaterialInput
        v-model="mediaItem.legacyId"
        v-bind="formFields.legacyId"
        :className="'inspector-input'"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { NUMBER_INPUT_REG_EXP } from '../utils/validation/regExp';

import LanguageTagManagement from './media/LanguageTagManagement.vue';
import MaterialSelect from './inputs/MaterialSelect.vue';
import MaterialInput from './inputs/MaterialInput.vue';
import MaterialTextArea from './inputs/MaterialTextArea.vue';
import MaterialCalendar2 from './inputs/MaterialCalendar2.vue';
import MaterialTime from './inputs/MaterialTime.vue';
import CONFIG from '../constants/config';

export default {
  name: 'BasicMediaInfo',
  components: {
    LanguageTagManagement,
    MaterialSelect,
    MaterialInput,
    MaterialTextArea,
    MaterialCalendar2,
    MaterialTime,
  },
  props: {
    mediaItem: {
      type: Object,
      default: null,
    },
    formPrefix: {
      type: String,
      default: null,
    },
    onLanguageChange: {
      type: Function,
      default: () => {},
    },
    languageError: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      errorDescription: CONFIG.errorMessages.errorDescriptionInspector,
      options: CONFIG.contentTypeList,
      newDate: '',
      newTime: '',
      formData: {
        duration: '',
        runTime: '',
      },
      formFields: {
        title: {
          type: 'text',
          name: 'title',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        duration: {
          type: 'text',
          name: 'duration',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        runTime: {
          type: 'text',
          name: 'runTime',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        description: {
          name: 'description',
          formPrefix: this.formPrefix,
          cols: 30,
          rows: 10,
          rules: {
            required: false,
            max: 1800,
          },
        },
        language: {
          name: 'language',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        contentType: {
          name: 'contentType',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        order: {
          type: 'number',
          name: 'order',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            regex: NUMBER_INPUT_REG_EXP,
            min_value: 0,
            max_value: 1000,
            numeric: true,
          },
        },
        date: {
          type: 'date',
          valueType: 'YYYY-MM-DD',
          name: 'date',
          format: 'DD MMM YYYY',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        time: {
          type: 'time',
          name: 'time',
          format: 'hh:mm A',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        legacyId: {
          type: 'text',
          name: 'legacyId',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getMainProfile',
    ]),
    totalRunTime() {
      return this.setMarkerAsDate(this.mediaItem.totalRunTime);
    },
    duration() {
      return this.setMarkerAsDate(this.mediaItem.duration);
    },
    languages() {
      return this.mediaItem.formattedLanguages
        ? this.mediaItem.formattedLanguages.map((item) => ({
          id: item.code, name: item.name,
        })) : [];
    },
    getTimezone() {
      return (this.getMainProfile.timezone) ? this.getMainProfile.timezone : moment.tz.guess();
    },
    date: {
      get() {
        return moment.utc(this.mediaItem.airDate).tz(this.getTimezone).format('YYYY-MM-DD');
      },
      set(date) {
        this.newDate = date;
      },
    },
    time() {
      return moment.utc(this.mediaItem.airDate).tz(this.getTimezone).format('hh:mm A');
    },
  },
  watch: {
    newDate: 'getDateTime',
    newTime: 'getDateTime',
  },
  methods: {
    setMarkerAsDate(value) {
      return moment.utc(value * 1000).format('HH:mm:ss');
    },
    onChangeTime(t) {
      this.newTime = `T${t.data.HH}:${t.data.mm}:00`;
    },
    getDateTime() {
      let dateTimeString = '';

      dateTimeString += this.newDate
        ? moment(this.newDate).format('YYYY-MM-DD')
        : this.date;

      dateTimeString += this.newTime
        ? this.newTime
        : `T${moment.utc(this.mediaItem.airDate).tz(this.getTimezone).format('HH:mm:ss')}`;

      this.mediaItem.airDate = moment.tz(dateTimeString, this.getTimezone).utc().format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>

<style scoped>
  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .group.failed {
    margin-bottom: 10px;
  }

  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  .failed .title-input {
    border-bottom: 1px solid #fb3951;
  }

  .asterisk {
    color: #fb3951;
  }

  .inline-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .legacy-id-wrapper {
    margin-top: 20px;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
</style>
