<!-- eslint-disable vue/valid-v-model -->
<template>
  <div
    v-if="modal"
    class="overlay"
  >
    <div
      v-model="modal"
      class="wrapper"
    >
      <div class="body-text">
        <ul>
          <li
            v-for="(message, index) in errorsMessages"
            :key="index"
          >
            {{ message }}
          </li>
        </ul>
      </div>
      <hr />
      <div class="button-wrapper">
        <Button
          :title="'OK'"
          :classType="'primary'"
          :eventFunction="closeModalhandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  SET_OVERFLOW,
} from '../store/actions/common';
import Button from './common/Button.vue';

export default {
  name: 'NotificationServerErrorsModal',
  components: {
    Button,
  },
  props: {
    modal: Boolean,
    title: {
      type: String,
      default: '',
    },
    bodyMessages: {
      type: Array,
      default: () => [],
    },
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
  },
  computed: {
    errorsMessages() {
      return this.bodyMessages.map((item) => item.error_description);
    },
  },
  methods: {
    closeModal() {
      if (this.onClose) {
        this.onClose();
        return;
      }
      this.$emit('input', false);
      this.$store.dispatch(SET_OVERFLOW, false);
    },
    closeModalhandler() {
      this.closeModal();
    },
  },
};
</script>

<style scoped>
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrapper {
    background-color: #383b40;
    border-radius: 4px;
    display: block;
    max-width: 800px;
    outline: none;
    overflow-wrap: break-word;
    text-decoration: none;
    width: 95%;
  }
  .body-text {
    color: #fff;
    font-size: 16px;
  }
  ul {
    margin: 40px 55px 5px 50px;
    max-height: 500px;
    overflow-y: auto;
  }
  li {
    margin-bottom: 15px;
  }
  hr {
    border: solid;
    border-color: rgba(0, 0, 0, 0.12);
    border-width: thin 0 0;
    display: block;
    flex: 1 1 0;
    height: 0;
    max-height: 0;
    max-width: 100%;
    transition: inherit;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
  }

</style>
