<script>
import TagManagement from '../inputs/TagManagement.vue';
import { DOMAIN_REG_EXP } from '../../utils/validation/regExp';

export default {
  name: 'DomainTagManagement',
  extends: TagManagement,
  methods: {
    createTag(data) {
      const validation = this.validateSearch(data.trim());

      if (validation.isValid) {
        const tag = {
          code: this.selectedValue.length + 1,
          name: data,
        };

        this.addTag(tag);
      }
    },
    validateSearch(searchValue) {
      const validation = {
        value: searchValue,
        isValid: true,
        message: '',
      };

      if (searchValue && !searchValue.match(DOMAIN_REG_EXP)) {
        validation.isValid = false;
        validation.message = 'Domain Format is invalid';
      } else if (searchValue.length > 63) {
        validation.isValid = false;
        validation.message = 'The field can have no more than 63 characters';
      }

      return validation;
    },
  },
};
</script>
