export const GET_BROWSER_REQUEST = 'GET_BROWSER_REQUEST';
export const GET_BROWSER_SUCCESS = 'GET_BROWSER_SUCCESS';
export const GET_BROWSER_ERROR = 'GET_BROWSER_ERROR';

export const GET_DEVICE_REQUEST = 'GET_DEVICE_REQUEST';
export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
export const GET_DEVICE_ERROR = 'GET_DEVICE_ERROR';

export const GET_TIME_PLAYED_REQUEST = 'GET_TIME_PLAYED_REQUEST';
export const GET_TIME_PLAYED_SUCCESS = 'GET_TIME_PLAYED_SUCCESS';
export const GET_TIME_PLAYED_ERROR = 'GET_TIME_PLAYED_ERROR';

export const GET_TOTAL_PLAY_REQUEST = 'GET_TOTAL_PLAY_REQUEST';
export const GET_TOTAL_PLAY_SUCCESS = 'GET_TOTAL_PLAY_SUCCESS';
export const GET_TOTAL_PLAY_ERROR = 'GET_TOTAL_PLAY_ERROR';

export const GET_UNIQUE_PLAY_REQUEST = 'GET_UNIQUE_PLAY_REQUEST';
export const GET_UNIQUE_PLAY_SUCCESS = 'GET_UNIQUE_PLAY_SUCCESS';
export const GET_UNIQUE_PLAY_ERROR = 'GET_UNIQUE_PLAY_ERROR';

export const GET_GEO_LOCATION_REQUEST = 'GET_GEO_LOCATION_REQUEST';
export const GET_GEO_LOCATION_SUCCESS = 'GET_GEO_LOCATION_SUCCESS';
export const GET_GEO_LOCATION_ERROR = 'GET_GEO_LOCATION_ERROR';

export const GET_UNIQUE_COUNTRY_PLAYS_REQUEST = 'GET_UNIQUE_COUNTRY_PLAYS_REQUEST';
export const GET_UNIQUE_COUNTRY_PLAYS_SUCCESS = 'GET_UNIQUE_COUNTRY_PLAYS_SUCCESS';
export const GET_UNIQUE_COUNTRY_PLAYS_ERROR = 'GET_UNIQUE_COUNTRY_PLAYS_ERROR';

export const GET_UNIQUE_USA_STATES_PLAYS_REQUEST = 'GET_UNIQUE_USA_STATES_PLAYS_REQUEST';
export const GET_UNIQUE_USA_STATES_PLAYS_SUCCESS = 'GET_UNIQUE_USA_STATES_PLAYS_SUCCESS';
export const GET_UNIQUE_USA_STATES_PLAYS_ERROR = 'GET_UNIQUE_USA_STATES_PLAYS_ERROR';

export const GET_ITEM_LIST_STATS_REQUEST = 'GET_ITEM_LIST_STATS_REQUEST';
export const GET_ITEM_LIST_STATS_SUCCESS = 'GET_ITEM_LIST_STATS_SUCCESS';
export const GET_ITEM_LIST_STATS_ERROR = 'GET_ITEM_LIST_STATS_ERROR';

export const SET_MEDIA_ITEM_LIST_CANCEL_TOKEN = 'SET_MEDIA_ITEM_LIST_CANCEL_TOKEN';
export const GET_MEDIA_ITEM_LIST_REQUEST = 'GET_MEDIA_ITEM_LIST_REQUEST';
export const GET_MEDIA_ITEM_LIST_SUCCESS = 'GET_MEDIA_ITEM_LIST_SUCCESS';
export const GET_MEDIA_ITEM_LIST_ERROR = 'GET_MEDIA_ITEM_LIST_ERROR';

export const GET_MEDIA_ITEM_LIST_ANALYTIC_REQUEST = 'GET_MEDIA_ITEM_LIST_ANALYTIC_REQUEST';
export const GET_MEDIA_ITEM_LIST_ANALYTIC_SUCCESS = 'GET_MEDIA_ITEM_LIST_ANALYTIC_SUCCESS';
export const GET_MEDIA_ITEM_LIST_ANALYTIC_ERROR = 'GET_MEDIA_ITEM_LIST_ANALYTIC_ERROR';

export const GET_MEDIA_TOTAL_REQUEST = 'GET_MEDIA_TOTAL_REQUEST';
export const GET_MEDIA_TOTAL_SUCCESS = 'GET_MEDIA_TOTAL_SUCCESS';
export const GET_MEDIA_TOTAL_ERROR = 'GET_MEDIA_TOTAL_ERROR';

export const GET_MOST_REQUESTED_REQUEST = 'GET_MOST_REQUESTED_REQUEST';
export const GET_MOST_REQUESTED_SUCCESS = 'GET_MOST_REQUESTED_SUCCESS';
export const GET_MOST_REQUESTED_ERROR = 'GET_MOST_REQUESTED_ERROR';

export const GET_GEO_LOCATION_BY_CITY_REQUEST = 'GET_GEO_LOCATION_BY_CITY_REQUEST';
export const GET_GEO_LOCATION_BY_CITY_SUCCESS = 'GET_GEO_LOCATION_BY_CITY_SUCCESS';
export const GET_GEO_LOCATION_BY_CITY_ERROR = 'GET_GEO_LOCATION_BY_CITY_ERROR';

export const GET_GEO_LOCATION_BY_STATE_REQUEST = 'GET_GEO_LOCATION_BY_STATE_REQUEST';
export const GET_GEO_LOCATION_BY_STATE_SUCCESS = 'GET_GEO_LOCATION_BY_STATE_SUCCESS';
export const GET_GEO_LOCATION_BY_STATE_ERROR = 'GET_GEO_LOCATION_BY_STATE_ERROR';
