export const CREATE_PLAYLIST_REQUEST = 'CREATE_PLAYLIST_REQUEST';
export const CREATE_PLAYLIST_SUCCESS = 'CREATE_PLAYLIST_SUCCESS';
export const CREATE_PLAYLIST_ERROR = 'CREATE_PLAYLIST_ERROR';

export const GET_PLAYLIST_REQUEST = 'GET_PLAYLIST_REQUEST';
export const GET_PLAYLIST_SUCCESS = 'GET_PLAYLIST_SUCCESS';
export const GET_PLAYLIST_ERROR = 'GET_PLAYLIST_ERROR';

export const GET_PLAYLIST_LIST_REQUEST = 'GET_PLAYLIST_LIST_REQUEST';
export const GET_PLAYLIST_LIST_SUCCESS = 'GET_PLAYLIST_LIST_SUCCESS';
export const GET_PLAYLIST_LIST_ERROR = 'GET_PLAYLIST_LIST_ERROR';

export const EDIT_PLAYLIST_REQUEST = 'EDIT_PLAYLIST_REQUEST';
export const EDIT_PLAYLIST_SUCCESS = 'EDIT_PLAYLIST_SUCCESS';
export const EDIT_PLAYLIST_ERROR = 'EDIT_PLAYLIST_ERROR';

export const DELETE_PLAYLIST_REQUEST = 'DELETE_PLAYLIST_REQUEST';
export const DELETE_PLAYLIST_SUCCESS = 'DELETE_PLAYLIST_SUCCESS';
export const DELETE_PLAYLIST_ERROR = 'DELETE_PLAYLIST_ERROR';

export const GET_SORT_BY_REQUEST = 'GET_SORT_BY_REQUEST';
export const GET_SORT_BY_SUCCESS = 'GET_SORT_BY_SUCCESS';
export const GET_SORT_BY_ERROR = 'GET_SORT_BY_ERROR';

export const GET_SORT_ORDER_REQUEST = 'GET_SORT_ORDER_REQUEST';
export const GET_SORT_ORDER_SUCCESS = 'GET_SORT_ORDER_SUCCESS';
export const GET_SORT_ORDER_ERROR = 'GET_SORT_ORDER_ERROR';

export const GET_MEDIA_TYPE_REQUEST = 'GET_MEDIA_TYPE_REQUEST';
export const GET_MEDIA_TYPE_SUCCESS = 'GET_MEDIA_TYPE_SUCCESS';
export const GET_MEDIA_TYPE_ERROR = 'GET_MEDIA_TYPE_ERROR';

export const GET_CONTENT_TYPE_REQUEST = 'GET_CONTENT_TYPE_REQUEST';
export const GET_CONTENT_TYPE_SUCCESS = 'GET_CONTENT_TYPE_SUCCESS';
export const GET_CONTENT_TYPE_ERROR = 'GET_CONTENT_TYPE_ERROR';

export const GET_DYNAMIC_MEDIA_REQUEST = 'GET_DYNAMIC_MEDIA_REQUEST';
export const GET_DYNAMIC_MEDIA_SUCCESS = 'GET_DYNAMIC_MEDIA_SUCCESS';
export const GET_DYNAMIC_MEDIA_ERROR = 'GET_DYNAMIC_MEDIA_ERROR';

export const GET_PLAYLISTS_CMS_REQUEST = 'GET_PLAYLISTS_CMS_REQUEST';
export const GET_PLAYLISTS_CMS_SUCCESS = 'GET_PLAYLISTS_CMS_SUCCESS';
export const GET_PLAYLISTS_CMS_ERROR = 'GET_PLAYLISTS_CMS_ERROR';
