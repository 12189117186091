<template>
  <div class="main wrapper 6">
    <Loader :isVisible="isLoading" />
    <div class="column column-1">
      <div class="contact-info">
        <div class="border">
          <h3>Support</h3>
          <SupportForm
            :mediaFolderId="!isSuperAdmin ?
              getRootMediaFolderId : 0"
            :enabled="isAccountActive"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import Loader from '../components/common/Loader.vue';
import SupportForm from '../components/forms/SupportForm.vue';
import CONFIG from '../constants/config';
import { SET_EDIT_FORM } from '../store/actions/common';

export default {
  name: 'Support',
  components: {
    SupportForm,
    Loader,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.support.isLoading,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
    ...mapGetters([
      'isSuperAdmin',
      'getCurrentAccount',
      'getRootMediaFolderId',
      'isAccountActive',
    ]),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
  },
  methods: {
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
    },
  },
};
</script>
<style scoped>
  .wrapper {
    display: flex;
    height: 100%;
    overflow-y: scroll;
  }

  .contact-info {
    display: flex;
    justify-content: center;
  }

  .border {
    margin-top: 5%;
    padding: 45px 60px;
    width: 470px;
  }

  h3 {
    text-align: center;
  }

  @media all and (max-width: 1400px) {
    .border {
      margin-bottom: 6%;
    }
  }
</style>
