import Preview from '../../assets/img/Preview2.png';
import PreviewLive from '../../assets/img/livePreviw.jpg';
import CONFIG from '../../constants/config';

const getPoster = (items, size) => {
  if (items instanceof Array && items.length) {
    const originalPosters = items.map((item) => ({ ...item }));
    originalPosters.sort((a, b) => a.height - b.height);
    const posters = originalPosters.filter((item) => item.height >= size);

    return posters[0] ? posters[0].url : originalPosters[originalPosters.length - 1].url;
  }

  return '';
};

const getMediaItemPoster = (item, size) => {
  if (!item.mediaItemPosters
    || !item.mediaItemPosters.length
    || item.replacing
    || item.status === CONFIG.transcoding.error
  ) {
    return item.type === 'vod' ? Preview : PreviewLive;
  }

  return getPoster(item.mediaItemPosters, size);
};

export default {
  getPoster,
  getMediaItemPoster,
};
