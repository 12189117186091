<template>
  <div class="tree-element">
    <div
      :class="['media-tree-element',
               { 'locked': isLock },
               level === 3 && 'media-tree-element-child-3',
               level >= 4 && 'media-tree-element-child-4']"
    >
      <MediaTreeItem
        :id="id"
        :name="name"
        :hasProtection="hasProtection"
        :isLock="isLock"
        :hasChildren="hasChildren"
        :children="children"
        :selectFolder="toggleFolder"
        :isOpen="isOpen"
        :type="type"
        :openModal="openModal"
        :parent="parent"
        :level="level"
        :path="path"
        :folderId="folderId"
        :uncFolderId="uncFolderId"
        :getMediaList="getMediaList"
        :mediaList="mediaList"
        :isSchedule="isSchedule"
        :tvAppsEnabled="tvAppsEnabled"
        :toggleTvApps="toggleTvApps"
        :isEditTvFolder="isEditTvFolder"
        :metadata="metadata"
        :playlist="playlist"
        :season="season"
        :feature="feature"
        :collectionType="collectionType"
      />
      <div
        v-for="item in children"
        v-show="isOpen || level === 1"
        :key="item.id"
        class="media-tree-folder"
      >
        <MediaTreeElement
          :id="item.id"
          :name="item.name"
          :hasProtection="item.hasProtection"
          :isLock="false"
          :hasChildren="item.children.length > 0"
          :children="item.children"
          :isOpen="isOpen"
          :type="item.type"
          :openModal="openModal"
          :parent="item.parent"
          :level="item.level"
          :path="item.path"
          :folderId="folderId"
          :uncFolderId="uncFolderId"
          :getMediaList="getMediaList"
          :mediaList="mediaList"
          :isSchedule="isSchedule"
          :onFolderChange="onFolderChange"
          :isEditTvFolder="isEditTvFolder"
          :tvAppsEnabled="item.tvAppsEnabled"
          :toggleTvAppsFolder="toggleTvAppsFolder"
          :metadata="item.metadata"
          :playlist="item.playlist"
          :feature="item.feature"
          :season="item.season"
          :collectionType="item.collectionType"
        />
      </div>
    </div>
    <div class="main-line"></div>
  </div>
</template>
<script>
import MediaTreeItem from './MediaTreeItem.vue';

export default {
  name: 'MediaTreeElement',
  components: {
    MediaTreeItem,
  },
  props: {
    mediaList: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    isLock: {
      type: Boolean,
      default: false,
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: null,
    },
    children: {
      type: Array,
      default: null,
    },
    openModal: {
      type: Function,
      default: () => {},
    },
    parent: {
      type: Object,
      default: null,
    },
    folderId: {
      type: String,
      default: '',
    },
    uncFolderId: {
      type: Number,
      default: null,
    },
    getMediaList: {
      type: Function,
      default: () => {},
    },
    onFolderChange: {
      type: Function,
      default: () => {},
    },
    isSchedule: {
      type: Boolean,
      default: false,
    },
    tvAppsEnabled: {
      type: Boolean,
      default: false,
    },
    isEditTvFolder: {
      type: Boolean,
      default: false,
    },
    hasProtection: {
      type: Boolean,
      default: false,
    },
    toggleTvAppsFolder: {
      type: Function,
      default: () => {},
    },
    metadata: {
      type: Object,
      default: () => {},
    },
    path: {
      type: String,
      default: '',
    },
    collectionType: {
      type: String,
      default: '',
    },
    playlist: {
      type: Object,
      default: () => {},
    },
    season: {
      type: Object,
      default: () => {},
    },
    feature: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: false,
      modalMode: '',
    };
  },
  created() {
    const parentsPath = this.isSchedule
      ? sessionStorage.getItem('parentsSchedulePath').split(',')
      : sessionStorage.getItem('parentsPath').split(',');

    if (parentsPath && parentsPath.includes(String(this.id)) && this.hasChildren) {
      this.isOpen = true;
    }
  },
  methods: {
    toggleFolder() {
      if (!this.isSchedule) {
        sessionStorage.setItem('parentsPath', this.path);
        if (this.id !== Number(this.folderId)) {
          this.$router.push(`/media/folder/${this.id}`);
        }
      } else {
        sessionStorage.setItem('parentsSchedulePath', this.path);
        this.onFolderChange(this.id);
      }

      this.isOpen = !this.isOpen;
      localStorage.setItem('openedFolder', this.id);

      if (this.parent) {
        localStorage.setItem('parentFolder', this.parent.id);
      }
    },
    toggleTvApps(event) {
      this.toggleTvAppsFolder(this.id, event);
    },
  },
};
</script>
<style scoped>
  .media-tree-element:hover {
    background-color: transparent;
  }

  .media-tree-element {
    padding: 0 0 0 35px;
  }

  .media-tree-element-child-3 {
    padding: 0;
  }

  .media-tree-element-child-4 {
    padding: 0 0 0 22px;
  }

  .locked {
    padding: 0;
    margin-top: 5px;
  }

  .tree-element-tv {
    padding-left: 8px;
  }

  .tree-element:last-child::after {
    display: none;
  }

  .media-tree-folder:last-child::after {
    display: none;
  }
</style>
