<template>
  <button
    type="button"
    class="login-btn login-btn--facebook"
    @click="onClick"
  >
    {{ title }}
  </button>
</template>
<script>
export default {
  name: 'FacebookButton',
  props: {
    handleClick: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      default: 'Login to Facebook',
    },
  },
  methods: {
    onClick() {
      this.handleClick();
    },
  },
};
</script>
<style>
  .login-btn {
    border: 0;
    border-radius: 0.2em;
    box-sizing: border-box;
    color: #fff;
    font-size: 16px;
    line-height: 34px;
    margin: 0.2em;
    margin-bottom: 15px;
    padding: 0 15px 0 46px;
    position: relative;
    text-align: left;
    white-space: nowrap;
  }

  .login-btn::before {
    box-sizing: border-box;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 34px;
  }

  .login-btn:focus {
    outline: none;
  }

  .login-btn:active {
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
  }

  .login-btn--facebook {
    background-color: #4c69ba;
    background-image: linear-gradient(#4c69ba, #3b55a0);
    text-shadow: 0 -1px 0 #354cbc;
  }

  .login-btn--facebook::before {
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
    border-right: #364e92 1px solid;
  }

  .login-btn--facebook:hover,
  .login-btn--facebook:focus {
    background-color: #5b7bd5;
    background-image: linear-gradient(#5b7bd5, #4864b1);
  }
</style>
