import { render, staticRenderFns } from "./GlobalSettingsForm.vue?vue&type=template&id=45ecf4c4&scoped=true&"
import script from "./GlobalSettingsForm.vue?vue&type=script&lang=js&"
export * from "./GlobalSettingsForm.vue?vue&type=script&lang=js&"
import style0 from "./GlobalSettingsForm.vue?vue&type=style&index=0&id=45ecf4c4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ecf4c4",
  null
  
)

export default component.exports