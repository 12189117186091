<template>
  <div class="settings main-body-right">
    <div class="wrap">
      <div class="system">
        Video Apps Engine
      </div>
      <div class="nav sub-nav">
        <router-link
          to="/cms/pages"
          tag="div"
          class="router"
          :activeClass="'active'"
          :event="moving"
        >
          Pages
        </router-link>
        <router-link
          to="/cms/banners"
          tag="div"
          class="router"
          :activeClass="'active'"
          :event="moving"
        >
          Banners & Sliders
        </router-link>
        <router-link
          to="/cms/navigation"
          tag="div"
          class="router"
          :activeClass="'active'"
          :event="moving"
        >
          Navigation
        </router-link>
        <router-link
          v-if="getCurrentAccount.vaeConfiguration
            && getCurrentAccount.vaeConfiguration.theme === 'church'"
          to="/cms/global-configurations"
          tag="div"
          class="router"
          :activeClass="'active'"
          :event="moving"
        >
          General Configuration
        </router-link>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CmsWrapper',
  computed: {
    ...mapGetters([
      'isAccountActive',
      'getCurrentAccount',
    ]),
    moving() {
      return this.isAccountActive ? 'click' : '';
    },
  },
};
</script>

<style scoped>
.main-body-right {
  display: flex !important;
  padding: 0;
}

.nav {
  background: transparent;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.system {
  border-bottom: 1px solid #383b40;
  padding: 30px 0 25px 25px;
}

.wrap {
  border-right: 1px solid #383b40;
  flex-direction: column;
  min-width: 330px;
}

.router {
  position: relative;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 35px;
}

.router:hover {
  color: #2591cf;
}

.active {
  color: #2591cf;
}

@media all and (max-width: 1200px) {
  .wrap {
    display: none;
  }
}
</style>
