<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ errors }"
    @input="handleFormChange"
  >
    <div
      :key="mediaItem.id"
      class="wrapper"
    >
      <div>
        <div class="player-wrapper">
          <div class="btn-row">
            <div class="btn-row-left">
              <Button
                :title="'Embed'"
                :classType="'primary'"
                :eventFunction="onShowWidgetHandle"
                :smallSize="true"
              />
              <Button
                v-if="mediaItem.type !== 'audio' && !isRead"
                :title="'Add to Event'"
                :classType="'primary'"
                :disabled="isAddToEventDisabled"
                :eventFunction="!isVodMedia ? onShowEventModal : onShowEventHandle"
                :smallSize="true"
                :isBack="true"
              />
            </div>
            <div class="btn-row-right">
              <Button
                v-if="!isRead"
                :title="'Delete'"
                :classType="'transparent'"
                :eventFunction="onOpenModalHandle"
                :smallSize="true"
                :disabled="mediaItem.locked || isReplaceNotAvailable"
              />
              <Button
                v-if="!isRead"
                :title="'Replace'"
                :classType="'warning'"
                :disabled="isReplace || isLoading || mediaItem.locked"
                :eventFunction="onOpenModalHandleReplace"
                :smallSize="true"
              />
              <Button
                v-if="!isRead"
                :title="'Apply'"
                :classType="'primary'"
                :className="'apply-btn'"
                :eventFunction="editMedia"
                :smallSize="true"
                :disabled="mediaItem.locked"
              />
              <div class="close-btn">
                <inline-svg
                  :src="iconClose"
                  class="svg-icon"
                  @click="handleCloseInspector"
                >
                </inline-svg>
              </div>
            </div>
          </div>
          <div class="player-area">
            <div>
              <div
                v-if="mediaItem.playerSource
                  && (mediaItem.playerSource.dash
                    || mediaItem.playerSource.hls
                    || mediaItem.playerSource.progressive)"
                :key="isVodMedia ? mediaItem.playerSource.progressive[0].bitrate : 0"
                class="player"
              >
                <BitmovinPlayer
                  v-show="!isPosterMode"
                  :mediaItem="mediaItem"
                />
              </div>
              <div
                v-else
                v-show="!isPosterMode"
                class="transcoding player"
              >
                <h3
                  v-if="(mediaItem.status === transcoding || mediaItem.replacing)
                    && mediaItem.status !== error"
                >
                  {{ mediaItem.type === 'vod' ? 'Video is transcoding' : 'Audio is packaging' }}
                </h3>
                <h1
                  v-if="mediaItem.status === error"
                >
                  Transcoding failed
                </h1>
              </div>
              <div
                v-show="isPosterMode"
                class="poster-image"
              >
                <PosterImage
                  ref="posterImage"
                  :poster="getPosterUrl(mediaItem, 1920)"
                  :onPosterChange="handlePosterChange"
                  :posterName="posterName"
                  :handleError="handlePosterError"
                  :disabled="mediaItem.locked"
                  :isPosterInspector="true"
                />
              </div>
            </div>
            <div class="left-icons">
              <div :title="moreDetails">
                <inline-svg
                  :src="iconMetaItem"
                  class="svg-icon icon-meta"
                  @click="openMetaInformation"
                >
                </inline-svg>
              </div>
              <div
                v-if="!isRead"
                :title="!mediaItem.locked ? lock : unlock"
              >
                <inline-svg
                  :src="!mediaItem.locked ? iconUnlocked : iconLocked"
                  class="svg-icon icon-close"
                  @click="lockedMedia"
                >
                </inline-svg>
              </div>
              <div v-if="!isRead">
                <div
                  v-if="mediaItem.approved"
                  title="Disapprove Media"
                >
                  <inline-svg
                    :src="approve"
                    :class="['svg-icon', mediaItem.locked ? 'disables-icon' : '']"
                    @click="onApprove(false)"
                  >
                  </inline-svg>
                </div>
                <div
                  v-else
                  title="Approve Media"
                >
                  <inline-svg
                    :src="nonApprove"
                    :class="['svg-icon', mediaItem.locked ? 'disables-icon' : '']"
                    @click="onApprove(true)"
                  >
                  </inline-svg>
                </div>
              </div>
              <div
                v-if="!isRead"
                :title="!isPosterMode ? posterMode : playerMode"
              >
                <inline-svg
                  :src="!isPosterMode ? iconPosterMode : iconPlayerMode"
                  class="svg-icon icon-mode"
                  @click="changeModeToPoster"
                >
                </inline-svg>
              </div>
              <div
                v-if="!isRevert"
                title="Revert"
              >
                <inline-svg
                  :src="iconRevert"
                  class="svg-icon icon-mode"
                  @click="revertMedia"
                >
                </inline-svg>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs-wrapper">
          <Tabs
            :onSelectTab="handleSelectTab"
            :smallStyle="true"
          >
            <Tab
              :id="'general'"
              name="GENERAL"
              :selected="true"
              :className="[tabErrors.general || hasCustomFieldErrors(getSortedFields(), errors)
                ? 'error-tab'
                : 'tab']"
            >
              <BasicMediaInfo
                ref="basicInfo"
                :mediaItem="mediaItem"
                :onLanguageChange="onLanguageChange"
                :languageError="languageError"
                :formPrefix="formPrefix"
              />
              <div
                v-if="hasSortedFields()"
                class="custom-field-wrapper"
              >
                <div class="custom-field-title">
                  Custom fields
                </div>
                <FieldValueRow
                  v-for="item in getSortedFields()"
                  :key="`fieldValue${item.field.id}`"
                  v-model="item.fieldValue"
                  :field="item.field"
                  :disabled="mediaItem.locked"
                  :fieldName="getFieldName(item.field)"
                  :formPrefix="formPrefix"
                  :inputClassName="'inspector-input'"
                  :selectClassName="'inspector-select'"
                  :calendarClassName="'inspector-calendar'"
                  :datetimeClassName="'inspector-datetime'"
                  :checkboxClassName="'inspector-checkbox'"
                />
              </div>
            </Tab>
            <Tab
              name="TAGS"
              :selected="false"
            >
              <div class="tags">
                <MediaTagManagement
                  :selectedValue="mediaItem.workflowMediaTags"
                  :label="'Workflow Tags'"
                  :tagPlaceholder="''"
                  :searchable="true"
                  :onChange="onTagManagementChange"
                  :disabled="mediaItem.locked || isRead"
                />
              </div>
              <div class="tags search-tags-wrap">
                <MediaTagManagement
                  :selectedValue="mediaItem.searchMediaTags"
                  :label="'Search Tags'"
                  :tagPlaceholder="''"
                  :searchable="true"
                  :onChange="onSearchTagsChange"
                  :isSearch="true"
                  :disabled="mediaItem.locked || isRead"
                />
              </div>
            </Tab>
            <Tab
              :id="'roles'"
              name="PEOPLE AND ROLES"
              :selected="false"
              :className="[tabErrors.roles ? 'error-tab' : 'tab']"
            >
              <PeopleAndRoles
                :key="mediaItem.id
                  + (mediaItem.mediaItemContributors ? mediaItem.mediaItemContributors.length : 0)"
                :onRemoveItem="onRemoveItem"
                :items="mediaItem.mediaItemContributors"
                :onChange="onMediaItemMembersChange"
                :onChangeRole="onChangeRole"
                :onChangePerson="onChangePerson"
                :disabled="mediaItem.locked"
                :formPrefix="formPrefix"
              />
            </Tab>
            <Tab
              v-if="!isRead && mediaItem.type === 'livestream'"
              name="CREDENTIALS"
              :selected="false"
            >
              <LiveStreamCredentials :credentials="mediaItem.credentials" />
            </Tab>
            <Tab
              :id="'availability'"
              name="AVAILABILITY"
              :selected="false"
            >
              <Availability
                :availabilityExpiration="availabilityExpiration"
                :mediaItem="mediaItem"
                :isInspector="true"
              />
            </Tab>
          </Tabs>
        </div>
        <DragAndDropMediaUploaderModal
          v-if="isModalReplaceOpen"
          :getMediaList="getMediaList"
          :isReplace="true"
          :mediaItemId="mediaItem.id"
          :onClose="closeReplace"
          :onFileUpload="onFileReplace"
        />
        <NotificationModal
          ref="modal"
          v-model="isModalOpen"
          :modal="isModalOpen"
          :title="!isLocked ? 'Delete Media' : (mediaItem.locked ? 'Unlock Media' : 'Lock Media')"
          :bodyMessage="bodyMessage"
          :bodyHelfullMessage="!isLocked && isCombiningDeletion ? bodyHelfullMessage : ''"
          :onAccept="isLocked ? lockedMediaRequest : removeMedia"
          :futureStandaloneEvents="isLocked
            ? null
            : Object.entries(mediaItem.futureStandaloneEvents)"
          :fallbackMedia="isLocked ? null : fallbackMedia"
          :notRecurringMediaItem="isLocked ? null : mediaItem.notRecurringMediaItemSchedule"
          :recurringMediaItem="isLocked ? null : mediaItem.recurringMediaItemSchedule"
          :isCombiningDeletion="isCombiningDeletion"
          :onClose="closeModal"
        />
        <NotificationModal
          ref="eventWarningModal"
          v-model="isShowWarningModal"
          :modal="isShowWarningModal"
          :bodyMessage="bodyMessage"
          :title="'Please Confirm'"
          :cancelButtonTitle="'Close'"
          :confirmButtonTitle="'Confirm'"
          :onAccept="onShowEventModal"
          :onClose="closeModal"
        />
        <NotificationReplaceModal
          ref="modalReplace"
          :bodyMessage="bodyMessage"
          :onAccept="removeMedia"
          :changeReplaceHandler="changeReplaceHandler"
          :getMediaList="getMediaList"
        />
        <WidgetModal
          v-if="showWidgetModal"
          :modalData="widgetModalData"
          :isMediaWidget="true"
          :legacyId="mediaItem.legacyIdEncoded"
          :mediaItemId="mediaItem.id"
          :onClose="onCloseWidgetModal"
          :dash="getManifest('dash')"
          :hls="getManifest('hls')"
          :getMediaList="getMediaList"
          :openInspector="openInspector"
        />
        <AddEventModal
          v-if="isShowEventModal"
          :mediaItemId="mediaItem.id"
          :isVodMedia="mediaItem.type === 'vod'"
          :onClose="onCloseEventModal"
          :handleCloseInspector="handleCloseInspector"
          :openInspector="openInspector"
        />
        <NotificationModal
          ref="approveMedia"
          v-model="isApprove"
          :modal="isApprove"
          :bodyMessage="message"
          :title="!approveValue ? 'Disapprove Media' : 'Approve Media'"
          :cancelButtonTitle="'No'"
          :confirmButtonTitle="'Yes'"
          :onAccept="onApprovedMedia"
          :onClose="closeApproveModal"
        />
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import { ValidationObserver } from 'vee-validate';
import moment from 'moment';
import CONFIG from '../constants/config';
import iconClose from '../assets/icons/icon-close-inspector.svg';
import iconUnlocked from '../assets/icons/icon-media-unlocked.svg';
import iconLocked from '../assets/icons/icon-media-locked.svg';
import iconPosterMode from '../assets/icons/icon-poster-mode.svg';
import iconPlayerMode from '../assets/icons/icon-player-mode.svg';
import iconMetaItem from '../assets/icons/icon-meta-item.svg';
import {
  EDIT_MEDIA_ITEM_REQUEST,
  LOCKED_MEDIA_REQUEST,
  REMOVE_MEDIA_ITEM_REQUEST,
  APPROVE_MEDIA_ITEM,
} from '../store/actions/media/mediaItem';
import { SET_EDIT_FORM } from '../store/actions/common';
import {
  FILE_APPEND_REPLACE_REQUEST,
  GENERATE_UPLOAD_URL_REPLACE_REQUEST,
  REVERT_PLACE_REQUEST,
  SEND_TRANSCODE_REPLACE_REQUEST,
} from '../store/actions/replaceActions/replace';
import { GET_WIDGET } from '../store/actions/widgets/widget';
import { GET_SCHEDULE } from '../store/actions/schedule/schedule';
import posterSelector from '../utils/poster/selector';

import BitmovinPlayer from './common/BitmovinPlayer.vue';
import MediaTagManagement from './media/MediaTagManagement.vue';
import NotificationModal from './NotificationModal.vue';
import BaseForm from './forms/BaseForm.vue';
import NotificationReplaceModal from './NotificationReplaceModal.vue';
import CustomFieldFormMixin from './forms/customFields/CustomFieldFormMixin.vue';
import AddEventModal from './events/AddEventModal.vue';

import Tabs from './common/Tabs/Tabs.vue';
import Tab from './common/Tabs/Tab.vue';
import Button from './common/Button.vue';
import BasicMediaInfo from './BasicMediaInfo.vue';
import PosterImage from './common/PosterImage/DragAndDropFileUploader.vue';
import LiveStreamCredentials from './liveStream/LiveStreamCredentials.vue';
import WidgetModal from './common/WidgetModal.vue';
import approve from '../assets/icons/approve.svg';
import nonApprove from '../assets/icons/non-approve.svg';
import iconRevert from '../assets/icons/icon-revert.svg';
import PeopleAndRoles from './media/inspector/PeopleAndRoles.vue';
import Availability from './media/inspector/Availability.vue';
import DragAndDropMediaUploaderModal from './common/DragAndDropMediaUploaderModal.vue';
import {
  SET_UPLOADING_FILES,
} from '../store/actions/UploadingActions/upload';

export default {
  name: 'Inspector',
  components: {
    Availability,
    WidgetModal,
    BasicMediaInfo,
    BitmovinPlayer,
    NotificationModal,
    MediaTagManagement,
    Tabs,
    Tab,
    Button,
    ValidationObserver,
    PosterImage,
    NotificationReplaceModal,
    LiveStreamCredentials,
    PeopleAndRoles,
    AddEventModal,
    DragAndDropMediaUploaderModal,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    mediaItem: {
      type: Object,
      default: null,
    },
    formPrefix: {
      type: String,
      default: 'inspector',
    },
    mediaList: {
      type: Array,
      default: null,
    },
    getMediaList: {
      type: Function,
      default: () => {},
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    closeInspector: {
      type: Function,
      default: () => {},
    },
    openInspector: {
      type: Function,
      default: () => {},
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    const {
      transcoding,
      error,
      uploading,
    } = CONFIG.transcoding;
    return {
      posterSelector,
      submitMessage: CONFIG.successMessages.editMediaMessage,
      posterMode: CONFIG.tooltips.posterMode,
      playerMode: CONFIG.tooltips.playerMode,
      moreDetails: CONFIG.tooltips.moreDetails,
      lock: CONFIG.tooltips.lock,
      unlock: CONFIG.tooltips.unlock,
      isPosterMode: false,
      iconPosterMode,
      iconPlayerMode,
      approve,
      nonApprove,
      iconClose,
      iconLocked,
      iconUnlocked,
      iconMetaItem,
      iconRevert,
      isLocked: false,
      lockedError: CONFIG.errorMessages.lockedError,
      replace: false,
      isModalOpen: false,
      isModalReplaceOpen: false,
      showWidgetModal: false,
      isShowEventModal: false,
      isShowWarningModal: false,
      widgetModalData: {},
      mediaId: null,
      transcoding,
      error,
      uploading,
      submitEvent: EDIT_MEDIA_ITEM_REQUEST,
      selectedTags: [],
      selectedSearchTags: [],
      selectedLanguage: [],
      mediaItemPosters: '',
      posterError: false,
      isPosterChanged: false,
      message: '',
      isApprove: false,
      approveValue: false,
      mediaItemContributors: [],
      availabilityExpiration: {
        avDate: '',
        avTime: '',
        expDate: '',
        expTime: '',
      },
      errorMapping: {
        title: [
          `${this.formPrefix}.name`,
        ],
        description: [
          `${this.formPrefix}.description`,
        ],
        contentType: [
          `${this.formPrefix}.contentType`,
        ],
        mediaItemContributors: [
          `${this.formPrefix}.mediaItemContributors.*`,
        ],
        order: [
          `${this.formPrefix}.episodeNumber`,
        ],
        legacyId: [
          `${this.formPrefix}.legacyId`,
        ],
        avDate: [
          `${this.formPrefix}.availableFrom`,
        ],
        expDate: [
          `${this.formPrefix}.expirationAt`,
        ],
      },
      tabErrors: {
        posterImage: false,
        general: false,
        roles: false,
      },
      languageError: '',
      isMetaInformation: false,
      fallbackMedia: null,
      notRecurringMediaItem: null,
      recurringMediaItem: null,
      bodyHelfullMessage: 'Would you like to remove its future schedules and delete the media?',
    };
  },
  computed: {
    ...mapState({
      player: (state) => state.mediaItem.player,
      isLoading: (state) => state.upload.isLoading,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getMainProfile',
      'getAppConfig',
      'isRead',
    ]),
    errorLanguages() {
      const key = 'languages';
      if (this.itemsErrors[key]
        && this.itemsErrors[key].length
      ) {
        return this.itemsErrors[key];
      }

      return null;
    },
    randomNumber() {
      return Math.floor(Math.random());
    },
    isReplace() {
      return !this.mediaItem.replaceAvailable;
    },
    isRevert() {
      return !this.mediaItem.revertAvailable;
    },
    isReplaceNotAvailable() {
      return !this.mediaItem.replaceAvailable && this.isVodMedia;
    },
    isVodMedia() {
      return this.mediaItem.type === 'vod';
    },
    isAudio() {
      return this.mediaItem.type === 'audio';
    },
    bodyMessage() {
      const {
        replaceMessage,
        removeMediaMessage,
        lockedMediaMessage,
        unLockedMediaMessage,
        eventWarningMessage,
      } = CONFIG.modals;

      if (this.replace) {
        return replaceMessage;
      }

      if (this.isLocked && this.mediaItem.locked) {
        return unLockedMediaMessage;
      }

      if (this.isLocked && !this.mediaItem.locked) {
        return lockedMediaMessage;
      }

      if (this.isShowWarningModal) {
        return eventWarningMessage;
      }

      if (this.isCombiningDeletion) {
        return 'This media is used in the following playback schedules:';
      }

      return removeMediaMessage;
    },
    posterName() {
      const url = this.getPosterUrl(this.mediaItem);
      return url.substring(url.lastIndexOf('/') + 1);
    },
    isAddToEventDisabled() {
      return this.isVodMedia && (this.mediaItem.status !== 'completed');
    },
    isCombiningDeletion() {
      return !!(this.fallbackMedia
        || this.mediaItem.notRecurringMediaItemSchedule.length
        || this.mediaItem.recurringMediaItemSchedule.length
        || (this.mediaItem.futureStandaloneEvents && this.mediaItem.futureStandaloneEvents.length));
    },
  },
  watch: {
    mediaItem(val, prev) {
      if (val.id !== prev.id) {
        this.tabErrors.general = false;
        this.tabErrors.posterImage = false;
        this.tabErrors.roles = false;
        this.isPosterMode = false;
        this.setCustomFields(this.mediaItem.customFields);
      }
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  created() {
    const { workflowMediaTags } = this.mediaItem;
    if (workflowMediaTags.length) {
      this.selectedTab = workflowMediaTags.map((item) => (item.id));
    }

    const { searchMediaTags } = this.mediaItem;
    if (searchMediaTags.length) {
      this.selectedTab = searchMediaTags.map((item) => (item.id));
    }

    this.setCustomFields(this.mediaItem.customFields);

    if (this.mediaItem.availableFrom) {
      this.availabilityExpiration.avTime = moment.utc(this.mediaItem.availableFrom).tz(this.getTimezone()).format('hh:mm A');
      this.availabilityExpiration.avDate = moment.utc(this.mediaItem.availableFrom).tz(this.getTimezone()).format('YYYY-MM-DD');
    } else {
      this.availabilityExpiration.avTime = null;
      this.availabilityExpiration.avDate = null;
    }

    if (this.mediaItem.expirationAt) {
      this.availabilityExpiration.expTime = moment.utc(this.mediaItem.expirationAt).tz(this.getTimezone()).format('hh:mm A');
      this.availabilityExpiration.expDate = moment.utc(this.mediaItem.expirationAt).tz(this.getTimezone()).format('YYYY-MM-DD');
    } else {
      this.availabilityExpiration.expTime = null;
      this.availabilityExpiration.expDate = null;
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
    if (sessionStorage.getItem('lastMedia')) {
      sessionStorage.removeItem('lastMedia');
    }
  },
  methods: {
    onFileReplace(files) {
      const chunkSize = Number(this.getAppConfig.mediaItem.uploading.maxPartSize);
      const {
        errorTypeFormat,
        invalidFileFormat,
        failedUpload,
        errorTypeSize,
        failedSizeUpload,
      } = CONFIG.errorMessages.upload;
      this.$toasted.global.success({
        message: this.$tc(
          CONFIG.successMessages.filesPreparedForUpload,
          files.length,
          { count: files.length },
        ),
      });

      this.$store.dispatch(SET_UPLOADING_FILES, files);

      // eslint-disable-next-line no-restricted-syntax
      Array.from(files).forEach((file) => {
        this.isLoading = true;

        const params = {
          accountId: this.getCurrentAccountId,
          itemId: this.mediaItem.id,
          file,
        };
        this.$store.dispatch(GENERATE_UPLOAD_URL_REPLACE_REQUEST, params)
          .then(async (res) => {
            let chunkCounter = 0;
            let loaded = 0;

            const appendFile = (params) => {
              const {
                invalidTranscode,
              } = CONFIG.errorMessages.upload;
              return this.$store.dispatch(FILE_APPEND_REPLACE_REQUEST, params)
                .then((data) => {
                  if (data.totalSize <= chunkSize ? !!data.id : data.id && data.isLastChunk) {
                    const params = {
                      accountId: this.getCurrentAccountId,
                      itemId: data.id,
                    };
                    this.$store.dispatch(SEND_TRANSCODE_REPLACE_REQUEST, params)
                      .then(() => {
                        this.getMediaList(localStorage.getItem('currentPage-media-list') || 1);
                      })
                      .catch(() => {
                        this.$toasted.global.error({
                          message: invalidTranscode,
                        });
                      });
                  }
                });
            };

            for (let i = 0; i < res.uploadUrl.length; i += 1) {
              chunkCounter += 1;
              const numberofChunks = Math.ceil(res.file.size / chunkSize);
              const start = 0;
              const chunkEnd = Math.min(start + chunkSize, res.file.size);
              let chunk = res.file.slice(start, chunkEnd);

              if (i !== 0) {
                loaded += chunkSize;
              }

              if (loaded <= res.file.size) {
                chunk = file.slice(loaded, loaded + chunkSize);
              } else {
                loaded = res.file.size;
              }

              const params = {
                file: res.file.size <= chunkSize ? res.file : chunk,
                id: res.id,
                uploadUrl: res.uploadUrl[i],
                numberofChunks,
                chunkCounter,
                totalSize: res.file.size,
              };

              if (res.file.size > chunkSize && (i + 1 === res.uploadUrl.length)) {
                params.isLastChunk = true;
              }

              // eslint-disable-next-line no-await-in-loop
              await appendFile(params);
            }
          })
          .catch((err) => {
            if (err.data && err.data.error === this.lockedError) {
              this.$toasted.global.error({
                message: CONFIG.errorMessages.lockedMessage,
              });
              this.$refs.replace.value = '';
              return;
            }

            if (err === errorTypeFormat) {
              this.$toasted.global.error({
                message: invalidFileFormat,
              });
            } else if (err.status === CONFIG.statuses.badRequest) {
              this.$toasted.global.error({
                message: failedUpload,
              });
            } else if (err === errorTypeSize) {
              this.$toasted.global.error({
                message: failedSizeUpload,
              });
            }
          });
      });
    },
    changeModeToPoster() {
      this.isPosterMode = !this.isPosterMode;
      if (this.player) {
        this.player.pause();
      }
    },
    openMetaInformation() {
      this.$router.push(`${this.$route.path}/more/${this.mediaItem.id}`);
      sessionStorage.setItem('lastMedia', this.mediaItem.id);
    },
    onCloseMetaInformation() {
      this.isMetaInformation = false;
    },
    onMediaItemMembersChange(val) {
      this.mediaItem.mediaItemContributors.push(val);
    },
    onRemoveItem(index) {
      this.mediaItem.mediaItemContributors.splice(index, 1);
    },
    onChangePerson(val, index) {
      this.mediaItem.mediaItemContributors[index].contributorPerson = { id: val };
    },
    onChangeRole(val, index) {
      this.mediaItem.mediaItemContributors[index].contributorRole = { id: val };
    },
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    handleFormChange() {
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    handleSelectTab(id) {
      if (id === 'posterImage') {
        this.tabErrors.posterImage = false;
      }

      if (id === 'general') {
        this.tabErrors.general = false;
      }

      if (id === 'roles') {
        this.tabErrors.roles = false;
      }
    },
    replaceMedia() {
      this.isModalReplaceOpen = false;
    },
    revertMedia() {
      const params = {
        accountId: this.getCurrentAccountId,
        itemId: this.mediaItem.id,
      };
      this.$store.dispatch(REVERT_PLACE_REQUEST, params)
        .then(() => {
          this.getMediaList(1);
        })
        .catch((error) => {
          this.getMediaList(1);
          if (error.data.error === this.lockedError) {
            this.$toasted.global.error({
              message: CONFIG.errorMessages.lockedMessage,
            });
          }
        });
    },
    handleCloseInspector() {
      this.closeInspector();
    },
    handlePosterChange(img) {
      this.isPosterChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.mediaItemPosters = img;
      if (img) {
        setTimeout(() => {
          this.isPosterMode = false;
        }, 600);
      }
    },
    onOpenModal(id) {
      this.isModalOpen = true;
      this.mediaId = id;
    },
    closeModal() {
      this.isShowWarningModal = false;
      this.isModalOpen = false;
      this.isLocked = false;
    },
    closeReplace() {
      this.replace = false;
      this.isModalReplaceOpen = false;
    },
    removeMedia() {
      const params = {
        accountId: this.getCurrentAccountId,
        itemId: this.mediaId,
      };
      this.$store.dispatch(REMOVE_MEDIA_ITEM_REQUEST, params)
        .then(() => {
          let page = localStorage.getItem('currentPage-media-list') || 1;
          if (this.mediaList.length === 1 && localStorage.getItem('currentPage-media-list') !== '1') {
            page = Number(localStorage.getItem('currentPage-media-list')) - 1;
          }
          this.$store.dispatch(SET_EDIT_FORM, false);
          this.$toasted.global.success({
            message: CONFIG.successMessages.removedMediaMessage,
          });
          this.handleCloseInspector();
          this.getMediaList(page);
        })
        .catch((error) => {
          const {
            lockedMessage,
            removeFallbackNotAvailable,
          } = CONFIG.errorMessages;
          if (error.error === this.lockedError) {
            this.$toasted.global.error({
              message: lockedMessage,
            });
          }

          if (error.payload && error.payload.accountSchedule) {
            this.$toasted.global.error({
              message: removeFallbackNotAvailable,
            });
          }
        });
    },
    onOpenModalHandle() {
      this.fallbackMedia = null;
      this.onOpenModal(this.mediaItem.id);
      const params = {
        scheduleId: this.mediaItem.accountSchedule.length && this.mediaItem.accountSchedule[0].id,
        accountId: this.getCurrentAccountId,
      };

      if (params.scheduleId) {
        this.$store.dispatch(GET_SCHEDULE, params)
          .then((response) => {
            this.fallbackMedia = response.fallbackMediaItem.id === this.mediaItem.id
              ? response.fallbackMediaItem
              : null;
          });
      }
    },
    onOpenModalHandleReplace() {
      this.replace = true;
      this.isModalReplaceOpen = true;
    },
    onShowWidgetHandle() {
      if (this.mediaItem.widget) {
        this.openWidgetModal(this.mediaItem.widget);
      } else {
        this.generateWidget({
          accountId: this.getCurrentAccountId,
          data: {
            mediaItem: this.mediaItem.id,
          },
        }, this.mediaItem);
      }
    },
    generateWidget(data, item) {
      this.$store.dispatch(GET_WIDGET, data)
        .then((res) => {
          item.widget = res;
          this.openWidgetModal(res);
        });
    },
    openWidgetModal(data) {
      this.widgetModalData = data;
      this.showWidgetModal = true;
    },
    onCloseWidgetModal() {
      this.showWidgetModal = false;
      this.widgetModalData = {};
    },
    changeReplaceHandler() {
      this.replace = false;
    },
    editMedia() {
      this.$refs[this.validationObserver].validate()
        .then(() => {
          this.tabErrors.posterImage = this.$refs.posterImage.isValid();
          this.submit();
        });
    },
    submit() {
      this.tabErrors.general = false;
      this.tabErrors.posterImage = false;
      this.tabErrors.roles = false;

      this.$store.dispatch(this.submitEvent, this.getResult())
        .then((response) => this.onSubmitSuccess(response))
        .catch((error) => this.onSubmitFail(error));
    },
    onSubmitFail(error) {
      if (error.error === this.lockedError) {
        this.$toasted.global.error({
          message: CONFIG.errorMessages.lockedMessage,
        });
        return;
      }

      const generalErrors = error.form_errors ? error.form_errors.children : null;
      if ((generalErrors && generalErrors.name) || (generalErrors && generalErrors.contentType)
      || (generalErrors && generalErrors.airDate) || (generalErrors && generalErrors.languages)) {
        this.tabErrors.general = true;
      }

      if (generalErrors && generalErrors.languages) {
        this.languageError = 'The field is required';
      }

      const roleErrors = error.form_errors ? error.form_errors.children : null;
      if (roleErrors
        && roleErrors.mediaItemContributors
        && roleErrors.mediaItemContributors.children) {
        this.tabErrors.roles = true;
      }

      const formErrors = this.getServerFormErrors(error);
      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    onSubmitSuccess() {
      this.isPosterChanged = false;
      this.getMediaList(this.page);
      this.$toasted.global.success({
        message: this.submitMessage,
      });
    },
    handlePosterError(value) {
      this.posterError = value;
    },
    getRequestData() {
      this.$store.dispatch(SET_EDIT_FORM, false);

      const params = {};
      params.recordSchedule = this.mediaItem.recordSchedule;
      params.workflowMediaTags = this.selectedTags;
      params.searchTags = this.selectedSearchTags;
      params.selectedLanguage = this.selectedLanguage;
      params.mediaItem = this.mediaItem;
      params.mediaItemContributors = this.mediaItem.mediaItemContributors.map((item) => ({
        contributorRole: item.contributorRole.id,
        contributorPerson: item.contributorPerson.id,
      }));
      params.fields = this.getFieldsData();
      params.legacyId = this.mediaItem.legacyId;

      if (moment(this.mediaItem.airDate).isValid()) {
        params.airDate = this.mediaItem.airDate;
      } else {
        params.airDate = null;
      }

      if (this.isPosterChanged) {
        params.posterImage = this.mediaItemPosters;
      }

      if (!this.mediaItemPosters && this.mediaItem.type === 'vod') {
        delete params.posterImage;
      }

      if (this.availabilityExpiration.avDate) {
        params.availableFrom = this.getDateTimeAv();
      }

      if (this.availabilityExpiration.expDate) {
        params.expirationAt = this.getDateTimeExp();
      }

      return {
        accountId: this.getCurrentAccountId,
        itemId: params.mediaItem.id,
        params,
      };
    },
    onTagManagementChange(value) {
      this.selectedTags = value;
    },
    onSearchTagsChange(value) {
      this.selectedSearchTags = value;
    },
    onLanguageChange(value) {
      this.selectedLanguage = value;
      this.languageError = '';
    },
    onApprove(value) {
      if (!this.mediaItem.locked) {
        this.isApprove = true;
        this.message = !value
          ? 'Are you sure you want to disapprove this Media Item?'
          : 'Are you sure you want to approve this Media Item?';
        this.approveValue = value;
      }
    },
    closeApproveModal() {
      this.isApprove = false;
    },
    onApprovedMedia() {
      const params = {
        accountId: this.getCurrentAccountId,
        itemId: this.mediaItem.id,
        data: {
          approved: this.approveValue,
        },
      };
      this.$store.dispatch(APPROVE_MEDIA_ITEM, params).then(() => {
        this.$toasted.global.success({
          message: `Media item has been ${this.approveValue ? 'approved' : 'disapproved'}`,
        });
      });
    },
    lockedMedia() {
      this.fallbackMedia = null;
      this.isLocked = true;
      this.isModalOpen = true;
    },
    lockedMediaRequest() {
      const params = {
        accountId: this.getCurrentAccountId,
        itemId: this.mediaItem.id,
        locked: !this.mediaItem.locked,
      };
      this.$store.dispatch(LOCKED_MEDIA_REQUEST, params)
        .then(() => {
          let lockedStatus;
          if (this.mediaItem.locked) {
            lockedStatus = 'locked';
          } else {
            lockedStatus = 'unlocked';
          }

          this.$toasted.global.success({
            message: `The Media has been successfully ${lockedStatus}`,
          });
        });
    },
    getPosterUrl(item) {
      return item.posterImage ? `${item.posterImage}?height=720` : '';
    },
    getManifest(type) {
      if (this.mediaItem && this.mediaItem.playerSource && this.mediaItem.playerSource[type]) {
        return this.mediaItem.playerSource[type];
      }
      return '';
    },
    onShowEventHandle() {
      this.isShowEventModal = true;
    },
    onShowEventModal() {
      this.isShowWarningModal = false;
      this.isShowEventModal = true;
    },
    onCloseEventModal() {
      this.isShowEventModal = false;
    },
    getDateTimeAv() {
      let avDate = '';

      if (this.availabilityExpiration.avDate) {
        avDate += moment(this.availabilityExpiration.avDate).format('YYYY-MM-DD');
        avDate += this.parseTimeForBE();
      }
      return moment.tz(avDate, this.getTimezone()).utc();
    },
    getDateTimeExp() {
      let expDate = '';

      if (this.availabilityExpiration.expDate) {
        expDate += moment(this.availabilityExpiration.expDate).format('YYYY-MM-DD');
        expDate += this.parseTimeForBEExp();
      }
      return moment.tz(expDate, this.getTimezone()).utc();
    },
    getTimezone() {
      return (this.getMainProfile.timezone) ? this.getMainProfile.timezone : moment.tz.guess();
    },
    parseTimeForBE() {
      return this.availabilityExpiration.avTime
        ? `T${this.availabilityExpiration.avTime.HH}:${this.availabilityExpiration.avTime.mm}:00`
        : 'T12:00:00';
    },
    parseTimeForBEExp() {
      return this.availabilityExpiration.expTime
        ? `T${this.availabilityExpiration.expTime.HH}:${this.availabilityExpiration.expTime.mm}:00`
        : 'T12:00:00';
    },
  },
};
</script>

<style scoped>
.wrapper {
  background: #383b40;
  height: calc(100% - 30px);
  overflow-y: auto;
  padding: 15px;
  position: absolute;
  top: 0;
}

.wrapper-inside {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px;
}

.player {
  height: auto;
  margin-left: 5px;
  width: 528px;
}

.svg-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
}

.transcoding {
  align-items: center;
  background-color: #000;
  display: flex;
  height: 225px;
  justify-content: center;
}

.right-col {
  display: flex;
  flex: 0.9;
  flex-direction: column;
  justify-content: flex-start;
}

.right-col-header {
  display: flex;
  justify-content: space-between;
}

.right-col-body {
  margin-top: 50px;
}

.flex {
  display: flex;
}

.btn-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.btn-row-right,
.btn-row-left {
  display: flex;
}

.close-btn {
  display: flex;
  align-items: center;
  margin-left: 17px;
  padding-right: 3px;
}

.tabs-wrapper {
  font-size: 14px;
  margin-top: 10px;
  padding-left: 5px;
  width: 520px;
}

.poster-image {
  margin-left: 5px;
}

.tags {
  margin: -20px 0 0 5px;
}

.player-area {
  display: flex;
}

.left-icons {
  margin-left: 9px;
  min-width: 35px;
  margin-right: 2px;
}

.icon-close {
  margin-bottom: 25px;
  margin-top: 10px;
}

.icon-meta {
  margin-bottom: 15px;
}

.disables-icon {
  cursor: not-allowed;
}

.icon-mode {
  margin-top: 25px;
}

.custom-field-wrapper {
  margin-top: 20px;
}

.custom-field-title {
  font-size: 16px;
  margin-bottom: 20px;
}

.custom-field-wrapper >>> input.display-time {
  background-color: #222;
}

.picker-date .vue__time-picker input.display-time {
  background: crimson;
}

.search-tags-wrap {
  padding-top: 20px;
}
</style>
