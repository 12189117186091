<!-- eslint-disable vue/valid-v-model -->
<template>
  <div
    class="overlay"
  >
    <div
      class="wrapper"
    >
      <div class="body-text">
        You have the following conflicts:
        <div
          v-for="item in getErrors()"
          :key="item.start"
          class="link"
          @click="onItemClick(item)"
        >
          <a>{{ getDate(item) }}</a>
        </div>
      </div>
      <hr />
      <div class="button-wrapper">
        <Button
          :title="'Cancel'"
          :classType="'primary grey'"
          :eventFunction="closeModal"
        />
        <Button
          :title="'Save'"
          :classType="'primary'"
          :eventFunction="acceptHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import Button from '../common/Button.vue';

export default {
  name: 'ConflictError',
  components: {
    Button,
  },
  props: {
    onAccept: {
      type: Function,
      default: () => {},
      required: false,
    },
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
    error: {
      type: Array,
      default: () => [],
    },
    date: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState({
      profile: (state) => state.common.profile,
    }),
  },
  methods: {
    getTimezone() {
      let tz = moment.tz.guess();
      this.tz = tz;
      if (this.profile.timezone) {
        tz = this.profile.timezone;
        this.tz = null;
      }
      return tz;
    },
    getErrors() {
      const tz = this.getTimezone();
      const errors = [];

      this.error.forEach((item) => {
        const start = moment(item.start).tz(tz);
        const finish = moment(item.finish).tz(tz);
        const itemStart = moment(start.format('YYYY-MM-DD'));
        const itemFinish = moment(finish.format('YYYY-MM-DD'));

        if (itemFinish > itemStart) {
          const intersectionStart = moment(item.intersection.start).tz(tz);
          const intersectionFinish = moment(item.intersection.finish).tz(tz);

          if (
            this.date !== start.format('YYYY-MM-DD')
            && (start.format('YYYY-MM-DD') === intersectionStart.format('YYYY-MM-DD')
            || start.format('YYYY-MM-DD') === intersectionFinish.format('YYYY-MM-DD'))
          ) {
            errors.push({
              start: moment(`${start.format('YYYY-MM-DD')}T${start.format('HH:mm:ss')}`)
                .tz(tz)
                .format('YYYY-MM-DD HH:mm:ss'),
              finish: moment(`${start.format('YYYY-MM-DD')}T${finish.format('HH:mm:ss')}`)
                .tz(tz)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
            });
          }
          if (
            this.date !== finish.format('YYYY-MM-DD')
            && (finish.format('YYYY-MM-DD') === intersectionStart.format('YYYY-MM-DD')
              || finish.format('YYYY-MM-DD') === intersectionFinish.format('YYYY-MM-DD'))
          ) {
            errors.push({
              start: moment(`${finish.format('YYYY-MM-DD')}T${start.format('HH:mm:ss')}`)
                .tz(tz)
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
              finish: moment(`${finish.format('YYYY-MM-DD')}T${finish.format('HH:mm:ss')}`)
                .tz(tz)
                .format('YYYY-MM-DD HH:mm:ss'),
            });
          }
        } else {
          errors.push({
            start: moment(`${start.format('YYYY-MM-DD')}T${start.format('HH:mm:ss')}`)
              .tz(tz)
              .format('YYYY-MM-DD HH:mm:ss'),
            finish: moment(`${finish.format('YYYY-MM-DD')}T${finish.format('HH:mm:ss')}`)
              .tz(tz)
              .format('YYYY-MM-DD HH:mm:ss'),
          });
        }
      });

      return errors;
    },
    getDate(item) {
      const tz = this.getTimezone();
      return moment(item.start).tz(tz).format('YYYY-MM-DD HH:mm:ss');
    },
    onItemClick(item) {
      const tz = this.getTimezone();
      const date = moment(item.start).tz(tz).format('YYYY-MM-DD');
      const startTime = moment(item.start).tz(tz).format('HH:mm:ss');
      const finish = moment(item.finish).tz(tz).format('HH:mm:ss');
      const routeData = this.$router.resolve(`schedule?view=day&date=${date}&startTime=${startTime}&finishTime=${finish}`);
      window.open(routeData.href, '_blank');
    },
    closeModal() {
      this.onClose();
    },
    accept() {
      this.onAccept();
      this.closeModal();
    },
    acceptHandler() {
      this.accept();
    },
  },
};
</script>

<style scoped>
  .wrapper {
    background-color: #383b40;
    border-radius: 4px;
    display: block;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    position: absolute;
    text-decoration: none;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .headline,
  .edit-wrapper {
    color: #fff;
  }

  .edit-wrapper {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375rem;
    padding: 13px 16px;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding: 13px 16px;
    word-break: break-all;
  }

  hr {
    border: solid;
    border-color: rgba(0, 0, 0, 0.12);
    border-width: thin 0 0;
    display: block;
    flex: 1 1 0;
    height: 0;
    max-height: 0;
    max-width: 100%;
    transition: inherit;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }

  .lighten-2 {
    color: #fff;
    font-size: 16px;
  }

  .body-text {
    color: #fff;
    font-size: 16px;
    padding: 40px 55px;
  }

  .link {
    color: #2591cf;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 5px;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }
</style>
