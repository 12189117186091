<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, classes, required, ariaInput, ariaMsg }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    mode="passive"
  >
    <div
      class="group"
      :class="[classes, className]"
    >
      <div
        class="label-block"
        :class="allowSearch ? 'search' : ''"
      >
        <label>
          {{ fieldLabel }}
          <span
            v-if="required"
            class="asterisk"
          >
            *
          </span>
        </label>
        <LiveSearch
          v-if="allowSearch"
          :onSearch="onSearch"
          :maxlength="255"
        />
      </div>
      <div :class="['text-block', disabled && 'disabled']">
        <textarea
          v-model="valueModel"
          :name="fieldName"
          :disabled="disabled"
          :maxlength="maxlength"
          :class="className"
          :cols="cols"
          :required="required"
          :rows="rows"
          v-bind="ariaInput"
        ></textarea>
        <inline-svg
          v-if="allowCopy"
          :src="iconCopy"
          class="svg-icon"
          @click="onCopy"
        >
        </inline-svg>
      </div>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
          v-bind="ariaMsg"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import LiveSearch from '../common/LiveSearch.vue';
import BaseMaterialInput from './BaseMaterialInput.vue';

export default {
  name: 'MaterialTextArea',
  components: {
    LiveSearch,
  },
  extends: BaseMaterialInput,
  props: {
    cols: {
      type: Number,
      default: undefined,
    },
    rows: {
      type: Number,
      default: undefined,
    },
    className: {
      type: String,
      default: '',
    },
    allowSearch: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSearch() {
    },
  },
};
</script>

<style scoped>
  textarea {
    background: #383b40;
    border: 0;
    border-radius: 0;
    height: 80px;
    margin-top: 10px;
    max-height: 400px;
    min-height: 80px;
    padding: 15px;
    resize: vertical;
  }

  .inspector-area {
    background: #222;
    max-height: 150px;
  }

  textarea:focus {
    outline: none;
  }

  textarea.inspector-input {
    background: #222;
  }

  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .group.failed {
    margin-bottom: 10px;
  }

  .group.failed textarea {
    border-bottom: 1px solid #fb3951;
  }

  label {
    font-size: 16px;
    color: #fff;
    font-weight: normal;
  }

  .label-block {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .label-block.search >>> .search-wrapper,
  .label-block.search label {
    width: 50%;
  }

  .label-block.search >>> .search-wrapper {
    margin-right: 0;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .asterisk {
    color: #fb3951;
  }

  .disabled {
    opacity: 0.7;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .widget-area {
    height: 260px;
  }

  .text-block {
    position: relative;
  }

  .text-block .widget-area {
    height: 218px;
    resize: none;
  }

  .svg-icon {
    bottom: 15px;
    cursor: pointer;
    position: absolute;
    right: 10px;
  }
</style>
