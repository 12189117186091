<template>
  <div class="form-container">
    <Loader :isVisible="isLoading" />
    <div class="left-path">
      <span class="system">
        Collections / Playlists
      </span>
      <span>
        / Edit {{ playlist.title }}
      </span>
    </div>
    <div class="title">
      Playlist Editor
    </div>
    <div>
      <ValidationObserver
        ref="validationObserver"
        v-slot="{ handleSubmit }"
      >
        <form
          class="contact-info"
          novalidate="novalidate"
          @submit.prevent="handleSubmit(submit)"
          @input="handleFormChange"
        >
          <div class="edit-button-wrapper">
            <Button
              :title="'Delete'"
              :classType="'transparent'"
              :eventFunction="deletePage"
            />
            <Button
              :title="'Cancel'"
              :classType="'warning'"
              :eventFunction="backToPlaylists"
            />
            <Button
              :title="'Apply'"
              :classType="'primary'"
              :type="'submit'"
            />
          </div>
          <Tabs
            :onSelectTab="handleSelectTab"
          >
            <Tab
              :id="'general'"
              name="General"
              :selected="mode === 'general'"
              :className="[tabErrors.general ? 'error-tab' : 'tab']"
            >
              <div
                v-if="formData.id"
                class="wrapper main-form-container"
              >
                <div class="column column-1">
                  <MetaDataForm
                    :formData="formData"
                    :formFields="formFields"
                    :type="'playlist'"
                    :titleImageError="titleImageError"
                    :isPosterSmallChanged="isPosterSmallChanged"
                    :isPosterLargeChanged="isPosterLargeChanged"
                    :isPosterTitleChanged="isPosterTitleChanged"
                    :isCategoryManagementAvailabe="true"
                    :isGenreManagementAvailabe="true"
                    :handleRemoveTitleImg="handleRemoveTitleImg"
                    :handleRemove="handleRemove"
                    :handleRemovePoster="handleRemovePoster"
                    :handlePosterChange="handlePosterChange"
                    :handleTitleChange="handleTitleChange"
                    :handlePosterBackChange="handlePosterBackChange"
                    :handleCategoryChange="handleCategoryChange"
                    :handleGenreChange="handleGenreChange"
                    :handleRemovePortraitImage="handleRemovePortraitImage"
                    :handleChangePortraitImage="handleChangePortraitImage"
                  />
                </div>
              </div>
            </Tab>
            <Tab
              :id="'dataSource'"
              name="Data Source"
              :selected="mode === 'dataSource'"
              :className="[tabErrors.dataSource ? 'error-tab' : 'tab']"
            >
              <DataSourcePlaylist
                :formData="formData"
                :formFields="formFields"
                :pagination="pagination"
                :mediaItems="mediaItems"
                :getMediaItems="getMediaItems"
                :errorLiveEvents="errorLiveEvents"
                :sortByOptions="sortByOptions"
                :sortOrderOptions="sortOrderOptions"
                :typesOptions="typesOptions"
                :contentTypesOptions="contentTypesOptions"
                :clearFolderError="clearFolderError"
                :foldError="foldError"
              />
            </Tab>
          </Tabs>
        </form>
      </validationobserver>
      <NotificationModal
        ref="modal"
        v-model="isModalOpenDelete"
        :modal="isModalOpenDelete"
        :bodyMessage="bodyMessage"
        :title="'Delete Playlist'"
        :warningMess="warningMess"
        :isInUseAsset="true"
        :onAccept="removePlaylist"
        :onClose="onCloseDelete"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CONFIG from '../../../constants/config';
import {
  SET_EDIT_FORM,
  SET_OVERFLOW,
} from '../../../store/actions/common';

import BaseForm from '../BaseForm.vue';
import Loader from '../../common/Loader.vue';
import Tabs from '../../common/Tabs/Tabs.vue';
import Tab from '../../common/Tabs/Tab.vue';
import Button from '../../common/Button.vue';
import NotificationModal from '../../NotificationModal.vue';
import {
  DELETE_PLAYLIST_REQUEST,
  EDIT_PLAYLIST_REQUEST,
  GET_CONTENT_TYPE_REQUEST,
  GET_DYNAMIC_MEDIA_REQUEST,
  GET_MEDIA_TYPE_REQUEST,
  GET_PLAYLIST_REQUEST,
  GET_SORT_BY_REQUEST,
  GET_SORT_ORDER_REQUEST,
} from '../../../store/actions/playlist/playlist';
import MetaDataForm from '../MetaDataForm.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import DataSourcePlaylist from '../../../views/playlist/DataSourcePlaylist.vue';

export default {
  name: 'PlaylistEditForm',
  components: {
    DataSourcePlaylist,
    MetaDataForm,
    NotificationModal,
    Tab,
    Tabs,
    Loader,
    Button,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'playlistEditForm',
    },
  },
  data() {
    return {
      warningMess: 'Deleting a Playlist will not result in deleting the Source Folder and its Media Items',
      titleImageError: '',
      isPosterSmallChanged: false,
      isPosterLargeChanged: false,
      isPosterTitleChanged: false,
      isPortraitImageChanged: false,
      playlist: {},
      mode: sessionStorage.getItem('lastOpenedPlaylistTab') || 'general',
      error: null,
      enabledLanguages: [],
      isModalOpenDelete: false,
      bodyMessage: CONFIG.modals.deletePlaylistMessage,
      hasFeed: '',
      hasFolder: '',
      foldError: '',
      mediaItems: null,
      errorLiveEvents: [],
      defaultLength: 20,
      tabErrors: {
        general: false,
        dataSource: false,
      },
      formData: {
        id: '',
        title: '',
        folderType: '',
        order: 0,
        shortDescription: '',
        longDescription: '',
        image: '',
        portraitImage: '',
        backgroundImage: '',
        titleImg: '',
        sortBy: 'episodic',
        sortDirection: 'ASC',
        includeMediaTypes: [],
        includeContentTypes: [],
        isSyncWithFolder: true,
        mediaFolder: '',
        dynamicContentLimit: 100,
        customFields: [],
        categories: [],
        genres: [],
      },
      formFields: {
        title: {
          type: 'text',
          name: 'title',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        shortDescription: {
          type: 'text',
          name: 'shortDescription',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        longDescription: {
          type: 'text',
          name: 'longDescription',
          cols: 30,
          rows: 10,
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 1800,
          },
        },
        sortBy: {
          type: 'text',
          name: 'sortBy',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
        sortDirection: {
          type: 'text',
          name: 'sortDirection',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
          },
        },
      },
      errorMapping: {
        title: [
          `${this.formPrefix}.title`,
        ],
        shortDescription: [
          `${this.formPrefix}.shortDescription`,
        ],
        longDescription: [
          `${this.formPrefix}.longDescription`,
        ],
        mediaFolder: [
          `${this.formPrefix}.mediaFolder`,
        ],
      },
      submitEvent: EDIT_PLAYLIST_REQUEST,
      successMessage: CONFIG.successMessages.editPlaylistMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    ...mapState({
      isLoading: (state) => state.playlist.isLoading,
      isEditedForm: (state) => state.common.isEditedForm,
      pagination: (state) => state.feeds.mediaItemsPagination,
      sortByOptions: (state) => state.playlist.sortByOptions,
      sortOrderOptions: (state) => state.playlist.sortOrderOptions.map((item) => ({
        code: item.code,
        name: item.name === 'ASC' ? 'Ascending' : 'Descending',
      })),
      typesOptions: (state) => state.playlist.typesOptions,
      contentTypesOptions: (state) => state.playlist.contentTypesOptions,
    }),
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  mounted() {
    this.$store.dispatch(GET_SORT_BY_REQUEST);
    this.$store.dispatch(GET_SORT_ORDER_REQUEST);
    this.$store.dispatch(GET_MEDIA_TYPE_REQUEST);
    this.$store.dispatch(GET_CONTENT_TYPE_REQUEST);

    const params = {
      accountId: this.getCurrentAccountId,
      playlistId: this.$route.params.id,
    };
    this.$store.dispatch(GET_PLAYLIST_REQUEST, params).then((res) => {
      this.playlist = res;
      this.initFormData(res);
      this.getMediaItems(1);
    });
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
    sessionStorage.removeItem('lastOpenedCMSTab');
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  methods: {
    initFormData(metadata) {
      this.formData.id = metadata.id;
      this.formData.title = metadata.title ? metadata.title : this.folderName;
      this.formData.shortDescription = metadata.shortDescription;
      this.formData.longDescription = metadata.longDescription;
      this.formData.categories = metadata.categories;
      this.formData.genres = metadata.genres;
      this.formData.image = metadata.posterImage ? `${metadata.posterImage}?height=360` : '';
      this.formData.portraitImage = metadata.portraitImage ? `${metadata.portraitImage}?height=360` : '';
      this.formData.titleImg = metadata.titleImage ? `${metadata.titleImage}?height=360` : '';
      this.formData.backgroundImage = metadata.backgroundImage ? `${metadata.backgroundImage}?height=360` : '';
      this.formData.mediaFolder = metadata.mediaFolder ? metadata.mediaFolder.id : null;
      this.formData.sortBy = metadata.sortBy;
      this.formData.sortDirection = metadata.sortDirection;
      this.formData.includeMediaTypes = metadata.includeMediaTypes.map((item) => ({
        id: item,
        name: item,
      }));
      this.formData.includeContentTypes = metadata.includeContentTypes.map((item) => ({
        id: item,
        name: item,
      }));
      this.formData.customFields = metadata.customFields;
      this.setCustomFields(metadata.customFields);
    },
    handleSelectTab(id) {
      if (id === 'dataSource') {
        this.tabErrors.dataSource = false;
      }

      if (id === 'general') {
        this.tabErrors.general = false;
      }
    },
    backToPlaylists() {
      this.$router.push('/collections/playlists');
    },
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
    handleFormChange(value) {
      this.$store.dispatch(SET_EDIT_FORM, value);
      this.error = null;
    },
    deletePage() {
      this.isModalOpenDelete = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    onCloseDelete() {
      this.isModalOpenDelete = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    removePlaylist() {
      const params = {
        accountId: this.getCurrentAccountId,
        playlistId: this.$route.params.id,
      };

      this.$store.dispatch(DELETE_PLAYLIST_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: 'The Playlist has been successfully deleted',
          });
          this.backToPlaylists();
        })
        .catch((err) => {
          if (err.status === 400 && err.error === 'ITEM_HAS_DEPENDENCIES') {
            this.$toasted.global.error({
              message: 'This Playlist is in use by Video Apps Engine and cannot be deleted',
            });
          }
        });
    },
    handleRemoveTitleImg() {
      this.formData.titleImg = '';
      if (this.titleImageError) {
        this.titleImageError = '';
      }
    },
    handleRemove() {
      this.formData.backgroundImage = '';
    },
    handleRemovePoster() {
      this.formData.image = '';
    },
    handleRemovePortraitImage() {
      this.formData.portraitImage = '';
    },
    handleChangePortraitImage(img) {
      this.isPortraitImageChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.portraitImage = img;
    },
    handlePosterChange(img) {
      this.isPosterSmallChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.image = img;
    },
    handleTitleChange(img) {
      this.isPosterTitleChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.titleImg = img;
      if (this.titleImageError) {
        this.titleImageError = '';
      }
    },
    handleCategoryChange(value) {
      this.formData.categories = value;
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    handleGenreChange(value) {
      this.formData.genres = value;
      this.$store.dispatch(SET_EDIT_FORM, true);
    },
    handlePosterBackChange(img) {
      this.isPosterLargeChanged = true;
      this.$store.dispatch(SET_EDIT_FORM, true);
      this.formData.backgroundImage = img;
    },
    clearFolderError() {
      this.foldError = '';
      this.tabErrors.dataSource = false;
    },
    getMediaItems(page) {
      if (!this.formData.mediaFolder) {
        this.mediaItems = [];
        return;
      }

      const allLength = page * this.defaultLength;
      const { dynamicContentLimit } = this.formData;
      let folders = this.formData.mediaFolder;
      let { includeMediaTypes } = this.formData;
      let { includeContentTypes } = this.formData;

      if (this.formData.mediaFolder[0] && this.formData.mediaFolder[0].name) {
        folders = this.formData.mediaFolder.map((item) => item.id);
      }

      if (this.formData.includeMediaTypes[0] && this.formData.includeMediaTypes[0].name) {
        includeMediaTypes = this.formData.includeMediaTypes.map((item) => item.id);
      }

      if (this.formData.includeContentTypes[0] && this.formData.includeContentTypes[0].name) {
        includeContentTypes = this.formData.includeContentTypes.map((item) => item.id);
      }

      const params = {
        allLength: dynamicContentLimit < allLength
          ? dynamicContentLimit : allLength,
        accountId: this.getCurrentAccountId,
        page: 1,
        mediaFolder: folders,
        includeMediaTypes,
        includeContentTypes,
        sortBy: this.formData.sortBy,
        sortDirection: this.formData.sortDirection,
        checkAvailability: 1,
        isHideChildren: true,
      };
      this.$store.dispatch(GET_DYNAMIC_MEDIA_REQUEST, params)
        .then((res) => {
          this.mediaItems = res;
        });
    },
    getRequestData() {
      const { formData } = this;
      const data = {
        title: formData.title,
        shortDescription: formData.shortDescription,
        longDescription: formData.longDescription,
        mediaFolder: this.formData.mediaFolder,
        sortBy: this.formData.sortBy,
        sortDirection: this.formData.sortDirection,
        includeMediaTypes: this.formData.includeMediaTypes,
        includeContentTypes: this.formData.includeContentTypes,
      };

      if (this.isPosterSmallChanged) {
        data.posterImage = this.formData.image;
        this.isPosterSmallChanged = false;
      }

      if (this.isPortraitImageChanged) {
        data.portraitImage = this.formData.portraitImage;
        this.isPortraitImageChanged = false;
      }

      if (this.isPosterLargeChanged) {
        data.backgroundImage = this.formData.backgroundImage;
        this.isPosterLargeChanged = false;
      }

      if (this.isPosterTitleChanged) {
        data.titleImage = this.formData.titleImg;
        this.isPosterTitleChanged = false;
      }

      data.fields = this.getFieldsData();

      data.categories = formData.categories.map((item) => item.id);
      data.genres = formData.genres.map((item) => item.id);

      return {
        playlistId: this.$route.params.id,
        accountId: this.getCurrentAccountId,
        data,
      };
    },
    onSubmitFail(error) {
      const titleErr = error.form_errors.children
        && error.form_errors.children.titleImage
        && error.form_errors.children.titleImage.errors;

      if (titleErr) {
        this.titleImageError = 'File MIME type should be PNG';
        this.tabErrors.general = true;
        return;
      }

      const folderError = error.form_errors
        && error.form_errors.children
        && error.form_errors.children.mediaFolder
        && error.form_errors.children.mediaFolder.errors
        && error.form_errors.children.mediaFolder.errors[0].error;

      if (folderError) {
        this.tabErrors.dataSource = true;
      }

      if (error.form_errors.children && error.form_errors.children.title) {
        this.tabErrors.general = true;
      }

      this.formErrors = this.getServerFormErrors(error);
      this.itemsErrors = this.getServerFormCollectionErrors(error);

      if (folderError && folderError === 'NOT_SYNCHRONIZED_ERROR') {
        this.foldError = 'This folder is already in use';
        return;
      }

      if (folderError && folderError === 'IS_BLANK_ERROR') {
        this.foldError = 'This field is required';
        return;
      }

      if (Object.entries(this.formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(this.formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.backToPlaylists();
      }, CONFIG.routing.redirectFromAddAcc);
    },
  },
};
</script>

<style scoped>
.left-path {
  margin-bottom: 30px;
  margin-right: 360px;
}

.system {
  color: #4c5057;
}

.form-container {
  padding: 25px 30px;
  overflow-y: auto;
  width: 100%;
}

.wrapper {
  display: flex;
}

.edit-button-wrapper {
  position: absolute;
  top: 75px;
  display: flex;
  right: 30px;
}
</style>
