<template>
  <div
    v-if="data && data.length"
    class="chart-wrapper"
  >
    <div class="delivery">
      Delivery Bandwidth
    </div>
    <div
      ref="chart"
      class="chart"
    >
    </div>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import Am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import Am4themesDark from '@amcharts/amcharts4/themes/dark';

import CONFIG from '../../constants/config';

export default {
  name: 'BandwichItem',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);
    am4core.useTheme(Am4themesDark);
    am4core.useTheme(Am4themesAnimated);
    const chart = am4core.create(this.$refs.chart, am4charts.XYChart);
    chart.colors.step = 2;

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;

    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = 'date';
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;

    const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;

    const arrangeColumns = () => {
      const series = chart.series.getIndex(0);

      const w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        const x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX');
        const x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX');
        const delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          const middle = chart.series.length / 2;

          let newIndex = 0;
          chart.series.each((series) => {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex += 1;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          const visibleCount = newIndex;
          const newMiddle = visibleCount / 2;

          chart.series.each((series) => {
            const trueIndex = chart.series.indexOf(series);
            const newIndex = series.dummyData;

            const dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate(
              { property: 'dx', to: dx },
              series.interpolationDuration,
              series.interpolationEasing,
            );
            series.bulletsContainer.animate(
              { property: 'dx', to: dx },
              series.interpolationDuration,
              series.interpolationEasing,
            );
          });
        }
      }
    };

    const createSeries = (value, name) => {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = 'date';
      series.name = name;
      series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';

      series.events.on('hidden', arrangeColumns);
      series.events.on('shown', arrangeColumns);

      const bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = 30;
      bullet.label.text = '{valueY}';
      bullet.label.fill = am4core.color('#fff');

      return series;
    };

    const format = CONFIG.date.monthYearDateFormat;
    chart.data = this.data.map((item) => ({
      date: this.$options.filters.date(item.date, format),
      vod: (item.vod / 1000000000).toFixed(3),
      livestream: (item.livestream / 1000000000).toFixed(3),
    }));

    createSeries('vod', 'VOD, Gb');
    createSeries('livestream', 'Live, Gb');
  },
};
</script>

<style scoped>
  .chart-wrapper {
    padding-top: 50px;
  }

  .delivery {
    padding-bottom: 20px;
    text-align: center;
  }

  .chart {
    height: 270px;
  }

  @media all and (max-width: 1400px) {
    .chart {
      width: 1000px;
    }
  }
</style>
