export const CREATE_FEATURE_REQUEST = 'CREATE_FEATURE_REQUEST';
export const CREATE_FEATURE_SUCCESS = 'CREATE_FEATURE_SUCCESS';
export const CREATE_FEATURE_ERROR = 'CREATE_FEATURE_ERROR';

export const GET_FEATURE_REQUEST = 'GET_FEATURE_REQUEST';
export const GET_FEATURE_SUCCESS = 'GET_FEATURE_SUCCESS';
export const GET_FEATURE_ERROR = 'GET_FEATURE_ERROR';

export const GET_FEATURES_LIST_REQUEST = 'GET_FEATURES_LIST_REQUEST';
export const GET_FEATURES_LIST_SUCCESS = 'GET_FEATURES_LIST_SUCCESS';
export const GET_FEATURES_LIST_ERROR = 'GET_FEATURES_LIST_ERROR';

export const EDIT_FEATURE_REQUEST = 'EDIT_FEATURE_REQUEST';
export const EDIT_FEATURE_SUCCESS = 'EDIT_FEATURE_SUCCESS';
export const EDIT_FEATURE_ERROR = 'EDIT_FEATURE_ERROR';

export const DELETE_FEATURE_REQUEST = 'DELETE_FEATURE_REQUEST';
export const DELETE_FEATURE_SUCCESS = 'DELETE_FEATURE_SUCCESS';
export const DELETE_FEATURE_ERROR = 'DELETE_FEATURE_ERROR';

export const GET_FEATURE_SORT_BY_REQUEST = 'GET_FEATURE_SORT_BY_REQUEST';
export const GET_FEATURE_SORT_BY_SUCCESS = 'GET_FEATURE_SORT_BY_SUCCESS';
export const GET_FEATURE_SORT_BY_ERROR = 'GET_FEATURE_SORT_BY_ERROR';

export const GET_FEATURE_SORT_ORDER_REQUEST = 'GET_FEATURE_SORT_ORDER_REQUEST';
export const GET_FEATURE_SORT_ORDER_SUCCESS = 'GET_FEATURE_SORT_ORDER_SUCCESS';
export const GET_FEATURE_SORT_ORDER_ERROR = 'GET_FEATURE_SORT_ORDER_ERROR';

export const GET_DYNAMIC_MEDIA_FEATURE_REQUEST = 'GET_DYNAMIC_MEDIA_FEATURE_REQUEST';
export const GET_DYNAMIC_MEDIA_FEATURE_SUCCESS = 'GET_DYNAMIC_MEDIA_FEATURE_SUCCESS';
export const GET_DYNAMIC_MEDIA_FEATURE_ERROR = 'GET_DYNAMIC_MEDIA_FEATURE_ERROR';

// export const GET_PLAYLISTS_CMS_REQUEST = 'GET_PLAYLISTS_CMS_REQUEST';
// export const GET_PLAYLISTS_CMS_SUCCESS = 'GET_PLAYLISTS_CMS_SUCCESS';
// export const GET_PLAYLISTS_CMS_ERROR = 'GET_PLAYLISTS_CMS_ERROR';
