<template>
  <div class="translate-wrapper">
    <ValidationObserver
      ref="validationObserver"
      v-slot="{ handleSubmit }"
    >
      <form
        class="contact-info"
        novalidate="novalidate"
        @submit.prevent="handleSubmit(submit)"
        @input="handleFormChange"
      >
        <MaterialInput
          v-model="formData.title"
          v-bind="formFields.title"
        />
        <MaterialTextArea
          v-if="!isGroup"
          v-model="formData.description"
          v-bind="formFields.description"
          :label="'Event Description'"
        />
        <div v-if="!isIndividualEvent">
          <div class="poster-title">
            Countdown & Preview Image
          </div>
          <PosterImage
            v-if="evGroupLanguage"
            :key="formData.image"
            ref="posterImage"
            :poster="formData.image"
            :onPosterChange="handlePosterChange"
            :isNeedRemove="true"
            :title="'Drag or click to upload Countdown & Preview Image'"
            :externalErrorMessage="imageError"
            :imageType="'Countdown & Preview Image'"
          />
        </div>
        <div class="btn-wrapper">
          <div class="btn-wrapper-b">
            <Button
              type="submit"
              :title="'Apply Changes'"
              :classType="'primary'"
            />
            <Button
              :title="'Revert'"
              :classType="'warning'"
              :eventFunction="revertFolderLanguage"
            />
          </div>
          <div
            v-if="isEditedForm.length
              && isEditedForm.find(item => item.id === lang.id && !!item.value)"
            class="changes-wrapper"
          >
            <inline-svg
              :src="iconChanges"
              class="svg-icon"
            >
            </inline-svg>
            <span class="changes-text">
              Unsaved changes
            </span>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import PosterImage from '../../common/PosterImage/DragAndDropFileUploader.vue';
import Button from '../../common/Button.vue';
import MaterialTextArea from '../../inputs/MaterialTextArea.vue';
import iconChanges from '../../../assets/icons/icon-localization-changes.svg';

import {
  ADD_LANGUAGE_EV_GROUP_REQUEST,
  ADD_LANGUAGE_SINGLE_EV_REQUEST,
  GET_LANGUAGE_EV_GROUP_REQUEST,
  GET_LANGUAGE_SINGLE_EV_REQUEST,
  INIT_FORM_CHANGES,
  SET_FORM_CHANGES,
} from '../../../store/actions/localization/localizationActions';

export default {
  name: 'TranslateEvGroupForm',
  components: {
    MaterialInput,
    PosterImage,
    Button,
    MaterialTextArea,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'evGroupLanguage',
    },
    evGroup: {
      type: Object,
      default: () => {},
    },
    lang: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    isIndividualEvent: {
      type: Boolean,
      default: false,
    },
    enabledLanguages: {
      type: Array,
      default: () => [],
    },
    changedLanguage: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconChanges,
      submitEvent: this.isGroup ? ADD_LANGUAGE_EV_GROUP_REQUEST : ADD_LANGUAGE_SINGLE_EV_REQUEST,
      successMessages: `The ${this.lang.name} Localization has been successfully updated`,
      isPosterSmallChanged: false,
      imageError: '',
      formData: {
        title: '',
        description: '',
        image: '',
      },
      formFields: {
        title: {
          type: 'text',
          name: this.isGroup ? 'title' : 'singleEvTitle',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 255,
          },
        },
        description: {
          type: 'text',
          name: 'description',
          formPrefix: this.formPrefix,
          rules: {
            required: false,
            max: 1800,
          },
        },
      },
      errorMapping: {
        title: [
          `${this.formPrefix}.title`,
        ],
        description: [
          `${this.formPrefix}.description`,
        ],
      },
    };
  },
  computed: {
    ...mapState({
      evGroupLanguage: (state) => state.localization.evGroupLanguage,
      singleEvLanguage: (state) => state.localization.singleEvLanguage,
      isEditedForm: (state) => state.localization.isEditedForm,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  created() {
    const value = this.enabledLanguages.map(((item) => ({
      id: item.id,
      value: false,
    })));
    this.$store.dispatch(INIT_FORM_CHANGES, value);

    const params = {
      accountId: this.getCurrentAccountId,
      evGroupId: this.evGroup.id,
      langId: this.lang.id,
    };
    this.$store.dispatch(this.isGroup
      ? GET_LANGUAGE_EV_GROUP_REQUEST
      : GET_LANGUAGE_SINGLE_EV_REQUEST, params).then(() => {
      this.initFormData();
    });
  },
  beforeDestroy() {
    this.$store.dispatch(SET_FORM_CHANGES, []);
  },
  methods: {
    handleFormChange() {
      this.$emit('changedLanguage', { id: this.lang.id, value: true });
    },
    initFormData() {
      if (this.isGroup) {
        this.formData.title = this.evGroupLanguage.name;
        this.formData.image = this.evGroupLanguage.backgroundImage ? `${this.evGroupLanguage.backgroundImage}?height=360` : '';
      } else {
        this.formData.title = this.singleEvLanguage.name;
        this.formData.description = this.singleEvLanguage.description;
        this.formData.image = this.singleEvLanguage.backgroundImage ? `${this.singleEvLanguage.backgroundImage}?height=360` : '';
      }
    },
    handlePosterChange(img) {
      this.isPosterSmallChanged = true;
      this.handleFormChange();
      this.formData.image = img;
      if (this.imageError) {
        this.imageError = '';
      }
    },
    revertFolderLanguage() {
      const params = {
        accountId: this.getCurrentAccountId,
        evGroupId: this.evGroup.id,
        langId: this.lang.id,
      };
      this.$store.dispatch(this.isGroup
        ? GET_LANGUAGE_EV_GROUP_REQUEST
        : GET_LANGUAGE_SINGLE_EV_REQUEST, params)
        .then(() => {
          this.$emit('changedLanguage', { id: this.lang.id, value: false });
          this.$store.dispatch(SET_FORM_CHANGES, { id: this.lang.id, value: false });
          this.isPosterSmallChanged = false;
          this.initFormData();
        });
    },
    getRequestData() {
      const data = {
        name: this.formData.title,
        backgroundImage: this.formData.image,
        description: this.formData.description,
      };

      if (!this.isPosterSmallChanged) {
        delete data.backgroundImage;
      }

      if (this.isGroup) {
        delete data.description;
      }

      if (this.isIndividualEvent) {
        delete data.backgroundImage;
      }

      return {
        accountId: this.getCurrentAccountId,
        evGroupId: this.evGroup.id,
        langId: this.lang.id,
        data,
      };
    },
    onSubmitSuccess() {
      this.isPosterSmallChanged = false;
      this.$emit('changedLanguage', { id: this.lang.id, value: false });
      this.$store.dispatch(SET_FORM_CHANGES, { id: this.lang.id, value: false });
      this.$toasted.global.success({
        message: this.successMessages,
      });
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);
      const imgError = error
        && error.form_errors
        && error.form_errors.children
        && error.form_errors.children.backgroundImage
        && error.form_errors.children.backgroundImage.errors
        && error.form_errors.children.backgroundImage.errors[0].error;

      if (imgError === 'INVALID_MIME_TYPE_ERROR') {
        this.imageError = 'File MIME type should be PNG or JPEG';
      }

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.translate-wrapper {
  padding-left: 20px;
  padding-right: 428px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 35px;
}

.changes-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #FB3951;
  border-radius: 2px;
  padding: 8px;
  color: #FB3951;
  margin-left: 20px;
}

.changes-text {
  padding-left: 5px;
}

.btn-wrapper-b {
  display: flex;
}

@media all and (max-width: 1670px) {
  .translate-wrapper {
    padding-right: 300px;
  }
}

@media all and (max-width: 1460px) {
  .translate-wrapper {
    padding-right: 200px;
  }
}

@media all and (max-width: 1300px) {
  .translate-wrapper {
    padding-right: 100px;
  }
}
</style>
