import { render, staticRenderFns } from "./AddCmsPageModal.vue?vue&type=template&id=015a2f32&scoped=true&"
import script from "./AddCmsPageModal.vue?vue&type=script&lang=js&"
export * from "./AddCmsPageModal.vue?vue&type=script&lang=js&"
import style0 from "./AddCmsPageModal.vue?vue&type=style&index=0&id=015a2f32&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "015a2f32",
  null
  
)

export default component.exports