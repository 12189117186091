<template>
  <div class="overlay">
    <div class="wrapper-form">
      <p
        class="headline grey lighten-2"
        primaryTitle
      >
        Add to Event
      </p>
      <ValidationObserver
        ref="validationObserver"
        v-slot="{ handleSubmit }"
      >
        <form
          novalidate="novalidate"
          @submit.prevent="handleSubmit(submit)"
          @input="handleFormChange"
        >
          <MaterialInput
            v-model="formData.name"
            v-bind="formFields.name"
          />
          <MaterialTextArea
            v-model="formData.description"
            v-bind="formFields.description"
          />
          <div class="inline-row">
            <MaterialCalendar2
              v-model="formData.date"
              v-bind="formFields.date"
              :disabledDate="unavailableDates"
            />
            <MaterialTime
              v-model="formData.time"
              v-bind="formFields.time"
              :isManualInput="false"
              :onChangeTime="onChangeTime"
              :onCloseTimepicker="onCloseTimepicker"
            />
          </div>
          <div
            v-if="!isVodMedia"
            class="duration-wrapper"
          >
            <div class="duration-block">
              <MaterialTime
                v-model="formData.duration"
                v-bind="formFields.duration"
                :blockClassName="'duration'"
                :disabled="!!isPerpetual"
              />
            </div>
            <div class="toggle-wrapper">
              <ToggleButton
                ref="perpetual"
                v-model="isPerpetual"
                color="#2591CF"
                :sync="true"
                :labels="false"
                @change="changePerpetual"
              />
              <div class="toggle-title">
                Perpetual
              </div>
            </div>
          </div>
          <div class="inline-row">
            <MaterialCheckbox
              :label="formFields.isInGroup.label"
              :toggle="toggleToBeInGroup"
              :checked="formData.isInGroup"
            />
          </div>
          <div v-if="formData.isInGroup">
            <MaterialSelect
              v-model="formData.eventGroup"
              v-bind="formFields.eventGroup"
              :withEmptyField="false"
              :options="eventGroupsOptions"
            />
          </div>
          <div class="button-wrapper">
            <Button
              :title="'Close'"
              :classType="'warning'"
              :eventFunction="onCancelHandle"
            />
            <Button
              :title="'Add'"
              type="submit"
              :classType="'primary'"
            />
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { ADD_EVENT } from '../../store/actions/events/events';
import { GET_ALL_EVENT_GROUPS } from '../../store/actions/events/eventGroups';

import BaseForm from '../forms/BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialTextArea from '../inputs/MaterialTextArea.vue';
import MaterialCalendar2 from '../inputs/MaterialCalendar2.vue';
import MaterialTime from '../inputs/MaterialTime.vue';
import MaterialCheckbox from '../inputs/MaterialCheckbox.vue';
import MaterialSelect from '../inputs/MaterialSelect.vue';
import Button from '../common/Button.vue';

import CONFIG from '../../constants/config';

export default {
  name: 'AddEventModal',
  components: {
    MaterialCalendar2,
    MaterialInput,
    MaterialTextArea,
    MaterialTime,
    MaterialSelect,
    MaterialCheckbox,
    Button,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'event',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    handleCloseInspector: {
      type: Function,
      default: () => {},
    },
    openInspector: {
      type: Function,
      default: () => {},
    },
    mediaItemId: {
      type: Number,
      required: true,
      default: null,
    },
    isVodMedia: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentSortedField: 'seg.name',
      selectedTime: {},
      isPerpetual: false,
      formData: {
        name: '',
        description: '',
        date: '',
        time: '',
        isInGroup: false,
        eventGroup: null,
        duration: '',
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            min: 1,
            max: 255,
          },
        },
        description: {
          type: 'text',
          name: 'description',
          formPrefix: this.formPrefix,
          rules: {
            min: 1,
            max: 1800,
          },
        },
        date: {
          type: 'date',
          valueType: 'YYYY-MM-DD',
          name: 'date',
          format: 'DD MMM YYYY',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        time: {
          type: 'time',
          name: 'time',
          format: 'hh:mm A',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        duration: {
          type: 'time',
          name: 'duration',
          format: 'HH:mm:ss',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        isInGroup: {
          label: 'Add to Event Group',
        },
        eventGroup: {
          type: 'text',
          name: 'eventGroup',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        date: [
          `${this.formPrefix}.date`,
        ],
        time: [
          `${this.formPrefix}.startDate`,
        ],
        duration: [
          `${this.formPrefix}.duration`,
        ],
        eventGroup: [
          `${this.formPrefix}.eventGroup`,
        ],
      },
      submitEvent: ADD_EVENT,
      successMessage: CONFIG.successMessages.addEventMessage,
      errorMessage: '',
      postEventMessageDefault: this.isVodMedia
        ? CONFIG.postEventAction[1].defaultMessage
        : CONFIG.postEventActionLive[0].defaultMessage,
    };
  },
  computed: {
    ...mapState({
      eventGroups: (state) => state.eventGroups.eventGroups,
      mediaItem: (state) => state.mediaItem.mediaItem,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'getMainProfile',
    ]),
    unavailableDates() {
      const accountCurrentDate = moment.utc().tz(this.getTimezone).date();
      const currentSystemDate = moment().date();

      if (accountCurrentDate < currentSystemDate) {
        return (date) => date < moment().subtract(2, 'd').toDate();
      }

      if (accountCurrentDate > currentSystemDate) {
        return (date) => date < moment().toDate();
      }

      return (date) => date < moment().subtract(1, 'd').toDate();
    },
    getTimezone() {
      return (this.getMainProfile.timezone) ? this.getMainProfile.timezone : moment.tz.guess();
    },
    getDateTime() {
      let dateTimeString = '';

      if (this.formData.date) {
        dateTimeString += moment(this.formData.date).format('YYYY-MM-DD');
        dateTimeString += this.parseTimeForBE;
      }

      return moment.tz(dateTimeString, this.getTimezone).utc();
    },
    eventGroupsOptions() {
      return this.eventGroups.map((item) => ({
        code: item.id,
        name: item.name.length > 50 ? `${item.name.slice(0, 50)}...` : item.name,
      }));
    },
    parseTimeForBE() {
      return `T${this.selectedTime.data.HH}:${this.selectedTime.data.mm}:00`;
    },
  },
  created() {
    const params = {
      accountId: this.getCurrentAccountId,
      sortFiled: this.currentSortedField,
      sortBy: CONFIG.sort.sortUp,
    };
    this.$store.dispatch(GET_ALL_EVENT_GROUPS, params);
  },
  methods: {
    onChangeTime(t) {
      this.selectedTime = t;
    },
    toggleToBeInGroup() {
      this.formData.isInGroup = !this.formData.isInGroup;
    },
    getRequestData() {
      const data = {
        name: this.formData.name,
        description: this.formData.description,
        mediaItem: this.mediaItemId,
        startDate: this.getDateTime,
        duration: moment.duration(this.formData.duration).asSeconds(),
      };

      if (this.isVodMedia && this.formData.isInGroup) {
        delete data.duration;
        data.standaloneEventGroup = this.formData.eventGroup;
      } else if (this.isVodMedia && !this.formData.isInGroup) {
        data.postEventMessage = this.postEventMessageDefault;
        data.hasPostEventPresentVideo = true;
        data.backgroundImage = null;
        delete data.duration;
      }

      if (!this.isVodMedia && !this.formData.isInGroup) {
        data.hasPostEventPresentVideo = false;
        data.backgroundImage = null;
        data.postEventMessage = this.postEventMessageDefault;
      } else if ((!this.isVodMedia && this.formData.isInGroup)) {
        data.standaloneEventGroup = this.formData.eventGroup;
        delete data.backgroundImage;
        delete data.postEventMessage;
        delete data.hasPostEventPresentVideo;
      }

      if (!this.isVodMedia && this.isPerpetual) {
        data.duration = null;
      }

      return {
        accountId: this.getCurrentAccountId,
        data,
      };
    },
    addMinutes(date, minutes) {
      date.setMinutes(date.getMinutes() + minutes);

      return date;
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });

      if (this.isVodMedia) {
        const selectedDate = new Date(this.getDateTime).toUTCString();
        const result1 = this.addMinutes(new Date(), 10).toUTCString();
        const result2 = this.addMinutes(new Date(),
          -Math.round(this.mediaItem.duration / 60)).toUTCString();

        if (selectedDate > result2 && selectedDate < result1) {
          this.$toasted.global.info({
            message: `The "${this.formData.name}" Event is being provisioned and will be ready in approximately 10 minutes`,
          });
        }
      }

      this.onClose();
      this.handleCloseInspector();
      this.openInspector(this.mediaItemId);
    },
    onCancelHandle() {
      this.onClose();
    },
    onCloseTimepicker() {
      if (!this.selectedTime.data) {
        this.formData.time = '12:00 AM';
      } else {
        const { A, hh, mm } = this.selectedTime.data;
        if (!A) {
          this.selectedTime.data.A = 'AM';
        }
        if (!hh) {
          this.selectedTime.data.hh = '12';
          this.selectedTime.data.HH = '00';
        }
        if (!mm) {
          this.selectedTime.data.mm = '00';
        }
        this.formData.time = `${this.selectedTime.data.hh}:${this.selectedTime.data.mm} ${this.selectedTime.data.A}`;
      }
    },
    changePerpetual() {
      if (this.isPerpetual) {
        this.formData.duration = '00:00:00';
      } else {
        this.formData.duration = '';
      }
    },
  },
};
</script>
<style scoped>
  .wrapper-form {
    background-color: #222;
    border-radius: 4px;
    display: block;
    padding: 30px;
    width: 500px;
  }

  .headline {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding-bottom: 30px;
  }

  .inline-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .inline-row > * {
    flex: 1;
    margin: 0;
    max-width: 220px;
  }

 .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
   padding-top: 10px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }

  .duration {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .toggle-wrapper {
    align-items: center;
    display: flex;
    padding-top: 9px;
  }

  .toggle-title {
    margin-left: 10px;
  }

  .duration-wrapper {
    align-items: center;
    display: flex;
  }

  .duration-block {
    width: 220px;
    margin-right: 60px;
  }

  @media screen and (max-height: 900px) {
    .overlay {
      align-items: flex-start;
    }
    .wrapper-form {
      margin-top: 70px;
    }
  }
</style>
