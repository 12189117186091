import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  SET_CANCEL_TOKEN,
} from '../../actions/peopleActions/people';
import {
  SET_CATEGORY_STORE_PROPERTY,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  GET_CATEGORY_IN_USE_STATUS_REQUEST,
  GET_CATEGORY_IN_USE_STATUS_SUCCESS,
  GET_CATEGORY_IN_USE_STATUS_ERROR,
  CHANGE_CATEGORY_STATUS_REQUEST,
  CHANGE_CATEGORY_STATUS_SUCCESS,
  CHANGE_CATEGORY_STATUS_ERROR,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  EDIT_CATEGORY_REQUEST,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_ERROR,
  GET_CATEGORIES_FOR_CHOICE_REQUEST,
  GET_CATEGORIES_FOR_CHOICE_SUCCESS,
  GET_CATEGORIES_FOR_CHOICE_ERROR,
} from '../../actions/categories/categoriesActions';

const state = {
  isLoading: false,
  categories: [],
  pagination: {},
  category: {},
  source: null,
  filterValue: '',
  sortFiled: 'c.name',
  sortBy: 'ASC',
};

const actions = {
  [GET_CATEGORIES_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    commit(GET_CATEGORIES_REQUEST);
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    let url = `${params.accountId}/taxonomy/category?p=${params.page}&l=15&filterField=c.name&filterValue=${state.filterValue}`;
    if (state.sortFiled) {
      url += `&s=${state.sortFiled}&d=${state.sortBy}`;
    }

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_CATEGORIES_SUCCESS, resp.data);
        resolve();
      })
      .catch(() => {
        commit(GET_CATEGORIES_ERROR);
      });
  }),
  [GET_CATEGORY_IN_USE_STATUS_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    commit(GET_CATEGORY_IN_USE_STATUS_REQUEST);

    const url = `${params.accountId}/taxonomy/category/${params.id}/is-in-use`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_CATEGORY_IN_USE_STATUS_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_CATEGORY_IN_USE_STATUS_ERROR);
        resolve({ isInUse: true });
      });
  }),
  [ADD_CATEGORY_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(ADD_CATEGORY_REQUEST);

    apiCall.post(`${accountId}/taxonomy/category`, data)
      .then((res) => {
        commit(ADD_CATEGORY_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_CATEGORY_ERROR, error);
        reject(error);
      });
  }),
  [GET_CATEGORY_REQUEST]:
    ({ commit }, { accountId, categoryId }) => new Promise((resolve, reject) => {
      commit(GET_CATEGORY_REQUEST);

      const url = `${accountId}/taxonomy/category/${categoryId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_CATEGORY_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_CATEGORY_ERROR, error);
          reject(error);
        });
    }),
  [CHANGE_CATEGORY_STATUS_REQUEST]:
    ({ commit }, { accountId, categoryId, data }) => new Promise((resolve, reject) => {
      commit(CHANGE_CATEGORY_STATUS_REQUEST);

      apiCall.put(`${accountId}/taxonomy/category/${categoryId}/enabled`, data)
        .then((resp) => {
          commit(CHANGE_CATEGORY_STATUS_SUCCESS, resp);
          resolve(resp);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(CHANGE_CATEGORY_STATUS_ERROR, error);
          reject(error);
        });
    }),
  [DELETE_CATEGORY_REQUEST]:
    ({ commit }, { accountId, categoryId }) => new Promise((resolve, reject) => {
      commit(DELETE_CATEGORY_REQUEST);

      const url = `${accountId}/taxonomy/category/${categoryId}`;

      apiCall.delete(url)
        .then(() => {
          commit(DELETE_CATEGORY_SUCCESS);
          resolve();
        })
        .catch((error) => {
          commit(DELETE_CATEGORY_ERROR, error);
          reject(error);
        });
    }),
  [EDIT_CATEGORY_REQUEST]:
    ({ commit }, { accountId, categoryId, data }) => new Promise((resolve, reject) => {
      commit(EDIT_CATEGORY_REQUEST);

      const url = `${accountId}/taxonomy/category/${categoryId}`;

      apiCall.put(url, data)
        .then(() => {
          commit(EDIT_CATEGORY_SUCCESS);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(EDIT_CATEGORY_ERROR, error);
          reject(error);
        });
    }),
  [GET_CATEGORIES_FOR_CHOICE_REQUEST]:
    ({ commit }, { accountId }) => new Promise((resolve, reject) => {
      commit(GET_CATEGORIES_FOR_CHOICE_REQUEST);

      const url = `choice/accounts/${accountId}/categories`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_CATEGORIES_FOR_CHOICE_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_CATEGORIES_FOR_CHOICE_ERROR, error);
          reject(error);
        });
    }),
};

const mutations = {
  [SET_CATEGORY_STORE_PROPERTY]: (state, payload) => {
    state[payload.key] = payload.value;
  },
  [GET_CATEGORIES_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [GET_CATEGORIES_SUCCESS]: (state, payload) => {
    state.categories = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
    state.isLoading = false;

    localStorage.setItem('categories', payload.pagination.page);
  },
  [GET_CATEGORIES_ERROR]: (state) => {
    state.isLoading = false;
  },
  [GET_CATEGORY_IN_USE_STATUS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_CATEGORY_IN_USE_STATUS_SUCCESS]: (state) => {
    state.isLoading = false;
  },
  [GET_CATEGORY_IN_USE_STATUS_ERROR]: (state) => {
    state.isLoading = false;
  },
  [ADD_CATEGORY_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_CATEGORY_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_CATEGORY_ERROR]: () => {
    state.isLoading = false;
  },
  [CHANGE_CATEGORY_STATUS_REQUEST]: () => {
    state.isLoading = true;
  },
  [CHANGE_CATEGORY_STATUS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CHANGE_CATEGORY_STATUS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_CATEGORY_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_CATEGORY_SUCCESS]: (stateLocal, payload) => {
    state.category = payload;
    state.isLoading = false;
  },
  [GET_CATEGORY_ERROR]: () => {
    state.isLoading = false;
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
  },
  [DELETE_CATEGORY_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_CATEGORY_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_CATEGORY_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_CATEGORY_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_CATEGORY_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_CATEGORY_ERROR]: () => {
    state.isLoading = false;
  },

  [GET_CATEGORIES_FOR_CHOICE_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_CATEGORIES_FOR_CHOICE_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_CATEGORIES_FOR_CHOICE_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
