<template>
  <div class="wrapper-sort">
    <div
      :class="['wrap-icon-sort', 'active']"
      @click="handleSort(sortField)"
    >
      <inline-svg
        :src="iconSort"
        :class="'svg'"
      >
      </inline-svg>
    </div>
  </div>
</template>

<script>
import iconSortDown from '../../assets/icons/icon-sort-down.svg';
import iconSortUp from '../../assets/icons/icon-sort-up.svg';
import iconSortDownActive from '../../assets/icons/icon-sort-down-active.svg';
import iconSortUpActive from '../../assets/icons/icon-sort-up-active.svg';

export default {
  name: 'Sorting',
  props: {
    sortField: {
      type: String,
      default: '',
    },
    defaultSortUp: {
      type: Boolean,
      default: true,
    },
    currentSortedField: {
      type: String,
      default: '',
    },
    handleSortUp: {
      type: Function,
      default: () => {},
    },
    handleSortDown: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isSortedUp: this.defaultSortUp,
      isSortedDown: !this.defaultSortUp,
      iconSortUp,
      iconSortDown,
    };
  },
  computed: {
    iconSort() {
      if (this.isSortedUp && this.currentSortedField === this.sortField) {
        return iconSortUpActive;
      }

      if (this.isSortedDown && this.currentSortedField === this.sortField) {
        return iconSortDownActive;
      }

      if (this.isSortedUp) {
        return iconSortUp;
      }

      if (this.isSortedDown) {
        return iconSortDown;
      }

      return null;
    },
  },
  methods: {
    sortUp(field) {
      this.handleSortUp(field);
      this.isSortedUp = true;
      this.isSortedDown = false;
    },
    sortDown(field) {
      this.handleSortDown(field);
      this.isSortedDown = true;
      this.isSortedUp = false;
    },
    handleSort(field) {
      return this.isSortedUp ? this.sortDown(field) : this.sortUp(field);
    },
  },
};

</script>

<style scoped>
.wrapper-sort {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-right: 5px;
}

.sort-arrows {
  align-items: center;
  display: flex;
  height: 4px;
  justify-content: center;
  width: 8px;
}

.sort-arrows:first-child {
  margin-bottom: 4px;
}

.svg {
  fill: #fff;
}

.active {
  fill: #2591cf;
}
</style>
