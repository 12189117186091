<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, classes, required, ariaInput, ariaMsg }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    mode="passive"
  >
    <div
      class="group"
      :class="classes"
    >
      <label class="label">
        {{ label }}
        <span
          v-if="required"
          class="asterisk"
        >
          *
        </span>
      </label>
      <span class="select-wrapper">
        <RadioCustomList
          v-model="valueModel"
          :options="options"
          :name="name"
          :tagLabel="tagLabel"
          :tagTrackBy="tagTrackBy"
          :disabled="disabled"
          :required="required"
          :ariaInput="ariaInput"
          :maxlength="maxlength"
          :type="type"
          :className="className"
        />
      </span>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
          v-bind="ariaMsg"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import BaseMaterialInput from './BaseMaterialInput.vue';
import RadioCustomList from './RadioCustomList.vue';

export default {
  name: 'MaterialRadioCustomList',
  components: {
    ValidationProvider,
    RadioCustomList,
  },
  extends: BaseMaterialInput,
  props: {
    options: {
      type: Array,
      default: null,
    },
    tagLabel: {
      type: String,
      default: 'name',
    },
    tagTrackBy: {
      type: String,
      default: 'code',
    },
  },
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
<style scoped>
  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .select-wrapper {
    margin-top: 10px;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
</style>
