import axios from 'axios';
import {
  GET_MEDIA_LIST,
  GET_MEDIA_LIST_REQUEST,
  GET_MEDIA_LIST_SUCCESS,
  GET_MEDIA_LIST_ERROR,
  SET_CANCEL_TOKEN,
  GET_TRANSCODING_PROGRESS_SSE_REQUEST,
  GET_TRANSCODING_PROGRESS_SSE_SUCCESS,
  GET_TRANSCODING_PROGRESS_SSE_CLOSED,
  UPLOAD_XML_REQUEST,
  UPLOAD_XML_SUCCESS,
  UPLOAD_XML_ERROR,
  UPLOAD_AS_BASE64_REQUEST,
} from '../../actions/media/mediaList';
import apiCall from '../../../utils/api/api';
import sseCall from '../../../utils/api/sse';
import { GET_MEDIA_ITEM_TRANSCODING_SUCCESS } from '../../actions/media/mediaItem';
import CONFIG from '../../../constants/config';
import { REVERT_PLACE_REQUEST } from '../../actions/replaceActions/replace';

const state = {
  mediaList: {},
  pagination: {},
  isLoading: true,
  source: null,
  replaceUploading: false,
};

let sseSource;

const actions = {
  [GET_MEDIA_LIST]:
  ({ commit, rootState }, { accountId, folderId, params }) => new Promise((resolve, reject) => {
    commit(GET_MEDIA_LIST_REQUEST);

    let dynamicParams = '';

    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    if (params.types) {
      params.types.forEach((tag) => {
        if (tag === 'livestream') {
          tag = 'livestream&types[]=livestream_url';
        }
        dynamicParams += `&types[]=${tag}`;
      });
    }

    const url = `${accountId}/media-collection/item/list/${folderId}/${params.isShowChildren}?p=${params.page}&l=${params.limit || 20}&filterField=mi.name&filterValue=${params.filterValue}&s=${params.sortFiled}&d=${params.sortBy}${dynamicParams}`;

    apiCall.get(url,
      {
        cancelToken: source.token,
        cache: false,
      })
      .then((resp) => {
        const uploadedFiles = rootState.upload.files;
        const payload = {
          uploadedFiles,
          data: resp.data,
        };
        commit(GET_MEDIA_LIST_SUCCESS, payload);
        localStorage.setItem('currentPage-media-list', resp.data.pagination.page);
        resolve(resp.data);
      }).catch((error) => {
        commit(GET_MEDIA_LIST_ERROR);
        reject(error);
      });
  }),
  [GET_TRANSCODING_PROGRESS_SSE_REQUEST]: ({ commit, dispatch, rootState }) => new Promise(() => {
    let count = 0;
    function connect() {
      count += 1;
      const url = '/media-collection/item/transcoding';
      sseSource = sseCall.getEventSource([url]);

      sseSource.then((eventSource) => {
        eventSource.onmessage = (event) => {
          const {
            completed,
            error,
            transcoding,
            packaging,
          } = CONFIG.transcoding;
          const res = JSON.parse(event.data);
          commit(GET_TRANSCODING_PROGRESS_SSE_SUCCESS, res);

          if (res.status === completed
            || res.status === error
            || res.status === transcoding
            || res.status === packaging
          ) {
            if (res.status === error) {
              const { id } = rootState.mediaItem.mediaItem;
              dispatch(REVERT_PLACE_REQUEST, id);
            }

            commit(GET_MEDIA_ITEM_TRANSCODING_SUCCESS, res);
          }
        };

        eventSource.onerror = () => {
          if (count <= 20) {
            window.setTimeout(() => {
              connect();
            }, 5000);
          } else {
            eventSource.close();
          }
        };
      });
    }

    connect();
  }),
  [GET_TRANSCODING_PROGRESS_SSE_CLOSED]: () => new Promise(() => {
    sseSource.then((eventSource) => {
    	eventSource.close();
    });
  }),
  [UPLOAD_AS_BASE64_REQUEST]: ({ commit }, file) => new Promise((resolve, reject) => {
    commit(UPLOAD_AS_BASE64_REQUEST);

    const {
      uploadXML,
      commonServerError,
    } = CONFIG.errorMessages;
    const reader = new FileReader();

    if (file.type === uploadXML.type && file.size) {
      reader.addEventListener('load', () => {
        resolve(reader.result);
      }, false);
      reader.readAsDataURL(file);
    } else if (file.type === uploadXML.type && !file.size) {
      reject(uploadXML.fileSizeError);
    } else if (file.type !== uploadXML.type) {
      reject(uploadXML.fileFormatError);
    } else {
      reject(commonServerError);
    }
  }),
  [UPLOAD_XML_REQUEST]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(UPLOAD_XML_REQUEST);

    apiCall.post(`${accountId}/media-collection/item/import`, data)
      .then((res) => {
        commit(UPLOAD_XML_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(UPLOAD_XML_ERROR, error);
        reject(error);
      });
  }),
};

const mutations = {
  [GET_MEDIA_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
    state.isLoading = true;
  },
  [GET_MEDIA_LIST_SUCCESS]: (stateLocal, payload) => {
    state.isLoading = false;
    state.source = null;
    state.mediaList = payload.data.data;
    state.pagination = payload.data.pagination;

    // eslint-disable-next-line array-callback-return
    payload.uploadedFiles.map((item) => {
      // eslint-disable-next-line array-callback-return
      state.mediaList.map((it) => {
        if (item.id === it.id) {
          state.replaceUploading = true;
        }
      });
    });
  },
  [GET_MEDIA_LIST_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_TRANSCODING_PROGRESS_SSE_SUCCESS]: (state, payload) => {
  	const {
  		id,
      config,
      status,
      posterImage,
      type,
    } = payload;
   	state.mediaList.map((media) => {
   		if (media.id === id) {
        media.config = config;
        media.status = status;
        media.posterImage = posterImage;
        media.type = type;
      }

   		return null;
    });
  },
  [UPLOAD_AS_BASE64_REQUEST]: () => {},
  [UPLOAD_XML_REQUEST]: () => {},
  [UPLOAD_XML_SUCCESS]: () => {},
  [UPLOAD_XML_ERROR]: () => {},
};

export default {
  state,
  actions,
  mutations,
};
