<template>
  <div class="scheduler-zoom-wrapper">
    <div class="minus-icon-wrapper">
      <inline-svg
        :src="iconMinus"
        class="svg-icon minus-icon"
        @click="onMinusClick"
      >
      </inline-svg>
    </div>
    <div class="input-range">
      <input
        type="range"
        :min="min"
        :max="max"
        :step="step"
        :value="timeCellHeight"
        @change="onChange"
      />
    </div>
    <div class="plus-icon-wrapper">
      <inline-svg
        :src="iconPlus"
        class="svg-icon plus-icon"
        @click="onPlusClick"
      >
      </inline-svg>
    </div>
  </div>
</template>
<script>
import iconMinus from '../../assets/icons/icon-zoom-minus.svg';
import iconPlus from '../../assets/icons/icon-zoom-plus.svg';

export default {
  name: 'ScheduleZoom',
  components: {
  },
  props: {
    onTimeCellHeightChange: {
      type: Function,
      default: () => {},
    },
    timeCellHeight: {
      type: Number,
      default: 40,
    },
  },
  data() {
    return {
      iconMinus,
      iconPlus,
      min: 40,
      max: 400,
      step: 40,
    };
  },
  computed: {},
  methods: {
    onChange(e) {
      this.onTimeCellHeightChange(e.target.value);
    },
    onMinusClick() {
      const value = this.timeCellHeight - this.step;
      if (value >= this.min) {
        this.onTimeCellHeightChange(value);
      }
    },
    onPlusClick() {
      const value = this.timeCellHeight + this.step;
      if (value <= this.max) {
        this.onTimeCellHeightChange(value);
      }
    },
  },
};
</script>
<style scoped>
  .scheduler-zoom-wrapper {
    align-items: center;
    display: flex;
    margin-left: 40px;
  }

  .minus-icon,
  .plus-icon {
    cursor: pointer;
    height: 15px;
    margin-top: 8px;
    padding: 0 5px;
    width: 15px;
  }

  .input-range {
    padding-top: 5px;
  }

  input[type=range] {
    -webkit-appearance: none;
    background: #222;
  }

  input[type=range]::-webkit-slider-runnable-track {
    height: 5px;
    width: 300px;
    border: none;
    background: #ddd;

    border-radius: 3px;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #2491cf;
    margin-top: -6px;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }
</style>
