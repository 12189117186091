<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="btn-add-mobile">
      <Button
        :title="'Add Person'"
        :classType="'primary'"
        :eventFunction="toCreatePersonHandler"
      />
    </div>
    <div class="flex">
      <div class="left-path">
        <span class="system">
          System Management
        </span>
        <span>
          / People
        </span>
      </div>
      <div class="right-path">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="255"
        />
        <div class="btn-add-full">
          <Button
            :title="'Add Person'"
            :classType="'primary'"
            :eventFunction="toCreatePersonHandler"
          />
        </div>
      </div>
    </div>
    <div class="accounts-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`cp.${sortedField.firstName}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          first name
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`cp.${sortedField.lastName}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          last name
        </div>
        <div class="th-name users-count">
          id
        </div>
        <div class="th-name status">
          status
        </div>
      </div>
      <div
        v-if="!people.length"
        class="empty-search"
      >
        No People to display
      </div>
      <div
        v-for="(item, key) in people"
        :key="item.id"
        class="account"
        @mouseover="onHoverElem(item)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div
          class="td company"
          :title="item.firstName"
        >
          {{ item.firstName }}
        </div>
        <div
          class="td company"
          :title="item.lastName"
        >
          {{ item.lastName }}
        </div>
        <div class="td users-count">
          {{ item.uniqueId }}
        </div>
        <div class="td status">
          <div
            class="status-ellipse"
            :style="item.enabled ? {background: '#1fee65'} : {background: '#fb3951'}"
          >
          </div>
          <span v-if="item.enabled">
            Enabled
          </span>
          <span v-else>
            Disabled
          </span>
        </div>
        <div
          v-show="isHover && item.id === selectedId"
          class="icons-wrapper"
        >
          <div :title="item.enabled ? disableTitle : enableTitle">
            <inline-svg
              :src="item.enabled ? iconEnabled : iconDisabled"
              class="svg-icon"
              @click="onOpenModal(item)"
            >
            </inline-svg>
          </div>
          <div class="icon-edit">
            <router-link
              :to="{ path: `people/${item.id}` }"
              :title="editPerson"
            >
              <inline-svg
                :src="iconEdit"
                class="svg-icon"
              >
              </inline-svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :totalPages="totalPages"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="modalTitle"
      :onAccept="changePersonStatus"
      :onClose="onClose"
    />
    <ErrorModal
      v-model="isModalErrorOpen"
      :modal="isModalErrorOpen"
      :bodyErrorMessage="bodyErrorMessage"
      :onClose="onModalErrorClose"
    />
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  CHANGE_PERSON_STATUS_REQUEST,
  GET_PEOPLE_REQUEST,
} from '../../store/actions/peopleActions/people';
import {
  SET_EDIT_FORM,
  SET_OVERFLOW,
} from '../../store/actions/common';
import CONFIG from '../../constants/config';

import iconEnabled from '../../assets/icons/icon-enabled-acc.svg';
import iconDisabled from '../../assets/icons/icon-disabled-acc.svg';

import Pagination from '../../components/common/Pagination.vue';
import Sorting from '../../components/common/Sorting.vue';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import LiveSearch from '../../components/common/LiveSearch.vue';
import Button from '../../components/common/Button.vue';
import Loader from '../../components/common/Loader.vue';
import NotificationModal from '../../components/NotificationModal.vue';
import ErrorModal from '../../components/ErrorModal.vue';

export default {
  name: 'PeopleList',
  components: {
    NotificationModal,
    Loader,
    Pagination,
    LiveSearch,
    Sorting,
    Button,
    ErrorModal,
  },
  data() {
    const {
      editPerson,
      disablePersonTitle,
      enablePersonTitle,
    } = CONFIG.tooltips;
    return {
      iconEdit,
      iconEnabled,
      iconDisabled,
      isModalOpen: false,
      enabledId: null,
      bodyMessage: '',
      modalTitle: '',
      isEnabled: false,
      isHover: false,
      selectedId: null,
      filter: '',
      sortedField: {
        firstName: 'firstName',
        lastName: 'lastName',
      },
      currentSortedField: 'cp.lastName',
      currentSortBy: 'ASC',
      isSortedUp: false,
      isSortedDown: false,
      disableTitle: disablePersonTitle,
      enableTitle: enablePersonTitle,
      editPerson,
      isModalErrorOpen: false,
      bodyErrorMessage: '',
    };
  },
  computed: {
    ...mapState({
      people: (state) => state.people.people,
      pagination: (state) => state.people.pagination,
      isLoading: (state) => state.people.isLoading,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    const params = {
      accountId: this.getCurrentAccountId,
      page: localStorage.getItem('peoplePage') || 1,
      filterValue: '',
    };
    this.$store.dispatch(GET_PEOPLE_REQUEST, params);
  },
  methods: {
    onErrorModalOpened() {
      this.isModalErrorOpen = true;
      this.bodyErrorMessage = CONFIG.modals.errorDisabledPerson;
    },
    onModalErrorClose() {
      this.isModalErrorOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onOpenModal(person) {
      const {
        disabledPersonMessage,
        enabledPersonMessage,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.isEnabled = person.enabled;
      this.enabledId = person.id;
      this.modalTitle = person.enabled ? 'Disable Person' : 'Enable Person';
      this.bodyMessage = person.enabled ? disabledPersonMessage : enabledPersonMessage;
    },
    changePersonStatus() {
      const personStatus = {
        enabled: !this.isEnabled,
        personId: this.enabledId,
        accountId: this.getCurrentAccountId,
      };

      this.$store.dispatch(CHANGE_PERSON_STATUS_REQUEST, personStatus)
        .then(() => {
          const params = {
            accountId: this.getCurrentAccountId,
            page: localStorage.getItem('peoplePage') || 1,
            filterValue: this.filter || '',
          };
          this.onSearch(params);
          this.$toasted.global.success({
            message: `The Person has been successfully ${this.isEnabled ? 'disabled' : 'enabled'}`,
          });
        })
        .catch((err) => {
          if (err) {
            this.onErrorModalOpened();
          }
        });
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    toCreatePersonHandler() {
      this.$router.push('people/add');
    },
    handleSortUp(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_PEOPLE_REQUEST, params);
    },
    handleSortDown(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_PEOPLE_REQUEST, params);
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        accountId: this.getCurrentAccountId,
        page: params.page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_PEOPLE_REQUEST, getParams);
    },
    getPaginationAction(page) {
      const params = {
        accountId: this.getCurrentAccountId,
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_PEOPLE_REQUEST, params);
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
  },
};
</script>

<style scoped>
  .flex {
    align-items: center;
    display: flex;
    flex: 0;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 40px;
  }

  .main-wrapper {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 25px 30px;
    width: 100%;
  }

  .system {
    color: #4c5057;
  }

  .accounts-wrapper {
    border: 1px solid #43434d;
    height: 100%;
    overflow-y: auto;
  }

  .ac-wrapper-header {
    background: #282a2e;
    display: flex;
    padding: 13px 0;
  }

  .th-name {
    align-items: center;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
  }

  .td {
    font-size: 14px;
    padding: 15px 0;
    word-break: break-word;
  }

  .number {
    padding-left: 21px;
    width: 67px;
  }

  .company {
    padding-left: 20px;
    width: 15%;
  }

  .users-count {
    padding-left: 20px;
    width: 30%;
  }

  .status {
    align-items: flex-start;
    display: flex;
    margin-left: 15px;
    width: 10%;
  }

  .account {
    display: flex;
  }

  .account:hover {
    background: #383b40;
  }

  .status-ellipse {
    border-radius: 50%;
    height: 5px;
    margin-right: 6px;
    margin-top: 6px;
    width: 5px;
  }

  .icons-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 0.9;
    justify-content: flex-end;
    padding-top: 10px;
  }

  .icon-edit {
    padding-left: 31px;
  }

  .create-button {
    background: #2591cf;
    color: #fff;
    font-size: 16px;
    letter-spacing: normal;
    padding: 0 16px;
    text-transform: none;
  }

  .right-path {
    align-items: center;
    display: flex;
  }

  .empty-search {
    padding-top: 40px;
    text-align: center;
  }

  .btn-add-mobile {
    display: none;
  }

  .svg-icon {
    cursor: pointer;
  }

  @media all and (max-width: 1160px) {
    .users-count {
      width: 20%;
    }
  }

  @media all and (max-width: 1024px) {
    .btn-add-mobile {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
      min-height: 40px;
    }

    .btn-add-full {
      display: none;
    }

    .search-wrapper {
      margin-right: 0;
    }
  }
</style>
