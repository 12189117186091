import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  SET_CANCEL_TOKEN,
} from '../../actions/peopleActions/people';
import {
  CREATE_PLAYLIST_ERROR,
  CREATE_PLAYLIST_REQUEST,
  CREATE_PLAYLIST_SUCCESS,
  DELETE_PLAYLIST_ERROR,
  DELETE_PLAYLIST_REQUEST,
  DELETE_PLAYLIST_SUCCESS,
  EDIT_PLAYLIST_ERROR,
  EDIT_PLAYLIST_REQUEST,
  EDIT_PLAYLIST_SUCCESS,
  GET_CONTENT_TYPE_ERROR,
  GET_CONTENT_TYPE_REQUEST,
  GET_CONTENT_TYPE_SUCCESS,
  GET_DYNAMIC_MEDIA_ERROR,
  GET_DYNAMIC_MEDIA_REQUEST,
  GET_DYNAMIC_MEDIA_SUCCESS,
  GET_MEDIA_TYPE_ERROR,
  GET_MEDIA_TYPE_REQUEST,
  GET_MEDIA_TYPE_SUCCESS,
  GET_PLAYLIST_ERROR,
  GET_PLAYLIST_LIST_ERROR,
  GET_PLAYLIST_LIST_REQUEST,
  GET_PLAYLIST_LIST_SUCCESS,
  GET_PLAYLIST_REQUEST,
  GET_PLAYLIST_SUCCESS,
  GET_PLAYLISTS_CMS_ERROR,
  GET_PLAYLISTS_CMS_REQUEST,
  GET_PLAYLISTS_CMS_SUCCESS,
  GET_SORT_BY_ERROR,
  GET_SORT_BY_REQUEST,
  GET_SORT_BY_SUCCESS,
  GET_SORT_ORDER_ERROR,
  GET_SORT_ORDER_REQUEST,
  GET_SORT_ORDER_SUCCESS,
} from '../../actions/playlist/playlist';

const state = {
  isLoading: false,
  playlistList: [],
  pagination: {},
  playlist: {},
  source: null,
  sortByOptions: [],
  sortOrderOptions: [],
  typesOptions: [],
  contentTypesOptions: [],
};

const actions = {
  [GET_PLAYLIST_LIST_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    let url = `${params.accountId}/media-collection/playlist?p=${params.page}&l=15&filterField=p.title&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_PLAYLIST_LIST_SUCCESS, resp.data);
        localStorage.setItem('playlist', resp.data.pagination.page);
        resolve();
      })
      .catch(() => {
        commit(GET_PLAYLIST_LIST_ERROR);
      });
  }),
  [CREATE_PLAYLIST_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(CREATE_PLAYLIST_REQUEST);

    apiCall.post(`${accountId}/media-collection/playlist`, data)
      .then((res) => {
        commit(CREATE_PLAYLIST_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_PLAYLIST_ERROR, error);
        reject(error);
      });
  }),
  [GET_PLAYLIST_REQUEST]: ({ commit }, { accountId, playlistId }) => new Promise(
    (resolve, reject) => {
      commit(GET_PLAYLIST_REQUEST);

      const url = `${accountId}/media-collection/playlist/${playlistId}`;

      apiCall.get(url)
        .then((resp) => {
          commit(GET_PLAYLIST_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(GET_PLAYLIST_ERROR, error);
          reject(error);
        });
    },
  ),
  [EDIT_PLAYLIST_REQUEST]:
    ({ commit }, { accountId, playlistId, data }) => new Promise((resolve, reject) => {
      commit(EDIT_PLAYLIST_REQUEST);

      apiCall.put(`${accountId}/media-collection/playlist/${playlistId}`, data)
        .then((resp) => {
          commit(EDIT_PLAYLIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch((err) => {
          const error = err.response.data;
          commit(EDIT_PLAYLIST_SUCCESS, error);
          reject(error);
        });
    }),
  [DELETE_PLAYLIST_REQUEST]:
    ({ commit }, { accountId, playlistId }) => new Promise((resolve, reject) => {
      commit(DELETE_PLAYLIST_REQUEST);

      apiCall.delete(`${accountId}/media-collection/playlist/${playlistId}`)
        .then((resp) => {
          commit(DELETE_PLAYLIST_SUCCESS, resp.data);
          resolve();
        })
        .catch((err) => {
          const error = err.response.data;
          commit(DELETE_PLAYLIST_ERROR, error);
          reject(error);
        });
    }),
  [GET_SORT_BY_REQUEST]: ({ commit }) => new Promise((resolve) => {
    const url = 'choice/playlist/sort-by';

    apiCall.get(url)
      .then((resp) => {
        commit(GET_SORT_BY_SUCCESS, resp.data.data);
        resolve();
      })
      .catch(() => {
        commit(GET_SORT_BY_ERROR);
      });
  }),
  [GET_SORT_ORDER_REQUEST]: ({ commit }) => new Promise((resolve) => {
    const url = 'choice/playlist/sort-direction';

    apiCall.get(url)
      .then((resp) => {
        commit(GET_SORT_ORDER_SUCCESS, resp.data.data);
        resolve();
      })
      .catch(() => {
        commit(GET_SORT_ORDER_ERROR);
      });
  }),
  [GET_MEDIA_TYPE_REQUEST]: ({ commit }) => new Promise((resolve) => {
    const url = 'choice/playlist/media-type';

    apiCall.get(url)
      .then((resp) => {
        commit(GET_MEDIA_TYPE_SUCCESS, resp.data.data);
        resolve();
      })
      .catch(() => {
        commit(GET_MEDIA_TYPE_ERROR);
      });
  }),
  [GET_CONTENT_TYPE_REQUEST]: ({ commit }) => new Promise((resolve) => {
    const url = 'choice/playlist/content-type';

    apiCall.get(url)
      .then((resp) => {
        commit(GET_CONTENT_TYPE_SUCCESS, resp.data.data);
        resolve();
      })
      .catch(() => {
        commit(GET_CONTENT_TYPE_ERROR);
      });
  }),
  [GET_DYNAMIC_MEDIA_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    let dynamicParams = '';
    if (params.mediaFolder) {
      dynamicParams += `&folders=${params.mediaFolder}`;
    }

    if (params.includeMediaTypes) {
      params.includeMediaTypes.forEach((tag) => {
        dynamicParams += `&includeMediaTypes[]=${tag}`;
      });
    }

    if (params.includeContentTypes) {
      params.includeContentTypes.forEach((tag) => {
        dynamicParams += `&includeContentTypes[]=${tag}`;
      });
    }

    if (params.isHideChildren) {
      dynamicParams += '&showChildren=0';
    }

    const url = `${params.accountId}/media-collection/item/search?p=${params.page}&l=${params.allLength}&filterField=title&s=${params.sortBy}&d=${params.sortOrder || params.sortDirection}&checkAvailability=${params.checkAvailability}${dynamicParams}`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_DYNAMIC_MEDIA_SUCCESS, resp.data);
        resolve(resp.data.data);
      }).catch((error) => {
        commit(GET_DYNAMIC_MEDIA_ERROR);
        reject(error);
      });
  }),
  [GET_PLAYLISTS_CMS_REQUEST]: ({ commit }, { accountId }) => new Promise((resolve) => {
    const url = `choice/accounts/${accountId}}/playlists`;

    apiCall.get(url)
      .then((resp) => {
        commit(GET_PLAYLISTS_CMS_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch(() => {
        commit(GET_PLAYLISTS_CMS_ERROR);
      });
  }),
};

const mutations = {
  [GET_PLAYLIST_LIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_PLAYLIST_LIST_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.playlistList = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  [CREATE_PLAYLIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_PLAYLIST_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_PLAYLIST_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_PLAYLIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_PLAYLIST_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_PLAYLIST_ERROR]: () => {
    state.isLoading = false;
  },
  [DELETE_PLAYLIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [DELETE_PLAYLIST_SUCCESS]: () => {
    state.isLoading = false;
  },
  [DELETE_PLAYLIST_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_PLAYLIST_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_PLAYLIST_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.playlist = payload;
  },
  [GET_PLAYLIST_ERROR]: () => {
    state.isLoading = false;
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
  },
  [GET_SORT_BY_SUCCESS]: (state, payload) => {
    state.sortByOptions = payload;
  },
  [GET_SORT_ORDER_SUCCESS]: (state, payload) => {
    state.sortOrderOptions = payload;
  },
  [GET_MEDIA_TYPE_SUCCESS]: (state, payload) => {
    state.typesOptions = payload;
  },
  [GET_CONTENT_TYPE_SUCCESS]: (state, payload) => {
    state.contentTypesOptions = payload;
  },
};

export default {
  state,
  actions,
  mutations,
};
