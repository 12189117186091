<template>
  <div class="wrapper">
    <p
      class="headline grey lighten-2"
      primaryTitle
    >
      Delete Media Folder
    </p>
    <div class="remove-title">
      <div v-if="modalData.hasChildren">
        This item includes additional Media Folders.
        This operation will remove these items as well.
        The media will be moved to the "Uncategorized" folder. Would you like to proceed?
      </div>
      <div v-else>
        Are you sure you want to delete "{{ modalData.name }}" folder?
      </div>
    </div>
    <div class="button-wrapper">
      <Button
        :title="'No'"
        :classType="'warning'"
        :eventFunction="onCancelHandle"
      />
      <Button
        :title="'Yes'"
        :classType="'primary'"
        :eventFunction="onDeleteHandler"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { DELETE_MEDIA_FOLDER } from '../../../../store/actions/media/mediaTree';
import CONFIG from '../../../../constants/config';
import Button from '../../../common/Button.vue';

export default {
  name: 'DeleteModalContent',
  components: {
    Button,
  },
  props: {
    modalData: {
      type: Object,
      default: null,
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  methods: {
    onDelete() {
      const { id, parent } = this.modalData;

      const data = {
        folderId: id,
        accountId: this.getCurrentAccountId,
      };
      this.$store.dispatch(DELETE_MEDIA_FOLDER, data)
        .then(() => {
          this.$toasted.global.success({
            message: CONFIG.successMessages.deleteMediaFolderMessage,
          });
          this.onCancel();

          if (Number(this.$route.params.folderId) === Number(id)) {
            this.$router.push(`/media/folder/${parent}`);
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            this.$toasted.global.error({
              message: 'This folder or its child folder is in use by Video Apps Engine',
            });
            return;
          }
          this.$toasted.global.error({
            message: CONFIG.errorMessages.commonServerError,
          });
        });
    },
    onDeleteHandler() {
      this.onDelete();
    },
    onCancelHandle() {
      this.onCancel();
    },
  },
};
</script>
<style scoped>
  .wrapper {
    background-color: #222;
    border-radius: 4px;
    display: block;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    padding: 30px;
    position: absolute;
    text-decoration: none;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .headline,
  .remove-title {
    color: #fff;
  }

  .remove-title {
    line-height: 1.375rem;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding-bottom: 30px;
    word-break: break-all;
  }

  .button-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
  }

  .button-wrapper button:first-child {
    margin-right: 15px;
  }
</style>
