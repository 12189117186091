<template>
  <div class="feed-result-preview">
    <Loader
      v-if="shouldUseLoader"
      :isVisible="isLoading"
      class="media-loader loader-overlay"
    />
    <div
      v-if="isNeedFixedItems"
      class="fixed-feeds"
    >
      <div class="fixed-feed-title">
        <div
          class="title"
          :class="itemLimitReached() ? 'error' : ''"
        >
          Fixed Items
        </div>
        <div class="flex-wrapper">
          <Button
            :title="'Add Fixed Event'"
            :classType="'primary'"
            :type="'button'"
            :eventFunction="addFixedEvent"
            :smallSize="true"
            :disabled="!addItemAllowed()"
          />
          <Button
            :title="'Add Fixed Media'"
            :classType="'primary'"
            :type="'button'"
            :eventFunction="addFixedItem"
            :smallSize="true"
            :disabled="!addItemAllowed()"
          />
        </div>
      </div>
      <SlickList
        v-model="fixedItemsLocal"
        class="list"
        :useWindowAsScrollContainer="false"
        lockAxis="y"
        :useDragHandle="true"
        @input="onSortEnd"
      >
        <div>
          <div v-if="fixedItemsLocal.length">
            <SlickItem
              v-for="(item, index) in fixedItemsLocal"
              :key="item.id"
              class="list-item"
              :index="index"
              :item="item"
            >
              <div class="feed-preview-item">
                <FeedPreviewItem
                  :key="item.id"
                  v-handle
                  :item="item"
                  :isFixedMedia="isFixedMedia"
                  :errorLiveEvents="errorLiveEvents"
                />
                <div class="icon-remove">
                  <inline-svg
                    :src="iconRemove"
                    class="svg-icon"
                    @click="() => onRemoveFixedItem(index)"
                  >
                  </inline-svg>
                </div>
              </div>
            </SlickItem>
          </div>
          <div
            v-else
            class="empty-search"
          >
            No items to display
          </div>
        </div>
      </SlickList>
    </div>
    <div class="dynamic-feeds">
      <div class="title">
        {{ titleComputed }}
      </div>
      <div
        ref="dynamicFeeds"
        :class="['dynamic-feeds-list', !isNeedFixedItems && 'dynamic-feeds-list-playlist']"
      >
        <div v-if="mediaItems && mediaItems.length && isDynamicContent">
          <div
            v-for="item in mediaItems"
            :key="item.id"
          >
            <FeedPreviewItem :item="item" />
          </div>
        </div>
        <div
          v-else
          class="empty-search"
        >
          No media to display
        </div>
      </div>
    </div>
    <div v-if="showModal">
      <FeedModal
        :accountId="getCurrentAccountId"
        :onClose="handleModalClose"
        :selectedItems="fixedItemsLocal"
        :onSelectFixedItem="handleSelectFixedItem"
        :addItemAllowed="addItemAllowed()"
        :isFixedMedia="isFixedMedia"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort';
import FeedPreviewItem from './FeedPreviewItem.vue';
import Button from '../common/Button.vue';
import FeedModal from './FeedModal.vue';
import Loader from '../common/Loader.vue';
import iconRemove from '../../assets/icons/icon-remove-target.svg';

export default {
  name: 'FeedResultsPreview',
  components: {
    FeedPreviewItem,
    Button,
    FeedModal,
    SlickItem,
    SlickList,
    Loader,
  },
  directives: { handle: HandleDirective },
  props: {
    mediaItems: {
      type: Array,
      default: () => [],
    },
    fixedItems: {
      type: Array,
      default: () => [],
    },
    getMediaItems: {
      type: Function,
      default: () => {},
    },
    onSelectFixedItem: {
      type: Function,
      default: () => {},
    },
    onChangeFixedItem: {
      type: Function,
      default: () => {},
    },
    onRemoveFixedItem: {
      type: Function,
      default: () => {},
    },
    isDynamicContent: {
      type: Boolean,
      default: false,
    },
    isNeedFixedItems: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Number,
      default: 1,
    },
    dynamicContentLimit: {
      type: Number,
      default: 1,
    },
    defaultLength: {
      type: Number,
      default: 1,
    },
    errorLiveEvents: {
      type: Array,
      default: () => [],
    },
    shouldUseLoader: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadedPage: 1,
      showModal: false,
      fixedItemsLocal: this.fixedItems,
      maxFixedItems: 100,
      iconRemove,
      isFixedMedia: false,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    titleComputed() {
      return this.title || `Dynamic Items ${(!this.isNeedFixedItems ? '/ Episodes' : '')}`;
    },
  },
  mounted() {
    const listElm = this.$refs.dynamicFeeds;
    listElm.addEventListener('scroll', this.getItems);
  },
  beforeDestroy() {
    const listElm = this.$refs.dynamicFeeds;
    listElm.removeEventListener('scroll', this.getItems);
  },
  methods: {
    onSortEnd(newCollection) {
      this.onChangeFixedItem(newCollection);
    },
    handleModalClose() {
      this.showModal = false;
    },
    getItems() {
      const listElm = this.$refs.dynamicFeeds;
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.loadedPage += 1;
        const dynamicContentLimit = Number(this.dynamicContentLimit);

        let maxPage = parseInt(dynamicContentLimit / this.defaultLength, 10);
        if (dynamicContentLimit % this.defaultLength > 0) {
          maxPage += 1;
        }

        if (this.pagination < dynamicContentLimit) {
          maxPage = parseInt(this.pagination / this.defaultLength, 10);
          if (this.pagination % this.defaultLength > 0) {
            maxPage += 1;
          }
        }

        if (maxPage >= this.loadedPage) {
          this.getMediaItems(this.loadedPage);
        }
      }
    },
    addFixedItem() {
      this.isFixedMedia = true;
      this.showModal = true;
    },
    addFixedEvent() {
      this.isFixedMedia = false;
      this.showModal = true;
    },
    handleSelectFixedItem(item) {
      this.onSelectFixedItem(item);
      if (this.isDynamicContent) {
        this.getMediaItems(1);
      }
    },
    addItemAllowed() {
      return this.maxFixedItems > this.fixedItemsLocal.length;
    },
    itemLimitReached() {
      return this.maxFixedItems < this.fixedItemsLocal.length;
    },
  },
};
</script>
<style scoped>
  .feed-result-preview {
    position: relative;
  }
  .dynamic-feeds,
  .fixed-feeds {
    padding: 10px 20px;
  }

  .dynamic-feeds-list {
    height: calc(100vh - 450px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .dynamic-feeds-list-playlist {
    height: calc(100vh - 340px);
  }

  .list {
    max-height: 195px;
    min-height: 195px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .title {
    margin-bottom: 12px;
  }

  .title.error {
    border-bottom: 1px solid #fb3951;
    color: #fb3951;
  }

  .empty-search {
    color: #4c5057;
    padding-top: 40px;
    text-align: center;
  }

  .fixed-feed-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .fixed-feed-title .title {
    margin-bottom: 0;
    margin-top: 10px;
  }

  .feed-preview-item {
    display: flex;
    justify-content: space-between;
  }

  .icon-remove {
    margin-right: 10px;
    margin-top: 10px;
    text-align: right;
  }

  .flex-wrapper {
    display: flex;
  }

  @media all and (max-width: 1200px) {
    .dynamic-feeds-list {
      height: calc(100vh - 380px);
    }
  }
</style>
