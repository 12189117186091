<template>
  <div class="overlay">
    <div :class="['wrapper', type === 'facebook' ? 'facebook-modal' : '']">
      <p class="headline grey lighten-2">
        {{ title }}
      </p>
      <div class="add-input">
        <MaterialSelect
          v-model="type"
          v-bind="formFields.type"
          :options="typeList"
          :withEmptyField="false"
          :handleChange="handleChangeType"
        />
        <div
          v-if="type === 'custom'"
          :key="type"
        >
          <CustomRTMPForm
            ref="customRTMPForm"
            :editDeliveryTarget="editDeliveryTarget"
            :onCancel="onCancel"
            :onSubmit="handleSubmit"
            :itemErrors="itemErrors"
          />
        </div>
        <div
          v-if="type === 'youtube'"
          :key="type"
        >
          <YoutubeRTMPForm
            ref="youtubeRTMPForm"
            :editDeliveryTarget="editDeliveryTarget"
            :onCancel="onCancel"
            :onSubmit="handleSubmit"
            :itemErrors="itemErrors"
          />
        </div>
        <div v-if="type === 'facebook'">
          <FacebookDeliveryTargetForm
            ref="facebookDeliveryTargetForm"
            :editDeliveryTarget="editDeliveryTarget"
            :onCancel="onCancel"
            :onSubmit="handleSubmit"
            :itemErrors="itemErrors"
            :fbEditData="fbEditData"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import CustomRTMPForm from '../../forms/deliveryTargets/CustomRTMPForm.vue';
import YoutubeRTMPForm from '../../forms/deliveryTargets/YoutubeRTMPForm.vue';
import FacebookDeliveryTargetForm from '../../forms/deliveryTargets/FacebookDeliveryTargetForm.vue';
import CONFIG from '../../../constants/config';

export default {
  name: 'AddDeliveryTarget',
  components: {
    YoutubeRTMPForm,
    MaterialSelect,
    CustomRTMPForm,
    FacebookDeliveryTargetForm,
  },
  formPrefix: {
    type: String,
    default: 'addDeliveryTarget',
  },
  props: {
    onCancel: {
      type: Function,
      default: () => {},
    },
    editDeliveryTarget: {
      type: Object,
      default: null,
    },
    handleModalSubmit: {
      type: Function,
      default: () => {},
    },
    onEditDeliveryTargetChange: {
      type: Function,
      default: () => {},
    },
    itemErrors: {
      type: Object,
      default: null,
    },
    fbEditData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      title: 'Add New Target',
      typeList: CONFIG.deliveryTargetTypes,
      type: CONFIG.deliveryTargetTypes[0].code,
      formFields: {
        type: {
          type: 'text',
          name: 'Delivery Target Type',
          formPrefix: this.formPrefix,
        },
      },
    };
  },
  created() {
    if (this.editDeliveryTarget) {
      this.type = this.editDeliveryTarget.type;
      this.title = 'Edit Target';
    }
  },
  methods: {
    handleSubmit(item) {
      const result = item;
      result.type = this.type;
      return this.handleModalSubmit(result);
    },
    handleChangeType() {
      const item = this.editDeliveryTarget && this.editDeliveryTarget.id
        ? { id: this.editDeliveryTarget.id }
        : null;

      this.onEditDeliveryTargetChange(item);
    },
    handleErrorResponse(error) {
      let form;
      switch (this.type) {
      case 'custom':
        form = this.$refs.customRTMPForm;

        break;
      case 'youtube':
        form = this.$refs.youtubeRTMPForm;

        break;
      case 'facebook':
        form = this.$refs.facebookDeliveryTargetForm;

        break;
      default:
        form = null;

        break;
      }

      if (form) {
        form.onSubmitFail(error);
      } else {
        this.$toasted.global.error({
          message: CONFIG.errorMessages.commonServerError,
        });
      }
    },
  },
};
</script>
<style scoped>
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .wrapper {
    background-color: #222;
    border-radius: 4px;
    display: block;
    left: 50%;
    margin: 20px 0;
    max-height: 90%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    overflow-y: auto;
    padding: 30px;
    position: absolute;
    text-decoration: none;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .facebook-modal {
    top: 50%;
  }

  .headline,
  .add-input {
    color: #fff;
  }

  .add-input {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    padding-bottom: 30px;
    word-break: break-all;
  }

  @media all and (max-width: 1024px) {
    .wrapper {
      top: 53%;
    }
  }
</style>
