<template>
  <div class="form-container">
    <Loader :isVisible="isLoading" />
    <div class="main wrapper">
      <div
        class="main-form-container"
        :style="disabledStyle"
      >
        <ProfileForm
          :disabled="disabled"
          :timezones="timezones"
        />
        <ChangePasswordForm
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { GET_TIMEZONE_REQUEST } from '../../store/actions/timezoneActions/timezone';
import { SET_EDIT_FORM } from '../../store/actions/common';

import Loader from '../../components/common/Loader.vue';
import ProfileForm from '../../components/forms/ProfileForm.vue';
import ChangePasswordForm from '../../components/forms/ChangePasswordForm.vue';
import CONFIG from '../../constants/config';

export default {
  name: 'Profile',
  components: {
    ChangePasswordForm,
    ProfileForm,
    Loader,
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.common.profile,
      isLoading: (state) => state.user.isLoading,
      timezones: (state) => state.timezone.times,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
    disabled() {
      return !this.currentUser.enabled;
    },
    disabledStyle() {
      if (this.disabled) {
        return {
          'max-width': '640px',
        };
      }

      return null;
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditedForm) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(CONFIG.confirmationLeaveMessage)) {
        this.$router.push(this.$route.path);
        return;
      }
      this.$store.dispatch(SET_EDIT_FORM, false);
    }
    next();
  },
  created() {
    this.$store.dispatch(GET_TIMEZONE_REQUEST);
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
  },
  methods: {
    preventNav(event) {
      if (this.isEditedForm) {
        event.returnValue = CONFIG.confirmationLeaveMessage;
      }
    },
  },
};
</script>

<style scoped>
  .form-container {
    overflow-y: auto;
    width: 100%;
  }

  .profile-password-form {
    max-width: 640px;
  }
</style>
