import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  ADD_CUSTOM_FIELD_REQUEST,
  ADD_CUSTOM_FIELD_SUCCESS,
  ADD_CUSTOM_FIELD_ERROR,
  GET_CUSTOM_FIELD_REQUEST,
  GET_CUSTOM_FIELD_SUCCESS,
  GET_CUSTOM_FIELD_ERROR,
  GET_CUSTOM_FIELDS_REQUEST,
  GET_CUSTOM_FIELDS_SUCCESS,
  GET_CUSTOM_FIELDS_ERROR,
  EDIT_CUSTOM_FIELD_REQUEST,
  EDIT_CUSTOM_FIELD_SUCCESS,
  EDIT_CUSTOM_FIELD_ERROR,
  CHANGE_CUSTOM_FIELD_STATUS_REQUEST,
  CHANGE_CUSTOM_FIELD_STATUS_SUCCESS,
  CHANGE_CUSTOM_FIELD_STATUS_ERROR,
  GET_CUSTOM_FIELD_TYPES_REQUEST,
  GET_CUSTOM_FIELD_TYPES_SUCCESS,
  GET_CUSTOM_FIELD_TYPES_ERROR,
  GET_CUSTOM_FIELD_CONTEXTS_REQUEST,
  GET_CUSTOM_FIELD_CONTEXTS_SUCCESS,
  GET_CUSTOM_FIELD_CONTEXTS_ERROR,
  GET_CUSTOM_FIELD_OPTIONS_REQUEST,
  GET_CUSTOM_FIELD_OPTIONS_SUCCESS,
  GET_CUSTOM_FIELD_OPTIONS_ERROR,
  SET_CANCEL_TOKEN,
} from '../../actions/fields/field';

const state = {
  isLoading: false,
  fields: [],
  entryTypes: [],
  contexts: [],
  pagination: {},
  field: {},
  source: null,
};

const actions = {
  [GET_CUSTOM_FIELDS_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    commit(GET_CUSTOM_FIELDS_REQUEST);

    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    let url = `${params.accountId}/custom-field/list?p=${params.page}&l=15&filterField=cf.name&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_CUSTOM_FIELDS_SUCCESS, resp.data);
        localStorage.setItem('fieldsPage', resp.data.pagination.page);
        resolve();
      })
      .catch(() => {
        commit(GET_CUSTOM_FIELDS_ERROR);
      });
  }),
  [ADD_CUSTOM_FIELD_REQUEST]:
  ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(ADD_CUSTOM_FIELD_REQUEST);

    apiCall.post(`${accountId}/custom-field`, data)
      .then((res) => {
        commit(ADD_CUSTOM_FIELD_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_CUSTOM_FIELD_ERROR, error);
        reject(error);
      });
  }),
  [GET_CUSTOM_FIELD_REQUEST]:
  ({ commit }, { accountId, fieldId }) => new Promise((resolve, reject) => {
    commit(GET_CUSTOM_FIELD_REQUEST);

    apiCall.get(`${accountId}/custom-field/${fieldId}`)
      .then((resp) => {
        commit(GET_CUSTOM_FIELD_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_CUSTOM_FIELD_ERROR, error);
        reject(error);
      });
  }),
  [EDIT_CUSTOM_FIELD_REQUEST]:
  ({ commit }, { accountId, fieldId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_CUSTOM_FIELD_REQUEST);

    apiCall.put(`${accountId}/custom-field/${fieldId}`, data)
      .then((resp) => {
        commit(EDIT_CUSTOM_FIELD_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_CUSTOM_FIELD_ERROR, error);
        reject(error);
      });
  }),
  [CHANGE_CUSTOM_FIELD_STATUS_REQUEST]:
  ({ commit }, { accountId, fieldId, enabled }) => new Promise((resolve, reject) => {
    commit(CHANGE_CUSTOM_FIELD_STATUS_REQUEST);

    apiCall.put(`${accountId}/custom-field/${fieldId}/status`, { enabled })
      .then((res) => {
        commit(CHANGE_CUSTOM_FIELD_STATUS_SUCCESS);
        resolve(res.data);
      })
      .catch((err) => {
        commit(CHANGE_CUSTOM_FIELD_STATUS_ERROR, err);
        reject(err);
      });
  }),
  [GET_CUSTOM_FIELD_TYPES_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_CUSTOM_FIELD_TYPES_REQUEST);

    apiCall.get('choice/field-entry-type')
      .then((resp) => {
        commit(GET_CUSTOM_FIELD_TYPES_SUCCESS, resp.data.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_CUSTOM_FIELD_TYPES_ERROR, error);
        reject(error);
      });
  }),
  [GET_CUSTOM_FIELD_CONTEXTS_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
    commit(GET_CUSTOM_FIELD_CONTEXTS_REQUEST);

    apiCall.get('choice/field-context')
      .then((resp) => {
        commit(GET_CUSTOM_FIELD_CONTEXTS_SUCCESS, resp.data.data);
        resolve();
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_CUSTOM_FIELD_CONTEXTS_ERROR, error);
        reject(error);
      });
  }),
  [GET_CUSTOM_FIELD_OPTIONS_REQUEST]: ({ commit, dispatch }) => new Promise((resolve, reject) => {
    commit(GET_CUSTOM_FIELD_OPTIONS_REQUEST);

    const entryTypeRequest = dispatch(GET_CUSTOM_FIELD_TYPES_REQUEST);
    const contextRequest = dispatch(GET_CUSTOM_FIELD_CONTEXTS_REQUEST);

    Promise.all([entryTypeRequest, contextRequest])
      .then(() => {
        commit(GET_CUSTOM_FIELD_OPTIONS_SUCCESS);
        resolve();
      })
      .catch(() => {
        commit(GET_CUSTOM_FIELD_OPTIONS_ERROR);
        reject();
      });
  }),
};

const mutations = {
  [GET_CUSTOM_FIELDS_REQUEST]: () => {
  },
  [GET_CUSTOM_FIELDS_SUCCESS]: (state, payload) => {
    state.fields = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  [GET_CUSTOM_FIELDS_ERROR]: () => {
    state.isLoading = false;
  },
  [ADD_CUSTOM_FIELD_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_CUSTOM_FIELD_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_CUSTOM_FIELD_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_CUSTOM_FIELD_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_CUSTOM_FIELD_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_CUSTOM_FIELD_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_CUSTOM_FIELD_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_CUSTOM_FIELD_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.field = payload;
  },
  [GET_CUSTOM_FIELD_ERROR]: () => {
    state.isLoading = false;
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
  },
  [EDIT_CUSTOM_FIELD_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_CUSTOM_FIELD_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_CUSTOM_FIELD_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_CUSTOM_FIELD_TYPES_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_CUSTOM_FIELD_TYPES_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.entryTypes = payload;
  },
  [GET_CUSTOM_FIELD_TYPES_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_CUSTOM_FIELD_CONTEXTS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_CUSTOM_FIELD_CONTEXTS_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.contexts = payload;
  },
  [GET_CUSTOM_FIELD_CONTEXTS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_CUSTOM_FIELD_OPTIONS_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_CUSTOM_FIELD_OPTIONS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_CUSTOM_FIELD_OPTIONS_ERROR]: () => {
    state.isLoading = false;
  },
  [CHANGE_CUSTOM_FIELD_STATUS_REQUEST]: () => {
    state.isLoading = true;
  },
  [CHANGE_CUSTOM_FIELD_STATUS_SUCCESS]: (state) => {
    state.isLoading = false;
  },
  [CHANGE_CUSTOM_FIELD_STATUS_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
