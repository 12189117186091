<!-- eslint-disable vue/valid-v-model -->
<template>
  <div>
    <div class="contact-info">
      <div class="inline-row">
        <MaterialInput
          v-model="formData.title"
          v-bind="formFields.title"
          :requiredFromBack="true"
        />
        <MaterialInput
          v-model="formData.shortDescription"
          v-bind="formFields.shortDescription"
        />
        <MaterialTextArea
          v-model="formData.longDescription"
          v-bind="formFields.longDescription"
        />
        <div class="categories-management-wrapper">
          <CategoriesManagement
            v-if="isCategoryManagementAvailabe"
            label="Select Categories"
            :selectedValue="formData.categories"
            :onChange="handleCategoryChange"
          />
        </div>
        <div class="genres-management-wrapper">
          <GenresManagement
            v-if="isGenreManagementAvailabe"
            label="Select Genres"
            :selectedValue="formData.genres"
            :onChange="handleGenreChange"
          />
        </div>
        <div
          v-if="isNeedTitleImg"
          class="wrapper-small"
        >
          <div class="poster-title">
            Title Image
          </div>
          <TitleSeriesImage
            ref="posterImage"
            :poster="formData.titleImg"
            :onPosterChange="handleTitleChange"
            :handleRemove="handleRemoveTitleImg"
            :externalErrorMessage="titleImageError"
          />
        </div>
        <div class="wrapper-small">
          <div class="poster-title">
            Poster Image (16:9)
          </div>
          <PosterImage
            ref="posterImage"
            :poster="formData.image"
            :onPosterChange="handlePosterChange"
            :typeOfPerson="'small'"
            :handleRemove="handleRemovePoster"
          />
        </div>
        <div class="wrapper-small">
          <div class="poster-title">
            Poster Image (2:3)
          </div>
          <PosterImage
            ref="portraitImage"
            :isPoster2x3="true"
            :poster="formData.portraitImage"
            :onPosterChange="handleChangePortraitImage"
            :typeOfPerson="'small'"
            :handleRemove="handleRemovePortraitImage"
            class="poster-2x3-image"
          />
        </div>
        <div class="wrapper-small">
          <div class="poster-title">
            Background Image
          </div>
          <PosterImage
            ref="posterImage"
            :poster="formData.backgroundImage"
            :onPosterChange="handlePosterBackChange"
            :typeOfPerson="'large'"
            :handleRemove="handleRemove"
            :imageType="backgroundImg"
          />
        </div>
        <div>
          <div class="localization-btn">
            <Button
              :title="'Localization'"
              :classType="'primary'"
              :className="'wide-btn'"
              :srcSvg="iconFolderLocalization"
              :svgClass="'folder-localization-icon'"
              :eventFunction="toMoreDetails"
            />
          </div>
        </div>
        <div
          v-if="hasSortedFields()"
          class="custom-field-wrapper"
        >
          <div class="custom-field-title">
            Custom fields
          </div>
          <FieldValueRow
            v-for="item in getSortedFields()"
            :key="`fieldValue${item.field.id}`"
            v-model="item.fieldValue"
            :field="item.field"
            :fieldName="getFieldName(item.field)"
            :formPrefix="formPrefix"
          />
        </div>
      </div>
    </div>
    <FolderLocalization
      v-if="isFolderOpen"
      :metadata="formData"
      :type="type"
      :onClose="onCloseLocalizationModal"
    />
  </div>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import { GET_LANGUAGES_REQUEST } from '../../store/actions/localization/localizationActions';
import CONFIG from '../../constants/config';

import Button from '../common/Button.vue';
import BaseForm from './BaseForm.vue';
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialTextArea from '../inputs/MaterialTextArea.vue';
import PosterImage from '../common/PosterImage/DragAndDropFileUploader.vue';
import TitleSeriesImage from '../common/PosterImage/TitleSeriesImage.vue';
import CustomFieldFormMixin from './customFields/CustomFieldFormMixin.vue';
import iconFolderLocalization from '../../assets/icons/icon-folder-localization.svg';
import FolderLocalization from '../localization/FolderLocalization.vue';
import iconWarning from '../../assets/icons/icon-warning-localization.svg';
import CategoriesManagement from '../inputs/CategoriesManagement.vue';
import GenresManagement from '../inputs/GenresManagement.vue';

export default {
  name: 'MetaDataForm',
  components: {
    FolderLocalization,
    MaterialInput,
    MaterialTextArea,
    Button,
    PosterImage,
    TitleSeriesImage,
    CategoriesManagement,
    GenresManagement,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    folderName: {
      type: String,
      default: '',
    },
    modal: {
      type: Boolean,
      default: false,
    },
    formPrefix: {
      type: String,
      default: 'metadata',
    },
    folderId: {
      type: Number,
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
    handleRemovePoster: {
      type: Function,
      default: () => {},
      required: false,
    },
    handleRemoveTitleImg: {
      type: Function,
      default: () => {},
      required: false,
    },
    handleRemove: {
      type: Function,
      default: () => {},
      required: false,
    },
    handlePosterChange: {
      type: Function,
      default: () => {},
      required: false,
    },
    handleTitleChange: {
      type: Function,
      default: () => {},
      required: false,
    },
    handlePosterBackChange: {
      type: Function,
      default: () => {},
      required: false,
    },
    handleCategoryChange: {
      type: Function,
      default: () => {},
      required: false,
    },
    handleRemovePortraitImage: {
      type: Function,
      default: () => {},
      required: false,
    },
    handleChangePortraitImage: {
      type: Function,
      default: () => {},
      required: false,
    },
    handleGenreChange: {
      type: Function,
      default: () => {},
      required: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    formFields: {
      type: Object,
      default: () => {},
    },
    titleImageError: {
      type: String,
      default: '',
    },
    isPosterSmallChanged: {
      type: Boolean,
      default: false,
    },
    isPosterLargeChanged: {
      type: Boolean,
      default: false,
    },
    isPosterTitleChanged: {
      type: Boolean,
      default: false,
    },
    isNeedTitleImg: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
    },
    isCategoryManagementAvailabe: {
      type: Boolean,
      default: false,
    },
    isGenreManagementAvailabe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconWarning,
      iconFolderLocalization,
      isFolderOpen: false,
      bodyMessage: CONFIG.modals.deleteMetaConfirmMessage,
      isModalOpen: false,
      backgroundImg: 'background image',
      enabledLanguages: [],
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  mounted() {
    const params = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };
    this.$store.dispatch(GET_LANGUAGES_REQUEST, params).then((res) => {
      this.enabledLanguages = res.data;
    });
    this.setCustomFields(this.formData.customFields);
  },
  methods: {
    toMoreDetails() {
      this.isFolderOpen = true;
    },
    onCloseLocalizationModal() {
      this.isFolderOpen = false;
    },
  },
};
</script>

<style scoped>
  .person p:last-child {
    background: #383b40;
    margin-top: 10px;
    padding: 15px;
  }

  .poster-title {
    color: #fff;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .wrapper-small {
    margin-bottom: 20px;
  }

  hr {
    border: 0 solid rgba(0, 0, 0, 0.12);
    border-top-width: thin;
    display: block;
    flex: 1 1 0;
    height: 0;
    max-height: 0;
    max-width: 100%;
    transition: inherit;
  }

  .custom-field-wrapper {
    margin-top: 20px;
    overflow-wrap: initial;
    white-space: initial;
  }

  .custom-field-title {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .wide-btn {
    width: 100%;
    margin-left: 0;
  }

  .categories-management-wrapper {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .poster-2x3-image{
    height: 400px;
  }

  .genres-management-wrapper {
    margin-bottom: 20px;
    margin-top: 20px;
  }
</style>
