<template>
  <div
    v-if="isOpen"
    class="overlay"
  >
    <div class="wrapper">
      <div class="headline">
        Stream Credentials
      </div>
      <div class="wowza">
        <MaterialInput
          v-model="credentials.url"
          :allowCopy="true"
          :label="'Streaming URL/RTMP Address:'"
          :disabled="true"
        />
      </div>
      <div class="wowza">
        <MaterialInput
          v-model="credentials.streamName"
          :allowCopy="true"
          :label="'Delivery Event Name:'"
          :disabled="true"
        />
      </div>
      <div class="wowza">
        <MaterialInput
          v-model="credentials.username"
          :allowCopy="true"
          :label="'RTMP User Name:'"
          :disabled="true"
        />
      </div>
      <div class="wowza">
        <MaterialInput
          v-model="credentials.password"
          :allowCopy="true"
          :label="'RTMP Password:'"
          :disabled="true"
        />
      </div>
      <div class="button-wrapper">
        <Button
          :title="'Done'"
          :classType="'primary'"
          :type="'submit'"
          :eventFunction="close"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from './common/Button.vue';
import MaterialInput from './inputs/MaterialInput.vue';

export default {
  name: 'WowzaCredentials',
  components: {
    Button,
    MaterialInput,
  },
  props: {
    credentials: {
      type: Object,
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    handleClose: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    close() {
      this.handleClose();
    },
  },
};
</script>

<style scoped>
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .wrapper {
    background-color: #222;
    border-radius: 4px;
    display: block;
    left: 50%;
    max-width: 100%;
    outline: none;
    overflow-wrap: break-word;
    position: absolute;
    text-decoration: none;
    top: 45%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 630px;
  }

  .wowza {
    margin-bottom: 20px;
  }

  .wowza p:last-child {
    background: #383b40;
    margin-top: 10px;
  }

  .headline {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    margin: 0;
    word-break: break-all;
    padding-bottom: 30px;
  }

  .button-wrapper {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
</style>
