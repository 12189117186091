<template>
  <form
    class="contact-info"
    novalidate="novalidate"
    @input="handleFormChange"
  >
    <Loader />
    <div class="title modal">
      Add Banner
    </div>
    <div>
      <MaterialSelect
        v-model="formData.name"
        v-bind="formFields.name"
        :options="bannersOption"
        :withEmptyField="false"
        :disabled="bannersOption.length === 1
          && bannersOption[0].code === 'No-items'"
      />
    </div>
    <div :class="[isEdit ? 'edit-button-wrapper' : 'end-buttons']">
      <div style="display: flex">
        <Button
          :title="'Cancel'"
          :classType="'warning'"
          :eventFunction="backToManually"
        />
        <Button
          :title="'Apply'"
          :classType="'primary'"
          :eventFunction="handleAddBanner"
          :disabled="(bannersOption.length === 1
            && bannersOption[0].code === 'No-items') || !formData.name"
        />
      </div>
    </div>
  </form>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import Button from '../../common/Button.vue';
import CustomFieldFormMixin from '../customFields/CustomFieldFormMixin.vue';
import MaterialSelect from '../../inputs/MaterialSelect.vue';
import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';
import iconWarning from '../../../assets/icons/icon-warning-localization.svg';
import Loader from '../../common/Loader.vue';

import {
  GET_BANNERS_LIST_SLIDER_REQUEST,
} from '../../../store/actions/cmsActions/cmsActions';
import {
  SET_EDIT_FORM,
  SET_OVERFLOW,
} from '../../../store/actions/common';

export default {
  name: 'AddBannerBySliderForm',
  components: {
    Loader,
    MaterialSelect,
    Button,
  },
  extends: BaseForm,
  mixins: [
    CustomFieldFormMixin,
  ],
  props: {
    formPrefix: {
      type: String,
      default: 'sliderForm',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    openedLocalization: {
      type: Function,
      default: () => {},
    },
    onAccept: {
      type: Function,
      default: () => {},
    },
    isEdItItem: {
      type: Object,
      default: () => {},
    },
    banners: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iconWarning,
      iconFolderLocalization,
      isModalOpen: false,
      bodyMessage: CONFIG.modals.deleteManuallyRowInCms,
      bannersOption: [],
      isLoading: false,
      isVisible: true,
      formData: {
        name: '',
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      successMessage: 'The Slider has been successfully added',
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
    ]),
  },
  created() {
    this.isLoading = true;
    const params = {
      accountId: this.getCurrentAccountId,
    };
    this.$store.dispatch(GET_BANNERS_LIST_SLIDER_REQUEST, params).then((res) => {
      this.bannersOption = res.data.map((item) => ({
        code: {
          id: item.id,
          name: item.name,
          type: item.type,
        },
        name: item.name,
      }));
      this.bannersOption = this.bannersOption
        .filter((banner) => !this.banners.some((ban) => ban.id === banner.code.id));

      if (!this.bannersOption.length) {
        this.bannersOption = [{
          code: 'No-items',
          name: 'No items to display',
        }];
        this.formData.name = this.bannersOption[0].code;
      }
      this.isLoading = false;
    });
  },
  methods: {
    handleAddBanner() {
      this.onAccept(this.formData.name);
      this.$toasted.global.success({
        message: 'Banner has been successfully selected',
      });
      this.onClose();
    },
    backToManually() {
      this.onClose();
    },
    onManuallyClose() {
      this.isModalOpen = false;
    },
    onOpenModal() {
      this.isModalOpen = true;
      this.$store.dispatch(SET_OVERFLOW, true);
    },
    onDeleteClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
  },
};
</script>

<style scoped>

.rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.toggles-wrapper {
  display: flex;
}

.toggle-wrapper,
.date-wrapper {
  align-items: center;
  display: flex;
  margin-right: 30px;
  width: 17vw;
}

.toggle-wrapper {
  width: auto;
}

.toggle-title {
  margin-right: 10px;
}

.dynamic {
  margin-top: 30px;
  margin-bottom: 30px;
}

.edit-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.end-buttons {
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

.country-field {
  width: 300px;
}

.country-field:not(:last-child) {
  margin-right: 20px;
}

.wowza p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.wrapper-small {
  margin-top: 20px;
}

.flex {
  justify-content: space-between;
}

.person {
  margin-bottom: 20px;
}

.person p:last-child {
  background: #383b40;
  margin-top: 10px;
  padding: 15px;
}

.select-wrapper {
  width: 300px;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-left: 2px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.localization-warning {
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}

.modal {
  color: inherit;
  margin-bottom: 30px;
}

@media all and (max-width: 1320px) {
  .flex {
    flex-wrap: wrap;
  }

  .country-field {
    margin-right: 0;
    width: 100%;
  }

  .country-field:not(:last-child) {
    margin-right: 0;
  }
}
</style>
