<template>
  <div>
    <div class="value-name-role">
      <MaterialInput
        v-model="valueModel"
        v-bind="formFields.value"
        :rules="valueRules"
        :disabled="valuesDisabled || disabled"
        class="row-item"
      />
      <MaterialInput
        v-model="nameModel"
        v-bind="formFields.name"
        class="row-item"
        :disabled="disabled"
      />
      <MaterialCheckbox
        :toggle="toggleAnchored"
        :label="'anchored'"
        :checked="anchored"
        class="row-item"
        :isDisabled="disabled"
      />
      <div class="trash-btn">
        <Button
          :title="''"
          :classType="'primary'"
          :eventFunction="handleRemoveItem"
          :srcSvg="iconTrash"
          :smallSize="true"
          :className="'small-icon icon-role'"
          :disabled="buttonsDisabled || disabled"
        />
      </div>
    </div>
  </div>
</template>
<script>
import MaterialInput from '../inputs/MaterialInput.vue';
import MaterialCheckbox from '../inputs/MaterialCheckbox.vue';
import Button from '../common/Button.vue';
import iconTrash from '../../assets/icons/icon-remove.svg';

export default {
  name: 'FieldSelectionRow',
  components: {
    MaterialCheckbox,
    MaterialInput,
    Button,
  },
  props: {
    formPrefix: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    anchored: {
      type: Boolean,
      default: false,
    },
    buttonsDisabled: {
      type: Boolean,
      default: false,
    },
    valuesDisabled: {
      type: Boolean,
      default: false,
    },
    valuesNumeric: {
      type: Boolean,
      default: false,
    },
    onRemoveItem: {
      type: Function,
      default: () => {},
    },
    onChangeValue: {
      type: Function,
      default: () => {},
    },
    onChangeName: {
      type: Function,
      default: () => {},
    },
    onChangeAnchored: {
      type: Function,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconTrash,
      formFields: {
        value: {
          type: 'text',
          name: `selections.${this.index}.value`,
          label: 'Value',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        name: {
          type: 'text',
          name: `selections.${this.index}.name`,
          label: 'Label',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        anchored: {
          type: 'text',
          name: `customField.${this.index}.anchored`,
          label: 'Anchored',
          formPrefix: this.formPrefix,
        },
      },
    };
  },
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.onChangeValue(this.index, value);
      },
    },
    nameModel: {
      get() {
        return this.name;
      },
      set(value) {
        this.onChangeName(this.index, value);
      },
    },
    valueRules() {
      const rules = { ...this.formFields.value.rules };

      if (this.valuesNumeric) {
        rules.numeric = true;
      }

      return rules;
    },
  },
  methods: {
    handleRemoveItem() {
      this.onRemoveItem(this.index);
    },
    toggleAnchored(isChecked) {
      if (isChecked !== this.anchored) {
        this.onChangeAnchored(this.index, isChecked);
      }
    },
  },
};
</script>
<style scoped>
  .value-name-role {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
