<template>
  <div class="form-container">
    <MaterialInput
      v-model="media.name"
      v-bind="media.name"
      :label="'Program Title'"
      :disabled="true"
    />
    <MaterialTextArea
      v-model="media.description"
      v-bind="media.description"
      :label="'Program Description'"
      :disabled="true"
    />
    <div class="poster-wrapper">
      <div class="poster-title">
        Poster Image (2:3)
      </div>
      <PosterImage
        ref="posterImage"
        :poster="media.posterImage2x3
          ? `${media.posterImage2x3}?height=360`
          : ''
        "
        :isNeedRemove="false"
        :disabled="true"
        :disabledText="'No Image to Display'"
        :isPoster2x3="true"
        :shouldHideTitle="true"
        class="poster-2x3-image"
      />
    </div>
    <div class="poster-title">
      Poster Image (16:9)
    </div>
    <PosterImage
      ref="posterImage"
      :poster="media.posterImage
        ? `${media.posterImage}?height=360`
        : ''
      "
      :isNeedRemove="false"
      :disabled="true"
      :disabledText="'No Image to Display'"
      :shouldHideTitle="true"
    />
  </div>
</template>

<script>
import MaterialInput from '../../inputs/MaterialInput.vue';
import MaterialTextArea from '../../inputs/MaterialTextArea.vue';
import PosterImage from '../../common/PosterImage/DragAndDropFileUploader.vue';

export default {
  name: 'MediaEngForm',
  components: {
    MaterialInput,
    MaterialTextArea,
    PosterImage,
  },
  props: {
    media: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss">
.form-container {
  padding: 32px 70px 20px 0;
}

.poster-wrapper {
  margin-bottom: 30px;
}

.poster-title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 10px;
}

.poster-2x3-image {
  height: 310px;
}
</style>
