<template>
  <div
    v-if="data && data.length"
    class="chart-wrapper"
  >
    <div class="delivery">
      Storage
    </div>
    <div
      ref="chart"
      class="chart"
    >
    </div>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import Am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import Am4themesDark from '@amcharts/amcharts4/themes/dark';

import CONFIG from '../../constants/config';

export default {
  name: 'StorageItem',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);
    am4core.useTheme(Am4themesDark);
    am4core.useTheme(Am4themesAnimated);
    const chart = am4core.create(this.$refs.chart, am4charts.XYChart);
    const format = CONFIG.date.monthYearDateFormat;
    chart.data = this.data.map((item) => ({
      date: this.$options.filters.date(item.date, format),
      vod: (item.vod / 1000000000).toFixed(3),
    }));
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    chart.legend.paddingBottom = 20;

    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.renderer.labels.template.adapter.add('dy', (dy, target) => {
      if (target.dataItem && target.dataItem.index) {
        return dy;
      }

      return dy;
    });

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'vod';
    series.dataFields.categoryX = 'date';
    series.name = 'Files, Gb';
    series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}';
    series.columns.template.fillOpacity = 0.8;

    const bullet = series.bullets.push(new am4charts.LabelBullet());
    bullet.interactionsEnabled = false;
    bullet.dy = 12;
    bullet.label.text = '{valueY}';
    bullet.label.fill = am4core.color('#fff');

    const columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;
  },
};
</script>

<style scoped>
  .chart-wrapper {
    padding-top: 50px;
  }

  .delivery {
    padding-bottom: 20px;
    text-align: center;
  }

  .chart {
    height: 270px;
  }

  @media all and (max-width: 1400px) {
    .chart {
      width: 1000px;
    }
  }
</style>
