<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="btn-add-role-mobile">
      <Button
        :title="'Add Role'"
        :classType="'primary'"
        :eventFunction="toCreatePersonHandler"
      />
    </div>
    <div class="flex">
      <div class="left-path">
        <span class="system">
          System Management
        </span>
        <span>
          / Roles
        </span>
      </div>
      <div class="right-path">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="255"
        />
        <div class="btn-add-role-full">
          <Button
            :title="'Add Role'"
            :classType="'primary'"
            :eventFunction="toCreatePersonHandler"
          />
        </div>
      </div>
    </div>
    <div class="accounts-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name company">
          <Sorting
            :sortField="`cr.${sortedField.name}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          title
        </div>
        <div class="th-name desc">
          description
        </div>
        <div class="th-name users-count">
          status
        </div>
      </div>
      <div
        v-if="!roles.length"
        class="empty-search"
      >
        No Custom Roles to display
      </div>
      <div
        v-for="(item, key) in roles"
        :key="item.id"
        class="account"
        @mouseover="onHoverElem(item)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div
          class="td company"
          :title="item.name"
        >
          {{ item.name }}
        </div>
        <div
          class="td desc"
          :title="item.description"
        >
          {{ item.description || '-' }}
        </div>
        <div class="td status">
          <div
            class="status-ellipse"
            :style="item.enabled ? {background: '#1fee65'} : {background: '#fb3951'}"
          >
          </div>
          <span v-if="item.enabled">
            Enabled
          </span>
          <span v-else>
            Disabled
          </span>
        </div>
        <div
          v-show="isHover && item.id === selectedId"
          class="icons-wrapper"
        >
          <div :title="removeRoleTitle">
            <inline-svg
              :src="iconRemove"
              class="svg-icon remove"
              @click="onOpenModalForRemove(item)"
            >
            </inline-svg>
          </div>
          <div :title="item.enabled ? disableTitle : enableTitle">
            <inline-svg
              :src="item.enabled ? iconEnabled : iconDisabled"
              class="svg-icon"
              @click="onOpenModal(item)"
            >
            </inline-svg>
          </div>
          <div class="icon-edit">
            <router-link
              :to="{ path: `roles/${item.id}` }"
              :title="editRole"
            >
              <inline-svg
                :src="iconEdit"
                class="svg-icon"
              >
              </inline-svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :totalPages="totalPages"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="modalTitle"
      :onAccept="!isRemoveOption ? changeRoleStatus : deleteCustomRole"
      :onClose="onClose"
    />
    <ErrorModal
      v-model="isModalErrorOpen"
      :modal="isModalErrorOpen"
      :bodyErrorMessage="bodyErrorMessage"
      :onClose="onModalErrorClose"
    />
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  DELETE_CUSTOM_ROLE_REQUEST,
  DISABLE_CUSTOM_ROLE_REQUEST,
  GET_ROLES_LIST_REQUEST,
} from '../../store/actions/roles/rolesActions';
import {
  SET_EDIT_FORM,
  SET_OVERFLOW,
} from '../../store/actions/common';
import CONFIG from '../../constants/config';

import iconEnabled from '../../assets/icons/icon-enabled-acc.svg';
import iconDisabled from '../../assets/icons/icon-disabled-acc.svg';
import iconRemove from '../../assets/icons/icon-remove-role.svg';

import Pagination from '../../components/common/Pagination.vue';
import Sorting from '../../components/common/Sorting.vue';
import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import LiveSearch from '../../components/common/LiveSearch.vue';
import Button from '../../components/common/Button.vue';
import NotificationModal from '../../components/NotificationModal.vue';
import Loader from '../../components/common/Loader.vue';
import ErrorModal from '../../components/ErrorModal.vue';

export default {
  name: 'RolesList',
  components: {
    ErrorModal,
    NotificationModal,
    Pagination,
    LiveSearch,
    Sorting,
    Button,
    Loader,
  },
  data() {
    const {
      removeRoleTitle,
      disableRoleTitle,
      enableRoleTitle,
      editRole,
    } = CONFIG.tooltips;
    return {
      iconEnabled,
      iconDisabled,
      iconEdit,
      iconRemove,
      isRemoveOption: false,
      isEnabled: false,
      isModalOpen: false,
      enabledId: null,
      bodyMessage: '',
      modalTitle: '',
      isHover: false,
      selectedId: null,
      filter: '',
      sortedField: {
        name: 'name',
      },
      currentSortedField: 'cr.name',
      currentSortBy: 'ASC',
      isSortedUp: false,
      isSortedDown: false,
      removeRoleTitle,
      disableTitle: disableRoleTitle,
      enableTitle: enableRoleTitle,
      editRole,
      isModalErrorOpen: false,
      bodyErrorMessage: '',
    };
  },
  computed: {
    ...mapState({
      roles: (state) => state.roles.roles,
      pagination: (state) => state.roles.pagination,
      isLoading: (state) => state.roles.isLoading,
    }),
    ...mapGetters([
      'getCurrentAccountId',
    ]),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);

    const params = {
      accountId: this.getCurrentAccountId,
      page: localStorage.getItem('rolesPage') || 1,
      filterValue: '',
    };
    this.$store.dispatch(GET_ROLES_LIST_REQUEST, params);
  },
  methods: {
    onOpenModal(role) {
      const {
        enabledRoleMessage,
        disabledRoleMessage,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.isEnabled = role.enabled;
      this.enabledId = role.id;
      this.bodyMessage = role.enabled ? disabledRoleMessage : enabledRoleMessage;
      this.modalTitle = role.enabled ? 'Disable Custom Role' : 'Enable Custom Role';
    },
    onOpenModalForRemove(role) {
      const {
        deleteCustomRoleMessage,
      } = CONFIG.modals;
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isRemoveOption = true;
      this.isModalOpen = true;
      this.isEnabled = role.enabled;
      this.enabledId = role.id;
      this.modalTitle = 'Delete Custom Role';
      this.bodyMessage = deleteCustomRoleMessage;
    },
    deleteCustomRole() {
      const params = {
        accountId: this.getCurrentAccountId,
        roleId: this.enabledId,
      };

      this.$store.dispatch(DELETE_CUSTOM_ROLE_REQUEST, params)
        .then(() => {
          this.$toasted.global.success({
            message: CONFIG.successMessages.removedRoleMessage,
          });

          const params = {
            accountId: this.getCurrentAccountId,
            page: localStorage.getItem('rolesPage') || 1,
            filterValue: '',
          };

          this.$store.dispatch(GET_ROLES_LIST_REQUEST, params);
        })
        .catch((err) => {
          const {
            errorMessages,
            statuses,
          } = CONFIG;
          if (err.code === statuses.failedStatus) {
            this.$toasted.global.error({
              message: errorMessages.commonServerError,
            });
          }

          if (err.error === 'ITEM_HAS_DEPENDENCIES') {
            this.onErrorModalOpened();
          }
        });
    },
    onErrorModalOpened() {
      this.isModalErrorOpen = true;
      this.bodyErrorMessage = CONFIG.modals.errorDisabledRole;
    },
    changeRoleStatus() {
      const roleStatus = {
        accountId: this.getCurrentAccountId,
        enabled: !this.isEnabled,
        roleId: this.enabledId,
      };

      this.$store.dispatch(DISABLE_CUSTOM_ROLE_REQUEST, roleStatus)
        .then(() => {
          const params = {
            accountId: this.getCurrentAccountId,
            page: localStorage.getItem('rolesPage') || 1,
            filterValue: this.filter || '',
          };
          this.onSearch(params);
          this.$toasted.global.success({
            message: `The Role has been successfully ${this.isEnabled ? 'disabled' : 'enabled'}`,
          });
        })
        .catch((err) => {
          if (err) {
            this.onErrorModalOpened();
          }
        });
    },
    onClose() {
      this.isModalOpen = false;
      this.isRemoveOption = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    toCreatePersonHandler() {
      this.$router.push('roles/add');
    },
    handleSortUp(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ROLES_LIST_REQUEST, params);
    },
    handleSortDown(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ROLES_LIST_REQUEST, params);
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        accountId: this.getCurrentAccountId,
        page: params.page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ROLES_LIST_REQUEST, getParams);
    },
    getPaginationAction(page) {
      const params = {
        accountId: this.getCurrentAccountId,
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ROLES_LIST_REQUEST, params);
    },
    onHoverElem(item) {
      this.selectedId = item.id;
      this.isHover = true;
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    onModalErrorClose() {
      this.isModalErrorOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
  },
};
</script>

<style scoped>
  .flex {
    align-items: center;
    display: flex;
    flex: 0;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 40px;
  }

  .main-wrapper {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 25px 30px;
    width: 100%;
  }

  .system {
    color: #4c5057;
  }

  .accounts-wrapper {
    border: 1px solid #43434d;
    height: 100%;
    overflow-y: auto;
  }

  .ac-wrapper-header {
    background: #282a2e;
    display: flex;
    padding: 13px 0;
  }

  .th-name {
    align-items: center;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
  }

  .td {
    font-size: 14px;
    padding: 15px 0;
    word-break: break-word;
  }

  .number {
    padding-left: 21px;
    width: 67px;
  }

  .company {
    padding-left: 20px;
    width: 20%;
  }

  .desc {
    padding-left: 20px;
    width: 30%;
  }

  .users-count {
    padding-left: 20px;
    width: 20%;
  }

  .status {
    align-items: flex-start;
    display: flex;
    padding-left: 20px;
    width: 10%;
  }

  .account {
    display: flex;
  }

  .account:hover {
    background: #383b40;
  }

  .status-ellipse {
    border-radius: 50%;
    height: 5px;
    margin-right: 6px;
    margin-top: 6px;
    width: 5px;
  }

  .icons-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 0.9;
    justify-content: flex-end;
    padding-top: 10px;
  }

  .icon-edit {
    padding-left: 31px;
  }

  .right-path {
    align-items: center;
    display: flex;
  }

  .empty-search {
    padding-top: 40px;
    text-align: center;
  }

  .remove {
    padding-right: 30px;
  }

  .btn-add-role-mobile {
    display: none;
  }

  .svg-icon {
    cursor: pointer;
  }

  @media all and (max-width: 1024px) {
    .btn-add-role-mobile {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
      min-height: 40px;
    }

    .btn-add-role-full {
      display: none;
    }

    .search-wrapper {
      margin-right: 0;
    }
  }
</style>
