<template>
  <div
    :class="['wrap-icon-sort', {'active' : isCurrentFieldSorted }]"
    @click="sort(sortField)"
  >
    <inline-svg
      :src="iconSort"
      :class="'svg'"
    >
    </inline-svg>
  </div>
</template>

<script>
import iconSortDown from '../../assets/icons/icon-sort-down.svg';
import iconSortUp from '../../assets/icons/icon-sort-up.svg';
import iconSortDownActive from '../../assets/icons/icon-sort-down-active.svg';
import iconSortUpActive from '../../assets/icons/icon-sort-up-active.svg';
import CONFIG from '../../constants/config';

export default {
  name: 'SortingByColumn',
  props: {
    sortField: {
      type: String,
      default: '',
    },
    currentSortParams: {
      type: Object,
      default: () => {},
    },
    handleSort: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconSortDown,
      iconSortUp,
      iconSortDownActive,
      iconSortUpActive,
    };
  },
  computed: {
    isCurrentFieldSorted() {
      return this.currentSortParams.field === this.sortField;
    },
    iconSort() {
      if (this.isCurrentFieldSorted) {
        if (this.currentSortParams.order === CONFIG.sort.sortUp) {
          return iconSortUpActive;
        }
        return iconSortDownActive;
      }
      return iconSortDown;
    },
  },
  methods: {
    sort(field) {
      this.handleSort(field);
    },
  },
};
</script>

<style>
  .an-table-header > div:hover div.wrap-icon-sort {
    display: inline-block;
  }

  .wrap-icon-sort {
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
  }

  .wrap-icon-sort:not(.active) {
     display: none;
  }

  .wrap-icon-sort.active {
    display: inline-block;
  }
</style>
