export const FILE_APPEND_REPLACE_REQUEST = 'FILE_APPEND_REPLACE_REQUEST';
export const FILE_APPEND_REPLACE_SUCCESS = 'FILE_APPEND_REPLACE_SUCCESS';
export const FILE_APPEND_REPLACE_ERROR = 'FILE_APPEND_REPLACE_ERROR';

export const GENERATE_UPLOAD_URL_REPLACE_REQUEST = 'GENERATE_UPLOAD_URL_REPLACE_REQUEST';
export const GENERATE_UPLOAD_URL_REPLACE_IS_START = 'GENERATE_UPLOAD_URL_REPLACE_IS_START';
export const GENERATE_UPLOAD_URL_REPLACE_SUCCESS = 'GENERATE_UPLOAD_URL_REPLACE_SUCCESS';
export const GENERATE_UPLOAD_URL_REPLACE_ERROR = 'GENERATE_UPLOAD_URL_REPLACE_ERROR';

export const SEND_TRANSCODE_REPLACE_REQUEST = 'SEND_TRANSCODE_REPLACE_REQUEST';
export const SEND_TRANSCODE_REPLACE_SUCCESS = 'SEND_TRANSCODE_REPLACE_SUCCESS';
export const SEND_TRANSCODE_REPLACE_ERROR = 'SEND_TRANSCODE_REPLACE_ERROR';

export const CHANGE_UPLOAD_REPLACE_PROGRESS = 'CHANGE_UPLOAD_REPLACE_PROGRESS';

export const ABORT_UPLOADING_REPLACE_REQUEST = 'ABORT_UPLOADING_REPLACE_REQUEST';

export const SET_CANCEL_REPLACE_REQUEST = 'SET_CANCEL_REPLACE_REQUEST';

export const REMOVE_UPLOAD_REPLACE_REQUEST = 'REMOVE_UPLOAD_REPLACE_REQUEST';

export const REVERT_PLACE_REQUEST = 'REVERT_PLACE_REQUEST';
export const REVERT_PLACE_SUCCESS = 'REVERT_PLACE_SUCCESS';
export const REVERT_PLACE_ERROR = 'REVERT_PLACE_ERROR';

export const SET_CURRENT_CHUNK_REPLACE = 'SET_CURRENT_CHUNK_REPLACE';

export const REPLACE_URL_REQUEST = 'REPLACE_URL_REQUEST';
export const REPLACE_URL_SUCCESS = 'REPLACE_URL_SUCCESS';
export const REPLACE_URL_ERROR = 'REPLACE_URL_ERROR';
