<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="flex">
      <div class="left-path">
        <span class="system">
          System Management
        </span>
        <span>
          / Categories
        </span>
      </div>
      <div class="right-path search-add-wrapper">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="255"
          className="search-instance"
        />

        <Button
          v-if="isAdmin"
          :title="'Add Category'"
          :classType="'primary'"
          :eventFunction="handleCreateCategory"
        />
      </div>
    </div>

    <CategoriesList
      ref="categoriesList"
    />

    <AddCategoryModal
      v-model="isAddingOpen"
      :modal="isAddingOpen"
      :onClose="onCloseAdding"
      :onSubmitManually="onSubmitAdding"
    />
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :totalPages="totalPages"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  SET_EDIT_FORM,
} from '../../store/actions/common';
import {
  SET_CATEGORY_STORE_PROPERTY,
  GET_CATEGORIES_REQUEST,
} from '../../store/actions/categories/categoriesActions';
import Pagination from '../../components/common/Pagination.vue';
import Loader from '../../components/common/Loader.vue';
import CategoriesList from './CategoriesList.vue';
import AddCategoryModal from './AddCategoryModal.vue';
import LiveSearch from '../../components/common/LiveSearch.vue';
import Button from '../../components/common/Button.vue';

export default {
  name: 'Categories',
  components: {
    Pagination,
    LiveSearch,
    CategoriesList,
    Loader,
    Button,
    AddCategoryModal,
  },
  data() {
    return {
      isAddingOpen: false,
    };
  },
  computed: {
    ...mapState({
      pagination: (state) => state.categories.pagination,
      isLoading: (state) => state.categories.isLoading,
      profile: (state) => state.common.profile,
    }),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    ...mapGetters([
      'getCurrentAccountId',
      'isManager',
      'getMainProfile',
    ]),
  },
  methods: {
    onCloseAdding() {
      this.isAddingOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    onSubmitAdding() {
      this.onCloseAdding();
      this.$refs.categoriesList.getCategories();
    },
    getPaginationAction(page) {
      const params = {
        accountId: this.getCurrentAccountId,
        page,
      };
      this.$store.dispatch(GET_CATEGORIES_REQUEST, params);
    },
    onSearch(params) {
      const commitsArray = [
        this.$store.commit(SET_CATEGORY_STORE_PROPERTY, {
          key: 'filterValue',
          value: params.filterValue,
        }),
      ];

      const getParams = {
        accountId: this.getCurrentAccountId,
        page: params.page,
      };

      Promise.all(commitsArray)
        .then(() => {
          this.$store.dispatch(GET_CATEGORIES_REQUEST, getParams);
        });
    },
    handleCreateCategory() {
      this.isAddingOpen = true;
    },
  },
};
</script>

<style scoped lang="scss">
.flex {
  align-items: center;
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 25px;
  min-height: 40px;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px 25px 25px 30px;
  width: 100%;
}

.system {
  color: #4c5057;
}

.search-add-wrapper {
  align-items: center;
  display: flex;
  gap: 22px;

  & .search-instance {
    margin-right: 0px;
  }
}
</style>
