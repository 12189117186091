<!-- eslint-disable vue/valid-v-model -->
<template>
  <div
    v-if="modal"
    class="overlay"
  >
    <div
      v-model="modal"
      class="wrapper"
    >
      <p
        v-if="title"
        class="headline grey lighten-2"
        primaryTitle
      >
        {{ title }}
      </p>
      <p
        v-else
        class="headline grey lighten-2"
      ></p>
      <div class="localization-warning-wrapper">
        <inline-svg
          :src="iconWarning"
          class="svg-icon"
        >
        </inline-svg>
        <span class="localization-warning">
          {{ warningMess }}
        </span>
      </div>
      <div class="info">
        Please add Icons to the following Navigation Items:
      </div>
      <div
        v-if="navList.length"
        :class="['nav-icons-wrapper',
                 navList.length > 3 ? 'nav-icons-wrapper-many' : '']"
      >
        <div
          v-for="icon in navList"
          :key="icon.id"
          class="nav-icon-block"
        >
          <div
            :class="['nav-icon']"
            @click="onOpenIconsModal(icon)"
          >
            <img
              v-if="icon && icon.icon"
              class="nav-icon-img"
              :src="icon && icon.icon && icon.icon.url"
              alt="image"
            />
            <inline-svg
              v-if="!icon.icon"
              :src="iconAddNavIcon"
              class="svg-icon"
            >
            </inline-svg>
          </div>
          <div class="nav-icon-title">
            {{ icon.title }}
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <Button
          v-if="isNeedCancel"
          :title="cancelButtonTitle"
          :classType="isOrangeCancel && isNeedToApply
            ? 'warning'
            : !isNeedToApply
              ? 'primary'
              : 'warning'"
          :eventFunction="closeModalhandler"
        />
        <Button
          v-if="isNeedToApply"
          :title="confirmButtonTitle"
          :classType="'primary'"
          :disabled="navList.find(item => !item.icon)"
          :eventFunction="acceptHandler"
        />
      </div>
    </div>
    <NavigationIconsModal
      ref="modal"
      v-model="isOpenIconsModal"
      :modal="isOpenIconsModal"
      :title="'Select Navigation Item Icon'"
      :onAccept="onAddNavIcons"
      :onClose="onCloseNavIcons"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  SET_OVERFLOW,
} from '../../store/actions/common';
import Button from './Button.vue';
import iconWarning from '../../assets/icons/icon-warning-localization.svg';
import iconNewWindow from '../../assets/icons/icon-new-window.svg';
import iconSelected from '../../assets/icons/icon-nav-icon-selected.svg';
import iconAddNavIcon from '../../assets/icons/icon-nav-add-icon.svg';
import NavigationIconsModal from './NavigationIconsModal.vue';

export default {
  name: 'SwitchToLeftThemeModal',
  components: {
    NavigationIconsModal,
    Button,
  },
  props: {
    modal: Boolean,
    title: {
      type: String,
      default: '',
    },
    bodyMessage: {
      type: String,
      default: '',
    },
    bodyHelfullMessage: {
      type: String,
      default: '',
    },
    onAccept: {
      type: Function,
      default: () => {},
      required: false,
    },
    onDecline: {
      type: Function,
      default: () => {},
      required: false,
    },
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
    confirmButtonTitle: {
      type: String,
      default: 'Apply',
    },
    cancelButtonTitle: {
      type: String,
      default: 'Cancel',
    },
    futureStandaloneEvents: {
      type: Array,
      default: () => [],
      required: false,
    },
    fallbackMedia: {
      type: Object,
      default: null,
      required: false,
    },
    notRecurringMediaItem: {
      type: Array,
      default: () => [],
      required: false,
    },
    recurringMediaItem: {
      type: Array,
      default: () => [],
      required: false,
    },
    isCombiningDeletion: {
      type: Boolean,
      default: false,
    },
    isNeedCancel: {
      type: Boolean,
      default: true,
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
    isDeleteAcc: {
      type: Boolean,
      default: false,
    },
    isOrangeCancel: {
      type: Boolean,
      default: false,
    },
    isInUseAsset: {
      type: Boolean,
      default: false,
    },
    isNeedToApply: {
      type: Boolean,
      default: true,
    },
    navList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iconWarning,
      iconNewWindow,
      iconSelected,
      iconAddNavIcon,
      selectedNavIcon: null,
      hasSelected: false,
      warningMess: 'It is possible to switch to Left Navigation Layout only if all Navigation Items have Icons',
      isOpenIconsModal: false,
      navIcon: null,
      selectedId: null,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentAccountId',
      'getCurrentAccount',
    ]),
    ...mapState({
      isLoading: (state) => state.cms.isLoading,
      isEditedForm: (state) => state.common.isEditedForm,
    }),
  },
  methods: {
    closeModal() {
      if (this.onClose) {
        this.onClose();
        return;
      }
      this.$emit('input', false);
      this.$store.dispatch(SET_OVERFLOW, false);
    },
    accept() {
      const navs = this.navList.map((item) => ({
        id: item.id,
        icon: item.icon.id,
      }));
      this.onAccept(navs);
      if (!this.isWarning) {
        this.closeModal();
      }
    },
    closeModalhandler() {
      this.onDecline();
      this.closeModal();
    },
    acceptHandler() {
      this.accept();
    },
    selectNavIcon(icon) {
      this.hasSelected = true;
      this.selectedNavIcon = icon;
    },
    onCloseNavIcons() {
      this.isOpenIconsModal = false;
    },
    onOpenIconsModal(data) {
      this.selectedId = data.id;
      this.isOpenIconsModal = true;
    },
    onAddNavIcons(data) {
      this.navList.map((item) => {
        if (item.id === this.selectedId) {
          // eslint-disable-next-line no-return-assign
          return item.icon = data;
        }
        return item;
      });
    },
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.wrapper {
  background-color: #222222;
  border-radius: 4px;
  display: block;
  left: 50%;
  max-width: 100%;
  outline: none;
  overflow-wrap: break-word;
  position: absolute;
  text-decoration: none;
  top: 49%;
  transform: translate(-50%, -50%);
  padding: 30px;
  width: 480px;
}

.headline {
  color: #fff;
}

.headline {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  margin: 0;
  padding-bottom: 15px;
  word-break: break-all;
}

.button-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}

.button-wrapper button:first-child {
  margin-right: 15px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding-left: 1em;
  text-indent: -.7em;
  padding-bottom: 15px;
}

li::before {
  content: "• ";
  color: var(--darkgray);
}

a {
  text-decoration: none;
  color: #2591CF;
}

.nav-icon {
  width: 90px;
  height: 90px;
  border: 1px dashed #4C5057;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
}

.nav-icon:last-child {
  margin-bottom: 0;
}

.nav-icon-img {
  width: 50%;
  height: 50%;
  object-fit: contain;
}

.nav-icons-wrapper {
  display: flex;
  flex-direction: column;
}

.nav-icons-wrapper-many {
  height: 325px;
  overflow: auto;
}

.localization-warning-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 5px;
}

.localization-warning {
  flex: 0.98;
  font-size: 16px;
  color: #F2C94C;
  padding-left: 8px;
}

.nav-icon-block {
  display: flex;
  align-items: center;
}

.nav-icon-title {
  margin-bottom: 20px;
}

.info {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 20px;
}
</style>
