<template>
  <div :class="['overlay-modal', windowHeight < 1000 ? 'scroll' : '']">
    <Loader :isVisible="isLoading" />
    <div class="back-btn">
      <Button
        :className="'back-local'"
        :title="isGroup ? 'Back to Event Group Settings' : 'Back to Event Settings'"
        :srcSvg="iconBackTo"
        :eventFunction="toEv"
        :hasBorder="true"
      />
    </div>
    <div class="main-tabs-container">
      <Tabs>
        <Tab
          id="localization"
          name="Localization"
          :selected="true"
          class="wrapper-general"
        >
          <div class="flex">
            <div class="eng-local-wrapper">
              <div class="pb-13">
                English (default)
              </div>
              <div class="eng-local-container">
                <EvGroupEngForm
                  :evGroup="evGroup"
                  :isGroup="isGroup"
                  :isIndividualEvent="isIndividualEvent"
                />
              </div>
            </div>
            <div class="translate-local-wrapper">
              <Tabs
                :removeMargin="true"
                :onSelectTab="handleSelectTab"
              >
                <Tab
                  v-for="lang in enabledLanguages"
                  :id="lang.name"
                  :key="lang.id"
                  :name="lang.name"
                  :selected="formData.mode === lang.name"
                  :isLocalisation="true"
                  :className="[isEditedForm.length
                    && isEditedForm.find(item => item.id === lang.id && !!item.value)
                    ? 'error-tab'
                    : 'tab']"
                >
                  <TranslateEvGroupForm
                    :lang="lang"
                    :evGroup="evGroup"
                    :isGroup="isGroup"
                    :isIndividualEvent="isIndividualEvent"
                    :enabledLanguages="enabledLanguages"
                    :selected="formData.mode === lang.name"
                    @changedLanguage="changedLanguage"
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import Button from '../common/Button.vue';
import Tabs from '../common/Tabs/Tabs.vue';
import Tab from '../common/Tabs/Tab.vue';
import iconBackTo from '../../assets/icons/icon-back-to.svg';
import { GET_LANGUAGES_REQUEST, SET_FORM_CHANGES } from '../../store/actions/localization/localizationActions';
import Loader from '../common/Loader.vue';
import EvGroupEngForm from '../forms/localization/EvGroupEngForm.vue';
import TranslateEvGroupForm from '../forms/localization/TranslateEvGroupForm.vue';

export default {
  name: 'EvGroupLocalization',
  components: {
    TranslateEvGroupForm,
    EvGroupEngForm,
    Loader,
    Button,
    Tabs,
    Tab,
  },
  props: {
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
    evGroup: {
      type: Object,
      default: () => {},
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    isIndividualEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconBackTo,
      windowHeight: window.outerHeight,
      enabledLanguages: [],
      formData: {
        mode: '',
        changedLanguage: {},
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.localization.isLoading,
      isEditedForm: (state) => state.localization.isEditedForm,
    }),
    ...mapGetters([
      'getLanguages',
    ]),
  },
  created() {
    window.addEventListener('resize', this.onResize);
    const params = {
      accountId: this.getCurrentAccountId,
      page: 1,
      limit: 1000,
      filterValue: '',
    };
    this.$store.dispatch(GET_LANGUAGES_REQUEST, params).then((res) => {
      this.enabledLanguages = res.data;
      this.formData.mode = res.data && res.data[0].name;
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    toEv() {
      this.onClose();
    },
    handleSelectTab(id) {
      this.formData.mode = id;
      this.$store.dispatch(SET_FORM_CHANGES, this.formData.changedLanguage);
    },
    onResize() {
      this.windowHeight = window.outerHeight;
    },
    changedLanguage(value) {
      this.formData.changedLanguage = value;
    },
  },
};
</script>

<style scoped lang="scss">
.overlay-modal {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 95%;
  background: #222222;
  z-index: 999;
}

.scroll {
  overflow: auto;
}

.back-btn {
  padding-top: 20px;
  padding-left: 20px;
}

.main-tabs-container {
  padding: 10px 20px 30px;
}

.eng-local-wrapper {
  width: 40%;
}

.eng-local-container {
  border-top: 2px solid var(--gray);
  border-right: 2px solid var(--gray);
  height: 100vh;
}

.pb-13 {
  padding-bottom: 15px;
  line-height: 15px;
}

.translate-local-wrapper {
  width: 60%;
}

.flex {
  display: flex;
  align-items: flex-start;
}

.back-local {
  padding-left: 0;
}

@media all and (max-height: 930px)  {
  .eng-local-container {
    height: 78vh;
  }
}

@media all and (max-height: 890px)  {
  .eng-local-container {
    height: 83vh;
  }
}

@media all and (max-height: 830px)  {
  .eng-local-container {
    height: 88vh;
  }
}

@media all and (max-height: 790px)  {
  .eng-local-container {
    height: 90vh;
  }
}
</style>
