export const GET_MEDIA_TREE = 'GET_MEDIA_TREE';
export const GET_MEDIA_TREE_REQUEST = 'GET_MEDIA_TREE_REQUEST';
export const GET_MEDIA_TREE_SUCCESS = 'GET_MEDIA_TREE_SUCCESS';
export const GET_MEDIA_TREE_ERROR = 'GET_MEDIA_TREE_ERROR';

export const POST_MEDIA_FOLDER = 'POST_MEDIA_FOLDER';
export const POST_MEDIA_FOLDER_REQUEST = 'POST_MEDIA_FOLDER_REQUEST';
export const POST_MEDIA_FOLDER_SUCCESS = 'POST_MEDIA_FOLDER_SUCCESS';
export const POST_MEDIA_FOLDER_ERROR = 'POST_MEDIA_FOLDER_ERROR';

export const DELETE_MEDIA_FOLDER = 'DELETE_MEDIA_FOLDER';
export const DELETE_MEDIA_FOLDER_SUCCESS = 'DELETE_MEDIA_FOLDER_SUCCESS';
export const DELETE_MEDIA_FOLDER_REQUEST = 'DELETE_MEDIA_FOLDER_REQUEST';
export const DELETE_MEDIA_FOLDER_ERROR = 'DELETE_MEDIA_FOLDER_ERROR';

export const EDIT_MEDIA_FOLDER = 'EDIT_MEDIA_FOLDER';
export const EDIT_MEDIA_FOLDER_SUCCESS = 'EDIT_MEDIA_FOLDER_SUCCESS';
export const EDIT_MEDIA_FOLDER_REQUEST = 'EDIT_MEDIA_FOLDER_REQUEST';
export const EDIT_MEDIA_FOLDER_ERROR = 'EDIT_MEDIA_FOLDER_ERROR';

export const GET_ALLOWED_MEDIA_FOLDERS = 'GET_ALLOWED_MEDIA_FOLDERS';
export const GET_ALLOWED_MEDIA_FOLDERS_SUCCESS = 'GET_ALLOWED_MEDIA_FOLDERS_SUCCESS';
export const GET_ALLOWED_MEDIA_FOLDERS_REQUEST = 'GET_ALLOWED_MEDIA_FOLDERS_REQUEST';
export const GET_ALLOWED_MEDIA_FOLDERS_ERROR = 'GET_ALLOWED_MEDIA_FOLDERS_ERROR';

export const GET_METADATA_REQUEST = 'GET_METADATA_REQUEST';
export const GET_METADATA_SUCCESS = 'GET_METADATA_SUCCESS';
export const GET_METADATA_ERROR = 'GET_METADATA_ERROR';

export const EDIT_METADATA_REQUEST = 'EDIT_METADATA_REQUEST';
export const EDIT_METADATA_SUCCESS = 'EDIT_METADATA_SUCCESS';
export const EDIT_METADATA_ERROR = 'EDIT_METADATA_ERROR';

export const DESTROY_META_REQUEST = 'DESTROY_META_REQUEST';
export const DESTROY_META_SUCCESS = 'DESTROY_META_SUCCESS';

export const DELETE_METADATA_REQUEST = 'DELETE_METADATA_REQUEST';
export const DELETE_METADATA_SUCCESS = 'DELETE_METADATA_SUCCESS';
export const DELETE_METADATA_ERROR = 'DELETE_METADATA_ERROR';

export const EDIT_TV_APPS_LIST = 'EDIT_TV_APPS_LIST';
export const EDIT_TV_APPS_LIST_SUCCESS = 'EDIT_TV_APPS_LIST_SUCCESS';
export const EDIT_TV_APPS_LIST_REQUEST = 'EDIT_TV_APPS_LIST_REQUEST';
export const EDIT_TV_APPS_LIST_ERROR = 'EDIT_TV_APPS_LIST_ERROR';
