<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ handleSubmit }"
  >
    <form
      class="contact-info"
      novalidate="novalidate"
      @submit.prevent="handleSubmit(submit)"
      @input="handleFormChange"
    >
      <div class="edit-button-wrapper">
        <Button
          v-if="isAdmin"
          title="Delete"
          :classType="'transparent'"
          :eventFunction="handleOpenModalForRemove"
          :disabled="isDeleteBtnDisabled"
        />
        <Button
          :title="isAdmin ? 'Cancel' : 'Close'"
          :classType="'warning'"
          :eventFunction="handleGoBack"
        />
        <Button
          v-if="isAdmin"
          title="Apply"
          :classType="'primary'"
          :type="'submit'"
        />
      </div>
      <div class="breadcrumbs">
        <span class="system">
          System Management / Categories
        </span>
        <span>
          / Edit {{ formData.name }}
        </span>
      </div>
      <div class="title">
        Category Editor
      </div>
      <div class="name-lacalization-wrapper">
        <MaterialInput
          v-model="formData.name"
          v-bind="formFields.name"
          :requiredFromBack="true"
          :disabled="!isAdmin"
        />
        <Button
          title="Localization"
          classType="primary"
          className="localization-btn"
          :srcSvg="iconFolderLocalization"
          :isReverseIcon="true"
          :eventFunction="handleOpenLocalization"
          :disabled="true"
        />
      </div>
      <MaterialInput
        v-model="formData.shortName"
        v-bind="formFields.shortName"
      />
      <MaterialTextArea
        v-model="formData.description"
        v-bind="formFields.description"
        :disabled="!isAdmin"
      />

      <DeleteCategoryInTwoSteps
        ref="deleteCategoryInTwoSteps"
        @runAfterComplete="handleGoBack"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  EDIT_CATEGORY_REQUEST,
  GET_CATEGORY_REQUEST,
} from '../../../store/actions/categories/categoriesActions';
import CONFIG from '../../../constants/config';

import BaseForm from '../BaseForm.vue';
import MaterialInput from '../../inputs/MaterialInput.vue';
import Button from '../../common/Button.vue';
import DeleteCategoryInTwoSteps from '../../../views/Categories/DeleteCategoryInTwoSteps.vue';
import MaterialTextArea from '../../inputs/MaterialTextArea.vue';

import iconFolderLocalization from '../../../assets/icons/icon-folder-localization.svg';

export default {
  name: 'EditCategoryForm',
  components: {
    MaterialTextArea,
    MaterialInput,
    Button,
    DeleteCategoryInTwoSteps,
  },
  extends: BaseForm,
  props: {
    formPrefix: {
      type: String,
      default: 'categoryForm',
    },
  },
  data() {
    return {
      iconFolderLocalization,
      formData: {
        name: '',
        description: '',
        shortName: '',
      },
      formFields: {
        name: {
          type: 'text',
          name: 'name',
          label: 'Name',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
            max: 255,
          },
        },
        shortName: {
          type: 'text',
          name: 'shortName',
          label: 'Short Name',
          formPrefix: this.formPrefix,
          disabled: true,
          rules: {
            required: true,
          },
        },
        description: {
          type: 'text',
          name: 'description',
          label: 'Description',
          formPrefix: this.formPrefix,
          cols: 30,
          rows: 10,
          rules: {
            required: false,
            max: 1800,
          },
        },
      },
      errorMapping: {
        name: [
          `${this.formPrefix}.name`,
        ],
        description: [
          `${this.formPrefix}.description`,
        ],
        shortName: [
          `${this.formPrefix}.shortName`,
        ],
      },
      submitEvent: EDIT_CATEGORY_REQUEST,
      successMessage: CONFIG.successMessages.editCategoryMessage,
      errorMessage: CONFIG.errorMessages.commonServerError,
    };
  },
  computed: {
    ...mapState({
      category: (state) => state.categories.category,
    }),
    ...mapGetters([
      'getCurrentAccountId',
      'isManager',
      'isAdmin',
    ]),
    isDeleteBtnDisabled() {
      return this.category?.enabled;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getCategory();
    }
  },
  methods: {
    getCategory() {
      const {
        errorMessages,
        statuses,
      } = CONFIG;

      const params = {
        accountId: this.getCurrentAccountId,
        categoryId: this.$route.params.id,
      };

      this.$store.dispatch(GET_CATEGORY_REQUEST, params)
        .then((res) => this.initFormData(res))
        .catch((err) => {
          if (err.error === errorMessages.unknown) {
            this.$router.push(`/${statuses.notFound}`);
          }
        });
    },
    initFormData(category) {
      this.formData.name = category.name;
      this.formData.description = category.description;
      this.formData.shortName = category.shortName;
    },
    handleOpenLocalization() {
      console.log('Open localization');
    },
    getRequestData() {
      const data = {
        name: this.formData.name,
        description: this.formData.description,
      };

      return {
        data,
        accountId: this.getCurrentAccountId,
        categoryId: this.$route.params.id,
      };
    },
    onSubmitSuccess() {
      this.$toasted.global.success({
        message: this.successMessage,
      });
      setTimeout(() => {
        this.handleGoBack();
      }, CONFIG.routing.redirectFromAddAcc);
    },
    onSubmitFail(error) {
      const formErrors = this.getServerFormErrors(error);

      if (Object.entries(formErrors).length && this.$refs[this.validationObserver]) {
        this.$refs[this.validationObserver].setErrors(formErrors);
      } else {
        this.$toasted.global.error({
          message: this.errorMessage,
        });
      }
    },
    handleGoBack() {
      this.$router.push('/settings/categories');
    },
    handleOpenModalForRemove() {
      this.$refs.deleteCategoryInTwoSteps.handleOpenModalForRemove(this.category);
    },
  },
};
</script>

<style scoped lang="scss">
  .edit-button-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 26px;
  }

  .breadcrumbs {
    margin-bottom: 25px;
  }

  .system {
    color: #4c5057;
  }

  .name-lacalization-wrapper {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 160px;
    gap: 10px;
    justify-content: space-between;

    .localization-btn {
      width: 100%;
      height: 48px;
      position: relative;
      top: 29px;
    }
  }
</style>
