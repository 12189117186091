<!-- eslint-disable vue/valid-v-model -->
<template>
  <div
    v-if="modal"
    class="overlay"
  >
    <div
      v-model="modal"
      class="wrapper"
    >
      <p
        v-if="title"
        class="headline grey lighten-2"
        primaryTitle
      >
        {{ title }}
      </p>
      <p
        v-else
        class="headline grey lighten-2"
      ></p>
      <div
        class="body-text"
        :class="{title : bodyTitle}"
      >
        {{ bodyMessage }}
      </div>
      <div class="key-name">
        <MaterialInput
          :id="createdAPIKey.id"
          v-model="createdAPIKey.value"
          :label="`${keyName} :`"
          :readonly="true"
          :allowCopy="true"
          :blur="true"
        />
      </div>
      <hr />
      <div class="button-wrapper">
        <Button
          v-if="isNeedCancel"
          :title="cancelButtonTitle"
          :classType="'primary grey'"
          :eventFunction="closeModalhandler"
        />
        <Button
          :title="confirmButtonTitle"
          :classType="'primary'"
          :eventFunction="acceptHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  SET_OVERFLOW,
} from '../../store/actions/common';
import Button from '../common/Button.vue';
import MaterialInput from '../inputs/MaterialInput.vue';

export default {
  name: 'CreatedAPIKeysModal',
  components: {
    MaterialInput,
    Button,
  },
  props: {
    modal: Boolean,
    title: {
      type: String,
      default: '',
    },
    bodyMessage: {
      type: String,
      default: '',
    },
    bodyHelfullMessage: {
      type: String,
      default: '',
    },
    onAccept: {
      type: Function,
      default: () => {},
      required: false,
    },
    onDecline: {
      type: Function,
      default: () => {},
      required: false,
    },
    onClose: {
      type: Function,
      default: () => {},
      required: false,
    },
    confirmButtonTitle: {
      type: String,
      default: 'Yes',
    },
    cancelButtonTitle: {
      type: String,
      default: 'No',
    },
    futureStandaloneEvents: {
      type: Array,
      default: () => [],
      required: false,
    },
    fallbackMedia: {
      type: Object,
      default: null,
      required: false,
    },
    notRecurringMediaItem: {
      type: Array,
      default: () => [],
      required: false,
    },
    recurringMediaItem: {
      type: Array,
      default: () => [],
      required: false,
    },
    isCombiningDeletion: {
      type: Boolean,
      default: false,
    },
    isNeedCancel: {
      type: Boolean,
      default: true,
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
    isDeleteAcc: {
      type: Boolean,
      default: false,
    },
    keyName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      createdAPIKey: (state) => state.apiKeys.createdAPIKey,
    }),
  },
  methods: {
    closeModal() {
      if (this.onClose) {
        this.onClose();
        return;
      }
      this.$emit('input', false);
      this.$store.dispatch(SET_OVERFLOW, false);
    },
    accept() {
      this.onAccept();
      if (!this.isWarning) {
        this.closeModal();
      }
    },
    closeModalhandler() {
      this.onDecline();
      this.closeModal();
    },
    acceptHandler() {
      this.accept();
    },
    standEventLink(standEvent) {
      return standEvent && standEvent[1].standaloneEventGroup
        ? `/schedule/event-group/individual/${standEvent[1].id}`
        : `/schedule/standalone-event/${standEvent[1].id}`;
    },
    getName(name) {
      return name.length > 45 ? `${name.slice(0, 45)}...` : name;
    },
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.wrapper {
  background-color: #222222;
  border-radius: 4px;
  display: block;
  left: 50%;
  max-width: 100%;
  outline: none;
  overflow-wrap: break-word;
  position: absolute;
  text-decoration: none;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.headline {
  color: #fff;
}

.body-text {
  padding: 15px 20px;
}

.key-name {
  padding: 0 20px;
}

.bodyTitle {
  padding-top: 7px;
}

.headline {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  margin: 0;
  padding: 15px 20px;
  word-break: break-all;
}

hr {
  border: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: thin 0 0;
  display: block;
  flex: 1 1 0;
  height: 0;
  max-height: 0;
  max-width: 100%;
  transition: inherit;
}

.button-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 20px 16px;
}

.delition-wrapper {
  padding: 15px 20px;
  padding-top: 0;
}

.futureStandaloneEvents {
  margin-top: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding-left: 1em;
  text-indent: -.7em;
  padding-bottom: 15px;
}

li::before {
  content: "• ";
  color: var(--darkgray);
}

.bodyHelfullMessage {
  line-height: 20px;
  padding: 0 20px;
}

a {
  text-decoration: none;
  color: #2591CF;
}

.scroll-wrapper {
  height: 165px;
  overflow: auto;
}
</style>
