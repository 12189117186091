<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, classes, required, ariaInput, ariaMsg }"
    :name="fieldName"
    :vid="name"
    :rules="rules"
    mode="passive"
  >
    <div
      class="group"
      :class="[classes]"
    >
      <label>
        {{ fieldLabel }}
        <span
          v-if="required"
          class="asterisk"
        >
          *
        </span>
      </label>
      <span class="select-wrapper">
        <CheckboxList
          v-model="valueModel"
          :options="optionModel"
          :name="name"
          :tagLabel="tagLabel"
          :tagTrackBy="tagTrackBy"
          :disabled="disabled"
          :className="className"
        />
      </span>
      <transition name="bounce">
        <span
          v-if="errors.length"
          class="error-label"
          v-bind="ariaMsg"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import BaseMaterialInput from './BaseMaterialInput.vue';
import CheckboxList from './CheckboxList.vue';

export default {
  name: 'MaterialMultiCheckbox',
  components: {
    CheckboxList,
  },
  extends: BaseMaterialInput,
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: null,
    },
    tagLabel: {
      type: String,
      default: 'name',
    },
    tagTrackBy: {
      type: String,
      default: 'code',
    },
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      optionModel: this.options,
    };
  },
  computed: {
    valueModel: {
      get() {
        return this.optionModel.filter((item) => this.value.includes(item[this.tagTrackBy]));
      },
      set(value) {
        this.$emit('input', value.map((item) => item[this.tagTrackBy]));
      },
    },
  },
  methods: {
  },
};
</script>

<style scoped>
  .error-label {
    color: #fb3951;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
  }

  .asterisk {
    color: #fb3951;
  }

  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .select-wrapper {
    margin-top: 10px;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
</style>
