<template>
  <div>
    <div class="analytics-filters">
      <div class="sort-box">
        <label
          for="searchSelect"
          class="sort-by"
        >
          Timeframe:
        </label>
        <div class="select-wrapper">
          <select
            id="searchSelect"
            v-model="sortBy"
            class="search-select"
            @change="onTimeFrameChange"
          >
            <option
              v-for="item in sortByList"
              :key="item.value"
              :value="item.value"
              class="select-option"
            >
              {{ item.name }}
            </option>
          </select>
          <inline-svg
            :src="iconSortDown"
            class="svg"
          />
        </div>
      </div>
      <div
        v-if="hasSerach"
        class="analytics-search"
      >
        <label
          for="searchSelect"
          class="sort-by filter-by"
        >
          Filter Results:
        </label>
        <div class="select-wrapper search-wrap">
          <select
            id="filterSelect"
            v-model="filterBy"
            class="search-select analytics-search-select"
            @change="() => {}"
          >
            <option
              v-for="item in filterByList"
              :key="item.value"
              :value="item.value"
              class="select-option"
            >
              {{ item.name }}
            </option>
          </select>
          <inline-svg
            :src="iconSortDown"
            class="svg"
          />
          <div class="search-wrapper search-wrap">
            <input
              v-model="search"
              class="search-input"
              :name="search"
              type="text"
              :maxlength="255"
            />
            <inline-svg
              :src="iconFilter"
              class="svg-icon search-icon analytics-search-icon"
              @click="onSearchClick"
            >
            </inline-svg>
          </div>
        </div>
        <Button
          :eventFunction="() => {}"
          :smallSize="true"
          :className="'small-button'"
          :srcSvg="iconPlus"
        />
        <Button
          :title="'Clear Filters'"
          :classType="'primary'"
          :eventFunction="clearFilters"
        />
      </div>
    </div>
    <div
      v-if="isCustomTimeframe"
      class="custom-dates-wrapper"
    >
      <div class="analytics-filters-custom-dates">
        <ValidationObserver
          ref="validationObserver"
          v-slot="{ handleSubmit }"
        >
          <form
            novalidate="novalidate"
            class="custom-dates-form"
            @submit.prevent="handleSubmit(submit)"
            @input="handleFormChange"
          >
            <MaterialCalendar
              v-model="formData.fromDate"
              v-bind="formFields.fromDate"
              :highlightedDates="selectedDates"
              :disabledDates="unavailableDatesToStart"
              @input="onSelectStartDate"
            />
            <MaterialCalendar
              v-model="formData.toDate"
              v-bind="formFields.toDate"
              :highlightedDates="selectedDates"
              :disabledDates="unavailableDatesToEnd"
              @input="onSelectEndDate"
            />
          </form>
        </ValidationObserver>
        <Button
          :title="'Apply'"
          :classType="'primary'"
          :eventFunction="onSelectCustomTimeframe"
        />
        <Button
          :title="'Cancel'"
          :classType="'primary grey'"
          :eventFunction="onCancelSelectCustomTimeframe"
        />
      </div>
      <p
        v-if="isLimitedDate"
        class="info"
      >
        {{ limitedDateMessage }}
      </p>
    </div>
    <div
      v-if="hasSerach"
      class="analytics-filters-2"
    >
      <div class="analytics-checkboxes">
        <MaterialMultiCheckbox
          v-model="videoFormatsSelected"
          :options="videoFormatsList"
          :isOneChoiceRequired="true"
          @input="onFilterVideoTypeChange"
        />
      </div>
      <div class="analytics-btns">
        <Button
          :eventFunction="() => {}"
          :smallSize="true"
          :className="'small-button'"
          :srcSvg="iconDownload"
        />
        <Button
          :eventFunction="() => {}"
          :smallSize="true"
          :className="'small-button'"
          :srcSvg="iconShare"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import CONFIG from '../../constants/config';
import Button from '../common/Button.vue';
import iconSortDown from '../../assets/icons/icon-sort-arrow-down.svg';
import iconPlus from '../../assets/icons/icon-plus.svg';
import iconFilter from '../../assets/icons/icon-filter.svg';
import iconDownload from '../../assets/icons/icon-download.svg';
import iconShare from '../../assets/icons/icon-share.svg';
import MaterialMultiCheckbox from '../inputs/MaterialMultiCheckbox.vue';
import MaterialCalendar from '../inputs/MaterialCalendar.vue';
import BaseForm from '../forms/BaseForm.vue';

export default {
  name: 'AnalyticsFilters',
  components: {
    Button,
    MaterialMultiCheckbox,
    MaterialCalendar,
  },
  extends: BaseForm,
  props: {
    onTimeFrameChange: {
      type: Function,
      default: () => {},
    },
    onClearFilter: {
      type: Function,
      default: () => {},
    },
    onFilterTypeChange: {
      type: Function,
      default: () => {},
    },
    onSearch: {
      type: Function,
      default: () => {},
    },
    search: {
      type: String,
      default: null,
    },
    isCustomTimeframe: {
      type: Boolean,
      default: false,
    },
    timeframe: {
      type: String,
      default: 'today',
    },
    previousTimeframe: {
      type: String,
      default: 'today',
    },
    formPrefix: {
      type: String,
      default: 'analyticCustomDates',
    },
    hasSerach: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      iconPlus,
      iconFilter,
      iconSortDown,
      iconDownload,
      iconShare,
      filterBy: CONFIG.analyticFilterBy[0].value,
      filterByList: CONFIG.analyticFilterBy,
      sortByList: CONFIG.analyticsTimeFrames,
      sortBy: this.timeframe,
      filterVideoType: 'all',
      videoFormatsList: CONFIG.videoFormats,
      videoFormatsSelected: CONFIG.defaultSelectedVideoFormats,
      limitedDateMessage: CONFIG.errorMessages.limitedDateMessage,
      isLimitedDate: false,
      formData: {
        fromDate: '',
        toDate: '',
      },
      formFields: {
        fromDate: {
          type: 'date',
          name: 'fromDate',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
        toDate: {
          type: 'text',
          name: 'toDate',
          formPrefix: this.formPrefix,
          rules: {
            required: true,
          },
        },
      },
      errorMapping: {
        feedName: [
          `${this.formPrefix}.fromDate`,
        ],
        feedDescription: [
          `${this.formPrefix}.toDate`,
        ],
      },
    };
  },
  computed: {
    unavailableDatesToStart() {
      return {
        from: moment(this.formData.toDate).toDate(),
        customPredictor(date) {
          const d = moment(date);
          const today = moment();

          return d.isAfter(today);
        },
      };
    },
    unavailableDatesToEnd() {
      return {
        to: moment(this.formData.fromDate).subtract(moment().hour(), 'hours').toDate(),
        customPredictor(date) {
          const d = moment(date);
          const today = moment();

          return d.isAfter(today);
        },
      };
    },
    selectedDates() {
      return {
        from: this.formData.fromDate,
        to: this.formData.toDate,
      };
    },
  },
  methods: {
    onFilterVideoTypeChange(selectedValues) {
      if (!selectedValues.length) {
        this.toggleSelectedValue();
      } else if (selectedValues.length === 1) {
        this.filterVideoType = selectedValues[0];
      } else {
        this.filterVideoType = 'all';
      }
      this.onFilterTypeChange(this.filterVideoType);
    },
    toggleSelectedValue() {
      const addValue = this.videoFormatsList.find((item) => item.code !== this.filterVideoType);
      this.videoFormatsSelected.push(addValue.code);
      this.filterVideoType = addValue.code;
    },
    clearFilters() {
      this.videoFormatsSelected = CONFIG.defaultSelectedVideoFormats;
      this.filterVideoType = 'all';
      this.filterBy = CONFIG.analyticFilterBy[0].value;
      this.onClearFilter();
    },
    onSearchClick() {
      this.onSearch(this.search);
    },
    onSelectCustomTimeframe() {
      this.$refs.validationObserver.validate()
        .then((success) => {
          if (!success) {
            return;
          }
          this.$emit('selectCustomTimeframe', this.formData);
        });
    },
    onCancelSelectCustomTimeframe() {
      this.$emit('cancelSelectCustomTimeframe');
      this.sortBy = this.previousTimeframe;
      this.formData.fromDate = '';
      this.formData.toDate = '';
    },
    onSelectStartDate() {
      this.isLimitedDate = false;
      const past13mounths = moment().subtract(13, 'months');
      const selectedDate = moment(this.formData.fromDate);

      if (selectedDate.isBefore(past13mounths)) {
        this.formData.fromDate = past13mounths.toDate();
        this.isLimitedDate = true;
      }
    },
    onSelectEndDate() {
      this.isLimitedDate = false;
      const past13mounths = moment().subtract(13, 'months');
      const selectedDate = moment(this.formData.toDate);

      if (moment(this.formData.toDate).isBefore(moment(), 'day')) {
        this.formData.toDate = moment(this.formData.toDate).set('hour', 23).set('minute', 59).toDate();
      }

      if (selectedDate.isBefore(past13mounths)) {
        this.formData.fromDate = past13mounths.toDate();
        this.formData.toDate = past13mounths.toDate();
        this.isLimitedDate = true;
      }
    },
  },
};
</script>
<style scoped>
.vdp-datepicker__clear-button {
  top: 2px !important;
}

  .sort-box {
    align-items: center;
    display: flex;
  }

  .sort-by {
    font-size: 14px;
    padding-right: 8px;
    white-space: nowrap;
  }

  .select-wrapper {
    color: #fff;
    display: flex;
    font-size: 14px;
  }

  .search-wrap {
    align-items: center;
    display: flex;
  }

  .search-select {
    appearance: none;
    background: #222;
    border: 1px solid #383b40;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    height: 100%;
    max-height: 30px;
    outline: none;
    padding: 6px;
    width: 130px;
  }

  .sorting-wrapper {
    display: flex;
    flex-direction: column;
  }

  .select-option {
    background: #383b40;
    color: #fff;
    font-size: 1em;
  }

  .svg {
    cursor: pointer;
    fill: #383b40;
    position: relative;
    right: 15px;
    top: 13px;
  }

  .analytics-filters,
  .analytics-filters-2 {
    display: flex;
    justify-content: space-between;
  }
  .custom-dates-wrapper {
    display: flex;
    flex-flow: column nowrap;
  }
  .analytics-filters-custom-dates,
  .custom-dates-form {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
  }
  .analytics-filters,
  .analytics-filters-custom-dates {
   margin-bottom: 20px;
  }
  .analytics-filters-custom-dates .button{
    margin-top: 33px;
    margin-left: 10px;
  }

  .analytics-search {
    display: flex;
    margin-left: 25px;
  }

  .analytics-search-select {
    background-color: #383b40;
    border-radius: 2px;
    margin-right: -7px;
    padding-left: 10px;
    padding-right: 25px;
    width: 130px;
  }

  .analytics-search .svg {
    fill: #2392cf;
    right: 10px;
    top: 0;
  }

  .analytics-search .search-input {
    border: 0;
  }

  .search-wrapper {
    border: 1px solid #383b40;
    border-radius: 2px;
    margin-left: -5px;
    margin-right: 5px;
    max-height: 30px;
  }

  .filter-by {
    padding-top: 7px;
  }

  .analytics-btns {
    display: flex;
  }
</style>
<style>
  .analytics-btns {
    display: none !important;
  }

  .analytics-filters .button,
  .analytics-btns .button {
    border-radius: 2px;
    font-family: 'Roboto', sans-serif;
    height: 30px;
    letter-spacing: normal;
  }

  .analytics-filters .button .button-content {
    letter-spacing: normal;
  }

  .analytics-filters .small-button,
  .analytics-btns .small-button {
    background-color: #3e3f40;
    min-width: 20px;
    padding: 0 5px;
    width: 30px;
  }

  .analytics-filters .small-button svg,
  .analytics-btns .small-button svg {
    height: 15px;
    width: 15px;
  }

  .analytics-search-icon {
    cursor: pointer;
    height: 17px;
    width: 17px;
  }

  .custom-dates-form .picker-date {
    margin-right: 10px;
    width: unset;
  }
  .analytics-filters-custom-dates .picker {
    font-size: inherit;
  }

  .analytics-filters-2 .checks-wrapper {
    padding-left: 0;
    flex-wrap: nowrap;
  }

  .analytics-filters-2 .checks-wrapper .option {
    white-space: nowrap;
  }
</style>
