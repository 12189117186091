<template>
  <div class="flex">
    <div class="border">
      <div class="title">
        Welcome
        <span class="cloud">
          Cloud Hosted Broadcast Automation Made Easy
        </span>
      </div>

      <LoginForm />
    </div>
  </div>
</template>

<script>
import LoginForm from '../components/forms/LoginForm.vue';

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  created() {
    const s = this.$route.name;
    document.title = s[0].toUpperCase() + s.slice(1);
  },
};
</script>

<style scoped>
  .flex {
    display: flex;
    justify-content: center;
  }

  .border {
    margin-top: 5%;
    padding: 60px;
  }

  h3 {
    text-align: center;
  }

  .title {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 40px;
    padding-left: 32px;
  }

  .cloud {
    font-size: 18px;
  }

  @media all and (max-width: 1400px) {
    .border {
      margin-bottom: 6%;
    }
  }
</style>
