import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  CREATE_CHECKOUT_REQUEST,
  CREATE_CHECKOUT_SUCCESS,
  CREATE_CHECKOUT_ERROR,
  UPDATE_PAYMENT_METHOD_REQUEST,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_ERROR,
  GET_ALL_INVOICES_REQUEST,
  GET_ALL_INVOICES_SUCCESS,
  GET_ALL_INVOICES_ERROR,
  INVOICES_REQUEST_SET_CANCEL_TOKEN,
  GET_CDN_REPORT_REQUEST,
  GET_CDN_REPORT_SUCCESS,
  GET_CDN_REPORT_ERROR,
  GET_STORAGE_REPORT_SUCCESS,
  GET_STORAGE_REPORT_ERROR,
  GET_STORAGE_REPORT_REQUEST,
} from '../../actions/billing/billing';

const state = {
  isLoading: false,
  invoices: [],
  pagination: {},
  cdnReports: [],
};

const actions = {
  [CREATE_CHECKOUT_REQUEST]: ({ commit }, accountId) => new Promise((resolve, reject) => {
    commit(CREATE_CHECKOUT_REQUEST);

    apiCall.post(`${accountId}/billing/checkout`)
      .then((res) => {
        commit(CREATE_CHECKOUT_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(CREATE_CHECKOUT_ERROR, error);
        reject(error);
      });
  }),
  [UPDATE_PAYMENT_METHOD_REQUEST]: ({ commit }, accountId) => new Promise((resolve, reject) => {
    commit(UPDATE_PAYMENT_METHOD_REQUEST);

    apiCall.post(`${accountId}/billing/update`)
      .then((res) => {
        commit(UPDATE_PAYMENT_METHOD_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(UPDATE_PAYMENT_METHOD_ERROR, error);
        reject(error);
      });
  }),
  [GET_ALL_INVOICES_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    commit(GET_ALL_INVOICES_REQUEST);

    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(INVOICES_REQUEST_SET_CANCEL_TOKEN, { token: source });

    let url = `${params.accountId}/invoice/list?p=${params.page}&l=15&filterField=i.uniqueId&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url,
      {
        cancelToken: source.token,
        cache: false,
      })
      .then((resp) => {
        commit(GET_ALL_INVOICES_SUCCESS, resp.data);
        localStorage.setItem('invoicesPage', resp.data.pagination.page);
        resolve();
      })
      .catch(() => {
        commit(GET_ALL_INVOICES_ERROR);
      });
  }),
  [GET_CDN_REPORT_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_CDN_REPORT_REQUEST);

    const url = `${params.accountId}/cdn-report/bandwidth?startDate=${params.startDate}&finishDate=${params.finishDate}`;

    apiCall.get(url,
      {
        cache: false,
      })
      .then((resp) => {
        commit(GET_CDN_REPORT_SUCCESS, resp.data.data);
        resolve(resp.data.data);
      })
      .catch((err) => {
        commit(GET_CDN_REPORT_ERROR);
        reject(err.response);
      });
  }),
  // eslint-disable-next-line max-len
  [GET_STORAGE_REPORT_REQUEST]: ({ commit }, params) => new Promise((resolve, reject) => {
    commit(GET_STORAGE_REPORT_REQUEST);

    const url = `${params.accountId}/cdn-report/storage?startDate=${params.startDate}&finishDate=${params.finishDate}`;

    apiCall.get(url,
      {
        cache: false,
      })
      .then((resp) => {
        commit(GET_STORAGE_REPORT_SUCCESS, resp.data.data);
        resolve(resp.data.data);
      })
      .catch((err) => {
        commit(GET_STORAGE_REPORT_ERROR);
        reject(err.response);
      });
  }),
};

const mutations = {
  [CREATE_CHECKOUT_REQUEST]: () => {
    state.isLoading = true;
  },
  [CREATE_CHECKOUT_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CREATE_CHECKOUT_ERROR]: () => {
    state.isLoading = false;
  },
  [UPDATE_PAYMENT_METHOD_REQUEST]: () => {
    state.isLoading = true;
  },
  [UPDATE_PAYMENT_METHOD_SUCCESS]: () => {
    state.isLoading = false;
  },
  [UPDATE_PAYMENT_METHOD_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_ALL_INVOICES_REQUEST]: () => {
  },
  [GET_ALL_INVOICES_SUCCESS]: (state, payload) => {
    state.invoices = payload.data;
    state.pagination = payload.pagination;
  },
  [GET_ALL_INVOICES_ERROR]: () => {
    state.isLoading = false;
  },
  [INVOICES_REQUEST_SET_CANCEL_TOKEN]: (state, payload) => {
    state.source = payload.token;
  },
  [GET_CDN_REPORT_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_CDN_REPORT_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_CDN_REPORT_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_STORAGE_REPORT_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_STORAGE_REPORT_SUCCESS]: () => {
    state.isLoading = false;
  },
  [GET_STORAGE_REPORT_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
