<template>
  <div class="main-wrapper">
    <Loader :isVisible="isLoading" />
    <div class="btn-create-user-mobile">
      <Button
        :title="'Add Account User'"
        :classType="'primary'"
        :eventFunction="createUserHandler"
      />
    </div>
    <div class="flex">
      <div class="left-path">
        <span class="system">
          System Management
        </span>
        <span>
          {{ `/ ${isSuperAdmin ? 'Users' : 'Account Users'}` }}
        </span>
      </div>
      <div class="right-path">
        <LiveSearch
          :onSearch="onSearch"
          :maxlength="100"
        />
        <div class="btn-create-user-full">
          <Button
            :title="isSuperAdmin ? 'Add User' : 'Add Account User'"
            :classType="'primary'"
            :eventFunction="createUserHandler"
          />
        </div>
      </div>
    </div>
    <div class="users-wrapper">
      <div class="ac-wrapper-header">
        <div class="th-name number">
          #
        </div>
        <div class="th-name first-name">
          <Sorting
            :sortField="`u.${sortedField.firstName}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          first name
        </div>
        <div class="th-name last-name">
          <Sorting
            :sortField="`u.${sortedField.lastName}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          last name
        </div>
        <div class="th-name roles">
          <Sorting
            :sortField="sortedField.roles"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          user role
        </div>
        <div
          v-if="!isSuperAdmin"
          class="th-name status"
        >
          <Sorting
            :sortField="`a.${sortedField.enabled}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          account status
        </div>
        <div class="th-name activation-status">
          <Sorting
            :sortField="sortedField.activationLinkStatus"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          user status
        </div>
        <div class="th-name login-date">
          <Sorting
            :sortField="`u.${sortedField.lastLoggedAt}`"
            :currentSortedField="currentSortedField"
            :handleSortUp="handleSortUp"
            :handleSortDown="handleSortDown"
          />
          last login date
        </div>
      </div>
      <div
        v-if="!users.length"
        class="empty-search"
      >
        No users to display
      </div>
      <div
        v-for="(item, key) in users"
        :key="item.id"
        class="account"
        @mouseover="onHoverElem(item.id)"
        @mouseleave="isHover = false"
      >
        <div class="td number">
          {{ getCurrentNumber(key) }}
        </div>
        <div
          class="td first-name"
          :title="item.firstName"
        >
          {{ item.firstName }}
        </div>
        <div
          class="td last-name"
          :title="item.lastName"
        >
          {{ item.lastName }}
        </div>
        <div class="td roles">
          {{ getRole(item) }}
        </div>
        <div
          v-if="!isSuperAdmin"
          class="td status"
        >
          <div
            v-if="item.enabled !== null"
            class="status-ellipse"
            :style="item.enabled ? {background: '#1fee65'} : {background: '#fb3951'}"
          >
          </div>
          <span v-if="item.enabled">
            Enabled
          </span>
          <span v-else-if="item.enabled === null">
            -
          </span>
          <span v-else>
            Disabled
          </span>
        </div>
        <div
          class="td activation-status"
          :style="item.activationLinkStatus === 'pending' && {color: '#e77925'}
            || item.activationLinkStatus === 'expired' && {color: '#fb3951'}"
        >
          {{ getActivationLinkStatus(item.activationLinkStatus) }}
        </div>
        <div
          v-if="item.lastLoggedAt"
          class="td login-date"
        >
          {{ item.lastLoggedAt | timezone(getMainProfile.timezone) }}
        </div>
        <div
          v-else
          class="td login-date"
        >
          -
        </div>
        <div
          :class="['icons-wrapper', (isHover && (item.id === selectedId))
            ? ''
            : 'icons-wrapper-hide'
          ]"
        >
          <div
            v-if="(isSuperAdmin || isAdmin) && item.activationLinkStatus !== activated"
            :title="sendUserActivationEmail"
            class="icon-resend"
          >
            <inline-svg
              :src="iconResend"
              class="svg-icon send-icon"
              @click="sendActivationEmail(item.id)"
            >
            </inline-svg>
          </div>
          <div v-show="getMainProfile.id !== item.id">
            <div
              v-show="isSuperAdmin
                && item.activationLinkStatus === 'activated'
                && getMainProfile.id !== item.id"
              class="icon-edit"
              :title="singInUnderUser"
              @click="loginUnderUser(item.email)"
            >
              <inline-svg
                :src="iconSignIn1"
                class="svg-icon"
              >
              </inline-svg>
              <inline-svg
                :src="iconSignIn2"
                class="svg-icon"
              >
              </inline-svg>
            </div>
          </div>
          <div class="icon-edit">
            <router-link
              :to="{ path: `users/${item.id}` }"
              :title="editUser"
            >
              <inline-svg
                :src="iconEdit"
                class="svg-icon"
              >
              </inline-svg>
            </router-link>
          </div>
          <div
            v-if="!item.distribution"
            :title="'Delete User'"
          >
            <inline-svg
              v-show="getMainProfile.id !== item.id"
              :src="iconRemove"
              class="svg-icon icon-remove"
              @click="onOpenModalForRemove(item)"
            >
            </inline-svg>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      :action="getPaginationAction"
      :page="pagination.page"
      :currentPage="currentPage"
      :totalPages="totalPages"
    />
    <NotificationModal
      ref="modal"
      v-model="isModalOpen"
      :modal="isModalOpen"
      :bodyMessage="bodyMessage"
      :title="'Delete User'"
      :onAccept="removeUser"
      :onClose="onClose"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import {
  DELETE_USER_AS_ADMIN_REQUEST,
  DELETE_USER_REQUEST,
  GET_ALL_USERS_REQUEST,
  SIGN_IN_OTHER_USER_REQUEST,
  USER_SEND_ACTIVATION_EMAIL_REQUEST,
  USER_SEND_ADMIN_ACTIVATION_EMAIL_REQUEST,
} from '../../store/actions/user';
import { SET_EDIT_FORM, SET_OVERFLOW } from '../../store/actions/common';

import Pagination from '../../components/common/Pagination.vue';
import Loader from '../../components/common/Loader.vue';
import Sorting from '../../components/common/Sorting.vue';
import LiveSearch from '../../components/common/LiveSearch.vue';
import Button from '../../components/common/Button.vue';
import NotificationModal from '../../components/NotificationModal.vue';

import iconEdit from '../../assets/icons/icon-edit-acc.svg';
import iconResend from '../../assets/icons/icon-resend.svg';
import iconSortUp from '../../assets/icons/icon-sort-arrow-up.svg';
import iconSortDown from '../../assets/icons/icon-sort-arrow-down.svg';
import iconSignIn1 from '../../assets/icons/icon-sigin-in1.svg';
import iconSignIn2 from '../../assets/icons/icon-sign-in2.svg';
import iconRemove from '../../assets/icons/icon-remove-role.svg';
import CONFIG from '../../constants/config';

export default {
  name: 'UserList',
  components: {
    Pagination,
    LiveSearch,
    Sorting,
    Loader,
    Button,
    NotificationModal,
  },
  data() {
    const {
      sort,
      userList,
    } = CONFIG;
    const {
      sendUserActivationEmail,
      singInUnderUser,
      editUser,
    } = CONFIG.tooltips;
    return {
      isHover: false,
      selectedId: null,
      filter: '',
      sortedField: {
        firstName: 'firstName',
        lastName: 'lastName',
        roles: 'roles',
        activationLinkStatus: 'activationLinkStatus',
        lastLoggedAt: 'lastLoggedAt',
        enabled: 'enabled',
      },
      currentSortedField: 'u.lastName',
      currentSortBy: sort.sortUp,
      activated: userList.userActiveStatus,
      isModalOpen: false,
      enabledId: null,
      iconEdit,
      iconResend,
      iconSortUp,
      iconSortDown,
      iconSignIn1,
      iconSignIn2,
      iconRemove,
      sendUserActivationEmail,
      singInUnderUser,
      editUser,
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.users,
      pagination: (state) => state.user.pagination,
      currentPage: (state) => state.user.currentPage,
      isLoading: (state) => state.user.isLoading,
      role: (state) => state.common.role,
    }),
    ...mapGetters([
      'isSuperAdmin',
      'isAdmin',
      'getMainProfile',
      'getCurrentAccountId',
      'getRootMediaFolderId',
    ]),
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    bodyMessage() {
      const { deleteUserMessage, deleteUserAdminMessage } = CONFIG.modals;
      if (this.isSuperAdmin) {
        return deleteUserMessage;
      }
      return deleteUserAdminMessage;
    },
  },
  created() {
    const s = this.$route.name;
    document.title = this.isSuperAdmin ? 'Users' : s[0].toUpperCase() + s.slice(1);

    const params = {
      accountId: this.getCurrentAccountId,
      page: localStorage.getItem('currentPage-user-list') || 1,
      filterValue: '',
    };
    this.$store.dispatch(GET_ALL_USERS_REQUEST, params);
  },
  methods: {
    handleSortUp(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortUp,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_USERS_REQUEST, params);
    },
    handleSortDown(field) {
      const params = {
        accountId: this.getCurrentAccountId,
        page: this.pagination.page,
        filterValue: this.filter,
        sortFiled: field,
        sortBy: CONFIG.sort.sortDown,
      };
      this.currentSortedField = params.sortFiled;
      this.currentSortBy = params.sortBy;
      this.$store.dispatch(GET_ALL_USERS_REQUEST, params);
    },
    onSearch(params) {
      this.filter = params.filterValue;
      const getParams = {
        accountId: this.getCurrentAccountId,
        page: params.page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ALL_USERS_REQUEST, getParams);
    },
    getPaginationAction(page) {
      const params = {
        accountId: this.getCurrentAccountId,
        page,
        filterValue: this.filter,
        sortFiled: this.currentSortedField,
        sortBy: this.currentSortBy,
      };
      this.$store.dispatch(GET_ALL_USERS_REQUEST, params);
    },
    getCurrentNumber(key) {
      const { pagination } = this;
      return (pagination.page - 1) * pagination.limit + key + 1;
    },
    createUser() {
      this.$router.push('users/add');
    },
    sendActivationEmail(id) {
      const {
        errorMessages,
        successMessages,
      } = CONFIG;
      if (this.isAdmin) {
        const params = {
          accountId: this.getCurrentAccountId,
          userId: id,
        };
        this.$store.dispatch(USER_SEND_ADMIN_ACTIVATION_EMAIL_REQUEST, params)
          .then(() => {
            this.$toasted.global.success({
              message: successMessages.resentRegLink,
            });
            const params = {
              accountId: this.getCurrentAccountId,
              page: localStorage.getItem('currentPage-user-list') || 1,
              filterValue: '',
            };
            this.$store.dispatch(GET_ALL_USERS_REQUEST, params);
          })
          .catch(() => {
            this.$toasted.global.error({
              message: errorMessages.commonServerError,
            });
          });
      } else {
        this.$store.dispatch(USER_SEND_ACTIVATION_EMAIL_REQUEST, id)
          .then(() => {
            this.$toasted.global.success({
              message: successMessages.resentRegLink,
            });
            const params = {
              accountId: this.getCurrentAccountId,
              page: localStorage.getItem('currentPage-user-list') || 1,
              filterValue: '',
            };
            this.$store.dispatch(GET_ALL_USERS_REQUEST, params);
          })
          .catch(() => {
            this.$toasted.global.error({
              message: errorMessages.commonServerError,
            });
          });
      }
    },
    getRole(value) {
      const item = (this.isSuperAdmin) ? value.userAccountRoles : value.userAccountRoleByAccount;
      if (item && item.length > 1) {
        return 'Multi';
      }

      const roleCode = (this.isSuperAdmin) ? (item && item.length && item[0].role) : item.role;
      if (CONFIG.roleList.find((role) => roleCode === role.code)) {
        return CONFIG.roleList.find((role) => roleCode === role.code).name;
      }
      return '-';
    },
    onHoverElem(id) {
      this.selectedId = id;
      this.isHover = true;
    },
    getActivationLinkStatus(status) {
      return CONFIG.activationLinkStatus[status];
    },
    loginUnderUser(email) {
      this.$store.dispatch(SIGN_IN_OTHER_USER_REQUEST, email)
        .then(() => {
          document.location.href = `/media/folder/${this.getRootMediaFolderId}`;
        });
    },
    createUserHandler() {
      this.createUser();
    },
    onOpenModalForRemove(item) {
      this.$store.dispatch(SET_OVERFLOW, true);
      this.isModalOpen = true;
      this.enabledId = this.isSuperAdmin ? item.id : item.userAccountRoleByAccount.id;
    },
    onClose() {
      this.isModalOpen = false;
      this.$store.dispatch(SET_EDIT_FORM, false);
    },
    removeUser() {
      const params = {
        accountId: this.getCurrentAccountId,
        userId: this.enabledId,
      };

      if (this.isSuperAdmin) {
        delete params.accountId;
      }

      this.$store.dispatch(
        this.isAdmin
          ? DELETE_USER_AS_ADMIN_REQUEST
          : DELETE_USER_REQUEST,
        params,
      )
        .then(() => {
          this.$toasted.global.success({
            message: 'The User has been successfully deleted',
          });

          const params = {
            accountId: this.getCurrentAccountId,
            page: localStorage.getItem('currentPage-user-list') || 1,
            filterValue: '',
          };
          this.$store.dispatch(GET_ALL_USERS_REQUEST, params).then((res) => {
            if (!res.data.length) {
              params.page = 1;
              this.$store.dispatch(GET_ALL_USERS_REQUEST, params);
            }
          });
        })
        .catch((err) => {
          if (err.error === 'USER_CANNOT_DELETE_HIS_ROLE') {
            this.$toasted.global.error({
              message: 'User cannot delete himself',
            });
          }
        });
    },
  },
};
</script>

<style scoped>
  .flex {
    align-items: center;
    display: flex;
    flex: 0;
    justify-content: space-between;
    margin-bottom: 25px;
    min-height: 40px;
  }

  .main-wrapper {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 25px 30px;
    width: 100%;
  }

  .system {
    color: #4c5057;
  }

  .users-wrapper {
    border: 1px solid #43434d;
    height: 100%;
    overflow-y: auto;
  }

  .ac-wrapper-header {
    background: #282a2e;
    display: flex;
    padding: 13px 0;
  }

  .th-name {
    align-items: center;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
  }

  .td {
    font-size: 14px;
    overflow: hidden;
    padding: 15px 0;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .number {
    padding-left: 21px;
    width: 67px;
  }

  .first-name,
  .last-name,
  .login-date,
  .roles {
    margin-right: 5px;
    width: 12%;
  }

  .status,
  .activation-status {
    width: 12%;
  }

  .status {
    align-items: flex-start;
    display: flex;
  }

  .account {
    display: flex;
  }

  .account:hover {
    background: #383b40;
  }

  .status-ellipse {
    border-radius: 50%;
    height: 5px;
    margin-right: 6px;
    margin-top: 6px;
    width: 5px;
  }

  .icons-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 0.95;
    justify-content: flex-end;
    min-width: 110px;
  }

  .icon-resend {
    padding: 10px 15px 0 10px;
    width: auto;
  }

  .icon-edit {
    padding: 10px 15px 0 10px;
  }

  .icon-remove {
    padding: 11px 0 0 10px;
  }

  .right-path {
    align-items: center;
    display: flex;
  }

  .empty-search {
    padding-top: 40px;
    text-align: center;
  }

  .svg-icon {
    cursor: pointer;
  }

  .icons-wrapper-hide {
    visibility: hidden;
  }

  .btn-create-user-mobile {
    display: none;
  }

  .left-path {
    margin-top: 5px;
  }

  @media all and (max-width: 1024px) {
    .btn-create-user-mobile {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
      min-height: 40px;
    }

    .btn-create-user-full {
      display: none;
    }

    .search-wrapper {
      margin-right: 0;
    }
  }
</style>
