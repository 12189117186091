import { render, staticRenderFns } from "./AssetRoles.vue?vue&type=template&id=19602fa8&scoped=true&"
import script from "./AssetRoles.vue?vue&type=script&lang=js&"
export * from "./AssetRoles.vue?vue&type=script&lang=js&"
import style0 from "./AssetRoles.vue?vue&type=style&index=0&id=19602fa8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19602fa8",
  null
  
)

export default component.exports