import axios from 'axios';
import apiCall from '../../../utils/api/api';
import {
  ADD_PERSON_ERROR,
  ADD_PERSON_REQUEST,
  ADD_PERSON_SUCCESS,
  CHANGE_PERSON_STATUS_ERROR,
  CHANGE_PERSON_STATUS_REQUEST,
  CHANGE_PERSON_STATUS_SUCCESS,
  DESTROY_PERSON_REQUEST,
  DESTROY_PERSON_SUCCESS,
  EDIT_PERSON_ERROR,
  EDIT_PERSON_REQUEST,
  EDIT_PERSON_SUCCESS,
  GET_PEOPLE_ERROR,
  GET_PEOPLE_REQUEST,
  GET_PEOPLE_SUCCESS,
  GET_PERSON_ERROR,
  GET_PERSON_REQUEST,
  GET_PERSON_SUCCESS,
  SET_CANCEL_TOKEN,
} from '../../actions/peopleActions/people';

const state = {
  isLoading: false,
  people: [],
  pagination: {},
  person: {},
  source: null,
};

const actions = {
  [GET_PEOPLE_REQUEST]: ({ commit }, params) => new Promise((resolve) => {
    if (state.source) {
      state.source.cancel();
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();
    commit(SET_CANCEL_TOKEN, { token: source });

    let url = `${params.accountId}/contributor-people/list?p=${params.page}&l=15&filterField=fullName&filterValue=${params.filterValue}`;
    if (params.sortFiled) {
      url += `&s=${params.sortFiled}&d=${params.sortBy}`;
    }

    apiCall.get(url, { cancelToken: source.token })
      .then((resp) => {
        commit(GET_PEOPLE_SUCCESS, resp.data);
        localStorage.setItem('peoplePage', resp.data.pagination.page);
        resolve();
      })
      .catch(() => {
        commit(GET_PEOPLE_ERROR);
      });
  }),
  [ADD_PERSON_REQUEST]: ({ commit }, { accountId, data }) => new Promise((resolve, reject) => {
    commit(ADD_PERSON_REQUEST);

    apiCall.post(`${accountId}/contributor-people`, data)
      .then((res) => {
        commit(ADD_PERSON_SUCCESS, res.data);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(ADD_PERSON_ERROR, error);
        reject(error);
      });
  }),
  [GET_PERSON_REQUEST]: ({ commit }, { accountId, personId }) => new Promise((resolve, reject) => {
    commit(GET_PERSON_REQUEST);

    let url = `${accountId}/contributor-people/`;
    url += (personId) ? `${personId}` : 'new';

    apiCall.get(url)
      .then((resp) => {
        commit(GET_PERSON_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(GET_PERSON_ERROR, error);
        reject(error);
      });
  }),
  [DESTROY_PERSON_REQUEST]: ({ commit }) => new Promise(() => {
    commit(DESTROY_PERSON_SUCCESS);
  }),
  [EDIT_PERSON_REQUEST]:
  ({ commit }, { accountId, personId, data }) => new Promise((resolve, reject) => {
    commit(EDIT_PERSON_REQUEST);

    apiCall.put(`${accountId}/contributor-people/${personId}`, data)
      .then((resp) => {
        commit(EDIT_PERSON_SUCCESS, resp);
        resolve(resp);
      })
      .catch((err) => {
        const error = err.response.data;
        commit(EDIT_PERSON_ERROR, error);
        reject(error);
      });
  }),
  [CHANGE_PERSON_STATUS_REQUEST]:
  ({ commit }, { accountId, personId, enabled }) => new Promise((resolve, reject) => {
    commit(CHANGE_PERSON_STATUS_REQUEST);

    const url = `${accountId}/contributor-people/${personId}/disable`;

    apiCall.put(url, { enabled })
      .then((res) => {
        commit(CHANGE_PERSON_STATUS_SUCCESS);
        resolve(res.data);
      })
      .catch((err) => {
        const error = err.response.data.form_errors.children.enabled.errors.length;
        commit(CHANGE_PERSON_STATUS_ERROR, error);
        reject(error);
      });
  }),
};

const mutations = {
  [GET_PEOPLE_SUCCESS]: (state, payload) => {
    state.people = payload.data;
    state.pagination = payload.pagination;
    state.source = null;
  },
  [ADD_PERSON_REQUEST]: () => {
    state.isLoading = true;
  },
  [ADD_PERSON_SUCCESS]: () => {
    state.isLoading = false;
  },
  [ADD_PERSON_ERROR]: () => {
    state.isLoading = false;
  },
  [EDIT_PERSON_REQUEST]: () => {
    state.isLoading = true;
  },
  [EDIT_PERSON_SUCCESS]: () => {
    state.isLoading = false;
  },
  [EDIT_PERSON_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_PERSON_REQUEST]: () => {
    state.isLoading = true;
  },
  [GET_PERSON_SUCCESS]: (state, payload) => {
    state.isLoading = false;
    state.person = payload;
  },
  [GET_PERSON_ERROR]: () => {
    state.isLoading = false;
  },
  [DESTROY_PERSON_SUCCESS]: () => {
    state.person = {};
  },
  [SET_CANCEL_TOKEN]: (stateLocal, payload) => {
    state.source = payload.token;
  },
  [CHANGE_PERSON_STATUS_REQUEST]: () => {
    state.isLoading = true;
  },
  [CHANGE_PERSON_STATUS_SUCCESS]: () => {
    state.isLoading = false;
  },
  [CHANGE_PERSON_STATUS_ERROR]: () => {
    state.isLoading = false;
  },
  [GET_PEOPLE_ERROR]: () => {
    state.isLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
};
