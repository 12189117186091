<template>
  <div class="feed-items">
    <div :class="['feed-item', isSelected && 'active-preview']">
      <div class="img-feed-container">
        <img
          class="img-feed"
          :src="posterRender"
        />
        <inline-svg
          v-if="item.hasProtection && getCurrentAccount.brushfireProtectionEnabled"
          :src="iconProtection"
          class="svg-icon brush"
        >
        </inline-svg>
        <inline-svg
          v-if="item.contentType === 'Movie'"
          :src="iconMovie"
          :class="['svg-icon content-icons',
                   item.hasProtection
                     && getCurrentAccount.brushfireProtectionEnabled
                     && 'hasBrush']"
        >
        </inline-svg>
        <inline-svg
          v-if="item.contentType === 'Event'"
          :src="iconEvent"
          :class="['svg-icon content-icons',
                   item.hasProtection
                     && getCurrentAccount.brushfireProtectionEnabled
                     && 'hasBrush']"
        >
        </inline-svg>
        <inline-svg
          v-if="item.contentType === 'Trailer'"
          :src="iconTrailer"
          :class="['svg-icon content-icons',
                   item.hasProtection
                     && getCurrentAccount.brushfireProtectionEnabled
                     && 'hasBrush']"
        >
        </inline-svg>
        <inline-svg
          v-if="item.contentType === 'Extra'"
          :src="iconExtra"
          :class="['svg-icon content-icons',
                   item.hasProtection
                     && getCurrentAccount.brushfireProtectionEnabled
                     && 'hasBrush']"
        >
        </inline-svg>
        <inline-svg
          v-if="item.contentType === 'Episode' && !item.episodeNumber"
          :src="iconEmptyEpisode"
          :class="['svg-icon content-icons',
                   item.hasProtection
                     && getCurrentAccount.brushfireProtectionEnabled
                     && 'hasBrush']"
        >
        </inline-svg>
        <div
          v-if="item.contentType === 'Episode' && item.episodeNumber"
          :class="['brush episode-wrapper',
                   item.episodeNumber && 'episode-number-wrapper',
                   item.hasProtection
                     && getCurrentAccount.brushfireProtectionEnabled
                     && 'hasBrush']"
        >
          <inline-svg
            :src="iconEpisode"
            class="svg-icon"
          >
          </inline-svg>
          <div
            v-if="item.episodeNumber"
            class="episode-number"
          >
            {{ item.episodeNumber }}
          </div>
        </div>
      </div>
      <div class="title-container">
        <div style="display: none">
          {{ error }}
        </div>
        <div
          class="title-item"
          :class="itemClass"
          :title="item.name"
        >
          {{ getTitle(item.name) }}
        </div>
        <div
          class="description"
          :title="item.description"
          :class="{ error: error }"
        >
          {{ item.description ? getName(item.description) : null }}
        </div>
        <div
          v-if="!item.type"
          class="live-time"
          :class="itemClass"
        >
          {{ parseDateTime(item.startDate) }}
          - {{ parseDateTime(item.finishDate) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import posterSelector from '../../utils/poster/selector';
import iconProtection from '../../assets/icons/icon-brush-protection-media.svg';
import iconExtra from '../../assets/icons/icon-extra.svg';
import iconEvent from '../../assets/icons/icon-event.svg';
import iconEpisode from '../../assets/icons/icon-episode.svg';
import iconMovie from '../../assets/icons/icon-movie.svg';
import iconTrailer from '../../assets/icons/icon-trailer.svg';
import iconEmptyEpisode from '../../assets/icons/icon-episode-feed.svg';

export default {
  name: 'FeedPreviewItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isFixedMedia: {
      type: Boolean,
      default: false,
    },
    errorLiveEvents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      posterSelector,
      iconProtection,
      iconExtra,
      iconEvent,
      iconEpisode,
      iconMovie,
      iconTrailer,
      iconEmptyEpisode,
    };
  },
  computed: {
    ...mapGetters([
      'getMainProfile',
      'getCurrentAccount',
    ]),
    posterRender() {
      if (this.item.backgroundImage) {
        return this.item.backgroundImage ? `${this.item.backgroundImage}?height=60` : '';
      }

      if (this.item.mediaItem) {
        if (this.item.mediaItem.posterImage) {
          return this.item.mediaItem.posterImage ? `${this.item.mediaItem.posterImage}?height=60` : '';
        }

        return this.item.mediaItem.mediaItemPoster ? `${this.item.mediaItem.mediaItemPoster}?height=60` : '';
      }
      return this.item.posterImage ? `${this.item.posterImage}?height=60` : '';
    },
    getTimezone() {
      return (this.getMainProfile.timezone) ? this.getMainProfile.timezone : moment.tz.guess();
    },
    error() {
      return this.errorLiveEvents.map((err) => +err.id === +this.item.id).find((it) => it === true);
    },
    itemClass() {
      if (this.item.status === 'completed' && !this.item.type) {
        return 'completed';
      }

      if (this.error) {
        return 'error';
      }

      return '';
    },
  },
  methods: {
    parseDateTime(value) {
      return moment.utc(value).tz(this.getTimezone).format('YYYY-MM-DD hh:mm A');
    },
    getName(name) {
      return name.length > 35 ? `${name.slice(0, 35)}...` : name;
    },
    getTitle(name) {
      return name.length > 42 ? `${name.slice(0, 42)}...` : name;
    },
  },
};
</script>
<style>
  .img-feed-container {
    position: relative;
    background: #222;
    height: 40px;
    text-align: center;
    width: 70px;
  }

  .feed-item {
    display: flex;
    font-size: 10px;
    max-height: 40px;
    overflow-y: hidden;
    padding: 4px 5px;
  }

  .description {
    color: #a0a0a0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    margin-bottom: 2px;
  }

  .title-item {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    word-break: break-word;
  }

  .title-container {
    margin-left: 10px;
    overflow-y: hidden;
    text-overflow: ellipsis;
  }

  .img-feed {
    height: 40px;
    max-width: 70px;
  }

  .active-preview {
    background: #4b4f55;
  }

  .error {
    color: #fb3951;
  }

  .completed {
    color: rgba(255, 255, 255, 0.5);
  }

  .brush {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 14px;
    height: 14px;
  }

  .episode-wrapper {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
  }

  .episode-number {
    font-weight: 700;
    line-height: 12px;
    color: #000000;
    font-size: 8px;
    padding-left: 2px;
  }

  .episode-number-wrapper {
    width: 35px;
    border-radius: 10px;
  }

  .content-icons {
    width: 14px;
    height: 14px;
    position: absolute;
    z-index: 2;
    right: 5px;
    top: 5px;
  }

  .hasBrush {
    right: 25px;
  }
</style>
